export default theme => ({
  actionButton: {
    padding: 0,
    margin: '0 10px',
    color: theme.palette.textColor.primary,
    '&:hover': {
      color: theme.palette.textColor.primary,
      background: `${theme.palette.background.default} !important`
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
      background: `${theme.palette.background.default} !important`
    },
    '& img': {
      marginRight: 5
    }
  },
  textRight: {
    textAlign: 'right'
  }
});
