import React, { useEffect, useState } from 'react';
import { Drawer, Typography, withStyles } from '@material-ui/core';
import { filters, drawer } from './styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { Close as CloseIcon } from '@material-ui/icons';
import TextField from '@material-ui/core/TextField';
import { isIOS, Option } from '../../../../../../components/Option';

const DrawerStyled = withStyles(drawer)(Drawer);

const FiltersDrawer = ({ value: initialValue, classes, onClose, onConfirm, ...props }) => {
  const [value, setValue] = useState(initialValue);
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <DrawerStyled anchor={'right'} {...props}>
      <Grid container spacing={2} justifyContent={'space-between'}>
        <Grid item xs={'auto'}>
          <Typography variant="h3">Filters</Typography>
        </Grid>
        <Grid item xs={'auto'}>
          <IconButton onClick={() => onClose()}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container spacing={2} direction={'column'} className={classes.form}>
        <Grid item xs={'auto'}>
          <label htmlFor="sortField" className={classes.label}>
            <Typography variant="body1" color="textSecondary">
              Sort by:
            </Typography>
            <TextField
              select
              id="sortField"
              name="sortField"
              placeholder={'Field'}
              value={value.sortField}
              onChange={(event) => setValue({ ...value, sortField: event.target.value })}
              variant="outlined"
              inputProps={{ 'aria-label': 'Sort Field' }}
              SelectProps={{
                native: isIOS,
              }}
              fullWidth
            >
              <Option key={'projectName'} value={'projectName'}>
                Project name
              </Option>
              <Option key={'username'} value={'username'}>
                User Name
              </Option>
            </TextField>
          </label>
        </Grid>
        <Grid item xs={'auto'}>
          <label htmlFor="sortDirection" className={classes.label}>
            <Typography variant="body1" color="textSecondary">
              Sort from:
            </Typography>
            <TextField
              select
              id="sortDirection"
              name="sortDirection"
              placeholder={'Direction'}
              value={value.sortDirection}
              onChange={(event) => setValue({ ...value, sortDirection: event.target.value })}
              variant="outlined"
              inputProps={{ 'aria-label': 'Sort Direction' }}
              SelectProps={{
                native: isIOS,
              }}
              fullWidth
            >
              <Option key={'asc'} value={'asc'}>
                A-Z
              </Option>
              <Option key={'desc'} value={'desc'}>
                Z-A
              </Option>
            </TextField>
          </label>
        </Grid>
        <Grid item xs={'auto'}>
          <label htmlFor="type" className={classes.label}>
            <Typography variant="body1" color="textSecondary">
              Type:
            </Typography>
            <TextField
              select
              id="type"
              name="type"
              value={value.type}
              placeholder={'Type'}
              onChange={(event) => setValue({ ...value, type: event.target.value })}
              variant="outlined"
              inputProps={{ 'aria-label': 'Type' }}
              SelectProps={{
                native: isIOS,
              }}
              fullWidth
            >
              <Option key={'all'} value={'all'}>
                All
              </Option>
              <Option key={'active'} value={'active'}>
                Active
              </Option>
              <Option key={'inactive'} value={'inactive'}>
                Inactive
              </Option>
            </TextField>
          </label>
        </Grid>
        <Grid item xs />
        <Grid item xs={'auto'}>
          <Grid container spacing={2} justifyContent={'center'}>
            <Grid item xs={'auto'}>
              <Button variant="outlined" className={classes.button} onClick={() => onClose()}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs={'auto'}>
              <Button color="primary" variant="outlined" className={classes.button} onClick={() => onConfirm(value)}>
                Apply
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DrawerStyled>
  );
};

export default withStyles(filters)(FiltersDrawer);
