import useTabs from './useTabs';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { exportExcel } from 'store/timeclocks/timeclocksPageOperations';
import timeclocksPageActions from 'store/timeclocks/timeclocksPageActions';
import { useSnackbar } from 'notistack';

export default () => {
  const dispatch = useDispatch();
  const { weekSummary, summary, excelLoading } = useSelector((state) => state.timeclocks);
  const { weekly } = useTabs();
  const exported = weekly ? weekSummary.exported : summary.exported;
  const { enqueueSnackbar } = useSnackbar();

  const setExported = useCallback(
    (exp) => {
      weekly
        ? dispatch(
          timeclocksPageActions.setWeekSummary({
              ...weekSummary,
              exported: exp,
            })
          )
        : dispatch(
          timeclocksPageActions.setSummary({
              ...summary,
              exported: exp,
            })
          );
    },
    [dispatch, weekSummary, summary]
  );

  const onDownload = useCallback(async () => {
    try {
      dispatch(timeclocksPageActions.setExcelLoading(true));

      await dispatch(exportExcel());
    } catch (e) {
      enqueueSnackbar('Error creating excel report.', { variant: 'error' });
      console.error(e);
    } finally {
      dispatch(timeclocksPageActions.setExcelLoading(false));
    }
  }, [dispatch]);

  return {
    weekly,
    exported,
    setExported,
    onDownload,
    excelLoading,
  };
};
