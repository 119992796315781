import { withStyles } from '@material-ui/core/styles';
import cn from 'classnames';
import userPhoto from 'assets/icons/user-photo-placeholder.svg';
import styles from './../../styles';

const UserTimeoffsList = ({ classes, timeoffs }) => {
  return (
    <div className={classes.userWrapper}>
      {timeoffs?.map((timeoff, i) => {
        return (
          <div className={classes.timeoffWrapper}>
            <div className={classes.userName}>
              <div className={classes.userPhotoWrapper}>
                <img
                  src={userPhoto}
                  width={22}
                  height={22}
                  alt="user photo"
                  className={classes.userPhoto}
                />
              </div>
              {timeoff.username}
            </div>
            <div className={classes.timeoffStatus}>
              <div
                className={cn(classes.statusBtn, {
                  [classes[`${timeoff?.overlapseTimeoff?.status}`]]: true,
                })}
              >
                {timeoff?.overlapseTimeoff?.status || ''}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default withStyles(styles)(UserTimeoffsList);
