import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import { getEquipmentColorClass } from 'helpers/_helpers';

export const useGetEquipmentInfo = (visibleEquipment = []) => {
  const equipment = useSelector((state) => state.schedule.resources.equipment);

  const getEquipmentInfo = useCallback(
    (id, field) => {
      const equipmentList = visibleEquipment.length
        ? visibleEquipment
        : equipment;

      const currentEquipment = equipmentList.find((eq) => eq._id === id);
      if (!currentEquipment) return '';
      if (field === 'number') {
        return parseInt(currentEquipment.name);
      }
      if (field === 'className') {
        return getEquipmentColorClass(currentEquipment.color);
      }
      return currentEquipment[field];
    },
    [equipment, visibleEquipment]
  );

  return { getEquipmentInfo };
};
