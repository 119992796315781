import { createReducer } from '@reduxjs/toolkit';
import { map } from 'lodash';
import { combineReducers } from 'redux';
import suppliesActions from './suppliesActions';
import { filterMaterials } from './utils';

const initialState = {
  materials: {
    loading: false,
    data: [],
    error: null,
    snackBarInfo: null,
    isSuccessfullyAdded: { type: null, message: null },
    filters: {},
  },
  inventory: {
    loading: false,
    data: [],
    snackBarInfo: null,
    drawerInventoryLoading: false,
    successfullyAdded: false,
    successfullyDeleted: false,
    postError: null,
    availableGlassBeads: [],
  },
  manufacturers: {
    loading: false,
    data: [],
    error: null,
    snackBarInfo: null,
    drawerManufacturersLoading: false,
    successfullyAddedManufacturer: false,
    successfullyManufacturerDeleted: false,
    validationManufactureError: false,
  },
  glassBeads: {
    loading: false,
    data: [],
    error: null,
    snackBarInfo: null,
    drawerGlassBeadsLoading: false,
    successfullyAddedGlassBeads: false,
    successfullyGlassBeadsDeleted: false,
    validationGlassBeadError: false,
  },
  surfaces: {
    loading: false,
    data: [],
    error: null,
    snackBarInfo: null,
  }
};
const materials = createReducer(initialState.materials, {
  [suppliesActions.getMaterialsRequest]: (state) => ({ ...state, loading: true }),
  [suppliesActions.getMaterialsSuccess]: (state, { payload }) => ({
    ...state,
    data: [...payload],
    loading: false,
  }),
  [suppliesActions.getMaterialsError]: (state, { payload }) => ({
    ...state,
    snackBarInfo: payload,
    loading: false,
  }),
  [suppliesActions.duplicateMaterialRequest]: (state, { payload }) => ({ ...state, loading: true }),
  [suppliesActions.duplicateMaterialSuccess]: (state, { payload }) => ({ ...state, data: filterMaterials([...state.data, payload], state.filters) }),

  [suppliesActions.postMaterialSuccess]: (state, { payload }) => ({
    ...state,
    data: filterMaterials([...state.data, payload], state.filters),
  }),
  [suppliesActions.isPostMaterialSuccessfully]: (state, { payload }) => ({
    ...state,
    isSuccessfullyAdded: payload,
  }),
  [suppliesActions.updateMaterialSuccess]: (state, { payload }) => ({
    ...state,
    data: filterMaterials(state.data.map((el) => (el._id === payload._id ? payload : el)), state.filters),
  }),
  [suppliesActions.deleteMaterialSuccess]: (state, { payload }) => ({
    ...state,
    data: state.data.filter((el) => el._id !== payload),
  }),
  [suppliesActions.setMaterialFilters]: (state, { payload }) => ({
    ...state,
    filters: payload,
  })
});

const inventory = createReducer(initialState.inventory, {
  [suppliesActions.getInventoryRequest]: (state) => ({ ...state, loading: true }),
  [suppliesActions.getInventorySuccess]: (state, { payload }) => ({
    ...state,
    data: [...payload],
    loading: false,
  }),
  [suppliesActions.getInventoryError]: (state, { payload }) => ({
    ...state,
    snackBarInfo: payload,
    loading: false,
  }),

  [suppliesActions.postInventoryRequest]: (state) => ({ ...state, drawerInventoryLoading: true }),
  [suppliesActions.postInventorySuccess]: (state, { payload }) => ({
    ...state,
    data: [...state.data, payload],
    drawerInventoryLoading: false,
  }),
  [suppliesActions.postInventoryError]: (state, { payload }) => ({
    ...state,
    drawerInventoryLoading: false,
    postError: payload,
  }),
  [suppliesActions.successfullyAdded]: (state, { payload }) => ({
    ...state,
    successfullyAdded: payload,
  }),

  [suppliesActions.updateInventoryRequest]: (state) => ({ ...state, drawerInventoryLoading: true }),
  [suppliesActions.updateInventorySuccess]: (state, { payload }) => ({
    ...state,
    data: state.data.map((el) => (el._id === payload._id ? payload : el)),
    drawerInventoryLoading: false,
  }),
  [suppliesActions.updateInventoryError]: (state, { payload }) => ({
    ...state,
    drawerInventoryLoading: false,
    postError: payload,
  }),

  [suppliesActions.deleteInventorySuccess]: (state, { payload }) => ({
    ...state,
    data: state.data.filter((el) => el._id !== payload),
  }),
  [suppliesActions.deleteInventoryError]: (state, { payload }) => ({
    ...state,
    snackBarInfo: payload,
  }),

  [suppliesActions.inventorySnackBarInfo]: (state, { payload }) => ({
    ...state,
    snackBarInfo: payload,
    drawerInventoryLoading: false,
  }),
  [suppliesActions.isDeleteInventorySuccess]: (state, { payload }) => ({
    ...state,
    successfullyDeleted: payload,
  }),
  [suppliesActions.getInventoryAvailableGlassBeadsSuccess]: (state, { payload }) => ({
    ...state,
    availableGlassBeads: payload,
  }),
  [suppliesActions.getInventoryAvailableGlassBeadsError]: (state, { payload }) => ({
    ...state,
    snackBarInfo: payload,
  }),
});

const manufacturers = createReducer(initialState.manufacturers, {
  [suppliesActions.getManufacturersRequest]: (state) => ({ ...state, loading: true }),
  [suppliesActions.getManufacturersSuccess]: (state, { payload }) => ({
    ...state,
    data: [...payload],
    loading: false,
  }),
  [suppliesActions.getManufacturersError]: (state, { payload }) => ({
    ...state,
    snackBarInfo: payload,
    loading: false,
  }),

  [suppliesActions.postManufactureRequest]: (state) => ({
    ...state,
    drawerManufacturersLoading: true,
  }),
  [suppliesActions.postManufactureSuccess]: (state, { payload }) => ({
    ...state,
    data: [...state.data, payload],
    drawerManufacturersLoading: false,
  }),
  [suppliesActions.postManufactureError]: (state, { payload }) => ({
    ...state,
    drawerManufacturersLoading: false,
    snackBarInfo: payload,
  }),
  [suppliesActions.successfullyAddedManufacturer]: (state, { payload }) => ({
    ...state,
    successfullyAddedManufacturer: payload,
  }),

  [suppliesActions.updateManufactureRequest]: (state) => ({
    ...state,
    drawerManufacturersLoading: true,
  }),
  [suppliesActions.updateManufactureSuccess]: (state, { payload }) => ({
    ...state,
    data: state.data.map((el) => (el._id === payload._id ? payload : el)),
    drawerManufacturersLoading: false,
  }),
  [suppliesActions.updateManufactureError]: (state, { payload }) => ({
    ...state,
    drawerManufacturersLoading: false,
    snackBarInfo: payload,
  }),

  [suppliesActions.deleteManufactureSuccess]: (state, { payload }) => ({
    ...state,
    data: state.data.filter((el) => el._id !== payload),
  }),
  [suppliesActions.deleteManufactureError]: (state, { payload }) => ({
    ...state,
    drawerManufacturersLoading: false,
    snackBarInfo: payload,
  }),
  [suppliesActions.validationManufactureError]: (state, { payload }) => ({
    ...state,
    validationManufactureError: payload,
  }),
  [suppliesActions.isDeleteManufacturerSuccess]: (state, { payload }) => ({
    ...state,
    successfullyManufacturerDeleted: payload,
  }),
});

const glassBeads = createReducer(initialState.glassBeads, {
  [suppliesActions.getGlassBeadRequest]: (state) => ({ ...state, loading: true }),
  [suppliesActions.getGlassBeadSuccess]: (state, { payload }) => ({
    ...state,
    data: [...payload],
    loading: false,
  }),
  [suppliesActions.getGlassBeadError]: (state, { payload }) => ({
    ...state,
    snackBarInfo: payload,
    loading: false,
  }),

  [suppliesActions.postGlassBeadRequest]: (state) => ({
    ...state,
    drawerGlassBeadsLoading: true,
  }),
  [suppliesActions.postGlassBeadSuccess]: (state, { payload }) => ({
    ...state,
    data: [...state.data, payload],
    drawerGlassBeadsLoading: false,
  }),
  [suppliesActions.postGlassBeadError]: (state, { payload }) => ({
    ...state,
    drawerGlassBeadsLoading: false,
    snackBarInfo: payload,
  }),
  [suppliesActions.successfullyAddedGlassBead]: (state, { payload }) => ({
    ...state,
    successfullyAddedGlassBeads: payload,
  }),

  [suppliesActions.updateGlassBeadRequest]: (state) => ({
    ...state,
    drawerGlassBeadsLoading: true,
  }),
  [suppliesActions.updateGlassBeadSuccess]: (state, { payload }) => ({
    ...state,
    data: state.data.map((el) => (el._id === payload._id ? payload : el)),
    drawerGlassBeadsLoading: false,
  }),
  [suppliesActions.updateGlassBeadError]: (state, { payload }) => ({
    ...state,
    drawerGlassBeadsLoading: false,
    snackBarInfo: payload,
  }),

  [suppliesActions.deleteGlassBeadSuccess]: (state, { payload }) => ({
    ...state,
    data: state.data.filter((el) => el._id !== payload),
  }),
  [suppliesActions.deleteGlassBeadError]: (state, { payload }) => ({
    ...state,
    drawerGlassBeadsLoading: false,
    snackBarInfo: payload,
  }),
  [suppliesActions.validationGlassBeadError]: (state, { payload }) => ({
    ...state,
    validationGlassBeadError: payload,
  }),
  [suppliesActions.isDeleteGlassBeadSuccess]: (state, { payload }) => ({
    ...state,
    successfullyGlassBeadsDeleted: payload,
  }),
});

const surfaces = createReducer(initialState.surfaces, {
  [suppliesActions.setSurfaces]: (state, { payload }) => ({
    ...state,
    data: payload,
  }),
  [suppliesActions.addSurface]: (state, { payload }) => ({
    ...state,
    data: state.data.concat([payload]),
  }),
  [suppliesActions.updateSurface]: (state, { payload }) => ({
    ...state,
    data: state.data.map(surf => (surf._id === payload._id ?  payload : surf)),
  }),
  [suppliesActions.deleteSurface]: (state, { payload }) => ({
    ...state,
    data: state.data.filter(surf => surf._id !== payload),
  }),
})

export default combineReducers({
  materials,
  inventory,
  manufacturers,
  glassBeads,
  surfaces,
});
