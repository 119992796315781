import { useMemo } from 'react';
import { generateHoursObject } from 'helpers/helpers';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';

export const useFormattedHoursRange = ({ createdAt, hours }) => {
  const getTimeFormatHook = useTimeSettingsFormat();

  const formattedHoursRange = useMemo(() => {
    if (!hours || hours?.start === undefined || hours?.end === undefined) return '';
    const { hourStart, minuteStart, amPmStart, hourEnd, minuteEnd, amPmEnd } = generateHoursObject(
      hours.start,
      hours.end,
      createdAt,
      getTimeFormatHook
    );

    const startObj = [null, hourStart, minuteStart, amPmStart];
    const endObj = [null, hourEnd, minuteEnd, amPmEnd];

    const start = `${getTimeFormatHook(startObj).formattedTime}`;
    const end = `${getTimeFormatHook(endObj).formattedTime}`;

    return `${start} \u2013 ${end}`;
  }, [createdAt, hours]);

  return { formattedHoursRange };
};
