export default () => ({
  root: {},
  title: {
    color: '#202020',
    fontFamily: 'Poppins',
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: '0.3px',
    lineHeight: '35px',
    marginBottom: 25,
  },
  fields: {
    marginBottom: 30,
  },
  field: {
    marginBottom: 4,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  error: {
    paddingTop: 4,
    color: '#DD6060',
    fontFamily: 'Open Sans',
    fontSize: '11px',
    letterSpacing: '0.14px',
    lineHeight: '15px',
    display: 'block',
  },
  submitBtn: {
    width: '100%',
    outline: 'none !important',
    boxSizing: 'border-box',
    border: '1px solid #5A87EF',
    borderRadius: 4,
    backgroundColor: '#FFFFFF',
    margin: '0 auto',
    padding: '10px 40px',
    color: '#5A87EF',
    fontFamily: 'Poppins',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '21px',
    display: 'block',
    '&:hover, &:active, &:focus': {
      border: '1px solid #5A87EF',
      backgroundColor: '#FFFFFF',
      color: '#5A87EF',
    },
  },
  forgotText: {
    cursor: 'pointer',
    fontSize: 14,
    marginBottom: 0,
    fontFamily: 'Open Sans',
    color: '#5A87EF',
  },
});
