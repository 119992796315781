import formatNumber from '../../utils/formatNumber';
import hoursMinutesToNumber from '../../utils/hoursMinutesToNumber';
import { format, parse } from 'date-fns';

export const getPayrollReconcileState = (data) => {
  if (data) {
    const reconcileState = {
      userId: data?.user?._id,
      totalTime: data.totalTime || 0,
      shopTime: data.shopTime || 0,
      sheets: [],
      timeclocks: [],
      timeoffs: [],
      holidays: [],
    };

    if (data.jobs && data.jobs.length) {
      reconcileState.sheets = data.jobs
        .filter((item = {}) => item.sheet)
        .map((item) => {
          return {
            sheetId: item.sheet._id,
            travelTimeOnly: item.sheet.travelTimeOnly,
            hours: [
              {
                id: item.hoursId,
                start: item.hours && item.hours.start,
                end: item.hours && item.hours.end,
                hours: item.hours && item.hours.hours,
                travelTimeTo:
                  item.travelTo ||
                  Number(formatNumber(hoursMinutesToNumber(item.hours.travelTimeTo))),
                travelTimeFrom:
                  item.travelFrom ||
                  Number(formatNumber(hoursMinutesToNumber(item.hours.travelTimeFrom))),
              },
            ],
          };
        });
      reconcileState.timeoffs = data.jobs
        .filter((item = {}) => item.jobType === 'timeoff' || item.isTimeoff)
        .map((item) => ({
          timeoffId: item._id,
          hours: item.thisDayDuration,
          isPTO: item.isPTO,
          start: item.utcStartDate,
          end: item.utcEndDate,
        }));
      reconcileState.holidays = data.jobs
        .filter((item = {}) => item.jobType === 'holiday')
        .map((item) => ({
          holidayId: item._id,
          hours: item.hours,
          minutes: item.minutes,
          isPaid: true, // only paid holidays shows in timeclock by individual week
        }));
    }
    if (data.timeclock && data.timeclock.length) {
      reconcileState.timeclocks = data.timeclock.map((timeclock) => ({
        timeclockId: timeclock._id,
        startTime: timeclock.modified.inTime || timeclock.inTime,
        endTime: timeclock.modified.outTime || timeclock.outTime,
        hours: timeclock.modified.hoursThisDay,
        lunchTime: timeclock.modified.lunchTime || timeclock.lunchTime,
      }));
    }

    return reconcileState;
  }

  return {};
};

export const getPayrollVerifyState = (date, data, dateFormat) => {
  if (data) {
    const verifyState = {
      userId: data && data.user._id,
      // date: mom$ent(date).format('MM/DD/YYYY'),
      date: format(parse(date, dateFormat, new Date()), dateFormat),
      ...getPayrollReconcileState(data),
    };

    return verifyState;
  }

  return {};
};
