import { useSelector } from 'react-redux';



export const useSettingsDefaultTime = () => {
  const { defaultDayTime, defaultNightTime } = useSelector(
    (store) => store.personalProfile.organization?.settings || {}
  );

  return { defaultDayTime, defaultNightTime };
};
