import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import { SnackbarProvider } from 'notistack';

import styles from './styles';
import Header from './components/Header';
import PageTabs from './components/PageTabs';
import ExportExcelModal from './modals/ExportExcelModal/ExportExcelModal';
import ByIndividualWeek from './components/ByIndividualWeek';
import DayTimeclocks from './components/DayTimeclocks';
import useTabs from './hooks/useTabs';
import useOnKioskEnter from './hooks/useOnKioskEnter';
import useExcelExport from './hooks/useExcelExport';
import timeclocksPageActions from 'store/timeclocks/timeclocksPageActions';
import store from 'store/store';
import WorklogDetails from '../../components/WorklogDetails/WorklogDetails';
import SheetForm from '../Worklogs/components/SheetForm/SheetForm';
import ResourcesForm from '../../components/ResourcesForm/ResourcesForm';
import CommentsForm from '../../components/CommentsForm/CommentsForm';
import MaterialForm from '../../components/MaterialForm/MaterialForm';
import TimeForm from '../../components/WorklogDetails/components/TimeBlock/components/TimeForm/TimeForm';
import WeatherForm from '../../components/WorklogDetails/components/WeatherBlock/components/WeatherForm/WeatherForm';
import NotesForm from '../../components/WorklogDetails/components/NotesBlock/components/NotesForm/NotesForm';
import GeneralInfoForm from '../../components/WorklogDetails/components/GeneralInfoBlock/components/GeneralInfoForm/GeneralInfoForm';
import SnackBar from '../../components/SnackBar';
import PageLoaderFullBlur from '../../components/PageLoaderFullBlur';
import { useParams } from 'react-router-dom';
import { isValid, toDate } from 'date-fns'
import { refreshTimeClock } from 'store/timeclocks/timeclocksPageOperations';
import { parseStringDate } from 'helpers/_date-helpers';

const TimeClockPage = ({ classes }) => {
  const { weekly } = useTabs();
  const dispatch = useDispatch();
  const onKioskEnter = useOnKioskEnter();
  const { exported, setExported, onDownload, excelLoading } = useExcelExport();
  const params = useParams()

  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    text: '',
    type: 'success',
  });
  const [drawer, setDrawer] = useState({
    type: 'update',
    isOpen: false,
    sheet: {},
  });
  const [additionalDrawer, setAdditionalDrawer] = useState({
    type: 'resources',
    subType: 'people',
    isOpen: false,
    material: {},
  });

  const openSnackbar = (type, text) =>
    setSnackbar((prev) => ({ ...prev, isOpen: true, type, text }));
  const closeSnackbar = () => setSnackbar((prev) => ({ ...prev, isOpen: false }));

  const closeDrawer = () => setDrawer((prev) => ({ ...prev, type: 'details', isOpened: false }));
  const openDrawer =
    (type, sheet = {}) =>
    () => {
      setDrawer((prev) => ({ type, isOpen: true, sheet }));
    };

  const openAdditionalDrawer =
    (type, subType, material = {}) =>
    () => {
      setAdditionalDrawer((prev) => ({
        ...prev,
        type,
        subType,
        isOpen: true,
        material,
      }));
    };
  const closeAdditionalDrawer = () => {
    setAdditionalDrawer((prev) => ({ ...prev, isOpen: false }));
  };

  const sheetId = params.sheetId

  useEffect(() => {
    const type = params.type
    const state = store.getState().timeclocks;
    dispatch(timeclocksPageActions.setActiveTab(state.tabs.findIndex((tab) => tab.name === type)));

    if (!state.summary.query?.date || !isValid(parseStringDate(state.summary.query?.date))) {
      dispatch(
        timeclocksPageActions.setSummary({
          ...state.summary,
          query: {
            ...state.summary.query,
            date: new Date(),
          },
          data: [],
        })
      );
    }

    return () => dispatch(timeclocksPageActions.reset());
  }, []);

  const updateVisibleSheets = () => {
    const queryParams = {}

    if( params.type === 'by-individual-week' ) {
      queryParams.byIndividual = 'true';
      dispatch(refreshTimeClock(queryParams))
      return
    }

    if (params.type === 'submitted') queryParams.getSubmitted = 'true';
    if (params.type === 'machine-shop') queryParams.getMachineShop = 'true';

    dispatch(refreshTimeClock(queryParams))
  }

  return (
    <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={1500}>
    <div>
      <div className={classes.root}>
        <Header className={classes.header} onKioskEnter={onKioskEnter} />
        <PageTabs className={classes.tabs} />
        {weekly ? (
          <ByIndividualWeek classes={classes} />
        ) : (
          <DayTimeclocks className={classes.brief} />
        )}
      </div>

      <ExportExcelModal
        open={!!exported}
        value={exported}
        chooseUser={weekly}
        onChange={setExported}
        onSubmit={onDownload}
        onClose={() => setExported(null)}
        onCancel={() => setExported(null)}
      />

      {sheetId && <WorklogDetails
        openSnackbar={openSnackbar}
        updateVisibleSheets={updateVisibleSheets}
        openDrawer={openDrawer}
        openAdditionalDrawer={openAdditionalDrawer}
        updateDrawerFromRouter={closeDrawer}
        sheetIds={[]}
        hasMore={false}
        pageCount={0}
        isTimeClock
      />
      }

      <Drawer
        anchor="right"
        open={drawer.isOpen}
        ModalProps={{
          BackdropProps: {
            classes: {
              root: sheetId ? classes.backdropOpacity : null,
            },
          },
        }}
      >
        {drawer.type === 'update' && (
          <SheetForm
            closeDrawer={closeDrawer}
            type={drawer.type}
            sheet={drawer.sheet}
            openSnackbar={openSnackbar}
          />
        )}
      </Drawer>
      <Drawer anchor="left" open={drawer.type === 'details' && additionalDrawer.isOpen}>
        {additionalDrawer.type === 'resources' && (
          <ResourcesForm closeDrawer={closeAdditionalDrawer} type={additionalDrawer.subType} openSnackbar={openSnackbar} />
        )}
        {additionalDrawer.type === 'comments' && (
          <CommentsForm closeDrawer={closeAdditionalDrawer} openSnackbar={openSnackbar} />
        )}
        {additionalDrawer.type === 'material' && (
          <MaterialForm
            closeDrawer={closeAdditionalDrawer}
            openSnackbar={openSnackbar}
            type={additionalDrawer.subType}
            material={additionalDrawer.material}
          />
        )}
        {additionalDrawer.type === 'time' && (
          <TimeForm closeDrawer={closeAdditionalDrawer} openSnackbar={openSnackbar} />
        )}
        {additionalDrawer.type === 'weather' && <WeatherForm closeDrawer={closeAdditionalDrawer} />}
        {additionalDrawer.type === 'notes' && (
          <NotesForm closeDrawer={closeAdditionalDrawer} openSnackbar={openSnackbar} />
        )}
        {additionalDrawer.type === 'general' && (
          <GeneralInfoForm closeDrawer={closeAdditionalDrawer} />
        )}
      </Drawer>
      <SnackBar closeSnackbar={closeSnackbar} {...snackbar} />

      <PageLoaderFullBlur loading={excelLoading} />
    </div>
    </SnackbarProvider>

  );
};

export default withStyles(styles)(TimeClockPage);
