import React, { useCallback, useState } from 'react';
import { Button, CloseButton, Dialog, DialogActions } from '../base';
import { DialogTitle, EmployeeName } from './styled';
import useStyles from './useStyles';
import TimeclockForm from './components/TimeclockForm';

const EditTimeclockModal = ({ onChange, onReset, onSubmit, data, onClose, open }) => {
  const classes = useStyles();
  const [touched, setTouched] = useState();
  const [error, setError] = useState(null);
  const handleChange = useCallback(
    ({ clockIn, clockOut, lunchTime }) => {
      setTouched(typeof touched === 'boolean');
      if (clockOut !== null && Number(new Date(clockIn)) > Number(new Date(clockOut))) {
        setError('Clock-in cannot be after Clock-out');
      } else {
        setError(null);
        onChange({
          ...data,
          inTime: clockIn,
          outTime: clockOut || undefined,
          lunchTime: lunchTime,
        });
      }
    },
    [data, onChange, touched]
  );

  const handleSubmit = useCallback(() => {
    if (error) {
      return;
    }

    onSubmit();
  }, [error, onSubmit, touched]);

  return (
    <Dialog open={open}>
      <CloseButton onClick={() => onClose() || setTouched(false)} />
      <DialogTitle className={classes.title} disableTypography={true}>
        Timeclock edit mode
      </DialogTitle>
      {data ? (
        <>
          <EmployeeName>{data.user.profile.fullName}</EmployeeName>
          <TimeclockForm
            value={{
              clockIn: data?.inTime,
              clockOut: data?.outTime,
              lunchTime: data?.lunchTime || data?.modified?.lunchTime,
              // clockIn: data.realInTime,
              // clockOut: data.realOutTime,
              // lunchTime: data.realLunchTime,
            }}
            error={touched && error}
            onChange={handleChange}
          />
        </>
      ) : null}
      <DialogActions disableSpacing={false}>
        <Button
          variant={'outlined'}
          color={'secondary'}
          fullWidth={true}
          onClick={() => onReset() || setTouched(false)}
        >
          Reset to origin
        </Button>
        <Button variant={'outlined'} fullWidth={true} onClick={handleSubmit}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditTimeclockModal;
