import React, { Component } from 'react';
import propTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';

import styles from './styles';

const SearchForm = ({ classes, searchValue, updateSearchValue }) => (
  <Formik
    initialValues={{ searchValue }}
    onSubmit={values => updateSearchValue(values.searchValue)}
  >
    {({ values, handleChange }) => (
      <Form autoComplete="off">
        <div>
          <TextField
            type="text"
            name="searchValue"
            variant="outlined"
            placeholder="Search"
            value={values.searchValue}
            onChange={handleChange}
            className={classes.search}
            inputProps={{ 'aria-label': 'Search' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                </InputAdornment>
              )
            }}
          />
        </div>
      </Form>
    )}
  </Formik>
);

SearchForm.propTypes = {
  classes: propTypes.object.isRequired,
  searchValue: propTypes.string.isRequired,
  updateSearchValue: propTypes.func.isRequired
}

export default withStyles(styles)(SearchForm);
