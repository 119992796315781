import {useMemo} from "react";
import {useSelector} from "react-redux";

export default () => {
    const {summary, weekSummary} = useSelector(state => state.timeclocks)
    const summaryTimeclocks = useMemo(() => summary.data.reduce((accum, d) => [...accum, ...d.timeclock], []), [summary.data])
    const weekSummaryTimeclocks = useMemo(() => weekSummary.data.summaries ? weekSummary.data.summaries.reduce((accum, d) => [...accum, ...(d.summary ? d.summary.timeclock : [])], []) : [], [weekSummary.data.summaries])

    return useMemo(() => [...summaryTimeclocks, ...weekSummaryTimeclocks], [weekSummaryTimeclocks, summaryTimeclocks])
}
