import React from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InfiniteScroll from 'react-infinite-scroller';
import UserCard from './components/UserCard';
import IconSunny from 'assets/images/icon-sunny.svg';
import IconMoon from 'assets/images/icon-moon.svg';
import styles from './styles';
import { format } from 'date-fns';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';
import { allTimeFormat } from 'common/dateFormatConfig';

const UsersList = ({
  classes,
  users,
  openDrawer,
  currentUserId,
  loadUsers,
  hasMore,
  openSnackbar,
}) => {
  const loadMore = (page) => {
    if (hasMore) loadUsers(false, page);
  };
  const { dateFormat } = useDateSettingsFormat();

  const formatLastLogin = (timestamp) => {
    return timestamp
      ? `last seen ${format(new Date(timestamp), `${dateFormat} 'at' ${allTimeFormat[1]}`)}`
      : '';
  };

  const renderUserShift = (type) => {
    switch (type) {
      case 'Day':
        return (
          <span>
            <img src={IconSunny} alt="sunny" className={classes.shiftImage} />
            Day
          </span>
        );
      case 'Night':
        return (
          <span>
            <img src={IconMoon} alt="moon" className={classes.shiftImage} />
            Night
          </span>
        );
      default:
        return 'Inactive';
    }
  };

  return users && users.length ? (
    <InfiniteScroll
      pageStart={0}
      loadMore={loadMore}
      hasMore={hasMore}
      loader={
        <div className={classes.loader} key={0}>
          Loading ...
        </div>
      }
      threshold={1400}
    >
      <Grid container spacing={2} justifyContent="flex-start" className={classes.paddingBottom}>
        {users.map((user) => (
          <Grid key={user._id} item xs={12} lg={3} xl="auto">
            <UserCard
              user={user}
              formatLastLogin={formatLastLogin}
              renderUserShift={renderUserShift}
              openDrawer={openDrawer}
              currentUserId={currentUserId}
              openSnackbar={openSnackbar}
            />
          </Grid>
        ))}
      </Grid>
    </InfiniteScroll>
  ) : (
    <Typography variant="h3" align="center" className={classes.noResultsBlock}>
      No Results
    </Typography>
  );
};

UsersList.propTypes = {
  classes: propTypes.object.isRequired,
  users: propTypes.array.isRequired,
  openDrawer: propTypes.func.isRequired,
  currentUserId: propTypes.string,
  loadUsers: propTypes.func.isRequired,
  hasMore: propTypes.bool.isRequired,
};

export default withStyles(styles)(UsersList);
