import { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { v4 as uuidv4 } from 'uuid';
import { hasPermissionsFor } from 'helpers/_helpers';
import ContactModal from './ContactModal';
import Button from '@material-ui/core/Button';
import { validateEmail } from 'components/WorklogDetails/components/SubmitSection/components/SubmitModal/SubmitModal';

const ContactCreator = ({
  classes,
  setCurrContractorContacts,
  setContactCreatorForm,
  values,
  setValue, //form setter
  contactIndex,
}) => {
  const [name, setName] = useState('');
  const [title, setTitle] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [modal, setModal] = useState(false);

  const addContactFromEditor = (relatesTo) => {
    const newContact = {
      name,
      title,
      email,
      phoneNumber,
      relatesTo,
      new: true,
      _id: uuidv4(),
    };

    const selectorIndex = contactIndex;

    if (values.alternateContacts[selectorIndex] === '') {
      const newIdContacts = values.alternateContacts.slice();
      newIdContacts[selectorIndex] = newContact._id;
      setValue('alternateContacts', newIdContacts);
    } else {
      setValue('alternateContacts', [...values.alternateContacts, newContact._id]);
    }

    setCurrContractorContacts((prev) => [...prev, newContact]);
    setContactCreatorForm(false);
  };

  const hasAddToProjAccess = () => {
    return hasPermissionsFor('projectsFullAccess') || hasPermissionsFor('projectsEdit');
  };

  const addContact = () => {
    if (hasAddToProjAccess()) {
      setModal(true); //open modal to run with the parameters addContactFromEditor('sheet' | 'contractor')
      return;
    }
    addContactFromEditor('sheet');
  };

  const disableRule =
    !name.trim() ||
    !!(email.trim() && !validateEmail(email.trim())) ||
    (phoneNumber.trim() && !(phoneNumber.trim().length === 10));

  return (
    <>
      <Grid container item xs={12} lg={12} justifyContent="space-between" alignItems="center">
        <Typography variant="h6" color="textPrimary" style={{ margin: '5px 0' }}>
          Add Contact:
        </Typography>
      </Grid>
      <Grid container spacing={3} key={'i'}>
        <Grid item xs={12} lg={6}>
          <label htmlFor="contactsName">
            <Typography variant="body1" color="textSecondary">
              Name<span className={classes.requiredSign}>*</span>:
            </Typography>
            <TextField
              className={classnames(classes.textField, classes.zeroPaddingTop)}
              inputProps={{ 'aria-label': 'contactsName' }}
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              variant="outlined"
              fullWidth
            />
          </label>
        </Grid>
        <Grid item xs={12} lg={6}>
          <label htmlFor="contactsTitle">
            <Typography variant="body1" color="textSecondary">
              Title:
            </Typography>
            <TextField
              className={classes.textField}
              variant="outlined"
              inputProps={{ 'aria-label': 'contactsTitle' }}
              fullWidth
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </label>
        </Grid>
        <Grid item xs={12} lg={6} className={classes.zeroPaddingTop}>
          <label htmlFor="contactsEmail">
            <Typography variant="body1" color="textSecondary">
              Email:
            </Typography>
            <TextField
              className={classes.textField}
              variant="outlined"
              inputProps={{ 'aria-label': 'contactsEmail' }}
              fullWidth
              error={email?.trim() && !validateEmail(email.trim())}
              helperText={
                email?.trim() && !validateEmail(email.trim()) && 'Enter valid email address'
              }
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </label>
        </Grid>
        <Grid item xs={12} lg={6} className={classes.zeroPaddingTop}>
          <label htmlFor="contactsPhoneNumber">
            <Typography variant="body1" color="textSecondary">
              Phone number:
            </Typography>
            <TextField
              className={classes.textField}
              variant="outlined"
              inputProps={{ 'aria-label': 'contactsPhoneNumber' }}
              error={phoneNumber.trim() && !(phoneNumber.trim().length === 10)}
              helperText={
                phoneNumber.trim() &&
                !(phoneNumber.trim().length === 10) &&
                'Phone number must be 10 digits'
              }
              fullWidth
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(e.target.value.replace(/[^0-9]*/g, ''));
              }}
            />
          </label>
        </Grid>
      </Grid>
      <Grid container item xs={12} lg={12} justifyContent="flex-end" alignItems="center">
        <Grid item container justifyContent="flex-end" alignItems="center" xs={4} lg={3}>
          <Button
            variant="text"
            className={classes.primaryTextButton}
            disabled={!!disableRule}
            onClick={() => {
              addContact();
            }}
            disableRipple
          >
            Save
          </Button>
          <Typography
            style={{
              backgroundColor: 'transparent',
              padding: '12px 0',
              marginLeft: 10,
              fontWeight: 500,
              cursor: 'pointer',
            }}
            onClick={() => {
              setContactCreatorForm(false);
            }}
            variant="body1"
            color="textSecondary"
            aria-label="Delete"
            disableTouchRipple
          >
            Cancel
          </Typography>
        </Grid>
      </Grid>
      {modal && (
        <ContactModal
          isOpen={modal}
          handleModal={setModal}
          addContactFromEditor={addContactFromEditor}
        />
      )}
    </>
  );
};

export default withStyles(styles)(ContactCreator);
