import React, { Component } from 'react';
import 'react-day-picker/lib/style.css';
import { format, addDays, isSameDay, isValid } from 'date-fns';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Button from '@material-ui/core/Button';
import styles from './styles';
import cs from 'classnames';

import './styles.css';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { getWeekStartDayNumO } from 'helpers/_helpers';
import { GLOBAL_DATE_FORMAT, momentAdepter } from 'common/dateFormatConfig';

class DayPickerOneDay extends Component {
  state = {
    selectedDay: this.props.selectedDate,
    isDayPickerOpen: false,
  };

  ref = React.createRef();

  shouldComponentUpdate(nextProps, nextState) {
    if (!isValid(nextState.selectedDay || !isValid(this.state.selectedDay))) return true;
    let newDay = isValid(nextState.selectedDay) ? new Date(nextState.selectedDay) : new Date();
    let prevSelectedDay = isValid(this.state.selectedDay)
      ? new Date(this.state.selectedDay)
      : new Date();
    const condition1 = !isSameDay(newDay, prevSelectedDay);
    const condition2 = this.state.isDayPickerOpen !== nextState.isDayPickerOpen;
    const condition3 = nextProps.dis != this.props;
    return condition1 || condition2 || condition3;
  }

  componentDidUpdate(prevProps, prevState) {
    const newDay = isValid(this.state.selectedDay) ? new Date(this.state.selectedDay) : new Date();
    const prevSelectedDay = isValid(prevState.selectedDay)
      ? new Date(prevState.selectedDay)
      : new Date();
    if (!isSameDay(newDay, prevSelectedDay)) {
      this.props.updateSelectedDate(this.state.selectedDay);
    }
  }

  handleDayClick = (day) => {
    this.setState(
      (prevState) => ({
        selectedDay: day,
        isDayPickerOpen: !prevState.isDayPickerOpen,
      }),
      () => this.ref.current.hideDayPicker()
    );
  };

  toggleCalendar = () => {
    this.setState(
      (prevState) => ({ isDayPickerOpen: !prevState.isDayPickerOpen }),
      () => {
        if (this.state.isDayPickerOpen) {
          this.ref.current.showDayPicker();
        } else {
          this.ref.current.hideDayPicker();
        }
      }
    );
  };

  getDayName = () => {
    const { selectedDay } = this.state;
    let date = isValid(selectedDay) ? selectedDay : new Date();
    return format(new Date(date), GLOBAL_DATE_FORMAT.fullDateWithDay);
  };

  closeDayPicker = () => {
    this.setState({ isDayPickerOpen: false }, () => this.ref.current.hideDayPicker());
  };

  changeDay = (diff) => () => {
    this.setState(
      (prevState) => ({
        selectedDay: addDays(new Date(prevState.selectedDay), diff),
        isDayPickerOpen: false,
      }),
      () => this.ref.current.hideDayPicker()
    );
  };

  setToday = () => this.setState({ selectedDay: new Date() });

  isToday = () => {
    if (!this.state.selectedDay) return true;
    return isSameDay(new Date(), new Date(this.state.selectedDay));
  };

  render() {
    const { selectedDay } = this.state;
    const { classes, dis, weekStart } = this.props;

    return (
      <div className={cs(classes.root, 'inlineFlexWrapper')}>
        <Button
          className={classes.todayButton}
          variant="contained"
          color="secondary"
          onClick={this.setToday}
          disabled={this.isToday()}
          disableTouchRipple
        >
          Today
        </Button>
        <ArrowLeftIcon
          className={cs(classes.arrowButton, 'changeMonthIcon leftArrow', { [classes.dis]: dis })}
          onClick={dis ? () => {} : this.changeDay(-1)}
        />
        <ArrowRightIcon
          className={cs(classes.arrowButton, 'changeMonthIcon rightArrow', { [classes.dis]: dis })}
          onClick={dis ? () => {} : this.changeDay(1)}
        />
        <ClickAwayListener onClickAway={this.closeDayPicker}>
          <div className="dayPickerWrapper endDatePicker fullWidth">
            <div className="selectedDayName" onClick={this.toggleCalendar}>
              {this.getDayName()} <ArrowDownIcon className={cs(classes.arrowButton, 'downArrow')} />
            </div>
            <DayPickerInput
              ref={this.ref}
              formatDate={formatDate}
              parseDate={parseDate}
              format={momentAdepter(GLOBAL_DATE_FORMAT.fullDateWithDay).momentShortDayDate} //* @ "ddd, MMMM DD, YYYY"
              value={selectedDay}
              onDayChange={this.handleDayClick}
              dayPickerProps={{
                firstDayOfWeek: getWeekStartDayNumO(weekStart),
              }}
            />
          </div>
        </ClickAwayListener>
      </div>
    );
  }
}

// export default withStyles(styles)(DayPickerOneDay);
export default withStyles(styles)(
  connect((state) => ({
    weekStart: state.settings?.settings?.weekStart || 'Sun',
  }))(DayPickerOneDay)
);
