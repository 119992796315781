export default theme => ({
  printLink: {
    fontFamily: 'Poppins,Open Sans,sans-serif',
    display: 'inline-block',
    textDecoration: 'none !important',
    padding: 0,
    marginLeft: 16,
    color: `${theme.palette.textColor.secondary} !important`,
    fontSize: '0.875rem',
    fontWeight: 500,
    height: 22,
    verticalAlign: 'middle',
    // [theme.breakpoints.only('xs')]: {
    //   padding: '2px 0 0',
    //   marginRight: 15
    // },
    '& img': {
      marginRight: 4
    },
    '&:hover': {
      color: theme.palette.textColor.secondary,
      backgroundColor: `${theme.palette.background.default} !important`,
    },
    '&:focus': {
      color: theme.palette.textColor.secondary,
      backgroundColor: `${theme.palette.background.default} !important`,
    }
  },
  paddingBottom: {
    paddingBottom: 20
  },
  loader: {
    fontSize: 14
  }
});
