import { WORKER_EVENT } from "subscriptions/events/events.enum";
import { subscriptionsFabric } from 'subscriptions/subscriptions.fabric';
import currentWorklogActions from 'store/currentWorklog/actions';

const handlers = {
  [WORKER_EVENT.shiftChanged]: (store) => (data) => {
    if (data._id) {
      const workerInCurrentWorklog = store.getState().currentWorklog.worklogSheet?.sheet?.workers?.find(w => w._id === data._id);
      if (workerInCurrentWorklog) {
        const updates = {
          _id: data._id,
          username: data.username,
          shifts: data.profile?.shifts,
        }
        store.dispatch(currentWorklogActions.updateWorker(updates));
      }
    }
  },
  [WORKER_EVENT.usernameChanged]: (store) => (data) => {
    if (data?._id) {
      const workerInCurrentWorklog = store.getState().currentWorklog.worklogSheet?.sheet?.workers?.find(w => w._id === data._id);
      if (workerInCurrentWorklog) {
        const updates = {
          _id: data._id,
          username: data.username,
          shifts: data.profile?.shifts,
        }
        store.dispatch(currentWorklogActions.updateWorker(updates));
      }
    }
  },
}


export const currentWorklogWorkersSubscriptionMiddleware = subscriptionsFabric(
  currentWorklogActions,
  WORKER_EVENT,
  handlers
);