export const styles = (theme) => ({
  cancelBtn: {
    width: 140,
    '&:hover, &:focus': {
      color: theme.palette.textColor.blue,
    },
  },
  dialogRoot: {
    width: 350,
  },
  cipBtn: {
    padding: 0,
    display: 'block',
    margin: '0',
    color: theme.palette.textColor.primary,
    '&:hover': {
      color: theme.palette.textColor.primary,
      background: theme.palette.background.default,
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
      background: theme.palette.background.default,
    },
    '&:disabled': {
      color: theme.palette.textColor.primary,
      background: theme.palette.background.default,
    },
  },
  cipContainer: {
    overflowY: 'auto',
    maxHeight: '75vh',
  },
  label: {
    position: 'relative',
    paddingBottom: 15,
  },
  selectError: {
    borderColor: theme.palette.textColor.red,
  },
  error: {
    color: theme.palette.textColor.red,
    padding: '0 5px',
    bottom: 5,
    fontSize: 11,
    position: 'absolute',
  },
  confirmBtn: {
    width: 140,
    '&:focus': {
      backgroundColor: theme.palette.textColor.blue,
    },
  },
  textArea: {
    '& textarea': {
      boxShadow: 'none',
      padding: 10,
      fontSize: 14,
      height: '60px !important',
      maxHeight: '60px !important'
    }
  },
});
