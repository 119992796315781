export default theme => ({
  card: {
    paddingTop: 10,
    [theme.breakpoints.only('xl')]: {
      width: 237,
    },
  },
  regularFontWeight: {
    fontWeight: 300,
  },
  paddingBox: {
    padding: '0 8px 8px',
  },
  paddingTop: {
    paddingTop: 8
  },
  paddingBottom: {
    paddingBottom: 8
  },
  paddingBottomSmall: {
    paddingBottom: 4
  },
  paddingLeft: {
    paddingLeft: 5
  },
  marginLeft: {
    marginLeft: 10
  },
  iconButton: {
    padding: 0,
    '&:hover': {
      background: `${theme.palette.background.default}!important`
    },
    '&:focus': {
      background: `${theme.palette.background.default}!important`
    },
  },
  downloadIcon: {
    color: theme.palette.primary.blue,
    '&:hover': {
      color: `${theme.palette.primary.blue}!important`,
      background: `${theme.palette.background.default}!important`
    },
    '&:focus': {
      color: `${theme.palette.primary.blue}!important`,
      background: `${theme.palette.background.default}!important`
    },
  },
  disabledIcon: {
    cursor: 'default',
    filter: 'opacity(0.4)',
    '& img': {
      cursor: 'default',
    }
  },
  capitalize: {
    textTransform: 'capitalize'
  },
  inlineFlex: {
    display: 'inline-flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  flexHeader: {
    alignItems: 'flex-start'
  },
  fixedWidth: {
    width: 'calc(100% - 30px)'
  },
  deleteButton: {
    minWidth: 24,
    height: 24,
    padding: 5,
    '&:hover': {
      background: `${theme.palette.background.default}!important`
    },
    '&:focus': {
      background: `${theme.palette.background.default}!important`
    },
  },
  expand: {
    transform: 'rotate(0deg)',
    padding: 0,
    '&:hover': {
      color: theme.palette.textColor.secondary,
      backgroundColor: '#fff',
    },
    '&:focus': {
      color: theme.palette.textColor.secondary,
      backgroundColor: '#fff',
    },
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  borderTop: {
    borderTop: `1px solid ${theme.palette.borderColor.primary}`,
  },
  actionsWrapper: {
    borderTop: `1px solid ${theme.palette.borderColor.primary}`,
    justifyContent: 'space-around',
    padding: 5,
    [theme.breakpoints.down('md')]: {
      paddingBottom: 0,
    },
  },
  linkText: {
    color: theme.palette.textColor.blue,
    cursor: 'pointer'
  },
  linkWrapper: {
    margin: '5px 0',
    cursor: 'pointer'
  },
  disabledLink: {
    margin: '5px 0',
    '& span': {
      color: theme.palette.textColor.secondary,
    }
  },
  defaultCursor: {
    cursor: 'auto'
  }
});
