const { object, string, number } = require('yup');

export const LineMaterialSchema = (lines = [], edit) =>
  object().shape({
    width: Number.isInteger(edit)
      ? number()
          .typeError('Number is invalid. Only symbols 0 - 9 allowed')
          .positive('Value should be positive number')
          .required('Field is required')
      : number()
          .typeError('Number is invalid. Only symbols 0 - 9 allowed')
          .positive('Value should be positive number')
          .required('Field is required')
          .test({
            name: 'lineWidthUnique',
            message: 'Line with same width already exists in current configuration',
            // if found line with same value => throw validation error
            test: (value) => !lines.find(({ width }) => width === value),
          }), // required
    ratio: number()
      .typeError('Number is invalid. Only symbols 0 - 9 allowed')
      .positive('Value should be positive number')
      .required('Field is required'), // required
    homepageStats: string().transform((value) => (!value ? undefined : value)), // enum['liquidMarking', 'eradication']
    testQuantity: number()
      .transform((currentValue, originalValue) => {
        return originalValue === '' ? null : currentValue;
      })
      .typeError('Number is invalid. Only symbols 0 - 9 allowed')
      .positive('Value should be positive number')
      .nullable(true),
    _id: string().optional(),
  });
