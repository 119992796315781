import React from 'react'
import cs from 'classnames'
import './styles.scss'
import UserItem from './components/UserItem'

const UsersCarousel = ({ current, next, previous, className, onChange, ...props }) => <div
  className={cs('users_slider', className)} {...props}>
  <div className={'users_slider-container'}>
    <div className={'users_slider-item'}>
      <UserItem user={previous}/>
    </div>
    <div className={cs('users_slider-item', 'users_slider-item_active')}>
      {<button className={cs('users_slider-item-button', 'users_slider-item-back-button', {
        'users_slider__invisible': !previous
      })}
               type={'button'}
               onClick={() => onChange(previous._id)}/>}
      <UserItem user={current}/>
      {<button className={cs('users_slider-item-button', 'users_slider-item-forward-button', {
        'users_slider__invisible': !next
      })}
               type={'button'}
               onClick={() => onChange(next._id)}/>}
    </div>
    <div className={'users_slider-item'}>
      <UserItem user={next}/>
    </div>
  </div>
</div>

export default UsersCarousel
