import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import propTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core';

import styles from '../../../HoursBlock/styles';
import { isIOS, Option } from '../../../../../Option';
import { useTimeStates } from 'hooks/useTimeStates';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';
import { DAY_PART, convertTimeObject } from 'common/dateFormatConfig';

const StartTimeBlock = ({ classes, sheet, data, updateStartTime, setStartTime, canEdit, noPadding }) => {
  const { hours, minutes } = useTimeStates({ minuteStep: 5, takeSettingsHours: true });
  const getTimeFormatHook = useTimeSettingsFormat();
  const is12Format = getTimeFormatHook().is12Format;

  const [amPm] = useState(DAY_PART);

  useEffect(() => {
    if (!data) {
      const {
        hour: hourStart,
        minute: minuteStart,
        amPm: amPmStart,
      } = convertTimeObject(sheet.startTime, is12Format);
      const startTime = { hourStart: String(Number(hourStart)), minuteStart, amPmStart };
      setStartTime(startTime);
    }
  }, [data]);

  if (!data) return null;
  return (
    <div className={classnames(classes.inlineFlex, classes.rowWrapper, classes.startTimeWrapper)}>
      <Typography
        variant="body1"
        color="textSecondary"
        className={classnames(classes.regularFontWeight, classes.paddingBottom)}
      >
        Start time:
      </Typography>
      <div className={classes.inlineFlex} style={{ paddingRight: noPadding ? 0 : '39px' }}>
        <TextField
          select
          className={classes.textField}
          variant="outlined"
          onChange={updateStartTime('hourStart')}
          value={data.hourStart}
          fullWidth
          disabled={!canEdit}
          SelectProps={{
            native: isIOS,
          }}
        >
          {hours.map((value, index) => (
            <Option key={index} value={value}>
              {value}
            </Option>
          ))}
        </TextField>
        :
        <TextField
          select
          className={classes.textField}
          variant="outlined"
          onChange={updateStartTime('minuteStart')}
          value={data.minuteStart}
          fullWidth
          disabled={!canEdit}
          SelectProps={{
            native: isIOS,
          }}
        >
          {minutes.map((value, index) => (
            <Option key={index} value={value}>
              {value}
            </Option>
          ))}
        </TextField>
        {is12Format && (
          <TextField
            select
            className={classes.textField}
            onChange={updateStartTime('amPmStart')}
            value={data.amPmStart}
            variant="outlined"
            fullWidth
            disabled={!canEdit}
            SelectProps={{
              native: isIOS,
            }}
          >
            {amPm.map((value, index) => (
              <Option key={index} value={value}>
                {value.toUpperCase()}
              </Option>
            ))}
          </TextField>
        )}
      </div>
    </div>
  );
};

StartTimeBlock.propTypes = {
  classes: propTypes.object.isRequired,
  sheet: propTypes.object.isRequired,
  updateStartTime: propTypes.func.isRequired,
};

export default withStyles(styles)(StartTimeBlock);
