import React from 'react';
import '../../styles.scss';
import InfoItem from '../InfoItem/InfoItem';
import isCorrectNumber from '../helpers/isCorrectNumber';

function FleetMaintenanceRole({ data }) {
  return (
    <>
      {data && data.equipment && (
        <>
          <div className="cards-wrapper">
            <InfoItem
              quantity={isCorrectNumber(data.equipment.total)}
              info={'Active Equipment'}
              borderColor={'#8EB585'}
              textColor={'black'}
            />
            <InfoItem
              quantity={isCorrectNumber(data.equipment.totalDowntime)}
              info={'Equipment downtime'}
              currency={'H'}
              borderColor={'#B63C36'}
              textColor={'black'}
            />
          </div>
        </>
      )}
    </>
  );
}

export default FleetMaintenanceRole;
