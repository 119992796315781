import React, { useEffect, useState } from 'react';
import { Root } from '../../../Timeclocks/components/TimeclockItem/styled';
import { Grid } from '@material-ui/core';
import formatNumber from '../../../../../../utils/formatNumber';
import { TotalTime } from '../../../styled';
import Warning from '../../../../../../../../components/icons/Warning';
import { makeStyles } from '@material-ui/core/styles';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const TimeoffItem = ({
  data,
  currentRoute,
  hasPayroll,
  payrollVerified,
  payrollTimeoffItem,
  ...props
}) => {
  const classes = useStyles();
  const [payrollDifferentTime, setPayrollDifferentTime] = useState(false);

  useEffect(() => {
    if (payrollTimeoffItem) {
      setPayrollDifferentTime(data.thisDayDuration !== payrollTimeoffItem.hours);
    } else {
      // set difference to true if payroll was verified before adding paid timeoff
      setPayrollDifferentTime(data.isPTO);
    }
  }, [data, payrollTimeoffItem]);

  const showExclamationMark = currentRoute && hasPayroll && !payrollVerified;

  return (
    <Root>
      <Grid {...props} className={classes.mobWrapper}>
        <Grid item xs={3}>
          <dl className={'dl'} className={classes.mobExplanation}>
            <dt className={'dt'}>Reason:</dt>
            <dd className={'dd'} style={{ color: '#DD6060' }}>
              {data.reason}
            </dd>
          </dl>
        </Grid>
        {/* <Grid item xs={6}></Grid> */}
        <Grid item xs={3}>
          <Grid container justifyContent={'flex-end'} alignItems={'center'}>
            <Grid item xs={'auto'} style={{ position: 'relative' }}>
              {showExclamationMark && payrollDifferentTime ? (
                <dt className={'warning-right'} style={{ bottom: '7px', right: '142px' }}>
                  <Warning />
                </dt>
              ) : null}
              <TotalTime style={{ color: '#DD6060' }}>
                {formatNumber(data.thisDayDuration)}
              </TotalTime>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Root>
  );
};

export default TimeoffItem;
