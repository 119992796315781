import React from 'react';
import { TextField, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

import { useTimeStates } from 'hooks/useTimeStates';
import { isIOS } from 'components/Option';
import DatePickerInput from 'components/DatePickerInput';
import styles from './styles';
import { createOptions } from 'helpers/createOptions';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';

const useStyles = makeStyles(styles);

const TimeForm = ({ values, setValues, openCalendar }) => {
  const { hours, minutes, dayPart } = useTimeStates({ takeSettingsHours: true });
  const getTimeFormatHook = useTimeSettingsFormat();
  const classes = useStyles();
  const labels = [
    ['Start Date', 'Start Time'],
    ['End Date', 'End Time'],
  ];

  const handleTimeChange = (index, subField) => (e) => {
    e.persist();
    setValues((prev) =>
      prev.map((value, i) =>
        i === index
          ? {
              ...value,
              time: {
                ...value.time,
                [subField]: e.target.value,
              },
            }
          : value
      )
    );
  };

  return (
    <Grid container>
      {values.map(({ date, time }, i) => (
        <React.Fragment key={i}>
          <Grid item xs={6} className={classes.rootContainer}>
            <label htmlFor={`date-${i}`} className={classes.label}>
              <Typography variant="body1" color="textSecondary">
                {labels[i][0]}
              </Typography>
              <div
                className={classnames(classes.wrapper, classes.dateContainer)}
                onClick={openCalendar(i)}
              >
                <DatePickerInput
                  selectedDay={date}
                  setFieldValue={() => null}
                  field="startDate"
                  // disabled
                  sm
                  fullwidth
                />
              </div>
            </label>
          </Grid>
          <Grid item xs={6} className={classes.rootContainer}>
            <label htmlFor={`hour-${i}`} className={classes.label}>
              <Typography variant="body1" color="textSecondary">
                {labels[i][1]}
              </Typography>
              <div className={classes.wrapper}>
                <TextField
                  select
                  id={`hour-${i}`}
                  variant="outlined"
                  value={time.hour}
                  onChange={handleTimeChange(i, 'hour')}
                  SelectProps={{
                    native: isIOS,
                  }}
                >
                  {hours.map(createOptions)}
                </TextField>
                <Typography variant="body1" className={classes.delimiter}>
                  :
                </Typography>
                <TextField
                  select
                  variant="outlined"
                  value={time.minute}
                  onChange={handleTimeChange(i, 'minute')}
                  SelectProps={{
                    native: isIOS,
                  }}
                >
                  {minutes.map(createOptions)}
                </TextField>
                {getTimeFormatHook().is12Format && (
                  <TextField
                    select
                    variant="outlined"
                    value={time.amPm}
                    onChange={handleTimeChange(i, 'amPm')}
                    SelectProps={{
                      native: isIOS,
                    }}
                  >
                    {dayPart.map(createOptions)}
                  </TextField>
                )}
              </div>
            </label>
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
};

export default TimeForm;
