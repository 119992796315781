export default theme => ({
  card: {
    paddingTop: 10,
    [theme.breakpoints.only('xl')]: {
      width: 237,
    },
  },
  regularFontWeight: {
    fontWeight: 300,
  },
  paddingBox: {
    padding: '0 8px 8px',
  },
  paddingBottom: {
    paddingBottom: 8
  },
  paddingBottomSmall: {
    paddingBottom: 4
  },
  marginBottom: {
    marginBottom: 8
  },
  inlineFlex: {
    display: 'inline-flex',
    alignItems: 'center',
    width: '100%'
  },
  flexHeader: {
    display: 'inline-flex',
    alignItems: 'center',
    width: '100%'
  },
  fixedWidth: {
    width: 'calc(100% - 30px)'
  },
  deleteButton: {
    minWidth: 24,
    height: 24,
    padding: 5,
    marginBottom: 8,
    '&:hover': {
      background: `${theme.palette.background.default}!important`
    },
    '&:focus': {
      background: `${theme.palette.background.default}!important`
    },
  },
  secondaryText: {
    color: theme.palette.textColor.secondary,
    fontSize: '0.8rem',
    fontWeight: 400,
    marginRight: 10,
  },
  displayBlock: {
    display: 'inline-block',
    // verticalAlign: 'middle',
    marginRight: 15
  },
  photoWrapper: {
    borderRadius: '50%',
    border: `1px solid ${theme.palette.textColor.blue}`,
    borderTopColor: 'transparent',
    borderBottomColor: 'transparent',
    width: 32,
    height: 32,
    transform: 'rotate(135deg)',
    minWidth: 32,
  },
  photo: {
    display: 'block',
    transform: 'rotate(-135deg)',
    height: 26,
    maxWidth: 26,
    borderRadius: '50%',
    margin: '2px auto 0',
  },
  flexBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  name: {
    marginLeft: 15,
  },
});
