import React from 'react';
import cs from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import DocumentIcon from 'assets/images/document-icon.svg'
import DownloadIcon from 'assets/images/download-icon.svg'

import styles from './styles';

const FileCard = ({ file, classes, onDownload = () => {}, className }) => (
  <div
    className={cs(classes.root, className)}
    onClick={(e) => {
      e.stopPropagation();
      onDownload(file);
    }}
  >
    <div className={classes.fileDescriptionWrapp}>
      <img src={DocumentIcon} className={classes.fileIcon} />
      <div className={classes.fileDescription}>
        <span className={classes.fileName}>{file.name || 'certificate.pdf'}</span>
        <span className={classes.fileSize}>{file.size}</span>
      </div>
    </div>
    <div>
      <img src={DownloadIcon} className={classes.downloadIcon} />
    </div>
  </div>
);

export default withStyles(styles)(FileCard);
