export default theme => ({
  root: {
    padding: '12px 14px 8px',
    border: '1px solid #d9dae3',
    borderRadius: 5
  },
  header: {
    position: 'relative'
  },
  title: {
    color: '#202020',
    fontWeight: 500,
    width: 'calc(100% - 30px)',
    fontSize: '0.95rem',
    lineHeight: 1.6,
    display: 'block'
  },
  number: {
    display: 'block',
    marginTop: 10,
    color: '#A5A4B7',
    fontSize: 13,
    letterSpacing: 0.16,
    lineHeight: '20px'
  },
  deleteButton: {
    position: 'absolute',
    minWidth: 24,
    width: 24,
    height: 24,
    right: 0,
    top: 0,
    padding: 5,
  },
  divider: {
    margin: '14px 0 0',
    border: '1px solid #DBDCE4 none none none',
  },
  body: {},
  definitionList: {
    margin: '14px 0 2px'
  },
  definitionTitle: {
    color: '#A4A5B6',
    fontSize: 13,
    fontFamily: 'Open Sans',
    fontWeight: 400,
    margin: '14px 0 0',
    letterSpacing: 0.16,
    lineHeight: '18px'
  },
  definitionDefinition: {
    margin: '6px 0 0',
    color: '#202020',
    fontSize: 13,
    fontWeight: 500,
    letterSpacing: 0.16,
    lineHeight: '20px'
  },
})
