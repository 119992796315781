import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import PageLoader from 'components/PageLoader2/PageLoader2';
import CommentsForm from 'components/CommentsForm';
import {
  useSheetFormStyles,
  useResourcesFormStyles,
  useSheetDetailsStyles,
} from '../../../../hooks';
import AlertModal from 'components/AlertModal/AlertModal';
import { checkUserRole } from 'helpers/_helpers';
import { userTypes } from 'pages/People/components/UserForm/components/UserPermissions/helpers/userTypes';
import { useDispatch, useSelector } from 'react-redux';
import { putWorklogNotes } from 'store/currentWorklog/operations';
import { updateSheetAddResource } from 'store/schedule/schedulePageOperation';
import PicCardListWrapper from './components/PicCardListWrapper/PicCardListWrapper';

const defaultModalState = { isOpen: false, text: '' };

const NotesForm = ({ closeDrawer, openSnackbar }) => {
  const dispatch = useDispatch();
  const sheet = useSelector((store) => store.currentWorklog.worklogSheet.sheet);
  const user = useSelector((store) => store.personalProfile.user) || {};
  const sheetFormClasses = useSheetFormStyles();
  const resourcesFormClasses = useResourcesFormStyles();
  const sheetDetailsClasses = useSheetDetailsStyles();
  const [infoModal, setInfoModal] = useState(defaultModalState);

  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    getValues,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (!sheet) return;
    const values = getValues() || {};
    setValue('notes', values.notes || sheet.notes);
    setValue('schedulerNotes', values.schedulerNotes || sheet.schedulerNotes);
  }, [sheet]);

  const closeModal = () => setInfoModal(defaultModalState);

  const onSubmit = async (values) => {
    if (errors && Object.keys(errors).length) return;

    try {
      await dispatch(putWorklogNotes(values, sheet._id));
      dispatch(updateSheetAddResource(sheet._id, 1));
      closeDrawer();
    } catch (e) {
      setInfoModal({ isOpen: true, text: e.message });
    }
  };

  const disabledInternalNote = () => {
    const currentUserId = user._id;
    const foreman = sheet.workers && sheet.workers.find(({ foreman }) => foreman);
    const isForeman = foreman && foreman._id === currentUserId;
    return checkUserRole(userTypes.fieldTechnician) && !isForeman;
  };

  return (
    <>
      <div className={resourcesFormClasses.formWrapper} role="presentation">
        {!Object.keys(sheet).length ? (
          <PageLoader loading>
            <div />
          </PageLoader>
        ) : (
          <PageLoader loading={false} small>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={resourcesFormClasses.formFlex}>
                <div>
                  <Typography variant="h3" style={{ marginBottom: '26px' }}>
                    Edit note
                  </Typography>

                  <IconButton
                    className={resourcesFormClasses.closeButton}
                    onClick={closeDrawer}
                    aria-label="Close"
                    disableTouchRipple
                  >
                    <CloseIcon />
                  </IconButton>

                  <Grid container spacing={4}>
                    <Grid item xs={12} className={sheetDetailsClasses.zeroPaddingTop}>
                      <label htmlFor="notes">
                        <Typography variant="body1" color="textSecondary">
                          Pinned note:
                        </Typography>
                        <Controller
                          name="notes"
                          control={control}
                          defaultValue=""
                          as={
                            <TextField
                              id="notes"
                              className={sheetDetailsClasses.textField}
                              variant="outlined"
                              fullWidth
                              multiline
                            />
                          }
                        />
                      </label>
                    </Grid>
                    <Grid item xs={12} className={sheetDetailsClasses.zeroPaddingTop}>
                      <label htmlFor="schedulerNotes">
                        <Typography variant="body1" color="textSecondary">
                          Internal note:
                        </Typography>
                        <Controller
                          name="schedulerNotes"
                          control={control}
                          defaultValue=""
                          as={
                            <TextField
                              id="schedulerNotes"
                              className={sheetDetailsClasses.textField}
                              error={Boolean(errors.schedulerNotes)}
                              variant="outlined"
                              disabled={disabledInternalNote()}
                              multiline
                              inputProps={{ 'aria-label': 'Humidity' }}
                              fullWidth
                            />
                          }
                        />
                        {errors.humidity ? (
                          <div className={sheetDetailsClasses.error}>{errors.humidity}</div>
                        ) : null}
                      </label>
                    </Grid>
                  </Grid>
                  <div style={{ marginLeft: '-15px' }}>
                    <CommentsForm
                      insideNotes
                      closeDrawer={() => null}
                      openSnackbar={openSnackbar}
                    />
                  </div>
                  <PicCardListWrapper sheet={sheet}/>
                  <div className={sheetFormClasses.buttonHolder}>
                    <Button
                      color="secondary"
                      variant="outlined"
                      className={sheetFormClasses.cancelButton}
                      onClick={closeDrawer}
                      disableTouchRipple
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      variant="outlined"
                      className={sheetFormClasses.saveButton}
                      disableTouchRipple
                      disabled={errors && !!Object.keys(errors).length}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </PageLoader>
        )}
      </div>
      {infoModal.isOpen && (
        <AlertModal isOpen={infoModal.isOpen} info={infoModal.text} onClose={closeModal} />
      )}
    </>
  );
};

NotesForm.propTypes = {
  closeDrawer: propTypes.func.isRequired,
  sheet: propTypes.object.isRequired,
};

export default NotesForm;
