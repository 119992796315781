import React, { useCallback, useMemo } from 'react';
import { Select } from '../../pages/TimeClock/modals/base';
import useStyles from './useStyles';
import { ExpandMore } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';
import {
  getMinutes,
  format,
  parse,
  getHours,
  setMinutes,
  subHours,
  addHours,
  setHours,
  isValid,
} from 'date-fns';
import { isIOS, Option } from '../Option';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';
import { useTimeStates } from 'hooks/useTimeStates';
import { createOptions } from 'helpers/createOptions';
import { DAY_PART } from 'common/dateFormatConfig';
import { parseStringDate } from 'helpers/_date-helpers';

const TimeInput = ({ value, onChange }) => {
  const classes = useStyles();
  const getTimeFormatHook = useTimeSettingsFormat();
  const is12Format = getTimeFormatHook().is12Format;
  const { hours, minutes, dayPart } = useTimeStates({ minuteStep: 1, takeSettingsHours: true });

  const momented = useMemo(() => {
    if (!isValid(parseStringDate(value))) return new Date();
    return parseStringDate(value || new Date());
  }, [value]);

  const hoursValue = useMemo(
    () => (is12Format ? format(momented, 'h') : format(momented, 'H')),
    [momented, is12Format]
  );
  const minutesValue = useMemo(() => getMinutes(momented), [momented]);
  const ampmValue = useMemo(
    () => (is12Format ? format(momented, 'a') : ''),
    [momented, is12Format]
  );

  const handleHoursChange = useCallback(
    (e) => {
      const inputHours = +e.target.value;
      let hours;

      if (is12Format) {
        const parsedDate = parse(`${inputHours}:0 ${ampmValue}`, 'h:m a', new Date());
        hours = getHours(parsedDate);
      } else {
        hours = inputHours % 24;
      }

      onChange(setHours(momented, hours));
    },
    [onChange, momented, ampmValue, is12Format]
  );
  const handleMinutesChange = useCallback(
    (e) => {
      onChange(setMinutes(momented, +e.target.value));
    },
    [momented]
  );
  const handleAMPPMChange = useCallback(
    (e) => {
      const ampm = e.target.value;
      const newValue = ampm === DAY_PART[0] ? subHours(momented, 12) : addHours(momented, 12);
      onChange(newValue);
    },
    [momented]
  );

  return (
    <Grid container alignItems={'center'} wrap={'nowrap'}>
      <Grid item xs>
        <Select
          native={isIOS}
          fullWidth={true}
          value={typeof value === 'undefined' || value === null ? '' : hoursValue}
          IconComponent={ExpandMore}
          onChange={handleHoursChange}
          variant="outlined"
          inputProps={{ 'aria-label': 'Hours' }}
        >
          <Option key={0} value={null}>
            -
          </Option>
          {hours.map(createOptions)}
        </Select>
      </Grid>
      <Grid item xs={'auto'}>
        <div className={classes.delimiter}>:</div>
      </Grid>
      <Grid item xs>
        <Select
          native={isIOS}
          fullWidth={true}
          IconComponent={ExpandMore}
          value={typeof value === 'undefined' || value === null ? '' : minutesValue}
          onChange={handleMinutesChange}
          variant="outlined"
          inputProps={{ 'aria-label': 'Minutes' }}
        >
          <Option key={0} value={null}>
            -
          </Option>
          {minutes.map((el, index) => (
            <Option key={index} value={index}>
              {String(index).padStart(2, '0')}
            </Option>
          ))}
        </Select>
      </Grid>
      <Grid item xs={'auto'}>
        <div className={classes.delimiter} />
      </Grid>
      {is12Format && (
        <Grid item xs>
          <Select
            native={isIOS}
            fullWidth={true}
            IconComponent={ExpandMore}
            value={ampmValue}
            onChange={handleAMPPMChange}
            variant="outlined"
            inputProps={{ 'aria-label': `${DAY_PART[0]}-${DAY_PART[1]}` }}
          >
            <Option key={1} value={DAY_PART[0]}>
              {DAY_PART[0]}
            </Option>
            <Option key={2} value={DAY_PART[1]}>
              {DAY_PART[1]}
            </Option>
          </Select>
        </Grid>
      )}
    </Grid>
  );
};

export default TimeInput;
