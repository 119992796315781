import { getTimeZone } from './getTimeZone';

export const getOptions = (contentType) => {
  return {
    headers: {
      'Content-Type': contentType || 'application/json',
      'X-Auth-Token': localStorage.getItem('authorization-token'),
      'X-User-TZ': getTimeZone(),
    },
  };
};

export const getKioskOptions = (contentType) => {
  const options = {
    headers: {
      'Content-Type': contentType || 'application/json',
      'X-User-TZ': getTimeZone(),
    },
  }

  if(localStorage.getItem('x-auth-kiosk-token')){
    options.headers['x-auth-kiosk-token'] = localStorage.getItem('x-auth-kiosk-token')
  }else{
    options.headers['X-Auth-Token'] = localStorage.getItem('authorization-token')
  }

  return options;
};

export const getKioskAdminOptions = (contentType) => {
  const options = {
    headers: {
      'Content-Type': contentType || 'application/json',
      'X-User-TZ': getTimeZone(),
    },
  }

  if(localStorage.getItem('x-admin-kiosk-token')){
    options.headers['x-admin-kiosk-token'] = localStorage.getItem('x-admin-kiosk-token')
  }else{
    options.headers['X-Auth-Token'] = localStorage.getItem('authorization-token')
  }

  return options;
};