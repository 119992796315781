import React from 'react';
import { Input, withStyles } from '@material-ui/core';

import Calendar from '../../../../../../../../../../components/icons/Calendar';
import styles from './styles';
import { format } from 'date-fns';
import { GLOBAL_DATE_FORMAT } from 'common/dateFormatConfig';

const Trigger = React.forwardRef(({ date, ...props }, ref) => (
  <Input
    {...props}
    ref={ref}
    value={format(new Date(date), GLOBAL_DATE_FORMAT.fullDateWithDay)}
    readOnly
    disableUnderline={true}
    endAdornment={<Calendar />}
  />
));

export default withStyles(styles)(Trigger);
