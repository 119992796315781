import React, { useCallback } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import classNames from 'classnames';
import { debounce } from 'lodash';
import styles from './styles';
import { TextField } from '@material-ui/core';
import { drawerTypes } from '../../../Equipment/components/helpers/drawerTypes';

const FiltersBar = ({ classes, openDrawer, updateSearchValue, noFilters, isDynamicSearch }) => {
  const debouncedOnChange = useCallback(
    debounce((value) => {
      updateSearchValue(value);
    }, 300),
    []
  );

  return (
    <Formik
      initialValues={{ searchValue: '' }}
      onSubmit={(values) => {
        updateSearchValue(values.searchValue);
      }}
    >
      {({ values, setFieldValue }) => (
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
          style={{ paddingTop: '8px', paddingBottom: '8px' }}
        >
          <Grid item xs={noFilters ? 12 : 8} md={noFilters ? 12 : 9} xl={noFilters ? 12 : 9}>
            <Form autoComplete="off" className={classes.formFlex}>
              <div className={classes.search}>
                <TextField
                  type="text"
                  name="searchValue"
                  variant="outlined"
                  placeholder="Search"
                  value={values.searchValue}
                  onChange={(e) => {
                    const input = e.target.value;
                    setFieldValue('searchValue', input);
                    isDynamicSearch && debouncedOnChange(input, setFieldValue);
                  }}
                  className={classes.search}
                  inputProps={{ 'aria-label': 'Search' }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <div className={classes.searchIcon}>
                          <SearchIcon />
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </Form>
          </Grid>
          {!noFilters && (
            <Grid item xs={4} md={3} xl={3}>
              <Button
                variant="contained"
                color="secondary"
                className={classes.toggleFiltersBtn}
                onClick={openDrawer(drawerTypes.filters, true)}
                disableTouchRipple
              >
                <i className={classNames('fa fa-sliders', classes.filtersIcon)} aria-hidden="true"></i>
                Filters
              </Button>
            </Grid>
          )}
        </Grid>
      )}
    </Formik>
  );
};

FiltersBar.propTypes = {
  classes: propTypes.object.isRequired,
  openDrawer: propTypes.func.isRequired,
  updateSearchValue: propTypes.func.isRequired,
  noFilters: propTypes.bool,
};

export default withStyles(styles)(FiltersBar);
