import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { TotalTime } from '../../../styled';
import { Root } from '../../../Timeclocks/components/TimeclockItem/styled';
import Warning from '../../../../../../../../components/icons/Warning';
import { makeStyles } from '@material-ui/core/styles';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const HolidayItem = ({
  data,
  currentRoute,
  hasPayroll,
  payrollVerified,
  payrollHolidayItem,
  ...props
}) => {
  const classes = useStyles();
  const [payrollDifferentTime, setPayrollDifferentTime] = useState(false);

  useEffect(() => {
    if (payrollHolidayItem) {
      setPayrollDifferentTime(
        data.hours !== payrollHolidayItem.hours || data.minutes !== payrollHolidayItem.minutes
      );
    } else {
      // set different time to true if holiday was added after verifying payroll
      setPayrollDifferentTime(true);
    }
  }, [data, payrollHolidayItem]);

  const showExclamationMark = currentRoute && hasPayroll && !payrollVerified;

  return (
    <Root>
      <Grid {...props} className={classes.mobWrapper}>
        <Grid item xs={3}>
          <dl className={'dl'} className={classes.mobExplanation}>
            <dt className={'dt'}>Name:</dt>
            <dd className={'dd'} style={{ color: '#DD6060' }}>
              {data.name}
            </dd>
          </dl>
        </Grid>
        <Grid item xs={3}>
          <Grid container justifyContent={'flex-end'} alignItems={'center'}>
            <Grid item xs={'auto'} style={{ position: 'relative' }}>
              {showExclamationMark && payrollDifferentTime ? (
                <dt className={'warning-right'} style={{ bottom: '7px', right: '142px' }}>
                  <Warning />
                </dt>
              ) : null}
              <TotalTime style={{ color: '#DD6060' }}>
                {Number(`${(data.hours * 60 + data.minutes) / 60}`).toFixed(2)}
              </TotalTime>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Root>
  );
};

export default HolidayItem;
