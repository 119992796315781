const statusOptions = [
  {
    value: 'Draft',
    label: 'Draft',
  },
  {
    value: 'Published',
    label: 'Published',
  },
  {
    value: 'Submitted',
    label: 'Submitted',
  },
  {
    value: 'Cancelled',
    label: 'Cancelled',
  },
  {
    value: 'Without CL',
    label: 'Without CL',
  },
  {
    value: 'Need to be reconciled',
    label: 'Need to be reconciled',
  },
  {
    value: 'Need to be updated',
    label: 'Need to be updated',
  },
];
const shiftOptions = ['All', 'Day', 'Night'];
const projectTypeOptions = [
  { value: 'Yes', label: 'Certified Payroll' },
  { value: 'No', label: 'Not Certified Payroll' },
];
const forceAccountOptions = [
  { value: 'All', label: 'All' },
  { value: 'Partial', label: 'Partial' },
  { value: 'Full', label: 'Full' },
  { value: 'No', label: 'No' },
];

export { statusOptions, shiftOptions, projectTypeOptions, forceAccountOptions };
