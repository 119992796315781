import React, { useCallback } from 'react';
import { withStyles, Grid, Typography } from '@material-ui/core';
import cs from 'classnames';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import Trigger from './components/Trigger';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import styles from './styles';
import { addDays, isAfter } from 'date-fns';
import { useWeekStart } from 'hooks/useWeekStart';
import { GLOBAL_DATE_FORMAT, momentAdepter } from 'common/dateFormatConfig';

const DatePicker = ({ classes, className, value, onChange }) => {
  const { weekDayFromO } = useWeekStart();
  const onChangeFromDate = useCallback((from) => {
    if (isAfter(from, value.to)) {
      return onChange({ ...value, from, to: from });
    }
    onChange({ ...value, from });
  });

  const onChangeToDate = useCallback((to) => {
    onChange({ ...value, to });
  });
  let dayPickerInputRef = null;
  let nextdayPickerInputRef = null;

  const { from, to } = value;
  const modifiers = { start: value.from, end: value.to };

  return (
    <ClickAwayListener
      onClickAway={() => {
        try {
          dayPickerInputRef.hideDayPicker();
          nextdayPickerInputRef.hideDayPicker();
        } catch {}
      }}
    >
      <Grid
        container
        spacing={0}
        className={cs(classes.root, className)}
        alignItems={'center'}
        wrap={'nowrap'}
      >
        <Grid item xs={6} className={classes.paddingRight}>
          <Typography variant="body1" color="textSecondary" className={classes.marginBottom}>
            From:
          </Typography>
          <div name="main-from">
            <DayPickerInput
              ref={(ref) => (dayPickerInputRef = ref)}
              value={from}
              placeholder="From"
              format={momentAdepter(GLOBAL_DATE_FORMAT.fullDateWithDay).momentShortDayDate} //* @ "ddd, MMMM DD, YYYY"
              formatDate={formatDate}
              parseDate={parseDate}
              dayPickerProps={{
                firstDayOfWeek: weekDayFromO,
                initialMonth: from,
                selectedDays: [from, { from, to }],
                disabledDays: { before: addDays(new Date(), 1) },
                // toMonth: to,
                modifiers,
                numberOfMonths: 1,
                onBlur: () => {
                  setTimeout(() => {
                    const elClicked = document.activeElement,
                      container = document.getElementsByName(`main-from`);
                    if (container && !container[0].contains(elClicked) && !dayPickerInputRef) {
                      dayPickerInputRef.hideDayPicker();
                    }
                  }, 1);
                },
              }}
              inputProps={{ date: from }}
              component={Trigger}
              onDayChange={onChangeFromDate}
            />
          </div>
        </Grid>
        <Grid item xs={6} className={classes.paddingLeft}>
          <Typography variant="body1" color="textSecondary" className={classes.marginBottom}>
            To:
          </Typography>
          <div name="main-to">
            <DayPickerInput
              ref={(ref) => (nextdayPickerInputRef = ref)}
              value={to}
              placeholder="To"
              format={momentAdepter(GLOBAL_DATE_FORMAT.fullDateWithDay).momentShortDayDate} //* @ "ddd, MMMM DD, YYYY"
              formatDate={formatDate}
              parseDate={parseDate}
              dayPickerProps={{
                firstDayOfWeek: weekDayFromO,
                selectedDays: [from, { from, to }],
                disabledDays: { before: from },
                month: from,
                // fromMonth: from,
                modifiers,
                numberOfMonths: 1,
                onBlur: () => {
                  setTimeout(() => {
                    const elClicked = document.activeElement,
                      container = document.getElementsByName(`main-to`);
                    if (container && !container[0].contains(elClicked) && !nextdayPickerInputRef) {
                      nextdayPickerInputRef.hideDayPicker();
                    }
                  }, 1);
                },
              }}
              inputProps={{ date: to }}
              component={Trigger}
              onDayChange={onChangeToDate}
            />
          </div>
        </Grid>
      </Grid>
    </ClickAwayListener>
  );
};

export default withStyles(styles)(DatePicker);
