import React, { useCallback, useState, useMemo, useEffect } from 'react';
import cs from 'classnames';
import { withStyles } from '@material-ui/core';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import styles from './styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ButtonBase from '@material-ui/core/ButtonBase';
import DoneIcon from '@material-ui/icons/Done';
import SettingsIcon from '@material-ui/icons/KeyboardArrowDown';
import useAutocomplete from 'components/AutocompleteMui/useAutocomplete';



const SearchMultipleSelectInput = ({
  options = [],
  loading,
  classes,
  inputId,
  onChange: onSelectChange,
  getOptionLabel = (option) => option,
  getOptionKey = (option) => option,
}) => {
  const [anchorEl, setAnchorEl] = useState(false);
  const [value, setValue] = useState([]);
  const [pendingValue, setPendingValue] = useState([]);
  
  const open = !!anchorEl;

  const onSelectClick = useCallback((e) => {
    if (!anchorEl) { // popup closed
      setAnchorEl(e.currentTarget);
      setPendingValue(value);
    } else { // popup open
      setAnchorEl(null);
      if (
        pendingValue?.length !== value?.length ||
        pendingValue?.some((option, index) => option !== value[index])
      ) {
        setValue(pendingValue)
        onSelectChange(pendingValue);
      }
    }
  }, [anchorEl, pendingValue, value])

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    if (
      pendingValue?.length !== value?.length ||
      pendingValue?.some((option, index) => option !== value[index])
    ) {
      setValue(pendingValue)
      onSelectChange(pendingValue);
    }
  }, [pendingValue])


  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    id: inputId || '',
    options: options,
    open: open,
    multiple: true,
    getOptionLabel,
    onChange: (e, v, reason) => {
      setPendingValue(v)
    },
    getOptionSelected: (option, value) => getOptionKey(option) === getOptionKey(value)
  });

  const renderOption = (option, index) => {
    const onSelect = getOptionProps({ option, index })['onClick'];
    const selected = getOptionProps({ option, index })['aria-selected'];
    return (
      <li {...getOptionProps({ option, index })} onTouchEnd={onSelect} className={classes.option}>
        <DoneIcon className={cs(classes.doneIcon, selected ? classes.optionSelected : null)} />
        <span className={classes.optionText}>{getOptionLabel(option)}</span>
      </li>
    )
  }

  const shownOptions = useMemo(() => groupedOptions.sort((a, b) => {
    // Display the selected first.
    let ai = value.indexOf(a);
    ai = ai === -1 ? value.length + options.indexOf(a) : ai;
    let bi = value.indexOf(b);
    bi = bi === -1 ? value.length + options.indexOf(b) : bi;
    return ai - bi;
  }), [value, options, groupedOptions])

  return (
    <>
      <div className={classes.root}>
      <ButtonBase
        disableRipple
        className={classes.selectButton}
        aria-describedby={inputId}
        onClick={onSelectClick}
      >
        <span style={{flexGrow: 1}}>{value.map(getOptionLabel).join(', ') || 'All'}</span>
        <SettingsIcon />
      </ButtonBase>
      {open ? (
        <ClickAwayListener onClickAway={handleClose}>
          <div className={classes.options}>
            <div className={classes.optionsContainer}>
              {loading ? (
                <div className={classes.loaderContainer}>
                  <LoadingSpinner height={32} margin={32} />
                </div>
              ): (
                <>
                  <div {...getRootProps()} className={classes.textInputWrapper}>
                    <input {...getInputProps()} className={classes.textInput} />
                  </div>
                  {shownOptions.length > 0 && (
                    <div {...getListboxProps()}>
                      {shownOptions.map(renderOption)}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </ClickAwayListener>
      ) : null}
      </div>
    </>
  );
};

export default withStyles(styles)(SearchMultipleSelectInput);
