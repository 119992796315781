import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './../../styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from 'assets/images/close-icon.svg';
import PrintIcon from 'assets/images/print-icon.svg';
import EditIcon from 'assets/images/edit-icon.svg';
import { Button, Divider } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import GreyUserI from 'assets/images/user-photo-placeholder.svg';
import classnames from 'classnames';
import EditModal from '../EditModal/EditModal';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import { openPrintPage } from 'components/ImagesForm/ImagesForm';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import { WORKLOG_PRINT_QUERY } from 'pages/WorklogPrint/components/constants';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';

const PicCard = ({ data, classes, onDelete, onEdit, sheetId }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [confirmRemove, setConfirmRemove] = useState(null);
  const { type } = useParams();
  const { dayDateSettingsFormat } = useDateSettingsFormat();

  const handleSaveEditModal = (description) => (e) => {
    onEdit(data._id, description?.trim());
    handleCloseEditModal(e);
  };
  const handleOpenEditModal = (e) => {
    e.stopPropagation();
    setEditModal(true);
  };
  const handleCloseEditModal = (e) => {
    e.stopPropagation();
    setEditModal(false);
  };

  const handleExpandClick = (e) => {
    e.stopPropagation();
    setIsExpanded((prev) => !prev);
    // setarr(prev=>[...prev, data.id])
  };

  const showConfirmRemove = (id) => setConfirmRemove(id);
  const closeConfirmRemove = () => setConfirmRemove(null);

  return (
    <div className={classes.picWrapper}>
      <div className={classes.headerBlock}>
        <div className={classes.flexHeader}>
          <div className={classnames(classes.flexBox, classes.avatarBox)}>
            <div className={classes.photoWrapper}>
              <img src={GreyUserI} className={classes.photo} />
            </div>
            <Typography variant="body1" color="textPrimary" className={classes.name}>
              {data.createdByUser}
            </Typography>
          </div>
          <div className={classes.actionsBlock}>
            <Button color="secondary" onClick={() => openPrintPage(sheetId, 'true', type, `&${WORKLOG_PRINT_QUERY.printSingleImgOnSheet}=${data._id}`)} disableRipple>
              <img src={PrintIcon} />
            </Button>
            <Button color="secondary" onClick={handleOpenEditModal} disableRipple>
              <img
                src={EditIcon}
              />
            </Button>
            <Button
              color="secondary"
              className={classes.deleteButton}
              // onClick={onDelete(data._id)}
              onClick={() => showConfirmRemove(data._id)}
              disableRipple
            >
              <img src={CloseIcon} alt="delete" />
            </Button>
          </div>
        </div>
      </div>
      <div className={classes.dateBlock}>
        {data.createdAt ? format(new Date(data.createdAt), dayDateSettingsFormat) : ''}
      </div>
      <Divider className={classes.divider} />
      <div className={classes.imgBlock}>
        <img src={`${process.env.REACT_APP_BASE_URL}/sheets/image?key=${data?.x480 || data.key}`} alt="Image" />
      </div>

      {data.description ? (
        <>
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <div className={classes.infoBlock}>Description:</div>
            <div className={classes.text}>{data.description}</div>
          </Collapse>
          <CardActions
            disableSpacing
            onClick={handleExpandClick}
            className={classes.actionsWrapper}
          >
            <IconButton
              className={classnames(classes.expand, {
                [classes.expandOpen]: isExpanded,
              })}
              aria-expanded={isExpanded}
              aria-label="Show more"
              disableRipple
              disableFocusRipple
            >
              <ExpandMoreIcon />
            </IconButton>
            <Typography color="textSecondary">
              {isExpanded ? 'Hide description' : <span>Show description</span>}
            </Typography>
            <IconButton
              className={classnames(classes.expand, {
                [classes.expandOpen]: isExpanded,
              })}
              aria-expanded={isExpanded}
              aria-label="Show more"
              disableRipple
              disableFocusRipple
            >
              <ExpandMoreIcon />
            </IconButton>
          </CardActions>
        </>
      ) : null}
      {editModal && (
        <EditModal
          isOpen={editModal}
          info={data.description}
          onClose={handleCloseEditModal}
          onSave={handleSaveEditModal}
        />
      )}
      {confirmRemove && (
        <ConfirmDialog
          isOpen
          onSubmit={()=>{
            onDelete(confirmRemove)();
            closeConfirmRemove();
          }}
          onClose={closeConfirmRemove}
          text="Are you sure you want to delete this Image from the Worklog?"
        />
      )}
    </div>
  );
};

export default withStyles(styles)(PicCard);
