const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
const isStandalone = !!(iOS && window.navigator.standalone);

export default (theme) => ({
  dialogRoot: {
    [theme.breakpoints.up('sm')]: {
      width: 420,
    },
    [theme.breakpoints.down('sm')]: {
      margin: 5,
    },
  },
  content: {
    color: theme.palette.textColor.dark,
    fontSize: 14,
    fontWeight: 500,
    padding: '5px 0',
  },
  closeButton: {
    position: 'absolute',
    top: '5px',
    right: '5px',
  },
  button: {
    color: theme.palette.primary.blue,
    border: theme.palette.primary.blue,
    background: '#fff',
    '&:hover': {
      color: '#fff',
      background: '#1976D2! important',
    },
    '&:focus': {
      color: '#fff',
      background: `${theme.palette.primary.blue} !important`,
    },
  },
  radio: {
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& label': {
      alignItems: 'center',
      justifyContent: 'center',
      '& span': {
        fontSize: 16,
        fontWeight: 400,
      },
    },
  },
  deleteButtonAlign: {
    color: theme.palette.error.red,
    '&:hover': {
      backgroundColor: 'transparent !important',
      color: `${theme.palette.error.red} !important`,
    },
    '&:focus': {
      backgroundColor: 'transparent !important',
      color: `${theme.palette.error.red} !important`,
    },
  },
  addButtonAlign: {
    color: theme.palette.textColor.primary,
    fontSize: 16,
    '&:hover': {
      backgroundColor: '#fff !important',
      color: `${theme.palette.textColor.primary} !important`,
    },
    '&:focus': {
      backgroundColor: '#fff !important',
      color: `${theme.palette.textColor.primary} !important`,
    },
  },
  legendAlign: {
    marginTop: 14,
  },
  buttonHolder: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  cancelButton: {
    margin: 10,
    minWidth: 140,
    padding: '7px 0px',
    '&:hover': {
      color: theme.palette.textColor.dark,
    },
    '&:focus': {
      color: theme.palette.textColor.dark,
      backgroundColor: '#fff',
    },
    '&:disabled': {
      backgroundColor: '#fff',
    },
  },
  saveButton: {
    margin: 10,
    minWidth: 140,
    padding: '7px 0px',
    '&:hover': {
      color: theme.palette.textColor.primary,
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
      backgroundColor: '#fff',
    },
    '&:disabled': {
      backgroundColor: '#fff',
    },
  },
});
