import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  Button,
  DialogContent,
  Typography,
  Box,
  TextField,
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { CloseButton } from '../../../TimeClock/modals/base';
import { styles } from './styles';
import { logout } from 'store/login/loginOperations';
import $api from 'http/index';
import { getOptions } from 'helpers/getOptions';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(styles);

const ExitModal = ({ onClose }) => {
  const navigate = useNavigate()
  const classes = useStyles();
  const [formShown, setFormShown] = useState(false);
  const [type, setType] = useState('text');
  const {
    control,
    clearErrors,
    formState: { errors },
    getValues,
    setError,
  } = useForm({
    defaultValues: {
      username: '',
      password: '',
    },
  });
const dispatch = useDispatch()
  const handleSubmit = () => {
    if (!formShown) return setFormShown(true);

    if (!getValues().username.length) {
      setError('username', { type: 'error', message: '' });
      setError('password', { type: 'error', message: 'Username is required' });
      return;
    }

    if (!getValues().password.length) {
      setError('password', { type: 'error', message: 'Password is required' });
      return;
    }

    const apiCall = async (values) => {
      try {
        // const options = {
        //   method: 'POST',
        //   headers: {
        //     'Content-Type': 'application/json',
        //     'X-Auth-Token': localStorage.getItem('authorization-token'),
        //   },
        //   body: JSON.stringify(values),
        // };
        await $api.post(
          `${process.env.REACT_APP_BASE_URL}/kiosk/admin-logout`,
          values,
          {
            headers: {
              ...getOptions().headers,
              'x-admin-kiosk-token': localStorage.getItem('x-admin-kiosk-token'),
            }
          },
        );
        dispatch(logout())
        navigate('/signin', {replace: true})
      } catch (e) {
        setError('password', { type: 'error', message: e?.response?.data?.message || e.message });
        setError('username', { type: 'error', message: '' });
      }
    };

    apiCall(getValues());
  };

  return (
    <Dialog
      open
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        classes: {
          root: classes.dialogRoot,
        },
      }}
    >
      <CloseButton onClick={onClose} />
      <DialogContent style={{ paddingTop: '3rem' }}>
        {!formShown && (
          <Box>
            <Typography variant="body1" style={{ fontSize: '1rem', maxWidth: 300 }}>
              Please enter Administrator credentials to exit kiosk mode.
            </Typography>
          </Box>
        )}
        {formShown && (
          <Box display="flex" flexDirection="column" style={{ width: 300 }}>
            <label htmlFor="username">
              <Typography variant="body1" color="textSecondary">
                Admin's Username:
              </Typography>
              <Controller
                control={control}
                name="username"
                render={({ onChange, value }) => (
                  <TextField
                    variant="outlined"
                    fullWidth
                    value={value}
                    onChange={(e) => {
                      clearErrors();
                      onChange(e.target.value);
                    }}
                    error={!!errors.username}
                    inputProps={{
                      'aria-label': "Admin's Username",
                      autoComplete: 'new-password',
                      'aria-autocomplete': 'none',
                    }}
                  />
                )}
              />
              {errors.username ? (
                <div className={classes.error}>{errors.username.message}</div>
              ) : null}
            </label>
            <label htmlFor="password">
              <Typography variant="body1" color="textSecondary">
                Admin's Password:
              </Typography>
              <Controller
                control={control}
                name="password"
                render={({ onChange, value }) => (
                  <TextField
                    variant="outlined"
                    fullWidth
                    value={value}
                    onChange={(e) => {
                      clearErrors();
                      setType('password');
                      onChange(e.target.value);
                    }}
                    error={!!errors.password}
                    type={type}
                    inputProps={{
                      autoComplete: 'new-password',
                      'aria-autocomplete': 'none',
                    }}
                  />
                )}
              />
              {errors.password ? (
                <div className={classes.error}>{errors.password.message}</div>
              ) : null}
            </label>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {!formShown && (
          <Button
            onClick={onClose}
            variant="outlined"
            className={classes.cancelBtn}
            disableTouchRipple
          >
            No
          </Button>
        )}
        <Button
          onClick={handleSubmit}
          variant="outlined"
          className={classes.confirmBtn}
          disableTouchRipple
        >
          {formShown ? 'Submit' : 'Yes'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExitModal;
