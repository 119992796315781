export default (theme) => ({
  square: {
    display: 'flex',
    boxSizing: 'border-box',
    flexGrow: 1,
    height: 'calc((100vh - 198px) / 5)',
    border: `0.5px solid  ${theme.palette.borderColor.lightGrey}`,
    minWidth: '105px',
    // width: 'calc(100% / 7)',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      height: 111,
      width: 'auto',
    },
  },
  clickedDay: {
    borderColor: `${theme.palette.textColor.primary}`,
    backgroundColor: '#F3F5FD',
  },
  dayCellWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: 1,
  },
  dayCellInner: {
    display: 'flex',
    flexGrow: '1',
    width: 'calc((100% - 40px) / 7)',
    [theme.breakpoints.down('md')]: {
      width: 'auto',
    },
  },
  dayCellHeder: {
    display: 'flex',
    padding: '10px 10px 5px 0',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  sheetQuantity: {
    color: '#a4a5b3',
    border: '1px solid #F9F9F9',
    height: '16px',
    display: 'flex',
    width: '28px',
    overflow: 'hidden',
    padding: '2px 9px',
    borderRadius: '20px',
    backgroundColor: '#F9F9F9',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dayNumber: {
    fontSize: 18,
    color: theme.palette.textColor.secondary,
    padding: '0 5px',
  },
  todayNumber: {
    color: theme.palette.textColor.primary,
  },
  clickedDayQuantity: {
    border: `1px solid ${theme.palette.textColor.primary}`,
    backgroundColor: '#ffff',
    color: `${theme.palette.textColor.primary}`,
  },
  dayCellBody: {
    margin: '5px',
    flexGrow: '1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  popper: {
    display: 'flex',
    maxWidth: '440px',
    width: '100%',
    boxShadow: '0px 4px 14px 0px #00000040',
    borderRadius: 10,
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      width: '96%',
    },
  },
  previewCellItemWrapper: {
    width: '100%',
    display: 'flex',
    marginBottom: '5px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  previewCellItem: {
    width: '80%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    paddingLeft: '3px',
    textOverflow: 'ellipsis',
    lineHeight: '15px',
  },
  previewCellArrow: {
    width: 15,
    height: 15,
    // cursor: 'pointer',
  },
  missingCL: {
    borderColor: `${theme.palette.borderColor.red}`,
  },
  canceled: {
    textDecoration: 'line-through',
  },
  previewItemInner: {
    display: 'flex',
    width: '88%',
  },
  previewCellImgWrapper: {
    width: 16,
    height: 16,
    borderRadius: '3px',
    textAlign: 'center',
    lineHeight: '14px',
  },
  activeIconWrapper: {
    backgroundColor: '#FFF',
  },
  notActiveIconWrapper: {
    backgroundColor: '#F3F5FD',
  },
});
