import React, { useState } from 'react';
import { CloseButton, Dialog } from '../base';
import { DialogTitle } from '../base/styled';
import CipForm from './components/CipForm';
import useStyles from './useStyles';

const AddCipHoursModal = ({ onClose, open, onChange, data }) => {
  const classes = useStyles();
  const [touched, setTouched] = useState(false);
  return (
    <Dialog open={open}>
      <CloseButton onClick={() => onClose()} />
      <DialogTitle className={classes.title} disableTypography={true}>
        Add CIP hours
      </DialogTitle>
      <CipForm onChange={onChange} onClose={onClose} data={data} />
    </Dialog>
  );
};

export default AddCipHoursModal;
