import { useEffect, useState } from 'react';
import { endOfDay, startOfWeek, format } from 'date-fns';

import { getOptions } from 'helpers/getOptions';
import { useWeekStart } from 'hooks/useWeekStart';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';
import { globalBEDateFormat } from 'common/dateFormatConfig';
import { parseStringDate } from 'helpers/_date-helpers';

export default (date, timeclocks, loading) => {
  const [totalWeekTime, setTotalWeekTime] = useState({});
  const { weekDayFromO } = useWeekStart();

  useEffect(() => {
    let mounted = true;
    getWeekHours({ date: parseStringDate(date), weekDayFromO }).then(
      (res) => mounted && setTotalWeekTime(res)
    );
    return () => (mounted = false);
  }, [date, weekDayFromO]);
  // }, [date, loading, timeclocks]);
  return totalWeekTime;
};

export const getWeekHours = async ({ date, weekDayFromO }) => {
  const endDate = format(endOfDay(date), globalBEDateFormat);
  const weekStart = format(startOfWeek(date, { weekStartsOn: weekDayFromO }), globalBEDateFormat);

  const searchParams = new URLSearchParams();
  searchParams.append('startDate', weekStart);
  searchParams.append('endDate', endDate);
  const options = {
    ...getOptions(),
    method: 'GET',
  };

  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/timeclock/total-week-hours?${searchParams}`,
    options
  ).then((res) => res.json());
  if (response.statusCode > 299) return {};
  return response;
};
