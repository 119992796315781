export default (theme) => ({
  tabs: {
    borderBottom: '1px solid #e8e8e8',
    paddingTop: 10,
    marginBottom: 0,
    color: theme.palette.textColor.grey,
    '& button': {
      minWidth: 110,
    },
    '& button:hover': {
      backgroundColor: 'transparent !important',
    },
    '& button.Mui-selected': {
      color: theme.palette.primary.blue,
    },
    '& span': {
      fontSize: '0.875rem',
      fontWeight: 500,
    },
  },
  loader: {
    fontSize: 14,
    paddingBottom: 10,
    '&:nth-of-type(1)': {
      display: 'none',
    },
  },
  hidden: {
    display: 'none',
  },
  show: {
    display: 'block',
  },
});
