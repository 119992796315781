import { createTheme } from '@material-ui/core/styles';

import overrides from './overrides';
import palette from './palette';
import typography from './typography';

const breakpointValues = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1280,
};

export default createTheme({
  overrides,
  palette,
  typography,
  breakpoints: { values: breakpointValues },
});
