import React, { useState, useCallback, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import AddIcon from '@material-ui/icons/Add';
import Tabs from 'components/Tabs';
import Tab from 'components/Tab';
import Header from './components/Header';
import Inventory from './components/Inventory';
import Manufacturers from './components/Manufacturers';
import MaterialsFormPanel from './components/Inventory/FormPanel';
import ManufacturerFormPanel from './components/Manufacturers/FormPanel';
import GlassBeadFormPanel from './components/GlassBeads/FormPanel/FormPanel';
import Materials from './components/Materials';
import styles from './styles';
import SnackBar from 'components/SnackBar';
import MaterialForm from './components/Materials/components/MaterialForm';
import GlassBeads from './components/GlassBeads/index';
import { fetchPersonalProfile } from 'store/personalProfile/personalProfileOperations';
import { useDispatch } from 'react-redux';
import Surfaces from './components/Surfaces/index';
import SurfacesFormPanel from './components/Surfaces/FormPanel/FormPanel';

const TABS = [
  {
    index: 0,
    name: 'materials',
    label: 'Materials',
    href: '/supplies/materials',
  },
  {
    index: 1,
    name: 'manufacturers',
    label: 'Manufacturers',
    href: '/supplies/manufacturers',
  },
  {
    index: 2,
    name: 'inventory',
    label: 'Inventory',
    href: '/supplies/inventory',
  },
  {
    index: 3,
    name: 'glass-beads',
    label: 'Reflective Media',
    href: '/supplies/reflective-media',
  },
  {
    index: 4,
    name: 'surfaces',
    label: 'Surfaces',
    href: '/supplies/surfaces',
  },
];

const SuppliesPage = ({ classes, type }) => {
  const dispatch = useDispatch();
  const defaultTab = type ? TABS.find((t) => t.name === type) : TABS[0];
  const [activeTab, setActiveTab] = useState(defaultTab);
  const [snackbar, setSnackbar] = useState(null);
  const [drawer, setDrawer] = useState(null);

  useEffect(() => {
    dispatch(fetchPersonalProfile());
  }, []);

  const openDrawer = useCallback((type, subType) => {
    setDrawer({ isOpen: true, type, subType });
  }, []);

  const closeDrawer = useCallback(() => {
    setDrawer({ ...drawer, isOpen: false });
  }, [drawer]);

  const onSelectManufacturer = useCallback((manufacturer) => {
    setDrawer({
      isOpen: true,
      type: 'manufacturer',
      data: manufacturer,
      subType: 'edit',
    });
  }, []);

  const onSelectMaterial = useCallback((material) => {
    setDrawer({ isOpen: true, type: 'material', data: material, subType: 'edit' });
  }, []);

  const onSelectInventory = useCallback((inventory) => {
    setDrawer({ isOpen: true, type: 'inventory', data: inventory, subType: 'edit' });
  }, []);

  const onSelectGlassBead = useCallback((glassBead) => {
    setDrawer({ isOpen: true, type: 'glassBead', data: glassBead, subType: 'edit' });
  }, []);

  const onSelectSurface = useCallback((surface) => {
    setDrawer({ isOpen: true, type: 'surfaces', data: surface, subType: 'edit' });
  }, []);

  const onSaveMaterial = useCallback(() => {
    setSnackbar({
      text: 'Successfully Added!',
      type: 'success',
    });
    setDrawer({ isOpen: false });
  }, []);

  const onSaveInventory = useCallback(() => {
    setSnackbar({
      text: 'Successfully Added!',
      type: 'success',
    });
    setDrawer({ isOpen: false });
  }, []);

  const onSaveManufacturer = useCallback(() => {
    setSnackbar({
      text: 'Successfully Added!',
      type: 'success',
    });
    setDrawer({ isOpen: false });
  }, []);

  const snackbarInfo = (info) => {
    setSnackbar(info);
    if (info?.type === 'success') {
      setDrawer({ isOpen: false });
    }
  };

  return (
    <>
      <div className={classes.root}>
        <Header>
          {activeTab.index === 0 && (
            <Button
              variant="text"
              color="secondary"
              disableTouchRipple
              className={classes.addButton}
              onClick={() => openDrawer('material', 'create')}
            >
              <AddIcon fontSize="medium" color="primary" />
              <span>Add Material</span>
            </Button>
          )}
          {activeTab.index === 1 && (
            <Button
              variant="text"
              color="secondary"
              disableTouchRipple
              className={classes.addButton}
              onClick={() => openDrawer('manufacturer', 'create')}
            >
              <AddIcon fontSize="medium" color="primary" />
              <span>Add Manufacturer</span>
            </Button>
          )}
          {activeTab.index === 2 && (
            <Button
              variant="text"
              color="secondary"
              disableTouchRipple
              className={classes.addButton}
              onClick={() => openDrawer('inventory', 'create')}
            >
              <AddIcon fontSize="medium" color="primary" />
              <span>Add Inventory</span>
            </Button>
          )}
          {activeTab.index === 3 && (
            <Button
              variant="text"
              color="secondary"
              disableTouchRipple
              className={classes.addButton}
              onClick={() => openDrawer('glassBead', 'create')}
            >
              <AddIcon fontSize="medium" color="primary" />
              <span>Add Reflective Media</span>
            </Button>
          )}
          {activeTab.index === 4 && (
            <Button
              variant="text"
              color="secondary"
              disableTouchRipple
              className={classes.addButton}
              onClick={() => openDrawer('surfaces', 'create')}
            >
              <AddIcon fontSize="medium" color="primary" />
              <span>Add Surface</span>
            </Button>
          )}
        </Header>
        <Tabs
          value={activeTab.index}
          onChange={(event, newValue) => setActiveTab(TABS.find((t) => t.index === newValue))}
          className={classes.tabs}
          variant="scrollable"
          scrollButtons="auto"
        >
          {TABS.map((tab) => (
            <Tab
              key={tab.index}
              label={tab.label}
              href={tab.href}
              disableRipple
              component="a"
              onClick={(event) => event.preventDefault()}
            />
          ))}
        </Tabs>
        {activeTab.index === 0 && (
          <Materials onSelect={onSelectMaterial} setSnackbar={setSnackbar} />
        )}
        {activeTab.index === 1 && (
          <Manufacturers onSelect={onSelectManufacturer} setSnackbar={setSnackbar} />
        )}
        {activeTab.index === 2 && (
          <Inventory onSelect={onSelectInventory} setSnackbar={setSnackbar} />
        )}
        {activeTab.index === 3 && (
          <GlassBeads onSelect={onSelectGlassBead} setSnackbar={setSnackbar} />
        )}
        {activeTab.index === 4 && <Surfaces onSelect={onSelectSurface} setSnackbar={setSnackbar} />}
      </div>
      {drawer && (
        <Drawer anchor="right" open={drawer.isOpen}>
          {drawer.type === 'material' && (
            <MaterialForm
              closeDrawer={closeDrawer}
              data={drawer.data}
              onSave={onSaveMaterial}
              snackbarInfo={snackbarInfo}
              isLoading={false}
              type={drawer.subType}
            />
          )}
          {drawer.type === 'inventory' && (
            <MaterialsFormPanel
              closeDrawer={closeDrawer}
              data={drawer.data}
              onSave={onSaveInventory}
              isLoading={false}
              type={drawer.subType}
              setSnackbar={setSnackbar}
            />
          )}
          {drawer.type === 'manufacturer' && (
            <ManufacturerFormPanel
              closeDrawer={closeDrawer}
              data={drawer.data}
              onSave={onSaveManufacturer}
              isLoading={false}
              type={drawer.subType}
            />
          )}
          {drawer.type === 'glassBead' && (
            <GlassBeadFormPanel
              closeDrawer={closeDrawer}
              data={drawer.data}
              onSave={onSaveManufacturer}
              isLoading={false}
              type={drawer.subType}
            />
          )}
          {drawer.type === 'surfaces' && (
            <SurfacesFormPanel
              closeDrawer={closeDrawer}
              data={drawer.data}
              onSave={onSaveMaterial}
              type={drawer.subType}
            />
          )}
        </Drawer>
      )}
      {snackbar && <SnackBar closeSnackbar={() => setSnackbar(null)} {...snackbar} isOpen={true} />}
    </>
  );
};

export default withStyles(styles)(SuppliesPage);
