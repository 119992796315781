import { createAction } from '@reduxjs/toolkit';

const setForceUpdate = createAction('worklogs/setForceUpdate');
const setDeletedActiveSheet = createAction('worklogs/setDeletedActiveSheet');
const setDeletedCrewSheet = createAction('worklogs/setDeletedCrewSheet');
const setDeletedSubmittedSheet = createAction('worklogs/setDeletedSubmittedSheet');
const filterDeletedSubmittedSheet = createAction('worklogs/filterDeletedSubmittedSheet');
const updateActiveWorklogs = createAction('worklogs/updateActiveWorklogs');
const setWorklogLoading = createAction('worklogs/setWorklogLoading');
const setForemanDataWorklogs = createAction('worklogs/setForemanDataWorklogs');
const setActiveWorklogs = createAction('worklogs/setActiveWorklogs');
const setSubmittedWorklogs = createAction('worklogs/setSubmittedWorklogs');
const changeArchivedActiveState = createAction('worklogs/changeArchivedActiveState');
const changeArchivedCrewState = createAction('worklogs/changeArchivedCrewState');
const setError = createAction('worklogs/setError');
const setRefreshActiveSheet = createAction('worklogs/setRefreshActiveSheet');
const setRefreshCrewSheet = createAction('worklogs/setRefreshCrewSheet');
const setRefreshSubmittedSheet = createAction('worklogs/setRefreshSubmittedSheet');
const resetWorklogs = createAction('worklogs/resetWorklogs');
const setCertGenerates = createAction('worklogs/submissions/setCertGenerates');

export default {
  setForceUpdate,
  setWorklogLoading,
  setActiveWorklogs,
  setSubmittedWorklogs,
  setForemanDataWorklogs,
  setDeletedActiveSheet,
  setDeletedCrewSheet,
  setDeletedSubmittedSheet,
  filterDeletedSubmittedSheet,
  updateActiveWorklogs,
  changeArchivedActiveState,
  changeArchivedCrewState,
  setError,
  setRefreshActiveSheet,
  setRefreshCrewSheet,
  setRefreshSubmittedSheet,
  resetWorklogs,
  setCertGenerates,
};
