import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const useRedirectChecker = () => {
  const navigate = useNavigate();
  const authorized = useSelector((store) => store.login.authorized);
  const isKioskModeActive = !!+localStorage.getItem('isKioskPage');

  const redirectChecker = () => {
    if (isKioskModeActive) {
      navigate('/not-found', { replace: true });
      return;
    }

    if (authorized) {
      navigate('/home', { replace: true });
    } else {
      localStorage.setItem('isKioskPage', '0');
    }
  };

  return { redirectChecker };
};
