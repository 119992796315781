import React from 'react';
import { TableHead, TableRow, TableCell, TableSortLabel, Checkbox } from '@material-ui/core';


const CustomTableHead = ({
  headCells,
  checkboxes,
  onSort,
  sortBy,
  sortOrder,
  isAllSelected,
  selected,
  onSelectAllClick,
}) => {

  const handleSort = (property) => () => {
    onSort(property);
  };

  const renderCell = ({ label, sortable, id }) => {
    if (!label) return '';
    if (!sortable) return <span>{label}</span>;
    return (
      <TableSortLabel
        active={sortBy === id}
        direction={sortBy === id ? sortOrder : 'asc'}
        onClick={handleSort(id)}
      >
        {label}
        {sortBy === id && (
          <span style={{ display: 'none' }}>
            {sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
          </span>
        )}
      </TableSortLabel>
    )
  }

  return (
    <TableHead className={'admin-panel-table-head'}>
      <TableRow>
        {checkboxes && <TableCell padding="checkbox">
          <Checkbox
            indeterminate={!isAllSelected && !!selected.length}
            checked={isAllSelected}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>}
        {headCells.map((cell) => (
          <TableCell
            key={cell.id}
            scope="col"
            className="head-cell"
            align={cell.align || 'center'}
            sortDirection={sortBy === cell.id ? sortOrder : false}
          >
            {renderCell(cell)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default CustomTableHead;