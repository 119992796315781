import { HowToVoteRounded } from '@material-ui/icons';

export default (theme) => ({
  root: {
    width: 300,
    border: `1px solid ${theme.palette.borderColor.lightGrey}`,
    position: 'absolute',
    transition: 'transform 0.2s ease',
  },
  mobileAdminRoot: {
    width: '100%',
  },
  rootHidden: {
    transform: 'translateX(-98%)',
  },
  searchWrapper: {
    height: 50,
    padding: '10px 20px 10px 10px',
    position: 'relative',
  },
  search: {
    display: 'block',
    margin: 0,
    '& > div': {
      border: 'none',
    },
    '& input': {
      padding: 0,
      height: 30,
    },
  },
  searchIcon: {
    color: theme.palette.textColor.secondary,
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  toogleButton: {
    position: 'absolute',
    borderRadius: 50,
    width: 25,
    height: 25,
    border: '1px solid #A4A5B3',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
    transform: 'translate(50%, -50%)',
    right: 0,
    top: '50%',
    background: 'white',
    cursor: 'pointer',
    '&:hover': {
      background: '#F6F6F7',
    },
    '&:focus': {
      boxShadow:
        '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
      background: '#F6F6F7',
    },
  },
});
