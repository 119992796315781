import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import Input from './../Input/Input';
import './../../styles.scss';
import axios from 'axios';
import PageLoader from 'components/PageLoader/PageLoader';
import { formatNumber, unFormatNumber } from './helpers';

const phoneValidation = (value) => {
  let error = '';
  const requiredFieldError = "This field can't be blank";
  const numberError = 'Phone number must be 10 digits';

  if (value.trim().length < 10) {
    error = numberError;
  }
  if (!value.trim()) {
    error = requiredFieldError;
  }

  return error;
};

const ResetViaPhone = ({ onToggleRestoreMethod, selectedType, setSnackbar }) => {
  const navigate = useNavigate();

  const handleOnChange = (event) => {
    if (event.length > 14) {
      return;
    }
    const rawNumber = unFormatNumber(event);
    setValue(rawNumber);
  };

  const sendPhoneCode = async () => {
    const rule = phoneValidation(value);
    if (!rule) {
      setisLoading(true);
      try {
        const data = {
          phoneNumber: value,
        };
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/reset-password/phone`,
          data
        );
        localStorage.setItem('resetPasswordToken', response.data.token);
        setIsCodeSent(true);
      } catch (error) {
        setSnackbar({
          text: error.response.data.message,
          type: 'error',
        });
      }
      setisLoading(false);
    } else {
      setError(rule);
    }
  };

  const codeCheck = async () => {
    const options = {
      headers: {
        'X-Reset-Phone-Code-Token': localStorage.getItem('resetPasswordToken'),
      },
    };
    try {
      const data = {
        code: codeValue,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/reset-password/phone/code`,
        data,
        options
      );
      localStorage.setItem('resetPasswordToken', response.data.token);
      navigate('/reset-password', {replace: true})
    } catch (error) {
      setSnackbar({
        text: error.response.data.message,
        type: 'error',
      });
    }
  };

  const [value, setValue] = useState('');
  const [codeValue, setCodeValue] = useState('');
  const [error, setError] = useState('');
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isLoading, setisLoading] = useState(false);

  return (
    <>
      {!isCodeSent && (
        <PageLoader loading={isLoading}>
          <div className={'forgot-pass-form'}>
            <p className={'forgot-form-title'}>Forgot password</p>
            <p className={'forgot-form-info'}>
              Enter your phone number and we’ll send you a code to reset your password
            </p>
            <Input
              label={'Phone number'}
              value={formatNumber(value)}
              setValue={handleOnChange}
              error={error}
              setError={setError}
              phoneNum={true}
            />
            <button className={'forgot-btn'} onClick={sendPhoneCode}>
              Send code
            </button>
            <button className={'change-type-link'} onClick={onToggleRestoreMethod}>
              <span className={'change-type-label'}>Or</span>
              {selectedType === 'phone'
                ? 'Send link for reset password on email'
                : 'Send code via phone number'}
            </button>
          </div>
        </PageLoader>
      )}
      {isCodeSent && (
        <div className={'forgot-pass-form'}>
          <p className={'forgot-form-title'}>Enter your code</p>

          <Input
            label={''}
            value={codeValue}
            setValue={setCodeValue}
            error={error}
            setError={setError}
          />
          <button className={'forgot-btn'} onClick={codeCheck}>
            Next
          </button>
          <button className={'change-type-link'} onClick={sendPhoneCode}>
            <span className={'change-type-label'}>Didn’t recieve the code?</span>
            Resend
          </button>
        </div>
      )}
    </>
  );
};
export default ResetViaPhone;
