import React, { useEffect, useState, useRef, useMemo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useSelector } from 'react-redux';
import CloseIcon from 'assets/images/close-icon.svg'
import styles from './styles';
import { MEASUREMENT_UNITS, MEASURE_UNITS_BY_SYSTEM } from 'common/measurements';

const MaterialCard = ({ classes, onDelete = () => null, data, onDuplicate, ...props }) => {
  const materialCard = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isShowBtn, setIsShowBtn] = useState(false);

  const dataMaterials = useSelector((state) => state.supplies?.materials?.data);
  const settings = useSelector(state => state.personalProfile?.organization?.settings);
  useEffect(() => {
    materialCard.current.clientHeight > 155 ? setIsShowBtn(true) : setIsShowBtn(null);
  }, [materialCard, dataMaterials]);

  const unitOfMeasurement = useMemo(() =>
    MEASURE_UNITS_BY_SYSTEM[data?.measurement || settings.measurement][data?.type === 'volume' ? MEASUREMENT_UNITS.lengthPerVolume : MEASUREMENT_UNITS.lengthPerWeight],
    [data, settings.measurement]
  )
  const unitOfWidth = useMemo(() =>
    MEASURE_UNITS_BY_SYSTEM[data?.measurement || settings.measurement][MEASUREMENT_UNITS.width],
    [data, settings.measurement]
  )

  return (
    <div className={classes.root} {...props}>
      <div className={classes.header}>
        <span className={classes.title}>{data && data.name}</span>
        <Button
          color="secondary"
          className={classes.deleteButton}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onDelete(data);
          }}
          disableRipple
        >
          <img src={CloseIcon} alt="delete" />
        </Button>
        <hr className={classes.divider} />
      </div>
      <div
        className={classes.body}
        style={isOpen ? { maxHeight: 'none' } : { maxHeight: '155px', overflow: 'hidden' }}
      >
        <dl className={classes.definitionList} ref={materialCard}>
          <dt className={classes.definitionTitle}>Unit of Measurement:</dt>
          <dd className={classes.definitionDefinition}>
            {data?.type === 'each'
              ? 'Each'
              : `${`${data?.type}`.split('').map((c, i) => i ? c : c.toLocaleUpperCase()).join('')} (${unitOfMeasurement})`
            }
          </dd>
          {Boolean(data?.lines?.length) && (
            <>
              <dt className={classes.definitionTitle}>Line:</dt>
              <dl style={{ display: 'flex', flexWrap: 'wrap' }}>
                {data?.lines?.map((elem) => (
                    <dd
                      className={classes.definitionDefinition}
                      key={elem._id}
                      style={{ marginRight: '6px' }}
                    >
                      {`${elem.width}${unitOfWidth.replace(/in/, '"')} - ${elem.ratio} ${unitOfMeasurement}`}
                      &#32;
                    </dd>
                  ))}
              </dl>
            </>
          )}
          {Boolean(data?.legends?.length) && (
            <>
              <dt className={classes.definitionTitle}>Legend Specifications:</dt>
              <dl style={{ display: 'flex', flexWrap: 'wrap' }}>
                {data?.legends?.map((elem) => (
                    <dd
                      className={classes.definitionDefinition}
                      key={elem._id}
                      style={{ marginRight: '6px' }}
                    >
                      {`${elem.symbol};`}
                    </dd>
                  ))}
              </dl>
            </>
          )}
          {Boolean(data?.letterings?.length) && (
            <>
              <dt className={classes.definitionTitle}>Lettering symbols:</dt>
              <dl style={{ display: 'flex', flexWrap: 'wrap' }}>
                {data?.letterings?.map((elem) => (
                    <dd
                      className={classes.definitionDefinition}
                      key={elem._id}
                      style={{ marginRight: '6px' }}
                    >
                      {`${elem.name};`}
                    </dd>
                  ))}
              </dl>
            </>
          )}
          {Boolean(data?.glassBeads?.length) && (
            <>
              <dt className={classes.definitionTitle}>Link of Reflective Media:</dt>
              <dl style={{ display: 'flex', flexWrap: 'wrap' }}>
                {data?.glassBeads?.map((elem) => (
                    <dd
                      className={classes.definitionDefinition}
                      key={elem._id}
                      style={{ marginRight: '6px' }}
                    >{`${elem.ratio}' ${MEASURE_UNITS_BY_SYSTEM[data.measurement || settings.measurement][elem.unitType]}`}</dd>
                  ))}
              </dl>
            </>
          )}
        </dl>
      </div>
      <hr className={classes.divider} />
      <div
        className={classes.buttonsWrapper}
        style={isShowBtn ? { justifyContent: 'space-between' } : { justifyContent: 'flex-end' }}
      >
        {isShowBtn && (
          <div
            className={classes.actionsWrapper}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setIsOpen((prev) => !prev);
            }}
          >
            <p className={classes.textIconMore}>{isOpen ? 'Show less' : 'Show more'}</p>
            <div className={isOpen ? classes.nonActionsIcon : classes.actionsIcon}>
              <ExpandMoreIcon />
            </div>
          </div>
        )}

        <button
          className={classes.dublicateButton}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onDuplicate(data);
          }}
        >
          <span className={classes.dublicateButtonTitle}>Duplicate material</span>
        </button>
      </div>
    </div>
  );
};

export default withStyles(styles)(MaterialCard);
