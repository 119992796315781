import { Typography, withStyles } from '@material-ui/core';

export const Title = withStyles({
  body1: {
    color: '#78797E',
    fontSize: 16,
    letterSpacing: 0.2,
    lineHeight: '25px',
    marginBottom: 24,
  },
})(Typography);

export const TotalTime = withStyles({
  body1: {
    color: '#202020',
    fontSize: '20px',
    fontWeight: '500',
    letterSpacing: '0.25px',
    lineHeight: '30px',
    display: 'inline-block',
    textAlign: 'right',
    '@media (max-width: 600px)': {
      fontSize: '22px',
    },
  },
  colorError: {
    color: '#DD6060',
  },
})(Typography);
