export default theme => ({
  error: {
    color: theme.palette.textColor.red,
    padding: '0 5px',
    fontSize: 11
  },
  zeroPaddingTop: {
    paddingTop: '0px !important'
  },
  flexBox: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  closeButton: {
    padding: 4,
    color: theme.palette.textColor.secondary,
    '&:hover': {
      color: theme.palette.textColor.secondary,
      background: `${theme.palette.background.default} !important`
    },
    '&:focus': {
      color: theme.palette.textColor.secondary,
      background: `${theme.palette.background.default} !important`
    }
  },
  signImg: {
    display: 'block',
    height: 175
  },
  addButton: {
    padding: 0,
    color: theme.palette.textColor.primary,
    '&:hover': {
      color: theme.palette.textColor.primary,
      background: `${theme.palette.background.default} !important`
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
      background: `${theme.palette.background.default} !important`
    }
  },
  signIcon: {
    marginRight: 10
  }
});
