import palette from '../../../../../../theme/palette';

export default (theme) => ({
  rootEvent: {
    // height: '100%',
    boxSizing: 'border-box',
    borderRadius: 4,
    border: `1px solid ${theme.palette.borderColor.lightGrey}`,
  },
  needReconcile: {
    borderColor: theme.palette.borderColor.red,
  },
  missingCL: {
    borderColor: theme.palette.borderColor.red,
    boxShadow: `0px 0px 5px 2px ${theme.palette.borderColor.red}`,
    '-webkit-box-shadow': `0px 0px 5px 2px ${theme.palette.borderColor.red}`,
    '-moz-box-shadow': `0px 0px 5px 2px ${theme.palette.borderColor.red}`,
  },
  originalTime: {
    width: 4,
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: theme.palette.background.indicatorColor,
    borderRadius: '4px 0 0 0',
  },
  paddingBox: {
    padding: 4,
  },
  marginBottomSmall: {
    marginBottom: 4,
  },
  marginBottom: {
    marginBottom: 8,
  },
  marginBottomBig: {
    marginBottom: 10,
  },
  notesText: {
    wordBreak: 'break-word',
  },
  header: {
    fontSize: '0.875rem',
    maxWidth: 'calc(100% - 20px)',
  },
  contractor: {
    marginBottom: 0,
    wordBreak: 'break-word',
    fontSize: '0.7rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: 134,
  },
  marginRightSmall: {
    marginRight: 4,
    wordBreak: 'break-word',
  },
  projectId: {
    color: theme.palette.textColor.secondary,
    fontSize: '0.8rem',
    fontWeight: 400,
  },
  deleteButton: {
    minWidth: 24,
    height: '24px !important',
    padding: '5px !important',
    position: 'absolute',
    top: 5,
    right: 5,
    '&:hover': {
      background: 'transparent',
    },
    '&:focus': {
      background: 'transparent',
    },
  },
  expandButton: {
    minWidth: 24,
    height: '24px !important',
    padding: '5px 0 5px 5px !important',
    position: 'absolute',
    top: 5,
    right: 5,
    '&:hover': {
      background: 'transparent',
      color: palette.primary.blue,
    },
    '&:focus': {
      background: 'transparent',
      color: palette.secondary.main,
    },
  },
  wordBreak: {
    wordBreak: 'break-word',
  },
  time: {
    fontSize: '0.875rem',
    fontWeight: 400,
  },
  flexBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  secondaryText: {
    color: theme.palette.textColor.secondary,
    fontSize: '0.8rem',
    fontWeight: 400,
  },
  photoWrapper: {
    borderRadius: '50%',
    border: `1px solid ${theme.palette.textColor.blue}`,
    borderTopColor: 'transparent',
    borderBottomColor: 'transparent',
    width: 20,
    height: 20,
    transform: 'rotate(135deg)',
    minWidth: 20,
    boxSizing: 'border-box',
  },
  photo: {
    display: 'block',
    transform: 'rotate(-135deg)',
    height: 18,
    maxWidth: 18,
    borderRadius: '50%',
    margin: '0 auto',
  },
  justifyContentStart: {
    justifyContent: 'flex-start',
  },
  username: {
    marginLeft: 8,
    paddingRight: 5,
    wordBreak: 'break-word',
    fontSize: 14,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  day: {
    color: theme.palette.textColor.yellow,
  },
  night: {
    color: theme.palette.textColor.darkBlue,
  },
  inactive: {
    color: theme.palette.textColor.secondary,
  },
  dayBorder: {
    border: `1px solid ${theme.palette.textColor.yellow}`,
  },
  nightBorder: {
    border: `1px solid ${theme.palette.textColor.darkBlue}`,
  },
  inactiveBorder: {
    border: `1px solid ${theme.palette.textColor.secondary}`,
  },
  equipmentBlock: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '17px',
    width: 'max-content',
    maxWidth: '100%',
    height: 20,
  },
  equipmentNumber: {
    boxSizing: 'border-box',
    fontSize: 14,
    fontWeight: 500,
    fontFamily: 'Poppins',
    color: theme.palette.textColor.white,
    padding: '3px 6px',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 180,
  },
  dayShift: {
    borderRadius: '4px 4px 0 0',
    backgroundColor: theme.palette.background.dayShift,
  },
  gridDayShift: {
    borderRadius: '4px 4px 0 0',
    backgroundColor: theme.palette.background.dayShift,
    minHeight: 135,
  },
  nightShift: {
    borderRadius: '4px 4px 0 0',
    backgroundColor: theme.palette.background.nightShift,
  },
  fullHeight: {
    height: '100%',
    boxSizing: 'border-box',
    borderRadius: 4,
  },
  canceledWrapper: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 10,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '&:before': {
      content: '""',
      backgroundImage:
        'linear-gradient(135deg, #f8dfdf 8.33%, #fff1f1 8.33%, #fff1f1 50%, #f8dfdf 50%, #f8dfdf 58.33%, #fff1f1 58.33%, #fff1f1 100%)',
      backgroundSize: '16.97px 16.97px',
      opacity: 0.85,
      height: '100%',
      width: '100%',
      position: 'absolute',
      zIndex: 5,
    },
  },
  canceledUp: {
    // top: -40,
    // height: 'calc(100% + 40px)',
    // '& $cancelHeader': {
    //   marginBottom: 8,
    //   '& img': {
    //     marginBottom: 0,
    //   },
    // },
  },
  cancelContent: {
    position: 'absolute',
    zIndex: 30,
    padding: '0 16px',
  },
  cancelHeader: {
    color: theme.palette.textColor.red,
    fontSize: '0.875rem',
    textAlign: 'center',
    marginBottom: 20,
    '& img': {
      display: 'block',
      margin: '0 auto 8px',
    },
  },
  cancelNote: {
    color: theme.palette.textColor.dark,
    textAlign: 'center',
    wordBreak: 'break-word',
  },
  itemWrapper: {
    border: `1px solid ${theme.palette.borderColor.white}`,
    width: 'fit-content',
    maxWidth: '100%',
  },
  otherDayBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 0',
    fontWeight: 500,
    fontSize: '0.875rem',
    color: theme.palette.textColor.primary,
    '& span': {
      margin: '0 12px',
    },
  },
  blueIcon: {
    color: theme.palette.textColor.primary,
  },
  ellipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: 182,
  },
  fixTop: {
    top: 42,
  },
  fixedHeight: {
    height: 270,
    overflow: 'hidden',
    background: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
  },
  relativePosition: {
    position: 'relative',
  },
  contentHeight: {
    height: 'auto',
    minHeight: 270,
    position: 'absolute',
    zIndex: 100,
    top: 1,
    left: 1,
    width: 'calc(100% - 2px)',
  },
  hiddenResources: {
    // maxHeight: 95,
    overflow: 'hidden',
  },
  toggleBtn: {
    padding: '0 8px',
    color: theme.palette.textColor.primary,
    '&:hover': {
      color: theme.palette.textColor.primary,
      background: theme.palette.background.default,
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
      background: theme.palette.background.default,
    },
  },
  overflowHidden: {
    overflow: 'hidden',
  },
  bottomPosition: {
    position: 'absolute',
    bottom: 0,
    left: 32,
  },
  redText: {
    color: theme.palette.textColor.red,
    textAlign: 'center',
    marginTop: '-3px',
    fontSize: '0.875rem',
    paddingBottom: 3,
  },
  eventText: {
    fontSize: '0.875rem',
    marginLeft: 6,
    fontWeight: '500',
  },
  column: {
    display: 'inline-block',
    verticalAlign: 'top',
    width: 'calc(50% - 5px)',
  },
  background: {
    backgroundColor: theme.palette.background.default,
  },
  container: {
    position: 'relative',
  },
  canceledGridContainer: {
    minHeight: '140px',
    flex: 1,
  },
  eventStatusWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '6px',
  },
  accordionItemImgWrapper: {
    width: '16px',
    border: '1px solid',
    height: '16px',
    textAlign: 'center',
    flexShrink: '0',
    display: 'flex',
    borderRadius: '4px',
    alignItems: 'center',
    justifyContent: 'center'
  },
  submittedImgWrapper: {
    borderColor: '#43B038',
    color: '#43B038',
  },
  publishedImgWrapper: {
    borderColor: '#3E82FF',
    color: '#3E82FF',
  },
  unpublishedImgWrapper: {
    borderColor: '#D14646',
    color: '#D14646',
  },
});
