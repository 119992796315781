import React, { useCallback, useState, useEffect } from 'react';
import IOSSwitch from 'components/IOSSwitch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import UserTypeInfo from './components/UserTypeInfo';
import { isIOS, Option } from 'components/Option';
import { permLabels, userTypes, extPermissions, changePermission } from 'common/permissions';

import styles from './styles';


const UserPermissions = ({ classes, values, editingUser, setFieldValue }) => {
  const [permissions, setPermissions] = useState(values.profile?.role?.extPermissions);
  const [roleName, setRoleName] = useState(values.profile?.role?.roleName);

  const handleChangeRole = (e) => {
    const role = e.target.value;
    setRoleName(role)

    let newPermissions = extPermissions[role].reduce((obj, permission) => ({ ...obj, [permission]: false }), {})
    if (role === editingUser?.profile?.role?.roleName && editingUser?.profile?.role?.extPermissions) {
      newPermissions = editingUser.profile.role.extPermissions;
    }
    setPermissions(newPermissions);

    setFieldValue('profile.role.extPermissions', newPermissions);
    setFieldValue('profile.role.roleName', role);
  }

  useEffect(() => {
    const role = values?.profile?.role?.roleName
    if (!extPermissions[role]) {
      return setPermissions({});
    }
    
    const permissionsValue = values?.profile?.role?.extPermissions
    const userPermissions = extPermissions[role];
    const nextPermissions = userPermissions.reduce((res, permission) => ({ ...res, [permission]: !!permissionsValue[permission] }), {});
    
    setRoleName(role);
    setPermissions(nextPermissions)
  }, []);

  const handleChangePermission = useCallback((permission) => (e) => {    
    const newPermissions = changePermission(roleName, permissions, permission)

    setPermissions({ ...newPermissions });

    setFieldValue(`profile.role.extPermissions`, { ...newPermissions });
  }, [setFieldValue, permissions, roleName]);


  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <label htmlFor="profile.role.roleName">
            <Typography variant="body1" color="textSecondary">
              User type:
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <TextField
                  select
                  id="profile.role.roleName"
                  name="profile.role.roleName"
                  value={values.profile?.role?.roleName}
                  onChange={handleChangeRole}
                  className={classes.textField}
                  variant="outlined"
                  inputProps={{ 'aria-label': 'User type' }}
                  fullWidth
                  SelectProps={{
                    native: isIOS,
                  }}
                >
                  {[
                    userTypes.admin,
                    userTypes.fieldTechnician,
                    userTypes.dispatcher,
                    userTypes.accounting,
                    userTypes.fleetMaintenance,
                    userTypes.projectManagement,
                    userTypes.machineShop,
                  ].map((type) => (
                    <Option key={type} value={type}>
                      {type}
                    </Option>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={8}>
                <UserTypeInfo type={values.profile.role.roleName} />
              </Grid>
            </Grid>
          </label>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      {permissions.length !== 0 && (
        <Grid
          container
          spacing={2}
          className={classes.marginSmall}
          justifyContent="space-between"
        >
          {Object.keys(permissions)
            .sort((a, b) => a.localeCompare(b))
            .map((permission) => (
              <Grid
                item
                xs={12}
                md={5}
                lg={5}
                key={Math.floor(+new Date() + Math.random() * 0xffffffff).toString(36)}
              >
                <FormControlLabel
                  label={permLabels[permission] + ':'}
                  labelPlacement="start"
                  control={
                    <IOSSwitch
                      checked={permissions[permission]}
                      onChange={handleChangePermission(permission)}
                      name={permission}
                      value={permission}
                    />
                  }
                />
              </Grid>
            ))}
        </Grid>
      )}
    </>
  )
}

export default withStyles(styles)(UserPermissions);