import React from 'react';
import { Option } from '../components/Option';


export const createOptions = (value, index) => (
  <Option key={index || value} value={value}>
    {value}
  </Option>
);

export const createOptionsWithLabel = ({ value, label, index }) =>  (
    <Option key={index || value} value={value}>
      {label || value}
    </Option>
);

export const createListWithOptions = ({ value, label, index }) => (
    <span key={index || value} value={value} style={{fontSize: '0.8rem'}}>
      {label || value}
  </span>
);

