import { withStyles } from '@material-ui/core/styles';
import UserTimeoffsList from './components/UserTimeoffsList/UserTimeoffsList';
import styles from './styles';
import { format } from 'date-fns';
import Divider from '@material-ui/core/Divider';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';
import { parseStringDate } from 'helpers/_date-helpers';

const UsersList = ({ classes, data }) => {
  const { dateFormat } = useDateSettingsFormat();

  return (
    <div className={classes.allUsersWrapper}>
      {data?.map((user) => {
        return (
          <>
            <div className={classes.dateItem}>{format(parseStringDate(user.date), dateFormat)}</div>
            <UserTimeoffsList key={user._id} timeoffs={user.workerTimeoffs} />
            <Divider style={{ marginBottom: 10 }} />
          </>
        );
      })}
    </div>
  );
};

export default withStyles(styles)(UsersList);
