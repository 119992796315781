import React from 'react';
import propTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import InfiniteScroll from 'react-infinite-scroller';

import styles from './styles';
import ContractorCard from './components/ContractorCard';

const ContractorsList = ({ classes, contractors, openDrawer, updateProjectsByPage, hasMore, hasFullAccess, page, loading, openSnackbar, initialLoad }) => {
  const loadMore = () => {
    if (loading) return
    if (hasMore) updateProjectsByPage();
  };
  return contractors.length ? ( 
    <InfiniteScroll
      pageStart={page}
      loadMore={loadMore}
      hasMore={hasMore}
      loader={
        <div className={classes.loader} key={0}>
          Loading ...
        </div>
      }
      threshold={300}
    >
      <Grid container spacing={2} justifyContent="flex-start" className={classes.paddingBottom}>
        {contractors.map((contractor) => (
          <Grid key={contractor._id} item xs={12} lg={3} xl="auto">
            <ContractorCard contractor={contractor} openDrawer={openDrawer} hasFullAccess={hasFullAccess} openSnackbar={openSnackbar} />
          </Grid>
        ))}
      </Grid>
    </InfiniteScroll>
  ) : (
    <Typography variant="h3" align="center" className={classes.noResultsBlock}>
      {!initialLoad && 'No Results'}
    </Typography>
  );
};

ContractorsList.propTypes = {
  classes: propTypes.object.isRequired,
  openDrawer: propTypes.func.isRequired,
  contractors: propTypes.array.isRequired,
  updateVisibleContractors: propTypes.func.isRequired,
  hasMore: propTypes.bool.isRequired,
};

export default withStyles(styles)(ContractorsList);
