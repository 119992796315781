export default (theme) => ({
  fixSafariOverflow: {
    overflow: 'visible',
  },
  nav: {
    [theme.breakpoints.up('lg')]: {
      position: 'fixed',
      zIndex: 30000,
      width: 70,
      height: 140,
      top: 'calc(50% - 70px)',
      background: '#000',
      cursor: 'pointer',
      opacity: 0.3,
    },
  },
  navLeft: {
    left: -40,
    borderRadius: '0 900px 900px 0',
    transition: 'all 0.1s ease-in-out',
    '&:hover': {
      left: -15,
      opacity: 0.4,
      '& svg': {
        display: 'block',
      },
    },
  },
  navIcon: {
    width: '100%',
    height: '100%',
    color: '#fff',
    display: 'none',
  },
  navRight: {
    right: -40,
    borderRadius: '900px 0 0 900px',
    transition: 'all 0.1s ease-in-out',
    '&:hover': {
      right: -15,
      opacity: 0.4,
      '& svg': {
        display: 'block',
      },
    },
  },
  formWrapper: {
    padding: '20px 15px 0',
    overflow: 'auto',
    position: 'relative',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      minWidth: 320,
    },
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      width: 768,
    },
    '@media (max-width: 295px)': {
      minWidth: 'auto',
    },
  },
  regularFontWeight: {
    fontWeight: 300,
  },
  mediumFontWeight: {
    fontWeight: 500,
  },
  yellowText: {
    color: theme.palette.textColor.yellow2,
  },
  paddingBottom: {
    paddingBottom: 8,
  },
  zeroPadding: {
    padding: 0,
  },
  zeroMargin: {
    margin: '0px !important',
  },
  marginBottom: {
    marginBottom: 8,
  },
  marginRightBig: {
    marginRight: 20,
  },
  displayBlock: {
    display: 'block',
  },
  header: {
    marginBottom: 4,
    [theme.breakpoints.up('md')]: {
      maxWidth: 'calc(100% - 210px)',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 30,
    },
  },
  jobNumber: {
    color: theme.palette.textColor.secondary,
    fontSize: '1rem',
    margin: '0 12px',
  },
  closeButton: {
    position: 'absolute',
    right: 15,
    top: 18,
    padding: 5,
    '&:focus': {
      color: '#fff',
      background: 'rgba(0, 0, 0, 0.08)',
    },
  },
  headerButtonsWrapper: {
    display: 'inline-flex',
    position: 'absolute',
    top: 24,
    right: 70,
    '& img': {
      marginRight: 8,
    },
  },
  primaryTextButton: {
    padding: 0,
    textDecoration: 'none',
    color: theme.palette.textColor.primary,
    '&:hover': {
      color: theme.palette.textColor.primary,
      background: `${theme.palette.background.transparent} !important`,
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
      background: `${theme.palette.background.transparent} !important`,
    },
  },
  commentsButton: {
    display: 'block',
    marginTop: 8,
  },
  photoWrapper: {
    borderRadius: '50%',
    border: `1px solid ${theme.palette.textColor.blue}`,
    borderTopColor: 'transparent',
    borderBottomColor: 'transparent',
    width: 42,
    height: 42,
    transform: 'rotate(135deg)',
    minWidth: 42,
  },
  photo: {
    display: 'block',
    transform: 'rotate(-135deg)',
    height: 36,
    maxWidth: 36,
    borderRadius: '50%',
    margin: '2px auto 0',
    boxSizing: 'border-box',
  },
  flexBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  name: {
    marginLeft: 15,
    fontSize: '1rem',
  },
  forceAccount: {
    display: 'inline',
    color: '#fff',
    backgroundColor: theme.palette.primary.blue,
    borderRadius: 9,
    padding: '3px 5px',
  },
  flexWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '100%',
    minWidth: 65,
    marginLeft: 10,
    marginTop: 1,
  },
  secondaryText: {
    color: theme.palette.textColor.secondary,
    fontSize: '0.8rem',
    fontWeight: 400,
    marginRight: 10,
  },
  inlineBlock: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  rtsBlock: {
    [theme.breakpoints.up('md')]: {
      textAlign: 'right',
    },
  },
  locationFlex: {
    display: 'flex',
    alignItems: 'center',
    wordBreak: 'break-word',
    '& > img': {
      marginLeft: 10,
      cursor: 'pointer',
    },
  },
  textCenter: {
    textAlign: 'center',
  },
  deleteButton: {
    padding: 0,
    color: theme.palette.textColor.red,
    '&:hover': {
      color: theme.palette.textColor.red,
      background: `${theme.palette.background.transparent} !important`,
    },
    '&:focus': {
      color: theme.palette.textColor.red,
      background: `${theme.palette.background.transparent} !important`,
    },
    '& img': {
      marginRight: 5,
    },
  },
  paddingBottom50: {
    paddingBottom: 50,
  },
  bottomPanel: {
    position: 'fixed',
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 16px)',
    },
    [theme.breakpoints.up('lg')]: {
      width: 752,
    },
  },
  scheduleFuncBtn: {
    color: theme.palette.textColor.red,
    background: 'rgba(255, 255, 255, 1)',
    '&:hover': {
      color: theme.palette.textColor.red,
      background: 'rgba(255, 255, 255, 1)',
    },
    '&:focus': {
      color: theme.palette.textColor.red,
      background: 'rgba(255, 255, 255, 1)',
    },
    '& img': {
      marginRight: 8,
    },
  },
  locations: {
    marginTop: 8,
    '&:first-child': {
      marginTop: 0,
    },
  },
  breakText: {
    wordBreak: 'break-word',
  },
  docLinks: {
    fontFamily: 'Poppins,Open Sans,sans-serif',
    color: theme.palette.textColor.primary,
    fontWeight: 500,
    fontSize: 14,
    textDecoration: 'underline',
    '&:visited': {},
  },
  textFieldSelect: {
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      color: theme.palette.textColor.secondary,
      transform: 'translate(14px, 9px) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {},
  },
});
