import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import styles from './styles';

const EditNoteModal = ({ classes, isOpen, onClose, onSubmit, initialText, modalType }) => {
  const [note, setNote] = useState('');
  useEffect(() => {
    setNote(initialText);
  }, [initialText]);

  const handleChange = (e) => setNote(e.target.value);
  const handleSubmit = () => onSubmit(note);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        classes: {
          root: classes.dialogRoot,
        },
      }}
    >
      <div className={classes.header}>
        <Typography variant="h5" color="textPrimary">
          Edit {modalType === 'comment' ? 'comment' : 'note'}
        </Typography>
        <IconButton className={classes.closeButton} onClick={onClose} aria-label="Close" disableRipple>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent style={{ paddingLeft: '20px' }}>
        <TextField
          id="note"
          name="note"
          rowsMax={2}
          value={note}
          onChange={handleChange}
          className={classes.textArea}
          variant="outlined"
          multiline
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <div className={classes.flexBox}>
          <Button
            color="secondary"
            variant="outlined"
            className={classes.cancelButton}
            onClick={onClose}
            disableTouchRipple
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="outlined"
            className={classes.saveButton}
            onClick={handleSubmit}
            disableTouchRipple
          >
            Save
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

EditNoteModal.propTypes = {
  classes: propTypes.object.isRequired,
  isOpen: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  onSubmit: propTypes.func.isRequired,
  initialText: propTypes.string.isRequired,
  modalType: propTypes.string.isRequired,
};

export default withStyles(styles)(EditNoteModal);
