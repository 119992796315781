import React, { Component } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import LoadingSpinner from '../../../LoadingSpinner';
import { checkUserRole } from 'helpers/_helpers';
import styles from './styles';


class QualityControlActions extends Component {
  state = {
    isLoading1: false,
    isLoading2: true,
    isAnyFullClaim: false,
    owner: null,
    claimId: null
  }

  componentDidMount () {
    const { openSnackbar } = this.props;
    const sheetId = this.props.sheet._id;
    const data = {
      api_key: process.env.REACT_APP_LIVEGENIC_APPI_KEY,
      sync: true,
      ticket: {
        external_system_record_id: sheetId,
      }
    };
    fetch(`${process.env.REACT_APP_LIVEGENIC_ENDPOINT_URL}/tickets/search`, {
      method: 'post',
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data)
    })
    .then(res => res.json())
    .then(data => {
      const isTicket = Boolean(data.tickets.length);
      if (isTicket) {
        const { videos, attachments, audios, pictures } = data.tickets[0];
        const isAnyFullClaim = Boolean(videos.length || attachments.length || audios.length || pictures.length);
        this.setState({
          isAnyFullClaim,
          isLoading2: false,
          owner: data.tickets[0].owner,
          claimId: data.tickets[0].id
        });
      } else {
        this.setState({
          isAnyFullClaim: false,
          isLoading2: false,
        });
      }
    })
    .catch(error => {
      console.error(error);
      openSnackbar('error', 'Network error. Please, reload page');
      this.setState({
        isAnyFullClaim: false,
        isLoading2: false,
      });
    });
  }

  openApp = () => {
    const { sheet, openSnackbar } = this.props;
    const user = this.props.user
    if(!user.profile.email) {
      openSnackbar('error', 'Please set email in your profile to use quality control');
      return;
    }
    if(!(user.profile.fullName && user.profile.fullName.split(' ')[1])) {
      openSnackbar('error', 'Please set full name in your profile to use quality control');
      return;
    }
    this.setState({ isLoading1: true });
    const project = sheet.project;
    const first_name = user.profile.fullName ? user.profile.fullName.split(' ')[0] : user.username;
    const last_name = user.profile.fullName ? user.profile.fullName.split(' ')[1] : '';
    const defaultOwner = {
      email: user.profile.email,
      first_name,
      last_name
    };
    const owner = this.state.owner || defaultOwner;
    const projectName = project.route + (project.section ? ', Section ' + project.section : '') + (project.county ? ', ' + project.county : '');
    const date = new Date(sheet.hours.start).toISOString();
    const custom_fields = {
      job_number: project.jobNumber,
      project: projectName,
      project_id: project.id || '',
      contractor: project.contractor.name,
      date
    };
    const data1 = {
      api_key: process.env.REACT_APP_LIVEGENIC_APPI_KEY,
      sync: true,
      ticket: {
        external_system_record_id: sheet._id,
        owner,
        customer: {
          email: user.profile.email,
          first_name,
          last_name
        },
        custom_fields
      }
    };
    const data2 = {
      api_key: process.env.REACT_APP_LIVEGENIC_APPI_KEY,
      sync: true,
      ticket: {
        external_system_record_id: sheet._id,
        customer: {
          email: user.profile.email,
          first_name,
          last_name
        }
      }
    };
    fetch(`${process.env.REACT_APP_LIVEGENIC_ENDPOINT_URL}/tickets`, {
      method: 'post',
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data1)
    })
    .then(res => res.json())
    .then(data => {
      if (data.meta.status !== 'success') {
        this.setState({ isLoading1: false });
        const errorMessage = JSON.stringify(data.meta.errors);
        openSnackbar('error', `Request failed. ${errorMessage}`);
      } else {        
        return fetch(`${process.env.REACT_APP_LIVEGENIC_ENDPOINT_URL}/tickets/share-app`, {
          method: 'post',
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(data2)
        });
      }
    })
    .then(res => res.json())
    .then(data => {
      if (data.meta.status !== 'success') {
        this.setState({ isLoading1: false });
        const errorMessage = JSON.stringify(data.meta.errors);
        openSnackbar('error', `Request failed. ${errorMessage}`);
      } else {
        this.setState({ isLoading1: false });
        window.location = data.share_url;
      }
    })
    .catch(error => {
      console.error(error);
      openSnackbar('error', 'Network error. Please, reload page');
      this.setState({ isLoading1: false });
    });
  }

  openAdminWebsite = () => {
    const { openSnackbar } = this.props;
    const { claimId } = this.state;
    const user = user;
    if(!user.profile.email) {
      // notify('Please set email in your profile to use quality control');//!epic mock
      openSnackbar('error', 'Please set email in your profile to use quality control');
      return;
    }
    this.setState({ isLoading2: true, isAnyFullClaim: false });
    const first_name = user.profile.fullName ? user.profile.fullName.split(' ')[0] : user.username;
    const last_name = user.profile.fullName ? user.profile.fullName.split(' ')[1] : '';
    const data = {
      api_key: process.env.REACT_APP_LIVEGENIC_APPI_KEY,
      sync: true,
      user: {
        email: user.profile.email,
        first_name,
        last_name
      }
    };
    fetch(`${process.env.REACT_APP_LIVEGENIC_ENDPOINT_URL}/users`, {
      method: 'post',
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data)
    })
    .then(res => res.json())
    .then(res => {
      if (res.meta.status !== 'success') {
        this.setState({ isLoading2: false });
        const errorMessage = JSON.stringify(res.meta.errors);
        const isEmailError = res.meta.errors && res.meta.errors.user && res.meta.errors.user.email;
        if (isEmailError) {
          openSnackbar('error', 'Request failed. Your email has already been taken.');
        } else {
          openSnackbar('error', `Request failed. ${errorMessage}`);
        }
      }
      return fetch(`${process.env.REACT_APP_LIVEGENIC_ENDPOINT_URL}/users/auth`, {
        method: 'post',
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data)
      })
    })
    .then(res => res.json())
    .then(data => {
      this.setState({ isLoading2: false, isAnyFullClaim: true });
      if (data.auth_token) {
        const url = `${process.env.REACT_APP_LIVEGENIC_ENDPOINT_URL}/tickets/${claimId}?token=${data.auth_token}`;
        window.open(url);
      }
    })
    .catch(error => {
      console.error(error);
      openSnackbar('error', 'Network error. Please, reload page');
      this.setState({ isLoading2: false, isAnyFullClaim: true });
    });
  }

  isAssignedWorker = () => {
    const { workers } = this.props.sheet;
    const userId = this.props.user._id;
    const isAssaigned = workers.some(worker => worker._id === userId);
    const isDesktop = window.innerWidth >= 1190;
    return isAssaigned && !isDesktop;
  }

  hasAdminAccess = () => checkUserRole('Super Admin') || checkUserRole('Admin') || checkUserRole('Dispatcher');


  render() {
    const { classes } = this.props;
    const { isLoading1, isLoading2, isAnyFullClaim } = this.state;

    return (
      <div className={classes.textRight}>
        {this.isAssignedWorker() && (
          isLoading1 ? (
            <LoadingSpinner height={20} margin={8} />
          ) : (
            <Button
              variant="text"
              className={classes.actionButton}
              onClick={this.openApp}
              disableRipple
              disableTouchRipple
            >
              + Add QC
            </Button>
        ))}
        {this.hasAdminAccess() && (
          isLoading2 ? (
            <LoadingSpinner height={20} margin={8} />
          ) : (
            isAnyFullClaim && (
            <Button
              variant="text"
              className={classes.actionButton}
              onClick={this.openAdminWebsite}
              disableRipple
              disableTouchRipple
            >
              <img src="/images/visibility-icon.svg" />
              View QC
            </Button>
          ))
        )}
      </div>
    );
  }
}

QualityControlActions.propTypes = {
  classes: propTypes.object.isRequired,
  sheet: propTypes.object.isRequired,
  openSnackbar: propTypes.func.isRequired
};

export default withStyles(styles)(QualityControlActions);
