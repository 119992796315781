import React, { useEffect, useState, useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Controller, useFormContext, useController } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import DoneIcon from '@material-ui/icons/Done';
import MenuItem from '@material-ui/core/MenuItem';
import IOSSwitch from 'components/IOSSwitch';
import DatePickerInput from 'components/DatePickerInput';
import classnames from 'classnames';
import { add, format, parse, startOfDay } from 'date-fns';
import CloseIcon from '@material-ui/icons/Close';
import ExportIcon from 'assets/images/arrow-export-up-grey.svg';
import styles from './styles';
import './styles.css';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';
import PreviewModal from 'components/PreviewModal/PreviewModal';
import { Tooltip } from 'pages/SuperAdmin/components/Clients/components/AddClientForm/components/Tooltip';

const palette = [
  '#ffffff',
  '#000000',
  '#f0592a',
  '#f00',
  '#f90',
  '#ff0',
  '#0f0',
  '#0ff',
  '#00f',
  '#90f',
  '#f0f',
  '#c00',
  '#e69138',
  '#f1c232',
  '#6aa84f',
  '#45818e',
  '#3d85c6',
  '#674ea7',
  '#a64d79',
  '#900',
  '#b45f06',
  '#bf9000',
  '#38761d',
  '#134f5c',
  '#0b5394',
  '#351c75',
  '#741b47',
  '#600',
  '#783f04',
  '#7f6000',
  '#274e13',
  '#0c343d',
  '#073763',
  '#20124d',
  '#4c1130',
];

const HomePageBlock = ({ classes, pageType, control, errors }) => {
  const { homepage: homepageSettings, desktopImage: currentImage } =
    useSelector((state) => state.settings.settings) || {};

  const { dateFormat } = useDateSettingsFormat();

  const { watch, setValue } = useFormContext();
  const countdownEnabled = watch('countdownEnabled');
  const [imageBroken, setImageBroken] = useState(false);
  const [previewModal, setPreviewModal] = useState({
    isOpen: false,
    file: null,
  });

  const {
    field: { value: file, onChange: onImageChange },
  } = useController({
    name: 'homePageImage',
    control,
    defaultValue: null,
  });
  const {
    field: { value: mobileImgFile, onChange: onMobileImageChange },
  } = useController({
    name: 'mobileImgFile',
    control,
    defaultValue: null,
  });
  const {
    field: { value: deleteCurrentImage, onChange: onDeleteImage },
  } = useController({
    name: 'deleteImage',
    control,
    defaultValue: null,
  });

  useEffect(() => {
    if (!countdownEnabled) {
      setValue('countdownDate', null);
    } else {
      if (homepageSettings && homepageSettings.countdownDate) {
        setValue('countdownDate', new Date(homepageSettings.countdownDate));
      } else {
        setValue('countdownDate', add(new Date(), { days: 1 }));
      }
    }
  }, [countdownEnabled]);

  useEffect(() => {
    setValue('countdownEnabled', (homepageSettings && homepageSettings.countdownEnabled) || false);
  }, [homepageSettings]);

  const onDropAccepted = ([droppedFile]) => {
    setPreviewModal({
      isOpen: true,
      file: droppedFile,
    });
    onDeleteImage(false);
  };
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open: openFileDialog,
  } = useDropzone({
    onDropAccepted,
    accept: 'image/*',
    maxFiles: 1,
    maxSize: 5 * 1024 * 1024, // 5MB
    multiple: false,
    disabled: pageType === 'read',
    noClick: true,
    noKeyboard: true,
  });

  const renderImage = useCallback(() => {
    if (deleteCurrentImage || (!file && !currentImage?.imageKey))
      return (
        <Typography
          variant="body2"
          color="textSecondary"
          className={classnames(classes.inlineFlex)}
        >
          Add Your Picture Here
        </Typography>
      );
    if (file) {
      return (
        <div key={file.name} className={classes.homepageDropzoneThumbWrapper}>
          <img
            src={URL.createObjectURL(file)}
            alt={file.name}
            className={classnames(
              classes.homepageDropzoneThumb
              // (!imageBroken && deleteCurrentImage) && classes.thumbGreyed
            )}
          />
        </div>
      );
    } else if (currentImage?.imageKey) {
      return (
        <div className={classes.homepageDropzoneThumbWrapper}>
          <img
            src={`${process.env.REACT_APP_BASE_URL}/settings/homepage-image?imageKey=${currentImage.imageKey}`}
            alt="Homepage"
            onError={() => setImageBroken(true)}
            className={classnames(
              classes.homepageDropzoneThumb
              // (!imageBroken && deleteCurrentImage) && classes.thumbGreyed
            )}
          />
        </div>
      );
    }
  }, [file, currentImage, imageBroken, deleteCurrentImage]);

  const onCancel = () => {
    setPreviewModal({
      isOpen: false,
      file: null,
    });
  };

  const onSave = (data) => {
    onImageChange(data.desktop);
    onMobileImageChange(data.mobile);
    onCancel();
  };

  return (
    <Grid container item xs={12} spacing={5}>
      <Grid item xs={12}>
        <Grid container spacing={5}>
          <Grid item xs={8} md={4}>
            <Typography variant="h4">Home Page Settings</Typography>
          </Grid>
          <Grid container item xs={12} spacing={6}>
            <Grid item xs={12} md={6} lg={6}>
              <Typography
                variant="body1"
                color="textSecondary"
                className={classes.regularFontWeight}
              >
                <Tooltip title={'Picture Upload:'}>
                  <div>
                    <Typography variant="body1" style={{ color: '#fff' }}>
                      Picture Requirements:
                    </Typography>
                    <br />
                    <Typography variant="body1" style={{ color: '#fff' }}>
                      Format:
                    </Typography>
                    Accepts JPEG, PNG, GIF, BMP, WebP, HEIC/HEIF, SVG formats. Ensure your image
                    matches these types.
                    <Typography variant="body1" style={{ color: '#fff' }}>
                      <br />
                      Maximum File Size:
                    </Typography>
                    Up to 5 MB. Larger files cannot be uploaded.
                    <Typography variant="body1" style={{ color: '#fff' }}>
                      <br />
                      Number of Files:
                    </Typography>
                    Only one file can be uploaded at a time.
                  </div>
                </Tooltip>
              </Typography>
              <div {...getRootProps()} id="homePageImage">
                <input {...getInputProps()} />
                <div
                  className={classnames(
                    classes.homepageDropzone,
                    isDragActive && classes.homepageDropzoneDragActive
                  )}
                >
                  {renderImage()}
                  {pageType !== 'read' && (
                    <div
                      className={classnames(
                        classes.delImg,
                        ((!file && !currentImage?.imageKey) || deleteCurrentImage) &&
                          classes.buttonGreyed
                      )}
                    >
                      <CloseIcon
                        fontSize={'medium'}
                        color={'primary'}
                        onClick={() => onDeleteImage(true)}
                      />
                    </div>
                  )}
                  {pageType !== 'read' && (
                    <div className={classes.uploadImg}>
                      <img
                        className={classes.uploadImgButton}
                        src={ExportIcon}
                        alt="export"
                        onClick={openFileDialog}
                      />
                    </div>
                  )}
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6} container spacing={4}>
              <Grid item xs={12}>
                <label htmlFor="homePageQuote">
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    className={classes.regularFontWeight}
                  >
                    Quote of the Day:
                  </Typography>
                  <Controller
                    name="homePageQuote"
                    control={control}
                    defaultValue={(homepageSettings && homepageSettings.quote) || ''}
                    as={
                      <TextField
                        id="homePageQuote"
                        name="homePageQuote"
                        variant="outlined"
                        inputProps={{ 'aria-label': 'homePageQuote' }}
                        fullWidth={true}
                        multiline
                        minRows={3}
                      />
                    }
                  />
                </label>
              </Grid>
              <Grid item xs={12}>
                <label htmlFor="homePageQuoteColor">
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    className={classes.regularFontWeight}
                  >
                    Color of Quote Text:
                  </Typography>
                  <Controller
                    name="homePageQuoteColor"
                    control={control}
                    defaultValue={homepageSettings.quoteColor || ''}
                    as={
                      <TextField
                        select
                        id="homePageQuoteColor"
                        name="homePageQuoteColor"
                        variant="outlined"
                        className={classes.textField}
                        inputProps={{ 'aria-label': 'homePageQuoteColor' }}
                        SelectProps={{
                          renderValue: (value) => (
                            <div
                              className={classnames(
                                classes.valueColor,
                                value === '#ffffff' && classes.whiteBlock
                              )}
                              style={{ backgroundColor: value }}
                            />
                          ),
                          MenuProps: {
                            classes: {
                              list: classes.customSelect,
                            },
                          },
                        }}
                        style={{ width: '250px' }}
                      >
                        {palette.map((color, i) => (
                          <MenuItem
                            key={i}
                            value={color}
                            className={classnames(
                              classes.colorItemWrapper,
                              homepageSettings.quoteColor === color && classes.checkedOuter
                            )}
                          >
                            <div
                              style={{ backgroundColor: color }}
                              className={classnames(
                                classes.colorItem,
                                homepageSettings.quoteColor === color && classes.checkedInner,
                                color === '#ffffff' && classes.whiteBlock
                              )}
                            >
                              {homepageSettings.quoteColor === color && (
                                <DoneIcon className={classes.checkedIcon} />
                              )}
                            </div>
                          </MenuItem>
                        ))}
                      </TextField>
                    }
                  />
                </label>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            spacing={4}
            className={classnames(classes.homepageCountdownBlock, classes.zeroPaddingTop)}
          >
            <Grid item xs={12} md={4} lg={3} className={classes.homepageCountdownWidgetBlock}>
              <Typography
                variant="body1"
                color="textSecondary"
                className={classnames(classes.regularFontWeight, classes.inlineFlex)}
              >
                Count Down Widget:
              </Typography>
              <Controller
                control={control}
                name="countdownEnabled"
                defaultValue={(homepageSettings && homepageSettings.countdownEnabled) || false}
                render={({ name, value, onChange }) => (
                  <IOSSwitch
                    name={name}
                    small
                    checked={value}
                    onChange={(e) => onChange(e.target.checked)}
                    disabled={pageType === 'read'}
                  />
                )}
              />
            </Grid>
            {countdownEnabled && (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <label htmlFor="countdownDate">
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    className={classes.regularFontWeight}
                  >
                    Count Down Date To:
                  </Typography>
                  <Controller
                    control={control}
                    name="countdownDate"
                    rules={{ required: countdownEnabled }}
                    defaultValue={
                      homepageSettings && homepageSettings.countdownDate
                        ? new Date(homepageSettings.countdownDate)
                        : add(startOfDay(new Date()), { days: 1 })
                    }
                    render={({ value, onChange }) => (
                      <DatePickerInput
                        selectedDay={value && format(new Date(value), dateFormat)}
                        setFieldValue={(_, value) =>
                          onChange(
                            parse(value, dateFormat, add(startOfDay(new Date()), { days: 1 }))
                          )
                        }
                        minDate={format(add(new Date(), { days: 1 }), dateFormat)}
                        field="countdownDate"
                        fullWidth
                        sm
                      />
                    )}
                  />
                  {errors.countdownDate && (
                    <Typography color="textRed">This field is required</Typography>
                  )}
                </label>
              </Grid>
            )}
            {countdownEnabled && (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <label htmlFor="countdownTitle">
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    className={classes.regularFontWeight}
                  >
                    Count Down Widget Title:
                  </Typography>
                  <Controller
                    name="countdownTitle"
                    control={control}
                    defaultValue={(homepageSettings && homepageSettings.countdownTitle) || ''}
                    as={
                      <TextField
                        id="countdownTitle"
                        name="countdownTitle"
                        variant="outlined"
                        inputProps={{ 'aria-label': 'countdownTitle' }}
                        fullWidth={true}
                      />
                    }
                  />
                </label>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      {previewModal.isOpen && (
        <PreviewModal
          isOpen={previewModal.isOpen}
          onSave={onSave}
          onCancel={onCancel}
          file={previewModal.file}
        />
      )}
    </Grid>
  );
};
export default withStyles(styles)(HomePageBlock);
