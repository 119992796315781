import { SHEET_MATERIALS_EVENT } from 'subscriptions/events/events.enum';
import { subscriptionsFabric } from 'subscriptions/subscriptions.fabric';
import currentWorklogActions from 'store/currentWorklog/actions';


const handlers = {
  [SHEET_MATERIALS_EVENT.created]: (store) => (data) => {
    const currentWorklog = store.getState().currentWorklog.worklogSheet?.sheet;
    if (currentWorklog && currentWorklog?._id === data?.sheetId) {
      store.dispatch(currentWorklogActions.postWorklogMaterialSuccess(data));
    }
  },
  [SHEET_MATERIALS_EVENT.updated]: (store) => (data) => {
    const currentWorklog = store.getState().currentWorklog.worklogSheet?.sheet;
    if (currentWorklog && data?.length) {
      for (const updatedMaterial of data) {
        if (currentWorklog?._id === updatedMaterial.sheetId) {
          store.dispatch(currentWorklogActions.updateWorklogMaterialSuccess(updatedMaterial));    
        }
      }
    }
  },
  [SHEET_MATERIALS_EVENT.deleted]: (store) => (data) => {
    const currentWorklog = store.getState().currentWorklog.worklogSheet?.sheet;
    if (currentWorklog && data?._id) {
      store.dispatch(currentWorklogActions.deleteWorklogMaterialSuccess(data));
    }
  },
}

export const currentWorklogSheetMaterialsSubscriptionMiddleware = subscriptionsFabric(
  currentWorklogActions,
  SHEET_MATERIALS_EVENT,
  handlers
);