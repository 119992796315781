import React, { useEffect, useMemo, useState } from 'react';
import propTypes from 'prop-types';
import { Tabs, Tab } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import HolidaysTab from '../AvailabilityCalendar/components/HolidaysTab';
import CompanyDayOffTab from '../AvailabilityCalendar/components/CompanyDayOffTab';
import styles from '../AvailabilityCalendar/styles';
import { RegisterTimeOffList } from './components/RegisterTimeOffList/RegisterTimeOffList';

const Timeoffs = ({
  openSnackbar,
  holidayDates,
  toggleHolidayModal,
  timeoffDates,
  updateHolidayTabStatus,
  refreshHoliday,
  ...props
}) => {
  const tabs = useMemo(
    () => [
      {
        label: 'Approved',
        value: 'Approved',
      },
      {
        label: 'Requested',
        value: 'Requested',
      },
      {
        label: 'Denied',
        value: 'Denied',
      },
      {
        label: 'Holidays',
        value: 'Holidays',
      },
      {
        label: 'Company Day Off',
        value: 'CompanyDayOff',
      },
    ],
    []
  );

  const status = useMemo(() => props.status, []);
  const tabIndex = useMemo(() => {
    if (!status) return;
    return tabs.findIndex(({ value }) => value === status);
  }, [status]);

  const [tabValue, setTabValue] = useState(tabIndex || 0);

  const selectedTab = useMemo(() => tabs[tabValue].value, [tabValue]);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
    updateHolidayTabStatus(newValue === 3 || newValue === 4);
  };

  return (
    <>
      <Tabs value={tabValue} onChange={handleChangeTab} className={props.classes.tabs}>
        {tabs.map(({ label }) => (
          <Tab key={label} label={label} disableRipple />
        ))}
      </Tabs>
      {[0,1,2].includes(tabValue) && (
        <RegisterTimeOffList 
          type={selectedTab}
          timeoffDates={timeoffDates}
          openSnackbar={openSnackbar}
          updateHolidayTabStatus={updateHolidayTabStatus}
          props
          />
      )}
      {tabValue === 3 && (
        <HolidaysTab
          openSnackbar={openSnackbar}
          holidayDates={holidayDates}
          toggleHolidayModal={toggleHolidayModal}
          refreshHoliday={refreshHoliday}
        />
      )}
      {tabValue === 4 && (
        <CompanyDayOffTab
          openSnackbar={openSnackbar}
          holidayDates={holidayDates}
          toggleHolidayModal={toggleHolidayModal}
          refreshHoliday={refreshHoliday}
        />
      )}
    </>
  );
};

Timeoffs.propTypes = {
  openSnackbar: propTypes.func.isRequired,
  timeoffDates: propTypes.array.isRequired,
};

export default withStyles(styles)(Timeoffs);