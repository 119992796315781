export default theme => ({
  card: {
    // maxWidth: 237,
    paddingTop: 8,
    paddingBottom: 8,
    [theme.breakpoints.up('xl')]: {
      width: 237
    },
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    padding: 0,
    '&:hover': {
      color: theme.palette.textColor.secondary,
      backgroundColor: '#fff'
    },
    '&:focus': {
      color: theme.palette.textColor.secondary,
      backgroundColor: '#fff'
    }
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  flexBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '10px',
    '& p': {
      marginBottom: 0,
    },
  },
  marginBottomNormal: {
    marginBottom: 8,
  },
  marginBottomSmall: {
    marginBottom: '5px !important',
  },
  paddingBox: {
    padding: '0 12px',
  },
  paddingBottom: {
    paddingBottom: 5
  },
  zeroPadding: {
    padding: 0
  },
  divider: {
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    },
    [theme.breakpoints.up('lg')]: {
      display: 'block'
    },
  },
  mainContent: {
    padding: '10px 10px',
    textAlign: 'justify',
    '& p': {
      marginBottom: 15,
    },
    '&:last-child': {
      paddingBottom: 0,
    }
  },
  uplWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& p':{
      marginBottom: 0,
      color: '#5A87EF',
    },
    '& img': {
      marginRight: '5px'
    },
  },
  contentWrapper: {
    padding: '8px 12px 12px',
    borderTop: `1px solid ${theme.palette.borderColor.primary}`
  },
  actionsWrapper: {
    borderTop: `1px solid ${theme.palette.borderColor.primary}`,
    justifyContent: 'space-around',
    padding: '5px 5px 0',
    [theme.breakpoints.down('md')]: {
      paddingBottom: 0
    },
  },
  deleteButton: {
    minWidth: 28,
    height: 34,
    padding: 5,
    '&:hover': {
      background: '#fff !important',
    }
  },
  deleteIcon: {
    paddingTop: 6
  },
  noteDividerPadding: {
    padding: '8px 0'
  },
  commentButton: {
    color: theme.palette.primary.blue,
    textTransform: 'none',
    fontSize: 13,
    padding: 0,
    marginBottom: 4,
    '&:hover': {
      color: theme.palette.primary.blue,
      backgroundColor: '#fff !important'
    },
    '&:focus': {
      color: theme.palette.primary.blue,
      backgroundColor: '#fff !important'
    }
  },
  forceAccount: {
    display: 'inline',
    color: '#fff',
    backgroundColor: theme.palette.primary.blue,
    borderRadius: 9,
    padding: '3px 5px',
    marginRight: 10
  },
  flexWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '100%'
  },
  notesPadding: {
    paddingTop: 9,
    paddingBottom: 9
  },
  wordBreak: {
    wordBreak: 'break-word',
  },
});
