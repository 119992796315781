import { object, string, number } from 'yup';
import lettersArray from '../../helpers/constants';

export const LetteringMaterialSchema = (letterings = [], edit) =>
  object().shape({
    name: Number.isInteger(edit)
      ? string().trim().required('Field is required')
      : string()
          .trim()
          .required('Field is required') // required
          .test({
            name: 'letteringNameUnique',
            message: 'Lettering with same name already exists in current configuration',
            // if found line with same value => throw validation error
            test: (value) => !letterings.find(({ name }) => name === value),
          }),
    height: number()
      .typeError('Number is invalid. Only symbols 0 - 9 allowed')
      .positive('Value should be positive number')
      .required('Field is required'), // required,
    width: number()
      .typeError('Number is invalid. Only symbols 0 - 9 allowed')
      .positive('Value should be positive number')
      .required('Field is required'), // required
    stroke: number()
      .typeError('Number is invalid. Only symbols 0 - 9 allowed')
      .positive('Value should be positive number')
      .required('Field is required'), // required
    ratio: number()
      .typeError('Number is invalid. Only symbols 0 - 9 allowed')
      .positive('Value should be positive number')
      .required('Field is required'), // required
    letterSet: object().noUnknown(false).required('All default letters should be specified'),
    _id: string().optional(),
  });

export const LetteringLetterSquareSchema = object().shape({
  letterSquare: number()
    .typeError('Number is invalid. Only symbols 0 - 9 allowed')
    .positive('Value should be positive number')
    .required('Field is required'), // required
});

export const LetteringNewLetterSchema = (addedLetters = []) =>
  object().shape({
    newLetter: string()
      .trim()
      .max(1, 'Only 1 character can be added')
      .required('Value should be a number')
      .test({
        name: 'letteringNewLetterUnique',
        message: 'Letter is already added to letter set',
        test: (value) => !addedLetters.includes(value),
      }),
    letterSquare: number()
      .typeError('Number is invalid. Only symbols 0 - 9 allowed')
      .positive('Value should be a positive number')
      .required('Field is required'), // required
    _id: string().optional(),
  });
