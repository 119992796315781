export default (theme) => ({
  textField: {
    [theme.breakpoints.up('lg')]: {
      // maxWidth: 250,
    },
    '& textarea': {
      padding: '.4rem',
    },
    '& .MuiInputAdornment-positionEnd': {
      marginLeft: 0,
    },
  },
  onJobTimeField: {
    // width: 70,
    width: '100%',
  },
  flexBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  spaceAround: {
    justifyContent: 'space-around',
  },
  shiftImage: {
    height: 16,
    margin: '0 5px 2px 0',
  },
  error: {
    color: theme.palette.textColor.red,
    padding: '0 5px',
    fontSize: 11,
  },
  requiredSign: {
    color: theme.palette.textColor.red,
  },
  zeroPaddingTop: {
    paddingTop: '0px !important',
  },
  paddingTop: {
    paddingTop: 8,
  },
  paddingBottomBig: {
    paddingBottom: 16,
  },
  deleteButton: {
    color: theme.palette.error.red,
    padding: 0,
    marginBottom: 14,
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff !important',
      color: `${theme.palette.error.red} !important`,
    },
    '&:focus': {
      backgroundColor: '#fff !important',
      color: `${theme.palette.error.red} !important`,
    },
  },
  deleteButtonAlign: {
    color: theme.palette.error.red,
    '&:hover': {
      backgroundColor: '#fff !important',
      color: `${theme.palette.error.red} !important`,
    },
    '&:focus': {
      backgroundColor: '#fff !important',
      color: `${theme.palette.error.red} !important`,
    },
  },
  addButtonAlign: {
    color: theme.palette.textColor.primary,
    fontSize: 16,
    '&:hover': {
      backgroundColor: '#fff !important',
      color: `${theme.palette.textColor.primary} !important`,
    },
    '&:focus': {
      backgroundColor: '#fff !important',
      color: `${theme.palette.textColor.primary} !important`,
    },
  },
  legendAlign: {
    marginTop: 14,
  },
  zeroPadding: {
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
  },
  tripleSelectWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: 215,
    gap: '5px',
    '& > div': {
      // maxWidth: 65,
      // maxWidth: 100,
    },
  },
  doubleSelectWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: 215,
    '& > div': {
      maxWidth: 100,
    },
  },
  constantHeight: {
    width: '100%',
    height: 15,
  },
  alignTop: {
    alignItems: 'flex-start',
  },
  adornment: {
    color: theme.palette.textColor.secondary,
    paddingRight: '0.4rem',
    fontSize: '0.875rem',
  },
  inputRoot: {
    padding: '0!important',
  },
  inputRootError: {
    borderColor: `${theme.palette.textColor.red}!important`,
  },
  input: {
    padding: '.4rem!important',
  },
  endAdornment: {
    '& button:hover': {
      backgroundColor: 'transparent!important',
      color: 'rgba(0, 0, 0, 0.54)!important',
    },
  },
  option: {
    fontSize: 14,
  },
  paddingBottom: {
    paddingBottom: '8px',
  },
  flexColumn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'column',
    width: '100%',
  },
  deleteLocationButton: {
    padding: 0,
    marginLeft: 15,
    color: theme.palette.textColor.red,
    width: 24,
    '&:hover': {
      color: theme.palette.textColor.red,
      background: theme.palette.background.default,
    },
    '&:focus': {
      color: theme.palette.textColor.red,
      background: theme.palette.background.default,
    },
  },
  primary: {
    color: theme.palette.textColor.primary,
  },
  primaryTextButton: {
    padding: 0,
    color: theme.palette.textColor.primary,
    '&:hover': {
      color: theme.palette.textColor.primary,
      background: `${theme.palette.background.default} !important`,
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
      background: `${theme.palette.background.default} !important`,
    },
  },
  fullWidth: {
    width: '100%',
  },
  blueButton: {
    paddingRight: '12px!important',
    paddingLeft: '12px!important',
    paddingBottom: '0px !important',
    paddingTop: '0px !important',
    color: theme.palette.textColor.primary,
    '&:hover': {
      color: theme.palette.textColor.primary,
      background: `${theme.palette.background.default} !important`,
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
      background: `${theme.palette.background.default} !important`,
    },
  },
  blueButtonIn: {
    display: 'block',
    height: '10px',
    width: '10px',
  },
  primaryContact: {
    height: '30px !important',
  },
  primaryAdd: {
    fontFamily: 'Poppins,Open Sans,sans-serif',
    fontSize: 14,
    fontWeight: 500,
    cursor: 'pointer',
    color: theme.palette.textColor.primary,
  },
  hideForDesktop: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  hideForMob: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  primaryCheckbox: {
    color: theme.palette.textColor.primary,
  },
  docLinks: {
    color: theme.palette.textColor.primary,
    fontWeight: 500,
    fontSize: 14,
    textDecoration: 'underline',
    '&:visited': {},
  },
  textFieldSelect: {
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      color: theme.palette.textColor.secondary,
      transform: 'translate(14px, 9px) scale(1)',
      zIndex: 0,
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {},
  },
});
