import { useState } from 'react';
import { format } from 'date-fns';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import styles from './../SheetItem/styles';
import PrintHLogo from 'assets/images/print-logo-header.png';
import { useSelector } from 'react-redux';
import { GLOBAL_DATE_FORMAT } from 'common/dateFormatConfig';

const OnlySheetImgPrint = ({ sheets, classes, isPrintById }) => {
  const settingsCompanyName = useSelector(
    (state) => state.personalProfile?.organization?.name || '3208 Technology, Inc.'
  );
  const sheetCompanyName = sheets?.[0]?.organization?.name || '3208 Technology, Inc.';
  const companyName = isPrintById ? sheetCompanyName : settingsCompanyName;


  const totalStateImgQuantity = sheets.reduce((accumulator, currentValue) => {
    if (!!currentValue?.images?.length) {
      return accumulator + currentValue?.images?.length || 0;
    } else return accumulator;
  }, 0);

  const [tmpStateImgQuantity, setTmpStateImgQuantity] = useState(0);
  const updateAllImgReadyState = () => {
    if (tmpStateImgQuantity + 1 >= totalStateImgQuantity) {
      setTimeout(() => {
        window.print();
      }, 200);
    } else {
      setTmpStateImgQuantity((prevState) => prevState + 1);
    }
  };

  return (
    <div style={{ padding: 25 }}>
      {/^Guidemark/.test(companyName) ? (
        <div item xs={12}>
          <img
            className={classnames(classes.logo)}
            src={PrintHLogo}
            // onLoad={props.updateHeaderLogoLoadedValue(sheet._id)}
          />
        </div>
      ) : (
        <div item xs={12} className={classes.textCenter}>
          <h1 className={classes.header1}>{companyName}</h1>
        </div>
      )}
      {sheets.map((sheet) => (
        <div key={sheet._id} className={classes.root}>
          {sheet?.images?.map((image) => (
            <div>
              <div style={{ pageBreakInside: 'avoid', marginBottom: 25 }}>
                <div>
                  <Typography
                    style={{ fontSize: 16, borderBottom: '1px solid #a4a5b3', marginTop: 15 }}
                    color="textSecondary"
                  >
                    {image.createdAt ? format(new Date(image.createdAt), GLOBAL_DATE_FORMAT.fullDateWithTime) : ''}
                  </Typography>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img
                      alt="WL Image"
                      key={image._id}
                      style={{
                        margin: '15px 0',
                        maxWidth: '210mm',
                        // maxHeight: '297mm',
                        // width: 'auto',
                        // height: 'auto'
                        // objectFit: 'contain',
                      }}
                      onLoad={() => {
                        updateAllImgReadyState();
                      }}
                      className={classnames()}
                      src={`${process.env.REACT_APP_BASE_URL}/sheets/image?key=${image.key}`}
                    />
                  </div>
                </div>
              </div>
              {image?.description && (
                <>
                  <Typography style={{ fontSize: 14 }} color="textSecondary">
                    Description:
                  </Typography>
                  <Typography style={{ fontSize: 14 }}>{image.description}</Typography>
                </>
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default withStyles(styles)(OnlySheetImgPrint);
