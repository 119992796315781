import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { materialGroups } from 'helpers/_sort-helpers';
import currentWorklogActions from './actions';

const initialState = {
  currentMaterial: {
    loading: false,
    data: [],
  },
  groupedMaterials: {
    loading: false,
    raw: [],
    data: [],
    isSuccessfullyAdded: { type: null, message: null },
  },
  worklogSheet: {
    sheet: {},
    timeOffs: null,
    surfaces: [],
    createdWlSurface: null,
  },
};

const worklogSheet = createReducer(initialState.worklogSheet, {
  [currentWorklogActions.setWorklogSheet]: (state, { payload }) => ({
    ...state,
    sheet: payload,
  }),
  [currentWorklogActions.clearWorklogSheet]: (state, { payload }) => ({
    ...state,
    sheet: {},
    timeOffs: null,
  }),
  [currentWorklogActions.patchWorklogSheet]: (state, { payload }) => ({
    ...state,
    sheet: {
      ...state.sheet,
      ...payload,
    },
  }),
  [currentWorklogActions.patchWorklogRTS]: (state, { payload }) => ({
    ...state,
    sheet: { ...state.sheet, returnToShop: payload.value },
  }),
  [currentWorklogActions.newNotesComment]: (state, { payload }) => ({
    ...state,
    sheet: { ...state.sheet, noteComments: [...(state.sheet.noteComments || []), payload] },
  }),
  [currentWorklogActions.setWorklogTimeOffs]: (state, { payload }) => ({
    ...state,
    timeOffs: payload,
  }),
  [currentWorklogActions.setWorklogSurfaces]: (state, { payload }) => ({
    ...state,
    surfaces: payload,
  }),
  [currentWorklogActions.addWorklogSurface]: (state, { payload }) => ({
    ...state,
    surfaces: state.surfaces?.some(surf => surf?._id === payload?._id) ? state.surfaces : [...state.surfaces, payload],
  }),
  [currentWorklogActions.deleteWorklogSurface]: (state, { payload }) => ({
    ...state,
    surfaces: state.surfaces.filter(surface => surface._id !== payload._id ),
  }),
  [currentWorklogActions.updateWorklogSurface]: (state, { payload }) => ({
    ...state,
    surfaces: state.surfaces.map((surface) => {
      if (surface._id !== payload._id) return surface;
      return payload
    }),
  }),
  [currentWorklogActions.editNotesComment]: (state, { payload }) => {
    const data = state.sheet.noteComments?.map((comment) => {
      return comment._id === payload._id ? payload : comment;
    });
    return {
      ...state,
      sheet: { ...state.sheet, noteComments: data },
    };
  },
  [currentWorklogActions.deleteNotesComment]: (state, { payload }) => {
    const data = state.sheet.noteComments?.filter((comment) => comment._id !== payload);

    return {
      ...state,
      sheet: { ...state.sheet, noteComments: data },
    };
  },
  [currentWorklogActions.removeWorker]: (state, { payload }) => {
    const data = state.sheet.workers.filter((worker) => worker._id !== payload);

    return {
      ...state,
      sheet: { ...state.sheet, workers: data },
    };
  },
  [currentWorklogActions.toggleCL]: (state, { payload }) => {
    if (!state.sheet.workers) return; //if run on clSchedule
    const data = state.sheet.workers.map((worker) => {
      if (worker._id === payload) {
        return { ...worker, foreman: !worker.foreman };
      } else return worker;
    });

    return {
      ...state,
      sheet: {
        ...state.sheet,
        workers: data,
      },
    };
  },
  [currentWorklogActions.updateWorker]: (state, { payload }) => {
    if (!state.sheet.workers) return; //if run on clSchedule

    return {
      ...state,
      sheet: {
        ...state.sheet,
        workers: state.sheet.workers.map((worker) =>
          worker._id === payload._id ? { ...worker, ...payload } : worker
        ),
      },
    };
  },
  [currentWorklogActions.updateEquipment]: (state, { payload }) => {
    if (!state.sheet.equipment) return;

    return {
      ...state,
      sheet: {
        ...state.sheet,
        equipment: state.sheet.equipment.map((equip) =>
          equip._id === payload._id ? { ...equip, ...payload } : equip
        ),
      },
    };
  },
  [currentWorklogActions.setCreatedWlSurface]: (state, { payload }) => {
    const surfaces = state.surfaces;
    if (payload && state.surfaces?.every(surf => surf._id !== payload._id)) {
      surfaces.push(payload)
    }

    return {
      ...state,
      surfaces,
      createdWlSurface: payload,
    }
  }
});

const groupedMaterials = createReducer(initialState.groupedMaterials, {
  [currentWorklogActions.getWorklogMaterialsRequest]: (state) => ({ ...state, loading: true }),
  [currentWorklogActions.isPostWorklogMaterialSuccessfully]: (state, { payload }) => ({
    ...state,
    isSuccessfullyAdded: payload,
  }),
  [currentWorklogActions.getWorklogMaterialsSuccess]: (state, { payload }) => {
    const groupedMaterials = materialGroups(payload);
    return {
      ...state,
      raw: payload.map((material) => ({ ...material })),
      data: groupedMaterials,
      loading: false,
    };
  },
  [currentWorklogActions.getWorklogMaterialsError]: (state, { payload }) => ({
    ...state,
    loading: false,
  }),
  [currentWorklogActions.postWorklogMaterialSuccess]: (state, { payload }) => {
    if (state.raw.some((material) => material._id === payload._id)) return state;

    const rawData = state.raw.slice();
    rawData.push(payload);
    const groupedMaterials = materialGroups(rawData);

    return {
      ...state,
      raw: rawData,
      data: groupedMaterials,
    };
  },
  [currentWorklogActions.updateWorklogMaterialSuccess]: (state, { payload }) => {
    const rawData = state.raw.map((material) =>
      material._id === payload._id ? payload : material
    );
    const groupedMaterials = materialGroups(rawData);

    return {
      ...state,
      raw: rawData,
      data: groupedMaterials,
    };
  },
  [currentWorklogActions.duplicateWorklogMaterialSuccess]: (state, { payload }) => {
    if (state.raw.some((material) => material._id === payload._id)) return state;

    const rawData = state.raw.slice();
    rawData.push(payload);
    const groupedMaterials = materialGroups(rawData);

    return {
      ...state,
      raw: rawData,
      data: groupedMaterials,
    };
  },
  [currentWorklogActions.patchWorklogMaterialSuccess]: (state, { payload }) => {
    if (!Array.isArray(payload)) return state;

    const rawData = state.raw.map((material) => {
      const updatedMaterial = payload.find(
        (payloadMaterial) => payloadMaterial._id === material._id
      );
      return updatedMaterial || material;
    });
    const groupedMaterials = materialGroups(rawData);

    return {
      ...state,
      raw: rawData,
      data: groupedMaterials,
    };
  },
  [currentWorklogActions.deleteWorklogMaterialSuccess]: (state, { payload }) => ({
    ...state,
    raw: state.raw.filter((material) => material._id !== payload._id),
    data: state.data
      .map((group) => ({
        ...group,
        materials: group.materials.filter(({ _id }) => _id !== payload._id),
      }))
      .filter((group) => group.materials?.length),
  }),
  [currentWorklogActions.deleteAllWorklogMaterials]: () => ({
    ...initialState.groupedMaterials,
  }),
});
const currentMaterial = createReducer(initialState.currentMaterial, {});

export default combineReducers({
  groupedMaterials,
  currentMaterial,
  worklogSheet,
});
