import { createAction } from '@reduxjs/toolkit';

const getEquipmentsData = createAction('equipments/getEquipmentsData');
// const equipmentsRequest = createAction('equipments/equipmentsRequest');
const equipmentsError = createAction('equipments/equipmentsError');
const getEquipmentsDataAdd = createAction('equipments/getEquipmentsDataAdd');
const deleteEquipment = createAction('equipments/deleteEquipment');
const createNewEquipment = createAction('equipments/createNewEquipment');
const updateEquipment = createAction('equipments/updateEquipment');
const resetHasMore = createAction('equipments/resetHasMore');

export default {
  getEquipmentsData,
  // equipmentsRequest,
  equipmentsError,
  getEquipmentsDataAdd,
  deleteEquipment,
  createNewEquipment,
  updateEquipment,
  resetHasMore,
};
