import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Drawer, Grid, Button, Divider } from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import classnames from 'classnames';
import CloseIcon from 'assets/images/close-icon.svg';
import { hasPermissionsFor, checkUserRole } from 'helpers/_helpers';
import { shortDate, isWithinCurrentWeek } from 'helpers/_date-helpers';
import { drawerTypes } from 'pages/Equipment/components/helpers/drawerTypes';
import PageLoader from '../PageLoader';
import ConfirmDialog from '../ConfirmDialog';
import CrewBlock from './components/CrewBlock';
import EquipmentBlock from './components/EquipmentBlock';
import SubmitSection from './components/SubmitSection';
import MaterialsBlock from './components/MaterialsBlock';
import CancelModal from './components/CancelModal';
import TimeBlock from './components/TimeBlock';
import WeatherBlock from './components/WeatherBlock';
import NotesBlock from './components/NotesBlock';
import GeneralInfoBlock from './components/GeneralInfoBlock';
import OverlappingModal from '../OverlapsingModal';
import ChangeTimeModal from '../OverlapsingModal/ChangeTimeModal';
import {
  updateSheetAddResource,
  getWorkers,
  getEquipment,
} from 'store/schedule/schedulePageOperation';
import { useRemoveSheet } from '../../pages/Schedule/components/ScheduleCalendar/components/ScheduleEvent/hooks/useRemoveSheet';
import {
  fetchWorklogMaterials,
  deleteAllWorklogMaterials,
  deleteWorklogAllResources,
  getTimeoffs,
  patchCancelWorklog,
  patchUncancelWorklog,
} from 'store/currentWorklog/operations';

import styles from './styles';
import { generateHoursObject } from 'helpers/helpers';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { fetchWorklogSheet } from 'store/currentWorklog/operations';
import currentWorklogActions from 'store/currentWorklog/actions';
import { userWorklogsDayHours } from './hooks/apiCalls';
import AlertModal from 'components/AlertModal/AlertModal';
import { fetchUsers } from 'store/people/peoplePageOperations';
import { useDuplicate } from './hooks/useDuplicate';
import { useWeekStart } from 'hooks/useWeekStart';
import {
  SHEET_MATERIALS_EVENT,
  SHEET_SURFACES_EVENT,
  TIMEOFFS_EVENT,
  SHEET_EVENT,
  WORKER_EVENT,
  CONTRACTORS_EVENT,
  PROJECTS_EVENT,
  EQUIPMENT_EVENT,
  SETTINGS_EVENT,
} from 'subscriptions/events/events.enum';
import personalProfileActions from 'store/personalProfile/personalProfileActions';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';
import { useCanEditWorklog } from 'common/hooks/use-can-edit-worklog';
// import { ChangeEquipTimeModal } from '../OverlapsingModal/ChangeTimeModal/ChageEquipTimeModal/ChangeEquipTimeModal';

const WorklogDetails = ({
  openSnackbar,
  openAdditionalDrawer,
  loading,
  shouldNotRender = false,
  isSchedule = false,
  isTimeClock,
  hasMore = false,
  sheetIds = [],
  openDrawer,
  pageCount = 0,
  updateDrawerFromRouter,
  updateVisibleSheets,
  showIndividualStartTime,
  noAccessCallback,
  mobileViewSheetsRefresh,
}) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector((store) => store.personalProfile.user) || {};
  const sheet = useSelector((store) => store.currentWorklog.worklogSheet.sheet);
  const { weekDayFromO } = useWeekStart();
  const getTimeFormatHook = useTimeSettingsFormat();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [overlappingModal, setOverlappingModal] = useState(null);
  const [userWorklogsModal, setUserWorklogsModal] = useState(null);
  const [equipWorklogsModal, setEquipWorklogsModal] = useState(null);
  const [localShouldNotRender, setLocalShouldNotRender] = useState(shouldNotRender);
  const [infoModal, setInfoModal] = useState({
    isOpen: false,
    text: '',
  });
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    text: 'Are you sure you want to clear all resources? This action cannot be reversed.',
    type: 'resources',
  });

  const dispatch = useDispatch();

  const { DuplicateModalComponent, setDuplicateModal } = useDuplicate(sheet._id, openSnackbar);
  const { removeSheet } = useRemoveSheet({ sheetId: sheet._id });

  useEffect(() => {
    if (params.sheetId) {
      dispatch(fetchWorklogMaterials(params.sheetId));
      dispatch(fetchWorklogSheet(params.sheetId));
      dispatch(getWorkers());
      dispatch(getEquipment());
      dispatch(fetchUsers({}, 0, Infinity));
      dispatch(getTimeoffs(params.sheetId));

      // sockets
      dispatch(currentWorklogActions.socketConnect(SHEET_MATERIALS_EVENT));
      dispatch(currentWorklogActions.socketConnect(SHEET_SURFACES_EVENT));
      dispatch(currentWorklogActions.socketConnect(TIMEOFFS_EVENT));
      dispatch(currentWorklogActions.socketConnect(SHEET_EVENT));
      dispatch(currentWorklogActions.socketConnect(WORKER_EVENT));
      dispatch(currentWorklogActions.socketConnect(CONTRACTORS_EVENT));
      dispatch(currentWorklogActions.socketConnect(PROJECTS_EVENT));
      dispatch(currentWorklogActions.socketConnect(EQUIPMENT_EVENT));
      dispatch(personalProfileActions.socketConnect(SETTINGS_EVENT));
      return () => {
        dispatch(currentWorklogActions.socketDisconnect(SHEET_MATERIALS_EVENT));
        dispatch(currentWorklogActions.socketDisconnect(SHEET_SURFACES_EVENT));
        dispatch(currentWorklogActions.socketDisconnect(TIMEOFFS_EVENT));
        dispatch(currentWorklogActions.socketDisconnect(SHEET_EVENT));
        dispatch(currentWorklogActions.socketDisconnect(WORKER_EVENT));
        dispatch(currentWorklogActions.socketDisconnect(CONTRACTORS_EVENT));
        dispatch(currentWorklogActions.socketDisconnect(PROJECTS_EVENT));
        dispatch(currentWorklogActions.socketDisconnect(EQUIPMENT_EVENT));
        dispatch(personalProfileActions.socketDisconnect(SETTINGS_EVENT));
      };
    }
  }, [params.sheetId]);

  useEffect(() => {
    if (!shouldNotRender) {
      updateDrawerFromRouter();
    }
  }, []);

  useEffect(() => {
    if (localShouldNotRender !== shouldNotRender) {
      setLocalShouldNotRender(shouldNotRender);
      updateDrawerFromRouter();
    }
  }, [shouldNotRender]);

  const closeInfoModal = () => setInfoModal({ isOpen: false, text: '' });

  // TODO: review this change and make it better if possible.
  // Case - closing my schedule worklog details on deleting worker (field tech) from resources
  const hasAccess = useCanEditWorklog(sheet);

  // to close Worklog Details on my-schedule/full-schedule (field-tech)
  useEffect(() => {
    if (
      loading ||
      !sheet?._id ||
      sheet?._id !== params.sheetId ||
      !user?._id ||
      !noAccessCallback ||
      typeof noAccessCallback !== 'function'
    )
      return;

    if (!hasAccess) noAccessCallback();
  }, [params.sheetId, loading, sheet, user, noAccessCallback, hasAccess]);

  if (!params.sheetId || !sheet || !Object.keys(sheet).length) {
    return null;
  }

  const close = () => {
    if (!isSchedule || isTimeClock) {
      updateVisibleSheets(sheet);
    }

    const arrPath = location.pathname.split('/');
    arrPath.pop();
    dispatch(currentWorklogActions.clearWorklogSheet());
    navigate(arrPath.join('/'), { replace: true });
  };

  const closeOverlappingModal = () => {
    setOverlappingModal(null);
  };

  const getFormattedHoursRange = (userHours) => {
    const { createdAt, hours } = sheet;
    const hoursToUse = userHours || hours;

    if (!hoursToUse) return;

    const { hourStart, minuteStart, amPmStart, dayStart, hourEnd, minuteEnd, amPmEnd, dayEnd } =
      generateHoursObject(hoursToUse.start, hoursToUse.end, createdAt, getTimeFormatHook);

    const startObj = [null, hourStart, minuteStart, amPmStart];
    const endObj = [null, hourEnd, minuteEnd, amPmEnd];
    const start = `${getTimeFormatHook(startObj).formattedTime} ${shortDate(
      createdAt,
      dayStart,
      getTimeFormatHook().localDayMonth
    )}`;
    const end = `${getTimeFormatHook(endObj).formattedTime} ${shortDate(
      createdAt,
      dayEnd,
      getTimeFormatHook().localDayMonth
    )}`;

    return `${start} \u2013 ${end}`;
  };

  const clearAllResources = () => {
    dispatch(deleteAllWorklogMaterials());
    dispatch(updateSheetAddResource(sheet._id, 1));
    dispatch(deleteWorklogAllResources(sheet._id));
    closeModal();
  };

  const clearAllResourcesModal = () => {
    setConfirmModal((prev) => ({ ...prev, isOpen: true }));
  };

  const isFirstInList = () => {
    return sheetIds.findIndex((id) => id === sheet._id) === 0;
  };

  const isLastInList = () => {
    const isLastInList = sheetIds.findIndex((id) => id === sheet._id) === sheetIds.length - 1;

    if (isLastInList && hasMore) {
      updateVisibleSheets(true, pageCount + 1);
    }

    return isLastInList;
  };

  const goToPrevSheet = () => {
    const currentIndex = sheetIds.findIndex((el) => el === sheet._id);
    const arrPath = location.pathname.split('/');
    arrPath.pop();
    arrPath.push(sheetIds[currentIndex - 1]);
    navigate(arrPath.join('/'), { replace: true });
  };

  const goToNextSheet = () => {
    const currentIndex = sheetIds.findIndex((el) => el === sheet._id);
    const arrPath = location.pathname.split('/');
    arrPath.pop();
    arrPath.push(sheetIds[currentIndex + 1]);
    navigate(arrPath.join('/'), { replace: true });
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsDeleteModalOpen(false);
    setIsCancelModalOpen(false);
    setConfirmModal((prev) => ({ ...prev, isOpen: false }));
  };

  const submitModal = () => {
    setIsModalOpen(false);
    openDrawer(drawerTypes.update, sheet);
  };

  const getScheduleFuncText = () => {
    const { published, canceledAt } = sheet;
    if (!published) return 'Remove from schedule';
    return canceledAt ? 'Mark as not canceled' : 'Mark as canceled';
  };

  const runCorrespondAction = async () => {
    const { published, canceledAt, _id } = sheet;
    if (!published) return setIsDeleteModalOpen(true);
    if (!canceledAt) return setIsCancelModalOpen(true);

    try {
      await dispatch(patchUncancelWorklog(sheet._id));
      await dispatch(updateSheetAddResource(sheet._id, 1));
    } catch (e) {
      console.log(e);
      if (e.response && e.response.data.type === 'sheet-time-overlapse') {
        setOverlappingModal({
          reason: e.response.data.message,
          data: e.response.data.data,
        });
      }
      if (e.response && e.response.data.type === 'timeoff-time-overlapse') {
        setInfoModal({ isOpen: true, text: e.response.data.message });
      }
    }
  };

  const handleDeleteSheet = () => {
    removeSheet().then(() => {
      mobileViewSheetsRefresh && mobileViewSheetsRefresh();
      setIsDeleteModalOpen(false);
    });
  };

  const handleFailedUncancel = () => {
    closeOverlappingModal();
    runCorrespondAction();
  };

  const cancelSheet = (text) => {
    try {
      dispatch(patchCancelWorklog(sheet._id, { cancelNote: text }));
      closeModal();
      dispatch(updateSheetAddResource(sheet._id, 1));
    } catch (e) {
      console.log(e);
    }
  };

  const hasSubmitAccess = () => {
    const userId = user._id;
    const isAssignedWorker = sheet.workers.some((worker) => worker._id === userId);
    const isFieldTechnician = checkUserRole('Field Technician');

    if (isFieldTechnician && !isAssignedWorker) {
      return Boolean(sheet.submissions && sheet.submissions.length);
    }
    return Boolean((sheet.submissions && sheet.submissions.length) || hasAccess);
  };

  const openUserWorklogsModal = (workerId, workerHours, worker) => async () => {
    const res = await userWorklogsDayHours(sheet._id, workerId);
    setUserWorklogsModal({ ...res, extendedWorker: worker });
  };

  const closeUserWorklogsModal = () => {
    setUserWorklogsModal(null);
  };

  const openEquipWorklogsModal = (equipId, workerHours, equipment) => async () => {
    const res = await userWorklogsDayHours(sheet._id, equipId);
    setEquipWorklogsModal({ ...res, extendedWorker: equipment });
  };

  const closeEquipWorklogsModal = () => {
    setEquipWorklogsModal(null);
  };

  const getStartTime = () => {
    if (showIndividualStartTime) {
      const workerId = user._id;
      const worker = sheet.workers.find(({ _id }) => _id === workerId);
      if (worker && worker.startTime) {
        return worker.startTime;
      }
    }
    return sheet.startTime;
  };

  const isFirstElementInList = isFirstInList();
  const isLastElementInList = isLastInList();
  const startTime = getStartTime();

  return (
    <>
      <Drawer
        anchor="right"
        open
        PaperProps={{
          classes: {
            root: classes.fixSafariOverflow,
          },
        }}
      >
        <div
          className={classnames(classes.formWrapper, isSchedule && classes.paddingBottom50)}
          role="presentation"
        >
          {loading ? (
            <PageLoader loading>
              <div />
            </PageLoader>
          ) : (
            <Grid container spacing={2}>
              {!isSchedule && !isFirstElementInList && (
                <div className={classnames(classes.nav, classes.navLeft)} onClick={goToPrevSheet}>
                  <KeyboardArrowLeft className={classes.navIcon} />
                </div>
              )}
              {!isSchedule && !isLastElementInList && (
                <div className={classnames(classes.nav, classes.navRight)} onClick={goToNextSheet}>
                  <KeyboardArrowRight className={classes.navIcon} />
                </div>
              )}
              <DuplicateModalComponent />

              <GeneralInfoBlock
                openAdditionalDrawer={openAdditionalDrawer}
                hasAccess={hasAccess}
                sheet={sheet}
                user={user}
                close={close}
                openSnackbar={openSnackbar}
                setDuplicateModal={setDuplicateModal}
              />

              <Grid item xs={12}>
                <Divider />
              </Grid>

              <NotesBlock
                hasAccess={hasAccess}
                notes={sheet.notes}
                noteComments={sheet.noteComments}
                schedulerNotes={sheet.schedulerNotes}
                openAdditionalDrawer={openAdditionalDrawer}
                sheet={sheet}
              />

              <Grid item xs={12}>
                <Divider />
              </Grid>

              <WeatherBlock
                weather={sheet.weather}
                hasAccess={hasAccess}
                openAdditionalDrawer={openAdditionalDrawer}
              />

              <Grid item xs={12}>
                <Divider />
              </Grid>

              <TimeBlock
                getFormattedHoursRange={getFormattedHoursRange}
                travelTime={sheet.travelTime}
                startTime={startTime}
                openAdditionalDrawer={openAdditionalDrawer}
                hasAccess={hasAccess}
                hours={sheet.hours}
                sheet={sheet}
              />

              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={12} md={12}>
                <CrewBlock
                  workers={sheet.workers}
                  getFormattedHoursRange={getFormattedHoursRange}
                  openAdditionalDrawer={openAdditionalDrawer}
                  openUserWorklogs={openUserWorklogsModal}
                  hasAccess={hasAccess}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <EquipmentBlock
                  equipment={sheet.equipment}
                  getFormattedHoursRange={getFormattedHoursRange}
                  openAdditionalDrawer={openAdditionalDrawer}
                  // openUserWorklogs={openEquipWorklogsModal}
                  hasAccess={hasAccess}
                />
              </Grid>
              {Boolean(sheet.workers.length || sheet.equipment.length) &&
                hasAccess &&
                !checkUserRole('Field Technician') && (
                  <Grid item xs={12} className={classes.textCenter}>
                    <Button
                      variant="text"
                      className={classes.deleteButton}
                      onClick={clearAllResourcesModal}
                      disableRipple
                    >
                      <img src={CloseIcon} alt="delete" />
                      Clear all resources
                    </Button>
                  </Grid>
                )}

              <Grid item xs={12}>
                <Divider />
              </Grid>
              {sheet.published && hasSubmitAccess() && (
                <>
                  <Grid item xs={12}>
                    <SubmitSection
                      submissions={sheet.submissions || []}
                      sheet={sheet}
                      openSnackbar={openSnackbar}
                      hasAccess={hasAccess}
                      user={user}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </>
              )}

              <MaterialsBlock
                openAdditionalDrawer={openAdditionalDrawer}
                sheet={sheet}
                openSnackbar={openSnackbar}
                hasAccess={hasAccess}
              />

              {isSchedule && hasPermissionsFor('worklogsFullAccess') && (
                <div className={classes.bottomPanel}>
                  <Button
                    variant="text"
                    className={classes.scheduleFuncBtn}
                    onClick={runCorrespondAction}
                    disableRipple
                  >
                    {!sheet.published && <img src={CloseIcon} alt="close" />}
                    {getScheduleFuncText()}
                  </Button>
                </div>
              )}
            </Grid>
          )}
        </div>
      </Drawer>

      {isModalOpen && (
        <ConfirmDialog
          isOpen={isModalOpen}
          onClose={closeModal}
          onSubmit={submitModal}
          text="The contractor is deactivated. Please, make sure you assign the worklog to an active contractor."
          cancelBtn="Cancel"
          confirmBtn="Ok"
        />
      )}
      {isDeleteModalOpen && (
        <ConfirmDialog
          isOpen={isDeleteModalOpen}
          onClose={closeModal}
          onSubmit={handleDeleteSheet}
          text="Are you sure you want to remove this project from the schedule?"
          loadingOnSubmit
        />
      )}
      {isCancelModalOpen && (
        <CancelModal isOpen={isCancelModalOpen} onClose={closeModal} onSubmit={cancelSheet} />
      )}
      {overlappingModal && (
        <OverlappingModal
          data={overlappingModal.data || []}
          message={overlappingModal.reason || ''}
          onClose={closeOverlappingModal}
          onSave={handleFailedUncancel}
        />
      )}
      {userWorklogsModal && (
        <ChangeTimeModal
          data={userWorklogsModal}
          onClose={closeUserWorklogsModal}
          save={closeUserWorklogsModal}
        />
      )}
      {/* {equipWorklogsModal && (
        <ChangeEquipTimeModal
          data={userWorklogsModal}
          onClose={closeUserWorklogsModal}
          save={closeUserWorklogsModal}
        />
      )} */}
      {infoModal.isOpen && (
        <AlertModal isOpen={infoModal.isOpen} info={infoModal.text} onClose={closeInfoModal} />
      )}
      {confirmModal.isOpen && confirmModal.type === 'resources' && (
        <ConfirmDialog
          isOpen={confirmModal.isOpen}
          onClose={closeModal}
          onSubmit={clearAllResources}
          text={confirmModal.text}
        />
      )}
    </>
  );
};

export default WorklogDetails;
