import React, { useEffect, useState, useMemo } from 'react';
import propTypes from 'prop-types';
import $api from 'http/index';
import { getOptions } from 'helpers/getOptions';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { parse, format, addDays, endOfDay } from 'date-fns';
import cn from 'classnames';
import PageLoader from '../../../../components/PageLoader';
import HolidayDetails from './components/HolidayDetails';
import styles from './styles';
import amStyles from '../AvailabilityCalendar/components/AssignModal/styles';
import { useForm } from 'react-hook-form';
import AssignModal from './components/AssignModal';
import AddIcon from '@material-ui/icons/Add';
import DialogActions from '@material-ui/core/DialogActions';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';
import { getNextDay } from 'components/Kiosk/helpers/getNextDay';
import { DAY_PART, globalBEDateFormat } from 'common/dateFormatConfig';
import DatePicker from 'components/DatePicker/DatePicker';
import { validateEndAfterStart } from 'components/Kiosk/helpers/validate';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';
import { useSettingsDefaultTime } from 'common/useSettingsDefaultTime';

const useAMStyles = makeStyles(amStyles);

const HolidayModal = ({
  classes,
  closeModal,
  holiday,
  defaultIndividual,
  openSnackbar,
  toggleRefreshHoliday,
}) => {
  const { dateFormat } = useDateSettingsFormat();
  const getTimeFormatHook = useTimeSettingsFormat();
  const { defaultDayTime } = useSettingsDefaultTime();

  const [selectedResources, setSelectedResources] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [assignModalOpened, setAssignModalOpened] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const defaultTimeState = useMemo(() => ({
    hour: `${((+defaultDayTime?.hour) % 12) || 12}` || '8',
    minute: `${defaultDayTime?.minute}` || '00',
    amPm: ((+defaultDayTime?.hour) > 12 ? DAY_PART[1] : DAY_PART[0]) || DAY_PART[0],
  }), [defaultDayTime]);
  const [calendarOpenedForInput, setCalendarOpenedForInput] = useState(-1);
  const [selectedTime, setSelectedTime] = useState([
    {
      date: format(new Date(), dateFormat),
      time: defaultTimeState,
    },
    {
      date: format(addDays(new Date(), 1), dateFormat),
      time: defaultTimeState,
    },
  ]);
  const selectedDates = useMemo(() => {
    return selectedTime.map((t) => t.date);
  }, [selectedTime]);

  useEffect(() => {
    if (holiday && holiday.type === 'period') {
      const startHours = +holiday?.startTime?.hour ?? 8;
      const startMinutes = +holiday?.startTime?.minute || 0;
      const endHours = +holiday?.endTime?.hour ?? 8;
      const endMinutes = +holiday?.endTime?.minute || 0;

      setSelectedTime([{
        date: format(new Date(holiday?.utcStartDate), dateFormat),
        time: {
          hour: `${(startHours % 12) || 12}` || '8',
          minute: `${startMinutes}`.padStart(2, '0') || '00',
          amPm: (((+startHours) >= 12) ? DAY_PART[1] : DAY_PART[0]) || DAY_PART[0],
        }
      }, {
        date: format(new Date(holiday?.utcEndDate), dateFormat),
        time: {
          hour: `${(endHours % 12) || 12}` || '8',
          minute: `${endMinutes}`.padStart(2, '0') || '00',
          amPm: (((+endHours) >= 12) ? DAY_PART[1] : DAY_PART[0]) || DAY_PART[0],
        }
      }])
    } else if (holiday) { // holiday.type === 'oneDay'
      setSelectedTime([
        { ...selectedTime[0], date: format(new Date(holiday?.utcStartDate), dateFormat) },
        { ...selectedTime[1], date: format(addDays(new Date(holiday?.utcEndDate), 1), dateFormat) },
      ])
    }
  }, [holiday])

  const toggleCalendar = (value = -1) => setCalendarOpenedForInput(value);

  const handleDateSelect = (date, options) => {
    if (options.disabled) return;
    const formattedDate = format(date, dateFormat);

    setSelectedTime((prev) => [
      {
        ...prev[0],
        date: 0 === calendarOpenedForInput ? formattedDate : prev[0].date,
      },
      {
        ...prev[1],
        date:
          1 === calendarOpenedForInput
            ? formattedDate
            : getNextDay(parse(formattedDate, dateFormat, new Date()), dateFormat),
      },
    ]);
  };

  const handleDateReset = () => {
    setSelectedTime((prev) => [
      {
        date: format(new Date(), dateFormat),
        time: defaultTimeState,
      },
      {
        date: format(addDays(new Date(), 1), dateFormat),
        time: defaultTimeState,
      },
    ]);
  };

  const {
    getValues,
    setValue,
    formState: { errors },
    control,
    watch,
    handleSubmit,
    setError,
    clearErrors,
    register,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      individual: defaultIndividual ? 'true' : 'false',
      endDate: format(addDays(new Date(), 1), dateFormat),
    },
  });
  const watchHolidayType = watch('holidayType');
  const watchIndividual = watch('individual');
  const amClasses = useAMStyles();

  const handleChangeHolidayType = () => {
    const holidayType = getValues().holidayType;

    if (holidayType === 'oneDay') {
      clearErrors();
      toggleCalendar(-1);
    }

  };
  useEffect(() => {
    const holidayType = getValues().holidayType;

    if (!holidayType || holidayType === 'oneDay') {
      clearErrors();
      return;
    }
    const dataForValidation = {
      startDate: selectedTime[0].date,
      startHours: selectedTime[0]?.time?.hour,
      startMinutes: selectedTime[0].time.minute,
      startAmPm: selectedTime[0].time.amPm,
      endDate: selectedTime[1].date,
      endHours: selectedTime[1]?.time?.hour,
      endMinutes: selectedTime[1].time.minute,
      endAmPm: selectedTime[1].time.amPm,
    };
    validateEndAfterStart(dataForValidation, setError, clearErrors, getTimeFormatHook);
  }, [selectedTime]);

  useEffect(() => {
    if (!holiday || initialized) return;
    setTimeout(() => {
      setValue('holidayType', holiday.type);
      setValue('name', holiday.name);
      setValue('isPaid', holiday.isPaid);
      setValue(
        'startDate',
        format(parse(holiday.startDate, globalBEDateFormat, new Date()), dateFormat)
      );
      // setValue('startDate', holiday.startDate);
      setValue(
        'endDate',
        format(parse(holiday.endDate, globalBEDateFormat, new Date()), dateFormat)
      );
      // setValue('endDate', holiday.endDate);
      setValue('hours', holiday.hours || '8');
      setValue('minutes', holiday.minutes || '00');
      setValue('individual', holiday.isIndividual ? 'true' : 'false');
      setSelectedResources((holiday.userIds || []).map((id) => ({ id })));
    }, 0);
    setInitialized(true);
  }, [holiday, initialized]);

  useEffect(() => setSelectedResources([]), [watchIndividual]);

  const setSubmitting = (d) => setIsSubmitting(d);

  useEffect(() => {
    if (isSubmitting) {
      handleSubmitL(isSubmitting);
    }
  }, [isSubmitting]);

  const handleSubmitL = async (values) => {
    const start = parse(selectedTime[0].date, dateFormat, new Date());
    let end = parse(selectedTime[1].date, dateFormat, new Date());

    if (values.holidayType === 'oneDay') {
      end = endOfDay(start);
    } else { // period
      const startTimeHours = (+selectedTime[0].time.hour % 12) + (selectedTime[0].time.amPm === 'PM' ? 12 : 0) % 24;
      const startTimeMinutes = (+selectedTime[0].time.minute);
      start.setHours(startTimeHours);
      start.setMinutes(startTimeMinutes);

      const endTimeHours = (+selectedTime[1].time.hour % 12) + (selectedTime[1].time.amPm === 'PM' ? 12 : 0) % 24;
      const endTimeMinutes = (+selectedTime[1].time.minute);
      end.setHours(endTimeHours);
      end.setMinutes(endTimeMinutes);
    }
    
    const data = {
      isIndividual: values.individual === 'true',
      name: values.name,
      isPaid: values.isPaid,
      utcStartDate: start.toISOString(),
      utcEndDate: end.toISOString(),
      type: values.holidayType,
    };

    if (values.holidayType === 'oneDay') {
      data.hours = +values.hours;
      data.minutes = +values.minutes;
    }

    data.userIds = selectedResources.map((r) => r.id) || [];

    if (holiday) {
      try {
        await $api.put(
          `${process.env.REACT_APP_BASE_URL}/holidays/${holiday._id}`,
          data,
          getOptions()
        );
        toggleRefreshHoliday();
        openSnackbar('success', 'Holiday was updated successfully');
        closeModal();
      } catch (e) {
        openSnackbar('error', e?.response?.data?.message || e.message);
      }
    } else {
      try {
        await $api.post(`${process.env.REACT_APP_BASE_URL}/holidays`, data, getOptions());
        toggleRefreshHoliday();
        openSnackbar('success', 'Holiday was created successfully');
        closeModal();
      } catch (e) {
        openSnackbar('error', e?.response?.data?.message || e.message);
      }
    }
    setIsSubmitting(false);
  };

  const openAssignModal = () => setAssignModalOpened(true);
  const closeAssignModal = () => setAssignModalOpened(false);

  const canSubmit =
    Object.keys(errors).length === 0 &&
    (watchIndividual !== 'true' || selectedResources.length !== 0);

  return (
    <Dialog
      open
      onClose={closeModal}
      PaperProps={{
        classes: {
          root: cn(classes.dialogRoot, classes.holiday),
        },
      }}
      backdropclick
      disableEscapeKeyDown
    >
      <div className={classes.formWrapper} role="presentation">
        <PageLoader loading={!!isSubmitting} small>
          <form autoComplete="off" className={classes.formFlex}>
            <div>
              <Typography className={classes.headerText}>
                {holiday ? 'Edit' : 'Add'} Holiday/Company Time Off
              </Typography>

              <IconButton
                className={classes.closeButton}
                onClick={closeModal}
                aria-label="Close"
                disableTouchRipple
              >
                <CloseIcon />
              </IconButton>

              <HolidayDetails
                errors={errors}
                handleChangeHolidayType={handleChangeHolidayType}
                control={control}
                getValues={getValues}
                setValue={setValue}
                watch={watch}
                selectedTime={selectedTime}
                setSelectedTime={setSelectedTime}
                toggleCalendar={toggleCalendar}
                setError={setError}
                clearErrors={clearErrors}
              />
            </div>

            {
              <div
                className={cn(
                  classes.assignHolder,
                  watchHolidayType === 'period' ? classes.assignHolderSm : ''
                )}
              >
                <Button
                  color="primary"
                  variant="text"
                  className={cn(amClasses.extraBtn, amClasses.assignBtn)}
                  onClick={openAssignModal}
                  disableTouchRipple
                >
                  <AddIcon /> {watchIndividual === 'false' ? 'Exclude' : 'Assign'} People
                </Button>
                <Typography color="secondary" className={classes.selectedText}>
                  {selectedResources.length ? `${selectedResources.length} people selected` : ''}
                </Typography>
              </div>
            }

            <DialogActions classes={{ root: amClasses.rootActions }}>
              <Button
                color="secondary"
                variant="outlined"
                className={amClasses.cancelBtn}
                onClick={closeModal}
                disabled={!!isSubmitting}
                disableTouchRipple
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="primary"
                variant="outlined"
                className={amClasses.confirmBtn}
                onClick={handleSubmit(setSubmitting)}
                disabled={!!isSubmitting || !canSubmit}
                disableTouchRipple
              >
                Save
              </Button>
            </DialogActions>
          </form>
        </PageLoader>
      </div>
      {assignModalOpened && (
        <AssignModal
          selectedResources={selectedResources}
          setSelectedResources={setSelectedResources}
          handleClose={closeAssignModal}
          small={watchHolidayType === 'period'}
          isIndividual={watchIndividual === 'true'}
        />
      )}
      {calendarOpenedForInput >= 0 && (
        <div className={classes.rightSidebar}>
          <DatePicker
            // showPeriod={0} // calendarOpenedForInput === 1
            showPeriod={calendarOpenedForInput === 1} // calendarOpenedForInput === 1
            startValue={parse(selectedDates[0], dateFormat, new Date())}
            value={parse(selectedDates[calendarOpenedForInput], dateFormat, new Date())}
            toggleCalendar={toggleCalendar}
            handleSelect={handleDateSelect}
            handleReset={handleDateReset}
            normalPeriodMode={true}
          />
        </div>
      )}
    </Dialog>
  );
};

HolidayModal.propTypes = {
  classes: propTypes.object.isRequired,
  closeModal: propTypes.func.isRequired,
  openSnackbar: propTypes.func.isRequired,
};

export default withStyles(styles)(HolidayModal);
