export const weekDaysOptions = ['Mon-Fri', 'Sun-Thu', 'Tue-Sat'];

export const USER_TIME_OF_DAY = {
  day: 'Day',
  night: 'Night',
  inactive: 'Inactive',
};

export const USER_WEEK_DAYS_OPTIONS = {
  monFri: 'Mon-Fri',
  sunThu: 'Sun-Thu',
  tueSat: 'Tue-Sat',
};

export const USER_WEEK_DAYS_RANGE = {
  [USER_WEEK_DAYS_OPTIONS.monFri]: [1, 2, 3, 4, 5],
  [USER_WEEK_DAYS_OPTIONS.sunThu]: [0, 1, 2, 3, 4],
  [USER_WEEK_DAYS_OPTIONS.tueSat]: [2, 3, 4, 5, 6],
};
