import React, { Component } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Autocomplete from 'react-autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import classnames from 'classnames';

import { getEquipmentColorClass } from 'helpers/_helpers';
import styles from './styles';

class SearchForm extends Component {
  state = {
    value: '',
  };

  onChangeInput = (e) => {
    this.setState({ value: e.target.value });
  };

  onSelectValue = (e, item) => {
    this.setState({ value: '' });
    this.props.selectItem(item.id);
    this.input.focus();
    this.input.blur();
  };

  renderItemsCondition = (item, value) => item.label.toLowerCase().indexOf(value.toLowerCase()) > -1;

  getEquipmentInfo = (field, item) => {
    if (!item) return '';
    if (field === 'number') {
      return parseInt(item.name);
    }
    if (field === 'className') {
      return getEquipmentColorClass(item.color);
    }
    return item[field];
  };

  itemRender = (item, highlighted) => {
    const { classes, type, usedItems, unavailableResources } = this.props;
    const isUsedResource = usedItems.find((r) => r._id ===  item._id);
    // const isUnaviable = unavailableResources.find((r) => r.resourceId === item.id);
    return type === 'people' ? (
      <div
        className={classnames(
          classes.userWrapper,
          highlighted && classes.highlighted,
          isUsedResource && classes.usedResource,
          // isUnaviable && classes.unavailableResource,
          classes[item.info.profile.shifts.timeOfDay.toLowerCase()]
        )}
        key={item.id}
      >
        {item.label}
      </div>
    ) : (
      <div
        className={classnames(
          classes.userWrapper,
        //   isUnaviable && classes.unavailableResource,
          highlighted && classes.highlighted
        )}
        key={item.id}
      >
        <div
          className={classnames(
            classes.equipmentBlock,
            isUsedResource && classes.usedResource,
          //   isUnaviable && classes.unavailableResource,
            this.getEquipmentInfo('className', item.info)
          )}
          style={{ backgroundColor: this.getEquipmentInfo('color', item.info) }}
        >
          <div className={classes.equipmentNumber}>{item.label}</div>
        </div>
      </div>
    );
  };

  menuRender = (items, value, style) => {
    const menuStyle = {
      border: '1px solid #a4a5b3',
      borderRadius: '5px',
      boxShadow: 'none',
      background: '#fff',
      padding: '2px 0',
      fontSize: '100%',
      position: 'fixed',
      overflow: 'auto',
      maxHeight: '35%',
      zIndex: '2500',
      left: 15,
      minWidth: style.minWidth ? style.minWidth + 42 : 0,
    };
    return items.length ? <div style={{ ...style, ...menuStyle }}>{items}</div> : <div />;
  };

  render() {
    const { value } = this.state;
    const { classes, list, type } = this.props;

    return (
      <Autocomplete
        ref={(el) => (this.input = el)}
        items={list}
        shouldItemRender={this.renderItemsCondition}
        getItemValue={(item) => item.label}
        renderItem={this.itemRender}
        renderMenu={this.menuRender}
        value={value}
        onChange={this.onChangeInput}
        onSelect={this.onSelectValue}
        inputProps={{ placeholder: `Search for ${type}` }}
        wrapperProps={{ style: { display: 'block' } }}
        renderInput={(props) => (
          <TextField
            {...props}
            inputRef={(el) => props.ref(el)}
            type="text"
            variant="outlined"
            className={classes.search}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                </InputAdornment>
              ),
            }}
            fullWidth
          />
        )}
      />
    );
  }
}

SearchForm.propTypes = {
  classes: propTypes.object.isRequired,
  list: propTypes.array.isRequired,
  type: propTypes.string.isRequired,
  selectItem: propTypes.func.isRequired,
  usedItems: propTypes.array.isRequired,
  unavailableResources: propTypes.array.isRequired,
};

export default withStyles(styles)(SearchForm);
