import React, { useState, useRef, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import classnames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import styles from './styles';
import UploadIcon from 'assets/images/upload-icon.svg';
import { withStyles } from '@material-ui/core/styles';
import ImageCard from './components/ImageCard/ImageCard';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

const ImagesBlock = ({ classes, hasAccess }) => {
  const sheet = useSelector((store) => store.currentWorklog.worklogSheet.sheet);
  const fileInputRef = useRef(null);

  const [picturesToSend, setPicturesToSend] = useState([]);
  const [randomKey, setRandomKey] = useState(Math.random().toString(36));

  const handleCustomButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const selectedFiles = await Array.from(event.target.files);

    const uploadedImageUrls = selectedFiles.map((file) => ({
      img: `${URL.createObjectURL(file)}`,
      size: file.size,
      file: file,
      localId: uuidv4(),
    }));
    setPicturesToSend([...picturesToSend, ...uploadedImageUrls]);
    setRandomKey(Math.random().toString(36));
  };

  return (
    <>
      {hasAccess && <Grid container alignItems="center" xs={12} md={12}>
        <IconButton
          className={classes.btn}
          disableRipple
          disableTouchRipple
          onClick={handleCustomButtonClick}
        >
          <img src={UploadIcon} alt="upload" />
        </IconButton>
        <input
          type="file"
          accept="image/*"
          multiple
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
          key={randomKey}
        />
        <Typography
          fontWeight={'fontWeightBold'}
          color="primary"
          className={classnames(classes.bold, classes.uploadBtn)}
          onClick={handleCustomButtonClick}
        >
          Upload
        </Typography>
      </Grid>}
      <Grid container xs={12} md={12}>
        {sheet?.images?.map((image, i) => (
          <Grid item key={image?.img || image._id} className={classes.cardWrapper}>
            <ImageCard
              data={image}
              index={i}
              sheetId={sheet._id}
              sheetImages={sheet?.images}
              setPicturesToSend={setPicturesToSend}
              picturesToSend={picturesToSend}
              hasAccess={hasAccess}
            />
          </Grid>
        ))}
        {picturesToSend
          .filter(
            (sendImage) => (sheet?.images || [])?.every((sheetImage) => sendImage.localId !== sheetImage._id)
          )
          .map((image, i) => (
            <Grid item key={image?.img || image._id} className={classes.cardWrapper}>
              <ImageCard
                data={image}
                index={i}
                sheetId={sheet._id}
                sheetImages={sheet?.images}
                setPicturesToSend={setPicturesToSend}
                picturesToSend={picturesToSend}
                hasAccess={hasAccess}
              />
            </Grid>
          ))}
      </Grid>
    </>
  );
};

export default withStyles(styles)(ImagesBlock);
