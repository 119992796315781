import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { Button, Typography } from '@material-ui/core';
import ExitModal from '../ExitModal';
import HeaderLogo from 'assets/images/header-logo1.svg';
import { ReactComponent as LogoutIcon } from 'assets/images/logout-i.svg';

const useStyles = makeStyles(styles);

const TopBar = () => {
  const classes = useStyles();
  const [modalVisible, setModalVisible] = useState(false);
  const companyName = useSelector((state) => state.kiosk?.company?.name || '');

  const handleExit = () => setModalVisible(true);
  const handleModalClose = () => setModalVisible(false);

  return (
    <nav className={classes.root}>
      <div className={classes.headerLogoBlockWrapper}>
        <div className={classes.headerLogoWrapper}>
          <img className={classes.headerLogo} src={HeaderLogo} alt="logo" />
        </div>
        <Typography variant="subtitle1" className={classes.companyName}>
          {companyName}
        </Typography>
      </div>
      <div className={classes.exitWrapper}>
        <Button variant="text" className={classes.exit} onClick={handleExit}>
          <LogoutIcon className={classes.exitIcon} />
          Exit Kiosk Mode
        </Button>
      </div>
      {modalVisible && <ExitModal onClose={handleModalClose} />}
    </nav>
  );
};
export default TopBar;
