import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import PageLoader from 'components/PageLoader';
import SettingsHeader from './components/SettingsHeader';
import SettingsForm from './components/SettingsForm';
import styles from './styles';
import { useTimeStates } from 'hooks/useTimeStates';
import { useForm, FormProvider } from 'react-hook-form';
import { getSettings, patchSettings } from 'store/settings/settingsOperations';
import { filterDataByTab } from './settings-requests.helper';
import SnackBar from 'components/SnackBar';
import OptionalModal from 'components/OptionalModal/OptionalModal';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';
import { convertTimeObject } from 'common/dateFormatConfig';

const options = {
  title: 'Are you sure you want to exit without changes?',
  text: '',
  cancelBtnText: 'Discard',
  saveBtnText: 'Save',
};

const SettingsPage = ({ classes }) => {
  const getTimeFormatHook = useTimeSettingsFormat();
  const is12Format = getTimeFormatHook().is12Format;
  const [pageType, setPageType] = useState('edit');
  const [submitting, setSubmitting] = useState(false);
  const { hours, minutes, dayPart } = useTimeStates({ takeSettingsHours: true });
  const [tabValue, setTabValue] = useState(0);
  const [modal, setModal] = useState({
    isOpen: false,
    route: null,
  });
  const dispatch = useDispatch();
  const isMobile = useMediaQuery('(max-width: 999px)');

  const settings = useSelector((state) => state.settings.settings);
  const defaultSettings = useMemo(
    () => ({
      ...settings,
      homePageQuote: settings?.homepage?.quote || '',
      homePageQuoteColor: settings?.homepage?.quoteColor || '',
      countdownDate: settings?.homepage?.countdownDate || '',
      countdownTitle: settings?.homepage?.countdownTitle || '',
      countdownEnabled: settings?.homepage?.countdownEnabled || false,
      defaultDayTime: convertTimeObject(
        settings?.defaultDayTime || { hour: '06', minute: '00' },
        is12Format
      ),
      defaultNightTime: convertTimeObject(
        settings?.defaultNightTime || { hour: '1', minute: '00' },
        is12Format
      ),
    }),
    [settings]
  );
  const methods = useForm({
    mode: 'onBlur',
    defaultValues: defaultSettings || {},
    shouldUnregister: false,
  });
  const {
    formState: { errors },
    handleSubmit,
    getValues,
    control,
    reset,
    onChange,
    formState: { isDirty },
  } = methods;
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    text: '',
    type: 'success',
  });

  const openSnackbar = useCallback((type, text) => setSnackbar({ isOpen: true, type, text }), []);
  const closeSnackbar = useCallback(() => setSnackbar((p) => ({ ...p, isOpen: false })), []);

  useEffect(() => {
    dispatch(getSettings());
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = '';
    };
    isDirty && window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDirty]);

  useEffect(() => {
    if (!settings?.homepage) return;
    reset(defaultSettings);
  }, [settings, reset]);

  const handleChange = (event, newValue) => {
    if (isDirty) {
      setModal((prev) => ({ ...prev, isOpen: true, route: newValue }));
    } else {
      setTabValue(newValue);
    }
  };

  const closeEdit = () => {
    setSubmitting(false);
  };

  const onSubmit = async () => {
    const values = getValues();

    const result = filterDataByTab(tabValue, values, settings);

    setSubmitting(true);

    await dispatch(patchSettings(result))
      .then((e) => {
        openSnackbar('success', 'Successfully Saved!');
        closeEdit();
        setModal((prev) => ({ ...prev, isOpen: false, route: null }));
      })
      .catch((e) => openSnackbar('error', e?.response?.data?.message || e.message));
  };

  if (!settings) return null;

  return (
    <div className={classes.root}>
      <SettingsHeader pageType={pageType} changePageType={setPageType} />
      <PageLoader loading={submitting}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} className={classes.formFlex}>
            <Grid container spacing={isMobile ? 2 : 5}>
              <SettingsForm
                pageType={pageType}
                control={control}
                hours={hours}
                minutes={minutes}
                dayPart={dayPart}
                errors={errors}
                settings={settings}
                onChange={onChange}
                tabValue={tabValue}
                handleChange={handleChange}
              />
            </Grid>
            {pageType === 'edit' && (
              <div className={classes.buttonHolder}>
                <Button
                  color="secondary"
                  variant="outlined"
                  className={classes.cancelButton}
                  onClick={closeEdit}
                  disabled={submitting}
                  disableTouchRipple
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  variant="outlined"
                  className={classes.saveButton}
                  disabled={submitting}
                  disableTouchRipple
                >
                  Save
                </Button>
              </div>
            )}
          </form>
        </FormProvider>
      </PageLoader>
      <SnackBar closeSnackbar={closeSnackbar} {...snackbar} />
      <OptionalModal
        isOpen={modal.isOpen}
        onClose={() => {
          setModal((prev) => ({ ...prev, isOpen: false }));
        }}
        onCancel={(e) => {
          closeEdit();
          reset(defaultSettings);
          setTabValue(modal?.route);
          setModal((prev) => ({ ...prev, isOpen: false }));
        }}
        onSave={() => {
          handleSubmit(onSubmit)();
          setTabValue(modal?.route);
        }}
        options={options}
      />
    </div>
  );
};

SettingsPage.propTypes = {
  classes: propTypes.object.isRequired,
};

export default withStyles(styles)(SettingsPage);
