export default (theme) => ({
  paddingBottom: {
    paddingBottom: 20,
  },
  loader: {
    fontSize: 14,
    paddingBottom: 10,
  },
  noResultsBlock: {
    padding: '1.72em 0 2em',
    opacity: 0.5,
    background: '#fff',
  },
  shiftImage: {
    height: 16,
    margin: '0 5px 2px 0',
  },
});
