import React, { useLayoutEffect, useState } from 'react';
import Typography from '../../../FieldLabel';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';

const CurrentTime = ({ classes }) => {
  const kioskTimeFormat = useSelector((store) => store.kiosk.company?.settings?.timeFormat);
  const getTimeFormatHook = useTimeSettingsFormat();
  const formatForTimeParsSec = getTimeFormatHook().formatForTimeParsSec;
  const getTime = () => {
    return format(new Date(), formatForTimeParsSec);
  };

  const [time, setTime] = useState(getTime());

  useLayoutEffect(() => {
    if (!kioskTimeFormat) {
      return setTime('');
    }
    setTime(getTime());
    const timer = setInterval(() => setTime(getTime()), 1000);
    return () => clearInterval(timer);
  }, [kioskTimeFormat]);

  return (
    <Typography variant="caption" className={classes.time}>
      {time}
    </Typography>
  );
};

export default CurrentTime;
