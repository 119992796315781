import React from "react";
import {Button} from "@material-ui/core";
import useStyles from "./useStyles";
import {ChevronRight} from "@material-ui/icons";

const EnterKioskButton = (props) => {
    const classes = useStyles()

    return <Button {...props} className={classes.root} variant={'text'} endIcon={<ChevronRight/>}>
        Enter kiosk mode
    </Button>
}

export default EnterKioskButton