export const getAdjacentUsers = (users, userWithIndex = {}) => {

    const { userIndex } = userWithIndex;

    const result = {
      prev: undefined,
      next: undefined,
    };

    if (userIndex > 0) result.prev = users[userIndex - 1];
    if (userIndex < users?.length) result.next = users[userIndex + 1];
    

    return result;
  }

  export const getBrowser = () => {
    const userAgent = navigator.userAgent;
    let browser = '';
  
    if (userAgent.indexOf('Chrome') > -1) {
      browser = 'Chrome';
    } else if (userAgent.indexOf('Safari') > -1) {
      browser = 'Safari';
    }
  
    return browser;
  };