export default (theme) => ({
  formWrapper: {
    padding: '20px 15px 0',
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
    },
    [theme.breakpoints.up('md')]: {
      width: 512, // temporary
    },
    [theme.breakpoints.up('lg')]: {
      width: 512, // temporary
    },
  },
  formFlex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '95vh',
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    right: '-5px',
    top: 0,
    padding: 8,
    '&:focus': {
      color: '#fff',
      background: 'rgba(0, 0, 0, 0.08)',
    },
  },
  buttonHolder: {
    textAlign: 'center',
  },
  cancelButton: {
    margin: 20,
    minWidth: 100,
    '&:hover': {
      color: 'inherit',
    },
    '&:focus': {
      color: 'inherit',
    },
    '&:disabled': {
      backgroundColor: '#fff',
    },
  },
  saveButton: {
    margin: 20,
    minWidth: 100,
    '&:hover': {
      color: theme.palette.textColor.primary,
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
    },
    '&:disabled': {
      backgroundColor: '#fff',
    },
  },
  postError: {
    color: '#e40909',
    padding: '35px 0 0',
    fontSize: 12,
    textAlign: 'center',
  },
  primaryTextButton: {
    padding: 4,
    minWidth: 'auto',
    marginLeft: '190px',
    color: theme.palette.textColor.primary,
    [theme.breakpoints.down('sm')]: {
      marginLeft: '100px',
    },
    '@media (max-width: 410px)': {
      marginLeft: '85px',
    },
    '@media (max-width: 375px) ': {
      marginLeft: '35px',
    },
    '@media (max-width: 340px) ': {
      marginLeft: '15px',
    },
    '&:hover': {
      color: theme.palette.textColor.primary,
      background: theme.palette.background.default
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
      background: theme.palette.background.default
    },
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'baseline',
  },
});
