import React, { useCallback, useState } from 'react';
import styles from './styles';
import { withStyles } from '@material-ui/core';
import cs from 'classnames';

const Input = React.forwardRef(
  ({ value, setValue, classes, className, label, error, setError, phoneNum, ...props }, ref) => {
    const [focused, setFocused] = useState(false);
    const [type, setType] = useState(props.type);
    const onFocus = useCallback((e) => {
      setFocused(true);
      props.onFocus && props.onFocus(e);
    }, []);

    const onBlur = useCallback((e) => {
      setFocused(false);
      props.onBlur && props.onBlur(e);
    }, []);

    const onShowClick = useCallback((e) => {
      e.nativeEvent.preventDefault();
      e.nativeEvent.stopPropagation();
      setType((t) => (t === 'password' ? 'text' : 'password'));
    }, []);

    return (
      <label className={cs(className, classes.root)}>
        <span
          className={cs(classes.label, {
            [classes.labelActive]: focused,
            [classes.labelError]: error,
          })}
        >
          {label}
        </span>
        <input
          value={value}
          {...props}
          type={type}
          className={cs(classes.input, {
            [classes.inputError]: error,
            [classes.inputNormal]: !error,
            [classes.inputFocus]: focused && !error,
          })}
          placeholder={phoneNum ? '(XXX) XXX-XXXX' : ''}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={(e) => {
            const { value } = e.target;
            setValue(value);

            if (error) {
              setError('');
            }
          }}
        />
        {props.type === 'password' && (
          <button className={classes.showBtn} onClick={onShowClick}>
            {type === 'password' ? 'Show' : 'Hide'}
          </button>
        )}
        {error && <div className={classes.textError}>{error}</div>}
      </label>
    );
  }
);

export default withStyles(styles)(Input);
