export default () => ({
  root: {
    width: 20,
    height: 20,
    cursor: 'pointer',
    textAlign: 'center',
    lineHeight: '16px',
    padding: 0,
    margin: 0,
    background: 'none',
    border: 'none',
    outline: 'none',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:focus,&:active,&:hover': {
      background: 'none',
      border: 'none',
    },
  },
});
