import React, { useState } from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@material-ui/core';
import { CloseButton } from 'pages/TimeClock/modals/base';
import PageLoader from 'components/PageLoader'
import { apiCalls } from '../../../../apiCalls';


export const InjuryReport = ({ classes, openSnackbar, onClose, onCancel, onSuccess }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState('');

  const onChange = (e) => {
    setMessage(e.target.value);
  }

  const handleSubmit = async () => {
    try {
      const body = {
        message,
      };

      setIsSubmitting(true);
      await apiCalls.injuryReport(body);

      setIsSubmitting(false);
      openSnackbar('success', `Injury reported`);
      onSuccess();
    } catch (e) {
      console.log(e);
      openSnackbar('error', e?.response?.data?.message || e.message);
    }
  }

  return (
    <PageLoader loading={isSubmitting}>
      <DialogTitle
        disableTypography
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <Typography variant="h6">Clock Out</Typography>
        <CloseButton style={{ top: 16 }} onClick={onClose} />
      </DialogTitle>
      <DialogContent style={{ paddingLeft: '20px' }}>
        <Typography variant="body1" color="textSecondary">
          Please describe your injury and how it happened.
        </Typography>
        <TextField
          id="note"
          name="note"
          rowsMax={4}
          value={message}
          onChange={onChange}
          className={classes.textArea}
          variant="outlined"
          multiline
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onCancel}
          variant="outlined"
          color="primary"
          className={classes.cancelBtn}
          disableTouchRipple
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          className={classes.confirmBtn}
          disableTouchRipple
        >
          Submit
        </Button>
      </DialogActions>
    </PageLoader>
  );
};
