export default theme => ({
  root: {
    width: '100%',
    marginTop: 10,
    // overflowX: 'auto',
    border: 'none'
  },
  table: {
    minWidth: 650,
    // tableLayout: 'fixed',
    width: '100%',
    border: 'none'
  },
  zeroPadding: {
    position: 'relative',
    padding: 0,
    '&:last-child': {
      paddingRight: 0
    }
  },
  noBorder: {
    border: 'none'
  },
  greyBackground: {
    backgroundColor: theme.palette.background.lightGrey
  }
});
