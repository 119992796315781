import React from 'react';

import cs from 'classnames';
import DatePicker from './components/DatePicker';
import styles from './styles';
import { withStyles } from '@material-ui/core';


const ExportForm = ({ className, onChange, classes, value = {} }) => {
  return (
    <form className={cs(className, classes.root)}>
      <DatePicker
        value={value.period}
        className={classes.control}
        onChange={(period) => onChange({ ...value, period })}
      />
    </form>
  );
};

export default withStyles(styles)(ExportForm);
