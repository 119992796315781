import React from 'react'
import styles from './styles'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CloseIcon from 'assets/images/close-icon.svg'

const ManufacturerCard = ({ classes, onDelete = () => null, data, ...props }) =>
  <div className={classes.root} {...props}>
    <div className={classes.header}>
      <span className={classes.title}>{data.name}</span>
      <Button
        color="secondary"
        className={classes.deleteButton}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          onDelete(data)
        }}
        disableRipple
      >
        <img src={CloseIcon} alt="delete"/>
      </Button>
      <span className={classes.number}>#{data.supplierCode}</span>
    </div>
    <hr className={classes.divider}></hr>
    <div className={classes.body}>
      <dl className={classes.definitionList}>
        <dt className={classes.definitionTitle}>Publication 408:</dt>
        <dd className={classes.definitionDefinition}>{data.publication408 ? data.publication408 : 'N/A'}</dd>
        <dt className={classes.definitionTitle}>Designation:</dt>
        <dd className={classes.definitionDefinition}>{data.designation ? data.designation : 'N/A'}</dd>
      </dl>
    </div>
  </div>

export default withStyles(styles)(ManufacturerCard)
