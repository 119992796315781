import React from 'react';
import { Label } from '../../../base';
import cs from 'classnames';
import DatePicker from './components/DatePicker';
import styles from './styles';
import { withStyles } from '@material-ui/core';
import UserPicker from './components/UserPicker';

const ExportForm = ({ className, onChange, classes, value = {}, chooseUser = true }) => {
  return (
    <form className={cs(className, classes.root)}>
      <DatePicker
        value={value.period}
        className={classes.control}
        onChange={(period) => onChange({ ...value, period })}
      />
      {chooseUser && (
        <label className={classes.field}>
          <Label>Username:</Label>
          <UserPicker value={value.user} onChange={(user) => onChange({ ...value, user })} />
        </label>
      )}
    </form>
  );
};

export default withStyles(styles)(ExportForm);
