import authActions from './authActions';

export const getCurrentUser = () => async (dispatch) => {
  try {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Auth-Token': localStorage.getItem('authorization-token'),
      },
    };

    const id = localStorage.getItem('userId');
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/users/${id}`,{
      ...options
    }).then((res) =>
      res.json()
    );

    if (response.statusCode > 299) throw new Error(response.message);

    dispatch(authActions.setCurrentUser(response));

  } catch (e) {
    console.log(e);
  }
};
