export default (theme) => ({
  root: {
    border: '1px solid #D9DAE3',
    borderRadius: 4,
    backgroundColor: '#FDFDFF',
    // padding: 36,
    textAlign: 'center',
    color: '#6D7278',
    fontSize: 18,
    letterSpacing: '0.23px',
    lineHeight: '27px',
    marginTop: '15px',
    '@media (max-width: 999px)': {
      width: '650px',
      margin: '0 auto',
      // padding: '36px 0',
    },
    '@media (max-width: 680px)': {
      width: '500px',
    },
    '@media (max-width: 530px)': {
      width: 'auto',
      margin: 0,
    },
  },
  worklogWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  usernameGrid: {
    display: 'inline-block',
    margin: '0 10px 0 0',
    color: '#A4A5B3',
    fontFamily: 'Open Sans',
    fontSize: 14,
    fontWeight: 600,
    // lineHeight: 20,
    [theme.breakpoints.down('md')]: {
      display: 'block',
      marginBottom: 10,
    },
  },
  usernameGridIndex: {
    display: 'inline-block',
    margin: 0,
    fontWeight: 500,
    fontSize: 16,
    // lineHeight: 20,
    [theme.breakpoints.down('sm')]: {
      // display: 'none',
    },
  },
  usernameCode: {
    display: 'inline-block',
    margin: '0 10px 0 0',
    color: '#A4A5B3',
    fontFamily: 'Open Sans',
    fontSize: 14,
    fontWeight: 500,
    [theme.breakpoints.down('md')]: {
      display: 'block',
      marginBottom: 10,
      marginRight: 0,
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: 35,
    },
  },
  worklogUsername: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    [theme.breakpoints.down('md')]: {
      marginBottom: 20,
    },
  },
  timeclockWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '75%',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      width: 'auto',
    },
  },
  worklogUsernameWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  timeclockWlHeader: {
    // border: '1px solid #d9dae3',
    borderRadius: 4,
    backgroundColor: '#ffffff',
    padding: '28px 16px',
  },
});
