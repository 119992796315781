import { useController } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import { isIOS, Option } from 'components/Option';
import { getContactName } from 'helpers/_helpers';
import ContactCreator from '../ContactCreator/ContactCreator';
import { useState } from 'react';

export const AlternateContacts = ({
  sheetDetailsClasses,
  control,
  currContractorContacts,
  CloseIcon,
  getValues,
  setCurrContractorContacts,
  setValue,
}) => {
  const {
    field: { onChange, value: contractorContactsIds },
  } = useController({
    name: 'alternateContacts',
    control,
    defaultValue: [''],
  });
  const [ContactCreatorForm, setContactCreatorForm] = useState(false);

  const handleSelectContact = (i) => (e) => {
    const { value } = e.target;

    if (value === 'addContact') {
      handleContactCreateForm(i);
      return;
    }
    const nextValue = contractorContactsIds.map((t, index) => (index === i ? value : t));
    onChange(nextValue);
  };

  const handleContactCreateForm = (value) => {
    if (value === false) {
      setContactCreatorForm(value);
      return;
    }
    setContactCreatorForm(value.toString());
  };

  const handleEmailRemoving = (i) => () => {
    const nextValue = contractorContactsIds.filter((_, index) => index !== i);
    if (!nextValue.length) {
      nextValue.push('');
    }
    onChange(nextValue);
  };

  return (
    <>
      {!!contractorContactsIds?.length &&
        contractorContactsIds.map((contactId, index) => (
          <Grid item xs={12} className={sheetDetailsClasses.zeroPaddingTop}>
            <label htmlFor="alternateContacts">
              <Typography variant="body1" color="textSecondary">
                {index === 0 ? 'Primary Contact' : `Alternate Contact ${index}`}:
              </Typography>
              <div className={sheetDetailsClasses.flexBox}>
                <TextField
                  select
                  id="alternateContacts"
                  className={sheetDetailsClasses.textField}
                  variant="outlined"
                  value={contactId}
                  onChange={handleSelectContact(index)}
                  inputProps={{ 'aria-label': 'alternateContacts' }}
                  SelectProps={{
                    native: isIOS,
                  }}
                  fullWidth
                >
                  <Option key={0} value=""></Option>
                  {currContractorContacts
                    .filter(
                      (contact) =>
                        !contractorContactsIds.find((cont) => contact._id === cont) ||
                        contact._id === contactId
                    )
                    .sort((a, b) => a.name?.localeCompare(b?.name))
                    .map((contact) => (
                      <Option key={`${contact._id}_${index}`} value={contact._id}>
                        {getContactName(contact)}
                      </Option>
                    ))}
                  <Option
                    key={'addContact'}
                    value="addContact"
                    className={sheetDetailsClasses.primary}
                  >
                    + Create New Contact
                  </Option>
                </TextField>
                <IconButton
                  className={sheetDetailsClasses.deleteLocationButton}
                  onClick={handleEmailRemoving(index)}
                  aria-label="Delete"
                  disableTouchRipple
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </label>
          </Grid>
        ))}
      <Grid item xs={12} className={sheetDetailsClasses.zeroPadding}>
        {ContactCreatorForm ? (
          <ContactCreator
            values={getValues()}
            setCurrContractorContacts={setCurrContractorContacts}
            setValue={setValue}
            setContactCreatorForm={setContactCreatorForm}
            contactIndex={ContactCreatorForm}//index of contact id slector's arr or false
          />
        ) : null}
      </Grid>
    </>
  );
};
