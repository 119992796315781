import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import StartTimeBlock from '../../../ResourcesForm/components/PeopleBlock/components/StartTimeBlock/StartTimeBlock';
import HoursEquipBlock from '../../../ResourcesForm/components/HoursEquipBlock/HoursEquipBlock';
import Button from '@material-ui/core/Button';
import { useStyles } from './useStyles';
import { isHoursOverlapseTimeOffs, isValidTimePeriods } from '../../../ResourcesForm/helpers';
import { useSelectedUserClasses } from '../../../ResourcesForm/components/PeopleBlock/components/SelectedUser/useStyles';
import { useCurrentUser } from '../../../Kiosk/hooks/useCurrentUser';
import { checkUserEditSheetPermissions } from 'helpers/_helpers';
import { useSelector, useDispatch } from 'react-redux';
import { getTimeoffs } from 'store/currentWorklog/operations';
import { useWeekStart } from 'hooks/useWeekStart';
import classnames from 'classnames';
import Divider from '@material-ui/core/Divider';
import { v4 as uuidv4 } from 'uuid';

export const TimeEquipForm = ({
  reset,
  update,
  sheetError,
  sheet,
  workerStartTime,
  onJobDate,
}) => {
  const { weekDayFromO } = useWeekStart();
  const dispatch = useDispatch();
  const selectedUserClasses = useSelectedUserClasses();
  const classes = useStyles();
  // const availableHours = useSelector((store) => store.currentWorklog.worklogSheet.timeOffs);
  const [data, setData] = useState({ start: null, onJob: null, error: null });
  // const [availableHours, setAvailableHours] = useState(null);
  const [canEdit, setCanEdit] = useState(false);

  useEffect(() => {
    const getCanEdit = async () => {
      const result = checkUserEditSheetPermissions(sheet, weekDayFromO);
      setCanEdit(result);
    };

    getCanEdit();
    dispatch(getTimeoffs(sheet._id));
  }, [sheet._id]);

  useEffect(() => {
    if (!onJobDate) return;
    setData((prev) => ({ ...prev, onJob: Array.isArray(onJobDate) ? onJobDate : [onJobDate] }));
  }, [onJobDate]);

  useEffect(() => {
    if (reset === null) return;
    setData((prev) => ({
      ...prev,
      onJob: Array.isArray(onJobDate) ? onJobDate : [onJobDate],
      start: workerStartTime,
    }));
  }, [reset]);

  useEffect(() => {
    if (!workerStartTime) return;
    setData((prev) => ({ ...prev, start: workerStartTime }));
  }, [workerStartTime]);

  useEffect(() => {
    update(data);
  }, [data]);

  const setError = (error) => setData((prev) => ({ ...prev, error }));

  // TODO: May be changed to validation with by calendar events
  // const adaptedAvailableHours = (equipId, availableHours) => {
  //   if (!availableHours) return null;
  //   const userTimeOffs = availableHours.filter((el) => el.userId === equipId)
  //   const res = userTimeOffs.map((timeOff) => ({
  //     start: new Date(timeOff.utcStartDate),
  //     end: new Date(timeOff.utcEndDate),
  //   }));
  //   return res;
  // };

  const validateHours = (hours) => {
    const isValidHours = sheet?.travelTimeOnly ? true : isValidTimePeriods(hours);
    if (!isValidHours) return setError('Time periods cross each other');
    else return setError(null);

    // const isOverlapsingTimeOff = isHoursOverlapseTimeOffs(
    //   hours,
    //   adaptedAvailableHours(equipId, availableHours),
    //   sheet
    // ); //! after refactoring => !result (from negative to positive)
    // const hasError = hours && isOverlapsingTimeOff;

    // setError(hasError ? 'User has timeoff at this time' : null);
  };

  const updateHours =
    (index) =>
    ({ error, ...updated }) => {
      if (error && !sheet?.travelTimeOnly) setError('Time periods cross each other'); //! is different data check
      const onJob = data.onJob.map((v, i) => (i === index ? updated : v));
      setData((prev) => ({
        ...prev,
        onJob,
      }));
      validateHours(onJob);
    };

  const deleteHours = (index) => () => {
    const onJob = data.onJob.filter((h, i) => i !== index);
    // const onJob = data.onJob
    //   .map((h, i) => (i === index ? { ...h, isWlHours: true } : h))
    //   .filter((el) => !(el.isWlTravelTime && el.isWlHours));

    setData((prev) => ({
      ...prev,
      onJob,
    }));
    validateHours(onJob);
  };
  const removeResourceTravelTime = (index) => () => {
    let onJob;
    if (sheet.travelTimeOnly) {
      onJob = data.onJob.filter((h, i) => i !== index);
    } else {
      onJob = data.onJob
        .map((h, i) => (i === index ? { ...h, isWlTravelTime: true } : h))
        .filter((el) => !(el.isWlTravelTime && el.isWlHours));
    }

    setData((prev) => ({
      ...prev,
      onJob,
    }));
  };

  const addResourceHours = (index) => {
    const moreTime = Array.isArray(onJobDate) ? onJobDate[0] : onJobDate;

    const { id, ...addedHours } = moreTime;
    const newAddHours = {
      ...addedHours,
      isWlHours: false,
      id: uuidv4(),
    };

    let updatedHours;
    if (index === 'new') {
      updatedHours = data.onJob ? [...data.onJob, newAddHours] : [newAddHours];
    } else if (index === 'full') {
      updatedHours = [
        ...data.onJob,
        {
          isWlHours: false,
          id: uuidv4(),
        },
      ];
    } else {
      updatedHours = data.onJob
        ? data.onJob.map((el, i) => (i === index ? { ...el, isWlHours: false } : el))
        : [newAddHours];
    }

    setData((prev) => ({ ...prev, onJob: updatedHours }));
    validateHours(updatedHours);
  };

  const isAnyCropped =
    !sheet.travelTimeOnly &&
    !!data?.onJob?.length &&
    data.onJob?.some((el) => el.isWlHours === true || el.isWlTravelTime === true);
  return (
    <>
      <Grid container spacing={2}>
        {data.onJob &&
          data.onJob.map((h, i) => (
            <>
              <Grid item xs={12} key={h.id + i}>
                <HoursEquipBlock
                  sheet={{ createdAt: sheet.createdAt, travelTimeOnly: sheet.travelTimeOnly }}
                  itemHours={h}
                  deleteTime={deleteHours(i)}
                  deleteTravelTime={removeResourceTravelTime(i)}
                  updateResourceHours={updateHours(i)}
                  reset={reset}
                  canEdit={canEdit}
                  isWorkersType
                  isAnyCropped={isAnyCropped}
                />
              </Grid>

              <Grid
                container
                item
                direction="row"
                xs={12}
                alignItems={'center'}
                justifyContent={'center'}
                className={classes.paddingBottom}
              >
                {!sheet.travelTimeOnly && h.isWlHours && (
                  <Grid item xs={4} md={3} className={classes.textCenter}>
                    <Button
                      variant="text"
                      onClick={() => addResourceHours(i)}
                      className={classes.addButton}
                      disableRipple
                      disableTouchRipple
                    >
                      + Add Hours
                    </Button>
                  </Grid>
                )}
              </Grid>
            </>
          ))}
        {!sheetError && Boolean(data.error) && (
          <div className={selectedUserClasses.error}>{data.error}</div>
        )}
        <Grid container>
          <Grid item xs={12} style={{ margin: '15px 0' }}>
            <Divider />
          </Grid>
        </Grid>
        {
          <Grid
            container
            item
            direction="row"
            xs={12}
            alignItems={'center'}
            justifyContent={'center'}
            className={classes.paddingBottom}
          >
            {!sheet.travelTimeOnly && !data?.onJob?.[data?.onJob?.length - 1]?.isWlHours && (
              <Grid item xs={4} md={3} className={classes.textCenter}>
                <Button
                  variant="text"
                  onClick={() => {
                    if (!data.onJob?.length) return addResourceHours('new');
                    addResourceHours(isAnyCropped ? 'full' : 'new');
                  }}
                  className={classes.addButton}
                  disableRipple
                  disableTouchRipple
                >
                  + Add Hours
                </Button>
              </Grid>
            )}
          </Grid>
        }
      </Grid>
    </>
  );
};
