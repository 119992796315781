import { IconButton } from '@material-ui/core';
import React, { memo, useCallback, useMemo } from 'react';
import propTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { SCHEDULE_TYPES } from '../../../SchedulePage/helpers/scheduleType';
import schedulePageActions from 'store/schedule/schedulePageActions';
import useStyles from './useStyles';
import grid from 'assets/images/grid-icon.svg'
import gridGrey from 'assets/images/grid-icon-grey.svg'
import time from 'assets/images/time-icon.svg'
import timeGrey from 'assets/images/time-icon-grey.svg'
import cl from 'assets/images/cl-icon.svg'
import clGrey from 'assets/images/cl-icon-grey.svg'

export const ScheduleViewSwitcher = memo(
  ({ scheduleType }) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const handleChange = useCallback(
      (type) => () => {
        if (type === scheduleType) return;
        dispatch(schedulePageActions.setScheduleView(type));
      },
      [scheduleType]
    );

    const buttons = useMemo(() => {
      return Object.keys(SCHEDULE_TYPES).map((type) => ({
        type: SCHEDULE_TYPES[type],
        selectedImg: `${type}`,
        notSelectedImg: `${type}Grey`,
      }));
    }, []);

    const buttonsI = {
      grid : grid,
      gridGrey: gridGrey,
      time : time,
      timeGrey: timeGrey,
      cl : cl,
      clGrey: clGrey,
    }

    return (
      <div>
        {buttons.map((button) => (
          <IconButton
            key={button.type}
            variant="text"
            color="secondary"
            className={classes.switchTypeBtn}
            onClick={handleChange(button.type)}
            disableTouchRipple
          >
            {scheduleType === button.type ? (
              <img alt={button.type} src={buttonsI[button.selectedImg]} />
            ) : (
              <img alt={button.type} src={buttonsI[button.notSelectedImg]} />
            )}
          </IconButton>
        ))}
      </div>
    );
  },
  (prevProps, nextProps) => prevProps.scheduleType === nextProps.scheduleType
);

ScheduleViewSwitcher.propTypes = {
  scheduleType: propTypes.string.isRequired,
};
