import React, { useState } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CloseI from 'assets/images/close-icon.svg';
import InputAdornment from '@material-ui/core/InputAdornment';
import classNames from 'classnames';
import { format } from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';
import { hasPermissionsFor, checkUserRole } from 'helpers/_helpers';
import ConfirmDialog from '../ConfirmDialog';
import EditCommentModal from './components/EditCommentModal';
import EditIcon from 'assets/images/edit-icon.svg';
import styles from './styles';
import photoPlaceholder from 'assets/images/user-photo-placeholder.svg';
import { postWorklogComment, deleteWorklogComment, putEditWorklogComment } from 'store/currentWorklog/operations';
import { GLOBAL_DATE_FORMAT } from 'common/dateFormatConfig';
import { useCanEditWorklog } from 'common/hooks/use-can-edit-worklog';
import { parseStringDate } from 'helpers/_date-helpers';

const CommentsForm = ({ classes, closeDrawer, insideNotes, openSnackbar, limiterW }) => {
  const [comment, setComment] = useState('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [commentInfo, setCommentInfo] = useState({
    sheetId: '',
    commentId: '',
    text: '',
  });

  const dispatch = useDispatch();
  const sheet = useSelector((store) => store.currentWorklog.worklogSheet.sheet);
  const user = useSelector((store) => store.personalProfile.user) || {};

  const formatDate = (date) =>{
    return format(parseStringDate(date), GLOBAL_DATE_FORMAT.fullDate)
  };

  const handleChange = (e) => setComment(e.target.value);

  const handleSubmit = async () => {
    if (!comment.trim()) {
      return;
    }
    try {
      await dispatch(postWorklogComment({ text: comment }, sheet._id));
      setComment('');
    } catch (e) {
      openSnackbar('error', e.message);
    }
  };

  const canEditWorklog = useCanEditWorklog(sheet);

  const canEditComment = (ownerId) => {
    const currentUserId = user._id;
    const canEditByRole = checkUserRole('Super Admin') || checkUserRole('Admin') || checkUserRole('Dispatcher');
    const isOwnComment = ownerId === currentUserId;
    return isOwnComment || canEditByRole;
  };

  const handleComment = async (commentText) => {
    const { sheetId, commentId, text } = commentInfo;
    closeModal();

    const textToUpdate = typeof commentText === 'string' ? commentText : text;

    if (!textToUpdate) {
      try {
        await dispatch(deleteWorklogComment(sheetId, commentId));
      } catch (e) {
        openSnackbar('error', e.message);
      }
      return;
    }

    try {
      await dispatch(putEditWorklogComment({text:textToUpdate},sheetId, commentId));
    } catch (e) {
      openSnackbar('error', e.message);
    }

  };

  const handleCommentHelper = (sheetId, commentId, text) => () => {
    const purpose = text.length ? 'edit' : 'delete';
    openModal('comment', purpose);
    setCommentInfo({ sheetId, commentId, text });
  };

  const openModal = (type, purpose) => {
    setIsDeleteModalOpen((prevState) =>
      purpose === 'delete' ? !prevState.isDeleteModalOpen : prevState.isDeleteModalOpen
    );
    setIsEditModalOpen((prevState) =>
      purpose === 'edit' ? !prevState.isEditModalOpen : prevState.isEditModalOpen
    );
  };

  const closeModal = () => {
    setIsDeleteModalOpen(false);
    setIsEditModalOpen(false);
  };

  if (!sheet._id) return null;

  return (
    <div className={classNames(classes.formWrapper, limiterW && classes.paddingRight)}role="presentation">
      <div
        className={classNames(classes.formFlex, insideNotes ? classes.formInsideNotes : undefined)}
      >
        <Typography variant="h4" color="textPrimary" className={classes.marginBottomSmall}>
          Comments
        </Typography>
        {!insideNotes && (
          <IconButton
            className={classes.closeButton}
            onClick={closeDrawer}
            aria-label="Close"
            disableTouchRipple
          >
            <CloseIcon />
          </IconButton>
        )}

        {canEditWorklog && (
          <div className={classNames(classes.marginBottomNormal)}>
            <TextField
              fullWidth
              type="text"
              name="comment"
              variant="outlined"
              placeholder="Add comment"
              value={comment}
              onChange={handleChange}
              className={classes.search}
              inputProps={{ 'aria-label': 'Comment' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      color="primary"
                      className={classes.searchBtn}
                      onClick={handleSubmit}
                      disableRipple
                    >
                      Send
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        )}

        {sheet.noteComments
          ? sheet.noteComments.map((comment) => (
              <div key={comment._id}>
                <div className={classes.flexBox}>
                  <div className={classes.flex}>
                    <div className={classes.userPhotoWrapper}>
                      <img src={photoPlaceholder} alt="user photo" className={classes.userPhoto} />
                    </div>
                    <Typography variant="body1" color="textPrimary">
                      {/* {getUsername(comment.createdBy)} */}
                      {comment.username}
                      <span className={classes.headerSecondText}>
                        {formatDate(comment.createdAt)}
                      </span>
                    </Typography>
                  </div>
                  {canEditComment(comment.createdBy) && (
                    <div>
                      <Button
                        color="secondary"
                        className={classes.deleteButton}
                        onClick={handleCommentHelper(sheet._id, comment._id, comment.text)}
                        disableRipple
                      >
                        <img src={EditIcon} alt="edit" />
                      </Button>
                      <Button
                        color="secondary"
                        className={classNames(classes.deleteIcon, classes.deleteButton)}
                        onClick={handleCommentHelper(sheet._id, comment._id, '')}
                        disableRipple
                      >
                        <img src={CloseI} alt="delete" />
                      </Button>
                    </div>
                  )}
                </div>
                <Typography
                  variant="body1"
                  color="textPrimary"
                  className={classNames(classes.marginBottomNormal, classes.wordBreak)}
                  style={{ fontFamily: 'Open Sans', fontSize: '0.875rem' }}
                >
                  {comment.text}
                </Typography>
              </div>
            ))
          : null}
      </div>

      {isEditModalOpen && (
        <EditCommentModal
          isOpen={true}
          onClose={closeModal}
          onSubmit={handleComment}
          initialText={commentInfo.text}
        />
      )}

      <ConfirmDialog
        isOpen={isDeleteModalOpen}
        onClose={closeModal}
        onSubmit={handleComment}
        text="Are you sure you want to delete this comment? This action cannot be reversed."
      />
    </div>
  );
};

CommentsForm.propTypes = {
  classes: propTypes.object.isRequired,
  closeDrawer: propTypes.func.isRequired,
  sheet: propTypes.object.isRequired,
  openSnackbar: propTypes.func.isRequired,
  insideNotes: propTypes.bool,
};

export default withStyles(styles)(CommentsForm);
