import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  addButton: {
    color: '#5A87EF',
    [theme.breakpoints.down('md')]:{
      fontSize: 16,
    },
    '&:hover': {
      color: '#5A87EF',
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
    '&:focus': {
      color: '#5A87EF',
      backgroundColor: '#fff',
    },
    '&.Mui-disabled': {
      backgroundColor: '#fff',
    },
  },
  disabledReconcile: {
    pointerEvents: 'none',
  },
  red: {
    color: theme.palette.textColor.red,
    borderColor: theme.palette.borderColor.white,
    background: theme.palette.background.lightRed,
    '&:hover': {
      color: `${theme.palette.textColor.red} !important`,
      borderColor: theme.palette.borderColor.red,
      background: theme.palette.background.lightRed,
    },
    '&:focus': {
      color: `${theme.palette.textColor.red} !important`,
      borderColor: theme.palette.borderColor.red,
      background: theme.palette.background.lightRed,
    },
    '&.Mui-disabled': {
      borderColor: theme.palette.borderColor.primary,
    },
  },
  reconcileBtn: {
    color: `${theme.palette.textColor.white} !important`,
    borderColor: `${theme.palette.borderColor.white} !important`,
    background: `${theme.palette.background.green} !important`,
    '&:hover': {
      color: `${theme.palette.textColor.white} !important`,
      borderColor: `${theme.palette.borderColor.white} !important`,
      background: `${theme.palette.background.green} !important`,
    },
    '&:focus': {
      color: `${theme.palette.textColor.white} !important`,
      borderColor: `${theme.palette.borderColor.white} !important`,
      background: `${theme.palette.background.green} !important`,
    },
  },
}));
