import React from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// import Link from '@material-ui/core/Link';
import InfiniteScroll from 'react-infinite-scroller';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { dayOfWeeekDate, numberDate } from 'helpers/_date-helpers';
import SheetCard from './components/SheetCard';
import SubmittedSheetCard from './components/SubmittedSheetCard';
import styles from './styles';
import PrintIcon from 'assets/images/print-icon.svg';
import { format } from 'date-fns';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';
import { globalBEDateFormat } from 'common/dateFormatConfig';

const SheetsList = ({
  classes,
  openSnackbar,
  sheets,
  submittedType,
  updateVisibleSheets,
  hasMore,
  page,
  isLoading,
  filter,
  todayRef = null,
}) => {
  const navigate = useNavigate();
  const { type } = useParams();
  const { dateFormat, dayDateSettingsFormat } = useDateSettingsFormat();

  const loadMore = () => {
    if (isLoading) return;
    if (hasMore) updateVisibleSheets();
  };

  const hrefPrint = (arraifySheets) => {
    const queryParams = new URLSearchParams();
    queryParams.append('projectId', filter?.projectId);
    queryParams.append('contractorId', filter?.contractorId);
    queryParams.append('status', filter?.status);
    queryParams.append('shift', filter?.shift);
    queryParams.append('forceAccount', filter?.forceAccount);
    queryParams.append('rated', filter?.rated);

    const path = type == 'submissions' ? '/submissions' : '';
    return `/worklog/print/${numberDate(
      arraifySheets[0],
      0,
      dateFormat,
      globalBEDateFormat
    )}${path}?${queryParams}`;
  };

  return !!sheets?.length ? (
    <InfiniteScroll
      pageStart={page}
      loadMore={loadMore}
      hasMore={hasMore}
      loader={
        <div className={classes.loader} key={0}>
          {/* Loading ... */}
        </div>
      }
      threshold={1400}
    >
      {sheets.map((arraifySheets, i) => (
        <Grid
          key={arraifySheets[0] + i}
          container
          spacing={2}
          justifyContent="flex-start"
          className={classes.paddingBottom}
          ref={(el) => {
            if (!todayRef) return;
            if (format(new Date(), dateFormat) === arraifySheets[0]) {
              todayRef.current = el;
            }
          }}
        >
          <Grid item xs={12}>
            <Typography variant="h4">
              {dayOfWeeekDate(arraifySheets[0], { dateFormat, dayDateSettingsFormat })}
              {submittedType && (
                <a
                  className={classes.printLink}
                  href={hrefPrint(arraifySheets)}
                  // href={'/print/worklog/' + numberDate(arraifySheets[0])}
                  target="_blank"
                >
                  <img src={PrintIcon} alt="print" />
                  Print
                </a>
              )}
            </Typography>
          </Grid>
          {arraifySheets[1].map((sheet) => {
            if (!sheet)
              return (
                <Typography
                  variant="h3"
                  align="center"
                  style={{ margin: '1em auto', opacity: 0.5 }}
                >
                  No Results
                </Typography>
              );

            return (
              <Grid key={sheet._id} item xs={12} lg={3} xl="auto">
                {submittedType ? (
                  <SubmittedSheetCard
                    sheet={sheet}
                    openSnackbar={openSnackbar}
                    navigate={navigate}
                  />
                ) : (
                  <SheetCard sheet={sheet} openSnackbar={openSnackbar} navigate={navigate} />
                )}
              </Grid>
            );
          })}
        </Grid>
      ))}
    </InfiniteScroll>
  ) : (
    <>
      {isLoading ? (
        <div style={{ padding: 100 }}></div>
      ) : (
        <Typography variant="h3" align="center" style={{ margin: '1.72em 0 2em', opacity: 0.5 }}>
          No Results
        </Typography>
      )}
    </>
  );
};

SheetsList.propTypes = {
  classes: propTypes.object.isRequired,
  openSnackbar: propTypes.func.isRequired,
  sheets: propTypes.array.isRequired,
  submittedType: propTypes.bool,
  updateVisibleSheets: propTypes.func.isRequired,
  hasMore: propTypes.bool.isRequired,
};

export default withStyles(styles)(SheetsList);
