import React, { useEffect } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { parse, format } from 'date-fns';
import classnames from 'classnames';

import styles from './styles';
import CalendarTable from './components/CalendarTable';
import { useSearchParams, useParams } from 'react-router-dom';
import { GLOBAL_DATE_FORMAT, allDatesFormat } from 'common/dateFormatConfig';

const AvailabilityPrintPage = ({ classes, selectedDate, monthEvents}) => {
  const [search, setSearch] = useSearchParams();
  const param = useParams();

  useEffect(() => {
    const isNeedToPrint = search.get('print');
    if (isNeedToPrint) {
      setTimeout(() => {
        window.print();
      }, 500)
    }
  }, []);

  const getDate = () => {
    const rawDate = param.date;
    const formatDate = format(parse(rawDate, allDatesFormat[1], new Date()), GLOBAL_DATE_FORMAT.fullMonthYear);
    return formatDate;
  }

    return (
      <div className={classes.root}>
        <div className={classes.row}>
            <div className={classnames(classes.column, classes.large12)}>
              <h1 className={classes.header}>
                Availability
              </h1>
              <h2 className={classes.header}>{getDate()}</h2>
          </div>
        </div>
        
        <CalendarTable
          monthEvents={monthEvents}
          selectedDate={selectedDate}
        />
      </div>
    );
}

AvailabilityPrintPage.propTypes = {
  classes: propTypes.object.isRequired,
  selectedDate: propTypes.string.isRequired,
  monthEvents: propTypes.array.isRequired
}

export default withStyles(styles)(AvailabilityPrintPage);
