import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { Formik, Form } from 'formik';
import classNames from 'classnames';
import { debounce } from 'lodash';
import styles from './styles';
import { drawerTypes } from 'pages/Equipment/components/helpers/drawerTypes';
import ImportIcon from 'assets/images/arrow-import-down-grey.svg';
import ExportIcon from 'assets/images/arrow-export-up-grey.svg';
import { useDropzone } from 'react-dropzone';
import { hasPermissionsFor } from 'helpers/_helpers';

const FiltersBar = ({
  classes,
  openDrawer,
  updateSearchValue,
  noFilters,
  isDynamicSearch,
  isImportExport = false,
  onExportData,
  onImportData,
}) => {
  const debouncedOnChange = debounce((value) => {
    updateSearchValue(value);
  }, 300);

  const sendImportData = async (file) => {
    const data = new FormData();
    data.append('file', file);
    onImportData(data);
  };

  const onDropAccepted = ([droppedFile]) => {
    sendImportData(droppedFile);
  };

  const {
    getRootProps: dropzoneRootProps,
    getInputProps: dropzoneInputProps,
    open: openDropzone,
  } = useDropzone({
    onDropAccepted,
    accept: 'text/csv',
    maxFiles: 1,
    maxSize: 10 * 1024 * 1024, // 10MB
    multiple: false,
    noClick: true,
    noDrag: true,
  });

  const sendExportData = () => {
    onExportData();
  };

  const searchXl = 'auto';
  const searchMd = 'auto';

  const searchXS = 12;

  const importExportXl = 2;
  const importExportMd = 2;
  const importExportXS = 6;

  const filterXl = 2;
  const filterMd = 2;
  const filterXS = 12;

  return (
    <Formik
      initialValues={{ searchValue: '' }}
      onSubmit={(values, { isSubmitting }) => {
        if (isSubmitting) return;
        updateSearchValue(values.searchValue);
      }}
    >
      {({ values, setFieldValue }) => (
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
          style={{ paddingTop: '8px', paddingBottom: '8px' }}
        >
          <Grid item xs={searchXS} md={searchMd} xl={searchXl} style={{ flexGrow: 1 }}>
            <Form autoComplete="off" className={classes.formFlex}>
              {/* <div className={classes.search}> */}
              <div>
                <TextField
                  type="text"
                  name="searchValue"
                  variant="outlined"
                  placeholder="Search"
                  value={values.searchValue}
                  onChange={(e) => {
                    const input = e.target.value;
                    setFieldValue('searchValue', input);
                    isDynamicSearch && debouncedOnChange(input, setFieldValue);
                  }}
                  // className={classes.search}
                  style={{ width: '100%' }}
                  inputProps={{ 'aria-label': 'Search' }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <div className={classes.searchIcon}>
                          <SearchIcon />
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </Form>
          </Grid>
          {isImportExport && hasPermissionsFor('projectsFullAccess') && (
            <>
              <Grid item xs={importExportXS} md={importExportMd} xl={importExportXl}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classNames('filters-button', classes.importExportBtn)}
                  style={{ width: '100%' }}
                  onClick={sendExportData}
                  disableTouchRipple
                >
                  <img className="filters-button__icon" src={ImportIcon} alt="export" />
                  Export CSV
                </Button>
              </Grid>
              <Grid item xs={importExportXS} md={importExportMd} xl={importExportXl}>
                <div {...dropzoneRootProps()} style={{ display: 'none' }}>
                  <input type="file" {...dropzoneInputProps()} />
                </div>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classNames('filters-button', classes.importExportBtn)}
                  style={{ width: '100%' }}
                  onClick={openDropzone}
                  disableTouchRipple
                >
                  <img className="filters-button__icon" src={ExportIcon} alt="import" />
                  Import CSV
                </Button>
              </Grid>
            </>
          )}
          {!noFilters && (
            <Grid item xs={filterXS} md={filterMd} xl={filterXl}>
              <Button
                variant="contained"
                color="secondary"
                className={classes.toggleFiltersBtn}
                onClick={openDrawer(drawerTypes.filters, true)}
                disableTouchRipple
              >
                <i
                  className={classNames('fa fa-sliders', classes.filtersIcon)}
                  aria-hidden="true"
                />
                Filters
              </Button>
            </Grid>
          )}
        </Grid>
      )}
    </Formik>
  );
};

FiltersBar.propTypes = {
  classes: propTypes.object.isRequired,
  openDrawer: propTypes.func.isRequired,
  updateSearchValue: propTypes.func.isRequired,
  noFilters: propTypes.bool,
};

export default withStyles(styles)(FiltersBar);
