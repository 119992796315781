import React from 'react';
import propTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
  progress: props => ({
    display: 'inline-block',
    verticalAlign: 'middle',
    margin: `0 ${props.margin}px`,
    width: `${props.height}px !important`,
    height: `${props.height}px !important`,
  }),
})

const LoadingSpinner = props => {
  const classes = useStyles(props);
  return (
    <CircularProgress className={classes.progress} />
  )
};

LoadingSpinner.propTypes = {
  height: propTypes.number.isRequired,
  margin: propTypes.number.isRequired
};

export default LoadingSpinner;
