import { createAction } from '@reduxjs/toolkit';

// Full Schedule
const getFullScheduleSheets = createAction('workerSchedule/fullSchedule/getSheets');
const addFullScheduleSheet = createAction('workerSchedule/fullSchedule/addSheet');
const putFullScheduleSheet = createAction('workerSchedule/fullSchedule/putSheet');
const patchFullScheduleSheet = createAction('workerSchedule/fullSchedule/patchSheet');
const deleteFullScheduleSheet = createAction('workerSchedule/fullSchedule/deleteSheet');
const patchFullScheduleSheetWorker = createAction('workerSchedule/fullSchedule/patchSheetWorker');
const patchFullScheduleWorker = createAction('workerSchedule/fullSchedule/patchWorker');
const patchFullScheduleEquipment = createAction('workerSchedule/fullSchedule/patchEquipment');
const setFullScheduleDate = createAction('workerSchedule/fullSchedule/setDate');

// My Schedule
const getMyScheduleSheets = createAction('workerSchedule/mySchedule/getSheets');
const addMyScheduleSheet = createAction('workerSchedule/mySchedule/addSheet');
const putMyScheduleSheet = createAction('workerSchedule/mySchedule/putSheet');
const patchMyScheduleSheet = createAction('workerSchedule/mySchedule/patchSheet');
const deleteMyScheduleSheet = createAction('workerSchedule/mySchedule/deleteSheet');
const patchMyScheduleSheetWorker = createAction('workerSchedule/mySchedule/patchSheetWorker');
const patchMyScheduleWorker = createAction('workerSchedule/mySchedule/patchWorker');
const patchMyScheduleEquipment = createAction('workerSchedule/mySchedule/patchEquipment');

// Loading
const setLoading = createAction('workerSchedule/loading');

// Snackbar
const setSnackbar = createAction('workerSchedule/snackbar');
const closeSnackbar = createAction('workerSchedule/closeSnackbar');

// Sockets
const socketConnect = createAction('workerSchedule/socketConnect');
const socketDisconnect = createAction('workerSchedule/socketDisconnect');

const actions = {
  getFullScheduleSheets,
  addFullScheduleSheet,
  putFullScheduleSheet,
  patchFullScheduleSheet,
  deleteFullScheduleSheet,
  patchFullScheduleSheetWorker,
  patchFullScheduleWorker,
  patchFullScheduleEquipment,
  setFullScheduleDate,
  getMyScheduleSheets,
  addMyScheduleSheet,
  putMyScheduleSheet,
  patchMyScheduleSheet,
  deleteMyScheduleSheet,
  patchMyScheduleSheetWorker,
  patchMyScheduleWorker,
  patchMyScheduleEquipment,
  setLoading,
  setSnackbar,
  closeSnackbar,
  socketConnect,
  socketDisconnect,
};

export default actions;