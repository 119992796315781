import { withStyles } from '@material-ui/core';
import styles from './styles';
import MonthPicker from 'components/MonthPicker';
import { useSelector, useDispatch } from 'react-redux';
import { updateMonthlySelectedDate } from 'store/schedule/schedulePageOperation';
import schedulePageActions from 'store/schedule/schedulePageActions';

const MonthlyCalendar = ({ classes }) => {
  const dispatch = useDispatch();
  const currentMonthDate = useSelector((state) => state.schedule.currentMonthDate);
  const updateDate = (date) => {
    dispatch(updateMonthlySelectedDate(date));
    dispatch(schedulePageActions.setSelectedMonthDayDate(''));
    dispatch(schedulePageActions.setSheets([]));
  };

  return (
    <div>
      <MonthPicker selectedDate={currentMonthDate} updateDate={updateDate} />
    </div>
  );
};

export default withStyles(styles)(MonthlyCalendar);
