import React from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import EditIcon from 'assets/images/edit-icon.svg'
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import styles from './styles';

const SettingsHeader = ({ classes, pageType, changePageType }) => {

  const editAction = () => {
    changePageType('edit')
  }

  return (
    <header className={classes.header}>
      <Typography variant="h3">
        Settings
      </Typography>
      {pageType === 'read' && (
        <Button
          variant="text"
          className={classes.editButton}
          onClick={editAction}
          disableRipple
        >
          <img src={EditIcon} alt="edit" />
        </Button>
      )}
    </header>
  )
}

SettingsHeader.propTypes = {
  classes: propTypes.object.isRequired,
  pageType: propTypes.string,
  changePageType: propTypes.func,
}

export default withStyles(styles)(SettingsHeader);
