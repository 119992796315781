import React from 'react';
import { Grid, Typography, TextField } from '@material-ui/core';
import { Option, isIOS } from '../../../../../../../../components/Option';
import { MEASUREMENT_SYSTEM } from 'common/measurements';


export const MaterialMeasurement = ({ errors, values, handleChange, handleBlur }) => {
  return (
    <Grid container spacing={1} style={{ justifyContent: 'space-between', marginBottom: '10px' }}>
      <Grid item xs={12} lg={6}>
      <label htmlFor="measurement">
        <Typography variant="body1" color="textSecondary">
          Measurement System:
        </Typography>
        <TextField
          select
          id="measurement"
          name="measurement"
          error={Boolean(errors.measurement)}
          variant="outlined"
          InputProps={{
            className: `${errors.measurement ? 'materials-input--error' : ''}`,
            style: {
                height: '35px',
              },
          }}
          fullWidth
          onChange={handleChange}
          onBlur={handleBlur}
          defaultValue={values.measurement || ''}
          SelectProps={{
            native: isIOS,
          }}
        >
          <Option value={MEASUREMENT_SYSTEM.imperial}>
            Imperial System
          </Option>
          <Option value={MEASUREMENT_SYSTEM.metric}>
            Metric System
          </Option>
        </TextField>
      </label>
      {errors.measurement && <span className="materials-input--error-text">Field is required</span>}
    </Grid>
  </Grid>
  )
}

export default MaterialMeasurement