const round = (number, digits = 2) => Math.round(number * (10 ** digits)) / (10 ** digits);

export const calculateLine = (quantity = 1, ratio) => {
  return round(quantity / ratio, 3);
};

export const calculateLegend = (quantity = 1, area, ratio) => {
  return round((quantity * area) / ratio, 3);
};

export const calculateLettering = (
  quantity = 1,
  text = '',
  letterSet,
  ratio,
  throwError = false
) => {
  return text
    .split('')
    .filter((letter) => letter !== ' ')
    .reduce((accum, letter) => {
      if (!letterSet.hasOwnProperty(letter)) {
        if (throwError) throw new Error(`${letter} is not added to the lettering set`);
        return accum;
      }
      return accum + round((quantity * letterSet[letter]) / ratio, 3);
    }, 0);
};

export const materialCalculations = (material) => {
  const { materialSpecifications } = material || {};
  if (!material || !materialSpecifications || material.materialType === 'each') {
    return;
  }
  const { line, legend, lettering } = materialSpecifications;

  if (line) {
    if (line.isSF) {
      return round(material.quantity, 3);
    }
    return calculateLine(material.quantity, line.ratio);
  } else if (legend) {
    return calculateLegend(material.quantity, legend.area, legend.ratio);
  } else if (lettering) {
    return calculateLettering(
      material.quantity,
      material.letteringText,
      lettering.letterSet,
      lettering.ratio
    );
  }
};
