import React, { Component } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SignaturePad from 'signature_pad';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import styles from './styles';

class SignatureModal extends Component {
  componentDidMount() {
    setTimeout(() => {
      const canvas = this.refs.canvas;
      this.signaturePad = new SignaturePad(canvas);
    }, 1000);
  }

  saveData = () => {
    const imgData = this.signaturePad.toDataURL();
    this.props.submitModal(imgData);
  };

  render() {
    const { classes, closeModal } = this.props;
    return (
      <Dialog
        open
        onClose={closeModal}
        PaperProps={{
          classes: {
            root: classes.dialogRoot,
          },
        }}
        backdropclick
        disableEscapeKeyDown
      >
        <div className={classes.formWrapper} role="presentation">
          <div className={classes.formFlex}>
            <div>
              <Typography variant="h3" style={{ marginBottom: '10px' }}>
                Add signature
              </Typography>

              <IconButton
                className={classes.closeButton}
                onClick={closeModal}
                aria-label="Close"
                disableTouchRipple
              >
                <CloseIcon />
              </IconButton>

              <div onTouchMove={() => false}>
                {window.innerWidth < 640 ? (
                  <canvas
                    ref="canvas"
                    width="290"
                    height="160"
                    className={classes.canvas}
                  />
                ) : (
                  <canvas
                    ref="canvas"
                    width="570"
                    height="300"
                    className={classes.canvas}
                  />
                )}
              </div>
            </div>

            <div className={classes.buttonHolder}>
              <Button
                color="secondary"
                variant="outlined"
                className={classes.cancelButton}
                onClick={closeModal}
                disableTouchRipple
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="outlined"
                className={classes.saveButton}
                onClick={this.saveData}
                disableTouchRipple
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}

SignatureModal.propTypes = {
  classes: propTypes.object.isRequired,
  closeModal: propTypes.func.isRequired,
  submitModal: propTypes.func.isRequired,
  type: propTypes.string.isRequired,
};

export default withStyles(styles)(SignatureModal);
