import React from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

import styles from './styles';
import { useSelector } from 'react-redux';

const AlertModal = ({ classes, isOpen, onClose, info }) => {
  const usersList = useSelector((store) => store.people.users);

  const createErrorInfo = (error) => {
    if (error.username) {
      return `This User ${error.username} ${error.infoText}`;
    }
    const currentUser = usersList.find(el=>el._id === error.userId);
    return `${error.errorInfo}`;
  };
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        classes: {
          root: classes.dialogRoot,
        },
      }}
    >
      <DialogTitle id="alert-dialog-title">
        {Array.isArray(info)
          ? info.map((el, index) => (
              <div key={index} className={classes.content}>
                {createErrorInfo(el)}
              </div>
            ))
          : info &&
            info.split('\n').map((line, i) => (
              <span key={i} style={{ marginBottom: '.5rem', display: 'block' }}>
                {line}
                <br />
              </span>
            ))}
      </DialogTitle>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="contained"
          className={classes.button}
          disableTouchRipple
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AlertModal.propTypes = {
  classes: propTypes.object.isRequired,
  isOpen: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
};

export default withStyles(styles)(AlertModal);
