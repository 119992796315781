import React from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';

import CrewUser from './components/CrewUser';
import EditIcon from 'assets/images/edit-icon.svg';
import styles from './styles';

const CrewBlock = ({
  classes,
  workers,
  getFormattedHoursRange,
  openAdditionalDrawer,
  hasAccess,
  openUserWorklogs,
}) => {
  return (
    <div>
      <Grid
        container
        spacing={2}
        direction={'row'}
        wrap={'nowrap'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Grid item xs={3}>
          <Typography variant="body1" color="textSecondary" className={classes.regularFontWeight}>
            Crew:
          </Typography>
        </Grid>
        <Grid container item xs={8} className={classes.hidForMobileWrapper}>
          {workers.some((data) => data?.hours) && (
            <>
              <Grid item xs={5}>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  className={classes.regularFontWeight}
                >
                  On Job Time:
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  className={classes.regularFontWeight}
                >
                  Individual Time To:
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  className={classes.regularFontWeight}
                  style={{ paddingLeft: 15}}
                >
                  Individual Time From:
                </Typography>
              </Grid>
            </>
          )}
        </Grid>

        <Grid item container xs={1} justifyContent={'flex-end'} spacing={0}>
          {hasAccess && (
            <IconButton
              className={classes.editButton}
              onClick={openAdditionalDrawer('resources', 'people')}
              disableRipple
              disableTouchRipple
            >
              <img src={EditIcon} alt="edit crew" />
            </IconButton>
          )}
        </Grid>
      </Grid>
      <>
        {/* {workers.sort(compareByForeman).map((w) => (epicmock */}
        {workers.map((w) => (
          <CrewUser
            key={w._id}
            workerData={w}
            getFormattedHoursRange={getFormattedHoursRange}
            hasAccess={hasAccess}
            openUserWorklogs={openUserWorklogs(w._id, w.hours, w)}
          />
        ))}
      </>
    </div>
  );
};

CrewBlock.propTypes = {
  classes: propTypes.object.isRequired,
  workers: propTypes.array.isRequired,
  getFormattedHoursRange: propTypes.func.isRequired,
  openAdditionalDrawer: propTypes.func.isRequired,
  openUserWorklogs: propTypes.func.isRequired,
  hasAccess: propTypes.bool.isRequired,
};

export default withStyles(styles)(CrewBlock);
