import React, { useState } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import ProjectsHeader from './components/ProjectsHeader';
import ProjectsPage from './components/ProjectsPage';
import ProjectForm from './components/ProjectsPage/components/ProjectForm';
import styles from './styles';
import { drawerTypes } from '../Equipment/components/helpers/drawerTypes';

const Projects = ({ classes, openSnackbar }) => {
  const [drawer, setDrawer] = useState({
    type: drawerTypes.create,
    isOpen: false,
  });

  const openDrawer = (type, open) => () => {
    setDrawer({ type, isOpen: open });
  };

  const closeDrawer = () => {
    setDrawer((prev) => ({ ...prev.drawer, isOpen: false }));
  };
  return (
    <div className={classes.root}>
      <ProjectsHeader openDrawer={openDrawer} type={'Projects'} />

      <ProjectsPage openSnackbar={openSnackbar} classes={classes} />
      <Drawer
        anchor="right"
        open={drawer.isOpen}
        classes={{
          paper: classes.drawer,
        }}
      >
        <ProjectForm closeDrawer={closeDrawer} openSnackbar={openSnackbar} type={drawer.type} />
      </Drawer>
    </div>
  );
};

Projects.propTypes = {
  classes: propTypes.object.isRequired,
  openSnackbar: propTypes.func.isRequired,
};

export default withStyles(styles)(Projects);
