export default theme => ({
  dialogRoot: {
    [theme.breakpoints.up('md')]: {
      maxWidth: 500
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      margin: 0
    }
  },
  formWrapper: {
    padding: '20px 15px 0',
    [theme.breakpoints.down('sm')]: {
      minWidth: 320,
    },
    [theme.breakpoints.up('md')]: {
      width: 500,
    }
  },
  closeButton: {
    position: 'absolute',
    right: 10,
    top: 18,
    padding: 4
  },
  buttonHolder: {
    textAlign: 'center',
  },
  cancelButton: {
    margin: 20,
    minWidth: 100,
    '&:hover': {
      color: 'inherit'
    },
    '&:disabled': {
      backgroundColor: '#fff',
    },
  },
  saveButton: {
    margin: 20,
    minWidth: 100,
    '&:hover': {
      color: theme.palette.textColor.primary,
    },
    '&:disabled': {
      backgroundColor: '#fff',
    },
  },
  formFlex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  flexBox: {
    marginBottom: 16,
    alignItems: 'center'
  },
  inlineFlex: {
    display: 'inline-flex',
    alignItems: 'center',
    flexDirection: 'row',
    maxWidth: '100%'
  },
  name: {
    marginLeft: 15,
    wordBreak: 'break-word',
    fontSize: 14,
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  photoWrapper: {
    borderRadius: '50%',
    border: `1px solid ${theme.palette.textColor.blue}`,
    borderTopColor: 'transparent',
    borderBottomColor: 'transparent',
    width: 22,
    height: 22,
    transform: 'rotate(135deg)',
    minWidth: 22,
  },
  photo: {
    display: 'block',
    transform: 'rotate(-135deg)',
    height: 20,
    maxWidth: 20,
    borderRadius: '50%',
    margin: '0 auto',
  },
  day: {
    color: theme.palette.textColor.yellow,
  },
  night: {
    color: theme.palette.textColor.darkBlue,
  },
  inactive: {
    color: theme.palette.textColor.secondary,
  },
  dayBorder: {
    border: `1px solid ${theme.palette.textColor.yellow}`,
  },
  nightBorder: {
    border: `1px solid ${theme.palette.textColor.darkBlue}`,
  },
  inactiveBorder: {
    border: `1px solid ${theme.palette.textColor.secondary}`,
  },
  textRight: {
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left'
    }
  },
  equipmentBlock: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '17px',
    width: 'max-content',
    maxWidth: '100%'
  },
  equipmentNumber: {
    fontSize: 14,
    fontWeight: 500,
    fontFamily: 'Poppins',
    color: theme.palette.textColor.white,
    padding: '3px 6px',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  label: {
    width: 'auto',
    '&:first-child': {
      marginRight: '20px !important'
    }
  },
  radioButton: {
    padding: 0,
    marginRight: 5,
    '&:hover': {
      background: theme.palette.background.default
    },
    '&:focus': {
      background: theme.palette.background.default
    }
  },
  icon: {
    borderRadius: '50%',
    width: 20,
    height: 20,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: theme.palette.background.blue,
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 6,
      height: 6,
      backgroundColor: theme.palette.background.default,
      content: '""',
      borderRadius: '50%',
      margin: '7px auto'
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
});
