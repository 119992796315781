import axios from 'axios';
import { getKioskOptions } from 'helpers/getOptions';


export const apiCalls = {
  clockIn: async (body) => {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/kiosk/clock-in`,
      {},
      getKioskOptions()
    );

    return response;
  },
  clockOut: async (body) => {
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}/kiosk/clock-out`,
      body,
      getKioskOptions()
    );
  },
  injuryReport: async (body) => {
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}/kiosk/injury-report`,
      body,
      getKioskOptions()
    )
  },
  injuryReportConfirmation: async () => {
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}/kiosk/injury-report/confirmation`,
      {},
      getKioskOptions()
    )
  },
};
