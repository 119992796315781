export const validation = (values) => {
  const requiredFieldError = "This field can't be blank";
  const numberError = 'Phone number must be 10 digits';
  const errors = {};

  if (!values.relationship.trim()) {
    errors.relationship = requiredFieldError;
  }
  if (!values.name.trim()) {
    errors.name = requiredFieldError;
  }
  if (values.phoneNumber.length !== 10) {
    errors.phoneNumber = numberError;
  }
  if (!values.address.trim()) {
    errors.address = requiredFieldError;
  }

  return errors;
};