export const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    position: 'relative',
    boxShadow: '0 4px 24px 0 rgba(59,59,59,.129)',
    minHeight: 49,

    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: 0,
      width: '100%',
      height: 1,
      background:
        'linear-gradient(90deg, #3e82ff 9.62%, rgba(62, 130, 255, 0.63) 28.47%, #d1d1d1 65.06%),linear-gradient(0deg, #fafafa, #fafafa)',
    },
  },
  companyName: {
    color: theme.palette.textColor.dark,
    fontWeight: 500,
    fontSize: '19px',
    lineHeight: '23px',
    padding: '13px 23px',
    '@media screen and (max-width: 766px)': {
      display: 'none',
    },
  },
  headerLogoBlockWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 20,
    '@media screen and (max-width: 1033px)': {
      marginLeft: 10,
    },
  },
  headerLogoWrapper: {
    paddingRight: 24,
    borderRight: '1px solid rgba(229, 229, 229, 0.619)',
    '@media screen and (max-width: 766px)': {
      paddingRight: 5,
      borderRight: 'none',
    },
  },
  headerLogo: {
    width: 186,
    height: 30,
  },
  exitIcon: {
    '& path': {
      fill: theme.palette.textColor.secondary,
    },
    marginRight: 10,
  },
  exitWrapper: {
    position: 'relative',
    '&:before': {
      top: '3px',
      left: '-10px',
      width: '1px',
      height: '30px',
      content: '""',
      position: 'absolute',
      backgroundColor: 'rgba(229, 229, 229, 0.619)',
    },
  },
  exit: {
    marginRight: 10,
    color: theme.palette.textColor.secondary,
    '&:hover': {
      color: theme.palette.textColor.headerLinkColor,
      background: `${theme.palette.background.lightBlue}`,
    },
    '&:hover $exitIcon': {
      '& path': {
        fill: theme.palette.textColor.headerLinkColor,
      },
    },
    '&:focus': {
      color: theme.palette.textColor.headerLinkColor,
      background: `${theme.palette.background.lightBlue}`,
    },
    '&:focus $exitIcon': {
      '& path': {
        fill: theme.palette.textColor.headerLinkColor,
      },
    },
  },
});
