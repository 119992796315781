import React from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import { hasPermissionsFor, checkUserRole } from 'helpers/_helpers';
import userPhoto from 'assets/icons/user-photo-placeholder.svg';
import UserPermissions from './components/UserPermissions';
import styles from './styles';
import { drawerTypes } from 'pages/Equipment/components/helpers/drawerTypes';

const UserCard = ({
  classes,
  user,
  formatLastLogin,
  renderUserShift,
  openDrawer,
  currentUserId,
  openSnackbar,
}) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.root}>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={'auto'}
          lg={12}
          onClick={
            hasPermissionsFor('usersFullAccess') ? openDrawer(drawerTypes.update, true, user) : null
          }
        >
          <div className={classes.root}>
            <div className={classes.flexBox}>
              <div className={classes.userPhotoWrapper}>
                <img src={userPhoto} alt="user photo" className={classes.userPhoto} />
              </div>
              <div className={classes.fixedWidth}>
                <Typography variant="body2" color="textSecondary" className={classes.lastSeen}>
                  {formatLastLogin(user.profile.lastLogin)}
                </Typography>
              </div>
            </div>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          lg={12}
          className={classes.paddingBottomRemove}
          onClick={
            hasPermissionsFor('usersFullAccess') ? openDrawer(drawerTypes.update, true, user) : null
          }
        >
          <CardHeader
            title={user.profile.fullName}
            subheader={user.profile.role.roleName}
            classes={{
              root: classes.cardHeaderRoot,
              content: classes.cardHeaderContent,
            }}
          />
          <div className={classes.paddingBox}>
            <Divider className={classes.divider} />
            <Typography variant="body1" color="textSecondary" className={classes.marginBottomSmall}>
              {(hasPermissionsFor('usersBasicRead') || hasPermissionsFor('usersFullAccess')) &&
              !checkUserRole('Dispatcher') &&
              user.profile.HICode
                ? `${user.profile.HICode} | `
                : ''}
              {user.profile.employeeNum}
            </Typography>
            <Typography variant="body1" color="textSecondary">
              {renderUserShift(user.profile.shifts.timeOfDay)}
              <Typography
                variant="body1"
                color="textPrimary"
                component="span"
                className={classes.shiftDays}
              >
                {user.profile.shifts.weekDays}
              </Typography>
            </Typography>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          lg={12}
          className={classes.paddingBottomRemove}
          onClick={
            hasPermissionsFor('usersFullAccess') ? openDrawer(drawerTypes.update, true, user) : null
          }
        >
          <CardContent className={classes.cardContent}>
            <Divider className={classes.divider} />
            <Grid container>
              <Grid item xs={12} sm={6} lg={12}>
                <Grid container justifyContent="space-between" spacing={2}>
                  <Grid item xs={6} sm={12} lg={6}>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      className={classes.marginBottomSmall}
                    >
                      Username:
                    </Typography>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      className={classnames(classes.marginBottomNormal, classes.wordBreak)}
                    >
                      {user.username}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} lg={12}>
                {user.profile.email && (
                  <>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      className={classes.marginBottomSmall}
                    >
                      Email:
                    </Typography>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      className={classnames(classes.marginBottomNormal, classes.wordBreak)}
                    >
                      {user.profile.email}
                    </Typography>
                  </>
                )}
                {user.profile.phoneNumber && (
                  <>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      className={classes.marginBottomSmall}
                    >
                      Phone:
                    </Typography>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      className={classes.marginBottomNormal}
                    >
                      {user.profile.phoneNumber}
                    </Typography>
                  </>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Grid>

        <Grid item xs={12} className={classes.smallPaddingTop}>
          {(hasPermissionsFor('usersFullAccess') ||
            (checkUserRole('Dispatcher') && user.profile.role.roleName === 'Field Technician')) && (
            <>
              <Collapse
                in={expanded}
                timeout="auto"
                unmountOnExit
                className={classes.contentWrapper}
              >
                <Grid container>
                  <Grid item xs={12} md={12} lg={12}>
                    <UserPermissions
                      user={user}
                      currentUserId={currentUserId}
                      openSnackbar={openSnackbar}
                    />
                  </Grid>
                </Grid>
              </Collapse>
              <CardActions
                disableSpacing
                onClick={handleExpandClick}
                className={classes.actionsWrapper}
              >
                <IconButton
                  className={classnames(classes.expand, {
                    [classes.expandOpen]: expanded,
                  })}
                  aria-expanded={expanded}
                  aria-label="Show more"
                  disableRipple
                  disableFocusRipple
                >
                  <ExpandMoreIcon />
                </IconButton>
                <Typography color="textSecondary">
                  {expanded ? 'Hide permissions' : 'Show permissions'}
                </Typography>
                <IconButton
                  className={classnames(classes.expand, {
                    [classes.expandOpen]: expanded,
                  })}
                  aria-expanded={expanded}
                  aria-label="Show more"
                  disableRipple
                  disableFocusRipple
                >
                  <ExpandMoreIcon />
                </IconButton>
              </CardActions>
            </>
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

UserCard.propTypes = {
  classes: propTypes.object.isRequired,
  user: propTypes.object.isRequired,
  formatLastLogin: propTypes.func.isRequired,
  renderUserShift: propTypes.func.isRequired,
  openDrawer: propTypes.func.isRequired,
  currentUserId: propTypes.string,
};

export default withStyles(styles)(UserCard);
