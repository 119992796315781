export default theme => ({
  root: {
    maxWidth: 1280, margin: '0 auto', padding: '10px 15px', width: '100%' 
  },
  drawer: {
    // [theme.breakpoints.down('sm')]: {
    //   width: '100%',
    // },
  },
  tabsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tabs: {
    borderBottom: '1px solid #e8e8e8',
    paddingTop: 0,
    marginBottom: 10,
    width: '100%',
    color: theme.palette.textColor.grey,
    '& a': {
      minWidth: 110
    },
    '& a:hover': {
      backgroundColor: 'transparent !important'
    },
    '& a.Mui-selected': {
      color: theme.palette.primary.blue,
    },
    '& span': {
      fontSize: '0.875rem',
      fontWeight: 500
    }
  }
});
