import React from 'react';
import propTypes from 'prop-types';
import { Formik, Form } from 'formik/dist/index';
import { withStyles } from '@material-ui/core/styles/index';
import Typography from '@material-ui/core/Typography/index';
import IconButton from '@material-ui/core/IconButton/index';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid/index';
import TextField from '@material-ui/core/TextField/index';
import Button from '@material-ui/core/Button/index';

import PageLoader from 'components/PageLoader';
import styles from './styles';
import { isIOS, Option } from 'components/Option';

const SortingPanel = ({ classes, closeDrawer, onCancel, sorting, onUpdate }) => {
  return (
    <div className={classes.formWrapper} role="presentation">
      <Formik
        initialValues={sorting}
        enableReinitialize={true}
        onSubmit={(values) => {
          onUpdate(values);
        }}
      >
        {({ values, handleChange }) => (
          <PageLoader loading={false}>
            <Form autoComplete="off" className={classes.formFlex}>
              <div>
                <Typography variant="h3" className={classes.marginHeader}>
                  Filters
                </Typography>

                <IconButton
                  className={classes.closeButton}
                  onClick={closeDrawer}
                  aria-label="Close"
                  disableTouchRipple
                >
                  <CloseIcon />
                </IconButton>

                <Grid container spacing={2} className={classes.flexContainer}>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="sortBy" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Sort by:
                      </Typography>
                      <TextField
                        select
                        id="sortBy"
                        name="sortBy"
                        value={values.sortBy}
                        onChange={handleChange}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Sort by' }}
                        fullWidth
                        SelectProps={{
                          native: isIOS,
                        }}
                      >
                        <Option key={1} value="name">
                          Name
                        </Option>
                        {/* <Option key={2} value="ratio">
                          Ratio
                        </Option> */}
                      </TextField>
                    </label>
                  </Grid>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="sortOrder" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Sort from:
                      </Typography>
                      <TextField
                        select
                        id="sortOrder"
                        name="sortOrder"
                        value={values.sortOrder}
                        onChange={handleChange}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Days' }}
                        fullWidth
                        SelectProps={{
                          native: isIOS,
                        }}
                      >
                        <Option key={1} value="asc">
                          {'A \u2013 Z'}
                        </Option>
                        <Option key={2} value="desc">
                          {'Z \u2013 A'}
                        </Option>
                      </TextField>
                    </label>
                  </Grid>
                </Grid>
                <button className={classes.linkButton} onClick={() => onCancel()}>
                  Reset all filters
                </button>
              </div>

              <div className={classes.buttonHolder}>
                <Button color="secondary" className={classes.cancelButton} onClick={closeDrawer}>
                  Cancel
                </Button>

                <div className={classes.verticalDivider} />

                <Button type="submit" color="primary" className={classes.saveButton}>
                  Apply
                </Button>
              </div>
            </Form>
          </PageLoader>
        )}
      </Formik>
    </div>
  );
};

SortingPanel.propTypes = {
  classes: propTypes.object.isRequired,
  onCancel: propTypes.func.isRequired,
  closeDrawer: propTypes.func.isRequired,
  sorting: propTypes.object.isRequired,
  onUpdate: propTypes.func.isRequired,
};

export default withStyles(styles)(SortingPanel);
