export default theme => ({
  root: {
    padding: '16px 14px',
    border: `1px solid ${theme.palette.borderColor.primary}`,
    borderRadius: 4,
  },
  regularFontWeight: {
    fontWeight: 300
  },
  holidayName: {
    fontSize: 14,
    wordBreak: 'break-word',
    marginRight: 30,
  },
  holidayText: {
    fontWeight: 500,
    color: theme.palette.textColor.dark,
    textTransform: 'capitalize',
    wordBreak: 'break-word',
    marginRight: 30,
  },
  actionBtn: {
    textTransform: 'none',
    padding: 0,
    minWidth: 25,
    minHeight: 25,
    marginLeft: 15,
    '&:first-child': {
      marginLeft: 0
    },
    '&:hover': {
      color: 'inherit',
      backgroundColor: 'inherit !important',
    },
    '&:focus': {
      color: 'inherit',
      backgroundColor: 'inherit !important',
    }
  },
  textRight: {
    textAlign: 'right'
  },
  deletedHoliday: {
    backgroundColor: theme.palette.background.lightGrey2,
    opacity: .5
  }
})