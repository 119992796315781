import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  hoursBlock: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  addButton: {
    padding: 0,
    color: theme.palette.textColor.primary,
    '&:hover': {
      color: theme.palette.textColor.primary,
      background: `${theme.palette.background.default} !important`,
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
      background: `${theme.palette.background.default} !important`,
    },
  },
  textCenter: {
    textAlign: 'center',
  },
});

export const useStyles = makeStyles(styles);
