import { useEffect, useState, useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PrintIcon from 'assets/images/print-icon.svg';
import DownloadIcon from 'assets/images/download-icon.svg';
import Button from '@material-ui/core/Button';
import UploadIcon from 'assets/images/upload-icon.svg';

import styles from './styles';
import PicCardList from 'components/ImagesForm/components/PicCardList/PicCardList';
import { fetchImages, openPrintPage, postCardDescription } from 'components/ImagesForm/ImagesForm';
import { deleteImage } from 'components/WorklogDetails/components/NotesBlock/components/ImagesBlock/components/ImageCard/ImageCard';
import $api from 'http/index';
import { getOptions } from 'helpers/getOptions';
import { useParams } from 'react-router-dom';
import { WORKLOG_PRINT_QUERY } from 'pages/WorklogPrint/components/constants';

const uploadImageFromImgForm = async (imageFile, sheetId) => {
  const formData = new FormData();
  formData.append('image', imageFile);

  try {
    const response = await $api.post(
      `${process.env.REACT_APP_BASE_URL}/sheets/${sheetId}/images
    `,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-Auth-Token': localStorage.getItem('authorization-token'),
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
};

export const downloadImageArchive = async (sheetId) => {
  try {
    const response = await $api.get(
      `${process.env.REACT_APP_BASE_URL}/sheets/${sheetId}/images/zip`,
      {
        ...getOptions(),
        responseType: 'arraybuffer',
      }
    );

    const blob = new Blob([response.data], { type: 'application/zip' });

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', `Work Log Pictures.zip`);

    document.body.appendChild(link);

    link.click();

    link.parentNode.removeChild(link);
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
};

const PicCardListWrapper = ({ classes, sheet }) => {
  const [imgData, setImgData] = useState([]);
  const [randomKey, setRandomKey] = useState(Math.random().toString(36));
  const fileInputRef = useRef(null);
  const { type } = useParams();

  useEffect(() => {
    fetchImages(sheet._id).then((data) => setImgData(data || []));
  }, []);

  const handleCustomButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleDeleteNoteImg = (id) => async () => {
    deleteImage(sheet._id, id).then((e) =>
      setImgData((prev) => prev.filter((el) => el._id !== id))
    );
  };

  const handleEditNoteImg = async (id, description) => {
    const editedState = imgData.map((cardItem) =>
      cardItem._id === id ? { ...cardItem, description } : cardItem
    );
    postCardDescription(sheet._id, id, description).then((e) =>
      setImgData(editedState).catch((e) => console.error('Error post images:', e))
    );
  };

  const handleFileChange = async (event) => {
    const selectedFiles = await Array.from(event.target.files);

    const uploadedImageUrls = selectedFiles.map((file) => ({
      img: `${URL.createObjectURL(file)}`,
      size: file.size,
      file: file,
    }));

    await Promise.all(uploadedImageUrls.map((img) => uploadImageFromImgForm(img.file, sheet._id)))
      .then((data) => setImgData([...imgData, ...data]))
      .catch((e) => {});

    setRandomKey(Math.random().toString(36));
  };
  //! WL EDIT NOTES FORM
  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h3">Images</Typography>
        <div>
          <Button
            className={classes.icon}
            onClick={() => openPrintPage(sheet._id, 'true', type, `&${WORKLOG_PRINT_QUERY.printAllImgsOnSheet}=true`)}
            disableRipple
          >
            <img src={PrintIcon} />
          </Button>
          <Button
            className={classes.icon}
            onClick={() => downloadImageArchive(sheet._id)}
            disableRipple
          >
            <img src={DownloadIcon} alt="download" />
          </Button>
          <Button
            color={'primary'}
            className={classes.icon}
            onClick={() => handleCustomButtonClick()}
            disableRipple
          >
            <img src={UploadIcon} alt="upload" />
            <input
              type="file"
              accept="image/*"
              multiple
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileChange}
              key={randomKey}
            />
          </Button>
        </div>
      </div>
      <PicCardList
        imgData={imgData}
        handleDeleteNoteImg={handleDeleteNoteImg}
        handleEditNoteImg={handleEditNoteImg}
        sheetId={sheet._id}
      />
    </div>
  );
};

export default withStyles(styles)(PicCardListWrapper);
