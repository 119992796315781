export const MEASUREMENT_SYSTEM = {
  imperial: 'imperial',
  metric: 'metric',
}

export const MEASUREMENT_UNITS = {
  area: 'area',
  areaPerVolume: 'area/volume',
  areaPerWeight: 'area/weight',
  length: 'length',
  volume: 'volume',
  weight: 'weight',
  weightPerVolume: 'weight/volume',
  weightPerArea: 'weight/area',
  width: 'width',
  lengthPerVolume: 'length/volume',
  lengthPerWeight: 'length/weight',
  each: 'each',
  discrete: 'discrete',
}

export const MEASURE_UNITS_BY_SYSTEM = {
  [MEASUREMENT_SYSTEM.imperial]: {
    [MEASUREMENT_UNITS.area]: 'sqft',
    [MEASUREMENT_UNITS.areaPerVolume]: 'sqft/gal',
    [MEASUREMENT_UNITS.areaPerWeight]: 'sqft/lb',
    [MEASUREMENT_UNITS.length]: 'ft',
    [MEASUREMENT_UNITS.lengthPerVolume]: 'ft/gal',
    [MEASUREMENT_UNITS.lengthPerWeight]: 'ft/lb',
    [MEASUREMENT_UNITS.volume]: 'gal',
    [MEASUREMENT_UNITS.weight]: 'lb',
    [MEASUREMENT_UNITS.weightPerVolume]: 'lbs/gal',
    [MEASUREMENT_UNITS.weightPerArea]: 'lbs/sqft',
    [MEASUREMENT_UNITS.width]: 'in',
    [MEASUREMENT_UNITS.each]: 'EA',
    [MEASUREMENT_UNITS.discrete]: 'EA',
  },
  [MEASUREMENT_SYSTEM.metric]: {
    [MEASUREMENT_UNITS.area]: 'm²',
    [MEASUREMENT_UNITS.areaPerVolume]: 'm²/l',
    [MEASUREMENT_UNITS.areaPerWeight]: 'm²/kg',
    [MEASUREMENT_UNITS.length]: 'm',
    [MEASUREMENT_UNITS.lengthPerVolume]: 'm/l',
    [MEASUREMENT_UNITS.lengthPerWeight]: 'm/kg',
    [MEASUREMENT_UNITS.volume]: 'l',
    [MEASUREMENT_UNITS.weight]: 'kg',
    [MEASUREMENT_UNITS.weightPerVolume]: 'kg/l',
    [MEASUREMENT_UNITS.weightPerArea]: 'kg/m²',
    [MEASUREMENT_UNITS.width]: 'cm',
    [MEASUREMENT_UNITS.each]: 'EA',
    [MEASUREMENT_UNITS.discrete]: 'EA',
  }
}