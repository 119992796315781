import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import cipPageActions from 'store/cip/cipPageActions';
import { exportExcel } from 'store/cip/cipPagesOperations';

export default () => {
  const dispatch = useDispatch();
  const { hours } = useSelector((state) => state.cip);
  const exported = hours.exported;
  const { enqueueSnackbar } = useSnackbar();

  const setExported = useCallback(
    (exp) => {
      dispatch(
        cipPageActions.setHours({
          ...hours,
          exported: exp,
        })
      );
    },
    [dispatch, hours]
  );

  const onDownload = useCallback(async () => {
    try {
      await dispatch(exportExcel());
    } catch (e) {
      enqueueSnackbar('Error creating excel report.', { variant: 'error' });
      console.error(e);
    }
  }, [dispatch]);

  return {
    exported,
    setExported,
    onDownload,
  };
};
