import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import styles from './styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { RadioGroup, FormControlLabel } from '@material-ui/core';
import CustomRadioButton from 'components/CustomRadioButton/CustomRadioButton';


const DuplicateModal = ({ classes, isOpen, onSave, onClose, onCancel }) => {
  const [value, setValue] = useState(false);

  const cancel = () => {
    onCancel(false);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose ? onClose : cancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        classes: {
          root: classes.dialogRoot,
        },
      }}
    >
      <IconButton
        className={classes.closeButton}
        onClick={onClose ? onClose : cancel}
        aria-label="Close"
        disableTouchRipple
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle id="alert-dialog-title" className={classes.title}>
        Duplicate the material
      </DialogTitle>

      <DialogContent className={classes.text}>
        <Typography variant="h5">
          Would you like to duplicate the material as it is or
          duplicate it while converting the measurement system?
        </Typography>
      </DialogContent>

      <RadioGroup
        className={classes.radio}
        onChange={(e) => {
          setValue(e.target.value === 'true');
        }}
        value={value}
        row
      >
        <FormControlLabel
          value={false}
          control={<CustomRadioButton big={true} />}
          label={'Duplicate as it is'}
          labelPlacement="end"
          className={classes.leftRadioButton}
        />
        <FormControlLabel
          value={true}
          control={<CustomRadioButton big={true} />}
          label={'Convert the measurement system'}
          labelPlacement="end"
        />
      </RadioGroup>

      <DialogActions style={{ justifyContent: 'space-around' }}>
        <Button
          color="secondary"
          variant="outlined"
          className={classes.cancelButton}
          onClick={cancel}
          disableTouchRipple
        >
          Cancel
        </Button>
        <Button
          type="submit"
          color="primary"
          variant="outlined"
          className={classes.saveButton}
          onClick={() => {
            onSave(value);
          }}
          disableTouchRipple
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(DuplicateModal);
