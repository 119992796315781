export const states = [
  { name: 'Alabama', abrev: 'AL' },
  { name: 'Alaska', abrev: 'AK' },
  { name: 'Arizona', abrev: 'AZ' },
  { name: 'Arkansas', abrev: 'AR' },
  { name: 'California', abrev: 'CA' },
  { name: 'Colorado', abrev: 'CO' },
  { name: 'Connecticut', abrev: 'CT' },
  { name: 'Delaware', abrev: 'DE' },
  { name: 'District of Columbia', abrev: 'DC' },
  { name: 'Florida', abrev: 'FL' },
  { name: 'Georgia', abrev: 'GA' },
  { name: 'Hawaii', abrev: 'HI' },
  { name: 'Idaho', abrev: 'ID' },
  { name: 'Illinois', abrev: 'IL' },
  { name: 'Indiana', abrev: 'IN' },
  { name: 'Iowa', abrev: 'IA' },
  { name: 'Kansas', abrev: 'KS' },
  { name: 'Kentucky', abrev: 'KY' },
  { name: 'Louisiana', abrev: 'LA' },
  { name: 'Maine', abrev: 'ME' },
  { name: 'Maryland', abrev: 'MD' },
  { name: 'Massachusetts', abrev: 'MA' },
  { name: 'Michigan', abrev: 'MI' },
  { name: 'Minnesota', abrev: 'MN' },
  { name: 'Mississippi', abrev: 'MS' },
  { name: 'Missouri', abrev: 'MO' },
  { name: 'Montana', abrev: 'MT' },
  { name: 'Nebraska', abrev: 'NE' },
  { name: 'Nevada', abrev: 'NV' },
  { name: 'New Hampshire', abrev: 'NH' },
  { name: 'New Jersey', abrev: 'NJ' },
  { name: 'New Mexico', abrev: 'NM' },
  { name: 'New York', abrev: 'NY' },
  { name: 'North Carolina', abrev: 'NC' },
  { name: 'North Dakota', abrev: 'ND' },
  { name: 'Ohio', abrev: 'OH' },
  { name: 'Oklahoma', abrev: 'OK' },
  { name: 'Oregon', abrev: 'OR' },
  { name: 'Pennsylvania', abrev: 'PA' },
  { name: 'Rhode Island', abrev: 'RI' },
  { name: 'South Carolina', abrev: 'SC' },
  { name: 'South Dakota', abrev: 'SD' },
  { name: 'Tennessee', abrev: 'TN' },
  { name: 'Texas', abrev: 'TX' },
  { name: 'Utah', abrev: 'UT' },
  { name: 'Vermont', abrev: 'VT' },
  { name: 'Virginia', abrev: 'VA' },
  { name: 'Washington', abrev: 'WA' },
  { name: 'West Virginia', abrev: 'WV' },
  { name: 'Wisconsin', abrev: 'WI' },
  { name: 'Wyoming', abrev: 'WY' },
];
