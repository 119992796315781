export default theme => ({
  dialogRoot: {
    [theme.breakpoints.up('sm')]: {
      width: 380,
    },
    [theme.breakpoints.down('sm')]: {
      margin: 5
    }
  },
  cancelBtn: {
    color: '#fff',
    background: theme.palette.primary.blue,
    '&:hover': {
      color: '#fff',
      background: '#1976D2! important',
    },
    '&:focus': {
      color: '#fff',
      background: `${theme.palette.primary.blue} !important`,
    }
  },
  confirmBtn: {
    color: '#fff',
    // background: '#de6464',
    background: theme.palette.error.red,
    '&:hover': {
      color: '#fff',
      background: '#D32F2F !important'
    },
    '&:focus': {
      color: '#fff',
      background: `${theme.palette.error.red} !important`
    }
  }
});
