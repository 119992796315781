export default (theme) => ({
  root: {
    marginBottom: '50px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '35px',
    },
  },
  scrollableFields: {
    height: '315px',
    overflowY: 'scroll',
    paddingLeft: 26,
    scrollbarColor: '#dadce0 transparent',
    [theme.breakpoints.down('sm')]: {
      paddingRight: 26,
    },
    '&::-webkit-scrollbar,::-webkit-scrollbar-corner': {
      background: 'transparent',
      height: '12px',
      width: '12px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'content-box currentColor',
      border: '2px solid transparent',
      borderRadius: '8px',
      color: '#dadce0',
      minHeight: '48px',
      minWidth: '48px',
    },
    '&:hover::-webkit-scrollbar-thumb': {
      color: '#80868b',
    },
    '&::-webkit-scrollbar-thumb:active': {
      color: '#5f6368',
    },
    '&::-webkit-scrollbar-button': {
      height: 0,
      width: '0',
    },
  },
  field: {
    marginBottom: 18,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  control: {
    width: '100% !important',
    padding: '0 26px',
  },
  marginBottom: {
    marginBottom: '6px',
  },
});
