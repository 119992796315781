import React, { useEffect, useState } from 'react';
import { Label } from '../../../base';
import cs from 'classnames';
import TimeInput from 'components/TimeInput';
import useStyles from '../../useStyles';
import DateInput from 'components/core/DateInput';
import { isValid } from 'date-fns';

const TimeclockForm = ({ error, className, onChange, value: initialValue = {} }) => {
  const [clockIn, setClockIn] = useState(initialValue.clockIn);
  const [clockOut, setClockOut] = useState(initialValue.clockOut);
  const classes = useStyles();

  useEffect(() => {
    onChange({ clockIn, clockOut });
  }, [clockIn, clockOut]);

  return (
    <form className={cs(className, classes.root)}>
      <div className={classes.formTitle}>Clock-in</div>
      <div className={classes.form}>
        <label className={classes.field}>
          <Label>Date:</Label>
          <DateInput value={clockIn} onChange={(date) => setClockIn(new Date(date))} />
        </label>
        <label className={classes.field}>
          <Label>Time:</Label>
          <TimeInput
            value={clockIn}
            onChange={(clockIn) => {
              if (!isValid(clockIn)) return;
              setClockIn(new Date(clockIn));
            }}
          />
        </label>
      </div>
      <div className={classes.formTitle}>Clock-out</div>
      <div className={classes.form}>
        <label className={classes.field}>
          <Label>Date:</Label>
          <DateInput
            value={clockOut}
            onChange={(date) => setClockOut(date ? new Date(date) : null)}
          />
        </label>
        <label className={classes.field}>
          <Label>Time:</Label>
          <TimeInput
            value={clockOut}
            onChange={(clockOut) => {
              if (!isValid(clockOut)) return;
              setClockOut(clockOut);
            }}
          />
        </label>
        {error && (
          <div
            style={{
              color: '#DD6060',
              fontFamily: 'Open Sans',
              fontSize: 11,
              letterSpacing: '0.14px',
              lineHeight: '15px',
            }}
          >
            {error}
          </div>
        )}
      </div>
    </form>
  );
};

export default TimeclockForm;
