export default () => ({
  root: {
    width: '100%',
    boxSizing: 'border-box',
    border: '1px solid #D9DAE3 !important',
    borderRadius: '4px !important',
    backgroundColor: '#FFFFFF !important',
    padding: '10px !important',
    margin: '0 !important',
    '& svg': {
      display: 'block',
      marginLeft: 8,
      marginTop: 1,
      marginBottom: 1,
    },
  },
  input: {
    outline: 'none !important',
    boxShadow: 'none !important',
    border: 'none !important',
    background: 'none !important',
    padding: '0 !important',
    height: '18px !important',
    margin: '0 !important',
    color: '#202020 !important',
    fontSize: '14px !important',
    fontWeight: '500 !important',
    lineHeight: '20px !important',
    '&:hover, &:focus, &:active': {
      outline: 'none !important',
      boxShadow: 'none !important',
      border: 'none !important',
      background: 'none !important',
    },
  },
});
