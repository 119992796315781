export const REGION_TYPE = {
  us: 'us',
  european: 'european',
};

export const DATE_FORMAT_CONFIG = {
  [REGION_TYPE.us]: ['MM-dd-yyyy', 'MM/dd/yyyy', 'M/d/yyyy', 'M/d/yy', 'MM/dd/yy'],
  [REGION_TYPE.european]: ['dd.MM.yyyy', 'dd.MM.yy', 'dd-MM-yyyy', 'dd/MM/yyyy', 'dd/MM/yy'],
};

export const TIME_FORMAT = ['12hour', '24hour']; //12hours
export const DAY_PART = ['AM', 'PM']; //12hours
export const FNS_DAY_PART_FORMAT = {
  upper: 'a', //AM/PM
  lower: 'aaa', //am/pm
};

const fullDate = 'MMMM dd, yyyy';
export const GLOBAL_DATE_FORMAT = {
  fullDateWithDay: 'EEEE, MMMM dd, yyyy', // Thursday, February 29, 2024
  shortDateWithDay: 'EEE, MMM dd, yyyy', // Thu, Feb 29, 2024
  fullDate: fullDate, // February 29, 2024
  fullDateWithTime: `${fullDate}, HH:mm`, // February 29, 2024 05:55
  fullMonthYear: 'MMMM yyyy', // February 2024
  dayContraction: 'EEEEE', // S
};
//export const allDatesFormat = ['dd/MM/yyyy', //! changed [0] format due to wrong new Date() parsing
// storage of all formats
export const allDatesFormat = [
  'dd/MM/yyyy',
  'MM-yyyy',
  'd MMMM',
  'M-d-yy',
  'MM-dd-yyyy',
  'yyyy-MM-dd',
  'MM/dd',
  'MM-dd',
  'M/d',
  'd/M',
];
export const allTimeFormat = [
  `h:mm ${FNS_DAY_PART_FORMAT.upper}`,
  `hh:mm ${FNS_DAY_PART_FORMAT.upper}`,
  'dd/MM/yyyy_h_a',
  `hh:mm ${FNS_DAY_PART_FORMAT.upper}`,
  'HH:mm',
  `hh:mm:ss ${FNS_DAY_PART_FORMAT.upper}`,
  'HH:mm:ss',
];

export const dateSettingsFormat = (dateFormat = allDatesFormat[0], timeFormat = TIME_FORMAT[0]) => {
  const formatForTimePars = timeFormat === TIME_FORMAT[0] ? allTimeFormat[3] : allTimeFormat[4];
  const dateFormatWithTime = `${dateFormat} ${formatForTimePars}`;
  const dayDateSettingsFormat = `iii ${dateFormat}`;
  const fullDayDateSettingsFormat = `iiii ${dateFormat}`;

  return { dateFormat, dateFormatWithTime, dayDateSettingsFormat, fullDayDateSettingsFormat };
};

export const momentAdepter = (dateString) => {
  // First, convert all letters to uppercase, excluding i, e, E, L
  let momentShortDayDate = dateString.replace(/[a-zA-Z]/g, (match) =>
    /[ieEL]/.test(match) ? match : match.toUpperCase()
  );
  // Then, replace all i, e, E, L with d
  momentShortDayDate = momentShortDayDate.replace(/[ieEL]/g, 'd');

  return { momentShortDayDate };
};

export const dateTimeFallback = `dd/MM/yyyy hh:mm ${FNS_DAY_PART_FORMAT.upper}`;
export const startEndFromTravelTimeOnlyFormat = 'yyyy-MM-dd HH:mm:ss';
export const needReplace = 'dd-MM-yyyy';

export const globalBEDateFormat = 'yyyy-MM-dd';

function transformDateFormat(dateFormat) {
  const delimiter = dateFormat.match(/[^a-zA-Z]/)[0];
  const dayFirst = dateFormat.indexOf('d') < dateFormat.indexOf('M');

  const hasDoubleDay = dateFormat.includes('dd');
  const hasDoubleMonth = dateFormat.includes('MM');

  if (dayFirst) {
    return `${hasDoubleDay ? 'dd' : 'd'}${delimiter}${hasDoubleMonth ? 'MM' : 'M'}`;
  } else {
    return `${hasDoubleMonth ? 'MM' : 'M'}${delimiter}${hasDoubleDay ? 'dd' : 'd'}`;
  }
}

export const getTimeFormat = (
  hoursObj = [],
  timeFormat = TIME_FORMAT[0],
  dateFormat = allDatesFormat[0]
) => {
  const formatForTimePars = timeFormat === TIME_FORMAT[0] ? allTimeFormat[3] : allTimeFormat[4];
  const formatForTimeParsSec = timeFormat === TIME_FORMAT[0] ? allTimeFormat[5] : allTimeFormat[6];

  const tmpHoursObj = [...hoursObj];

  if (tmpHoursObj[1]) {
    tmpHoursObj[1] = String(tmpHoursObj[1]).padStart(2, '0');
  }

  const formattedTime =
    timeFormat === TIME_FORMAT[0]
      ? `${tmpHoursObj[1]}:${tmpHoursObj[2]} ${tmpHoursObj?.[3] || formatToAmPm(tmpHoursObj[1])}`
      : `${tmpHoursObj[1]}:${tmpHoursObj[2]}`;
  const formattedTimeDate = `${tmpHoursObj?.[0]} ${formattedTime}`;
  const formatForDateTimePars = `${dateFormat} ${formatForTimePars}`;
  const is12Format = timeFormat === TIME_FORMAT[0];
  const localDayMonth = transformDateFormat(dateFormat);

  return {
    formattedTime,
    formattedTimeDate,
    formatForTimePars,
    formatForDateTimePars,
    is12Format,
    formatForTimeParsSec,
    localDayMonth,
  };
};

export const formatToAmPm = (hour) => {
  const amPm = parseInt(hour, 10) >= 12 ? DAY_PART[1] : DAY_PART[0]; //PM : AM
  return amPm;
};
export const formatToAmPmHour = (hour) => {
  const formattedHour = parseInt(hour, 10) % 12 || 12;

  return formattedHour;
};

export const convertTo24HourFormat = (hour, amPm) => {
  let hourIn24 = parseInt(hour, 10);

  if (amPm === 'PM' && hourIn24 < 12) {
    hourIn24 += 12;
  } else if (amPm === 'AM' && hourIn24 === 12) {
    hourIn24 = 0;
  }

  return String(hourIn24).padStart(2, '0');
};

export const convertTime = (timeObj, is12Format) => {
  const { hour, minute } = timeObj;

  if (is12Format) {
    const amPm = formatToAmPm(hour);
    const formattedHour = formatToAmPmHour(hour);

    return `${formattedHour}:${minute} ${amPm}`;
  } else {
    return `${hour}:${minute}`;
  }
};

export const convertTimeObject = (timeObj, is12Format) => {
  const { hour, minute } = timeObj;

  if (is12Format) {
    const formattedHour = formatToAmPmHour(hour);
    const amPm = formatToAmPm(hour);

    return {
      hour: String(formattedHour),
      minute,
      amPm,
    };
  } else {
    return {
      hour: String(parseInt(hour, 10)),
      minute,
      amPm: null,
    };
  }
};
