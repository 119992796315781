// import { Box, withStyles } from '@material-ui/core/index';

// export const Root = withStyles({
//   root: {
//     padding: '17px 16px 17px 14px',
//   },
// })(Box);
export const styles = (theme) => ({
  root: {
    padding: '17px 16px 17px 14px',
  },
  rootMob: {
    padding: '17px 16px 17px 14px',
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
    '@media print': {
      padding: '17px 16px 17px 14px',
    }
  },
  timeThisWeek: {
    display: 'inline-block',
    margin: '0 10px 0 0',
    color: '#A4A5B3',
    fontFamily: 'Open Sans',
    fontSize: 14,
    fontWeight: 600,
    [theme.breakpoints.down('md')]: {
      minWidth: 115,
    },
  },
  timeTotal: {
    textAlign: 'right',
    [theme.breakpoints.down('md')]: {
      textAlign: 'inherit',
      minWidth: 125,
      width: 130,
      marginLeft: 'auto',
    },
    '@media print': {
      textAlign: 'right',
      minWidth: 'auto',
      width: 'auto',
    }
  },
  timeTotalWrapper: {
    flexGrow: 0,
    maxWidth: '33.3333%',
    flexBasis: '33.3333%',
    [theme.breakpoints.down('md')]: {
      width: 130,
      maxWidth: 'none',
      flexBasis: 'auto',
    },
    '@media print': {
      flexGrow: 0,
      maxWidth: '33.3333%',
      flexBasis: '33.3333%',
      width: 'auto',

    }
  },
  timeSectionTimeWord: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'inline-block',
      marginLeft: 5,
      color: '#202020',
      fontSize: 14,
      textAlign: 'right',
      fontWeight: 500,
      letterSpacing: 0.25,
    },
    '@media print': {
      display: 'none',
    }
  },
  hourThisWeek: {
    padding: '0 !important',
    [theme.breakpoints.down('md')]: {
      maxWidth: '37.333333%',
      flexBasis: '25.333333%',
      padding: '0 0 0 16px !important',
    },
    '@media (max-width: 370px)': {
      maxWidth: '47.333333%',
    },
  },
  footerHourThisWeek: {
    alignItems: 'center',
    width: '100%',
    margin: 0,
    [theme.breakpoints.down('md')]: {
      // alignItems: 'flex-end',
      width: 'auto',
    },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'nowrap',
    },
    '@media (max-width: 370px)': {
      flexWrap: 'wrap',
    },
  },
});
