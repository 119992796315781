import React, { useMemo, useState } from 'react';
import propTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classnames from 'classnames';
import { endOfDay, parse, format, isBefore } from 'date-fns';
import CloseIconGrey from 'assets/images/close-icon-grey.svg';
import EditIconGrey from 'assets/images/edit-icon-grey.svg';

import styles from 'pages/Availability/components/Timeoffs/components/TimeoffList/slyles';
import {
  StyledTableCell,
  StyledTableRow,
} from 'pages/Availability/components/Timeoffs/components/TimeoffList/TimeoffList';

import { styles as stylesMob } from './styles';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';
import { convertTime, globalBEDateFormat } from 'common/dateFormatConfig';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';

const useStyles = makeStyles(styles);
const useStylesMob = makeStyles(stylesMob);

export const TimeoffList = ({ timeoffs, openModal, openDrawer, future }) => {
  const classes = useStyles();
  const classesMob = useStylesMob();
  const { dateFormat } = useDateSettingsFormat();
  const getTimeFormatHook = useTimeSettingsFormat();

  const formatDate = (date) => format(parse(date, globalBEDateFormat, new Date()), dateFormat);
  const formatTime = (time) =>
    convertTime(time, getTimeFormatHook().is12Format);
  const [isExpanded, setIsExpanded] = useState(false);
  const [listLenght, setlistLenght] = useState(2);

  const canEditDelete = (timeoff) => {
    if (timeoff.status.toLowerCase() === 'denied') return false;
    const date = timeoff.utcStartDate
      ? new Date(timeoff.utcStartDate)
      : endOfDay(parse(timeoff.startDate, dateFormat, new Date()));
    return isBefore(new Date(), date);
  };

  const header = useMemo(() => `${future ? 'Future' : 'Past'} Times Off`, [future]);
  const formatReason = (reason) => (reason === 'Bereavement/FMLA' ? 'Bereavement / FMLA' : reason);

  if (!timeoffs.length) return null;

  const handleExpandClick = (e) => {
    e.stopPropagation();
    setIsExpanded((prev) => !prev);
    isExpanded ? setlistLenght(2) : setlistLenght(timeoffs.length);
  };

  return (
    <div className={classesMob.timeOffListWrapper}>
      <Typography variant="h4" className={classesMob.rootTitle}>
        {header}
      </Typography>
      <Paper className={classnames(classes.root, classesMob.rootDesctop)}>
        <Table className={classes.table} style={{ tableLayout: 'fixed' }}>
          <TableHead className={classnames(classes.tableHeader)}>
            <TableRow>
              <StyledTableCell colSpan={2} className={classnames(classes.cell, classes.datesCell)}>
                Start date
              </StyledTableCell>
              <StyledTableCell colSpan={2} className={classnames(classes.cell, classes.datesCell)}>
                End date
              </StyledTableCell>
              <StyledTableCell colSpan={2} className={classnames(classes.cell, classes.timeCell)}>
                Start time
              </StyledTableCell>
              <StyledTableCell colSpan={2} className={classnames(classes.cell, classes.timeCell)}>
                End time
              </StyledTableCell>
              <StyledTableCell colSpan={1} className={classnames(classes.cell, classes.hoursCell)}>
                Hours
              </StyledTableCell>
              <StyledTableCell colSpan={3} className={classnames(classes.cell, classes.typeCell)}>
                Type
              </StyledTableCell>
              <StyledTableCell colSpan={1} className={classnames(classes.cell, classes.ptoCell)}>
                PTO
              </StyledTableCell>
              <StyledTableCell colSpan={4} className={classnames(classes.cell, classes.notesCell)}>
                Notes
              </StyledTableCell>
              <StyledTableCell colSpan={3} className={classes.cell}>
                Status
              </StyledTableCell>
              <StyledTableCell
                colSpan={3}
                className={classnames(classes.cell, classes.actionsCell)}
              >
                Actions
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {timeoffs.map((timeoff) => (
              <StyledTableRow key={timeoff._id}>
                <StyledTableCell
                  colSpan={2}
                  className={classnames(classes.cell, classes.datesCell)}
                >
                  {formatDate(timeoff.startDate)}
                </StyledTableCell>
                <StyledTableCell
                  colSpan={2}
                  className={classnames(classes.cell, classes.datesCell)}
                >
                  {formatDate(timeoff.endDate)}
                </StyledTableCell>
                <StyledTableCell colSpan={2} className={classnames(classes.cell, classes.timeCell)}>
                  {timeoff.startTime ? (
                    formatTime(timeoff.startTime)
                  ) : (
                    <Typography style={{ paddingTop: 5, paddingLeft: '1.35rem' }} variant="body2">
                      -
                    </Typography>
                  )}
                </StyledTableCell>
                <StyledTableCell colSpan={2} className={classnames(classes.cell, classes.timeCell)}>
                  {timeoff.endTime ? (
                    formatTime(timeoff.endTime)
                  ) : (
                    <Typography style={{ paddingTop: 5, paddingLeft: '1.35rem' }} variant="body2">
                      -
                    </Typography>
                  )}
                </StyledTableCell>
                <StyledTableCell
                  colSpan={1}
                  className={classnames(classes.cell, classes.hoursCell)}
                >
                  {timeoff.timeoffDuration}
                </StyledTableCell>
                <StyledTableCell colSpan={3} className={classnames(classes.cell, classes.typeCell)}>
                  {formatReason(timeoff.reason)}
                </StyledTableCell>
                <StyledTableCell colSpan={1} className={classnames(classes.cell, classes.ptoCell)}>
                  {timeoff.isPTO ? 'Yes' : 'No'}
                </StyledTableCell>
                <StyledTableCell
                  colSpan={4}
                  className={classnames(classes.cell, classes.notesCell)}
                >
                  {timeoff.notes}
                </StyledTableCell>
                <StyledTableCell colSpan={3} className={classes.cell}>
                  {timeoff.status}
                </StyledTableCell>
                <StyledTableCell colSpan={3}>
                  <div className={classes.flexBox}>
                    {canEditDelete(timeoff) ? (
                      <>
                        <Button
                          color="secondary"
                          className={classes.actionBtn}
                          onClick={openDrawer(timeoff)}
                          disableRipple
                        >
                          <img src={EditIconGrey} alt="edit" />
                        </Button>
                        <Button
                          color="secondary"
                          className={classes.actionBtn}
                          onClick={openModal('delete', timeoff._id)}
                          disableRipple
                        >
                          <img src={CloseIconGrey} alt="delete" />
                        </Button>
                      </>
                    ) : (
                      <Typography
                        style={{ paddingTop: 5, textAlign: 'center' }}
                        variant="body2"
                        color="secondary"
                      >
                        No actions allowed
                      </Typography>
                    )}
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      {timeoffs.slice(0, listLenght).map((timeoff) => (
        <div className={classesMob.wrap} key={timeoff._id}>
          <div className={classesMob.projectType}>
            <div className={classesMob.textTheme}>Start date:</div>
            <div className={classesMob.subTextTheme}>{formatDate(timeoff.startDate)}</div>
          </div>
          <div className={classesMob.projectType}>
            <div className={classesMob.textTheme}>End date:</div>
            <div className={classesMob.subTextTheme}>{formatDate(timeoff.endDate)}</div>
          </div>
          <div className={classesMob.projectType}>
            <div className={classesMob.textTheme}>Start time:</div>
            <div className={classesMob.subTextTheme}>
              {timeoff.startTime ? (
                formatTime(timeoff.startTime)
              ) : (
                <Typography style={{ paddingTop: 5, paddingLeft: '1.35rem' }} variant="body2">
                  -
                </Typography>
              )}
            </div>
          </div>
          <div className={classesMob.projectType}>
            <div className={classesMob.textTheme}>End time:</div>
            <div className={classesMob.subTextTheme}>
              {timeoff.endTime ? (
                formatTime(timeoff.endTime)
              ) : (
                <Typography style={{ paddingTop: 5, paddingLeft: '1.35rem' }} variant="body2">
                  -
                </Typography>
              )}
            </div>
          </div>
          <div className={classesMob.projectType}>
            <div className={classesMob.textTheme}>Hours:</div>
            <div className={classesMob.subTextTheme}>{timeoff.timeoffDuration}</div>
          </div>
          <div className={classesMob.projectType}>
            <div className={classesMob.textTheme}>Type:</div>
            <div className={classesMob.subTextTheme}>{formatReason(timeoff.reason)}</div>
          </div>
          <div className={classesMob.projectType}>
            <div className={classesMob.textTheme}>PTO:</div>
            <div className={classesMob.subTextTheme}>{timeoff.isPTO ? 'Yes' : 'No'}</div>
          </div>
          <div className={classesMob.projectTypeBt}>
            <div className={classesMob.textThemeNotes}>Notes:</div>
            <div className={classesMob.subTextTheme}>{timeoff.notes}</div>
          </div>
          <div className={classesMob.projectType}>
            <div className={classesMob.textTheme}>Status:</div>
            <div className={classesMob.subTextTheme}>{timeoff.status}</div>
          </div>
          <div className={classesMob.projectType}>
            {canEditDelete(timeoff) ? (
              <>
                <Button
                  color="secondary"
                  className={classes.actionBtn}
                  onClick={openDrawer(timeoff)}
                  disableRipple
                >
                  <img src={EditIconGrey} alt="edit" />
                </Button>
                <Button
                  color="secondary"
                  className={classes.actionBtn}
                  onClick={openModal('delete', timeoff._id)}
                  disableRipple
                >
                  <img src={CloseIconGrey} alt="delete" />
                </Button>
              </>
            ) : (
              <Typography style={{ paddingTop: 5 }} variant="body2" color="secondary">
                No actions allowed
              </Typography>
            )}
          </div>
        </div>
      ))}
      {timeoffs.length > 2 && (
        <div className={classesMob.actionsWrapper} disableSpacing onClick={handleExpandClick}>
          <p className={classesMob.textIconMore}>
            {isExpanded ? 'Hide' : `Show more (${timeoffs.length - 2})`}
          </p>
          <div className={isExpanded ? classesMob.nonActionsIcon : classesMob.actionsIcon}>
            <ExpandMoreIcon />
          </div>
        </div>
      )}
    </div>
  );
};

TimeoffList.propTypes = {
  timeoffs: propTypes.array.isRequired,
  future: propTypes.bool,
  openModal: propTypes.func.isRequired,
  openDrawer: propTypes.func.isRequired,
};
