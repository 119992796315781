import React, { Component } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import photoPlaceholder from 'assets/images/user-photo-placeholder.svg';
import IOSSwitch from '../../../../../IOSSwitch';
import HoursBlock from '../../../HoursBlock';
import styles from './styles';
import { isValidTimePeriods, isHoursOverlapseTimeOffs } from '../../../../helpers';
import StartTimeBlock from '../StartTimeBlock';
import Divider from '@material-ui/core/Divider';
import { setMinutes } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';

class SelectedUser extends Component {
  setForeman = () => {
    const { updateSelectedResource, workerData } = this.props;
    const updatedData = {
      ...workerData,
      foreman: !workerData.foreman,
    };
    updateSelectedResource(workerData._id, updatedData);
  };

  removeResource = () => {
    const { removeSelectedResource, workerData } = this.props;
    removeSelectedResource(workerData._id);
  };

  addResourceHours = (index) => {
    const { updateSelectedResource, workerData, sheet, user } = this.props;
    const id = uuidv4();

    let hours;
    if (index === 'new') {
      hours = workerData.hours
        ? [
            ...workerData.hours,
            {
              start: new Date(sheet.hours?.start || setMinutes(new Date(), 0)),
              end: new Date(sheet.hours?.end || setMinutes(new Date(), 0)),
              id,
              isWlHours: false,
              isWlTravelTime: true,
            },
          ]
        : [
            {
              start: new Date(sheet.hours?.start || setMinutes(new Date(), 0)),
              end: new Date(sheet.hours?.end || setMinutes(new Date(), 0)),
              id,
              isWlHours: false,
              isWlTravelTime: true,
            },
          ];
    } else if (index === 'full') {
      hours = [
        ...workerData.hours,
        {
          isWlTravelTime: false,
          isWlHours: false,
          id,
        },
      ];
    } else {
      hours = workerData.hours
        ? workerData.hours.map((el, i) =>
            i === index
              ? {
                  ...el,
                  isWlHours: false,
                }
              : el
          )
        : [
            {
              start: new Date(sheet.hours?.start || setMinutes(new Date(), 0)),
              end: new Date(sheet.hours?.end || setMinutes(new Date(), 0)),
              id,
              isWlHours: false,
              isWlTravelTime: true,
            },
          ];
    }

    const updatedData = {
      ...workerData,
      hours,
    };

    const isValidHours = isValidTimePeriods(hours);
    if (isValidHours) {
      const isInvalidHoursByTimeoffs = isHoursOverlapseTimeOffs(
        hours,
        workerData.availableHours,
        sheet
      );
      if (!isInvalidHoursByTimeoffs) {
        delete updatedData.error;
      } else {
        updatedData.error = 'User has timeoff at this time';
      }
    } else {
      if (!sheet.travelTimeOnly) updatedData.error = 'Time periods cross each other';
    }
    updateSelectedResource(workerData._id, updatedData);
  };

  addTravelTime = (index) => {
    const { updateSelectedResource, workerData, user } = this.props;
    const id = uuidv4();

    let hours;
    if (index === 'new') {
      hours = workerData.hours
        ? [
            ...workerData.hours,
            {
              isWlTravelTime: false,
              isWlHours: true,
              id,
            },
          ]
        : [
            {
              isWlTravelTime: false,
              isWlHours: true,
              id,
            },
          ];
    } else {
      hours = workerData.hours
        ? workerData.hours.map((el, i) => (i === index ? { ...el, isWlTravelTime: false } : el))
        : [
            {
              isWlTravelTime: false,
              isWlHours: true,
              id,
            },
          ];
    }

    const updatedData = {
      ...workerData,
      hours,
    };
    updateSelectedResource(workerData._id, updatedData);
  };

  removeResourceHours = (index) => () => {
    const { updateSelectedResource, workerData, sheet } = this.props;
    // let filteredHours = workerData.hours
    //   .map((h, i) => (i === index ? { ...h, isWlHours: true } : h))
    //   .filter((el) => !(el.isWlTravelTime && el.isWlHours));
    let filteredHours = workerData.hours.filter((h, i) => i !== index);

    if (!filteredHours.length) filteredHours = null;
    const updatedData = {
      ...workerData,
      hours: filteredHours,
      startTime: null,
    };

    const hoursToCheck = filteredHours || [sheet.hours];
    const isValidHours = isValidTimePeriods(hoursToCheck);
    if (isValidHours) {
      const isValidHoursByTimeoffs = !isHoursOverlapseTimeOffs(
        hoursToCheck,
        workerData.availableHours,
        sheet
      );
      if (isValidHoursByTimeoffs) {
        delete updatedData.error;
      } else {
        updatedData.error = 'User has timeoff at this time';
      }
    } else {
      if (!sheet.travelTimeOnly) updatedData.error = 'Time periods cross each other';
    }
    updateSelectedResource(workerData._id, updatedData);
  };

  removeResourceTravelTime = (index) => () => {
    const { updateSelectedResource, workerData, sheet } = this.props;

    let filteredHours;
    if (sheet.travelTimeOnly) {
      filteredHours = workerData.hours.filter((h, i) => i !== index);
    } else {
      filteredHours = workerData.hours
        .map((h, i) => (i === index ? { ...h, isWlTravelTime: true } : h))
        .filter((el) => !(el.isWlTravelTime && el.isWlHours));
    }

    if (!filteredHours.length) filteredHours = null;
    const updatedData = {
      ...workerData,
      hours: filteredHours,
    };
    updateSelectedResource(workerData._id, updatedData);
  };

  setStartTime = (value) => {
    const { updateSelectedResource, workerData } = this.props;

    const updatedData = {
      ...workerData,
      startTime: {
        ...(workerData.startTime || {}),
        ...value,
      },
    };
    updateSelectedResource(workerData._id, updatedData);
  };

  updateStartTime = (type) => (e) => {
    const { updateSelectedResource, workerData } = this.props;

    const updatedData = {
      ...workerData,
      startTime: {
        ...(workerData.startTime || {}),
        [type]: e.target.value,
      },
    };
    updateSelectedResource(workerData._id, updatedData);
  };

  updateResourceHours = (index) => (data) => {
    const { updateSelectedResource, workerData, sheet } = this.props;
    const updatedHours = [...workerData.hours];
    updatedHours[index] = data;
    const updatedData = {
      ...workerData,
      hours: updatedHours,
    };

    const hoursToCheck = updatedHours.length ? updatedHours : [sheet.hours];
    const isValidHours = isValidTimePeriods(hoursToCheck);

    if (isValidHours) {
      const isValidHoursByTimeoffs = !isHoursOverlapseTimeOffs(
        hoursToCheck,
        workerData.availableHours,
        sheet
      );
      if (isValidHoursByTimeoffs) {
        delete updatedData.error;
      } else {
        updatedData.error = 'User has timeoff at this time';
      }
    } else {
      if (!sheet.travelTimeOnly) updatedData.error = 'Time periods cross each other';
    }
    updateSelectedResource(workerData._id, updatedData);
  };

  getResourceError = () => this.props.workerData.error;

  render() {
    const { classes, user, sheet, workerData } = this.props;
    const isAnyCropped =
      !sheet.travelTimeOnly &&
      !!workerData?.hours?.length &&
      workerData?.hours?.some((el) => el.isWlHours === true || el.isWlTravelTime === true);

    return (
      <Grid container className={classes.paddingBottom}>
        <Grid item xs={6} className={classes.paddingBottom}>
          <div className={classes.flexBox}>
            {user && (
              <div className={classes.inlineFlex}>
                <div
                  className={classnames(
                    classes.photoWrapper,
                    classes[`${user.profile.shifts?.timeOfDay?.toLowerCase()}Border`]
                  )}
                >
                  <img src={photoPlaceholder} className={classes.photo} />
                </div>
                <Typography
                  variant="body1"
                  color="textPrimary"
                  className={classnames(
                    classes.name,
                    classes[user.profile.shifts?.timeOfDay?.toLowerCase()]
                  )}
                >
                  {user.username}
                </Typography>
              </div>
            )}

            <Button
              variant="text"
              className={workerData.foreman ? classes.activeButton : classes.inactiveButton}
              onClick={this.setForeman}
              disableRipple
            >
              (CL)
            </Button>
          </div>
        </Grid>
        <Grid item xs={6} className={classnames(classes.rtsBlock, classes.paddingBottom)}>
          <IconButton
            className={classes.closeButton}
            onClick={this.removeResource}
            aria-label="Close"
            disableRipple
            disableTouchRipple
          >
            <CloseIcon />
          </IconButton>
        </Grid>
        {workerData.hours && !!workerData.hours.length && (
          <Grid item xs={12} className={classes.hoursBlock}>
            <StartTimeBlock
              sheet={sheet}
              data={workerData.startTime}
              updateStartTime={this.updateStartTime}
              setStartTime={this.setStartTime}
              canEdit
            />
          </Grid>
        )}

        {workerData.hours &&
          workerData.hours.length &&
          workerData.hours.map((h, i) => (
            <>
              <Grid item xs={12} key={h.id + i} className={classes.hoursBlock}>
                <HoursBlock
                  sheet={sheet}
                  itemData={workerData}
                  itemHours={h}
                  deleteTime={this.removeResourceHours(i)}
                  deleteTravelTime={this.removeResourceTravelTime(i)}
                  updateResourceHours={this.updateResourceHours(i)}
                  availableUserHours={workerData.availableHours}
                  isWorkersType
                  isAnyCropped={isAnyCropped}
                />
              </Grid>
              <Grid
                container
                item
                direction="column"
                xs={12}
                alignItems={'center'}
                justifyContent={'center'}
                className={classes.paddingBottom}
              >
                {h.isWlHours && !sheet.travelTimeOnly && (
                  <Grid item xs={4} md={5} className={classes.textCenter}>
                    <Button
                      variant="text"
                      onClick={() => this.addResourceHours(i)}
                      className={classes.addButton}
                      disableRipple
                      disableTouchRipple
                    >
                      + Add Hours
                    </Button>
                  </Grid>
                )}
                {h.isWlTravelTime && !sheet.travelTimeOnly && (
                  <Grid item xs={6} md={4} className={classes.textCenter}>
                    <Button
                      variant="text"
                      onClick={() => this.addTravelTime(i)}
                      className={classnames(classes.addButton, classes.textCenter)}
                      disableRipple
                      disableTouchRipple
                    >
                      + Individual Travel Time
                    </Button>
                  </Grid>
                )}
              </Grid>
            </>
          ))}
        {Boolean(this.getResourceError()) && (
          <div className={classes.error}>{this.getResourceError()}</div>
        )}
        <Grid container>
          <Grid item xs={12} style={{ margin: '15px 0' }}>
            <Divider />
          </Grid>
        </Grid>
        {
          <Grid
            container
            item
            direction="row"
            xs={12}
            alignItems={'center'}
            justifyContent={'center'}
            className={classes.paddingBottom}
          >
            {!sheet.travelTimeOnly &&
              !workerData?.hours?.[workerData?.hours?.length - 1]?.isWlHours && (
                <Grid item xs={4} md={5} className={classes.textCenter}>
                  <Button
                    variant="text"
                    onClick={() => {
                      if (!workerData?.hours?.length) return this.addResourceHours('new');
                      this.addResourceHours(isAnyCropped ? 'full' : 'new');
                    }}
                    className={classes.addButton}
                    disableRipple
                    disableTouchRipple
                  >
                    + Add Hours
                  </Button>
                </Grid>
              )}
            {(!isAnyCropped || sheet.travelTimeOnly) && (
              <Grid item xs={6} md={4} className={classes.textCenter}>
                <Button
                  variant="text"
                  onClick={() => this.addTravelTime('new')}
                  className={classnames(classes.addButton, classes.textCenter)}
                  disableRipple
                  disableTouchRipple
                >
                  + Individual Travel Time
                </Button>
              </Grid>
            )}
          </Grid>
        }
      </Grid>
    );
  }
}

SelectedUser.propTypes = {
  classes: propTypes.object.isRequired,
  user: propTypes.object.isRequired,
  sheet: propTypes.object.isRequired,
  workerData: propTypes.object.isRequired,
  updateSelectedResource: propTypes.func.isRequired,
  removeSelectedResource: propTypes.func.isRequired,
};

export default withStyles(styles)(SelectedUser);
