export default (theme) => ({
  textField: {
    [theme.breakpoints.up('lg')]: {
      // maxWidth: 250,
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense':{
      color: theme.palette.textColor.secondary,
      transform: 'translate(14px, 9px) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink':{
      display: 'none'
    }
  },
  timeSelectWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
    },
  },
  timeSelectFields: {
    maxWidth: 100,
    [theme.breakpoints.down('md')]: {
      marginRight: 10,
    },
  },
  shiftImage: {
    height: 16,
    margin: '0 5px 2px 0',
  },
  error: {
    color: theme.palette.textColor.red,
    padding: '0 5px',
    fontSize: 11,
  },
  requiredSign: {
    color: theme.palette.textColor.red,
  },
  zeroPaddingTop: {
    paddingTop: '0px !important',
  },
  paddingTop: {
    paddingTop: 8,
  },
  deleteButton: {
    color: theme.palette.error.red,
    padding: 0,
    marginBottom: 14,
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff !important',
      color: `${theme.palette.error.red} !important`,
    },
    '&:focus': {
      backgroundColor: '#fff !important',
      color: `${theme.palette.error.red} !important`,
    },
  },
  deleteButtonDoc: {
    color: theme.palette.error.red,
    padding: 0,
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff !important',
      color: `${theme.palette.error.red} !important`,
    },
    '&:focus': {
      backgroundColor: '#fff !important',
      color: `${theme.palette.error.red} !important`,
    },
  },
  locationContainer: {
    alignItems: 'flex-start',
    [theme.breakpoints.only('xs')]: {
      alignItems: 'center',
    },
  },
  adornment: {
    color: theme.palette.textColor.secondary,
    paddingRight: '0.4rem',
    fontSize: '0.875rem',
  },
  primaryButton: {
    padding: 4,
    minWidth: 'auto',
    color: theme.palette.textColor.primary,
    '&:hover': {
      color: theme.palette.textColor.primary,
      background: theme.palette.background.default,
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
      background: theme.palette.background.default,
    },
  },
  legendAlign: {
    marginTop: 27,
  },
  primaryAdd: {
    color: theme.palette.textColor.primary,
    paddingBottom: '15px',
    paddingTop: '5px',
    fontSize: '14px',
    fontWeight: '500',
    '& span': {
      cursor: 'pointer',
    },
  },
  clearButtonWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  clearButton: {
    color: theme.palette.error.red,
    paddingBottom: '10px',
    fontSize: '12px',
    fontWeight: '500',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.textColor.red,
      background: theme.palette.background.default,
    },
    '&:focus': {
      color: theme.palette.textColor.red,
      background: theme.palette.background.default,
    },
  },
  flexBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  primary: {
    color: theme.palette.textColor.primary,
  },
  primaryTextButton: {
    padding: 0,
    marginRight: 5,
    color: theme.palette.textColor.primary,
    '& span': {
      '&:first-letter': {
        fontSize: 20,
      },
    },
    '&:hover': {
      color: theme.palette.textColor.primary,
      background: `${theme.palette.background.default} !important`,
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
      background: `${theme.palette.background.default} !important`,
    },
  },
  customSelect: {
    '& .MuiSvgIcon-root': {
      display: 'none',
    },
  },
  zeroPaddingTop: {
    paddingTop: '0px !important',
  },
  fullWidth: {
    width: '100%',
  },
  requiredSign: {
    color: theme.palette.textColor.red,
  },
  error: {
    color: theme.palette.textColor.red,
    padding: '0 5px',
    fontSize: 11,
    marginTop: 8,
  },
  delCustomSelectIcon: {
    color: theme.palette.error.red,
    cursor: 'pointer',
  }
});
