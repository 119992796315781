import { after } from 'lodash';

export default (theme) => ({
  root: {
    padding: '10px 12px 8px',
    border: '1px solid #d9dae3',
    borderRadius: 5,
    '& dl':{
      marginBottom: '1.25rem',
    }
  },
  header: {
    position: 'relative',
  },
  divider: {
    margin: '12px 0 0',
    border: '1px solid #DBDCE4 none none none',
  },
  title: {
    color: '#202020',
    fontWeight: 500,
    width: 'calc(100% - 30px)',
    fontSize: '0.95rem',
    lineHeight: 1.6,
    display: 'block',
  },
  number: {
    marginTop: 8,
    display: 'block',
    fontWeight: 500,
    fontSize: '0.8rem',
    lineHeight: 1.2,
    color: '#a4a5b3',
  },
  deleteButton: {
    position: 'absolute',
    minWidth: 24,
    width: 24,
    height: 24,
    right: 0,
    top: 0,
    padding: 5,
  },
  body: {},
  definitionList: {
    margin: '0 0 2px',
  },
  definitionTitle: {
    color: '#A4A5B6',
    fontSize: 13,
    fontFamily: 'Open Sans',
    fontWeight: 400,
    margin: '10px 0 0',
    letterSpacing: 0.16,
    lineHeight: '18px',
  },
  definitionDefinition: {
    margin: '6px 0 0',
    color: '#202020',
    fontSize: 13,
    fontWeight: 500,
    letterSpacing: 0.16,
    lineHeight: '20px',
  },

  dublicateButton: {
    // cursor: 'hover',
    padding: '0',
    //   display: 'flex',
    //   justifyContent: 'center',
    //   alignItems: 'center',
    // width: 'calc(100% + 24px)',
    //   height: '40px',
    margin: '0',
    textAlign: 'start',
    background: 'none',
    border: 'none',
    outline: 'none',
    color: '#A4A5B3',
    fontSize: 12,
    // lineHeight: '18px',
    '&:hover': {
      background: 'none',
      color: '#A4A5B3',
    },
    '&:focus': {
      background: 'none',
      color: '#A4A5B3',
    },
  },
  dublicateButtonTitle: {
    fontWeight: '500',
    lineHeight: '18px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    color: '#6288E8',
  },
  actionsWrapper: {
    display: 'flex',
    cursor: 'pointer',
    color: '#5a87ef',
    alignItems: 'center',
  },
  actionsIcon: {
    transform: 'rotate(0deg) translateY(2px)',
  },
  nonActionsIcon: {
    transform: 'rotate(180deg) translateY(2px)',
  },
  textIconMore: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 700,
    marginBottom: 0,
    color: '#5a87ef',
  },
  buttonsWrapper: {
    display: 'flex',
    alignItems: 'center',
    margin: '5px 0 0',
    minHeight: '23px',
  },
});
