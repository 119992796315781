import React, { useState } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { notesResolve } from 'pages/NotesByProject/apiCalls';

import styles from './styles';

const ResolveModal = ({ classes, isOpen, onClose, noteId, closeDrawer, updateVisibleNotes }) => {
  const [comment, setComment] = useState('');

  const handleChange = e => setComment(e.target.value);

  const handleSubmit = async () => {
    try {
      await notesResolve(comment, noteId)
      updateVisibleNotes()
      onClose();
      closeDrawer();
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        classes: {
          root: classes.dialogRoot
        }
      }}
    >
      <div className={classes.header}>
        <Typography variant="h5" color="textPrimary">
          Resolve this note
        </Typography>
        <IconButton
          className={classes.closeButton}
          onClick={onClose}
          aria-label="Close"
          disableRipple
          >
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent style={{ paddingLeft: '20px' }}>
        <Typography variant="body1" color="textSecondary">
          Optional comment
        </Typography>
        <TextField
          id="note"
          name="note"
          rowsMax={2}
          value={comment}
          onChange={handleChange}
          className={classes.textArea}
          variant="outlined"
          multiline
          fullWidth
          />
      </DialogContent>
      <DialogActions>
        <div className={classes.flexBox}>
          <Button
            color="secondary"
            variant="outlined"
            className={classes.cancelButton}
            onClick={onClose}
            disableTouchRipple
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="outlined"
            className={classes.saveButton}
            onClick={handleSubmit}
            disableTouchRipple
          >
            Resolve
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

ResolveModal.propTypes = {
  classes: propTypes.object.isRequired,
  isOpen: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  noteId: propTypes.string,
  closeDrawer: propTypes.func.isRequired
};

export default withStyles(styles)(ResolveModal);
