import $api from 'http/index';
import { format } from 'date-fns';
import { getOptions } from 'helpers/getOptions';
import workerSchedulePageActions from './workerSchedulePageActions';
import { globalBEDateFormat } from 'common/dateFormatConfig';

export const catchError = (error, dispatch) => {
  let message = error.message;
  if (error?.response?.data?.message) {
    if (Array.isArray(error.response.data.message))
      message = error.response.data.message.join('\n');
    else message = error.response.data.message;
  }

  dispatch(workerSchedulePageActions.snackBarInfo({ isOpen: true, type: 'error', text: message }));
  if (error.response.data.statusCode > 299) throw new Error(message);
};

// Full Schedule

export const fetchFullScheduleSheets = (date) => async (dispatch) => {
  try {
    const formattedDate = format(new Date(date), globalBEDateFormat);

    const response = await $api.get(
      `${process.env.REACT_APP_BASE_URL}/sheets/schedule/full-schedule`,
      { ...getOptions(), params: { scheduleDate: formattedDate } }
    );

    dispatch(workerSchedulePageActions.getFullScheduleSheets(response.data));
  } catch (error) {
    catchError(error, dispatch);
  }
};

// My Schedule

export const fetchMyScheduleSheets = (date) => async (dispatch) => {
  try {
    const response = await $api.get(
      `${process.env.REACT_APP_BASE_URL}/sheets/schedule/my-schedule`,
      { ...getOptions() }
    );

    dispatch(workerSchedulePageActions.getMyScheduleSheets(response.data));
  } catch (error) {
    catchError(error, dispatch);
  }
};
