import React, { useState } from 'react';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';

import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { getProjectName } from 'helpers/_helpers';
import { agoDate, shortDate } from 'helpers/_date-helpers';
import styles from './styles';
import photoPlaceholder from 'assets/images/user-photo-placeholder.svg';
import CloseIcon from 'assets/images/close-icon.svg';
import LocationIcon from 'assets/images/location-icon.svg';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { generateHoursObject } from 'helpers/helpers';
import { useNavigate } from 'react-router-dom';
import modalsPageActions from 'store/modals/modalsPageActions';
import { useRemoveSheet } from 'pages/Schedule/components/ScheduleCalendar/components/ScheduleEvent/hooks/useRemoveSheet';
import { getSheets } from 'store/schedule/schedulePageOperation';
import { FORMATS } from 'helpers/formats';
import { format } from 'date-fns';
import { useGetEquipmentInfo } from 'pages/Schedule/hooks/useGetEquipmentInfo';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';
import { globalBEDateFormat, convertTimeObject } from 'common/dateFormatConfig';
import { useCanEditWorklog } from 'common/hooks/use-can-edit-worklog';

const SheetCard = ({ classes, sheet, sheets, openSnackbar, sheetsWithoutForemanIds }) => {
  const selectedDate = useSelector((state) => state.schedule.selectedDate);
  const { dateFormatWithTime } = useDateSettingsFormat();
  const getTimeFormatHook = useTimeSettingsFormat();
  const workersData = useSelector((state) => state.schedule.resources.workers);
  const userId = useSelector((state) => state.personalProfile.user?._id);
  const { getEquipmentInfo } = useGetEquipmentInfo(sheets.flatMap((el) => el.equipment));

  const [isExpanded, setIsExpanded] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { removeSheet } = useRemoveSheet({ sheetId: sheet._id });

  const openDeleteModal = (e) => {
    e.stopPropagation();
    const date = format(selectedDate, globalBEDateFormat);

    dispatch(
      modalsPageActions.openModal({
        name: 'remove',
        data: 'Are you sure you want to remove this project from the schedule?',
        onSubmit: (e) =>
          removeSheet()
            .then(() => dispatch(modalsPageActions.closeAllModals()))
            .then(() =>
              dispatch(
                getSheets({
                  date,
                  filters: {},
                  isCrewLeader: false,
                  isGridView: false,
                  isMobile: true,
                })
              )
            ),
      })
    );
  };

  const handleExpandClick = (e) => {
    e.stopPropagation();
    setIsExpanded((prev) => !prev);
  };

  const getSheetStatus = () => {
    const { published, submittedAt, canceledAt } = sheet;
    if (!published) return 'Draft';
    if (submittedAt) {
      return `Submitted ${agoDate(submittedAt, null, dateFormatWithTime)}`;
    } else if (canceledAt) {
      return 'Canceled';
    } else return 'Published';
  };

  const getJobNumber = () => sheet.project.jobNumber;

  const getFormattedHoursRange = () => {
    const { createdAt, hours } = sheet;
    if (!hours) return;
    const { hourStart, minuteStart, amPmStart, dayStart, hourEnd, minuteEnd, amPmEnd, dayEnd } =
      generateHoursObject(hours.start, hours.end, createdAt, getTimeFormatHook);

    const startObj = [null, hourStart, minuteStart, amPmStart];
    const endObj = [null, hourEnd, minuteEnd, amPmEnd];

    const start = `${getTimeFormatHook(startObj).formattedTime} ${shortDate(
      createdAt,
      dayStart,
      getTimeFormatHook().localDayMonth
    )}`;
    const end = `${getTimeFormatHook(endObj).formattedTime} ${shortDate(
      createdAt,
      dayEnd,
      getTimeFormatHook().localDayMonth
    )}`;

    return `${start} \u2013 ${end}`;
  };

  const openWorklogDetails = () => {
    navigate(`/schedule/${sheet._id}`);
  };

  const sheetLocation = (sheet, isStandalone, locationId, fieldName) => {
    if (!sheet.project) return '';
    const allPlaces =
      fieldName === 'sheetRallyPoints'
        ? [...(sheet?.sheetRallyPoints || []), ...(sheet.project?.rallyPoints || [])]
        : [...(sheet?.sheetLocations || []), ...(sheet.project?.locations || [])];
    const location = _.find(allPlaces, { _id: locationId });
    if (isStandalone) {
      if (!location) return '';
      const locationZip = location.zipcode ? `, ${location.zipcode}` : '';
      return location.name || `${location.address}${locationZip}`;
    }
    const nameOrAddress = location.name || location.address;
    return location && nameOrAddress ? ', ' + nameOrAddress : '';
  };

  const openLocation = (sheet, locationId, fieldName) => () => {
    const allPlaces =
      fieldName === 'sheetRallyPoints'
        ? [...(sheet?.sheetRallyPoints || []), ...(sheet.project?.rallyPoints || [])]
        : [...(sheet?.sheetLocations || []), ...(sheet.project?.locations || [])];
    const location = _.find(allPlaces, { _id: locationId });
    const url = `https://www.google.com/maps/place/${location?.address}`;
    window.open(url, '_blank', 'noreferrer');
  };

  const getIndividualStartTime = () => {
    const workerId = userId;
    const worker = sheet.workers.find(({ _id }) => _id === workerId);

    const startTime = (worker && worker.startTime) || sheet.startTime;

    const { hour, minute, amPm } = convertTimeObject(
      { hour: startTime.hour, minute: startTime.minute },
      getTimeFormatHook().is12Format
    );

    const hourObj = [null, hour, minute, amPm];

    return getTimeFormatHook(hourObj).formattedTime;
  };

  const hasEditAccess = useCanEditWorklog(sheet);

  return (
    <>
      <Card
        className={classnames(
          classes.card,
          sheetsWithoutForemanIds.includes(sheet._id) && classes.missingCL
        )}
        onClick={openWorklogDetails}
      >
        <Grid container direction={'column'}>
          <div
            className={classnames(
              classes.shiftColorWrapper,
              sheet.timeOfDay === 'Day' ? classes.dayShift : classes.nightShift
            )}
          >
            <Grid
              item
              xs={12}
              className={classnames(classes.paddingBottom, classes.inlineFlex, classes.flexHeader)}
            >
              <div className={classnames(classes.flexColumn, classes.selfCenter)}>
                <Typography variant="h6" color="textPrimary" className={classes.name}>
                  {sheet.project.contractor && sheet.project.contractor.name}
                </Typography>
                {sheet.forceAccount && sheet.forceAccount !== 'No' && (
                  <div className={classes.flexWrapper}>
                    <Typography
                      variant="body2"
                      className={classnames(classes.forceAccount, classes.marginBottomNormal)}
                    >
                      T&M: {sheet.forceAccount}
                    </Typography>
                  </div>
                )}
              </div>
              <div className={classnames(classes.inlineFlex, classes.buttons)}>
                {(Boolean(sheet.workers?.length) ||
                  Boolean(sheet.equipment?.length) ||
                  Boolean(sheet.notes)) && (
                  <IconButton
                    className={classnames(classes.expand, {
                      [classes.expandOpen]: isExpanded,
                    })}
                    aria-expanded={isExpanded}
                    aria-label="Show more"
                    disableRipple
                    disableFocusRipple
                    onClick={handleExpandClick}
                  >
                    <ExpandMoreIcon fontSize="medium" />
                  </IconButton>
                )}

                {hasEditAccess && <Button
                  color="secondary"
                  className={classes.deleteButton}
                  onClick={openDeleteModal}
                  disableRipple
                >
                  <img src={CloseIcon} alt="delete" />
                </Button>}
              </div>
            </Grid>
            <Grid item xs={12} className={classes.paddingBottom}>
              <Typography variant="h6" color="textPrimary">
                {getProjectName(sheet.project)}
              </Typography>
            </Grid>
          </div>
          <div className={classes.bottomWrapper}>
            <Grid item xs={12}>
              <div
                className={classnames(
                  classes.inlineFlex,
                  classes.paddingBottom,
                  classes.itemsFlexStart
                )}
              >
                <Typography
                  variant="body1"
                  color="textSecondary"
                  className={classnames(
                    classes.mediumFontWeight,
                    sheet.locations && sheet.locations.length ? classes.paddingTopSmall : null
                  )}
                >
                  #{getJobNumber()}
                </Typography>
                <div
                  className={classnames(
                    classes.flexColumn,
                    classes.widthAuto,
                    classes.overflowHidden
                  )}
                >
                  {sheet.rallyPoints &&
                    !!sheet.rallyPoints.length &&
                    !!sheet.rallyPoints[0] &&
                    sheet.rallyPoints.map((locationId) => (
                      <div
                        className={classnames(
                          classes.locationFlex,
                          classes.paddingBottomSmall,
                          classes.fullWidth
                        )}
                        key={`${locationId}`}
                      >
                        <img
                          src={LocationIcon}
                          alt="location"
                          onClick={openLocation(sheet, locationId, 'sheetRallyPoints')}
                        />
                        <Typography
                          variant="body1"
                          color="textPrimary"
                          className={classes.location}
                        >
                          {sheetLocation(sheet, true, locationId, 'sheetRallyPoints')}
                        </Typography>
                      </div>
                    ))}
                  {sheet.locations &&
                    !!sheet.locations.length &&
                    !!sheet.locations[0] &&
                    sheet.locations.map((locationId) => (
                      <div
                        className={classnames(
                          classes.locationFlex,
                          classes.paddingBottomSmall,
                          classes.fullWidth
                        )}
                        key={`${locationId}`}
                      >
                        <img
                          src={LocationIcon}
                          alt="location"
                          onClick={openLocation(sheet, locationId, 'sheetLocations')}
                        />
                        <Typography
                          variant="body1"
                          color="textPrimary"
                          className={classes.location}
                        >
                          {sheetLocation(sheet, true, locationId, 'sheetLocations')}
                        </Typography>
                      </div>
                    ))}
                  {!Boolean(sheet.locations.length) && !Boolean(sheet.rallyPoints.length) && (
                    <div className={classes.locationFlex}>
                      <Typography variant="body1" color="textPrimary" className={classes.location}>
                        N/A
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
              <Typography
                variant="body1"
                color="textSecondary"
                className={classnames(
                  classes.paddingBottom,
                  classes.mediumFontWeight,
                  getSheetStatus() === 'Canceled' ? classes.redText : classes.yellowText
                )}
              >
                {getSheetStatus()}
              </Typography>
            </Grid>
            {/* <Grid item xs={12} className={classes.paddingBottom}>
              <Divider />
            </Grid> */}
            {!!sheet.startTime && (
              <Grid item xs={12} md={6} className={classes.paddingBottom}>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  className={classnames(classes.regularFontWeight, classes.paddingBottomSmall)}
                >
                  Start Time:
                </Typography>
                <Typography variant="body1" color="textPrimary">
                  {getIndividualStartTime()}
                </Typography>
              </Grid>
            )}
            {!!sheet.travelTime && (
              <Grid item xs={12} md={6} className={classes.paddingBottom}>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  className={classnames(classes.regularFontWeight, classes.paddingBottomSmall)}
                >
                  Travel Time:
                </Typography>
                <Typography variant="body1" color="textPrimary">
                  {sheet.travelTime.hours}:{sheet.travelTime.minutes}
                </Typography>
              </Grid>
            )}
            {sheet?.hours?.start && sheet?.hours?.start && (
              <Grid item xs={12} className={classes.paddingBottom}>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  className={classnames(classes.regularFontWeight, classes.paddingBottomSmall)}
                >
                  On Job Time:
                </Typography>
                <Typography variant="body1" color="textPrimary">
                  {getFormattedHoursRange()}
                </Typography>
              </Grid>
            )}

            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
              <Grid>
                {Boolean(sheet.notes) && (
                  <Grid item className={classes.paddingBottom}>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      className={classes.marginBottomSmall}
                    >
                      Note:
                    </Typography>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      className={classnames(classes.notesText, classes.marginBottom)}
                    >
                      {sheet.notes}
                    </Typography>
                  </Grid>
                )}
                {Boolean(sheet.workers.length) && (
                  <Grid item>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      className={classes.marginBottomSmall}
                    >
                      People:
                    </Typography>
                    <div className={classnames(classes.marginBottomSmall)}>
                      {sheet.workers
                        .map((w) => (workersData || []).find((worker) => worker._id === w._id))
                        .filter((w) => !!w)
                        .sort((a, b) => b.foreman - a.foreman)

                        .map((worker) => (
                          <div
                            key={worker._id}
                            className={classnames(classes.marginBottom, classes.itemWrapper)}
                          >
                            <div
                              className={classnames(
                                classes.flexBox,
                                classes.justifyContentStart,
                                classes.marginBottomSmall
                              )}
                            >
                              <div
                                className={classnames(
                                  classes.photoWrapper,
                                  classes[`${worker.profile.shifts.timeOfDay.toLowerCase()}Border`]
                                )}
                              >
                                <img src={photoPlaceholder} className={classes.photo} />
                              </div>
                              <Typography
                                variant="body1"
                                color="textPrimary"
                                className={classnames(
                                  classes.username,
                                  classes[worker.profile.shifts.timeOfDay.toLowerCase()]
                                )}
                              >
                                {worker.username}
                              </Typography>
                            </div>
                          </div>
                        ))}
                    </div>
                  </Grid>
                )}
                {Boolean(sheet.equipment.length) && (
                  <Grid item>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      className={classes.marginBottomSmall}
                    >
                      Equipment:
                    </Typography>
                    <div className={classnames(classes.marginBottomSmall)}>
                      {sheet.equipment.map((item) => (
                        <div
                          key={item._id}
                          className={classnames(
                            classes.marginBottom,
                            classes.itemWrapper,
                            classes.marginBottomSmall
                          )}
                        >
                          <div
                            className={classnames(
                              classes.equipmentBlock,
                              getEquipmentInfo(item._id, 'className')
                            )}
                            style={{
                              backgroundColor: getEquipmentInfo(item._id, 'color'),
                            }}
                          >
                            <div className={classes.equipmentNumber}>
                              {getEquipmentInfo(item._id, 'name')}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Grid>
                )}
              </Grid>
            </Collapse>
          </div>
        </Grid>
      </Card>
    </>
  );
};

export default withStyles(styles)(SheetCard);
