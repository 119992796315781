export default (theme) => ({
  monthCalendarWrapper: {
    flexGrow: 1,
    marginLeft: 325,
    width: 'calc(100% - 325px)',
    // width: '100%',
    transition: 'margin 0.2s ease',
    height: 'calc(100vh - 146px)',
    borderTop: `1px solid ${theme.palette.borderColor.lightGrey}`,
    [theme.breakpoints.down('md')]: {
      borderTop: 'none',
    },
  },
  showRes: {
    marginLeft: '40px !important',
    // width: '100%',
    width: 'calc(100% - 40px)',
  },
  calHeader: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  headerCell: {
    height: 50,
    display: 'flex',
    alignItems: 'center',
  },
  calHeaderText: {
    color: ` ${theme.palette.textColor.secondary}`,
    fontSize: 10,
    fontWeight: 700,
  },
  grid: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    border: `0.5px solid  ${theme.palette.borderColor.lightGrey}`,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      border: 'none',
    },
  },
  gridRow: {
    display: 'flex',
    flexGrow: '1',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
});
