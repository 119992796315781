export default (theme) => ({
  textField: {
    maxWidth: 250,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  divider: {
    margin: '10px 0 5px',
    display: 'block',
    [theme.breakpoints.only('md')]: {
      display: 'none',
    },
  },
  marginSmall: {
    marginTop: 10,
  },
  paddingBottomRemove: {
    paddingBottom: '0 !important',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
});
