export default theme => ({
  regularFontWeight: {
    fontWeight: 300,
  },
  mediumFontWeight: {
    fontWeight: 500,
  },
  paddingBottom: {
    paddingBottom: 8
  },
  paddingBottomSmall: {
    paddingBottom: 4
  },
  primaryColor: {
    color: theme.palette.textColor.primary,
    cursor: 'pointer',
  },
  darkColor: {
    color: theme.palette.textColor.dark
  },
  expand: {
    color: theme.palette.textColor.primary,
    transform: 'rotate(0deg)',
    padding: 0,
    '&:hover': {
      color: theme.palette.textColor.primary,
      backgroundColor: theme.palette.background.default
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
      backgroundColor: theme.palette.background.default
    }
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  cardActionsWrapper: {
    padding: '0 0 8px 0'
  },
  contentWrapper: {
    padding: 12,
    borderTop: `1px solid ${theme.palette.borderColor.primary}`
  },
  username: {
    wordBreak: 'break-word',
    fontSize: 14
  },
  dividerWrapper: {
    padding: '8px 0'
  },
  day: {
    color: theme.palette.textColor.yellow,
  },
  night: {
    color: theme.palette.textColor.darkBlue,
  },
  inactive: {
    color: theme.palette.textColor.secondary,
  }
});
