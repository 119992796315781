export default theme => ({
  tabs: {
    color: theme.palette.textColor.secondary,
    '& a': {
      minWidth: 110,
      textTransform: 'none',
    },
    '& a:hover': {
      backgroundColor: 'transparent !important',
      color: '#5a87ef'
    },
    '& a.Mui-selected': {
      color: '#5a87ef',
    },
    '& .MuiTabs-indicator': {
      backgroundColor: '#5a87ef'
    },
    '& span': {
      fontSize: '0.875rem',
      fontWeight: 500
    }
  },
});
