export default (theme) => ({
  root: {},
  control: {
    color: '#5A87EF',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '21px',
    position: 'relative',
    cursor: 'pointer',
    height: 32,
    display: 'inline-flex',
    alignItems: 'flex-end',
  },
  icon: {
    display: 'inline-block',
    marginRight: 10,
    height: 16,
    width: 16,
  },
  trigger: {
    cursor: 'pointer',
  },
  input: {
    cursor: 'pointer',
    margin: '0 !important',
    opacity: 0,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  error: {
    color: theme.palette.textColor.red,
    padding: '0 5px',
    marginTop: 8,
    fontSize: 11,
    display: 'block',
  },
});
