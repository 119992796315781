import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { parse, startOfMonth, endOfMonth, format } from 'date-fns';

import AvailabilityHeader from './components/AvailabilityHeader';
import Timeoffs from './components/Timeoffs';
import AvailabilityCalendar from './components/AvailabilityCalendar/AvailabilityCalendar';
import HolidayModal from './components/HolidayModal';
import styles from './styles';
import availabilityPageActions from 'store/availability/availabilityPageActions';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCalendarAllUsers,
  getEquipmentEvents,
  getHolidays,
} from 'store/availability/availabilityPagesOperations';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';

const AvailabilityPage = (props) => {
  const dispatch = useDispatch();
  const { timeoffsByType, selectedDate, equipmentEvents, holidays } = useSelector(
    (store) => store.availability
  );
  const { dateFormat, dateFormatWithTime, dayDateSettingsFormat } = useDateSettingsFormat();

  const [isTimeoffList, setIsTimeoffList] = useState(!!props.startDate);
  const [holidayModal, setHolidayModal] = useState(undefined);
  const [isHolidayTabActive, setIsHolidayTabActive] = useState(false);
  const [refreshHoliday, setRefreshHoliday] = useState(0);
  const [holidayDates, setHolidayDates] = useState([
    startOfMonth(new Date()),
    endOfMonth(new Date()),
  ]);
  const [timeoffDates, setTimeoffDates] = useState([
    props.startDate ? parse(props.startDate, dateFormat) : startOfMonth(new Date()),
    props.endDate ? parse(props.endDate, dateFormat) : endOfMonth(new Date()),
  ]);
  const [tabValue, setTabValue] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (!selectedDate) return;
    // dispatch(getTimeoffs());
    dispatch(getCalendarAllUsers());
    dispatch(getEquipmentEvents());
    dispatch(getHolidays());
  }, [selectedDate, isTimeoffList]);

  const toggleTimeoffList = () => {
    setIsTimeoffList((prev) => !prev);
    setIsHolidayTabActive(false);
  };

  const toggleHolidayModal = (holiday) => () => setHolidayModal(holiday);
  const toggleRefreshHoliday = () => setRefreshHoliday((prev) => ++prev);

  const { classes, openSnackbar } = props;

  const setSelectedDate = (date) =>
    dispatch(availabilityPageActions.setDate(format(new Date(date), dateFormat)));

  const updateHolidayAndTimeoffDates = (date) => {
    setHolidayDates(date);
    setTimeoffDates(date);
  };

  if (!selectedDate) return <></>;
  return (
    <div className={classes.root}>
      <AvailabilityHeader
        isTimeoffList={isTimeoffList}
        toggleTimeoffList={toggleTimeoffList}
        selectedDate={parse(selectedDate, dateFormat, new Date())}
        updateDate={setSelectedDate}
        toggleHolidayModal={toggleHolidayModal}
        isHolidayTabActive={isHolidayTabActive}
        holidayDates={holidayDates}
        updateHolidayDates={updateHolidayAndTimeoffDates}
        timeoffDates={timeoffDates}
        updateTimeoffDates={updateHolidayAndTimeoffDates}
      />
      {isTimeoffList ? (
        <Timeoffs
          openSnackbar={openSnackbar}
          selectedDate={parse(selectedDate, dateFormat, new Date())}
          timeoffDates={timeoffDates}
          status={props.status}
          tabValue={tabValue}
          holidayDates={holidayDates}
          toggleHolidayModal={toggleHolidayModal}
          updateHolidayTabStatus={setIsHolidayTabActive}
          holidays={holidays}
          refreshHoliday={refreshHoliday}
        />
      ) : (
        <AvailabilityCalendar
          openSnackbar={openSnackbar}
          selectedDate={parse(selectedDate, dateFormat, new Date())}
          userEvents={timeoffsByType.approved}
          equipmentEvents={equipmentEvents}
          tabValue={tabValue}
          handleChangeTab={handleChangeTab}
          holidayDates={holidayDates}
          toggleHolidayModal={toggleHolidayModal}
          // updateHolidayTabStatus={setIsHolidayTabActive}
          holidays={holidays}
        />
      )}
      {holidayModal !== undefined && (
        <HolidayModal
          closeModal={toggleHolidayModal()}
          holiday={holidayModal}
          defaultIndividual={tabValue === 3}
          openSnackbar={openSnackbar}
          toggleRefreshHoliday={toggleRefreshHoliday}
        />
      )}
    </div>
  );
};

AvailabilityPage.propTypes = {
  classes: propTypes.object.isRequired,
  openSnackbar: propTypes.func.isRequired,
  timeoffsByType: propTypes.objectOf(propTypes.array).isRequired,
  equipmentEvents: propTypes.array.isRequired,
  holidays: propTypes.array.isRequired,
  selectedDate: propTypes.string.isRequired,
};

export default withStyles(styles)(AvailabilityPage);
