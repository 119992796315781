import React, { useState, memo } from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import GreyUserI from 'assets/images/user-photo-placeholder.svg';
import CloseIcon from 'assets/images/close-icon.svg';
import IconSunny from 'assets/images/icon-sunny.svg';
import IconMoon from 'assets/images/icon-moon.svg';
import ConfirmDialog from 'components/ConfirmDialog';
import styles from './styles';
import { drawerTypes } from '../../../../../../../Equipment/components/helpers/drawerTypes';
import { fetchDeleteProject } from 'store/projectTypes/projectTypesOperations';
import { useDispatch } from 'react-redux';

const ProjectCard = memo(
  ({ classes, project, openDrawer, hasFullAccess }) => {
    const dispatch = useDispatch();
    const [isExpanded, setIsExpanded] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const renderUserShift = (type) => {
      switch (type) {
        case 'Day':
          return (
            <span>
              <img src={IconSunny} alt="sunny" className={classes.shiftImage} />
              Day
            </span>
          );
        case 'Night':
          return (
            <span>
              <img src={IconMoon} alt="moon" className={classes.shiftImage} />
              Night
            </span>
          );
        default:
          return 'Inactive';
      }
    };

    const handleExpandClick = (e) => {
      e.stopPropagation();
      setIsExpanded((prev) => !prev);
    };

    const closeModal = () => {
      setIsModalOpen(false);
    };

    const deleteProject = () => {
      dispatch(fetchDeleteProject(project._id));
    };

    const openDeleteModal = (e) => {
      e.stopPropagation();
      setIsModalOpen(true);
    };

    return (
      <>
        <Card
          className={classes.card}
          onClick={hasFullAccess ? openDrawer(drawerTypes.update, project) : null}
        >
          <Grid container className={classes.paddingBox}>
            <Grid item xs={12} className={classes.paddingBottom}>
              <div className={classnames(classes.inlineFlex, classes.flexHeader)}>
                <Typography
                  variant="h6"
                  color="textPrimary"
                  className={classnames(classes.fixedWidth, classes.wordBreak)}
                >
                  {project.route} {project.section && `\u0387 ${project.section}`}
                </Typography>
                {hasFullAccess && (
                  <Button
                    color="secondary"
                    className={classes.deleteButton}
                    onClick={openDeleteModal}
                    disableRipple
                  >
                    <img src={CloseIcon} alt="delete" />
                  </Button>
                )}
              </div>
            </Grid>
            <Grid item xs={12} className={classes.paddingBottom}>
              <div className={classes.inlineFlex}>
                <Typography variant="body1" color="textSecondary">
                  #{project.jobNumber}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} className={classes.paddingBottom}>
              <Divider />
              <div className={classes.flexBox}>
                <div className={classes.photoWrapper}>
                  <img src={GreyUserI} className={classes.photo} />
                </div>
                <Typography
                  variant="body1"
                  color="textPrimary"
                  className={classnames(classes.name, classes.wordBreak)}
                >
                  {project.contractor.name}
                </Typography>
              </div>
              <Divider />
            </Grid>
            <Grid item xs={12} md={6} className={classes.paddingBottom}>
              <Typography
                variant="body1"
                color="textSecondary"
                className={classnames(classes.regularFontWeight, classes.paddingBottomSmall)}
              >
                Type:
              </Typography>
              <Typography variant="body1" color="textPrimary">
                {project.type}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} className={classes.paddingBottom}>
              <Typography
                variant="body1"
                color="textSecondary"
                className={classnames(classes.regularFontWeight, classes.paddingBottomSmall)}
              >
                Certified Payroll:
              </Typography>
              <Typography variant="body1" color="textPrimary">
                {project.rated ? 'Yes' : 'No'}
              </Typography>
            </Grid>
            {project.id && (
              <Grid item xs={12} md={6} className={classes.paddingBottom}>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  className={classnames(classes.regularFontWeight, classes.paddingBottomSmall)}
                >
                  Project ID:
                </Typography>
                <Typography variant="body1" color="textPrimary">
                  {project.id}
                </Typography>
              </Grid>
            )}
            {project.county && (
              <Grid item xs={12} md={6} className={classes.paddingBottom}>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  className={classnames(classes.regularFontWeight, classes.paddingBottomSmall)}
                >
                  County:
                </Typography>
                <Typography variant="body1" color="textPrimary">
                  {project.county}
                </Typography>
              </Grid>
            )}
            {project.state && (
              <Grid item xs={12} md={6} className={classes.paddingBottom}>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  className={classnames(classes.regularFontWeight, classes.paddingBottomSmall)}
                >
                  State:
                </Typography>
                <Typography variant="body1" color="textPrimary">
                  {project.state}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} md={6} className={classes.paddingBottom}>
              <Typography
                variant="body1"
                color="textSecondary"
                className={classnames(classes.regularFontWeight, classes.paddingBottomSmall)}
              >
                Shift:
              </Typography>
              <Typography variant="body1" color="textPrimary">
                {renderUserShift(project.shift)}
              </Typography>
            </Grid>
            {project.earningsCode && (
              <Grid item xs={12} md={6} className={classes.paddingBottom}>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  className={classnames(classes.regularFontWeight, classes.paddingBottomSmall)}
                >
                  Earnings code:
                </Typography>
                <Typography variant="body1" color="textPrimary" className={classes.wordBreak}>
                  {project.earningsCode}
                </Typography>
              </Grid>
            )}
            {project.baseRate && (
              <Grid item xs={12} md={6} className={classes.paddingBottom}>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  className={classnames(classes.regularFontWeight, classes.paddingBottomSmall)}
                >
                  Base rate:
                </Typography>
                <Typography variant="body1" color="textPrimary" className={classes.wordBreak}>
                  {project.baseRate}
                </Typography>
              </Grid>
            )}
            {project.fringeBenefit && (
              <Grid item xs={12} md={6} className={classes.paddingBottom}>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  className={classnames(classes.regularFontWeight, classes.paddingBottomSmall)}
                >
                  Fringe Benefit:
                </Typography>
                <Typography variant="body1" color="textPrimary" className={classes.wordBreak}>
                  {project.fringeBenefit}
                </Typography>
              </Grid>
            )}
          </Grid>

          {project.locations && project.locations.length ? (
            <Grid item xs={12}>
              <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    className={classnames(
                      classes.paddingBottom,
                      classes.paddingTop,
                      classes.borderTop
                    )}
                  >
                    {project.rallyPoints?.map((location, index) => (
                      <div key={location._id}>
                        {index === 0 && (
                          <div className={classes.paddingBox}>
                            <div className={classes.inlineFlex}>
                              <Typography
                                variant="body1"
                                color="textSecondary"
                                className={classnames(
                                  classes.regularFontWeight,
                                  classes.paddingBottomSmall
                                )}
                              >
                                Rally Points:
                              </Typography>
                            </div>
                          </div>
                        )}
                        {location.name && (
                          <div className={classes.paddingBox}>
                            <div className={classes.inlineFlex}>
                              <div>
                                <Typography
                                  variant="body1"
                                  color="textSecondary"
                                  className={classnames(
                                    classes.regularFontWeight,
                                    classes.paddingBottomSmall
                                  )}
                                >
                                  Name:
                                </Typography>
                                <Typography
                                  variant="body1"
                                  color="textPrimary"
                                  className={classes.wordBreak}
                                >
                                  {location.name}
                                </Typography>
                              </div>
                              <span
                                className={classnames(classes.locationsCount, classes.innerCounter)}
                              >
                                {index + 1}
                              </span>
                            </div>
                          </div>
                        )}
                        <div className={classes.paddingBox}>
                          <div className={classes.inlineFlex}>
                            <div>
                              <Typography
                                variant="body1"
                                color="textSecondary"
                                className={classnames(
                                  classes.regularFontWeight,
                                  classes.paddingBottomSmall
                                )}
                              >
                                Address:
                              </Typography>
                              <Typography
                                variant="body1"
                                color="textPrimary"
                                className={classes.wordBreak}
                              >
                                {location.address}
                              </Typography>
                            </div>
                            {!location.name && (
                              <span
                                className={classnames(classes.locationsCount, classes.innerCounter)}
                              >
                                {index + 1}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className={classes.paddingBox}>
                          <Typography
                            variant="body1"
                            color="textSecondary"
                            className={classnames(
                              classes.regularFontWeight,
                              classes.paddingBottomSmall
                            )}
                          >
                            ZIP:
                          </Typography>
                          <Typography
                            variant="body1"
                            color="textPrimary"
                            className={classes.wordBreak}
                          >
                            {location.zipcode}
                          </Typography>
                        </div>
                      </div>
                    ))}
                    {!!project.locations?.length && !!project.rallyPoints?.length && (
                      <Divider style={{ margin: '5px 0' }} />
                    )}
                    {project.locations.map((location, index) => (
                      <div key={location._id}>
                        {index === 0 && (
                          <div className={classes.paddingBox}>
                            <div className={classes.inlineFlex}>
                              <Typography
                                variant="body1"
                                color="textSecondary"
                                className={classnames(
                                  classes.regularFontWeight,
                                  classes.paddingBottomSmall
                                )}
                              >
                                Project Locations:
                              </Typography>
                            </div>
                          </div>
                        )}
                        {location.name && (
                          <div className={classes.paddingBox}>
                            <div className={classes.inlineFlex}>
                              <div>
                                <Typography
                                  variant="body1"
                                  color="textSecondary"
                                  className={classnames(
                                    classes.regularFontWeight,
                                    classes.paddingBottomSmall
                                  )}
                                >
                                  Name:
                                </Typography>
                                <Typography
                                  variant="body1"
                                  color="textPrimary"
                                  className={classes.wordBreak}
                                >
                                  {location.name}
                                </Typography>
                              </div>
                              <span
                                className={classnames(classes.locationsCount, classes.innerCounter)}
                              >
                                {index + 1}
                              </span>
                            </div>
                          </div>
                        )}
                        <div className={classes.paddingBox}>
                          <div className={classes.inlineFlex}>
                            <div>
                              <Typography
                                variant="body1"
                                color="textSecondary"
                                className={classnames(
                                  classes.regularFontWeight,
                                  classes.paddingBottomSmall
                                )}
                              >
                                Address:
                              </Typography>
                              <Typography
                                variant="body1"
                                color="textPrimary"
                                className={classes.wordBreak}
                              >
                                {location.address}
                              </Typography>
                            </div>
                            {!location.name && (
                              <span
                                className={classnames(classes.locationsCount, classes.innerCounter)}
                              >
                                {index + 1}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className={classes.paddingBox}>
                          <Typography
                            variant="body1"
                            color="textSecondary"
                            className={classnames(
                              classes.regularFontWeight,
                              classes.paddingBottomSmall
                            )}
                          >
                            ZIP:
                          </Typography>
                          <Typography
                            variant="body1"
                            color="textPrimary"
                            className={classes.wordBreak}
                          >
                            {location.zipcode}
                          </Typography>
                        </div>
                      </div>
                    ))}
                  </Grid>
                </Grid>
              </Collapse>
              <CardActions
                disableSpacing
                onClick={handleExpandClick}
                className={classes.actionsWrapper}
              >
                <IconButton
                  className={classnames(classes.expand, {
                    [classes.expandOpen]: isExpanded,
                  })}
                  aria-expanded={isExpanded}
                  aria-label="Show more"
                  disableRipple
                  disableFocusRipple
                >
                  <ExpandMoreIcon />
                </IconButton>
                <Typography color="textSecondary">
                  {isExpanded ? (
                    'Hide location(s)'
                  ) : (
                    <span>
                      Show
                      <span className={classes.locationsCount}>
                        {project.locations.length + project.rallyPoints.length}
                      </span>
                      location(s)
                    </span>
                  )}
                </Typography>
                <IconButton
                  className={classnames(classes.expand, {
                    [classes.expandOpen]: isExpanded,
                  })}
                  aria-expanded={isExpanded}
                  aria-label="Show more"
                  disableRipple
                  disableFocusRipple
                >
                  <ExpandMoreIcon />
                </IconButton>
              </CardActions>
            </Grid>
          ) : null}
        </Card>
        {/* </PageLoader> */}

        {isModalOpen && (
          <ConfirmDialog
            isOpen={isModalOpen}
            onClose={closeModal}
            onSubmit={deleteProject}
            text="All worklogs connected to this project will be permanently deleted. This action cannot be reversed. Do you want to permanently delete this project and its data?"
            disableEscape
            loadingOnSubmit
          />
        )}
      </>
    );
  },
  (prev, next) => {
    return next.project == prev.project;
  }
);

ProjectCard.propTypes = {
  classes: propTypes.object.isRequired,
  project: propTypes.object.isRequired,
  openDrawer: propTypes.func.isRequired,
};

export default withStyles(styles)(ProjectCard);
