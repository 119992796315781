export default theme => ({
  root: {
    padding: '10px 15px !important',
    margin: '0 auto',
    maxWidth: '1280px'
  },
  drawer: {
    // [theme.breakpoints.down('sm')]: {
    //   width: '100%',
    // },
  }
});
