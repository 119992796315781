import _ from 'lodash';
import React from 'react';
import LoadingSpinner from '../../components/LoadingSpinner';
import TableCSV from './TableCSV';
import { useParams } from 'react-router-dom';
import { useGetCSV } from './hooks/useGetCSV';

const SubmissionsCSV = () => {
  const {submissionId, type} = useParams()
  const {csv, loading} = useGetCSV(submissionId, type)

    return (
      <div>
        {loading ? (
          <div style={{ display: 'flex', alignItem: 'center', justifyContent: 'center', marginTop: '100px' }}>
            <LoadingSpinner height={40} margin={32} />
          </div>
        ) :
        (
          <TableCSV csv={csv} type={type}/>
        )}
      </div>
    )
}

export default SubmissionsCSV;
