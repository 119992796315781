import React, { useCallback, useMemo } from 'react';
import styles, { DayPickerStyles } from './styles';
import { Button, Grid, withStyles } from '@material-ui/core';
import WeekPicker from 'components/form/WeekPicker';
import { useDispatch, useSelector } from 'react-redux';
import DayPickerOneDayBase from 'components/DayPicker';
import timeclocksPageActions from 'store/timeclocks/timeclocksPageActions';
import useTabs from '../../hooks/useTabs';
import ExportExcelButton from '../ExportExcelButton';
import EnterKioskButton from '../EnterKioskButton';
import { hasPermissionsFor } from 'helpers/_helpers';
import { parse, startOfWeek, endOfWeek, format, isValid } from 'date-fns';
import debounce from 'lodash/debounce';
import { useWeekStart } from 'hooks/useWeekStart';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';
import { allDatesFormat } from 'common/dateFormatConfig';
import { parseStringDate } from 'helpers/_date-helpers';

const DayPickerOneDay = withStyles(DayPickerStyles)(DayPickerOneDayBase);

const Header = ({ classes, onKioskEnter, ...props }) => {
  const dispatch = useDispatch();
  const weekStart = useSelector((state) => state.settings?.settings?.weekStart);
  const { weekDayFromO } = useWeekStart();
  const { dateFormat } = useDateSettingsFormat();
  
  const { weekly } = useTabs();

  const { summary, weekSummary, users, dis } = useSelector((state) => state.timeclocks);

  const currentDate = useMemo(() => {
    if (!summary.query.date || !isValid(summary.query.date)) {
      return new Date();
    } else if (/^\d{1,2}-\d{1,2}-\d{2}$/.test(summary.query.date)) {
      return parse(summary.query.date, allDatesFormat[3], new Date());
    } else {
      return parseStringDate(summary.query.date);
    }
  }, [summary.query.date]);
  
  const startOfCurrentWeek = useMemo(() => startOfWeek(currentDate, { weekStartsOn: weekDayFromO }).toString(), [currentDate, weekDayFromO]);

  const period = useMemo(() => {
    if (weekSummary.query.period) {
      const [from, to] = weekSummary.query.period
        .split('..')
        .map((d) => parse(d, dateFormat, new Date()).toString());
      return { from, to };
    } else {
      return {};
    }
  }, [weekSummary.query.period, weekDayFromO]);

  const onExportExcel = useCallback(() => {
    if (weekly) {
      dispatch(
        timeclocksPageActions.setWeekSummary({
          ...weekSummary,
          exported: {
            ...weekSummary.exported,
            period: {
              from: parse(weekSummary.query.period.split('..')[0], dateFormat, new Date()),
              to: parse(weekSummary.query.period.split('..')[1], dateFormat, new Date()),
            },
            user: users.current,
          },
        })
      );
    } else {
      dispatch(
        timeclocksPageActions.setSummary({
          ...summary,
          exported: {
            ...summary.exported,
            period: {
              from: startOfWeek(summary.query.date, { weekStartsOn: weekDayFromO }),
              to: endOfWeek(summary.query.date, { weekStartsOn: weekDayFromO }),
            },
          },
        })
      );
    }
  }, [weekly, summary, weekSummary, dispatch, users, weekDayFromO]);

  const onChangePeriod = useCallback(
    (from, to) => {
      dispatch(
        timeclocksPageActions.setWeekSummary({
          ...weekSummary,
          query: {
            ...weekSummary.query,
            period: `${format(from,dateFormat)}..${format(to,dateFormat)}`,
          },
        })
      );
    },
    [dispatch, weekSummary, weekDayFromO]
  );

  const onWeekChange = useCallback(
    ({ from, to }) => {
      dispatch(timeclocksPageActions.setLoading(true));
      onChangePeriod(from, to);
    },
    [onChangePeriod, weekDayFromO]
  );

  const onCurrentWeek = useCallback(() => {
    const from = startOfWeek(new Date(), { weekStartsOn: weekDayFromO });
    const to = endOfWeek(new Date(), { weekStartsOn: weekDayFromO });

    onChangePeriod(from, to);
  }, [onChangePeriod, weekDayFromO]);

  const handleChangeDay = useCallback(
    debounce((date) => {
      dispatch(
        timeclocksPageActions.setSummary({
          ...summary,
          query: {
            ...summary.query,
            date: date,
          },
          data: [],
          page: 0,
        })
      );
    }, 300),
    [summary, dispatch, weekDayFromO]
  );

  return (
    <Grid container spacing={2} justifyContent={'space-between'} {...props}>
      <Grid item xs={12} xl={6}>
        <Grid container spacing={5} justifyContent={'space-between'} alignItems={'center'}>
          <Grid item xs>
            <Grid container spacing={5} justifyContent={'space-between'} alignItems={'center'}>
              <Grid item xs={'auto'} className={classes.title}>
                Time clock
              </Grid>
              {hasPermissionsFor('kioskMode') && (
                <Grid item xs={'auto'}>
                  <EnterKioskButton onClick={onKioskEnter} />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={'auto'}>
            <ExportExcelButton onClick={onExportExcel} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} xl={6}>
        {weekly ? (
          <Grid container spacing={2} justifyContent={'space-between'} alignItems={'center'}>
            <Grid item xs={'auto'}>
              <Button
                variant="contained"
                color="secondary"
                disabled={startOfCurrentWeek === String(period.from)}
                className={classes.currentWeekBtn}
                onClick={onCurrentWeek}
              >
                Current Week
              </Button>
            </Grid>
            <Grid item xs={'auto'}>
              {weekStart && <WeekPicker className={classes.weekPicker} value={period} onChange={onWeekChange} dis={dis}/>}
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2} justifyContent={'space-between'} alignItems={'center'}>
            <Grid item xs />
            <Grid item xs={'auto'}>
              <DayPickerOneDay
                dis={dis}
                selectedDate={currentDate}
                updateSelectedDate={(e) => {
                  dispatch(timeclocksPageActions.setLoading(true));
                  handleChangeDay(e);
                }}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(Header);
