import { KIOSK_EVENT } from 'subscriptions/events/events.enum';
import { subscriptionsFabric } from 'subscriptions/subscriptions.fabric';
import kioskPageActions from 'store/kiosk/kioskPageActions';
import { getItem } from 'helpers/localStorage';
import { getKioskUsers } from 'store/kiosk/kioskPageOperations';

const handlers = {
  [KIOSK_EVENT.usersAdded]: (store) => (data) => {
    if (Array.isArray(data)) store.dispatch(kioskPageActions.addUsers(data));
  },

  [KIOSK_EVENT.usersUpdated]: (store) => (data) => {
    if (data?._id) {
      store.dispatch(kioskPageActions.editUser(data));
    }
  },

  [KIOSK_EVENT.usersDeleted]: (store) => (data) => {
    if (data?._id) {
      store.dispatch(kioskPageActions.deleteUser(data));
    }
  },
};

export const kioskSubscriptionMiddleware = subscriptionsFabric(
  kioskPageActions,
  KIOSK_EVENT,
  handlers,
  {
    connectionOptions: {
      auth: (cb) =>
        cb({
          token: getItem('x-admin-kiosk-token'),
        }),
    },
    onConnect: (store) => {
      if (store.getState()?.kiosk?.socketConnectionStatus === 'disconnected') {
        store.dispatch(getKioskUsers());
      }
      store.dispatch(kioskPageActions.setSocketConnectionStatus('connected'));
    },
    onDisconnect: (store) => {
      store.dispatch(kioskPageActions.setSocketConnectionStatus('disconnected'));
    },
    getRoomId: ((store) => store.getState().kiosk.company._id),
  }
);
