export const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  linearProgressInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
  linearProgressTitle: {
    fontSize: '16px',
    color: '#6f6b6b',
    fontWeight: 'normal',
  },
  linearProgressCount: {
    fontSize: '16px',
    color: 'black',
    fontWeight: 'bold',
  },
});
