import React, { useCallback, useEffect, useState } from 'react';
import cs from 'classnames';
import Grid from '@material-ui/core/Grid';
import makeTimeclockIterator, { SummarySymbol } from '../../../../utils/makeTimeclockIterator';
import TimeclockItem from '../Timeclocks/components/TimeclockItem';
import Jobs from '../Jobs';
import CIPProjects from '../CIPProjects';
import Footer from '../Footer';
import DeleteModal from '../../../../modals/DeleteModal';
import EditTimeclockModal from '../../../../modals/EditTimeclockModal';
import TimeclockItemEmpty from '../Timeclocks/components/TimeclockItemEmpty';
import { withStyles } from '@material-ui/core';
import styles from './styles';
import EditLunchTimeModal from '../../../../modals/EditLunchTimeModal/EditLunchTimeModal';
import ChangeTimeModal from 'components/OverlapsingModal/ChangeTimeModal';
import AddCipHoursModal from '../../../../modals/AddCipHoursModal/AddCipHoursModal';
import EditCipHoursModal from '../../../../modals/EditCipHoursModal/EditCipHoursModal';
import { useParams } from 'react-router-dom';
import { userWorklogsDayHours } from '../../../../../../components/WorklogDetails/hooks/apiCalls';
import { format, startOfDay, parse, setSeconds, endOfDay, isValid } from 'date-fns';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';
import { globalBEDateFormat } from 'common/dateFormatConfig';

const Worklog = ({
  userTitle,
  kiosk,
  data,
  currentDate,
  onChangeLunchTime,
  onRemoveTimeclock,
  onRemoveLunchTime,
  onChangeTimeclock: onUpdateTimeclock,
  onCreateTimeclock: onCreate,
  editable = false,
  classes,
  editMode,
  addCipHours,
  currentRoute,
  payroll,
  hasPayroll,
  payrollVerified,
  date,
  addMoreHours,
  setAddMoreHours,
}) => {
  const [queue, setQueue] = useState([]);
  const [index, setIndex] = useState(-1);
  const [walkMode, setWalkMode] = useState(false);
  const [deletingStartTimeOverride, setDeletingStartTimeOverride] = useState(null);
  const [deletingEndTimeOverride, setDeletingEndTimeOverride] = useState(null);
  const [deletingTimeclock, setDeletingTimeclock] = useState(null);
  const [deletingLunchTime, setDeletingLunchTime] = useState(null);
  const [editingLunchTime, setEditingLunchTime] = useState(null);
  const [editingTimeclock, setEditingTimeclock] = useState(null);
  const [deletingCip, setDeletingCip] = useState(null);
  const [editingCip, setEditingCip] = useState(null);
  const [editingOnJob, setEditingOnJob] = useState(null);
  // const [openCipModal, setOpenCipModal] = useState(addCipHours);
  const { dateFormat } = useDateSettingsFormat();

  const onKeyDown = useCallback(
    (e) => {
      if (e.key === 'ArrowDown') {
        setIndex((index) => {
          if (index === -1) {
            return index;
          }

          return index + 1 < queue.length ? index + 1 : index;
        });
      } else if (e.key === 'ArrowUp') {
        setIndex((index) => {
          if (index === -1) {
            return index;
          }

          return index > 1 ? index - 1 : 0;
        });
      }

      if (['ArrowDown', 'ArrowUp'].includes(e.key)) {
        e.stopImmediatePropagation();
        e.preventDefault();
        e.stopPropagation();
        return false;
      }
    },
    [queue]
  );

  const onKeyUp = useCallback((e) => {
    if (['ArrowDown', 'ArrowUp'].includes(e.key)) {
      e.stopImmediatePropagation();
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
  }, []);

  useEffect(() => {
    if (walkMode) {
      window.addEventListener('keyup', onKeyUp);
      window.addEventListener('keydown', onKeyDown);
    }

    return () => {
      if (walkMode) {
        window.removeEventListener('keyup', onKeyUp);
        window.removeEventListener('keydown', onKeyDown);
      }
    };
  }, [walkMode, onKeyUp, onKeyDown]);

  useEffect(() => {
    setWalkMode(index > -1);
  }, [index]);

  useEffect(() => {
    if (!addMoreHours) return;
    onCreateTimeclock();
    setTimeout(() => {
      setAddMoreHours(false);
    }, 300);
  }, [addMoreHours]);

  const onCreateTimeclock = useCallback(() => {
    const currDate = isValid(currentDate) ? new Date(currentDate) : new Date();
    const clickOnDate =
      date && isValid(parse(date, globalBEDateFormat, new Date()))
        ? parse(date, globalBEDateFormat, new Date())
        : currDate;

    const newTimeclock = {
      user: data.user,
      inTime: startOfDay(clickOnDate),
      outTime: undefined,
    };

    setEditingTimeclock(newTimeclock);
  }, [data, currentDate]);

  const setQueueSelect = useCallback(
    (selected, key) => {
      const nextIndex = queue.findIndex((el) =>
        key && selected[key] ? el[key] === selected[key] : el === selected
      );
      setIndex((prevIndex) => (prevIndex === nextIndex ? -1 : nextIndex));
    },
    [queue]
  );

  useEffect(() => {
    const iterator = makeTimeclockIterator(data);
    setQueue([...iterator]);
  }, [data.timeclock, data.jobs, data.cipJobs]);

  const onChangeTimeclock = useCallback((t) => {
    setEditingTimeclock(t);
  }, []);

  const onDeleteTimeclock = useCallback((t) => {
    setDeletingTimeclock(t);
  }, []);

  const onEditingTimeclockReset = useCallback(() => {
    setEditingTimeclock(null);
    return console.warn('resetting timeclock skipped');

    const newTimeclock = {
      ...editingTimeclock,
      inTime: editingTimeclock.realInTime,
      outTime: editingTimeclock.realOutTime,
    };

    if (newTimeclock._id) {
      onUpdateTimeclock(newTimeclock);
    } else {
      onCreate(newTimeclock);
    }
  }, [editingTimeclock]);

  const onEditingLunchTimeReset = useCallback(() => {
    setEditingLunchTime(null);
    const newTimeclock = {
      ...editingTimeclock,
      inTime: editingTimeclock.realInTime,
      outTime: editingTimeclock.realOutTime,
    };
    if (newTimeclock._id) {
      onChangeLunchTime(newTimeclock);
    } else {
      onCreate(newTimeclock);
    }
  }, [editingLunchTime]);

  const onConfirmDeleteTimeclock = useCallback(() => {
    onRemoveTimeclock(deletingTimeclock);
    setDeletingTimeclock(null);
  }, [deletingTimeclock]);

  const onEditingTimeclockConfirmed = useCallback(() => {
    if (editingTimeclock._id) {
      onUpdateTimeclock(editingTimeclock);
    } else {
      onCreate(editingTimeclock);
    }
    setEditingTimeclock(null);
  }, [editingTimeclock]);

  const onEditingLunchTimeConfirmed = useCallback(() => {
    onChangeLunchTime(editingLunchTime);
    setEditingLunchTime(null);
  }, [editingLunchTime]);

  const onConfirmChangeTimeclock = useCallback(() => {
    deletingStartTimeOverride &&
      onUpdateTimeclock({ ...deletingStartTimeOverride, overrideClockIn: 0 });
    deletingEndTimeOverride &&
      onUpdateTimeclock({ ...deletingEndTimeOverride, overrideClockOut: 0 });
    deletingLunchTime && onRemoveLunchTime({ ...deletingLunchTime, lunchTime: 0 });
    setDeletingStartTimeOverride(null);
    setDeletingEndTimeOverride(null);
    setDeletingLunchTime(null);
  }, [deletingStartTimeOverride, deletingEndTimeOverride, deletingLunchTime]);

  const editWorkerOnJob = async (editingData) => {
    const onJobHours = await userWorklogsDayHours(
      editingData.sheet._id,
      editingData.sheet.worker._id
    );

    setEditingOnJob(onJobHours);
  };

  const closeWorkerOnJob = () => setEditingOnJob(null);

  const selected = queue.length && index !== -1 ? queue[index] : null;

  return (
    <>
      <Grid className={cs(classes.root, 'timeclock-day-report-worklog')}>
        <div className={'timeclock-day-report-wrapper'}>
          {kiosk && (
            <div className={'timeclock-day-report-actions'}>
              <div className={classes.userTitleContainer}>
                <span className={classes.userTitleName}>{userTitle.name}</span>
                <span className={classes.userTitleNumber}>#{userTitle.number}</span>
              </div>
            </div>
          )}
          <div className={'timeclock-day-report-worklog-header'}>
            <Grid className={classes.worklogWrapper}>
              <Grid className={classes.worklogUsername}>
                <Grid className={classes.worklogUsernameWrapper}>
                  <dl className={'dl'}>
                    <dt className={classes.usernameGrid}>Username:</dt>
                    <dd className={classes.usernameGridIndex}>{data.user.username}</dd>
                  </dl>
                  <dl className={'dl'}>
                    <dt className={classes.usernameCode}>HI code #:</dt>
                    <dd className={'dd'}>{data.hiCode || data.user.profile.HICode}</dd>
                  </dl>
                </Grid>
              </Grid>
              <Grid className={classes.timeclockWrapper}>
                {editMode && !data.timeclock.length && (
                  <TimeclockItemEmpty key={'new'} onChange={onCreateTimeclock} />
                )}
                {data.timeclock.map((t) => (
                  <TimeclockItem
                    currentRoute={currentRoute}
                    hasPayroll={hasPayroll}
                    payrollVerified={payrollVerified}
                    payrollTimeclockItem={
                      payroll &&
                      (payroll.timeclocks || []).find((elem) => elem.timeclockId === t._id)
                    }
                    data={{ ...t, user: data.user }}
                    key={t._id}
                    editMode={editMode}
                    onDeleteStartTimeOverride={setDeletingStartTimeOverride}
                    onDeleteEndTimeOverride={setDeletingEndTimeOverride}
                    onDeleteLunchTime={setDeletingLunchTime}
                    onEditLunchTime={setEditingLunchTime}
                    onChange={onChangeTimeclock}
                    onDelete={onDeleteTimeclock}
                    onClick={() => setQueueSelect(t, '_id')}
                    className={
                      selected && t._id === selected._id && 'timeclock-day-report__selected'
                    }
                  />
                ))}
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={'timeclock-day-report-worklog-body'}>
          {!!data.jobs.length && (
            <Jobs
              currentRoute={currentRoute}
              hasPayroll={hasPayroll}
              payrollVerified={payrollVerified}
              payroll={payroll}
              data={data.jobs}
              shopTime={data.shopTime}
              onItemClick={(job, key) => setQueueSelect(job, key)}
              selected={selected}
              editMode={editMode}
              onChange={editWorkerOnJob}
            />
          )}
          <CIPProjects
            currentRoute={currentRoute}
            data={data.cipJobs}
            userId={data.user._id}
            selected={selected}
            editMode={editMode}
            // onChange={onChangeCip}
            // onDelete={onDeleteCip}
            onItemClick={(item) => setQueueSelect(item, 'timeclockId')}
          />
        </div>
        <Footer
          currentRoute={currentRoute}
          payrollVerified={payrollVerified}
          payroll={payroll}
          data={data}
          className={cs({
            'timeclock-day-report__selected': selected && selected === SummarySymbol,
          })}
          onClick={() => setQueueSelect(SummarySymbol)}
        />
      </Grid>

      {editMode && (
        <>
          <AddCipHoursModal
            data={data}
            onClose={!addCipHours}
            open={!!addCipHours}
            onChange={setEditingCip}
          />
          {/* <EditCipHoursModal
            onReset={setEditingCip}
            data={editingCip}
            onChange={onChangeCip}
            open={!!editingCip}
            onClose={() => setEditingCip(null)}
            onCancel={() => setEditingCip(null)}
            onSubmit={null}
          /> */}

          <EditTimeclockModal
            onReset={onEditingTimeclockReset}
            data={editingTimeclock}
            onChange={setEditingTimeclock}
            open={!!editingTimeclock}
            onClose={() => setEditingTimeclock(null)}
            onCancel={() => setEditingTimeclock(null)}
            onSubmit={onEditingTimeclockConfirmed}
          />

          <EditLunchTimeModal
            onReset={onEditingLunchTimeReset}
            data={editingLunchTime}
            onChange={setEditingLunchTime}
            open={!!editingLunchTime}
            onClose={() => setEditingLunchTime(null)}
            onCancel={() => setEditingLunchTime(null)}
            onSubmit={onEditingLunchTimeConfirmed}
          />

          <DeleteModal
            title={'Delete lunch time'}
            text={'Are you sure you want to delete lunch time?'}
            open={!!deletingLunchTime}
            onClose={() => setDeletingLunchTime(null)}
            onCancel={() => setDeletingLunchTime(null)}
            onSubmit={onConfirmChangeTimeclock}
          />

          <DeleteModal
            title={'Delete manual override time'}
            text={'Are you sure you want to delete manual override time'}
            open={!!deletingStartTimeOverride || !!deletingEndTimeOverride}
            onClose={() => setDeletingStartTimeOverride(null) || setDeletingEndTimeOverride(null)}
            onCancel={() => setDeletingStartTimeOverride(null) || setDeletingEndTimeOverride(null)}
            onSubmit={onConfirmChangeTimeclock}
          />

          <DeleteModal
            title={'Delete clock-in / clock-out pair'}
            text={'Are you sure the clock-in/out times should be deleted?'}
            open={!!deletingTimeclock}
            onClose={() => setDeletingTimeclock(null)}
            onCancel={() => setDeletingTimeclock(null)}
            onSubmit={onConfirmDeleteTimeclock}
          />
          {/* <DeleteModal
            title={'Delete CIP hours?'}
            text={'Are you sure want remove CIP hours?'}
            open={!!deletingCip}
            onClose={() => setDeletingCip(null)}
            onCancel={() => setDeletingCip(null)}
            onSubmit={onConfirmDeleteTimeclock}
          /> */}

          {editingOnJob && (
            <ChangeTimeModal
              onClose={closeWorkerOnJob}
              save={closeWorkerOnJob}
              data={editingOnJob}
            />
          )}
        </>
      )}
    </>
  );
};

export default withStyles(styles)(Worklog);
