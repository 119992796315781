import settingsActions from './settingsAction';
import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

const initialState = {
  settings: {},
};

const settings = createReducer(initialState.settings, {
  [settingsActions.getSettings]: (state, { payload }) => ({ ...state, ...payload }),
  [settingsActions.updateSettings]: (state, { payload }) => ({ ...state, ...payload }),
});

export default combineReducers({
  settings,
});
