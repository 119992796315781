import React from 'react';
import RangeBar from '../Range/Range';
import './styles.scss';
import quantityWorkingHoursInMonth from './helpers/quantityWorkingHoursInMonth';
import isCorrectNumber from '../SpecialRoleInfo/components/helpers/isCorrectNumber';

const HoursContainer = ({ data, user }) => {
  const allHoursInThisMonth = quantityWorkingHoursInMonth(user.profile.shifts.weekDays);

  return (
    <>
      {data && (
        <div className="hours-container">
          <h2 className="hours-title">Hours</h2>
          <div className="range-bar-wrapper">
            <RangeBar maxValue={40} value={isCorrectNumber(data.week)} label="This week" />
            <RangeBar
              maxValue={allHoursInThisMonth}
              value={isCorrectNumber(data.month)}
              label="This month"
            />
            <RangeBar maxValue={2080} value={isCorrectNumber(data.year)} label="This year" />
          </div>
        </div>
      )}
    </>
  );
};
export default HoursContainer;
