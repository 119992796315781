export const findEquipment = (id, equipmentList, sheets) => {
  const foundInEquipmentList = equipmentList?.find((eq) => eq._id === id);

  if (foundInEquipmentList) {
    return foundInEquipmentList;
  } else {
    const flattenedSheets = sheets?.flatMap((item) => item.equipment || []);
    const foundInSheets = flattenedSheets?.find((eq) => eq._id === id);

    if (foundInSheets) {
      return foundInSheets;
    } else {
      return undefined;
    }
  }
};
