import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles, Collapse } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroller';
import Brief from '../Brief/Brief';
import DayReport from '../DayReport';
import styles from './styles';
import NoData from '../NoData/NoData';
import useTimeclockActions from '../../hooks/useTimeclockActions';
import FiltersBar from './components/FiltersBar';
import useWeekHours from '../../hooks/useWeekHours';
import FiltersDrawer from './components/FiltersDrawer';
import timeclocksPageActions from 'store/timeclocks/timeclocksPageActions';
import useTimeclocks from '../../hooks/useTimeclocks';
import { getTimeclockSummary } from 'store/timeclocks/timeclocksPageOperations';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import LoadingSpinner from 'components/LoadingSpinner';
import Typography from '@material-ui/core/Typography';
import { parseStringDate } from 'helpers/_date-helpers';

const DayTimeclocks = ({ classes, ...props }) => {
  const dispatch = useDispatch();
  const params = useParams()

  const timeclocksDate = useSelector((state) => state.timeclocks.summary.query?.date);
  const summary = useSelector((state) => state.timeclocks.summary);

  useEffect(() => {
    const queryParams = { date: timeclocksDate }

    if (params.type === 'submitted') queryParams.getSubmitted = 'true';
    if (params.type === 'machine-shop') queryParams.getMachineShop = 'true';
    dispatch(timeclocksPageActions.setLoading(true))

    dispatch(getTimeclockSummary(queryParams))
  }, [timeclocksDate, params.type]);



  const { loading } = useSelector((state) => state.timeclocks);

  const timeclocks = useTimeclocks();
  const { gmWeekHours, msWeekHours } = useWeekHours(summary.query.date, timeclocks, loading);
  const { onChange, onRemove, onCreate, onChangeLunchTime } = useTimeclockActions(timeclocks);
  const [nextPageLoading, setNextPageLoading] = useState(false);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const weekday = useMemo(() => format(parseStringDate(summary.query.date), 'iiii'), [summary.query.date]);
  const filters = useMemo(
    () => ({
      sortField: summary.query.sortField,
      sortDirection: summary.query.sortDirection,
      type: summary.query.type,
    }),
    [summary.query.type, summary.query.sortField, summary.query.sortDirection]
  );

  const setFilters = useCallback(
    (filters) => {
      dispatch(
        timeclocksPageActions.setSummary({
          ...summary,
          query: {
            ...summary.query,
            sortField: filters.sortField,
            sortDirection: filters.sortDirection,
            type: filters.type,
          },
        })
      );
      setFiltersVisible(false);
    },
    [dispatch]
  );

  const loadMore = () => {
    if (loading || nextPageLoading || !summary.hasMore) return;
    setNextPageLoading(true);
    dispatch(timeclocksPageActions.setCurrentPage(summary.page + 1));
  };

  useEffect(() => {
    if (summary.data.length) {
      setTimeout(() => setNextPageLoading(false), 0);
    }
  }, [summary.data]);

if(loading)return (
  <Typography align={'center'}>
    <LoadingSpinner height={40} margin={32} />
  </Typography>)

  return (
    <div {...props}>
      <FiltersDrawer
        open={filtersVisible}
        value={filters}
        onClose={() => setFiltersVisible(false)}
        onConfirm={setFilters}
      />
      <FiltersBar className={classes.filters} onFiltersClick={() => setFiltersVisible((v) => !v)} />
      <Collapse in={weekday === 'Saturday'} className={classes.brief}>
        <Brief shopTime={msWeekHours} totalTime={gmWeekHours} />
      </Collapse>
      <div className={classes.timeclocks}>
        <InfiniteScroll
          loadMore={loadMore}
          hasMore={summary.hasMore}
          loader={
            <div className={classes.loader} key={0}>
              Loading ...
            </div>
          }
          threshold={1400}
        >
          {summary.data.map((d) => (
            <DayReport
              userTitle={{
                name: d.user.profile.fullName,
                number: d.user.profile.employeeNum,
              }}
              key={d.user._id}
              editable
              worklog
              data={d}
              currentDate={summary.query.date}
              className={classes.dayReport}
              onChangeTimeclock={onChange}
              onRemoveTimeclock={onRemove}
              onCreateTimeclock={onCreate}
              onChangeLunchTime={onChangeLunchTime}
            />
          ))}
        </InfiniteScroll>
      </div>
      {!loading && !summary.data.length && <NoData className={classes.dayReport} />}
    </div>
  );
};

export default withStyles(styles)(DayTimeclocks);
