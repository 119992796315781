import { isToday, isTomorrow, isYesterday, parse, format, isValid } from 'date-fns';
import { parseStringDate } from 'helpers/_date-helpers';

const deshDateRegex = /\d{1,2}-\d{1,2}-\d{1,2}/;
const slashDateRegex = /\d{1,2}\/\d{1,2}\/\d{4}/;

export const dayOfWeeekDateRevers = function (timestamp, dateFormatObject) {
  if (timestamp) {
    let momentTimestamp;

    if (slashDateRegex.test(timestamp) && isValid(parse(timestamp, dateFormatObject.dateFormat, new Date()))) {
      momentTimestamp = parse(timestamp, dateFormatObject.dateFormat, new Date());
    } else if (deshDateRegex.test(timestamp) && isValid(parse(timestamp, dateFormatObject.dateFormat, new Date()))) {
      momentTimestamp = parse(timestamp, dateFormatObject.dateFormat, new Date());
    } else if (isValid(parseStringDate(timestamp))) {
      momentTimestamp = parseStringDate(timestamp);
    } else {
      return '';
    }

    switch (momentTimestamp) {
      case isToday(momentTimestamp):
        return 'Today';
      case isTomorrow(momentTimestamp):
        return 'Tomorrow';
      case isYesterday(momentTimestamp):
        return 'Yesterday';
      default:
        return format(momentTimestamp, dateFormatObject.fullDayDateSettingsFormat);
    }
  }
  return '';
};
