import $api from 'http/index';
import { useState, useEffect } from 'react';
import { getOptions } from 'helpers/getOptions';

export const useGetCSV = (submissionId, type) => {
  const [loading, setLoading] = useState(true);
  const [csv, setCsv] = useState(null);

  useEffect(() => {
    const getCSV = async () => {
      try {
        const res = await $api.get(
          `${process.env.REACT_APP_BASE_URL}/submissions/${submissionId}/csv/${type}`,
          getOptions()
        );
        setCsv(res.data)
        setLoading(false)
      } catch (error) {
        console.log(error);
      }
    };
    getCSV()
  }, []);

  return { csv, loading };
};
