import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom'
import Input from '../ForgotPassword/components/Input/Input';
import './../ForgotPassword/styles.scss';
import axios from 'axios';
import SnackBar from 'components/SnackBar/SnackBar';

const confirmValidation = (value, confirmValue) => {
  let error = '';
  const requiredFieldError = "This field can't be blank";
  const correctError = 'Please enter correct password';

  const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^\w\s]).{8,}/;
  const rule = reg.test(value);
  if (!rule) {
    error = correctError;
  }
  if (value !== confirmValue) {
    error = correctError;
  }
  if (!value.trim()) {
    error = requiredFieldError;
  }

  return error;
};

const ResetPassword = () => {
  const [successfulyChangedPass, setSuccessfulyChangedPass] = useState(false);
  const [value, setValue] = useState('');
  const [confirmValue, setConfirmValue] = useState('');
  const [error, setError] = useState(false);
  const [snackbar, setSnackbar] = useState(null);

  const [queryParams, _] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (queryParams.get('token')) {
      const token = queryParams.get('token');
      localStorage.setItem('resetPasswordToken', token);

      const checkToken = async () => {
        const options = {
          headers: {
            'X-Reset-Password-Token': token,
          },
        };
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/reset-password/check-token`,
            options
          );
        } catch (error) {
          setSnackbar({
            text: error.response.data.message,
            type: 'error',
          });
          setTimeout(() => {
            navigate('/signin', {replace: true})
          }, 2000);
        }
      };
      checkToken();
    }
  }, []);

  const backToSignIn = () => {
    navigate('/signin', {replace: true})
  };

  const saveNewPass = async () => {
    const rule = confirmValidation(value, confirmValue);
    if (!rule) {
      const options = {
        headers: {
          'X-Reset-Password-Token': localStorage.getItem('resetPasswordToken'),
        },
      };
      try {
        const data = {
          password: value,
        };
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/reset-password`,
          data,
          options
        );
        setSuccessfulyChangedPass(true);
      } catch (error) {
        setSnackbar({
          text: error.response.data.message,
          type: 'error',
        });
      }
    } else {
      setError(rule);
    }
  };
  return (
    <section className={'forgot-pass-wrapper'}>
      <span className={'back-link'} onClick={backToSignIn}>
        {'< Back to Sign In page'}
      </span>
      <div className={'forgot-pass-block'} style={{ marginTop: 100 }}>
        <h2 className={'forgot-pass-title'}>3208 Technology, Inc.</h2>
        {!successfulyChangedPass && (
          <div className={'forgot-pass-form'}>
            <p className={'forgot-form-title'}>Create new password</p>
            <p className={'forgot-form-info'} style={{ fontSize: 12 }}>
              Your new password should contain 8+ characters with at least 1 number, 1 uppercase
              letter, 1 lowercase and 1 special character
            </p>
            <Input
              label={'New password'}
              value={value}
              setValue={setValue}
              error={error}
              setError={setError}
              type={'password'}
            />
            <Input
              label={'Confirm new password'}
              value={confirmValue}
              setValue={setConfirmValue}
              error={error}
              setError={setError}
              type={'password'}
            />
            <button className={'forgot-btn'} onClick={() => saveNewPass()}>
              Save
            </button>
          </div>
        )}
        {successfulyChangedPass && (
          <div className={'forgot-pass-form'}>
            <p className={'forgot-form-title'}>Your password has been changed</p>
            <button
              className={'forgot-btn'}
              onClick={() => {
                navigate('/signin', {replace: true})
              }}
            >
              Sign In
            </button>
          </div>
        )}
      </div>
      {snackbar && <SnackBar closeSnackbar={() => setSnackbar(null)} {...snackbar} isOpen={true} />}
    </section>
  );
};
export default ResetPassword;
