export default (theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      width: 550,
    },
  },
  textField: {
    [theme.breakpoints.up('lg')]: {
      // maxWidth: 250,
    },
  },
  marginVertical: {
    margin: '20px 0',
  },
  error: {
    color: theme.palette.textColor.red,
    padding: '0 5px',
    fontSize: 11,
  },
  buttonsWrapper: {
    paddingTop: 28,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: 120,
    '& p': {
      marginBottom: 0,
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
    },
  },
  flexBox: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '10px',
    '& > div': {
      // minWidth: 70,
      width: '100%',
    },
  },
  flexBox2: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
    '& > label': {
      width: '50%',
      display: 'block',
      '&:nth-child(2)': {
        paddingLeft: 15,
        boxSizing: 'border-box',
        // [theme.breakpoints.down('sm')]: {
        //   paddingLeft: 0,
        // },
      },
      '& > span:last-child': {
        color: theme.palette.textColor.dark,
      },
    },
  },
  flexButt: {
    paddingTop: 22,
    textAlign: 'right',
    marginRight: 10,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      '& button': {
        paddingRight: 0,
        paddingLeft: 0,
      },
    },
  },
  textArea: {
    width: '100%',
    '& textarea': {
      boxShadow: 'none',
      padding: 10,
      fontSize: 14,
      height: '60px !important',
      maxHeight: '60px !important',
    },
  },
  checkboxWrapper: {
    display: 'inline-flex',
    alignItems: 'center',
    paddingRight: 10,
    '& > span': {
      padding: '0 10px 0 0',
      color: `${theme.palette.error.red} !important`,
      '&:hover': {
        backgroundColor: '#fff important',
      },
    },
    '& > p': {
      color: theme.palette.error.red,
    },
  },
  zeroPaddingTop: {
    paddingTop: '0px !important',
  },
  flexBox3: {
    display: 'flex',
    alignItems: 'center',
  },
  userPhotoWrapper: {
    borderRadius: '50%',
    border: `1px solid ${theme.palette.borderColor.blue}`,
    width: 37,
    height: 37,
    marginRight: 15,
  },
  userPhoto: {
    display: 'block',
    height: 35,
  },
  name: {
    fontSize: 16,
    fontWeight: 500,
    padding: '4px 8px',
    borderRadius: '18px',
    color: 'white',
  },
  'text-black': {
    color: 'black',
  },
  removeButton: {
    margin: 0,
    padding: 0,
    border: 'none',
    background: 'none',
    outline: 'none',
    color: '#5A87EF',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '21px',
    '&:hover': {
      color: '#5A87EF',
      border: 'none',
      background: 'none',
    },
    '&:active': {
      color: '#5A87EF',
      border: 'none',
      background: 'none',
    },
  },
  removeButtonIcon: {
    height: 16,
    width: 16,
    margin: '2px 10px 2px 0',
  },
  subtractedError: {
    position: 'relative',
    width: '100%',
  },
});
