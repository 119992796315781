import './../../viewHours.scss';

const HoursTableHeader = () => {
  return (
    <thead>
      <tr class="header-row border-bottom">
        <td>Employee #</td>
        <td>Username</td>
        <td>Full Name</td>
        <td>HI Code</td>
        <td></td>
        <td></td>
        <td></td>
        <td>Clock-In</td>
        <td>Clock-Out</td>
        <td class="timeclock-table-hours">Hours</td>
      </tr>
    </thead>
  );
};

export default HoursTableHeader;
