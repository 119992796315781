import React, { useCallback } from 'react';
import { Formik, Form } from 'formik';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { TextField } from '@material-ui/core';
import { debounce } from 'lodash';

import './styles.scss';

const FiltersBar = ({ openFilters, updateSearchValue, isUserTable, children, isDynamicSearch }) => {
  const debouncedOnChange = useCallback(
    debounce((value) => {
      updateSearchValue(value);
    }, 300),
    []
  );

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="row"
      justifyContent="space-between"
      className={'super-admin-filters-bar-wrapper'}
    >
      <Formik
        initialValues={{ searchValue: '' }}
        onSubmit={(values) => {
          updateSearchValue(values.searchValue);
        }}
        autoComplete="off"
      >
        {({ values, setFieldValue, handleSubmit }) => (
          <Form className={'filters-search-form'} onSubmit={handleSubmit}>
            <TextField
              type="text"
              name="searchValue"
              variant="outlined"
              placeholder="Search"
              value={values.searchValue}
              onChange={(e) => {
                const input = e.target.value;
                setFieldValue('searchValue', input);
                isDynamicSearch && debouncedOnChange(input, setFieldValue);
              }}
              onBlur={handleSubmit}
              className={'search-field'}
              inputProps={{ 'aria-label': 'Search' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <div className={'search-icon'}>
                      <SearchIcon />
                    </div>
                  </InputAdornment>
                ),
              }}
            />
            <input type="submit" className={'search-input'} style={{ display: 'none' }} />
          </Form>
        )}
      </Formik>

      <Button
        variant="contained"
        color="secondary"
        className={'filters-button'}
        onClick={openFilters}
        disableTouchRipple
      >
        <i className={'fa fa-sliders filters-button__icon'} aria-hidden="true"></i>
        Filters
      </Button>
      {children}
    </Box>
  );
};

export default FiltersBar;
