import {makeStyles} from "@material-ui/core";

export default makeStyles({
    root: {
        padding: '12px 12px 18px 14px',
        border: '1px solid #D9DAE3',
        borderRadius: 4,
        backgroundColor: '#FFFFFF',
    },
    header: {
        paddingRight: 20,
        marginBottom: 14,
        position: 'relative'
    },
    title: {
        color: '#202020',
        fontSize: 16,
        fontWeight: 500,
        letterSpacing: '0.2px',
        lineHeight: '25px'
    },
    closeBtn: {
        position: 'absolute',
        right: -12,
        top: -12,
    },
    info: {
        padding: '14px 0',
        borderTop: '1px solid #EAEBF5',
        borderBottom: '1px solid #EAEBF5',
        marginBottom: 16
    },
    label: {
        color: "#A4A5B3",
        fontFamily: "Open Sans",
        fontSize: 14,
        letterSpacing: 0,
        lineHeight: '19px',
        marginRight: 6
    },
    value: {
        color: '#202020',
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: 0,
        lineHeight: '20px'
    },
    description: {
        color: '#202020',
        fontFamily: "Open Sans",
        fontSize: 14,
        letterSpacing: 0,
        lineHeight: '20px'
    }
})