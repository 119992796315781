import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import classnames from 'classnames';
import styles from '../../styles';
import Button from '@material-ui/core/Button';
import { Option } from 'components/Option';
import { v4 as uuidv4 } from 'uuid';
import MenuItem from '@material-ui/core/MenuItem';
import { getContactName } from 'helpers/_helpers';

const ContractorContacts = ({
  classes,
  values,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  fullyContractorContacts,
  handleSelectContact,
  addForm,
  handleContactAddForm,
  setFullyContractorContacts,
  validateForm,
  setErrors,
}) => {
  const clearContact = {
    name: '',
    title: '',
    email: '',
    phoneNumber: '',
  };
  const isContactSelected = !!values.selectedContactId;
  const addContactFromEditor = async () => {
    let err;
    await validateForm().then((errors) => {
      err = errors;
    });
    if (err.newContact) return;

    if (!isContactSelected) {
      const newContact = {
        name: values?.newContact?.name || '',
        title: values?.newContact?.title || '',
        email: values?.newContact?.email || '',
        phoneNumber: values?.newContact?.phoneNumber || '',
        new: true,
        _id: uuidv4(),
      };

      setFullyContractorContacts((prev) => [...prev, newContact]);
    } else {
      setFullyContractorContacts((prev) =>
        prev.map((el) =>
          el._id === values.selectedContactId
            ? {
                ...el,
                name: values?.newContact?.name || '',
                title: values?.newContact?.title || '',
                email: values?.newContact?.email || '',
                phoneNumber: values?.newContact?.phoneNumber || '',
              }
            : el
        )
      );
    }
    setFieldValue('selectedContactId', '');
    resetEditForm();
    handleContactAddForm(false);
  };

  const resetEditForm = () => {
    setErrors({});
    setFieldValue('newContact', clearContact);
  };

  return (
    <Grid className={classnames(classes.flexBox, classes.fullWidth)}>
      <Grid xs={12} lg={12} container item justifyContent="space-between" alignItems="center">
        <Typography variant="h4" color="textPrimary" style={{ margin: '15px 0' }}>
          Contacts:
        </Typography>
        <Grid
          container
          xs={12}
          lg={12}
          className={classnames(classes.zeroPaddingTop, classes.fullWidth)}
        >
          <div className={classnames(classes.flexColumn, classes.paddingBottom, classes.fullWidth)}>
            <Typography variant="body1" color="textSecondary" style={{ margin: '5px 0' }}>
              Contact:
            </Typography>
            <div className={classnames(classes.flexBox, classes.fullWidth)}>
              <TextField
                select
                id={'selectedContactId'}
                name={'selectedContactId'}
                className={classnames(classes.textField, classes.customSelect)}
                value={values.selectedContactId}
                onChange={handleSelectContact(setFieldValue)}
                variant="outlined"
                fullWidth
                label={'Select Contact'}
                size="small"
              >
                <MenuItem key={'000'} value="" />
                {[...fullyContractorContacts]
                  .sort((a, b) => a.name?.localeCompare(b?.name))
                  .map((contact, i) => {
                    return (
                      <MenuItem value={contact._id} key={contact._id}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                            justifyContent: 'space-between',
                          }}
                        >
                          <span>{getContactName(contact)}</span>
                          <CloseIcon
                            color="error"
                            fontSize={'small'}
                            className={classnames(classes.delCustomSelectIcon)}
                            onClick={(e) => {
                              e.stopPropagation();
                              setFieldValue('selectedContactId', '');
                              handleContactAddForm(false);
                              setFullyContractorContacts((prev) =>
                                prev.filter((el) => el._id !== contact._id)
                              );
                            }}
                          />
                        </div>
                      </MenuItem>
                    );
                  })}
                <MenuItem key={'add'} value="add" className={classes.primary}>
                  + Create New Contact
                </MenuItem>
              </TextField>
              <IconButton
                style={{ backgroundColor: 'transparent', padding: '12px 0', marginLeft: 10 }}
                onClick={() => {
                  setFieldValue('selectedContactId', '');
                  handleContactAddForm(false);
                  setFieldValue('newContact', clearContact);
                  setErrors({});
                }}
                aria-label="Delete"
                disableTouchRipple
              >
                <CloseIcon color="error" />
              </IconButton>
            </div>
          </div>
        </Grid>

        {!!addForm && (
          <>
            <Grid container item xs={12} lg={12} justifyContent="space-between" alignItems="center">
              <Typography variant="h6" color="textPrimary" style={{ margin: '5px 0' }}>
                {isContactSelected ? 'Edit' : 'Add'} Contact:
              </Typography>
            </Grid>
            <Grid container spacing={3} key={'i'}>
              <Grid item xs={12} lg={6}>
                <label htmlFor="contactsName">
                  <Typography variant="body1" color="textSecondary">
                    Name<span className={classes.requiredSign}>*</span>:
                  </Typography>
                  <TextField
                    id={`newContact.name`}
                    name={`newContact.name`}
                    value={values?.newContact && values?.newContact?.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={(classes.textField, classes.zeroPaddingTop)}
                    error={Boolean(errors.newContact?.name)}
                    variant="outlined"
                    inputProps={{ 'aria-label': 'contactsName' }}
                    fullWidth
                  />
                  {errors?.newContact?.name ? (
                    <div className={classes.error}>{errors?.newContact?.name}</div>
                  ) : null}
                </label>
              </Grid>
              <Grid item xs={12} lg={6}>
                <label htmlFor="contactsTitle">
                  <Typography variant="body1" color="textSecondary">
                    Title:
                  </Typography>
                  <TextField
                    id={`newContact.title`}
                    name={`newContact.title`}
                    value={values?.newContact && values.newContact.title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={classes.textField}
                    variant="outlined"
                    inputProps={{ 'aria-label': 'contactsTitle' }}
                    fullWidth
                  />
                </label>
              </Grid>
              <Grid item xs={12} lg={6} className={classes.zeroPaddingTop}>
                <label htmlFor="contactsEmail">
                  <Typography variant="body1" color="textSecondary">
                    Email:
                  </Typography>
                  <TextField
                    id={`newContact.email`}
                    name={`newContact.email`}
                    value={values?.newContact && values.newContact.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={classes.textField}
                    error={Boolean(errors.newContact?.email)}
                    variant="outlined"
                    inputProps={{ 'aria-label': 'contactsEmail' }}
                    fullWidth
                  />
                  {errors?.newContact?.email ? (
                    <div className={classes.error}>{errors?.newContact?.email}</div>
                  ) : null}
                </label>
              </Grid>
              <Grid item xs={12} lg={6} className={classes.zeroPaddingTop}>
                <label htmlFor="contactsPhoneNumber">
                  <Typography variant="body1" color="textSecondary">
                    Phone number:
                  </Typography>
                  <TextField
                    id={`newContact.phoneNumber`}
                    name={`newContact.phoneNumber`}
                    value={values?.newContact && values.newContact.phoneNumber}
                    onChange={(e) => {
                      const { value } = e.target;
                      setFieldValue('newContact.phoneNumber', value.replace(/[^0-9]*/g, ''));
                    }}
                    error={Boolean(errors.newContact?.phoneNumber)}
                    onBlur={handleBlur}
                    className={classes.textField}
                    variant="outlined"
                    inputProps={{ 'aria-label': 'contactsPhoneNumber' }}
                    fullWidth
                  />
                  {errors?.newContact?.phoneNumber ? (
                    <div className={classes.error}>{errors?.newContact?.phoneNumber}</div>
                  ) : null}
                </label>
              </Grid>
            </Grid>
            <Grid container item xs={12} lg={12} justifyContent="flex-end" alignItems="center">
              <Grid item container justifyContent="flex-end" alignItems="center" xs={4} lg={3}>
                <Button
                  variant="text"
                  className={classes.primaryTextButton}
                  disabled={errors?.newContact || !values?.newContact?.name}
                  // disabled={!values?.newContact?.name}
                  onClick={() => {
                    addContactFromEditor();
                  }}
                  disableRipple
                >
                  Save
                </Button>
                <Typography
                  style={{
                    backgroundColor: 'transparent',
                    padding: '12px 0',
                    marginLeft: 10,
                    fontWeight: 500,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    handleContactAddForm(false);
                    resetEditForm();
                  }}
                  variant="body1"
                  color="textSecondary"
                  aria-label="Delete"
                  disableTouchRipple
                >
                  Cancel
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(ContractorContacts);
