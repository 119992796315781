import React, { useCallback, useState, useEffect, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import CIPProjectCard from '../CIPProjectCard';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import cipPageActions from 'store/cip/cipPageActions';
import DeleteModal from '../../../TimeClock/modals/DeleteModal';
import store from 'store/store';
import Typography from '../../../../components/FieldLabel';
import { getCipProjects, removeCipProjects, toggleCipProjects } from 'store/cip/cipPagesOperations';
import NoData from '../../../TimeClock/components/NoData';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import useStyles from './useStyles';
import FiltersBar from 'components/FiltersBar';

const getFilterApiStatusName = (index) => {
  return index === 0 ? 'true' : 'false';
};

const Projects = () => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const dispatch = useDispatch();
  const projects = useSelector((state) => state.cip.projects.data);
  const deleting = useSelector((state) => state.cip.projects.deleting);
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [searchValue, setSearchValue] = useState('');

  const filteredProjects = useMemo(
    () =>
      projects.filter((project) =>
        tabValue === 0 ? project.active === true : project.active === false
      ),
    [projects, tabValue]
  );

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(getCipProjects({ active: getFilterApiStatusName(tabValue), searchValue }));
      setLoading(false);
    };
    fetchData();
  }, [tabValue, searchValue]);

  const updateSearchValue = (value) => {
    setSearchValue(value);
  };

  const remove = useCallback(async (id) => {
    try {
      dispatch(removeCipProjects(id));
      enqueueSnackbar('Successfully Deleted!', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar('Error removing project', { variant: 'error' });
      console.error(e);
    }
  }, []);

  const onRemove = useCallback(
    (id) => {
      const state = store.getState().cip.projects;
      const project = state.data.find((p) => p._id === id);
      dispatch(cipPageActions.setProjects({ ...state, deleting: project }));
    },
    [dispatch]
  );

  const onDeleteCancel = useCallback(() => {
    const state = store.getState().cip.projects;
    dispatch(cipPageActions.setProjects({ ...state, deleting: null }));
  }, [dispatch]);

  const onDeleteSubmit = useCallback(() => {
    const state = store.getState().cip.projects;
    remove(state.deleting._id);
    dispatch(cipPageActions.setProjects({ ...state, deleting: null }));
  }, [dispatch]);

  const onActivate = useCallback(async (id, activated) => {
    try {
      dispatch(toggleCipProjects(id, activated));
      enqueueSnackbar(`Project ${activated ? 'activated' : 'deactivated'} successfully`, {
        variant: 'success',
      });
    } catch (e) {
      enqueueSnackbar(`Error ${activated ? 'activating' : 'deactivating'} project`, {
        variant: 'error',
      });
      console.error(e);
    }
  }, []);

  const handleChangeTab = (event, newValue) => {
    if (newValue === tabValue) return;
    setTabValue(newValue);
  };

  return (
    <div>
      <Tabs value={tabValue} onChange={handleChangeTab} className={classes.tabs}>
        <Tab label="Active" disableRipple />
        <Tab label="Inactive" disableRipple />
      </Tabs>
      <FiltersBar noFilters={true} updateSearchValue={updateSearchValue} isDynamicSearch={true} />
      {loading ? (
        <Typography align={'center'}>
          {' '}
          <LoadingSpinner height={40} margin={32} />
        </Typography>
      ) : (
        <>
          <Grid container spacing={2}>
            {filteredProjects.map((project) => (
              <Grid key={project._id} item xs={12} lg={3} xl={3}>
                <CIPProjectCard
                  onActivate={onActivate}
                  onClose={onRemove}
                  onClick={() => dispatch(cipPageActions.setEditingProject(project))}
                  id={project._id}
                  title={project.name}
                  active={project.active}
                  description={project.description}
                  hoursLogged={project.totalHours}
                />
              </Grid>
            ))}
          </Grid>
          {!filteredProjects.length && <NoData />}
        </>
      )}
      <DeleteModal
        onClose={onDeleteCancel}
        onCancel={onDeleteCancel}
        onSubmit={onDeleteSubmit}
        title={'Delete CIP Project'}
        text={
          <Typography align={'center'} gutterBottom={false}>
            Are you sure you want to delete this project? <br /> This action cannot be reversed.
          </Typography>
        }
        open={!!deleting}
      />
    </div>
  );
};

export default Projects;
