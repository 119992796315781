export const styles = (theme) => ({
  wrapper: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  wrapperForKiosk: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '650px',
      margin: '0 auto',
    },
  },
  wrapperKioskInputs: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '2.5em',
    '& input[disabled]':{
      boxSizing: 'content-box',
      height: '17px !important',
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      marginBottom: 0,
      alignItems: 'flex-end',
    },
  },
  greeting: {
    fontWeight: 400,
    [theme.breakpoints.down('md')]: {
      display: 'none !important',
    },
  },
  kioskInputUsername: {
    marginRight: '10px',
    minWidth: '220px',
    '@media (min-width: 991.95px)': {
      maxWidth: '235px',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  kioskLabelUsername: {
    height: '78px',
    [theme.breakpoints.down('md')]: {
      height: 'auto',
      width: '100%',
    },
  },
  loginButton: {
    margin: '0 0 0 0.5rem',
    background: '#fff !important',
    backgroundColor: '#fff !important',
    border: '2px solid #5a87ef !important',
    width: 35,
    height: 35,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
    },
    [theme.breakpoints.down('md')]: {
      margin: '0.7rem 0 0 0.5rem',
      padding: 0,
    },
  },
  employeeNum: {
    minHeight: '1.1876em',
    '& input:disabled': {
      padding: '.5rem!important',
      backgroundColor: theme.palette.background.default,
      color: theme.palette.textColor.secondary,
    },
  },
  hiddenPass: {
    '-webkit-text-security': 'disc',
  },
  togglePassword: {
    color: theme.palette.textColor.blue,
    '-webkit-text-security': 'none',
    '&:hover': {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.textColor.blue,
    },
    '&:focus': {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.textColor.darkBlue,
    },
  },
  error: {
    color: theme.palette.textColor.red,
    // padding: '0 5px',
    padding: '0 0px',
    fontSize: 11,
    // position: 'absolute',
  },
  inputRoot: {
    padding: '0!important',
  },
  inputRootError: {
    borderColor: `${theme.palette.textColor.red}!important`,
  },
  input: {
    padding: '.5rem!important',
    backgroundColor: `${theme.palette.background.default}!important`,
    '&:disabled': {
      color: `${theme.palette.textColor.secondary}!important`,
    },
  },
  endAdornment: {
    '& button:hover': {
      backgroundColor: 'transparent!important',
      color: 'rgba(0, 0, 0, 0.54)!important',
    },
  },
  option: {
    fontSize: 14,
  },
  popper: {
    top: 37,
    width: '100%!important',
  },
});
