export default (theme) => ({
  dialogRoot: {
    position: 'relative',
    overflow: 'visible',
    [theme.breakpoints.up('sm')]: {
      width: 390,
      height: 540,
    },
    [theme.breakpoints.down('sm')]: {
      margin: 5,
    },
  },
  header: {
    padding: '16px 20px 20px',
  },
  content: {
    padding: 0,
  },
  closeButton: {
    position: 'absolute',
    right: 20,
    top: 19,
    padding: 0,
    color: theme.palette.textColor.dark,
    '&:hover': {
      color: theme.palette.textColor.dark,
      background: theme.palette.background.default,
    },
    '&:focus': {
      color: theme.palette.textColor.dark,
      background: theme.palette.background.default,
    },
  },
  selectedCount: {
    fontSize: '14px',
    lineHeight: 1,
    fontWeight: 500,
    color: theme.palette.textColor.secondary,
  },
  rootActions: {
    padding: '20px 45px',
    margin: 0,
  },
  extraBtn: {
    padding: 0,
    fontSize: 14,
    lineHeight: 1,
  },
  assignBtn: {
    '&:hover': {
      color: theme.palette.textColor.primary,
      backgroundColor: 'rgba(255, 255, 255, 0.8) !important',
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
      backgroundColor: 'rgba(255, 255, 255, 0.8) !important',
    },
  },
  selectBtn: {
    '&:hover': {
      color: theme.palette.textColor.secondary,
      backgroundColor: 'rgba(255, 255, 255, 0.8) !important',
    },
    '&:focus': {
      color: theme.palette.textColor.secondary,
      backgroundColor: 'rgba(255, 255, 255, 0.8) !important',
    },
  },
  cancelBtn: {
    width: 140,
    textTransform: 'none',
    color: theme.palette.textColor.secondary,
    background: theme.palette.background.default,
    '&:hover': {
      color: theme.palette.textColor.secondary,
      background: `${theme.palette.background.default} !important`,
    },
    '&:focus': {
      color: theme.palette.textColor.secondary,
      background: `${theme.palette.background.default} !important`,
    },
    '&:disabled': {
      background: `${theme.palette.background.default} !important`,
      borderColor: theme.palette.borderColor.secondary,
      color: theme.palette.secondary.main,
    },
  },
  confirmBtn: {
    width: 140,
    textTransform: 'none',
    color: theme.palette.textColor.primary,
    background: theme.palette.background.default,
    borderColor: theme.palette.borderColor.blue,
    '&:disabled': {
      background: theme.palette.background.default,
      borderColor: theme.palette.borderColor.secondary,
      color: theme.palette.secondary.main,
    },
    '&:hover': {
      background: theme.palette.background.blue,
      borderColor: theme.palette.borderColor.blue,
      color: theme.palette.textColor.white,
    },
    '&:focus': {
      background: theme.palette.background.blue,
      borderColor: theme.palette.borderColor.blue,
      color: theme.palette.textColor.white,
    },
  },
  leftSidebar: {
    position: 'absolute',
    left: '-182px',
    top: 0,
    width: 177,
    height: 250,
    backgroundColor: theme.palette.background.default,
    borderRadius: '4px',
    overflowY: 'auto',
  },
  rightSidebar: {
    position: 'absolute',
    right: '-285px',
    top: 0,
    width: 280,
    maxHeight: 370,
    backgroundColor: theme.palette.background.default,
    borderRadius: '4px',
    [theme.breakpoints.down('sm')]: {
      right: 0,
      border: `1px solid ${theme.palette.borderColor.primary}`
    },
  },
  holiday: {
    zIndex: 1400,
    maxHeight: 506,
    minHeight: 475,
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
  },
  holidaySm: {
    maxHeight: 475,
  },
});
