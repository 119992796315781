import axios from 'axios';
import { setItems, removeItems } from 'helpers/localStorage';
import loginActions from './loginActions';
import { getTimeZone } from '../../helpers/getTimeZone';
import personalProfileActions from 'store/personalProfile/personalProfileActions';
import kioskPageActions from 'store/kiosk/kioskPageActions';
import permissionsActions from 'store/permissions/permissionsActions';

export const loginWithPassword = (username, password, navigate) => async (dispatch) => {

  const data = {
    username,
    password,
  }

    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/login`, data);

    const localData = [
      { key: 'authorization-token', value:response.data.token },
      { key: 'authorization-token-exp-at', value:response.data.expiresAt },
      { key: 'refresh-token', value:response.data.refreshToken },
      { key: 'refresh-token-exp-at', value:response.data.refreshExpiresAt },
    ]

    dispatch(loginActions.setAuthUser(response.data));
    setItems(localData)
    navigate('/')

};

export const loginWithCode = (code, navigate) => async (dispatch) => {

  // try {
    const data = {phoneCode: code}
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/login/phone/code`,
      data
    );

    const localData = [
      { key: 'authorization-token', value:response.data.token },
      { key: 'authorization-token-exp-at', value:response.data.expiresAt },
      { key: 'refresh-token', value:response.data.refreshToken },
      { key: 'refresh-token-exp-at', value:response.data.refreshExpiresAt },
    ]

    dispatch(loginActions.setAuthUser(response.data));
    setItems(localData)
    navigate('/')

  // } catch (error) {
  //   console.log('login error code');
  // }
};

export const logout = () => async (dispatch) => {
  const options = {
    headers: {
      'X-Auth-Token': localStorage.getItem('authorization-token'),
      'X-User-TZ': getTimeZone(),
    },
  };

  try {
    removeItems([
      'authorization-token',
      'authorization-token-exp-at',
      'refresh-token',
      'refresh-token-exp-at',
      'userId',
      'x-auth-kiosk-token',
      'x-admin-kiosk-token'
    ])
    localStorage.setItem('isKioskPage', '0')
    dispatch(kioskPageActions.logout())
    dispatch(permissionsActions.clearPermissions())
    dispatch(kioskPageActions.clearPersonalProfile())
    dispatch(loginActions.clearUserData());
    dispatch(personalProfileActions.clearPersonalProfile());

    await axios.post(
      `${process.env.REACT_APP_BASE_URL}/logout`, null,
      options
    );


  } catch (error) {
    console.log('login error');
  }
};