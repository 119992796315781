export default (theme) => ({
  containerWrapper: {
    alignItems: 'flex-start',
  },
  colorSwatch: {
    height: 14,
    width: 18,
    borderRadius: 11,
    marginRight: 10,
    display: 'inline-block',
    verticalAlign: 'middle',
    border: '1px solid #000',
    marginBottom: 1,
  },
  header: {
    fontSize: 15,
    marginBottom: 4,
    color: theme.palette.textColor.primary,
    cursor: 'pointer',
  },
  materialTable: {
    width: 'auto !important',
    border: 'none',
    marginBottom: 8,
    '& td': {
      verticalAlign: 'middle',
      padding: '0.5625rem 0.625rem',
    },
    '& td:first-child': {
      paddingLeft: 0,
      width: '50%',
      [theme.breakpoints.up('md')]: {
        width: 150,
      },
    },
  },
  equals: {
    padding: '0 15px',
    fontSize: 24,
    color: theme.palette.textColor.secondary,
  },
  breakWord: {
    wordBreak: 'break-word',
    fontFamily: 'Poppins,Open Sans,sans-serif',
    fontSize: 14,
  },
  deleteButton: {
    padding: 0,
    display: 'block',
    margin: '0 0 10px auto',
    color: theme.palette.textColor.red,
    '&:hover': {
      color: theme.palette.textColor.red,
      background: theme.palette.background.default,
    },
    '&:focus': {
      color: theme.palette.textColor.red,
      background: theme.palette.background.default,
    },
  },
  primaryButton: {
    padding: 0,
    display: 'block',
    margin: '0 0 0 auto',
    color: theme.palette.textColor.primary,
    '&:hover': {
      color: theme.palette.textColor.primary,
      background: theme.palette.background.default,
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
      background: theme.palette.background.default,
    },
  },
  icon: {
    marginRight: 10,
    display: 'inline-block',
    verticalAlign: 'middle',
    maxHeight: 20,
  },
});
