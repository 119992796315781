import { areIntervalsOverlapping, isAfter, isValid } from 'date-fns';

export const isValidTimePeriods = (updatedHours = []) => {
  for (let i = 0; i < updatedHours.length; i++) {
    if (updatedHours[i]?.isWlHours === true) {
      continue;
    }
    for (let j = i + 1; j < updatedHours.length; j++) {
      if (updatedHours[j]?.isWlHours === true) {
        continue;
      }
      const start = new Date(updatedHours[j].start);
      const end = new Date(updatedHours[j].end);
      const outerStart = new Date(updatedHours[i].start);
      const outerEnd = new Date(updatedHours[i].end);
      if (!isValid(start) || !isValid(end) || !isValid(outerStart) || !isValid(outerEnd) || start > end || outerStart > outerEnd) {
        return false;
      }
      const valid = !areIntervalsOverlapping({ start: outerStart, end: outerEnd }, { start, end }, { inclusive: true });
      if (!valid) return false;
    }
  }
  return true;
};

export const invalidTimePeriods = (updatedHours = []) => {
  const indexes = [];
  for (let i = 0; i < updatedHours.length; i++) {
    if (updatedHours[i]?.data?.isWlHours === true) {
      continue;
    }
    for (let j = i + 1; j < updatedHours.length; j++) {
      if (updatedHours[j]?.data?.isWlHours === true) {
        continue;
      }
      const start = new Date(updatedHours[j].data?.start);
      const end = new Date(updatedHours[j].data?.end);
      if(!isValid(start) || !isValid(end)) {
        continue;
      }
      const outerStart = new Date(updatedHours[i].data?.start);
      const outerEnd = new Date(updatedHours[i].data?.end);
      if(!isValid(outerStart) || !isValid(outerEnd)) {
        continue;
      }
      if (start > end || outerStart > outerEnd) {
        indexes.push(...[updatedHours[i].index, updatedHours[j].index]);
        continue;
      }

      const isOverlapping = areIntervalsOverlapping(
        { start: outerStart, end: outerEnd },
        { start, end },
        { inclusive: true }
      );
      if (isOverlapping) {
        indexes.push(...[updatedHours[i].index, updatedHours[j].index])};
    }
  }
  return [...new Set(indexes)];
};

export const isHoursOverlapseTimeOffs = (updatedHours = [], availableHours = [], sheet) => {
  const validationArray = updatedHours.map((hourObject) => {
    return availableHours.reduce((doOverlaps, availableHourObject) => {
      if (doOverlaps) return true;

      if (
        isAfter(new Date(hourObject?.start), new Date(hourObject?.end)) ||
        isAfter(new Date(availableHourObject.start), new Date(availableHourObject.end))
      ) {
        return;
      }

      if (
        sheet?.travelTimeOnly ||
        hourObject?.isWlHours ||
        !isValid(new Date(hourObject?.start)) ||
        !isValid(new Date(hourObject?.end))
      ) {
        if (!isValid(new Date(sheet?.hours?.start)) || !isValid(new Date(sheet?.hours?.end))) {// if sheet.travelTimeOnly hours = null
          return false;
        }
          return areIntervalsOverlapping(
            { start: new Date(sheet?.hours?.start), end: new Date(sheet?.hours?.end) },
            { start: new Date(availableHourObject.start), end: new Date(availableHourObject.end) },
            { inclusive: true }
          );
      }
      return areIntervalsOverlapping(
        { start: new Date(hourObject.start), end: new Date(hourObject.end) },
        { start: new Date(availableHourObject.start), end: new Date(availableHourObject.end) },
        { inclusive: true }
      );
    }, false);
  });
  return validationArray.some((el) => el);
};
