export const drawer = () => ({
  root: {},
  paper: {
    width: '100%',
    maxWidth: 570,
    padding: '20px 25px',
  },
});

export const filters = () => ({
  form: {
    height: '100%',
  },
  button: {
    minWidth: 140,
  },
});
