import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import kioskPageActions from 'store/kiosk/kioskPageActions';
import { getCompany, getKioskUsers } from 'store/kiosk/kioskPageOperations';
import Box from '../../components/Field';
import TopBar from './components/TopBar';
import Kiosk from '../../components/Kiosk';
import StillHereModal from './components/StillHereModal';
import { useKioskUser } from './hooks/useKioskUser';
import { IsHereListener } from '../../components/common';
import './styles.scss';
import { KIOSK_EVENT } from 'subscriptions/events/events.enum';

export const KioskPage = () => {
  const userList = useSelector((store) => store.kiosk.users);
  const { user, authorize, logout, error: authError, clearError } = useKioskUser();
  const [modalShown, setModalShown] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logoutFromStore = () => {
    dispatch(kioskPageActions.logout());
  };

  const closeModal = () => setModalShown(false);

  const handleNotHere = () => {
    closeModal();
    logoutFromStore();
    logout();
  };

  useEffect(() => {
    logoutFromStore();

    dispatch(getKioskUsers());
    dispatch(getCompany());
    const userId = localStorage.getItem('userId');
    if (!userId) {
      navigate('/signin');
    }
  }, []);

  useEffect(() => {
    // sockets
    dispatch(kioskPageActions.socketConnect(KIOSK_EVENT));

    return () => {
      dispatch(kioskPageActions.socketDisconnect(KIOSK_EVENT));
    };
  }, []);

  return (
    <Box display="flex" flexDirection="column" className="kioskModeWrapper">
      <TopBar />
      <Box
        className="page-width fullTab"
        style={{ paddingTop: 26, width: '100%', boxSizing: 'content-box' }}
      >
        <Box
          style={{
            padding: '0 15px',
            boxSizing: 'border-box',
            maxWidth: '1280px',
            margin: '0 auto',
          }}
        >
          <Kiosk
            logoutFromStore={logoutFromStore}
            authError={authError}
            clearAuthError={clearError}
            selectedUser={user}
            userList={userList}
            authorize={authorize}
            logout={logout}
          />
        </Box>
      </Box>
      {user && (
        <IsHereListener
          condition={!!user}
          wait={180}
          visible={modalShown}
          setVisible={setModalShown}
        >
          <StillHereModal onSubmit={closeModal} onCancel={handleNotHere} />
        </IsHereListener>
      )}
    </Box>
  );
};
