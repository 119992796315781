import { startOfDay, endOfDay, isAfter, isBefore } from 'date-fns';
import { SHEET_EVENT } from 'subscriptions/events/events.enum';
import { subscriptionsFabric } from 'subscriptions/subscriptions.fabric';
import workerSchedulePageActions from 'store/workerSchedule/workerSchedulePageActions';
import { fetchFullScheduleSheets } from 'store/workerSchedule/workerSchedulePageOperations';


const handlers = {
  [SHEET_EVENT.created]: (store) => (data) => {
    // Add only if sheet is published and is within selected day
    if (data.published) {
      const selectedDate = store.getState().workerSchedule.fullSchedule.selectedDate;
      const sheetStart = new Date(data.hours.start);
      const sheetEnd = new Date(data.hours.end);
      const dayStart = startOfDay(new Date(selectedDate));
      const dayEnd = endOfDay(new Date(selectedDate));
      if (
        (isAfter(sheetStart, dayStart) && isBefore(sheetStart, dayEnd)) ||
        (isAfter(sheetEnd, dayStart) && isBefore(sheetEnd, dayEnd)) ||
        (isBefore(sheetStart, dayStart) && isAfter(sheetEnd, dayEnd))
      ) {
        store.dispatch(workerSchedulePageActions.addFullScheduleSheet(data))
      }
    }
  },
  [SHEET_EVENT.updated]: (store) => (data) => {
    const sheets = store.getState().workerSchedule.fullSchedule.sheets;
    const sheetInSchedule = sheets.some((sheet) => sheet._id === data?._id);

    if (sheetInSchedule) {
      store.dispatch(workerSchedulePageActions.putFullScheduleSheet(data));
    }
  },
  [SHEET_EVENT.resourcesUpdated]: (store) => (data) => {
    const sheets = store.getState().workerSchedule.fullSchedule.sheets;
    if (data?.length) {
      for (const updatedSheet of data) {
        const sheetInSchedule = sheets.some((sheet) => sheet._id === updatedSheet?._id);

        if (sheetInSchedule) {
          store.dispatch(workerSchedulePageActions.patchFullScheduleSheet(updatedSheet));
        }
      }
    }
  },
  [SHEET_EVENT.deleted]: (store) => (data) => {
    const sheets = store.getState().workerSchedule.fullSchedule.sheets;
    const sheetInSchedule = sheets.some((sheet) => sheet._id === data?._id);

    if (sheetInSchedule) store.dispatch(workerSchedulePageActions.deleteFullScheduleSheet(data));
  },
  [SHEET_EVENT.notes]: (store) => (data) => {
    const sheets = store.getState().workerSchedule.fullSchedule.sheets;
    const sheetInSchedule = sheets.some((sheet) => sheet._id === data?._id);

    if (sheetInSchedule) {
      store.dispatch(workerSchedulePageActions.patchFullScheduleSheet(data));
    }
  },
  [SHEET_EVENT.updatedCrewLeader]: (store) => (data) => {
    const sheets = store.getState().workerSchedule.fullSchedule.sheets;
    const sheetInSchedule = sheets.some((sheet) => sheet._id === data?._id);

    if (sheetInSchedule) store.dispatch(workerSchedulePageActions.patchFullScheduleSheetWorker(data));
  },
  [SHEET_EVENT.canceled]: (store) => (data) => {
    const sheets = store.getState().workerSchedule.fullSchedule.sheets;
    const sheetInSchedule = sheets.some((sheet) => sheet._id === data?._id);

    if (sheetInSchedule) store.dispatch(workerSchedulePageActions.patchFullScheduleSheet(data));
  },
  [SHEET_EVENT.uncanceled]: (store) => (data) => {
    const sheets = store.getState().workerSchedule.fullSchedule.sheets;
    const sheetInSchedule = sheets.some((sheet) => sheet._id === data?._id);

    if (sheetInSchedule) store.dispatch(workerSchedulePageActions.patchFullScheduleSheet({ ...data, canceledAt: null, canceledBy: null }));
  },
  [SHEET_EVENT.publishedBunch]: (store) => async (data) => {
    const selectedDate = store.getState().workerSchedule.fullSchedule.selectedDate;

    await store.dispatch(fetchFullScheduleSheets(selectedDate));
  },
  [SHEET_EVENT.submitted]: (store) => (data) => {
    const sheets = store.getState().workerSchedule.fullSchedule.sheets;
    const sheetInSchedule = sheets.some((sheet) => sheet._id === data?._id);

    if (sheetInSchedule) {
      const submissions = sheetInSchedule.submissions?.map(sub => sub) || [];

      if (data.submission) submissions.push(data.submission);

      const updates = {
        _id: data._id,
        notes: data.notes || sheetInSchedule.notes || '',
        submittedAt: data.submittedAt,
        submittedBy: data.submittedBy,
        submissions: submissions,
      };

      store.dispatch(workerSchedulePageActions.patchFullScheduleSheet(updates));
    }
  },
  [SHEET_EVENT.submissionDeleted]: (store) => (data) => {
    const sheets = store.getState().workerSchedule.fullSchedule.sheets;
    const sheetInSchedule = sheets.some((sheet) => sheet._id === data?._id);

    if (sheetInSchedule) {
      let submissions = sheetInSchedule.submissions;
      if (submissions?.length) submissions = submissions.filter(submission => submission._id !== data.submission?._id);

      const updates = {
        _id: data._id,
        notes: data.notes || sheetInSchedule.notes || '',
        submittedAt: data.submittedAt,
        submittedBy: data.submittedBy,
      };
      if (submissions) updates.submissions = submissions.map(sub => sub);

      store.dispatch(workerSchedulePageActions.patchFullScheduleSheet(updates));
    }
  },
};

export const fullScheduleSheetsSubscriptionMiddleware = subscriptionsFabric(
  workerSchedulePageActions,
  SHEET_EVENT,
  handlers
);
