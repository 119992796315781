import {makeStyles} from "@material-ui/core";

export default makeStyles({
    root: {
        marginBottom: 10,
        minHeight: 64
    },
    title: {
        color: '#202020',
        fontSize: 24,
        fontWeight: 500,
        letterSpacing: '0.3px',
        lineHeight: '35px'
    }
})