export default () => ({
  root: {
    border: '1px solid #D9DAE3',
    borderRadius: 4,
    backgroundColor: '#FDFDFF',
    padding: 36,
    textAlign: 'center',
    color: '#6D7278',
    fontSize: 18,
    letterSpacing: '0.23px',
    lineHeight: '27px',
    marginTop: '15px',
    '@media (max-width: 999px)': {
      width: '650px',
      margin: '0 auto',
      padding: '36px 0',
    },
    '@media (max-width: 680px)': {
      width: '500px',
    },
    '@media (max-width: 530px)': {
      width: 'auto',
      margin: 0,
    },
  },
});
