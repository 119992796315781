import React from 'react';
import { Button, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';

import { CloseButton } from 'pages/TimeClock/modals/base';

export const InjuryCheck = ({ classes, onClose, onNegative, onPositive }) => {
  return (
    <>
      <DialogTitle disableTypography style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="h6">Clock Out</Typography>
        <CloseButton style={{ top: 16 }} onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <Typography variant="h5" color="textSecondary">
          Were you injured on the job today?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onNegative}
          variant="contained"
          color="primary"
          className={classes.cancelBtn}
          disableTouchRipple>
          No
        </Button>
        <Button
          onClick={onPositive}
          variant="outlined"
          color="primary"
          className={classes.confirmBtn}
          disableTouchRipple>
          Yes
        </Button>
      </DialogActions>
    </>
  );
};
