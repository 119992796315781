export default (theme) => ({
  root: {
    // height: '95vh',
    overflow: 'hidden',
    position: 'relative',
    zIndex: 100,
  },
  smallHeight: {
    height: 'auto',
    overflow: 'hidden',
    position: 'relative',
    zIndex: 100,
  },
  progressWrapper: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 200,
    background: 'rgba(255, 255, 255, 0.7)',
  },
  progress: {
    position: 'absolute',
    top: '30%',
    left: 'calc(50% - 12px)',
  },
});
