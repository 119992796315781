import { SHEET_EVENT } from 'subscriptions/events/events.enum';
import { subscriptionsFabric } from 'subscriptions/subscriptions.fabric';
import currentWorklogActions from 'store/currentWorklog/actions';


const handlers = {
  [SHEET_EVENT.updated]: (store) => (data) => {
    const currentWorklog = store.getState().currentWorklog.worklogSheet?.sheet;
    if (currentWorklog?._id === data._id) {
      store.dispatch(currentWorklogActions.setWorklogSheet(data));
    }
  },
  [SHEET_EVENT.resourcesUpdated]: (store) => (data) => {
    const currentWorklog = store.getState().currentWorklog.worklogSheet?.sheet;
    const currentWorklogUpdates = data?.find(worklog => worklog._id === currentWorklog?._id)
    if (currentWorklogUpdates) {
      store.dispatch(currentWorklogActions.patchWorklogSheet(currentWorklogUpdates));
    }
  },
  [SHEET_EVENT.deleted]: (store) => (data) => {
    const currentWorklog = store.getState().currentWorklog.worklogSheet?.sheet;
    if (currentWorklog?._id && data?._id === currentWorklog?._id) {
      store.dispatch(currentWorklogActions.clearWorklogSheet());
    }
  },
  [SHEET_EVENT.returnToShopChanged]: (store) => (data) => {
    const currentWorklog = store.getState().currentWorklog.worklogSheet?.sheet;
    if (data?._id === currentWorklog?._id) {
      store.dispatch(currentWorklogActions.patchWorklogSheet(data))
    }
  },
  [SHEET_EVENT.notes]: (store) => (data) => {
    const currentWorklog = store.getState().currentWorklog.worklogSheet?.sheet;

    if (data?._id === currentWorklog?._id) {
      store.dispatch(currentWorklogActions.patchWorklogSheet(data))
    }
  },
  [SHEET_EVENT.noteCommentAdded]: (store) => (data) => {
    const currentWorklog = store.getState().currentWorklog.worklogSheet?.sheet;
    if (currentWorklog?._id && currentWorklog?._id === data?._id && data.noteComment?._id) {
      store.dispatch(currentWorklogActions.newNotesComment(data.noteComment));
    }
  },
  [SHEET_EVENT.noteCommentUpdated]: (store) => (data) => {
    const currentWorklog = store.getState().currentWorklog.worklogSheet?.sheet;
    if (currentWorklog?._id && currentWorklog?._id === data?._id && data.noteComment?._id) {
      store.dispatch(currentWorklogActions.editNotesComment(data.noteComment));
    }
  },
  [SHEET_EVENT.noteCommentDeleted]: (store) => (data) => {
    const currentWorklog = store.getState().currentWorklog.worklogSheet?.sheet;
    if (currentWorklog?._id && currentWorklog?._id === data?._id && data.noteComment?._id) {
      store.dispatch(currentWorklogActions.deleteNotesComment(data.noteComment._id));
    }
  },
  [SHEET_EVENT.weather]: (store) => (data) => {
    const currentWorklog = store.getState().currentWorklog.worklogSheet?.sheet;
    if (currentWorklog?._id && currentWorklog?._id === data?._id) {
      store.dispatch(currentWorklogActions.patchWorklogSheet(data));
    }
  },
  [SHEET_EVENT.materialLocation]: (store) => (data) => {
    const currentWorklog = store.getState().currentWorklog.worklogSheet?.sheet;
    if (currentWorklog?._id && currentWorklog?._id === data?._id) {
      store.dispatch(currentWorklogActions.patchWorklogSheet(data));
    }
  },
  [SHEET_EVENT.updatedCrewLeader]: (store) => (data) => {
    const currentWorklog = store.getState().currentWorklog.worklogSheet?.sheet;
    const currentWorker = currentWorklog?.workers.some(worker => worker._id === data.worker._id);
    if (data?._id === currentWorklog?._id && currentWorker) {
      store.dispatch(currentWorklogActions.updateWorker({ ...currentWorker, ...data.worker }));
    }
  },
  [SHEET_EVENT.canceled]: (store) => (data) => {
    const currentWorklog = store.getState().currentWorklog.worklogSheet?.sheet;
    if (currentWorklog?._id && data?._id === currentWorklog?._id) {
      store.dispatch(currentWorklogActions.patchWorklogSheet(data));
    }
  },
  [SHEET_EVENT.uncanceled]: (store) => (data) => {
    const currentWorklog = store.getState().currentWorklog.worklogSheet?.sheet;
    if (currentWorklog?._id && data?._id === currentWorklog?._id) {
      store.dispatch(currentWorklogActions.patchWorklogSheet({ _id: data._id, canceledAt: null, canceledBy: null }));
    }
  },
  [SHEET_EVENT.submitted]: (store) => (data) => {
    const currentWorklog = store.getState().currentWorklog.worklogSheet?.sheet;

    if (currentWorklog?._id && currentWorklog?._id === data?._id) {
      const submissions = currentWorklog.submissions?.map(sub => sub) || [];

      if (data.submission) submissions.push(data.submission);

      const updates = {
        _id: data._id,
        notes: data.notes || currentWorklog.notes || '',
        submittedAt: data.submittedAt,
        submittedBy: data.submittedBy,
        submissions: submissions,
      };

      store.dispatch(currentWorklogActions.patchWorklogSheet(updates));
    }
  },
  [SHEET_EVENT.submissionDeleted]: (store) => (data) => {
    const currentWorklog = store.getState().currentWorklog.worklogSheet?.sheet;
    if (currentWorklog?._id && currentWorklog?._id === data._id) {
      let submissions = currentWorklog.submissions;
      if (submissions?.length) submissions = submissions.filter(submission => submission._id !== data.submission?._id);

      const updates = {
        _id: data._id,
        notes: data.notes || currentWorklog.notes || '',
        submittedAt: data.submittedAt,
        submittedBy: data.submittedBy,
      };
      if (submissions) updates.submissions = submissions.map(sub => sub);

      store.dispatch(currentWorklogActions.patchWorklogSheet(updates))
    }
  },
  [SHEET_EVENT.publishedBunch]: (store) => async (data) => {
    const currentWorklog = store.getState().currentWorklog.worklogSheet?.sheet;
    if (currentWorklog?._id && data?.some(publishedSheet => publishedSheet?._id === currentWorklog?._id)) {
      store.dispatch(currentWorklogActions.patchWorklogSheet({ published: true, unpublishedChanges: false }));
    }
  },
  [SHEET_EVENT.sheetsImageAdded]: (store) => async (data) => {
    const currentWorklog = store.getState().currentWorklog.worklogSheet?.sheet;
    if (currentWorklog?._id && data?._id === currentWorklog?._id) {
      if(currentWorklog?.images.some(el => el._id === data.image._id)) {return}
      const updates = {
        images: [
          ...(currentWorklog?.images || []),
          data.image,
        ]
      };

      store.dispatch(currentWorklogActions.patchWorklogSheet(updates));
    }
  },
  [SHEET_EVENT.sheetsImageDeleted]: (store) => async (data) => {
    const currentWorklog = store.getState().currentWorklog.worklogSheet?.sheet;
    if (currentWorklog?._id && data?._id === currentWorklog?._id) {
      const updates = {
        images: (currentWorklog?.images || []).filter(worklogImage => worklogImage._id != data.image._id)
      };
  
      store.dispatch(currentWorklogActions.patchWorklogSheet(updates));
    }
  },
};


export const currentWorklogSheetsSubscriptionMiddleware = subscriptionsFabric(
  currentWorklogActions,
  SHEET_EVENT,
  handlers
);
