import React from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import classnames from 'classnames';
import GreyUserI from 'assets/images/user-photo-placeholder.svg';
import Divider from '@material-ui/core/Divider';
import { isIOS, Option } from 'components/Option';
import styles from './styles';
import ContractorContacts from './components/ContractorContacts/ContractorContacts';

const ContractorDetails = ({
  classes,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  file,
  isFileError,
  deleteLogo,
  contractor,
  setFieldValue,
  fullyContractorContacts,
  handleSelectContact,
  addForm,
  handleContactAddForm,
  setFullyContractorContacts,
  validateForm,
  setErrors,
}) => {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.zeroPaddingTop}>
          <div className={classes.flexBox}>
            <div className={classes.photoWrapper}>
              <img src={file || GreyUserI} className={classes.photo} />
            </div>
            <div>
              <div>
                <Button variant="text" className={classes.primaryButton}>
                  Choose logo
                </Button>
              </div>
              <div>
                <Button
                  variant="text"
                  className={classnames(classes.primaryButton, classes.secondaryButton)}
                >
                  Remove logo
                </Button>
              </div>
              {/* {file ? (
                <div
                  className={classnames(classes.resetButton, classes.deleteButton)}
                  onClick={deleteLogo}
                >
                  Delete logo
                </div>
              ) : ( */}
              {/* <Typography variant="caption" component="p" color="textPrimary">
                  Choose logo
                </Typography> */}
              {/* )} */}
              {/* <label htmlFor="contractorPhoto">
                <input
                  type="file"
                  name="contractorPhoto"
                  id="contractorPhoto"
                  accept="image/*"
                  style={{display: 'none'}}
                  onChange={handleFileChange}
                /> */}
              {/* <div className={classes.resetButton}>
                  Choose logo
                </div> */}
              {/* </label> */}
            </div>
          </div>
          {isFileError ? (
            <div className={classes.error}>Too large image. No more than 3MB</div>
          ) : null}
        </Grid>
        <Grid item xs={12} lg={6}>
          <label htmlFor="name">
            <Typography variant="body1" color="textSecondary">
              Name<span className={classes.requiredSign}>*</span>:
            </Typography>
            <TextField
              id="name"
              name="name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              className={classes.textField}
              error={Boolean(errors.name) && Boolean(touched.name)}
              variant="outlined"
              inputProps={{ 'aria-label': 'Name' }}
              fullWidth
            />
            {errors.name && touched.name ? (
              <div className={classes.error}>{errors.name}</div>
            ) : null}
          </label>
        </Grid>
        <Grid item xs={12} lg={6}>
          <label htmlFor="nickname">
            <Typography variant="body1" color="textSecondary">
              Nickname:
            </Typography>
            <TextField
              id="nickname"
              name="nickname"
              value={values.nickname}
              onChange={handleChange}
              onBlur={handleBlur}
              className={classes.textField}
              error={Boolean(errors.nickname) && Boolean(touched.nickname)}
              variant="outlined"
              inputProps={{ 'aria-label': 'Nickname' }}
              fullWidth
            />
            {errors.nickname && touched.nickname ? (
              <div className={classes.error}>{errors.nickname}</div>
            ) : null}
          </label>
        </Grid>
        <Grid item xs={12} lg={6}>
          <label htmlFor="active">
            <Typography variant="body1" color="textSecondary">
              Status:
            </Typography>
            <TextField
              select
              id="active"
              name="active"
              value={values.active}
              onChange={handleChange}
              className={classes.textField}
              variant="outlined"
              inputProps={{ 'aria-label': 'Status' }}
              fullWidth
              SelectProps={{
                native: isIOS,
              }}
            >
              <Option key={1} value={true}>
                Active
              </Option>
              <Option key={2} value={false}>
                Inactive
              </Option>
            </TextField>
          </label>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
      <ContractorContacts
        values={values}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        handleChange={handleChange}
        deleteLogo={deleteLogo}
        contractor={contractor}
        setFieldValue={setFieldValue}
        fullyContractorContacts={fullyContractorContacts}
        handleSelectContact={handleSelectContact}
        addForm={addForm}
        handleContactAddForm={handleContactAddForm}
        setFullyContractorContacts={setFullyContractorContacts}
        validateForm={validateForm}
        setErrors={setErrors}
      />
    </>
  );
};

ContractorDetails.propTypes = {
  classes: propTypes.object.isRequired,
  values: propTypes.object.isRequired,
  errors: propTypes.object.isRequired,
  touched: propTypes.object.isRequired,
  handleChange: propTypes.func.isRequired,
  handleBlur: propTypes.func.isRequired,
  handleFileChange: propTypes.func.isRequired,
  isFileError: propTypes.bool.isRequired,
  deleteLogo: propTypes.func.isRequired,
};

export default withStyles(styles)(ContractorDetails);
