import React from 'react';
import propTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import PageLoader from '../../../../components/PageLoader';
import styles from './styles';
import { isIOS, Option } from '../../../../components/Option';

const SortingAndFilteringPanel = ({ classes, closeDrawer, filter, updateFilter, isLoading }) => {
  const defaultValues = {
    sortBy: 'name',
    sortOrder: 'asc',
  };

  const sortingTypeLabel = (sortBy) => {
    switch (sortBy) {
      case 'createdAt':
        return ['Newest \u2013 Oldest', 'Oldest \u2013 Newest'];
      case 'year':
        return ['0 \u2013 9', '9 \u2013 0'];
      default:
        return ['A \u2013 Z', 'Z \u2013 A'];
    }
  };

  return (
    <div className={classes.formWrapper} role="presentation">
      <Formik
        initialValues={filter}
        enableReinitialize={true}
        onSubmit={(values) => {
          updateFilter(values);
        }}>
        {({ values, handleChange, resetForm }) => (
          <PageLoader loading={isLoading}>
            <Form autoComplete="off" className={classes.formFlex}>
              <div>
                <Typography variant="h3" className={classes.marginHeader}>
                  Filters
                </Typography>

                <IconButton className={classes.closeButton} onClick={closeDrawer} aria-label="Close" disableTouchRipple>
                  <CloseIcon />
                </IconButton>

                <Grid container spacing={2} className={classes.flexContainer}>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="sortBy" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Sort by:
                      </Typography>
                      <TextField
                        select
                        id="sortBy"
                        name="sortBy"
                        value={values.sortBy}
                        onChange={handleChange}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Sort by' }}
                        fullWidth
                        SelectProps={{
                          native: isIOS,
                        }}>
                        <Option key={1} value="name">
                          Equipment name
                        </Option>
                        <Option key={2} value="number">
                          Truck number
                        </Option>
                        <Option key={3} value="year">
                          Year made
                        </Option>
                        <Option key={4} value="model">
                          Model
                        </Option>
                      </TextField>
                    </label>
                  </Grid>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="sortOrder" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Sort from:
                      </Typography>
                      <TextField
                        select
                        id="sortOrder"
                        name="sortOrder"
                        value={values.sortOrder}
                        onChange={handleChange}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Days' }}
                        fullWidth>
                        <Option key={1} value="asc">
                          {sortingTypeLabel(values.sortBy)[0]}
                        </Option>
                        <Option key={2} value="desc">
                          {sortingTypeLabel(values.sortBy)[1]}
                        </Option>
                      </TextField>
                    </label>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      color="primary"
                      className={classes.resetButton}
                      onClick={() => resetForm({values:defaultValues})}
                      disableTouchRipple>
                      Reset all filters
                    </Button>
                  </Grid>
                </Grid>
              </div>

              <div className={classes.buttonHolder}>
                <Button color="secondary" className={classes.cancelButton} onClick={closeDrawer}>
                  Cancel
                </Button>

                <div className={classes.verticalDivider} />

                <Button type="submit" color="primary" className={classes.saveButton}>
                  Apply
                </Button>
              </div>
            </Form>
          </PageLoader>
        )}
      </Formik>
    </div>
  );
};

SortingAndFilteringPanel.propTypes = {
  classes: propTypes.object.isRequired,
  closeDrawer: propTypes.func.isRequired,
  filter: propTypes.object.isRequired,
  updateFilter: propTypes.func.isRequired,
  isLoading: propTypes.bool.isRequired,
};

export default withStyles(styles)(SortingAndFilteringPanel);
