import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from './styles';
import CrossIcon from '../../../../../../components/icons/Cross';
import ClockIcon from '../../../../../../components/icons/Clock';

const RemovableRow = ({ children, classes, onRemoveClick, canEdit, onEditClick }) => (
  <span className={classes.root}>
    <span className={classes.row}>{children}</span>
    <div>
      {canEdit && (
        <span className={classes.edit_btn} onClick={onEditClick}>
          <ClockIcon />
        </span>
      )}
      <span className={classes.remove_btn} onClick={onRemoveClick}>
        <CrossIcon />
      </span>
    </div>
  </span>
);

export default withStyles(styles)(RemovableRow);
