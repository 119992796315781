export default theme => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 8
  },
  editButton: {
    padding: 4,
    '&:hover': {
      background: `${theme.palette.background.transparent} !important`
    },
    '&:focus': {
      background: `${theme.palette.background.transparent} !important`
    }
  },
  hidForMobileWrapper: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
});
