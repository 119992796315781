import React, { useState } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import styles from './styles';

const CancelModal = ({ classes, isOpen, onClose, onSubmit }) => {
  const [text, setText] = useState('');
  const handleChange = e => setText(e.target.value);
  const handleSubmit = () => onSubmit(text.trim());

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        classes: {
          root: classes.dialogRoot
        }
      }}
    >
      <div className={classes.header}>
        <Typography variant="h5" color="textPrimary">
          Canceled Worklog
        </Typography>
        <IconButton
          className={classes.closeButton}
          onClick={onClose}
          aria-label="Close"
          disableRipple
          >
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent style={{ paddingLeft: '20px'}}>
        <Typography variant="body1" color="textSecondary">
          Reason for cancelling
        </Typography>
        <TextField
          id="cancelValue"
          name="cancelValue"
          rowsMax={2}
          value={text}
          onChange={handleChange}
          className={classes.textArea}
          variant="outlined"
          multiline
          fullWidth
          />
      </DialogContent>
      <DialogActions>
        <div className={classes.flexBox}>
          <Button
            color="secondary"
            variant="outlined"
            className={classes.cancelButton}
            onClick={onClose}
            disableTouchRipple
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="outlined"
            className={classes.saveButton}
            onClick={handleSubmit}
            disableTouchRipple
          >
            Mark
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

CancelModal.propTypes = {
  classes: propTypes.object.isRequired,
  isOpen: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  onSubmit: propTypes.func.isRequired
};

export default withStyles(styles)(CancelModal);
