export default (theme) => ({
  root: {
    padding: '0.5 rem',
    height: 40,
  },
  textField: {
    maxWidth: 250,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 200,
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '100%',
    },
  },
});
