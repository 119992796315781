import Gear from './../../assets/images/Gear.png';
import mobileGear from './../../assets/images/gear-mobile.png';

export default () => ({
  root: {
    '@media (max-width: 760px)': {
      height: '100vh',
    },
    height: 'calc(100vh - 72px - 220px)',
    padding: '20px',
    position: 'relative',
  },
  titleWrapper: {
    '@media (min-width: 999px)': {
      minHeight: 275,
    },
    '@media (max-width: 650px)': {
      minHeight: 140,
    },
  },
  title: {
    margin: 0,
    color: '#202020',
    fontFamily: 'Poppins',
    fontSize: 51,
    fontWeight: 700,
    letterSpacing: '0.5px',
    lineHeight: '60px',
    textAlign: 'center',
    display: 'block',
    marginBottom: '20px',
    '@media (max-width: 760px)': {
      fontSize: 25,
      marginBottom: 0,
    },
    '@media (max-width: 360px)': {
      fontSize: 20,
      marginBottom: 0,
    },
    '@media only screen and (max-width: 999px) and (orientation: landscape)': {
      fontSize: 25,
    },
  },
  form: {
    maxWidth: 340,
    width: '100%',
    margin: '0 auto',
  },
  footer: {
    marginTop: '15px',
  },
  loader: {
    color: '#5A87EF',
  },
  loaderContainer: {
    textAlign: 'center',
  },
  label: {
    color: '#202020',
    fontFamily: 'Open Sans',
    fontSize: 14,
    lineHeight: '19px',
    textAlign: 'center',
    marginRight: '10px',
  },
  link: {
    cursor: 'pointer !important',
    color: '#5A87EF !important',
    background: 'none !important',
    border: 'none !important',
    outline: 'none !important',
    padding: '0 !important',
    // margin: '0 auto !important',
    fontFamily: 'Open Sans',
    fontSize: '14px !important',
    lineHeight: '19px !important',
    textAlign: 'center !important',
    display: 'block !important',
    '&:hover, &:active, &:focus': {
      background: 'none !important',
      border: 'none !important',
      color: '#5A87EF !important',
    },
  },
  logoImg: {
    display: 'inline-block',
    verticalAlign: 'middle',
    maxWidth: '100%',
    height: 'auto',
  },
  logo: {
    width: '267px',
    height: '120px',
    position: 'absolute',
    left: '10px',
    '@media (max-width: 760px)': {
      width: '131px',
      height: '59px',
      top: '50px',
    },
    '@media only screen and (max-width: 999px) and (orientation: landscape)': {
      width: '131px',
      height: '59px',
      top: '50px',
    },
  },
  background: {
    '@media (max-width: 760px)': {
      backgroundImage: `url(${mobileGear})`,
    },
    backgroundImage: `url(${Gear})`,
    height: '100vh',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  },
  forgotText: {
    fontSize: 15,
    fontWeight: 500,
  },
});
