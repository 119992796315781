import { useMemo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { useSelector } from 'react-redux';
import SheetCard from '../SheetItem/SheetCard';
import { getTime } from 'date-fns';
import { getSheetStartTimeDateFns } from 'helpers/_date-helpers';


const sortingByContractorName = (sheets) => {
  return sheets.slice().sort((a, b) => {
    const namesComp = (a.project.contractor.name || '').localeCompare(
      b.project.contractor.name || ''
    );
    if (!namesComp) {
      const aTime = a.sheet.travelTimeOnly
        ? getSheetStartTimeDateFns(a.sheet.createdAt, a.sheet.startTime)
        : getTime(new Date(a.sheet.hours.start));
      const bTime = b.sheet.travelTimeOnly
        ? getSheetStartTimeDateFns(b.sheet.createdAt, b.sheet.startTime)
        : getTime(new Date(b.sheet.hours.start));
      return aTime - bTime;
    }
    return namesComp;
  });
};

const SheetList = ({ classes, openSnackbar, sheetsWithoutForemanIds, sheets }) => {
  const sortedSheets = useMemo(() => sortingByContractorName(sheets), [sheets]);
  return (
    <div className={classes.wrapper}>
      {sortedSheets.map((sheet) => (
        <SheetCard
          key={sheet.sheet._id}
          sheet={sheet.sheet}
          sheets={sheets}
          openSnackbar={openSnackbar}
          sheetsWithoutForemanIds={sheetsWithoutForemanIds}
        />
      ))}
    </div>
  );
};

export default withStyles(styles)(SheetList);
