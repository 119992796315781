import { createReducer } from '@reduxjs/toolkit';
import peoplePageActions from './peoplePageActions';
import hiCodesActions from './hiCodesActions';
import { USERS_PER_PAGE } from './peoplePageConstants';

const initialState = {
  people: {
    users: [],
    settings: null,
    currentUserId: '',
    hasMore: true,
    hiCodes: {
      hiCodes: [],
      snackBarInfo: null,
      hiCodesLoading: true
    }
  },
};

export const sortHICodes = (hiCodes) => hiCodes.map(code => ({ ...code })).sort((a, b) => `${a?.name}`.localeCompare(b?.name, 'false', { sensitivity: 'base' }))

const people = createReducer(initialState.people, {
  [peoplePageActions.getUsersPeoplePageData]: (state, { payload }) => ({
    ...state,
    users: payload,
    hasMore: payload.length === USERS_PER_PAGE,
  }),
  [peoplePageActions.addMorePeoplePageData]: (state, { payload }) => {
    const usersToAdd = payload.filter((user) => !~state.users.findIndex((u) => u._id === user._id));
    return {
      ...state,
      users: [...state.users, ...usersToAdd],
      hasMore: !usersToAdd?.length ? false : payload.length === USERS_PER_PAGE,
    };
  },
  [peoplePageActions.postUserPeoplePage]: (state, { payload }) => ({
    ...state,
    users: [...state.users, payload],
  }),
  [peoplePageActions.patchUserPeoplePage]: (state, { payload }) => {
    const updated = state.users.slice();
    const atIndex = updated.findIndex((user) => user._id === payload._id);
    if (~atIndex) {
      updated[atIndex] = payload;
    }
    return {
      ...state,
      users: updated,
    };
  },
  [peoplePageActions.deleteUserPeoplePage]: (state, { payload: userId }) => ({
    ...state,
    users: state.users.filter((user) => user._id !== userId),
  }),
  [peoplePageActions.getSettingsPeoplePage]: (state, { payload }) => ({
    ...state,
    settings: payload,
  }),
  [peoplePageActions.getCurrentUserPeoplePage]: (state, { payload: userId }) => ({
    ...state,
    currentUserId: userId,
  }),
  // HI Codes
  [hiCodesActions.getHICodes]: (state, { payload }) => ({
    ...state,
    hiCodes: {
      ...state.hiCodes,
      hiCodes: sortHICodes(payload)
    }
  }),
  [hiCodesActions.postHICode]: (state, { payload }) => ({
    ...state,
    hiCodes: {
      ...state.hiCodes,
      hiCodes: sortHICodes(
        [...state.hiCodes.hiCodes, payload]
      )
    }
  }),
  [hiCodesActions.putHICode]: (state, { payload: updatedHICode }) => ({
    ...state,
    hiCodes: {
      ...state.hiCodes,
      hiCodes: sortHICodes(
        state.hiCodes.hiCodes.map(hiCode => hiCode._id === updatedHICode._id ? ({ ...updatedHICode }) : ({ ...hiCode }))
      )
    }
  }),
  [hiCodesActions.deleteHICode]: (state, { payload: deletedHiCode }) => ({
    ...state,
    hiCodes: {
      ...state.hiCodes,
      hiCodes: sortHICodes(state.hiCodes.hiCodes.filter(hiCode => hiCode._id !== deletedHiCode._id))
    }
  }),
  [hiCodesActions.snackBarInfo]: (state, { payload }) => ({
    ...state,
    hiCodes: {
      ...state.hiCodes,
      snackBarInfo: payload,
    }
  }),
  [hiCodesActions.setLoading]: (state, { payload }) => ({
    ...state,
    hiCodes: {
      ...state.hiCodes,
      hiCodesLoading: payload,
    }
  })
});

export default people;
