import React, { useState } from 'react';
import propTypes from 'prop-types';
import { info } from './info';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
import { userTypes } from '../../helpers/userTypes';

const useStyles = makeStyles(styles);

const UserTypeInfo = ({ type }) => {
  const [shown, setShown] = useState(false);
  const classes = useStyles();

  if (!info[type]) return null;
  return (
    <div className={classes.container}>
      <span className={classes.infoIcon} onMouseEnter={() => setShown(true)} onMouseLeave={() => setShown(false)}>
        i
      </span>
      {shown && (
        <div className={classes.hint}>
          {type !== userTypes.admin && <strong>Core Functionality:&nbsp;</strong>}
          <span>{info[type]}</span>
        </div>
      )}
    </div>
  );
};

UserTypeInfo.propTypes = {
  type: propTypes.string.isRequired,
};

export default UserTypeInfo;
