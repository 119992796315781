import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import { isIOS, Option } from 'components/Option';
import { getContactName } from 'helpers/_helpers';
import { useState } from 'react';
import ContactCreator from 'components/WorklogDetails/components/GeneralInfoBlock/components/GeneralInfoForm/components/ContactCreator/ContactCreator';

export const AlternateContacts = ({
  CloseIcon,
  classes,
  values,
  setFieldValue,
  contractorAlternateContacts,
  setContractorAlternateContacts,
}) => {
  const [contactCreateForm, setContactCreateForm] = useState(false);

  const handleSelectContact = (i) => (e) => {
    const { value } = e.target;

    if (value === 'add') {
      handleContactCreateForm(i);
      return;
    }

    const nextValue = values.alternateContacts.map((t, index) => (index === i ? value : t));
    setFieldValue('alternateContacts', nextValue);
  };

  const handleContactCreateForm = (value) => {
    if (value === false) {
      setContactCreateForm(value);
      return;
    }
    setContactCreateForm(value.toString());
  };
  const handleEmailRemoving = (i) => () => {
    const nextValue = values.alternateContacts.filter((_, index) => index !== i);
    setFieldValue('alternateContacts', nextValue.length ? nextValue : ['']);
  };

  const handleAddContact = () => {
    if (contractorAlternateContacts.length > values.alternateContacts.length) {
      setFieldValue('alternateContacts', [...values.alternateContacts, '']);
    }
  };

  return (
    <>
      {!!values.alternateContacts?.length &&
        values.alternateContacts.map((contactId, index) => (
          <Grid item xs={12} className={classes.zeroPaddingTop}>
            <label htmlFor="alternateContacts">
              <Typography variant="body1" color="textSecondary">
                {index === 0 ? 'Primary Contact' : `Alternate Contact ${index}`}:
              </Typography>
              <div className={classes.flexBox}>
                <TextField
                  select
                  id="alternateContacts"
                  className={classes.textField}
                  variant="outlined"
                  value={contactId}
                  onChange={handleSelectContact(index)}
                  inputProps={{ 'aria-label': 'Time & Material' }}
                  SelectProps={{
                    native: isIOS,
                  }}
                  fullWidth
                >
                  <Option key={0} value=""></Option>
                  {contractorAlternateContacts
                    .filter(
                      (contact) =>
                        !values.alternateContacts.find((cont) => contact._id === cont) ||
                        contact._id === contactId
                    )
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((contact) => (
                      <Option key={`${contact._id}_${index}`} value={contact._id}>
                        {getContactName(contact)}
                      </Option>
                    ))}
                  <Option key={'add'} value="add" className={classes.primary}>
                    + Create New Contact
                  </Option>
                </TextField>
                <IconButton
                  className={classes.deleteLocationButton}
                  onClick={handleEmailRemoving(index)}
                  aria-label="Delete"
                  disableTouchRipple
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </label>
          </Grid>
        ))}
      {values.contractorId &&
        !!contractorAlternateContacts?.length &&
        contractorAlternateContacts?.length > values.alternateContacts?.length && (
          <Grid item xs={12} className={classes.zeroPaddingTop}>
            <div className={classes.primaryAdd} onClick={handleAddContact}>
              <span>+ Add Alternate Contact</span>
            </div>
          </Grid>
        )}
      <Grid item xs={12} className={classes.zeroPadding}>
        {contactCreateForm ? (
          <ContactCreator
            values={values}
            setCurrContractorContacts={setContractorAlternateContacts}
            setValue={setFieldValue}
            setContactCreatorForm={setContactCreateForm}
            contactIndex={contactCreateForm}
          />
        ) : null}
      </Grid>
    </>
  );
};
