import { createAction } from '@reduxjs/toolkit';

const setActiveTab = createAction('cip/setActiveTab');
const setLoading = createAction('cip/setLoading');
const setHours = createAction('cip/setHours');
const setProjects = createAction('cip/setProjects');
const setAddingProject = createAction('cip/setAddingProject');
const setEditingProject = createAction('cip/setEditingProject');
const reset = createAction('cip/reset');

const setCipProjects = createAction('cip/setCipProjects');
const deleteCipProjects = createAction('cip/deleteCipProjects');
const toggleActivateCipProjects = createAction('cip/toggleActivateCipProjects');
const addCipProject = createAction('cip/addCipProject');
const setDisabled = createAction('cip/setDisabled');

export default {
  setActiveTab,
  setLoading,
  setHours,
  setProjects,
  setAddingProject,
  setEditingProject,
  reset,

  setCipProjects,
  deleteCipProjects,
  toggleActivateCipProjects,
  addCipProject,
  setDisabled,
};
