import React, { useState, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import LoadingSpinner from 'components/LoadingSpinner';
import WorklogsPage from './WorklogsPage';
import SnackBar from 'components/SnackBar';
import {
  EQUIPMENT_EVENT,
  WORKER_EVENT,
  SHEET_EVENT,
  CONTRACTORS_EVENT,
  PROJECTS_EVENT,
  SETTINGS_EVENT
} from 'store/schedule/subscriptions';
import schedulePageActions from 'store/schedule/schedulePageActions';
import { useDispatch } from 'react-redux';
import personalProfileActions from 'store/personalProfile/personalProfileActions';

const WorklogsWrapper = () => {
  const location = useLocation()
  const dispatch = useDispatch()

  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    text: '',
    type: 'success',
  })
  const loading = false

  useEffect(() => {
    dispatch(schedulePageActions.socketConnect(SHEET_EVENT));
    // dispatch(schedulePageActions.socketConnect(CALENDAR_EVENTS_EVENT));
    dispatch(schedulePageActions.socketConnect(EQUIPMENT_EVENT));
    dispatch(schedulePageActions.socketConnect(WORKER_EVENT));
    // dispatch(schedulePageActions.socketConnect(HOLIDAYS_EVENT));
    dispatch(schedulePageActions.socketConnect(CONTRACTORS_EVENT));
    dispatch(schedulePageActions.socketConnect(PROJECTS_EVENT));
    dispatch(personalProfileActions.socketConnect(SETTINGS_EVENT));
    return () => {
      dispatch(schedulePageActions.socketDisconnect(SHEET_EVENT));
      // dispatch(schedulePageActions.socketDisconnect(CALENDAR_EVENTS_EVENT));
      dispatch(schedulePageActions.socketDisconnect(EQUIPMENT_EVENT));
      dispatch(schedulePageActions.socketDisconnect(WORKER_EVENT));
      // dispatch(schedulePageActions.socketDisconnect(HOLIDAYS_EVENT));
      dispatch(schedulePageActions.socketDisconnect(CONTRACTORS_EVENT));
      dispatch(schedulePageActions.socketDisconnect(PROJECTS_EVENT));
      dispatch(personalProfileActions.socketDisconnect(SETTINGS_EVENT));
    }
  }, []);

  const openSnackbar = useCallback((type, text) => setSnackbar({ isOpen: true, type, text }), []);

  const closeSnackbar =  useCallback(() => setSnackbar((p)=>({ ...p, isOpen: false })), []);

  const pageType = location.pathname || ''

    return (
      <div>
        {loading ? (
          <div
            style={{
              display: 'flex',
              alignItem: 'center',
              justifyContent: 'center',
              marginTop: '100px',
            }}
          >
            <LoadingSpinner height={40} margin={32} />
          </div>
        ) : (
          <>
              <WorklogsPage openSnackbar={openSnackbar} pageType={pageType}/>
              <SnackBar closeSnackbar={closeSnackbar} {...snackbar} />
          </>
        )}
      </div>
    );
  
}

export default WorklogsWrapper;