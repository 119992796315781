import { Button } from '@material-ui/core';
import React, { memo } from 'react';
import FilterI from 'assets/images/filter-icon.svg'
import useStyles from './useStyles';

export const FilterButton = memo(({ openDrawer }) => {
  const classes = useStyles();

  return (
    <div className={classes.relativePosition}>
      <Button
        variant="text"
        className={classes.filterBtn}
        onClick={openDrawer}
        disableTouchRipple
      >
        <img src={FilterI} />
      </Button>
    </div>
  );
});
