export default (theme) => ({
  textField: {
    [theme.breakpoints.up('lg')]: {
      // maxWidth: 250,
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      color: theme.palette.textColor.secondary,
      transform: 'translate(14px, 9px) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      display: 'none',
    },
  },
  error: {
    color: theme.palette.textColor.red,
    padding: '0 5px',
    fontSize: 11,
    marginTop: 8,
  },
  requiredSign: {
    color: theme.palette.textColor.red,
  },
  zeroPaddingTop: {
    paddingTop: '0px !important',
  },
  fullWidth: {
    width: '100%',
  },
  photoWrapper: {
    borderRadius: '50%',
    border: `2px solid ${theme.palette.textColor.blue}`,
    borderTopColor: 'transparent',
    borderBottomColor: 'transparent',
    width: 52,
    height: 52,
    transform: 'rotate(135deg)',
    minWidth: 52,
    marginRight: 15,
  },
  photo: {
    display: 'block',
    transform: 'rotate(-135deg)',
    height: 44,
    maxWidth: 44,
    margin: '2px auto 0',
    borderRadius: '50%',
  },
  flexBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  primary: {
    color: theme.palette.textColor.primary,
  },
  primaryButton: {
    padding: '0 4px',
    margin: '4px 0',
    fontSize: 13,
    fontWeight: 500,
    color: theme.palette.textColor.primary,
    '&:hover': {
      color: theme.palette.textColor.primary,
      backgroundColor: '#fff !important',
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
      backgroundColor: '#fff',
    },
  },
  secondaryButton: {
    color: theme.palette.textColor.secondary,
    '&:hover': {
      color: theme.palette.textColor.secondary,
      backgroundColor: '#fff !important',
    },
    '&:focus': {
      color: theme.palette.textColor.secondary,
      backgroundColor: '#fff',
    },
  },
  deleteButton: {
    color: theme.palette.textColor.red,
    marginTop: 0,
    cursor: 'pointer',
  },
  primaryTextButton: {
    padding: 0,
    marginRight: 5,
    color: theme.palette.textColor.primary,
    '& span': {
      '&:first-letter': {
        fontSize: 20,
      },
    },
    '&:hover': {
      color: theme.palette.textColor.primary,
      background: `${theme.palette.background.default} !important`,
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
      background: `${theme.palette.background.default} !important`,
    },
  },
  searchIcon: {
    color: theme.palette.textColor.primary,
    cursor: 'pointer',
    scale: '0.8',
  },

  customSelect: {
    '& .MuiSvgIcon-root': {
      display: 'none',
    },
  },
});
