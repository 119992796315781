import { useCallback } from 'react';
import { getOptions } from 'helpers/getOptions';
import { useDispatch } from 'react-redux';
import $api from 'http/index';
import store from 'store/store';
import schedulePageActions from 'store/schedule/schedulePageActions';

export const useGridSetPosition = () => {
  const dispatch = useDispatch();
  const gridSetPosition = useCallback(async ({ sheetId, gridPosition, conflictAction }) => {
    const state = store.getState().schedule;
    try {
      const data = {
        sheetId, gridPosition, conflictAction: conflictAction ? 'swap' : null
      }
      await $api.post(
        `${process.env.REACT_APP_BASE_URL}/sheets/schedule/grid-position`,
        data,
        getOptions()
      );

      if (data.conflictAction === 'swap') {
        const sourceSheet = state?.sheets?.find((sheet) => sheet.sheet?._id === sheetId);
        const destinationSheet = state?.sheets?.find((sheet) => sheet.grid?.position == gridPosition);
        if (sourceSheet && destinationSheet) {
          dispatch(schedulePageActions.swapGridsPosition({
            swappedGrids: [
            { _id: sourceSheet.sheet._id, position: destinationSheet.grid.position },
            { _id: destinationSheet.sheet._id, position: sourceSheet.grid.position },
          ]}))
        }
      } else {
        dispatch(schedulePageActions.setGridPosition({ id: sheetId, position: gridPosition }));
      }
    } catch (e) {
      console.error(e.message);
    }
  }, []);

  return { gridSetPosition };
};
