export default (theme) => ({
  cellContent: {
    width: 177,
    height: 193,
    zIndex: 0,
  },
  dayNumber: {
    fontSize: 30,
    lineHeight: 1.5,
    color: theme.palette.textColor.secondary,
    padding: '0 5px',
  },
  todayNumber: {
    color: theme.palette.textColor.primary,
  },
  absolute: {
    position: 'absolute',
    height: 'auto',
    border: '1px solid #dadbe3',
    borderTop: 'none',
    width: 'calc(100% + 2px)',
    left: '-1px',
    top: 0,
    backgroundColor: theme.palette.background.default,
    zIndex: 20,
    padding: '0 1px 0 0',
  },
  padding: {
    padding: '0 5px',
  },
  flexBox: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 0 10px',
    justifyContent: 'space-between',
  },
  flexBoxInline: {
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  defaultCursor: {
    cursor: 'auto',
  },
  userPhotoWrapper: {
    borderRadius: '50%',
    width: 21,
    height: 21,
    marginRight: 10,
  },
  dayBorder: {
    border: `1px solid ${theme.palette.textColor.yellow}`,
  },
  nightBorder: {
    border: `1px solid ${theme.palette.textColor.darkBlue}`,
  },
  inactiveBorder: {
    border: `1px solid ${theme.palette.textColor.secondary}`,
  },
  userPhoto: {
    display: 'block',
    height: 19,
  },
  name: {
    fontSize: 14,
    fontWeight: 500,
    maxWidth: 55,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  day: {
    color: theme.palette.textColor.yellow,
  },
  night: {
    color: theme.palette.textColor.darkBlue,
  },
  inactive: {
    color: theme.palette.textColor.secondary,
  },
  startTime: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.textColor.blue,
    border: `1px solid ${theme.palette.borderColor.blue}`,
    borderRadius: '10px',
    marginLeft: 5,
    width: 56,
    padding: '1px 0 2px',
    textAlign: 'center',
  },
  dayOff: {
    color: theme.palette.textColor.white,
    backgroundColor: theme.palette.background.greenBright,
    border: `1px solid ${theme.palette.background.greenBright}`,
  },
  actionBtn: {
    textTransform: 'none',
    padding: 0,
    minWidth: 15,
    minHeight: 15,
    marginLeft: 5,
    '&:hover': {
      color: 'inherit',
      backgroundColor: 'inherit !important',
    },
    '&:focus': {
      color: 'inherit',
      backgroundColor: 'inherit !important',
    },
    '& img': {
      height: 12,
    },
  },
  list: {
    maxHeight: 118,
    overflow: 'hidden',
    padding: '0 5px',
  },
  othersBtn: {
    padding: 0,
    margin: '0 5px',
    minWidth: 'auto',
    textTransform: 'none',
    color: theme.palette.textColor.primary,
    fontSize: 14,
    fontWeight: 500,
    '&:hover': {
      color: theme.palette.textColor.primary,
      backgroundColor: 'inherit !important',
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
      backgroundColor: 'inherit !important',
    },
  },
  visible: {
    maxHeight: 'none !important',
  },
  borderBottom: {
    paddingBottom: 10,
  },
  fullWidth: {
    maxWidth: 136,
  },
  width1: {
    maxWidth: 75,
  },
  width2: {
    maxWidth: 115,
  },
  greyBackground: {
    backgroundColor: theme.palette.background.lightGrey,
  },
  greenBackground: {
    backgroundColor: theme.palette.background.lightGreen,
  },
  holidayName: {
    color: theme.palette.textColor.green,
    fontSize: 14,
    fontWeight: 500,
    textTransform: 'capitalize',
    padding: '0 5px 8px',
    wordBreak: 'break-word',
    overflow: 'hidden',
    maxHeight: 30,
  },
  maxHeightHoliday: {
    maxHeight: 86,
  },
  emptyCellHolidayName: {
    textAlign: 'center',
    marginTop: 40,
  },
});
