/**
 *
 * @param {number} maxValue
 * @param {number} step
 * @param {number} start
 * @param {number} padMaxLength
 * @returns {string[]}
 */
export const generateIncrement = ({ maxValue, step, start = 0, padMaxLength = 1 } = {}) => {
  return Array(maxValue / step)
    .fill(0)
    .map((v, i) => String(i * step + start).padStart(padMaxLength, '0'));
};
