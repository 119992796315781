export const styles = (theme) => ({
  email: {
    '&:hover, &:focus, &:active': {
      backgroundColor: 'transparent',
      color: theme.palette.textColor.secondary,
    },
    '& svg': {
      fill: theme.palette.textColor.primary,
    },
    '&.MuiButton-text': {
      padding: '0 8px 10px 8px',
    },
  },
  tabs: {
    overflow: 'visible!important',
    '& > div': {
      overflow: 'visible!important',
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: '1.4rem',
    },
  },
  ptoDetails: {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 10,
    },
  },
  ptoDetailsItem: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 15,
    padding: 5,
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      marginRight: 15,
    },
  },
  calendar: {
    height: 35,
    marginLeft: 'auto',
    marginRight: 0,
    display: 'flex',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  tabsWrapper: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
    marginBottom: '4.4rem',
    borderBottom: '1px solid #e8e8e8',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
      borderBottom: 'none',
      width: '650px',
      margin: '0 auto 15px',
    },
    '@media (max-width: 680px)': {
      width: '500px',
    },
    '@media (max-width: 530px)': {
      width: 'auto',
      margin: '0',
    },
  },
});
