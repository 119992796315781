import { useCallback } from 'react';
import { useCheckCanAssignWorklog } from './useCheckCanAssignWorklog';
import { useAddResource } from './useAddResource';
import { updateSheets } from 'store/schedule/schedulePageOperation';
import { useDispatch, useSelector } from 'react-redux';
import modalsPageActions from 'store/modals/modalsPageActions';
import { CALENDAR_VIEW_TYPES } from 'pages/Schedule/components/ScheduleHeader/components/DateTypeSelector/constants';

export const useHandleDrop = ({
  allSheetsThisDay,
  sheetsInView,
  sheet,
}) => {
  const dispatch = useDispatch();
  const { calendarTypeView } = useSelector(store => store.schedule);

  const openModal = useCallback((...args) => dispatch(modalsPageActions.openModal(...args)), [dispatch]);

  const { checkCanAssignWorklog } = useCheckCanAssignWorklog();
  const { addResource } = useAddResource();

  const handleDrop = useCallback(
    async (e) => {
      const { canAssign, data } = await checkCanAssignWorklog({
        e,
        start: sheet.hours.start,
        end: sheet.hours.end,
        sheet: sheet,
        sheetsInView,
        openModal,
        allSheetsThisDay,
      });

      if (!canAssign) return;

      const fullListResource = data.resourceType === 'workers' ? sheet.workers : sheet.equipment
      const resource = { _id: data.resource._id };

      await addResource({
        sheetId: sheet._id,
        resources: { [data.resourceType]: [resource,...fullListResource] },
      })
        .then((res) => {
          if (calendarTypeView != CALENDAR_VIEW_TYPES.monthly) dispatch(updateSheets());
          if (res.error !== 'sheet-time-overlapse') return;
          openModal({ name: 'conflict', data: {
            to: sheet._id,
            from: res.details[0].overlappedSheets.find(
              (s) => s._id !== sheet._id
            ),
            addData: data,
            removeData: {
              resourceId: data.resource._id,
              resourceType: data.resourceType,
            },
          }});
        })
        .catch((err) => {
          openModal({ name: 'info', data: err?.response?.data?.message || err.message });
        });
    },
    [
      allSheetsThisDay,
      sheetsInView,
      sheet,
      openModal,
      addResource,
      checkCanAssignWorklog,
    ]
  );

  return { handleDrop };
};
