import React, { useEffect, useMemo, useRef, useState } from 'react';
import { addDays, format, startOfWeek } from 'date-fns';
import cn from 'classnames';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';

import { ArrowIcon } from 'components/common';

import styles from 'components/DayPicker/styles';
import 'components/DayPicker/styles.css';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { useWeekStart } from 'hooks/useWeekStart';
import { GLOBAL_DATE_FORMAT, momentAdepter } from 'common/dateFormatConfig';

const useStyles = makeStyles(styles);

const DayPicker = ({ selectedDate, setSelectedDate }) => {
  const ref = useRef();
  const classes = useStyles();
  const { weekDayFromO } = useWeekStart();

  const [open, setOpen] = useState(false);

  const changeDay = (diff) => () => {
    setSelectedDate((prev) => addDays(new Date(prev), diff));
    setOpen(false);
  };

  useEffect(() => {
    open ? ref.current.showDayPicker() : ref.current.hideDayPicker();
  }, [open]);

  const toggle = () => setOpen((prev) => !prev);
  const close = () => setOpen(false);
  const displayedDate = useMemo(
    () => format(new Date(selectedDate), GLOBAL_DATE_FORMAT.fullDateWithDay),
    [selectedDate]
  );

  return (
    <>
      <ArrowIcon
        style={{ margin: '3px 5px 0 0' }}
        direction="left"
        disabled={
          format(selectedDate, 'P') ===
          format(startOfWeek(new Date(), { weekStartsOn: weekDayFromO }), 'P')
        }
        onClick={changeDay(-1)}
      />
      <ArrowIcon
        style={{ margin: '3px 0 0 0' }}
        direction="right"
        disabled={format(selectedDate, 'P') === format(new Date(), 'P')}
        onClick={changeDay(1)}
      />
      <ClickAwayListener onClickAway={close}>
        <div className="dayPickerWrapper endDatePicker fullWidth">
          <div className="selectedDayName" onClick={toggle}>
            {displayedDate} <ArrowDownIcon className={cn(classes.arrowButton, 'downArrow')} />
          </div>
          <DayPickerInput
            ref={ref}
            formatDate={formatDate}
            parseDate={parseDate}
            format={momentAdepter(GLOBAL_DATE_FORMAT.fullDateWithDay).momentShortDayDate} //* @ "ddd, MMMM DD, YYYY" //(was moment format)Tue, March 19, 2024 =>
            value={selectedDate}
            onDayChange={setSelectedDate}
            dayPickerProps={{
              firstDayOfWeek: weekDayFromO,
              modifiers: {
                disabled: {
                  after: new Date(),
                  before: startOfWeek(new Date(), { weekStartsOn: weekDayFromO }),
                },
              },
            }}
          />
        </div>
      </ClickAwayListener>
    </>
  );
};

export default DayPicker;
