import React from 'react';
import propTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconSunny from 'assets/images/icon-sunny.svg';
import IconMoon from 'assets/images/icon-moon.svg';
import PageLoader from 'components/PageLoader';
import styles from './styles';
import { isIOS, Option } from 'components/Option';

const validation = (values) => {
  const errors = {};
  if (values.userTypes.length === 0) {
    errors.userTypes = "This field can't be blank";
  }
  return errors;
};

const SortingAndFilteringPanel = ({ classes, closeDrawer, filters, updateFilter, isLoading }) => {
  const [userTypes, setUserTypes] = React.useState(filters.userTypes);

  const defaultValues = {
    sortBy: 'username',
    sortOrder: 'asc',
    timeOfDay: 'All',
    weekDays: 'All',
    userTypes: [
      'Admin',
      'Field Technician',
      'Dispatcher',
      'Accounting',
      'Fleet Maintenance',
      'Project Management',
      'Machine Shop',
    ],
  };

  const handleChangeMultipleSelect = (setFieldValue) => (e) => {
    const clickedOption = e.currentTarget.dataset.value;
    const newValue = e.target.value;
    if (clickedOption === 'All') {
      if (newValue.length === 8) {
        setUserTypes([]);
        setFieldValue('userTypes', []);
      } else {
        setUserTypes(defaultValues.userTypes);
        setFieldValue('userTypes', defaultValues.userTypes);
      }
    } else {
      setUserTypes(newValue);
      setFieldValue('userTypes', newValue);
    }
  };

  const sortingTypeLabel = (sortBy) => {
    switch (sortBy) {
      case 'lastLogin':
        return [{ label: 'Most recently', value: 'desc' }, { label: 'Long ago', value: 'asc' }];
      case 'employeeNum':
        return [{ label: '0 \u2013 9', value: 'asc' }, { label: '9 \u2013 0', value: 'desc' }];
      default:
        return [{ label: 'A \u2013 Z', value: 'asc' }, { label: 'Z \u2013 A', value: 'desc' }];
    }
  };

  return (
    <div className={classes.formWrapper} role="presentation">
      <Formik
        validate={validation}
        validateOnChange={false}
        validateOnBlur={true}
        initialValues={filters}
        enableReinitialize={true}
        onSubmit={(values, { isSubmitting }) => {
          if (isSubmitting) return;
          updateFilter(values);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, resetForm, setFieldValue }) => (
          <PageLoader loading={isLoading}>
            <Form autoComplete="off" className={classes.formFlex}>
              <div>
                <Typography variant="h3" className={classes.marginHeader}>
                  Filters
                </Typography>

                <IconButton
                  className={classes.closeButton}
                  onClick={closeDrawer}
                  aria-label="Close"
                  disableTouchRipple
                >
                  <CloseIcon />
                </IconButton>

                <Grid container spacing={2} className={classes.flexContainer}>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="sortBy" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Sort by:
                      </Typography>
                      <TextField
                        select
                        id="sortBy"
                        name="sortBy"
                        value={values.sortBy}
                        onChange={handleChange}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Sort by' }}
                        fullWidth
                        SelectProps={{
                          native: isIOS,
                        }}
                      >
                        <Option key={2} value="email">
                          Email
                        </Option>
                        <Option key={3} value="employeeNum">
                          Employee #
                        </Option>
                        <Option key={4} value="lastLogin">
                          Last login
                        </Option>
                        <Option key={1} value="username">
                          Username
                        </Option>
                      </TextField>
                    </label>
                  </Grid>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="sortOrder" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Sort from:
                      </Typography>
                      <TextField
                        select
                        id="sortOrder"
                        name="sortOrder"
                        value={values.sortOrder}
                        onChange={handleChange}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Days' }}
                        SelectProps={{
                          native: isIOS,
                        }}
                        fullWidth
                      >
                        {sortingTypeLabel(values.sortBy).map(option => (
                          <Option key={`${option.label}_${option.value}`} value={option.value}>
                            {option.label}
                          </Option>
                        ))}
                      </TextField>
                    </label>
                  </Grid>
                </Grid>
                <Divider className={classes.marginVertical} />
                <Grid container spacing={2} className={classes.flexContainer}>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="timeOfDay" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Shift:
                      </Typography>
                      <TextField
                        select
                        id="timeOfDay"
                        name="timeOfDay"
                        value={values.timeOfDay}
                        onChange={handleChange}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Shift' }}
                        SelectProps={{
                          native: isIOS,
                        }}
                        fullWidth
                      >
                        <Option key={1} value="All">
                          All
                        </Option>
                        <Option key={2} value="Day">
                          {isIOS ? (
                            '🌤 '
                          ) : (
                            <img src={IconSunny} alt="sunny" className={classes.shiftImage} />
                          )}
                          Day
                        </Option>
                        <Option key={3} value="Night">
                          {isIOS ? (
                            '🌙 '
                          ) : (
                            <img src={IconMoon} alt="moon" className={classes.shiftImage} />
                          )}
                          Night
                        </Option>
                      </TextField>
                    </label>
                  </Grid>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="weekDays" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Days:
                      </Typography>
                      <TextField
                        select
                        id="weekDays"
                        name="weekDays"
                        value={values.weekDays}
                        onChange={handleChange}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Days' }}
                        SelectProps={{
                          native: isIOS,
                        }}
                        fullWidth
                      >
                        <Option key={1} value="All">
                          All
                        </Option>
                        <Option key={2} value="Mon-Fri">
                          Mon &mdash; Fri
                        </Option>
                        <Option key={3} value="Sun-Thu">
                          Sun &mdash; Thu
                        </Option>
                        <Option key={4} value="Tue-Sat">
                          Tue &mdash; Sat
                        </Option>
                      </TextField>
                    </label>
                  </Grid>
                </Grid>
                <Divider className={classes.marginVertical} />
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="userTypes" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        User types<span className={classes.requiredSign}>*</span>:
                      </Typography>
                      <TextField
                        select
                        id="userTypes"
                        name="userTypes"
                        value={userTypes}
                        onChange={handleChangeMultipleSelect(setFieldValue)}
                        onBlur={handleBlur}
                        error={Boolean(errors.userTypes) && Boolean(touched.userTypes)}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'User type' }}
                        SelectProps={{
                          multiple: true,
                          renderValue: (selected) =>
                            selected.length === 7 ? 'All' : selected.join(', '),
                          native: isIOS,
                        }}
                        fullWidth
                      >
                        <Option
                          key={1}
                          value="All"
                          className={userTypes.length === 7 ? classes.customSelectedStyle : ''}
                          disableTouchRipple
                        >
                          All
                        </Option>
                        <Option key={5} value="Accounting" disableTouchRipple>
                          Accounting
                        </Option>
                        <Option key={2} value="Admin" disableTouchRipple>
                          Admin
                        </Option>
                        <Option key={4} value="Dispatcher" disableTouchRipple>
                          Dispatcher
                        </Option>
                        <Option key={3} value="Field Technician" disableTouchRipple>
                          Field Technician
                        </Option>
                        <Option key={6} value="Fleet Maintenance" disableTouchRipple>
                          Fleet Maintenance
                        </Option>
                        <Option key={8} value="Machine Shop" disableTouchRipple>
                          Machine Shop
                        </Option>
                        <Option key={7} value="Project Management" disableTouchRipple>
                          Project Management
                        </Option>
                      </TextField>
                    </label>
                    {errors.userTypes && touched.userTypes ? (
                      <div className={classes.error}>{errors.userTypes}</div>
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      color="primary"
                      className={classes.resetButton}
                      onClick={() => {
                        resetForm({ values:defaultValues});
                        // resetForm(defaultValues);
                        
                        setUserTypes(defaultValues.userTypes);
                      }}
                      disableTouchRipple
                    >
                      Reset all filters
                    </Button>
                  </Grid>
                </Grid>
              </div>

              <div className={classes.buttonHolder}>
                <Button color="secondary" className={classes.cancelButton} onClick={closeDrawer}>
                  Cancel
                </Button>

                <div className={classes.verticalDivider}></div>

                <Button type="submit" color="primary" className={classes.saveButton}>
                  Apply
                </Button>
              </div>
            </Form>
          </PageLoader>
        )}
      </Formik>
    </div>
  );
};

SortingAndFilteringPanel.propTypes = {
  classes: propTypes.object.isRequired,
  closeDrawer: propTypes.func.isRequired,
  filters: propTypes.object.isRequired,
  updateFilter: propTypes.func.isRequired,
  isLoading: propTypes.bool.isRequired,
};

export default withStyles(styles)(SortingAndFilteringPanel);
