export default (theme) => ({
  brief: {
    marginBottom: 92 - 35,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
      marginTop: 40
    },
  },
  filters: {
    marginBottom: 15,
    display: 'none',
  },
  dayReport: {
    marginBottom: 76,
  },
  timeclocks: {
    paddingTop: 35,
  },
});
