import React, { useMemo } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { cssColor, pluralizeSymbol } from 'helpers/_helpers';
import { materialCalculations } from 'helpers/material-calculations';
import styles from './styles';
import { MEASUREMENT_UNITS, MEASURE_UNITS_BY_SYSTEM } from 'common/measurements';

const round = (number, digits = 2) => Math.round(number * (10 ** digits)) / (10 ** digits);

// TODO: add svg import

const TYPE_OF_WORK = {
  'contract': 'contract',
  'forceAccount': 'Time & Material',
};

const MaterialItem = ({
  classes,
  material,
  handleDeleteMaterial,
  duplicateMaterial,
  openAdditionalDrawer,
  hasAccess,
  measurement,
}) => {
  const { unitType, calculatedQuantity } = useMemo(() => {
    const { materialType } = material;

    let unitType = '';
    if (materialType === 'volume') unitType = MEASURE_UNITS_BY_SYSTEM[measurement][MEASUREMENT_UNITS.volume];
    if (materialType === 'weight') unitType = MEASURE_UNITS_BY_SYSTEM[measurement][MEASUREMENT_UNITS.weight];

    let calculatedQuantity = materialCalculations(material);

    return {
      unitType,
      calculatedQuantity,
    };
  }, [material]);

  const renderMaterialHeader = () => {
    const { materialSpecifications } = material || {};

    const color = materialSpecifications && materialSpecifications.colorOrNumber;
    const cssColorBlock = cssColor(color) ? (
      <span className={classes.colorSwatch} style={{ background: cssColor(color) }} />
    ) : null;

    return (
      <>
        {cssColorBlock}
        {`${material.materialName || ''} - ${TYPE_OF_WORK[material.typeOfWork] || ''}`}
        {color && !cssColor(color) ? ` - ${color}` : null}
      </>
    );
  };

  const renderMaterial = () => {
    const { materialSpecifications, materialType } = material;
    if (materialType === 'each') {
      return (
        <>
          {`${material.quantity} ${MEASURE_UNITS_BY_SYSTEM[measurement][materialSpecifications.discreteUnitType] || ''} ${
            materialSpecifications.symbolOrWidth
              ? ' of ' + materialSpecifications.symbolOrWidth
              : ''
          }`}
          {material.description && <div>({material.description})</div>}
        </>
      );
    } else {
      const lengthUnit = MEASURE_UNITS_BY_SYSTEM[measurement][MEASUREMENT_UNITS.length];
      const widthUnit = MEASURE_UNITS_BY_SYSTEM[measurement][MEASUREMENT_UNITS.width].replace(/in/, '"').replace(/cm/, ' cm');

      if (materialSpecifications.line) {
        return (
          <>
            {materialSpecifications.line.isSF !== true ? (
              <div>{`${materialSpecifications.line.width}${widthUnit} ${material.description || ''}`}</div>
            ) : material.description ? (
              <div>({material.description})</div>
            ) : null}
            <div>
              {material.quantity} {materialSpecifications.line.isSF
                ? MEASURE_UNITS_BY_SYSTEM[measurement][MEASUREMENT_UNITS.area]
                : MEASURE_UNITS_BY_SYSTEM[measurement][MEASUREMENT_UNITS.length]}
            </div>
          </>
        );
      } else if (materialSpecifications.legend) {
        return (
          <>
            {material.quantity}{' '}
            {pluralizeSymbol(material.quantity, materialSpecifications.legend.symbol)}
            {material.description && <div>{material.description}</div>}
          </>
        );
      } else if (materialSpecifications.lettering) {

        const { lettering = {} } = materialSpecifications;
        const letteringParamsArr = [];
        if (lettering.height) letteringParamsArr.push(`${lettering.height}${lengthUnit} H`);
        if (lettering.width) letteringParamsArr.push(`${lettering.width}${widthUnit} W`);
        if (lettering.stroke) letteringParamsArr.push(`${lettering.stroke}${widthUnit} S`);
        return (
          <>
            <div>
              {material.quantity} "{material.letteringText}"
            </div>
            <div>{letteringParamsArr.join(' - ')}</div>
            {material.description && <div>{material.description}</div>}
          </>
        );
      }
    }
  };

  const renderUsedMaterial = () => {
    const { materialSpecifications, materialType } = material;

    if (materialType === 'each') {
      return null;
    }

    return (
      <>
        <td className={classes.equals}>=</td>
        <td className={classes.breakWord}>
          {calculatedQuantity && (
            <div>
              {calculatedQuantity.toFixed(3)} {unitType} material
            </div>
          )}
          {(materialSpecifications.glassBeads || []).map((bead) => (
            <div key={Math.floor(+new Date() + Math.random() * 0xffffffff).toString(36)}>
              {round(calculatedQuantity * bead.ratio, 3)} {MEASURE_UNITS_BY_SYSTEM[measurement][bead.unitType]} {bead.name}
            </div>
          ))}
        </td>
      </>
    );
  };

  return (
    <Grid container spacing={2} className={classes.containerWrapper}>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={hasAccess ? 9 : 12}>
        <Typography
          variant="body1"
          color="textPrimary"
          className={classes.header}
          onClick={hasAccess ? openAdditionalDrawer : null}
        >
          {renderMaterialHeader()}
        </Typography>
        <table className={classes.materialTable}>
          <tbody>
            <tr>
              <td className={classes.breakWord}>{renderMaterial()}</td>

              {renderUsedMaterial()}
            </tr>
          </tbody>
        </table>
      </Grid>
      {hasAccess && (
        <Grid item xs={3}>
          <Button
            variant="text"
            className={classes.deleteButton}
            onClick={handleDeleteMaterial}
            disableRipple
          >
            <span className={classes.icon}>
              <svg
                width="14px"
                height="14px"
                viewBox="0 0 14 14"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g
                    id="Guidemark-Admin-Desktop-Worklog-details-add-work-(line)"
                    transform="translate(-912.000000, -537.000000)"
                    fill="#DD6060"
                    stroke="#DD6060"
                    strokeWidth="0.4"
                  >
                    <g
                      id="Sidebar-/-Work-log-details-/-Scrolled"
                      transform="translate(590.000000, 50.000000)"
                    >
                      <g id="Group" transform="translate(322.000000, 484.000000)">
                        <g id="Icons-/-Close-/-Red" transform="translate(0.000000, 3.000000)">
                          <path
                            d="M13.5625,0.4375 C13.8041246,0.679124578 13.8041246,1.07087542 13.5625,1.3125 L7.875,7 L13.5625,12.6875 C13.8041246,12.9291246 13.8041246,13.3208754 13.5625,13.5625 C13.3208754,13.8041246 12.9291246,13.8041246 12.6875,13.5625 L7,7.875 L1.3125,13.5625 C1.07087542,13.8041246 0.679124578,13.8041246 0.4375,13.5625 C0.195875422,13.3208754 0.195875422,12.9291246 0.4375,12.6875 L6.125,7 L0.4375,1.3125 C0.195875422,1.07087542 0.195875422,0.679124578 0.4375,0.4375 C0.679124578,0.195875422 1.07087542,0.195875422 1.3125,0.4375 L7,6.125 L12.6875,0.4375 C12.9291246,0.195875422 13.3208754,0.195875422 13.5625,0.4375 Z"
                            id="ion-ios-close-empty---Ionicons"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </span>
            Delete
          </Button>
          <Button
            variant="text"
            className={classes.primaryButton}
            onClick={duplicateMaterial}
            disableRipple
          >
            <span className={classes.icon}>
              <svg
                width="18px"
                height="18px"
                viewBox="0 0 18 18"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g
                    id="Icons-/-Copy-/-Blue"
                    transform="translate(1.000000, 1.000000)"
                    fill="#3E82FF"
                    fillRule="nonzero"
                    stroke="#3E82FF"
                    strokeWidth="0.4"
                  >
                    <path
                      d="M15.5151515,16 C15.7829149,16 16,15.7829197 16,15.5151564 L16,4.84848485 C16,4.58072149 15.7829149,4.36366308 15.5151515,4.36363636 L11.6363636,4.36363636 L11.6363636,0.484848485 C11.6363369,0.217085129 11.4192785,0 11.1515152,0 L0.484848485,0 C0.217085129,0 0,0.217085129 0,0.484848485 L0,11.15152 C0,11.4192834 0.217085129,11.6363418 0.484848485,11.6363685 L4.36363636,11.6363685 L4.36363636,15.5151564 C4.36366308,15.7829197 4.58072149,16 4.84848485,16 L15.5151515,16 L15.5151515,16 Z M15.030303,15.0303079 L5.33333333,15.0303079 L5.33333333,5.33333333 L15.030303,5.33333333 L15.030303,15.0303079 Z M4.36363636,10.6666715 L0.96969697,10.6666715 L0.96969697,0.96969697 L10.6666667,0.96969697 L10.6666667,4.36363636 L4.84848485,4.36363636 C4.58072149,4.36366308 4.36366308,4.58072149 4.36363636,4.84848485 L4.36363636,10.6666715 L4.36363636,10.6666715 Z"
                      id="Shape"
                    ></path>
                  </g>
                </g>
              </svg>
            </span>
            Duplicate
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

MaterialItem.propTypes = {
  classes: propTypes.object.isRequired,
  material: propTypes.object.isRequired,
  handleDeleteMaterial: propTypes.func.isRequired,
  duplicateMaterial: propTypes.func.isRequired,
  openAdditionalDrawer: propTypes.func.isRequired,
  hasAccess: propTypes.bool.isRequired,
};

export default withStyles(styles)(MaterialItem);
