import {
  eachDayOfInterval,
  isFriday,
  isMonday,
  endOfMonth,
  startOfMonth,
  isSaturday,
  isSunday,
} from 'date-fns';

export default function quantityWorkingHoursInMonth({ type }) {
  const start = startOfMonth(new Date());
  const end = endOfMonth(new Date());

  const arr = eachDayOfInterval({
    start,
    end,
  });

  // type = "monFri" | "sunTue" | "tueSat"
  const isWorkDay = (v, type) => {
    if (type === 'Mon-Fri') {
      return isSaturday(v) || isSunday(v);
    }
    if (type === 'Sun-Tue') {
      return isSaturday(v) || isFriday(v);
    }
    if (type === 'Tue-Sat') {
      return isSunday(v) || isMonday(v);
    }
    return false;
  };

  const workDays = arr.filter((v) => !isWorkDay(v, type)).length * 8;

  return workDays;
}
