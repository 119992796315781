import BaseSelect from '@material-ui/core/Select';
import BaseDialog from '@material-ui/core/Dialog';
import BaseDialogTitle from '@material-ui/core/DialogTitle';
import BaseDialogActions from '@material-ui/core/DialogActions';
import BaseDialogContentText from '@material-ui/core/DialogContentText';
import BaseButton from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';
import { Typography, withStyles } from '@material-ui/core';

export const Dialog = withStyles({
  paper: {
    padding: '20px 26px',
    margin: 0,
  },
})(BaseDialog);

export const DialogTitle = withStyles({
  root: {
    padding: 0,
    color: '#202020',
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: '0.25px',
    lineHeight: '30px',
    margin: '0 46px 22px 0',
  },
})(BaseDialogTitle);

export const DialogActions = withStyles({
  root: {
    padding: 0,
    margin: '0 20px',
  },
  spacing: {
    '& > *': {
      marginLeft: 15,
    },
    '& > *:first-child': {
      marginLeft: 0,
    },
  },
})(BaseDialogActions);

export const Button = withStyles({
  outlined: {
    borderRadius: 4,
    border: '1px solid #5A87EF',
    backgroundColor: '#FFFFFF',
    color: '#5A87EF',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '21px',
    whiteSpace: 'nowrap',
    padding: 0,
    '&:hover, &:active, &:focus': {
      border: '1px solid #5A87EF',
      backgroundColor: '#FFFFFF',
      color: '#5A87EF',
    },
  },
  label: {
    padding: '0 16px',
    margin: '9px 16px',
    width: 'auto',
  },
  outlinedSecondary: {
    color: '#A4A5B3',
    border: '1px solid #D9DAE3',
    '&:hover, &:active, &:focus': {
      backgroundColor: '#FFFFFF',
      color: '#A4A5B3',
      border: '1px solid #D9DAE3',
    },
  },
})(BaseButton);

export const CloseButton = withStyles({
  root: {
    position: 'absolute',
    top: 20,
    right: 20,
    cursor: 'pointer',
  },
})(ClearIcon);

export const Select = withStyles({
  root: {
    border: '1px solid #D9DAE3',
    borderRadius: 4,
    backgroundColor: '#FFFFFF',
    color: '#202020',
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: '0.17px',
    lineHeight: '21px',
    margin: 0,
    padding: '10px 40px 8px 12px',
  },
  '@global': {
    '.MuiInput-underline:before, .MuiInput-underline:after': {
      display: 'none',
    },
  },
})(BaseSelect);

export const Label = withStyles({
  body1: {
    color: '#A4A5B3',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    lineHeight: '19px',
    marginBottom: 6,
  },
})(Typography);

export const DialogContentText = withStyles({
  root: {
    color: '#A4A5B3',
    fontFamily: 'Open Sans',
    fontSize: 14,
    lineHeight: '19px',
    marginBottom: 50,
  },
})(BaseDialogContentText);
