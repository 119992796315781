import $api from 'http/index';
import { getOptions } from 'helpers/getOptions';
import { format, startOfDay, endOfDay } from 'date-fns';
import { downloadFile } from 'helpers/downloadFile';

export const exportWorklogsExcel = async (data) => {
  const searchParams = new URLSearchParams();
  searchParams.append('from', startOfDay(data.period.from).toISOString());
  searchParams.append('to', endOfDay(data.period.to).toISOString());
  
  if (data?.jobNumber?.length) searchParams.append('jobNumber', data.jobNumber.map((v) => v.replace(/,/, '\\,')).join(','));
  if (data?.cl?.length) searchParams.append('cl', data.cl.map((v) => v._id.replace(/,/, '\\,')).join(','));
  if (data?.materialName?.length) searchParams.append('materialName', data.materialName.map((v) => v.replace(/,/, '\\,')).join(','));
  if (data?.materialUnit?.length) searchParams.append('materialUnit', data.materialUnit.map((v) => v.replace(/,/, '\\,')).join(','));
  if (data?.materialColor?.length) searchParams.append('materialColor', data.materialColor.map((v) => v.replace(/,/, '\\,')).join(','));
  if (data?.materialSpecification?.length) searchParams.append('materialSpecification', data.materialSpecification.map((v) => v.replace(/,/, '\\,')).join(','));
  if (data?.lineWidth?.length) searchParams.append('lineWidth', data.lineWidth.join(','));
  if (data?.legendSymbol?.length) searchParams.append('legendSymbol', data.legendSymbol.map((v) => v.replace(/,/, '\\,')).join(','));
  if (data?.letteringText?.length) searchParams.append('letteringText', data.letteringText.map((v) => v.replace(/,/, '\\,')).join(','));
  if (data?.letteringParameters?.length) searchParams.append('letteringParameters', data.letteringParameters.map((v) => v.replace(/,/, '\\,')).join(','));

  const response = await $api.get(
    `${process.env.REACT_APP_BASE_URL}/submissions/materials/excel`,
    {
      params: searchParams,
      ...getOptions('text/xlsx'),
      responseType: 'blob',
    }
  );
  downloadFile(
    response.data,
    `Submissions ${format(data.period.from, 'M-d-yy')}_${format(data.period.to, 'M-d-yy')}.xlsx`
  );
};

export const fetchMaterialExportOptions = async (values) => {
  try {
    const searchParams = new URLSearchParams();

    if (values?.period?.from && values?.period?.to) {
      searchParams.append('from', startOfDay(values.period.from).toISOString());
      searchParams.append('to', endOfDay(values.period.to).toISOString());
    }
    if (values?.jobNumber?.length) searchParams.append('jobNumber', values.jobNumber.map((v) => v.replace(/,/, '\\,')).join(','));
    if (values?.cl?.length) searchParams.append('cl', values.cl.map((v) => v._id.replace(/,/, '\\,')).join(','));
    if (values?.materialName?.length) searchParams.append('materialName', values.materialName.map((v) => v.replace(/,/, '\\,')).join(','));
    if (values?.materialUnit?.length) searchParams.append('materialUnit', values.materialUnit.map((v) => v.replace(/,/, '\\,')).join(','));
    if (values?.materialColor?.length) searchParams.append('materialColor', values.materialColor.map((v) => v.replace(/,/, '\\,')).join(','));
    if (values?.materialSpecification?.length) searchParams.append('materialSpecification', values.materialSpecification.map((v) => v.replace(/,/, '\\,')).join(','));
    if (values?.lineWidth?.length) searchParams.append('lineWidth', values.lineWidth.join(','));
    if (values?.legendSymbol?.length) searchParams.append('legendSymbol', values.legendSymbol.map((v) => v.replace(/,/, '\\,')).join(','));
    if (values?.letteringText?.length) searchParams.append('letteringText', values.letteringText.map((v) => v.replace(/,/, '\\,')).join(','));
    if (values?.letteringParameters?.length) searchParams.append('letteringParameters', values.letteringParameters.map((v) => v.replace(/,/, '\\,')).join(','));
    
    const result = await $api.get(
      `${process.env.REACT_APP_BASE_URL}/submissions/materials/options`,
      {
        params: searchParams,
        ...getOptions()
      }
    );

    return result.data;
  } catch (err) {
    console.log(err);
  }
};
