import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography, IconButton, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useSelector, useDispatch } from 'react-redux';

import PageLoader from '../PageLoader2';
import PeopleBlock from './components/PeopleBlock';
import EquipmentBlock from './components/EquipmentBlock';
import ResourcesConflictModal from './components/ResourcesConflictModal';
import AlertModal from '../../components/AlertModal';
import styles from './styles';
import OverlappingModal from '../OverlapsingModal';
import { updateSheetAddResource, getEquipment } from 'store/schedule/schedulePageOperation';
import { equipmentCheckConflicts, workersCheckConflicts } from './apiCalls';
import { putWorklogEquipment, putWorklogWorkers } from 'store/currentWorklog/operations';
import { userTypes } from 'common/permissions/userTypes';
import { checkUserRole } from 'helpers/_helpers';

const ResourcesForm = ({
  type,
  closeDrawer,
  classes,
  isDataLoading,
  unavailableResources,
  openSnackbar,
}) => {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [conflictModal, setConflictModal] = useState({
    isOpen: false,
    conflictResources: [],
    resourceType: '',
    allSheetsThisDay: [],
  });
  const [conflictWorkers, setConflictWorkers] = useState([]);
  const [infoModal, setInfoModal] = useState({
    isOpen: false,
    text: '',
  });
  const [overlappingModal, setOverlappingModal] = useState(null);

  const sheet = useSelector((store) => store.currentWorklog.worklogSheet.sheet);
  const equipmentList = useSelector((store) => store.schedule.resources.equipment) || [];
  const usersList = useSelector((store) => store.schedule.resources.workers);

  // useEffect(() => {
  //   dispatch(getEquipment());
  // }, []);
  const setSubmitting = () => setIsSubmitting(true);

  const setSubmitData = async (data, isValid) => {
    if (!isValid) {
      setIsSubmitting(false);
      return;
    }
    if (type === 'people') {
      const workerIds = data.map((el) => el._id);

      try {
        // TODO: change constant string Super Admin to dictionary variable
        const shouldCheckConflicts =
          checkUserRole(userTypes.admin) ||
          checkUserRole(userTypes.dispatcher) ||
          checkUserRole('Super Admin');
        if (shouldCheckConflicts) {
          await workersCheckConflicts({ workerIds }, sheet._id);
        }
        await dispatch(putWorklogWorkers({ workers: data }, sheet._id));

        dispatch(updateSheetAddResource(sheet._id, 1));
        closeDrawer();
      } catch (e) {
        setIsSubmitting(false);
        if (e.response && e.response.data.type === 'resource-conflict') {
          setConflictModal({
            isOpen: true,
            conflictResources: e.response.data.conflictResourceIds,
            resourceType: 'workers',
            data: data,
          });
        } else if (e.response && e.response.data.type === 'sheet-time-overlapse') {
          setOverlappingModal({
            reason: e.response.data.message,
            data: e.response.data.data,
          });
        } else if (e.response && e.response.data.type === 'timeoff-time-overlapse') {
          setInfoModal({ isOpen: true, text: e.response.data.message });
        } else if (openSnackbar) openSnackbar('error', e.response.data.message || e.message);
      }
    } else {
      const equipmentIds = data.map((el) => el._id);

      try {
        await equipmentCheckConflicts({ equipmentIds }, sheet._id);
        await dispatch(putWorklogEquipment({ equipment: data }, sheet._id));

        dispatch(updateSheetAddResource(sheet._id, 1));
        closeDrawer();
      } catch (e) {
        setIsSubmitting(false);
        if (e.response && e.response.data.type === 'resource-conflict') {
          setConflictModal({
            isOpen: true,
            conflictResources: e.response.data.conflictResourceIds,
            resourceType: 'equipment',
            data: data,
          });
        } else if (e.response && e.response.data.type === 'sheet-time-overlapse') {
          setOverlappingModal({
            reason: e.response.data.message,
            data: e.response.data.data,
            type: e.response.data.resourceType,
          });
        } else if (e.response && e.response.data.type === 'timeoff-time-overlapse') {
          setInfoModal({ isOpen: true, text: e.response.data.message });
        } else if (openSnackbar) openSnackbar('error', e.response.data.message || e.message);
      }
    }
  };

  const closeConflictModal = () => {
    setIsSubmitting(false);
    setConflictModal((prev) => ({ ...prev, isOpen: false }));
  };

  const closeModal = () => {
    setIsSubmitting(false);
    setInfoModal({ isOpen: false, text: '' });
  };

  const closeOverlappingModal = () => {
    setOverlappingModal(null);
  };

  if (!sheet._id) return null;

  return (
    <>
      <div className={classes.formWrapper} role="presentation">
        {isDataLoading ? (
          <PageLoader loading>
            <div />
          </PageLoader>
        ) : (
          <PageLoader loading={isSubmitting} small>
            <div className={classes.formFlex}>
              <div>
                <Typography variant="h3" style={{ marginBottom: '10px' }}>
                  Edit {type === 'people' ? 'crew' : 'equipment'}
                </Typography>

                <IconButton
                  className={classes.closeButton}
                  onClick={closeDrawer}
                  aria-label="Close"
                  disableTouchRipple
                >
                  <CloseIcon />
                </IconButton>

                {type === 'people' ? (
                  <PeopleBlock
                    list={usersList}
                    listOfAll={usersList}
                    sheet={sheet}
                    isSubmitting={isSubmitting}
                    setSubmitData={setSubmitData}
                    usedItems={sheet.workers}
                    unavailableResources={unavailableResources}
                  />
                ) : (
                  <EquipmentBlock
                    list={equipmentList}
                    sheet={sheet}
                    isSubmitting={isSubmitting}
                    setSubmitData={setSubmitData}
                    usedItems={sheet.equipment}
                    unavailableResources={unavailableResources}
                  />
                )}
              </div>

              <div className={classes.buttonHolder}>
                <Button
                  color="secondary"
                  variant="outlined"
                  className={classes.cancelButton}
                  onClick={closeDrawer}
                  disableTouchRipple
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="outlined"
                  className={classes.saveButton}
                  onClick={setSubmitting}
                  disableTouchRipple
                >
                  Save
                </Button>
              </div>

              {conflictModal.isOpen && (
                <ResourcesConflictModal
                  conflictResources={conflictModal.conflictResources}
                  resourceType={conflictModal.resourceType}
                  allSheetsThisDay={conflictModal.allSheetsThisDay}
                  sheet={sheet}
                  closeModal={closeConflictModal}
                  closeResourcesForm={closeDrawer}
                  conflictWorkers={conflictWorkers}
                  data={conflictModal.data}
                />
              )}
            </div>
          </PageLoader>
        )}
      </div>

      {infoModal.isOpen && (
        <AlertModal isOpen={infoModal.isOpen} info={infoModal.text} onClose={closeModal} />
      )}
      {overlappingModal && (
        <OverlappingModal
          data={overlappingModal.data}
          message={overlappingModal.reason}
          onClose={closeOverlappingModal}
          type={overlappingModal?.type}
        />
      )}
    </>
  );
};

ResourcesForm.propTypes = {
  classes: propTypes.object.isRequired,
  closeDrawer: propTypes.func.isRequired,
  isDataLoading: propTypes.bool.isRequired,
  usersList: propTypes.array.isRequired,
  equipmentList: propTypes.array.isRequired,
  sheet: propTypes.object.isRequired,
  type: propTypes.string.isRequired,
};

export default withStyles(styles)(ResourcesForm);
