import React from 'react';
import { Root } from './styled';
import Grid from '@material-ui/core/Grid';
import formatNumber from '../../../../utils/formatNumber';
import { TotalTime } from '../styled';
import Typography from '@material-ui/core/Typography';
import Warning from '../../../../../../components/icons/Warning';
import { Box } from '@material-ui/core/index';
import { styles } from './styled';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(styles);

const Footer = ({ data, payrollVerified, payroll, currentRoute, ...props }) => {
  const classes = useStyles();
  const {type} = useParams()

  return (
    <Box className={classes.rootMob}>
      <Grid
        container
        spacing={2}
        justifyContent={'space-between'}
        className={classes.footerHourThisWeek}
      >
        <Grid item xs={4} className={classes.hourThisWeek}>
          <dl className={'dl'}>
            <Typography align={'left'} component={'div'} style={{ lineHeight: 1.4 }}>
              <dt className={classes.timeThisWeek}>
                {typeof data.weekHours === 'undefined' ? '' : 'Hours this week:'}
              </dt>
              <dd className={'dd'}>
                {typeof data.weekHours === 'undefined' ? null : (
                  <>
                    <TotalTime>{formatNumber(data.weekHours)}</TotalTime>
                    &nbsp;hours
                  </>
                )}
              </dd>
            </Typography>
          </dl>
        </Grid>
        {type ? (
          <Grid item xs={4}>
            <dl className={'dl'}>
              <Typography align={'center'} component={'div'}>
                <dt className={'dt'}>Allocated OT:</dt>
                <dd className={'dd'}>
                  <TotalTime>{formatNumber(data.overtime)}</TotalTime>
                </dd>
              </Typography>
            </dl>
          </Grid>
        ) : null}

        <Grid className={classes.timeTotalWrapper}>
          <dl className={'dl'}>
            <Typography className={classes.timeTotal} align={'right'} component={'div'}>
              {currentRoute && payroll && !payrollVerified && data.totalTime !== payroll.totalTime && (
                <dd className={'warning-right'} style={{ top: '9px', left: '227px' }}>
                  <Warning />
                </dd>
              )}
              <dt className={'dt'}>Total time:</dt>
              <dd className={'dd'}>
                <TotalTime>{formatNumber(data.totalTime)}</TotalTime>
                <Typography className={classes.timeSectionTimeWord}>hours</Typography>
              </dd>
            </Typography>
          </dl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
