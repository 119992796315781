import React, { useEffect, useMemo, useState } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles/index';
import Typography from '@material-ui/core/Typography/index';
import IconButton from '@material-ui/core/IconButton/index';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid/index';
import TextField from '@material-ui/core/TextField/index';
import Button from '@material-ui/core/Button/index';

import PageLoader from 'components/PageLoader/PageLoaderMaterialForm';

import styles from './styles';
import UploadButton from './UploadButton';
import { Controller, useForm } from 'react-hook-form';
import { isIOS, Option } from 'components/Option';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAddInventory, fetchUpdateInventory, fetchGlassBeads } from 'store/supplies/suppliesOperations';
import suppliesActions from 'store/supplies/suppliesActions';
import { materialProperties } from 'api/data/material-properties';

const FormPanel = ({ classes, closeDrawer, data, onSave, type, setSnackbar }) => {
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [showSymbolsField, setShowSymbolsField] = useState(true);
  const glassBeadsData = useSelector((state) => state.supplies.inventory.availableGlassBeads);

  const [sortedGlassBeadsData, setSortedGlassBeadsData] = useState([]);

  useEffect(() => {
    setSortedGlassBeadsData(
      [...glassBeadsData, ...(data.bead ? [data.bead] : [])].sort((a, b) =>
        a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
      )
    );
    if (data.bead?._id) {
      setValue('data.materialId', data.bead._id)
    }
  }, [data, glassBeadsData]);

  const materialsData = useSelector((state) => state.supplies.materials.data);
  const manufacturersData = useSelector((state) => state.supplies.manufacturers.data);
  const drawerInventoryLoading = useSelector(
    (state) => state.supplies.inventory.drawerInventoryLoading
  );
  const successfullyAdded = useSelector((state) => state.supplies.inventory.successfullyAdded);
  const postError = useSelector((state) => state.supplies.inventory.postError);
  const manufactureLoading = useSelector((state) => state.supplies.manufacturers.loading);
  const materialsLoading = useSelector((state) => state.supplies.materials.loading);

  const {
    control,
    errors,
    getValues,
    handleSubmit,
    setError,
    clearErrors,
    register,
    setValue,
    watch,
  } = useForm();

  useEffect(() => {
    if (successfullyAdded) {
      onSave();
      dispatch(fetchGlassBeads(null, 'false'));// refresh available glass-beads(because must be unique with select inventory)
      dispatch(suppliesActions.postInventoryError(null));
      dispatch(suppliesActions.successfullyAdded(false));
    }
  }, [successfullyAdded]);

  useEffect(() => {
    register({ name: 'bulkCert' });
  }, [register]);

  useEffect(() => {
    setValue('bulkCert', data.bulkCert);
  }, [data]);

  const setFileError = (err) => {
    setError('bulkCert', 'file error', err);
  };

  const resetFileError = (err) => {
    clearErrors('bulkCert');
  };

  const onFileChange = (file) => {
    setFile(file);
    resetFileError();

    setValue('bulkCert', file);
  };

  const currentMaterial = watch('data.materialId');
  useEffect(() => {
    if (currentMaterial) {
      const currentMaterialType = materialsData.find((el) => el._id === currentMaterial);

      if (currentMaterialType && currentMaterialType.type === 'each') {
        setShowSymbolsField(true);
      } else {
        setShowSymbolsField(false);
      }
    }
  }, [currentMaterial]);

  const onSubmit = async (values) => {
    const postData = {};
    if (!file) {
      delete values.bulkCert;
    } else {
      postData.bulkCert = values.bulkCert;
    }

    const valuesData = { ...values.data };

    const isItGlassBead = sortedGlassBeadsData.find((el) => el._id === values.data.materialId);
    if (isItGlassBead) {
      valuesData.beadId = valuesData.materialId;
      delete valuesData.materialId;
    }

    postData.data = JSON.stringify(valuesData);

    if (data._id) {
      dispatch(fetchUpdateInventory(data._id, postData));
    } else {
      dispatch(fetchAddInventory(postData));
      }
  };

  // const beadMaterialName = useMemo(() => {
  //   const oldMaterialSpec = materialProperties.materials.find((m) => m.id === data.oldMaterialId);
  //   if (oldMaterialSpec && oldMaterialSpec.name) {
  //     return oldMaterialSpec.name;
  //   }
  //   return '';
  // }, [data.oldMaterialId]);

  return (
    <div className={classes.formWrapper} role="presentation">
      <PageLoader loading={drawerInventoryLoading || manufactureLoading || materialsLoading}>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className={classes.formFlex}>
          <div>
            <Typography variant="h3" className={classes.marginHeader}>
              {type === 'create' ? 'Add' : 'Edit'} Inventory
            </Typography>

            <IconButton
              className={classes.closeButton}
              onClick={() => {
                dispatch(suppliesActions.postInventoryError(null));
                closeDrawer();
              }}
              aria-label="Close"
              disableTouchRipple
            >
              <CloseIcon />
            </IconButton>

            <Grid container spacing={2} className={classes.flexContainer}>
              <Grid item xs={12} lg={12} md={12} sm={12} xl={12}>
                <label htmlFor="data.materialId" className={classes.label}>
                  <Typography variant="body1" color="textSecondary">
                    Material:
                  </Typography>
                  <Controller
                    name="data.materialId"
                    control={control}
                    defaultValue={data.materialId || ''}
                    rules={{
                      validate: () => {
                        // workaround for inventories for glass beads
                        if (data.isBead && data.oldMaterialId) return true;
                        const { data: valuesData } = getValues();
                        return !!valuesData.materialId || 'Material is required';
                      },
                    }}
                    as={
                      <TextField
                        select
                        id="data.materialId"
                        className={classes.textField}
                        error={Boolean(errors.data && errors.data.materialId) || postError}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Material' }}
                        fullWidth
                        SelectProps={{
                          native: isIOS,
                        }}
                      >
                        {/* {data.isBead &&
                          data.oldMaterialId &&
                          beadMaterialName && ( // workaround for inventory glass beads
                            <Option key="" value="">
                              {beadMaterialName}
                            </Option>
                          )} */}
                        {materialsData.map((m) => (
                          <Option key={m._id} value={m._id}>
                            {m.name}
                          </Option>
                        ))}
                        {sortedGlassBeadsData.map((m) => (
                          <Option key={m._id} value={m._id}>
                            Reflective Media - {m.name}
                          </Option>
                        ))}
                      </TextField>
                    }
                  />
                  {errors.data && errors.data.materialId && (
                    <span className={classes.error}>{errors.data.materialId.message}</span>
                  )}
                </label>
              </Grid>

              {showSymbolsField && (
                <Grid
                  item
                  xs={12}
                  lg={6}
                  style={!data.symbolOrWidth && type === 'edit' ? { display: 'none' } : {}}
                >
                  <label htmlFor="data.symbolOrWidth" className={classes.label}>
                    <Typography variant="body1" color="textSecondary">
                      Symbol / Width:
                    </Typography>
                    <Controller
                      name="data.symbolOrWidth"
                      control={control}
                      defaultValue={data.symbolOrWidth || ''}
                      as={
                        <TextField
                          id="data.symbolOrWidth"
                          className={classes.textField}
                          variant="outlined"
                          inputProps={{ 'aria-label': 'Symbol / Width' }}
                          fullWidth
                          error={postError}
                        />
                      }
                    />
                  </label>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                lg={type === 'edit' ? (!data.symbolOrWidth ? 12 : 6) : !showSymbolsField ? 12 : 6}
              >
                <label htmlFor="data.colorOrNumber" className={classes.label}>
                  <Typography variant="body1" color="textSecondary">
                    Color / Number:
                  </Typography>
                  <Controller
                    name="data.colorOrNumber"
                    control={control}
                    defaultValue={data.colorOrNumber || ''}
                    as={
                      <TextField
                        id="data.colorOrNumber"
                        name="data.colorOrNumber"
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Color' }}
                        fullWidth
                        error={postError}
                      />
                    }
                  />
                </label>
              </Grid>

              <Grid item xs={12} lg={12}>
                <label htmlFor="data.manufacturerId" className={classes.label}>
                  <Typography variant="body1" color="textSecondary">
                    Manufacturer:
                  </Typography>
                  <Controller
                    name="data.manufacturerId"
                    control={control}
                    defaultValue={data.manufacturerId || ''}
                    rules={{
                      validate: () => {
                        const { data } = getValues();
                        return !!data.manufacturerId || 'Manufacturer is required';
                      },
                    }}
                    as={
                      <TextField
                        select
                        id="data.manufacturerId"
                        className={classes.textField}
                        error={Boolean(errors.data && errors.data.manufacturerId) || postError}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Manufacturer' }}
                        fullWidth
                        SelectProps={{
                          native: isIOS,
                        }}
                      >
                        {manufacturersData.map((m) => (
                          <Option key={m._id} value={m._id}>
                            {m.name}
                          </Option>
                        ))}
                      </TextField>
                    }
                  />
                  {errors.data && errors.data.manufacturerId && (
                    <span className={classes.error}>{errors.data.manufacturerId.message}</span>
                  )}
                </label>
              </Grid>

              <Grid item xs={12} lg={6}>
                <label htmlFor="data.lotNumber" className={classes.label}>
                  <Typography variant="body1" color="textSecondary">
                    Lot Number:
                  </Typography>
                  <Controller
                    name="data.lotNumber"
                    control={control}
                    defaultValue={data.lotNumber || ''}
                    as={
                      <TextField
                        id="data.lotNumber"
                        name="data.lotNumber"
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Lot number' }}
                        fullWidth
                      />
                    }
                  />
                </label>
              </Grid>
              <Grid item xs={12} lg={6}>
                <label htmlFor="bulkCert" className={classes.label}>
                  <Typography variant="body1" color="textSecondary">
                    Bulk Certificate:
                  </Typography>

                  <UploadButton
                    name="bulkCert"
                    value={getValues().bulkCert}
                    className={classes.fileUpload}
                    onChange={onFileChange}
                    error={errors.bulkCert}
                    setError={setFileError}
                    file={file}
                  />
                </label>
              </Grid>
            </Grid>
            {postError && (
              <div className={classes.postError}>This combination for material already exists</div>
            )}
          </div>

          <div className={classes.buttonHolder}>
            <Button
              color="secondary"
              className={classes.cancelButton}
              onClick={() => {
                dispatch(suppliesActions.postInventoryError(null));
                closeDrawer();
              }}
            >
              Cancel
            </Button>

            <div className={classes.verticalDivider} />

            <Button type="submit" color="primary" className={classes.saveButton}>
              Save
            </Button>
          </div>
        </form>
      </PageLoader>
    </div>
  );
};

FormPanel.propTypes = {
  classes: propTypes.object.isRequired,
  closeDrawer: propTypes.func.isRequired,
  data: propTypes.object,
  onUpdate: propTypes.func,
  isLoading: propTypes.bool.isRequired,
  type: propTypes.string.isRequired,
};

FormPanel.defaultProps = {
  data: {
    materialId: '',
    symbolOrWidth: '',
    colorOrNumber: '',
    manufacturerId: '',
    lotNumber: '',
    bulkCert: '',
  },
  onUpdate: () => {},
  onSave: () => {},
};

export default withStyles(styles)(FormPanel);
