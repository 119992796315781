import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import ResourcesSidebar from 'pages/Schedule/components/ResourcesSidebar/ResourcesSidebar';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FORMATS } from 'helpers/formats';
import { format } from 'date-fns';
import { getSheets } from 'store/schedule/schedulePageOperation';
import { globalBEDateFormat } from 'common/dateFormatConfig';

const Resource = ({ classes }) => {
  const dispatch = useDispatch();
  const selectedDate = useSelector((state) => state.schedule.selectedDate);
  const filters = useSelector((state) => state.schedule.filters);

  useEffect(() => {
    const date = format(selectedDate, globalBEDateFormat);
    dispatch(
      getSheets({
        date,
        filters,
        isCrewLeader: false,
        isGridView: false,
      })
    );
  }, [selectedDate, filters]);

  return <ResourcesSidebar isAdminMobile />;
};

export default withStyles(styles)(Resource);
