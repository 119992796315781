export default (theme) => ({
  formWrapper: {
    width: 380,
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      width: 550,
    },
  },
  closeButton: {
    position: 'absolute',
    right: 15,
    top: 12,
  },
  buttonHolder: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  cancelButton: {
    margin: 20,
    minWidth: 100,
    '&:hover': {
      color: theme.palette.textColor.dark,
    },
    '&:focus': {
      color: theme.palette.textColor.dark,
      backgroundColor: '#fff',
    },
    '&:disabled': {
      backgroundColor: '#fff',
    },
  },
  saveButton: {
    margin: 20,
    minWidth: 100,
    '&:hover': {
      color: theme.palette.textColor.primary,
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
      backgroundColor: '#fff',
    },
    '&:disabled': {
      backgroundColor: '#fff',
    },
  },
  formFlex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '95vh',
    padding: '20px 15px 0',
  },
  textField: {
    maxWidth: '100%',
  },
  marginVertical: {
    margin: '20px 0',
  },
  shiftImage: {
    height: 16,
    margin: '-2px 5px 0 0',
  },
  label: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  marginHeader: {
    marginBottom: 30,
  },
  verticalDivider: {
    width: 1,
    height: 30,
    backgroundColor: theme.palette.borderColor.primary,
  },
  error: {
    color: theme.palette.textColor.red,
    padding: '0 5px',
    fontSize: 11,
    [theme.breakpoints.down('md')]: {
      maxWidth: 200,
    },
  },
  postError: {
    color: theme.palette.textColor.red,
    padding: '35px 0 0',
    fontSize: 11,
    textAlign: 'center',
  },
  fieldWrapper: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: 255,
    },
  },
  flexContainer: {
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'space-between',
    },
  },
  resetButton: {
    '&:hover': {
      color: theme.palette.primary.light,
      backgroundColor: '#fff !important',
    },
    '&:focus': {
      color: theme.palette.primary.blue,
      backgroundColor: '#fff',
    },
    textTransform: 'none',
  },
  customSelectedStyle: {
    backgroundColor: 'rgba(0, 0, 0, 0.14)',
  },
});
