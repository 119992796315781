import { differenceInYears, differenceInMonths, differenceInWeeks, differenceInDays } from 'date-fns';


export const getLastSignDate = (lastLogin) => {
  if (!lastLogin) return 'N/A';

  const date = new Date(lastLogin);
  const diffInYears = differenceInYears(new Date(), date);
  if (diffInYears) return `${diffInYears} year${diffInYears > 1 ? 's' : ''} ago`;

  const diffInMonths = differenceInMonths(new Date(), date);
  if (diffInMonths) return `${diffInMonths} month${diffInMonths > 1 ? 's' : ''} ago`;

  const diffInWeeks = differenceInWeeks(new Date(), date);
  if (diffInWeeks) return `${diffInWeeks} week${diffInWeeks > 1 ? 's' : ''} ago`;

  const diffInDays = differenceInDays(new Date(), date);
  if (diffInDays) return `${diffInDays} day${diffInDays > 1 ? 's' : ''} ago`;

  return 'Today';
}