export default (theme) => ({
  dialogRoot: {
    padding: '15px',
    [theme.breakpoints.up('sm')]: {
      width: 390,
    },
    [theme.breakpoints.down('sm')]: {
      margin: 5,
      width: '100%',
    },
  },
  content: {
    color: theme.palette.textColor.dark,
    fontSize: 14,
    fontWeight: 500,
    padding: '5px 0',
  },
  button: {
    margin: '0 auto',
    color: '#fff',
    background: theme.palette.primary.blue,
    '&:hover': {
      color: '#fff',
      background: '#1976D2! important',
    },
    '&:focus': {
      color: '#fff',
      background: `${theme.palette.primary.blue} !important`,
    },
  },
  actions: {
    margin: 0,
  },
  title: {
    padding: 0,
    fontSize: '20px',
    fontWeight: '500',
    letterSpacing: '0.25px',
    margin: '10px 0',
  },
  closeButton: {
    position: 'absolute',
    right: 15,
    top: 12,
    color: theme.palette.textColor.dark,
    padding: 5,
    '&:hover': {
      backgroundColor: '#fff !important',
      color: `${theme.palette.textColor.dark} !important`,
    },
  },
});
