export default theme => ({
  showForPrint: {
    display: 'none',
    '@media print': {
      display: 'block'
    }
  },
  worklogContainer: {
    border: '1px solid #000',
    margin: '0 0 10px 0',
    pageBreakInside: 'avoid'
  },
  worklogHeader: {
    padding: 10,
    fontSize: 12,
    borderBottom: '1px solid #000',
    '& h5': {
      fontSize: 16
    }
  },
  textCenter: {
    fontSize: 22,
    textAlign: 'center'
  },
  textCenterHours: {
    textAlign: 'center'
  },
  resourcesList: {
    padding: 10,
    fontSize: 12
  },
  equipmentList: {
    padding: '10px 0px',
    fontSize: 12
  }
});
