import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import styles from './styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { RadioGroup, FormControlLabel } from '@material-ui/core';
import CustomRadioButton from '../../../../../../CustomRadioButton/CustomRadioButton';

const LocationModal = ({ classes, isOpen, handleModal, addLocationFromEditor, type }) => {
  const [value, setValue] = useState('project');
  const onClose = () => {
    handleModal(false);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        classes: {
          root: classes.dialogRoot,
        },
      }}
    >
      <IconButton
        className={classes.closeButton}
        onClick={onClose}
        aria-label="Close"
        disableTouchRipple
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h3">Where do you want to Add {type === 'projectLocation' ? 'Project Location' : 'Rally Point'}?</Typography>
      </DialogTitle>
      <RadioGroup
        className={classes.radio}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        value={value}
        row
      >
        <FormControlLabel
          value="sheet"
          control={<CustomRadioButton big={true} />}
          label="Save to Work Log"
          labelPlacement="end"
        />
        <FormControlLabel
          value="project"
          control={<CustomRadioButton big={true}/>}
          label="Save to Project"
          labelPlacement="end"
        />
      </RadioGroup>
      <DialogActions style={{ justifyContent: 'space-around' }}>
        <Button
          color="secondary"
          variant="outlined"
          className={classes.cancelButton}
          onClick={onClose}
          disableTouchRipple
        >
          Cancel
        </Button>
        <Button
          type="submit"
          color="primary"
          variant="outlined"
          className={classes.saveButton}
          onClick={() => {
            addLocationFromEditor(value);
          }}
          disableTouchRipple
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(LocationModal);
