import { createReducer } from '@reduxjs/toolkit';
import availabilityPageActions from './availabilityPageActions';

const initialState = {
  selectedDate: null,
  timeoffsByType: { approved: [], denied: [], requested: [] },
  holidays: [],
  users: [],
  equipmentEvents: [],
  equipment: [],
};

const availability = createReducer(initialState, {
  [availabilityPageActions.setDate]: (state, { payload }) => ({ ...state, selectedDate: payload }),
  [availabilityPageActions.setAllUsers]: (state, { payload }) => ({ ...state, users: payload }),
  [availabilityPageActions.setAllEquipments]: (state, { payload }) => ({ ...state, equipment: payload }),
  [availabilityPageActions.setHolidays]: (state, { payload }) => ({ ...state, holidays: payload }),
  [availabilityPageActions.setEquipmentEvents]: (state, { payload }) => ({ ...state, equipmentEvents: payload }),
  [availabilityPageActions.setTimeoffsByType]: (state, { payload }) => {
    return {
      ...state,
      timeoffsByType: payload,
    };
  },
  // Updated Calendar Events
  [availabilityPageActions.addEquipmentEvents]: (state, { payload }) => ({
    ...state,
    equipmentEvents: state.equipmentEvents.concat(payload),
  }),
  [availabilityPageActions.updateEquipmentEvent]: (state, { payload }) => ({
    ...state,
    equipmentEvents: state.equipmentEvents.map(event => event._id !== payload._id ? event : payload),
  }),
  [availabilityPageActions.removeEquipmentEvent]: (state, { payload }) => ({
    ...state,
    equipmentEvents: state.equipmentEvents.filter(event => event._id !== payload._id),
  }),
});

export default availability;
