let rounded = function (number) {
  return +number.toFixed(2);
};

export default function isCorrectNumber(num) {
  if (num < 0 || num === undefined) {
    return 0;
  }
  return rounded(num);
}
