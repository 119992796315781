import { HOLIDAYS_EVENT } from 'subscriptions/events/events.enum';
import { subscriptionsFabric } from 'subscriptions/subscriptions.fabric';
import schedulePageActions from '../schedulePageActions';

const handlers = {
  [HOLIDAYS_EVENT.created]: (store) => (data) => {
    if (data._id) store.dispatch(schedulePageActions.addCalendarEvent(data))
  },
  [HOLIDAYS_EVENT.paidUpdated]: (store) => (data) => {
    if (!data._id) return;
    const calendarEvents = store.getState().schedule.calendarEvents;
    const updatedcalendarEvents = calendarEvents.map(el => el._id === data._id ? data : el)
    store.dispatch(schedulePageActions.setCalendarEvents(updatedcalendarEvents))
  },
  [HOLIDAYS_EVENT.paidDeleted]: (store) => (data) => {
    if (data._id) store.dispatch(schedulePageActions.removeCalendarEvents(data._id))
  },
};

export const holidaysSubscriptionMiddleware = subscriptionsFabric(
  schedulePageActions,
  HOLIDAYS_EVENT,
  handlers
);
