import { makeStyles } from '@material-ui/core';

const styles = (theme) => ({
  root: {},
  tabs: {
    borderBottom: '1px solid #e8e8e8',
    paddingTop: 0,
    marginBottom: 10,
    color: theme.palette.textColor.grey,
    '& button': {
      minWidth: 100,
      padding: 0,
    },
    '& button:hover': {
      backgroundColor: 'transparent !important',
    },
    '& button.Mui-selected': {
      color: theme.palette.primary.blue,
    },
    '& span': {
      fontSize: '0.875rem',
      fontWeight: 500,
    },
  },
});

const useStyles = makeStyles(styles);
export default useStyles;
