import classnames from 'classnames';
import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import ReactDOM from 'react-dom';
import photoPlaceholder from 'assets/images/user-photo-placeholder.svg';

const useStyles = makeStyles((theme) => ({
  divider: {
    width: '1px',
    display: 'block',
    backgroundColor: '#e8e8e8',
    position: 'absolute',
    height: '100%',
    zIndex: 2,
    top: 0,
  },
  clHeader: {
    display: 'flex',
    marginLeft: '43px',
    position: 'fixed',
    zIndex: 999999,
    top: '120px',
    background: 'white',
    height: '50px',
  },
  flexBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '203px',
  },
  day: {
    color: theme.palette.textColor.yellow,
  },
  night: {
    color: theme.palette.textColor.darkBlue,
  },
  inactive: {
    color: theme.palette.textColor.secondary,
  },
  dayBorder: {
    border: `1px solid ${theme.palette.textColor.yellow}`,
  },
  nightBorder: {
    border: `1px solid ${theme.palette.textColor.darkBlue}`,
  },
  inactiveBorder: {
    border: `1px solid ${theme.palette.textColor.secondary}`,
  },
  photoWrapper: {
    borderRadius: '50%',
    width: 20,
    height: 20,
    transform: 'rotate(135deg)',
    minWidth: 20,
    boxSizing: 'border-box',
  },
  photo: {
    display: 'block',
    transform: 'rotate(-135deg)',
    height: 18,
    maxWidth: 18,
    borderRadius: '50%',
    margin: '0 auto',
  },
  username: {
    marginLeft: 8,
    paddingRight: 5,
    wordBreak: 'break-word',
    fontSize: 14,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export const CrewLeaderHeader = ({ crewLeaders, workers }) => {
  const el = document.querySelector('.fc-time-grid__header');
  const classes = useStyles();

  if (!el) return null;


  return ReactDOM.createPortal(
    <>
      {crewLeaders.map((worker) => {
        if (!worker) return null;
        return (
          <div
            key={worker._id}
            style={{ width: 203 }}
            className={classes.flexBox}
          >
            <div
              className={classnames(
                classes.photoWrapper,
                classes[
                  `${worker.shifts.timeOfDay.toLowerCase()}Border`
                ]
              )}
            >
              <img
                src={photoPlaceholder}
                className={classes.photo}
              />
            </div>
            <Typography
              variant="body1"
              color="textPrimary"
              className={classnames(
                classes.username,
                classes[worker.shifts.timeOfDay.toLowerCase()]
              )}
            >
              {worker.username}
            </Typography>
          </div>
        );
      })}
    </>,
    el
  );
};
