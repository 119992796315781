import React from 'react';
import propTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif',
    width: '100%',
    '& tr:nth-of-type(2n)': {
      background: '#f9f9f9',
    },
  },
  tableCell: {
    textAlign: 'right',
    fontSize: '.875rem',
    padding: '0.5625rem 0.625rem',
    '&:nth-child(1)': {
      textAlign: 'left',
    },
    '&:nth-child(2)': {
      textAlign: 'left',
    },
  },
}));

const TableCSV = ({ csv, type }) => {
  const classes = useStyles();

  if (!csv.length) {
    return null;
  }

  const header = Object.keys(csv[0]);

  return (
    <table id="CSV-table" className={classes.root}>
      <tbody>
        <tr>
          {header.map((el, i) => (
            <td key={i} className={classes.tableCell}>
              {el}
            </td>
          ))}
        </tr>
        {csv?.map((obj, index) => (
          <tr key={index}>
            {Object.values(obj).map((el, i) => (
              <td key={i} className={classes.tableCell}>
                {el}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

TableCSV.propTypes = {
  csv: propTypes.array.isRequired,
};

export default TableCSV;
