import React from 'react';
import { Label, RemoveBtn, Root, Value } from './styled';
import CrossIcon from '../../../../../../../../../../components/icons/Cross';
import IconButton from '../../../../../../../../../../components/core/IconButton/IconButton';

const ManualOverride = ({ label, value, onRemoveClick, children }) => (
  <Root>
    {children}
    {label && <Label>{label}</Label>}
    <Value>{value}</Value>
    <RemoveBtn onClick={onRemoveClick}>
      <IconButton>
        <CrossIcon />
      </IconButton>
    </RemoveBtn>
  </Root>
);

export default ManualOverride;
