import $api from 'http/index';
import { getOptions } from '../../helpers/getOptions';
import { startOfMonth, endOfMonth, startOfWeek, endOfWeek, parse } from 'date-fns';
import { allDatesFormat } from 'common/dateFormatConfig';

const dateHelper = (date) => {
  return [
    startOfMonth(parse(date, allDatesFormat[1], new Date())).toISOString(),
    endOfMonth(parse(date, allDatesFormat[1], new Date())).toISOString(),
  ];
};

export const getPrintTimeoffs = async (date) => {
  const [from, to] = dateHelper(date);
  const queryParams = new URLSearchParams();
  queryParams.append('from', from);
  queryParams.append('to', to);
  queryParams.append('status', 'Approved');

  const res = await $api.get(
    `${process.env.REACT_APP_BASE_URL}/timeoffs?${queryParams}`,
    getOptions()
  );
  return res.data;
};

export const getPrintEquipmentEvents = async (date) => {
  const [from, to] = dateHelper(date);
  const queryParams = new URLSearchParams();
  queryParams.append('from', from);
  queryParams.append('to', to);

  const res = await $api.get(
    `${process.env.REACT_APP_BASE_URL}/calendar-events?${queryParams}`,
    getOptions()
  );
  return res.data;
};

export const getPrintHolidays = async (date) => {
  const [from, to] = dateHelper(date);
  const queryParams = new URLSearchParams();
  queryParams.append('from', from);
  queryParams.append('to', to);
  queryParams.append('deleted', 'false');

  const res = await $api.get(
    `${process.env.REACT_APP_BASE_URL}/holidays?${queryParams}`,
    getOptions()
  );
  return res.data;
};

export const getPrintAllUsers = async () => {
  const res = await $api.get(
    `${process.env.REACT_APP_BASE_URL}/users?isAvailability=true
      `,
    getOptions()
  );
  return res.data;
};
