import { MEASUREMENT_SYSTEM } from "common/measurements";

const defaultData = (settings = {}) => ({
  name: '',
  type: '',
  discreteHomepageStats: false,
  customDescription: false,
  lines: [],
  legends: [],
  letterings: [],
  glassBeads: [],
  ticks: {},
  measurement: settings?.measurement || MEASUREMENT_SYSTEM.imperial,
});
export default defaultData;
