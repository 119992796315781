import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import classnames from 'classnames';
import { addLeadingZero } from 'helpers/helpers';

const TimeInfo = ({ classes, workerHours, sheet, getFormattedHoursRange }) => {
  const isMobile = window.innerWidth <= 700;

  return (
    <>
      {isMobile ? (
        <div className={classnames(classes.gridsWrapper)}>
          <div className={classnames(classes.rowWrapper)}>
            {workerHours.map((h, i) => (
              <div className={classnames(classes.gridCellWrapper)}>
                {i === 0 ? (
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    className={classes.regularFontWeight}
                  >
                    On Job Time:
                  </Typography>
                ) : null}
                <Typography variant="body1" color="textPrimary">
                  {sheet.travelTimeOnly || sheet.isWlHours ? 'N/A' : getFormattedHoursRange(h)}
                </Typography>
              </div>
            ))}
          </div>
          <div className={classnames(classes.rowWrapper)}>
            {workerHours.map((h, i) => (
              <div className={classnames(classes.gridCellWrapper)}>
                {i === 0 ? (
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    className={classes.regularFontWeight}
                  >
                    Individual Time To:
                  </Typography>
                ) : null}
                <Typography variant="body1" color="textPrimary">
                  {h.isWlTravelTime ? (
                    'N/A'
                  ) : (
                    <>
                      {addLeadingZero(h?.travelTimeTo?.hours)}h{' '}
                      {addLeadingZero(h?.travelTimeTo?.minutes)}m
                    </>
                  )}
                </Typography>
              </div>
            ))}
          </div>
          <div className={classnames(classes.rowWrapper)}>
            {workerHours.map((h, i) => (
              <div className={classnames(classes.gridCellWrapper)}>
                {i === 0 ? (
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    className={classes.regularFontWeight}
                  >
                    Individual Time From:
                  </Typography>
                ) : null}
                <Typography variant="body1" color="textPrimary">
                  {h.isWlTravelTime ? (
                    'N/A'
                  ) : (
                    <>
                      {addLeadingZero(h?.travelTimeFrom?.hours)}h{' '}
                      {addLeadingZero(h?.travelTimeFrom?.minutes)}m
                    </>
                  )}
                </Typography>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <>
          {workerHours.map((h) => (
            <div className={classnames(classes.gridCellWrapper)}>
              <Grid item xs={5} className={classnames(classes.gridCell)}>
                <Grid key={h.id}>
                  <Typography variant="body1" color="textPrimary">
                    {sheet.travelTimeOnly || sheet.isWlHours ? 'N/A' : getFormattedHoursRange(h)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={3} className={classnames(classes.gridCell)}>
                <Typography variant="body1" color="textPrimary">
                  {h.isWlTravelTime ? (
                    'N/A'
                  ) : (
                    <>
                      {addLeadingZero(h?.travelTimeTo?.hours)}h{' '}
                      {addLeadingZero(h?.travelTimeTo?.minutes)}m
                    </>
                  )}
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                className={classnames(classes.gridCell)}
                style={{ paddingLeft: 15 }}
              >
                <Typography variant="body1" color="textPrimary">
                  {h.isWlTravelTime ? (
                    'N/A'
                  ) : (
                    <>
                      {addLeadingZero(h?.travelTimeFrom?.hours)}h{' '}
                      {addLeadingZero(h?.travelTimeFrom?.minutes)}m
                    </>
                  )}
                </Typography>
              </Grid>
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default TimeInfo;
