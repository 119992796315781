import { createReducer } from '@reduxjs/toolkit';
import worklogsPageActions from './worklogsPageActions';
import { sortAndMap, sortAndMapSubmitted } from 'store/worklogs/worklogsPageOperations';

const initialState = {
  error: null,
  forceUpdate: false,
  isLoading: false,
  dataActive: [],
  dataSubmitted: [],
  dataForeman:[],
  sheetIds: [],
  hasMore: true,
  sheetsLength: 0,
  submissionCertGenerates: false,
};

const worklogs = createReducer(initialState, {
  [worklogsPageActions.setWorklogLoading]: (state) => ({ ...state, isLoading: true }),

  [worklogsPageActions.setActiveWorklogs]: (state, { payload }) => {
    const data = payload.clear ? payload.data : [ ...state.dataActive.flatMap(item => item[1]), ...payload.data]
    return {
    ...state,
    dataActive: sortAndMap(data, 'reverse', payload?.shouldBeToday, payload.dateFormat),
    forceUpdate: false,
    isLoading: false,
    hasMore: payload.hasMore,
    sheetIds: [...state.sheetIds, ...payload.sheetIds],
    sheetsLength: data.length,
  }},
  [worklogsPageActions.updateActiveWorklogs]: (state, { payload }) => {
    return {
    ...state,
    dataActive: payload,
  }},
  [worklogsPageActions.resetWorklogs]: () => ({
    ...initialState
  }),
  [worklogsPageActions.setSubmittedWorklogs]: (state, { payload }) => {
    const data = payload.clear ? payload.data : [ ...state.dataSubmitted.flatMap(item => item[1]), ...payload.data] 
    return {
    ...state,
    dataSubmitted: sortAndMapSubmitted(data, payload.dateFormat),
    forceUpdate: false,
    isLoading: false,
    pageCount: payload.pageCount,
    hasMore: payload.hasMore,
    sheetIds: [...state.sheetIds, ...payload.sheetIds],
  }},
  [worklogsPageActions.setForemanDataWorklogs]: (state, { payload }) => {
    const data = payload.clear ? payload.data : [ ...state.dataForeman.flatMap(item => item[1]), ...payload.data] 
    return {
    ...state,
    dataForeman: sortAndMap(data, 'reverse', null, payload.dateFormat),
    forceUpdate: false,
    isLoading: false,
    pageCount: payload.pageCount,
    hasMore: payload.hasMore,
    sheetIds: [...state.sheetIds, ...payload.sheetIds],
  }},
  
  [worklogsPageActions.setDeletedActiveSheet]: (state, { payload }) => {
    const reducedData = state.dataActive.reduce( (acc, el) => {
      const temp = [el[0], el[1].filter((sheet) => sheet._id !== payload)]
      if(!temp[1].length) return acc;
      acc.push(temp)
      return acc
    },[])

    return {
    ...state,
    dataActive: reducedData,
    isLoading: false,
    }
  },

  [worklogsPageActions.setRefreshActiveSheet]: (state, { payload }) => {
    const reducedData = state.dataActive.map( (el) => {
      const temp = [el[0], el[1].map((sheet) =>{
        if (sheet?._id != payload._id) return sheet
        return payload
      })
      ]
      return temp
    })
    return {
    ...state,
    dataActive: reducedData,
    isLoading: false,
    }
  },
  [worklogsPageActions.setRefreshCrewSheet]: (state, { payload }) => {
    const reducedData = state.dataForeman.map( (el) => {
      const temp = [el[0], el[1].map((sheet) =>{
        if (sheet?._id != payload._id) return sheet
        return payload
      })
      ]
      return temp
    })
    return {
    ...state,
    dataForeman: reducedData,
    isLoading: false,
    }
  },
  [worklogsPageActions.setRefreshSubmittedSheet]: (state, { payload }) => {
    const reducedData = state.dataSubmitted.map( (el) => {
      const temp = [el[0], el[1].map((s) =>{
        if (s?.sheetId != payload._id) return s
        return {...s, sheet: payload}
      })
      ]
      return temp
    })

    return {
    ...state,
    dataSubmitted: reducedData,
    isLoading: false,
    }
  },
  [worklogsPageActions.setDeletedCrewSheet]: (state, { payload }) => {
    const reducedData = state.dataForeman.reduce( (acc, el) => {
      const temp = [el[0], el[1].filter((sheet) => sheet._id !== payload)]
      if(!temp[1].length) return acc;
      acc.push(temp)
      return acc
    },[])

    return {
    ...state,
    dataForeman: reducedData,
    isLoading: false,
    }
  },
  [worklogsPageActions.setDeletedSubmittedSheet]: (state, { payload }) => {
    const reducedData = state.dataSubmitted.reduce( (acc, el) => {
      const temp = [el[0], el[1].filter((sheet) => sheet._id !== payload)]
      if(!temp[1].length) return acc;
      acc.push(temp)
      return acc
    },[])

    return {
    ...state,
    dataSubmitted: reducedData,
    isLoading: false,
    }
  },

  [worklogsPageActions.filterDeletedSubmittedSheet]: (state, { payload }) => {
    const reducedData = state.dataSubmitted.reduce( (acc, el) => {
      const temp = [el[0], el[1].filter((sheet) => sheet.sheet._id !== payload)]
      if(!temp[1].length) return acc;
      acc.push(temp)
      return acc
    },[])

    return {
    ...state,
    dataSubmitted: reducedData,
    isLoading: false,
    }
  },
  
  [worklogsPageActions.changeArchivedActiveState]: (state, { payload }) => ({
    ...state,
    dataActive: state.dataActive.map((el) => [el[0], el[1].map((sheet) => sheet._id !== payload ? sheet : {...sheet, archived: !sheet.archived})]),
    isLoading: false,
  }),
  [worklogsPageActions.changeArchivedCrewState]: (state, { payload }) => ({
    ...state,
    dataForeman: state.dataForeman.map((el) => [el[0], el[1].map((sheet) => sheet._id !== payload ? sheet : {...sheet, archived: !sheet.archived})]),
    isLoading: false,
  }),

  [worklogsPageActions.setError]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  
  [worklogsPageActions.setForceUpdate]: (state, { payload }) => {
    return { ...state, forceUpdate: payload, isLoading: false };
  },

  [worklogsPageActions.setCertGenerates]: (state, { payload }) => ({ ...state, submissionCertGenerates: payload })
});

export default worklogs;
