import { userTypes } from './userTypes';

export const changePermission = (userRole, permissions, permission) => {
  if (permission === 'kioskModeMobile' || permission === 'remoteKiosk') {
    if (
      (permission === 'remoteKiosk' && permissions[permission]) ||
      (permission === 'kioskModeMobile' && !permissions[permission])
    ) {
      const value = permission === 'kioskModeMobile' && !permissions[permission];
      return {
        ...permissions,
        remoteKiosk: value,
        kioskModeMobile: value,
      };
    }
    return { ...permissions, [permission]: !permissions[permission] };
  }

  switch (userRole) {
    case userTypes.accounting: {
      if (
        (permission === 'availabilityFullAccess' && permissions[permission]) ||
        (permission === 'editPTO' && !permissions[permission])
      ) {
        const value = permission === 'editPTO' && !permissions[permission];
        return {
          ...permissions,
          availabilityFullAccess: value,
          editPTO: value,
        };
      }

      if (
        (permission === 'projectsRead' && permissions[permission]) ||
        (permission === 'projectsFullAccess' && !permissions[permission])
      ) {
        const value = permission === 'projectsFullAccess' && !permissions[permission];
        return {
          ...permissions,
          projectsRead: value,
          projectsFullAccess: value,
        };
      }

      return { ...permissions, [permission]: !permissions[permission] };
    }
    case userTypes.fleetMaintenance: {
      if (
        (permission === 'availabilityRead' && permissions[permission]) ||
        (permission === 'availabilityEquipmentEdit' && !permissions[permission])
      ) {
        const value = permission === 'availabilityEquipmentEdit' && !permissions[permission];
        return {
          ...permissions,
          availabilityEquipmentEdit: value,
          availabilityRead: value,
        };
      }

      return { ...permissions, [permission]: !permissions[permission] };
    }
    case userTypes.projectManagement: {
      if (
        (permission === 'worklogsEdit' && permissions[permission]) ||
        (permission === 'worklogsEditSubmitted' && !permissions[permission])
      ) {
        const value = permission === 'worklogsEditSubmitted' && !permissions[permission];
        return {
          ...permissions,
          worklogsEdit: value,
          worklogsEditSubmitted: value,
        };
      }

      return { ...permissions, [permission]: !permissions[permission] };
    }
    default: {
      return { ...permissions, [permission]: !permissions[permission] };
    }
  }
};
