import formatNumber from 'pages/TimeClock/utils/formatNumber';
import hoursMinutesToNumber from 'pages/TimeClock/utils/hoursMinutesToNumber';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';

export const JobBlock = ({ day }) => {
  const navigate = useNavigate();
  const getTimeFormatHook = useTimeSettingsFormat();
  const formatForTimePars = getTimeFormatHook().formatForTimePars;

  return (
    <>
      {day?.jobs.map((job) => {
        return (
          <>
            <tr class="header-row border-top">
              <td>Job #</td>
              <td>T&M</td>
              <td>Description</td>
              <td>Certified Payroll</td>
              <td>County</td>
              <td>State</td>
              <td>Travel Time</td>
              <td>On Job Time</td>
              <td>End</td>
              <td>Hours</td>
            </tr>
            {job.sheet ? (
              <>
                <tr>
                  <td class="">{job.sheet.project.jobNumber}</td>
                  <td class="timeclock-force-account">{job.sheet.forceAccount}</td>
                  <td class="timeclock-table-description">
                    <a href={`/worklog/submissions/${job.sheet._id}`}>
                      {job.sheet.project.contractor.name} - {job.sheet.project.route}
                      {job.sheet.section ? ' - ' + job.sheet.section : null}
                    </a>
                  </td>
                  <td>{job.sheet.project.rated ? 'Yes' : 'No'}</td>
                  <td>{job.sheet.project.county}</td>
                  <td>{job.sheet.project.state || 'N/A'}</td>
                  <td>
                    {' '}
                    {job.travel >= 0
                      ? formatNumber(job.travel)
                      : formatNumber(hoursMinutesToNumber(job.sheet.travelTime))}
                  </td>
                  <td>{format(new Date(job.hours.start), formatForTimePars)}</td>
                  <td class="endTime">{format(new Date(job.hours.end), formatForTimePars)}</td>
                  <td> {formatNumber(job.hours.hours)}</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td colspan="2"></td>
                  <td class="text-right" colspan="1">
                    <strong>Travel Time:</strong>
                  </td>
                  <td>{day.travelTime}</td>
                  <td></td>
                  <td></td>
                  <td class="text-right" colspan="1">
                    <strong>Job Time:</strong>
                  </td>
                  <td>{day.totalJobTime}</td>
                </tr>
              </>
            ) : (
              <tr>
                <td>No Jobs</td>
              </tr>
            )}
          </>
        );
      })}
    </>
  );
};
