import { getProjectName } from 'helpers/_helpers';

export const createWorklogName = (sheet, shorter = false) => {
  const length = shorter ? 29 : 57;
  const projectName = getProjectName(sheet.project);
  const projectCounty = sheet.project.county ? `, ${sheet.project.county}` : '';
  const worklogName = projectName + projectCounty;
  return worklogName.length > length
    ? worklogName.slice(0, length - 4).trim() + '...'
    : worklogName;
};
