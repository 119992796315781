import { getOptions } from 'helpers/getOptions';

export const getSettings = async ({} = { settingName: 'defaultSettings' }) => {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/settings`, {
    ...getOptions(),
    method: 'GET',
  }).then((res) => res.json());

  if (response.statusCode > 299) throw new Error(response.message);
  return response;
};
