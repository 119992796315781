import { withStyles } from '@material-ui/core/styles';
import cn from 'classnames';
import styles from '../../styles';
import { getEquipmentColorClass } from 'helpers/_helpers';

const EquipmentCalendarEventsList = ({ classes, calendarEvents }) => {

  return (
    <div className={classes.userWrapper}>
      {calendarEvents?.map((calendarEvent, i) => {
        return (
          <div className={classes.timeoffWrapper}>
            <div
              className={cn(
                classes.equipmentBlock,
                getEquipmentColorClass(calendarEvent.equipment.color)
              )}
              style={{ backgroundColor: calendarEvent.equipment.color }}
            >
              <div className={classes.equipmentNumber}>{calendarEvent.equipment.name}</div>
            </div>

          </div>
        );
      })}
    </div>
  );
};
export default withStyles(styles)(EquipmentCalendarEventsList);
