import React, { Component } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import InputAdornment from '@material-ui/core/InputAdornment';
import { shortDate, getDateFromHours } from 'helpers/_date-helpers';
import styles from './styles';
import { isIOS, Option } from '../../../Option';
import { generateIncrement } from 'helpers/generateIncrement';
import { generateHoursObject, generateTravelTimeHours, isSelectedTimeValid } from 'helpers/helpers';
import _ from 'lodash';
import { connect } from 'react-redux';
import { getTimeFormat, DAY_PART } from 'common/dateFormatConfig';
import { generateIncrementDateCreator } from 'hooks/useTimeStates';

class HoursEquipBlock extends Component {
  state = {
    days: ['-1', '0', '1'],
    hours: generateIncrement(
      generateIncrementDateCreator({ maxValue: 12, step: 1, start: 1 }, this.props.timeFormat)
    ),
    minutes: generateIncrement({ maxValue: 60, step: 5, padMaxLength: 2 }),
    amPm: DAY_PART,
    values: {
      dayStart: '0',
      hourStart: '1',
      minuteStart: '00',
      amPmStart: DAY_PART[0],
      dayEnd: '0',
      hourEnd: '1',
      minuteEnd: '00',
      amPmEnd: DAY_PART[0],
      isShowHours: false,
    },
    isValid: true,
    errors: [],
    originValues: null,
  };

  componentDidMount() {
    const { sheet, itemHours } = this.props;
    const getTimeFormatHook = (hourObj) =>
      getTimeFormat(hourObj, this.props.timeFormat, this.props.dateFormat);
    const hourObj = sheet.travelTimeOnly
      ? {}
      : generateHoursObject(itemHours?.start, itemHours?.end, sheet.createdAt, getTimeFormatHook);

    this.setState((prevState) => ({
      values: {
        ...prevState.values,
        ...hourObj,
        isShowHours: !itemHours.isWlHours,
      },
      originValues: { ...prevState.values, ...hourObj },
    }));
  }

  componentDidUpdate(prevProps) {
    if (_.isEqual(this.props, prevProps)) return;

    this.setState((prevState) => ({
      values: {
        ...prevState.values,
        isShowHours: !this.props?.itemHours?.isWlHours,
      },
    }));

  }

  handleChange = (field) => (e) => {
    e.persist();
    const { updateResourceHours, sheet } = this.props;

    const getTimeFormatHook = (hourObj) =>
      getTimeFormat(hourObj, this.props.timeFormat, this.props.dateFormat);
    this.setState(
      (prevState) => ({
        values: { ...prevState.values, [field]: e.target.value },
      }),
      () => {
        const { isValid, errorsOnFields } = isSelectedTimeValid(
          this.state.values,
          getTimeFormatHook,
          this.props.dateFormat,
        );

        this.setState({ isValid, errors: errorsOnFields });
        const { start, end } = getDateFromHours(
          this.state.values,
          sheet.createdAt,
          getTimeFormatHook
        );
        const updatedHours = {
          start,
          end,
        };
        if (!isValid) {
          updatedHours.error = true;
        } else {
          delete updatedHours.error;
        }
        updateResourceHours(updatedHours);
      }
    );
  };

  render() {
    const {
      classes,
      sheet,
    } = this.props;
    const { days, hours, minutes, amPm, values, errors } = this.state;
    const canEdit = this.props.canEdit === undefined ? true : this.props.canEdit;

    return (
      <div>
        {!sheet?.travelTimeOnly && values.isShowHours && (
          <div className={classes.flexBox}>
            <div style={{ width: '100%' }}>
              <div
                className={classnames(
                  classes.inlineFlex,
                  classes.rowWrapper,
                  classes.startTimeWrapper
                )}
              >
                <Typography
                  variant="body1"
                  color="textSecondary"
                  className={classnames(classes.regularFontWeight, classes.paddingBottom)}
                >
                  On Job Time:
                </Typography>
                <div className={classes.inlineFlex}>
                  <TextField
                    select
                    className={classnames(classes.textField, classes.dateField)}
                    value={values.dayStart}
                    onChange={this.handleChange('dayStart')}
                    error={errors && errors.includes('dayStart')}
                    variant="outlined"
                    fullWidth
                    disabled={!canEdit}
                    SelectProps={{
                      native: isIOS,
                    }}
                  >
                    {days.map((value, index) => (
                      <Option key={index} value={value}>
                        {shortDate(
                          sheet.createdAt,
                          value,
                          getTimeFormat([], this.props.timeFormat, this.props.dateFormat)
                            .localDayMonth
                        )}
                      </Option>
                    ))}
                  </TextField>
                  <TextField
                    select
                    className={classes.textField}
                    value={values.hourStart}
                    onChange={this.handleChange('hourStart')}
                    error={errors && errors.includes('hourStart')}
                    variant="outlined"
                    fullWidth
                    disabled={!canEdit}
                    SelectProps={{
                      native: isIOS,
                    }}
                  >
                    {hours.map((value, index) => (
                      <Option key={index} value={value}>
                        {value}
                      </Option>
                    ))}
                  </TextField>
                  :
                  <TextField
                    select
                    className={classes.textField}
                    value={values.minuteStart}
                    onChange={this.handleChange('minuteStart')}
                    error={errors && errors.includes('minuteStart')}
                    variant="outlined"
                    fullWidth
                    disabled={!canEdit}
                    SelectProps={{
                      native: isIOS,
                    }}
                  >
                    {minutes.map((value, index) => (
                      <Option key={index} value={value}>
                        {value}
                      </Option>
                    ))}
                  </TextField>
                  {getTimeFormat([], this.props.timeFormat, this.props.dateFormat).is12Format && (
                    <TextField
                      select
                      className={classes.textField}
                      value={values.amPmStart}
                      onChange={this.handleChange('amPmStart')}
                      error={errors && errors.includes('amPmStart')}
                      variant="outlined"
                      fullWidth
                      disabled={!canEdit}
                      SelectProps={{
                        native: isIOS,
                      }}
                    >
                      {amPm.map((value, index) => (
                        <Option key={index} value={value}>
                          {value.toUpperCase()}
                        </Option>
                      ))}
                    </TextField>
                  )}
                </div>
              </div>
              <div className={classnames(classes.inlineFlex, classes.rowWrapper)}>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  className={classnames(
                    classes.regularFontWeight,
                    classes.paddingBottom,
                    classes.mobileHidden
                  )}
                >
                  &nbsp;
                </Typography>
                <div className={classes.inlineFlex}>
                  <TextField
                    select
                    className={classnames(classes.textField, classes.dateField)}
                    value={values.dayEnd}
                    onChange={this.handleChange('dayEnd')}
                    error={errors && errors.includes('dayEnd')}
                    variant="outlined"
                    fullWidth
                    disabled={!canEdit}
                    SelectProps={{
                      native: isIOS,
                    }}
                  >
                    {days.map((value, index) => (
                      <Option key={index} value={value}>
                        {shortDate(
                          sheet.createdAt,
                          value,
                          getTimeFormat([], this.props.timeFormat, this.props.dateFormat)
                            .localDayMonth
                        )}
                      </Option>
                    ))}
                  </TextField>
                  <TextField
                    select
                    className={classes.textField}
                    value={values.hourEnd}
                    onChange={this.handleChange('hourEnd')}
                    error={errors && errors.includes('hourEnd')}
                    variant="outlined"
                    fullWidth
                    disabled={!canEdit}
                    SelectProps={{
                      native: isIOS,
                    }}
                  >
                    {hours.map((value, index) => (
                      <Option key={index} value={value}>
                        {value}
                      </Option>
                    ))}
                  </TextField>
                  :
                  <TextField
                    select
                    className={classes.textField}
                    value={values.minuteEnd}
                    onChange={this.handleChange('minuteEnd')}
                    error={errors && errors.includes('minuteEnd')}
                    variant="outlined"
                    fullWidth
                    disabled={!canEdit}
                    SelectProps={{
                      native: isIOS,
                    }}
                  >
                    {minutes.map((value, index) => (
                      <Option key={index} value={value}>
                        {value}
                      </Option>
                    ))}
                  </TextField>
                  {getTimeFormat([], this.props.timeFormat, this.props.dateFormat).is12Format && (
                    <TextField
                      select
                      className={classes.textField}
                      value={values.amPmEnd}
                      onChange={this.handleChange('amPmEnd')}
                      error={errors && errors.includes('amPmEnd')}
                      variant="outlined"
                      fullWidth
                      disabled={!canEdit}
                      SelectProps={{
                        native: isIOS,
                      }}
                    >
                      {amPm.map((value, index) => (
                        <Option key={index} value={value}>
                          {value.toUpperCase()}
                        </Option>
                      ))}
                    </TextField>
                  )}
                </div>
              </div>
            </div>

            <div className={classes.inlineFlex}>
              {canEdit && (
                <IconButton
                  className={classnames(
                    classes.deleteButton,
                    classes.individualDeleteButton,
                  )}
                  onClick={this.props.deleteTime}
                  aria-label="Delete"
                  disableTouchRipple
                >
                  <CloseIcon />
                </IconButton>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

HoursEquipBlock.propTypes = {
  classes: propTypes.object.isRequired,
  itemHours: propTypes.object.isRequired,
  sheet: propTypes.object.isRequired,
  deleteTime: propTypes.func.isRequired,
  updateResourceHours: propTypes.func.isRequired,
  isWorkersType: propTypes.bool,
};

export default connect((state) => ({
  dateFormat: state.personalProfile.organization?.settings?.dateFormat,
  timeFormat: state.personalProfile.organization?.settings?.timeFormat,
}))(withStyles(styles)(HoursEquipBlock));
