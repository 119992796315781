import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import classnames from 'classnames';
import { format, parse } from 'date-fns';
import { useDispatch } from 'react-redux';
import EditIcon from 'assets/images/edit-icon.svg';
import DuplicateI from 'assets/images/duplicate.svg';
import LocationIcon from 'assets/images/location-icon.svg';
import userPhoto from 'assets/icons/user-photo-placeholder.svg';
import { getProjectName, hasPermissionsFor } from 'helpers/_helpers';
import { agoDate } from 'helpers/_date-helpers';
import QualityControlActions from '../QualityControlActions/QualityControlActions';
import { useWorklogStyles } from '../../hooks';
import PrintIcon from 'assets/images/print-icon.svg';
import { useParams } from 'react-router-dom';
import { fetchProjects } from 'store/projectTypes/projectTypesOperations';
import { fetchAllContractorsData } from 'store/contractors/contractorsPageOperations';
import projectTypesActions from 'store/projectTypes/projectTypesActions';
import { SheetsContacts } from './components/SheetsContacts/SheetsContacts';
import OptionalModal from 'components/OptionalModal/OptionalModal';
import { globalBEDateFormat, GLOBAL_DATE_FORMAT } from 'common/dateFormatConfig';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';

const options = {
  title: 'Do you want to include image(s) to the print?',
  text: '',
  trueOption: 'Yes',
  falseOption: 'No',
};

const GeneralInfoBlock = ({
  hasAccess,
  sheet,
  user,
  close,
  openSnackbar,
  openAdditionalDrawer,
  setDuplicateModal,
}) => {
  const worklogClasses = useWorklogStyles();
  const dispatch = useDispatch();
  const { type } = useParams();
  const { dayDateSettingsFormat } = useDateSettingsFormat();

  const [printModal, setPrintModal] = useState(false);

  const openPrintPage = (query) => {
    const path = type == 'worklog-foreman' ? '/isCrew' : '';
    const url = `/worklog/print/${sheet._id}${path}${query === 'true' ? '?printImg=true' : ''}`;
    window.open(url, '_blank', 'noreferrer');
  };

  useEffect(() => {
    dispatch(fetchProjects({}, NaN, null, { id: sheet._id }));
    dispatch(fetchAllContractorsData({ id: sheet._id }));
    return () => dispatch(projectTypesActions.resetProjects());
  }, []);

  const getSheetOwner = () => {
    return sheet.ownerUsername ? `by ${sheet.ownerUsername}` : null;
  };

  const getSheetStatus = () => {
    const { published, submittedAt, canceledAt } = sheet;
    if (!published) return 'Draft';
    if (submittedAt) {
      return `Submitted ${agoDate(submittedAt, null, dayDateSettingsFormat)}`;
    }
    return canceledAt ? 'Canceled' : 'Published';
  };

  const getImageSrc = (public_id) => {
    return userPhoto;
  };

  const formatGridDate = (date) =>
    format(parse(date, globalBEDateFormat, new Date()), dayDateSettingsFormat);

  const sheetLocation = (sheet, isStandalone, locationId, fieldName) => {
    if (!sheet.project) return '';
    const allPlaces =
      fieldName === 'sheetRallyPoints'
        ? [...(sheet?.sheetRallyPoints || []), ...(sheet.project?.rallyPoints || [])]
        : [...(sheet?.sheetLocations || []), ...(sheet.project?.locations || [])];
    const location = allPlaces.find((l) => l._id === locationId);
    if (isStandalone) {
      if (!location) return '';
      const locationZip = location.zipcode ? `, ${location.zipcode}` : '';
      return location.name || `${location.address}${locationZip}`;
    }
    const nameOrAddress = location.name || location.address;
    return location && nameOrAddress ? ', ' + nameOrAddress : '';
  };

  const openLocation = (locationId, fieldName) => () => {
    const allPlaces =
      fieldName === 'sheetRallyPoints'
        ? [...(sheet?.sheetRallyPoints || []), ...(sheet.project?.rallyPoints || [])]
        : [...(sheet?.sheetLocations || []), ...(sheet.project?.locations || [])];
    const location = allPlaces.find((l) => l._id === locationId);
    const url = `https://www.google.com/maps/place/${location.address}`;
    window.open(url, '_blank', 'noreferrer');
  };
  const sheetHasLocation = sheet.locations && sheet.locations.length && !!sheet.locations[0];

  const duplicatePermissions =
    hasPermissionsFor('scheduleEdit') || hasPermissionsFor('scheduleFullAccess');
  const duplicateRule = !sheet.canceledAt && duplicatePermissions;

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h4" className={worklogClasses.header}>
          {getProjectName(sheet.project)}
          {sheet.project.county ? `, ${sheet.project.county}` : ''}

          {sheet.project.jobNumber && (
            <span className={worklogClasses.jobNumber}>#{sheet.project.jobNumber}</span>
          )}
        </Typography>

        <IconButton
          className={worklogClasses.closeButton}
          onClick={close}
          aria-label="Close"
          disableRipple
        >
          <CloseIcon />
        </IconButton>

        <div className={worklogClasses.headerButtonsWrapper}>
          {hasAccess && duplicateRule && (
            <Button
              variant="text"
              className={classnames(
                worklogClasses.primaryTextButton,
                worklogClasses.marginRightBig
              )}
              onClick={() => setDuplicateModal((p) => ({ ...p, isOpen: true }))}
              disableRipple
            >
              <Tooltip title="Duplicate">
                <img src={DuplicateI} alt="duplicate" />
              </Tooltip>
            </Button>
          )}
          <Button
            variant="text"
            className={classnames(worklogClasses.primaryTextButton, worklogClasses.marginRightBig)}
            disableRipple
          >
            <a
              className={classnames(worklogClasses.primaryTextButton)}
              onClick={() => {
                if (sheet?.images?.length) {
                  setPrintModal(true);
                } else {
                  openPrintPage(null); //print without img
                }
              }}
              // href={openPrintPage()}
              // target="_blank"
            >
              <Tooltip title="Print">
                <img src={PrintIcon} alt="print" />
              </Tooltip>
            </a>
          </Button>
          {hasAccess && (
            <Button
              variant="text"
              className={worklogClasses.primaryTextButton}
              onClick={openAdditionalDrawer('general')}
              disableRipple
            >
              <Tooltip title="Edit">
                <img src={EditIcon} alt="edit" />
              </Tooltip>
            </Button>
          )}
        </div>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classnames(worklogClasses.regularFontWeight, worklogClasses.marginBottom)}
        >
          Created on {agoDate(sheet.createdAtDate, null, dayDateSettingsFormat)} {getSheetOwner()}
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classnames(worklogClasses.mediumFontWeight, worklogClasses.yellowText)}
        >
          {getSheetStatus()}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} md={9}>
            <div className={worklogClasses.flexBox}>
              <div className={worklogClasses.photoWrapper}>
                <img
                  src={getImageSrc(sheet.project.contractor && sheet.project.contractor.logo)}
                  className={worklogClasses.photo}
                  alt="logo"
                />
              </div>
              <Typography variant="body1" color="textPrimary" className={worklogClasses.name}>
                {sheet.project.contractor && sheet.project.contractor.name}
              </Typography>
              {sheet.forceAccount && sheet.forceAccount !== 'No' && (
                <div className={worklogClasses.flexWrapper}>
                  <Typography variant="body2" className={worklogClasses.forceAccount}>
                    T&M: {sheet.forceAccount}
                  </Typography>
                </div>
              )}
            </div>
          </Grid>
          <Grid item xs={12} md={3}>
            <QualityControlActions sheet={sheet} user={user} openSnackbar={openSnackbar} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={6} md={4}>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classnames(worklogClasses.regularFontWeight, worklogClasses.paddingBottom)}
        >
          Date:
        </Typography>
        <Typography variant="body1" color="textPrimary">
          {agoDate(sheet.createdAt, null, dayDateSettingsFormat)}
        </Typography>
      </Grid>
      <Grid item xs={6} md={4}>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classnames(worklogClasses.regularFontWeight, worklogClasses.paddingBottom)}
        >
          Grid View Date:
        </Typography>
        <Typography variant="body1" color="textPrimary">
          {formatGridDate(sheet.grid.date)}
        </Typography>
      </Grid>
      {!!sheet.projectId && (
        <Grid item xs={6} md={4}>
          <Typography
            variant="body1"
            color="textSecondary"
            className={classnames(worklogClasses.regularFontWeight, worklogClasses.paddingBottom)}
          >
            Project ID:
          </Typography>
          <Typography variant="body1" color="textPrimary">
            {(sheet.project && sheet.project.id) || 'N/A'}
          </Typography>
        </Grid>
      )}

      {!!sheet.rallyPoints?.length ? (
        sheet.rallyPoints.map((locationId, index) => (
          <Grid item xs={12} md={6} key={`sheetLocation_${locationId}_${index}`}>
            <Typography
              variant="body1"
              color="textSecondary"
              className={classnames(worklogClasses.regularFontWeight, worklogClasses.paddingBottom)}
            >
              Rally Point{sheet.rallyPoints.length === 1 ? null : ` ${index + 1}`}:
            </Typography>
            <Typography variant="body1" color="textPrimary" className={worklogClasses.locationFlex}>
              {sheetLocation(sheet, true, locationId, 'sheetRallyPoints')}
              {
                <img
                  src={LocationIcon}
                  alt="location"
                  onClick={openLocation(locationId, 'sheetRallyPoints')}
                />
              }
            </Typography>
          </Grid>
        ))
      ) : (
        <Grid item xs={12} md={6}>
          <Typography
            variant="body1"
            color="textSecondary"
            className={classnames(worklogClasses.regularFontWeight, worklogClasses.paddingBottom)}
          >
            Rally Point:
          </Typography>
          <Typography variant="body1" color="textPrimary" className={worklogClasses.locationFlex}>
            N/A
          </Typography>
        </Grid>
      )}

      {sheetHasLocation ? (
        sheet.locations.map((locationId, index) => (
          <Grid item xs={12} md={6} key={`sheetLocation_${locationId}_${index}`}>
            <Typography
              variant="body1"
              color="textSecondary"
              className={classnames(worklogClasses.regularFontWeight, worklogClasses.paddingBottom)}
            >
              Project Location{sheet.locations.length === 1 ? null : ` ${index + 1}`}:
            </Typography>
            <Typography variant="body1" color="textPrimary" className={worklogClasses.locationFlex}>
              {sheetLocation(sheet, true, locationId, 'sheetLocations')}
              {
                <img
                  src={LocationIcon}
                  alt="location"
                  onClick={openLocation(locationId, 'sheetLocations')}
                />
              }
            </Typography>
          </Grid>
        ))
      ) : (
        <Grid item xs={12} md={6}>
          <Typography
            variant="body1"
            color="textSecondary"
            className={classnames(worklogClasses.regularFontWeight, worklogClasses.paddingBottom)}
          >
            Project Location:
          </Typography>
          <Typography variant="body1" color="textPrimary" className={worklogClasses.locationFlex}>
            N/A
          </Typography>
        </Grid>
      )}

      <SheetsContacts sheet={sheet} />

      <OptionalModal
        isOpen={printModal}
        onCancel={() => {
          setPrintModal(false);
        }}
        onSave={(query) => {
          openPrintPage(query);
          setPrintModal(false);
        }}
        options={options}
      />
    </>
  );
};

GeneralInfoBlock.propTypes = {
  sheet: propTypes.object.isRequired,
  hasAccess: propTypes.bool.isRequired,
  close: propTypes.func.isRequired,
  openSnackbar: propTypes.func.isRequired,
  openAdditionalDrawer: propTypes.func.isRequired,
};

export default GeneralInfoBlock;
