import { withStyles, Typography } from '@material-ui/core';
import BaseExportForm from './components/ExportForm';
import { DialogTitle as BaseDialogTitle, Dialog as BaseDialog } from 'pages/TimeClock/modals/base/styled';
// import { DialogTitle as BaseDialogTitle, Dialog as BaseDialog } from './../base/styled';

export const Dialog = withStyles({
  paper: {
    overflowY: 'visible',
  },
})(BaseDialog);

export const DialogTitle = withStyles({
  root: {
    marginBottom: 12,
  },
})(BaseDialogTitle);

export const DialogDescription = withStyles({
  body1: {
    color: '#A4A5B3',
    fontFamily: 'Open Sans',
    fontSize: 14,
    lineHeight: '19px',
    marginBottom: 22,
  },
})(Typography);

export const Day = withStyles({
  body1: {
    color: '#202020',
    fontSize: 14,
    letterSpacing: '0.17px',
    lineHeight: '21px',
    marginBottom: 34,
  },
})(Typography);

export const ExportForm = withStyles({})(BaseExportForm);
