import React, { useCallback, useState } from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Divider, TextField, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ResourcesList from './components/ResourcesList';
import styles from './styles';
import arrowLeft from 'assets/images/arrowLeftBtn.svg';
import arrowRight from 'assets/images/arrowRightBtn.svg';

const ResourcesSidebar = ({ classes, isShow, onChange, isAdminMobile }) => {
  const [searchValue, setSearchValue] = useState('');
  const [toggle, setToggle] = useState(isShow);
  const handleChange = () => {
    onChange();
    setToggle(!toggle);
  };

  const handleSearchChange = useCallback((e) => setSearchValue(e.target.value), []);

  return (
    <div
      className={classnames(
        isAdminMobile ? classes.mobileAdminRoot : classes.root,
        toggle && classes.rootHidden
      )}
    >
      <div className={classes.searchWrapper}>
        {!isAdminMobile && (
          <div className={classes.toogleButton} onClick={handleChange}>
            {toggle ? <img src={arrowRight} /> : <img src={arrowLeft} />}
          </div>
        )}
        <TextField
          type="text"
          name="searchValue"
          variant="outlined"
          placeholder="Search"
          value={searchValue}
          onChange={handleSearchChange}
          className={classes.search}
          inputProps={{ 'aria-label': 'Search' }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
              </InputAdornment>
            ),
          }}
          fullWidth
        />
      </div>
      <Divider />
      <ResourcesList searchValue={searchValue} isAdminMobile={isAdminMobile}/>
    </div>
  );
};

ResourcesSidebar.propTypes = {
  classes: propTypes.object.isRequired,
};

export default withStyles(styles)(ResourcesSidebar);
