import { EQUIPMENT_EVENT } from 'subscriptions/events/events.enum';
import { subscriptionsFabric } from 'subscriptions/subscriptions.fabric';
import currentWorklogActions from 'store/currentWorklog/actions';


const handlers = {
  [EQUIPMENT_EVENT.updated]: (store) => (data) => {
    if (!data._id) return;
    
    const currentWorklog = store.getState().currentWorklog.worklogSheet?.sheet;

    if (currentWorklog?._id && currentWorklog?.equipment?.some(equip => equip._id === data._id)) {
      store.dispatch(currentWorklogActions.updateEquipment(data));
    }
  },
};

export const currentWorklogEquipmentSubscriptionMiddleware = subscriptionsFabric(
  currentWorklogActions,
  EQUIPMENT_EVENT,
  handlers
);
