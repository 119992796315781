import React, { useState, memo } from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CloseIcon from 'assets/images/close-icon.svg'
import GreyUserI from 'assets/images/user-photo-placeholder.svg';

import PageLoader from 'components/PageLoader';
import IOSSwitch from 'components/IOSSwitch';
import ConfirmDialog from 'components/ConfirmDialog';
import styles from './styles';
import { drawerTypes } from '../../../../../../../Equipment/components/helpers/drawerTypes';
import { patchContractorsStatus } from 'store/contractors/contractorsPageOperations';
import { useDispatch } from 'react-redux';
import { fetchDeleteContractors } from '../../../../../../../../store/contractors/contractorsPageOperations';

const ContractorCard = memo(({classes, contractor, openDrawer, hasFullAccess, openSnackbar})=> {

  const dispatch = useDispatch()

  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleChangeActiveState = (e, value) => {
    dispatch(patchContractorsStatus({active:value},contractor._id))
  };

  const closeModal = () => {
    setIsModalOpen(false)
  };

  const deleteContractor = async () => {
    await dispatch(fetchDeleteContractors(contractor._id))
    openSnackbar('success', 'Successfully Deleted!')
  };

  const openDeleteModal = (e) => {
    e.stopPropagation();
    setIsModalOpen(true)
  };

  const preventOpenDrawer = (e) => e.stopPropagation();


    return (
      <>
        <PageLoader loading={false} small>
          <Card
            className={classes.card}
            onClick={hasFullAccess ? openDrawer(drawerTypes.update, contractor) : null}
          >
            <Grid container alignItems="center" className={classes.paddingBox}>
              <Grid item xs={12} className={classes.paddingBottom}>
                <div className={classes.flexHeader}>
                  <div className={classnames(classes.flexBox, classes.fixedWidth)}>
                    <div className={classes.photoWrapper}>
                      <img src={GreyUserI} className={classes.photo} />
                    </div>
                    <Typography variant="body1" color="textPrimary" className={classes.name}>
                      {contractor.name}
                    </Typography>
                  </div>
                  {hasFullAccess && (
                    <Button
                      color="secondary"
                      className={classes.deleteButton}
                      onClick={openDeleteModal}
                      disableRipple
                    >
                      <img src={CloseIcon} alt="delete" />
                    </Button>
                  )}
                </div>
              </Grid>
              {/* <Grid item xs={12} className={classes.paddingBottom}>
                <div className={classes.inlineFlex}>
                  <span className={classnames(classes.secondaryText, classes.displayBlock)}>Active</span>
                  <IOSSwitch
                    name="isActive"
                    checked={contractor.active}
                    onChange={handleChangeActiveState}
                    onClick={preventOpenDrawer}
                    value={contractor.active}
                    disabled={!hasFullAccess}
                    small
                  />
                </div>
              </Grid> */}

              {contractor.nickname && (
                <Grid item xs={12} className={classes.paddingBottom}>
                  <Divider className={classes.marginBottom} />
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    className={classnames(classes.regularFontWeight, classes.paddingBottomSmall)}
                  >
                    Nickname:
                  </Typography>
                  <Typography variant="body1" color="textPrimary">
                    {contractor.nickname}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Card>
        </PageLoader>

        {isModalOpen && (
          <ConfirmDialog
            isOpen={isModalOpen}
            onClose={closeModal}
            onSubmit={deleteContractor}
            text="Are you sure you want to permanently delete this contractor?"
            disableEscape
            loadingOnSubmit
          />
        )}
      </>
    );
},(prev, next)=>{
  return next.contractor == prev.contractor
})

ContractorCard.propTypes = {
  classes: propTypes.object.isRequired,
  contractor: propTypes.object.isRequired,
  openDrawer: propTypes.func.isRequired,
};

export default withStyles(styles)(ContractorCard);
