export default (theme) => ({
  allUsersWrapper: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Poppins',
    fontSize: '14px',
    maxHeight: 180,
    // overflow: 'scroll',
    paddingRight: 30,
  },
  timeoffWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
    justifyContent: 'space-between',
  },
  dateItem: {
    width: 97,
    height: 26,
    background: '#E4E4EB',
    borderRadius: 26,
    display: 'inline-block',
    fontSize:14,
    fontFamily: 'Poppins',
    textAlign: 'center',
    lineHeight: 1.9,
    marginBottom: 15,
  },
  userWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  userName: {
    flexBasis: '25%',
    display: 'flex',
    alignItems: 'center',
    color: '#DFA400',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '21px',
  },
  timeoffDate: {
    flexBasis: '45%',
    textAlign: 'right',
    color: '#606060',
  },
  timeoffStatus: {
    flexBasis: '30%',
    textAlign: 'center',
  },
  userPhotoWrapper: {
    width: 22,
    height: 22,
    marginRight: 10,
  },
  userPhoto: {
    border: '1.3px solid #D14646',
    borderRadius: '50%',
  },
  statusBtn: {
    width: 90,
    height: 22,
    borderRadius: 13,
    marginLeft: 'auto',
    lineHeight: 1.4,
    fontFamily: 'Open Sans',
  },
  Requested: {
    background: '#DDE7FE',
    color: '#5A87EF',
  },
  Approved: {
    background: '#E0FFE8',
    color: '#0C842B',
  },
  equipmentBlock: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '17px',
    width: 'max-content',
    padding: '3px 6px',
    color: '#ffff'
  },
});
