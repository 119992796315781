import React, {memo} from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import ProjectNoteCard from './components/ProjectNoteCard';
import WorklogNoteCard from './components/WorklogNoteCard';
import styles from './styles';

const NotesCards = memo(({notes, worklogNotes, deleteNote, restoreNote, openDrawer, setNoteIdToResolve, handleNote, openImagesDrawer }) => {
    return notes?.length ? (
      <Grid container spacing={2} justifyContent="flex-start">
        {notes.map((note) => (
          <Grid key={note._id} item xs={12} lg={3} xl="auto">
            {worklogNotes ? (
              <WorklogNoteCard note={note} openDrawer={openDrawer} handleNote={handleNote} openImagesDrawer={openImagesDrawer}/>
            ) : (
              <ProjectNoteCard
                note={note}
                openDrawer={openDrawer}
                deleteNote={deleteNote}
                restoreNote={restoreNote}
                setNoteIdToResolve={setNoteIdToResolve}
              />
            )}
          </Grid>
        ))}
      </Grid>
    ) : (
      <Typography variant="h3" align="center" style={{ margin: '1.72em 0 2em', opacity: 0.5 }}>
        No Results
      </Typography>
    );
})

NotesCards.propTypes = {
  classes: propTypes.object.isRequired,
  notes: propTypes.array.isRequired,
  openDrawer: propTypes.func.isRequired,
  deleteNote: propTypes.func,
  worklogNotes: propTypes.bool,
  restoreNote: propTypes.func,
  setNoteIdToResolve: propTypes.func,
  handleNote: propTypes.func,
};

export default withStyles(styles)(NotesCards);
