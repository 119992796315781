export const statisticBlocksMapping = [
  {
    name: 'Active Companies',
    field: 'activeClients',
    active: true,
  },
  {
    name: 'Active Users',
    field: 'activeUsers',
    active: true,
  },
  // {
  //   name: 'Monthly Billing',
  //   field: '',
  // },
  {
    name: 'Total Hours',
    field: 'totalHours',
    active: true,
  },
  {
    name: 'Monthly DWL',
    field: 'monthlyDWL',
    active: true,
  },
  {
    name: 'Jobs Scheduled',
    field: 'scheduledJobs',
    active: true,
  },
];
