export default () => ({
  root: {
    marginBottom: 50,
  },
  field: {
    marginBottom: 18,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  control: {
    width: '100% !important',
  },
});
