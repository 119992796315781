import { Button } from '@material-ui/core';
import React, { memo, useCallback } from 'react';
import propTypes from 'prop-types';
import PrintIcon from 'assets/images/print-icon.svg';

import useStyles from './useStyles';

export const PrintButton = memo(
  ({ handlePrint }) => {
    const classes = useStyles();

    const handleClick = useCallback(() => {
      handlePrint(true)();
    }, [handlePrint]);

    return (
      <Button
        variant="text"
        className={classes.printButton}
        onClick={handleClick}
        disableRipple
      >
        <img src={PrintIcon} alt="print" />
        Print
      </Button>
    );
  },
  (prevProps, nextProps) => prevProps.handlePrint === nextProps.handlePrint
);

PrintButton.propTypes = {
  handlePrint: propTypes.func.isRequired,
};
