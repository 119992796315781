export default (theme) => ({
  root: {
    padding: 20,
  },
  textCenter: {
    textAlign: 'center',
    fontSize: 27,
    '-webkit-font-smoothing': 'initial',
    [theme.breakpoints.down('sm')]: {
      fontSize: 22,
    },
  },
  worklogHeader: {
    display: 'flex',
    padding: 10,
    borderBottom: '1px solid #333',
    '& h5': {
      fontWeight: 700,
      fontSize: 16,
      [theme.breakpoints.down('sm')]: {
        fontSize: 10,
      },
    },
  },
  worklogHeaderItem: {
    flexBasis: '25%',
  },
  worklogHeaderItemC: {
    flexBasis: '25%',
    textAlign: 'center',
  },
  itemRow: {
    display: 'flex',
    lineHeight: '18px',
  },
  rowItems: {
    flexBasis: '25%',
  },
  rowItemsC: {
    flexBasis: '25%',
    textAlign: 'center',
  },
  infoBlock: {
    padding: '20px 10px',
    fontSize: 12,
    [theme.breakpoints.down('sm')]: {
      fontSize: 8,
    },
  },
});
