export default (theme) => ({
  regularFontWeight: {
    fontWeight: 300,
  },
  paddingTopSm: {
    paddingTop: 12,
  },
  zeroPaddingTop: {
    paddingTop: '0 !important',
  },
  inlineFlex: {
    display: 'inline-flex',
  },
  homepageCountdownBlock: {
    alignItems: 'center',
    minHeight: 115,
  },
  homepageCountdownWidgetBlock: {
    display: 'flex',
    columnGap: 16,
  },
  homepageDropzone: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 160,
    position: 'relative',
    border: 2,
    borderStyle: 'dashed',
    borderColor: theme.palette.borderColor.primary,
    borderRadius: 4,
    margin: '8px 0',
    [theme.breakpoints.down('sm')]: {
      height: 140,
    },
  },
  homepageDropzoneDragActive: {
    borderColor: theme.palette.borderColor.blue,
  },
  homepageDropzoneThumbWrapper: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    overflow: 'hidden',
    opacity: 0.8,
    borderRadius: 4,
    zIndex: -1,
  },
  homepageDropzoneThumb: {
    width: '100%',
    height: 'auto',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  cursorDefault: {
    cursor: 'default',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  customSelect: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: 255,
    padding: 8
  },
  colorItemWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 30,
    height: 30,
    borderRadius: '8px',
    padding: 0,
    minHeight: 30,
    margin: 2
  },
  colorItem: {
    width: 28,
    height: 28,
    borderRadius: '8px',
    textAlign: 'center'
  },
  checkedOuter: {
    border: `1px solid ${theme.palette.borderColor.blue}`
  },
  checkedInner: {
    border: `1px solid ${theme.palette.background.default}`
  },
  checkedIcon: {
    color: theme.palette.background.default,
    fontSize: '20px',
    paddingTop: 5
  },
  valueColor: {
    width: 20,
    height: 20,
    borderRadius: '5px',
    padding: 0
  },
  whiteBlock: {
    border: '0.5px solid black',
  },
  thumbGreyed: {
    opacity: 0.37,
    backgroundColor: 'rgba(0, 0, 0, 0.23)'
  },
  buttonGreyed: {
    opacity: 0.67,
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      width: '100%',
      height: '100%',
      borderRadius: '4px',
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
      cursor: 'default',
    }
  },
  delImg: {
    width: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4px',
    justifyContent: 'center',
    backgroundColor: '#e1e9fc',
    position: 'absolute',
    top: '-12px',
    right: '-12px',
    cursor: 'pointer',
    fontSize: '20px',
    '& svg': {
      scale: '0.6',
    },
  },
  uploadImg: {
    width: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4px',
    justifyContent: 'center',
    backgroundColor: '#e1e9fc',
    color: '#5a87ef',
    position: 'absolute',
    top: '-12px',
    right: '22px',
    cursor: 'pointer',
    fontSize: '20px',
    '& svg': {
      scale: '0.6',
    },
    '& img': {
      filter: 'invert(49%) sepia(17%) saturate(2435%) hue-rotate(189deg) brightness(103%) contrast(88%)',
    }
  }
});
