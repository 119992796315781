import searchIcon from './../../../assets/images/search-icon-grey.svg';

export default (theme) => ({
  root: {
    position: 'relative',
    overflow: 'visible'
  },
  options: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    top: 41,
    zIndex: 999
  },
  'options-container': {
    borderRadius: '3px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 2px 8px 0 rgba(0,0,0,0.21)',
    maxHeight: '300px',
    overflow: 'auto'
  },
  emptyOption: {
    color: '#A4A5B3',
    fontSize: 14,
    letterSpacing: '0.27px',
    lineHeight: '21px',
    padding: '15px 24px'
  },
  loaderContainer: {
    padding: '15px 24px',
    textAlign: 'center'
  },
  option: {
    padding: '12px 20px',
    borderRadius: '4px',
    backgroundColor: '#fff',
    color: '#000000',
    fontSize: '14px',
    letterSpacing: '0.17px',
    lineHeight: '21px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f4f7fe',
    },
  },
  highlighted: {
    color: '#5A87EF'
  },
  input: {
    margin: '0 !important',
    overflow: 'visible',
    padding: '10px 10px 10px 36px !important',
    height: '40px !important',
    border: '1px solid #D9DAE3 !important',
    borderRadius: '4px !important',
    backgroundColor: '#FFFFFF !important',
    color: 'rgba(0,0,0,0.75) !important',
    fontSize: '14px !important',
    lineHeight: '21px !important',
    boxShadow: 'none !important',
    backgroundImage: `url(${searchIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '10px center',
    '&:focus': {
      backgroundImage: `url(${searchIcon})`,
      backgroundRepeat: 'no-repeat !important',
      backgroundPosition: '10px center !important',
      border: '1px solid #D9DAE3 !important',
    },
    '&::placeholder': {
      color: '#A4A5B3 !important',
      fontSize: '14px !important',
      lineHeight: '21px !important',
    }
  }
})
