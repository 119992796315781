import React, { useMemo, useState } from 'react';
import useStyles from './useStyles';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import CalendarV2 from '../../CalendarV2';
import { format } from 'date-fns';
import InputBase, { Popover } from './styled';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';

export default ({ value, onChange = () => null }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { fullDayDateSettingsFormat } = useDateSettingsFormat();
  const formattedValue = useMemo(
    () => (value ? format(new Date(value), fullDayDateSettingsFormat) : '-'),
    [value]
  );

  const handleChange = (e) => {
    onChange(e);
  };

  const handleClickDay = (clickedDay) => {
    if (clickedDay) setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <InputBase
        value={formattedValue}
        endAdornment={anchorEl ? <ExpandLess /> : <ExpandMore />}
        fullWidth={true}
        readOnly={true}
        onClick={(e) => setAnchorEl((el) => (el ? null : e.currentTarget))}
      />
      <Popover
        open={!!anchorEl}
        elevation={0}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
          <CalendarV2
            value={value ? new Date(value) : new Date()}
            onChange={handleChange}
            onClickDay={handleClickDay}
            onClose={() => setAnchorEl(null)}
            maxDate={new Date()}
          />
        </ClickAwayListener>
      </Popover>
    </div>
  );
};
