import React from 'react';
import { useSelector } from 'react-redux';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import EditIcon from 'assets/images/edit-icon.svg';

import Grid from '@material-ui/core/Grid';
import classnames from 'classnames';
import styles from './styles';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { addLeadingZero } from 'helpers/helpers';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';
import { convertTimeObject } from 'common/dateFormatConfig';

const TimeBlock = ({
  classes,
  getFormattedHoursRange,
  startTime,
  hasAccess,
  openAdditionalDrawer,
  hours,
  sheet,
}) => {
  const getTimeFormatHook = useTimeSettingsFormat();
  const { hour, minute, amPm } = convertTimeObject(
    { hour: startTime.hour, minute: startTime.minute },
    getTimeFormatHook().is12Format
  );

  return (
    <>
      {!sheet.travelTimeOnly && (
        <Grid item xs={12} md={3}>
          <Typography
            variant="body1"
            color="textSecondary"
            className={classnames(classes.regularFontWeight, classes.paddingBottom)}
          >
            On Job Time:
          </Typography>
          <Typography variant="body1" color="textPrimary">
            {getFormattedHoursRange()}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} md={2}>
        <div className={classnames(classes.header, classes.paddingBottom)}>
          <Typography
            variant="body1"
            color="textSecondary"
            className={classnames(classes.regularFontWeight)}
          >
            Start Time:
          </Typography>
          {hasAccess && (
            <Button
              className={classes.editButton}
              variant="text"
              onClick={openAdditionalDrawer('time')}
              disableRipple
              disableTouchRipple
            >
              <img src={EditIcon} alt="edit time" />
            </Button>
          )}
        </div>
        <Typography variant="body1" color="textPrimary">
          {getTimeFormatHook([null, hour, minute, amPm]).formattedTime}
        </Typography>
      </Grid>
      {sheet?.rainPay &&
        sheet?.isRainPayEnabled &&
        (+sheet?.rainPay.hours > 0 || +sheet?.rainPay.minutes > 0) && (
          <Grid item xs={12} md={2}>
            <Typography
              variant="body1"
              color="textSecondary"
              className={classnames(classes.regularFontWeight, classes.paddingBottom)}
            >
              Misc. Stipend:
            </Typography>
            <Typography
              variant="body1"
              color="textPrimary"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              {addLeadingZero(sheet?.rainPay?.hours)}h {addLeadingZero(sheet?.rainPay?.minutes)}m
              <Tooltip
                title={sheet?.rainPay?.description || ''}
                placement="top"
                enterTouchDelay={0}
              >
                <InfoIcon
                  fontSize="small"
                  style={{ width: 16, height: 16, marginLeft: 5 }}
                  color="primary"
                />
              </Tooltip>
            </Typography>
          </Grid>
        )}
      <Grid item xs={12} md={3}>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classnames(classes.regularFontWeight, classes.paddingBottom)}
        >
          Travel Time To Job:
        </Typography>
        <Typography variant="body1" color="textPrimary">
          {addLeadingZero(hours?.travelTimeTo?.hours)}h{' '}
          {addLeadingZero(hours?.travelTimeTo?.minutes)}m
        </Typography>
      </Grid>
      <Grid item xs={12} md={3}>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classnames(classes.regularFontWeight, classes.paddingBottom)}
        >
          Travel Time From Job:
        </Typography>
        <Typography variant="body1" color="textPrimary">
          {addLeadingZero(hours?.travelTimeFrom?.hours)}h{' '}
          {addLeadingZero(hours?.travelTimeFrom?.minutes)}m
        </Typography>
      </Grid>
    </>
  );
};

TimeBlock.propTypes = {
  classes: propTypes.object.isRequired,
  getFormattedHoursRange: propTypes.func.isRequired,
  travelTime: propTypes.object,
  startTime: propTypes.object.isRequired,
  hasAccess: propTypes.bool.isRequired,
  openAdditionalDrawer: propTypes.func.isRequired,
};

export default withStyles(styles)(TimeBlock);
