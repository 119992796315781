export default (theme) => ({
  paper: {
    width: '100%',
  },
  fullDr: {
    '& .MuiPaper-root': {
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
  },
});
