export default theme => ({
  flexBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  leftFlexPart: {
    minWidth: 'calc(100% - 100px)',
    maxWidth: 'calc(100% - 100px)',
    '@media (max-width: 295px)': {
      minWidth: 'auto',
      maxWidth: 'auto',
    },
  },
  label: {
    fontWeight: 300,
    color: theme.palette.textColor.secondary
  },
  submitButton: {
    padding: 0,
    minWidth: 'max-content',
    color: theme.palette.textColor.primary,
    '&:hover': {
      color: theme.palette.textColor.primary,
      background: `${theme.palette.background.transparent} !important`
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
      background: `${theme.palette.background.transparent} !important`
    }
  },
  leftPart: {
    marginRight: 50,
    '@media (max-width: 295px)': {
      marginRight: 0,
    },
  },
  signIcon: {
    marginRight: 10
  },
  inlineFlex: {
    display: 'flex',
    padding: '6px 0'
  },
  signature: {
    cursor: 'pointer'
  }
});
