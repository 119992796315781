import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import { parse, format, addDays } from 'date-fns';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import PageLoader from '../PageLoader';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';
import { useSettingsDefaultTime } from 'common/useSettingsDefaultTime';
import {
  globalBEDateFormat,
  DAY_PART,
  convertTimeObject,
  convertTo24HourFormat,
} from 'common/dateFormatConfig';
import { removeCalendarEvent, splitCalendarEvent, updateCalendarEvent } from './api';
import availabilityPageActions from 'store/availability/availabilityPageActions';
import CalendarEventDetails from './components/CalendarEventDetails/CalendarEventDetails';

import styles from './styles';


const SplitCalendarEventForm = ({
  event,
  options,
  openSnackbar,
  closeDrawer,
  classes,
}) => {
  const dispatch = useDispatch();

  const equipment = useMemo(() => event.equipment, [event]);

  const { dateFormat } = useDateSettingsFormat();
  const getTimeFormatHook = useTimeSettingsFormat();
  const is12Format = getTimeFormatHook().is12Format;
  const { defaultDayTime } = useSettingsDefaultTime();

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
    watch,
    setValue,
    clearErrors,
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: {
      startDate: [
        event?.start && format(parse(event.start, globalBEDateFormat, new Date()), dateFormat),
        options.splitBy && format(addDays(parse(options.splitBy, globalBEDateFormat, new Date()), 1), dateFormat),
      ],
      endDate: [
        options.splitBy && format(parse(options.splitBy, globalBEDateFormat, new Date()), dateFormat),
        event?.end && format(parse(event.end, globalBEDateFormat, new Date()), dateFormat),
      ],
      startHours: [
        (event?.startTime?.hour && `${convertTimeObject(event?.startTime, is12Format).hour}`) ||
          `${convertTimeObject(defaultDayTime, is12Format).hour}` ||
          '8',
        (event?.startTime?.hour && `${convertTimeObject(event?.startTime, is12Format).hour}`) ||
          `${convertTimeObject(defaultDayTime, is12Format).hour}` ||
          '8',
      ],
      startMinutes: [
        (event?.startTime?.minute && `${convertTimeObject(event?.startTime, is12Format).minute}`) ||
          `${convertTimeObject(defaultDayTime, is12Format).minute}` ||
          '00',
        (event?.startTime?.minute && `${convertTimeObject(event?.startTime, is12Format).minute}`) ||
          `${convertTimeObject(defaultDayTime, is12Format).minute}` ||
          '00',
      ],
      startAmPm: [
        (event?.startTime?.hour && `${convertTimeObject(event?.startTime, is12Format).amPm}`) ||
          `${convertTimeObject(defaultDayTime, is12Format).amPm}` || 
          DAY_PART[0],
        (event?.startTime?.hour && `${convertTimeObject(event?.startTime, is12Format).amPm}`) ||
          `${convertTimeObject(defaultDayTime, is12Format).amPm}` || 
          DAY_PART[0],
      ],
      endHours: [
        (event.endTime?.hour && `${convertTimeObject(event?.endTime, is12Format).hour}`) ||
          `${((+convertTimeObject(defaultDayTime, is12Format).hour) + 8) % 12 || 12}` ||
          '4',
        (event.endTime?.hour && `${convertTimeObject(event?.endTime, is12Format).hour}`) ||
          `${((+convertTimeObject(defaultDayTime, is12Format).hour) + 8) % 12 || 12}` ||
          '4',
      ],
      endMinutes: [
        (event?.endTime?.minute && `${convertTimeObject(event?.endTime, is12Format).minute}`) ||
          `${convertTimeObject(defaultDayTime, is12Format).minute}` || 
          '00',
        (event?.endTime?.minute && `${convertTimeObject(event?.endTime, is12Format).minute}`) ||
          `${convertTimeObject(defaultDayTime, is12Format).minute}` || 
          '00',
      ],
      endAmPm: [
        (event?.endTime?.hour && `${convertTimeObject(event?.endTime, is12Format).amPm}`) ||
          `${((convertTimeObject(defaultDayTime, is12Format).hour) + 8) > 12}`|| 
          DAY_PART[1],
        (event?.endTime?.hour && `${convertTimeObject(event?.endTime, is12Format).amPm}`) ||
          `${((convertTimeObject(defaultDayTime, is12Format).hour) + 8) > 12}`|| 
          DAY_PART[1],
      ],
    },
  });

  const values = watch();

  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const onRemoveClick = () => {
    setIsRemoveModalOpen(true);
  }

  const closeModal = () => setIsRemoveModalOpen(false);

  const onRemove = async () => {
    try {
      await removeCalendarEvent(event._id);
      dispatch(availabilityPageActions.removeEquipmentEvent(event));

      openSnackbar('success', 'The Time off was deleted successfully');
      closeDrawer();
    } catch (err) {
      openSnackbar('error', err?.response?.data?.message || err.message);
    }
  }

  const onSubmit = async (values) => {
    const dates = [];
    for (let i = 0; i <= 1; ++i) {
      dates[i] = {
        startTime: {
          hour: convertTo24HourFormat(values.startHours[i], values.startAmPm?.[i]),
          minute: values.startMinutes[i],
        },
        endTime: {
          hour: convertTo24HourFormat(values.endHours[i], values.endAmPm?.[i]),
          minute: values.endMinutes[i],
        }
      };

      const startHoursObj = [
        values.startDate[i],
        values.startHours[i],
        values.startMinutes[i],
        values.startAmPm?.[i] || null,
      ];
      dates[i].utcStartDate = parse(
        getTimeFormatHook(startHoursObj).formattedTimeDate,
        getTimeFormatHook(startHoursObj).formatForDateTimePars,
        new Date()
      );
      const endHoursObj = [
        values.endDate[i],
        values.endHours[i],
        values.endMinutes[i],
        values.endAmPm?.[i] || null,
      ];
      dates[i].utcEndDate = parse(
        getTimeFormatHook(endHoursObj).formattedTimeDate,
        getTimeFormatHook(endHoursObj).formatForDateTimePars,
        new Date()
      );
    }

    const data = {
      dates,
      notes: values.notes,
    }

    try {
      const res = await splitCalendarEvent(event._id, data);
      dispatch(availabilityPageActions.addEquipmentEvents(res.data));
      dispatch(availabilityPageActions.removeEquipmentEvent(event));
      closeDrawer();
    } catch (e) {
      openSnackbar('error', e?.response?.data?.message || e.message);
      setIsSubmitting(false);
    }
  }

  return (
    <div className={classes.formWrapper} role="presentation">
      <PageLoader loading={isSubmitting}>
        <form onSubmit={handleSubmit(onSubmit)} className={classes.formFlex}>
          <div>
            <Typography variant="h3" style={{ marginBottom: '30px' }}>
              Edit Equipment Event
            </Typography>

            <IconButton
              className={classes.closeButton}
              onClick={closeDrawer}
              aria-label="Close"
              disableTouchRipple
            >
              <CloseIcon />
            </IconButton>

            {event && (
              <CalendarEventDetails
                control={control}
                setValue={setValue}
                clearErrors={clearErrors}
                setError={setError}
                errors={errors}
                values={values}
                equipment={equipment}
                onRemove={onRemoveClick}
              />
            )}
          </div>

          <div className={classes.buttonHolder}>
            <Button
              color="secondary"
              variant="outlined"
              className={classes.cancelButton}
              onClick={closeDrawer}
              disabled={isSubmitting}
              disableTouchRipple
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="outlined"
              className={classes.saveButton}
              disabled={isSubmitting || !!Object.keys(errors).length}
              disableTouchRipple
            >
              Save
            </Button>
          </div>
        </form>
      </PageLoader>
      <ConfirmDialog
        isOpen={isRemoveModalOpen}
        onClose={closeModal}
        onSubmit={onRemove}
        text="Are sure you want to delete this event?"
      />
    </div>
  );
}

export default withStyles(styles)(SplitCalendarEventForm);