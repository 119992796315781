export default theme => ({
  formWrapper: {
    padding: '20px 15px 0',
    [theme.breakpoints.down('sm')]: {
      minWidth: 320,
    },
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      width: 550,
    },
  },
  closeButton: {
    position: 'absolute',
    right: 15,
    top: 12,
  },
  buttonHolder: {
    textAlign: 'center',
  },
  cancelButton: {
    margin: 20,
    minWidth: 100,
    '&:hover': {
      color: 'inherit'
    },
    '&:disabled': {
      backgroundColor: '#fff',
    },
  },
  saveButton: {
    margin: 20,
    minWidth: 100,
    '&:hover': {
      color: theme.palette.textColor.primary,
    },
    '&:disabled': {
      backgroundColor: '#fff',
    },
  },
  formFlex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '95vh',
  },
  deleteButton: {
    padding: 10,
    width: 170,
    margin: '20px 0 0 auto',
    display: 'flex',
    color: theme.palette.textColor.red,
    '&:hover': {
      color: `${theme.palette.textColor.red} !important`,
      backgroundColor: 'rgba(0, 0, 0, 0.08) !important',
    },
    '&:focus': {
      color: `${theme.palette.textColor.red} !important`,
      backgroundColor: '#fff !important',
    },
    '&:disabled': {
      backgroundColor: '#fff',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '20px auto 0',
    },
  },
  flexBox: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: 300,
    marginTop: 20
  },
  fixedWidth: {
    width: 210
  },
  fixedHeight: {
    minHeight: 29,
  }
});
