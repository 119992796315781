import React from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import styles from './styles';
import { hasPermissionsFor } from 'helpers/_helpers';
import ExportExcelButton from 'pages/TimeClock/components/ExportExcelButton/ExportExcelButton';
import Grid from '@material-ui/core/Grid';
import { useParams } from 'react-router-dom';

const WorklogsHeader = ({ classes, openDrawer, pageType, handleExcelExport }) => {
  const params = useParams();

  return (
    <header className={classes.header}>
      <Typography variant="h3">Work Log</Typography>
      {!pageType.includes('submissions') &&
        (hasPermissionsFor('worklogsFullAccess') ||
          hasPermissionsFor('worklogsEdit') ||
          hasPermissionsFor('worklogsCreate')) && (
          <Button
            variant="text"
            color="secondary"
            className={classes.addButton}
            onClick={openDrawer('create')}
            disableTouchRipple
          >
            + New Worklog
          </Button>
        )}
        { params?.type == 'submissions' && <Grid item xs={'auto'}>
          <ExportExcelButton onClick={handleExcelExport} />
        </Grid>}
    </header>
  );
};

WorklogsHeader.propTypes = {
  classes: propTypes.object.isRequired,
  openDrawer: propTypes.func.isRequired,
  pageType: propTypes.string,
};

export default withStyles(styles)(WorklogsHeader);
