export default (theme) => ({
  formWrapper: {
    width: 380,
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      width: 850,
    },
  },
  closeButton: {
    position: 'absolute',
    right: 15,
    top: 12,
  },
  buttonHolder: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  cancelButton: {
    margin: 20,
    minWidth: 100,
    '&:hover': {
      color: theme.palette.textColor.dark,
    },
    '&:focus': {
      color: theme.palette.textColor.dark,
      backgroundColor: '#fff',
    },
    '&:disabled': {
      backgroundColor: '#fff',
    },
  },
  addButton: {
    '&:hover': {
      color: theme.palette.textColor.primary,
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
      backgroundColor: '#fff',
    },
    '&:disabled': {
      backgroundColor: '#fff',
    },
  },
  cancelEddingButton: {
    color: '#a4a5b3',
    '&:hover': {
      color: '#a4a5b3',
    },
    '&:focus': {
      color: '#a4a5b3',
      backgroundColor: 'rgb(12 11 11 / 19%)',
    },
    '&:disabled': {
      backgroundColor: 'rgb(12 11 11 / 19%)',
    },
  },
  saveButton: {
    margin: 20,
    minWidth: 100,
    '&:hover': {
      color: theme.palette.textColor.primary,
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
      backgroundColor: '#fff',
    },
    '&:disabled': {
      backgroundColor: '#fff',
    },
  },
  formFlex: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '95vh',
    justifyContent: 'space-between',
    padding: '25px 25px 30px',
  },
  textField: {
    maxWidth: 250,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 200,
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '100%',
    },
  },
  marginVertical: {
    margin: '20px 0',
  },
  shiftImage: {
    height: 16,
    margin: '-2px 5px 0 0',
  },
  label: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  marginHeader: {
    marginBottom: 30,
  },
  verticalDivider: {
    width: 1,
    height: 30,
    backgroundColor: theme.palette.borderColor.primary,
  },
  fieldWrapper: {},
  flexContainer: {
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'space-between',
    },
  },
  resetButton: {
    '&:hover': {
      color: theme.palette.primary.light,
      backgroundColor: '#fff !important',
    },
    '&:focus': {
      color: theme.palette.primary.blue,
      backgroundColor: '#fff',
    },
    textTransform: 'none',
  },
  customSelectedStyle: {
    backgroundColor: 'rgba(0, 0, 0, 0.14)',
  },
  fileUpload: {
    marginTop: 8,
  },
  error: {
    color: theme.palette.textColor.red,
    padding: '0 5px',
    fontSize: 11,
  },
  postError: {
    color: '#e40909',
    padding: '35px 0 0',
    fontSize: 12,
    textAlign: 'center',
  },
  itemsList: {
    listStyle: 'none',
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: '0',
    marginBottom: '0',
  },
  dndWrapper: {
    display: 'flex',
    gap: '10px',
    maxWidth: '100%',
    flexWrap: 'wrap',
  },
  dndItem: {
    transformOrigin: '50% 50%',
    width: 'max-content',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // boxShadow: isDragging
    //   ? 'rgb(63 63 68 / 5%) 0px 2px 0px 2px, rgb(34 33 81 / 15%) 0px 2px 3px 2px'
    //   : 'rgb(63 63 68 / 5%) 0px 0px 0px 1px, rgb(34 33 81 / 15%) 0px 1px 3px 0px',
    // transform: isDragging ? 'scale(1.05)' : 'scale(1)',
    background: 'rgba(90, 135, 239, 0.18)',
    borderRadius: '4px',
    display: 'flex',
    padding: '4px 8px 3px 9px',
    justifyContent: 'space-between',
    color: 'rgb(90, 135, 239)',
    lineHeight: '22px',
  },
  dndItemText: {
    fontSize: '14px',
    marginRight: '5px',
    fontWeight: '500',
  },
});
