import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom'
import styles from './styles';
import { withStyles } from '@material-ui/core';
import cs from 'classnames';
import Input from './components/Input';
import { useForm } from 'react-hook-form';

const SignInForm = ({ classes, value = {}, error = {}, onSubmit, className, setError }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();


  const forgotPass = useCallback(async () => {
    navigate('/forgot-password', {replace: true})
  }, []);

  return (
    <form
      className={cs(className, classes.root)}
      onSubmit={handleSubmit(onSubmit)}
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: '280px' }}
    >
      <p className={classes.title}>Sign In</p>
      <div className={classes.fields} style={{ width: '100%' }}>
        <div className={classes.field} style={{ marginBottom: 4 }}>
          <Input
            name={'username'}
            error={errors.username || error.reason}
            ref={register({ required: true })}
            autoComplete={'username'}
            defaultValue={value.username}
            label={'Username:'}
            onChange={(e) => {return errors.username || error.reason ? setError() : null}}
          />
          {errors.username && <span className={classes.error}>Username is required</span>}
          {error.reason && error.reason !== 'Incorrect password' && (
            <span className={classes.error}>{error.reason}</span>
          )}
        </div>

        <div className={classes.field}>
          <Input
            name={'password'}
            error={errors.password || error.reason}
            ref={register({ required: true })}
            autoComplete={'current-password'}
            defaultValue={value.password}
            label={'Password:'}
            type={'password'}
            onChange={(e) => {return errors.password || error.reason ? setError() : null}}
          />
          {errors.password && <span className={classes.error}>Password is required</span>}
          {error.reason === 'Incorrect password' && (
            <span className={classes.error}>{error.reason}</span>
          )}
        </div>
        <p className={classes.forgotText} onClick={forgotPass}>
          Forgot password?
        </p>
      </div>
      <button className={classes.submitBtn} type={'submit'}>
        Sign In
      </button>
    </form>
  );
};

export default withStyles(styles)(SignInForm);
