import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Box, Button, IconButton, Typography, InputAdornment, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowRightAltRoundedIcon from '@material-ui/icons/ArrowRightAltRounded';
import classnames from 'classnames';

import { styles } from './styles';
import Autocomplete from '../../../AutocompleteMui/Autocomplete';

const useStyles = makeStyles(styles);

const CurrentUserBlock = ({
  authError,
  clearAuthError,
  selectedUser,
  userList,
  authorize,
  showLoginButton,
}) => {
  const classes = useStyles();
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordShown = () => setPasswordShown((prev) => !prev);

  const {
    control,
    setValue,
    getValues,
    clearErrors,
    setError,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      user: null,
      employeeNum: '',
    },
    mode: 'onChange',
  });

  useEffect(() => {
    setValue('user', selectedUser || null);
    setValue('employeeNum', selectedUser ? selectedUser.profile.employeeNum : '');
  }, [selectedUser]);

  useEffect(() => {
    if (!authError) return clearErrors();
    setError('user', { type: 'auth', message: '' });
    setError('employeeNum', { type: 'auth', message: authError });
  }, [authError]);

  const handleLogIn = () => {
    const { user, employeeNum } = getValues();
    if (!user) {
      setError('user', { type: 'empty', message: 'Please, select username.' });
    }
    if (!employeeNum) {
      setError('employeeNum', { type: 'empty', message: 'Please, enter employee number.' });
    }
    if (!user || !employeeNum) return;

    authorize({ user, employeeNum });
  };

  // why employee number field is text https://stackoverflow.com/a/32836440/8334154
  return (
    <Box
      display="flex"
      flexDirection="column"
      className={classnames(classes.wrapperForKiosk, !showLoginButton && classes.wrapper)}
    >
      <Typography variant="h2" className={classes.greeting} gutterBottom>
        Hi there!
      </Typography>
      <Box className={classes.wrapperKioskInputs}>
        <label htmlFor="user" className={classes.kioskLabelUsername}>
          <Typography variant="body1" color="textSecondary">
            Username:
          </Typography>
          <div className={classes.kioskInputUsername}>
            <Controller
              control={control}
              name="user"
              render={({ onChange, value }) => (
                <div style={{ position: 'relative' }}>
                  <Autocomplete
                    id="user"
                    disabled={!!selectedUser}
                    options={selectedUser ? [selectedUser] : userList}
                    getOptionLabel={(option) => option.username || ''}
                    value={value}
                    getOptionSelected={(option, value) => option._id === (value ? value._id : '')}
                    onKeyDown={(e) => (e.key === 'Enter' ? handleLogIn() : null)}
                    onChange={(e, value, reason) => {
                      if (reason === 'blur') return;
                      clearAuthError();
                      onChange(reason === 'clear' ? null : value);
                    }}
                    classes={{
                      inputRoot: classnames(classes.inputRoot, {
                        [classes.inputRootError]: errors.user,
                      }),
                      input: classes.input,
                      endAdornment: classes.endAdornment,
                      option: classes.option,
                      popper: classes.popper,
                    }}
                    disablePortal
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        // error={!!errors.user}
                        variant="outlined"
                      />
                    )}
                  />
                </div>
              )}
            />
          </div>
          {errors.user ? <div className={classes.error}>{errors.user.message}</div> : null}
        </label>
        <label htmlFor="employeeNum" className={classes.kioskLabelUsername}>
          <Typography variant="body1" color="textSecondary">
            Employee Number:
          </Typography>
          <div className={classes.kioskInputUsername}>
            <Controller
              control={control}
              name="employeeNum"
              render={({ onChange, value }) => (
                <TextField
                  variant="outlined"
                  fullWidth
                  value={value}
                  onKeyDown={(e) => (e.key === 'Enter' ? handleLogIn() : null)}
                  onChange={(e) => {
                    clearAuthError();
                    onChange(e.target.value);
                  }}
                  error={!!errors.employeeNum}
                  type="text"
                  disabled={!!selectedUser}
                  className={classnames(classes.employeeNum, !passwordShown && classes.hiddenPass)}
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          aria-label="toggle password visibility"
                          onClick={togglePasswordShown}
                          className={classes.togglePassword}
                          size="small"
                        >
                          {passwordShown ? 'hide' : 'show'}
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </div>
          {errors.employeeNum ? (
            <div className={classes.error}>{errors.employeeNum.message}</div>
          ) : null}
        </label>
        {showLoginButton && (
          <IconButton
            className={classes.loginButton}
            onClick={handleLogIn}
            disabled={!!selectedUser}
          >
            <ArrowRightAltRoundedIcon color={selectedUser ? 'secondary' : 'primary'} />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default CurrentUserBlock;
