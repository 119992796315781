import { Button, DialogActions } from 'pages/TimeClock/modals/base/styled';
import { DialogTitle, DialogDescription, ExportForm, Dialog } from './../DuplicateModal/styled';
import { addDays } from 'date-fns';
import { useState } from 'react';
import styles from './../ConflictModal/styles';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import PageLoader from 'components/PageLoader';

const DuplicateModal = ({ onSubmit, onClose, data, classes }) => {
  const [submitting, setSubmitting] = useState(false);
  const [date, setDate] = useState({
    period: {
      from: addDays(new Date(), 1),
      to: addDays(new Date(), 7),
    },
  });

  const onChangeTime = (period) => {
    setDate(period);
  };

  return (
    <Dialog open={data?.isOpen}>
      <PageLoader loading={submitting} small>
        <IconButton
          className={classes.closeButton}
          onClick={onClose}
          aria-label="Close"
          disableTouchRipple
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle disableTypography={true}>Duplicate Worklog</DialogTitle>
        <DialogDescription>
          Please, choose the date range to which you would <br />
          like to duplicate the worklog
        </DialogDescription>

        <ExportForm value={date} onChange={onChangeTime} />

        <DialogActions disableSpacing={false}>
          <Button
            // disabled={submitting}
            variant={'outlined'}
            color={'secondary'}
            fullWidth={true}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            // disabled={submitting}
            variant={'outlined'}
            fullWidth={true}
            onClick={() => {
              setSubmitting(true);
              onSubmit(date)
              .then(()=>setSubmitting(false));
            }}
          >
            Duplicate
          </Button>
        </DialogActions>
      </PageLoader>
    </Dialog>
  );
};

export default withStyles(styles)(DuplicateModal);
