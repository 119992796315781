import { useCallback } from 'react';
import { getHours } from 'date-fns';
import { getOptions } from 'helpers/getOptions';
import { findUsed } from '../../../../../helpers/findUsed';
import { findUsedWorkers } from 'helpers/sheet.helpers';
import $api from 'http/index';

export const useCheckCanAssignWorklog = () => {
  const checkCanAssignWorklog = useCallback(
    async ({ e, start, end, openModal, sheet, sheetsInView, allSheetsThisDay }) => {
      const resourceId = e.dataTransfer.getData('resourceId');
      const resourceType = e.dataTransfer.getData('resourceType');
      const resourceDraggedFromSheet = e.dataTransfer.getData('sheetsId') || null;

      const alreadyInSheet =
        sheet[resourceType] && sheet[resourceType].some((r) => r._id === resourceId);
      if (!resourceId || alreadyInSheet) return { canAssign: false, data: null };

      const usedWorkers = findUsed(allSheetsThisDay, 'workers');
      const usedEquipment = findUsed(allSheetsThisDay, 'equipment');

      const resourceUsed = [...usedWorkers, ...usedEquipment].some((w) => w._id === resourceId);

      try {
        const postData =
          resourceType === 'workers' ? { workerIds: [resourceId] } : { equipmentIds: [resourceId] };
        const path = resourceType === 'workers' ? 'workers' : 'equipment';

        await $api.post(
          `${process.env.REACT_APP_BASE_URL}/sheets/${sheet._id}/${path}/check-conflicts`,
          postData,
          {
            ...getOptions(),
          }
        );

        if (resourceType === 'workers') {
          const timeOfSheet = sheet.start ? getHours(new Date(sheet.start)) : null;
          const sheetsWithSameTime = sheetsInView.filter(
            (sheet) =>
              getHours(new Date(sheet.start)) === timeOfSheet && sheet.sheet._id !== sheet._id
          );
          let workers = findUsedWorkers(sheetsWithSameTime);
          if (workers.find((worker) => worker._id === resourceId)) {
            throw new Error('Project already scheduled for this day.');
          }
        }

        const data = {
          resourceType,
          resource: { _id: resourceId },
        };

        return { canAssign: true, data };
      } catch (e) {
        if (e.response && e.response.data.type === 'resource-conflict') {
          const testConflictData = {
            to: sheet._id,
            from: resourceDraggedFromSheet,
            addData: {
              resourceType,
              resource: { _id: resourceId },
            },
            removeData: { resourceId, resourceType },
            sheet,
          };
          openModal({ name: 'conflict', data: testConflictData });
        }

        if (e.response && e.response.data.type === 'sheet-time-overlapse') {
          openModal({ name: 'conflict', data: e.response.data.data });
        }
        if (e.response && e.response.data.type === 'timeoff-time-overlapse') {
          openModal({ name: 'info', data: e.response.data.message });
        }
        // openModal({ name: 'info', data: e.response.data.message });
        // setModals((prev) => ({
        //   ...prev,
        //   info: { ...prev.info, open: true, data: e.response.data.message },
        // }));
        return { canAssign: false, data: null };
      }
    },
    []
  );

  return { checkCanAssignWorklog };
};
