import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';
import { formatISO } from 'date-fns';
import classnames from 'classnames';
import { connect, useDispatch, useSelector } from 'react-redux';
import SheetForm from 'pages/Worklogs/components/SheetForm/SheetForm';
import ScheduleHeader from '../ScheduleHeader';
import ScheduleCalendar from '../ScheduleCalendar';
import PrintPage from '../PrintPage';
import ResourcesSidebar from '../ResourcesSidebar';
import styles from './styles';
import { updateSelectedDate } from 'store/schedule/schedulePageOperation';
import { ScheduleFilters } from '../ScheduleHeader/components/ScheduleFilters/ScheduleFilters';
import { DRAWER_TYPES } from './helpers/drawerType';
import { useNavigate } from 'react-router-dom';
import modalsPageActions from 'store/modals/modalsPageActions';
import Modals from './components/Modals/Modals';
import MonthViewCalendar from '../MonthViewCalendar/MonthViewCalendar';
import { CALENDAR_VIEW_TYPES } from '../ScheduleHeader/components/DateTypeSelector/constants';
import schedulePageActions from 'store/schedule/schedulePageActions';

const SchedulePage = ({
  classes,
  openSnackbar,
  selectedDate,
  updateSelectedDate,
  calendarTypeView,
  selectedMonthDayDate,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [reconcile, setReconcile] = useState({
    visible: true,
    disabled: true,
    showModal: false,
  });
  const scheduleDateType = useSelector((state) => state.schedule.calendarTypeView);

  const [showPublishModal, setShowPublishModal] = useState(false);
  const [drawer, setDrawer] = useState(null);
  const [worklogsForPrint, setStateWorklogsForPrint] = useState([]);
  const [needToSetWorklogs, setNeedToSetWorklogs] = useState(false);
  const [needToPrint, setNeedToPrint] = useState(false);
  const [toggleButton, setToggleButton] = useState(false);

  useEffect(() => {
    dispatch(
      modalsPageActions.addModals([
        // set correct onSubmit on opening 'remove' modal
        {
          name: 'remove',
          onSubmit: () => null,
          onClose: () => dispatch(modalsPageActions.closeModal('remove')),
        },
        {
          name: 'info',
          onSubmit: () => null,
          onClose: () => dispatch(modalsPageActions.closeModal('info')),
        },
        {
          name: 'conflict',
          onSubmit: () => null,
          onClose: () => dispatch(modalsPageActions.closeModal('conflict')),
        },
        {
          name: 'overlapping',
          onSubmit: () => dispatch(modalsPageActions.closeModal('overlapping')),
          onClose: () => dispatch(modalsPageActions.closeModal('overlapping')),
        },
      ])
    );

    return () => {
      dispatch(modalsPageActions.removeModal('remove'));
      dispatch(modalsPageActions.removeModal('info'));
      dispatch(modalsPageActions.removeModal('conflict'));
      dispatch(modalsPageActions.removeModal('overlapping'));
      sessionStorage.setItem('scheduleDate', formatISO(selectedDate));
      dispatch(schedulePageActions.setCalendarTypeView(CALENDAR_VIEW_TYPES.daily));
    };
  }, []);

  const updatePublishControl = (value) => () => {
    if (value !== showPublishModal) {
      setShowPublishModal(value);
    }
  };

  const updateReconcileBtn = (visible, disabled) => {
    if (visible !== reconcile.visible || disabled !== reconcile.disabled) {
      setReconcile((prev) => ({ ...prev, visible, disabled }));
    }
  };
  const updateReconcileModal = (value) => {
    if (value === reconcile.showModal) return;
    setReconcile((prev) => ({ ...prev, showModal: value }));
  };
  const showReconcileModal = () => {
    updateReconcileModal(true);
  };
  const closeReconcileModal = () => {
    updateReconcileModal(false);
  };
  const toggleResourses = () => {
    setToggleButton((prev) => !prev);
  };

  const openDrawer = (type) => () => setDrawer(type);
  const closeDrawer = () => setDrawer(null);

  const updatePrintWorklogsControl = (value) => () => {
    if (value !== needToPrint) {
      setNeedToPrint(value);
    }
  };
  const updateSetWorklogsControl = (value) => () => {
    if (value !== needToSetWorklogs) {
      setNeedToSetWorklogs(value);
    }
  };
  const setWorklogsForPrint = (worklogs) => {
    setStateWorklogsForPrint(worklogs);
    setNeedToPrint(true);
    setNeedToSetWorklogs(false);
  };

  return (
    <>
      <div className={classnames(classes.root, classes.hideForPrint)}>
        <ScheduleHeader
          openDrawer={openDrawer}
          selectedDate={selectedDate}
          updateSelectedDate={updateSelectedDate}
          reconcile={reconcile}
          showReconcileModal={showReconcileModal}
          updatePublishControl={updatePublishControl}
          updateSetWorklogsControl={updateSetWorklogsControl}
        />
        <div style={{ display: 'flex' }}>
          <ResourcesSidebar isShow={toggleButton} onChange={toggleResourses} />
          {scheduleDateType === CALENDAR_VIEW_TYPES.daily ? (
            <ScheduleCalendar
              openSnackbar={openSnackbar}
              showPublishModal={showPublishModal}
              updatePublishControl={updatePublishControl}
              reconcileModalShown={reconcile.showModal}
              updateReconcileBtn={updateReconcileBtn}
              closeReconcileModal={closeReconcileModal}
              needToSetWorklogs={needToSetWorklogs}
              setWorklogsForPrint={setWorklogsForPrint}
              isShow={toggleButton}
              navigate={navigate}
            />
          ) : (
            <MonthViewCalendar
              isShow={toggleButton}
              onChange={toggleResourses}
              openSnackbar={openSnackbar}
              showPublishModal={showPublishModal}
              updatePublishControl={updatePublishControl}
            />
          )}
        </div>

        <Drawer anchor="right" open={!!drawer}>
          {drawer && drawer === DRAWER_TYPES.addWorklog && (
            <SheetForm
              closeDrawer={closeDrawer}
              type={'create'}
              openSnackbar={openSnackbar}
              defaultDate={
                calendarTypeView === CALENDAR_VIEW_TYPES.monthly
                  ? selectedMonthDayDate
                  : selectedDate
              }
            />
          )}
          {drawer && drawer === DRAWER_TYPES.filters && (
            <ScheduleFilters closeDrawer={closeDrawer} />
          )}
        </Drawer>
      </div>
      <PrintPage
        selectedDate={selectedDate}
        worklogsForPrint={worklogsForPrint}
        updatePrintWorklogsControl={updatePrintWorklogsControl}
        needToPrint={needToPrint}
      />

      <Modals />
    </>
  );
};

SchedulePage.propTypes = {
  classes: propTypes.object.isRequired,
  openSnackbar: propTypes.func.isRequired,
};

export default connect(
  (state) => ({
    scheduleType: state.schedule.view,
    selectedDate: state.schedule.selectedDate,
    calendarTypeView: state.schedule.calendarTypeView,
    selectedMonthDayDate: state.schedule.selectedMonthDayDate,
  }),
  {
    updateSelectedDate,
  }
)(withStyles(styles)(SchedulePage));
