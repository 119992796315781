import { withStyles, Typography } from '@material-ui/core';
import BaseExportForm from './components/ExportForm';
import {
  DialogTitle as BaseDialogTitle,
  Dialog as BaseDialog,
} from 'pages/TimeClock/modals/base/styled';

export const Dialog = withStyles({
  paper: {
    overflowY: 'visible',
    width: 455,
    padding: 0,
  },
})(BaseDialog);

export const DialogTitle = withStyles({
  root: {
    marginBottom: 12,
    padding: '20px 26px 0',
  },
})(BaseDialogTitle);

export const DialogDescription = withStyles({
  body1: {
    color: '#A4A5B3',
    fontFamily: 'Open Sans',
    fontSize: 14,
    lineHeight: '19px',
    marginBottom: 22,
    padding: '0 26px',
  },
})(Typography);

export const Day = withStyles({
  body1: {
    color: '#202020',
    fontSize: 14,
    letterSpacing: '0.17px',
    lineHeight: '21px',
    marginBottom: 34,
  },
})(Typography);

export const ExportForm = withStyles({})(BaseExportForm);
