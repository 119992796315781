import React, { Component } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { parse, format } from 'date-fns';
import classnames from 'classnames';
import photoPlaceholder from 'assets/images/user-photo-placeholder.svg';
import { getEquipmentColorClass } from '../../../../../../../../helpers/_helpers';
import styles from './styles';
import { connect } from 'react-redux';
import { dateSettingsFormat, globalBEDateFormat } from 'common/dateFormatConfig';

class UsedResources extends Component {
  getUsername = (user) => {
    if (!user) return '';
    return user.username;
  };

  getEquipmentInfo = (equipment, field) => {
    if (!equipment) return '';
    if (field === 'number') {
      return parseInt(equipment.name);
    }
    if (field === 'className') {
      return getEquipmentColorClass(equipment.color);
    }
    return equipment[field];
  };

  getDayNumber = (date) =>
    format(parse(date, globalBEDateFormat, new Date()), 'dd');

  getUserShift = (user) => {
    if (!user) return '';
    return user.profile.shifts.timeOfDay.toLowerCase();
  };

  getHolidayInfo = () => {
    const { holidays, event, type } = this.props;
    if (type !== 'people') return null;

    return holidays.find((h) => h.inDays.includes(event.dayNumber) && !h.isIndividual);
  };

  render() {
    const { classes, event, type, eventsWithIndividualHolidays } = this.props;

    return (
      <div
        className={classnames(
          classes.cellContent,
          this.getHolidayInfo() && classes.greenBackground
        )}
      >
        <div>
          <span className={classes.dayNumber}>{this.getDayNumber(event.dayNumber)}</span>
        </div>

        {this.getHolidayInfo() && (
          <div
            className={classnames(
              classes.holidayName,
              !eventsWithIndividualHolidays.length && classes.emptyCellHolidayName
            )}
          >
            {this.getHolidayInfo().name}
          </div>
        )}

        <div className={classes.list}>
          {type === 'people'
            ? eventsWithIndividualHolidays.map((timeoff) => (
                <div key={timeoff._id} className={classes.flexBox}>
                  <div className={classes.flexBoxInline}>
                    <div
                      className={classnames(
                        classes.userPhotoWrapper,
                        classes[`${this.getUserShift(timeoff.user)}Border`]
                      )}
                    >
                      <img src={photoPlaceholder} alt="user photo" className={classes.userPhoto} />
                    </div>
                    <Typography
                      className={classnames(
                        classes.name,
                        timeoff.type === 'full' && classes.fullWidth,
                        timeoff.type !== 'full' && classes.width1,
                        classes[this.getUserShift(timeoff.user)]
                      )}
                    >
                      {this.getUsername(timeoff.user)}
                    </Typography>
                    {timeoff.type === 'partial' && (
                      <div className={classes.startTime}>
                        {timeoff.startTime.hours}:{timeoff.startTime.minutes}
                        <span>{timeoff.startTime.amPm}</span>
                      </div>
                    )}
                    {timeoff.isIndividual && (
                      <div className={classnames(classes.startTime, classes.dayOff)}>
                        <span>Day Off</span>
                      </div>
                    )}
                  </div>
                </div>
              ))
            : event.events.map((calendarEvent) => (
                <div key={calendarEvent._id} className={classes.flexBox}>
                  <div
                    className={classnames(
                      classes.equipmentBlock,
                      this.getEquipmentInfo(calendarEvent.equipment, 'className')
                    )}
                    style={{
                      backgroundColor: this.getEquipmentInfo(calendarEvent.equipment, 'color'),
                    }}
                  >
                    <div className={classes.equipmentNumber}>
                      {this.getEquipmentInfo(calendarEvent.equipment, 'name')}
                    </div>
                  </div>
                </div>
              ))}
        </div>
      </div>
    );
  }
}

UsedResources.propTypes = {
  classes: propTypes.object.isRequired,
  event: propTypes.object.isRequired,
  type: propTypes.string.isRequired,
};

export default connect((state) => ({
  dateFormat: state.personalProfile.organization?.settings?.dateFormat,
}))(withStyles(styles)(UsedResources));
