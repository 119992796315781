import React from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import styles from './styles';
import { hasPermissionsFor } from 'helpers/_helpers';
import { drawerTypes } from '../../../Equipment/components/helpers/drawerTypes';

const ContractorsHeader = ({ classes, openDrawer, type }) => {
  return (
    <header className={classes.header}>
      <Typography variant="h3">{type}</Typography>
      {(hasPermissionsFor('projectsFullAccess') ||
        hasPermissionsFor('projectsEdit')) && (
        <Button
          variant="text"
          color="secondary"
          className={classes.addButton}
          onClick={openDrawer(drawerTypes.create, true)}
          disableTouchRipple
        >
          + Add Contractor
        </Button>
      )}
    </header>
  );
};

ContractorsHeader.propTypes = {
  classes: propTypes.object.isRequired,
  openDrawer: propTypes.func.isRequired,
  type: propTypes.string.isRequired,
};

export default withStyles(styles)(ContractorsHeader);
