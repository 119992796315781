export default theme => ({
  calendarWrapper: {
    margin: '4px 0 10px 0'
  },
  dayName: {
    fontWeight: 300
  },
  regularFontWeight: {
    fontWeight: 300
  },
  noResults: {
    margin: '1.72em 0 2em',
    opacity: 0.5
  },
  inlineBlock: {
    display: 'inline-block',
    verticalAlign: 'middle'
  },
  marginRight: {
    marginRight: 24
  },
  marginLeft: {
    marginLeft: 24
  },
  addButton: {
    color: '#5A87EF',
    '&:hover': {
      color: '#5A87EF',
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
    '&:focus': {
      color: '#5A87EF',
      backgroundColor: '#fff',
    },
    '&.Mui-disabled': {
      backgroundColor: '#fff',
    }
  },
});
