import React from 'react';
import JobItem from './components/JobItem';
import TimeoffItem from './components/TimeoffItem';
import HolidayItem from './components/HolidayItem';
import cs from 'classnames';
import { Container } from './styled';
import { Title, TotalTime } from '../styled';
import formatNumber from '../../../../utils/formatNumber';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { styles } from './styled';
import { makeStyles } from '@material-ui/core/styles';
import Warning from '../../../../../../components/icons/Warning';

const useStyles = makeStyles(styles);

const Jobs = ({
  data,
  shopTime,
  onItemClick,
  selected,
  editMode,
  onDelete,
  onChange,
  currentRoute,
  hasPayroll,
  payrollVerified,
  payroll,
  ...props
}) => {
  const classes = useStyles();
  if (!data.length) return null;

  const showExclamationMark = currentRoute && hasPayroll && payroll && !payrollVerified;
  const showExclamationMarkShopTime = showExclamationMark && (shopTime > 0 || payroll.shopTime > 0);

  return (
    <Container {...props}>
      <Grid container spacing={2} justifyContent={'space-between'}>
        <Grid item xs={'auto'}>
          <Typography className={classes.jobTitle}>Jobs</Typography>
        </Grid>
        <Grid item xs={'auto'} className={classes.jobShopTimeDesc}>
          <dl className={'dl'}>
            {showExclamationMarkShopTime && shopTime !== payroll.shopTime && (
              <dd className={'warning-right'} style={{ top: '8px', left: '-32px' }}>
                <Warning />
              </dd>
            )}
            <dt className={'dt'}>Shop time:</dt>
            <dd className={'dd'}>
              <TotalTime color={shopTime < 0 ? 'error' : 'initial'}>
                {formatNumber(shopTime)}
              </TotalTime>
            </dd>
          </dl>
        </Grid>
      </Grid>
      {data.map((job, i) => {
        if (job.jobType === 'holiday') {
          return (
            <HolidayItem
              key={job._id}
              currentRoute={currentRoute}
              hasPayroll={hasPayroll}
              payrollVerified={payrollVerified}
              payrollHolidayItem={
                payroll && (payroll.holidays || []).find((elem) => elem.holidayId === job._id)
              }
              data={job}
              onClick={() => onItemClick(job, '_id')}
              className={cs({
                'timeclock-day-report__selected': selected && selected._id === job._id,
              })}
            />
          );
        }
        if (job.jobType === 'timeoff' || job.isTimeoff) {
          return (
            <TimeoffItem
              key={job._id}
              currentRoute={currentRoute}
              hasPayroll={hasPayroll}
              payrollVerified={payrollVerified}
              payrollTimeoffItem={
                payroll && (payroll.timeoffs || []).find((elem) => elem.timeoffId === job._id)
              }
              data={job}
              onClick={() => onItemClick(job, '_id')}
              className={cs({
                'timeclock-day-report__selected': selected && selected._id === job._id,
              })}
            />
          );
        }
        if (Array.isArray(job) && job[0] && (job[0].isTimeoff || job[0].jobType === 'timeoff')) {
          return (
            <TimeoffItem
              key={job[0]._id}
              data={job[0]}
              onClick={() => onItemClick(job[0], '_id')}
              className={cs({
                'timeclock-day-report__selected': selected && selected._id === job[0]._id,
              })}
            />
          );
        }
        return (
          <JobItem
            key={`${job.sheet._id}-${i}`}
            currentRoute={currentRoute}
            hasPayroll={hasPayroll}
            payrollVerified={payrollVerified}
            payrollSheetItems={
              payroll &&
              (payroll.sheets || []).filter(
                (el) => el.hours[0].id === job.hoursId || el.sheetId === job.sheet._id
              )
            }
            data={job}
            onDelete={onDelete}
            onChange={onChange}
            editMode={editMode}
            onClick={() => onItemClick(job, 'hoursId')}
            className={cs({
              'timeclock-day-report__selected': selected && selected.hoursId === job.hoursId,
            })}
            shopTime={shopTime}
          />
        );
      })}
    </Container>
  );
};

export default Jobs;
