import React from 'react';
import formatNumber from '../../../../../../utils/formatNumber';
import { Name, Root } from './styled';
import { TotalTime } from '../../../styled';
import Grid from '@material-ui/core/Grid';
import Typography from '../../../../../../../../components/FieldLabel';
import { Box } from '@material-ui/core';
import { styles } from './styled';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '../../../../../../../../components/core/IconButton/IconButton';
import CrossIcon from '../../../../../../../../components/icons/Cross';
import ClockIcon from '../../../../../../../../components/icons/Clock';

const useStyles = makeStyles(styles);

export const getHoursToDate = (hoursPerUser, userId) => {
  const userHours = hoursPerUser.find((h) => h.userId === userId);
  return userHours ? userHours.hours : 0;
};

const CIPProjectItem = ({ data, userId, editMode, onChange, onDelete, ...props }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root} {...props}>
      <Grid className={classes.cipProjItem}>
        <Grid className={classes.cipBlockItemTitle}>
          <Name>{data.cipProject ? data.cipProject.name : 'N/A'}</Name>
        </Grid>
        <Grid className={classes.cipBlockItemHours}>
          <Typography align={'center'} component={'div'}>
            <div className={classes.cipBlockItemHoursDate}>
              <dt className={'dt'}>Hours to date:</dt>
              <dd className={'dd'}>
                {data.cipProject
                  ? formatNumber(getHoursToDate(data.cipProject.hoursPerUser, userId))
                  : 'N/A'}
              </dd>
            </div>
          </Typography>
        </Grid>
        <Grid item xs={3} className={classes.cipBlockItemPriceWrapper}>
          {/* {editMode ? (
            <Grid
              container
              justify={'flex-end'}
              alignItems={'center'}
              // spacing={2}
            >
              <Grid item xs={2}>
                <Grid
                  container
                  justify={'space-between'}
                  // spacing={1}
                  style={{ alignItems: 'center' }}
                >
                  <Grid item xs={3}>
                    <IconButton onClick={() => onChange(data)}>
                      <ClockIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs={4}>
                    <IconButton onClick={() => onDelete(data)}>
                      <CrossIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Typography align={'right'} component={'div'}>
                  <TotalTime>{formatNumber(data.hoursTotal)}</TotalTime>
                </Typography>
              </Grid>
            </Grid>
          ) : ( */}
            <Grid className={classes.cipBlockItemPrice}>
              <Typography align={'right'} component={'div'}>
                <TotalTime>{formatNumber(data.hoursTotal)}</TotalTime>
                <p className={classes.timeSectionTimeWord}>hours</p>
              </Typography>
            </Grid>
          {/* )} */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default CIPProjectItem;
