import React from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import photoPlaceholder from 'assets/images/user-photo-placeholder.svg';
import styles from '../SignatureModal/styles';
import classnames from 'classnames';

const OutsideClockInModal = ({ classes, closeModal, users, submitModal }) => {
  return (
    <Dialog
      open
      onClose={closeModal}
      PaperProps={{
        classes: {
          root: classes.dialogRoot,
        },
      }}
      disableEscapeKeyDown
    >
      <div className={classes.formWrapper} role="presentation">
        <div className={classes.formFlex}>
          <div>
            <Typography variant="h5" style={{ marginBottom: '10px', marginRight: 40 }}>
              On Job Time is not within Clock-in Time range. Do you want to submit anyways?
            </Typography>
            <IconButton
              className={classes.closeButton}
              onClick={closeModal}
              aria-label="Close"
              disableTouchRipple
            >
              <CloseIcon />
            </IconButton>
            {users.map((user) => {
              return (
                <div className={classes.inlineFlex} key={user._id}>
                  <div
                    className={classnames(
                      classes.photoWrapper,
                      classes[`${user.profile.shifts.timeOfDay.toLowerCase()}Border`]
                    )}
                  >
                    <img src={photoPlaceholder} className={classes.photo} />
                  </div>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    className={classnames(
                      classes.name,
                      classes[user.profile.shifts.timeOfDay.toLowerCase()]
                    )}
                  >
                    {user.username}
                  </Typography>
                </div>
              );
            })}
          </div>

          <div className={classes.buttonHolder}>
            <Button
              color="secondary"
              variant="outlined"
              className={classes.cancelButton}
              onClick={closeModal}
              disableTouchRipple
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="outlined"
              className={classes.saveButton}
              onClick={submitModal}
              disableTouchRipple
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

OutsideClockInModal.propTypes = {
  classes: propTypes.object.isRequired,
  closeModal: propTypes.func.isRequired,
  submitModal: propTypes.func.isRequired,
  users: propTypes.array.isRequired,
};

export default withStyles(styles)(OutsideClockInModal);
