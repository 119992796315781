import React, { useMemo, useState } from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Button, Divider, ClickAwayListener } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import CloseIcon from 'assets/images/close-icon.svg';
import photoPlaceholder from 'assets/images/user-photo-placeholder.svg';
import ArrowUp from 'assets/images/arrow-up-icon.svg';
import { hasPermissionsFor } from 'helpers/_helpers';
import styles from './styles';
import { createWorklogName } from './helpers/createWorklogName';
import { useFormattedHoursRange } from './hooks/useFormattedHoursRange';
import { useGetEquipmentInfo } from '../../../../hooks/useGetEquipmentInfo';
import { useRemoveSheet } from './hooks/useRemoveSheet';
import { useHiddenResources } from './hooks/useHiddenResources';
import { useToggleHeightBtn } from './hooks/useToggleHeightBtn';
import { useHandleDrop } from './hooks/useHandleDrop';
import CancelIcon from 'assets/images/cancel-icon.svg';
import ArrowDown from 'assets/images/arrow-down-icon.svg';
import modalsPageActions from 'store/modals/modalsPageActions';
import SubmittedIcon from 'assets/images/done-icon.svg';
import PublishedIcon from 'assets/images/hourglass-icon.svg';
import unpublishedIcon from 'assets/images/not-icon.svg';

const ScheduleEvent = (props) => {
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.schedule.filters);
  const workersData = useSelector((state) => state.schedule.resources.workers);
  const { hiddenResources } = useHiddenResources(props);
  const { isToggleHeightBtn } = useToggleHeightBtn(props);
  const { formattedHoursRange } = useFormattedHoursRange(props.sheet);
  const { getEquipmentInfo } = useGetEquipmentInfo(
    props.allSheetsThisDay.flatMap((el) => el.equipment)
  );
  const isSomeContent = useMemo(() => {
    const { sheet } = props;
    return Boolean(sheet.notes || sheet.workers.length || sheet.equipment.length);
  }, [props.sheet]);

  const [isFullHeight, setIsFullHeight] = useState(false);
  const toggleFullHeight = (e) => {
    e.stopPropagation();
    setIsFullHeight((prev) => !prev);
  };
  const closeFullHeight = () => setIsFullHeight(false);

  const { removeSheet } = useRemoveSheet({ sheetId: props.sheet._id });
  const handleClickDelete = (e) => {
    e.stopPropagation();
    dispatch(
      modalsPageActions.openModal({
        name: 'remove',
        data: 'Are you sure you want to remove this project from the schedule?',
        onSubmit: (e) => removeSheet().then(() => dispatch(modalsPageActions.closeAllModals())),
      })
    );
  };

  const { handleDrop } = useHandleDrop({
    allSheetsThisDay: props.allSheetsThisDay,
    sheetsInView: props.sheetsInView,
    sheet: props.sheet,
  });

  const handleDragStart = (e) => {
    if (!!Object.keys(filters).length)
      return dispatch(
        modalsPageActions.openModal({
          name: 'info',
          data: 'Please, clear filters before moving the Worklog',
        })
      );
    setDragEventData(e);
  };
  const handleDragOver = (e) => e.preventDefault();
  const setDragElementData = (resourceId, resourceType) => (e) => {
    e.dataTransfer.setData('resourceId', resourceId);
    e.dataTransfer.setData('resourceType', resourceType);
    e.dataTransfer.setData('sheetsId', props.sheet._id);
  };
  const setDragEventData = (e) => {
    e.dataTransfer.setData('sheetsId', props.sheet._id);
    e.dataTransfer.setData('prevPosition', props.sheet.grid.position);
  };

  const openWorklogDetails = () => {
    props.navigate(`/schedule/${props.sheet._id}`);
  };

  const doNothing = () => null;

  const getStatusComponent = (sheet) => {
    let imgWrapperName = '';
    let iconSrc = '';
    let text = '';

    if (!sheet.published && !sheet.submittedAt) {
      imgWrapperName = 'unpublishedImgWrapper';
      iconSrc = unpublishedIcon;
      text = 'Not published';
    }
    if (sheet.published) {
      imgWrapperName = 'publishedImgWrapper';
      iconSrc = PublishedIcon;
      text = 'Published';
    }
    if (sheet.submittedAt) {
      imgWrapperName = 'submittedImgWrapper';
      iconSrc = SubmittedIcon;
      text = 'DWL Submitted';
    }
    return (
      <div className={classnames(classes.eventStatusWrapper)}>
        <div className={classnames(classes.accordionItemImgWrapper, classes[imgWrapperName])}>
          <img src={iconSrc} className={classnames(classes.previewCellImg)} alt="status" />
        </div>
        <Typography className={classnames(classes.eventText, classes[imgWrapperName])}>
          {text}
        </Typography>
      </div>
    );
  };

  const {
    classes,
    sheet,
    originalTimeHeight,
    sheetsWithoutForemanIds,
    shouldRenderNextDayBlock,
    shouldRenderPrevDayBlock,
    isGridView,
    isEndNotToday,
    needToChangeHeight,
    isXXLWidth,
    hasAccess,
    canDragResources,
  } = props;

  const showItems = (type) => {
    if (type === 'workers') {
      if (isXXLWidth) return 3;
      return sheet.workers?.length >= 3 ? 3 : sheet.workers.length;
    }
    if (type === 'equipment') {
      if (isXXLWidth) return 3;
      if (sheet.workers?.length >= 3) {
        return 0;
      } else if (!sheet.workers?.length) {
        return 3;
      } else {
        return 3 - sheet.workers?.length;
      }
    }
  };

  const isShort =
    (!isGridView &&
      Boolean(sheet.notes) + Boolean(sheet.workers.length) + Boolean(sheet.equipment.length)) === 1;

  return (
    <>
      <ClickAwayListener onClickAway={isGridView ? closeFullHeight : doNothing}>
        <div
          className={classnames(
            classes.rootEvent,
            !isEndNotToday && classes.overflowHidden,
            isGridView && classes.fixedHeight,
            isGridView && isFullHeight && classes.contentHeight,
            !sheet.reconciled && classes.needReconcile,
            sheetsWithoutForemanIds.includes(sheet._id) && classes.missingCL
          )}
          onClick={openWorklogDetails}
          onDragOver={sheet.canceledAt ? null : handleDragOver}
          onDrop={sheet.canceledAt ? null : handleDrop}
          onDragStart={isGridView ? handleDragStart : null}
          draggable={isGridView}
        >
          {shouldRenderPrevDayBlock && (
            <Typography
              variant="body1"
              className={classnames(
                classes.otherDayBlock,
                sheet.timeOfDay === 'Day' ? classes.dayShift : classes.nightShift,
                isGridView && classes.gridDayShift
              )}
            >
              <img src={ArrowUp} className={classes.blueIcon} />
              <span>Previous day</span>
              <img src={ArrowUp} className={classes.blueIcon} />
            </Typography>
          )}
          <div className={classes.originalTime} style={{ height: originalTimeHeight }} />
          <div
            className={classnames(
              classes.paddingBox,
              isGridView && classes.relativePosition,
              sheet.timeOfDay === 'Day' ? classes.dayShift : classes.nightShift,
              isGridView && classes.gridDayShift,
              !isSomeContent && classes.fullHeight
            )}
          >
            {/* {true ? (
              <Typography variant="h6" className={classes.blueText}>
                DWL Submitted
              </Typography>
            ) : (
              !sheet.published &&
              !isGridView && (
                <Typography variant="h6" className={classes.redText}>
                  Not published
                </Typography>
              )
            )} */}
            {getStatusComponent(sheet)}
            <Typography
              variant="body1"
              color="textSecondary"
              className={classnames(classes.ellipsis, classes.marginBottomBig)}
            >
              {sheet.project.contractor.name}
            </Typography>
            {sheet.submittedAt ||
            sheet.published ||
            !hasPermissionsFor('worklogsFullAccess') ? null : (
              <Button
                color="secondary"
                className={classnames(
                  classes.deleteButton,
                  shouldRenderPrevDayBlock && classes.fixTop
                )}
                onClick={handleClickDelete}
                disableRipple
              >
                <img src={CloseIcon} alt="delete" />
              </Button>
            )}
            <Typography variant="h4" className={classes.header}>
              <span className={classes.marginRightSmall}>
                {createWorklogName(
                  sheet,
                  isGridView ? sheet.submittedAt && hiddenResources : false
                )}
              </span>
              {sheet.project.jobNumber && (
                <span className={classes.projectId}>#{sheet.project.jobNumber}</span>
              )}
            </Typography>
            <Typography
              variant="body1"
              color="textPrimary"
              className={classnames(classes.time, classes.marginBottomBig)}
            >
              {formattedHoursRange}
            </Typography>
          </div>

          {isSomeContent && (
            <>
              <Divider />

              <div
                className={classnames(
                  classes.paddingBox,
                  isGridView && !isFullHeight && classes.hiddenResources,
                  classes.background,
                  classes.container,
                  Boolean(sheet.canceledAt) && classes.canceledGridContainer
                )}
              >
                {Boolean(sheet.canceledAt) && (
                  <div
                    className={classnames(
                      classes.canceledWrapper,
                      isShort ? classes.canceledUp : ''
                    )}
                  >
                    <div className={classes.cancelContent}>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        className={classes.cancelHeader}
                      >
                        <img src={CancelIcon} />
                        Canceled
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        className={classes.cancelNote}
                      >
                        {sheet.cancelNote}
                      </Typography>
                    </div>
                  </div>
                )}

                {!isGridView && Boolean(sheet.notes) && (
                  <>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      className={classes.marginBottomSmall}
                    >
                      Note:
                    </Typography>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      className={classnames(classes.notesText, classes.marginBottom)}
                    >
                      {sheet.notes.substr(0, 50) + '...'}
                    </Typography>
                  </>
                )}

                {Boolean(sheet.workers.length) && (
                  <>
                    {!isGridView && (
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        className={classes.marginBottom}
                      >
                        People:
                      </Typography>
                    )}
                    <div
                      className={classnames(
                        isGridView && isXXLWidth && classes.column,
                        classes.marginBottomSmall
                      )}
                    >
                      {sheet.workers
                        .map((w) => (workersData || []).find((worker) => worker._id === w._id))
                        .filter((w) => !!w)
                        .sort((a, b) => b.foreman - a.foreman)
                        .slice(0, isFullHeight || !isGridView ? Infinity : showItems('workers'))
                        .map((worker) => (
                          <div
                            key={worker._id}
                            className={classnames(classes.marginBottom, classes.itemWrapper)}
                            draggable={
                              typeof canDragResources === 'undefined' ? true : !!canDragResources
                            }
                            onDragStart={setDragElementData(worker._id, 'workers')}
                          >
                            <div
                              className={classnames(classes.flexBox, classes.justifyContentStart)}
                            >
                              <div
                                className={classnames(
                                  classes.photoWrapper,
                                  classes[`${worker.profile.shifts.timeOfDay.toLowerCase()}Border`]
                                )}
                              >
                                <img src={photoPlaceholder} className={classes.photo} />
                              </div>
                              <Typography
                                variant="body1"
                                color="textPrimary"
                                className={classnames(
                                  classes.username,
                                  classes[worker.profile.shifts.timeOfDay.toLowerCase()]
                                )}
                              >
                                {worker.username}
                              </Typography>
                            </div>
                          </div>
                        ))}
                    </div>
                  </>
                )}

                {Boolean(sheet.equipment.length) && (
                  <>
                    {!isGridView && (
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        className={classes.marginBottom}
                      >
                        Equipment:
                      </Typography>
                    )}
                    <div
                      className={classnames(
                        isGridView && isXXLWidth && classes.column,
                        classes.marginBottomSmall
                      )}
                    >
                      {sheet.equipment
                        .slice(0, isFullHeight || !isGridView ? Infinity : showItems('equipment'))
                        .map((item) => (
                          <div
                            key={item._id}
                            className={classnames(classes.marginBottom, classes.itemWrapper)}
                            draggable={
                              typeof canDragResources === 'undefined' ? true : !!canDragResources
                            }
                            onDragStart={setDragElementData(item._id, 'equipments')}
                          >
                            <div
                              className={classnames(
                                classes.equipmentBlock,
                                getEquipmentInfo(item._id, 'className')
                              )}
                              style={{
                                backgroundColor: getEquipmentInfo(item._id, 'color'),
                              }}
                            >
                              <div className={classes.equipmentNumber}>
                                {getEquipmentInfo(item._id, 'name')}
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </>
                )}
              </div>

              {isGridView && isToggleHeightBtn && (
                <Button
                  variant="text"
                  color="primary"
                  onClick={toggleFullHeight}
                  className={classes.toggleBtn}
                  disableRipple
                  disableTouchRipple
                >
                  {isFullHeight ? 'show less' : `+ ${hiddenResources} others`}
                </Button>
              )}
            </>
          )}

          {shouldRenderNextDayBlock && (
            <Typography variant="body1" className={classes.otherDayBlock}>
              <img src={ArrowDown} className={classes.blueIcon} />
              <span>Following day</span>
              <img src={ArrowDown} className={classes.blueIcon} />
            </Typography>
          )}
        </div>
      </ClickAwayListener>
    </>
  );
};

ScheduleEvent.propTypes = {
  classes: propTypes.object.isRequired,
  sheet: propTypes.object.isRequired,
  originalTimeHeight: propTypes.number.isRequired,
  sheetsWithoutForemanIds: propTypes.array.isRequired,
  allSheetsThisDay: propTypes.array.isRequired,
  sheetsInView: propTypes.array.isRequired,
  shouldRenderNextDayBlock: propTypes.bool.isRequired,
  shouldRenderPrevDayBlock: propTypes.bool.isRequired,
  canDragResources: propTypes.bool,
};

export default withStyles(styles)(ScheduleEvent);
