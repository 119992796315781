import { createAction } from '@reduxjs/toolkit';

const setNotes = createAction('notes/setNotes');
const setProjectNotes = createAction('notes/setProjectNotes');
const setNotesFilter = createAction('notes/setNotesFilter');
const setNotesProjectId = createAction('notes/setNotesProjectId');
const setNotesSearchValue = createAction('notes/setNotesSearchValue');
const setAllProjectsNotes = createAction('notes/setAllProjectsNotes');
const setSelctedByIdProjectNotes = createAction('notes/setSelctedByIdProjectNotes');

export default {
  setNotes,
  setProjectNotes,
  setNotesFilter,
  setNotesProjectId,
  setNotesSearchValue,
  setAllProjectsNotes,
  setSelctedByIdProjectNotes,
};
