import React from 'react';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

import './../../styles.scss';

const StatisticBlock = ({ quantity, text, diff, active }) => {
  return (
    <div className={active ? 'info-statistic-block' : 'info-statistic-block-inactive'}>
      <div className={'info-statistic-header'}>
        <p className="info-statistic-quantity">{quantity}</p>
        {diff ? (
          <div className={'diff-wrapper'}>
            <ArrowUpward
              fontSize={'small'}
              style={
                diff > 0
                  ? { marginRight: 5, color: '#0E9745' }
                  : { marginRight: 5, transform: 'rotate(180deg)', color: 'red' }
              }
            />
            <span
              className={'info-statistic-diff'}
              style={diff > 0 ? { color: '#0e9745' } : { color: 'red' }}
            >
              {diff} (MoM)
            </span>
          </div>
        ) : (
          <span className="info-statistic-text">-</span>
        )}
      </div>
      <p className="info-statistic-text">{text}</p>
    </div>
  );
};

export default StatisticBlock;
