import React from 'react';
import '../../styles.scss';
import InfoItem from '../InfoItem/InfoItem';
import isCorrectNumber from '../helpers/isCorrectNumber';
import { MEASUREMENT_UNITS, MEASURE_UNITS_BY_SYSTEM } from 'common/measurements';

function FieldTechRole({ data, settings }) {
  return (
    <>
      {data && (
        <>
          <h2 className="info-block-title">Projects</h2>
          <div className="cards-wrapper">
            {!!data.sheets && (
              <>
                <InfoItem
                  quantity={isCorrectNumber(data.sheets.week)}
                  info={'This week'}
                  sizeClass={'small'}
                />
                <InfoItem
                  quantity={isCorrectNumber(data.sheets.month)}
                  info={'This month'}
                  sizeClass={'small'}
                />
                <InfoItem
                  quantity={isCorrectNumber(data.sheets.year)}
                  info={'This year'}
                  sizeClass={'small'}
                />
              </>
            )}
          </div>
          <h2 className="info-block-title">Distance Striped YTD</h2>
          <div className="cards-wrapper">
            {!!data.distanceStriped && (
              <>
                <InfoItem
                  quantity={isCorrectNumber(data.distanceStriped.liquidMarking)}
                  info={'Liquid Marking'}
                  currency={MEASURE_UNITS_BY_SYSTEM[settings.measurement]?.[MEASUREMENT_UNITS.length]}
                />
                <InfoItem
                  quantity={isCorrectNumber(data.distanceStriped.eradication)}
                  info={'Eradication'}
                  currency={MEASURE_UNITS_BY_SYSTEM[settings.measurement]?.[MEASUREMENT_UNITS.length]}
                />
              </>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default FieldTechRole;
