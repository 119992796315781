import { Box, withStyles } from '@material-ui/core';
export const styles = (theme) => ({
  jobTitle: {
    color: '#78797E',
    fontSize: 16,
    letterSpacing: 0.2,
    lineHeight: '25px',
    marginBottom: 24,
    [theme.breakpoints.down('md')]: {
      color: '#202020',
      fontSize: '20px',
      fontWeight: 500,
      letterSpacing: '0.25px',
      margin: '5px 0 18px',
    },
    '@media print': {
      color: '#78797E',
      fontSize: 16,
      letterSpacing: 0.2,
      margin: '0',
      marginBottom: 24,
      fontWeight: 400,
    }
  },
  jobShopTimeDesc: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    '@media print': {
      display: 'block',
    }
  },
});

export const Container = withStyles({
  root: {
    padding: '13px 16px 32px 16px',
    '@media (max-width: 999px)': {
      padding: '5px 0 0px',
    },
    '@media print': {
      padding: '13px 16px 32px 16px',
    }
  },
})(Box);
