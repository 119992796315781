import { getOptions } from 'helpers/getOptions';
import { $fetch } from 'http/fetch';
import { catchError } from 'http/index';
import $api from 'http/index';

const asyncTimeout = async (time = 1000) => new Promise((res) => setTimeout(res, time));

export const downloadFile = (blob, filename) => {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', filename);

  document.body.appendChild(link);

  link.click();

  link.parentNode.removeChild(link);
};

/**
 * @param {[{ blob: Blob, name: string }]} files array of files, file is a base64 string
 */
export const downloadMultipleFiles = async (files) => {
  if (!Array.isArray(files) || !files?.length) return;

  const link = document.createElement('a');
  document.body.appendChild(link);

  for (const file of files) {
    const url = window.URL.createObjectURL(file.blob);
    link.setAttribute('href', url);
    link.setAttribute('download', file.name);

    link.click();
    await asyncTimeout(200); // wait;
  }

  link.parentNode.removeChild(link);
};

export const exportData = async (route, queryParamsObj, openSnackBar) => {
  try {
    const searchParams = new URLSearchParams(queryParamsObj).toString();

    const response = await $fetch(`${process.env.REACT_APP_BASE_URL}/${route}?${searchParams}`, {
      headers: { ...getOptions().headers, Accept: 'multipart/form-data' },
      method: 'GET',
    });
    if (response.status >= 300) throw new Error('Something went wrong');

    const formData = await response.formData();

    const files = [];
    for (const pair of formData.entries()) {
      files.push({ name: `${pair[0]}.csv`, blob: new Blob([pair[1]], { type: 'text/csv' }) });
    }
    if (!files.length) {
      openSnackBar('success', 'Nothing to export');
    }

    await downloadMultipleFiles(files);
    openSnackBar('success', `Successfully Exported`);
  } catch (error) {
    openSnackBar('error', catchError(error));
  }
};

export const importData = async (route, data, openSnackBar) => {
  try {
    const response = await $api.post(`${process.env.REACT_APP_BASE_URL}/${route}`, data, {
      ...getOptions('multipart/form-data'),
    });

    // if (Object.keys(response?.data?.errors || {}).length) {
    //   const res = Object.entries(response?.data?.errors)
    //     .map(([line, error]) => `Line ${line}: ${error}`)
    //     .join('\n');
    //   throw new Error(res);
    // }
    if (response?.data?.createdEntities) {
      openSnackBar('success', `Successfully Imported ${response?.data?.createdEntities}`);
    } else if (!response?.data?.createdEntities && Object.getOwnPropertyNames(response?.data.errors)?.length) {
      openSnackBar('error', `No valid rows to import. Please check the file you're trying to import`);
    }
  } catch (error) {
    if (error.response.status === 400) {
      openSnackBar('error', `The file can't be processed — it might be invalid or missing some information. Please check the file and try again`);
    } else {
      openSnackBar('error', error?.response?.data?.message || error?.message);
    }
  }
};
