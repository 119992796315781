import { useState } from 'react';
import Typography from '@material-ui/core/Typography';

export const Tooltip = ({ title, text, children }) => {
  const [isInfoOpen, setInfoOpen] = useState(false);

  return (
    <div style={{ display: 'flex' }}>
      <Typography variant="body1" color="textSecondary">
        {title}
      </Typography>
      <div
        className={'tool-info-icon'}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setInfoOpen(true);
          setTimeout(() => {
            setInfoOpen(false);
          }, 3000);
        }}
      >
        i
        <div className={'pass-info-block'} style={isInfoOpen ? { display: 'block' } : {}}>
          {text}
          {children}
        </div>
      </div>
    </div>
  );
};
