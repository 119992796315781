import { createReducer } from '@reduxjs/toolkit';
import permissionsActions from './permissionsActions';

const initialState = {
  loading: 0,
};

const permission = createReducer(initialState, {
  [permissionsActions.setLoading]: (state, { payload }) => ({
    ...state,
    loading: payload ? state.loading + 1 : (state.loading || 1) - 1,
  }),
  [permissionsActions.setPermissions]: (state, { payload }) => ({ ...state, ...payload }),
  [permissionsActions.clearPermissions]: () => initialState,
});

export default permission;
