import React from 'react';
import { Label, Select } from '../../base';
import { ExpandMore } from '@material-ui/icons';
import { isIOS, Option } from 'components/Option';

export const ProjectInput = (props) => {
  const { field, projects, label } = props;

  const renderProjects = projects.map((value, index) => (
    <Option key={index} value={value._id}>
      {value.name}
    </Option>
  ));

  return (
    <div style={{ marginBottom: '20px', width: '340px' }}>
      <Label>{label}</Label>
      <Select
        native={isIOS}
        fullWidth={true}
        IconComponent={ExpandMore}
        variant="outlined"
        {...field}
        inputProps={{ 'aria-label': 'Project name' }}
      >
        <Option key={0} value={null}>
          -
        </Option>
        {renderProjects}
      </Select>
    </div>
  );
};

export default ProjectInput;

