import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core';
import {DialogTitle as BaseDialogTitle} from "../base";

export const EmployeeName = withStyles({
    body1: {
        color: '#A4A5B3',
        fontSize: 14,
        lineHeight: '21px',
        marginBottom: 34,
    },
})(Typography);

export const DialogTitle = withStyles({})(BaseDialogTitle);

export const Day = withStyles({
    body1: {
        color: '#202020',
        fontSize: 14,
        letterSpacing: '0.17px',
        lineHeight: '21px',
        marginBottom: 34,
    },
})(Typography);
