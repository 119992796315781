import { createAction } from '@reduxjs/toolkit';

const setClockInStatus = createAction('kiosk/setClockInStatus');
const setSummary = createAction('kiosk/setSummary');
const setError = createAction('kiosk/setError');
const setAlert = createAction('kiosk/setAlert');
const setPto = createAction('kiosk/setPto');
const login = createAction('kiosk/login');
const logout = createAction('kiosk/logout');
const setCompany = createAction('kiosk/setCompany');
const setSpecialProfilePermissions = createAction('kiosk/setSpecialProfilePermissions');
const clearPersonalProfile = createAction('kiosk/clearPersonalProfile');
const setUsers = createAction('kiosk/setUsers');
const addUsers = createAction('kiosk/addUsers');
const editUser = createAction('kiosk/editUser');
const deleteUser = createAction('kiosk/deleteUser');
const setSocketConnectionStatus = createAction('kiosk/setSocketConnectionStatus');

// Sockets
const socketConnect = createAction('kiosk/socketConnect');
const socketDisconnect = createAction('kiosk/socketDisconnect');

export default {
  setClockInStatus,
  setSummary,
  setError,
  setAlert,
  setPto,
  login,
  logout,
  setCompany,
  setSpecialProfilePermissions,
  clearPersonalProfile,
  socketConnect,
  socketDisconnect,
  setUsers,
  addUsers,
  editUser,
  deleteUser,
  setSocketConnectionStatus,
};
