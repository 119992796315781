import React, { useState, useEffect, useCallback } from 'react';
import propTypes from 'prop-types';
import CompanyDayOffList from './components/CompanyDayOffList';
import $api from 'http/index';
import { getOptions } from 'helpers/getOptions';

const CompanyDayOffTab = ({
  openSnackbar,
  holidayDates,
  toggleHolidayModal,
  refreshHoliday,
}) => {
  const [holidaysState, updateHolidaysState] = useState([]);

  const getHolidays = useCallback(async () => {
    const queryParams = new URLSearchParams();
    queryParams.append('from', holidayDates[0].toISOString());
    queryParams.append('to', holidayDates[1].toISOString());
    queryParams.append('isIndividual', 'true');
    try {
      const res = await $api.get(
        `${process.env.REACT_APP_BASE_URL}/holidays?${queryParams}`,
        getOptions()
      );
      updateHolidaysState(res.data);
    } catch (e) {
      openSnackbar('error', e.reason);
    }
  }, [holidayDates]);

  useEffect(() => {
    getHolidays();
  }, [holidayDates, refreshHoliday]);

  return (
    <>
      <CompanyDayOffList
        openSnackbar={openSnackbar}
        holidays={holidaysState}
        toggleHolidayModal={toggleHolidayModal}
        getHolidays={getHolidays}
      />
    </>
  );
};

CompanyDayOffTab.propTypes = {
  openSnackbar: propTypes.func.isRequired,
  holidays: propTypes.array.isRequired,
  isDataLoading: propTypes.bool.isRequired,
  holidayDates: propTypes.array.isRequired,
  toggleHolidayModal: propTypes.func.isRequired,
};

export default CompanyDayOffTab;