import {makeStyles} from "@material-ui/core/styles";

const DAY_WIDTH = 44;
const DAY_HEIGHT = 44;

export default makeStyles(() => ({
    root: {
        background: '#FFFFFF',
        borderRadius: 8,
        fontFamily: 'Poppins',
        overflow: 'hidden',
        display: 'inline-block',
        padding: '16px 0 0 0',
        boxShadow: '0 2px 10px 0 rgba(0,0,0,0.06)',
        '& abbr': {
            cursor: 'pointer'
        },
        '& abbr[title]': {
            borderBottom:'1px dotted #ddd',
        },
        '& *': {
            outline: 'none',
            border: 'none',
            fontFamily: 'Poppins',
            fontWeight: 500
        },
        '@global': {
            '.react-calendar': {
                border: 'none',
                width: 320,
                margin: '16px auto 22px',
                backgroundColor: 'transparent',
            },
            '.react-calendar__month-view__weekdays': {
                justifyContent: 'center',
            },
            '.react-calendar__month-view__weekdays__weekday': {
                width: DAY_WIDTH,
                fontWeight: 'normal',
                marginBottom: 10,
                color: '#A9B5BD',
                textAlign: 'center',
                textTransform: 'none',
                fontSize: 12,
                flexBasis: `${DAY_WIDTH}px !important`,
                lineHeight: '14px',
                '& *': {
                    textDecoration: 'none',
                },
            },
            '.react-calendar__month-view__days__day--neighboringMonth': {
                color: '#D9DAE3 !important',
                '&:hover': {
                    color: '#5A87EF !important',
                },
            },
            '.react-calendar__month-view__days': {
                justifyContent: 'center',
            },
            '.react-calendar__tile': {
                padding: 2,
                width: DAY_WIDTH,
                height: DAY_HEIGHT,
                maxHeight: DAY_HEIGHT,
                maxWidth: DAY_WIDTH,
                flexBasis: `${DAY_WIDTH}px !important`,
                color: '#202020',
                textAlign: 'center',
                fontSize: 14,
                cursor: 'pointer',
                lineHeight: '20px',
                backgroundPosition: 'center',
                transition: 'background 0.4s',
                '&:hover': {
                    background: 'transparent',
                    color: '#5A87EF',
                },
                '&:active': {
                    backgroundColor: 'transparent',
                },
            },
            '.react-calendar__tile--active:enabled': {
                backgroundColor: 'transparent',
                color: '#5A87EF',
                position: 'relative',
                '&:after': {
                    position: 'absolute',
                    bottom: 1,
                    width: 10,
                    left: (DAY_WIDTH - 10) / 2,
                    content: '""',
                    display: 'inline-block',
                    height: 2,
                    backgroundColor: '#5A87EF'
                },
            },
            '.react-calendar__tile--now': {
                backgroundColor: 'transparent',
            },
        },
    },
}))