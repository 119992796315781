import { parse } from 'date-fns';

export const getDate = (date, hours, minutes, dayPart, getTimeFormatHook) => {
  if (!hours) return new Date();

  const startHoursObj = [date, hours, minutes, dayPart];
  return parse(
    getTimeFormatHook(startHoursObj).formattedTimeDate,
    getTimeFormatHook(startHoursObj).formatForDateTimePars,
    new Date()
  );
};
