import React from 'react';
import cs from 'classnames';
import './styles.scss';
import formatHours from '../../utils/formatHours';
import formatNumber from '../../utils/formatNumber';

const Summary = ({
  data: { weekHours, hoursThisDay, weekShopTime, weekOvertime },
  className,
  ...props
}) => (
  <div className={cs('timeclock-summary', className)} {...props}>
    <div className={'timeclock-summary-item'}>
      <div className={'timeclock-summary-item-label'}>Hours This Week:</div>
      <div className={'timeclock-summary-item-time'}>
        {formatNumber(weekHours)}
      </div>
      <div className={'timeclock-summary-item-measure'}>hours</div>
    </div>
    <div className={'timeclock-summary-item'}>
      <div className={'timeclock-summary-item-label'}>Overtime This Week:</div>
      <div className={'timeclock-summary-item-time'}>
        {formatNumber(weekOvertime)}
      </div>
      <div className={'timeclock-summary-item-measure'}>hours</div>
    </div>

    <div className={'timeclock-summary-item'}>
      <div className={'timeclock-summary-item-label'}>Shop Time:</div>
      <div
        className={cs('timeclock-summary-item-time', {
          ['timeclock-summary-item-time-danger']: weekShopTime < 0,
        })}
      >
        {formatNumber(weekShopTime)}
      </div>
    </div>
  </div>
);

export default Summary;
