import React, { useState } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

import PageLoader from '../PageLoader';
import styles from './styles';

const ConfirmDialog = ({
  classes,
  isOpen,
  onClose,
  onSubmit,
  text,
  cancelBtn = 'No',
  confirmBtn = 'Yes',
  disableEscape = false,
  loadingOnSubmit = false,
}) => {
  const [isLoading, setLoading] = useState(false);
  const handleSubmit = async () => {
    if (loadingOnSubmit) {
      setLoading(true);
    }
    await onSubmit();
    setLoading(false);
  };
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        classes: {
          root: classes.dialogRoot,
        },
      }}
      disableEscapeKeyDown={disableEscape}
    >
      <PageLoader loading={isLoading} small>
        <>
          <DialogTitle id="alert-dialog-title">{text}</DialogTitle>
          <DialogActions>
            <Button
              onClick={onClose}
              variant="contained"
              color="primary"
              className={classes.cancelBtn}
              disableTouchRipple
            >
              {cancelBtn}
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              className={classes.confirmBtn}
              disableTouchRipple
            >
              {confirmBtn}
            </Button>
          </DialogActions>
        </>
      </PageLoader>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  classes: propTypes.object.isRequired,
  isOpen: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  onSubmit: propTypes.func.isRequired,
  text: propTypes.string.isRequired,
  cancelBtn: propTypes.string,
  confirmBtn: propTypes.string,
  disableEscape: propTypes.bool,
  loadingOnSubmit: propTypes.bool,
};

export default withStyles(styles)(ConfirmDialog);
