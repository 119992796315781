import React, { Component } from 'react';
import PeoplePage from './PeoplePage';
import SnackBar from '../../components/SnackBar';

class People extends Component {
  state = {
    snackbar: {
      isOpen: false,
      text: '',
      type: 'success',
    },
  };

  openSnackbar = (type, text) => this.setState({ snackbar: { isOpen: true, type, text } });

  closeSnackbar = () => this.setState({ snackbar: { ...this.state.snackbar, isOpen: false } });

  render() {
    const { snackbar } = this.state;

    return (
      <>
        <PeoplePage openSnackbar={this.openSnackbar} />
        <SnackBar closeSnackbar={this.closeSnackbar} {...snackbar} />
      </>
    );
  }
}

export default People;
