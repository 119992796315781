import React, { useState, useEffect } from 'react';
import LoadingSpinner from '../../components/LoadingSpinner';
import ProjectAndWorklogNotes from './ProjectAndWorklogNotes';
import SnackBar from '../../components/SnackBar';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import notesPageActions from 'store/notes/notesPageActions';
import { getNotesProjects } from 'store/notes/notesPageOperations';

const NotesByProject = ({}) => {
  const dispatch = useDispatch();
  const { projectId } = useParams();
  dispatch(notesPageActions.setNotesProjectId(projectId));

  const project = useSelector(store => store.notes.project)

  useEffect(() => {
    dispatch(getNotesProjects());
  }, []);

  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    text: '',
    type: 'success',
  });

  const openSnackbar = (type, text = 'Oops... Something went wrong') =>
    setSnackbar({ isOpen: true, type, text });

  const closeSnackbar = () => setSnackbar((prev) => ({ ...prev, isOpen: false }));

  return (
    <>
      <ProjectAndWorklogNotes
        project={project}
        openSnackbar={openSnackbar}
      />
      <SnackBar closeSnackbar={closeSnackbar} {...snackbar} />
    </>
    // )
  );
};

export default NotesByProject;
