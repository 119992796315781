
  export function findUsedWorkers(sheets) {
    let uniqueWorkers = [];
    sheets.forEach(sheet => {
      const canceledAt = sheet.sheet ? sheet.sheet.canceledAt : sheet.canceledAt;
      if (sheet.workers && sheet.workers.length !== 0 && !canceledAt) {
        sheet.workers.forEach(worker => {
          let uniqueWorker = uniqueWorkers.find(w => w._id === worker._id);
          if (!uniqueWorker) {
            uniqueWorkers.push(worker);
          }
        });
      }
    });
    return uniqueWorkers;
  }

  export function findUsedEquipment (sheets) {
    let uniqueEquipment = [];
    sheets.forEach(sheet => {
      const canceledAt = sheet.sheet ? sheet.sheet.canceledAt : sheet.canceledAt;
      if (sheet.equipment && sheet.equipment.length !== 0 && !canceledAt) {
        sheet.equipment.forEach(equipment => {
          let uniqueEq = uniqueEquipment.find(w => w._id === equipment._id);
          if (!uniqueEq) {
            uniqueEquipment.push(equipment);
          }
        });
      }
    });
    return uniqueEquipment;
  }

