import $api from 'http/index';
import { getOptions } from 'helpers/getOptions';

export const equipmentCheckConflicts = async (data, sheetId) => {
    const res = await $api.post(
      `${process.env.REACT_APP_BASE_URL}/sheets/${sheetId}/equipment/check-conflicts`,
      data,
      {
        ...getOptions(),
      }
    );
};

export const workersCheckConflicts = async (data, sheetId) => {

    const res = await $api.post(
      `${process.env.REACT_APP_BASE_URL}/sheets/${sheetId}/workers/check-conflicts`,
      data,
      {
        ...getOptions(),
      }
    );

};


