import { permissions as allPermissions } from 'api/users/users.permissions';

const routePermissions = {
  Home: Object.keys(allPermissions.All),
  Projects: ['projectsRead', 'projectsFullAccess', 'projectsEdit'],
  'Profile settings': Object.keys(allPermissions.All),
  Notes: ['notesRead', 'notesFullAccess'],
  'My Schedule': ['assignedSchedule'],
  'Remote Kiosk': ['remoteKiosk'],
  'Mobile Kiosk': ['kioskModeMobile'],
  Availability: [
    'availabilityRead',
    'availabilityFullAccess',
    'availabilityEquipmentEdit',
    'availabilityPeopleEdit',
  ],
  Cip: ['cipProjectsFullAccess', 'cipFullAccess'],
  'Cip Hours': ['cipFullAccess'],
  'Cip Projects': ['cipProjectsFullAccess', 'cipFullAccess'],
  Equipment: ['equipmentRead', 'equipmentFullAccess'],
  'People Page': ['usersBasicRead', 'usersFullAccess'],
  Settings: ['settings'],
  Supplies: ['suppliesFullAccess', 'suppliesEdit'],
  Timeline: ['timelineRead', 'timelineFullAccess'],
  'Time Clock': ['timeClockRead', 'timeClockFullAccess'],
  Schedule: ['scheduleRead', 'scheduleFullAccess'],
  Worklog: ['worklogsRead', 'worklogsFullAccess', 'worklogsEdit'],
  Resources: [
    'usersBasicRead',
    'usersFullAccess',
    'equipmentRead',
    'equipmentFullAccess',
    'suppliesFullAccess',
    'availabilityRead',
    'availabilityFullAccess',
    'timeClockRead',
    'timeClockFullAccess',
    'cipProjectsFullAccess',
    'cipFullAccess',
    'settings',
  ],
};
export default routePermissions;
