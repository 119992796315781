import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { Grid, TextField, Typography } from '@material-ui/core';
import { addHours, format } from 'date-fns';

import { isIOS } from '../../../Option';
import { useTimeStates } from 'hooks/useTimeStates';
import { createOptions } from 'helpers/createOptions';
import { getDate } from 'helpers/getDate';
import { validateDatesOverlapping, validateEndAfterStart } from '../../../Kiosk/helpers/validate';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';
import { convertToArr } from 'helpers/_date-helpers';

const TimeSelectForm = ({
  classes,
  type,
  control,
  label,
  name,
  index,
  errors,
  setError,
  clearErrors,
  values,
  setValue,
}) => {
  const { hours, minutes, dayPart } = useTimeStates({ takeSettingsHours: true });
  const { dateFormat } = useDateSettingsFormat();
  const getTimeFormatHook = useTimeSettingsFormat();

  const fieldNames = useMemo(
    () =>
      getTimeFormatHook().is12Format
        ? [`${name}Hours[${index}]`, `${name}Minutes[${index}]`, `${name}AmPm[${index}]`]
        : [`${name}Hours[${index}]`, `${name}Minutes[${index}]`],
    [name]
  );

  const options = useMemo(() => [hours, minutes, dayPart].map((opt) => opt.map(createOptions)), []);

  const setNextEndDate = (nextEndDate, startHours, startAmPm) => {
    const [endDate, endHours, endAmPm] = nextEndDate;

    setValue(`endDate[${index}]`, endDate);
    setValue(`endHours[${index}]`, endHours);
    setValue(`endAmPm[${index}]`, endAmPm);

    validateDatesOverlapping(
      {
        ...values,
        startHours,
        startAmPm,
        endDate: values.endDate.map((value, i) => (i === index ? endDate : value)),
        endHours: values.endHours.map((value, i) => (i === index ? endHours : value)),
        endAmPm: values.endAmPm?.map((value, i) => (i === index ? endAmPm : value)),
      },
      setError,
      clearErrors,
      getTimeFormatHook
    );
  };

  const handleStartHoursChange = (value) => {
    setValue(`endHours[${index}]`, value);
  };

  const handleStartMinutesChange = (value) => {
    setValue(`endMinutes[${index}]`, value);
    validateDatesOverlapping(
      {
        ...values,
        startMinutes: values.startMinutes.map((val, i) => (i === index ? value : val)),
        endMinutes: values.endMinutes.map((val, i) => (i === index ? value : val)),
      },
      setError,
      clearErrors,
      getTimeFormatHook
    );
  };

  const handleStartAmPmChange = (value) => {
    setValue(`endHours[${index}]`, values.startHours[index]);
    setValue(`endAmPm[${index}]`, value);
  };

  const handleChange = (field, value) => {
    if (name === 'end') {
      const nextValues = {
        ...values,
        [field.split('[')[0]]: [
          index === 0 ? value : values[field.split('[')[0]][0],
          index === 1 ? value : values[field.split('[')[0]][1],
        ],
      };
      return (
        validateEndAfterStart(nextValues, setError, clearErrors, getTimeFormatHook) &&
        values.startDate[1] &&
        validateDatesOverlapping(nextValues, setError, clearErrors, getTimeFormatHook)
      );
    }

    return [handleStartHoursChange, handleStartMinutesChange, handleStartAmPmChange][
      fieldNames.indexOf(field)
    ](value);
  };

  const error = () => {
    return errors.startHours && errors.startHours[index];
  };

  return (
    <Grid item xs={12} md={6} className={classes.zeroPaddingTop}>
      <label htmlFor={fieldNames[0]}>
        <Typography variant="body1" color="textSecondary">
          {label}:
        </Typography>
        <div className={classes.flexBox}>
          {fieldNames.map((field, i) => (
            <Controller
              key={field}
              name={field}
              control={control}
              render={({ value, onChange }) => (
                <TextField
                  select
                  id={field}
                  error={error(field)}
                  className={classes.textField}
                  variant="outlined"
                  value={value}
                  onChange={(e) => {
                    const { value } = e.target;
                    onChange(value);
                    handleChange(field, value);
                  }}
                  SelectProps={{
                    native: isIOS,
                  }}
                >
                  {options[i]}
                </TextField>
              )}
            />
          ))}
        </div>
        {errors[`${name}Hours`] && errors[`${name}Hours`][index] ? (
          <div className={classes.error}>{errors[`${name}Hours`][index].message}</div>
        ) : null}
      </label>
    </Grid>
  );
};

export default TimeSelectForm;
