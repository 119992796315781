import { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const EditModal = ({ classes, isOpen, onClose, onSave, info }) => {
  const [value, setVaue] = useState(info || '');

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        classes: {
          root: classes.dialogRoot,
        },
      }}
    >
      <div className={classes.title}>Edit description</div>
      <IconButton
        className={classes.closeButton}
        onClick={onClose}
        aria-label="Close"
        disableRipple
      >
        <CloseIcon />
      </IconButton>
      <TextField
        multiline
        rows={5}
        variant="outlined"
        value={value}
        onChange={(e) => setVaue(e.target.value)}
      />
      <DialogActions className={classes.actions}>
        <Button
          onClick={onClose}
          variant="outlined"
          color="secondary"
          disableTouchRipple
          fullWidth={true}
        >
          Close
        </Button>
        <Button
          onClick={onSave(value)}
          variant="outlined"
          color="primary"
          disableTouchRipple
          fullWidth={true}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(EditModal);
