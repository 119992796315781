import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

import NoteDetails from './components/NoteDetails';
import PageLoader from '../../../../components/PageLoader';
import styles from './styles';
import { getProjectNotes } from 'store/notes/notesPageOperations';
import { useDispatch } from 'react-redux';
import $api from 'http/index';

const validation = (values) => {
  const errors = {};
  if (!values.text.trim()) {
    errors.text = "This field can't be blank";
  }
  return errors;
};

const ProjectNoteEditingForm = ({
  classes,
  closeDrawer,
  note,
  openSnackbar,
  deleteNote,
  restoreNote,
  setNoteIdToResolve,
}) => {
  const dispatch = useDispatch()
  const [files, setFiles] = useState([]);
  const [filesPreviewSources, setFilesPreviewSources] = useState([]);
  const [previousUploadedFiles, setPreviousUploadedFiles] = useState([]);
  const [previousUploadedFilesToDelete, setPreviousUploadedFilesToDelete] = useState([]);

  useEffect(() => {
    setPreviousUploadedFiles(note.images);
  }, []);

  const readURL = (files) => {
    if (files.length) {
      const reader = new FileReader();
      reader.onload = (e) => setFilesPreviewSources((prev) => [...prev, e.target.result]);
      reader.readAsDataURL(files[0]);
    }
  };

  const handleFileChange = (e) => {
    const addedFiles = e.target.files;
    readURL(addedFiles);
    setFiles((prev) => [...prev, addedFiles]);
  };

  const deleteImage = (index) => () => {
    setFiles((prev) => prev.filter((el, i) => i !== index));
    setFilesPreviewSources((prev) => prev.filter((el, i) => i !== index));
  };

  const deletePreviousUploadedFiles = (index) => () => {
    setPreviousUploadedFiles((prev) => prev.filter((el, i) => el !== index));
    setPreviousUploadedFilesToDelete((prev) => [...prev, index]);
  };

  const initialValues = {
    projectId: note.projectId,
    text: note.text,
    highPriority: note.highPriority,
  };

  return (
    <div className={classes.formWrapper} role="presentation">
      <Formik
        validate={validation}
        validateOnChange={false}
        validateOnBlur={true}
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting }) => {

          const uploadedFiles = files.map((el) => el[0]);

          const options = {
            headers: {
              'X-Auth-Token': localStorage.getItem('authorization-token'),
              'Content-Type': 'multipart/form-data',
            },
          };
          const requestData = new FormData();
          if(previousUploadedFilesToDelete.length){
            values.images = note?.images.filter( img => !previousUploadedFilesToDelete.includes(img))
          }
          if (!previousUploadedFilesToDelete.length){
            values.images = note.images || []
          }
          requestData.append('data', JSON.stringify(values));
          if (files.length) {
            for (let i = 0; i < uploadedFiles.length; i++) {
              requestData.append(`images`, uploadedFiles[i]);
            }
          }
          try {
            await $api.put(
              `${process.env.REACT_APP_BASE_URL}/notes/${note._id}`,
              requestData,
              options
            );
            await dispatch(getProjectNotes());
            openSnackbar('success', 'Successfully Updated!');
            closeDrawer()
          } catch (e) {}
        }}
      >
        {({ errors, touched, values, handleChange, handleBlur, isSubmitting }) => (
          <PageLoader loading={isSubmitting}>
            <Form autoComplete="off" className={classes.formFlex}>
              <div>
                <Typography variant="h3" style={{ marginBottom: '20px' }}>
                  Edit Note
                </Typography>

                <IconButton
                  className={classes.closeButton}
                  onClick={closeDrawer}
                  aria-label="Close"
                  disableTouchRipple
                >
                  <CloseIcon />
                </IconButton>

                <Divider />

                <NoteDetails
                  note={note}
                  filesPreviewSources={filesPreviewSources}
                  previousUploadedFiles={previousUploadedFiles}
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  handleFileChange={handleFileChange}
                  deleteImage={deleteImage}
                  deletePreviousUploadedFiles={deletePreviousUploadedFiles}
                  deleteNote={deleteNote}
                  restoreNote={restoreNote}
                  setNoteIdToResolve={setNoteIdToResolve}
                />
              </div>

              <div className={classes.buttonHolder}>
                <Button
                  color="secondary"
                  variant="outlined"
                  className={classes.cancelButton}
                  onClick={closeDrawer}
                  disabled={isSubmitting}
                  disableTouchRipple
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  variant="outlined"
                  className={classes.saveButton}
                  disabled={isSubmitting}
                  disableTouchRipple
                >
                  Save
                </Button>
              </div>
            </Form>
          </PageLoader>
        )}
      </Formik>
    </div>
  );
};

ProjectNoteEditingForm.propTypes = {
  classes: propTypes.object.isRequired,
  closeDrawer: propTypes.func.isRequired,
  note: propTypes.object.isRequired,
  openSnackbar: propTypes.func.isRequired,
  deleteNote: propTypes.func.isRequired,
  restoreNote: propTypes.func.isRequired,
  setNoteIdToResolve: propTypes.func.isRequired,
};

export default withStyles(styles)(ProjectNoteEditingForm);
