export default (theme) => ({
  paddingBottom: {
    paddingBottom: 10,
  },
  photoWrapper: {
    borderRadius: '50%',
    border: `1px solid ${theme.palette.textColor.blue}`,
    borderTopColor: 'transparent',
    borderBottomColor: 'transparent',
    width: 22,
    height: 22,
    transform: 'rotate(135deg)',
    minWidth: 22,
  },
  photo: {
    display: 'block',
    transform: 'rotate(-135deg)',
    height: 20,
    maxWidth: 20,
    borderRadius: '50%',
    margin: '0 auto',
  },
  flexBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  inlineFlex: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  name: {
    marginLeft: 15,
    wordBreak: 'break-word',
    fontSize: 14,
  },
  day: {
    color: theme.palette.textColor.yellow,
  },
  night: {
    color: theme.palette.textColor.darkBlue,
  },
  inactive: {
    color: theme.palette.textColor.secondary,
  },
  dayBorder: {
    border: `1px solid ${theme.palette.textColor.yellow}`,
  },
  nightBorder: {
    border: `1px solid ${theme.palette.textColor.darkBlue}`,
  },
  inactiveBorder: {
    border: `1px solid ${theme.palette.textColor.secondary}`,
  },
  activeButton: {
    padding: 0,
    fontWeight: 300,
    color: theme.palette.textColor.primary,
    minWidth: 'auto',
    '&:hover': {
      color: theme.palette.textColor.primary,
      background: `${theme.palette.background.default} !important`,
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
      background: `${theme.palette.background.default} !important`,
    },
  },
  inactiveButton: {
    padding: 0,
    fontWeight: 300,
    color: theme.palette.textColor.secondary,
    minWidth: 'auto',
    '&:hover': {
      color: theme.palette.textColor.secondary,
      background: `${theme.palette.background.default} !important`,
    },
    '&:focus': {
      color: theme.palette.textColor.secondary,
      background: `${theme.palette.background.default} !important`,
    },
  },
  closeButton: {
    marginLeft: 15,
    padding: 0,
    color: theme.palette.textColor.red,
    '&:hover': {
      color: theme.palette.textColor.red,
      background: theme.palette.background.default,
    },
    '&:focus': {
      color: theme.palette.textColor.red,
      background: theme.palette.background.default,
    },
  },
  inlineBlock: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  secondaryText: {
    color: theme.palette.textColor.secondary,
    fontSize: '0.8rem',
    fontWeight: 400,
    marginRight: 10,
  },
  rtsBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  hoursBlock: {
    marginBottom: 10,
  },
  textCenter: {
    textAlign: 'center',
  },
  addButton: {
    padding: 0,
    color: theme.palette.textColor.primary,
    '&:hover': {
      color: theme.palette.textColor.primary,
      background: `${theme.palette.background.default} !important`,
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
      background: `${theme.palette.background.default} !important`,
    },
  },
  removeButton: {
    padding: 0,
    color: theme.palette.textColor.red,
    '&:hover': {
      color: theme.palette.textColor.red,
      background: `${theme.palette.background.default} !important`,
    },
    '&:focus': {
      color: theme.palette.textColor.red,
      background: `${theme.palette.background.default} !important`,
    },
  },
  error: {
    color: theme.palette.textColor.red,
    padding: '0 5px',
    fontSize: 11,
    margin: '0 auto 5px',
  },
});
