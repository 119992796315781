import homePageActions from './homePageActions';
import { getTimeZone } from '../../helpers/getTimeZone';
import $api from 'http/index';

export const fetchHomePageData = () => async (dispatch) => {
  const options = {
    headers: {
      'X-Auth-Token': localStorage.getItem('authorization-token'),
      'X-User-TZ': getTimeZone(),
    },
  };
  try {
    const response = await $api.get(`${process.env.REACT_APP_BASE_URL}/homepage`, options);
    dispatch(homePageActions.getHomePageData(response.data));
  } catch (error) {
    if (error.statusCode > 299) throw new Error(error.message);
  }
};

export const fetchHomePageDataSelectedUser = (userId) => async (dispatch) => {
  const options = {
    headers: {
      'X-Auth-Token': localStorage.getItem('authorization-token'),
      'X-User-TZ': getTimeZone(),
    },
  };
  try {
    const response = await $api.get(
      `${process.env.REACT_APP_BASE_URL}/homepage?userId=${userId}`,
      options
    );
    dispatch(homePageActions.getHomePageDataSelectedUser(response.data));
  } catch (error) {
    if (error.statusCode > 299) throw new Error(error.message);
  }
};
