import React from 'react';
import cs from 'classnames';
import './styles.scss';

const Badge = ({ className, children, classes, ...props }) => (
  <div className={cs('badge', className, classes && classes.root, props.red && 'bg-red')} {...props}>
    {children}
  </div>
);

export default Badge;
