export default theme => ({
  textField: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: 250,
    },
  },
  marginVertical: {
    margin: '20px 0',
  },
  shiftImage: {
    height: 16,
    margin: '0 5px 2px 0',
  },
  passwordToggler: {
    width: 50,
    textAlign: 'center',
    color: theme.palette.textColor.primary,
    cursor: 'pointer'
  },
  error: {
    color: theme.palette.textColor.red,
    padding: '0 5px',
    fontSize: 11
  },
  requiredSign: {
    color: theme.palette.textColor.red
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: 11,
    gap: 3,
    '@media (max-width: 999px)': {
      marginRight: 0,
    },
  },
  delimiter: {
    display: 'inline-flex',
    alignItems: 'center',
    flexBasis: '4px',
  },
  clearSelect: {
    display: 'inline-flex',
    color: 'red',
    flexBasis: '24px'
  },
  select: {
    // flexBasis: '72px',
    flexGrow: 1,
    '& > div': {
      height: '34px',
    },
    // '@media (max-width: 990px)': {
    //   flexBasis: '112px',
    // },
  },
});
