import { format } from 'date-fns';
import scheduleActions from '../schedulePageActions';
import { CALENDAR_EVENTS_EVENT } from 'subscriptions/events/events.enum';
import { subscriptionsFabric } from 'subscriptions/subscriptions.fabric';

const handlers = {
  [CALENDAR_EVENTS_EVENT.created]: (store) => (data) => {
    const date = store.getState().schedule.selectedDate;
    const dateFormat = store.getState().personalProfile.organization?.settings?.dateFormat;
    const formattedDate = format(date, dateFormat);

    if (data.start === formattedDate) {
      store.dispatch(scheduleActions.addCalendarEvent(data));
    }
  },
  [CALENDAR_EVENTS_EVENT.deleted]:
    (store) =>
    ({ _id }) => {
      store.dispatch(scheduleActions.removeCalendarEvents(_id));
    },
};

export const calendarEventsSubscriptionMiddleware = subscriptionsFabric(
  scheduleActions,
  CALENDAR_EVENTS_EVENT,
  handlers
);
