import React, { useState, useEffect, useRef, useMemo } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import SheetsList from '../SheetsList';
import SheetForm from '../SheetForm/SheetForm';
import SortingAndFilteringPanel from '../SortingAndFilteringPanel';
import WorklogDetails from 'components/WorklogDetails';
import ResourcesForm from 'components/ResourcesForm';
import CommentsForm from 'components/CommentsForm';
import MaterialForm from 'components/MaterialForm';
import TodayFiltersBar from 'components/FiltersBar/TodayFiltersBar';
import PageLoader from 'components/PageLoader';
import TimeForm from 'components/WorklogDetails/components/TimeBlock/components/TimeForm/TimeForm';
import WeatherForm from 'components/WorklogDetails/components/WeatherBlock/components/WeatherForm/WeatherForm';
import NotesForm from 'components/WorklogDetails/components/NotesBlock/components/NotesForm/NotesForm';
import GeneralInfoForm from 'components/WorklogDetails/components/GeneralInfoBlock/components/GeneralInfoForm/GeneralInfoForm';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveWorklogs, WORKLOG_LIMIT } from 'store/worklogs/worklogsPageOperations';
import { addYears, subYears, subWeeks, startOfDay, endOfDay, } from 'date-fns';
import worklogsPageActions from 'store/worklogs/worklogsPageActions';

const styles = {
  backdropOpacity: {
    opacity: '0 !important',
  },
};

const ActiveWorklogs = (props) => {
  const dispatch = useDispatch();
  const todayRef = useRef(null)
  const scrollToToday = useRef(null)
  const { classes, openSnackbar, params } = props;

  const { dataActive: sheets, sheetIds, hasMore, forceUpdate, sheetsLength } = useSelector((store) => store.worklogs);
  const fetchPage = useMemo(() => sheetsLength / WORKLOG_LIMIT, [sheetsLength])

  const [today, setToday] = useState(false);
  const [drawer, setDrawer] = useState({
    type: 'filters',
    isOpen: false,
    sheet: {},
  });
  const [additionalDrawer, setAdditionalDrawer] = useState({
    type: 'resources',
    subType: 'people',
    isOpen: false,
    material: {},
  });
  const [filter, setFilter] = useState({
    projectId: 'All',
    contractorId: 'All',
    dates: [startOfDay(subYears(new Date(), 1)), endOfDay(addYears(new Date(), 1))],
    status: 'All',
    shift: 'All',
    forceAccount: 'All',
    rated: 'All',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isTodayLoading, setIsTodayLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (!forceUpdate) return
    const limit = fetchPage === 0 ?  40 : (fetchPage * 40)
    dispatch(getActiveWorklogs({ searchValue, filter, page: fetchPage, skip: '0', limit, isClear: true, shouldBeToday: scrollToToday?.current }));
  }, [forceUpdate]);

  useEffect(() => {
    return ()=>{
      dispatch(worklogsPageActions.resetWorklogs())
    }
  }, []);

  useEffect(() => {
    updateVisibleSheets(true);
  }, [searchValue, filter]);

  const updateSearchValue = (value) => {
    scrollToToday.current = false
    setSearchValue(value);
  };

  const scroller = () => {
    const DESKTOP = -55
    const MOBILE = +200
    const yOffset = (window.innerWidth || document.documentElement.clientWidth) > 1100 ? DESKTOP : MOBILE;
    const y = todayRef?.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
    setTimeout(()=>{
      window.scrollTo({top: y, behavior: 'smooth'})
      scrollToToday.current = false
    }, 100)
  }

  useEffect(() => {
    if(scrollToToday.current && todayRef.current) {
      return scroller()
    }
  });

  const scrollTodayWorklog = async () => {
    if (todayRef.current) {
      return scroller()
    }

    scrollToToday.current = true
    setIsTodayLoading(true)
    await dispatch(getActiveWorklogs({
      searchValue,
      filter: { ...filter, dates: [startOfDay(subWeeks(new Date(), 1)), endOfDay(addYears(new Date(), 1))] },
      page: null,
      skip: 0,
      limit: Infinity,
      isClear: true,
      isTodayMode: true,
      shouldBeToday: scrollToToday.current
    }));
    setIsTodayLoading(false)
  }

  const updateFilter = (filter) => {
    setFilter(filter);
    scrollToToday.current = false
    setDrawer((prev) => ({
      ...prev,
      type: 'details',
      isOpen: false,
    }));
  };

  const updateVisibleSheets = async( isClear ) => {
    setIsLoading(true)
    const fetchOptions = {
      searchValue,
      filter,
      page: isClear ? 0 : fetchPage,
      isClear,
      shouldBeToday: scrollToToday.current
    }

    if ((sheetsLength % WORKLOG_LIMIT) && !isClear) {
      const sheetsMax = Math.ceil(sheetsLength / WORKLOG_LIMIT)
      fetchOptions.skip = sheetsLength
      fetchOptions.limit = (sheetsMax * WORKLOG_LIMIT) - sheetsLength
    }

    await dispatch(getActiveWorklogs(fetchOptions));
    setIsLoading(false)
  };

  const openDrawer =
    (type, sheet = {}) =>
    () => {
      setDrawer({ type, isOpen: true, sheet });
    };

  const closeDrawer = () => {
    setDrawer((prev) => ({ ...prev, type: 'details', isOpen: false }));
  };

  const openAdditionalDrawer =
    (type, subType, material = {}) =>
    () => {
      setAdditionalDrawer({ type, subType, isOpen: true, material });
    };

  const closeAdditionalDrawer = () => {
    setAdditionalDrawer((prev) => ({ ...prev, isOpen: false }));
  };

  const getFullRefreshedList = (sheet) => {
    dispatch(worklogsPageActions.setRefreshActiveSheet(sheet))
  };

  const sheetId = params.sheetId;

  return (
    <>
      <TodayFiltersBar openDrawer={openDrawer} updateSearchValue={updateSearchValue} scrollTodayWorklog={scrollTodayWorklog} isTodayLoading={isTodayLoading} isDynamicSearch={true}/>

        <PageLoader loading={isLoading}>
          <SheetsList
            openSnackbar={openSnackbar}
            sheets={sheets}
            isLoading={isLoading}
            updateVisibleSheets={updateVisibleSheets}
            hasMore={hasMore}
            page={fetchPage}
            todayRef={todayRef}
          />
        </PageLoader>

      {sheetId && !isLoading &&  (
        <WorklogDetails
          openSnackbar={props.openSnackbar}
          updateVisibleSheets={getFullRefreshedList}
          openDrawer={openDrawer}
          openAdditionalDrawer={openAdditionalDrawer}
          updateDrawerFromRouter={closeDrawer}
          sheetIds={sheetIds}
          hasMore={hasMore}
          // pageCount={pageCount}
          showIndividualStartTime={true}
        />
      )}

      <Drawer
        anchor="right"
        open={drawer.isOpen}
        ModalProps={{
          BackdropProps: {
            classes: {
              root: sheetId ? classes.backdropOpacity : null,
            },
          },
        }}
      >
        {drawer.type === 'update' && (
          <SheetForm
            closeDrawer={closeDrawer}
            type={drawer.type}
            sheet={drawer.sheet}
            openSnackbar={props.openSnackbar}
          />
        )}
        {drawer.type === 'filters' && (
          <SortingAndFilteringPanel
            closeDrawer={closeDrawer}
            filter={filter}
            updateFilter={updateFilter}
            isLoading={isLoading}
          />
        )}
      </Drawer>

      <Drawer anchor="left" open={drawer.type === 'details' && additionalDrawer.isOpen}>
        {additionalDrawer.type === 'resources' && (
          <ResourcesForm closeDrawer={closeAdditionalDrawer} type={additionalDrawer.subType} openSnackbar={openSnackbar}/>
        )}
        {additionalDrawer.type === 'comments' && (
          <CommentsForm closeDrawer={closeAdditionalDrawer} openSnackbar={openSnackbar} limiterW={true} />
        )}
        {additionalDrawer.type === 'material' && (
          <MaterialForm
            closeDrawer={closeAdditionalDrawer}
            openSnackbar={openSnackbar}
            type={additionalDrawer.subType}
            material={additionalDrawer.material}
          />
        )}

        {additionalDrawer.type === 'time' && (
          <TimeForm closeDrawer={closeAdditionalDrawer} openSnackbar={openSnackbar} />
        )}
        {additionalDrawer.type === 'weather' && <WeatherForm closeDrawer={closeAdditionalDrawer} />}
        {additionalDrawer.type === 'notes' && (
          <NotesForm closeDrawer={closeAdditionalDrawer} openSnackbar={openSnackbar} />
        )}
        {additionalDrawer.type === 'general' && (
          <GeneralInfoForm closeDrawer={closeAdditionalDrawer} />
        )}
      </Drawer>
    </>
  );
};

ActiveWorklogs.propTypes = {
  classes: propTypes.object.isRequired,
  openSnackbar: propTypes.func.isRequired,
};

export default withStyles(styles)(ActiveWorklogs);
