import React, { useMemo, useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { differenceInMonths, startOfMonth, startOfYear, addDays, format } from 'date-fns';

import './styles.scss';
import 'react-calendar/dist/Calendar.css';
import { useWeekStart } from 'hooks/useWeekStart';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';

const CustomCalendar = ({ dates, updateDates, monthMaxDetail }) => {
  const maxDetails = useMemo(() => ['month', 'year'], []);
  const defaultMaxDetail = useMemo(() => maxDetails[+Boolean(monthMaxDetail)], [monthMaxDetail]);
  const {weekDayFromO} = useWeekStart();
  const { dateFormat } = useDateSettingsFormat()

  const [open, setIsOpen] = useState(false);
  const [selectedDates, setSelectedDates] = useState(dates);
  const [selectedMaxDetail, setSelectedMaxDetail] = useState(defaultMaxDetail);
  const [selectedView, setSelectedView] = useState(defaultMaxDetail);
  const [activeStartDate, setActiveStartDate] = useState(
    selectedMaxDetail === maxDetails[0] ? startOfMonth(selectedDates[0]) : startOfYear(selectedDates[0])
  );
  useEffect(() => {
    setSelectedDates(dates)
  }, [dates]);

  const toggleCalendar = () => setIsOpen((prev) => !prev);

  const closeCalendar = () => setIsOpen(false);

  const handleChange = (dates) => {
    setSelectedDates(dates);
    const shouldShowMonthView = selectedMaxDetail !== maxDetails[0] && differenceInMonths(...dates) === 0;
    updateDates(dates);
    if (!shouldShowMonthView) {
      return closeCalendar();
    }
    setSelectedMaxDetail(maxDetails[0]);
    setSelectedView(maxDetails[0]);
    setActiveStartDate(startOfMonth(dates[0]));
  };

  const handleActiveStartDateChange = ({ activeStartDate, value, view }) => {
    setActiveStartDate(activeStartDate);
  };

  const getDaysName = () => {
    const startDate = format(selectedDates[0], dateFormat);
    const endDate = format(selectedDates[1], dateFormat);
    return `${startDate} - ${endDate}`;
  };

  const updateView = ({ view }) => {
    setSelectedView(view);
  };

  const updateMaxDetail = ({ view }) => {
    updateView({ view });
    if (maxDetails.includes(view)) return setSelectedMaxDetail(view);
    setSelectedMaxDetail(maxDetails[maxDetails.length - 1]);
  };

  const onSelectWeek = (weekNumber, startDate) => {
    handleChange([startDate, addDays(startDate, 6)]);
  };

  return (
    <ClickAwayListener onClickAway={closeCalendar}>
      <div className="rootCalendarWrapper">
        <div className="selectedDaysName" onClick={toggleCalendar}>
          {getDaysName()} <ArrowDownIcon className="downArrow" />
        </div>
        {open && (
          <div className="calendarWrapper">
            <Calendar
              onChange={handleChange}
              value={selectedDates}
              calendarType={weekDayFromO === 0 ? "US" : "ISO 8601"}
              locale="en-US"
              maxDetail={selectedMaxDetail}
              activeStartDate={activeStartDate}
              onActiveStartDateChange={handleActiveStartDateChange}
              onDrillUp={updateMaxDetail}
              onDrillDown={updateView}
              view={selectedView}
              onClickWeekNumber={onSelectWeek}
              showWeekNumbers
              selectRange
            />
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default CustomCalendar;
