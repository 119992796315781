export default theme => ({
  card: {
    paddingTop: 10,
    [theme.breakpoints.only('xl')]: {
      width: 237,
    },
  },
  regularFontWeight: {
    fontWeight: 300,
  },
  paddingBox: {
    padding: '0 8px 8px',
  },
  paddingTop: {
    paddingTop: 8
  },
  paddingBottom: {
    paddingBottom: 8
  },
  paddingBottomSmall: {
    paddingBottom: 4
  },
  inlineFlex: {
    display: 'inline-flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  flexHeader: {
    alignItems: 'flex-start'
  },
  fixedWidth: {
    width: 'calc(100% - 30px)'
  },
  deleteButton: {
    minWidth: 24,
    height: 24,
    padding: 5,
    '&:hover': {
      background: `${theme.palette.background.default}!important`
    },
    '&:focus': {
      background: `${theme.palette.background.default}!important`
    },
  },
  secondaryText: {
    color: theme.palette.textColor.secondary,
    fontSize: '0.8rem',
    fontWeight: 400,
    marginRight: 10,
  },
  displayBlock: {
    display: 'inline-block',
    // verticalAlign: 'middle',
  },
  photoWrapper: {
    borderRadius: '50%',
    border: `1px solid ${theme.palette.textColor.blue}`,
    borderTopColor: 'transparent',
    borderBottomColor: 'transparent',
    width: 32,
    height: 32,
    transform: 'rotate(-50deg)',
    // minWidth: 32,
  },
  photo: {
    display: 'block',
    transform: 'rotate(50deg)',
    height: 26,
    width: 26,
    borderRadius: '50%',
    margin: '2px auto 0',
    objectFit: 'contain',
  },
  flexBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '8px 0 8px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      width: '100%',
    },
  },
  name: {
    marginLeft: 15,
  },
  shiftImage: {
    height: 16,
    margin: '0 5px 2px 0',
  },
  expand: {
    transform: 'rotate(0deg)',
    padding: 0,
    '&:hover': {
      color: theme.palette.textColor.secondary,
      backgroundColor: '#fff',
    },
    '&:focus': {
      color: theme.palette.textColor.secondary,
      backgroundColor: '#fff',
    },
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  borderTop: {
    borderTop: `1px solid ${theme.palette.borderColor.primary}`,
  },
  actionsWrapper: {
    borderTop: `1px solid ${theme.palette.borderColor.primary}`,
    justifyContent: 'space-around',
    padding: 5,
    [theme.breakpoints.down('md')]: {
      paddingBottom: 0,
    },
  },
  locationsCount: {
    color: theme.palette.textColor.primary,
    borderRadius: '50%',
    border: `1px solid ${theme.palette.borderColor.blue}`,
    display: 'inline-block',
    verticalAlign: 'middle',
    minWidth: 20,
    minHeight: 20,
    paddingTop: 1,
    textAlign: 'center',
    margin: '0 5px',
    boxSizing: 'border-box',
  },
  innerCounter: {
    fontSize: '0.8rem',
    paddingTop: 2
  },
  wordBreak: {
    wordBreak: 'break-word',
  },
});
