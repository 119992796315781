export default theme => ({
  formWrapper: {
    padding: '20px 15px 0',
    width: 380,
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      width: 550
    },
  },
  closeButton: {
    position: 'absolute',
    right: 15,
    top: 12,
  },
  buttonHolder: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  cancelButton: {
    margin: 20,
    minWidth: 100,
    '&:hover': {
      color: theme.palette.textColor.dark,
    },
    '&:focus': {
      color: theme.palette.textColor.dark,
      backgroundColor: '#fff',
    },
    '&:disabled': {
      backgroundColor: '#fff',
    },
  },
  saveButton: {
    margin: 20,
    minWidth: 100,
    '&:hover': {
      color: theme.palette.textColor.primary,
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
      backgroundColor: '#fff',
    },
    '&:disabled': {
      backgroundColor: '#fff',
    },
  },
  formFlex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '95vh'
  },
  textField: {
    maxWidth: 250,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 200,
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '100%'
    },
  },
  marginVertical: {
    margin: '20px 0',
  },
  shiftImage: {
    height: 16,
    margin: '-2px 5px 0 0',
  },
  label: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
  },
  marginHeader: {
    marginBottom: 30
  },
  verticalDivider: {
    width: 1,
    height: 30,
    backgroundColor: theme.palette.borderColor.primary
  },
  error: {
    fontSize: 11,
    float: 'right',
    color: theme.palette.textColor.red,
    padding: '0 5px',
    maxWidth: 250,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 200,
    }
  },
  requiredSign: {
    color: theme.palette.textColor.red
  },
  fieldWrapper: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: 255,
    }
  },
  flexContainer: {
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'space-between'
    }
  },
  resetButton: {
    '&:hover': {
      color: theme.palette.primary.light,
      backgroundColor: '#fff !important',
    },
    '&:focus': {
      color: theme.palette.primary.blue,
      backgroundColor: '#fff',
    },
    textTransform: 'none',
  },
  customSelectedStyle: {
    backgroundColor: 'rgba(0, 0, 0, 0.14)'
  }
});
