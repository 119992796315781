export const styles = (theme) => ({
  addTimeOffWrapper: {
    '@media (max-width: 999px)': {
      width: '650px',
      margin: '0 auto',
    },
    '@media (max-width: 680px)': {
      width: '500px',
    },
    '@media (max-width: 530px)': {
      width: 'auto',
      margin: 0,
    },
  },
  radioGroup: {
    display: 'flex',
    '& > label': {
      width: 'auto',
      '&:first-child': {
        paddingRight: '4rem',
      },
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  error: {
    color: theme.palette.textColor.red,
    padding: '0 5px',
    fontSize: 11,
    position: 'absolute',
  },
  // errorMob: {
  //   [theme.breakpoints.down('sm')]: {
  //     position: 'static',
  //     marginBottom: '9px',
  //     width: '100%',
  //     textAlign: 'center',
  //     padding: '12px',
  //   },
  // },
  text: {
    color: theme.palette.textColor.dark,
    fontSize: '.8rem',
    margin: '1.5rem 0 1rem',
    [theme.breakpoints.down('sm')]: {
      margin: '0.5rem 0 1rem',
    },
  },
  startForm: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '48%',
    minWidth: '24rem',
    '@media (max-width: 999px)': {
      width: '100%',
    },
    '@media (max-width: 530px)': {
      flexDirection: 'column',
      width: '340px',
      minWidth: 'auto',
      justifyContent: 'center',
      marginBottom: '12px,',
    },
    '@media (max-width: 330px)': {
      width: '300px',
    },
  },
  startFormBig: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '80%',
    flexWrap: 'wrap',
    marginBottom: '1rem',
    '@media (max-width: 1025px)': {
      width: '95%',
    },
    '@media (max-width: 999px)': {
      width: '100%',
    },
    '@media (max-width: 530px)': {
      width: '340px',
    },
    '@media (max-width: 330px)': {
      width: '300px',
    },
  },
  formInputWrapper: {
    '@media (max-width: 530px)': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  select: {
    // marginRight: 5,
    // minWidth: 65,
    width: '100%',
    '& > div': {
      height: '2.35rem',
    },
    '@media (max-width: 999px)': {
      width: '94px',
    },
    '@media (max-width: 680px)': {
      width: '75px',
    },
    '@media (max-width: 530px)': {
      width: '340px',
    },
    '@media (max-width: 330px)': {
      width: '300px',
    },
  },
  selectMob: {
    width: '12.8rem',
    marginRight: 0,
    '@media (max-width: 999px)': {
      width: '290px',
    },
    '@media (max-width: 680px)': {
      width: '233px',
    },
    '@media (max-width: 530px)': {
      width: '340px',
    },
    '@media (max-width: 330px)': {
      width: '300px',
    },
  },
  relationshipBlock: {
    width: '10.5rem',
    '@media (max-width: 999px)': {
      width: '320px',
    },
    '@media (max-width: 680px)': {
      width: '230px',
    },
    '@media (max-width: 530px)': {
      width: '100%',
    },
  },
  heading: {
    fontSize: '1.25rem',
    marginTop: '-2.5rem',
    marginBottom: '1rem',
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
    },
  },
  pto: {
    display: 'flex',
    alignItems: 'center',
    '& > p': {
      marginRight: '1rem',
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
      marginBottom: '12px',
    },
  },
  textArea: {
    width: '100%',
    '& input': {
      minHeight: '2.2rem',
      width: '12.8rem',
      '@media (max-width: 999px)': {
        width: '291px',
      },
      '@media (max-width: 680px)': {
        width: '230px',
      },
    },
    '& textarea': {
      boxShadow: 'none',
      padding: 10,
      fontSize: 14,
      height: '60px !important',
      maxHeight: '60px !important',
    },
  },
  time: {
    '&:last-of-type': {
      marginRight: 0,
    },
    '& > div > div': {
      paddingRight: '2.5rem!important',
    },
  },
  submit: {
    // '&:hover, &:focus': {
    //   color: '#5a87ef',
    // },
  },
  commonSubtractedError: {
    width: '12.8rem',
    minHeight: '2.2rem',
    borderRadius: '4px',
    lineHeight: '16px',
    padding: '5px 5px',
    [theme.breakpoints.down('md')]: {
      position: 'static',
      marginBottom: '9px',
      textAlign: 'center',
      width: '290px',
    },
    '@media (max-width: 680px)': {
      width: '233px',
    },
    '@media (max-width: 530px)': {
      marginBottom: '9px',
      width: '340px',
      textAlign: 'center',
      padding: '12px',
    },
    '@media (max-width: 330px)': {
      width: '300px',
    },
  },
  willSubtractedAvailablePTO: {
    backgroundColor: theme.palette.background.lightRed,
    color: theme.palette.textColor.red,
    borderColor: theme.palette.background.lightRed,
    boxSizing: 'border-box',
  },
  isNotSubtractedAvailablePTO: {
    backgroundColor: theme.palette.background.lightBlue,
    color: theme.palette.primary.main,
    borderColor: theme.palette.background.lightBlue,
    boxSizing: 'border-box',
  },
  startTimeWrapper: {
    [theme.breakpoints.up('md')]: {
      width: 230,
    },
  },
  startTimeSelectorsWrapper: {
    gap: '10px',
  },
});
