export default theme => ({
  paddingBottom: {
    paddingBottom: 10,
  },
  equipmentBlock: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '17px',
    width: 'max-content'
  },
  equipmentNumber: {
    fontSize: 14,
    fontWeight: 500,
    fontFamily: 'Poppins',
    color: theme.palette.textColor.white,
    padding: '3px 6px',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  textRight: {
    textAlign: 'right'
  },
  hoursBlock: {
    marginBottom: 10
  },
  textCenter: {
    textAlign: 'center'
  },
  addButton: {
    padding: 0,
    color: theme.palette.textColor.primary,
    '&:hover': {
      color: theme.palette.textColor.primary,
      background: `${theme.palette.background.default} !important`
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
      background: `${theme.palette.background.default} !important`
    }
  },
  closeButton: {
    marginLeft: 15,
    padding: 0,
    color: theme.palette.textColor.red,
    '&:hover': {
      color: theme.palette.textColor.red,
      background: theme.palette.background.default
    },
    '&:focus': {
      color: theme.palette.textColor.red,
      background: theme.palette.background.default
    }
  },
  error: {
    color: theme.palette.textColor.red,
    padding: '0 5px',
    fontSize: 11,
    margin: '0 auto 5px',
  },
});
