import { useEffect, useState } from 'react';
import { fetchHours } from './apiCalls';
import { useSearchParams } from 'react-router-dom';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import HoursTableHeader from './components/HoursTableHeader/HoursTableHeader';
import { dayOfWeeekDateRevers } from './helpers';
import './viewHours.scss';
import HoursTableBody from '../HoursTable/components/HoursTableBody/HoursTableBody';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';

export const HoursTable = ({}) => {
  const { dateFormat, fullDayDateSettingsFormat } = useDateSettingsFormat();
  let [searchParams] = useSearchParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const token = searchParams.get('token');

  useEffect(() => {
    setLoading(true);
    fetchHours(token).then((data) => {
      setData(data);
      setLoading(false);
    });
  }, [token]);

  useEffect(() => {
    if(token && data.summaries){
      setTimeout(() => {
        window.print();
      }, 500)
    }
  }, [token, data]);

  return loading ? (
    <div
      style={{
        display: 'flex',
        alignItem: 'center',
        justifyContent: 'center',
        marginTop: '100px',
      }}
    >
      <LoadingSpinner height={40} margin={32} />
    </div>
  ) : (
    <>
      {data?.summaries?.map((day) => {
        return (
          <div key={day.date} className={'viewHours'}>
            <div className="row filter-header collapseSides viewHours-header">
              <h2>{`Hours ${dayOfWeeekDateRevers(day.date, { dateFormat, fullDayDateSettingsFormat })}`}</h2>
            </div>
            {day?.summary ? (
              <>
                <table class="time-clock-user print-avoid-page-break">
                  <HoursTableHeader day={day}/>
                  <HoursTableBody day={day.summary} date={day.date}/>
                </table>
              </>
            ) : (
              <>
                <hr />
                <h3
                  className="text-center-res"
                  style={{ margin: '2em 0', opacity: '.5', fontWeight: 500 }}
                >
                  No Results
                </h3>
              </>
            )}
          </div>
        );
      })}
    </>
  );
};
