import React, { useState } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { useController } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styles from './styles';
import CloseIcon from 'assets/images/close-icon.svg';
import cn from 'classnames';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import DocuSignSigner from '../DocuSignSigner/index';
import ForceAccountsBlock from '../ForceAccountsBlock/ForceAccountsBlock';
import PtoBlock from '../PtoBlock/PtoBlock';
import PayrollBlock from '../PayrollBlock/PayrollBlock';

const EmailNotificationsItem = ({ classes, pageType, control, errors }) => {
  const {
    field: { onChange, value: EmailNotifications },
  } = useController({
    name: 'emailReceivers',
    control,
  });

  const [isShowEmails, setShowEmails] = useState(false);

  const handleNotificationChange = (i, fieldName) => (e) => {
    const { value } = e.target;
    const nextValue = EmailNotifications.map((notificationObject, index) =>
      index === i ? { ...notificationObject, [fieldName]: value } : notificationObject
    );
    onChange(nextValue);
  };
  const handleEmailRemoving = (i) => () => {
    const nextValue = EmailNotifications.filter((_, index) => index !== i);
    onChange(nextValue);
  };
  const handleAddEmail = () => {
    setShowEmails(true);
    onChange([...EmailNotifications.filter((v) => v.email), { name: '', email: '' }]);
  };

  return (
    <div>
      <div className={classes.actionsWrapper}>
        {!!EmailNotifications.length &&
          EmailNotifications?.slice(0, isShowEmails ? EmailNotifications.length : 3).map(
            (notificationObject, index) => (
              <Grid container spacing={2} key={`emailReceivers-${index}`} alignItems="center">
                <Grid item xs={10} sm={6} md={4} className={classes.zeroPaddingTop}>
                  {index === 0 && (
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      className={classes.regularFontWeight}
                    >
                      Name:
                    </Typography>
                  )}
                  <TextField
                    id={`emailReceivers[${index}].name`}
                    name={`emailReceivers[${index}].name`}
                    type="text"
                    value={notificationObject.name}
                    className={classes.textField}
                    onChange={handleNotificationChange(index, 'name')}
                    variant="outlined"
                    inputProps={{ 'aria-label': 'Name Notifications' }}
                    fullWidth
                    disabled={pageType === 'read'}
                  />
                </Grid>
                <Grid item xs={10} sm={6} md={4} className={classes.zeroPaddingTop}>
                  {index === 0 && (
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      className={classes.regularFontWeight}
                    >
                      Email:
                    </Typography>
                  )}
                  <TextField
                    id={`emailReceivers[${index}].email`}
                    name={`emailReceivers[${index}].email`}
                    type="email"
                    value={notificationObject.email}
                    className={classes.textField}
                    onChange={handleNotificationChange(index, 'email')}
                    variant="outlined"
                    inputProps={{ 'aria-label': 'Email Notifications' }}
                    fullWidth
                    disabled={pageType === 'read'}
                  />
                </Grid>
                {pageType === 'edit' && (
                  <span
                    className={classes.removeEmail}
                    onClick={handleEmailRemoving(index)}
                    style={index === 0 ? { marginTop: 15 } : {}}
                  >
                    <img src={CloseIcon} alt="delete" />
                  </span>
                )}
              </Grid>
            )
          )}
        {EmailNotifications?.length > 3 && (
          <div
            className={classes.buttonWrapper}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShowEmails((prev) => !prev);
            }}
          >
            <div className={classes.nonActionsIcon}>
              <ExpandMoreIcon className={cn(isShowEmails ? '' : classes.reversIcon)} />
            </div>
            <p className={classes.textIconMore}>{`${
              isShowEmails ? 'Hide' : 'Show'
            } additional email addresses`}</p>
          </div>
        )}
        {pageType === 'edit' && (
          <Button
            variant="text"
            className={classes.primaryTextButton}
            onClick={handleAddEmail}
            disableRipple
          >
            + Add Email
          </Button>
        )}
      </div>
    </div>
  );
};

const EmailNotifications = ({ classes, pageType, control, errors }) => {
  const { generateDocusignCertificates, generateCertificates } = useSelector(
    (state) => state.settings.settings
  );

  const renderTabs = [
    {
      label: 'DWL Submission',
      Component: (props) => <EmailNotificationsItem classes={classes} {...props} />,
    },
    ...(generateDocusignCertificates && generateCertificates
      ? [
          {
            label: 'DocuSign Signer Email',
            Component: (props) => <DocuSignSigner {...props} />,
          },
        ]
      : []),
    {
      label: 'Time & Mat. Submission',
      Component: (props) => <ForceAccountsBlock {...props} />,
    },
    {
      label: 'Time Off Request',
      Component: (props) => <PtoBlock {...props} />,
    },
    {
      label: 'Payroll Verification',
      Component: (props) => <PayrollBlock {...props} />,
    },
  ];
  const [tabValue, setTabValue] = useState(0);

  const handleChangeTab = (e, newValue) => {
    if (newValue === tabValue) return;
    setTabValue(newValue);
  };

  return (
    <div>
      <Typography variant="h4">Email Notification</Typography>

      <Tabs
        value={tabValue}
        variant="scrollable"
        onChange={handleChangeTab}
        className={classes.tabs}
        scrollButtons="off"
      >
        {renderTabs.map((tabItem, i) => (
          <Tab key={i} label={tabItem.label} disableRipple />
        ))}
      </Tabs>

      {renderTabs.map((tab, index) => {
        const Component = tab.Component;
        return (
          <div key={index}>
            {tabValue === index &&
              Component({
                pageType: pageType,
                errors: errors,
                control: control,
                classes: classes,
              })}
          </div>
        );
      })}
    </div>
  );
};

EmailNotifications.propTypes = {
  classes: propTypes.object.isRequired,
  pageType: propTypes.string.isRequired,
  errors: propTypes.object.isRequired,
  control: propTypes.object.isRequired,
};

export default withStyles(styles)(EmailNotifications);
