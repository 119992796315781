import React, { useCallback, useState } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InfiniteScroll from 'react-infinite-scroller';

import ConfirmDialog from '../../../../../../../../components/ConfirmDialog';
import CompanyDayOffCard from './components/CompanyDayOffCard';
import styles from './styles';
import { getClientTzOffset } from '../../../../../../../../helpers/_helpers';
import { getOptions } from 'helpers/getOptions';
import $api from 'http/index';

const CompanyDayOffList = ({
  classes,
  openSnackbar,
  holidays,
  toggleHolidayModal,
  getHolidays
}) => {

  const [holidayId, setHolidayId] = useState(null);
  const openDeleteModal = useCallback((id) => setHolidayId(id), []);
  const closeDeleteModal = useCallback(() => setHolidayId(null), []);

  const deleteHoliday = useCallback(async () => {
    try {
      await $api.delete(
        `${process.env.REACT_APP_BASE_URL}/holidays/${holidayId}`,
        getOptions()
      );
      getHolidays()
      setHolidayId(null);
    } catch (e) {
      openSnackbar('error', e.message)
    }
  }, [holidayId]);

  if (!holidays.length)
    return (
      <Typography variant="h3" align="center" className={classes.noResultsBlock}>
        No Results
      </Typography>
    );

  return (
    <>
        <Grid container spacing={2} justifyContent="flex-start" className={classes.paddingVertical}>
          {holidays.map((holiday) => (
            <Grid key={holiday._id} item xs={12}>
              <CompanyDayOffCard
                holiday={holiday}
                toggleHolidayModal={toggleHolidayModal}
                openDeleteModal={openDeleteModal}
              />
            </Grid>
          ))}
        </Grid>
      {holidayId && (
        <ConfirmDialog
          isOpen={!!holidayId}
          onClose={closeDeleteModal}
          onSubmit={deleteHoliday}
          text="Are you sure you want to delete this holiday?"
          disableEscape
          loadingOnSubmit
        />
      )}
    </>
  );
};

CompanyDayOffList.propTypes = {
  openSnackbar: propTypes.func.isRequired,
  classes: propTypes.object.isRequired,
  holidays: propTypes.array.isRequired,
  getHolidays: propTypes.func.isRequired,
  hasMore: propTypes.bool.isRequired,
  toggleHolidayModal: propTypes.func.isRequired,
};

export default withStyles(styles)(CompanyDayOffList);
