import { useCallback } from 'react';

import { getOptions } from 'helpers/getOptions';

export const useCheckOutsideClockIn = ({ hours, sheetId, workers }) => {
  const checkOutsideClockIn = useCallback(async () => {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/sheets/${sheetId}/submit/check-out-of-range`, {
      ...getOptions(),
      method: 'GET',
    }).then((res) => res.json());
    if (response.statusCode > 299) return console.error(response);
    if (response.message && response.message === 'On Job Time is within Clock-in Time range') return;
    return response;
  }, [sheetId]);

  return { checkOutsideClockIn };
};
