const { object, string, number } = require('yup');

export const LegendMaterialSchema = (legends = [], edit) =>
  object().shape({
    symbol: Number.isInteger(edit)
      ? string().trim().required('Field is required')
      : string()
          .trim()
          .required('Field is required') // required
          .test({
            name: 'legendSymbolUnique',
            message: 'Legend with same symbol already exists in current configuration',
            // if found line with same value => throw validation error
            test: (value) => !legends.find(({ symbol }) => symbol === value),
          }),
    area: number()
      .typeError('Number is invalid. Only symbols 0 - 9 allowed')
      .positive('Value should be positive number')
      .required('Field is required'), // required
    ratio: number()
      .typeError('Number is invalid. Only symbols 0 - 9 allowed')
      .positive('Value should be positive number')
      .required('Field is required'), // required
    testQuantity: number()
      .transform((currentValue, originalValue) => {
        return originalValue === '' ? null : currentValue;
      })
      .typeError('Number is invalid. Only symbols 0 - 9 allowed')
      .positive('Value should be positive number')
      .nullable(true),
    _id: string().optional(),
  });
