import React from 'react';
import { useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core';
import styles from './styles';
import cs from 'classnames';
import formatNumber from '../../utils/formatNumber';

const Brief = ({ shopTime, totalTime, classes, className }) => {
  const companyName = useSelector(state => state.personalProfile?.organization?.name || '')

  return (
    <div className={cs(className, classes.root)}>
      <div className={classes.item}>
        <span className={classes.title}>{companyName} hours this week:</span>
        <span className={classes.time}>{formatNumber(totalTime)}</span>
        <span className={classes.measure}>hours</span>
      </div>
      <div className={classes.item}>
        <span className={classes.title}>Machine shop hours this week:</span>
        <span className={classes.time}>{formatNumber(shopTime)}</span>
        <span className={classes.measure}>hours</span>
      </div>
    </div>
  )
};

export default withStyles(styles)(Brief);
