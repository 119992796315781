import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import propTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from 'assets/images/edit-icon.svg';

import { useWorklogStyles, useCrewBlockStyles } from '../../hooks';

const WeatherBlock = ({ weather, hasAccess, openAdditionalDrawer }) => {
  const worklogClasses = useWorklogStyles();
  const crewBlockClasses = useCrewBlockStyles();

  const isWeatherData = (weather) => {
    if (!weather) return false;
    const isAnyData =
      (weather.temperature && weather.temperature.length) || weather.humidity || weather.notes;
    return !!isAnyData;
  };

  const formatWeatherToString = (weather) => {
    const { temperature, humidity } = weather;
    return (
      <>
        {Boolean(temperature && temperature.length) &&
          temperature.map((t, i, arr) => (
            <span key={Math.floor(+new Date() + Math.random() * 0xffffffff).toString(36)}>
              <span className={classnames(worklogClasses.secondaryText, worklogClasses.zeroMargin)}>
                Temp -{' '}
              </span>
              {t + String('\xB0') + ' F '}
              {humidity || i + 1 !== arr.length ? ' \u2022 ' : ''}
            </span>
          ))}
        {!!humidity && (
          <>
            <span className={classnames(worklogClasses.secondaryText, worklogClasses.zeroMargin)}>
              Hum -{' '}
            </span>
            {humidity + '%'}
          </>
        )}
      </>
    );
  };

  return (
    <>
      <Grid item xs={12} md={6}>
        <div className={crewBlockClasses.header}>
          <Typography
            variant="body1"
            color="textSecondary"
            className={classnames(worklogClasses.regularFontWeight, worklogClasses.paddingBottom)}
          >
            Weather:
          </Typography>
        </div>
        <Typography variant="body1" color="textPrimary">
          {isWeatherData(weather) ? formatWeatherToString(weather) : 'N/A'}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <div className={crewBlockClasses.header}>
          <Typography
            variant="body1"
            color="textSecondary"
            className={classnames(worklogClasses.regularFontWeight, worklogClasses.paddingBottom)}
          >
            Weather Notes:
          </Typography>
          {hasAccess && (
            <IconButton
              className={crewBlockClasses.editButton}
              onClick={openAdditionalDrawer('weather')}
              disableRipple
              disableTouchRipple
            >
              <img src={EditIcon} alt="edit crew" />
            </IconButton>
          )}
        </div>
        <Typography variant="body1" color="textPrimary">
          {weather && <span className={worklogClasses.displayBlock}>{weather.notes || 'N/A'}</span>}
        </Typography>
      </Grid>
    </>
  );
};

WeatherBlock.propTypes = {
  weather: propTypes.object,
  hasAccess: propTypes.bool.isRequired,
  openAdditionalDrawer: propTypes.func.isRequired,
};

export default WeatherBlock;
