import React, { Component } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { getEquipmentColorClass } from '../../../../../../helpers/_helpers';
import HoursBlock from '../../../HoursBlock';
import styles from './styles';
import { isValidTimePeriods } from 'components/ResourcesForm/helpers';

class SelectedEquipment extends Component {
  removeResource = () => {
    const { removeSelectedResource, equipmentData } = this.props;
    removeSelectedResource(equipmentData._id);
  };

  addResourceHours = () => {
    const { updateSelectedResource, equipmentData, sheet } = this.props;
    const hours = { ...sheet.hours };
    const updatedData = {
      ...equipmentData,
      hours: (equipmentData.hours || []).concat([hours]),
    };
    updateSelectedResource(equipmentData._id, updatedData);
  };

  removeResourceHours = (index) => () => {
    const { updateSelectedResource, equipmentData } = this.props;
    const updatedData = { ...equipmentData, hours: equipmentData.hours.filter((h, i) => i !== index) };

    updateSelectedResource(equipmentData._id, updatedData);
  };

  updateResourceHours = (index) => (data) => {
    const { updateSelectedResource, equipmentData, sheet } = this.props;
    const updatedData = {
      ...equipmentData,
      hours: equipmentData.hours.map((h, i) => i === index ? data : h),
    };
    
    const isValidHours = isValidTimePeriods(updatedData.hours.filter(hours => !hours.error));

    if (isValidHours) {
      delete updatedData.error;
      // const isValidHoursByTimeoffs = !isHoursOverlapseTimeOffs(
      //   hoursToCheck,
      //   workerData.availableHours,
      //   sheet
      // );
      // if (isValidHoursByTimeoffs) {
      //   delete updatedData.error;
      // } else {
      //   updatedData.error = 'User has timeoff at this time';
      // }
    } else {
      if (!sheet.travelTimeOnly) updatedData.error = 'Time periods cross each other';
    }
    
    updateSelectedResource(equipmentData._id, updatedData);
  };

  getEquipmentInfo = (field) => {
    const { item } = this.props;
    if (field === 'number') {
      return parseInt(item.name);
    }
    if (field === 'className') {
      return getEquipmentColorClass(item.color);
    }
    return item[field];
  };

  getResourceError = () => this.props.equipmentData?.error;

  render() {
    const { classes, sheet, equipmentData, item } = this.props;

    return (
      <Grid container className={classes.paddingBottom}>
        {item && <Grid item xs={10} className={classes.paddingBottom}>
          <div
            className={classnames(
              classes.equipmentBlock,
              this.getEquipmentInfo('className')
            )}
            style={{ backgroundColor: this.getEquipmentInfo('color') }}
          >
            <div className={classes.equipmentNumber}>
              {this.getEquipmentInfo('name')}
            </div>
          </div>
        </Grid>}

        <Grid
          item
          xs={2}
          className={classnames(classes.textRight, classes.paddingBottom)}
        >
          <IconButton
            className={classes.closeButton}
            onClick={this.removeResource}
            aria-label="Close"
            disableRipple
            disableTouchRipple
          >
            <CloseIcon />
          </IconButton>
        </Grid>

        {!!equipmentData.hours?.length && equipmentData.hours.map((hoursPair, i) => (
          <Grid item xs={12} className={classes.hoursBlock}>
            <HoursBlock
              sheet={sheet}
              itemData={equipmentData}
              itemHours={hoursPair}
              deleteTime={this.removeResourceHours(i)}
              updateResourceHours={this.updateResourceHours(i)}
              isEquipment
            />
          </Grid>
        ))}
        {Boolean(this.getResourceError()) && (
          <div className={classes.error}>{this.getResourceError()}</div>
        )}
        <Grid item xs={12} className={classes.textCenter}>
            {!sheet.travelTimeOnly && <Button
              variant="text"
              onClick={this.addResourceHours}
              className={classes.addButton}
              disableRipple
              disableTouchRipple
            >
              + Add Hours
            </Button>}
          </Grid>
      </Grid>
    );
  }
}

SelectedEquipment.propTypes = {
  classes: propTypes.object.isRequired,
  item: propTypes.object.isRequired,
  sheet: propTypes.object.isRequired,
  equipmentData: propTypes.object.isRequired,
  updateSelectedResource: propTypes.func.isRequired,
  removeSelectedResource: propTypes.func.isRequired,
};

export default withStyles(styles)(SelectedEquipment);
