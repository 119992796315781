import propTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import PageLoader from '../../../../../PageLoader2/PageLoader2';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import classnames from 'classnames';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import { Controller, useForm } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import AlertModal from '../../../../../AlertModal/AlertModal';
import {
  useSheetFormStyles,
  useResourcesFormStyles,
  useSheetDetailsStyles,
} from './../../../../hooks';
import { useSelector, useDispatch } from 'react-redux';
import { putWorklogWeatherlInfo } from 'store/currentWorklog/operations';

const defaultModalState = { isOpen: false, text: '' };

const WeatherForm = ({ closeDrawer }) => {
  const sheet = useSelector((store) => store.currentWorklog.worklogSheet.sheet);
  const sheetFormClasses = useSheetFormStyles();
  const resourcesFormClasses = useResourcesFormStyles();
  const sheetDetailsClasses = useSheetDetailsStyles();
  const [infoModal, setInfoModal] = useState(defaultModalState);
  const [temperature, setTemperature] = useState([]);

  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    register,
    setError,
    clearErrors,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const dispatch = useDispatch(); //

  useEffect(() => {
    register({ name: 'temperature' });
  }, [register]);

  useEffect(() => {
    if (!sheet) return;
    const weather = sheet.weather || {
      temperature: [],
      humidity: '',
      notes: '',
    };

    const temp = weather.temperature.filter((el) => el !== '');

    setTemperature([...temp, '']);
    setValue('temperature', [...temp, '']);

    setValue('humidity', weather.humidity);
    setValue('notes', weather.notes);
  }, [sheet]);

  const handleTemperatureChange = (i) => (e) => {
    const { value } = e.target;
    clearErrors(`temperature${i}`);
    const nextValue = temperature
      .map((t, index) => (index === i ? value : t))
      .filter((t) => t !== '');
    nextValue.push('');
    setTemperature(nextValue);
    if (!Number.isFinite(+value)) setError(`temperature${i}`);
    setValue('temperature', nextValue);
  };

  const onSubmit = async (values) => {
    if (errors && Object.keys(errors).length) return;
    try {
      await dispatch(putWorklogWeatherlInfo({ weather: values }, sheet._id));
      closeDrawer();
    } catch (e) {
      setInfoModal({ isOpen: true, text: e.message });
    }
  };

  const closeModal = () => setInfoModal(defaultModalState);

  const loading = !Object.keys(sheet).length;
  return (
    <>
      <div className={resourcesFormClasses.formWrapper} role="presentation">
        {loading ? (
          <PageLoader loading>
            <div />
          </PageLoader>
        ) : (
          <PageLoader loading={false} small>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={resourcesFormClasses.formFlex}>
                <div>
                  <Typography variant="h3" style={{ marginBottom: '26px' }}>
                    Edit weather
                  </Typography>

                  <IconButton
                    className={classnames(
                      sheetDetailsClasses.closeButton,
                      resourcesFormClasses.closeButton
                    )}
                    onClick={closeDrawer}
                    aria-label="Close"
                    disableTouchRipple
                  >
                    <CloseIcon />
                  </IconButton>

                  <Grid container spacing={4}>
                    {temperature.map((t, i) => (
                      <Grid
                        item
                        xs={12}
                        lg={4}
                        className={sheetDetailsClasses.zeroPaddingTop}
                        key={i}
                        style={{ alignSelf: 'flex-end' }}
                      >
                        <label htmlFor={`temperature${i}`}>
                          <Typography variant="body1" color="textSecondary">
                            {i === 0 ? 'Temperature:' : ' '}
                          </Typography>
                          <TextField
                            id={`temperature${i}`}
                            className={sheetDetailsClasses.textField}
                            variant="outlined"
                            fullWidth
                            inputProps={{ maxLength: 3 }}
                            onChange={handleTemperatureChange(i)}
                            value={t}
                          />
                          {errors[`temperature${i}`] ? (
                            <div className={sheetDetailsClasses.error}>
                              Please, enter a valid temperature
                            </div>
                          ) : null}
                        </label>
                      </Grid>
                    ))}
                    <Grid item xs={12} className={sheetDetailsClasses.zeroPaddingTop}>
                      <label htmlFor="humidity">
                        <Typography variant="body1" color="textSecondary">
                          Humidity:
                        </Typography>
                        <Controller
                          name="humidity"
                          control={control}
                          defaultValue=""
                          rules={{
                            validate: {
                              isNumber: (value) =>
                                Number.isFinite(+value) || 'Please, enter a number',
                              isCorrect: (value) => {
                                if (+value > 100 || +value < 0) {
                                  return 'Must be a number from 0 to 100';
                                } 
                              },
                            },
                          }}
                          as={
                            <TextField
                              id="humidity"
                              className={sheetDetailsClasses.textField}
                              error={Boolean(errors.humidity)}
                              variant="outlined"
                              inputProps={{
                                'aria-label': 'Humidity',
                                maxLength: 3,
                              }}
                              fullWidth
                            />
                          }
                        />
                        {errors.humidity ? (
                          <div className={sheetDetailsClasses.error}>{errors.humidity.message}</div>
                        ) : null}
                      </label>
                    </Grid>
                    <Grid item xs={12} className={sheetDetailsClasses.zeroPaddingTop}>
                      <label htmlFor="notes">
                        <Typography variant="body1" color="textSecondary">
                          Notes:
                        </Typography>
                        <Controller
                          name="notes"
                          control={control}
                          defaultValue=""
                          as={
                            <TextField
                              id="notes"
                              className={sheetDetailsClasses.textField}
                              error={Boolean(errors.notes)}
                              variant="outlined"
                              inputProps={{ 'aria-label': 'Weather notes' }}
                              fullWidth
                            />
                          }
                        />
                        {errors.notes ? (
                          <div className={sheetDetailsClasses.error}>{errors.notes}</div>
                        ) : null}
                      </label>
                    </Grid>
                  </Grid>
                </div>

                <div className={sheetFormClasses.buttonHolder}>
                  <Button
                    color="secondary"
                    variant="outlined"
                    className={sheetFormClasses.cancelButton}
                    onClick={closeDrawer}
                    disableTouchRipple
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    variant="outlined"
                    className={sheetFormClasses.saveButton}
                    disableTouchRipple
                    disabled={errors && !!Object.keys(errors).length}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </form>
          </PageLoader>
        )}
      </div>
      {infoModal.isOpen && (
        <AlertModal isOpen={infoModal.isOpen} info={infoModal.text} onClose={closeModal} />
      )}
    </>
  );
};

WeatherForm.propTypes = {
  closeDrawer: propTypes.func.isRequired,
};

export default WeatherForm;
