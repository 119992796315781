import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { format, parse, isAfter, endOfDay, startOfDay } from 'date-fns';
import classnames from 'classnames';

import ConfirmDialog from '../../../../../../../../components/ConfirmDialog';
import { getEquipmentColorClass } from 'helpers/_helpers';
import { hasPermissionsFor } from 'helpers/_helpers';
import styles from './styles';
import CloseIconGrey from 'assets/images/close-icon-grey.svg';
import { getEquipmentEvents } from 'store/availability/availabilityPagesOperations';
import { getOptions } from 'helpers/getOptions';
import $api from 'http/index';
import { useDispatch } from 'react-redux';
import { globalBEDateFormat } from 'common/dateFormatConfig';
import availabilityPageActions from 'store/availability/availabilityPageActions';

const EquipmentListForADay = ({
  classes,
  dayInfo,
  isNotCurrentMonth,
  openAssignModal,
  isInPast,
  openSnackbar,
  openDrawer,
}) => {
  const dispatch = useDispatch();
  const [isHidden, setIsHidden] = useState(true);
  const [modal, setModal] = useState({
    isOpen: false,
    type: 'delete',
    eventId: '',
  });

  const ref = React.createRef();

  useEffect(() => {
    document.addEventListener('click', hideList, true);
    return () => {
      document.removeEventListener('click', hideList, true);
    };
  }, []);

  const toggleListLength = (e) => {
    e.stopPropagation();
    setIsHidden((prev) => !prev);
  };

  const hideList = (event) => {
    if (ref.current && !ref.current.contains(event.target) && !isHidden && !modal.isOpen) {
      setIsHidden(true);
    }
  };

  const getEquipmentInfo = (equipment, field) => {
    if (!equipment) return '';
    if (field === 'number') {
      return parseInt(equipment.name);
    }
    if (field === 'className') {
      return getEquipmentColorClass(equipment.color);
    }
    return equipment[field];
  };

  const getDayNumber = (date) => format(parse(date, globalBEDateFormat, new Date()), 'dd');

  const isToday = (date) => format(new Date(), globalBEDateFormat) === date;

  const handleDeleteEquipmentEvent = (event) => (e) => {
    e.stopPropagation();

    const date = dayInfo.dayNumber
    if (event.inDays?.length > 1 && (date === event.inDays?.[0] || date === event.inDays?.[event.inDays?.length - 1])) {
      setModal({ isOpen: true, type: 'day', eventId: event._id, date });
    } else if (event.inDays?.length === 1) { // one day event - completely delete
      setModal({ isOpen: true, type: 'delete', eventId: event._id });
    } else { // split calendar event
      openDrawer('split', event, { splitBy: date });
    }
  };

  const deleteEquipmentEvent = async () => {
    const { eventId } = modal;

    try {
      await $api.delete(
        `${process.env.REACT_APP_BASE_URL}/calendar-events/${eventId}`,
        getOptions()
      );
      dispatch(getEquipmentEvents());
      openSnackbar('success', 'Successfully Deleted!');
    } catch (error) {
      openSnackbar('error', error.message);
    }
    closeModal();
  };

  const deleteDayEquipmentEvent = async () => {
    const { eventId, date } = modal;

    try {
      const res = await $api.put(
        `${process.env.REACT_APP_BASE_URL}/calendar-events/${eventId}/delete-day`,
        { date },
        getOptions()
      );

      dispatch(availabilityPageActions.updateEquipmentEvent(res.data));
      openSnackbar('success', 'Day Successfully Deleted!');
    } catch (error) {
      openSnackbar('error', error.message);
    }
    closeModal();
  }

  const isEditableEvent = (start) => {
    const startDate = endOfDay(parse(start, globalBEDateFormat, new Date()));
    const todayStartTime = startOfDay(new Date());
    return isAfter(startDate, todayStartTime);
  };

  const hasAccess = () => {
    return (
      hasPermissionsFor('availabilityEquipmentEdit') || hasPermissionsFor('availabilityFullAccess')
    );
  };
  const closeModal = () => {
    setModal((prev) => ({ ...prev, isOpen: false }));
    setIsHidden(true);
  };

  const onEdit = (calendarEvent) => (e) => {
    e.stopPropagation();
    if (hasAccess()) {
      openDrawer('update', calendarEvent)
    }
  }

  return (
    <>
      <div
        className={classnames(
          classes.cellContent,
          !isHidden && classes.absolute,
          !isHidden && isInPast(dayInfo.dayNumber) && classes.greyBackground
        )}
        onBlur={hideList}
        ref={ref}
        onClick={
          (hasAccess() && !isInPast(dayInfo.dayNumber))
            ? openAssignModal('equipment', dayInfo)
            : null
        }
      >
        <div>
          <span
            className={classnames(
              classes.dayNumber,
              isToday(dayInfo.dayNumber) && classes.todayNumber
            )}
          >
            {getDayNumber(dayInfo.dayNumber)}
          </span>
        </div>

        <div className={classnames(classes.list, !isHidden && classes.visible)}>
          {dayInfo.events.map((calendarEvent) => (
            <div key={calendarEvent._id} className={classes.flexBox}>
              <div
                className={classnames(
                  classes.equipmentBlock,
                  getEquipmentInfo(calendarEvent.equipment, 'className'),
                  isEditableEvent(calendarEvent.start) ? classes.editableEvent : classes.fullWidth
                )}
                style={{
                  backgroundColor: getEquipmentInfo(calendarEvent.equipment, 'color'),
                }}
                onClick={onEdit(calendarEvent)}
              >
                <div className={classes.equipmentNumber}>
                  {getEquipmentInfo(calendarEvent.equipment, 'name')}
                </div>
              </div>
              {isEditableEvent(calendarEvent.start) && hasAccess() && (
                <Button
                  color="secondary"
                  className={classes.actionBtn}
                  onClick={handleDeleteEquipmentEvent(calendarEvent)}
                  disableRipple
                >
                  <img src={CloseIconGrey} alt="delete" />
                </Button>
              )}
            </div>
          ))}
        </div>
        {dayInfo.events.length > 4 && (
          <div className={classnames(!isHidden && classes.borderBottom)}>
            <Button
              className={classes.othersBtn}
              onClick={toggleListLength}
              variant="text"
              disableRipple
            >
              {isHidden ? `+ ${dayInfo.events.length - 4} others` : 'hide'}
            </Button>
          </div>
        )}
      </div>

      {modal.isOpen && modal.type === 'delete' && (
        <ConfirmDialog
          isOpen={modal.isOpen}
          onClose={closeModal}
          onSubmit={deleteEquipmentEvent}
          text="Are sure you want to delete this calendar event?"
          disableEscape
          loadingOnSubmit
        />
      )}
      {modal.isOpen && modal.type === 'day' && modal.date && (
        <ConfirmDialog
          isOpen={modal.isOpen}
          onClose={closeModal}
          onSubmit={deleteDayEquipmentEvent}
          text="Are sure you want to delete this day from the calendar event?"
          disableEscape
          loadingOnSubmit
        />
      )}
    </>
  );
};

EquipmentListForADay.propTypes = {
  classes: propTypes.object.isRequired,
  dayInfo: propTypes.object.isRequired,
  openSnackbar: propTypes.func.isRequired,
  isNotCurrentMonth: propTypes.func.isRequired,
  openAssignModal: propTypes.func.isRequired,
};

export default withStyles(styles)(EquipmentListForADay);
