import React from 'react';
import propTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import Grid from '@material-ui/core/Grid';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { useWorklogStyles, useCrewBlockStyles } from '../../hooks';
import EditIcon from 'assets/images/edit-icon.svg';
import PrintIcon from 'assets/images/print-icon.svg';
import ImagesBlock from './components/ImagesBlock/ImagesBlock';
import { openPrintPage } from '../../../ImagesForm/ImagesForm';
import { useParams } from 'react-router-dom';
import { WORKLOG_PRINT_QUERY } from 'pages/WorklogPrint/components/constants';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import { isIOS } from 'components/Option';
import MenuItem from '@material-ui/core/MenuItem';

const NotesBlock = ({
  hasAccess,
  notes,
  noteComments,
  schedulerNotes,
  openAdditionalDrawer,
  sheet,
}) => {
  const worklogClasses = useWorklogStyles();
  const crewBlockClasses = useCrewBlockStyles();
  const { type } = useParams();
  return (
    <>
      {!!sheet?.project?.documentStorage?.length && (
        <Grid item xs={12} md={12}>
          <div className={crewBlockClasses.header}>
            <Typography variant="body1" color="textSecondary">
              Documents:
            </Typography>
          </div>
          {sheet?.project?.documentStorage?.length === 1 ? (
            <Grid item xs={12} lg={12} className={classnames(worklogClasses.zeroPaddingTop)}>
              <span className={classnames(worklogClasses.docLinks)}>
                <Link
                  className={worklogClasses.docLinks}
                  href={sheet?.project?.documentStorage[0].url}
                  target="_blank"
                >
                  {sheet?.project?.documentStorage[0].name}
                </Link>
                &nbsp;
              </span>
            </Grid>
          ) : (
            <Grid item xs={12} lg={4} className={classnames(worklogClasses.zeroPaddingTop)}>
              <TextField
                select
                id="documentStorageId"
                name="documentStorageId"
                className={worklogClasses.textFieldSelect}
                variant="outlined"
                onChange={(e) => {
                  e.preventDefault();
                }}
                label={'Select the document to open'}
                inputProps={{
                  shrink: true,
                  'aria-label': 'Shift',
                }}
                SelectProps={{
                  displayEmpty: true,
                }}
                fullWidth
                size="small"
                value={''}
              >
                {[...sheet?.project?.documentStorage]
                  ?.sort((a, b) => a?.name?.localeCompare(b?.name))
                  .map((doc) => (
                    <MenuItem
                      key={doc.name}
                      value={doc.name}
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(doc.url, '_black', 'noreferrer');
                      }}
                    >
                      {doc.name}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
          )}
        </Grid>
      )}
      <Grid item xs={12} md={6}>
        <div className={crewBlockClasses.header}>
          <Typography
            variant="body1"
            color="textSecondary"
            className={classnames(worklogClasses.regularFontWeight, worklogClasses.paddingBottom)}
          >
            Notes:
          </Typography>
        </div>
        <Typography variant="body1" color="textPrimary" className={worklogClasses.breakText}>
          {notes || 'N/A'}
          {hasAccess && notes && (
            <Button
              variant="text"
              className={classnames(
                worklogClasses.primaryTextButton,
                worklogClasses.commentsButton
              )}
              onClick={openAdditionalDrawer('comments')}
              disableRipple
            >
              {noteComments ? noteComments.length : 0} Comment(s)
            </Button>
          )}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <div className={crewBlockClasses.header}>
          <Typography
            variant="body1"
            color="textSecondary"
            className={classnames(worklogClasses.regularFontWeight, worklogClasses.paddingBottom)}
          >
            Internal Notes:
          </Typography>
          {hasAccess && (
            <IconButton
              className={crewBlockClasses.editButton}
              onClick={openAdditionalDrawer('notes')}
              disableRipple
              disableTouchRipple
            >
              <img src={EditIcon} alt="edit crew" />
            </IconButton>
          )}
        </div>
        <Typography variant="body1" color="textPrimary" className={worklogClasses.breakText}>
          {schedulerNotes || 'N/A'}
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <div className={crewBlockClasses.header}>
          <Typography
            variant="body1"
            color="textSecondary"
            className={classnames(worklogClasses.regularFontWeight, worklogClasses.paddingBottom)}
          >
            Images:
          </Typography>
          {hasAccess && !!sheet?.images?.length && (
            <div>
              <IconButton
                className={crewBlockClasses.editButton}
                onClick={() =>
                  openPrintPage(
                    sheet._id,
                    'true',
                    type,
                    `&${WORKLOG_PRINT_QUERY.printAllImgsOnSheet}=true`
                  )
                }
                disableRipple
                disableTouchRipple
              >
                <img src={PrintIcon} alt="print" />
              </IconButton>
              <IconButton
                className={crewBlockClasses.editButton}
                onClick={openAdditionalDrawer('notes')}
                disableRipple
                disableTouchRipple
              >
                <img src={EditIcon} alt="edit crew" />
              </IconButton>
            </div>
          )}
        </div>
      </Grid>
      <Grid item style={{ marginTop: '-25px' }}>
        <ImagesBlock hasAccess={hasAccess} />
      </Grid>
    </>
  );
};

NotesBlock.propTypes = {
  notes: propTypes.string,
  noteComments: propTypes.array,
  schedulerNotes: propTypes.string,
  hasAccess: propTypes.bool.isRequired,
  openAdditionalDrawer: propTypes.func.isRequired,
};

export default NotesBlock;
