import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Dialog, Typography, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useForm } from 'react-hook-form';
import Divider from '@material-ui/core/Divider';

import PageLoader from 'components/PageLoader2';
import AlertModal from 'components/AlertModal';
import styles from './styles';
import { OverlapsingModal } from './../OverlapsDuplicateModal/OverlapsingModal';
import ConflictItems from './components/ConflictItems/ConflictItems';
import { format } from 'date-fns';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';
import { parseStringDate } from 'helpers/_date-helpers';

const ConflictModal = ({ classes, data = [], closeModal, openSnackbar, submit }) => {
  const { dateFormat } = useDateSettingsFormat();

  const { handleSubmit, control } = useForm({ mode: 'onBlur' });
  const [infoModal, setInfoModal] = useState({
    isOpen: false,
    text: '',
  });
  const [overlappingModal, setOverlappingModal] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);

  const closeInfoModal = () => setInfoModal({ isOpen: false, text: '' });
  const closeOverlappingModal = () => {
    setOverlappingModal(null);
  };

  const saveOverlapping = () => {
    closeOverlappingModal();
    closeModal();
  };

  const onSubmit = async (values) => {
    const resData = [];
    for (const key in values) {
      resData.push({
        sheetId: key,
        ...(values[key].workers
          ? {
              workers: Object.entries(values[key].workers).map((el) => ({
                _id: el[0],
                conflictAction: el[1],
              })),
            }
          : {}),
        ...(values[key].equipment
          ? {
              equipment: Object.entries(values[key].equipment).map((el) => ({
                _id: el[0],
                conflictAction: el[1],
              })),
            }
          : {}),
      });
    }

    try {
      await submit(resData);
      closeModal();
    } catch (e) {
      if (e?.response?.data?.type === 'range-sheet-time-overlapse') {
        setOverlappingModal({
          reason: e.response.data.message,
          data: e.response.data.data,
        });
      }
      if (e?.response?.data?.type === 'timeoff-time-overlapse') {
        setInfoModal({ isOpen: true, text: e.response.data.message });
      }
    }
  };

  return (
    <>
      <Dialog
        open
        onClose={closeModal}
        PaperProps={{
          classes: {
            root: classes.dialogRoot,
          },
        }}
        backdropclick
        disableEscapeKeyDown
      >
        <div className={classes.formWrapper} role="presentation">
          <PageLoader loading={isSubmitting} small>
            <form autoComplete="off" className={classes.formFlex} onSubmit={handleSubmit(onSubmit)}>
              <div>
                <Typography variant="h4" style={{ marginBottom: '20px' }}>
                  Assigned resources
                </Typography>

                <IconButton
                  className={classes.closeButton}
                  onClick={closeModal}
                  aria-label="Close"
                  disableTouchRipple
                >
                  <CloseIcon />
                </IconButton>
                <div className={classes.worklogsWrapper}>
                  {data
                    .filter((worklog) => worklog.conflictWorkers || worklog.conflictEquipment)
                    .map((worklog) => {
                      return (
                        <>
                          <div className={classes.dateItem}>
                            {format(parseStringDate(worklog.date), dateFormat)}
                          </div>
                          {worklog.conflictWorkers && (
                            <ConflictItems
                              conflictResources={worklog.conflictWorkers}
                              control={control}
                              resourceType={'workers'}
                              sheetId={worklog.sheetId}
                              type={'workers'}
                            />
                          )}

                          {worklog.conflictEquipment && (
                            <ConflictItems
                              conflictResources={worklog.conflictEquipment}
                              control={control}
                              resourceType={'equipment'}
                              sheetId={worklog.sheetId}
                              type={'equipment'}
                            />
                          )}
                          <Divider style={{ marginBottom: 10 }} />
                        </>
                      );
                    })}
                </div>
              </div>

              <div className={classes.buttonHolder}>
                <Button
                  color="secondary"
                  variant="outlined"
                  className={classes.cancelButton}
                  onClick={closeModal}
                  disabled={isSubmitting}
                  disableRipple
                  disableTouchRipple
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  variant="outlined"
                  className={classes.saveButton}
                  disabled={isSubmitting}
                  disableRipple
                  disableTouchRipple
                >
                  Save
                </Button>
              </div>
            </form>
          </PageLoader>
        </div>
      </Dialog>

      {infoModal.isOpen && (
        <AlertModal isOpen={infoModal.isOpen} info={infoModal.text} onClose={closeInfoModal} />
      )}
      {overlappingModal && (
        <OverlapsingModal
          data={overlappingModal.data}
          message={overlappingModal.reason}
          onClose={closeOverlappingModal}
          onSave={saveOverlapping}
        />
      )}
    </>
  );
};

export default withStyles(styles)(ConflictModal);
