export const findUsed = (sheets, item) => {
  const items = sheets.reduce((result, sheet) => {
    const canceledAt = sheet.sheet ? sheet.sheet.canceledAt : sheet.canceledAt;
    if (sheet[item] && sheet[item].length !== 0 && !canceledAt) {
      return [...result, ...sheet[item]];
    }
    return result;
  }, []);

  return [...new Set(items)];
};
