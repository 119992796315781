import { createAction } from '@reduxjs/toolkit';

const getContractorsData = createAction('contractors/getContractorsData');
const contractorsRequest = createAction('contractors/contractorsRequest');
const contractorsError = createAction('contractors/contractorsError');
const getContractorsDataAdd = createAction('contractors/getContractorsDataAdd');
const toggleContractorStatus = createAction('contractors/toggleContractorStatus');
const deleteContractor = createAction('contractors/deleteContractor');
const createNewContractor = createAction('contractors/createNewContractor');
const updateContractor = createAction('contractors/updateContractor');

export default {
  getContractorsData,
  contractorsRequest,
  contractorsError,
  getContractorsDataAdd,
  toggleContractorStatus,
  deleteContractor,
  createNewContractor,
  updateContractor
};
