const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
const isStandalone = !!(iOS && window.navigator.standalone);

const overrides = {
  MuiDrawer: {
    paper: {
      maxWidth: '100%',
      paddingTop: isStandalone ? 20 : 0,
    },
  },
  MuiTypography: {
    colorTextSecondary: {
      color: '#a4a5b3',
    },
    colorTextPrimary: {
      color: '#202020',
      fontWeight: 500,
    },
  },
  MuiIconButton: {
    root: {
      '&:focus': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
      },
    },
  },
  MuiButton: {
    root: {
      textTransform: 'none',
      minWidth: 0,
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
      },
      '&:focus': {
        backgroundColor: '#fff',
      },
      '&.Mui-disabled': {
        backgroundColor: 'transparent!important',
      },
    },
    containedSecondary: {
      color: '#777',
      backgroundColor: '#fff',
      textTransform: 'none',
      boxShadow: 'none',
      border: '1px solid #d9dae3',
      borderRadius: 5,
      '&:hover': {
        color: '#777 !important',
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
      },
      '&:focus': {
        color: '#777 !important',
        backgroundColor: '#fff',
      },
      '&.Mui-disabled': {
        borderColor: '#d9dae3',
      },
    },
  },
  MuiPaper: {
    elevation1: {
      boxShadow: 'none',
      border: '1px solid #d9dae3',
      borderRadius: 5,
    },
  },
  MuiTab: {
    root: {
      textTransform: 'none',
      fontSize: '0.9rem !important',
      '&:hover': {
        color: '#5a87ef !important',
        backgroundColor: 'rgba(0, 0, 0, 0.08) !important',
      },
      '&:focus': {
        color: '#5a87ef !important',
        backgroundColor: '#fff !important',
      },
      '@media (max-width: 370px)': {
        fontSize: '12px !important',
      },
    },
  },
  MuiTabs: {
    indicator: {
      backgroundColor: '#5a87ef',
    },
  },
  MuiTextField: {
    root: {
      margin: '8px 0',
      '& > div': {
        border: '1px solid #d9dae3',
        borderRadius: 5,
        padding: 0,
        overflow: 'hidden',
        '&.Mui-error': {
          borderColor: '#de6464',
        },
      },
      '& fieldset': {
        border: 'none',
        display: 'none',
      },
      '& input': {
        margin: 0,
        border: 'none',
        boxShadow: 'none',
        height: '100%',
        padding: '0.4rem',
        boxSizing: 'border-box',
        fontSize: 14,
      },
      '& input:disabled': {
        backgroundColor: '#ddd',
        color: 'rgba(0,0,0,.75)',
      },
      '& textarea': {
        border: 'none',
        boxShadow: 'none',
        padding: '0.6rem 0.4rem',
      },
    },
  },
  InputBase:{
    color:'red'
  },
  MuiSelect: {
    root: {
      padding: 1,
    },
    select: {
      fontSize: '0.8rem',
      padding: '0.4rem',
      borderRadius: 5,
      '&:focus': {
        borderRadius: 5,
      },
    },
    outlined: {
      paddingRight: '24px!important',
    },
  },
  MuiFormControlLabel: {
    root: {
      width: '100%',
      justifyContent: 'space-between',
      margin: '0 !important',
    },
  },
  MuiExpansionPanel: {
    root: {
      border: 'none',
      borderTop: '1px solid #d9dae3',
      borderRadius: '0 !important',
    },
  },
  MuiDialogActions: {
    root: {
      marginBottom: 10,
      paddingLeft: 25,
      paddingRight: 25,
      justifyContent: 'space-between',
    },
  },
  MuiMenuItem: {
    root: {
      fontSize: '0.8rem',
    },
  },
  Mui: {
    root: {
      '&$error': {},
    },
  },
  MuiAutocomplete: {
    inputRoot: {
      padding: '0!important',
    },
    input: {
      fontSize: '0.8rem!important',
      padding: '6.4px 24px 6.4px 6.4px!important',
      margin: '0!important',
    },
  },
};

export default overrides;
