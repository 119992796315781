import React, {useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import styles from './styles';
import { drawerTypes } from '../../../Equipment/components/helpers/drawerTypes';
import { useSelector } from 'react-redux';

const NotesHeader = ({ classes, openDrawer, isAddButtonShown }) => {
  const {allProjects,projects, projectId} = useSelector(store=>store.notes)

  const [project, setProject] = useState(null)
  useEffect(() => {
    setProject(allProjects.find(el=>el._id === projectId))
  }, [allProjects, projectId]);
  return (
    <header className={classes.header}>
      {project && <Typography variant="h3">{project.route + (project.section ? ', Section ' + project.section : '')}</Typography>}
      {isAddButtonShown && (
        <Button
          color="secondary"
          className={classes.addButton}
          onClick={openDrawer(drawerTypes.create, true)}
          // disabled={checkUserRole('Project Management')}
          disableTouchRipple
        >
          + Add Note
        </Button>
      )}
    </header>
  );
};

NotesHeader.propTypes = {
  classes: propTypes.object.isRequired,
  openDrawer: propTypes.func.isRequired,
  project: propTypes.object.isRequired,
  isAddButtonShown: propTypes.bool.isRequired,
};

export default withStyles(styles)(NotesHeader);
