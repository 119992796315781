import React from "react";
import {Button} from "@material-ui/core";
import Excel from "../../../../components/icons/Excel";
import useStyles from "./useStyles";

const ExportExcelButton = (props) => {
    const classes = useStyles()

    return <Button {...props} className={classes.root} variant={'text'} startIcon={<Excel/>}>
        Export to Excel
    </Button>
}

export default ExportExcelButton