export default (theme) => ({
  stepButton: {
    background: 'none',
    border: 'none',
    outline: 'none',
    margin: '0',
    padding: '0',
    '&:hover,&:focus': {
      background: 'none',
      border: 'none',
    },
  },
  triggerButton: {
    background: 'none',
    border: 'none',
    outline: 'none',
    padding: 0,
    margin: 0,
    width: '100%',
    height: '100%',
    '&:hover,&:focus': {
      background: 'none',
      border: 'none',
    },
  },
  incrementIcon: {
    width: 9,
    height: 15,
    display: 'block',
  },
  decrimentIcon: {
    transform: 'rotate(180deg)',
    // marginRight: 8,
    width: 9,
    height: 15,
    display: 'block',
  },
  label: {
    color: '#202020',
    fontSize: 18,
    letterSpacing: 0.23,
    lineHeight: '27px',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    '& *': {
      outline: 'none',
    },
  },
  triggerIcon: {
    transform: 'rotate(90deg)',
    width: 9,
    height: 15,
    display: 'block',
  },
  triggerIconActive: {
    transform: 'rotate(270deg)',
  },
  dis: {
    opacity: 0.3,
  },
});
