import { useState } from 'react';

import { generateIncrement } from 'helpers/generateIncrement';
import { useSelector } from 'react-redux';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';
import { TIME_FORMAT, DAY_PART } from 'common/dateFormatConfig';

/**
 *
 * @param {number} hourMaxValue
 * @param {number} hourStep
 * @param {number} hourStart
 * @param {number} hourPadMaxLength
 * @param {number} minuteMaxValue
 * @param {number} minuteStep
 * @param {number} minuteStart
 * @param {number} minutePadMaxLength
 * @returns {{hours: string[], minutes: string[], days: [string, string, string], dayPart: [string, string]}}
 */
export const useTimeStates = ({
  hourMaxValue = 12,
  hourStep = 1,
  hourStart = 1,
  hourPadMaxLength = 0,
  minuteMaxValue = 60,
  minuteStep = 15,
  minuteStart = 0,
  minutePadMaxLength = 2,
  takeSettingsHours = false,
  overlapsHourMaxValue,
} = {}) => {
  const timeFormat = useSelector(
    (store) => store.personalProfile.organization?.settings?.timeFormat
  );
  const is12Format = timeFormat === TIME_FORMAT[0];
  const settingsTime = is12Format ? (overlapsHourMaxValue ? hourMaxValue : 12) : 24;

  const [time] = useState({
    hours: generateIncrement({
      maxValue: takeSettingsHours ? settingsTime : hourMaxValue,
      step: hourStep,
      start: is12Format ? hourStart : 0,
      padMaxLength: hourPadMaxLength,
    }),
    minutes: generateIncrement({
      maxValue: minuteMaxValue,
      step: minuteStep,
      start: minuteStart,
      padMaxLength: minutePadMaxLength,
    }),
    dayPart: DAY_PART,
    days: ['-1', '0', '1'],
  });

  return time;
};

export const generateIncrementDateCreator = (options, timeFormat) => {
  const is12Format = timeFormat === TIME_FORMAT[0];
  const maxValue = is12Format ? 12 : 24;
  const start = is12Format ? options.start : 0;

  return { maxValue, step: 1, start };
};
