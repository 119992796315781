import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import {
  Button,
  Dialog,
  Typography,
  IconButton,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import photoPlaceholder from 'assets/images/user-photo-placeholder.svg';
import PageLoader from 'components/PageLoader2';
import AlertModal from 'components/AlertModal';
import styles from './styles';
import OverlappingModal from 'components/OverlapsingModal';
import { useGetEquipmentInfo } from '../../../../hooks/useGetEquipmentInfo';
import { useGetUserData } from '../../../../hooks/useGetUserData';
import { getOptions } from 'helpers/getOptions';
import $api from 'http/index';
import currentWorklogActions from 'store/currentWorklog/actions';
import { updateSheets } from 'store/schedule/schedulePageOperation';
import modalsPageActions from 'store/modals/modalsPageActions';
import { CALENDAR_VIEW_TYPES } from 'pages/Schedule/components/ScheduleHeader/components/DateTypeSelector/constants';

const ResourceConflictModal = ({ classes, data, closeModal }) => {
  const { handleSubmit, control } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: { conflictAction: 'move' },
  });
  const [submitting, setSubmitting] = useState(false);
  const [infoModal, setInfoModal] = useState({ isOpen: false, text: '' });
  const [overlappingModal, setOverlappingModal] = useState(null);

  const closeInfoModal = () => setInfoModal({ isOpen: false, text: '' });
  const closeOverlappingModal = () => {
    setOverlappingModal(null);
    closeModal();
  };

  const saveOverlapping = () => {
    closeOverlappingModal();
  };

  const [resourceData, setResourceData] = useState(null);
  const { getUserData } = useGetUserData();
  const { getEquipmentInfo } = useGetEquipmentInfo();
  const { calendarTypeView } = useSelector((store) => store.schedule);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data.removeData.resourceType !== 'workers') return;
    getUserData(data.removeData.resourceId).then((data) =>
      setResourceData({
        name: data.username,
        shift: data.profile.shifts.timeOfDay.toLowerCase(),
      })
    );
  }, [data.removeData.resourceType === 'workers']);

  useEffect(() => {
    if (data.removeData.resourceType !== 'equipments') return;
    getEquipmentInfo(data.removeData.resourceId);
  }, [data.removeData.resourceType === 'equipments']);

  const onSubmit = async (values) => {
    const sourceSheetId = values.conflictAction === 'move' ? data.from || undefined : undefined;
    const resource = {
      _id: data.addData.resource._id,
      conflictAction: values.conflictAction,
      sourceSheetId: sourceSheetId,
    };

    if (data.addData.resourceType === 'workers') {
      try {
        const res = await $api.put(
          `${process.env.REACT_APP_BASE_URL}/sheets/${data.to}/workers`,
          { workers: [resource, ...data.sheet.workers] },
          { ...getOptions() }
        );

        dispatch(currentWorklogActions.setWorklogSheet(res.data));
        if (calendarTypeView != CALENDAR_VIEW_TYPES.monthly) dispatch(updateSheets());
        closeModal();
      } catch (e) {
        if (e.response && e.response.data.type === 'sheet-time-overlapse') {
          dispatch(
            modalsPageActions.openModal({
              data: {
                reason: e.response.data.message,
                data: e.response.data.data,
                moveFrom: sourceSheetId,
              },
            })
          );
          setOverlappingModal({
            reason: e.response.data.message,
            data: e.response.data.data,
            moveFrom: sourceSheetId,
          });
        }
        if (e.response && e.response.data.type === 'timeoff-time-overlapse') {
          setInfoModal({ isOpen: true, text: e.response.data.message });
        }
      }
    } else if (data.removeData.resourceType === 'equipments') {
      try {
        const res = await $api.put(
          `${process.env.REACT_APP_BASE_URL}/sheets/${data.to}/equipment`,
          { equipment: [resource, ...data.sheet.equipment] },
          getOptions()
        );

        dispatch(currentWorklogActions.setWorklogSheet(res.data));
        dispatch(updateSheets());
        closeModal();
      } catch (e) {
        if (e.response && e.response.data.type === 'sheet-time-overlapse') {
          dispatch(
            modalsPageActions.openModal({
              data: {
                reason: e.response.data.message,
                data: e.response.data.data,
                moveFrom: sourceSheetId,
                type: e.response.data.resourceType,
              },
            })
          );
          setOverlappingModal({
            reason: e.response.data.message,
            data: e.response.data.data,
            moveFrom: sourceSheetId,
            type: e.response.data.resourceType,
          });
        }
        if (e.response && e.response.data.type === 'timeoff-time-overlapse') {
          setInfoModal({ isOpen: true, text: e.response.data.message });
        }
        if (e.response && e.response.data.type === 'calendar-event-time-overlapse') {
          setInfoModal({ isOpen: true, text: e.response.data.message });
        }
      }
    }
  };

  if (!resourceData && data.removeData.resourceType !== 'equipments') return null; //

  return (
    <>
      <Dialog
        open={true}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          classes: {
            root: classes.dialogRoot,
          },
        }}
        disableEscapeKeyDown
      >
        <div className={classes.formWrapper} role="presentation">
          <PageLoader loading={submitting} small>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className={classes.formFlex}>
              <div>
                <Typography variant="h4" style={{ marginBottom: '20px' }}>
                  Assigned resource
                </Typography>

                <IconButton
                  className={classes.closeButton}
                  onClick={closeModal}
                  aria-label="Close"
                  disableTouchRipple
                >
                  <CloseIcon />
                </IconButton>

                <Grid container spacing={1} className={classes.flexBox}>
                  <Grid item xs={12} md={6}>
                    {data.removeData.resourceType === 'workers' ? (
                      <div className={classes.inlineFlex}>
                        <div
                          className={classnames(
                            classes.photoWrapper,
                            classes[`${resourceData.shift}Border`]
                          )}
                        >
                          <img src={photoPlaceholder} className={classes.photo} />
                        </div>
                        <Typography
                          variant="body1"
                          color="textPrimary"
                          className={classnames(classes.name, classes[resourceData.shift])}
                        >
                          {resourceData.name}
                        </Typography>
                      </div>
                    ) : (
                      <div
                        className={classnames(
                          classes.equipmentBlock,
                          getEquipmentInfo(data.removeData.resourceId, 'className')
                        )}
                        style={{
                          backgroundColor: getEquipmentInfo(data.removeData.resourceId, 'color'),
                        }}
                      >
                        <div className={classes.equipmentNumber}>
                          {getEquipmentInfo(data.removeData.resourceId, 'name')}
                        </div>
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6} className={classes.textRight}>
                    <Controller
                      name="conflictAction"
                      control={control}
                      render={({ value, onChange }) => (
                        <RadioGroup
                          name="conflictAction"
                          value={value}
                          onChange={onChange}
                          className={classes.inlineFlex}
                        >
                          <FormControlLabel
                            value="move"
                            label="Move"
                            labelPlacement="end"
                            control={
                              <Radio
                                color="primary"
                                className={classes.radioButton}
                                checkedIcon={
                                  <span className={classnames(classes.icon, classes.checkedIcon)} />
                                }
                                icon={<span className={classes.icon} />}
                                disableRipple
                              />
                            }
                            className={classes.label}
                          />
                          <FormControlLabel
                            value="duplicate"
                            label="Duplicate"
                            labelPlacement="end"
                            control={
                              <Radio
                                color="primary"
                                className={classes.radioButton}
                                checkedIcon={
                                  <span className={classnames(classes.icon, classes.checkedIcon)} />
                                }
                                icon={<span className={classes.icon} />}
                                disableRipple
                              />
                            }
                            className={classes.label}
                          />
                        </RadioGroup>
                      )}
                    />
                  </Grid>
                </Grid>
              </div>

              <div className={classes.buttonHolder}>
                <Button
                  color="secondary"
                  variant="outlined"
                  className={classes.cancelButton}
                  onClick={closeModal}
                  disabled={submitting}
                  disableRipple
                  disableTouchRipple
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  variant="outlined"
                  className={classes.saveButton}
                  disabled={submitting}
                  disableRipple
                  disableTouchRipple
                >
                  Save
                </Button>
              </div>
            </form>
          </PageLoader>
        </div>
      </Dialog>

      {infoModal.isOpen && (
        <AlertModal isOpen={infoModal.isOpen} info={infoModal.text} onClose={closeInfoModal} />
      )}
      {overlappingModal && (
        <OverlappingModal
          data={overlappingModal.data}
          message={overlappingModal.reason}
          onClose={closeOverlappingModal}
          onSave={saveOverlapping}
          moveFrom={overlappingModal.moveFrom}
          type={overlappingModal?.type}
        />
      )}
    </>
  );
};

ResourceConflictModal.propTypes = {
  classes: propTypes.object.isRequired,
  data: propTypes.object.isRequired,
  closeModal: propTypes.func.isRequired,
};

export default withStyles(styles)(ResourceConflictModal);
