export default (theme) => ({
  formWrapper: {
    padding: '20px 16px 0',
    [theme.breakpoints.down('sm')]: {
      minWidth: 320,
    },
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      width: 550,
    },
  },
  closeButton: {
    position: 'absolute',
    right: 5,
    top: 12,
  },
  buttonHolder: {
    textAlign: 'center',
  },
  cancelButton: {
    margin: 20,
    minWidth: 100,
    '&:hover': {
      color: 'inherit',
    },
    '&:disabled': {
      backgroundColor: '#fff',
    },
  },
  saveButton: {
    margin: 20,
    minWidth: 100,
    '&:hover': {
      color: theme.palette.textColor.primary,
    },
    '&:disabled': {
      backgroundColor: '#fff',
    },
  },
  formFlex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '95vh',
  },
  flexBox: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: 300,
    marginTop: 20,
  },
  fixedWidth: {
    width: 210,
  },
  fixedHeight: {
    minHeight: 29,
  },
});
