export default theme => ({
  popper: {
    top: 'auto !important',
    left: 'auto !important',
    transform: 'none !important',
    zIndex: 100
  },
  paper: {
    padding: 8,
    width: 250
  },
  title: {
    fontSize: '0.9rem'
  },
  time: {
    fontSize: '0.8rem'
  },
  email: {
    fontSize: '0.7rem'
  },
  signatureImg: {
    maxWidth:'100%',
  }
});
