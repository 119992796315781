export const SummarySymbol = Symbol('Summary');

function* iterator(timeclock) {
  for (const t of timeclock.timeclock) {
    yield t;
  }

  for (const j of timeclock.jobs) {
    yield j;
  }

  for (const c of timeclock.cipJobs) {
    yield c;
  }

  yield SummarySymbol;
}

export default iterator;
