export default (theme) => ({
  container: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  infoIcon: {
    backgroundColor: '#5A87EF',
    borderRadius: '50%',
    width: 18,
    minWidth: 18,
    height: 18,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontWeight: 600,
    cursor: 'hover',
    lineHeight: 1,
    alignSelf: 'flex-start',
    marginTop: 0,
    '@media (min-width: 766px)': {
      marginTop: 14,
    },
  },
  hint: {
    marginLeft: 10,
  },
});
