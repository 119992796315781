import schedulePageActions from '../schedulePageActions';
import { EQUIPMENT_EVENT } from 'subscriptions/events/events.enum';
import { subscriptionsFabric } from 'subscriptions/subscriptions.fabric';

const handlers = {
  [EQUIPMENT_EVENT.created]: (store) => (data) => {
    if (!data._id) return;
    const equipment = store.getState().schedule.resources.equipment;
    store.dispatch(schedulePageActions.setResourcesEquipment([data, ...equipment]));
  },
  [EQUIPMENT_EVENT.updated]: (store) => (data) => {
    if (!data._id) return;
    const equipment = store.getState().schedule.resources.equipment;
    const updatedEquipment = equipment.map(el => el._id === data._id ? data : el)
    store.dispatch(schedulePageActions.setResourcesEquipment(updatedEquipment));
    store.dispatch(schedulePageActions.updateSheetsEquipment(data));
  },
  [EQUIPMENT_EVENT.deleted]:
    (store) =>
    ({ _id }) => {
      const equipment = store.getState().schedule.resources.equipment;
      const updatedEquipment = equipment.filter(el => el._id !== _id)
      store.dispatch(schedulePageActions.setResourcesEquipment(updatedEquipment));
    },
};

export const equipmentSubscriptionMiddleware = subscriptionsFabric(
  schedulePageActions,
  EQUIPMENT_EVENT,
  handlers
);
