import personalProfileActions from './personalProfileActions';
import $api from 'http/index';
import { setItem } from 'helpers/localStorage';
import { getTimeZone } from 'helpers/getTimeZone';
import availabilityPageActions from 'store/availability/availabilityPageActions';
import { format } from 'date-fns';

const catchError = (error) => {
  if (error.response) {
    if (Array.isArray(error.response.data.message))
      throw new Error(error.response.data.message.join('\n'));
    else throw new Error(error.response.data.message);
  } else {
    throw new Error(error.message);
  }
};

export const fetchPersonalProfile = () => async (dispatch) => {
  const options = {
    headers: {
      'X-Auth-Token': localStorage.getItem('authorization-token'),
    },
  };

  try {
    const response = await $api.get(`${process.env.REACT_APP_BASE_URL}/personal-profile`, options);

    setItem('userId', response.data._id);
    dispatch(personalProfileActions.getPersonalProfile(response.data));
    dispatch(
      availabilityPageActions.setDate(
        format(new Date(), response.data.organization.settings.dateFormat)
      )
    );
  } catch (error) {
    catchError(error);
  }
};

export const patchPersonalProfile = (data) => async (dispatch) => {
  const options = {
    headers: {
      'X-Auth-Token': localStorage.getItem('authorization-token'),
    },
  };

  try {
    const response = await $api.patch(
      `${process.env.REACT_APP_BASE_URL}/personal-profile`,
      data,
      options
    );

    dispatch(personalProfileActions.updatePersonalProfile(response.data));
  } catch (error) {
    catchError(error);
  }
};

export const changePasswordPersonalProfile = async (password) => {
  const options = {
    headers: {
      'X-Auth-Token': localStorage.getItem('authorization-token'),
    },
  };

  const data = {
    password,
  };

  try {
    await $api.post(
      `${process.env.REACT_APP_BASE_URL}/personal-profile/change-password`,
      data,
      options
    );
  } catch (error) {
    catchError(error);
  }
};
