export default () => ({
  root: {
    position: 'relative',
    width: '100%',
    height: '80px',
  },
  label: {
    color: '#4D4D4D',
    fontFamily: 'Open Sans',
    fontSize: 16,
    lineHeight: '19px',
    marginBottom: 6,
    display: 'block',
  },
  labelActive: {
    color: '#5A87EF',
  },
  labelError: {
    color: '#DD6060',
  },
  inputError: {
    border: '1px solid #DD6060 !important',
    '&:focus, &:active': {
      border: '1px solid #DD6060 !important',
    },
  },
  textError: {
    color: '#DD6060',
    fontSize: 12,
  },
  inputNormal: {
    border: '1px solid #D9DAE3 !important',
    '&:focus, &:active': {
      border: '1px solid #D9DAE3 !important',
    },
  },
  inputFocus: {
    border: '1px solid #5A87EF !important',
    '&:focus, &:active': {
      border: '1px solid #5A87EF !important',
    },
  },
  input: {
    borderRadius: '4px !important',
    backgroundColor: '#FFFFFF !important',
    padding: '10px 10px 8px !important',
    color: '#202020 !important',
    fontFamily: 'Poppins !important',
    fontSize: '14px !important',
    fontWeight: '500 !important',
    lineHeight: '20px !important',
    margin: '0 !important',
    display: 'block !important',
    boxShadow: 'none !important',
    boxSizing: 'border-box',
    width: '100%',
    '&:focus, &:active': {
      backgroundColor: '#FFFFFF !important',
      boxShadow: 'none !important',
    },
  },
  showBtn: {
    outline: 'none !important',
    background: 'none !important',
    border: 'none !important',
    padding: '0 !important',
    margin: '0 !important',
    color: '#5A87EF !important',
    fontFamily: 'Poppins !important',
    fontSize: '12px !important',
    fontWeight: '500 !important',
    lineHeight: '18px !important',
    position: 'absolute',
    right: 10,
    bottom: 10,
    top: 18,
  },
});
