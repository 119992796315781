
import { WORKER_EVENT } from 'subscriptions/events/events.enum';
import { subscriptionsFabric } from 'subscriptions/subscriptions.fabric';
import workerSchedulePageActions from 'store/workerSchedule/workerSchedulePageActions';

const handlers = {
  [WORKER_EVENT.shiftChanged]: (store) => (data) => {
    if (data?._id) {
      const workerData = {
        _id: data._id,
        username: data.username,
        shifts: data.profile?.shifts,
      }
      store.dispatch(workerSchedulePageActions.patchFullScheduleWorker(workerData));
    }
  },
  [WORKER_EVENT.usernameChanged]: (store) => (data) => {
    if (data?._id) {
      const workerData = {
        _id: data._id,
        username: data.username,
        shifts: data.profile?.shifts,
      }
      store.dispatch(workerSchedulePageActions.patchFullScheduleWorker(workerData));
    }
  },
}

export const fullScheduleWorkersSubscriptionMiddleware = subscriptionsFabric(
  workerSchedulePageActions,
  WORKER_EVENT,
  handlers
);
