import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { isIOS, Option } from 'components/Option/Option';
import { format } from 'date-fns';
import { RadioGroup, FormControlLabel } from '@material-ui/core';
import CustomRadioButton from 'components/CustomRadioButton/CustomRadioButton';
import { DATE_FORMAT_CONFIG, TIME_FORMAT, REGION_TYPE } from 'common/dateFormatConfig';

const CompanyDateFormatBlock = ({ classes, values, handleChange, setFieldValue }) => {
  return (
    <>
      <Grid container item xs={12} lg={12} alignItems="center">
        <Typography variant="h4">Date Format:</Typography>
        <div className={'tool-info-icon'}>i</div>
      </Grid>
      <Grid container item xs={12} lg={12}>
        <div className={classes.tooltipInfo}>
          Please choose the date format carefully as it cannot be modified later.
        </div>
      </Grid>

      <Grid container item xs={12} lg={12}>
        <RadioGroup
          className={classes.radio}
          onChange={(e) => {
            setFieldValue('settings.dateFormatType', e.target.value);
            setFieldValue('settings.dateFormat', DATE_FORMAT_CONFIG[e.target.value][0]);
          }}
          value={values?.settings.dateFormatType}
          row
        >
          <FormControlLabel
            value={REGION_TYPE.us}
            control={<CustomRadioButton big={'true'} />}
            className={classes.radioItem}
            label="US"
            labelPlacement="end"
          />
          <FormControlLabel
            value={REGION_TYPE.european}
            control={<CustomRadioButton big={'true'} />}
            label="Europe"
            labelPlacement="end"
            className={classes.radioItem}
          />
        </RadioGroup>
      </Grid>

      <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
        <label htmlFor="dateFormat" className={classes.label}>
          <Typography variant="body1" color="textSecondary">
            Date Format:
          </Typography>
          <TextField
            select
            id="settings.dateFormat"
            name="settings.dateFormat"
            value={values.settings.dateFormat}
            onChange={handleChange}
            className={classes.textField}
            variant="outlined"
            inputProps={{ 'aria-label': 'Date Format' }}
            fullWidth
            SelectProps={{
              native: isIOS,
            }}
          >
            {DATE_FORMAT_CONFIG[values.settings.dateFormatType].map((format) => (
              <Option key={format} value={format}>
                {format?.toUpperCase()}
              </Option>
            ))}
          </TextField>
        </label>
      </Grid>
      <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
        <label htmlFor="timeFormat" className={classes.label}>
          <Typography variant="body1" color="textSecondary">
            Time Format:
          </Typography>
          <TextField
            select
            id="settings.timeFormat"
            name="settings.timeFormat"
            value={values.settings.timeFormat}
            onChange={handleChange}
            className={classes.textField}
            variant="outlined"
            inputProps={{ 'aria-label': 'Time Format' }}
            fullWidth
            SelectProps={{
              native: isIOS,
            }}
          >
            {TIME_FORMAT.map((hoursFormat) => (
              <Option key={hoursFormat} value={hoursFormat}>
                {hoursFormat.replace('hour', ' hours')}
              </Option>
            ))}
          </TextField>
        </label>
      </Grid>
      <Grid container item xs={12} lg={12} alignItems="center" className={classes.fieldWrapper}>
        <Typography variant="caption" color="textPrimary" style={{ marginRight: 5 }}>
          Preview:
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {`${format(new Date(), values.settings.dateFormat)}`}
        </Typography>
      </Grid>
    </>
  );
};

export default CompanyDateFormatBlock;
