import { createAction } from '@reduxjs/toolkit';

const setLoading = createAction('permission/setLoading');
const setPermissions = createAction('permission/setPermissions');
const clearPermissions = createAction('permission/clearPermissions');

export default {
  setLoading,
  setPermissions,
  clearPermissions,
};
