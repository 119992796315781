import { getOptions } from 'helpers/getOptions';
import $api from 'http/index';
import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { setItem, removeItems } from 'helpers/localStorage';

export default () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate()

  return useCallback(async () => {
      try {
        const res = await $api.get(`${process.env.REACT_APP_BASE_URL}/kiosk/admin-login
        `, getOptions());
        setItem('x-admin-kiosk-token', res.data.token)

            removeItems([
      'authorization-token',
      'authorization-token-exp-at',
      'refresh-token',
      'refresh-token-exp-at',
      'x-auth-kiosk-token'
    ])
        localStorage.setItem('isKioskPage', '1');
        navigate('/kiosk')
      }catch(e){
        enqueueSnackbar(e.message, { variant: 'error' });
      }
  }, []);
};
