import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import Input from './../Input/Input';
import './../../styles.scss';
import axios from 'axios';

const emailValidation = (value) => {
  let error = '';
  const requiredFieldError = "This field can't be blank";
  const correctEmail = 'Please enter correct email address';

  if (!value.trim().includes('@') || !value.trim().includes('.')) {
    error = correctEmail;
  }
  if (!value.trim()) {
    error = requiredFieldError;
  }

  return error;
};

const ResetViaEmail = ({ onToggleRestoreMethod, selectedType, setSnackbar }) => {
  const navigate = useNavigate();

  const sendEmailLink = async () => {
    const rule = emailValidation(value);
    if (!rule) {
      try {
        const data = {
          email: value,
        };
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/reset-password/email`,
          data
        );
        setIsLinkSent(true);
      } catch (error) {
        setSnackbar({
          text: error.response.data.message,
          type: 'error',
        });
      }
    } else {
      setError(rule);
    }
  };
  const [value, setValue] = useState('');
  const [error, setError] = useState(false);
  const [isLinkSent, setIsLinkSent] = useState(false);

  return (
    <>
      {!isLinkSent && (
        <div className={'forgot-pass-form'}>
          <p className={'forgot-form-title'}>Forgot password</p>
          <p className={'forgot-form-info'}>
            Enter your email and we’ll send you a link to reset your password
          </p>
          <Input
            label={'Email'}
            value={value}
            setValue={setValue}
            error={error}
            setError={setError}
          />
          <button className={'forgot-btn'} onClick={sendEmailLink}>
            Send link
          </button>
          <button className={'change-type-link'} onClick={onToggleRestoreMethod}>
            <span className={'change-type-label'}>Or</span>
            {selectedType === 'phone'
              ? 'Send link for reset password on email'
              : 'Send code via phone number'}
          </button>
        </div>
      )}
      {isLinkSent && (
        <div className={'forgot-pass-form'}>
          <p className={'forgot-form-title'}>Email has been sent</p>
          <p className={'forgot-form-info'}>
            Plese check your inbox and follow the link to reset the password
          </p>
          <button
            className={'forgot-btn'}
            onClick={() => {
              navigate('/signin', {replace: true})
            }}
          >
            Log In
          </button>
          <button className={'change-type-link'} onClick={sendEmailLink}>
            <span className={'change-type-label'}>Didn’t recieve the link?</span>
            Resend
          </button>
        </div>
      )}
    </>
  );
};
export default ResetViaEmail;
