import React, { Component } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import NotesCards from '../NotesCards';
import SortingAndFilteringPanel from '../SortingAndFilteringPanel';
import EditNoteModal from '../EditNoteModal';
import WorklogNoteEditingForm from '../WorklogNoteEditingForm';
import FiltersBar from 'components/FiltersBar';
import PageLoader from '../../../../components/PageLoader';
import ConfirmDialog from '../../../../components/ConfirmDialog';
import styles from './styles';
import { drawerTypes } from '../../../Equipment/components/helpers/drawerTypes';
import $api from 'http/index';
import { getOptions } from 'helpers/getOptions';
import { connect } from 'react-redux';
import { startOfYear } from 'date-fns';
import ImagesForm from 'components/ImagesForm/ImagesForm';

export const defaultWorklogNotesFilters = {
  sortBy: 'date',
  sortOrder: 'asc',
  dates: [startOfYear(new Date()), new Date()],
  forceAccount: 'All',
}

class WorklogNotes extends Component {
  state = {
    visibleNotes: [],
    drawer: {
      type: drawerTypes.filters,
      isOpen: false,
    },
    isImagesDrawerOpen: false,
    currentSheetFromNotes: '',
    isDeleteModalOpen: false,
    isEditModalOpen: false,
    modalType: 'notes',
    commentInfo: {
      sheetId: '',
      commentId: '',
      text: '',
    },
    noteInfo: {
      sheetId: '',
      noteType: '',
      text: '',
      removeComments: false,
    },
    filter: defaultWorklogNotesFilters,
    note: {},
    isLoading: false,
    searchValue: '',
  };

  componentDidMount() {
    this.updateVisibleNotes();
  }

  setLoading = () => this.setState({ isLoading: true });

  updateSearchValue = (value) => this.setState({ searchValue: value }, this.updateVisibleNotes);

  updateFilter = (filter) => this.setState({ filter }, this.updateVisibleNotes);

  checker = () => {
    if (
      !Object.keys(this.state.note || {}).length &&
      this.state.drawer.type === 'update' &&
      this.state.drawer.isOpen
    ) {
      this.closeDrawer();
    }
  };

  updateVisibleNotes = async () => {
    this.setState({ isLoading: true });

    const {
      filter: { sortBy, sortOrder, dates, forceAccount },
      searchValue,
    } = this.state;

    const { projectId } = this.props;
    const isNoteOpened = this.state.note && this.state.note._id;
    const openedNoteId = isNoteOpened ? this.state.note._id : null;

    try {
      const queryParams = new URLSearchParams();

      if (searchValue) {
        queryParams.append('searchValue', searchValue);
      }
      queryParams.append('sortBy', sortBy);
      queryParams.append('sortOrder', sortOrder);
      queryParams.append('forceAccount', forceAccount);
      queryParams.append('projectId', projectId);
      queryParams.append('from', dates[0]);
      queryParams.append('to', dates[1]);

      const res = await $api.get(
        `${process.env.REACT_APP_BASE_URL}/sheets/notes?${queryParams}`,
        getOptions()
      );

      this.setState(
        (prevState) => ({
          visibleNotes: res.data,
          note: openedNoteId
            ? res.data.length
              ? res.data.filter((el) => el._id === openedNoteId)[0]
              : {}
            : prevState.note,
          drawer:
            prevState.drawer.type === drawerTypes.filters
              ? { ...prevState.drawer, isOpen: false }
              : prevState.drawer,
          isLoading: false,
        }),
        this.checker
      );
    } catch (e) {
      this.props.openSnackbar('error', e?.response?.data?.message || e.reason);
    }
  };

  openModal = (type, purpose) =>
    this.setState((prevState) => ({
      isDeleteModalOpen:
        purpose === 'delete' ? !prevState.isDeleteModalOpen : prevState.isDeleteModalOpen,
      isEditModalOpen: purpose === 'edit' ? !prevState.isEditModalOpen : prevState.isEditModalOpen,
      modalType: type ? type : prevState.modalType,
    }));

  closeModal = () =>
    this.setState({
      isDeleteModalOpen: false,
      isEditModalOpen: false,
    });

  handleComment = async (commentText) => {
    const {
      commentInfo: { sheetId, commentId, text },
    } = this.state;
    this.closeModal();

    const textToUpdate = typeof commentText === 'string' ? commentText : null;
    if (!textToUpdate) {
      try {
        await $api.delete(
          `${process.env.REACT_APP_BASE_URL}/sheets/${sheetId}/note-comments/${commentId}
    `,
          getOptions()
        );
        this.stateCommentChangeManagement(sheetId, commentId);
        this.props.openSnackbar('success', 'The Comment was deleted successfully');
        // this.updateVisibleNotes();
      } catch (e) {
        this.props.openSnackbar('error', e?.response?.data?.message || e.reason);
      }
      return;
    }

    try {
      await $api.put(
        `${process.env.REACT_APP_BASE_URL}/sheets/${sheetId}/note-comments/${commentId}
    `,
        { text: textToUpdate },
        getOptions()
      );
      // this.updateVisibleNotes();
      this.stateCommentChangeManagement(sheetId, commentId, textToUpdate);
      this.props.openSnackbar('success', 'Successfully Updated!');
    } catch (e) {
      this.props.openSnackbar('error', e?.response?.data?.message || e.reason);
    }
  };

  stateChangeManagement = (sheetId, textToUpdate = null, editableField) => {
    const newVisibleNotes = this.state.visibleNotes.map((el) => {
      if (el._id !== sheetId) return el;
      return {
        ...el,
        [editableField]: textToUpdate,
      };
    });

    const filteredVisibleNotes = newVisibleNotes.filter(
      (note) => note.notes || note.schedulerNotes
    );

    if (this.state.note._id === sheetId) {
      //* if note open in editModal

      const openedNote = filteredVisibleNotes.find((note) => note._id === this.state.note._id);

      if (!openedNote) {
        this.setState({ note: {} }, this.checker);
      }
      if (openedNote) {
        this.setState({ note: openedNote }, this.checker);
      }
    }

    this.setState({ visibleNotes: filteredVisibleNotes });
  };

  stateCommentChangeManagement = (sheetId, commentId, textToUpdate = null) => {
    const newVisibleNotes = this.state.visibleNotes.map((el) => {
      if (el._id !== sheetId) return el;

      if (textToUpdate) {
        return {
          ...el,
          noteComments: el.noteComments?.map((comment) => {
            if (comment._id !== commentId) return comment;

            return {
              ...comment,
              text: textToUpdate,
            };
          }),
        };
      }
      if (!textToUpdate) {
        return {
          ...el,
          noteComments: el.noteComments?.filter((comment) => comment._id !== commentId),
        };
      }
    });

    if (this.state.note._id === sheetId) {
      //* if note open in editModal

      const openedNote = newVisibleNotes.find((note) => note._id === this.state.note._id);

      this.setState((prevState) => ({
        note: { ...prevState.note, noteComments: openedNote.noteComments },
      }));
    }

    this.setState({ visibleNotes: newVisibleNotes });
  };

  addComment = (noteId, comment) => {
    const newVisibleNotes = this.state.visibleNotes.map((el) => {
      if (el._id !== noteId) return el;
      return {
        ...el,
        noteComments: [...el.noteComments, comment],
      };
    });
    this.setState({
      visibleNotes: newVisibleNotes,
    });

    if (this.state.note._id === noteId) {
      this.setState((prevState) => ({
        note: { ...prevState.note, noteComments: [...prevState.note.noteComments, comment] },
      }));
    }
  };

  handleCommentHelper = (sheetId, commentId, text) => () => {
    const purpose = text.length ? 'edit' : 'delete';
    this.openModal('comment', purpose);
    this.setState({ commentInfo: { sheetId, commentId, text } });
  };

  handleNote = async (noteText) => {
    const {
      noteInfo: { sheetId, noteType, text, removeComments },
    } = this.state;
    this.closeModal();

    const textToUpdate = typeof noteText === 'string' ? noteText : null;

    //*=============================schedulerNotes==================================
    if (noteType === 'schedulerNotes') {
      //* internal notes
      try {
        if (textToUpdate) {
          //*update internal notes
          await $api.put(
            `${process.env.REACT_APP_BASE_URL}/sheets/notes/${sheetId}/scheduler-notes`,
            { schedulerNotes: textToUpdate },
            getOptions()
          );

          this.props.openSnackbar('success', 'Successfully Updated!');
          this.stateChangeManagement(sheetId, textToUpdate, 'schedulerNotes');
        } else {
          //*del internal notes
          await $api.delete(
            `${process.env.REACT_APP_BASE_URL}/sheets/notes/${sheetId}/scheduler-notes`,
            getOptions()
          );

          this.stateChangeManagement(sheetId, textToUpdate, 'schedulerNotes');
          this.props.openSnackbar('success', 'Successfully Updated!');
        }
      } catch (e) {
        this.props.openSnackbar('error', e?.response?.data?.message || e.reason);
      }
    }
    //*=======================================notes========================================
    if (noteType === 'notes') {
      try {
        if (textToUpdate) {
          //*update notes
          await $api.put(
            `${process.env.REACT_APP_BASE_URL}/sheets/notes/${sheetId}/notes`,
            { notes: textToUpdate },
            getOptions()
          );

          this.props.openSnackbar('success', 'Successfully Updated!');
          this.stateChangeManagement(sheetId, textToUpdate, 'notes');
        } else {
          //*del notes
          await $api.delete(
            `${process.env.REACT_APP_BASE_URL}/sheets/notes/${sheetId}/notes`,
            getOptions()
          );

          this.props.openSnackbar('success', 'Successfully Updated!');
          this.stateChangeManagement(sheetId, textToUpdate, 'notes');
        }
      } catch (e) {
        this.props.openSnackbar('error', e?.response?.data?.message || e.reason);
      }
    }
    //*===============================================================
    // this.updateVisibleNotes();
  };

  handleNoteHelper = (sheetId, noteType, text, removeComments) => () => {
    const purpose = text.length ? 'edit' : 'delete';
    this.openModal(noteType, purpose);
    this.setState({ noteInfo: { sheetId, noteType, text, removeComments } });
  };

  openDrawer = (type, open, note) => () => {
    this.setState({ drawer: { type, isOpen: open }, note });
  };

  closeDrawer = () => {
    this.setState((prevState) => ({ drawer: { ...prevState.drawer, isOpen: false } }));
  };

  openImagesDrawer = (note) => {
    this.setState({ isImagesDrawerOpen: true, currentSheetFromNotes: note });
  };
  closeImagesDrawer = () => {
    this.setState({ isImagesDrawerOpen: false, currentSheetFromNotes: '' });
  };

  switchDeleteText = () => {
    switch (this.state.modalType) {
      case 'notes':
        return 'Are you sure you want to delete this Note? All the comments are going to be deleted as well. This action cannot be reversed.';
      case 'comment':
        return 'Are you sure you want to delete this comment? This action cannot be reversed.';
      default:
        return 'Are you sure you want to delete this Note? This action cannot be undone.';
    }
  };

  render() {
    const { classes } = this.props;
    const {
      visibleNotes,
      drawer,
      isImagesDrawerOpen,
      isDeleteModalOpen,
      isEditModalOpen,
      filter,
      isLoading,
      modalType,
      noteInfo,
      commentInfo,
      note,
      currentSheetFromNotes,
    } = this.state;

    return (
      <div className={classes.root}>
        <FiltersBar openDrawer={this.openDrawer} updateSearchValue={this.updateSearchValue} isDynamicSearch={true}/>
        <PageLoader loading={isLoading}>
          <NotesCards
            notes={visibleNotes}
            openDrawer={this.openDrawer}
            handleNote={this.handleNoteHelper}
            openImagesDrawer={this.openImagesDrawer}
            worklogNotes
          />
        </PageLoader>
        <Drawer
          anchor="right"
          open={drawer.isOpen}
          classes={{
            paper: classes.drawer,
          }}
        >
          {drawer.type !== drawerTypes.filters ? (
            visibleNotes.length ? (
              <WorklogNoteEditingForm
                closeDrawer={this.closeDrawer}
                openSnackbar={this.props.openSnackbar}
                handleNote={this.handleNoteHelper}
                handleComment={this.handleCommentHelper}
                note={note}
                addComment={this.addComment}
              />
            ) : null
          ) : (
            <SortingAndFilteringPanel
              closeDrawer={this.closeDrawer}
              filter={filter}
              updateFilter={this.updateFilter}
              isLoading={isLoading}
            />
          )}
        </Drawer>
        <Drawer
          anchor="left"
          open={isImagesDrawerOpen}
          className={classes.fullDr}
          classes={{
            paper: classes.drawer,
          }}
        >
          <ImagesForm
            closeDrawer={this.closeImagesDrawer}
            note={note}
            sheetId={currentSheetFromNotes?._id || ''}
          />
        </Drawer>
        <EditNoteModal
          isOpen={isEditModalOpen}
          onClose={this.closeModal}
          onSubmit={modalType !== 'comment' ? this.handleNote : this.handleComment}
          initialText={modalType !== 'comment' ? noteInfo.text : commentInfo.text}
          modalType={modalType}
        />
        <ConfirmDialog
          isOpen={isDeleteModalOpen}
          onClose={this.closeModal}
          onSubmit={modalType !== 'comment' ? this.handleNote : this.handleComment}
          text={this.switchDeleteText()}
        />
      </div>
    );
  }
}

WorklogNotes.propTypes = {
  notes: propTypes.array.isRequired,
  classes: propTypes.object.isRequired,
  openSnackbar: propTypes.func.isRequired,
  projectIds: propTypes.array.isRequired,
};

export default connect(
  (state) => ({
    projectId: state.notes.projectId,
  }),
  {
    // getNotesProjects,
  }
)(withStyles(styles)(WorklogNotes));
