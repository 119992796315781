import { getTimeZone } from 'helpers/getTimeZone';
import axios from 'axios';
import { getOptions,getKioskOptions } from 'helpers/getOptions';

const options = {
  headers: {
    'Content-Type': 'application/json',
    'X-Auth-Token': localStorage.getItem('authorization-token'),
    'X-User-TZ': getTimeZone(),
  },
};

export const apiCalls = {
  updateTimeOff: async (timeOffId, body) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_BASE_URL}/timeoffs/${timeOffId}`, {
        ...options,
        method: 'PUT',
        body: JSON.stringify(body),
      }).then((res) => res.json());
      return res;
    } catch (err) {
      console.log('> ', err);
    }
  },
  removeTimeOff: async (timeOffId) => {
    try {
      const res = await axios.delete(`${process.env.REACT_APP_BASE_URL}/kiosk/timeoffs/${timeOffId}`, 
      getKioskOptions())
      return res.data;
    } catch (err) {
      console.log('> ', err);
    }
  },
};
