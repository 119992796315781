import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import LoadingSpinner from '../../components/LoadingSpinner';
import AvailabilityPrintPage from './AvailabilityPrintPage';
import { getCurrentMonthDates, splitArrayToArraysOfRow } from 'helpers/_helpers';
import { useParams } from 'react-router-dom';
import {
  getPrintEquipmentEvents,
  getPrintTimeoffs,
  getPrintHolidays,
  getPrintAllUsers,
} from './apiCalls';
import availabilityPageActions from 'store/availability/availabilityPageActions';
import { useDispatch, useSelector } from 'react-redux';
import { parse, format } from 'date-fns';
import { allDatesFormat, globalBEDateFormat } from 'common/dateFormatConfig';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';

const AvailabilityPrintWrapper = ({ loading }) => {
  const dispatch = useDispatch();
  const param = useParams();
  const { dateFormat } = useDateSettingsFormat();
  const [monthEvents, setMonthEvents] = useState(null);
  const weekStartDay = useSelector(
    (state) => state.personalProfile.organization.settings.weekStart
  );

  useEffect(() => {
    const api = async () => {
      const [timeoffs, equipments, holidays, allUsers] = await Promise.all([
        getPrintTimeoffs(param.date),
        getPrintEquipmentEvents(param.date),
        getPrintHolidays(param.date),
        getPrintAllUsers(),
      ]);
      const events = [...timeoffs, ...equipments];

      const mappedEvents = getCurrentMonthDates(
        parse(param.date, allDatesFormat[1], new Date()),
        weekStartDay,
        globalBEDateFormat
      );

      mappedEvents.forEach((dateObj, i, arr) => {
        events.forEach((event) => {
          if ((event.inDays || []).some((el) => el === dateObj.dayNumber)) {
            arr[i].events.push(event);
          }
          if (event.start) {
            // const startDate = format(
            //   parse(event.start, dateFormat, new Date(event.start)),
            //   dateFormat
            // );
            if (event.start === dateObj.dayNumber) {
              arr[i].events.push(event);
            }
          }
        });
      });

      mappedEvents.forEach((dateObj, i, arr) => {
        holidays.forEach((holiday) => {
          if (holiday.inDays.some((el) => el === dateObj.dayNumber)) {
            if (holiday.isIndividual) {
              const users = allUsers.filter((user) => holiday.userIds.includes(user._id));
              if (users) holiday.users = users.map(({ _id, username }) => ({ _id, username }));
            }
            arr[i].holiday = holiday;
          }
        });
      });
      setMonthEvents(splitArrayToArraysOfRow(mappedEvents));
      dispatch(availabilityPageActions.setAllUsers(allUsers));
    };
    api();
  }, []);

  return loading ? (
    <div
      style={{
        display: 'flex',
        alignItem: 'center',
        justifyContent: 'center',
        marginTop: '100px',
      }}
    >
      <LoadingSpinner height={40} margin={32} />
    </div>
  ) : (
    <>
      {monthEvents ? (
        <AvailabilityPrintPage selectedDate={param.date} monthEvents={monthEvents} />
      ) : (
        ''
      )}
    </>
  );
};

AvailabilityPrintWrapper.propTypes = {
  loading: propTypes.bool.isRequired,
  monthEvents: propTypes.array.isRequired,
  selectedDate: propTypes.string.isRequired,
};

export default AvailabilityPrintWrapper;
