import { Typography, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { useSelector, useDispatch } from 'react-redux';
import { updateSelectedDate } from 'store/schedule/schedulePageOperation';
import { isSameDay } from 'date-fns';

const ScheduleMobileHeader = ({ classes }) => {
  const dispatch = useDispatch()
  const selectedDate = useSelector((state) => state.schedule.selectedDate);

  const isToday = () => {
    if (!selectedDate) return true;
    return isSameDay(new Date(), new Date(selectedDate));
  };

  const setToday = () => {
    dispatch(updateSelectedDate(new Date()));
  };

  return (
    <>
      <Grid container justifyContent="space-between" style={{ padding: '0 15px' }}>
        <Typography variant="h3">Schedule</Typography>
        <Button
          className={classes.todayButton}
          variant="contained"
          color="secondary"
          onClick={setToday}
          disabled={isToday()}
          disableTouchRipple
        >
          Today
        </Button>
      </Grid>
    </>
  );
};

export default withStyles(styles)(ScheduleMobileHeader);
