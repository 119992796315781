import React from 'react';
import { TableContainer, Table } from '@material-ui/core';
import TableHead from './components/Head/Head';
import TableBody from './components/Body/Body';
import Typography from '@material-ui/core/Typography';

import './styles.scss';

const CustomTable = ({
  headCells,
  rows,
  checkboxes,
  sortBy,
  sortOrder,
  onSort,
  isAllSelected,
  selected,
  onSelectAllClick,
}) => {
  return (
    <>
      <TableContainer className={'admin-panel-table-container'}>
        <Table aria-labelledby="tableTitle" size={'medium'} aria-label="enhanced table">
          <TableHead
            headCells={headCells}
            checkboxes={checkboxes}
            onSort={onSort}
            sortBy={sortBy}
            sortOrder={sortOrder}
            isAllSelected={isAllSelected}
            selected={selected}
            onSelectAllClick={onSelectAllClick}
          />
          {!!rows?.length && <TableBody checkboxes={checkboxes} rows={rows} />}
        </Table>
      </TableContainer>
      {!rows?.length && (
        <Typography variant="h6" align="center" style={{ margin: '1.72em 0 2em', opacity: 0.5 }}>
          No Results
        </Typography>
      )}
    </>
  );
};

export default CustomTable;
