import React from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SighInI from 'assets/images/sign-icon.svg';
import styles from './styles';

const SubmitDetails = ({
  classes,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  openSignatureModal,
  removeSignature,
  type,
}) => {
  return (
    <Grid container spacing={4}>
      {type === 'dot' ? (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4" color="textPrimary">
                DOT Inspector signature
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.zeroPaddingTop}>
              <label htmlFor="dotName">
                <Typography variant="body1" color="textSecondary">
                  Name:
                </Typography>
                <TextField
                  id="dotName"
                  name="dotName"
                  value={values.dotName}
                  error={Boolean(errors.dotName) && Boolean(touched.dotName)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                />
                {errors.dotName && touched.dotName ? (
                  <div className={classes.error}>{errors.dotName}</div>
                ) : null}
              </label>
            </Grid>
            <Grid item xs={12} className={classes.zeroPaddingTop}>
              <label htmlFor="dotEmail">
                <Typography variant="body1" color="textSecondary">
                  Email address:
                </Typography>
                <TextField
                  id="dotEmail"
                  name="dotEmail"
                  value={values.dotEmail}
                  error={Boolean(errors.dotEmail) && Boolean(touched.dotEmail)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                />
                {errors.dotEmail && touched.dotEmail ? (
                  <div className={classes.error}>{errors.dotEmail}</div>
                ) : null}
              </label>
            </Grid>
            <Grid item xs={12} className={classes.zeroPaddingTop}>
              {values.dotSignature ? (
                <>
                  <div className={classes.flexBox}>
                    <Typography variant="body1" color="textSecondary">
                      Signature:
                    </Typography>
                    <IconButton
                      className={classes.closeButton}
                      onClick={removeSignature('dotSignature', setFieldValue)}
                      disableRipple
                      disableTouchRipple
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <img src={values.dotSignature} className={classes.signImg} />
                </>
              ) : (
                <Button
                  variant="text"
                  className={classes.addButton}
                  onClick={openSignatureModal('dot')}
                  disableRipple
                  disableTouchRipple
                >
                  <img src={SighInI} alt="sign" className={classes.signIcon} />
                  Add signature
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4" color="textPrimary">
                Contractor signature
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.zeroPaddingTop}>
              <label htmlFor="contractorName">
                <Typography variant="body1" color="textSecondary">
                  Name:
                </Typography>
                <TextField
                  id="contractorName"
                  name="contractorName"
                  value={values.contractorName}
                  error={Boolean(errors.contractorName) && Boolean(touched.contractorName)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                />
                {errors.contractorName && touched.contractorName ? (
                  <div className={classes.error}>{errors.contractorName}</div>
                ) : null}
              </label>
            </Grid>
            <Grid item xs={12} className={classes.zeroPaddingTop}>
              <label htmlFor="contractorEmail">
                <Typography variant="body1" color="textSecondary">
                  Email address:
                </Typography>
                <TextField
                  id="contractorEmail"
                  name="contractorEmail"
                  value={values.contractorEmail}
                  error={Boolean(errors.contractorEmail) && Boolean(touched.contractorEmail)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                />
                {errors.contractorEmail && touched.contractorEmail ? (
                  <div className={classes.error}>{errors.contractorEmail}</div>
                ) : null}
              </label>
            </Grid>
            <Grid item xs={12} className={classes.zeroPaddingTop}>
              {values.contractorSignature ? (
                <>
                  <div className={classes.flexBox}>
                    <Typography variant="body1" color="textSecondary">
                      Signature:
                    </Typography>
                    <IconButton
                      className={classes.closeButton}
                      onClick={removeSignature('contractorSignature', setFieldValue)}
                      disableRipple
                      disableTouchRipple
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <img src={values.contractorSignature} className={classes.signImg} />
                </>
              ) : (
                <Button
                  variant="text"
                  className={classes.addButton}
                  onClick={openSignatureModal('contractor')}
                  disableRipple
                  disableTouchRipple
                >
                  <img src={SighInI} alt="sign" className={classes.signIcon} />
                  Add signature
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

SubmitDetails.propTypes = {
  classes: propTypes.object.isRequired,
  values: propTypes.object.isRequired,
  errors: propTypes.object.isRequired,
  touched: propTypes.object.isRequired,
  handleChange: propTypes.func.isRequired,
  handleBlur: propTypes.func.isRequired,
  setFieldValue: propTypes.func.isRequired,
  openSignatureModal: propTypes.func.isRequired,
  removeSignature: propTypes.func.isRequired,
  type: propTypes.string.isRequired,
};

export default withStyles(styles)(SubmitDetails);
