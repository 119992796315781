export default (theme) => ({
  settingsDetailsBlock: {
    paddingRight: '0 !important',
  },
  secondaryText: {
    color: theme.palette.textColor.secondary,
    fontSize: '0.8rem',
    fontWeight: 400,
    marginRight: 10,
  },
  inlineBlock: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  regularFontWeight: {
    fontWeight: 300,
  },
  paddingBottom: {
    paddingBottom: 16,
  },
  paddingTop: {
    paddingTop: 16,
  },
  zeroPaddingTop: {
    paddingTop: '0 !important',
  },
  shiftImage: {
    height: 16,
    margin: '0 5px 2px 0',
  },
  primaryTextButton: {
    padding: 0,
    color: theme.palette.textColor.primary,
    '&:hover': {
      color: theme.palette.textColor.primary,
      background: `${theme.palette.background.default} !important`,
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
      background: `${theme.palette.background.default} !important`,
    },
  },
  textField: {
    maxWidth: 250,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 200,
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '100%',
    },
  },
  fieldValue: {
    marginLeft: '10px',
  },
  tripleSelectWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: 215,
    '& > div': {
      maxWidth: 65,
    },
  },

  settingsWrapper: {
    display: 'flex',
    width: '100vw',
    columnGap: '75px',
    [theme.breakpoints.down('md')]: {
      columnGap: '15px',
    },
  },
  settingsTabs: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '18px',
    width: 200,
    marginLeft: '20px',
    [theme.breakpoints.down('md')]: {
      width: '35px',
      overflow: 'hidden',
    },

    '& .MuiTab-root': {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '0px 0 0px 8px',
      height: '35px',
      marginBottom: '10px',
    },
    '& .MuiTab-root:focus': {
    },
    '& .MuiTab-root:hover': {
      backgroundColor: '#6588e817 !important',
      borderRadius: 5,
      '& .superAdminTabIcon': {
        filter:
          'invert(42%) sepia(93%) saturate(1352%) hue-rotate(212deg) brightness(119%) contrast(119%)',
      },
    },

    '& .MuiTab-wrapper': {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      // width: 140,
    },

    '& .superAdminTabIcon': {
      width: 20,
      marginBottom: '0 !important',
      marginRight: 14,
    },

    '& .MuiTab-labelIcon': {
      minHeight: 'auto !important',
    },

    '& .Mui-selected': {
      backgroundColor: '#6588e817',
      // border: '2px solid #6588e8',
      borderRadius: 5,
      // paddingLeft: 18,
      color: '#6588e8',
      '& img': {
        filter:
          'invert(42%) sepia(93%) saturate(1352%) hue-rotate(212deg) brightness(119%) contrast(119%)',
      },
    },

    '& .MuiTabs-indicator': {
      display: 'none',
    },

    '& .MuiTab-textColorInherit': {
      opacity: 1,
    },
  },

  superAdminInfoBlock: {
    marginTop: 30,
    width: '100%',
    // paddingRight: 30,
    maxWidth: 1500,
  },
  settingsTabsInner: {
    marginTop: 30,
    width: '200px',
    [theme.breakpoints.down('md')]: {
      width: 'auto',
    },
  },
  scaleDownI: {
    scale: 0.85
  },
  scaleUpI:{
    scale: 1.2
  }
});
