export const surfaces = [
  'Milled',
  'Scratch',
  'Binder',
  'Final',
  'Slurry',
  'Microsurface',
  'Diamond Grind',
  'Concrete',
  'Old Top',
  'Michigan Joint'
];
