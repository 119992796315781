import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import classNames from 'classnames';
import { GLOBAL_DATE_FORMAT } from 'common/dateFormatConfig';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.textColor.secondary,
  },
  body: {
    fontSize: 14,
  },
  root: {
    borderBottom: 0,
    paddingTop: 8,
    paddingBottom: 8,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.background.lightBlue,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: 5,
    overflowX: 'auto',
    border: 0,
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
    },
  },
  table: {
    minWidth: 280,
    border: 0,
  },
  tableHeader: {
    '& th': {
      fontSize: 14,
      fontWeight: 400,
      color: theme.palette.textColor.grey,
      fontFamily: 'Open Sans',
    },
  },
  badge: {
    height: '25px',
    width: '25px',
    display: 'inline-block',
    color: '#5A87EF',
    textAlign: 'center',
    verticalAlign: 'middle',
    borderRadius: '50%',
    border: '1.5px solid #5A87EF !important',
    padding: '3px',
    boxSizing: 'border-box',
  },
  red: {
    borderColor: `${theme.palette.error.red} !important`,
    color: theme.palette.error.red,
    margin: '0 5px',
    boxSizing: 'border-box',
  },
  countCell: {
    paddingLeft: 0,
    paddingRight: 0,
    width: '60px !important',
    textAlign: 'center',
  },
  projectIdColumn: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  hideForSmall: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  showForSmall: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'table-cell',
    },
  },
  lastActivityForSmall: {
    fontSize: 12,
    fontWeight: 600,
    marginBottom: 0,
    paddingTop: 5,
  },
  projectCell: {
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      fontWeight: 600,
    },
  },
  grey: {
    fontWeight: 400,
    color: theme.palette.textColor.secondary,
  },
  loader: {
    fontSize: 14,
    paddingBottom: 10,
  },
}));

export default function NotesList(props) {
  const classes = useStyles();
  const formatCreationDate = (lastActivity) => (lastActivity ? format(new Date(lastActivity), GLOBAL_DATE_FORMAT.fullDate) : null);

  return props.projects.length ? (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead className={classNames(classes.tableHeader, classes.hideForSmall)}>
          <TableRow>
            <StyledTableCell />
            <StyledTableCell>Project</StyledTableCell>
            <StyledTableCell>Contractor</StyledTableCell>
            <StyledTableCell className={classes.projectIdColumn}>ID</StyledTableCell>
            <StyledTableCell>Last activity</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.projects.map((project) => (
            <StyledTableRow key={project._id}>
              <StyledTableCell className={classNames(classes.countCell, classes.hideForSmall)}>
                <span className={classNames(project.highPriority && classes.red, classes.badge)}>
                  {project.notesCount || '0'}
                </span>
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                onClick={props.openProjectNotes(project._id)}
                className={classes.projectCell}
              >
                {project.route + (project.section ? ', Section ' + project.section : '')}
                {project.lastActivity && (
                  <p className={classNames(classes.lastActivityForSmall, classes.showForSmall)}>
                    <span className={classes.grey}>Last activity: </span>
                    {formatCreationDate(project.lastActivity)}
                  </p>
                )}
              </StyledTableCell>
              <StyledTableCell className={classNames(classes.countCell, classes.showForSmall)}>
                <span className={classNames(project.highPriority && classes.red, classes.badge)}>
                  {project.notesCount || '0'}
                </span>
              </StyledTableCell>
              <StyledTableCell className={classNames(classes.contractorColumn, classes.hideForSmall)}>
                {project?.contractor?.name}
              </StyledTableCell>
              <StyledTableCell className={classNames(classes.projectIdColumn, classes.hideForSmall)}>
                {project.id}
              </StyledTableCell>
              <StyledTableCell className={classes.hideForSmall}>
                {formatCreationDate(project.lastActivity)}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  ) : null;
}
