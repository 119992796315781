import React from 'react';
import cs from 'classnames';
import CIPProjectItem from './components/CIPProjectItem';
import '../../../../../../styles/base/definition-list.scss';
import { Title } from '../styled';
import { Box } from '@material-ui/core';
import { styles } from './styled';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(styles);

const CIPProjects = ({
  data,
  userId,
  selected,
  onItemClick,
  editMode,
  onChange,
  onDelete,
  currentRoute,
  ...props
}) => {
  const classes = useStyles();

  return data.length ? (
    <div className={classes.cipWrapper}>
      <p className={classes.cipTitleMobileBlock}>CIP projects</p>
      <Box {...props} className={classes.root}>
        <div className={classes.cipContainer}>
          <Title className={classes.cipTitleBlock}>CIP projects</Title>
          {data.map((el) => (
            <CIPProjectItem
              currentRoute={currentRoute}
              editMode={editMode}
              onChange={onChange}
              onDelete={onDelete}
              key={el}
              data={el}
              userId={userId}
              onClick={() => onItemClick(el)}
              className={cs({
                'timeclock-day-report__selected':
                  selected && selected.timeclockId === el.timeclockId,
              })}
            />
          ))}
        </div>
      </Box>
    </div>
  ) : null;
};

export default CIPProjects;
