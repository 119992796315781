const typography = {
  fontFamily: [
    'Poppins',
    'Open Sans',
    'sans-serif',
  ].join(','),
  // fontWeightMedium: 500,
  caption: {
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: 1.2,
  },
  body1: {
    fontSize: '0.8rem',
    fontWeight: 400,
    lineHeight: 1.2,
  },
  body2: {
    fontSize: '0.7rem',
    fontWeight: 400,
    lineHeight: 1.1,
  },
  body3: {
    fontSize: '14px',
    fontWeight: 500,
  },
  subtitle1: {
    fontSize: 12,
  },
  subtitle2: {
    fontSize: '0.6rem',
    fontWeight: 400,
    lineHeight: 1.1,
  },
  button: {
    // fontStyle: 'italic',
  },
  h2: {
    fontSize: '2rem',
    fontWeight: 500,
    lineHeight: 1.3,
  },
  h3: {
    // fontSize: '1.75rem',
    fontSize: '1.5rem',
    fontWeight: 500,
    lineHeight: 1.4,
    letterSpacing: 0.3,
    color: '#202020'
  },
  h4: {
    fontSize: '1.25rem',
    fontWeight: 500,
    lineHeight: 1.4,
    letterSpacing: 0.3,
    color: '#202020'
  },
  h5: {
    fontSize: '1.1rem',
  },
  h6: {
    fontSize: '0.95rem',
  }
};

export default typography;
