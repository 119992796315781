import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';

import { Button, CloseButton, Dialog, DialogActions } from '../base';
import { DialogTitle, EmployeeName } from '../EditTimeclockModal/styled';
import useStyles from '../EditTimeclockModal/useStyles';
import { isIOS, Option } from 'components/Option';
import { useTimeStates } from 'hooks/useTimeStates';

const EditLunchTimeModal = ({ onChange, onReset, onSubmit, data, onClose, open }) => {
  const classes = useStyles();
  const { hours, minutes } = useTimeStates({
    hourMaxValue: 13,
    hourStart: 0,
    takeSettingsHours: true,
    overlapsHourMaxValue: true,
  });
  const [lunchTime, setLunchTime] = useState({ hour: undefined, minute: undefined });

  useEffect(() => {
    if (!data) return;
    let dataLunchTime = data.lunchTime;
    if (typeof lunchTime.hour === 'undefined' && typeof lunchTime.minute === 'undefined') {
      dataLunchTime = data.modified?.lunchTime || data.lunchTime;
    }
    const hour = Math.trunc(dataLunchTime) || 0;
    const minute = (dataLunchTime - hour) * 60 || 0;
    setLunchTime({ hour, minute });
  }, [data]);

  const handleHourChange = (hour) => {
    setLunchTime((prev) => ({ ...prev, hour }));
    onChange({
      ...data,
      lunchTime: hour + (lunchTime.minute ? lunchTime.minute / 60 : 0),
    });
  };

  const handleMinuteChange = (minute) => {
    setLunchTime((prev) => ({ ...prev, minute }));
    onChange({
      ...data,
      lunchTime: lunchTime.hour + (minute ? minute / 60 : 0),
    });
  };

  return (
    <Dialog open={open}>
      <CloseButton onClick={onClose} />
      <DialogTitle className={classes.title} disableTypography={true}>
        Timeclock edit mode
      </DialogTitle>
      {data ? (
        <>
          <EmployeeName>{data.user.profile.fullName}</EmployeeName>
          <form className={classes.root}>
            <div className={classes.form}>
              <label className={classes.field}>
                Lunch Time:
                <div className={classes.inlineFlex} style={{ paddingRight: '39px' }}>
                  <TextField
                    select
                    className={classes.textField}
                    variant="outlined"
                    onChange={(e) => handleHourChange(e.target.value)}
                    value={lunchTime.hour}
                    fullWidth
                    SelectProps={{
                      native: isIOS,
                    }}
                  >
                    {hours.map((value, index) => (
                      <Option key={index} value={+value}>
                        {value}
                      </Option>
                    ))}
                  </TextField>
                  <span className={classes.delimiter}>:</span>
                  <TextField
                    select
                    className={classes.textField}
                    variant="outlined"
                    onChange={(e) => handleMinuteChange(e.target.value)}
                    value={lunchTime.minute}
                    fullWidth
                    SelectProps={{
                      native: isIOS,
                    }}
                  >
                    {minutes.map((value, index) => (
                      <Option key={index} value={+value}>
                        {value}
                      </Option>
                    ))}
                  </TextField>
                </div>
              </label>
            </div>
          </form>
        </>
      ) : null}
      <DialogActions disableSpacing={false}>
        <Button variant={'outlined'} color={'secondary'} fullWidth={true} onClick={onReset}>
          Cancel
        </Button>
        <Button variant={'outlined'} fullWidth={true} onClick={onSubmit}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditLunchTimeModal;
