import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core';
import styles from './styles';
import SignInForm from './components/SignInForm';
import CircularProgress from '@material-ui/core/CircularProgress';
import SignInPhoneForm from './components/SignInPhoneForm';
import Logo from './../../assets/images/Logo.png';
import { loginWithPassword, loginWithCode } from 'store/login/loginOperations';
import { useDispatch } from 'react-redux';
import axios from 'axios'
import { useRedirectChecker } from 'hooks/useRedirectChecker';

const SignIn = ({ classes }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { redirectChecker } = useRedirectChecker()

  // const onSuccessfulSignIn = useCallback(async () => {
  //   navigate('/');
  // }, []);

  const [signInMethod, setSignInMethod] = useState('phone');
  const [loading, setLoading] = useState(false);
  const [codeSent, setCodeSent] = useState(false);
  const [error, setError] = useState();
  const [credentials, setCredentials] = useState();

  const onSignIn = useCallback(async ({ username, password }) => {
    setCredentials({ username, password });
    setLoading(true);
    setError();
    try {
      await dispatch(loginWithPassword(username, password, navigate));

    } catch (e) {
      console.log(e);
      setLoading(false);
      setError({reason: e.response.data.message});
    }
  }, []);

  const onPhoneSignIn = useCallback(
    async (credentials) => {
      setCredentials(credentials);
      setError();
      if (codeSent) {
        setLoading(true);
        try {
          await dispatch(loginWithCode(credentials.secureCode, navigate));

        } catch (e) {
          setLoading(false);
          setError({reason: e.response.data.message});
        }
      } else {
        setLoading(true);
        try {
          const data = {phoneNumber: credentials.phone};
          await axios.post(
            `${process.env.REACT_APP_BASE_URL}/login/phone`,
            data
          );
          setCodeSent(true);
        } catch (e) {
          setLoading(false);
          console.error(e);
          setError({ error: e.response.data.message});
        }
        setLoading(false);
      }
    },
    [codeSent]
  );

  const onToggleSignInMethod = useCallback(() => {
    setSignInMethod((prevMethod) => (prevMethod === 'username' ? 'phone' : 'username'));
  }, []);

  useEffect(() => {
    redirectChecker()
  }, []);

  return (
    <div className={classes.background}>
      <Grid
        className={classes.root}
        container
        direction={'column'}
        alignItems={'center'}
        wrap={'nowrap'}
      >
        <Grid className={classes.logo}>
          <img src={Logo} alt="logo" className={classes.logoImg} />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          container
          direction={'column'}
          justifyContent={'flex-end'}
          className={classes.titleWrapper}
        >
          <Grid item xs={'auto'}>
            <h1 className={classes.title}>
              Welcome to Motivity<span style={{ color: '#f58426' }}>360</span>
            </h1>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={5} container direction={'column'} justifyContent={'center'}>
          <Grid item xs={'auto'}>
            {loading ? (
              <div className={classes.loaderContainer}>
                <CircularProgress size={64} className={classes.loader} />
              </div>
            ) : signInMethod === 'username' ? (
              <SignInForm
                className={classes.form}
                error={error}
                value={credentials}
                onSubmit={onSignIn}
                setError={setError}
              />
            ) : (
              <SignInPhoneForm
                className={classes.form}
                codeSent={codeSent}
                value={credentials}
                error={error}
                onSubmit={onPhoneSignIn}
                setError={setError}
              />
            )}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          container
          direction={'column'}
          justifyContent={'flex-start'}
          alignItems={'center'}
        >
          <Grid item xs={'auto'}>
            <div className={classes.footer}>
              <button className={classes.link} onClick={onToggleSignInMethod}>
                <span className={classes.label}>Or</span>
                {signInMethod === 'username'
                  ? 'Sign In with Phone Number'
                  : 'Sign In with User Name And Password'}
              </button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(SignIn);
