import React from 'react';
import propTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import PageLoader from '../../../../components/PageLoader';
import DatePickerRange from '../../../../components/DatePickerRange';
import styles from './styles';
import { isIOS, Option } from '../../../../components/Option';
import { defaultWorklogNotesFilters } from '../WorklogNotes/WorklogNotes';

  const defaultProjectNotesFilters = {
    sortBy: 'date',
    sortOrder: 'asc',
  }

const SortingAndFilteringPanel = ({
  classes,
  closeDrawer,
  filter,
  updateFilter,
  isLoading,
  projectNotesPage,
}) => {
  const sortingTypeLabel = (sortBy) => {
    switch (sortBy) {
      case 'date':
        return ['Newest \u2013 Oldest', 'Oldest \u2013 Newest'];
      case 'projectId':
        return ['0 \u2013 9', '9 \u2013 0'];
      default:
        return ['A \u2013 Z', 'Z \u2013 A'];
    }
  };

  return (
    <div className={classes.formWrapper} role="presentation">
      <Formik
        initialValues={filter}
        enableReinitialize={true}
        onSubmit={(values) => {
          updateFilter(values);
        }}
      >
        {({ values, handleChange, setFieldValue, resetForm }) => (
          <PageLoader loading={isLoading}>
            <Form autoComplete="off" className={classes.formFlex}>
              <div>
                <Typography variant="h3" className={classes.marginHeader}>
                  Filters
                </Typography>

                <IconButton
                  className={classes.closeButton}
                  onClick={closeDrawer}
                  aria-label="Close"
                  disableTouchRipple
                >
                  <CloseIcon />
                </IconButton>

                <Grid container spacing={2} className={classes.flexContainer}>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="sortBy" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Sort by:
                      </Typography>
                      <TextField
                        select
                        id="sortBy"
                        name="sortBy"
                        value={values.sortBy}
                        onChange={handleChange}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Sort by' }}
                        fullWidth
                        SelectProps={{
                          native: isIOS,
                        }}
                      >
                        {projectNotesPage
                          ? [
                              <Option key={1} value="date">
                                Most recent
                              </Option>,
                              <Option key={2} value="username">
                                Username
                              </Option>,
                            ]
                          : [
                              <Option key={1} value="date">
                                Most recent
                              </Option>,
                            ]}
                      </TextField>
                    </label>
                  </Grid>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="sortOrder" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Sort from:
                      </Typography>
                      <TextField
                        select
                        id="sortOrder"
                        name="sortOrder"
                        value={values.sortOrder}
                        onChange={handleChange}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Days' }}
                        fullWidth
                        SelectProps={{
                          native: isIOS,
                        }}
                      >
                        <Option key={1} value="asc">
                          {sortingTypeLabel(values.sortBy)[0]}
                        </Option>
                        <Option key={2} value="desc">
                          {sortingTypeLabel(values.sortBy)[1]}
                        </Option>
                      </TextField>
                    </label>
                  </Grid>
                </Grid>
                {!projectNotesPage ? (
                  <>
                    <Divider className={classes.marginVertical} />
                    <Grid
                      container
                      spacing={2}
                      className={classes.flexContainer}
                    >
                      <Grid item xs={12} lg={6}>
                        <label htmlFor="dates" className={classes.label}>
                          <Typography variant="body1" color="textSecondary">
                            Dates:
                          </Typography>
                          <DatePickerRange
                            dates={values.dates}
                            setFieldValue={setFieldValue}
                          />
                        </label>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        lg={6}
                        className={classes.fieldWrapper}
                      >
                        <label htmlFor="forceAccount" className={classes.label}>
                          <Typography variant="body1" color="textSecondary">
                            Time & Material:
                          </Typography>
                          <TextField
                            select
                            id="forceAccount"
                            name="forceAccount"
                            value={values.forceAccount}
                            onChange={handleChange}
                            className={classes.textField}
                            variant="outlined"
                            inputProps={{ 'aria-label': 'Shift' }}
                            fullWidth
                            SelectProps={{
                              native: isIOS,
                            }}
                          >
                            <Option key={1} value="All">
                              All
                            </Option>
                            <Option key={2} value="Full">
                              Full
                            </Option>
                            <Option key={3} value="Partial">
                              Partial
                            </Option>
                            <Option key={4} value="No">
                              No
                            </Option>
                          </TextField>
                        </label>
                      </Grid>
                    </Grid>
                  </>
                ) : null}
                <Grid item xs={12}>
                  <Button
                    color="primary"
                    className={classes.resetButton}
                    onClick={() => {
                      resetForm({
                        values: projectNotesPage
                          ? defaultProjectNotesFilters
                          : defaultWorklogNotesFilters,
                      });
                    }}
                    disableTouchRipple
                  >
                    Reset all filters
                  </Button>
                </Grid>
              </div>

              <div className={classes.buttonHolder}>
                <Button
                  color="secondary"
                  className={classes.cancelButton}
                  onClick={closeDrawer}
                >
                  Cancel
                </Button>

                <div className={classes.verticalDivider} />

                <Button
                  type="submit"
                  color="primary"
                  className={classes.saveButton}
                >
                  Apply
                </Button>
              </div>
            </Form>
          </PageLoader>
        )}
      </Formik>
    </div>
  );
};

SortingAndFilteringPanel.propTypes = {
  classes: propTypes.object.isRequired,
  closeDrawer: propTypes.func.isRequired,
  filter: propTypes.object.isRequired,
  updateFilter: propTypes.func.isRequired,
  isLoading: propTypes.bool.isRequired,
  projectNotesPage: propTypes.bool,
};

export default withStyles(styles)(SortingAndFilteringPanel);
