import React, { Component } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import { format, parse } from 'date-fns';

import styles from './slyles';
import { hasPermissionsFor } from 'helpers/_helpers';
import { reasons } from 'components/Kiosk/components/AddTimeOff/helpers/helpers';
import CloseIconGrey from 'assets/images/close-icon-grey.svg';
import EditIconGrey from 'assets/images/edit-icon-grey.svg';
import { useSelector } from 'react-redux';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';
import { convertTime, globalBEDateFormat } from 'common/dateFormatConfig';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';

export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.textColor.secondary,
  },
  body: {
    fontSize: 14,
  },
  root: {
    borderBottom: 0,
    // paddingTop: 8,
    // paddingBottom: 8
    padding: '8px 16px',
    [theme.breakpoints.down('lg')]: {
      padding: '8px 8px',
    },
  },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
    '&:nth-of-type(even)': {
      backgroundColor: '#DDE7FE',
    },
  },
}))(TableRow);

const TimeoffList = ({timeoffs, classes, type, openModal, openDrawer, }) => {
  const { dateFormat } = useDateSettingsFormat();
  const getTimeFormatHook = useTimeSettingsFormat();

  const getUsername = (user) => {
    return user.username;
  };

  const formatDate = (date) => format(parse(date, globalBEDateFormat, new Date()) , dateFormat);
  const formatTime = (time) => convertTime(time, getTimeFormatHook().is12Format);


  const hasAccess = () =>
    hasPermissionsFor('editPTO') ||
    hasPermissionsFor('availabilityPeopleEdit') ||
    hasPermissionsFor('availabilityFullAccess');

    const calcHeader = () => {
    switch (type) {
      case 'Requested':
        return 'Requested Times Off';
      case 'Approved':
        return 'Approved Times Off';
      default:
        return 'Denied Times Off';
    }
  };

    return (
      <>
        {!!timeoffs?.length ? (
          <div>
            <Typography variant="h4">{calcHeader()}</Typography>
            <Paper className={classes.root}>
              <Table className={classes.table} style={{ tableLayout: 'fixed' }}>
                <TableHead className={classNames(classes.tableHeader)}>
                  <TableRow>
                    <StyledTableCell colSpan={3} className={classes.usernameCell}>
                      Username
                    </StyledTableCell>
                    <StyledTableCell colSpan={2} className={classes.datesCell}>
                      Start date
                    </StyledTableCell>
                    <StyledTableCell colSpan={2} className={classes.datesCell}>
                      End date
                    </StyledTableCell>
                    <StyledTableCell colSpan={2} className={classes.timeCell}>
                      Start time
                    </StyledTableCell>
                    <StyledTableCell colSpan={2} className={classes.timeCell}>
                      End time
                    </StyledTableCell>
                    <StyledTableCell colSpan={1} className={classes.hoursCell}>
                      Hours
                    </StyledTableCell>
                    <StyledTableCell colSpan={3} className={classes.typeCell}>
                      Type
                    </StyledTableCell>
                    <StyledTableCell colSpan={1} className={classes.ptoCell}>
                      PTO
                    </StyledTableCell>
                    <StyledTableCell colSpan={4}>Notes</StyledTableCell>
                    <StyledTableCell colSpan={4}>Actions</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {timeoffs?.map((timeoff) => (
                    <StyledTableRow key={timeoff._id}>
                      <StyledTableCell colSpan={3} className={classes.usernameCell}>
                        {getUsername(timeoff.user)}
                      </StyledTableCell>
                      <StyledTableCell colSpan={2} className={classes.datesCell}>
                        {formatDate(timeoff.startDate)}
                      </StyledTableCell>
                      <StyledTableCell colSpan={2} className={classes.datesCell}>
                        {formatDate(timeoff.endDate)}
                      </StyledTableCell>
                      <StyledTableCell colSpan={2} className={classes.timeCell}>
                        {timeoff.startTime && formatTime(timeoff.startTime)}
                      </StyledTableCell>
                      <StyledTableCell colSpan={2} className={classes.timeCell}>
                        {timeoff.endTime && formatTime(timeoff.endTime)}
                      </StyledTableCell>
                      <StyledTableCell colSpan={1} className={classes.hoursCell}>
                        {timeoff.timeoffDuration}
                      </StyledTableCell>
                      <StyledTableCell colSpan={3} className={classes.typeCell}>
                        {timeoff.reason}
                      </StyledTableCell>
                      <StyledTableCell colSpan={1} className={classes.ptoCell}>
                        {timeoff.isPTO ? 'Yes' : 'No'}
                      </StyledTableCell>
                      <StyledTableCell colSpan={4} className={classes.notesCell}>
                        {timeoff.notes}{' '}
                        {timeoff.reason === reasons[2] ? ` (${timeoff.relationship})` : ''}
                      </StyledTableCell>
                      <StyledTableCell colSpan={4}>
                        <div className={classes.flexBox}>
                          {hasAccess() && (
                            <Button
                              color="secondary"
                              className={classes.actionBtn}
                              onClick={openDrawer('updateTimeoff', timeoff)}
                              disableRipple
                            >
                              <img src={EditIconGrey} alt="edit" />
                            </Button>
                          )}
                          {type === 'Requested' && (
                            <>
                              <Button
                                color="secondary"
                                className={classNames(classes.actionBtn, classes.approveBtn)}
                                onClick={openModal('confirm', timeoff._id)}
                                disableRipple
                              >
                                Approve
                              </Button>
                              <Button
                                color="secondary"
                                className={classNames(classes.actionBtn, classes.declineBtn)}
                                onClick={openModal('reject', timeoff._id)}
                                disableRipple
                              >
                                Decline
                              </Button>
                            </>
                          )}
                          <Button
                            color="secondary"
                            className={classes.actionBtn}
                            onClick={openModal('delete', timeoff._id)}
                            disableRipple
                          >
                            <img src={CloseIconGrey} alt="delete" />
                          </Button>
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </div>
        ) : 
        (
          <Typography
            variant="h3"
            align="center"
            style={{ margin: '0.8em 0 2em', opacity: 0.5 }}
          >
            No Results
          </Typography>
        )}
      </>
    );
}

TimeoffList.propTypes = {
  classes: propTypes.object.isRequired,
  timeoffs: propTypes.array.isRequired,
  type: propTypes.string.isRequired,
  openModal: propTypes.func.isRequired,
  openDrawer: propTypes.func.isRequired,
};

export default withStyles(styles)(TimeoffList);
