import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchClients, fetchStatistic } from 'store/superadmin/superadminOperations';
import { statisticBlocksMapping } from './constants';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MonthPicker from './components/MonthPicker/MonthPicker';
import StatisticBlock from './components/StatisticCard/StatisticBlock';
import { isIOS, Option } from 'components/Option';

import './styles.scss';

const Home = () => {
  const [selectedClient, setSelectedClient] = useState(null);
  const [month, setMonth] = useState(new Date());
  const clients = useSelector((state) => state.superadmin.clients);
  const statistic = useSelector((state) => state.superadmin.statistic);
  const statisticArray = useMemo(() => {
    return statisticBlocksMapping.map(({ name, field, active }) => ({
      text: name,
      quantity: +(statistic?.[field]?.curr?.toFixed(2)) || (active ? 0 : 'N/A'),
      diff: +((statistic?.[field]?.curr || 0) - (statistic?.[field]?.prev || 0)).toFixed(2),
      active: active,
    }))
  }, [statistic])

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchClients());
  }, []);

  useEffect(() => {
    dispatch(fetchStatistic(month, selectedClient));
  }, [month, selectedClient])


  const handleCompanyChange = (e) => setSelectedClient(e.target.value);

  return (
    <>
      <div>
        <Typography variant="h3" style={{marginBottom: '20px'}}>
          Statistics
        </Typography>
        <div className={'statistic-month-picker-wrapper'}>
          <MonthPicker onChange={setMonth} />

          <label htmlFor="companies" style={{width: '255px'}}>
            <TextField
              select
              id="companies"
              name="companies"
              variant="outlined"
              inputProps={{ 'aria-label': 'companies' }}
              defaultValue='All'
              onChange={handleCompanyChange}
              fullWidth
              SelectProps={{
                native: isIOS,
              }}
            >
              <Option key={'default'} value="All">
                All Companies
              </Option>
              {clients.map((el) => {
                return (
                  <Option key={el._id} value={el._id}>
                    {el.name}
                  </Option>
                )
              })}
            </TextField>
          </label>
        </div>
      </div>
      <div className={'statistic-card-home-wrapper'}>
        {statisticArray.map((el, index) => (
          <StatisticBlock key={index} quantity={el.quantity} text={el.text} diff={el.diff} active={el.active} />
        ))}
      </div>
    </>
  );
};

export default Home;
