export default (theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
    '@media (max-width: 1050px)': {
      flexWrap: 'wrap',
      '& > *': {
        marginBottom: 16,
      },
    },
  },
  subtitle: {
    color: theme.palette.textColor.secondary,
    fontSize: '0.75rem',
    display: 'block',
    textTransform: 'capitalize',
  },
});
