import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import styles from './styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { RadioGroup, FormControlLabel } from '@material-ui/core';
import CustomRadioButton from 'components/CustomRadioButton/CustomRadioButton';

const OptionalModal = ({ classes, isOpen, onSave, onClose, onCancel, options }) => {
  const [value, setValue] = useState('false');

  const cancel = () => {
    onCancel(false);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose ? onClose : cancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        classes: {
          root: classes.dialogRoot,
        },
      }}
    >
      <IconButton
        className={classes.closeButton}
        onClick={onClose ? onClose : cancel}
        aria-label="Close"
        disableTouchRipple
      >
        <CloseIcon />
      </IconButton>
      {options?.title && (
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h3">{options.title}</Typography>
        </DialogTitle>
      )}
      {options?.text && (
        <DialogContent>
          <Typography variant="h5">{options.text}</Typography>
        </DialogContent>
      )}
      {options?.trueOption && options?.falseOption && (
        <RadioGroup
          className={classes.radio}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          value={value}
          row
        >
          <FormControlLabel
            value={'true'}
            control={<CustomRadioButton big={true} />}
            label={options.trueOption}
            labelPlacement="end"
          />
          <FormControlLabel
            value={'false'}
            control={<CustomRadioButton big={true} />}
            label={options.falseOption}
            labelPlacement="end"
          />
        </RadioGroup>
      )}
      <DialogActions style={{ justifyContent: 'space-around' }}>
        <Button
          color="secondary"
          variant="outlined"
          className={classes.cancelButton}
          onClick={cancel}
          disableTouchRipple
        >
          {options?.cancelBtnText ? options.cancelBtnText : 'Cancel'}
        </Button>
        <Button
          type="submit"
          color="primary"
          variant="outlined"
          className={classes.saveButton}
          onClick={() => {
            onSave(value);
          }}
          disableTouchRipple
        >
          {options?.saveBtnText ? options.saveBtnText : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(OptionalModal);
