import { useCallback } from 'react';
import { getOptions } from 'helpers/getOptions';

export const useGetUserData = () => {
  const getUserData = useCallback(async (userId) => {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/users/${userId}`, {
      ...getOptions(),
      method: 'GET',
    }).then((res) => res.json());

    if (response.statusCode > 299) throw new Error(response.message);
    return response;
  }, []);

  return { getUserData };
};
