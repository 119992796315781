import React from 'react';
import cl from 'classnames';
import '../../styles.scss';

function InfoItem({ quantity = 0, info, currency, textColor, borderColor, sizeClass }) {
  const styles = {
    block: {
      borderColor: borderColor || '#6588E8',
    },
    text: {
      color: textColor,
    },
  };
  return (
    <div className={cl('info-item-block', sizeClass)} style={styles.block}>
      <p className="info-quantity">
        {quantity} {currency}
      </p>
      <p className="info-text" style={styles.text}>
        {info}
      </p>
    </div>
  );
}

export default InfoItem;
