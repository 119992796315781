import { applyMiddleware, compose, createStore } from 'redux';
import rootReducer from './reducer';
import thunk from 'redux-thunk';
import {
  calendarEventsSubscriptionMiddleware,
  sheetsSubscriptionMiddleware,
  equipmentSubscriptionMiddleware,
  workersSubscriptionMiddleware,
  holidaysSubscriptionMiddleware,
  contractorsSubscriptionMiddleware,
  projectsSubscriptionMiddleware,
} from './schedule/subscriptions'
import {
  fullScheduleSheetsSubscriptionMiddleware,
  fullScheduleWorkersSubscriptionMiddleware,
  fullScheduleEquipmentSubscriptionMiddleware,
  myScheduleSheetsSubscriptionMiddleware,
  myScheduleWorkersSubscriptionMiddleware,
  myScheduleEquipmentSubscriptionMiddleware,
} from './workerSchedule/subscriptions'
import {
  currentWorklogSheetsSubscriptionMiddleware,
  currentWorklogSheetMaterialsSubscriptionMiddleware,
  currentWorklogWorkersSubscriptionMiddleware,
  currentWorklogTimeoffsSubscriptionMiddleware,
  currentWorklogProjectsSubscriptionMiddleware,
  currentWorklogContractorsSubscriptionMiddleware,
  currentWorklogEquipmentSubscriptionMiddleware,
  currentWorklogSheetSurfacesSubscriptionMiddleware,
} from './currentWorklog/subscriptions';
import {
  personalProfileSettingsSubscriptionMiddleware,
} from './personalProfile/subscriptions';
import { kioskSubscriptionMiddleware } from './kiosk/subscriptions/kiosk.subscriptions';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middlewares = [
  thunk,
  calendarEventsSubscriptionMiddleware,
  sheetsSubscriptionMiddleware,
  equipmentSubscriptionMiddleware,
  workersSubscriptionMiddleware,
  holidaysSubscriptionMiddleware,
  contractorsSubscriptionMiddleware,
  projectsSubscriptionMiddleware,
  // worker schedule
  fullScheduleSheetsSubscriptionMiddleware,
  fullScheduleWorkersSubscriptionMiddleware,
  fullScheduleEquipmentSubscriptionMiddleware,
  myScheduleSheetsSubscriptionMiddleware,
  myScheduleWorkersSubscriptionMiddleware,
  myScheduleEquipmentSubscriptionMiddleware,
  // current worklog
  currentWorklogSheetsSubscriptionMiddleware,
  currentWorklogSheetMaterialsSubscriptionMiddleware,
  currentWorklogWorkersSubscriptionMiddleware,
  currentWorklogTimeoffsSubscriptionMiddleware,
  currentWorklogProjectsSubscriptionMiddleware,
  currentWorklogContractorsSubscriptionMiddleware,
  currentWorklogEquipmentSubscriptionMiddleware,
  // personal profile settings
  personalProfileSettingsSubscriptionMiddleware,
  currentWorklogSheetSurfacesSubscriptionMiddleware,
  // kiosk
  kioskSubscriptionMiddleware,
];

export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middlewares)));

export default store;
