import React from 'react';
import ProjectInput from './ProjectInput';
import CipField from './CipField';
import CipButton from '@material-ui/core/Button';
import useStyles from '../useStyles';
import { Button, DialogActions } from '../../base';
import { Formik, Form, Field, FieldArray } from 'formik';
import Grid from '@material-ui/core/Grid';
import { useDispatch } from 'react-redux';
import store from 'store/store';
import cipPageActions from 'store/cip/cipPageActions';
const initCipJob = {
  project: '',
  time: {
    hours: 0,
    minutes: 0,
  },
};

const CipForm = ({ onClose, data }) => {
  const state = store.getState();
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <div className="className={classes.form}">
      <Formik
        initialValues={{
          сipJobs: [initCipJob],
        }}
        onSubmit={(values) => {

          if (values) {
            const timeclockId = data && data.timeclock[0]._id,
              userId = data && data.user._id;
              const CipHoursIdPairDto = values.сipJobs.map((el) => {
              const minute = el.time.minutes / 60;
              const hours = el.time.hours + minute;
              const cipProjectId = el.project;

              return {
                hours,
                cipProjectId,
              };
            });

            const res = {
              timeclockId,
              userId,
              CipHoursIdPairDto,
            };

            dispatch(cipPageActions.setHours(res));
          }

          onClose();
        }}
        render={({ values }) => (
          <Form>
            <FieldArray
              name="сipJobs"
              render={(arrayHelpers) => {
                return (
                  <div>
                    {values && values.сipJobs.length > 0
                      ? values.сipJobs.map((job, index) => (
                          <React.Fragment key={job._id}>
                            <Field
                              name={`сipJobs.${index}.project`}
                              render={(props) => <ProjectInput {...props} label="Project name:" />}
                            />
                            <Grid
                              container
                              alignItems={'center'}
                              wrap={'nowrap'}
                              style={{ marginBottom: '20px' }}
                            >
                              <Field
                                name={`сipJobs.${index}.time.hours`}
                                render={(props) => (
                                  <CipField
                                    {...props}
                                    range={12}
                                    label="Hours"
                                    style={{ marginRight: '20px' }}
                                  />
                                )}
                              />
                              <Field
                                name={`сipJobs.${index}.time.minutes`}
                                render={(props) => (
                                  <CipField {...props} range={60} label="Minutes" />
                                )}
                              />
                            </Grid>

                            <div className={classes.timeContainer}>
                              <CipButton
                                className={classes.CipButton}
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                <span
                                  className={classes.CipButtonText}
                                  style={{
                                    fontSize: ' 38px',
                                    fontWeight: '100',
                                    marginRight: '5px',
                                  }}
                                >
                                  -
                                </span>
                                <span className={classes.CipButtonText}>Remove CIP</span>
                              </CipButton>
                              <CipButton
                                className={classes.CipButton}
                                onClick={() => arrayHelpers.insert(index + 1, '')}
                              >
                                <span
                                  className={classes.CipButtonText}
                                  style={{
                                    fontSize: ' 30px',
                                    marginRight: '8px',
                                    fontWeight: '100',
                                  }}
                                >
                                  +
                                </span>
                                <span className={classes.CipButtonText}>Add CIP</span>
                              </CipButton>
                            </div>
                          </React.Fragment>
                        ))
                      : null}
                  </div>
                );
              }}
            ></FieldArray>
            <DialogActions disableSpacing={false} style={{ justifyContent: 'center' }}>
              <Button
                type="submit"
                variant={'outlined'}
                fullWidth={true}
                style={{ width: '140px' }}
              >
                Submit
              </Button>
            </DialogActions>
          </Form>
        )}
      ></Formik>
    </div>
  );
};
export default CipForm;
