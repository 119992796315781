export const styles = (theme) => ({
  timeBlockWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minWidth: '38em',
    [theme.breakpoints.down('md')]: {
      width: '650px',
      margin: '0 auto 25px',
      flexDirection: 'column',
      alignItems: 'center',
    },
    '@media (max-width: 680px)': {
      width: '500px',
    },
    '@media (max-width: 530px)': {
      width: '100%',
      minWidth: 'auto',
    },
  },
  timeBlockContainer: {
    margin: '0',
    '@media (min-width: 991.95px)': {
      marginTop: '18px',
    },
  },
  stopContainer: {
    width: '2.65em',
    height: '2.65em',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  stopContainer2: {
    backgroundColor: '#DD6060',
    borderRadius: '50%',
    height: '2.208em',
    width: '2.208em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  stopIcon: {
    fontSize: '1.5em',
    color: '#fff',
  },
  startIcon: {
    fontSize: '2.65em',
  },
  time: {
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.7rem',
    },
  },
  logoutButton: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: '20px 0 25px 0',
    },
    '@media (min-width: 991.95px)': {
      marginTop: '18px',
    },
    '&:hover, &:focus': {
      color: theme.palette.textColor.primary,
    },
    '&:disabled': {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.textColor.secondary,
    },
  },
});
