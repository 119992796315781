import React from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import DayPicker from '../../../../components/DayPicker';
import Button from '@material-ui/core/Button';
import styles from './styles';
import { ScheduleViewSwitcher } from './components/ScheduleViewSwitcher/ScheduleViewSwitcher';
import { PrintButton } from './components/PrintButton/PrintButton';
import { FilterButton } from './components/FilterButton/FilterButton';
import { ManageButtons } from './components/ManageButtons/ManageButtons';
import { useSelector, useDispatch } from 'react-redux';
import { DRAWER_TYPES } from '../SchedulePage/helpers/drawerType';
import DateTypeSelector from './components/DateTypeSelector/DateTypeSelector';
import MonthlyCalendar from './components/MonthlyCalendar/MonthlyCalendar';
import { CALENDAR_VIEW_TYPES } from 'pages/Schedule/components/ScheduleHeader/components/DateTypeSelector/constants';
import { isSameMonth } from 'date-fns';
import { updateMonthlySelectedDate } from 'store/schedule/schedulePageOperation';
import schedulePageActions from 'store/schedule/schedulePageActions';

const ScheduleHeader = ({
  classes,
  openDrawer,
  selectedDate,
  reconcile,
  updateSelectedDate,
  updatePublishControl,
  showReconcileModal,
  updateSetWorklogsControl,
}) => {
  const dispatch = useDispatch();
  const scheduleType = useSelector((state) => state.schedule.view);
  const scheduleDateType = useSelector((state) => state.schedule.calendarTypeView);
  const currentMonthDate = useSelector((state) => state.schedule.currentMonthDate);

  const isToday = () => {
    if (!selectedDate) return true;
    return isSameMonth(new Date(), new Date(currentMonthDate));
  };
  const setToday = () => {
    dispatch(updateMonthlySelectedDate(new Date()));
    dispatch(schedulePageActions.setSelectedMonthDayDate(new Date()));
  };

  return (
    <header className={classes.header}>
      <Typography variant="h3">
        Schedule
        <span className={classes.subtitle}>{scheduleType} view</span>
      </Typography>

      <ManageButtons
        openDrawer={openDrawer(DRAWER_TYPES.addWorklog)}
        reconcile={reconcile}
        showReconcileModal={showReconcileModal}
        updatePublishControl={updatePublishControl}
      />

      <FilterButton openDrawer={openDrawer(DRAWER_TYPES.filters)} />

      {scheduleDateType === CALENDAR_VIEW_TYPES.daily && (
        <>
          <ScheduleViewSwitcher scheduleType={scheduleType} />
          <PrintButton handlePrint={updateSetWorklogsControl} />
        </>
      )}
      <DateTypeSelector />
      {scheduleDateType === CALENDAR_VIEW_TYPES.monthly && (
        <Button
          className={classes.todayButton}
          variant="contained"
          color="secondary"
          onClick={setToday}
          disabled={isToday()}
          disableTouchRipple
        >
          Today
        </Button>
      )}
      {scheduleDateType === CALENDAR_VIEW_TYPES.daily ? (
        <DayPicker selectedDate={selectedDate} updateSelectedDate={updateSelectedDate} />
      ) : (
        <MonthlyCalendar />
      )}
    </header>
  );
};

ScheduleHeader.propTypes = {
  classes: propTypes.object.isRequired,
  openDrawer: propTypes.func.isRequired,
  selectedDate: propTypes.object.isRequired,
  reconcile: propTypes.object.isRequired,
  updateSelectedDate: propTypes.func.isRequired,
  updatePublishControl: propTypes.func.isRequired,
  showReconcileModal: propTypes.func.isRequired,
  updateSetWorklogsControl: propTypes.func.isRequired,
};

export default withStyles(styles)(ScheduleHeader);
