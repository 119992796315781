import React, { useCallback } from 'react';
import useStyles from './useStyles';
import BaseCalendar from 'react-calendar';
import CalendarHeader from './components/CalendarHeader';
import './../CustomCalendar';
import { addMonths, subMonths } from 'date-fns';
import { useWeekStart } from 'hooks/useWeekStart';

const CalendarV2 = React.forwardRef(
  (
    {
      value,
      onChange = () => null,
      onClose = () => null,
      onClickDay = () => null,
      maxDate = undefined,
    },
    ref
  ) => {
    const { weekDayFromO } = useWeekStart();
    const classes = useStyles();
    const onPrev = useCallback(() => onChange(subMonths(value, 1)), [value]);
    const onNext = useCallback(() => onChange(addMonths(value, 1)), [value]);

    return (
      <div className={classes.root} ref={ref}>
        <CalendarHeader date={value} onPrev={onPrev} onNext={onNext} onClose={onClose} />
        <BaseCalendar
          locale="us-US"
          calendarType={weekDayFromO === 0 ? 'US' : 'ISO 8601'}
          showNavigation={false}
          value={value}
          onClickDay={onClickDay}
          onChange={(v) => onChange(v)}
          maxDate={maxDate}
        />
      </div>
    );
  }
);

export default CalendarV2;
