import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './styles';
import { Grid, withStyles } from '@material-ui/core';
import cs from 'classnames';
import DayPickerInput from '../../DateInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import ArrowBlack from 'assets/images/arrow-right-black.svg';
import { startOfWeek, endOfWeek, addDays, subDays, format } from 'date-fns';
import { useWeekStart } from 'hooks/useWeekStart';
import { useSelector } from 'react-redux';
import { GLOBAL_DATE_FORMAT, momentAdepter } from 'common/dateFormatConfig';

const getWeekRange = (date, weekDayFromO) => {
  if (!date) {
    return {
      from: startOfWeek(new Date(), { weekStartsOn: weekDayFromO }),
      to: endOfWeek(new Date(), { weekStartsOn: weekDayFromO }),
    };
  }
  return {
    from: startOfWeek(new Date(date), { weekStartsOn: weekDayFromO }),
    to: endOfWeek(new Date(date), { weekStartsOn: weekDayFromO }),
  };
};

const getWeekDays = (weekStart) => {
  const days = [weekStart];
  for (let i = 1; i < 7; i += 1) {
    days.push(addDays(weekStart, i));
  }
  return days;
};

const DateInputTrigger = React.forwardRef(({ selectedRange: { from, to }, ...props }, ref) => (
  <span {...props} ref={ref} tabIndex="-1">
    {`${format(from, GLOBAL_DATE_FORMAT.shortDateWithDay)} - ${format(
      to,
      GLOBAL_DATE_FORMAT.shortDateWithDay
    )}`}
  </span>
));

const WeekPicker = ({
  value: initialValue,
  onChange,
  classes,
  className,
  trigger = DateInputTrigger,
  disableControls = false,
  dis,
}) => {
  const { weekDayFromO } = useWeekStart();
  const [pickerShown, setPickerShown] = useState(false);
  const [value, setValue] = useState(
    getWeekRange(initialValue ? initialValue.from : new Date(), weekDayFromO)
  );
  const [hoveredWeek, setHoveredWeek] = useState(null);
  const weekStart = useSelector((state) => state.settings?.settings?.weekStart);

  const inputRef = useRef();

  const onDayPickerShow = useCallback(() => {
    setPickerShown(true);
  }, []);

  const onDayPickerHide = useCallback(() => {
    setPickerShown(false);
  }, []);

  const onNextWeekClick = useCallback(() => {
    setValue(getWeekRange(addDays(value.from, 7), weekDayFromO));
  }, [value, weekDayFromO]);

  const onPreviousWeekClick = useCallback(() => {
    setValue(getWeekRange(subDays(value.from, 7), weekDayFromO));
  }, [value, weekDayFromO]);

  const handleDayClick = useCallback(
    (date) => {
      setValue(getWeekRange(date, weekDayFromO));
    },
    [weekDayFromO]
  );

  const handleDayEnter = useCallback(
    (date) => {
      setHoveredWeek(getWeekRange(date, weekDayFromO));
    },
    [weekDayFromO]
  );
  const handleDayLeave = useCallback(() => {
    setHoveredWeek(null);
  }, []);

  const onTriggerClick = useCallback(() => {
    pickerShown ? inputRef.current.hideDayPicker() : inputRef.current.showDayPicker();
  }, [pickerShown, inputRef]);

  useEffect(() => {
    if (
      (!initialValue || Number(new Date(initialValue.from)) !== Number(value.from)) &&
      weekStart
    ) {
      onChange(value);
    }
  }, [value, weekStart]);

  useEffect(() => {
    if (initialValue) {
      setValue(getWeekRange(initialValue.from, weekDayFromO));
    }
  }, [initialValue]);

  const modifiers = {
    hoverRange: hoveredWeek,
    hoverRangeStart: hoveredWeek && hoveredWeek.from,
    hoverRangeEnd: hoveredWeek && hoveredWeek.to,
    selectedRangeStart: getWeekDays(value.from)[0],
    selectedRangeEnd: getWeekDays(value.from)[6],
    selectedRange: value,
  };

  return (
    <Grid
      container
      spacing={disableControls ? 0 : 2}
      className={className}
      alignItems={'center'}
      wrap={'nowrap'}
    >
      {!disableControls && (
        <Grid item xs={'auto'}>
          <Grid container spacing={2} wrap={'nowrap'} className={dis ? classes.dis : ''}>
            <Grid item xs={'auto'}>
              <button
                type={'button'}
                className={classes.stepButton}
                onClick={dis ? () => {} : onPreviousWeekClick}
              >
                <img className={classes.decrimentIcon} src={ArrowBlack} />
              </button>
            </Grid>
            <Grid item xs={'auto'}>
              <button
                type={'button'}
                className={classes.stepButton}
                onClick={dis ? () => {} : onNextWeekClick}
              >
                <img className={classes.incrementIcon} src={ArrowBlack} />
              </button>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item xs={disableControls ? 12 : 'auto'}>
        <div className={classes.label}>
          <DayPickerInput
            ref={inputRef}
            value={value.from}
            onDayPickerShow={onDayPickerShow}
            onDayPickerHide={onDayPickerHide}
            dayPickerProps={{
              firstDayOfWeek: weekDayFromO,
              initialMonth: value.from,
              onDayClick: handleDayClick,
              onDayMouseEnter: handleDayEnter,
              onDayMouseLeave: handleDayLeave,
              modifiers,
              selectedDays: getWeekDays(value.from),
            }}
            inputProps={{ selectedRange: value }}
            component={trigger}
            format={momentAdepter(GLOBAL_DATE_FORMAT.shortDateWithDay).momentShortDayDate} //* @ "ddd, MMMM DD, YYYY"
            formatDate={formatDate}
            parseDate={parseDate}
          />
        </div>
      </Grid>
      {!disableControls && (
        <Grid item xs={'auto'}>
          <button type={'button'} className={classes.triggerButton} onClick={onTriggerClick}>
            <img
              className={cs(classes.triggerIcon, { [classes.triggerIconActive]: pickerShown })}
              src={ArrowBlack}
            />
          </button>
        </Grid>
      )}
    </Grid>
  );
};

export default withStyles(styles)(WeekPicker);
