import React, { useCallback } from 'react';
import styles from './styles';
import { Button, withStyles } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import kioskPageActions from 'store/kiosk/kioskPageActions';
import { useDispatch, useSelector } from 'react-redux';
import loginActions from 'store/login/loginActions';
import personalProfileActions from 'store/personalProfile/personalProfileActions';

const NotFoundPage = ({ classes }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onReturnHomeClick = useCallback(() => {
    if (!!+localStorage.getItem('isKioskPage')) {
      dispatch(personalProfileActions.clearPersonalProfile());
      dispatch(loginActions.clearUserData());
      dispatch(kioskPageActions.logout());
      navigate('/kiosk');
    } else {
      navigate('/');
    }
  }, []);

  const companyName = useSelector(state => (state.personalProfile?.organization?.name || '3208 Technology, Inc.'));

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <span className={classes.appName}>{companyName}</span>
      </div>
      <div className={classes.body}>
        <div className={classes.top}>
          <span className={classes.statusCode}>404</span>
        </div>
        <div className={classes.bottom}>
          <span className={classes.title}>Oops, something went wrong.</span>
          <span className={classes.description}>
            The page you are looking for was deleted or doesn't exist. <br />
            Please, try again later.
          </span>
          <Button className={classes.returnHomeBtn} onClick={onReturnHomeClick}>
            Return Home
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(NotFoundPage);
