import React, { useCallback, useState, useMemo } from 'react';
import styles from './styles';
import { withStyles } from '@material-ui/core';
import SearchInput from '../../../../../../../../components/form/SearchInput/SearchInput';
import debounce from 'lodash/debounce';
import {
  formatUserOptions,
  userSearch
} from '../../../../../../components/ByIndividualWeek/ByIndividualWeek';
import Fuse from 'fuse.js';
import { useSelector } from 'react-redux';

const searchOptions = {
    shouldSort: true,
    keys: ['username', 'profile.employeeNum', 'profile.fullName'],
  };

const getUserName = user => (user.profile && user.profile.fullName ? user.profile.fullName : user.username);

const UserPicker = ({ value, onChange }) => {
  const allUsers = useSelector((state) => state.people.users);

  const [userOptions, setUserOptions] = useState([]);
  const [users, setUsers] = useState([]);
  const [selected, setSelected] = useState(value);
  const [search, setSearch] = useState('');
  const [focused, setFocused] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [fuse, setFuse] = useState(null);
  
  const onSearchFocus = useCallback(async e => {
    if (e.target.value) {
      return;
    }

    setSearchLoading(true);
    setFocused(true);
    setSearchLoading(false);
  }, []);

  const onSearchInput = useCallback(
    debounce(async e => {
    }, 400),
    []
  );

  const onUserSelect = useCallback(
    ({ value }) => {
      const user = allUsers.find(u => u._id === value);
      setSelected(user);
      onChange(user);
    },
    [allUsers]
  );

  useMemo(() => {
    setFuse(new Fuse(allUsers, searchOptions));
  }, [allUsers]);

  const usersForSearch = formatUserOptions(fuse && search ? fuse.search(`${search}`) : allUsers);

  return (
    <SearchInput
      placeholder={'Search by User Name & Full Name & Employee #'}
      onFocus={onSearchFocus}
      value={selected && !focused ? getUserName(selected) : search}
      loading={searchLoading}
      onInput={e => {
        setSearchLoading(true);
        setSearch(e.target.value);
        onSearchInput(e.persist() || e);
        setSearchLoading(false);
      }}
      onBlur={() => {
        setTimeout(() => {
          setFocused(false);
        }, 400);
      }}
      onSelect={onUserSelect}
      options={usersForSearch}
    />
  );
};

export default withStyles(styles)(UserPicker);
