import { Box, withStyles } from '@material-ui/core/index';

export const Root = withStyles({
  root: {
    marginBottom: 8,
    '&:last-child': {
      marginBottom: 0,
    },
  },
})(Box);
