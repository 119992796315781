export const permLabels = {
  cipKioskMode: 'CIP time entry',
  editPTO: 'Edit PTO',
  activeSchedule: 'Active Schedule',
  remoteKiosk: 'My Remote Kiosk',
  kioskModeMobile: 'Mobile Kiosk',
  projectsRead: 'View projects',
  equipmentRead: 'View equipment',
  availabilityRead: 'View availability',
  availabilityFullAccess: 'Edit availability',
  availabilityPeopleEdit: 'Edit availability (people)',
  availabilityEquipmentEdit: 'Edit availability (equipment)',
  timeClockFullAccess: 'Edit timeclock',
  cipFullAccess: 'Edit CIP',
  usersFullAccess: 'Edit people',
  holidaysCreate: 'Add holiday',
  holidaysEdit: 'Edit holiday',
  worklogsRead: 'View worklog',
  equipmentFullAccess: 'Edit equipment',
  cipProjectsFullAccess: 'Edit CIP projects',
  worklogsFullAccess: 'Edit worklogs',
  worklogsEdit: 'Edit worklogs',
  worklogsEditSubmitted: 'Edit submitted worklogs',
  projectsFullAccess: 'Edit projects',
  scheduleFullAccess: 'Edit Schedule',
  suppliesFullAccess: 'Edit supplies',
  kioskMode: 'Enter kiosk mode',
  PTOOvertimeException: 'PTO  Overtime Exception',
  HolidayOvertimeException: 'Holiday Overtime Exception',
  myScheduleFull: 'Full Schedule',
  payrollActions: 'Payroll',
  materialCertification: 'Material Certifications',
};
