import React, { useState } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { useController } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styles from './styles';
import CloseIcon from 'assets/images/close-icon.svg';
import cn from 'classnames';
import InjuryReportEmails from '../InjuryReportEmails/index';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const LateClockIn = ({ classes, pageType, control, errors }) => {

  const {
    field: { onChange, value: LateClockIn },
  } = useController({
    name: 'lateClockInEmails',
    control,
  });
  const [tabValue, setTabValue] = useState(0);

  const handleChangeTab = (e, newValue) => {
    if (newValue === tabValue) return;
    setTabValue(newValue);
  };

  const [isShowEmails, setShowEmails] = useState(false);


  const handleEmailChange = (i) => (e) => {
    const { value } = e.target;
    const nextValue = LateClockIn.map((t, index) => (index === i ? value : t));
    onChange(nextValue);
  };
  const handleEmailRemoving = (i) => () => {
    const nextValue = LateClockIn.filter((_, index) => index !== i);
    onChange(nextValue);
  };
  const handleAddEmail = () => {
    setShowEmails(true);
    onChange([...LateClockIn.filter((v) => v.length), '']);
  };

  return (
    <div>
      <Typography variant="h4">Reports</Typography>

      <Tabs
        value={tabValue}
        variant="scrollable"
        onChange={handleChangeTab}
        className={classes.tabs}
      >
        <Tab label="Late Clock-In Report" disableRipple />
        <Tab label="Injury Report" disableRipple />
      </Tabs>

      {tabValue === 0 && (
        <div className={classes.actionsWrapper}>
          {!!LateClockIn.length &&
            LateClockIn?.slice(0, isShowEmails ? LateClockIn.length : 3)?.map((email, index) => (
              <Grid container spacing={2} key={`lateClockInEmails-${index}`} alignItems="center">
                <Grid item xs={10} sm={6} md={4} className={classes.zeroPaddingTop}>
                  {index === 0 && (
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      className={classes.regularFontWeight}
                    >
                      Email:
                    </Typography>
                  )}
                  <TextField
                    id={`lateClockInEmails[${index}]`}
                    name={`lateClockInEmails[${index}]`}
                    type="email"
                    value={email}
                    className={classes.textField}
                    onChange={handleEmailChange(index)}
                    variant="outlined"
                    inputProps={{ 'aria-label': 'Late Clock-In Report' }}
                    fullWidth
                    disabled={pageType === 'read'}
                  />
                </Grid>
                {pageType === 'edit' && (
                  <span
                    style={index === 0 ? { marginTop: 15 } : {}}
                    className={classes.removeEmail}
                    onClick={handleEmailRemoving(index)}
                  >
                    <img src={CloseIcon} alt="delete" />
                  </span>
                )}
              </Grid>
            ))}
          {LateClockIn?.length > 3 && (
            <div
              className={classes.buttonWrapper}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setShowEmails((prev) => !prev);
              }}
            >
              <div className={classes.nonActionsIcon}>
                <ExpandMoreIcon className={cn(isShowEmails ? '' : classes.reversIcon)} />
              </div>
              <p className={classes.textIconMore}>{`${
                isShowEmails ? 'Hide' : 'Show'
              } additional email addresses`}</p>
            </div>
          )}
          {pageType === 'edit' && (
            <Button
              variant="text"
              className={classes.primaryTextButton}
              onClick={handleAddEmail}
              disableRipple
            >
              + Add Email
            </Button>
          )}
        </div>
      )}
      {tabValue === 1 && (
        <>
          <InjuryReportEmails pageType={pageType} errors={errors} control={control} />
        </>
      )}
    </div>
  );
};

LateClockIn.propTypes = {
  classes: propTypes.object.isRequired,
  pageType: propTypes.string.isRequired,
  errors: propTypes.object.isRequired,
  control: propTypes.object.isRequired,
};

export default withStyles(styles)(LateClockIn);
