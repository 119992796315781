import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { addSurface, patchSurfaceWorklogMaterial } from 'store/currentWorklog/operations';
import { TextField, Typography } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import currentWorklogActions from 'store/currentWorklog/actions';
import { InputAdornment } from '../../../node_modules/@material-ui/core/index';
import classNames from '../../../node_modules/classnames/index';

const SurfaceInput = ({
  classes,
  groupedMaterials,
  sheet,
  shouldRenderAddButton,
  isMaterialForm,
  setFieldValue,
  values,
  hasAccess,
  locationOptions,
  errors,
  handleBlur,
  touched,
}) => {
  const dispatch = useDispatch();
  const { surfaces, createdWlSurface } = useSelector((store) => store.currentWorklog.worklogSheet);
  const [surfaceSelectValue, setSurfaceSelectValue] = useState('');
  const [localSurfaces, setLocalSurfaces] = useState(surfaces || []);
  // This field is required to distinguish which form is creating surface (as with opened drawer there is 2 of them present),
  //   from materials drawer or from Materials Block of Worklog Details.
  // Removing or skipping setting/unsetting this field lead
  //   to problem of setting surface to first Materials Block of Worklog Details page while creating new surface from Drawer.
  //   (Open drawer -> create surface -> Material Block form became set with newly created surface)
  const [surfaceFormOrigin, setSurfaceFormOrigin] = useState(null);
  const [isCreate, setIsCreate] = useState(false);
  const [inputValue, setInputValue] = useState({ name: '' });

  useEffect(() => {
    setLocalSurfaces(surfaces);
  }, [surfaces]);

  useEffect(() => {
    if (createdWlSurface && surfaceFormOrigin === isMaterialForm) {
      setSurfaceSelectValue(createdWlSurface._id);
      handleChangeSurface(null, createdWlSurface.name);
      if (isMaterialForm) setFieldValue('surface', createdWlSurface.name);

      dispatch(currentWorklogActions.setCreatedWlSurface(null));
      setSurfaceFormOrigin(null);
    }
  }, [createdWlSurface])

  const locationValue = useMemo(() => {
    if (values?.location) {
      return values.location;
    }
    if (groupedMaterials.location) {
      return groupedMaterials.location;
    }
    if (locationOptions.length) {
      const index = locationOptions.length === 1 ? 0 : locationOptions.length - 1;
      return locationOptions[index].id;
    }
    return '';
  }, [groupedMaterials?.location, values?.location]);

  const surfaceValue = useMemo(() => {
    const surfaceName = values?.surface || groupedMaterials?.surface || '';
    const surfaceObj = localSurfaces.find((el) => el.name === surfaceName);
    if (!surfaceObj) {
      setLocalSurfaces((prevValue) => [
        ...prevValue,
        {
          name: surfaceName,
          _id: uuidv4(),
          fallback: true,
        },
      ]);
      return surfaceName || '';
    }
    setSurfaceSelectValue(surfaceObj?._id || '');
    return surfaceObj?.name || surfaceName || '';
  }, [groupedMaterials?.surface, values?.surface, localSurfaces]);

  const handleChangeSurface = (e, name) => {
    const value = e?.target?.value || name;
    if (isMaterialForm) {
      setFieldValue('surface', value);
    } else {
      const prevSurface = surfaceValue;

      dispatch(
        patchSurfaceWorklogMaterial(sheet._id, {
          prevSurface,
          nextSurface: value,
          locationId: locationValue,
        })
      );
    }
  };

  const handleChangeSurfaceSelect = (e) => {
    const value = e.target.value;
    if (value === 'create-wl-surface') {
      return setIsCreate(true);
    }

    setSurfaceSelectValue(value);
    const surfaceName = localSurfaces.find((elem) => elem._id === value);
    handleChangeSurface(null, surfaceName.name);
    if (isMaterialForm) setFieldValue('surface', surfaceName.name);
  };

  // Creating new surface related only to worklog
  const handleChangeInput = (e) => {
    const value = e.target.value;
    setInputValue((prev) => ({ ...prev, name: value }));
  };

  const onCreate = (e) => {
    e.preventDefault();
    dispatch(addSurface({ ...inputValue, sheetId: sheet._id }, sheet._id));

    setInputValue({ name: '' });
    setIsCreate(false);
    setSurfaceFormOrigin(isMaterialForm);
  }

  const cancelCreate = (e) => {
    e.preventDefault();
    
    setInputValue({ name: '' });
    setIsCreate(false);
    setSurfaceFormOrigin(null);
  }

  return (
    <>
      <Typography variant="body1" color="textSecondary">
        Surface{isMaterialForm && <span className={classes.requiredSign}>*</span>}:
      </Typography>

      {(isCreate) ? (
        <TextField
          id="HICode"
          name="HICode"
          value={inputValue?.name || ''}
          onChange={handleChangeInput}
          type="text"
          className={classes.textField}
          variant="outlined"
          fullWidth
          inputProps={{ 'aria-label': 'HI Code' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <div className={classes.inlineFlex}>
                  <Button
                    variant="text"
                    className={classNames(classes.inputButton, classes.primaryButton)}
                    onClick={onCreate}
                    disabled={!inputValue.name}
                  >
                    Add
                  </Button>
                  <Button
                    variant="text"
                    className={classes.inputButton}
                    onClick={cancelCreate}
                    disableRipple
                  >
                    Cancel
                  </Button>
                </div>
              </InputAdornment>
            ),
          }}
        />
      ) : (
        <TextField
        select
        name="surface"
        id="surface"
        value={surfaceSelectValue}
        onChange={handleChangeSurfaceSelect}
        className={classes.textField}
        variant="outlined"
        disabled={!isMaterialForm && !hasAccess}
        fullWidth
        onBlur={isMaterialForm ? handleBlur : null}
        error={isMaterialForm ? Boolean(errors?.surface && touched?.surface) : null}
      >
        {localSurfaces.map((surfaceEl) => (
          <MenuItem key={surfaceEl._id} value={surfaceEl._id} className={classes.option}>
            {surfaceEl.name}
          </MenuItem>
        ))}
        <MenuItem key="create-wl-surface" value={"create-wl-surface"} className={classes.createSurfaceOption}>
          + Add Surface
        </MenuItem>
      </TextField>
      )}
      {errors?.surface && touched?.surface ? <div className={classes.error}>{errors.surface}</div> : null}
    </>
  );
};

export default withStyles(styles)(SurfaceInput);
