import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import classnames from 'classnames';
import useWorklogStyles from 'components/WorklogDetails/hooks/useWorklogStyles';
import { Divider } from '@material-ui/core';
import { getContactName } from 'helpers/_helpers';

export const SheetsContacts = ({ sheet }) => {
  const worklogClasses = useWorklogStyles();

  return (
    <>
      {(!!sheet?.primaryContact || !!sheet.alternateContacts?.length) && (
        <Grid item xs={12}>
          <Divider />
        </Grid>
      )}
      {sheet?.primaryContact && (
        <Grid item xs={12} lg={12}>
          <Typography
            variant="body1"
            color="textSecondary"
            className={classnames(worklogClasses.regularFontWeight, worklogClasses.paddingBottom)}
          >
            Primary Contact:
          </Typography>
          <Typography variant="body1" color="textPrimary">
            {getContactName(sheet?.primaryContact)}
          </Typography>
        </Grid>
      )}
      {sheet.alternateContacts?.map((contact, i) => {
        return (
          <Grid item xs={12} lg={12}>
            <Typography
              variant="body1"
              color="textSecondary"
              className={classnames(worklogClasses.regularFontWeight, worklogClasses.paddingBottom)}
            >
              Alternate Contact {sheet.alternateContacts.length < 1 ? null : i + 1}:
            </Typography>
            <Typography variant="body1" color="textPrimary">
              {getContactName(contact)}
            </Typography>
          </Grid>
        );
      })}
    </>
  );
};
