import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import AlertModal from '../../../AlertModal';
import PageLoader from '../../../PageLoader';
import SearchForm from '../SearchForm';
import SelectedUser from './components/SelectedUser';
import { compareByForeman } from 'helpers/_helpers';
import { isHoursOverlapseTimeOffs } from '../../helpers';
import { useSelector } from 'react-redux';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';
import { convertTimeObject } from 'common/dateFormatConfig';
import { convertTo24HourFormat } from '../../../../helpers/_date-helpers';

const PeopleBlock = ({
  sheet,
  usedItems,
  setSubmitData,
  unavailableResources,
  list,
  listOfAll,
  isSubmitting,
}) => {
  const sheetsTimeoffs = useSelector((store) => store.currentWorklog.worklogSheet.timeOffs);
  const getTimeFormatHook = useTimeSettingsFormat();
  const is12Format = getTimeFormatHook().is12Format;

  const [selectedResources, setSelectedResources] = useState([]);
  const [availableResources, setAvailableResources] = useState([]);

  const [infoModal, setInfoModal] = useState({
    isOpen: false,
    text: '',
  });

  const hoursAdapter = (hourObj) => {
    const {
      hour: hourStart,
      minute: minuteStart,
      amPm: amPmStart,
    } = convertTimeObject(hourObj, is12Format);

    return {
      hourStart,
      minuteStart,
      amPmStart,
    };
  };

  useEffect(() => {
    const workers = sheet.workers.map((worker) => {
      return {
        ...worker,
        ...(worker.startTime
          ? {
              startTime: hoursAdapter(worker.startTime),
            }
          : {}),
      };
    });

    if (workers.length && sheetsTimeoffs) {
      const usedResourcesIds = workers.map((w) => w._id);
      const availableResourcesData = list.filter((el) => !usedResourcesIds.includes(el._id));
      const workersWithTimeoffs = [];
      workers.forEach((w, i, arr) => {
        const userTimeOffs = sheetsTimeoffs.filter((el) => el.userId === w._id);
        const res = userTimeOffs.map((timeOff) => ({
          start: new Date(timeOff.utcStartDate),
          end: new Date(timeOff.utcEndDate),
        }));

        workersWithTimeoffs.push({ ...w, availableHours: res });
        if (i + 1 === arr.length) {
          setAvailableResources(availableResourcesData);
          setSelectedResources(workersWithTimeoffs);
        }
      });
    } else {
      setAvailableResources([...list]);
      setSelectedResources([]);
    }
  }, [list, sheetsTimeoffs, sheet]);

  useEffect(() => {
    const isValidTimePeriods = !selectedResources.some((r) => r.error);
    const isValidTravelTime = !selectedResources.some((r) => r.travelTimeErrors);
    const isValidHours = selectedResources.reduce((isValid, r) => (
      !r.hours?.some((h) => h.error) && isValid
    ), true);

    const rawData = selectedResources.map((r) => {
      const resource = { ...r };
      if (resource.hours) {
        const rawHours = resource.hours.map((h) => {
          const hour = {
            ...h,
            travelTimeTo: {
              hours: h.travelTimeTo?.hours || '0',
              minutes: h.travelTimeTo?.minutes || '0',
            },
            travelTimeFrom: {
              hours: h.travelTimeFrom?.hours || '0',
              minutes: h.travelTimeFrom?.minutes || '0',
            },
          };
          if (sheet.travelTimeOnly) {
            delete hour.start;
            delete hour.end;
          }
          delete hour.error;
          return hour;
        });
        const resultData = sheet.travelTimeOnly
          ? rawHours.map((el, i) =>
              i === 0 ? el : { ...el, isWlTravelTime: false, isWlHours: false }
            )
          : rawHours;

        resource.hours = resultData;
      }
      resource.startTime = resource.startTime
        ? {
            hour: is12Format
              ? convertTo24HourFormat(resource.startTime?.hourStart, resource.startTime?.amPmStart)
              : resource.startTime?.hourStart,
            minute: resource.startTime?.minuteStart,
          }
        : null;
      delete resource.availableHours;
      if (resource.error) {
        delete resource.error;
      }
      return resource;
    });
    const isValid =
      isValidTimePeriods && (isValidHours || sheet.travelTimeOnly) && isValidTravelTime;
    if (isSubmitting) {
      setSubmitData(rawData, isValid);
    }
  }, [isSubmitting]);

  const formatList = () => {
    return availableResources.map((user) => ({
      label: user.username,
      id: user._id,
      info: user,
    }));
  };

  const selectItem = (selectedId) => {
    let selectedItem;
    const availableResourcesData = availableResources.filter((el) => {
      if (el._id === selectedId) {
        selectedItem = {
          _id: el._id,
          foreman: false,
        };
      }
      return el._id !== selectedId;
    });

    const userTimeOffs = sheetsTimeoffs?.filter((el) => el.userId === selectedId);
    const res = userTimeOffs?.map((timeOff) => ({
      start: new Date(timeOff.utcStartDate),
      end: new Date(timeOff.utcEndDate),
    }));

    let error = '';
    const hours = { start: new Date(sheet.hours.start), end: new Date(sheet.hours.end) };
    const isInvalidHoursByTimeoffs = isHoursOverlapseTimeOffs([hours], res || [], sheet);

    if (isInvalidHoursByTimeoffs) error = 'User has timeoff at this time';

    // const item = { ...selectedItem };
    const item = { ...selectedItem, availableHours: res, error };

    setAvailableResources(availableResourcesData);
    setSelectedResources((prev) => [...prev, item]);
  };

  const getUser = (user) =>
    listOfAll.find((el) => el._id === user._id) || { ...user, profile: { shifts: user.shifts } };

  const updateSelectedResource = (id, data) => {
    const resourceIndex = selectedResources.findIndex((el) => el._id === id);
    const resources = [...selectedResources];
    resources[resourceIndex] = data;

    setSelectedResources(resources.sort(compareByForeman));
  };

  const removeSelectedResource = (id) => {
    const selectedResourcesData = selectedResources.filter((el) => el._id !== id);
    const usedResourcesIds = selectedResourcesData.map((w) => w._id);
    const availableResources = list.filter((el) => !usedResourcesIds.includes(el._id));

    setAvailableResources(availableResources);
    setSelectedResources(selectedResourcesData);
  };

  const closeInfoModal = () => setInfoModal({ isOpen: false, text: '' });

  return (
    <PageLoader loading={false}>
      <>
        <div>
          <SearchForm
            list={formatList()}
            selectItem={selectItem}
            type="people"
            usedItems={usedItems}
            unavailableResources={unavailableResources}
          />
        </div>

        <div>
          {selectedResources.map((r) => (
            <SelectedUser
              key={r._id}
              user={getUser(r)}
              sheet={sheet}
              workerData={r}
              updateSelectedResource={updateSelectedResource}
              removeSelectedResource={removeSelectedResource}
            />
          ))}
        </div>

        {infoModal.isOpen && (
          <AlertModal isOpen={infoModal.isOpen} info={infoModal.text} onClose={closeInfoModal} />
        )}
      </>
    </PageLoader>
  );
};

PeopleBlock.propTypes = {
  list: propTypes.array.isRequired,
  sheet: propTypes.object.isRequired,
  isSubmitting: propTypes.bool.isRequired,
  setSubmitData: propTypes.func.isRequired,
  usedItems: propTypes.array.isRequired,
  unavailableResources: propTypes.array.isRequired,
};

export default PeopleBlock;
