import { useCallback } from 'react';
import { Formik, Form } from 'formik/dist/index';
import { withStyles } from '@material-ui/core/styles/index';
import Typography from '@material-ui/core/Typography/index';
import IconButton from '@material-ui/core/IconButton/index';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid/index';
import TextField from '@material-ui/core/TextField/index';
import Button from '@material-ui/core/Button/index';
import PageLoader from 'components/PageLoader';
import styles from './styles';
import { useDispatch } from 'react-redux';
import suppliesActions from 'store/supplies/suppliesActions';
import { addSuppliesSurface, updateSuppliesSurface } from 'store/supplies/suppliesOperations';

const FormPanel = ({ classes, closeDrawer, data, onSave, type }) => {
  const dispatch = useDispatch();

  const onSubmit = useCallback(async (values) => {
    if (data?._id) {
      await dispatch(updateSuppliesSurface(data._id, values));
    } else {
      await dispatch(addSuppliesSurface(values));
    }
    onSave();
  }, []);

  const initialValues = data?._id
    ? { ...data }
    : { name: '' };

  return (
    <div className={classes.formWrapper} role="presentation">
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validate={(values) => {
          const errors = {};
          if (!values.name) {
            errors.name = 'Name is required';
          }
          return errors;
        }}
        onSubmit={(values) => {
          onSubmit(values);
        }}
      >
        {({ values, errors, handleChange }) => (
          <PageLoader>
            <Form autoComplete="off" className={classes.formFlex}>
              <div>
                <Typography variant="h3" className={classes.marginHeader}>
                  {type === 'create' ? 'Create' : 'Edit'} Surface
                </Typography>

                <IconButton
                  className={classes.closeButton}
                  onClick={() => {
                    dispatch(suppliesActions.validationGlassBeadError(false));
                    closeDrawer();
                  }}
                  aria-label="Close"
                  disableTouchRipple
                >
                  <CloseIcon />
                </IconButton>

                <Grid container xs={12}>
                  <Grid container spacing={1} style={{ justifyContent: 'space-between' }}>
                    <Grid item xs={12}>
                      <label htmlFor="name">
                        <Typography variant="body1" color="textSecondary">
                          Type of Surface
                        </Typography>
                        <TextField
                          id="name"
                          value={values.name}
                          variant="outlined"
                          className="materials-input"
                          InputProps={{
                            inputMode: 'numeric',
                            pattern: '[0-9]*',
                            className: `${errors.name ? 'materials-input--error' : ''}`,
                          }}
                          fullWidth={true}
                          onChange={handleChange}
                        />
                      </label>
                      {errors.name && (
                        <span className="materials-input--error-text">{errors.name}</span>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </div>

              <div className={classes.buttonHolder}>
                <Button
                  color="secondary"
                  className={classes.cancelButton}
                  onClick={() => {
                    dispatch(suppliesActions.validationGlassBeadError(false));
                    closeDrawer();
                  }}
                >
                  Cancel
                </Button>

                <div className={classes.verticalDivider}></div>

                <Button type="submit" color="primary" className={classes.saveButton}>
                  Save
                </Button>
              </div>
            </Form>
          </PageLoader>
        )}
      </Formik>
    </div>
  );
};

export default withStyles(styles)(FormPanel);
