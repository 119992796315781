import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import routes from 'api/routes/routes';
import RequireAuth from './hoc/RequireAuth/RequireAuth';
import SignIn from './pages/SignIn';
import ForgotPassword from 'pages/ForgotPassword/ForgotPassword';
import ResetPassword from 'pages/ResetPassword/ResetPassword';
import WorklogPrint from 'pages/WorklogPrint/index';
import NotFoundPage from 'pages/NotFound/NotFoundPage';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useCallback } from 'react';
import { fetchPersonalProfile, } from 'store/personalProfile/personalProfileOperations';
import SubmissionsCSV from './pages/SubmissionsCSV/index';
import {HoursTable} from './pages/HoursTable/HoursTable'
import { logout } from 'store/login/loginOperations';
import { getItem } from 'helpers/localStorage';
import ScheduleReport from 'pages/ScheduleWorklogsReport/ScheduleReport';
import { getSettings } from 'store/settings/settingsOperations';

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const authorized = useSelector((store) => store.login.authorized);

  const logOutChecker = useCallback(async (e) =>{
    if(e.key === 'userId' && e.newValue === null) {
      dispatch(logout())
      navigate('/', {replace: true})
    }
    if(e.key === 'authorization-token' && e.newValue === null) {
      navigate('/kiosk', {replace: true})
    }
  },[])

  useEffect(()=>{
    if (getItem('x-admin-kiosk-token') && (getItem('isKioskPage') === '1')){
      navigate('/kiosk', {replace: true})
    }

    if ( authorized ){
      try {
        Promise.resolve(dispatch(fetchPersonalProfile()))
        .then(() => dispatch(getSettings()));
      } catch (error) {}
      window.addEventListener('storage', logOutChecker)
    }
    return () => {
      window.removeEventListener('storage', logOutChecker);
    }
  },[ authorized ])

  return (
    <>
      <Routes>
        <Route index element={<Navigate replace to="/home" />} />

        <Route path="*" element={<NotFoundPage />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />

        <Route path='schedule-report' element={<ScheduleReport/>} />

        <Route path='worklog/print/:sheetId' element={<WorklogPrint/>} />
        <Route path='worklog/print/:sheetId/:isCrew' element={<WorklogPrint/>} />
        <Route path='hours/*' element={<HoursTable/>} />

        <Route path='submissions/:submissionId/csv/:type' element={<SubmissionsCSV/>} />

        <Route path="/reset-password" element={<ResetPassword />} />

        {routes.map((route) => {
          return (
            <Route
              key={route.path}
              path={route.path}
              element={
                <RequireAuth
                  routePermissions={route.permissions}
                  routeRole={route.role}
                  isWithoutHeader={route.isWithoutHeader}
                >
                  {route.children()}
                </RequireAuth>
              }
            />
          );
        })}
      </Routes>
    </>
  );
}

export default App;
