import React, { useCallback, useEffect, useState } from 'react';
import styles from './styles';
import { withStyles } from '@material-ui/core';
import cs from 'classnames';
import escapeStringRegexp from 'escape-string-regexp';
import LoadingSpinner from '../../LoadingSpinner';

const getHighlighted = (source, value, highlightedProps = {}) => {
  return source.split(new RegExp(`(${escapeStringRegexp(value)})`, 'g')).map((el, index) =>
    index % 2 ? (
      <span {...highlightedProps} key={index}>
        {el}
      </span>
    ) : (
      <span key={index}>{el}</span>
    )
  );
};

const SearchInput = ({
  type = 'text',
  options = [],
  value,
  loading,
  classes,
  className,
  onSelect = () => null,
  onFocus = () => null,
  onBlur = () => null,
  onInput = () => null,
  ...props
}) => {
  const [focused, setFocused] = useState(false);
  const [query, setQuery] = useState(value);

  useEffect(() => {
    setQuery(value);
  }, [value]);

  const onInputFocus = useCallback((e) => {
    onFocus(e);
    setFocused(true);
  }, []);
  const onInputBlur = useCallback((e) => {
    setTimeout(() => {
      setFocused(false);
      onBlur(e);
    }, 200);
  }, []);

  const onControlInput = useCallback((e) => {
    onInput(e);
    setQuery(e.target.value);
  }, []);

  return (
    <div className={cs(classes.root, className)}>
      <input
        {...props}
        type={type}
        value={query}
        className={classes.input}
        onInput={onControlInput}
        onChange={onControlInput}
        onFocus={onInputFocus}
        onBlur={onInputBlur}
      />
      {focused ? (
        <div className={classes.options}>
          <div className={classes['options-container']}>
            {options.length
              ? options.map((o) => (
                  <div className={classes.option} key={o.value} onTouchStart={() => onSelect(o)} onClick={() => onSelect(o)}>
                    {getHighlighted(o.title, query, {
                      className: classes.highlighted,
                    })}
                  </div>
                ))
              : null}
            {query && !loading && !options.length ? <div className={classes.emptyOption}>No matches found</div> : null}
            {loading && (
              <div className={classes.loaderContainer}>
                <LoadingSpinner height={32} margin={32} />
              </div>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default withStyles(styles)(SearchInput);
