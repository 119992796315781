export default (theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 0,
    minHeight: 36,
    color: '#A4A5B3',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '21px',
    textAlign: 'center',
    padding: '0 10px 15px 10px',
    '&:hover': {
      background: 'none !important',
      color: '#5A87EF',
    },
    '@media (max-width: 999px)': {
      padding: '0 10px 15px 5px',
    },
    '@media (max-width: 740px)': {
      padding: '0 10px 15px 10px',
    },
  },
  selected: {
    color: '#5A87EF',
  },
});
