import { makeStyles } from '@material-ui/core';
import React from 'react';
import ReactDOM from 'react-dom';

const useStyles = makeStyles((theme) => ({
  divider: {
    width: '1px',
    display: 'block',
    backgroundColor: '#e8e8e8',
    position: 'absolute',
    height: '100%',
    zIndex: 2,
    top: 0,
  },
}));

export const CrewLeaderGrid = ({ crewLeaders }) => {
  const classes = useStyles();
  if (!crewLeaders) return null;

  const el = document.querySelector('.fc-time-grid');
  if (!el) return null;

  return ReactDOM.createPortal(
    <>
      {crewLeaders.map((cl, index) => {
        if (!cl) return null;
        return (
          <div
            key={cl._id}
            className={classes.divider}
            style={{ left: (index + 1) * 203 + 43 }}
          />
        );
      })}
    </>,
    el
  );
};
