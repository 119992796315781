export default (theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
    // marginLeft: 15,
    // marginTop: 15,
  },
  editButton: {
    padding: 0,
    minWidth: 40,
    color: theme.palette.textColor.primary,
    '&:hover': {
      color: theme.palette.textColor.primary,
      background: `${theme.palette.background.default} !important`,
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
      background: `${theme.palette.background.default} !important`,
    },
    '& img': {
      width: 16.4,
    },
  },
});
