export default (theme) => ({
  wrapper: {
    display: 'flex',
    flexGrow: 1,
    // maxWidth: '440px',
    background: '#ffff',
    flexDirection: 'column',
    borderRadius: 10,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexGrow: '1',
    padding: '10px 10px 10px 10px',
  },
  subHeader: {
    padding: '0px 10px',
  },
  listOfWl: {
    maxHeight: 350,
    overflow: 'scroll',
    marginTop: 5,
    paddingBottom: 10,
  },
  deleteButton: {
    filter: 'grayscale(0.9)',
  },
});
