import React, { useMemo, useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@material-ui/core';

import kioskPageActions from 'store/kiosk/kioskPageActions';
import { CloseButton } from 'pages/TimeClock/modals/base';
import { useTimeStates } from 'hooks/useTimeStates';
import { isIOS, Option } from 'components/Option';

const createOption = (option) => (
  <Option value={option.value} key={option.value}>
    {option.label}
  </Option>
);

//todo: add form for this
const CipQuestions = ({ classes, onClose, onCipFormSubmit }) => {
  const dispatch = useDispatch();
  const { hours, minutes } = useTimeStates({
    hourMaxValue: 13,
    hourStart: 0,
    takeSettingsHours: true,
    overlapsHourMaxValue: true,
  });
  const ref = useRef();

  const [allCipProjects, setAllCipProjects] = useState([]);

  const defaultCipValue = useMemo(
    () => ({
      project: '',
      hours: 0,
      minutes: minutes[0],
    }),
    []
  );

  const [cipProjects, setCipProjects] = useState([defaultCipValue]);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    const apiCall = async () => {
      /** types:
       * @returns Array<*cip-projects*>
       */
      try {
        const options = {
          headers: {
            'Content-Type': 'application/json',
          },
        };
        if (localStorage.getItem('x-admin-kiosk-token')) {
          options.headers['x-admin-kiosk-token'] = localStorage.getItem('x-admin-kiosk-token');
        } else {
          options.headers['X-Auth-Token'] = localStorage.getItem('authorization-token');
        }

        const res = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/cip-projects?isKiosk=true&active=true`,
          options
        );
        setAllCipProjects(res.data);
      } catch (e) {
        dispatch(kioskPageActions.setError(e.message));
        dispatch(kioskPageActions.setAlert({ type: 'error', text: e.message }));
      }
    };
    apiCall();
  }, []);

  const onSubmit = () => {
    const err = [];
    cipProjects.map((cip, cipI) => {
      if (cip.project && (cip.hours !== 0 || cip.minutes !== minutes[0])) return;
      err.push(cipI);

      const nextErrors = [...errors];
      nextErrors[cipI] = !cip.project
        ? { project: 'Please, select the project' }
        : { hours: 'Please, choose the time' };

      return setErrors(nextErrors);
    });
    console.log(err);

    if (!err.length) {
      onCipFormSubmit(cipProjects);
    }
  };

  const handleChange = (field, index) => (e) => {
    const { value } = e.target;
    const nextValue = cipProjects.map((p, i) =>
      i === index
        ? {
            ...p,
            [field]: value,
          }
        : p
    );
    setCipProjects(nextValue);
    if (errors[index] && errors[index][field]) {
      setErrors((prev) => prev.map((err, i) => (i === index ? null : err)));
    }
  };

  const removeCip = () => setCipProjects((prev) => prev.filter((_, i) => i !== prev.length - 1));
  const addCip = () => {
    setCipProjects((prev) => [...prev, defaultCipValue]);
    setTimeout(() => ref.current.scrollIntoView(), 0);
  };

  return (
    <>
      <DialogTitle
        disableTypography
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <Typography variant="h6">Clock Out</Typography>
        <CloseButton style={{ top: 16 }} onClick={onClose} />
      </DialogTitle>
      <DialogContent className={classes.cipContainer}>
        {cipProjects.map((cip, i) => (
          <Box display="flex" flexDirection="column">
            <label className={classes.label} htmlFor="project">
              <Typography variant="body1" color="textSecondary">
                Project:
              </Typography>
              <TextField
                select
                id={`project[${i}]`}
                name="project"
                className={classes.textField}
                variant="outlined"
                value={cip.project}
                onChange={handleChange('project', i)}
                error={errors[i] && errors[i].project}
                inputProps={{ 'aria-label': 'Sort by' }}
                placeholder="Select the project"
                fullWidth
                SelectProps={{
                  native: isIOS,
                  value: cip.project,
                }}
              >
                {allCipProjects.map((c) => (
                  <Option key={c._id} value={c._id}>
                    {c.name}
                  </Option>
                ))}
              </TextField>
              {errors[i] && errors[i].project ? (
                <div className={classes.error}>{errors[i].project}</div>
              ) : null}
            </label>
            <Box
              display="flex"
              justifyContent="space-between"
              style={{ position: 'relative', paddingBottom: 15 }}
            >
              <label style={{ width: '48%' }} htmlFor="hours">
                <Typography variant="body1" color="textSecondary">
                  Hours
                </Typography>
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  disabled={!cip.project}
                  value={cip.hours}
                  onChange={handleChange('hours', i)}
                  error={errors[i] && errors[i].hours}
                  className={classes.password}
                  inputProps={{
                    'aria-label': 'Hours',
                  }}
                  SelectProps={{
                    native: isIOS,
                  }}
                >
                  {hours.map((hour) => createOption({ value: hour, label: hour }))}
                </TextField>
                {errors[i] && errors[i].hours ? (
                  <div className={classes.error}>{errors[i].hours}</div>
                ) : null}
              </label>
              <label style={{ width: '48%' }} htmlFor="minutes">
                <Typography variant="body1" color="textSecondary">
                  Minutes
                </Typography>
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  disabled={!cip.project}
                  value={cip.minutes}
                  onChange={handleChange('minutes', i)}
                  error={errors[i] && errors[i].hours}
                  className={classes.minutes}
                  inputProps={{
                    'aria-label': 'Hours',
                  }}
                  SelectProps={{
                    native: isIOS,
                  }}
                >
                  {minutes.map((minute) => createOption({ value: minute, label: minute }))}
                </TextField>
              </label>
            </Box>
          </Box>
        ))}
        <Box display="flex" justifyContent="space-between" ref={ref}>
          <Button
            variant="text"
            className={classes.cipBtn}
            onClick={removeCip}
            disabled={cipProjects.length === 1}
            disableRipple
          >
            - Remove CIP
          </Button>
          <Button variant="text" className={classes.cipBtn} onClick={addCip} disableRipple>
            + Add CIP
          </Button>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onSubmit}
          variant="contained"
          color="primary"
          className={classes.confirmBtn}
          disableTouchRipple
        >
          Submit
        </Button>
      </DialogActions>
    </>
  );
};

export default React.memo(CipQuestions);
