import React, { useCallback, useMemo, useState, useEffect } from 'react';
import Fuse from 'fuse.js';
import FiltersBar from '../FiltersBar';
import MaterialFilters from './components/MaterialFiltersPanel/MaterialFilters';
import ConfirmDialog from '../../../../components/ConfirmDialog';
import MaterialsCard from './components/MaterialForm/components/MaterialCard/MaterialsCard';
import {
  fetchMaterials,
  fetchDeleteMaterial,
  fetchDuplicateMaterial,
  fetchGlassBeads,
} from 'store/supplies/suppliesOperations';

import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import PageLoader from '../../../../components/PageLoader';
import DuplicateModal from './components/DuplicateModal/DuplicateModal';
import suppliesActions from 'store/supplies/suppliesActions';


const Materials = ({ onSelect, setSnackbar }) => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');
  const [drawer, setDrawer] = useState(null);
  const [deletingMaterial, setDeletingMaterial] = useState(null);
  const [fuse, setFuse] = useState(null);

  const searchOptions = {
    shouldSort: true,
    keys: ['name'],
  };

  const { data: dataMaterials, snackBarInfo: materialsSnackbar, loading, filters } = useSelector(
    (state) => (state?.supplies?.materials || {})
  );
  const settings = useSelector((state) => state.personalProfile.organization.settings)
  useMemo(() => {
    setFuse(new Fuse(dataMaterials, searchOptions));
  }, [dataMaterials]);

  const defaultFilters = useMemo(() => ({
    unitType: 'All',
    markingType: 'All',
    sortBy: 'name',
    sortOrder: 'asc',
    measurement: settings?.measurement || 'All',
  }), [settings]);

  const [modal, setModal] = useState({});
  const [idToDuplicate, setIdToDuplicate] = useState(null);

  useEffect(() => {
    setSnackbar(materialsSnackbar);
  }, [materialsSnackbar]);

  const onFilterCancel = useCallback(() => {
    dispatch(suppliesActions.setMaterialFilters(defaultFilters));
    closeDrawer();
  }, []);

  const onUpdateFilter = useCallback((values) => {
    dispatch(suppliesActions.setMaterialFilters(values));
    closeDrawer();
  }, []);

  const onDeletingConfirmed = useCallback(async () => {
    if (!deletingMaterial) {
      console.warn('Deleting material is empty');
      return;
    }

    await dispatch(fetchDeleteMaterial(deletingMaterial._id));
    setSnackbar({
      text: 'Successfully Deleted!',
      type: 'success',
    });
    setDeletingMaterial(null);
  }, [deletingMaterial]);

  const onDelete = useCallback((material) => {
    setDeletingMaterial(material);
  }, []);

  const searchMaterialData = useMemo(() => (
    (fuse && searchValue)
      ? fuse.search(searchValue)
      : dataMaterials
    ),
    [fuse, searchValue, dataMaterials]
  );

  const onDuplicate = (material) => {
    if (material.type !== 'each') {
      setIdToDuplicate(material._id);
      setModal({ open: true, type: 'duplicate' });
    } else {
      dispatch(fetchDuplicateMaterial(material._id));
    }
  };
  
  const duplicate = (data) => {
    dispatch(fetchDuplicateMaterial(idToDuplicate, data));
    setIdToDuplicate(null);
    setModal(prev => ({ ...prev, open: false }));
  }

  const openDrawer = useCallback(
    (type) => () => {
      setDrawer({ isOpen: true, type });
    },
    []
  );
  const closeDrawer = useCallback(() => {
    setDrawer({ ...drawer, isOpen: false });
  }, [drawer]);


  useEffect(() => {
    dispatch(fetchMaterials(filters, false, false));
  }, [filters])

  useEffect(() => {
    dispatch(suppliesActions.setMaterialFilters(defaultFilters)); // This will emit useEffect with fetching materials (see above)
    dispatch(fetchGlassBeads());
  }, []);

  return loading ? (
    <PageLoader loading>
      <div style={{ height: 100 }} />
    </PageLoader>
  ) : (
    <>
      <FiltersBar openDrawer={openDrawer} updateSearchValue={setSearchValue} isDynamicSearch={true}/>
      <Grid container spacing={2}>
        {searchMaterialData &&
          searchMaterialData.map((m) => (
            <Grid item xs={12} md={6} lg={3} key={m._id}>
              <MaterialsCard
                data={m}
                onDelete={onDelete}
                onClick={() => onSelect(m)}
                onDuplicate={onDuplicate}
              />
            </Grid>
          ))}
      </Grid>
      {drawer && (
        <Drawer anchor="right" open={drawer.isOpen}>
          <MaterialFilters
            materials={dataMaterials}
            closeDrawer={closeDrawer}
            onCancel={onFilterCancel}
            filter={filters}
            onUpdate={onUpdateFilter}
          />
        </Drawer>
      )}
      {deletingMaterial && (
        <ConfirmDialog
          isOpen={!!deletingMaterial}
          onClose={() => setDeletingMaterial(null)}
          onSubmit={onDeletingConfirmed}
          text="Are you sure you want to delete this material?"
          cancelBtn={'Cancel'}
          confirmBtn={'Delete'}
          disableEscape
          loadingOnSubmit
        />
      )}
      <DuplicateModal
        isOpen={modal.open}
        onClose={() => {
          setModal((prev) => ({ ...prev, open: false }));
        }}
        onCancel={() => {
          setModal((prev) => ({ ...prev, open: false }));
        }}
        onSave={(convertMeasurements) => {
          duplicate({ convertMeasurements })
        }}
      />
    </>
  );
};
export default Materials;
