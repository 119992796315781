import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import ResetViaEmail from './components/ResetViaEmail/ResetViaEmail';
import ResetViaPhone from './components/ResetViaPhone/ResetViaPhone';
import './styles.scss';
import SnackBar from 'components/SnackBar/SnackBar';
import { useRedirectChecker } from 'hooks/useRedirectChecker';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { redirectChecker } = useRedirectChecker()

  const [selectedType, setSelectedType] = useState(null);
  const [snackbar, setSnackbar] = useState(null);

  useEffect(()=>{
    redirectChecker()
  },[])

  const backToSignIn = () => {
    navigate('/signin', {replace: true})
  };

  const onToggleRestoreMethod = () => {
    setSelectedType((prev) => (prev === 'phone' ? 'email' : 'phone'));
  };

  return (
    <section className={'forgot-pass-wrapper'}>
      <span className={'back-link'} onClick={backToSignIn}>
        {'< Back to Sign In page'}
      </span>
      <div className={'forgot-pass-block'}>
        <h2 className={'forgot-pass-title'}>3208 Technology, Inc.</h2>
        {!selectedType && (
          <div className={'forgot-pass-form'}>
            <p className={'forgot-form-title'}>Forgot password</p>
            <p className={'forgot-form-info'}>Set up new password with:</p>
            <button className={'forgot-btn'} onClick={() => setSelectedType('phone')}>
              Phone number
            </button>
            <button className={'forgot-btn'} onClick={() => setSelectedType('email')}>
              Email
            </button>
          </div>
        )}
        {selectedType === 'phone' ? (
          <ResetViaPhone
            onToggleRestoreMethod={onToggleRestoreMethod}
            selectedType={selectedType}
            setSnackbar={setSnackbar}
          />
        ) : null}
        {selectedType === 'email' ? (
          <ResetViaEmail
            onToggleRestoreMethod={onToggleRestoreMethod}
            selectedType={selectedType}
            setSnackbar={setSnackbar}
          />
        ) : null}
      </div>
      {snackbar && <SnackBar closeSnackbar={() => setSnackbar(null)} {...snackbar} isOpen={true} />}
    </section>
  );
};
export default ForgotPassword;
