import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  modal: {
    display: 'block',
    margin: 0,
    padding: '16px 21px 20px 19px',
  },
  field: {
    display: 'block',
    marginBottom: 10,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  form: {
    marginBottom: '27px',
  },
  CipButton: {
    border: 'none',
    margin: 0,
    padding: 0,
    '&:hover, &:active, &:focus': {
      backgroundColor: 'transparent',
      color: '#5A87EF',
    },
  },
  timeContainer: {
    marginBottom: '27px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  CipButtonText: {
    color: '#5A87EF',
    fontSize: '14px',
    fontWeight: '500',
    letterSpacing: '0',
    lineHeight: '21px',
  },
}));
