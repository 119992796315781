import { createReducer } from '@reduxjs/toolkit';
import personalProfileActions from './personalProfileActions';
import { rolePermissions } from 'common/permissions';

const initialState = {
  personalProfile: {
    user: null,
    organization: null,
    fullUserPermissions: null,
  },
};

const personalProfile = createReducer(initialState.personalProfile, {
  [personalProfileActions.getPersonalProfile]: (state, { payload }) => {
    const userRole = payload.profile.role;
    const permissionsList = {
      ...rolePermissions[userRole.roleName],
      ...userRole.extPermissions,
    };
    return {
      ...state,
      user: payload,
      fullUserPermissions: permissionsList,
      organization: payload.organization,
    };
  },
  [personalProfileActions.updatePersonalProfile]: (state, { payload }) => {
    const userRole = payload.profile.role;
    const permissionsList = {
      ...rolePermissions[userRole.roleName],
      ...userRole.extPermissions,
    };

    return {
      ...state,
      user: payload,
      fullUserPermissions: permissionsList,
    };
  },

  [personalProfileActions.clearPersonalProfile]: (state, _) => {
    return {
      user: null,
      fullUserPermissions: null,
    };
  },
  [personalProfileActions.setSpecialProfilePermissions]: (state,  { payload }) => {
    const userRole = payload?.profile?.role;

    let permissionsList = {};
    if(userRole.extPermissions.remoteKiosk) permissionsList.remoteKiosk = true;
    if(userRole.extPermissions.cipKioskMode) permissionsList.cipKioskMode = true;
    if(userRole.extPermissions.kioskMode) permissionsList.kioskMode = true;

    return {
      ...state,
      user: payload,
      fullUserPermissions: permissionsList,
    };
  },
  [personalProfileActions.setOrganization]: (state, { payload }) => ({
    ...state,
    organization: payload,
  }),
  [personalProfileActions.setOrganizationSettings]: (state, { payload }) => ({
    ...state,
    organization: {
      ...(state?.organization || {}),
      settings: payload,
    },
  }),
});

export default personalProfile;
