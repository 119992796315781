import React from 'react';
import { TableBody, TableRow, TableCell, Checkbox } from '@material-ui/core';
import classnames from 'classnames'


const CustomTableBody = ({ checkboxes, rows }) => {

  const tableRowProps = (isItemSelected, onRowClick) => (
    checkboxes
      ? ({ onClick: onRowClick, role: 'checkbox', 'aria-selected': isItemSelected, selected: isItemSelected })
      : ({})
  )


  const renderCell = (id, cell, index, labelId) => (
    <TableCell
      component="th"
      id={labelId}
      align={cell?.align || 'center'}
      className={classnames('table-body-cell', cell.className || '')}
      onClick={cell.onClick}
      key={`${id}_${index}`}
    >
    <span>
      {cell.children}
    </span>
    </TableCell>
  )

  return (
    <TableBody className={'admin-panel-table-body'}>
      {rows.map(({ onRowClick, isItemSelected, key, cells }, index) => {
        const labelId = `enhanced-table-checkbox-${index}`;

        return (
          <TableRow
            hover
            tabIndex={-1}
            key={key}
            {...tableRowProps(isItemSelected, onRowClick)}
          >
            {checkboxes && (
              <TableCell padding="checkbox">
                <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
              </TableCell>
            )}
            {cells?.length && cells.map((cell, index) => renderCell(key, cell, index, labelId))}
          </TableRow>
        );
      })}
    </TableBody>
  );
}

export default CustomTableBody;