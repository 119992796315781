import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { isSameDay, format } from 'date-fns';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { updateSelectedDate } from 'store/schedule/schedulePageOperation';
import Grid from '@material-ui/core/Grid';

const WeekItem = ({ classes, weekItem, selectedCarouselDate, setSelectedCarouselDate }) => {
  const dispatch = useDispatch();

  const onSelectDate = (date) => {
    dispatch(updateSelectedDate(date));
    setSelectedCarouselDate(date);
  };

  return (
    <div>
      <Grid container style={{ padding: '0 15px' }}>
        <Typography variant="h6" color="textSecondary">
          {format(weekItem[0].date, 'MMMM')}
        </Typography>
      </Grid>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
      >
        {weekItem.map((el) => {
          return (
            <div
              key={el.date}
              className={classnames(
                classes.dayWrapper,
                isSameDay(el.date, selectedCarouselDate) && classes.selectedDay
              )}
              onClick={() => onSelectDate(el.date)}
            >
              <Typography variant="h6" color="textSecondary">
                {el.formatDay}
              </Typography>
              <Typography variant="h5" color="textPrimary">
                {el.dayNum}
              </Typography>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default withStyles(styles)(WeekItem);
