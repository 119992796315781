import React from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { hasPermissionsFor } from '../../../../helpers/_helpers';
import styles from './styles';
import { drawerTypes } from '../../../Equipment/components/helpers/drawerTypes';

const PeopleHeader = ({ classes, openDrawer }) => {
  return (
    <header className={classes.header}>
      <Typography variant="h3">People</Typography>
      <Button
        color="secondary"
        className={classes.addButton}
        onClick={openDrawer(drawerTypes.create, true)}
        disabled={!hasPermissionsFor('usersFullAccess')}
        disableTouchRipple
      >
        + Add Person
      </Button>
    </header>
  );
};

PeopleHeader.propTypes = {
  classes: propTypes.object.isRequired,
  openDrawer: propTypes.func.isRequired,
};

export default withStyles(styles)(PeopleHeader);
