const usPhone = '(###) ###-####';

export const formatNumber = (value, format = usPhone) => {
  let formattedValue = '';
  const valueStack = value.split('').reverse(); // Create a stack of the values
  // iterate over the format
  for (let i = 0; i < format.length && valueStack.length; i++) {
    if (format[i] === '#') {
      formattedValue += valueStack.pop();
    } else {
      formattedValue += format[i];
    }
  }
  return formattedValue;
};

const isNumber = (character) => !!character.match(/\d/);

export const unFormatNumber = (formattedValue) => {
  return formattedValue.split('').filter(isNumber).join('');
};
