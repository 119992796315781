import React, { useCallback, useState, useMemo } from 'react';
import cs from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { materialProperties } from 'api/data/material-properties';
import CloseIcon from 'assets/images/close-icon.svg';
import styles from './styles';
import FileCard from './FileCard';

const MaterialCard = ({ classes, onDelete = () => null, data, ...props }) => {
  const [certificateShowed, setCertificateShowed] = useState(false);
  const onDownload = useCallback(({ url }) => {
    let qp = `?bulkCert=${url}&authToken=${localStorage.getItem('authorization-token')}`;
    try {
      let request = `${process.env.REACT_APP_BASE_URL}/inventory/certs` + qp;
      window.open(request, '_black', 'noreferrer');
    } catch (e) {}
  }, []);

  const materialName = useMemo(() => {
    if (!data) return '[Material Deleted]';
    if (data.material) return data.material.name;
    if (data?.bead?.name){
      if (data?.bead?.deletedAt) {
        return '[Reflective Media Deleted]'
      }
      return `Reflective Media - ${data.bead.name}`
    }
    else if (data.isBead && data.oldMaterialId) {
      const oldMaterialSpec = materialProperties.materials.find((m) => m.id === data.oldMaterialId);
      if (oldMaterialSpec && oldMaterialSpec.name) {
        return oldMaterialSpec.name;
      }
    }
    return '[Material Deleted]';
  }, [data]);

  return (
    <div className={classes.root} {...props}>
      <div className={classes.header}>
        <span className={classes.title}>{materialName}</span>
        <Button
          color="secondary"
          className={classes.deleteButton}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onDelete(data);
          }}
          disableRipple
        >
          <img src={CloseIcon} alt="delete" />
        </Button>
        <hr className={classes.divider} />
      </div>
      <div className={classes.body}>
        <dl className={classes.definitionList}>
          <Grid container spacing={0}>
            <Grid item xs={6} xl={6} md={6} sm={6} lg={6}>
              <dt className={classes.definitionTitle}>Color / Number:</dt>
              <dd className={classes.definitionDefinition}>{data.colorOrNumber || 'N/A'}</dd>
            </Grid>
            <Grid item xs={6} xl={6} md={6} sm={6} lg={6}>
              <dt className={classes.definitionTitle}>Lot number:</dt>
              <dd className={classes.definitionDefinition}>{data.lotNumber || 'N/A'}</dd>
            </Grid>
          </Grid>
          <dt className={classes.definitionTitle}>Manufacturer:</dt>
          <dd className={classes.definitionDefinition}>
            {data.manufacturer ? data.manufacturer.name : 'N/A'}
          </dd>
          <dt className={classes.definitionTitle}>Symbol / Width:</dt>
          <dd className={classes.definitionDefinition}>{data.symbolOrWidth || 'N/A'}</dd>
        </dl>
      </div>
      {data.bulkCert && (
        <>
          <hr className={classes.hr} />
          {certificateShowed && (
            <>
              <FileCard
                file={{ url: data.bulkCert }}
                className={classes.file}
                onDownload={onDownload}
              />
              <hr className={classes.hr} />
            </>
          )}
          <button
            className={classes.certificatesButton}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setCertificateShowed(!certificateShowed);
            }}
          >
            <ExpandMoreIcon
              className={cs(classes.certificatesButtonIcon, {
                [classes.certificatesButtonIconExpanded]: certificateShowed,
              })}
            />
            {certificateShowed ? 'Hide bulk certificate' : 'Show bulk certificate'}
            <ExpandMoreIcon
              className={cs(classes.certificatesButtonIcon, {
                [classes.certificatesButtonIconExpanded]: certificateShowed,
              })}
            />
          </button>
        </>
      )}
    </div>
  );
};

export default withStyles(styles)(MaterialCard);
