import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import propTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import FiltersBar from '../../../../../../components/FiltersBar';
import CalendarTable from '../CalendarTable';
import AssignModal from '../AssignModal';
import { getCurrentMonthDates, splitArrayToArraysOfRow } from '../../../../../../helpers/_helpers';
import {
  getCalendarAllEquipment,
  getEquipmentEvents,
} from 'store/availability/availabilityPagesOperations';
import { globalBEDateFormat } from 'common/dateFormatConfig';
import AlertModal from 'components/AlertModal';
import EditCalendarEventForm from 'components/EditCalendarEventForm/EditCalendarEventForm';
import SplitCalendarEventForm from 'components/EditCalendarEventForm/SplitCalendarEventForm';

const EquipmentCalendar = ({
  selectedDate,
  weekStart,
  passedEquipmentEvents,
  openSnackbar,
}) => {
  const dispatch = useDispatch();
  // const dateFormat = useSelector((state) => state.personalProfile.organization?.settings?.dateFormat);

  const [equipmentEvents, setEquipmentEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [assignModal, setAssignModal] = useState({ isOpen: false, type: 'equipment', event: {} });
  const [drawer, setDrawer] = useState({ isOpen: false, type: 'update', event: {} });
  const [infoModal, setInfoModal] = useState({ isOpen: false, info: [] });


  const updateSearchValue = (value) => {
    setSearchValue(value);
    dispatch(getEquipmentEvents(value));
  };

  const updateVisibleItems = (isNoLoadingRequired) => {
    if (!isNoLoadingRequired) {
      setIsLoading(true);
    }

    const mappedEvents = getCurrentMonthDates(
      selectedDate,
      weekStart,
      globalBEDateFormat
    );

    mappedEvents.forEach((dateObj, i) => {
      passedEquipmentEvents.forEach((event) => {
        if (event.inDays.includes(dateObj.dayNumber)) {
          mappedEvents[i].events.push(event);
        }
      });
    });

    const arrayOfRow = splitArrayToArraysOfRow(mappedEvents);
    setIsLoading(isNoLoadingRequired ? isLoading : false);
    setEquipmentEvents(arrayOfRow);
    setDrawer((prev) => ({ ...prev, isOpen: false }));
  };

  useEffect(() => {
    dispatch(getEquipmentEvents());
    dispatch(getCalendarAllEquipment());
  }, [])

  useEffect(() => {
    updateVisibleItems();
  }, [selectedDate, passedEquipmentEvents, searchValue])


  const openAssignModal = (type, event) => () => {
    setAssignModal({ isOpen: true, type, event });
  };

  const closeAssignModal = useCallback(() => {
    setAssignModal({ ...assignModal, isOpen: false });
  }, [assignModal]);

  const openInfoModal = (info) => {
    setInfoModal({ info, isOpen: true });
  };

  const closeInfoModal = () => {
    setInfoModal({ info: [], isOpen: false });
  };

  const openDrawer = (type, event, options = {}) => {
    // setModal((prev) => ({ ...prev, isOpen: false }));
    setDrawer({ type, event, options, isOpen: true });
  };

  const closeDrawer = useCallback(() => {
    setDrawer({ isOpen: false, type: 'update', event: {} });
  }, [drawer, setDrawer]);


  return (
    <>
      <FiltersBar
        openDrawer={() => null}
        updateSearchValue={updateSearchValue}
        noFilters
        isDynamicSearch={true}
      />
      {/* <PageLoader loading={isLoading}> */}
      <CalendarTable
        equipmentEvents={equipmentEvents}
        selectedDate={selectedDate}
        openSnackbar={openSnackbar}
        openAssignModal={openAssignModal}
        weekStart={weekStart}
        openDrawer={openDrawer}
      />
      {/* </PageLoader> */}

      <Drawer anchor="right" open={drawer.isOpen}>
        {drawer.isOpen && drawer.type === 'update' && (
          <EditCalendarEventForm
            event={drawer.event}
            openSnackbar={openSnackbar}
            closeDrawer={closeDrawer}
          />
        )}
        {drawer.isOpen && drawer.type === 'split' && (
          <SplitCalendarEventForm
            event={drawer.event}
            options={drawer.options}
            openSnackbar={openSnackbar}
            closeDrawer={closeDrawer}
          />
        )}
      </Drawer>
      {/* {drawer.isOpen && drawer.type === 'split' && (
        <SplitCalendarEventForm
          event={drawer.event}
        />
      )} */}
      {assignModal.isOpen && (
        <AssignModal
          type={assignModal.type}
          defaultEventInfo={assignModal.event}
          events={equipmentEvents}
          onClose={closeAssignModal}
          openSnackbar={openSnackbar}
          openInfoModal={openInfoModal}
        />
      )}
      {infoModal.isOpen && (
        <AlertModal
          isOpen={infoModal.isOpen}
          info={infoModal.info}
          onClose={closeInfoModal}
        />
      )}
    </>
  );
}

EquipmentCalendar.propTypes = {
  openSnackbar: propTypes.func.isRequired,
  selectedDate: propTypes.object.isRequired,
  passedEquipmentEvents: propTypes.array.isRequired,
};

export default EquipmentCalendar;
