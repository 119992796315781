export default (theme) => ({
  root: {
    height: '100vh',
    width: '100vw',
    overflow: 'hidden',
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 1500,
  },
  progressWrapper: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 2000,
    background: 'rgba(255, 255, 255, 0.75)',
  },
  progress: {
    position: 'absolute',
    top: '40%',
    left: 'calc(50% - 12px)',
  },
});
