import React from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { Formik, Form } from 'formik';

import styles from './styles';

const FiltersBar = ({ classes, updateSearchValue, setDoWeShowSearch, noFilters }) => {

  return (
    <Formik
      initialValues={{ searchValue: '' }}
      onSubmit={(values) => {
        updateSearchValue(values.searchValue);
        setDoWeShowSearch(true);
      }}
    >
      {({ values, handleChange, isSubmitting }) => (
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
          style={{ paddingBottom: '10px' }}
        >
          <Grid item xs={noFilters ? 12 : 8} md={noFilters ? 12 : 9} xl={noFilters ? 12 : 'auto'}>
            <Form autoComplete="off" className={classes.formFlex}>
              <div className={classes.search}>
                <TextField
                  type="text"
                  name="searchValue"
                  variant="outlined"
                  placeholder="Search"
                  value={values.searchValue}
                  onChange={handleChange}
                  onFocus={() => setDoWeShowSearch(true)}
                  onKeyUp={() => updateSearchValue(values.searchValue)}
                  onClick={() => updateSearchValue(values.searchValue)}
                  onInput={() => setDoWeShowSearch(true)}
                  className={classes.search}
                  inputProps={{ 'aria-label': 'Search' }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <div className={classes.searchIcon}>
                          <SearchIcon />
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </Form>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
};

FiltersBar.propTypes = {
  classes: propTypes.object.isRequired,
  updateSearchValue: propTypes.func.isRequired,
  noFilters: propTypes.bool,
};

export default withStyles(styles)(FiltersBar);
