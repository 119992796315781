import React, {useEffect} from 'react';
import WorkerSchedulePage from './WorkerSchedulePage';
import { getWorkers } from 'store/schedule/schedulePageOperation';
import { useDispatch } from 'react-redux';
import { EQUIPMENT_EVENT, WORKER_EVENT, SHEET_EVENT } from 'store/schedule/subscriptions';
import workerSchedulePageActions from 'store/workerSchedule/workerSchedulePageActions';

const WorkerScheduleWrapper = ({ loading }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getWorkers()); //todo if canceled need a users
    dispatch(workerSchedulePageActions.socketConnect(SHEET_EVENT));
    dispatch(workerSchedulePageActions.socketConnect(EQUIPMENT_EVENT));
    dispatch(workerSchedulePageActions.socketConnect(WORKER_EVENT));
    return () => {
      dispatch(workerSchedulePageActions.socketDisconnect(SHEET_EVENT));
      dispatch(workerSchedulePageActions.socketDisconnect(EQUIPMENT_EVENT));
      dispatch(workerSchedulePageActions.socketDisconnect(WORKER_EVENT));
    };
  }, []);

  return (
    <div>
      <WorkerSchedulePage />
    </div>
  );
};

export default WorkerScheduleWrapper;
