import React, { useState, useEffect, createRef, forwardRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import styles from './styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

export const ImgPreview = forwardRef(
  (
    { file, classes, desktopCroppedImg, mobileCroppedImg, getCropForDesktop, getCropForMobile },
    ref
  ) => {
    const [image, setImage] = useState(file || '');

    useEffect(() => {
      if (!file) return;
      const reader = new FileReader();

      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }, [file]);

    return (
      <div>
        <div className={classes.mainCropperWrapper}>
          {!desktopCroppedImg && (
            <>
              <Cropper
                ref={ref.cropperRef}
                style={{ height: 400, width: '100%' }}
                initialAspectRatio={220 / 70}
                preview=".img-preview"
                src={image}
                dragMode={'none'}
                cropBoxResizable={false}
                guides={false}
                viewMode={1} //1-2top
              />
            </>
          )}
          {desktopCroppedImg && !mobileCroppedImg && (
            <>
              <Cropper
                ref={ref.cropperRef2}
                style={{ height: 400, width: '100%' }}
                initialAspectRatio={1}
                preview=".img-preview"
                src={image}
                dragMode={'none'}
                cropBoxResizable={false}
                guides={false}
                viewMode={1} //1-2top
                // autoCropArea={1}
              />
            </>
          )}
        </div>

        <div>
          <div className={classes.resultImgWrapper}>
            {desktopCroppedImg && mobileCroppedImg && (
              <>
                <div className={classes.positionerWrapper}>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    className={classes.cropperTitle}
                  >
                    Desktop Picture:
                  </Typography>
                  <div className={classes.imgWrapper} style={{ width: '300px', height: '95px' }}>
                    <img
                      className={classes.wlImg}
                      src={URL.createObjectURL(desktopCroppedImg)}
                      alt="cropped"
                    />
                  </div>
                </div>
                <div className={classes.positionerWrapper}>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    className={classes.cropperTitle}
                  >
                    Mobile Picture:
                  </Typography>
                  <div className={classes.imgWrapper} style={{ width: '200px', height: '200px' }}>
                    <img
                      className={classes.wlImg}
                      src={URL.createObjectURL(mobileCroppedImg)}
                      alt="cropped"
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
);

const PreviewModal = ({ classes, isOpen, onSave, onClose, onCancel, file }) => {
  const [desktopCroppedImg, setDesktopCroppedImg] = useState('');
  const [mobileCroppedImg, setMobileCroppedImg] = useState('');

  const cropperRef = createRef();
  const cropperRef2 = createRef();

  useEffect(() => {
    return () => {
      setDesktopCroppedImg('');
      setMobileCroppedImg('');
    };
  }, []);

  const getCropForDesktop = () => {
    if (typeof cropperRef.current?.cropper == 'undefined') return;
    const cropper = cropperRef.current.cropper;
    cropper.getCroppedCanvas().toBlob((blob) => {
      const file = new File([blob], 'desktopCroppedImage.jpg', { type: 'image/jpeg' });
      setDesktopCroppedImg(file);
    }, 'image/jpeg');
  };

  const getCropForMobile = () => {
    if (typeof cropperRef2.current?.cropper == 'undefined') return;
    const cropper = cropperRef2.current.cropper;
    cropper.getCroppedCanvas().toBlob((blob) => {
      const file = new File([blob], 'desktopMobileImage.jpg', { type: 'image/jpeg' });
      setMobileCroppedImg(file);
    }, 'image/jpeg');
  };

  const getModalTitle = () => {
    if (desktopCroppedImg && mobileCroppedImg) return 'Pictures Preview';
    if (!desktopCroppedImg) return 'Desktop Crop Area';
    if (desktopCroppedImg) return 'Mobile Crop Area';
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose ? onClose : onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        classes: {
          root: classes.dialogRoot,
        },
      }}
    >
      <IconButton
        className={classes.closeButton}
        onClick={onClose ? onClose : onCancel}
        aria-label="Close"
        disableTouchRipple
      >
        <CloseIcon />
      </IconButton>
      <Typography className={classes.title}>{getModalTitle()}</Typography>
      <DialogContent>
        {(!desktopCroppedImg || !mobileCroppedImg) && (
          <Typography className={classes.cropperTitle} variant="h5">
            Zoom in/out and adjust the area to crop
          </Typography>
        )}
        {file && (
          <ImgPreview
            file={file}
            ref={{ cropperRef, cropperRef2 }}
            classes={classes}
            desktopCroppedImg={desktopCroppedImg}
            mobileCroppedImg={mobileCroppedImg}
            getCropForDesktop={getCropForDesktop}
            getCropForMobile={getCropForMobile}
          />
        )}
      </DialogContent>

      <DialogActions style={{ justifyContent: 'space-around' }}>
        <Button
          color="secondary"
          variant="outlined"
          className={classes.cancelButton}
          onClick={onCancel}
          disableTouchRipple
        >
          Cancel
        </Button>
        <Button
          type="submit"
          color="primary"
          variant="outlined"
          className={classes.saveButton}
          onClick={() => {
            return !desktopCroppedImg || !mobileCroppedImg
              ? !desktopCroppedImg
                ? getCropForDesktop()
                : getCropForMobile()
              : onSave({
                  desktop: desktopCroppedImg,
                  mobile: mobileCroppedImg,
                });
          }}
          disableTouchRipple
        >
          {!desktopCroppedImg || !mobileCroppedImg ? 'Crop' : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(PreviewModal);
