export default theme => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  regularFontWeight: {
    fontWeight: 300
  },
  paddingBottom: {
    paddingBottom: 8
  },
  editButton: {
    position: 'absolute',
    right: 16,
    padding: 4,
    minWidth: 0,
    '&:hover': {
      background: `${theme.palette.background.transparent} !important`
    },
    '&:focus': {
      background: `${theme.palette.background.transparent} !important`
    }
  }
});
