export default (theme) => ({
  root: {
    padding: '10px 12px 8px',
    border: '1px solid #d9dae3',
    borderRadius: 5,
  },
  header: {
    position: 'relative',
  },
  divider: {
    margin: '14px 0 0',
    border: '1px solid #DBDCE4 none none none',
  },
  title: {
    color: '#202020',
    fontWeight: 500,
    width: 'calc(100% - 30px)',
    fontSize: '0.95rem',
    lineHeight: 1.6,
    display: 'block',
  },
  number: {
    marginTop: 8,
    display: 'block',
    fontWeight: 500,
    fontSize: '0.8rem',
    lineHeight: 1.2,
    color: '#a4a5b3',
  },
  deleteButton: {
    position: 'absolute',
    minWidth: 24,
    width: 24,
    height: 24,
    right: 0,
    top: 0,
    padding: 5,
  },
  body: {},
  definitionList: {
    margin: '0 0 2px',
  },
  definitionTitle: {
    color: '#A4A5B6',
    fontSize: 13,
    fontFamily: 'Open Sans',
    fontWeight: 400,
    margin: '14px 0 0',
    letterSpacing: 0.16,
    lineHeight: '18px',
  },
  definitionDefinition: {
    margin: '6px 0 0',
    color: '#202020',
    fontSize: 13,
    fontWeight: 500,
    letterSpacing: 0.16,
    lineHeight: '20px',
  },
  hr: {
    margin: '14px -12px 0',
    border: '1px solid #DBDCE4 none none none',
  },
  certificatesButton: {
    cursor: 'hover',
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'calc(100% + 24px)',
    height: '40px',
    margin: '0 -12px -8px',
    textAlign: 'center',
    background: 'none',
    border: 'none',
    outline: 'none',
    color: '#A4A5B3',
    fontSize: 12,
    lineHeight: '18px',
    '&:hover': {
      background: 'none',
      color: '#A4A5B3',
    },
    '&:focus': {
      background: 'none',
      color: '#A4A5B3',
    },
  },
  certificatesButtonIcon: {
    margin: '0 10px',
  },
  certificatesButtonIconExpanded: {
    transform: 'rotate(180deg)',
  },
  file: {
    marginTop: 14,
  },
});
