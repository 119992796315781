import { InputBase, withStyles } from '@material-ui/core';

export default withStyles({
  input: {
    boxSizing: 'border-box',
    height: 'auto !important',
    border: '1px solid #D9DAE3 !important',
    borderRadius: '4px !important',
    backgroundColor: '#FFFFFF !important',
    padding: '0.4rem !important',
    color: '#202020 !important',
    fontSize: '14px !important',
    fontWeight: 400,
    letterSpacing: 0,
    boxShadow: 'none !important',
    '&:focus, &:active': {
      border: '1px solid #D9DAE3 !important',
      borderColor: '#5A87EF !important',
    },
  },
})(InputBase);
