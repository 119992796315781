import { createAction } from '@reduxjs/toolkit';

const createProjectType = createAction('projectTypes/createProjectType');
const getProjectTypes = createAction('projectTypes/getProjectTypes');
const deleteProjectType = createAction('projectTypes/deleteProjectType');
const updateProjectTypes = createAction('projectTypes/updateProjectTypes');
const snackBarInfo = createAction('projectTypes/snackBarInfo');

const projectsRequest = createAction('projectTypes/projectsRequest');
const getProjectsSuccess = createAction('projectTypes/getProjectsSuccess');
const getProjectsSuccessAdd = createAction('projectTypes/getProjectsSuccessAdd');
const toggleProjectStatus = createAction('projectTypes/toggleProjectStatus');
const getProjectsError = createAction('projectTypes/getProjectsError');
const deleteProject = createAction('projectTypes/deleteProject');
const createNewProject = createAction('projectTypes/createNewProject');
const updateProject = createAction('projectTypes/updateProject');
const resetProjects = createAction('projectTypes/resetProjects');

export default {
  createProjectType,
  getProjectTypes,
  deleteProjectType,
  updateProjectTypes,
  snackBarInfo,

  projectsRequest,
  getProjectsSuccess,
  getProjectsSuccessAdd,
  getProjectsError,
  toggleProjectStatus,
  deleteProject,
  createNewProject,
  updateProject,
  resetProjects,
};
