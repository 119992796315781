export default theme => ({
  card: {
    paddingTop: 10,
    position: 'relative',
    overflow: 'visible',
    [theme.breakpoints.only('xl')]: {
      width: 237,
    },
  },
  colorLine: {
    position: 'absolute',
    top: '-1px',
    left: '-1px',
    width: 'calc(100% + 2px)',
    height: 6,
    borderRadius: '5px 5px 0 0'
  },
  regularFontWeight: {
    fontWeight: 300,
  },
  paddingBox: {
    padding: '0 8px 8px',
  },
  paddingTop: {
    paddingTop: 8
  },
  paddingBottom: {
    paddingBottom: 8
  },
  paddingBottomSmall: {
    paddingBottom: 4
  },
  inlineFlex: {
    display: 'inline-flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  flexHeader: {
    alignItems: 'flex-start'
  },
  fixedWidth: {
    width: 'calc(100% - 30px)'
  },
  deleteButton: {
    minWidth: 24,
    height: 24,
    padding: 5,
    '&:hover': {
      background: `${theme.palette.background.default}!important`
    },
    '&:focus': {
      background: `${theme.palette.background.default}!important`
    },
  },
  expand: {
    transform: 'rotate(0deg)',
    padding: 0,
    '&:hover': {
      color: theme.palette.textColor.secondary,
      backgroundColor: '#fff',
    },
    '&:focus': {
      color: theme.palette.textColor.secondary,
      backgroundColor: '#fff',
    },
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  borderTop: {
    borderTop: `1px solid ${theme.palette.borderColor.primary}`,
  },
  actionsWrapper: {
    borderTop: `1px solid ${theme.palette.borderColor.primary}`,
    justifyContent: 'space-around',
    padding: 5,
    [theme.breakpoints.down('md')]: {
      paddingBottom: 0,
    },
  },
  description: {
    overflow: 'hidden',
    wordBreak: 'break-word',
  }
});
