import React, { useEffect, useState } from 'react';
import { Box, IconButton, Typography, Button } from '@material-ui/core';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import StopRoundedIcon from '@material-ui/icons/StopRounded';
import { makeStyles } from '@material-ui/core/styles';

import { apiCalls } from '../../apiCalls';
import { styles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { getSummary } from 'store/kiosk/kioskPageOperations';
import ClockOutModal from '../ClockOutModal';
import { configureTimeForTimer } from 'helpers/configureTimeForTimer';
import { format } from 'date-fns';
import kioskPageActions from 'store/kiosk/kioskPageActions';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';

const useStyles = makeStyles(styles);

const ClockInBlock = ({
  visible,
  userId,
  openSnackbar,
  logout,
  setError,
  showExitButton,
  date,
  shouldUpdate,
  settings,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const getTimeFormatHook = useTimeSettingsFormat();
  const formatForTimePars = getTimeFormatHook().formatForTimePars;

  const defaultTime = '00:00:00';
  const [time, setTime] = useState(defaultTime);

  const [clockedIn, setClockedIn] = useState(null);
  const { oldClockIn, summary } = useSelector((state) => ({
    oldClockIn: state.kiosk.clockIn,
    summary: state.kiosk.summary,
  }));

  const [clockOutModalVisible, setClockOutModalVisible] = useState(false);
  const closeClockOutModal = () => setClockOutModalVisible(false);

  const handleReturnToKiosk = (shouldLogout = true) => {
    setTime(defaultTime);
    setClockedIn(null);
    closeClockOutModal();
    shouldLogout && logout();
  };

  const handleClockOut = () => {
    if (shouldUpdate) dispatch(getSummary({ date, userId }));
    dispatch(kioskPageActions.setClockInStatus(null));
    return handleReturnToKiosk(showExitButton);
  };

  const toggleClockInOut = () => {
    if (clockedIn) {
      return setClockOutModalVisible(true);
    }

    const apiCall = async () => {
      try {
        const response = await apiCalls.clockIn();
        setClockedIn(clockedIn || showExitButton ? null : response.data);
      } catch (e) {
        openSnackbar('error', e?.response?.data?.message || e.message);
        setError(e?.response?.data?.message || e.message);
      }
    };
    openSnackbar('success', `Clocked in at ${format(new Date(), formatForTimePars)}`);

    apiCall().then(() => {
      if (showExitButton) return handleReturnToKiosk();
      if (shouldUpdate) dispatch(getSummary({ date, userId }));
    });
  };

  useEffect(() => {
    if (!summary) {
      handleReturnToKiosk();
    }
  }, [summary]);

  useEffect(() => {
    if (oldClockIn) setClockedIn(oldClockIn);
  }, [oldClockIn]);

  useEffect(() => {
    if (!clockedIn) {
      return setTime(defaultTime);
    }
    const nextInterval = setInterval(() => {
      setTime(configureTimeForTimer(clockedIn.inTime));
    }, 1000);

    return () => clearInterval(nextInterval);
  }, [clockedIn]);

  return (
    <Box className={classes.timeBlockWrapper}>
      {visible && (
        <>
          <Box
            className={classes.timeBlockContainer}
            display="flex"
            alignItems="center"
            style={{ width: '240px' }}
          >
            <IconButton
              onClick={userId ? toggleClockInOut : () => null}
              disabled={!userId}
              style={{ padding: '0', marginRight: '16px' }}
            >
              {clockedIn && summary ? (
                <div className={classes.stopContainer}>
                  <div className={classes.stopContainer2}>
                    <StopRoundedIcon className={classes.stopIcon} />
                  </div>
                </div>
              ) : (
                <PlayCircleFilledWhiteIcon className={classes.startIcon} color="primary" />
              )}
            </IconButton>
            <Typography variant="h2" className={classes.time}>
              {summary ? time : defaultTime}
            </Typography>
          </Box>
          {showExitButton && (
            <Button
              variant="outlined"
              color="primary"
              className={classes.logoutButton}
              onClick={handleReturnToKiosk}
              disabled={!userId}
            >
              Return to Kiosk
            </Button>
          )}
          {clockOutModalVisible && (
            <ClockOutModal
              userId={userId}
              openSnackbar={openSnackbar}
              handleReturnToKiosk={handleReturnToKiosk}
              onClose={closeClockOutModal}
              clockedIn={clockedIn}
              onSuccess={handleClockOut}
              settings={settings}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default ClockInBlock;
