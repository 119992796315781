export default (theme) => ({
  paddingVertical: {
    padding: '16px 0 20px',
  },
  loader: {
    fontSize: 14,
    paddingBottom: 10,
  },
  noResultsBlock: {
    padding: '1.72em 0 2em',
    opacity: 0.5,
    background: '#fff',
  },
});
