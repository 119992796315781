import { createAction } from '@reduxjs/toolkit';

const getUsersPeoplePageData = createAction('people/getUsersPeoplePageData');
const addMorePeoplePageData = createAction('people/addMorePeoplePageData');
const postUserPeoplePage = createAction('people/postUserPeoplePage');
const patchUserPeoplePage = createAction('people/patchUserPeoplePage');
const deleteUserPeoplePage = createAction('people/deleteUserPeoplePage');
const getSettingsPeoplePage = createAction('people/getSettingsPeoplePage');
const getCurrentUserPeoplePage = createAction('people/getCurrentUserPeoplePage');

export default {
  getUsersPeoplePageData,
  addMorePeoplePageData,
  postUserPeoplePage,
  patchUserPeoplePage,
  deleteUserPeoplePage,
  getSettingsPeoplePage,
  getCurrentUserPeoplePage,
};
