import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './../../styles';
import PicCard from '../PicCard/PicCard';
import { Masonry } from 'masonic';

const PicCardList = ({ classes, imgData, handleDeleteNoteImg, handleEditNoteImg, sheetId }) => {
  const originalArray = imgData;

  const { evenArray, oddArray } = originalArray.reduce(
    (acc, current, index) => {
      if (index % 2 === 0) {
        acc.evenArray.push(current);
      } else {
        acc.oddArray.push(current);
      }
      return acc;
    },
    { evenArray: [], oddArray: [] }
  );

  return (
    // <div className={classes.picListWrapper}>
    //   <Masonry
    //     // Provides the data for our grid items
    //     key={imgData}
    //     items={imgData}
    //     // Adds 8px of space between the grid cells
    //     columnGutter={19} //19
    //     // Sets the minimum column width to 172px
    //     columnWidth={230} //230
    //     // Pre-renders 5 windows worth of content
    //     overscanBy={5} //15
    //     // This is the grid item component
    //     itemKey={(data) => {
    //       return data._id;
    //     }}
    //     render={({ data, index }) => {
    //       return (
    //         <PicCard
    //           itemKey={data._id}
    //           key={data._id}
    //           data={data}
    //           i={index}
    //           onDelete={handleDeleteNoteImg}
    //           onEdit={handleEditNoteImg}
    //           sheetId={sheetId}
    //         />
    //       );
    //     }}
    //   />
    // </div>
    <div className={classes.picListWrapperNative}>
      <div className={classes.picListWrapperColumn}>
        {evenArray.map((data, index) => {
          return (
            <PicCard
              itemKey={data._id}
              key={data._id}
              data={data}
              i={index}
              onDelete={handleDeleteNoteImg}
              onEdit={handleEditNoteImg}
              sheetId={sheetId}
            />
          );
        })}
      </div>
      <div className={classes.picListWrapperColumn}>
        {oddArray.map((data, index) => {
          return (
            <PicCard
              itemKey={data._id}
              key={data._id}
              data={data}
              i={index}
              onDelete={handleDeleteNoteImg}
              onEdit={handleEditNoteImg}
              sheetId={sheetId}
            />
          );
        })}
      </div>
    </div>
  );
};

export default withStyles(styles)(PicCardList);
