import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import classNames from 'classnames';
import { hasPermissionsFor, checkUserRole } from 'helpers/_helpers';
import photoPlaceholder from 'assets/images/user-photo-placeholder.svg';
import EditIcon from 'assets/images/edit-icon.svg';
import CloseI from 'assets/images/close-icon.svg';
import styles from './styles';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUsers } from 'store/people/peoplePageOperations';
import $api from 'http/index';
import { getOptions } from 'helpers/getOptions';
import { format, isValid } from 'date-fns';
import { GLOBAL_DATE_FORMAT } from 'common/dateFormatConfig';
import { useCanEditWorklog } from 'common/hooks/use-can-edit-worklog';

const WorklogNoteEditingForm = ({
  addComment,
  classes,
  closeDrawer,
  note,
  handleComment,
  handleNote,
  openSnackbar,
}) => {
  const dispatch = useDispatch();

  const { projects, projectId, project: projectById } = useSelector((store) => store.notes);
  const users = useSelector((store) => store.people.users);
  const user = useSelector((store) => store.personalProfile.user) || {};

  const [project, setProject] = useState({});
  const [comment, setComment] = useState('');

  useEffect(() => {
    dispatch(fetchUsers({}, 0, Infinity));
  }, []);

  useEffect(() => {
    setProject(projectById || {});
  }, [projects]);

  const formatDate = (date) =>{
    if(!isValid(date)) {
      return ''
    }
    return format(new Date(date), GLOBAL_DATE_FORMAT.fullDate)};

  const handleChange = (e) => setComment(e.target.value);

  const handleSubmit = async () => {
    if (!comment.trim()) {
      return;
    } else {
      try {
        const res = await $api.post(
          `${process.env.REACT_APP_BASE_URL}/sheets/${note._id}/note-comments
        `,
          { text: comment },
          getOptions()
        );
        addComment(note._id, res.data);
        setComment('');
        openSnackbar('success', 'The Comment was added successfully');
      } catch (e) {
        openSnackbar('error', e?.response?.data?.message || e.reason);
      }
    }
  };

  const getUsername = (userId) => {
    const user = users.find((el) => el._id === userId);
    return user?.profile?.fullName || user?.username;
  };

  const canEditWorklog = useCanEditWorklog(note);

  const canEditInternalNotes = () => checkUserRole('Super Admin') || checkUserRole('Admin') || checkUserRole('Dispatcher');

  const canEditComment = (ownerId) => {
    const currentUserId = user._id;
    const canEditByRole = checkUserRole('Super Admin') || checkUserRole('Admin') || checkUserRole('Dispatcher');
    const isOwnComment = ownerId === currentUserId;
    return isOwnComment || canEditByRole;
  };

  return (
    <div className={classes.formWrapper} role="presentation">
      <div className={classes.formFlex}>
        <div>
          <Typography variant="h3" style={{ marginBottom: '10px', paddingRight: '45px' }}>
            <span className={classes.marginRightBig}>
              {project.route + (project.section ? ', Section ' + project.section : '')}
            </span>
            {project.jobNumber && (
              <span className={classes.headerSecondText}>{'#' + project.jobNumber}</span>
            )}
          </Typography>

          <IconButton
            className={classes.closeButton}
            onClick={closeDrawer}
            aria-label="Close"
            disableTouchRipple
          >
            <CloseIcon />
          </IconButton>
        </div>
        <Typography variant="body1" color="textSecondary" className={classes.marginBottomBig}>
          {formatDate(note.hours?.start)}
        </Typography>

        <Divider />

        {note.notes ? (
          <>
            <div
              className={classNames(classes.zeroPadding, classes.paddingBottom, classes.flexBox)}
            >
              <Typography variant="body1" color="textSecondary" className={classes.paddingSmall}>
                Note:
              </Typography>
              <div>
                <Button
                  color="secondary"
                  className={classes.deleteButton}
                  onClick={handleNote(note._id, 'notes', note.notes, false)}
                  disableRipple
                >
                  <img src={EditIcon} alt="edit" />
                </Button>
                <Button
                  color="secondary"
                  className={classNames(classes.deleteIcon, classes.deleteButton)}
                  onClick={handleNote(note._id, 'notes', '', true, true)}
                  disableTouchRipple
                >
                  <img src={CloseI} alt="delete" />
                </Button>
              </div>
            </div>
            <Typography variant="body1" color="textPrimary" className={classNames(classes.marginBottomBig, classes.wordBreak)}>
              {note.notes}
            </Typography>
            <Typography variant="h6" color="textPrimary" className={classes.marginBottomSmall}>
              Comments ({note.noteComments ? note.noteComments.length : 0})
            </Typography>
            {canEditWorklog && (
              <div className={classes.marginBottomNormal}>
                <TextField
                  fullWidth
                  type="text"
                  name="comment"
                  variant="outlined"
                  placeholder="Add comment"
                  value={comment}
                  onChange={handleChange}
                  className={classes.search}
                  inputProps={{ 'aria-label': 'Comment' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          color="primary"
                          className={classes.searchBtn}
                          onClick={handleSubmit}
                          disableRipple
                        >
                          Send
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            )}

            {note.noteComments
              ? note.noteComments.map((comment) => (
                  <div key={comment._id}>
                    <div className={classes.flexBox}>
                      <div className={classes.flex}>
                        <div className={classes.userPhotoWrapper}>
                          <img
                            src={photoPlaceholder}
                            alt="user photo"
                            className={classes.userPhoto}
                          />
                        </div>
                        <Typography variant="body1" color="textPrimary">
                            {comment.fullName}
                          <span className={classes.headerSecondText}>
                            {formatDate(comment.createdAt)}
                          </span>
                        </Typography>
                      </div>
                      {canEditComment(comment.createdBy) && (
                        <div>
                          <Button
                            color="secondary"
                            className={classes.deleteButton}
                            onClick={handleComment(note._id, comment._id, comment.text)}
                            disableRipple
                          >
                            <img src={EditIcon} alt="edit" />
                          </Button>
                          <Button
                            color="secondary"
                            className={classNames(classes.deleteIcon, classes.deleteButton)}
                            onClick={handleComment(note._id, comment._id, '')}
                            disableRipple
                          >
                            <img src={CloseI} alt="delete" />
                          </Button>
                        </div>
                      )}
                    </div>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      className={classes.marginBottomNormal}
                      style={{ fontFamily: 'Open Sans', fontSize: '0.875rem', wordBreak:'break-word' }}
                    >
                      {comment.text}
                    </Typography>
                  </div>
                ))
              : null}
          </>
        ) : null}

        {note.schedulerNotes ? (
          <>
            {note.notes ? (
              <div className={classes.noteDividerPadding}>
                <Divider />
              </div>
            ) : null}
            <div
              className={classNames(classes.zeroPadding, classes.paddingBottom, classes.flexBox)}
            >
              <Typography variant="body1" color="textSecondary" className={classes.paddingSmall}>
                Internal Note:
              </Typography>
              {canEditInternalNotes() && (
                <div>
                  <Button
                    color="secondary"
                    className={classes.deleteButton}
                    onClick={handleNote(note._id, 'schedulerNotes', note.schedulerNotes, false)}
                    disableRipple
                  >
                    <img src={EditIcon} alt="edit" />
                  </Button>
                  <Button
                    color="secondary"
                    className={classNames(classes.deleteIcon, classes.deleteButton)}
                    onClick={handleNote(note._id, 'schedulerNotes', '', false)}
                    disableRipple
                  >
                    <img src={CloseI} alt="delete" />
                  </Button>
                </div>
              )}
            </div>
            <Typography variant="body1" color="textPrimary" className={classNames(classes.marginBottomNormal, classes.wordBreak)}>
              {note.schedulerNotes}
            </Typography>
          </>
        ) : null}
      </div>
    </div>
  );
};

WorklogNoteEditingForm.propTypes = {
  classes: propTypes.object.isRequired,
  closeDrawer: propTypes.func.isRequired,
  note: propTypes.object.isRequired,
  handleComment: propTypes.func.isRequired,
  handleNote: propTypes.func.isRequired,
  openSnackbar: propTypes.func.isRequired,
};

export default withStyles(styles)(WorklogNoteEditingForm);
