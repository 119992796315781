import React, { Component } from 'react';
import propTypes from 'prop-types';
import Picker from 'react-month-picker'
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import 'react-month-picker/css/month-picker.css'
import './styles.css';
import { getMonth, getYear, setMonth, setYear, addMonths, isSameMonth } from 'date-fns';

class MonthPicker extends Component {
  state = {
    pickerLang: {
      months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      from: 'From',
      to: 'To',
      fullMonthNames: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ]
    },
    mvalue: {
      year: getYear(new Date(this.props.selectedDate)),
      month: getMonth(new Date(this.props.selectedDate)) + 1
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (!isSameMonth(prevProps.selectedDate, this.props.selectedDate)) {
      this.handleAMonthChange( getYear(this.props.selectedDate), getMonth(this.props.selectedDate) + 1)
    }
    const condition1 = prevState.mvalue.year !== this.state.mvalue.year;
    const condition2 = prevState.mvalue.month !== this.state.mvalue.month;
    if (condition1 || condition2) {
      const updatedDate = setYear((setMonth(new Date(), this.state.mvalue.month - 1)), this.state.mvalue.year);
      this.props.updateDate(updatedDate);
    }
  }

  makeText = () => {
    const { year, month } = this.state.mvalue;
    if (year && month) {
      return (this.state.pickerLang.fullMonthNames[month-1] + ' ' + year)
    };
    return '?'
  }

  handleAMonthChange = (value, text) => this.setState({ mvalue: { year: value, month: text } });

  handleAMonthDissmis = value => this.setState({ mvalue: value });

  handleClickMonthBox = () => this.refs.pickAMonth.show();

  changeMonth = diff => () => {
    let currentDate = setYear((setMonth(new Date(), this.state.mvalue.month - 1)), this.state.mvalue.year);
    currentDate =  addMonths( currentDate, diff);
    this.setState({
      mvalue: {
        year: getYear(currentDate),
        month: getMonth(currentDate) + 1
      }
    })
  }

  render() {
    const { pickerLang, mvalue } = this.state;
    const minYear = mvalue.year - 10;
    const maxYear = mvalue.year + 10;

    return (
      <div className="inlineBlock">
        {/* <ArrowLeftIcon
          className="changeMonthIcon leftArrow"
          onClick={this.changeMonth(-1)}
        />
        <ArrowRightIcon
          className="changeMonthIcon rightArrow"
          onClick={this.changeMonth(1)}
        /> */}
        <div className="edit">
          <Picker
            ref="pickAMonth"
            years={{min: minYear, max: maxYear}}
            value={mvalue}
            lang={pickerLang.months}
            onChange={this.handleAMonthChange}
            onDismiss={this.handleAMonthDissmis}
            >
            <MonthBox value={this.makeText()} onClick={this.handleClickMonthBox} />
          </Picker>
        </div>
      </div>
    );
  }
}

export default MonthPicker;


class MonthBox extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
        value: this.props.value || 'N/A',
    }
    this._handleClick = this._handleClick.bind(this)
  }
  componentWillReceiveProps(nextProps){
    this.setState({
      value: nextProps.value || 'N/A',
    })
  }
  render() {
    return (
      <div className="box" onClick={this._handleClick}>
        <label>{this.state.value} <ArrowDownIcon className="changeMonthIcon"/></label>
      </div>
    )
  }
  _handleClick(e) {
    this.props.onClick && this.props.onClick(e)
  }
}
MonthBox.propTypes = {
  value: propTypes.string,
  onClick: propTypes.func,
}
