export default (theme) => ({
  formWrapper: {
    padding: '20px 15px 0',
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
    },
    [theme.breakpoints.up('md')]: {
      width: 570, // temporary
    },
    [theme.breakpoints.up('lg')]: {
      width: 570, // temporary
    },
  },
  dialogRoot: {
    [theme.breakpoints.up('md')]: {
      maxWidth: 500,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: 0,
    },
  },
  formFlex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '95vh',
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    right: '-5px',
    top: 0,
    padding: 5,
    '&:focus': {
      color: '#fff',
      background: 'rgba(0, 0, 0, 0.08)',
    },
  },
  buttonHolder: {
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'space-evenly',
    }
  },
  cancelButton: {
    margin: 20,
    minWidth: 100,
    '&:hover': {
      color: 'inherit',
    },
    '&:focus': {
      color: 'inherit',
    },
    '&:disabled': {
      backgroundColor: '#fff',
    },
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },
  saveButton: {
    margin: 20,
    minWidth: 100,
    '&:hover': {
      color: theme.palette.textColor.primary,
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
    },
    '&:disabled': {
      backgroundColor: '#fff',
    },
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },
  changeButton: {
    margin: 4,
    marginLeft: 'auto',
  },
  photoWrapper: {
    borderRadius: '50%',
    border: `1px solid ${theme.palette.textColor.blue}`,
    borderTopColor: 'transparent',
    borderBottomColor: 'transparent',
    width: 22,
    height: 22,
    minWidth: 22,
    marginRight: theme.spacing(2),
  },
  dayBorder: {
    border: `1px solid ${theme.palette.textColor.yellow}`,
  },
  nightBorder: {
    border: `1px solid ${theme.palette.textColor.darkBlue}`,
  },
  inactiveBorder: {
    border: `1px solid ${theme.palette.textColor.secondary}`,
  },
  inlineFlex: {
    display: 'inline-flex',
    alignItems: 'center',
    width: '100%',
  },
  day: {
    color: theme.palette.textColor.yellow,
  },
  night: {
    color: theme.palette.textColor.darkBlue,
  },
  inactive: {
    color: theme.palette.textColor.secondary,
  },
  dialog: {
    maxWidth: 676,
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      maxHeight: '100%'
    },
  },
  dateItem: {
    width: 97,
    height: 26,
    background: '#E4E4EB',
    borderRadius: 26,
    display: 'inline-block',
    fontSize:14,
    fontFamily: 'Poppins',
    textAlign: 'center',
    lineHeight: 1.9,
    marginBottom: 15,
  },
  equipmentBlock: {
    borderRadius: '17px',
    minWidth: 34,
    height: 21,
    color: '#fff',
    maxWidth: 338
  },
  equipmentNumber: {
    fontSize: 14,
    fontWeight: 500,
    fontFamily: 'Poppins',
    color: theme.palette.textColor.white,
    padding: '3px 6px',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
});
