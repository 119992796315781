import { useState, useCallback } from 'react';
import DuplicateModal from '../components/DuplicateModal/DuplicateModal';
import { postDuplicate, putConflictActionResources } from './apiCalls';
import { MODAL_TYPES } from '../constants';
import ConflictModal from '../components/ConflictModal/ConflictModal';
import TimeoffModal from '../components/TimeoffModal/TimeoffModal';

export const useDuplicate = (sheetId, openSnackbar) => {
  const [modal, setModal] = useState({ type: MODAL_TYPES.calendar, isOpen: false, data: null });

  const submitDuplicateModal = (data) => postDuplicate(data, sheetId, setModal, openSnackbar);
  const closeDuplicateModal = () =>
    setModal({ type: MODAL_TYPES.calendar, isOpen: false, data: null });
  const closeTimeoffModal = () => {
    if (modal.data.some((worklog) => worklog.conflictWorkers || worklog.conflictEquipment))
      return setModal((prev) => ({ ...prev, type: MODAL_TYPES.conflict, isOpen: true }));
    closeDuplicateModal();
  };
  const submitConflictModal = (data) => putConflictActionResources(data);

  const duplicateModal = useCallback(() => {
    if (modal.type === MODAL_TYPES.calendar)
      return (
        <DuplicateModal
          data={modal}
          onClose={closeDuplicateModal}
          onSubmit={submitDuplicateModal}
        />
      );

    if (modal.type === MODAL_TYPES.conflict)
      return (
        <ConflictModal
          data={modal.data}
          closeModal={closeDuplicateModal}
          openSnackbar={openSnackbar}
          submit={submitConflictModal}
        />
      );

    if (modal.type === MODAL_TYPES.timeoff)
      return <TimeoffModal data={modal.data} onClose={closeTimeoffModal} />;
  },[modal]);

  return {
    DuplicateModalComponent: modal?.isOpen ? duplicateModal : () => null,
    setDuplicateModal: setModal,
  };
};
