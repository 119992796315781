export const searchOptions = {
  keys: ['activeUsers', 'name'],
  shouldSort: false,
  threshold: 0.1,
};

export const headCells = [
  { id: 'name', align: 'left', sortable: true, label: 'Companies' },
  { id: 'subscription', align: 'left', sortable: true, label: 'Subscription' },
  { id: 'activeUsers', align: 'left', sortable: true, label: 'Active Users' },
  { id: 'active', sortable: true, label: 'Status' },
  { id: 'edit', label: 'Action'},
  { id: 'login', label: ''}
];

export const DRAWER_TYPES = {
  filters: 'clients_filters',
  addClient: 'clients_addClient',
  editClient: 'clients_editClient',
}

export const subscriptions = ['Core'];