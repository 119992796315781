import React, { useCallback, useEffect } from 'react';
import BaseDrawer from 'components/BaseDrawer';
import Grid from '@material-ui/core/Grid';
import { Button, TextField } from '@material-ui/core';
import FieldLabel from 'components/FieldLabel';
import Input from 'components/Input';
import Field from 'components/Field';
import { useForm, Controller } from 'react-hook-form';
import Typography from '@material-ui/core/Typography';
import { Option, isIOS } from 'components/Option';
import useStyles from './useStyles';

const Footer = ({ onSave, onCancel }) => (
  <Grid container spacing={2} justifyContent={'center'}>
    <Grid item xs={'auto'}>
      <Button color="secondary" variant="outlined" onClick={onCancel} disableTouchRipple>
        Cancel
      </Button>
    </Grid>
    <Grid item xs={'auto'}>
      <Button type="submit" color="primary" variant="outlined" onClick={onSave} disableTouchRipple>
        Save
      </Button>
    </Grid>
  </Grid>
);

const restDefaultValues = (value) => {
  return {
    name: value?.name || '',
    description: value?.description || '',
    active: value?.active === 'true' || value?.active === true ? true : false,
  };
};

const CIPProjectDrawer = ({ children, value = {}, onClose, onSave, editMode, ...props }) => {
  const classes = useStyles();
  const {
    register,
    formState: { errors },
    trigger,
    getValues,
    control,
    reset,
  } = useForm({
    defaultValues: restDefaultValues(value || {}),
  });

  useEffect(() => {
    reset(restDefaultValues(value || {}));
  }, [value]);

  const handleSave = useCallback(async () => {
    if (await trigger()) {
      const res = await { ...value, ...getValues() };
      res.active = res?.active === 'true' || res?.active === true ? true : false;
      onSave(res);
    }
  }, [trigger, getValues, value]);

  const handleCancel = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <BaseDrawer
      {...props}
      onClose={onClose}
      footer={<Footer onSave={handleSave} onCancel={handleCancel} />}
      title={`${editMode ? 'Edit' : 'Add'} Project`}
    >
      <Field>
        <FieldLabel>Project name:</FieldLabel>
        <Input
          name="name"
          fullWidth={true}
          inputRef={register({ required: 'Project name is required' })}
        />
        <Typography variant={'caption'} color={'error'}>
          {errors.name && errors.name.message}
        </Typography>
      </Field>
      <Field>
        <FieldLabel>Description:</FieldLabel>
        <Input name="description" fullWidth={true} inputRef={register()} />
      </Field>
      <Field>
        <FieldLabel>Status:</FieldLabel>
        <Controller
          name="active"
          control={control}
          render={({ onChange, value }) => (
            <TextField
              select
              id="active"
              name="active"
              value={value}
              onChange={(e) => {
                const { value } = e.target;
                onChange(value);
              }}
              variant="outlined"
              className={classes.textField}
              inputProps={{ 'aria-label': 'Status' }}
              fullWidth
              SelectProps={{
                native: isIOS,
              }}
            >
              <Option key={1} value={true}>
                Active
              </Option>
              <Option key={2} value={false}>
                Inactive
              </Option>
            </TextField>
          )}
        />
      </Field>
    </BaseDrawer>
  );
};

export default CIPProjectDrawer;
