import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom'
import baseStyles from './../SignInForm/styles';
import { withStyles } from '@material-ui/core';
import Input from '../SignInForm/components/Input/Input';
import { useForm } from 'react-hook-form';
import cs from 'classnames';
import { formatNumber, unFormatNumber } from '../../../ForgotPassword/components/ResetViaPhone/helpers';

const SignInPhoneForm = ({ classes, className, error = {}, value = {}, onSubmit, codeSent, setError }) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const forgotPass = useCallback(async () => {
    navigate('/forgot-password', {replace: true})
  }, []);

  return (
    <form
      className={cs(className, classes.root)}
      onSubmit={handleSubmit(onSubmit)}
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: '280px' }}
    >
      <p className={classes.title}>Sign In</p>
      <div className={classes.fields} style={{ width: '100%' }}>
      {!codeSent && (<div className={classes.field}>
          <Input
            name={'phone'}
            error={errors.phone || error.error}
            ref={register({
              required: 'Phone number is required',
              pattern: {
                // value: /^[\d +]{9,14}$/,
                message: 'Phone number is not valid',
              },
              setValueAs: v => unFormatNumber(v)}
            )}
            placeholder={'(XXX) XXX-XXXX'}
            autoComplete={'tel'}
            defaultValue={formatNumber(value?.phone || '')}
            label={'Phone Number:'}
            onChange={(e) => {
              setValue('phone', formatNumber(unFormatNumber(e.target.value)))
              return errors.phone || error.error ? setError() : null}}
          />
          {errors.phone && <span className={classes.error}>{errors.phone.message}</span>}
          {error?.error && (
            <span className={classes.error}>{error.error}</span>
          )}
        </div>)}

        {codeSent && (
          <div className={classes.field}>
            <Input
              name={'secureCode'}
              error={errors.secureCode || error.error}
              ref={register({
                required: 'Secure code is required',
              })}
              autoComplete={'one-time-code'}
              defaultValue={value.secureCode}
              label={'Secure code:'}
            />
            {errors.secureCode && (
              <span className={classes.error}>{errors.secureCode.message}</span>
            )}
            {error.error === 'code-expired' && (
              <span className={classes.error}>Secure code is expired</span>
            )}
            {error.error === 'invalid-code' && (
              <span className={classes.error}>Secure code is invalid</span>
            )}
          </div>
        )}
        <p className={classes.forgotText} onClick={forgotPass}>
          Forgot password?
        </p>
      </div>
      <button className={classes.submitBtn} type={'submit'}>
        {codeSent ? 'Sign In' : 'Send Code'}
      </button>
    </form>
  );
};

export default withStyles(baseStyles)(SignInPhoneForm);
