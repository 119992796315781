import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import classnames from 'classnames';
import styles from '../../styles';
import Button from '@material-ui/core/Button';
import { v4 as uuidv4 } from 'uuid';
import MenuItem from '@material-ui/core/MenuItem';

const DocumentStorage = ({
  classes,
  values,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  fullyProjectsDocuments,
  handleSelectDocument,
  addForm,
  handleDocumentAddForm,
  setFullyProjectsDocuments,
  validateForm,
  setErrors,
}) => {
  const clearDocument = {
    name: '',
    url: '',
  };
  const isDocumentSelected = !!values.selectedDocumentId;
  const addDocumentFromEditor = async () => {
    let err;
    await validateForm().then((errors) => {
      err = errors;
    });
    if (err.newDocument) return;

    if (!isDocumentSelected) {
      const newDocument = {
        name: values?.newDocument?.name || '',
        url: values?.newDocument?.url || '',
        new: true,
        _id: uuidv4(),
      };

      setFullyProjectsDocuments((prev) => [...prev, newDocument]);
    } else {
      setFullyProjectsDocuments((prev) =>
        prev.map((el) =>
          el.name === values.selectedDocumentId
            ? {
                ...el,
                name: values?.newDocument?.name || '',
                url: values?.newDocument?.url || '',
              }
            : el
        )
      );
    }
    setFieldValue('selectedDocumentId', '');
    resetEditForm();
    handleDocumentAddForm(false);
  };

  const resetEditForm = () => {
    setErrors({});
    setFieldValue('newDocument', clearDocument);
  };

  return (
    <Grid className={classnames(classes.flexBox, classes.fullWidth)}>
      <Grid xs={12} lg={8} container item justifyContent="space-between" alignItems="center">
        <Typography variant="h4" color="textPrimary" style={{ margin: '15px 0' }}>
          Document Storage
        </Typography>
        <Grid
          container
          xs={12}
          lg={12}
          className={classnames(classes.zeroPaddingTop, classes.fullWidth)}
        >
          <div className={classnames(classes.flexColumn, classes.paddingBottom, classes.fullWidth)}>
            <Typography variant="body1" color="textSecondary" style={{ margin: '5px 0' }}>
              Document Description:
            </Typography>
            <div className={classnames(classes.flexBox, classes.fullWidth)}>
              <TextField
                select
                id={'selectedDocumentId'}
                name={'selectedDocumentId'}
                className={classnames(classes.textField, classes.customSelect)}
                value={values.selectedDocumentId}
                onChange={handleSelectDocument(setFieldValue)}
                variant="outlined"
                fullWidth
                label={'Select Document'}
                size="small"
              >
                <MenuItem key={'000'} value="" />
                {[...fullyProjectsDocuments]
                  .sort((a, b) => a.name?.localeCompare(b?.name))
                  .map((projectDocument, i) => {
                    return (
                      <MenuItem value={projectDocument.name} key={projectDocument.name}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                            justifyContent: 'space-between',
                          }}
                        >
                          <span>{projectDocument.name}</span>
                          <CloseIcon
                            fontSize={'small'}
                            className={classnames(classes.delCustomSelectIcon)}
                            onClick={(e) => {
                              e.stopPropagation();
                              setFieldValue('selectedDocumentId', '');
                              handleDocumentAddForm(false);
                              setFullyProjectsDocuments((prev) =>
                                prev.filter((el) => el.name !== projectDocument.name)
                              );
                            }}
                          />
                        </div>
                      </MenuItem>
                    );
                  })}
                <MenuItem key={'add'} value="add" className={classes.primary}>
                  + Add New Document
                </MenuItem>
              </TextField>
              <Grid container item xs={2} lg={2} justifyContent="flex-end" alignItems="center">
                <div
                  className={classes.delCustomSelectIcon}
                  onClick={() => {
                    setFieldValue('selectedDocumentId', '');
                    handleDocumentAddForm(false);
                    setFieldValue('newDocument', clearDocument);
                    setErrors({});
                  }}
                  aria-label="Delete"
                >
                  <CloseIcon />
                </div>
              </Grid>
            </div>
          </div>
        </Grid>

        {!!addForm && (
          <>
            <Grid container item xs={12} lg={12} justifyContent="space-between" alignItems="center">
              <Typography variant="h6" color="textPrimary" style={{ margin: '5px 0' }}>
                {isDocumentSelected ? 'Edit' : 'Add'} a Document:
              </Typography>
            </Grid>
            <Grid container spacing={3} key={'i'}>
              <Grid item xs={12} lg={6}>
                <label htmlFor="documentsName">
                  <Typography variant="body1" color="textSecondary">
                    Document Name<span className={classes.requiredSign}>*</span>:
                  </Typography>
                  <TextField
                    id={`newDocument.name`}
                    name={`newDocument.name`}
                    value={values?.newDocument && values?.newDocument?.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={(classes.textField, classes.zeroPaddingTop)}
                    error={Boolean(errors.newDocument?.name)}
                    variant="outlined"
                    inputProps={{ 'aria-label': 'documentsName' }}
                    fullWidth
                  />
                  {errors?.newDocument?.name ? (
                    <div className={classes.error}>{errors?.newDocument?.name}</div>
                  ) : null}
                </label>
              </Grid>
              <Grid item xs={12} lg={6}>
                <label htmlFor="documentsUrl">
                  <Typography variant="body1" color="textSecondary">
                    Link<span className={classes.requiredSign}>*</span>:
                  </Typography>
                  <TextField
                    id={`newDocument.url`}
                    name={`newDocument.url`}
                    value={values?.newDocument && values.newDocument.url}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={classes.textField}
                    error={Boolean(errors.newDocument?.url)}
                    variant="outlined"
                    inputProps={{ 'aria-label': 'documentsUrl' }}
                    fullWidth
                  />
                  {errors?.newDocument?.url ? (
                    <div className={classes.error}>{errors?.newDocument?.url}</div>
                  ) : null}
                </label>
              </Grid>
            </Grid>
            <Grid container item xs={12} lg={12} justifyContent="flex-end" alignItems="center">
              <Grid item container justifyContent="flex-end" alignItems="center" xs={4} lg={3}>
                <Button
                  variant="text"
                  className={classes.primaryTextButton}
                  disabled={errors?.newDocument || !values?.newDocument?.name}
                  onClick={() => {
                    addDocumentFromEditor();
                  }}
                  disableRipple
                >
                  Save
                </Button>
                <Typography
                  style={{
                    backgroundColor: 'transparent',
                    padding: '12px 0',
                    marginLeft: 10,
                    fontWeight: 500,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    handleDocumentAddForm(false);
                    resetEditForm();
                  }}
                  variant="body1"
                  color="textSecondary"
                  aria-label="Delete"
                  disableTouchRipple
                >
                  Cancel
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(DocumentStorage);
