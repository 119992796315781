export default (theme) => ({
  closeButton: {
    right: '0!important',
  },
  dash: {
    position: 'absolute',
    top: 'calc(50% - .55em)',
    left: 'calc(50% - 13px)',
  },
  flex: {
    display: 'flex',
  },
  formWrapper: {
    padding: '20px 16px 0',
    [theme.breakpoints.down('sm')]: {
      minWidth: 320,
    },
    [theme.breakpoints.up('md')]: {
      width: 700,
    },
  },
  tripleSelectWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 215,
    gap: '5px',
    '& > div': {
      // maxWidth: 65,
    },
  },
  onJobTimeField: {
    width: 70,
    // width: '100%',
    marginRight: 16,
  },
  error: {
    color: theme.palette.textColor.red,
    padding: '0 5px',
    fontSize: 11,
  },
  primaryCheckbox: {
    color: theme.palette.textColor.primary,
    marginLeft: '-9px',
  },
  primaryAdd: {
    fontFamily: 'Poppins,Open Sans,sans-serif',
    fontSize: 14,
    fontWeight: 500,
    cursor: 'pointer',
    color: theme.palette.textColor.primary,
  },
  doubleSelectWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: 215,
    '& > div': {
      maxWidth: 65,
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 150,
    },
  },
  adornment: {
    color: theme.palette.textColor.secondary,
    paddingRight: '5px',
    fontSize: '0.875rem',
  },
});
