export const styles = (theme) => ({
  timeOffListWrapper: {
    [theme.breakpoints.down('md')]: {
      width: '650px',
      margin: '0 auto',
    },
    '@media (max-width: 680px)': {
      width: '500px',
    },
    '@media (max-width: 530px)': {
      width: 'auto',
      margin: 0,
    },
  },
  wrap: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      padding: '14px 16px 0',
      display: 'flex',
      flexDirection: 'column',
      border: '1px solid #d9dae3',
      marginTop: '20px',
    },
  },
  projectType: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '12px',
  },
  projectTypeBt: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '10px',
  },
  textTheme: {
    color: '#A4A5B3',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 700,
  },
  textThemeNotes: {
    color: '#A4A5B3',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 700,
    marginBottom: '10px',
  },
  subTextTheme: {
    color: '#202020',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
  },
  rootDesctop: {
    display: 'block',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  rootTitle: {
    [theme.breakpoints.down('md')]: {
      marginTop: '15px',
    },
  },
  actionsWrapper: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      cursor: 'pointer',
      color: '#5a87ef',
      marginTop: '15px',
    },
  },
  actionsIcon: {
    transform: 'rotate(0deg)',
  },
  nonActionsIcon: {
    transform: 'rotate(180deg) translateY(2px)',
  },
  textIconMore: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 700,
    marginBottom: 0,
  },
});
