export default (theme) => ({
  root: {
    padding: '10px 15px !important',
  },
  drawer: {
    // [theme.breakpoints.down('sm')]: {
    //   width: '100%',
    // },
  },
  hideForPrint: {
    '@media print': {
      display: 'none',
    },
  },
});
