import $api from 'http/index';
import availabilityPageActions from './availabilityPageActions';
import { getOptions } from '../../helpers/getOptions';
import { startOfMonth, endOfMonth, startOfWeek, endOfWeek, parse } from 'date-fns';

export const getTimeoffs =
  (usedFilter, searchValue, weekDayStartsFrom) => async (dispatch, getState) => {
    const selectedDate = getState().availability.selectedDate;
    const dateFormat = getState().personalProfile.organization?.settings?.dateFormat;

    const from = startOfWeek(startOfMonth(parse(selectedDate, dateFormat, new Date())), {
      weekStartsOn: weekDayStartsFrom,
    });
    const to = endOfWeek(endOfMonth(parse(selectedDate, dateFormat, new Date())), {
      weekStartsOn: weekDayStartsFrom,
    });

    const queryParams = new URLSearchParams();
    queryParams.append('from', from.toISOString());
    queryParams.append('to', to.toISOString());
    if (usedFilter && Object.keys(usedFilter).length) {
      Object.entries(usedFilter).map(([key, value]) => queryParams.append(key, value));
    }
    if (searchValue) {
      queryParams.append('searchValue', searchValue);
    }

    try {
      const res = await $api.get(
        `${process.env.REACT_APP_BASE_URL}/timeoffs?${queryParams}`,
        getOptions()
      );

      const timeoffsByType = {
        approved: [],
        denied: [],
        requested: [],
      };

      res.data.forEach((timeoff) => {
        if (timeoff.status === 'Approved') {
          timeoffsByType.approved.push(timeoff);
        } else if (timeoff.status === 'Requested') {
          timeoffsByType.requested.push(timeoff);
        } else {
          timeoffsByType.denied.push(timeoff);
        }
      });

      dispatch(availabilityPageActions.setTimeoffsByType(timeoffsByType));
    } catch (e) {
      console.log(e);
    }
  };

export const getCalendarAllUsers = () => async (dispatch) => {
  try {
    const res = await $api.get(
      `${process.env.REACT_APP_BASE_URL}/users?isAvailability=true
    `,
      getOptions()
    );

    dispatch(availabilityPageActions.setAllUsers(res.data));
  } catch (e) {
    console.log(e);
  }
};

// * EQUIPMENT *

export const getEquipmentEvents = (searchValue) => async (dispatch) => {
  try {
    const queryParams = new URLSearchParams();

    searchValue && queryParams.append('searchValue', searchValue);

    const res = await $api.get(
      `${process.env.REACT_APP_BASE_URL}/calendar-events?${queryParams}`,
      getOptions()
    );

    dispatch(availabilityPageActions.setEquipmentEvents(res.data));
    return;
  } catch (e) {
    console.log(e);
  }
};

export const getHolidays =
  (deleted = 'false', isIndividual) =>
  async (dispatch) => {
    try {
      const queryParams = new URLSearchParams();

      deleted && queryParams.append('deleted', deleted);
      isIndividual && queryParams.append('isIndividual', isIndividual);

      const res = await $api.get(
        `${process.env.REACT_APP_BASE_URL}/holidays?${queryParams}`,
        getOptions()
      );

      dispatch(availabilityPageActions.setHolidays(res.data));
    } catch (e) {
      console.log(e);
    }
  };

export const getCalendarAllEquipment = () => async (dispatch) => {
  try {
    const res = await $api.get(
      `${process.env.REACT_APP_BASE_URL}/equipment?isAvailability=true
    `,
      getOptions()
    );

    dispatch(availabilityPageActions.setAllEquipments(res.data));
  } catch (e) {
    console.log(e);
  }
};
