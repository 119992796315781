import React, { useCallback, useMemo } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { format } from 'date-fns';
import PrintIcon from 'assets/images/print-icon.svg';

import Calendar from 'components/CustomCalendar';
import { hasPermissionsFor } from 'helpers/_helpers';
import MonthPicker from 'components/MonthPicker';
import styles from './styles';
import { allDatesFormat } from 'common/dateFormatConfig';

const AvailabilityHeader = ({
  classes,
  isTimeoffList,
  toggleTimeoffList,
  selectedDate,
  updateDate,
  toggleHolidayModal,
  isHolidayTabActive,
  holidayDates,
  updateHolidayDates,
  timeoffDates,
  updateTimeoffDates,
}) => {
  const canSeeTimeoffs = hasPermissionsFor('editPTO') || hasPermissionsFor('ptoFullAccess');
  const canAddHoliday =
    hasPermissionsFor('holidaysFullAccess') || hasPermissionsFor('holidaysCreate');
  const printUrl = '/availability/print/' +  format(new Date(selectedDate), allDatesFormat[1]) + '?print=true';

  const dates = useMemo(
    () => (isHolidayTabActive ? holidayDates : timeoffDates),
    [isHolidayTabActive, isTimeoffList, holidayDates, timeoffDates]
  );

  const updateDates = useCallback(
    (dates) => {
      if (isHolidayTabActive) {
        updateHolidayDates([...dates]);
      } else {
        updateTimeoffDates([...dates]);
      }
    },
    [isHolidayTabActive, isTimeoffList]
  );
  return (
    <header className={classes.header}>
      <div className={classes.leftPart}>
        <Typography variant="h3" className={classes.headerText}>
          {isTimeoffList ? 'Requests' : 'Availability'}
        </Typography>
        {canSeeTimeoffs && (
          <Button
            color="secondary"
            className={classes.inactiveBtn}
            onClick={toggleTimeoffList}
            disableRipple
            disableTouchRipple
            disableFocusRipple
          >
            {isTimeoffList ? 'Calendar' : 'Register'}
          </Button>
        )}
      </div>
      <div className={classes.rightPart}>
        {isTimeoffList ? (
          <>
            {canAddHoliday && (
              <Button
                color="secondary"
                className={classes.inactiveBtn}
                onClick={toggleHolidayModal(null)}
                disableRipple
                disableTouchRipple
                disableFocusRipple
              >
                Add Holiday/Company Time
              </Button>
            )}
          </>
        ) : (
          <div className={classes.empty} />
        )}
        {!isTimeoffList && (
          <Link className={classes.printLink} href={printUrl} target="_blank">
            <img src={PrintIcon} alt="print" />
            Print
          </Link>
        )}
        {isHolidayTabActive || isTimeoffList ? (
          <Calendar
            dates={dates}
            updateDates={updateDates}
            monthMaxDetail={Boolean(isTimeoffList) && !Boolean(isHolidayTabActive)}
          />
        ) : (
          <MonthPicker selectedDate={selectedDate} updateDate={updateDate} />
        )}
      </div>
    </header>
  );
};

AvailabilityHeader.propTypes = {
  classes: propTypes.object.isRequired,
  isTimeoffList: propTypes.bool.isRequired,
  toggleTimeoffList: propTypes.func.isRequired,
  selectedDate: propTypes.object.isRequired,
  updateDate: propTypes.func.isRequired,
  toggleHolidayModal: propTypes.func.isRequired,
  isHolidayTabActive: propTypes.bool.isRequired,
  holidayDates: propTypes.array.isRequired,
  updateHolidayDates: propTypes.func.isRequired,
  timeoffDates: propTypes.array.isRequired,
  updateTimeoffDates: propTypes.func.isRequired,
};

export default withStyles(styles)(AvailabilityHeader);
