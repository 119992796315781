export default (theme) => ({
  root: {
    padding: '10px 15px !important',
    maxWidth: '1280px',
    margin: '0 auto',
  },
  shiftImage: {
    height: 16,
    margin: '0 5px 0 0',
  },
  drawer: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  loader: {
    fontSize: 14,
    paddingBottom: 10,
  },
});
