// unitType: 'All',
// markingType: 'All',
// sortBy: 'name',
// sortOrder: 'asc',
// measurement: settings?.measurement || 'All',

export const filterMaterials = (materials, filters) => {
  return materials.filter(material => {
    let matchUnitType = true;
    let matchMarkingType = true;
    let matchMeasurement = true;

    if (filters?.unitType && filters?.unitType !== 'All') {
      matchUnitType = filters.unitType === material.type;
    }
    if (filters?.markingType && filters?.markingType !== 'All') {
      if (filters?.markingType === 'line') matchMarkingType = !!material?.lines?.length;
      if (filters?.markingType === 'legend') matchMarkingType = !!material?.legends?.length;
      if (filters?.markingType === 'lettering') matchMarkingType = !!material?.letterings?.length;
      if (filters?.markingType === 'glassBeads') matchMarkingType = !!material?.glassBeads?.length;
    }
    if (filters?.measurement && filters?.measurement !== 'All') {
      matchMeasurement = filters.measurement === material.measurement;
    }

    return matchUnitType && matchMarkingType && matchMeasurement;
  }).sort((a, b) => {
    const sortDirection = filters.sortOrder === 'asc' ? 1 : -1
    if (filters.sortBy === 'type') {
      return (a.type.localeCompare(b.type) * sortDirection) || // by type => by name
        (a.name.localeCompare(b.name) * sortDirection)
    }
    return a.name.localeCompare(b.name) * sortDirection // by name
  })
}