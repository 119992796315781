import formatNumber from 'pages/TimeClock/utils/formatNumber';
import { getHoursToDate } from 'pages/TimeClock/components/DayReport/components/CIPProjects/components/CIPProjectItem/CIPProjectItem';

export const CipBlock = ({ day }) => {
  return (
    <>
      <tr class="header-row border-top">
        <td colspan="3">Project Name</td>
        <td colspan="6">Hours to Date</td>
        <td>Hours</td>
      </tr>
      {!!day?.cipJobs.length ? (
        <>
          {day.cipJobs.map((cip) => {
            return (
              <tr class="border-top">
                <td colspan="3">{cip.cipProject.name}</td>
                <td colspan="6">
                  {cip.cipProject
                    ? formatNumber(getHoursToDate(cip.cipProject.hoursPerUser, day.user._id))
                    : 'N/A'}
                </td>
                <td class="text-center">{formatNumber(cip.hoursTotal)}</td>
              </tr>
            );
          })}
        </>
      ) : (
        <tr>
          <td colspan="10">No Jobs</td>
        </tr>
      )}
    </>
  );
};
