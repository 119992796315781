import React, { useState, useEffect, useMemo } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Checkbox,
  Typography,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import classnames from 'classnames';
import photoPlaceholder from 'assets/images/user-photo-placeholder.svg';

import styles from './styles';
import { useUpdatePublish } from './hooks/useUpdatePublish';
import PageLoader from 'components/PageLoader';
import { updateSheets } from 'store/schedule/schedulePageOperation';
import { useDispatch, useSelector } from 'react-redux';
import { CALENDAR_VIEW_TYPES } from 'pages/Schedule/components/ScheduleHeader/components/DateTypeSelector/constants';

const UpdateModal = ({ classes, isOpen, onClose, data, openSnackbar }) => {
  const dispatch = useDispatch()
  const { updatePublish } = useUpdatePublish();
  const { calendarTypeView } = useSelector(store => store.schedule);
  const workers = useMemo(
    () => data.workers.map((w) => ({ ...w, text: `Scheduled for ${w.addedTo.join(', ')}.` })),
    [data.workers]
  );
  const [isLoading, setIsLoading] = useState(false);
  const [selectedWorkersIds, setSelectedWorkers] = useState(data.workers.map((w) => w._id));
  useEffect(() => {
    setSelectedWorkers(data.workers.map((w) => w._id));
  }, [data.workers]);

  const updateWorker = (id) => () => {
    if (selectedWorkersIds.includes(id)) {
      return setSelectedWorkers(selectedWorkersIds.filter((workerId) => workerId !== id));
    }
    setSelectedWorkers([...selectedWorkersIds, id]);
  };

  const settings = useSelector((state) => state.personalProfile.organization.settings);
  const submitText = useMemo(
    () => (settings && settings.scheduleNotifications ? 'Send Updates' : 'Update Schedule'),
    [settings]
  );
  const mainText = useMemo(
    () =>
      settings && settings.scheduleNotifications
        ? 'Notices will be sent to the following employees:'
        : 'Notifications are currently turned off. Notices will not be sent.',
    [settings]
    );

  const handleUpdateAllSelectedFlag = () => {
    if (selectedWorkersIds.length) {
      setSelectedWorkers([]);
    } else {
      setSelectedWorkers(data.workers.map((w) => w._id));
    }
  };

  const handleSubmit = async () => {
    let workersIds = [];
    if (settings && settings.scheduleNotifications) {
      workersIds = workers
        .filter((worker) => selectedWorkersIds.includes(worker._id))
        .map((worker) => worker._id);
    }

    setIsLoading(true);
    try {
      const res = await updatePublish(data.ids, workersIds);
      if (calendarTypeView != CALENDAR_VIEW_TYPES.monthly) {
        dispatch(updateSheets())
      }
      onClose();
      if (res.errorMessage) {
        openSnackbar('error', res.errorMessage);
      }
    } catch (e) {
      openSnackbar('error', e.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          classes: {
            root: classes.dialogRoot,
          },
        }}
      >
        <div className={classes.header}>
          <Typography variant="h5" color="textPrimary">
            Update Schedule
          </Typography>
          <IconButton
            className={classes.closeButton}
            onClick={onClose}
            aria-label="Close"
            disableRipple
            disabled={isLoading}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <PageLoader loading={isLoading} small>
          <>
            <DialogContent className={classes.paddingBox}>
              <Typography variant="h6" color="textPrimary" className={classes.marginBottom}>
                {workers.length ? mainText : ''}
              </Typography>

              {workers.map((w) => (
                <Grid container key={w._id} spacing={2}>
                  <Grid item xs={6}>
                    <div className={classes.inlineFlex}>
                      <Checkbox
                        checked={selectedWorkersIds.includes(w._id)}
                        onChange={updateWorker(w._id)}
                        color="primary"
                        className={classes.checkbox}
                      />
                      <div
                        className={classnames(
                          classes.photoWrapper,
                          classes[`${w.user?.profile?.shifts?.timeOfDay?.toLowerCase()}Border`]
                        )}
                        onClick={updateWorker(w._id)}
                      >
                        <img src={photoPlaceholder} className={classes.photo} />
                      </div>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        className={classnames(
                          classes.name,
                          classes[w.user?.profile?.shifts?.timeOfDay?.toLowerCase()]
                        )}
                        onClick={updateWorker(w._id)}
                      >
                        {w.user?.username}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" color="textPrimary">
                      {w.text}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </DialogContent>
            {workers.length ? (
              <div className={classes.flexBoxClearBtn}>
                <Button
                  color="secondary"
                  variant="text"
                  className={classnames(classes.extraBtn, classes.selectBtn)}
                  onClick={handleUpdateAllSelectedFlag}
                  disableTouchRipple
                >
                  {selectedWorkersIds.length ? 'Clear' : 'Select'} all
                </Button>
              </div>
            ) : null}
            <DialogActions>
              <div className={classes.flexBox}>
                <Button
                  color="primary"
                  variant="outlined"
                  className={classes.saveButton}
                  onClick={handleSubmit}
                  disableTouchRipple
                  disabled={isLoading}
                >
                  {submitText}
                </Button>
              </div>
            </DialogActions>
          </>
        </PageLoader>
      </Dialog>
    </>
  );
};

UpdateModal.propTypes = {
  classes: propTypes.object.isRequired,
  isOpen: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  data: propTypes.object.isRequired,
  openSnackbar: propTypes.func.isRequired,
};

export default withStyles(styles)(UpdateModal);
