import React, { useEffect, useState } from 'react';
import TimeoffList from './../TimeoffList';
import FiltersBar from 'components/FiltersBar';
import { drawerTypes } from '../../../../../Equipment/components/helpers/drawerTypes';
import { Drawer } from '@material-ui/core';
import EditTimeoffForm from 'components/EditTimeoffForm/EditTimeoffForm';
import AlertModal from 'components/AlertModal/AlertModal';
import ConfirmDialog from 'components/ConfirmDialog';
import SortingAndFilteringPanel from './../SortingAndFilteringPanel';
import { getRegisterTimeoffs } from './apiCalls';
import LoadingSpinner from 'components/LoadingSpinner';
import { getOptions } from 'helpers/getOptions';
import $api from 'http/index';

export const defaultRegisterTimeOffListFilters = {
  sortBy: 'username',
  sortOrder: 'asc',
  reason: 'All',
  isPTO: 'All',
  userShift: 'All',
  userRole: [
    'Admin',
    'Field Technician',
    'Dispatcher',
    'Accounting',
    'Fleet Maintenance',
    'Project Management',
    'Machine Shop',
  ],
  type: 'All',
};

export const RegisterTimeOffList = ({
  openSnackbar,
  type,
  timeoffDates,
  updateHolidayTabStatus,
  ...props
}) => {
  const [visibleTimeoffs, setVisibleTimeoffs] = useState([]);
  const [drawer, setDrawer] = useState({
    type: drawerTypes.filters,
    isOpen: false,
    timeoff: {},
  });
  const [filter, setFilter] = useState(defaultRegisterTimeOffListFilters);
  const [modal, setModal] = useState({
    isModalOpen: false,
    text: '',
    type: 'delete',
    itemId: null,
  });
  const [infoModal, setInfoModal] = useState({
    isOpen: false,
    info: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    updateVisibleItems();
  }, [timeoffDates, filter, searchValue, type]);

  const updateFilter = (nextValue) => {
    setFilter(nextValue);
  };
  const updateSearch = (nextValue) => {
    setSearchValue(nextValue);
  };

  const updateVisibleItems = async () => {
    setIsLoading(true);
    const result = await getRegisterTimeoffs(type, timeoffDates, filter, searchValue);
    setVisibleTimeoffs(result);
    setIsLoading(false);
  };

  const openDrawer =
    (type, timeoff = {}) =>
    () =>
      setDrawer({ type, timeoff, isOpen: true });
  const closeDrawer = () => setDrawer((prev) => ({ ...prev, isOpen: false }));

  const closeEdit = () => {
    updateVisibleItems();
    closeDrawer();
  };

  const closeRemove = () => {
    closeEdit()
  };

  const openInfoModal = (info) => {
    setInfoModal({
      isOpen: true,
      info,
    });
  };

  const closeInfoModal = () => {
    setInfoModal({ isOpen: false, info: '' });
  };

  const openModal = (type, itemId) => () => {
    const actions = {
      confirm: 'approve this request',
      reject: 'deny this request',
      delete: 'delete this request',
    };

    setModal({
      type,
      itemId,
      isModalOpen: true,
      text: `Are you sure you want to ${actions[type]}?`,
    });
  };
  const closeModal = () => {
    setModal((prev) => ({ ...prev, isModalOpen: false }));
  };

  const confirmAction = async () => {
    const { itemId: timeoffId, type } = modal;
    closeModal();

    const changeStatus = type === 'confirm' || type === 'reject';
    const status = type === 'confirm' ? 'Approved' : 'Denied';

    if (changeStatus) {
      try {
        await $api.patch(
          `${process.env.REACT_APP_BASE_URL}/timeoffs/${timeoffId}/status`,
          { status },
          getOptions()
        );
        updateVisibleItems()
      } catch (e) {
        openSnackbar('error', e?.response?.data?.message || e.message);
      }
    } else {
      try {
        await $api.delete(`${process.env.REACT_APP_BASE_URL}/timeoffs/${timeoffId}`, 
        getOptions())
        updateVisibleItems()
      } catch (err) {
        console.log('> ', err);
        openSnackbar('error',err?.response?.data?.message || err.message);
      }
    }

  };
  return (
    <>
      <FiltersBar openDrawer={openDrawer} updateSearchValue={updateSearch} isDynamicSearch={true}/>

      {isLoading ? (
        <div
          style={{ display: 'flex', alignItem: 'center', justifyContent: 'center' }}
        >
          <LoadingSpinner height={40} margin={32} />
        </div>
      ) : (
        <TimeoffList
          timeoffs={visibleTimeoffs}
          type={type}
          openModal={openModal}
          openDrawer={openDrawer}
        />
      )}

      <Drawer anchor="right" open={drawer.isOpen}>
        {drawer.type !== drawerTypes.filters ? (
          <EditTimeoffForm
            closeDrawer={closeDrawer}
            closeEdit={closeEdit}
            closeRemove={closeRemove}
            openSnackbar={openSnackbar}
            timeoff={drawer.timeoff}
            isAdminPage={true}
            isCalendar={true}
          />
        ) : (
          <SortingAndFilteringPanel
            closeDrawer={closeDrawer}
            filter={filter}
            updateFilter={updateFilter}
            isLoading={isLoading}
          />
        )}
      </Drawer>
      <ConfirmDialog
        isOpen={modal.isModalOpen}
        onClose={closeModal}
        onSubmit={confirmAction}
        text={modal.text}
      />
      {infoModal.isOpen && (
        <AlertModal isOpen={true} info={infoModal.info} onClose={closeInfoModal} />
      )}
    </>
  );
};
