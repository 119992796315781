import React from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { format, parse } from 'date-fns';
import classnames from 'classnames';
import styles from './styles';
import { useSelector } from 'react-redux';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';
import { allDatesFormat, globalBEDateFormat } from 'common/dateFormatConfig';

const weekDaysSun = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
const weekDaysMon = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    // color: theme.palette.textColor.secondary,
  },
  body: {
    fontSize: 14,
  },
  root: {
    border: '1px solid #202020 !important',
    verticalAlign: 'top',

    padding: '0 8px',
    '&:last-child': {
      paddingRight: 8,
    },
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#fff',
    },
    '&:nth-of-type(even)': {
      backgroundColor: '#fff',
    },
    '& > th': {
      border: 'none',
    },
  },
}))(TableRow);

const CalendarTable = ({ classes, monthEvents, selectedDate }) => {
  const weekStart = useSelector((state) => state.personalProfile.organization.settings.weekStart);
  const { dateFormat } = useDateSettingsFormat();

  const getDayNumber = (date) => format(parse(date, globalBEDateFormat, new Date()), 'dd');

  const isInSelectedMonth = (date) =>
    format(parse(date, globalBEDateFormat, new Date()), allDatesFormat[1]) === selectedDate;

  const renderHoliday = (holiday) => {
    if (holiday.isIndividual) {
      return holiday.users.map((user) => (
        <Typography className={classes.holidayTitle} key={user._id + holiday.name}>
          {`${user.username} (Day Off)`}
        </Typography>
      ));
    }
    return <Typography className={classes.holidayTitle}>{holiday.name}</Typography>;
  };

  const getTitle = (data) => {
    let title = '';

    if (data.user?.profile?.shifts && data.user?.profile?.shifts?.weekDays) {
      title = data.user.username + ' (' + data.user.profile.shifts.weekDays + ')';
    } else if (data?.user) {
      title = data.user.username;
    }

    if (data?.equipment?.name) {
      title = data.equipment.name;
    }
    return title;
  };

  const renderDays = weekStart === 'Sun' ? weekDaysSun : weekDaysMon;

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <StyledTableRow>
            {renderDays.map((el, i) => (
              <StyledTableCell key={i} align="center" component="th" scope="row">
                {el}
              </StyledTableCell>
            ))}
          </StyledTableRow>
        </TableHead>

        <TableBody>
          {monthEvents.map((row, i) => (
            <StyledTableRow key={i}>
              {row.map((event) => (
                <StyledTableCell
                  key={event.dayNumber}
                  className={classnames(
                    classes.zeroPadding,
                    !isInSelectedMonth(event.dayNumber) && classes.noBorder
                  )}
                >
                  <div className={classes.cellContent}>
                    {isInSelectedMonth(event.dayNumber) && (
                      <div className={classes.marginBottom}>
                        <span className={classes.dayNumber}>{getDayNumber(event.dayNumber)}</span>
                      </div>
                    )}
                    <div className={classes.list}>
                      {event.holiday && renderHoliday(event.holiday)}
                      {event.events.map((resource) => (
                        <Typography
                          key={resource._id + resource.eventDate}
                          className={classes.resourceTitle}
                        >
                          {getTitle(resource)}
                        </Typography>
                      ))}
                    </div>
                  </div>
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

CalendarTable.propTypes = {
  classes: propTypes.object.isRequired,
  selectedDate: propTypes.string.isRequired,
  monthEvents: propTypes.array.isRequired,
};

export default withStyles(styles)(CalendarTable);
