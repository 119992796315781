export default theme => ({
  formWrapper: {
    padding: '20px 15px 0',
    [theme.breakpoints.down('sm')]: {
      minWidth: 320,
    },
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      width: 550,
    },
  },
  closeButton: {
    position: 'absolute',
    right: 6,
    top: 14,
  },
  buttonHolder: {
    textAlign: 'center',
  },
  cancelButton: {
    margin: 20,
    minWidth: 100,
    '&:hover': {
      color: 'inherit'
    },
    '&:disabled': {
      backgroundColor: '#fff',
    },
  },
  saveButton: {
    margin: 20,
    minWidth: 100,
    '&:hover': {
      color: theme.palette.textColor.primary,
    },
    '&:disabled': {
      backgroundColor: '#fff',
    },
  },
  formFlex: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '95vh',
  },
  deleteButton: {
    minWidth: 28,
    height: 34,
    padding: 5,
    '&:hover': {
      background: '#fff !important',
    }
  },
  userPhotoWrapper: {
    borderRadius: '50%',
    border: `1px solid ${theme.palette.primary.blue}`,
    borderTopColor: 'transparent',
    borderBottomColor: 'transparent',
    width: 32,
    height: 32,
    transform: 'rotate(135deg)',
    marginRight: 20
  },
  userPhoto: {
    display: 'block',
    transform: 'rotate(-135deg)',
    height: 26,
    margin: '2px auto 0'
  },
  flexBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '10px 0 10px'
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  fixedWidth: {
    width: 210
  },
  fixedHeight: {
    minHeight: 29,
  },
  headerSecondText: {
    color: theme.palette.textColor.secondary,
    display: 'inline-block',
    marginLeft: 10
  },

  deleteIcon: {
    paddingTop: 6
  },
  searchBtn: {
    textTransform: 'none',
    padding: '3px 8px',
    color: theme.palette.textColor.blue,
    '&:hover': {
      color: theme.palette.textColor.blue,
      backgroundColor: '#fff !important',
    },
    '&:focus': {
      color: theme.palette.textColor.blue,
      backgroundColor: '#fff !important',
    },
    '&:disabled': {
      color: theme.palette.textColor.blue,
      backgroundColor: '#fff !important',
    }
  },
  noteDividerPadding: {
    marginTop: 5
  },
  marginBottomSmall: {
    marginBottom: 5
  },
  marginBottomNormal: {
    marginBottom: 10
  },
  marginBottomBig: {
    marginBottom: 20
  },
  marginRightBig: {
    marginRight: 20
  },
  wordBreak: {
    wordBreak: 'break-word',
  },
});
