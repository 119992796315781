export default (theme) => ({
  root: {
    minHeight: 36,
    [theme.breakpoints.down('md')]: {
      borderBottom: '1px solid #e8e8e8',
      width: '100%',
    },
  },
  indicator: {
    backgroundColor: '#5286F7',
  },
});
