import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

const IOSSwitch = withStyles(theme => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    // margin: theme.spacing(1),
  },
  rootSmall: {
    width: 36,
    height: 20,
    padding: 0
  },
  switchBase: {
    padding: '1px !important',
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#5A87EF',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#5A87EF',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  thumbSmall: {
    width: 18,
    height: 18,
    color: '#ffffff'
  },
  track: {
    boxSizing: 'border-box',
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  disabled: {
    color: '#fff !important',
    '& + $track': {
      backgroundColor: '#5A87EF !important',
      opacity: '0.12 !important',
      border: `1px solid ${theme.palette.grey[400]} !important`,
    },
  },
  checked: {},
  focusVisible: {},
}))(({ classes, small, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: small ? classes.rootSmall : classes.root,
        switchBase: classes.switchBase,
        thumb: small ? classes.thumbSmall : classes.thumb,
        track: classes.track,
        checked: classes.checked,
        disabled: classes.disabled
      }}
      {...props}
    />
  );
});

export default IOSSwitch;
