export default (theme) => ({
  formWrapper: {
    padding: '20px 15px 0',
    width: 380,
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      width: 550,
    },
  },
  closeButton: {
    position: 'absolute',
    right: 15,
    top: 12,
  },
  buttonHolder: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  cancelButton: {
    margin: 20,
    minWidth: 100,
    '&:hover': {
      color: theme.palette.textColor.dark,
    },
    '&:focus': {
      color: theme.palette.textColor.dark,
      backgroundColor: '#fff',
    },
    '&:disabled': {
      backgroundColor: '#fff',
    },
  },
  saveButton: {
    margin: 20,
    minWidth: 100,
    '&:hover': {
      color: theme.palette.textColor.primary,
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
      backgroundColor: '#fff',
    },
    '&:disabled': {
      backgroundColor: '#fff',
    },
  },
  formFlex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '95vh',
  },
  textField: {
    maxWidth: '100%',
    '@media (max-width: 573px)': {
      width: 500,
    },
  },
  marginVertical: {
    margin: '20px 0',
  },
  shiftImage: {
    height: 16,
    margin: '-2px 5px 0 0',
  },
  label: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  labelEmail: {
    marginBottom: 10,
  },
  marginHeader: {
    marginBottom: 30,
  },
  marginSubHeader: {
    marginBottom: 20,
  },
  verticalDivider: {
    width: 1,
    height: 30,
    backgroundColor: theme.palette.borderColor.primary,
  },
  actionsIcon: {
    transform: 'rotate(0deg) translateY(2px)',
  },
  nonActionsIcon: {
    transform: 'rotate(180deg) translateY(2px)',
  },
  reversIcon: {
    transform: 'rotate(180deg) translateY(2px)',
  },
  marginVertical: {
    margin: '20px 0',
  },
  textIconMore: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 700,
    marginBottom: 0,
    color: '#5a87ef',
  },
  buttonWrapper: {
    display: 'flex',
    width: 'fit-content',
    alignItems: 'center',
    marginTop: '5px',
    cursor: 'pointer',
  },
  emailBlockWrapper: {
    // display: 'flex',
    alignItems: 'center',
  },
  removeEmail: {
    cursor: 'pointer',
    marginLeft: 10,
  },
  error: {
    fontSize: 11,
    // float: 'right',
    color: theme.palette.textColor.red,
    padding: '0 5px',
    maxWidth: 250,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 200,
    },
  },
  fieldWrapper: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: 255,
    },
  },
  flexContainer: {
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'space-between',
    },
  },
  resetButton: {
    '&:hover': {
      color: theme.palette.primary.light,
      backgroundColor: '#fff !important',
    },
    '&:focus': {
      color: theme.palette.primary.blue,
      backgroundColor: '#fff',
    },
    textTransform: 'none',
  },
  customSelectedStyle: {
    backgroundColor: 'rgba(0, 0, 0, 0.14)',
  },
  linkButton: {
    padding: 0,
    outline: 'none',
    border: 'none',
    background: 'none',
    color: '#5A87EF',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '21px',
    marginTop: 30,
    '&:hover': {
      color: '#5A87EF',
      border: 'none',
      background: 'none',
    },
  },
  primaryTextButton: {
    padding: 0,
    // marginBottom: 8,
    // marginLeft: 6,
    color: theme.palette.textColor.primary,
    '& span': {
      // display: 'block',
      '&:first-letter': {
        fontSize: 15,
      },
    },
    '&:hover': {
      color: theme.palette.textColor.primary,
      background: `${theme.palette.background.default} !important`,
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
      background: `${theme.palette.background.default} !important`,
    },
  },
  actionsWrapper: {
    display: 'block',
    width: '100%',
    color: '#5a87ef',
  },
  tooltipInfo: {
    color: theme.palette.textColor.primary,
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '21px',
    backgroundColor: theme.palette.textColor.lightBlue,
    padding: '0px 6px',
    borderRadius: '2px',
  },
  radio: {
    display: 'flex',
    gap: '20px',
  },
  radioItem: {
    width: 'auto',
  },
  disabledFormat: {
    height: 28,
  },
  headerDesc: {
    fontSize: '0.95rem',
    color: theme.palette.secondary.main,
  },
});
