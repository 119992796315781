import { getOptions } from 'helpers/getOptions';
import { useDispatch } from 'react-redux';
import schedulePageActions from 'store/schedule/schedulePageActions';
import $api from 'http/index';

export const useUpdatePublish = () => {
  const dispatch = useDispatch();

  const updatePublish = async (sheetId,workersIds) => {

      const res = await $api.post(
        `${process.env.REACT_APP_BASE_URL}/sheets/schedule/update-publish`,
        { sheetIds: sheetId,
          notifyWorkerIds: workersIds
        },
        getOptions()
      );

      dispatch(schedulePageActions.setUnpublishedSchedule(false));
      dispatch(schedulePageActions.setUnpublishedChanges(false));
      return res.data
  };

  return { updatePublish };
};
