import React, { useState, useEffect, useMemo } from 'react';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import styles from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { FacebookCircularProgress } from 'components/CircularProgress/CircularProgress';
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded';
import { format } from 'date-fns';
import $api from 'http/index';
import axios, { CancelToken } from 'axios';
import { getOptions } from 'helpers/getOptions';
import ConfirmDialog from 'components/ConfirmDialog';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';

export const deleteImage = async (sheetId, imageId) => {
  try {
    await $api.delete(
      `${process.env.REACT_APP_BASE_URL}/sheets/${sheetId}/images/${imageId}
    `,
      getOptions()
    );
  } catch (error) {
    console.error('Error delete image:', error);
  }
};

const uploadImage = async (data, sheetId, onUploadProgress, setError, cancelTokenSource) => {
  const formData = new FormData();
  formData.append('image', data.file);
  formData.append('_id', data.localId);

  setError((prev) => (prev ? false : prev));

  try {
    const response = await $api.post(
      `${process.env.REACT_APP_BASE_URL}/sheets/${sheetId}/images
    `,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-Auth-Token': localStorage.getItem('authorization-token'),
        },
        onUploadProgress: onUploadProgress,
        cancelToken: cancelTokenSource.token,
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
};

const ImageCard = ({ classes, data, index, sheetId, setPicturesToSend, hasAccess }) => {
const { dayDateSettingsFormat } = useDateSettingsFormat();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [confirmRemove, setConfirmRemove] = useState(null);
  const [error, setError] = useState(false);
  let totalSize = (data?.size || 0) / (1024 * 1024);
  const cancelTokenSource = useMemo(()=> axios.CancelToken.source(), []);

  const progressSetter = (progressEvent) => {
    setUploadProgress(progressEvent.loaded / 1024);
    totalSize = progressEvent.total / (1024 * 1024);
  };

  useEffect(() => {
    if (data.key || data._id) return; //already uploaded img
    uploadImage(data, sheetId, progressSetter, setError, cancelTokenSource)
      .then((uploaded) => {
        setPicturesToSend((prev) => {
          return prev.filter((el, i) => el.localId != data.localId);
        }); //filter current, this picture will be received via sockets
      })
      .catch((error) => {
        setError(error);
        console.error('Upload error:', error);
      })
      .finally(() => setUploadProgress(0));
  }, []);

  const handleDeleteImage = () => {
    if (data._id) {
      deleteImage(sheetId, data._id, setPicturesToSend).then((e) => {
        setPicturesToSend((prev) => prev.filter((image) => image._id !== data._id));
      });
    } else {
      cancelTokenSource.cancel('cancel by del');
      setPicturesToSend((prev) => prev.filter((image, i) => i !== index));
    }
    closeConfirmRemove();
  };

  const showConfirmRemove = () => setConfirmRemove(true);
  const closeConfirmRemove = () => setConfirmRemove(null);

  const calculatePercentage = (uploadedSize, totalSize) => {
    return ((uploadedSize * 1024) / (1024 * 1024) / totalSize) * 100;
  };

  return (
    <div>
      {!!error && (
        <RefreshRoundedIcon
          onClick={() =>
            uploadImage(data.file, sheetId, progressSetter, setError, cancelTokenSource)
          }
          className={classes.refreshI}
        />
      )}

      {!data?.createdAt && uploadProgress > 0 && (
        <FacebookCircularProgress progress={calculatePercentage(uploadProgress, totalSize)} />
      )}

      <div className={classes.positionerWrapper}>
        <div className={classes.imgWrapper}>
          <img
            className={classes.wlImg}
            src={
              data.key ? `${process.env.REACT_APP_BASE_URL}/sheets/image?key=${data?.x480 || data.key}` : data.img
            }
            alt="Image"
          />
        </div>
        {hasAccess && <div className={classes.delImg}>
          <CloseIcon fontSize={'small'} color={'primary'} onClick={() => showConfirmRemove()} />
        </div>}
      </div>

      {error ? (
        <Typography color="error" className={classnames(classes.regularText)}>
          {error && "Server doesn't respond"}
        </Typography>
      ) : (
        <Typography color="secondary" className={classnames(classes.regularText)}>
          {data?.createdAt ? 'Uploaded:' : 'Uploading:'}
        </Typography>
      )}
      <Typography className={classes.regularBold}>
        {data?.createdAt
          ? format(new Date(data.createdAt), dayDateSettingsFormat)
          : uploadProgress > 0
          ? `${parseInt(uploadProgress)}KB out of ${totalSize.toFixed(1)}MB`
          : null}
      </Typography>

      {confirmRemove && (
        <ConfirmDialog
          isOpen
          onSubmit={handleDeleteImage}
          onClose={closeConfirmRemove}
          text="Are you sure you want to delete this Image from the Worklog?"
        />
      )}
    </div>
  );
};

export default withStyles(styles)(ImageCard);
