export default theme => ({
  root: {
    padding: '10px 15px !important',
  },
  tabs: {
    borderBottom: '1px solid #e8e8e8',
    paddingTop: 0,
    marginBottom: 10,
    color: theme.palette.textColor.grey,
    '& button': {
      minWidth: '50%',
      maxWidth: '50%'
    },
    '& button:hover': {
      backgroundColor: 'transparent !important'
    },
    '& button.Mui-selected': {
      color: theme.palette.primary.blue,
    },
    '& span': {
      fontSize: '0.875rem',
      fontWeight: 500
    }
  }
});
