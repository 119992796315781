export default (theme) => ({
  dialogRoot: {
    minWidth: '100%',
    [theme.breakpoints.up('sm')]: {
      minWidth: 580,
    },
  },
  paddingBox: {
    padding: '0 20px',
  },
  marginBottom: {
    marginBottom: 10,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 20px 10px',
  },
  flexBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  cancelButton: {
    textTransform: 'capitalize',
    margin: '0 20px',
    minWidth: 100,
    '&:hover': {
      color: 'inherit',
    },
    '&:disabled': {
      backgroundColor: '#fff',
    },
  },
  saveButton: {
    textTransform: 'capitalize',
    margin: '0 20px',
    minWidth: 100,
    '&:hover': {
      color: theme.palette.textColor.primary,
    },
    '&:disabled': {
      backgroundColor: '#fff',
    },
  },
  textArea: {
    '& textarea': {
      boxShadow: 'none',
      padding: 10,
      fontSize: 14,
      height: '60px !important',
      maxHeight: '60px !important',
    },
  },
  closeButton: {
    position: 'absolute',
    right: 15,
    top: 12,
    color: theme.palette.textColor.dark,
    padding: 5,
    '&:hover': {
      backgroundColor: '#fff !important',
      color: `${theme.palette.textColor.dark} !important`,
    },
  },
  photoWrapper: {
    borderRadius: '50%',
    border: `1px solid ${theme.palette.textColor.blue}`,
    borderTopColor: 'transparent',
    borderBottomColor: 'transparent',
    width: 22,
    height: 22,
    transform: 'rotate(135deg)',
    minWidth: 22,
    cursor: 'pointer',
  },
  photo: {
    display: 'block',
    transform: 'rotate(-135deg)',
    height: 20,
    maxWidth: 20,
    borderRadius: '50%',
    margin: '0 auto',
  },
  inlineFlex: {
    display: 'inline-flex',
    alignItems: 'center',
    maxWidth: '100%',
  },
  name: {
    marginLeft: 15,
    wordBreak: 'break-word',
    fontSize: 14,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
  },
  day: {
    color: theme.palette.textColor.yellow,
  },
  night: {
    color: theme.palette.textColor.darkBlue,
  },
  inactive: {
    color: theme.palette.textColor.secondary,
  },
  dayBorder: {
    border: `1px solid ${theme.palette.textColor.yellow}`,
  },
  nightBorder: {
    border: `1px solid ${theme.palette.textColor.darkBlue}`,
  },
  inactiveBorder: {
    border: `1px solid ${theme.palette.textColor.secondary}`,
  },
  checkbox: {
    padding: 0,
    width: 10,
    height: 10,
    marginRight: 10,
    '&:hover': {
      background: theme.palette.background.default,
    },
    '&:focus': {
      background: theme.palette.background.default,
    },
  },
  extraBtn: {
    padding: 0,
    fontSize: 14,
    lineHeight: 1,
  },
  selectBtn: {
    '&:hover': {
      color: theme.palette.textColor.secondary,
      backgroundColor: 'rgba(255, 255, 255, 0.8) !important',
    },
    '&:focus': {
      color: theme.palette.textColor.secondary,
      backgroundColor: 'rgba(255, 255, 255, 0.8) !important',
    },
  },
  flexBoxClearBtn: {
    display: 'flex',
    paddingLeft: 15,
    marginTop: 6,
    width: 95,
    justifyContent: 'flex-start',
  },
});
