import { createAction } from '@reduxjs/toolkit';

const setDate = createAction('availability/setDate');
const setTimeoffsByType = createAction('availability/setTimeoffsByType');
const setAllUsers = createAction('availability/setAllUsers');
const setEquipmentEvents = createAction('availability/setEquipmentEvents');
const setAllEquipments = createAction('availability/setAllEquipments');
const setHolidays = createAction('availability/setHolidays');
const addEquipmentEvents = createAction('availability/equipmentEvents/add');
const updateEquipmentEvent = createAction('availability/equipmentEvents/update');
const removeEquipmentEvent = createAction('availability/equipmentEvents/remove');

export default {
  setDate,
  setTimeoffsByType,
  setAllUsers,
  setEquipmentEvents,
  setAllEquipments,
  setHolidays,
  addEquipmentEvents,
  updateEquipmentEvent,
  removeEquipmentEvent,
};
