import { combineReducers } from 'redux';
import timeclocks from './timeclocks';
import cip from './cip';
import worklogs from './worklogs';
import kiosk from './kiosk';
import availability from './availability';
import schedule from './schedule';
import auth from './auth';
import permissions from './permissions';
import settings from './settings';
import homePage from './homePage';
import supplies from './supplies';
import currentWorklog from './currentWorklog';
import projectTypes from './projectTypes';
import people from './people';
import personalProfile from './personalProfile';
import login from './login/loginReducer';
import contractors from './contractors/contractorsPageReducer';
import equipments from './equipment/equipmentPageReducer';
import notes from './notes/notesPageReducer'
import superadmin from './superadmin/superadminReducer';
import workerSchedule from './workerSchedule/workerSchedulePageReducer';
import modals from './modals/modalsPageReducer';


const rootReducer = combineReducers({
  timeclocks,
  cip,
  worklogs,
  availability,
  kiosk,
  schedule,
  auth,
  permissions,
  settings,
  homePage,
  supplies,
  currentWorklog,
  projectTypes,
  people,
  personalProfile,
  login,
  contractors,
  equipments,
  notes,
  superadmin,
  workerSchedule,
  modals,
});

export default rootReducer;
