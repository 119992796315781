export const prepareUpdates = (values, state) => {
  const updates = {
    ...values,
  };

  if (!state.line && updates.lines && updates.lines.length) {
    updates.lines = [];
  }
  if (!state.legend && updates.legends && updates.legends.length) {
    updates.legends = [];
  }
  if (!state.lettering && updates.letterings && updates.letterings.length) {
    updates.letterings = [];
  }
  if (!state.glassBead && updates.glassBeads && updates.glassBeads.length) {
    updates.glassBeads = [];
  }

  return updates;
};

export const fallbacksCleaner = (values) => {
  const updates = {
    ...values,
  };
  if (updates.lines?.length) {
    updates.lines.forEach((line) => {
      if (!line.homepageStats) delete line.homepageStats;
    });
  }

  if (updates.legends?.length) {
    updates.legends.forEach((legend) => {
      delete legend.testQuantity;
    });
  }

  return updates;
};
