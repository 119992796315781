import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  root: {
    height: '100%',
    padding: '20px 25px',
  },
  grid: {
    height: '100%',
  },
  header: {
    position: 'relative',
    display: 'block',
    marginBottom: 16,
  },
  title: {
    color: '#202020',
    fontSize: '24px',
    fontWeight: 500,
    letterSpacing: '0.3px',
    lineHeight: '35px',
    paddingRight: 34,
  },
  paper: {
    width: '100%',
    maxWidth: 570,
  },
  closeBtn: {
    position: 'absolute',
    right: -12,
    top: -12,
  },
  body: {},
  footer: {},
});
