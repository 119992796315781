function round(number, decimals) {
  var pow = Math.pow(10, decimals);
  return (Math.round(number * pow) / pow).toFixed(decimals);
}

export const materialProperties = {
  materials: [
    {
      name: 'Paint - Standard',
      id: 1,
      materialFunction: function(SF) {
        return Math.ceil(SF / 107);
      },
      unit: 'gal',
      beads: [
        {
          typeId: 4, // Type A
          beadFunction: function(material) {
            return Math.round(material * 7);
          }
        }
      ]
    },
    {
      name: 'Thermoplastic - Hot',
      id: 2,
      materialFunction: function(SF) {
        return Math.ceil(SF / 1);
      },
      unit: 'lb',
      beads: [
        {
          typeId: 4, // Type A
          beadFunction: function(material) {
            return Math.round(material / 10);
          }
        }
      ]
    },
    {
      name: 'Plural - Epoxy',
      id: 3,
      materialFunction: function(SF) {
        return Math.ceil(SF / 80);
      },
      unit: 'gal',
      beads: [
        {
          typeId: 4, // Type A
          beadFunction: function(material) {
            return Math.round(material * 10);
          }
        },
        {
          typeId: 5, // Type B
          beadFunction: function(material) {
            return Math.round(material * 10);
          }
        }
      ]
    },
    {
      name: 'Glass Beads - Type A',
      shortName: 'Type A Beads',
      id: 4,
      unit: 'lb',
      isBead: true
    },
    {
      name: 'Glass Beads - Type B',
      shortName: 'Type B Beads',
      id: 5,
      unit: 'lb',
      isBead: true
    },
    {
      name: 'Paint - Dusting',
      id: 6,
      duplicatesMaterialId: 1,
      materialFunction: function(SF) {
        return round(SF / 107 / 2, 2);
      },
      unit: 'gal',
      beads: [
        {
          typeId: 4, // Type A
          beadFunction: function(material) {
            return round(material * 7 / 2, 2);
          }
        }
      ]
    },
    {
      name: 'RPM - Permanent',
      id: 7,
      discreteItem: true,
      extraMaterialCertsIds: [14] // RPM Glue
    },
    {
      name: 'RPM - Lens Only',
      id: 8,
      discreteItem: true
    },
    {
      name: 'RPM - Temporary',
      id: 9,
      discreteItem: true,
      extraMaterialCertsIds: [14] // RPM Glue
    },
    {
      name: 'Plural - Polyurea',
      id: 10,
      materialFunction: function(SF) {
        return Math.ceil(SF / 64);
      },
      unit: 'gal',
      beads: [
        {
          typeId: 4, // Type A
          beadFunction: function(material) {
            return Math.round(material * 10);
          }
        },
        {
          typeId: 12, // E16 Visi
          beadFunction: function(material) {
            return Math.round(material * 7);
          }
        },
        {
          typeId: 13, // Elements
          beadFunction: function(material) {
            return Math.round(material * 5);
          }
        }
      ]
    },
    {
      name: 'Plural - MMA',
      id: 11,
      materialFunction: function(SF) {
        return Math.ceil(SF / 55);
      },
      unit: 'gal',
      beads: [
        {
          typeId: 4, // Type A
          beadFunction: function(material) {
            return Math.round(material * 10);
          }
        },
        {
          typeId: 5, // Type B
          beadFunction: function(material) {
            return Math.round(material * 10);
          }
        }
      ]
    },
    {
      name: 'Glass Beads - E16 Visi',
      shortName: 'E16 Visi Beads',
      id: 12,
      unit: 'lb',
      isBead: true
    },
    {
      name: 'Glass Beads - Elements',
      shortName: 'Elements Beads',
      id: 13,
      unit: 'lb',
      isBead: true
    },
    {
      name: 'RPM Glue',
      id: 14,
      doNotList: true
    },
    {
      name: 'Thermoplastic - Preformed',
      id: 15,
      discreteItem: true,
      hasSymbolField: true
    },
    {
      name: 'Tape - Permanent',
      id: 16,
      discreteItem: true,
      hasSymbolField: true
    },
    {
      name: 'Alternative Work',
      id: 17,
      otherItem: true
    },
    {
      name: 'Paint - Waterborne',
      id: 19,
      duplicatesMaterialId: 1,
      materialFunction: function(SF) {
        return Math.ceil(SF / 107);
      },
      unit: 'gal',
      beads: [
        {
          typeId: 4, // Type A
          beadFunction: function(material) {
            return Math.round(material * 7);
          }
        }
      ]
    },
    {
      name: 'Pavement Marking Removal',
      id: 20,
      noColor: true,
      materialFunction: function(SF) {
        return Math.ceil(SF / 80);
      },
      unit: 'gal'
    },
    {
      name: 'Tape - Temporary',
      id: 21,
      discreteItem: true,
      hasSymbolField: true
    },
  ],
  widths: [
    4,
    6,
    8,
    12,
    24
  ],
  widthUnits: [
    'inches'
  ],
  symbols: [
    {
      name:   'Straight Arrow',
      plural: 's',
      SF:     13.00
    },
    {
      name:   'Turn Arrow',
      plural: 's',
      SF:     20.00,
      deprecated: true
    },
    {
      name:   'Left Arrow',
      plural: 's',
      SF:     20.00
    },
    {
      name:   'Right Arrow',
      plural: 's',
      SF:     20.00
    },
    {
      name:   'Combination Arrow',
      plural: 's',
      SF:     32.00
    },
    {
      name:   'Wrong Way Arrow',
      plural: 's',
      SF:     24.12
    },
    {
      name:   'Lane Drop Arrow',
      plural: 's',
      SF:     42.00
    },
    {
      name:   'Elliptical Dot',
      plural: 's',
      SF:     14.73
    },
    {
      name:   'Intersection "+"',
      plural: '\'s',
      SF:     32.00
    },
    {
      name:   'BUS',
      plural: ' Symbols',
      SF:     29.30,
      removed: true
    },
    {
      name:   'ONLY',
      plural: ' Symbols',
      SF:     31.00,
      removed: true
    },
    {
      name:   'STOP',
      plural: ' Symbols',
      SF:     23.50,
      removed: true
    },
    {
      name:   'SLOW',
      plural: ' Symbols',
      SF:     35.84,
      removed: true
    },
    {
      name:   'SCHOOL',
      plural: ' Symbols',
      SF:     46.00,
      removed: true
    },
    {
      name:   'RxR',
      plural: ' Symbols',
      SF:     60.00
    },
    {
      name:   'AHEAD',
      plural: ' Symbols',
      SF:     45.89,
      removed: true
    },
    {
      name:   'ZONE',
      plural: ' Symbols',
      SF:     37.73,
      removed: true
    },
    {
      name:   'MPH',
      plural: ' Symbols',
      SF:     29.51,
      removed: true
    },
    {
      name:   'Yield Triangle 12"x18"',
      plural: ' Symbols',
      SF:     0.75
    },
    {
      name:   'Yield Triangle 24"x36"',
      plural: ' Symbols',
      SF:     3.00
    },
    {
      name:   'Slow Curve (24", Arrow, & SLOW)',
      plural: ' Symbols',
      SF:     99.84
    }
  ],
  symbol: {
    materialFunction: function(SF) {
      return Math.ceil(SF);
    },
    beadFunction: function(material) {
      return Math.round(material);
    }
  }
};
