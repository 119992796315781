import React, { useState } from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import _ from 'lodash';
import PageLoader from 'components/PageLoader';
import ConfirmDialog from 'components/ConfirmDialog';
import { getProjectName, hasPermissionsFor } from 'helpers/_helpers';
import { agoDate, shortDate } from 'helpers/_date-helpers';
import styles from './styles';
import worklogsPageActions from 'store/worklogs/worklogsPageActions';
import {
  fetchDeleteActiveSheet,
  fetchDeleteCrewSheet,
  setChangeArchivedActiveState,
  setChangeArchivedCrewState,
} from 'store/worklogs/worklogsPageOperations';
import GreyUserI from 'assets/images/user-photo-placeholder.svg';
import CloseIcon from 'assets/images/close-icon.svg';
import ArchiveIcon from 'assets/images/archive-icon.svg';
import UnarchiveIcon from 'assets/images/unarchive-icon.svg';
import LocationIcon from 'assets/images/location-icon.svg';
import { generateHoursObject } from 'helpers/helpers';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';
import { convertTimeObject } from 'common/dateFormatConfig';

const SheetCard = ({
  classes,
  sheet,
  openSnackbar,
  setForceUpdate,
  userId,
  fetchDeleteCrewSheet,
  fetchDeleteActiveSheet,
  setChangeArchivedActiveState,
  setChangeArchivedCrewState,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { dateFormatWithTime } = useDateSettingsFormat();
  const getTimeFormatHook = useTimeSettingsFormat();
  const setLoading = (value) => setIsLoading(value);

  const changeArchivedState = () => {
    const value = !sheet.archived;

    if (location.pathname.includes('active')) {
      setChangeArchivedActiveState(sheet._id, value, openSnackbar);
    }
    if (location.pathname.includes('worklog-foreman')) {
      setChangeArchivedCrewState(sheet._id, value, openSnackbar);
    }
    closeModal();
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsArchiveModalOpen(false);
  };

  const deleteSheet = async () => {
    if (location.pathname.includes('active')) {
      await fetchDeleteActiveSheet(sheet._id);
    }
    if (location.pathname.includes('worklog-foreman')) {
      await fetchDeleteCrewSheet(sheet._id);
    }
    openSnackbar('success', ' Successfully Deleted!');
    closeModal();
    // setForceUpdate(true);
  };

  const openDeleteModal = (e) => {
    e.stopPropagation();
    setIsModalOpen(true);
  };

  const openArchiveModal = (e) => {
    e.stopPropagation();
    setIsArchiveModalOpen(true);
  };

  const getImageSrc = (public_id) => {
    //   ? s3Client().getSignedUrl('getObject', params)
    //   : '/images/user-photo-placeholder.svg';
    return GreyUserI;
  };

  const getSheetStatus = () => {
    const { published, submittedAt, canceledAt } = sheet;
    if (!published) return 'Draft';
    if (submittedAt) {
      return `Submitted ${agoDate(submittedAt, null, dateFormatWithTime)}`;
    } else if (canceledAt) {
      return 'Canceled';
    } else return 'Published';
  };

  const getJobNumber = () => sheet.project.jobNumber;

  const getFormattedHoursRange = () => {
    const { createdAt, hours } = sheet;
    if (!hours || hours?.start === undefined || hours?.end === undefined) return;
    const { hourStart, minuteStart, amPmStart, dayStart, hourEnd, minuteEnd, amPmEnd, dayEnd } =
      generateHoursObject(hours.start, hours.end, createdAt, getTimeFormatHook);

    const startObj = [null, hourStart, minuteStart, amPmStart];
    const endObj = [null, hourEnd, minuteEnd, amPmEnd];

    const start = `${getTimeFormatHook(startObj).formattedTime} ${shortDate(
      createdAt,
      dayStart,
      getTimeFormatHook().localDayMonth
    )}`;
    const end = `${getTimeFormatHook(endObj).formattedTime} ${shortDate(
      createdAt,
      dayEnd,
      getTimeFormatHook().localDayMonth
    )}`;

    return `${start} \u2013 ${end}`;
  };

  const openWorklogDetails = () => {
    navigate(`/worklog/${params.type}/${sheet._id}`);
  };

  const hasFullAccess = (sheet) => {
    if (sheet.submittedAt)
      return hasPermissionsFor('worklogsFullAccess') || hasPermissionsFor('worklogsEditSubmitted');
    else return hasPermissionsFor('worklogsFullAccess') || hasPermissionsFor('worklogsEdit');
  };
  const sheetLocation = (sheet, isStandalone, locationId, fieldName) => {
    if (!sheet.project) return '';
    const allPlaces =
      fieldName === 'sheetRallyPoints'
        ? [...(sheet?.sheetRallyPoints || []), ...(sheet.project?.rallyPoints || [])]
        : [...(sheet?.sheetLocations || []), ...(sheet.project?.locations || [])];
    const location = _.find(allPlaces, { _id: locationId });
    if (isStandalone) {
      if (!location) return '';
      const locationZip = location.zipcode ? `, ${location.zipcode}` : '';
      return location.name || `${location.address}${locationZip}`;
    }
    const nameOrAddress = location.name || location.address;
    return location && nameOrAddress ? ', ' + nameOrAddress : '';
  };

  const openLocation = (sheet, locationId, fieldName) => () => {
    const allPlaces =
      fieldName === 'sheetRallyPoints'
        ? [...(sheet?.sheetRallyPoints || []), ...(sheet.project?.rallyPoints || [])]
        : [...(sheet?.sheetLocations || []), ...(sheet.project?.locations || [])];
    const location = _.find(allPlaces, { _id: locationId });
    const url = `https://www.google.com/maps/place/${location.address}`;
    window.open(url, '_blank', 'noreferrer');
  };

  const getIndividualStartTime = () => {
    const workerId = userId;
    const worker = sheet.workers.find(({ _id }) => _id === workerId);

    const startTime = (worker && worker.startTime) || sheet.startTime;

    const { hour, minute, amPm } = convertTimeObject(
      { hour: startTime.hour, minute: startTime.minute },
      getTimeFormatHook().is12Format
    );

    const hourObj = [null, hour, minute, amPm];

    return getTimeFormatHook(hourObj).formattedTime;
  };

  return (
    <>
      <PageLoader loading={isLoading} small>
        <Card className={classes.card} onClick={openWorklogDetails}>
          <Grid container className={classes.paddingBox}>
            <Grid
              item
              xs={12}
              className={classnames(classes.paddingBottom, classes.inlineFlex, classes.flexHeader)}
            >
              <div className={classes.photoWrapper}>
                <img
                  src={getImageSrc(sheet.project.contractor && sheet.project.contractor.logo)}
                  className={classes.photo}
                />
              </div>
              <div className={classnames(classes.flexColumn, classes.selfCenter)}>
                <Typography variant="h6" color="textPrimary" className={classes.name}>
                  {sheet.project.contractor && sheet.project.contractor.name}
                </Typography>
                {sheet.forceAccount && sheet.forceAccount !== 'No' && (
                  <div className={classes.flexWrapper}>
                    <Typography
                      variant="body2"
                      className={classnames(classes.forceAccount, classes.marginBottomNormal)}
                    >
                      T&M: {sheet.forceAccount}
                    </Typography>
                  </div>
                )}
              </div>
              <div className={classnames(classes.inlineFlex, classes.buttons)}>
                {hasFullAccess(sheet) && getSheetStatus() !== 'Draft' && (
                  <Button
                    className={classes.archiveButton}
                    onClick={openArchiveModal}
                    disableRipple
                  >
                    {sheet.archived ? (
                      <img src={UnarchiveIcon} alt="unarchive" />
                    ) : (
                      <img src={ArchiveIcon} alt="archive" />
                    )}
                  </Button>
                )}
                {hasFullAccess(sheet) && (
                  <Button
                    color="secondary"
                    className={classes.deleteButton}
                    onClick={openDeleteModal}
                    disableRipple
                  >
                    <img src={CloseIcon} alt="delete" />
                  </Button>
                )}
              </div>
            </Grid>
            <Grid item xs={12} className={classes.paddingBottom}>
              <Typography variant="h6" color="textPrimary">
                {getProjectName(sheet.project)}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.paddingBottom}>
              <Divider />
            </Grid>
            <Grid item xs={12} className={classes.paddingBottom}>
              <div
                className={classnames(
                  classes.inlineFlex,
                  classes.paddingBottom,
                  classes.itemsFlexStart
                )}
              >
                <Typography
                  variant="body1"
                  color="textSecondary"
                  className={classnames(
                    classes.mediumFontWeight,
                    sheet.locations && sheet.locations.length ? classes.paddingTopSmall : null
                  )}
                >
                  #{getJobNumber()}
                </Typography>
                <div
                  className={classnames(
                    classes.flexColumn,
                    classes.widthAuto,
                    classes.overflowHidden
                  )}
                >
                  {sheet.rallyPoints &&
                    !!sheet.rallyPoints.length &&
                    !!sheet.rallyPoints[0] &&
                    sheet.rallyPoints.map((locationId) => (
                      <div
                        className={classnames(
                          classes.locationFlex,
                          classes.paddingBottomSmall,
                          classes.fullWidth
                        )}
                        key={`${locationId}`}
                      >
                        <img
                          src={LocationIcon}
                          alt="location"
                          onClick={openLocation(sheet, locationId, 'sheetRallyPoints')}
                        />
                        <Typography
                          variant="body1"
                          color="textPrimary"
                          className={classes.location}
                        >
                          {sheetLocation(sheet, true, locationId, 'sheetRallyPoints')}
                        </Typography>
                      </div>
                    ))}
                  {sheet.locations &&
                    !!sheet.locations.length &&
                    !!sheet.locations[0] &&
                    sheet.locations.map((locationId) => (
                      <div
                        className={classnames(
                          classes.locationFlex,
                          classes.paddingBottomSmall,
                          classes.fullWidth
                        )}
                        key={`${locationId}`}
                      >
                        <img
                          src={LocationIcon}
                          alt="location"
                          onClick={openLocation(sheet, locationId, 'sheetLocations')}
                        />
                        <Typography
                          variant="body1"
                          color="textPrimary"
                          className={classes.location}
                        >
                          {sheetLocation(sheet, true, locationId, 'sheetLocations')}
                        </Typography>
                      </div>
                    ))}
                  {!Boolean(sheet.locations.length) && !Boolean(sheet.rallyPoints.length) && (
                    <div className={classes.locationFlex}>
                      <Typography variant="body1" color="textPrimary" className={classes.location}>
                        N/A
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
              <Typography
                variant="body1"
                color="textSecondary"
                className={classnames(
                  classes.mediumFontWeight,
                  getSheetStatus() === 'Canceled' ? classes.redText : classes.yellowText
                )}
              >
                {getSheetStatus()}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.paddingBottom}>
              <Divider />
            </Grid>
            {!!sheet.startTime && (
              <Grid item xs={12} md={6} className={classes.paddingBottom}>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  className={classnames(classes.regularFontWeight, classes.paddingBottomSmall)}
                >
                  Start Time:
                </Typography>
                <Typography variant="body1" color="textPrimary">
                  {getIndividualStartTime()}
                </Typography>
              </Grid>
            )}
            {!!sheet.travelTime && (
              <Grid item xs={12} md={6} className={classes.paddingBottom}>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  className={classnames(classes.regularFontWeight, classes.paddingBottomSmall)}
                >
                  Travel Time:
                </Typography>
                <Typography variant="body1" color="textPrimary">
                  {sheet.travelTime.hours}:{sheet.travelTime.minutes}
                </Typography>
              </Grid>
            )}
            {!!sheet.hours?.start && !!sheet.hours?.end && (
              <Grid item xs={12} className={classes.paddingBottom}>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  className={classnames(classes.regularFontWeight, classes.paddingBottomSmall)}
                >
                  On Job Time:
                </Typography>
                <Typography variant="body1" color="textPrimary">
                  {getFormattedHoursRange()}
                </Typography>
              </Grid>
            )}

            <Grid item xs={12} className={classes.paddingBottom}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                color="textSecondary"
                className={classnames(
                  classes.regularFontWeight,
                  !sheet.ownerUsername && classes.paddingBottom
                )}
              >
                Created on {agoDate(sheet.createdAtDate, null, dateFormatWithTime)} &#8226;
              </Typography>
              {sheet.ownerUsername && (
                <Typography
                  variant="body1"
                  color="textSecondary"
                  className={classnames(classes.regularFontWeight, classes.paddingBottom)}
                >
                  {sheet.ownerUsername}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Card>
      </PageLoader>

      {isModalOpen && (
        <ConfirmDialog
          isOpen={isModalOpen}
          onClose={closeModal}
          onSubmit={deleteSheet}
          text="Are you sure you want to delete this worklog? This action cannot be reversed."
          disableEscape
          loadingOnSubmit
        />
      )}

      {isArchiveModalOpen && (
        <ConfirmDialog
          isOpen={isArchiveModalOpen}
          onClose={closeModal}
          onSubmit={changeArchivedState}
          text={`Are you sure you want to ${
            sheet.archived ? 'reactivate' : 'archive'
          } this worklog?`}
          disableEscape
          loadingOnSubmit
        />
      )}
    </>
  );
};

SheetCard.propTypes = {
  classes: propTypes.object.isRequired,
  openSnackbar: propTypes.func.isRequired,
  setForceUpdate: propTypes.func.isRequired,
  sheet: propTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDeleteActiveSheet: (args) => {
      dispatch(fetchDeleteActiveSheet(args));
    },
    fetchDeleteCrewSheet: (args) => {
      dispatch(fetchDeleteCrewSheet(args));
    },
    setForceUpdate: (args) => {
      dispatch(worklogsPageActions.setForceUpdate(args));
    },
    setChangeArchivedActiveState: (...args) => {
      dispatch(setChangeArchivedActiveState(...args));
    },
    setChangeArchivedCrewState: (...args) => {
      dispatch(setChangeArchivedCrewState(...args));
    },
  };
};

const mapStateToProps = ({ personalProfile }) => ({
  userId: personalProfile.user && personalProfile.user._id,
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SheetCard));
