export const styles = (theme) => ({
  dialogRoot: {
    [theme.breakpoints.up('md')]: {
      maxWidth: 600,
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      margin: 0,
      // minHeight: '100%',
    },
  },
  cancelBtn: {
    width: 140,
    textTransform: 'none',
    color: theme.palette.textColor.secondary,
    background: theme.palette.background.default,
    '&:hover': {
      color: theme.palette.textColor.secondary,
      background: `${theme.palette.background.default} !important`,
    },
    '&:focus': {
      color: theme.palette.textColor.secondary,
      background: `${theme.palette.background.default} !important`,
    },
    '&:disabled': {
      background: `${theme.palette.background.default} !important`,
      borderColor: theme.palette.borderColor.secondary,
      color: theme.palette.secondary.main,
    },
  },
  confirmBtn: {
    width: 140,
    textTransform: 'none',
    color: theme.palette.textColor.primary,
    background: theme.palette.background.default,
    borderColor: theme.palette.borderColor.blue,
    '&:disabled': {
      background: theme.palette.background.default,
      borderColor: theme.palette.borderColor.secondary,
      color: theme.palette.secondary.main,
    },
    '&:hover': {
      background: theme.palette.background.blue,
      borderColor: theme.palette.borderColor.blue,
      color: theme.palette.textColor.white,
    },
    '&:focus': {
      background: theme.palette.background.blue,
      borderColor: theme.palette.borderColor.blue,
      color: theme.palette.textColor.white,
    },
  },
  stillHereBtn: {
    borderColor: '#fff',
  },
  stillHereBack: {
    width: 310,
    background: 'transparent',
    boxShadow: 'none',
    color: theme.palette.textColor.white,
  },
  error: {
    color: theme.palette.textColor.red,
    padding: '0 5px',
    fontSize: 11,
    marginTop: 8,
  },
});
