import React from 'react';
import '../../styles.scss';
import InfoItem from '../InfoItem/InfoItem';
import isCorrectNumber from '../helpers/isCorrectNumber';
import makeArrFromObj from '../helpers/makeArrFromObj';

function DispatcherRole({ data }) {
  return (
    <>
      {!!data && (
        <>
          <h2 className="info-block-title">Total Scheduled Projects</h2>
          {!!data.sheets && (
            <div className="cards-wrapper">
              {!!data.sheets.scheduled && (
                <>
                  <InfoItem
                    quantity={isCorrectNumber(data.sheets.scheduled.year)}
                    info={'This year'}
                  />

                  <InfoItem
                    quantity={isCorrectNumber(data.sheets.scheduled.month)}
                    info={'This month'}
                  />
                </>
              )}
              {!!data.sheets.submitted && (
                <>
                  <InfoItem
                    quantity={isCorrectNumber(data.sheets.submitted.year)}
                    info={'Scheduled Submitted'}
                  />
                  <InfoItem
                    quantity={isCorrectNumber(data.sheets.submitted.month)}
                    info={'Scheduled Submitted'}
                  />
                </>
              )}
            </div>
          )}

          <h2 className="info-block-title">Active Projects</h2>
          {!!data.projects && (
            <>
              <div className="cards-wrapper">
                {makeArrFromObj(data.projects).map((e) => (
                  <InfoItem key={e.title} quantity={isCorrectNumber(e.total)} info={e.title} />
                ))}
              </div>
            </>
          )}
          <h2 className="info-block-title">Material Certs Submitted YTD</h2>
          {!!data.certsSubmitted && (
            <div className="cards-wrapper">
              <InfoItem quantity={data.certsSubmitted.files} info={'Files submitted'} />
            </div>
          )}
        </>
      )}
    </>
  );
}

export default DispatcherRole;
