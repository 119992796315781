import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';

import PageLoader from '../../../../../../components/PageLoader';
import ContractorDetails from './components/ContractorDetails';
import styles from './styles';
import {
  postCreateContractor,
  postUpdateContractor,
} from 'store/contractors/contractorsPageOperations';
import { useDispatch } from 'react-redux';
import { validateEmail } from 'components/WorklogDetails/components/SubmitSection/components/SubmitModal/SubmitModal';

// TODO: turn on uploading logo

const validation = (values) => {
  const numberError = 'Phone number must be 10 digits';
  const correctEmail = 'Enter valid email address';

  const errors = {};
  if (!values.name.trim()) {
    errors.name = "This field can't be blank";
  }
  const newContact = {};

  if (
    values.newContact.name.trim() ||
    values.newContact?.phoneNumber?.trim() ||
    values.newContact?.email.trim() ||
    values.newContact?.title.trim()
  ) {
    if (!values.newContact.name.trim()) {
      newContact.name = "This field can't be blank";
    }
    if (
      values.newContact?.phoneNumber?.trim() &&
      !(values.newContact.phoneNumber.trim().length === 10)
    ) {
      newContact.phoneNumber = numberError;
    }
    if (values.newContact?.email.trim() && !validateEmail(values.newContact.email)) {
      newContact.email = correctEmail;
    }
  }

  if (Object.keys(newContact).length) {
    errors.newContact = newContact;
  }

  return errors;
};

const ContractorForm = ({ closeDrawer, openSnackbar, contractor, type, classes }) => {
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [isFileError, setIsFileError] = useState(false);
  const [fullyContractorContacts, setFullyContractorContacts] = useState([]);
  const [addForm, setAddForm] = useState(false);

  useEffect(() => {
    if (contractor) {
      setFullyContractorContacts((contractor?.contacts?.length && contractor.contacts) || []);
    }
  }, [contractor]);

  const handleFileChange = (e) => {
    if (!(e.target.files && e.target.files.length)) return;

    if (e.target.files[0].size > 3145728) {
      setIsFileError(true);
      return;
    }

    setFile(URL.createObjectURL(e.target.files[0]));
    setFileToUpload(e.target.files);
    setIsFileError(false);
  };

  const deleteLogo = () => {
    setFile(null);
    setFileToUpload(null);
  };

  const handleSelectContact = (setFieldValue) => (e) => {
    const id = e.target.value;

    if (id === 'add' || id === '') {
      handleContactAddForm(true);
      setFieldValue('selectedContactId', '');
      setFieldValue('newContact', {
        name: '',
        email: '',
        phoneNumber: '',
        title: '',
      });
      return;
    }

    handleContactAddForm(true);
    const currentFullyContact = fullyContractorContacts.find((el) => el._id === id);
    setFieldValue('newContact.name', currentFullyContact.name);
    setFieldValue('newContact.email', currentFullyContact.email);
    setFieldValue('newContact.phoneNumber', currentFullyContact.phoneNumber);
    setFieldValue('newContact.title', currentFullyContact.title);
    setFieldValue('selectedContactId', id);
  };

  const handleContactAddForm = (value) => {
    setAddForm(value);
  };

  const initialValues = contractor
    ? {
        name: contractor.name,
        nickname: contractor.nickname,
        contacts: [...((contractor?.contacts?.length && contractor.contacts) || [])],
        selectedContactId: '',
        newContact: {
          name: '',
          email: '',
          phoneNumber: '',
          title: '',
        },
        active: contractor.active,
      }
    : {
        name: '',
        nickname: '',
        contacts: [],
        selectedContactId: '',
        newContact: {
          name: '',
          email: '',
          phoneNumber: '',
          title: '',
        },
        active: true,
      };

  return (
    <div className={classes.formWrapper} role="presentation">
      <Formik
        validate={validation}
        validateOnChange={false}
        validateOnBlur={true}
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={async (values, { setSubmitting }) => {
          const data = {
            name: values.name,
            nickname: values.nickname,
            contacts: fullyContractorContacts.filter((el) => !!el),
            active: values.active,
          };
          if (type === 'create') {
            try {
              await dispatch(postCreateContractor(data));
              closeDrawer();
              openSnackbar('success', 'Successfully Added!');
            } catch (e) {
              openSnackbar('error', e.response.data.message);
              setSubmitting(false);
            }
          } else {
            try {
              await dispatch(postUpdateContractor(data, contractor._id));
              closeDrawer();
              openSnackbar('success', 'Successfully Updated!');
            } catch (e) {
              openSnackbar('error', e.response.data.message);
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          touched,
          values,
          handleChange,
          handleBlur,
          isSubmitting,
          setFieldValue,
          setErrors,
          validateForm,
        }) => (
          <PageLoader loading={isSubmitting}>
            <Form autoComplete="off" className={classes.formFlex}>
              <div>
                <Typography variant="h3" style={{ marginBottom: '40px' }}>
                  {type === 'create' ? 'Create' : 'Edit'} Contractor
                </Typography>

                <IconButton
                  className={classes.closeButton}
                  onClick={closeDrawer}
                  aria-label="Close"
                  disableTouchRipple
                >
                  <CloseIcon />
                </IconButton>

                <ContractorDetails
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  handleFileChange={handleFileChange}
                  file={file}
                  isFileError={isFileError}
                  deleteLogo={deleteLogo}
                  contractor={contractor}
                  setFieldValue={setFieldValue}
                  fullyContractorContacts={fullyContractorContacts}
                  handleSelectContact={handleSelectContact}
                  addForm={addForm}
                  handleContactAddForm={handleContactAddForm}
                  setFullyContractorContacts={setFullyContractorContacts}
                  validateForm={validateForm}
                  setErrors={setErrors}
                />
              </div>

              <div className={classes.buttonHolder}>
                <Button
                  color="secondary"
                  variant="outlined"
                  className={classes.cancelButton}
                  onClick={closeDrawer}
                  disabled={isSubmitting}
                  disableTouchRipple
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  variant="outlined"
                  className={classes.saveButton}
                  disabled={isSubmitting}
                  disableTouchRipple
                >
                  Save
                </Button>
              </div>
            </Form>
          </PageLoader>
        )}
      </Formik>
    </div>
  );
};

ContractorForm.propTypes = {
  classes: propTypes.object.isRequired,
  closeDrawer: propTypes.func.isRequired,
  openSnackbar: propTypes.func.isRequired,
  type: propTypes.string.isRequired,
  contractor: propTypes.object,
};

export default withStyles(styles)(ContractorForm);
