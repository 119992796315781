import React from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import photoPlaceholder from 'assets/images/user-photo-placeholder.svg';
import { getEquipmentColorClass } from 'helpers/_helpers';
import styles from './styles';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';

const ConflictItems = ({ classes, conflictResources, control, resourceType }) => {
  const equipmentList = useSelector((store) => store.schedule.resources.equipment);
  const usersList = useSelector((store) => store.schedule.resources.workers);
  // const usersList = useSelector((store) => store.schedule.resources.allWorkers);

  const userInfo = (id) => usersList.find(el=>el._id === id);

  const getEquipmentInfo = (field, id) => {
    const item = equipmentList.find(el=> el._id === id);
    if (!item) return '';
    if (field === 'number') {
      return parseInt(item.name);
    }
    if (field === 'className') {
      return getEquipmentColorClass(item.color);
    }
    return item[field];
  };

  return (
    <div>
      {conflictResources.map((r) => (
        <Grid container key={r} spacing={1} className={classes.flexBox}>
          <Grid item xs={12} md={6}>
            {resourceType === 'workers' ? (
              <div className={classes.inlineFlex}>
                <div
                  className={classnames(
                    classes.photoWrapper,
                    classes[`${userInfo(r)?.profile?.shifts?.timeOfDay?.toLowerCase()}Border`]
                  )}
                >
                  <img src={photoPlaceholder} className={classes.photo} />
                </div>
                <Typography
                  variant="body1"
                  color="textPrimary"
                  className={classnames(
                    classes.name,
                    classes[userInfo(r)?.profile?.shifts?.timeOfDay?.toLowerCase()]
                  )}
                >
                  {userInfo(r)?.username}
                </Typography>
              </div>
            ) : (
              <div
                className={classnames(classes.equipmentBlock, getEquipmentInfo('className', r))}
                style={{ backgroundColor: getEquipmentInfo('color', r) }}
              >
                <div className={classes.equipmentNumber}>{getEquipmentInfo('name', r)}</div>
              </div>
            )}
          </Grid>
          <Grid item xs={12} md={6} className={classes.textRight}>
            <Controller
              name={r}
              control={control}
              defaultValue="move"
              as={
                <RadioGroup className={classes.inlineFlex}>
                  <FormControlLabel
                    value="move"
                    label="Move"
                    labelPlacement="end"
                    control={
                      <Radio
                        color="primary"
                        className={classes.radioButton}
                        checkedIcon={
                          <span className={classnames(classes.icon, classes.checkedIcon)} />
                        }
                        icon={<span className={classes.icon} />}
                        disableRipple
                      />
                    }
                    className={classes.label}
                  />
                  <FormControlLabel
                    value="duplicate"
                    label="Duplicate"
                    labelPlacement="end"
                    control={
                      <Radio
                        color="primary"
                        className={classes.radioButton}
                        checkedIcon={
                          <span className={classnames(classes.icon, classes.checkedIcon)} />
                        }
                        icon={<span className={classes.icon} />}
                        disableRipple
                      />
                    }
                    className={classes.label}
                  />
                </RadioGroup>
              }
            />
          </Grid>
        </Grid>
      ))}
    </div>
  );
};

ConflictItems.propTypes = {
  classes: propTypes.object.isRequired,
  conflictResources: propTypes.array.isRequired,
  control: propTypes.object.isRequired,
  resourceType: propTypes.string.isRequired,
};

export default withStyles(styles)(ConflictItems);
