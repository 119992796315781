export default theme => ({
  textField: {
    [theme.breakpoints.up('lg')]: {
      // maxWidth: 250,
    },
  },
  error: {
    color: theme.palette.textColor.red,
    padding: '0 5px',
    fontSize: 11
  },
  requiredSign: {
    color: theme.palette.textColor.red
  },
  zeroPaddingTop: {
    paddingTop: '0px !important'
  },
  textArea: {
    width: '100%',
    '& textarea': {
      boxShadow: 'none',
      padding: 10,
      fontSize: 14,
      height: '60px !important',
      maxHeight: '60px !important',
      [theme.breakpoints.down('md')]: {
        height: '110px !important',
        maxHeight: '110px !important',
      },
    }
  },
  customSelect: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: 255,
    padding: 8
  },
  colorItemWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 30,
    height: 30,
    borderRadius: '8px',
    padding: 0,
    minHeight: 30,
    margin: 2
  },
  colorItem: {
    width: 28,
    height: 28,
    borderRadius: '8px',
    textAlign: 'center'
  },
  checkedOuter: {
    border: `1px solid ${theme.palette.borderColor.blue}`
  },
  checkedInner: {
    border: `1px solid ${theme.palette.background.default}`
  },
  checkedIcon: {
    color: theme.palette.background.default,
    fontSize: '20px',
    paddingTop: 5
  },
  valueColor: {
    width: 20,
    height: 20,
    borderRadius: '5px',
    padding: 0
  }
});
