import React, { Component } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import NotesHeader from './components/NotesHeader';
import ProjectNotes from './components/ProjectNotes';
import WorklogNotes from './components/WorklogNotes';
import NoteCreationForm from '../../components/NoteCreationForm';
import { hasPermissionsFor, checkUserRole } from 'helpers/_helpers';
import styles from './styles';
import { drawerTypes } from '../Equipment/components/helpers/drawerTypes';

class ProjectAndWorklogNotes extends Component {
  state = {
    tabValue: 0,
    drawer: {
      type: drawerTypes.create,
      isOpen: false,
    },
  };

  handleChangeTab = (event, newValue) => this.setState({ tabValue: newValue });

  openDrawer = (type, open) => () => {
    this.setState({ drawer: { type, isOpen: open } });
  };

  closeDrawer = () => {
    this.setState((prevState) => ({ drawer: { ...prevState.drawer, isOpen: false } }));
  };

  canSeeWorklogs = () => {
    const isFieldTechnician =
      checkUserRole('Field Technician') || checkUserRole('Project Management');
    const canEditWorklogs =
      hasPermissionsFor('worklogsFullAccess') || hasPermissionsFor('worklogsEdit');
    return isFieldTechnician || canEditWorklogs;
  };

  render() {
    const { classes, project } = this.props;
    const { drawer, tabValue } = this.state;
  return (
      <div className={classes.root}>
        <NotesHeader
          openDrawer={this.openDrawer}
          isAddButtonShown={tabValue === 0}
        />

        <Tabs value={tabValue} onChange={this.handleChangeTab} className={classes.tabs}>
          <Tab label="Project notes" disableRipple />
          {this.canSeeWorklogs() && <Tab label="Worklog notes" disableRipple />}
        </Tabs>
        {tabValue === 0 && (
          <ProjectNotes
            openSnackbar={this.props.openSnackbar}
          />
        )}
        {tabValue === 1 && (
          <WorklogNotes
            openSnackbar={this.props.openSnackbar}
          />
        )}

        <Drawer
          anchor="right"
          open={drawer.isOpen}
          classes={{
            paper: classes.drawer,
          }}
        >
          <NoteCreationForm
            onSuccess={this.closeDrawer}
            closeDrawer={this.closeDrawer}
            openSnackbar={this.props.openSnackbar}
            project={project}
          />
        </Drawer>
      </div>
    );
  }
}


export default withStyles(styles)(ProjectAndWorklogNotes);
