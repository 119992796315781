import { createAction } from '@reduxjs/toolkit';

const getPersonalProfile = createAction('personalProfile/getPersonalProfile');
const updatePersonalProfile = createAction('personalProfile/updatePersonalProfile');
const clearPersonalProfile = createAction('personalProfile/clearPersonalProfile');
const setSpecialProfilePermissions = createAction('personalProfile/setSpecialProfilePermissions');
const setOrganization = createAction('personalProfile/setOrganization');
const setOrganizationSettings = createAction('personalProfile/setOrganizationSettings');
const socketConnect = createAction('personalProfile/socketConnect');
const socketDisconnect = createAction('personalProfile/socketDisconnect');

export default {
  getPersonalProfile,
  updatePersonalProfile,
  clearPersonalProfile,
  setSpecialProfilePermissions,
  setOrganization,
  setOrganizationSettings,
  // personal profile sockets
  socketConnect,
  socketDisconnect,
};
