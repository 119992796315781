import React, { useState, useEffect } from 'react';
import NotesPage from './NotesPage';
import SnackBar from '../../components/SnackBar';
import { useDispatch } from 'react-redux';
import { getNotesProjects } from 'store/notes/notesPageOperations';

const NotesMainPage = () => {
  const dispatch = useDispatch()
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    text: '',
    type: 'success',
  });

  useEffect(() => {
    dispatch(getNotesProjects());
  }, []);

  const openSnackbar = ()=>  (type, text) => setSnackbar({ isOpen: true, type, text });

  const closeSnackbar = () => setSnackbar(prev => ({ ...prev, isOpen: false }));

  return (
    <>
      <NotesPage openSnackbar={openSnackbar} />
      <SnackBar closeSnackbar={closeSnackbar} {...snackbar} />
    </>
  );
};

export default NotesMainPage;
