import React, { useState, useEffect, useCallback } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';

import NotesCards from '../NotesCards';
import ProjectNoteEditingForm from '../ProjectNoteEditingForm';
import SortingAndFilteringPanel from '../SortingAndFilteringPanel';
import ResolveModal from '../ResolveModal';
import FiltersBar from '../../../../components/FiltersBar';
import PageLoader from '../../../../components/PageLoader';
import ConfirmDialog from '../../../../components/ConfirmDialog';
import styles from './styles';
import { drawerTypes } from '../../../Equipment/components/helpers/drawerTypes';
import $api from 'http/index';
import { getOptions } from 'helpers/getOptions';
import { getProjectNotes } from 'store/notes/notesPageOperations';
import { useDispatch, useSelector } from 'react-redux';
import notesPageActions from 'store/notes/notesPageActions';

const ProjectNotes = ({ classes, openSnackbar }) => {
  const dispatch = useDispatch()
  const {notes, filter, searchValue} = useSelector(store => store.notes)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [noteIdToDelete, setNoteIdToDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [noteIdToResolve, setNoteIdToResolve] = useState('');

  const [drawer, setDrawer] = useState({
    type: drawerTypes.filters,
    isOpen: false,
    note: {},
  });

  useEffect(() => {
    updateVisibleNotes();
  }, [filter, searchValue]);

  useEffect(() => {
    return () => dispatch(notesPageActions.setNotes([]));
  }, []);

  const updateSearchValue = (value) => dispatch(notesPageActions.setNotesSearchValue(value));

  const updateFilter = (filter) => dispatch(notesPageActions.setNotesFilter(filter));

  const updateVisibleNotes = useCallback(async () => {
    setIsLoading(true);
    dispatch(getProjectNotes())
      .then(() => {
        setDrawer((prev) => ({ ...prev, isOpen: false }));
      })
      .catch((e) => {
        openSnackbar('error', e?.response?.data?.message || e.message);
      });
    setIsLoading(false);
  }, []);

  const toggleModal = () => setIsModalOpen((prev) => !prev);

  const deleteNote = async () => {
    toggleModal();
    try {
      await $api.patch(
        `${process.env.REACT_APP_BASE_URL}/notes/${noteIdToDelete}/archive`,{},
        getOptions()
      );
      updateVisibleNotes();
      openSnackbar('success', 'The Note was archived successfully');
    } catch (e) {
      openSnackbar('error', e.reason);
    }
  };

  const deleteNoteHelper = useCallback(
    (id) => () => {
      toggleModal();
      setNoteIdToDelete(id);
    },
    []
  );

  const restoreNote = useCallback(
    (id) => async () => {
      try {
        await $api.patch(
          `${process.env.REACT_APP_BASE_URL}/notes/${id}/unarchive`, {},
          getOptions()
        );
        updateVisibleNotes();
        openSnackbar('success', 'The Note was restored successfully');
      } catch (e) {
        openSnackbar('error', e.reason);
      }
    },
    []
  );

  const openDrawer = useCallback(
    (type, open, note = {}) =>
      () => {
        setDrawer({ type, isOpen: open, note });
      },
    []
  );

  const closeDrawer = () => {
    setDrawer((prev) => ({ ...prev, isOpen: false, note: {} }));
  };

  const setNoteIdToResolveId = useCallback((id) => () => setNoteIdToResolve(id), []);

  return (
    <div className={classes.root}>
      <FiltersBar openDrawer={openDrawer} updateSearchValue={updateSearchValue} isDynamicSearch={true}/>
      {/* <PageLoader loading={isLoading}> */}
      <NotesCards
        openDrawer={openDrawer}
        notes={notes}
        deleteNote={deleteNoteHelper}
        restoreNote={restoreNote}
        setNoteIdToResolve={setNoteIdToResolveId}
      />
      {/* </PageLoader> */}

      <Drawer
        anchor="right"
        open={drawer.isOpen}
        classes={{
          paper: classes.drawer,
        }}
      >
        {drawer.type !== drawerTypes.filters ? (
          <ProjectNoteEditingForm
            closeDrawer={closeDrawer}
            openSnackbar={openSnackbar}
            note={drawer.note}
            deleteNote={deleteNoteHelper}
            restoreNote={restoreNote}
            setNoteIdToResolve={setNoteIdToResolveId}
          />
        ) : (
          <SortingAndFilteringPanel
            closeDrawer={closeDrawer}
            filter={filter}
            updateFilter={updateFilter}
            isLoading={isLoading}
            projectNotesPage
          />
        )}
      </Drawer>

      <ConfirmDialog
        isOpen={isModalOpen}
        onClose={toggleModal}
        onSubmit={deleteNote}
        text="Are you sure you want to archive this note?"
      />

      <ResolveModal
        isOpen={Boolean(noteIdToResolve)}
        noteId={noteIdToResolve}
        onClose={setNoteIdToResolveId(null)}
        closeDrawer={closeDrawer}
        updateVisibleNotes={updateVisibleNotes}
      />
    </div>
  );
};

ProjectNotes.propTypes = {
  notes: propTypes.array.isRequired,
  classes: propTypes.object.isRequired,
  openSnackbar: propTypes.func.isRequired,
};

export default withStyles(styles)(ProjectNotes);
