import React from 'react'
import styles from './styles'
import { withStyles } from '@material-ui/core/styles'

const Header = ({ classes, children }) => <header className={classes.header}>
  <p className={classes.title}>
    Supplies
  </p>
  {children}
</header>

export default withStyles(styles)(Header)
