export default (theme) => ({
  paddingBottom: {
    paddingBottom: 10,
  },
  photoWrapper: {
    borderRadius: '50%',
    border: `1px solid ${theme.palette.textColor.blue}`,
    borderTopColor: 'transparent',
    borderBottomColor: 'transparent',
    width: 22,
    height: 22,
    transform: 'rotate(135deg)',
    minWidth: 22,
    boxSizing: 'border-box',
  },
  photo: {
    display: 'block',
    transform: 'rotate(-135deg)',
    height: 20,
    maxWidth: 20,
    borderRadius: '50%',
    margin: '0 auto',
  },
  flexBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  inlineFlex: {
    display: 'inline-flex',
    alignItems: 'center',
    maxWidth: '100%',
  },
  hoursBlock: {
    width: '100%',
    padding: '4px 0',
    justifyContent: 'flex-end',
  },
  name: {
    marginLeft: 15,
    wordBreak: 'break-word',
    fontSize: 14,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  activeButton: {
    padding: 0,
    fontWeight: 300,
    color: theme.palette.textColor.primary,
    minWidth: 'auto',
    '&:hover': {
      color: theme.palette.textColor.primary,
      background: `${theme.palette.background.transparent} !important`,
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
      background: `${theme.palette.background.transparent} !important`,
    },
  },
  inactiveButton: {
    padding: 0,
    fontWeight: 300,
    color: theme.palette.textColor.secondary,
    minWidth: 'auto',
    '&:hover': {
      color: theme.palette.textColor.secondary,
      background: `${theme.palette.background.transparent} !important`,
    },
    '&:focus': {
      color: theme.palette.textColor.secondary,
      background: `${theme.palette.background.transparent} !important`,
    },
  },
  day: {
    color: theme.palette.textColor.yellow,
  },
  night: {
    color: theme.palette.textColor.darkBlue,
  },
  inactive: {
    color: theme.palette.textColor.secondary,
  },
  dayBorder: {
    border: `1px solid ${theme.palette.textColor.yellow}`,
  },
  nightBorder: {
    border: `1px solid ${theme.palette.textColor.darkBlue}`,
  },
  inactiveBorder: {
    border: `1px solid ${theme.palette.textColor.secondary}`,
  },
  clickable: {
    cursor: 'pointer',
  },
  gridCellWrapper: {
    display: 'flex',
    flexBasis: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  gridCell: {
    height: '25px',
    display: 'flex',
    alignItems: 'center',
  },
  gridsWrapper: {
    flexDirection: 'column',
    flexBasis: '100%',
  },
  rowWrapper: {
    marginBottom: 8,
    '& p': {
      marginBottom: 5,
    },
  },
  hidForMobileWrapper: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
});
