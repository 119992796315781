export default (theme) => ({
  root: {},
  userTitleContainer: {
    marginLeft: 14,
    marginRight: 44,
    [theme.breakpoints.down('md')]: {
      paddingBottom: 15,
      marginLeft: 15,
      marginRight: 15,
      marginTop: 10,
      width: '100%',
      borderBottom: '1px solid #e8e8e870',
    },
  },
  userTitleName: {
    color: '#202020',
    fontSize: 20,
    fontWeight: '500',
    letterSpacing: '0.25px',
    lineHeight: '30px',
    marginRight: 12,
  },
  userTitleNumber: {
    marginRight: 30,
    color: '#A4A5B3',
    fontFamily: 'Open Sans',
    fontSize: 14,
    letterSpacing: 0,
    lineHeight: '19px',
  },
  worklogWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    '@media print': {
      flexDirection: 'row',
    }
  },
  usernameGrid: {
    display: 'inline-block',
    margin: '0 10px 0 0',
    color: '#A4A5B3',
    fontFamily: 'Open Sans',
    fontSize: 14,
    fontWeight: 600,
    // lineHeight: 20,
    [theme.breakpoints.down('md')]: {
      display: 'block',
      marginBottom: 10,
    },
    '@media print': {
      display: 'inline-block',
      margin: '0 10px 0 0',
    }
  },
  usernameGridIndex: {
    display: 'inline-block',
    margin: 0,
    fontWeight: 500,
    fontSize: 16,
    // lineHeight: 20,
    [theme.breakpoints.down('sm')]: {
      // display: 'none',
    },
  },
  usernameCode: {
    display: 'inline-block',
    margin: '0 10px 0 0',
    color: '#A4A5B3',
    fontFamily: 'Open Sans',
    fontSize: 14,
    fontWeight: 500,
    [theme.breakpoints.down('md')]: {
      display: 'block',
      marginBottom: 10,
      marginRight: 0,
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: 35,
    },
    '@media print': {
      display: 'inline-block',
      margin: '0 10px 0 0',
    }
  },
  worklogUsername: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    [theme.breakpoints.down('md')]: {
      marginBottom: 20,
    },
    '@media print': {
      marginBottom: 0,
    }
  },
  timeclockWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '75%',
    [theme.breakpoints.down('md')]: {
      width: 'auto',
    },
    '@media print': {
      width: '75%',
    }
  },
  worklogUsernameWrapper: {
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    '@media print': {
      display: 'flex',
      flexDirection: 'column',
    }
  },
});
