import React from 'react';
import { Label, Select } from '../../base';
import Grid from '@material-ui/core/Grid';
import { ExpandMore } from '@material-ui/icons';
import { isIOS, Option } from 'components/Option';

const CipField = (props) => {
  const { range, label, style } = props;

  const rangeOptions = new Array(range).fill(1).map((el, i) => (
    <Option key={i} value={i + 1}>
      {i + 1}
    </Option>
  ));

  return (
    <>
      <Grid item xs style={style}>
        <Label>{label}</Label>
        <Select
          native={isIOS}
          fullWidth={true}
          IconComponent={ExpandMore}
          {...props.field}
          variant="outlined"
          inputProps={{ 'aria-label': 'Hours' }}
        >
          <Option key={0} value={null}>
            0
          </Option>
          {rangeOptions}
        </Select>
      </Grid>
    </>
  );
};

export default CipField;
