import React from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from './styles';

const PageLoader = ({ classes, loading, children, small }) => {
  return loading ? (
    <div className={small ? classes.smallHeight : classes.root} style={{ display: loading ? 'block' : 'none'}}>
      <div className={classes.progressWrapper}>
        <CircularProgress className={classes.progress} />
      </div>
      {children}
    </div>
  ) : children
};

PageLoader.propTypes = {
  classes: propTypes.object.isRequired,
  loading: propTypes.bool.isRequired,
  children: propTypes.element.isRequired,
  small: propTypes.bool
};

export default withStyles(styles)(PageLoader);
