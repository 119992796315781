import { useCallback, useEffect, useState } from 'react';
import TimeclockAPI from 'api/timeclock';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  patchEditTimeclockTime,
  deleteTimeclockTime,
  postCreateTimeclockTime,
  patchEditLunchTime,
} from 'store/timeclocks/timeclocksPageOperations';

const IS_WEEK = 'by-individual-week'

const useTimeclocks = (timeclocks) => {
  const { summary } = useSelector((state) => state.timeclocks);
  const dispatch = useDispatch();
  const {type} = useParams()
  const isByIndividualWeek = type === IS_WEEK
  const [data, setData] = useState(timeclocks);
  useEffect(() => {
    setData(timeclocks);
  }, [timeclocks]);
  const { enqueueSnackbar } = useSnackbar();
  
  const onRemove = useCallback(async (removingTimeclock) => {

    try {
      await dispatch(deleteTimeclockTime(removingTimeclock._id, removingTimeclock.user._id, isByIndividualWeek));
    } catch (err) {
      enqueueSnackbar('Error removing timeclock', { variant: 'error' });
      return console.error(err);
    }

    enqueueSnackbar('Timeclock removed successfully', {
      variant: 'success',
    });
  }, []);

    const onChangeLunchTime = async (updatingTimeclock) => {
      const { user, ...newTimeclock } = { ...updatingTimeclock };
      const currentTimeclock = data.find((t) => t._id === newTimeclock._id);

      if (newTimeclock.lunchTime !== currentTimeclock.lunchTime) {
        try{
          await dispatch(
            patchEditLunchTime(
              newTimeclock._id,
              {
                lunchTime: Number(newTimeclock.lunchTime),
              },
              newTimeclock.userId,
              isByIndividualWeek,
            )
          );
          enqueueSnackbar('Timeclock updated successfully', {
            variant: 'success',
          });
          } catch (err){
            enqueueSnackbar(err?.response?.data?.message || err.message, { variant: 'error', className: 'overriddenNotistackProviderStyles', });
          }
      }
    };

  const onCreate = useCallback(async (newTimeclock) => {
    const normalizedTimeclock = {
      inTime: newTimeclock.inTime,
      outTime: newTimeclock.outTime,
      userId: newTimeclock.user._id,
    };

    try {
      await dispatch(postCreateTimeclockTime(normalizedTimeclock, newTimeclock.user._id, isByIndividualWeek,));
    } catch (err) {
      enqueueSnackbar(err?.response?.data?.message || err.message, { variant: 'error', className: 'overriddenNotistackProviderStyles', });
      return console.error(err);
    }

    enqueueSnackbar('Successfully Created!', {
      variant: 'success',
    });
  }, []);

  const onChange = useCallback(
    async (updatingTimeclock) => {
      const { user, ...newTimeclock } = { ...updatingTimeclock };
      const currentTimeclock = data.find((t) => t._id === newTimeclock._id);

      if (newTimeclock.clockIn !== currentTimeclock.clockIn) {
        await TimeclockAPI.updateTime(newTimeclock._id, 'start', newTimeclock);
      }

      if (newTimeclock.clockOut !== currentTimeclock.clockOut) {
        await TimeclockAPI.updateTime(newTimeclock._id, 'end', newTimeclock);
      }

      if (newTimeclock.overrideClockIn === 0) {
        try {
          await TimeclockAPI.deleteOverride(newTimeclock._id, 'start');
        } catch (err) {
          enqueueSnackbar('Error deleting timeclock clock in override', {
            variant: 'error',
            className: 'overriddenNotistackProviderStyles',
          });
          return console.error(err);
        }

        delete newTimeclock.overrideClockIn;
      }

      if (newTimeclock.overrideClockOut === 0) {
        try {
          await TimeclockAPI.deleteOverride(newTimeclock._id, 'end');
        } catch (err) {
          enqueueSnackbar('Error deleting timeclock clock out override', {
            variant: 'error',
            className: 'overriddenNotistackProviderStyles',
          });
          return console.error(err);
        }

        delete newTimeclock.overrideClockOut;
      }

      try {
        await dispatch(
          patchEditTimeclockTime(
            newTimeclock._id,
            { inTime: newTimeclock.inTime, outTime: newTimeclock.outTime },
            newTimeclock.userId,
            isByIndividualWeek,
          )
        );

      } catch (err) {
        enqueueSnackbar('Error updating timeclock', {
          variant: 'error',
          className: 'overriddenNotistackProviderStyles',
        });
        return console.error(err);
      }

      enqueueSnackbar('Timeclock updated successfully', {
        variant: 'success',
      });
    },
    [data]
  );

  return {
    onChangeLunchTime,
    onRemove,
    onCreate,
    onChange,
  };
};


export default useTimeclocks;