import { useState, useMemo } from 'react';
import ViewHours from '../components/ViewHours';
import ViewTimeOff from '../components/ViewTimeOff';
import AddTimeOff from '../components/AddTimeOff';

export const useTabs = (fullAccess) => {

  const tabs = useMemo(
    () =>
      fullAccess
        ? [
            { title: 'View Hours', Component: ViewHours },
            { title: 'View Time Off', Component: ViewTimeOff },
            { title: 'Add Time Off', Component: AddTimeOff },
          ]
        : [
            { title: 'View Time Off', Component: ViewTimeOff },
            { title: 'Add Time Off', Component: AddTimeOff },
          ],
    [fullAccess]
  );

  const [currentTab, setCurrentTab] = useState(0);

  return {
    tabs,
    currentTab,
    setCurrentTab,
  };
};
