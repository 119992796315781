import React from 'react';
import Grid from '@material-ui/core/Grid';
import SearchInput from '../../../../../../components/form/SearchInput/SearchInput';
import FiltersButton from '../../../../../../components/core/FiltersButton/FiltersButton';

const FiltersBar = ({ onFiltersClick, ...props }) => (
  <Grid {...props} container spacing={2}>
    <Grid item xs={9}>
      <SearchInput placeholder={'Search'} />
    </Grid>
    <Grid item xs={3}>
      <FiltersButton onClick={onFiltersClick} />
    </Grid>
  </Grid>
);

export default FiltersBar;
