export default () => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  row: {},
  remove_btn: {
    marginLeft: 13,
    cursor: 'pointer',
  },
  edit_btn: {
    marginLeft: 20,
    cursor: 'pointer',
  },
});
