import { createAction } from '@reduxjs/toolkit';

const removeModal = createAction('modals/removeModal');
const closeAllModals = createAction('closeAllModals');
const closeModal = createAction('closeModal');
const addModal = createAction('addModal');
const addModals = createAction('addModals');
const openModal = createAction('openModal');
const setModalData = createAction('setModalData')

export default {
  closeAllModals,
  closeModal,
  addModal,
  addModals,
  openModal,
  removeModal,
  setModalData,
};
