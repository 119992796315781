export const styles = (theme) => ({
  jobItemWrapper: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexWrap: 'nowrap',
      flexDirection: 'column',
      borderRadius: '5px',
      border: '1px solid #d9dae3',
      padding: '22px 9px',
      margin: '0 -8px 15px',
    },
    '@media print': {
      display: 'flex',
      border: 'none',
      margin: '-8px',
      flexDirection: 'row',
      padding: '0',
    },
  },
  jobItemHeader: {
    [theme.breakpoints.down('md')]: {
      maxWidth: 'none',
      flexBasis: 'auto',
    },
    '@media print': {
      maxWidth: '25%',
      flexBasis: '25%',

    },
  },
  jobItemSubheader: {
    [theme.breakpoints.down('md')]: {
      borderBottom: '1px solid #e8e8e870',
      paddingBottom: '15px',
    },
    '@media print': {
      borderBottom: 'none',
      paddingBottom: '0px',
    }
  },
  jobItemSubheaderState: {
    margin: 0,
    lineHeight: '20px',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      margin: '0 45px 0 0',
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'right',
      margin: '0 35px 0 0',
    },
  },
  jobOnJobtimeWrapper: {
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    margin: 0,
    lineHeight: '20px',
    fontWeight: 700,
    [theme.breakpoints.down('md')]: {
      textAlign: 'start',
    },
  },
  desctopInfo: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    '@media print': {
      display: 'flex',
    },
  },
  main: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
    '@media print': {
      display: 'none',
    },
  },
  wrap: {
    padding: '0 8px',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Open Sans',
  },
  projectType: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '14px',
  },
  projectTypeBt: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginBottom: '12px'
  },
  flexBasis: {
    flexBasis: '33.3333%',
  },
  benefits: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '12px',
  },
  textTheme: {
    color: '#A4A5B3',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '19px',
  },
  subTextTheme: {
    margin: 0,
    color: '#202020',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
  },
});
