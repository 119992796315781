import React from 'react';
import '../../styles.scss';
import Profile from 'assets/images/profile.svg'

function InfoItemAvatar({ quantity, src }) {
  return (
    <div className="info-item-block-admin">
      <div className="info-admin-wrapper">
        <p className="info-quantity">{quantity}</p>
        <p className="info-text-black">Active Employees</p>
      </div>
      <div className="admin-avatar">
        <img src={Profile} alt="user photo" />
      </div>
    </div>
  );
}

export default InfoItemAvatar;
