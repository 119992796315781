import { useEffect, useState } from 'react';
import { getSettings } from '../helpers/getSettings';

export const useSettings = ({ name } = { name: 'defaultSettings' }) => {
  const [settings, setSettings] = useState(null);

  useEffect(() => {
    getSettings({ name })
      .then((res) => setSettings(res))
      .catch((e) => console.log(e));
  }, [name]);

  return { settings };
};
