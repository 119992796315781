import { createReducer } from '@reduxjs/toolkit';
import homePageActions from './homePageActions';
import { combineReducers } from 'redux';

const initialState = {
  homePage: {},
  selectedUser: {},
};

const homePage = createReducer(initialState.homePage, {
  [homePageActions.getHomePageData]: (_, { payload }) => payload,
});

const homePageSelectedUser = createReducer(initialState.selectedUser, {
  [homePageActions.getHomePageDataSelectedUser]: (_, { payload }) => payload,
});
export default combineReducers({ homePage, homePageSelectedUser });
