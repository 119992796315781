import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import SheetsList from '../SheetsList/SheetsList';
import SheetForm from '../SheetForm/SheetForm';
import SortingAndFilteringPanel from '../SortingAndFilteringPanel';
import WorklogDetails from 'components/WorklogDetails';
import ResourcesForm from 'components/ResourcesForm';
import CommentsForm from 'components/CommentsForm';
import MaterialForm from 'components/MaterialForm';
import FiltersBar from 'components/FiltersBar';
import PageLoader from 'components/PageLoader';
import TimeForm from 'components/WorklogDetails/components/TimeBlock/components/TimeForm/TimeForm';
import WeatherForm from 'components/WorklogDetails/components/WeatherBlock/components/WeatherForm/WeatherForm';
import NotesForm from 'components/WorklogDetails/components/NotesBlock/components/NotesForm/NotesForm';
import GeneralInfoForm from 'components/WorklogDetails/components/GeneralInfoBlock/components/GeneralInfoForm/GeneralInfoForm';
import { useDispatch, useSelector } from 'react-redux';
import { subYears, addDays } from 'date-fns';
import { getForemanDataWorklogs } from 'store/worklogs/worklogsPageOperations';
import worklogsPageActions from 'store/worklogs/worklogsPageActions';

const styles = {
  backdropOpacity: {
    opacity: '0 !important',
  },
};

const ForemanWorklogs = (props) => {
  const dispatch = useDispatch();
  const { classes, openSnackbar, params } = props;

  const { dataForeman: sheets, sheetIds, hasMore, forceUpdate } = useSelector((store) => store.worklogs);

  const [drawer, setDrawer] = useState({
    type: 'filters',
    isOpen: false,
    sheet: {},
  });
  const [additionalDrawer, setAdditionalDrawer] = useState({
    type: 'resources',
    subType: 'people',
    isOpen: false,
    material: {},
  });
  const [filter, setFilter] = useState({
    projectId: 'All',
    contractorId: 'All',
    dates: [subYears(new Date(), 1), addDays(new Date(), 1)],
    status: 'All',
    shift: 'All',
    forceAccount: 'All',
    rated: 'All',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (!forceUpdate) return
    const limit = page === 0 ?  40 : (page * 40)
    dispatch(getForemanDataWorklogs({ searchValue, filter, page, isCrewLeader: 'true', skip: '0', limit, isClear: true}))
  }, [forceUpdate]);

  useEffect(() => {
    return ()=>{
      dispatch(worklogsPageActions.resetWorklogs())
    }
  }, []);

  useEffect(() => {
    updateVisibleSheets();
  }, [searchValue, filter]);

  const updateSearchValue = (value) => {
    setPage(0);
    setSearchValue(value);
  };

  const updateFilter = (filter) => {
    setPage(0);
    setFilter(filter);
    setDrawer((prev) => ({
      ...prev,
      type: 'details',
      isOpen: false,
    }));
  };

  const updateVisibleSheets = async () => {
    setIsLoading(true);

    await dispatch(getForemanDataWorklogs({ searchValue, filter, page, isCrewLeader: 'true'}))
    setPage((prev) => prev + 1);

    setIsLoading(false);
  };

  const openDrawer =
    (type, sheet = {}) =>
    () => {
      setDrawer({ type, isOpen: true, sheet });
    };

  const closeDrawer = () => {
    setDrawer((prev) => ({ ...prev, type: 'details', isOpen: false }));
  };

  const openAdditionalDrawer =
    (type, subType, material = {}) =>
    () => {
      setAdditionalDrawer({ type, subType, isOpen: true, material });
    };

  const closeAdditionalDrawer = () => {
    setAdditionalDrawer((prev) => ({ ...prev, isOpen: false }));
  };

  const getFullRefreshedList = (sheet) => {
    dispatch(worklogsPageActions.setRefreshCrewSheet(sheet))
  };

  const sheetId = params.sheetId;

  return (
    <>
      <FiltersBar openDrawer={openDrawer} updateSearchValue={updateSearchValue} isDynamicSearch={true}/>

        <PageLoader loading={isLoading}>
          <SheetsList
            openSnackbar={openSnackbar}
            openDrawer={openDrawer}
            sheets={sheets}
            updateVisibleSheets={updateVisibleSheets}
            hasMore={hasMore}
            page={page}
            isLoading={isLoading}
          />
        </PageLoader>

      {sheetId && !isLoading &&  (
        <WorklogDetails
          openSnackbar={props.openSnackbar}
          updateVisibleSheets={getFullRefreshedList}
          openDrawer={openDrawer}
          openAdditionalDrawer={openAdditionalDrawer}
          updateDrawerFromRouter={closeDrawer}
          sheetIds={sheetIds}
          hasMore={hasMore}
          // pageCount={pageCount}
          showIndividualStartTime={true}
        />
      )}

      <Drawer
        anchor="right"
        open={drawer.isOpen}
        ModalProps={{
          BackdropProps: {
            classes: {
              root: sheetId ? classes.backdropOpacity : null,
            },
          },
        }}
      >
        {drawer.type === 'update' && (
          <SheetForm
            closeDrawer={closeDrawer}
            type={drawer.type}
            sheet={drawer.sheet}
            openSnackbar={props.openSnackbar}
          />
        )}
        {drawer.type === 'filters' && (
          <SortingAndFilteringPanel
            closeDrawer={closeDrawer}
            filter={filter}
            updateFilter={updateFilter}
            isLoading={isLoading}
          />
        )}
      </Drawer>

      <Drawer anchor="left" open={drawer.type === 'details' && additionalDrawer.isOpen}>
        {additionalDrawer.type === 'resources' && (
          <ResourcesForm closeDrawer={closeAdditionalDrawer} type={additionalDrawer.subType} openSnackbar={openSnackbar}/>
        )}
        {additionalDrawer.type === 'comments' && (
          <CommentsForm closeDrawer={closeAdditionalDrawer} openSnackbar={openSnackbar} />
        )}
        {additionalDrawer.type === 'material' && (
          <MaterialForm
            closeDrawer={closeAdditionalDrawer}
            openSnackbar={openSnackbar}
            type={additionalDrawer.subType}
            material={additionalDrawer.material}
          />
        )}
        {additionalDrawer.type === 'time' && (
          <TimeForm closeDrawer={closeAdditionalDrawer} openSnackbar={openSnackbar} />
        )}
        {additionalDrawer.type === 'weather' && <WeatherForm closeDrawer={closeAdditionalDrawer} />}
        {additionalDrawer.type === 'notes' && (
          <NotesForm closeDrawer={closeAdditionalDrawer} openSnackbar={openSnackbar} />
        )}
        {additionalDrawer.type === 'general' && (
          <GeneralInfoForm closeDrawer={closeAdditionalDrawer} />
        )}
      </Drawer>
    </>
  );
};

ForemanWorklogs.propTypes = {
  classes: propTypes.object.isRequired,
  openSnackbar: propTypes.func.isRequired,
};

export default withStyles(styles)(ForemanWorklogs);
