import React, { useState, useMemo } from 'react';
import { add, sub, format, startOfMonth } from 'date-fns';
import Picker from 'react-month-picker'
import classnames from 'classnames';
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import 'react-month-picker/css/month-picker.css';
import './../../styles.scss';
import { GLOBAL_DATE_FORMAT } from 'common/dateFormatConfig';

const MonthPicker = ({ onChange }) => {
  const [date, setDate] = useState(new Date());
  
  const dateValue = useMemo(() => ({ year: date.getFullYear(), month: date.getMonth() + 1 }), [date]);
  
  const minYear = new Date().getFullYear() - 10;
  const maxYear = { year: new Date().getFullYear(), month: new Date().getMonth + 1 };
  const minDate = sub(new Date(), { years: 10 });
  const maxDate = new Date();

  const nextArrActive = useMemo(() => (startOfMonth(date) < startOfMonth(maxDate)), [date]);
  const prevArrActive = useMemo(() => (startOfMonth(date) > startOfMonth(minDate)), [date]);

  const pickerLang = {
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ]
  };
  
  const handleAMonthChange = (year, month) => {
    const updatedDate = new Date().setFullYear(year, month - 1, 1);
    setDate(updatedDate);
    onChange(updatedDate);
  };

  const changeMonth = diff => () => {
    if (diff > 0 && !nextArrActive) return;
    if (diff < 0 && !prevArrActive) return;

    const updatedDate = add(date, { months: diff });
    setDate(updatedDate);
    onChange(updatedDate);
  };

  return (
    <div className="inlineBlock">
      <ArrowLeftIcon
        className={classnames("changeMonthIcon leftArrow", prevArrActive ? 'active' : 'disable')}
        onClick={changeMonth(-1)}
      />
      <ArrowRightIcon
        className={classnames("changeMonthIcon rightArrow", nextArrActive ? 'active' : 'disable')}
        onClick={changeMonth(1)}
      />
      <div className="edit">
        <Picker
          years={{ min: minYear, max: maxYear }}
          value={dateValue}
          lang={pickerLang.months}
          onChange={handleAMonthChange}
          >
          <div>
            <label style={{fontFamily: 'Poppins', fontSize: '18px', color:'#000'}}>
              {format(date, GLOBAL_DATE_FORMAT.fullMonthYear)}
            </label>
          </div>
        </Picker>
      </div>
    </div>
  );
}

export default MonthPicker;
