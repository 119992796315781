const { object, string, number, array, boolean } = require('yup');

const MaterialSchema = object().shape({
  _id: string(),
  name: string().required('Field is required'), // required
  type: string().required('Required'), // required enum['volume', 'weight', 'each']
  discreteHomepageStats: boolean().when('type', {
    is: 'each',
    then: (schema) => schema.default(false),
    otherwise: (schema) => schema.default(undefined),
  }), // default false
  customDescription: boolean(), // default false
  measurement: string().required('Required'), // enum['imperial', 'metric'] see MEASUREMENT_SYSTEM
  lines: array().of(
    object()
      .shape({
        width: number().required('Required'), // required
        ratio: number().required('Required'), // required
        homepageStats: string(), // enum['liquidMarking', 'eradication']
      })
      .default(undefined)
  ),
  legends: array().of(
    object()
      .shape({
        symbol: string().required('Required'), // required
        area: number().required('Required'), // required
        ratio: number().required('Required'), // required
      })
      .default(undefined)
  ),
  letterings: array().of(
    object()
      .shape({
        name: string().required('Required'), // required
        height: number().required('Required'), // required
        width: number().required('Required'), // required
        stroke: number().required('Required'), // required
        ratio: number().required('Required'), // required
        letterSet: object()
          .noUnknown(false)
          .required('All default letters should be specified')
          .default(undefined),
      })
      .default(undefined)
  ),
  glassBeads: array().of(
    object()
      .shape({
        beadId: string().required('Required'), // required
        ratio: number().required('Required'), // required
        unitType: string().required('Required'), // required enum['weight/volume', 'weight/area']
      })
      .default(undefined)
  ),
});

export default MaterialSchema;
