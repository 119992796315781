import React, { useState, useCallback } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, Dialog, Typography, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import PageLoader from '../../../PageLoader2';
import ConflictItems from './components/ConflictItems';
import AlertModal from '../../../AlertModal';
import styles from './styles';
import OverlappingModal from '../../../OverlapsingModal';
import { putWorklogEquipment } from 'store/currentWorklog/operations';
import { updateSheets } from 'store/schedule/schedulePageOperation';
// import { DateTime } from 'luxon';
import { putWorklogWorkers } from 'store/currentWorklog/operations';
import SnackBar from 'components/SnackBar';

const ResourcesConflictModal = ({
  classes,
  conflictResources,
  resourceType,
  closeModal,
  closeResourcesForm,
  conflictWorkers,
  data,
}) => {
  const sheet = useSelector((store) => store.currentWorklog.worklogSheet.sheet);
  const equipmentList = useSelector((store) => store.schedule.resources.equipment);
  const dispatch = useDispatch();
  const [currentConflictWorkers, setCurrentConflictWorkers] = useState(conflictWorkers);
  const { handleSubmit, control } = useForm({ mode: 'onBlur' });
  const [infoModal, setInfoModal] = useState({
    isOpen: false,
    text: '',
  });
  const [overlappingModal, setOverlappingModal] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    text: '',
    type: 'success',
  });
  const openSnackbar = useCallback((type, text) => setSnackbar({ isOpen: true, type, text }), []);
  const closeSnackbar = useCallback(() => setSnackbar((p) => ({ ...p, isOpen: false })), []);

  const closeInfoModal = () => setInfoModal({ isOpen: false, text: '' });
  const closeOverlappingModal = () => setOverlappingModal(null);

  const handleUpdateConflictWorkers = (workerId) => {
    const updatedConflictWorkers = currentConflictWorkers.filter(({ _id }) => _id !== workerId);
    setCurrentConflictWorkers(updatedConflictWorkers);
  };

  const saveOverlapping = () => {
    closeOverlappingModal();
    closeModal();
    closeResourcesForm();
  };

  const onSubmit = async (values) => {
    if (resourceType === 'equipment') {
      const updateData = data.map((el) => {
        if (values.hasOwnProperty(el._id)) {
          return { ...el, conflictAction: values[el._id] };
        }
        return el;
      });
      try {
        await dispatch(putWorklogEquipment({ equipment: updateData }, sheet._id));
        dispatch(updateSheets());

        closeResourcesForm();
      } catch (e) {
        if (e.response && e.response.data.type === 'sheet-time-overlapse') {
          setOverlappingModal({
            reason: e.response.data.message,
            data: e.response.data.data,
            type: e.response.data.resourceType,
          });
        } else if (e.response && e.response.data.type === 'timeoff-time-overlapse') {
          setInfoModal({ isOpen: true, text: e.response.data.message });
        } else if (e.response && e.response.data.type === 'calendar-event-time-overlapse') {
          setInfoModal({ isOpen: true, text: e.response.data.message });
        } else {
          openSnackbar('error', e.response.data.message || e.message);
        }
      }
    }
    if (resourceType === 'workers') {
      const updateData = data.map((el) => {
        if (values.hasOwnProperty(el._id)) {
          return { ...el, conflictAction: values[el._id] };
        }
        return el;
      });

      try {
        await dispatch(putWorklogWorkers({ workers: updateData }, sheet._id));
        dispatch(updateSheets());
        closeModal();
        closeResourcesForm();
      } catch (e) {
        if (e.response && e.response.data.type === 'sheet-time-overlapse') {
          setOverlappingModal({
            reason: e.response.data.message,
            data: e.response.data.data,
          });
        } else if (e.response && e.response.data.type === 'timeoff-time-overlapse') {
          setInfoModal({ isOpen: true, text: e.response.data.message });
        } else {
          openSnackbar('error', e.response.data.message || e.message);
        }
      }
    }
  };

  return (
    <>
      <Dialog
        open
        onClose={closeModal}
        PaperProps={{
          classes: {
            root: classes.dialogRoot,
          },
        }}
        backdropclick
        disableEscapeKeyDown
      >
        <div className={classes.formWrapper} role="presentation">
          <PageLoader loading={isSubmitting} small>
            <form autoComplete="off" className={classes.formFlex} onSubmit={handleSubmit(onSubmit)}>
              <div>
                <Typography variant="h4" style={{ marginBottom: '20px' }}>
                  Assigned resources
                </Typography>

                <IconButton
                  className={classes.closeButton}
                  onClick={closeModal}
                  aria-label="Close"
                  disableTouchRipple
                >
                  <CloseIcon />
                </IconButton>

                <ConflictItems
                  conflictResources={conflictResources}
                  control={control}
                  resourceType={resourceType}
                />
              </div>

              <div className={classes.buttonHolder}>
                <Button
                  color="secondary"
                  variant="outlined"
                  className={classes.cancelButton}
                  onClick={closeModal}
                  disabled={isSubmitting}
                  disableRipple
                  disableTouchRipple
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  variant="outlined"
                  className={classes.saveButton}
                  disabled={isSubmitting}
                  disableRipple
                  disableTouchRipple
                >
                  Save
                </Button>
              </div>
            </form>
          </PageLoader>
        </div>
        <SnackBar closeSnackbar={closeSnackbar} {...snackbar} />
      </Dialog>

      {infoModal.isOpen && (
        <AlertModal isOpen={infoModal.isOpen} info={infoModal.text} onClose={closeInfoModal} />
      )}
      {overlappingModal && (
        <OverlappingModal
          data={overlappingModal.data}
          message={overlappingModal.reason}
          onClose={closeOverlappingModal}
          onSave={saveOverlapping}
          handleUpdateConflictWorkers={handleUpdateConflictWorkers}
          type={overlappingModal?.type}
        />
      )}
    </>
  );
};

ResourcesConflictModal.propTypes = {
  classes: propTypes.object.isRequired,
  conflictResources: propTypes.array.isRequired,
  resourceType: propTypes.string.isRequired,
  allSheetsThisDay: propTypes.array.isRequired,
  sheet: propTypes.object.isRequired,
  closeModal: propTypes.func.isRequired,
  closeResourcesForm: propTypes.func.isRequired,
  conflictWorkers: propTypes.array.isRequired,
};

export default withStyles(styles)(ResourcesConflictModal);
