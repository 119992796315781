export const styles = (theme) => ({
  mobWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      border: '1px solid #d9dae3',
      borderRadius: '5px',
      width: '100%',
      padding: '6px 16px',
      margin: '0 0 15px',
    },
    '@media print': {
      border: 'none',
      width: 'auto',
      padding: '0',
      margin: '0',
    }
  },
  mobExplanation: {
    margin: 0,
    [theme.breakpoints.down('md')]: {
      minWidth: '176px',
    },
  },
});
