export default (theme) => ({
  cellContent: {},
  dayNumber: {
    fontSize: 30,
    lineHeight: 1.5,
    color: theme.palette.textColor.secondary,
    padding: '0 5px',
  },
  padding: {
    padding: '0 5px',
  },
  flexBox: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 0 10px',
    justifyContent: 'space-between',
  },
  flexBoxInline: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  userPhotoWrapper: {
    borderRadius: '50%',
    width: 21,
    height: 21,
    marginRight: 10,
  },
  dayBorder: {
    border: `1px solid ${theme.palette.textColor.yellow}`,
  },
  nightBorder: {
    border: `1px solid ${theme.palette.textColor.darkBlue}`,
  },
  inactiveBorder: {
    border: `1px solid ${theme.palette.textColor.secondary}`,
  },
  userPhoto: {
    display: 'block',
    height: 19,
  },
  name: {
    fontSize: 14,
    fontWeight: 500,
    maxWidth: 55,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  day: {
    color: theme.palette.textColor.yellow,
  },
  night: {
    color: theme.palette.textColor.darkBlue,
  },
  inactive: {
    color: theme.palette.textColor.secondary,
  },
  startTime: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.textColor.blue,
    border: `1px solid ${theme.palette.borderColor.blue}`,
    borderRadius: '10px',
    marginLeft: 5,
    width: 56,
    padding: '1px 0 2px',
    textAlign: 'center',
  },
  equipmentBlock: {
    borderRadius: '17px',
    minWidth: 34,
    height: 21,
    color: '#fff',
    maxWidth: 168,
  },
  equipmentNumber: {
    height: 19,
    fontSize: 14,
    fontWeight: 500,
    fontFamily: 'Poppins',
    padding: '3px 6px 0',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  list: {
    maxHeight: 200,
    overflowY: 'auto',
    padding: '0 5px',
  },
  fullWidth: {
    maxWidth: 136,
  },
  width1: {
    maxWidth: 75,
  },
  greenBackground: {
    backgroundColor: theme.palette.background.lightGreen,
    minHeight: '100%',
  },
  holidayName: {
    color: theme.palette.textColor.green,
    fontSize: 14,
    fontWeight: 500,
    textTransform: 'capitalize',
    padding: '0 5px 8px',
    wordBreak: 'break-word',
  },
  emptyCellHolidayName: {
    textAlign: 'center',
    marginTop: 40,
  },
  dayOff: {
    color: theme.palette.textColor.white,
    backgroundColor: theme.palette.background.greenBright,
    border: `1px solid ${theme.palette.background.greenBright}`,
  },
});
