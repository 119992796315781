export const headCells = [
  { id: 'fullName', align: 'left', sortable: true, label: 'User' },
  { id: 'companyName', align: 'left', sortable: true, label: 'Company'},
  { id: 'userType', align: 'left', sortable: true, label: 'User Type' },
  { id: 'status', label: 'Status' },
  { id: 'lastSignIn', sortable: true, label: 'Last Sign In' },
  { id: 'edit', label: 'Action' },
]; 


export const DRAWER_TYPES = {
  filters: 'superadmin_users_userFilters',
  editUser: 'superadmin_users_editUser',
  createUser: 'superadmin_users_createUser',
  assignToClient: 'superadmin_users_assignToClient',
}