import React from 'react';
import cs from 'classnames';
import { withStyles } from '@material-ui/core';
import styles from './styles';
import UploadIcon from 'assets/images/upload-icon.svg'

const UploadButton = ({ classes, className, onChange, value, file, error, setError, ...props }) => {
  const onFilesChange = (e) => {
    const value = e.target.files[0];
    if (value.type === 'application/pdf') {
      onChange(value);
    } else {
      setError('Wrong file type of file. Only *.pdf-file expected.');
    }
  };

  const uploadLabel = value ? 'Reupload PDF' : 'Upload PDF';

  return (
    <div className={cs(classes.root, className)}>
      <div className={classes.control}>
        <div className={classes.trigger}>
          <img alt="" src={UploadIcon} className={classes.icon} />
          {file ? file.name : uploadLabel}
        </div>
        <input
          type={'file'}
          accept={'application/pdf'}
          className={classes.input}
          onChange={onFilesChange}
          {...props}
        />
      </div>
      {error && <span className={classes.error}>{error.message}</span>}
    </div>
  );
};

export default withStyles(styles)(UploadButton);
