export const styles = (theme) => ({
  root: {
    margin: '0 0 20px',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  cipWrapper: {
    border: '1px solid #D9DAE3',
    margin: '-1px',
    borderRadius: '4px',
    [theme.breakpoints.down('md')]: {
      border: 'none',
    },
    '@media print': {
      border: '1px solid #D9DAE3',
    }
  },
  textName: {
    color: '#202020',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '25px',
  },
  cipTitleBlock: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    '@media print': {
      display: 'block',
    }
  },
  cipTitleMobileBlock: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      color: '#202020',
      fontSize: '20px',
      fontWeight: 500,
      letterSpacing: '0.25px',
      margin: '15px 0 18px 0',
    },
    '@media print': {
      display: 'none',
    }
  },
  cipContainer: {
    padding: '14px 16px 21px',
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
    '@media print': {
      padding: '14px 16px 21px',
    }
  },
});
