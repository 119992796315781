import { useSelector } from 'react-redux';
import { useMemo } from 'react';

export default () => {
  const { tabs, activeTab } = useSelector(state => state.timeclocks);
  const currentTab = useMemo(() => tabs.find(t => t.index === activeTab), [tabs, activeTab]);
  const weekly = useMemo(() => currentTab.name === 'by-individual-week', [currentTab]);

  return {
    tabs,
    activeTab,
    weekly,
  };
};
