import React, { useMemo } from 'react';
import useStyles from './useStyles';
import Grid from '@material-ui/core/Grid';
import { IconButton } from '@material-ui/core';
import { ChevronLeft, ChevronRight, Close } from '@material-ui/icons';
import { format } from 'date-fns';
import { GLOBAL_DATE_FORMAT } from 'common/dateFormatConfig';
import { parseStringDate } from 'helpers/_date-helpers';

export default ({ date, onPrev = () => null, onNext = () => null, onClose = () => null }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={1} justifyContent={'space-between'} alignItems={'center'} wrap={'nowrap'}>
        <Grid item xs>
          <span className={classes.title}>
            {format(parseStringDate(date), GLOBAL_DATE_FORMAT.fullMonthYear)}
          </span>
        </Grid>
        <Grid item xs={'auto'}>
          <IconButton size={'small'} onClick={() => onPrev()}>
            <ChevronLeft />
          </IconButton>
          <IconButton size={'small'} onClick={() => onNext()}>
            <ChevronRight />
          </IconButton>
        </Grid>
        <Grid item xs={'auto'}>
          <IconButton size={'small'} onClick={() => onClose()}>
            <Close />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
};
