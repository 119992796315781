import React from "react";
import {Typography, withStyles} from "@material-ui/core";

export default withStyles({
    body1: {
        color: '#A4A5B3',
        fontFamily: "Open Sans",
        fontSize: 14,
        letterSpacing: 0,
        lineHeight: '19px',
        marginBottom: 6
    }
})(Typography)
