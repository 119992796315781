import { createAction } from '@reduxjs/toolkit';

const getHICodes = createAction('hiCodes/getHICodes');
const postHICode = createAction('hiCodes/postHICode');
const putHICode = createAction('hiCodes/putHICode');
const deleteHICode = createAction('hiCodes/deleteHICode');
const snackBarInfo = createAction('hiCodes/snackBarInfo');
const setLoading = createAction('hiCodes/setLoading');

export default {
  getHICodes,
  postHICode,
  putHICode,
  deleteHICode,
  snackBarInfo,
  setLoading,
};
