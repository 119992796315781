import React, { useEffect, useRef } from 'react';
import './styles.scss';
import { startOfYear, addYears, format } from 'date-fns';
import { GLOBAL_DATE_FORMAT } from 'common/dateFormatConfig';

const CountDownTimer = ({
  deadline = addYears(startOfYear(new Date()), 1),
  // quantity = 134,
  title,
}) => {
  const days = useRef(null);
  const hours = useRef(null);
  const minutes = useRef(null);
  const seconds = useRef(null);

  useEffect(() => {
    function getTimeRemaining(endtime) {
      let t = Date.parse(endtime) - Date.parse(new Date());
      if (t <= 0) {
        return { total: 0, days: 0, hours: 0, minutes: 0, seconds: 0 };
      }
      let seconds = Math.floor((t / 1000) % 60);
      let minutes = Math.floor((t / 1000 / 60) % 60);
      let hours = Math.floor((t / (1000 * 60 * 60)) % 24);
      let days = Math.floor(t / 1000 / 60 / 60 / 24);
      return {
        total: t,
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      };
    }

    function initializeClock(id, endtime) {
      let daysSpan = days.current;
      let hoursSpan = hours.current;
      let minutesSpan = minutes.current;
      let secondsSpan = seconds.current;
      let timeinterval;
      function updateClock() {
        let t = getTimeRemaining(endtime);

        daysSpan.innerHTML = t.days < 10 ? `0${t.days}` : t.days;
        hoursSpan.innerHTML = t.hours < 10 ? `0${t.hours}` : t.hours;
        minutesSpan.innerHTML = t.minutes < 10 ? `0${t.minutes}` : t.minutes;
        secondsSpan.innerHTML = t.seconds < 10 ? `0${t.seconds}` : t.seconds;

        if (t.total <= 0) {
          clearInterval(timeinterval);
        }
      }
      updateClock();
      timeinterval = setInterval(updateClock, 1000);
    }
    // let deadline = new Date(Date.parse(new Date()) + 15 * 24 * 60 * 60 * 1000);
    initializeClock('countdown', deadline);
  }, []);

  return (
    <div className="countdown-wrapper">
      <h2 className="countdown-title">
        {title || `Count down to ${format( new Date(deadline), GLOBAL_DATE_FORMAT.fullDate)}`}
      </h2>
      <div id="countdown" className="countdown">
        <div className="countdown-number">
          <span ref={days} className="days countdown-time"></span>
          <span className="countdown-text">days</span>
        </div>
        <p className="separator">:</p>
        <div className="countdown-number">
          <span ref={hours} className="hours countdown-time"></span>
          <span className="countdown-text">hours</span>
        </div>
        <p className="separator">:</p>
        <div className="countdown-number">
          <span ref={minutes} className="minutes countdown-time"></span>
          <span className="countdown-text">minutes</span>
        </div>
        <p className="separator">:</p>
        <div className="countdown-number">
          <span ref={seconds} className="seconds countdown-time countdown-time-left-align"></span>
          <span className="countdown-text">seconds</span>
        </div>
      </div>
      {/* <div className="weather-block">
          <span className="info-quantity">{quantity}</span>
          <div className="weather-avatar">
            <WbSunny fontSize='large'/>
          </div>
          <p className="info-text-black">Striping days</p>
        </div> */}
    </div>
  );
};

export default CountDownTimer;
