import React, { useCallback, useState } from 'react'
import styles from './styles'
import { withStyles } from '@material-ui/core'
import cs from 'classnames'

const Input = React.forwardRef(({ classes, className, label, error, ...props }, ref) => {
  const [focused, setFocused] = useState(false)
  const [type, setType] = useState(props.type)
  const onFocus = useCallback((e) => {
    setFocused(true)
    props.onFocus && props.onFocus(e)
  }, [])

  const onBlur = useCallback((e) => {
    setFocused(false)
    props.onBlur && props.onBlur(e)
  }, [])

  const onShowClick = useCallback((e) => {
    e.nativeEvent.preventDefault()
    e.nativeEvent.stopPropagation()
    setType((t) => t === 'password' ? 'text' : 'password')
  }, [])

  return <label className={cs(className, classes.root)}>
    <span
      className={cs(classes.label, { [classes.labelActive]: focused, [classes.labelError]: error })}>
      {label}
    </span>
    <input {...props}
           type={type}
           ref={ref}
           className={cs(classes.input, {
             [classes.inputError]: error,
             [classes.inputNormal]: !error,
             [classes.inputFocus]: focused && !error
           })}
           onFocus={onFocus}
           onBlur={onBlur}/>
    {props.type === 'password' && <button className={classes.showBtn} onClick={onShowClick}>Show</button>}
  </label>
})

export default withStyles(styles)(Input)
