import React from 'react';
import '../../styles.scss';
import InfoItemAvatar from '../InfoItemAvatar/InfoItemAvatar';
import InfoItem from '../InfoItem/InfoItem';
import isCorrectNumber from '../helpers/isCorrectNumber';

function AccountingRole({ data }) {
  return (
    <>
      {data && data.totalWorkedHours && (
        <>
          <InfoItemAvatar quantity={isCorrectNumber(data.activeUsers)} />
          <h2 className="info-block-title">Total hours worked</h2>
          <div className="cards-wrapper">
            <InfoItem quantity={isCorrectNumber(data.totalWorkedHours.week)} info={'This week'} />
            <InfoItem quantity={isCorrectNumber(data.totalWorkedHours.month)} info={'This month'} />
          </div>
        </>
      )}
    </>
  );
}

export default AccountingRole;
