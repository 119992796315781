import React, { useEffect, useMemo, useState } from 'react';
import propTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DialogContentText, Typography } from '@material-ui/core';
import styles from 'components/ResourcesForm/styles';
import Divider from '@material-ui/core/Divider';

import ChangeTimeModal from 'components/OverlapsingModal/ChangeTimeModal';

import DialogContent from '@material-ui/core/DialogContent';
import classnames from 'classnames';
import photoPlaceholder from 'assets/images/user-photo-placeholder.svg';
import { format } from 'date-fns';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';
import { parseStringDate } from 'helpers/_date-helpers';
import { ChangeEquipTimeModal } from 'components/OverlapsingModal/ChangeTimeModal/ChageEquipTimeModal/ChangeEquipTimeModal';
import { getEquipmentColorClass } from 'helpers/_helpers';
const useStyles = makeStyles(styles);

export const OverlapsingModal = ({
  onClose,
  data,
  message,
  onSave,
  handleUpdateConflictWorkers,
}) => {
  const [changeTime, setChangeTime] = useState(null);
  const [changeEquipmentTime, setChangeEquipmentTime] = useState(null);
  const [overlappingData, setOverlappingData] = useState(data);
  const [overlappingMessage, setOverlappingMessage] = useState(message);
  const { dateFormat } = useDateSettingsFormat();

  useEffect(() => {
    setOverlappingData(data);
  }, [data]);

  useEffect(() => {
    setOverlappingMessage(message);
  }, [message]);

  const classes = useStyles();
  const showMessage =
    overlappingData.length === 1 &&
    (overlappingData[0]?.workerOverlapses?.length === 1 ||
      overlappingData[0]?.equipmentOverlapses?.length === 1);

  const handleChangeTime = (data) => () => {
    setChangeTime(data);
  };

  const handleEquipmentChangeTime = (data) => () => {
    setChangeEquipmentTime(data);
  };

  const closeChangeTime = () => {
    setChangeEquipmentTime(null);
    setChangeTime(null);
  };

  const saveChangeTime = () => {
    // if resolve workers
    const changeTimeUsername = changeTime.worker.username;
    const availableToChangeOverlappingData = [];
    overlappingData.forEach((day) => {
      if (day.date !== changeTime.date) return availableToChangeOverlappingData.push(day);
      if (day.equipmentOverlapses) return availableToChangeOverlappingData.push(day);

      const filteredDay = {
        ...day,
        workerOverlapses: day.workerOverlapses.filter(
          ({ worker }) => changeTime.worker._id !== worker._id
        ),
      };

      if (filteredDay.workerOverlapses.length) {
        availableToChangeOverlappingData.push(filteredDay);
      }
    });
    setChangeTime(null);
    setOverlappingData(availableToChangeOverlappingData);
    // if (handleUpdateConflictWorkers) handleUpdateConflictWorkers(changeTime.workerId);

    const userMessage = overlappingMessage
      .split('\n')
      .filter(
        (message) =>
          !(
            message.includes(changeTimeUsername) &&
            message.includes(format(parseStringDate(changeTime.date), dateFormat))
          )
      )
      .join('\n');
    setOverlappingMessage(userMessage);
    if (!availableToChangeOverlappingData.length) {
      return onSave ? onSave() : onClose();
    }
  };

  const saveEquipChangeTime = () => {
    // if resolve equip
    const equipmentName = changeEquipmentTime.equipment.name;
    const availableToChangeOverlappingData = [];

    overlappingData.forEach((day) => {
      if (day.date !== changeEquipmentTime?.date) return availableToChangeOverlappingData.push(day);
      if (day.workerOverlapses) return availableToChangeOverlappingData.push(day);

      const filteredDay = {
        ...day,
        equipmentOverlapses: day.equipmentOverlapses.filter(
          ({ equipment }) => changeEquipmentTime.equipment._id !== equipment._id
        ),
      };

      if (filteredDay.equipmentOverlapses.length) {
        availableToChangeOverlappingData.push(filteredDay);
      }
    });
    setChangeEquipmentTime(null);
    setOverlappingData(availableToChangeOverlappingData);
    // if (handleUpdateConflictWorkers) handleUpdateConflictWorkers(changeEquipmentTime.workerId);

    const userMessage = overlappingMessage
      .split('\n')
      .filter(
        (message) =>
          !(
            message.includes(equipmentName) &&
            message.includes(format(parseStringDate(changeEquipmentTime.date), dateFormat))
          )
      )
      .join('\n');
    setOverlappingMessage(userMessage);
    if (!availableToChangeOverlappingData.length) {
      return onSave ? onSave() : onClose();
    }
  };

  const resourcesReference = useMemo(() => {
    if(overlappingData.every(day => !day.equipmentOverlapses?.length)) return 'Users';
    if(overlappingData.every(day => !day.workerOverlapses?.length)) return 'Equipments';
    return 'Resources';
  }, [overlappingData])

  return (
    <>
      <Dialog
        open
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          classes: {
            root: classes.dialogRoot,
          },
        }}
      >
        <DialogTitle id="alert-dialog-title" disableTypography>
          {showMessage && <Typography variant="h3">{overlappingMessage.split(':')[0]}</Typography>}
          {!showMessage && <Typography variant="h3">{resourcesReference} already have worklogs</Typography>}
        </DialogTitle>
        <DialogContent>
          {showMessage && <DialogContentText>{overlappingMessage}</DialogContentText>}
          {!showMessage &&
            overlappingData.map((day, dayIndex) => {
              return (
                <div>
                  <div className={classes.dateItem}>
                    {format(parseStringDate(day.date), dateFormat)}
                  </div>
                  <div>
                    {day?.workerOverlapses?.map(({ worker }, i) => (
                      <div className={classes.inlineFlex}>
                        <div
                          className={classnames(
                            classes.photoWrapper,
                            classes[
                              `${
                                worker?.profile?.shifts?.timeOfDay?.toLowerCase() ||
                                worker?.shifts?.timeOfDay?.toLowerCase()
                              }Border`
                            ]
                          )}
                        >
                          <img src={photoPlaceholder} className={classes.photo} />
                        </div>
                        <Typography
                          variant="body1"
                          color="textPrimary"
                          className={classnames(
                            classes.name,
                            classes[
                              worker?.profile?.shifts?.timeOfDay?.toLowerCase() ||
                                worker?.shifts?.timeOfDay?.toLowerCase()
                            ]
                          )}
                        >
                          {worker.username}
                        </Typography>
                        <Button
                          variant="outlined"
                          color="primary"
                          className={classnames(classes.saveButton, classes.changeButton)}
                          disableTouchRipple
                          onClick={handleChangeTime({ ...day.workerOverlapses[i], date: day.date })}
                        >
                          Change Time
                        </Button>
                      </div>
                    ))}
                    {day?.equipmentOverlapses?.map(({ equipment }, i) => (
                      <div className={classes.inlineFlex}>
                        <div
                          className={classnames(
                            classes.equipmentBlock,
                            getEquipmentColorClass(equipment.color)
                          )}
                          style={{ backgroundColor: equipment.color }}
                        >
                          <div className={classes.equipmentNumber}>{equipment.name}</div>
                        </div>

                        <Button
                          variant="outlined"
                          color="primary"
                          className={classnames(classes.saveButton, classes.changeButton)}
                          disableTouchRipple
                          onClick={handleEquipmentChangeTime({
                            ...day.equipmentOverlapses[i],
                            date: day.date,
                          })}
                        >
                          Change Time
                        </Button>
                      </div>
                    ))}
                  </div>
                  <Divider style={{ margin: '20px 0' }} />
                </div>
              );
            })}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            variant="outlined"
            color="secondary"
            className={classes.cancelButton}
            disableTouchRipple
            style={{ margin: '20px auto' }}
          >
            Cancel
          </Button>
          {showMessage && (
            <Button
              onClick={() => {
                if (overlappingData[0]?.workerOverlapses?.[0]) {
                  setChangeTime({
                    ...overlappingData[0]?.workerOverlapses?.[0],
                    date: overlappingData[0].date,
                  });
                } else {
                  setChangeEquipmentTime({
                    ...overlappingData[0]?.equipmentOverlapses?.[0],
                    date: overlappingData[0].date,
                  });
                }
              }}
              variant="outlined"
              color="primary"
              className={classes.saveButton}
              disableTouchRipple
              style={{ margin: '20px auto' }}
            >
              Change Time
            </Button>
          )}
        </DialogActions>
      </Dialog>
      {changeTime && (
        <ChangeTimeModal
          save={saveChangeTime}
          data={changeTime}
          onClose={closeChangeTime}
          isDuplicate={true}
        />
      )}
      {changeEquipmentTime && (
        <ChangeEquipTimeModal
          save={saveEquipChangeTime}
          data={changeEquipmentTime}
          onClose={closeChangeTime}
          isDuplicate={true}
        />
      )}
    </>
  );
};

OverlapsingModal.propTypes = {
  message: propTypes.string.isRequired,
  onClose: propTypes.func.isRequired,
  data: propTypes.array.isRequired,
};
