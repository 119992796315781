import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  arrowBtn: {
    verticalAlign: 'sub',
    fontSize: '1.2rem',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
  },
  disabled: {
    color: theme.palette.textColor.secondary,
    cursor: 'default'
  },
}));
