import React from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import DatePickerInput from '../../../DatePickerInput';
import { getNextDay } from '../../../Kiosk/helpers/getNextDay';
import { validateDatesOverlapping } from '../../../Kiosk/helpers/validate';
import styles from './styles';
import TimeSelectForm from '../TimeSelectForm';
import CalendarTime from 'assets/images/calendar-time.svg';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';
import { validParse } from 'helpers/_date-helpers';
import { getEquipmentColorClass } from 'helpers/_helpers';


const CalendarEventDetails = ({
  classes,
  equipment,
  onRemove,
  errors,
  control,
  values,
  setValue,
  clearErrors,
  setError,
}) => {
  const { dateFormat } = useDateSettingsFormat();
  const getTimeFormatHook = useTimeSettingsFormat();

  const hasTimeoffError = (() => {
    if (errors.type && errors.type.type === 'timeOverlaps') return errors.type.message;

    const errorField = Object.keys(errors).find(
      (field) =>
        Array.isArray(errors[field]) &&
        ((errors[field][0] && errors[field][0].type === 'timeOverlaps') ||
          (errors[field][1] && errors[field][1].type === 'timeOverlaps'))
    );
    return errorField && errors[errorField].find((e) => e && e.type === 'timeOverlaps').message;
  })();

  const timeForms = [
    {
      label: 'Start Time',
      name: 'start',
    },
    {
      label: 'End Time',
      name: 'end',
    },
  ];

  return (
    <Grid container spacing={4} justifyContent="space-between" className={classes.root}>
      <Grid item xs={12}>
        <div className={classes.flexBox3}>
          <Typography className={classnames(classes.name, getEquipmentColorClass(equipment.color))} style={{ backgroundColor: equipment.color }}>{equipment.name}</Typography>
        </div>
      </Grid>
      <Grid item xs={12} md={6} className={classes.zeroPaddingTop}>
        <label htmlFor="startDate">
          <Typography variant="body1" color="textSecondary">
            Start Date:
          </Typography>
          <Controller
            name={'startDate[0]'}
            control={control}
            render={({ value, onChange }) => (
              <DatePickerInput
                selectedDay={value}
                setFieldValue={(_, value) => {
                  onChange(value);

                  const nextEndDate = getNextDay(validParse(value, dateFormat), dateFormat);
                  const nextEndHours = values.startHours[0];
                  const nextEndAmPm = values.startAmPm?.[0];
                  setValue('endDate[0]', nextEndDate);
                  setValue('endHours[0]', nextEndHours);
                  setValue('endAmPm[0]', nextEndAmPm);

                  values.startDate[1] &&
                    validateDatesOverlapping(
                      {
                        ...values,
                        startDate: [value, values.startDate[1]],
                        endDate: [nextEndDate, values.endDate[1]],
                        endHours: [nextEndHours, values.endHours[1]],
                        endAmPm: [nextEndAmPm, values.endAmPm[1]],
                      },
                      setError,
                      clearErrors,
                      getTimeFormatHook
                    );
                }}
                field="startDate"
              />
            )}
          />
        </label>
      </Grid>
      <Grid item xs={12} md={6} className={classes.zeroPaddingTop}>
        <label htmlFor="endDate">
          <Typography variant="body1" color="textSecondary">
            End Date:
          </Typography>
          <Controller
            name={'endDate[0]'}
            control={control}
            render={({ value, onChange }) => (
              <DatePickerInput
                selectedDay={value}
                setFieldValue={(_, value) => {
                  onChange(value);
                  values.endDate[1] &&
                    validateDatesOverlapping(
                      { ...values, endDate: [value, values.endDate[1]] },
                      setError,
                      clearErrors,
                      getTimeFormatHook
                    );
                }}
                field="endDate"
                minDate={getNextDay(validParse(values.startDate[0], dateFormat), dateFormat)}
              />
            )}
          />
        </label>
      </Grid>

      {timeForms.map((form) => (
        <TimeSelectForm
          classes={classes}
          values={values}
          setValue={setValue}
          index={0}
          control={control}
          label={form.label}
          name={form.name}
          errors={errors}
          setError={setError}
          clearErrors={clearErrors}
        />
      ))}

      {values.startDate[1] && (
        <>
          <Grid item xs={12} md={6} className={classes.zeroPaddingTop}>
            <label htmlFor="startDate">
              <Typography variant="body1" color="textSecondary">
                Start Date:
              </Typography>
              <Controller
                name="startDate[1]"
                control={control}
                render={({ value, onChange }) => (
                  <DatePickerInput
                    selectedDay={value}
                    setFieldValue={(_, value) => {
                      onChange(value);

                      const nextEndDate = getNextDay(validParse(value, dateFormat), dateFormat);
                      const nextEndHours = values.startHours[1];
                      const nextEndAmPm = values.startAmPm?.[1];
                      setValue('endDate[1]', nextEndDate);
                      setValue('endHours[1]', nextEndHours);
                      setValue('endAmPm[1]', nextEndAmPm);
                      validateDatesOverlapping(
                        {
                          ...values,
                          startDate: [values.startDate[0], value],
                          endDate: [values.endDate[0], nextEndDate],
                          endHours: [values.endHours[0], nextEndHours],
                          endAmPm: [values.endAmPm[0], nextEndAmPm],
                          getTimeFormatHook,
                        },
                        setError,
                        clearErrors,
                        getTimeFormatHook
                      );
                    }}
                    field="startDate1"
                  />
                )}
              />
            </label>
            {hasTimeoffError ? <div className={classes.error}>{hasTimeoffError}</div> : null}
          </Grid>
          <Grid item xs={12} md={6} className={classes.zeroPaddingTop}>
            <label htmlFor="endDate">
              <Typography variant="body1" color="textSecondary">
                End Date:
              </Typography>
              <Controller
                name="endDate[1]"
                control={control}
                render={({ value, onChange }) => (
                  <DatePickerInput
                    selectedDay={value}
                    setFieldValue={(_, value) => {
                      onChange(value);
                      validateDatesOverlapping(
                        { ...values, endDate: [values.endDate[0], value] },
                        setError,
                        clearErrors,
                        getTimeFormatHook
                      );
                    }}
                    field="endDate"
                    minDate={getNextDay(validParse(values.startDate[1], dateFormat), dateFormat)}
                  />
                )}
              />
            </label>
          </Grid>
          {timeForms.map((form) => (
            <TimeSelectForm
              classes={classes}
              type={values.type}
              values={values}
              setValue={setValue}
              index={1}
              control={control}
              label={form.label}
              name={form.name}
              errors={errors}
              setError={setError}
              clearErrors={clearErrors}
            />
          ))}
        </>
      )}

      <Grid item xs={12} className={classes.zeroPaddingTop}>
        <label>
          <button className={classes.removeButton} type={'button'} onClick={onRemove}>
            <img className={classes.removeButtonIcon} src={CalendarTime} />
            Remove Event
          </button>
        </label>
      </Grid>
    </Grid>
  );
};

CalendarEventDetails.propTypes = {
  classes: propTypes.object.isRequired,
  equipment: propTypes.object.isRequired,
  values: propTypes.object.isRequired,
  errors: propTypes.object.isRequired,
  onRemove: propTypes.func.isRequired,
  clearErrors: propTypes.func.isRequired,
  setError: propTypes.func.isRequired,
  setValue: propTypes.func.isRequired,
};

export default withStyles(styles)(CalendarEventDetails);
