import React, { Component } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import PageLoader from 'components/PageLoader';
import SubmitDetails from './components/SubmitDetails';
import SignatureModal from './components/SignatureModal';
import styles from './styles';

const validation = (values) => {
  const errors = {};
  if (values.dotSignature && !values.dotName.trim()) {
    errors.dotName = "This field can't be blank if there is a signature.";
  }
  if (values.contractorSignature && !values.contractorName.trim()) {
    errors.contractorName =
      "This field can't be blank if there is a signature.";
  }
  return errors;
};

class SubmitModal extends Component {
  state = {
    signatureModal: {
      isOpen: false,
      type: 'dot',
    },
  };

  openSignatureModal = (type) => () => {
    this.setState({
      signatureModal: { type, isOpen: true },
    });
  };

  closeSignatureModal = () => {
    this.setState((prevState) => ({
      signatureModal: { ...prevState.signatureModal, isOpen: false },
    }));
  };

  setSignature = (type, setFieldValue) => (data) => {
    setFieldValue(`${type}Signature`, data);
    this.closeSignatureModal();
  };

  removeSignature = (fieldName, setFieldValue) => () => {
    setFieldValue(`${fieldName}`, null);
  };

  render() {
    const { classes, closeModal, type, submissionId } = this.props;
    const { signatureModal } = this.state;
    const initialValues =
      type === 'dot'
        ? {
            dotName: '',
            dotEmail: '',
            dotSignature: null,
          }
        : {
            contractorName: '',
            contractorEmail: '',
            contractorSignature: null,
          };

    return (
      <Dialog
        open
        onClose={closeModal}
        PaperProps={{
          classes: {
            root: classes.dialogRoot,
          },
        }}
        disableEscapeKeyDown
      >
        <div className={classes.formWrapper} role="presentation">
          <Formik
            validate={validation}
            validateOnChange={false}
            validateOnBlur={true}
            initialValues={initialValues}
            enableReinitialize={false}
            onSubmit={(values, { setSubmitting }) => {
              const data = {};
              const dot = {
                name: values.dotName,
                email: values.dotEmail,
                image: values.dotSignature,
                time: new Date(),
              };
              const contractor = {
                name: values.contractorName,
                email: values.contractorEmail,
                image: values.contractorSignature,
                time: new Date(),
              };
              if (values.dotName) data['signatures.dot'] = dot;
              if (values.contractorName)
                data['signatures.contractor'] = contractor;

                setSubmitting(false);
                closeModal();
            }}
          >
            {({
              errors,
              touched,
              values,
              handleChange,
              handleBlur,
              isSubmitting,
              setFieldValue,
            }) => (
              <PageLoader loading={isSubmitting}>
                <Form autoComplete="off" className={classes.formFlex}>
                  <div>
                    <Typography variant="h3" style={{ marginBottom: '10px' }}>
                      Update submit
                    </Typography>

                    <IconButton
                      className={classes.closeButton}
                      onClick={closeModal}
                      aria-label="Close"
                      disableTouchRipple
                    >
                      <CloseIcon />
                    </IconButton>

                    <SubmitDetails
                      values={values}
                      errors={errors}
                      touched={touched}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      openSignatureModal={this.openSignatureModal}
                      removeSignature={this.removeSignature}
                      type={type}
                    />
                  </div>

                  <div className={classes.buttonHolder}>
                    <Button
                      color="secondary"
                      variant="outlined"
                      className={classes.cancelButton}
                      onClick={closeModal}
                      disabled={isSubmitting}
                      disableTouchRipple
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      variant="outlined"
                      className={classes.saveButton}
                      disabled={isSubmitting}
                      disableTouchRipple
                    >
                      Submit
                    </Button>
                  </div>

                  {signatureModal.isOpen && (
                    <SignatureModal
                      closeModal={this.closeSignatureModal}
                      submitModal={this.setSignature(
                        signatureModal.type,
                        setFieldValue
                      )}
                      type={signatureModal.type}
                    />
                  )}
                </Form>
              </PageLoader>
            )}
          </Formik>
        </div>
      </Dialog>
    );
  }
}

SubmitModal.propTypes = {
  classes: propTypes.object.isRequired,
  closeModal: propTypes.func.isRequired,
  type: propTypes.string.isRequired,
  submissionId: propTypes.string.isRequired,
};

export default withStyles(styles)(SubmitModal);
