import React from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { format, parse, subDays, addDays } from 'date-fns';

import IOSSwitch from '../../../../../../components/IOSSwitch';
import DatePickerInput from '../../../../../../components/DatePickerInput';
import CustomRadioButton from '../../../../../../components/CustomRadioButton';
import styles from './styles';
import { isIOS, Option } from '../../../../../../components/Option';
import { Controller } from 'react-hook-form';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';
import TimeForm from '../TimeForm/TimeForm';

const HolidayDetails = ({
  classes,
  errors,
  control,
  getValues,
  handleChangeHolidayType,
  setValue,
  watch,
  selectedTime,
  setSelectedTime,
  toggleCalendar,
  setError,
  clearErrors,
}) => {
  const values = getValues();
  const { dateFormat } = useDateSettingsFormat();

  const createHoursArray = () => {
    const emptyArray = new Array(8);
    return emptyArray.fill(1).map((el, i) => (
      <Option key={i} value={String(i + 1)}>
        {i + 1}
      </Option>
    ));
  };
  const createMinutesArray = () => {
    const emptyArray = new Array(12);
    return emptyArray.fill(1).map((el, i) => (
      <Option key={i} value={String(i * 5).length === 1 ? '0' + i * 5 : String(i * 5)}>
        {String(i * 5).length === 1 ? '0' + i * 5 : String(i * 5)}
      </Option>
    ));
  };

  const watchStartDate = watch('startDate');
  const watchEndDate = watch('endDate');

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} className={classes.zeroPaddingTopBottom}>
        <label htmlFor="individual">
          <Typography variant="body1" color="textSecondary">
            Applied To:
          </Typography>
          <Controller
            name="individual"
            control={control}
            defaultValue="false"
            as={
              <TextField
                select
                id="individual"
                name="individual"
                error={!!errors.individual || !!errors.individual}
                variant="outlined"
                SelectProps={{
                  native: isIOS,
                }}
                fullWidth
              >
                <Option value="false">Companywide</Option>
                <Option value="true">Individual(s)</Option>
              </TextField>
            }
          />
        </label>
      </Grid>
      <Grid item xs={12} className={classes.zeroPaddingTop}>
        <label htmlFor="holidayType">
          <Controller
            name="holidayType"
            control={control}
            defaultValue="oneDay"
            render={({ onChange, value }) => (
              <RadioGroup
                id="holidayType"
                value={value}
                onChange={(e, v) => {
                  onChange(v);
                  handleChangeHolidayType();
                }}
                className={classes.flexBox2}
                row
              >
                <FormControlLabel
                  value="oneDay"
                  control={<CustomRadioButton />}
                  label="One day"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="period"
                  control={<CustomRadioButton />}
                  label="Period"
                  labelPlacement="end"
                />
              </RadioGroup>
            )}
          />
        </label>
      </Grid>
      <TimeForm
        selectedTime={selectedTime}
        setSelectedTime={setSelectedTime}
        openCalendar={(v) => () => toggleCalendar(v)}
        formValues={values}
        setError={setError}
        clearErrors={clearErrors}
        errors={errors}
      />
      {/* <Grid item xs={12} md={12} className={classes.zeroPaddingTop}>
        <label htmlFor="startDate">
          <Typography variant="body1" color="textSecondary">
            Start Date:
          </Typography>
          <Controller
            name="startDate"
            control={control}
            defaultValue={format(new Date(), dateFormat)}
            render={({ onChange, value }) => (
              <DatePickerInput
                selectedDay={value}
                setFieldValue={(field, v) => onChange(v)}
                field="startDate"
                maxDate={
                  values.holidayType === 'period'
                    ? format(subDays(parse(watchEndDate, dateFormat, new Date()), 1), dateFormat)
                    : null
                }
              />
            )}
          />
        </label>
      </Grid> */}
      {/* {values.holidayType === 'period' && (
        <Grid item xs={12} md={12} className={classes.zeroPaddingTop}>
          <label htmlFor="endDate">
            <Typography variant="body1" color="textSecondary">
              End Date:
            </Typography>
            <Controller
              name="endDate"
              control={control}
              defaultValue={format(
                addDays(parse(watchStartDate, dateFormat, new Date()), 1),
                dateFormat
              )}
              render={({ onChange, value }) => (
                <DatePickerInput
                  selectedDay={value}
                  setFieldValue={(field, v) => onChange(v)}
                  field="endDate"
                  minDate={format(
                    addDays(parse(watchStartDate, dateFormat, new Date()), 1),
                    dateFormat
                  )}
                  alignRight
                />
              )}
            />
          </label>
        </Grid>
      )} */}
      <Grid item xs={12} className={classes.zeroPaddingTop}>
        <label htmlFor="name">
          <Typography variant="body1" color="textSecondary">
            Description:
          </Typography>
          <Controller
            name="name"
            control={control}
            defaultValue=""
            rules={{
              required: "This field can't be blank",
            }}
            as={
              <TextField
                id="name"
                error={!!errors.name}
                className={classes.textField}
                variant="outlined"
                fullWidth
              />
            }
          />
          {errors.name ? <div className={classes.error}>{errors.name.message}</div> : null}
        </label>
      </Grid>
      <Grid item xs={12} className={classes.zeroPaddingTop}>
        <div className={classes.buttonsWrapper}>
          <Typography variant="body1" color="textSecondary">
            PTO (paid time off):
          </Typography>
          <Controller
            name="isPaid"
            control={control}
            defaultValue={false}
            render={({ onChange, value }) => (
              <IOSSwitch checked={value} onChange={(a, b) => onChange(b)} value={value} />
            )}
          />
        </div>
      </Grid>

      {getValues().holidayType !== 'period' && (
        <Grid item xs={12} className={classes.zeroPaddingTopBottom}>
          <div>
            <Typography variant="body1" color="textSecondary">
              PTO time:
            </Typography>
            <div className={classes.timeSelectWrapper}>
              <div className={classes.timeSelectFields}>
                <Controller
                  name="hours"
                  control={control}
                  defaultValue="8"
                  rules={{
                    validate: () => {
                      const values = getValues();
                      return (
                        +values.hours !== 8 ||
                        +values.minutes === 0 ||
                        "Holiday duration can't be longer than 8 hours"
                      );
                    },
                  }}
                  as={
                    <TextField
                      select
                      id="hours"
                      name="hours"
                      error={!!errors.hours || !!errors.minutes}
                      variant="outlined"
                      SelectProps={{
                        native: isIOS,
                      }}
                      fullWidth
                    >
                      {createHoursArray()}
                    </TextField>
                  }
                />
                <Typography variant="body1" color="textPrimary">
                  &nbsp; hours
                </Typography>
              </div>
              <div className={classes.timeSelectFields}>
                <Controller
                  name="minutes"
                  control={control}
                  defaultValue="00"
                  rules={{
                    validate: () => {
                      const values = getValues();
                      return (
                        +values.hours !== 8 ||
                        +values.minutes === 0 ||
                        "Holiday duration can't be longer than 8 hours"
                      );
                    },
                  }}
                  as={
                    <TextField
                      select
                      id="minutes"
                      error={!!errors.hours || !!errors.minutes}
                      variant="outlined"
                      SelectProps={{
                        native: isIOS,
                      }}
                      fullWidth
                    >
                      {createMinutesArray()}
                    </TextField>
                  }
                />
                <Typography variant="body1" color="textPrimary">
                  &nbsp; minutes
                </Typography>
              </div>
            </div>
            {errors.hours || errors.minutes ? (
              <div className={classes.error}>{(errors.hours || errors.minutes).message}</div>
            ) : null}
          </div>
        </Grid>
      )}
    </Grid>
  );
};

HolidayDetails.propTypes = {
  classes: propTypes.object.isRequired,
  errors: propTypes.object.isRequired,
};

export default withStyles(styles)(HolidayDetails);
