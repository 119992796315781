import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getClockInStatus, getPto } from 'store/kiosk/kioskPageOperations';
import jwt_decode from "jwt-decode";
import $api from 'http/index';
import { setItem } from 'helpers/localStorage';
import { getOptions } from 'helpers/getOptions';
import kioskPageActions from 'store/kiosk/kioskPageActions';

export const useKioskUser = () => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  //* login in kiosk as worker after choosing an employee and employee number
  const authorize = async ({ user, employeeNum }) => {
    try {
      const data = {
        username: user.username,
        employeeNum,
      }
      const res = await $api.post(`${process.env.REACT_APP_BASE_URL}/kiosk/login`, data, getOptions());
      const decode = jwt_decode(res.data.token);
      setItem('x-auth-kiosk-token', res.data.token)
      
      const decodedUser = {
        _id : decode.sub,
        username: decode.username,
        profile: {
          role: JSON.parse(decode.role),
          shifts: JSON.parse(decode.shifts),
          employeeNum: decode.employee_num,
          email: decode.email,
        }
      }

      setUser(decodedUser);
      // dispatch(kioskPageActions.login(decodedUser));
      dispatch(getPto(decodedUser._id));
      dispatch(getClockInStatus(decodedUser._id));
      dispatch(kioskPageActions.setSpecialProfilePermissions(decodedUser))
    } catch (e) {
      setError(e?.response?.data?.message || e.message);
    }
  };

  const logout = () =>{
    setUser(null)};

  const clearError = () => setError(null);

  return { user, authorize, logout, error, clearError };
};
