const { object, string, number } = require('yup');

export const GlassBeadsMaterialSchema = (glassBeads = [], edit) => {
  return object().shape({
    // name: Number.isInteger(edit)
    //   ? string().trim().required('Field is required')
    //   : string()
    //       .trim()
    //       .required('Field is required') // required
    //       ,
    beadId: string().trim().required('Field is required'),
    ratio: number()
      .typeError('Number is invalid. Only symbols 0 - 9 allowed')
      .positive('Value should be positive number')
      .required('Field is required'), // required
    unitType: string().trim().required('Field is required'), // required
    oldBeadId: number().optional(),
    _id: string().optional(),
  });
};
