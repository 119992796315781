import './../../viewHours.scss';
import { TimeClockBlock } from './../TimeClockBlock/TimeClockBlock';
import { JobBlock } from './../JobBlock/JobBlock';
import { CipBlock } from './../CipBlock/CipBlock';
import { FooterBlock } from './../FooterBlock/FooterBlock';

const HoursTableBody = ({ day, date }) => {

  return (
    <>
      <tbody>
        <TimeClockBlock day={day} />
        <JobBlock day={day} />
        <CipBlock day={day} />
        <FooterBlock day={day} />
      </tbody>
    </>
  );
};

export default HoursTableBody;
