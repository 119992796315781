export default (theme) => ({
  root: {
    padding: '20px 30px',
    maxWidth: '1280px',
    margin: '0 auto',
  },
  header: {
    marginBottom: 25,
    '@media print': {
      flexWrap: 'nowrap',
    }
  },
  tabs: {
    marginBottom: 20,
    borderBottom: '1px solid #e8e8e8',
  },
  brief: {
    marginBottom: 50,
  },
  filters: {
    marginBottom: 30,
  },
  usersSlider: {
    marginBottom: 40,
  },
  report: {
    marginBottom: 15,
  },
});
