import { MEASUREMENT_UNITS, MEASURE_UNITS_BY_SYSTEM } from "common/measurements";

export const validation = (currentObj, currentTab) => (values) => {
  const errors = {};
  const requiredFieldError = "This field can't be blank";
  const quantityError = 'Quantity must be a number';
  const numberError = 'Quantity must be bigger than 0';

  if (!values.location.trim()) {
    errors.location = requiredFieldError;
  }
  if (!!!values?.surface?.trim()) {
    errors.surface = requiredFieldError;
  }
  if (values.materialName === '') {
    errors.materialName = requiredFieldError;
  }
  if (values.allColors && values.materialSpecifications.colorOrNumber === '') {
    errors.color = requiredFieldError;
  }

  if (values.dataType === 'alternative' && !values.alternativeDescription.trim()) {
    errors.alternativeDescription = requiredFieldError;
  }

  if (values.dataType === 'each') {
    if (!isFinite(values.eachQuantity)) {
      errors.eachQuantity = quantityError;
    }
    if (isFinite(values.eachQuantity) && values.eachQuantity <= 0) {
      errors.eachQuantity = numberError;
    }
  }

  if (values.dataType === 'alternative') {
    if (!isFinite(values.alternativeQuantity)) {
      errors.alternativeQuantity = quantityError;
    }
    if (isFinite(values.alternativeQuantity) && values.alternativeQuantity <= 0) {
      errors.alternativeQuantity = numberError;
    }
  }

  if (values.dataType === 'lines') {
    if (!isFinite(values.lineQuantity) || typeof values.lineQuantity === 'string') {
      errors.lineQuantity = quantityError;
    }
    if (isFinite(values.lineQuantity) && values.lineQuantity <= 0) {
      errors.lineQuantity = numberError;
    }
    if (values.lineQuantity === '') errors.lineQuantity = requiredFieldError;
  }

  if (values.dataType === 'legends' && !values.symbolLegend.trim()) {
    errors.symbolLegend = requiredFieldError;
  }
  if (values.dataType === 'legends') {
    if (!isFinite(values.legendQuantity) || typeof values.legendQuantity === 'string') {
      errors.legendQuantity = quantityError;
    }
    if (isFinite(values.legendQuantity) && values.legendQuantity <= 0) {
      errors.legendQuantity = numberError;
    }
    if (values.legendQuantity === '') errors.legendQuantity = requiredFieldError;
  }

  if (
    values.dataType === 'letterings' &&
    !!values.letteringText &&
    currentObj &&
    currentObj.letterings
  ) {
    const obj = currentObj.letterings.find((el) => el.name === currentTab.options._idLettering);
    const letterSet = Object.keys(obj.letterSet);
    let err = [];
    const stringArr = values.letteringText.split(' ').join('').split('');
    err = Array.from(
      stringArr.reduce((result, currLetter) => {
        if (!letterSet.includes(currLetter)) {
          result.add(currLetter);
        }
        return result;
      }, new Set())
    );

    if (err.length) {
      errors.letteringText = `${err} ${err.length == 1 ? 'is' : 'are'} not added to the letter set`;
    }
  }

  if (values.dataType === 'letterings' && !values.letteringText.trim()) {
    errors.letteringText = requiredFieldError;
  }
  if (values.dataType === 'letterings') {
    if (!isFinite(values.letteringQuantity) || typeof values.letteringQuantity === 'string') {
      errors.letteringQuantity = quantityError;
    }
    if (isFinite(values.letteringQuantity) && values.letteringQuantity <= 0) {
      errors.letteringQuantity = numberError;
    }
    if (values.letteringQuantity === '') errors.letteringQuantity = requiredFieldError;
  }

  return errors;
};

export const getMaterialSaveObject = (values, state, currentTab) => {
  const material = {
    locationId: values.location,
    surface: values.surface,
    typeOfWork: values.typeOfWork,
    materialName: state.materialObject.name,
    materialType: state.materialType,
    materialSpecifications: {},
    quantity: '',
  };

  if (values.glassBeads) {
    material.materialSpecifications.glassBeads = values.glassBeads;
  }

  if (values.dataType === 'lines') {
    material.materialSpecifications.colorOrNumber = values.materialSpecifications.colorOrNumber;
    material.materialSpecifications.line = {
      width: currentTab.options.widthLine,
      ratio: currentTab.options.ratio,
    };
    material.quantity = values.lineQuantity;
    if (values.lineDescription) {
      material.description = values.lineDescription;
    }
    if (values.manufacturerId) {
      material.manufacturerId = values.manufacturerId;
    }
  } else if (values.dataType === 'legends') {
    material.materialSpecifications.colorOrNumber = values.materialSpecifications.colorOrNumber;
    material.materialSpecifications.legend = {
      symbol: currentTab.options.symbols,
      area: currentTab.options.area,
      ratio: currentTab.options.ratio,
    };
    material.quantity = values.legendQuantity;
    if (values.legendDescription) {
      material.description = values.legendDescription;
    }
    if (values.manufacturerId) {
      material.manufacturerId = values.manufacturerId;
    }
  } else if (values.dataType === 'letterings') {
    material.materialSpecifications.colorOrNumber = values.materialSpecifications.colorOrNumber;
    const obj = state.materialObject.letterings.find(
      (el) => el.name === currentTab.options._idLettering
    );

    material.materialSpecifications.lettering = {
      name: obj.name,
      height: obj.height,
      width: obj.width,
      stroke: obj.stroke,
      ratio: obj.ratio,
      letterSet: obj.letterSet,
    };
    material.letteringText = values.letteringText;
    material.quantity = values.letteringQuantity;
    if (values.letteringDescription) {
      material.description = values.letteringDescription;
    }
    if (values.manufacturerId) {
      material.manufacturerId = values.manufacturerId;
    }
  } else if (values.dataType === 'each') {
    material.materialSpecifications.colorOrNumber = values.materialSpecifications.colorOrNumber;
    material.quantity = values.eachQuantity;
    material.materialSpecifications.discreteUnitType = values.eachQuantityUnit;
    if (values.eachDescription) {
      material.description = values.eachDescription;
    }
    if (values.manufacturerId) {
      material.manufacturerId = values.manufacturerId;
    }
    if (values.eachSymbolWidth) {
      material.materialSpecifications.symbolOrWidth = values.eachSymbolWidth;
    }
  } else if (values.dataType === 'alternative') {
    material.quantity = values.alternativeQuantity;
    material.description = values.alternativeDescription;
    material.materialSpecifications.discreteUnitType = values.alternativeQuantityUnit;
  }
  return material;
};

export const initialValuesOnCreate = (typeOfWork, surfaceOptions, locationOptions) => {
  return {
    surface: surfaceOptions?.[0]?.name || '',
    location: '',
    typeOfWork,
    materialName: '',
    materialType: '',
    materialSpecifications: {
      colorOrNumber: '',
    },
    lineQuantity: '',
    widthLine: '',
    lineDescription: '',
    symbolLegend: '',
    legendQuantity: '',
    letteringText: '',
    paremeters: '',
    eachSymbolWidth: '',
    alternativeQuantityUnit: 'length',
    alternativeQuantity: '',
    allColors: '',
    eachQuantity: '',
    legendDescription: '',
    eachDescription: '',
    alternativeDescription: '',
    letteringQuantity: '',
    letteringDescription: '',
    dataType: '',
    eachQuantityUnit: 'length',
    glassBeads: '',
  };
};

export const initialValuesOnUpdate = (material, allMaterials, sheet, setState, setCurrentTab) => {

  const initialValues = {
    surface: material.surface,
    location: material.locationId,
    materialName: material.materialName,
    materialType: material.materialType,
    materialSpecifications: {
      colorOrNumber:
        material.materialSpecifications && material.materialSpecifications.colorOrNumber,
    },
    typeOfWork: material.typeOfWork,
    lineQuantity: '',
    widthLine: '',
    lineDescription: '',
    symbolLegend: '',
    legendQuantity: '',
    letteringText: '',
    paremeters: '',
    eachSymbolWidth: '',
    alternativeQuantityUnit: 'length',
    alternativeQuantity: '',
    allColors: '',
    eachQuantity: '',
    legendDescription: '',
    eachDescription: '',
    alternativeDescription: '',
    letteringQuantity: '',
    letteringDescription: '',
    dataType: '',
    eachQuantityUnit: 'length',
    glassBeads: '',
  };
  const materialObject = allMaterials.find((obj) => obj.name === material.materialName);
  setState((prev) => ({ ...prev, materialObject: materialObject }));

  if (
    material.materialSpecifications &&
    material.materialSpecifications.glassBeads &&
    material.materialSpecifications.glassBeads.length
  ) {
    initialValues.glassBeads = material.materialSpecifications.glassBeads;
  }

  if (material.materialType === 'each' && material.materialName === 'Alternative Work') {
    initialValues.alternativeQuantity = material.quantity;
    initialValues.alternativeDescription = material.description;
    initialValues.alternativeQuantityUnit = material.materialSpecifications.discreteUnitType;
    initialValues.dataType = 'alternative';
  }

  if (material.materialType === 'each' && material.materialName !== 'Alternative Work') {
    initialValues.eachQuantity = material.quantity;
    initialValues.eachSymbolWidth = material.materialSpecifications.symbolOrWidth;
    initialValues.dataType = 'each';
    initialValues.eachQuantityUnit = material.materialSpecifications.discreteUnitType;
    if (material.description) {
      initialValues.eachDescription = material.description;
    }
    if (material.manufacturerId) {
      initialValues.manufacturerId = material.manufacturerId;
    }
  }

  if (material.materialType !== 'each') {
    if (material.materialSpecifications.line) {
      initialValues.widthLine = material.materialSpecifications.line.width;
      initialValues.lineQuantity = material.quantity;
      initialValues.dataType = 'lines';
      if (material.description) {
        initialValues.lineDescription = material.description;
      }
      if (material.manufacturerId) {
        initialValues.manufacturerId = material.manufacturerId;
      }

      setCurrentTab((prev) => ({
        ...prev,
        options: {
          width: [material.materialSpecifications.line.width],
          widthLine: material.materialSpecifications.line.width,
          ratio: material.materialSpecifications.line.ratio,
        },
      }));
    }

    if (material.materialSpecifications.legend) {
      initialValues.symbolLegend = material.materialSpecifications.legend.symbol;
      initialValues.legendQuantity = material.quantity;
      initialValues.dataType = 'legends';
      if (material.description) {
        initialValues.legendDescription = material.description;
      }
      if (material.manufacturerId) {
        initialValues.manufacturerId = material.manufacturerId;
      }

      setCurrentTab((prev) => ({
        ...prev,
        options: {
          symbols: material.materialSpecifications.legend.symbol,
          AllSymbols: [],
          area: material.materialSpecifications.legend.area,
          ratio: material.materialSpecifications.legend.ratio,
        },
      }));
    }

    if (material.materialSpecifications.lettering) {
      initialValues.paremeters = material.materialSpecifications.lettering.name;
      initialValues.letteringQuantity = material.quantity;
      initialValues.letteringText = material.letteringText;
      initialValues.dataType = 'letterings';
      if (material.description) {
        initialValues.letteringDescription = material.description;
      }
      if (material.manufacturerId) {
        initialValues.manufacturerId = material.manufacturerId;
      }

      const AllParams = createOptionsForLettering([material.materialSpecifications.lettering], sheet.measurement);

      setCurrentTab((prev) => ({
        ...prev,
        options: {
          _idLettering: AllParams[0]._id,
          AllParams: AllParams,
        },
      }));
    }
  }

  if (materialObject) {
    setState((prev) => ({ ...prev, materialType: materialObject.type }));
  }
  if (materialObject && materialObject.inventories && materialObject.inventories.length) {
    const colors = Array.from(
      materialObject.inventories.reduce((set, m) => {
        if (m.colorOrNumber) {
          set.add(m.colorOrNumber);
        }
        return set;
      }, new Set())
    );

    setState((prev) => ({ ...prev, colorOptions: colors }));

    let eachSymbolWidth = [];
    if (material.materialType === 'each' && material.materialName !== 'Alternative Work') {
      const arrSymbols = [];
      materialObject.inventories.forEach((el) => {
        if (el.colorOrNumber && el.symbolOrWidth) {
          el.colorOrNumber === material.materialSpecifications.colorOrNumber && arrSymbols.push(el)
        }
      });
      eachSymbolWidth = filterByProp(arrSymbols, 'symbolOrWidth');
      if (eachSymbolWidth.length) {
        setState((prev) => ({
          ...prev,
          eachSymbolWidth: eachSymbolWidth,
          _idEachInventory: material.materialSpecifications.symbolOrWidth,
        }));
      } else {
        setState((prev) => ({ ...prev, eachSymbolWidth: '', _idEachInventory: '' }));
        initialValues.eachSymbolWidth = '';
      }
    }

    const arr = [];
    if (materialObject.type === 'each' && eachSymbolWidth.length) {
      materialObject.inventories.forEach((el) => {
        if (el.colorOrNumber && el.manufacturer && el.symbolOrWidth) {
          el.colorOrNumber === material.materialSpecifications.colorOrNumber &&
          el.symbolOrWidth === material.materialSpecifications.symbolOrWidth
            && arr.push(el.manufacturer)
        }
      });
    } else {
      materialObject.inventories.forEach((el) => {
        if (el.colorOrNumber && el.manufacturer) {
          el.colorOrNumber === material.materialSpecifications.colorOrNumber
            && arr.push(el.manufacturer)
        }
      });
    }

    let manufacturers = filterByProp(arr, 'name');
    if (manufacturers.length) {
      setState((prev) => ({ ...prev, manufacturersArr: manufacturers }));
    }
  }

  if (materialObject) {
    if (material.materialType === 'each' && material.materialName !== 'Alternative Work') {
      initialValues.dataType = 'each';
    }
    if (material.materialName === 'Alternative Work') {
      initialValues.dataType = 'alternative';
    }

    if (material.materialType !== 'each') {
      if (material.materialSpecifications.line) {
        const width = materialObject.lines.map((el) => el.width);

        setCurrentTab((prev) => ({
          ...prev,
          options: {
            width: width,
            widthLine: material.materialSpecifications.line.width,
            ratio: material.materialSpecifications.line.ratio,
          },
        }));
      }
      if (material.materialSpecifications.legend) {
        const AllSymbols = materialObject.legends.map((el) => el.symbol);

        setCurrentTab((prev) => ({
          ...prev,
          options: {
            symbols: material.materialSpecifications.legend.symbol,
            AllSymbols: AllSymbols,
            area: material.materialSpecifications.legend.area,
            ratio: material.materialSpecifications.legend.ratio,
          },
        }));
      }
      if (material.materialSpecifications.lettering) {
        const AllParams = createOptionsForLettering(materialObject.letterings, sheet.measurement);
        const createLetteringParam = createOptionsForLettering([
          material.materialSpecifications.lettering,
        ], sheet.measurement);

        setCurrentTab((prev) => ({
          ...prev,
          options: {
            _idLettering: createLetteringParam[0]._id,
            AllParams: AllParams,
          },
        }));
      }

      const tabs = [];
      if (materialObject.lines && materialObject.lines.length) {
        tabs.push('lines');
      }
      if (materialObject.legends && materialObject.legends.length) {
        tabs.push('legends');
      }
      if (materialObject.letterings && materialObject.letterings.length) {
        tabs.push('letterings');
      }
      setCurrentTab((prev) => ({ ...prev, active: initialValues.dataType, allTabs: tabs }));
    }
  }
  return { ...initialValues };
};

export function filterByProp(arr, prop) {
  let seen = {};
  let result = arr.filter((item) => {
    if (seen[item[prop]]) {
      return false;
    } else {
      seen[item[prop]] = true;
      return true;
    }
  });
  return result;
}
export const createOptionsForLettering = (arr, measurement) => {
  const widthUnit = MEASURE_UNITS_BY_SYSTEM[measurement][MEASUREMENT_UNITS.width].replace(/in/, '"');
  const lengthUnit = MEASURE_UNITS_BY_SYSTEM[measurement][MEASUREMENT_UNITS.length]

  const res = [];
  arr.forEach((el) => {
    const str = [];
    if (el.height) {
      str.push(`${el.height}${lengthUnit} H`);
    }
    if (el.width) {
      str.push(`${el.width}${widthUnit} W`);
    }
    if (el.stroke) {
      str.push(`${el.stroke}${widthUnit} S`);
    }
    res.push({ _id: el.name, param: str.join(' - ') });
  });
  return res;
};
