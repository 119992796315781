import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import classnames from 'classnames';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import './style.css';

import LocationSurfaceInput from '../../../LocationSurfaceInput';
import styles from './styles';
import { isIOS, Option } from '../../../Option';
import { MEASURE_UNITS_BY_SYSTEM, MEASUREMENT_UNITS } from 'common/measurements';

const MaterialDetails = ({
  classes,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  sheet,
  locationOptions,
  surfaceOptions,
  openSnackbar,
  handleChangeTypeOfWork,
  handleSelectMaterial,
  handleSelectColor,
  type,
  fillPrevious,
  handleChangeLetteringText,
  allMaterials,
  state,
  handleChangeManufacturer,
  currentTab,
  handlecurrentTab,
  handleWidthLine,
  handleSymbolLegend,
  handleParameters,
  handleChangeeachSymbolWidth,
  resetForm,
  setLocationOptions,
  measurement,
}) => {
  return (
    <>
      <Grid container spacing={2}>
        {sheet.forceAccount === 'Partial' && (
          <Grid item xs={12} className={classes.flexEnd}>
            <div
              className={classnames(
                classes.inlineFlex,
                classes.buttonsWrapper,
                classes.doubleButton
              )}
            >
              <Button
                variant="outlined"
                onClick={handleChangeTypeOfWork('contract', setFieldValue)}
                className={classnames(
                  classes.button,
                  classes.leftButton,
                  values.typeOfWork === 'contract' && classes.selectedButton,
                  values.typeOfWork === 'contract' && classes.leftShift
                )}
                disableRipple
                disableTouchRipple
              >
                Contract
              </Button>
              <Button
                variant="outlined"
                onClick={handleChangeTypeOfWork('forceAccount', setFieldValue)}
                className={classnames(
                  classes.button,
                  classes.rightButton,
                  values.typeOfWork === 'forceAccount' && classes.selectedButton,
                  values.typeOfWork === 'forceAccount' && classes.rightShift
                )}
                disableRipple
                disableTouchRipple
              >
                Time & Material
              </Button>
            </div>
          </Grid>
        )}
        <Grid item xs={12}>
          <LocationSurfaceInput
            locationOptions={locationOptions}
            setLocationOptions={setLocationOptions}
            surfaceOptions={surfaceOptions}
            sheet={sheet}
            groupedMaterials={{}}
            openSnackbar={openSnackbar}
            setFieldValue={setFieldValue}
            values={values}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            isMaterialForm
          />
        </Grid>
        {allMaterials && (
          <Grid item xs={12} md={6}>
            <label htmlFor="materialName">
              <Typography variant="body1" color="textSecondary">
                Material/Operation<span className={classes.requiredSign}>*</span>:
              </Typography>
              <TextField
                select
                id="materialName"
                name="materialName"
                value={values.materialName || ''}
                error={Boolean(errors.materialName) && Boolean(touched.materialName)}
                onChange={handleSelectMaterial(setFieldValue, values)}
                onBlur={handleBlur}
                className={classes.textField}
                variant="outlined"
                fullWidth
                SelectProps={{
                  native: isIOS,
                }}
              >
                <Option key={''} value={''}></Option>
                {allMaterials
                  .filter((el) => el.name)
                  .map((material) => (
                    <Option key={material._id} value={material.name}>
                      {material.name}
                    </Option>
                  ))}
              </TextField>
              {errors.materialName && touched.materialName ? (
                <div className={classes.error}>{errors.materialName}</div>
              ) : null}
            </label>
          </Grid>
        )}

        {state.colorOptions && !!state.colorOptions.length && (
          <Grid item xs={12} md={6}>
            <label htmlFor="materialSpecifications.colorOrNumber">
              <Typography variant="body1" color="textSecondary">
                Color/Number<span className={classes.requiredSign}>*</span>:
              </Typography>
              <TextField
                select
                id="materialSpecifications.colorOrNumber"
                name="materialSpecifications.colorOrNumber"
                value={
                  (values.materialSpecifications && values.materialSpecifications.colorOrNumber) ||
                  ''
                }
                error={Boolean(errors.color) && Boolean(touched.color)}
                onChange={handleSelectColor(setFieldValue)}
                onBlur={handleBlur}
                className={classes.textField}
                variant="outlined"
                fullWidth
                SelectProps={{
                  native: isIOS,
                }}
              >
                {state.colorOptions.map((option) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
              </TextField>
            </label>
            {errors.color && touched.color ? (
              <div className={classes.error}>{errors.color}</div>
            ) : null}
          </Grid>
        )}

        {state.materialType !== 'each' && currentTab.allTabs && (
          <>
            {state.manufacturersArr ? (
              <Grid item xs={12}>
                <label htmlFor="manufacturerId" className={classes.label}>
                  <Typography variant="body1" color="textSecondary">
                    Manufacturer:
                  </Typography>
                  <TextField
                    select
                    id="manufacturerId"
                    name="manufacturerId"
                    value={values.manufacturerId || ''}
                    onChange={handleChangeManufacturer(setFieldValue)}
                    className={classes.textField}
                    variant="outlined"
                    fullWidth
                    SelectProps={{
                      native: isIOS,
                    }}
                  >
                    {state.manufacturersArr.map((manufacturer) => (
                      <Option key={manufacturer._id} value={manufacturer._id}>
                        {manufacturer.name}
                      </Option>
                    ))}
                  </TextField>
                </label>
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} md={8}>
              <ToggleButtonGroup
                value={currentTab.active}
                exclusive
                onChange={handlecurrentTab(setFieldValue)}
                aria-label="text alignment"
                className={'buttons'}
              >
                {currentTab.allTabs.map((el) => (
                  <ToggleButton
                    key={el}
                    style={{ textTransform: 'capitalize' }}
                    disableRipple
                    disableTouchRipple
                    className={'btn'}
                    value={el}
                    aria-label="centered"
                  >
                    {el}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Grid>
            {currentTab.active === 'lines' && (
              <>
                <Grid item xs={12}>
                  <label htmlFor="lineQuantity">
                    <Typography variant="body1" color="textSecondary">
                      Quantity<span className={classes.requiredSign}>*</span>:
                    </Typography>
                  </label>
                  <Grid container spacing={2}>
                    <Grid item xs={10}>
                      <TextField
                        id="lineQuantity"
                        name="lineQuantity"
                        type="number"
                        value={values.lineQuantity}
                        error={Boolean(errors.lineQuantity) && Boolean(touched.lineQuantity)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={classes.textField}
                        variant="outlined"
                        fullWidth
                        inputProps={{
                          step: 0.01,
                        }}
                      />
                      {errors.lineQuantity && touched.lineQuantity ? (
                        <div className={classes.error}>{errors.lineQuantity}</div>
                      ) : null}
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        id="unit"
                        name="unit"
                        value={MEASURE_UNITS_BY_SYSTEM[measurement][MEASUREMENT_UNITS.length]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled
                        className={classes.textField}
                        variant="outlined"
                        fullWidth
                        SelectProps={{
                          native: isIOS,
                        }}
                      ></TextField>
                    </Grid>
                  </Grid>
                </Grid>
                {currentTab.options && currentTab.options.width && (
                  <Grid item xs={3}>
                    <label htmlFor="widthLine">
                      <Typography variant="body1" color="textSecondary">
                        Width:
                      </Typography>
                      <TextField
                        select
                        id="widthLine"
                        name="widthLine"
                        value={
                          values.widthLine ||
                          (currentTab.options &&
                            currentTab.options.width &&
                            currentTab.options.width[0]) ||
                          ''
                        }
                        onChange={handleWidthLine(setFieldValue)}
                        onBlur={handleBlur}
                        className={classes.textField}
                        variant="outlined"
                        fullWidth
                        SelectProps={{
                          native: isIOS,
                        }}
                      >
                        {currentTab.options.width.map((width) => (
                          <Option key={width} value={width}>
                            {width}{MEASURE_UNITS_BY_SYSTEM[measurement][MEASUREMENT_UNITS.width].replace(/in/, '"')}
                          </Option>
                        ))}
                      </TextField>
                    </label>
                  </Grid>
                )}
                {state.materialObject && state.materialObject.customDescription && (
                  <Grid item xs={9}>
                    <label htmlFor="lineDescription">
                      <Typography variant="body1" color="textSecondary">
                        Line Description:
                      </Typography>
                      <TextField
                        id="lineDescription"
                        name="lineDescription"
                        value={values.lineDescription}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={classes.textField}
                        variant="outlined"
                        fullWidth
                      />
                    </label>
                  </Grid>
                )}
              </>
            )}
            {currentTab.active === 'letterings' && (
              <>
                <Grid item xs={12}>
                  <label htmlFor="letteringText">
                    <Typography variant="body1" color="textSecondary">
                      Lettering<span className={classes.requiredSign}>*</span>:
                    </Typography>
                    <TextField
                      id="letteringText"
                      name="letteringText"
                      value={values.letteringText || ''}
                      error={Boolean(errors.letteringText) && Boolean(touched.letteringText)}
                      onChange={handleChangeLetteringText(setFieldValue)}
                      onBlur={handleBlur}
                      className={classes.textField}
                      variant="outlined"
                      fullWidth
                    />
                    {errors.letteringText && touched.letteringText ? (
                      <div className={classes.error}>{errors.letteringText}</div>
                    ) : null}
                  </label>
                </Grid>
                {currentTab.options && currentTab.options.AllParams && (
                  <Grid item xs={6}>
                    <label htmlFor="paremeters">
                      <Typography variant="body1" color="textSecondary">
                        Parameters:
                      </Typography>
                      <TextField
                        select
                        id="paremeters"
                        name="paremeters"
                        value={
                          values.paremeters ||
                          (currentTab.options && currentTab.options._idLettering) ||
                          ''
                        }
                        onChange={handleParameters(setFieldValue)}
                        onBlur={handleBlur}
                        className={classes.textField}
                        variant="outlined"
                        fullWidth
                        SelectProps={{
                          native: isIOS,
                        }}
                      >
                        {currentTab.options.AllParams.map((el) => (
                          <Option key={el._id} value={el._id}>
                            {el.param}
                          </Option>
                        ))}
                      </TextField>
                    </label>
                  </Grid>
                )}
                <Grid item xs={6}>
                  <label htmlFor="letteringQuantity">
                    <Typography variant="body1" color="textSecondary">
                      Quantity<span className={classes.requiredSign}>*</span>:
                    </Typography>
                    <TextField
                      id="letteringQuantity"
                      name="letteringQuantity"
                      type="number"
                      value={values.letteringQuantity || ''}
                      error={
                        Boolean(errors.letteringQuantity) && Boolean(touched.letteringQuantity)
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={classes.textField}
                      variant="outlined"
                      fullWidth
                      inputProps={{
                        step: 1,
                      }}
                    />
                    {errors.letteringQuantity && touched.letteringQuantity ? (
                      <div className={classes.error}>{errors.letteringQuantity}</div>
                    ) : null}
                  </label>
                </Grid>
                {state.materialObject.customDescription && (
                  <Grid item xs={12}>
                    <label htmlFor="letteringDescription">
                      <Typography variant="body1" color="textSecondary">
                        Lettering Description:
                      </Typography>
                      <TextField
                        id="letteringDescription"
                        name="letteringDescription"
                        value={values.letteringDescription}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={classes.textField}
                        variant="outlined"
                        fullWidth
                      />
                    </label>
                  </Grid>
                )}
              </>
            )}
            {currentTab.active === 'legends' && (
              <>
                {currentTab.options && currentTab.options.AllSymbols && (
                  <Grid item xs={6}>
                    <label htmlFor="symbolLegend">
                      <Typography variant="body1" color="textSecondary">
                        Symbol<span className={classes.requiredSign}>*</span>:
                      </Typography>
                      <TextField
                        select
                        id="symbolLegend"
                        name="symbolLegend"
                        value={
                          values.symbolLegend ||
                          (currentTab.options &&
                            currentTab.options.AllSymbols &&
                            currentTab.options.AllSymbols[0]) ||
                          ''
                        }
                        error={Boolean(errors.symbolLegend) && Boolean(touched.symbolLegend)}
                        onChange={handleSymbolLegend(setFieldValue)}
                        onBlur={handleBlur}
                        className={classes.textField}
                        variant="outlined"
                        fullWidth
                        SelectProps={{
                          native: isIOS,
                        }}
                      >
                        {currentTab.options.AllSymbols.map((symbol) => (
                          <Option key={symbol} value={symbol}>
                            {symbol}
                          </Option>
                        ))}
                      </TextField>
                      {errors.symbolLegend && touched.symbolLegend ? (
                        <div className={classes.error}>{errors.symbolLegend}</div>
                      ) : null}
                    </label>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <label htmlFor="legendQuantity">
                    <Typography variant="body1" color="textSecondary">
                      Quantity<span className={classes.requiredSign}>*</span>:
                    </Typography>
                    <TextField
                      id="legendQuantity"
                      name="legendQuantity"
                      type="number"
                      value={values.legendQuantity}
                      error={Boolean(errors.legendQuantity) && Boolean(touched.legendQuantity)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={classes.textField}
                      variant="outlined"
                      fullWidth
                      inputProps={{
                        step: 1,
                      }}
                    />
                    {errors.legendQuantity && touched.legendQuantity ? (
                      <div className={classes.error}>{errors.legendQuantity}</div>
                    ) : null}
                  </label>
                </Grid>
                {state.materialObject.customDescription && (
                  <Grid item xs={12}>
                    <label htmlFor="legendDescription">
                      <Typography variant="body1" color="textSecondary">
                        Legend Description:
                      </Typography>
                      <TextField
                        id="legendDescription"
                        name="legendDescription"
                        value={values.legendDescription}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={classes.textField}
                        variant="outlined"
                        fullWidth
                      />
                    </label>
                  </Grid>
                )}
              </>
            )}
          </>
        )}
        {state.materialType === 'each' && state.materialObject && (
          <>
            {state.eachSymbolWidth && (
              <Grid item xs={12} md={6}>
                <label htmlFor="eachSymbolWidth">
                  <Typography variant="body1" color="textSecondary">
                    Symbol/Width:
                  </Typography>
                  <TextField
                    select
                    id="eachSymbolWidth"
                    name="eachSymbolWidth"
                    value={values.eachSymbolWidth || ''}
                    onChange={handleChangeeachSymbolWidth(setFieldValue, values)}
                    onBlur={handleBlur}
                    className={classes.textField}
                    variant="outlined"
                    fullWidth
                    SelectProps={{
                      native: isIOS,
                    }}
                  >
                    {state.eachSymbolWidth.map((symbol) => (
                      <Option key={symbol._id} value={symbol.symbolOrWidth}>
                        {symbol.symbolOrWidth}
                      </Option>
                    ))}
                  </TextField>
                </label>
              </Grid>
            )}
            {state.manufacturersArr ? (
              <Grid item xs={12} md={6}>
                <label htmlFor="manufacturerId" className={classes.label}>
                  <Typography variant="body1" color="textSecondary">
                    Manufacturer:
                  </Typography>
                  <TextField
                    select
                    id="manufacturerId"
                    name="manufacturerId"
                    value={values.manufacturerId || ''}
                    onChange={handleChangeManufacturer(setFieldValue)}
                    className={classes.textField}
                    variant="outlined"
                    fullWidth
                    SelectProps={{
                      native: isIOS,
                    }}
                  >
                    {state.manufacturersArr.map((manufacturer) => (
                      <Option key={manufacturer._id} value={manufacturer._id}>
                        {manufacturer.name}
                      </Option>
                    ))}
                  </TextField>
                </label>
              </Grid>
            ) : null}
            {/* {state.materialObject &&
              state.materialObject.customDescription &&
              state.materialObject.name !== 'Alternative Work' &&
              state.materialObject.inventories &&
              !!state.materialObject.inventories.length && ( */}
            {state.materialObject.name !== 'Alternative Work' && (
              <>
                <Grid item xs={12}>
                  <label htmlFor="eachQuantity">
                    <Typography variant="body1" color="textSecondary">
                      Quantity<span className={classes.requiredSign}>*</span>:
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={10}>
                        <TextField
                          id="eachQuantity"
                          name="eachQuantity"
                          type="number"
                          value={values.eachQuantity}
                          error={Boolean(errors.eachQuantity) && Boolean(touched.eachQuantity)}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={classes.textField}
                          variant="outlined"
                          fullWidth
                          inputProps={{
                            step: 1,
                          }}
                        />
                        {errors.eachQuantity && touched.eachQuantity ? (
                          <div className={classes.error}>{errors.eachQuantity}</div>
                        ) : null}
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          select
                          id="eachQuantityUnit"
                          name="eachQuantityUnit"
                          value={values.eachQuantityUnit}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={classes.textField}
                          variant="outlined"
                          fullWidth
                          SelectProps={{
                            native: isIOS,
                          }}
                        >
                          <Option key={0} value="length">
                            {MEASURE_UNITS_BY_SYSTEM[measurement][MEASUREMENT_UNITS.length].toLocaleUpperCase()}
                          </Option>
                          <Option key={1} value="area">
                            {MEASURE_UNITS_BY_SYSTEM[measurement][MEASUREMENT_UNITS.area].toLocaleUpperCase()}
                          </Option>
                          <Option key={2} value="discrete">
                            EA
                          </Option>
                        </TextField>
                      </Grid>
                    </Grid>
                  </label>
                </Grid>

                {state.materialObject.customDescription && (
                  <Grid item xs={12}>
                    <label htmlFor="eachDescription">
                      <Typography variant="body1" color="textSecondary">
                        Description:
                      </Typography>
                      <TextField
                        id="eachDescription"
                        name="eachDescription"
                        value={values.eachDescription}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={classes.textField}
                        variant="outlined"
                        fullWidth
                      />
                    </label>
                  </Grid>
                )}
              </>
            )}
            {state.materialObject && state.materialObject.name === 'Alternative Work' && (
              <>
                <Grid item xs={12}>
                  <label htmlFor="alternativeDescription">
                    <Typography variant="body1" color="textSecondary">
                      Description:<span className={classes.requiredSign}>*</span>
                    </Typography>
                    <TextField
                      id="alternativeDescription"
                      name="alternativeDescription"
                      value={values.alternativeDescription}
                      error={
                        Boolean(errors.alternativeDescription) &&
                        Boolean(touched.alternativeDescription)
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={classes.textArea}
                      variant="outlined"
                      multiline
                      fullWidth
                    />
                    {errors.alternativeDescription && touched.alternativeDescription ? (
                      <div className={classes.error}>{errors.alternativeDescription}</div>
                    ) : null}
                  </label>
                </Grid>

                <Grid item xs={12}>
                  <label htmlFor="alternativeQuantity">
                    <Typography variant="body1" color="textSecondary">
                      Quantity<span className={classes.requiredSign}>*</span>:
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={10}>
                        <TextField
                          id="alternativeQuantity"
                          name="alternativeQuantity"
                          type="number"
                          value={values.alternativeQuantity}
                          error={
                            Boolean(errors.alternativeQuantity) &&
                            Boolean(touched.alternativeQuantity)
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={classes.textField}
                          variant="outlined"
                          fullWidth
                          inputProps={{
                            step: 0.01,
                          }}
                        />
                        {errors.alternativeQuantity && touched.alternativeQuantity ? (
                          <div className={classes.error}>{errors.alternativeQuantity}</div>
                        ) : null}
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          select
                          id="alternativeQuantityUnit"
                          name="alternativeQuantityUnit"
                          value={values.alternativeQuantityUnit}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={classes.textField}
                          variant="outlined"
                          fullWidth
                          SelectProps={{
                            native: isIOS,
                          }}
                        >
                          <Option key={0} value="length">
                            {MEASURE_UNITS_BY_SYSTEM[measurement][MEASUREMENT_UNITS.length].toLocaleUpperCase()}
                          </Option>
                          <Option key={1} value="area">
                            {MEASURE_UNITS_BY_SYSTEM[measurement][MEASUREMENT_UNITS.area].toLocaleUpperCase()}
                          </Option>
                          <Option key={2} value="discrete">
                            EA
                          </Option>
                        </TextField>
                      </Grid>
                    </Grid>
                  </label>
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
    </>
  );
};


export default withStyles(styles)(MaterialDetails);
