import React, { useCallback, useEffect, useState } from 'react';
import styles from './styles';
import { withStyles } from '@material-ui/core';
import Tabs from 'components/core/Tabs';
import Tab from 'components/core/Tab';
import { checkUserRole } from 'helpers/_helpers';
import { useDispatch, useSelector } from 'react-redux';
import timeclocksPageActions from 'store/timeclocks/timeclocksPageActions';
import { useParams, useNavigate } from 'react-router-dom';

const PageTabs = ({ classes, ...props }) => {
  const dispatch = useDispatch();
  const params = useParams()
  const navigate = useNavigate();
  const { tabs, activeTab, summary } = useSelector((state) => state.timeclocks);

  useEffect(() => {
    if (params.type && tabs) {
      const currentRoute = tabs.filter((tab) => !tab.hidden).find((el) => el.name === params.type);
      if (currentRoute && activeTab !== currentRoute?.index) {
        handleActiveTabChange(null, currentRoute?.index);
      }
    }
  }, [params.type, tabs]);

  useEffect(() => {
    if (checkUserRole('Accounting')) {
      const [allTab, ...allowedTabs] = tabs;
      dispatch(timeclocksPageActions.setTabs([{ ...allTab, hidden: true }, ...allowedTabs]));

      if (!allowedTabs.find(({ name }) => name === params.type)) {
        navigate(allowedTabs[allowedTabs.length - 1].name);
      }
    } else {
      dispatch(timeclocksPageActions.setTabs(tabs.map((tab) => ({ ...tab, hidden: false }))));
    }
  }, []);

  const handleActiveTabChange = useCallback(
    (event, newValue) => {
      const activeTab = tabs.find((t) => t.index === newValue);
      switch (activeTab.name) {
        case 'by-individual-week':
          if (params.type == 'by-individual-week') return;
          navigate('/timeclock/by-individual-week', { replace: true });
          break;
        case 'all':
          if (params.type == 'all') return;
          dispatch(
            timeclocksPageActions.setSummary({
              ...summary,
              query: {
                ...summary.query,
                submitted: true,
                machineShop: true,
              },
            })
          );
          navigate('/timeclock/all', { replace: true });
          break;
        case 'submitted':
          if (params.type == 'submitted') return;
          dispatch(
            timeclocksPageActions.setSummary({
              ...summary,
              query: {
                ...summary.query,
                submitted: true,
                machineShop: false,
              },
            })
          );
          navigate('/timeclock/submitted', { replace: true });
          break;
        case 'machine-shop':
          if (params.type == 'machine-shop') return;
          dispatch(
            timeclocksPageActions.setSummary({
              ...summary,
              query: {
                ...summary.query,
                submitted: false,
                machineShop: true,
              },
            })
          );
          navigate('/timeclock/machine-shop', { replace: true });
          break;
      }
      dispatch(timeclocksPageActions.setActiveTab(activeTab.index));
    },
    [summary, dispatch, tabs, summary]
  );

  return (
    <Tabs value={activeTab} onChange={handleActiveTabChange} {...props}>
      {tabs
        .filter((tab) => !tab.hidden)
        .map((tab) => (
          <Tab
            className={classes.tab}
            key={tab.index}
            label={tab.label}
            href={tab.href}
            value={tab.index}
            disableRipple
            component="a"
            onClick={(event) => event.preventDefault()}
          />
        ))}
    </Tabs>
  );
};

export default withStyles(styles)(PageTabs);
