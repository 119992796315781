export const getTimeZone = () => {
  let res = '';
  try {
    res = Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (e) {
    res = '';
  }

  return res || 'America/New_York';
};
