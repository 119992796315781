export default theme => ({
  title: {
    color: '#202020',
    fontSize: 24,
    fontWeight: 500,
    letterSpacing: 0.3,
    lineHeight: '35px',
  },
  currentWeekBtn: {
    color: '#A4A5B3',
    fontFamily: 'Poppins',
    borderRadius: 4,
    border: '1px solid #A5A4B7',
    fontSize: 14,
    fontWeight: 500,
    whiteSpace: 'nowrap',
    lineHeight: '22px',
    background: '#ffffff',
    outline: 'none',
    padding: '7px 20px',
    margin: '0 16px 0 0',

    '&:disabled' : {
      opacity: '.7'
    }
  },
  weekPicker: {
    width: '410px'
  },
});

export const DayPickerStyles = () => ({
  root: {},
  arrowButton: {
    width: 30,
    height: 30,
  },
  todayButton: {},
});
