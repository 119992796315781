export default {
  wrapper: {
    margin: '0 auto',
    maxWidth: '62.5rem',
    width: '100%'
  },
  root: {
    position: 'relative',
    '@media print': {
      pageBreakInside: 'avoid',
    },
  },
};
