import React, { memo } from 'react';
import { makeStyles, Drawer } from '@material-ui/core';
import { drawerTypes } from '../../../../../Equipment/components/helpers/drawerTypes';
import SheetForm from '../../../../../Worklogs/components/SheetForm/SheetForm';
import ResourcesForm from '../../../../../../components/ResourcesForm/ResourcesForm';
import CommentsForm from '../../../../../../components/CommentsForm/CommentsForm';
import MaterialForm from '../../../../../../components/MaterialForm/MaterialForm';
import TimeForm from '../../../../../../components/WorklogDetails/components/TimeBlock/components/TimeForm/TimeForm';
import WeatherForm from '../../../../../../components/WorklogDetails/components/WeatherBlock/components/WeatherForm/WeatherForm';
import NotesForm from '../../../../../../components/WorklogDetails/components/NotesBlock/components/NotesForm/NotesForm';
import GeneralInfoForm from '../../../../../../components/WorklogDetails/components/GeneralInfoBlock/components/GeneralInfoForm/GeneralInfoForm';

const useStyles = makeStyles({
  backdropOpacity: {
    opacity: '0 !important',
  },
});

export const WorklogDrawer = memo(
  ({
    drawer,
    sheetId,
    additionalDrawer,
    openSnackbar,
    closeDrawer,
    closeAdditionalDrawer,
  }) => {
    const classes = useStyles();
    return (
      <>
        <Drawer
          anchor="right"
          open={drawer.isOpen}
          ModalProps={{
            BackdropProps: {
              classes: {
                root: sheetId ? classes.backdropOpacity : null,
              },
            },
          }}
        >
          {drawer.type === drawerTypes.update && (
            <SheetForm
              closeDrawer={closeDrawer}
              type={drawer.type}
              sheet={drawer.sheet}
              openSnackbar={openSnackbar}
            />
          )}
        </Drawer>

        <Drawer
          anchor="left"
          open={drawer.type === 'details' && additionalDrawer.isOpen}
        >
          {additionalDrawer.type === 'resources' && (
            <ResourcesForm
              closeDrawer={closeAdditionalDrawer}
              type={additionalDrawer.subType}
              openSnackbar={openSnackbar}
            />
          )}
          {additionalDrawer.type === 'comments' && (
            <CommentsForm
              closeDrawer={closeAdditionalDrawer}
              openSnackbar={openSnackbar}
              limiterW={true}
            />
          )}
          {additionalDrawer.type === 'material' && (
            <MaterialForm
              closeDrawer={closeAdditionalDrawer}
              openSnackbar={openSnackbar}
              type={additionalDrawer.subType}
              material={additionalDrawer.material}
            />
          )}
          {additionalDrawer.type === 'time' && (
            <TimeForm
              closeDrawer={closeAdditionalDrawer}
              openSnackbar={openSnackbar}
            />
          )}
          {additionalDrawer.type === 'weather' && (
            <WeatherForm closeDrawer={closeAdditionalDrawer} />
          )}
          {additionalDrawer.type === 'notes' && (
            <NotesForm
              closeDrawer={closeAdditionalDrawer}
              openSnackbar={openSnackbar}
            />
          )}
          {additionalDrawer.type === 'general' && (
            <GeneralInfoForm closeDrawer={closeAdditionalDrawer} />
          )}
        </Drawer>
      </>
    );
  },
  (prevProps, nextProps) => {
    const drawerClosed =
      prevProps.drawer.isOpen === nextProps.drawer.isOpen &&
      nextProps.drawer.isOpen === false;
    const additionalDrawerClosed =
      prevProps.additionalDrawer.isOpen === nextProps.additionalDrawer.isOpen &&
      nextProps.additionalDrawer.isOpen === false;
    return drawerClosed && additionalDrawerClosed;
  }
);
