import React from 'react';
import { Button } from '@material-ui/core';
import useStyles from './useStyles';
import { Add } from '@material-ui/icons';

const AddProjectButton = (props) => {
  const classes = useStyles();

  return (
    <Button
      {...props}
      className={classes.root}
      variant={'text'}
      startIcon={<Add />}
    >
      Add Project
    </Button>
  );
};

export default AddProjectButton;
