import axios from 'axios';
import { getItem, setItems } from '../helpers/localStorage';
import { logout } from 'store/login/loginOperations';
import store from 'store/store';

class AuthManager {
  _queue = [];
  _loading = false;
  _lastRefreshedAt;
  _lastData;

  constructor() {
    this._queue = [];
    this._loading = false;
  }

  async refreshToken() {
    const task = { at: new Date() };
    this._queue.push(task);
    return this._processRefreshing(task);
  }

  async _processRefreshing(task) {
    if (!task) {
      throw new Error('Internal error: unable to process refreshing without task (EMPTY_REFRESH_TASK)')
    }

    const refreshToken = getItem('refresh-token');
    if (!refreshToken) {
      throw new Error('Internal error: token was not found (EMPTY_REFRESH_TOKEN)');
    }

    // Ensure processing one item in a moment of time
    if (this._loading) {
      return new Promise((res) => {
        return setTimeout(() => this._processRefreshing(task).then(res), 300);
      });
    }

    // If task were filtered out of queue - consider refreshing completed successfully
    const taskInQueue = this._queue.includes(task);
    if (!taskInQueue) return this._lastData;

    // If in some case task were not filtered out on successful refreshing - filter it out here and consider successful token refresh
    if (this._lastRefreshedAt && (this._lastRefreshedAt?.getTime() + 5000) > task?.at?.getTime()) {
      this._flushQueue(new Date(this._lastRefreshedAt.getTime() + 5000));
      return this._lastData;
    }

    // Refresh token
    this._loading = true;

    if (task?.at) {
      const { dispatch } = store;

      try {
        const data = { refreshToken };
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/refresh-token`, data);

        const localData = [
          { key: 'authorization-token', value: response.data.token },
          { key: 'authorization-token-exp-at', value: response.data.expiresAt },
          { key: 'refresh-token', value: response.data.refreshToken },
          { key: 'refresh-token-exp-at', value: response.data.refreshExpiresAt },
        ];

        this._lastData = localData;

        setItems(localData);
        this._flushQueue(new Date());
      } catch (err) {
        console.error(err);
        dispatch(logout());
      } finally {
        this._loading = false;
      }
    }

    return this._lastData;
  }

  // We are considering there should be small difference so we can ignore it and flush whole queue
  _flushQueue(refreshedAt) {
    this._lastRefreshedAt = new Date(refreshedAt)
    this._queue = [];
  }
}

export default new AuthManager();