import React from 'react';
import useStyles from './useStyles';
import Grid from '@material-ui/core/Grid';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

const CIPProjectCard = ({
  id,
  title,
  hoursLogged,
  active,
  description,
  onActivate = () => null,
  onClose = () => null,
  ...props
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root} {...props}>
      <div className={classes.header}>
        <div className={classes.title}>{title}</div>
        <IconButton
          className={classes.closeBtn}
          onClick={(e) => e.stopPropagation() || onClose(id)}
        >
          <Close />
        </IconButton>
      </div>
      <div className={classes.info}>
        <Grid container justifyContent={'space-between'} spacing={1} alignItems={'center'}>
          <Grid item xs={'auto'}>
            <span className={classes.label}>Hours logged:</span>
            <span className={classes.value}>{hoursLogged}h</span>
          </Grid>
        </Grid>
      </div>
      <div className={classes.description}>{description}</div>
    </div>
  );
};

export default CIPProjectCard;
