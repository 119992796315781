export default (theme) => ({
  root: {
    height: '100vh',
  },
  body: {
    height: 'calc(100% - 50px)',
  },
  header: {
    height: 50,
    width: '100%',
    backgroundColor: theme.palette.background.default,
    padding: '12px 20px 11px',
    position: 'relative',
    boxShadow: '0 4px 24px 0 rgba(59,59,59,.129)',

    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: 1,
      background:
        'linear-gradient(90deg, #3e82ff 9.62%, rgba(62, 130, 255, 0.63) 28.47%, #d1d1d1 65.06%),linear-gradient(0deg, #fafafa, #fafafa)',
    },
  },
  appName: {
    color: theme.palette.textColor.dark,
    fontFamily: 'Poppins',
    fontSize: 19,
    fontWeight: 500,
    letterSpacing: '0.2px',
    height: 27,
    lineHeight: '23px',
    padding: '13px 23px 13px 8px',
  },
  top: {
    height: '40.7792207792%',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  bottom: {
    height: '59.2207792208%',
    backgroundColor: '#F0F0F3',
    padding: 50,
  },
  statusCode: {
    display: 'block',
    color: '#F0F0F3',
    fontFamily: 'Poppins',
    fontSize: 240,
    height: 204,
    marginBottom: -2,
    lineHeight: '240px',
    fontWeight: 'bold',
    letterSpacing: '-0.01px',
  },
  title: {
    display: 'block',
    color: '#000000',
    fontFamily: 'Poppins',
    fontSize: 26,
    letterSpacing: '0.57px',
    lineHeight: '40px',
    textAlign: 'center',
    marginBottom: 30,
  },
  description: {
    display: 'block',
    color: '#848484',
    fontFamily: 'Poppins',
    fontSize: 18,
    letterSpacing: '0.4px',
    lineHeight: '28px',
    textAlign: 'center',
    marginBottom: 38,
  },
  returnHomeBtn: {
    display: 'block',
    margin: '0 auto',
    border: '1px solid #5A87EF',
    borderRadius: '4px',
    padding: '10px 24px',
    color: '#5A87EF',
    backgroundColor: '#F0F0F3',
    fontFamily: 'Poppins',
    fontSize: 14,
    letterSpacing: 0,
    lineHeight: '21px',
    textTransform: 'none',
    '&:hover': {
      color: '#5A87EF',
      backgroundColor: '#F0F0F3',
    },
  },
});
