export default (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  item: {
    margin: '0 40px',
    [theme.breakpoints.down('sm')]: {
      margin: '0 0 25px',
    },
  },
  title: {
    color: '#A5A6B4',
    fontSize: 18,
    letterSpacing: 0,
    lineHeight: '27px',
    marginRight: 20,
  },
  time: {
    color: '#202020',
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: '0.25',
    lineHeight: '30px',
    marginRight: 4,
  },
  measure: {
    color: '#202020',
    fontSize: 14,
    fontWeight: '500',
    letterSpacing: 0,
    lineHeight: '21px',
  },
});
