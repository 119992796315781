import React, { useCallback } from 'react';
import useStyles from './useStyles';
import { Grid, withStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import cipPageActions from 'store/cip/cipPageActions';
import ExportExcelModal from '../../../TimeClock/modals/ExportExcelModal/ExportExcelModal';
import useExcelExport from '../../hooks/useExcelExport';
import ExportExcelButton from '../../../TimeClock/components/ExportExcelButton';
import AddProjectButton from '../AddProjectButton';
import CIPProjectDrawer from '../CIPProjectDrawer';
import { useSnackbar } from 'notistack';
import { DayPickerStyles } from '../../../TimeClock/components/Header/styles';
import DayPickerOneDayBase from 'components/DayPicker/DayPicker';
import { createCipProjects, updateCipProjects } from 'store/cip/cipPagesOperations';
import { startOfWeek, endOfWeek } from 'date-fns';
import debounce from 'lodash/debounce';
import { useWeekStart } from 'hooks/useWeekStart';
import { parseStringDate } from 'helpers/_date-helpers';

const DayPickerOneDay = withStyles(DayPickerStyles)(DayPickerOneDayBase);

const Header = (props) => {
  const { weekDayFromO } = useWeekStart();
  const classes = useStyles();
  const dispatch = useDispatch();
  const activeTab = useSelector((state) => state.cip.activeTab);
  const hours = useSelector((state) => state.cip.hours);
  const projects = useSelector((state) => state.cip.projects);
  const disabled = useSelector((state) => state.cip.disabled);
  const { enqueueSnackbar } = useSnackbar();

  const handleSaveProject = useCallback(
    async (project) => {
      try {
        if (project._id) {
          await dispatch(updateCipProjects(project))
        } else {
          await dispatch(createCipProjects(project));
        }
        enqueueSnackbar('Successfully Saved!', { variant: 'success' });
      } catch (e) {
        enqueueSnackbar('Project is not saved', { variant: 'error' });
        console.error(e);
      }
      dispatch(cipPageActions.setAddingProject(null));
      dispatch(cipPageActions.setEditingProject(null));
    },
    [dispatch, enqueueSnackbar]
  );

  const { exported, setExported, onDownload } = useExcelExport();

  const handleExcelExport = useCallback(() => {
    setExported({
      period: {
        from: startOfWeek(parseStringDate(hours.query.date), { weekStartsOn: weekDayFromO }),
        to: endOfWeek(parseStringDate(hours.query.date), { weekStartsOn: weekDayFromO }),
      },
    });
  }, [hours, setExported]);

  const updateSelectedDate = useCallback(debounce((date) => {
    dispatch(cipPageActions.setDisabled(true))
    dispatch(
      cipPageActions.setHours({
        ...hours,
        // data: [],
        query: {
          ...hours.query,
          date,
        },
      })
    )
  }, 300), []);

  return (
    <>
      <Grid
        {...props}
        className={classes.root}
        container
        spacing={2}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Grid item xs>
          <div className={classes.title}>Construction In Progress</div>
        </Grid>
        {activeTab === 0 && (
          <>
            <Grid item xs={'auto'}>
              <ExportExcelButton onClick={handleExcelExport} />
            </Grid>
            <Grid item xs={'auto'}>
              <DayPickerOneDay
                dis={disabled}
                selectedDate={hours.query.date || new Date()}
                updateSelectedDate={(date) => updateSelectedDate(date)}
              />
            </Grid>
          </>
        )}

        {activeTab === 1 && (
          <Grid item xs={'auto'}>
            <AddProjectButton onClick={() => dispatch(cipPageActions.setAddingProject({}))} />
          </Grid>
        )}
      </Grid>

      <CIPProjectDrawer
        open={!!projects.adding || !!projects.editing}
        value={projects.adding || projects.editing}
        editMode={!projects.adding}
        onSave={handleSaveProject}
        onClose={() => (!!projects.adding ? dispatch(cipPageActions.setAddingProject(null)) : dispatch(cipPageActions.setEditingProject(null)))}
      />

      <ExportExcelModal
        open={!!exported}
        value={exported}
        chooseUser={false}
        onChange={(value) => setExported(value)}
        onSubmit={onDownload}
        onClose={() => setExported(null)}
        onCancel={() => setExported(null)}
      />
    </>
  );
};

export default Header;
