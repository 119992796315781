import React from 'react';

export const Cross = () => (
  <svg width="16px" height="16px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1247.000000, -692.000000)" fill="#5A87EF" fillRule="nonzero">
        <g transform="translate(1247.000000, 692.000000)">
          <g>
            <path d="M14.3164062,4.20996092 C13.985026,4.37027996 13.8463542,4.76904296 14.0066732,5.10026042 C14.4446615,6.00504558 14.6666667,6.980306 14.6666667,8 C14.6666667,11.675944 11.675944,14.6666667 8,14.6666667 C4.324056,14.6666667 1.33333333,11.675944 1.33333333,8 C1.33333333,4.324056 4.324056,1.33333333 8,1.33333333 C9.52327475,1.33333333 10.9542643,1.83072917 12.1386719,2.77164712 C12.425944,3.00097658 12.8460287,2.95296225 13.0753581,2.66471354 C13.3046875,2.37662758 13.2566732,1.95703125 12.9682617,1.72802733 C11.5660807,0.61360675 9.80159504,0 8,0 C3.58902996,0 -2.84217094e-14,3.58902996 -2.84217094e-14,8 C-2.84217094e-14,12.41097 3.58902996,16 8,16 C12.41097,16 16,12.41097 16,8 C16,6.77766925 15.7330729,5.60628254 15.2067057,4.519694 C15.0467122,4.18766275 14.6466471,4.04931642 14.3164062,4.20996092 Z"></path>
            <path d="M8,2.66666667 C7.63199871,2.66666667 7.33333333,2.96533204 7.33333333,3.33333333 L7.33333333,8 C7.33333333,8.36800129 7.63199871,8.66666667 8,8.66666667 L11.3333333,8.66666667 C11.7013346,8.66666667 12,8.36800129 12,8 C12,7.63199871 11.7013346,7.33333333 11.3333333,7.33333333 L8.66666667,7.33333333 L8.66666667,3.33333333 C8.66666667,2.96533204 8.36800129,2.66666667 8,2.66666667 Z"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Cross;
