export default theme => ({
  regularFontWeight: {
    fontWeight: 300,
  },
  mediumFontWeight: {
    fontWeight: 500,
  },
  paddingBottom: {
    paddingBottom: 8
  },
  paddingBottomSmall: {
    paddingBottom: 4
  },
  marginBottom: {
    marginBottom: 8
  },
  primaryColor: {
    color: theme.palette.textColor.primary,
    cursor: 'pointer',
  },
  darkColor: {
    color: theme.palette.textColor.dark
  },
  expand: {
    color: theme.palette.textColor.primary,
    transform: 'rotate(0deg)',
    padding: 0,
    '&:hover': {
      color: theme.palette.textColor.primary,
      backgroundColor: theme.palette.background.default
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
      backgroundColor: theme.palette.background.default
    }
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  cardActionsWrapper: {
    padding: '0 0 8px 0'
  },
  contentWrapper: {
    padding: 12,
    borderTop: `1px solid ${theme.palette.borderColor.primary}`
  },
  dividerWrapper: {
    padding: '8px 0'
  },
  equipmentBlock: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '17px',
    width: 'max-content',
    maxWidth: '100%',
  },
  equipmentNumber: {
    fontSize: 14,
    fontWeight: 500,
    fontFamily: 'Poppins',
    color: theme.palette.textColor.white,
    padding: '3px 6px',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
});
