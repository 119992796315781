export default theme => ({
  root: {
    overflowX: 'auto',
    '@media (max-width: 1050px)': {
      height: 'calc(100vh - 215px)'
    },
    '@media (min-width: 1051px)': {
      height: 'calc(100vh - 145px)'
    },
    '& table':{
      width: '100%',
    }
  },
  cell: {
    minWidth: 203,
    maxWidth: 203,
    height: 273,
    padding: 1,
    border: `1px solid ${theme.palette.borderColor.lightGrey} !important`,
    position: 'relative'
  }
});
