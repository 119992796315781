export const userTypes = {
  admin: 'Admin',
  fieldTechnician: 'Field Technician',
  accounting: 'Accounting',
  fleetMaintenance: 'Fleet Maintenance',
  projectManagement: 'Project Management',
  dispatcher: 'Dispatcher',
  machineShop: 'Machine Shop',
};

export const userTypesArray = [
  'Admin',
  'Field Technician',
  'Accounting',
  'Fleet Maintenance',
  'Project Management',
  'Dispatcher',
  'Machine Shop',
]