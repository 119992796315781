import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DayReport from 'pages/TimeClock/components/DayReport';
import { getSummary } from 'store/kiosk/kioskPageOperations';

const ViewHours = ({ date, userId }) => {
  const dispatch = useDispatch();
  const { summary } = useSelector((state) => ({
    summary: state.kiosk.summary,
  }));

  useEffect(() => {
    dispatch(getSummary({ date, userId }));
  }, [date]);

  const log = (a) => console.log(a);

  return summary && !!summary.length ? (
    summary.map((d, idx) => (
      <div style={idx !== 0 ? { marginTop: 70 } : {}} key={d.user._id}>
        <DayReport
          userTitle={{
            name: d.user.profile.fullName,
            number: d.user.profile.employeeNum,
          }}
          kiosk
          data={d}
          onChangeTimeclock={log}
          onCreateTimeclock={log}
          onRemoveTimeclock={log}
          worklog={!!d}
        />
      </div>
    ))
  ) : (
    <DayReport data={null} onChangeTimeclock={log} onCreateTimeclock={log} onRemoveTimeclock={log} />
  );
};

export default ViewHours;
