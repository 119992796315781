import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import $api from 'http/index';
import { getTimeZone } from 'helpers/getTimeZone';
import { startOfDay, endOfDay, parse } from 'date-fns';
import { useParams, useLocation, useSearchParams } from 'react-router-dom';
import LoadingSpinner from '../../components/LoadingSpinner';
import WorklogsPrintPage from './WorklogsPrintPage';
import { getOptions } from 'helpers/getOptions';
import { useSelector } from 'react-redux';
import OnlySheetImgPrint from './components/OnlySheetImgPrint/OnlySheetImgPrint';
import { WORKLOG_PRINT_QUERY } from './components/constants';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';
import { globalBEDateFormat } from 'common/dateFormatConfig';

export const isDay = (sheetId) => sheetId.match(/^\d{4}-\d{2}-\d{2}$/);

const WorklogPrintWrapper = () => {
  const { dateFormat } = useDateSettingsFormat();
  const [searchParams, setSearchParams] = useSearchParams();
  const isPrintAllImgsOnSheet = searchParams.get(WORKLOG_PRINT_QUERY.printAllImgsOnSheet);
  const isPrintSingleImgOnSheet = searchParams.get(WORKLOG_PRINT_QUERY.printSingleImgOnSheet); //this is the _id of the target image
  const isPrintImgsOnSheet = searchParams.get(WORKLOG_PRINT_QUERY.printSheetsWithImg);

  const personalProfile = useSelector((state) => state.personalProfile.user);
  const { isCrew, sheetId } = useParams();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [sheets, setSheets] = useState([]);

  const addMaterialSheets = (data) => {
    const promises = data.map((sheet) => {
      return $api
        .get(`${process.env.REACT_APP_BASE_URL}/sheets/${sheet._id}/materials`, getOptions())
        .then((response) => {
          sheet.materials = response.data;
          return response;
        })
        .catch((error) => {
          console.log(`Error getting data for _id ${sheet._id}:`, error);
        });
    });

    Promise.all(promises)
      .then(() => {
        console.log('All requests have completed successfully');
        if (isPrintSingleImgOnSheet) {
          const sheetWithTargetImgArr = [
            {
              ...data?.[0],
              images: data?.[0].images.filter((img) => img._id === isPrintSingleImgOnSheet),
            },
          ];
          setSheets(sheetWithTargetImgArr);
        } else {
          setSheets(data);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log('One or more requests failed:', error);
      });
  };

  useEffect(() => {
    const getPrintWorklogsByDate = async (date) => {
      const from = startOfDay(parse(date, globalBEDateFormat, new Date())).toISOString();
      const to = endOfDay(parse(date, globalBEDateFormat, new Date())).toISOString();
      const options = {
        headers: {
          'X-Auth-Token': localStorage.getItem('authorization-token'),
          'X-User-TZ': getTimeZone(),
        },
        params: {
          from,
          to,
          print: 'true',
          isCrewLeader: isCrew == 'isCrew' ? 'true' : null,
        },
      };
      try {
        setIsLoading(true);

        // const url = isCrew == 'submissions' ? 'submissions' : 'sheets';
        if (isCrew == 'submissions') {
          options.params.separateBySubmission = 'true';
          delete options.params.print;
        }

        const res = await $api.get(
          `${process.env.REACT_APP_BASE_URL}/sheets${location?.search}`,
          options
        );

        addMaterialSheets(res.data);
      } catch (e) {
        console.log(e);
      }
    };

    const getPrintWorklogById = async (_id) => {
      const options = {
        headers: {
          'X-Auth-Token': localStorage.getItem('authorization-token'),
          'X-User-TZ': getTimeZone(),
        },
      };
      try {
        setIsLoading(true);
        const res = await $api.get(`${process.env.REACT_APP_BASE_URL}/sheets/${_id}`, options);
        addMaterialSheets([res.data]);
      } catch (e) {
        console.log(e);
      }
    };

    if (isDay(sheetId)) {
      getPrintWorklogsByDate(sheetId);
    } else {
      getPrintWorklogById(sheetId);
    }
  }, []);

  const loadingRule = !isDay(sheetId) ? isLoading : isLoading || !personalProfile;

  return (
    <div>
      {loadingRule ? (
        <div
          style={{
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            marginTop: '100px',
          }}
        >
          <LoadingSpinner height={40} margin={32} />
        </div>
      ) : (
        <>
          {(isPrintAllImgsOnSheet || isPrintSingleImgOnSheet) && isPrintImgsOnSheet ? (
            <OnlySheetImgPrint sheets={sheets} isPrintById={!isDay(sheetId)} />
          ) : (
            <WorklogsPrintPage
              sheets={sheets}
              isPrintById={!isDay(sheetId)}
              printImg={isPrintImgsOnSheet}
            />
          )}
        </>
      )}
    </div>
  );
};

WorklogPrintWrapper.propTypes = {
  loading: propTypes.bool.isRequired,
  sheets: propTypes.array.isRequired,
};

export default WorklogPrintWrapper;
