import React, { useEffect } from 'react';
import { number, func, bool, node } from 'prop-types';

export const IsHereListener = ({ children, condition, wait, visible, setVisible }) => {
  useEffect(() => {
    let timeout = null;

    const updateInterval = () => {
      if (!condition && timeout) return clearTimeout(timeout);
      if (!condition || visible) return;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        setVisible(true);
      }, wait * 1000);
    };

    if (condition) {
      updateInterval();
      document.addEventListener('mousemove', updateInterval);
      document.addEventListener('click', updateInterval);
    } else {
      document.removeEventListener('mousemove', updateInterval);
      document.removeEventListener('click', updateInterval);
    }

    return () => {
      document.removeEventListener('mousemove', updateInterval);
      document.removeEventListener('click', updateInterval);
      if (timeout) clearTimeout(timeout);
    };
  }, [condition]);

  return <>{visible && children}</>;
};

IsHereListener.propTypes = {
  children: node.isRequired,
  condition: bool.isRequired,
  wait: number,
  visible: bool.isRequired,
  setVisible: func.isRequired,
};

IsHereListener.defaultProps = {
  wait: 180,
};
