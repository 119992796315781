import { SETTINGS_EVENT } from 'subscriptions/events/events.enum';
import { subscriptionsFabric } from 'subscriptions/subscriptions.fabric';
import personalProfileActions from 'store/personalProfile/personalProfileActions';


const handlers = {
  [SETTINGS_EVENT.updated]: (store) => (data) => {
    const settings = store.getState().personalProfile?.organization?.settings;

    if (settings) {
      store.dispatch(personalProfileActions.setOrganizationSettings(data));
    }
  },
};

export const personalProfileSettingsSubscriptionMiddleware = subscriptionsFabric(
  personalProfileActions,
  SETTINGS_EVENT,
  handlers
);
