import React from 'react'
import BaseTabs from 'components/core/Tabs'
import BaseTab from 'components/core/Tab'

const Tabs = ({tabs, ...props}) => {
    return <BaseTabs {...props}>
        {tabs.map(tab => <BaseTab label={tab.label} value={tab.index} key={tab.index}/>)}
    </BaseTabs>
}

export default Tabs
