import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { TextField, Typography, Grid, Button, IconButton } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux';
import propTypes from 'prop-types';

import { isIOS, Option } from 'components/Option';
import {
  createOptions,
  createOptionsWithLabel,
  createListWithOptions,
} from 'helpers/createOptions';
import { useTimeoffsStyles } from 'pages/Availability/components/Timeoffs/components/SortingAndFilteringPanel/useStyles';
import schedulePageActions from 'store/schedule/schedulePageActions';
import { statusOptions, shiftOptions, projectTypeOptions, forceAccountOptions } from './options';
import { getOptions } from 'helpers/getOptions';
import { getProjectName } from 'helpers/_helpers';
import classnames from 'classnames';

export const ScheduleFilters = ({ closeDrawer, mobileStyles = {} }) => {
  const dispatch = useDispatch();
  const classes = { ...useTimeoffsStyles(), ...mobileStyles };
  const workers = useSelector((state) => state.schedule.resources.workers);
  const equipment = useSelector((state) => state.schedule.resources.equipment);
  const defaultFilters = useSelector((state) => state.schedule.filters);
  const contractorOptions = useSelector((state) => state.schedule.filterOptions.contractors);
  const projectOptions = useSelector((state) => state.schedule.filterOptions.projects);
  const personIdOptions = useMemo(
    () =>
      [{ _id: 'All', username: 'All' }, ...workers].map((w) => ({
        value: w._id,
        label: w.username,
      })),
    [workers]
  );

  const equipmentIdOptions = useMemo(
    () =>
      [{ _id: 'All', username: 'All' }, ...equipment].map((e) => ({
        value: e._id,
        label: e.name,
      })),
    [equipment]
  );

  const [filteredProjectOptions, setFilteredProjectOptions] = useState(
    projectOptions
      .filter((p) => p.contractorId === defaultFilters.contractorId)
      .map((p) => ({ value: p._id, label: getProjectName(p) }))
  );

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: {
      status: statusOptions.map((o) => o.value),
      shift: shiftOptions[0],
      userId: defaultFilters.userId || 'All',
      equipment: defaultFilters.equipment || 'All',
      contractorId: defaultFilters.contractorId || 'All',
      projectId: defaultFilters.projectId || 'All',
      rated: projectTypeOptions.map((o) => o.value),
      forceAccount: forceAccountOptions[0].value,
      ...defaultFilters,
    },
  });

  const selectedContractorId = watch('contractorId');

  useEffect(() => {
    const fetchContractors = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/contractors?isWithoutSubmissionClone=1`,
          {
            ...getOptions(),
            method: 'GET',
          }
        ).then((res) => res.json());

        if (response.statusCode > 299) throw new Error(response.message);
        dispatch(
          schedulePageActions.setFilterOptions({
            contractors: response
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((c) => ({ value: c._id, label: c.name })),
          })
        );
      } catch (e) {
        console.log(e);
        dispatch(schedulePageActions.setFilterOptions({ contractors: [] }));
      }
    };
    const fetchProjects = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/projects?isWithoutSubmissionClone=1`,
          {
            ...getOptions(),
            method: 'GET',
          }
        ).then((res) => res.json());

        if (response.statusCode > 299) throw new Error(response.message);
        dispatch(
          schedulePageActions.setFilterOptions({
            projects: response.sort((a, b) => a.route.localeCompare(b.route)),
          })
        );
      } catch (e) {
        console.log(e);
        dispatch(schedulePageActions.setFilterOptions({ projects: [] }));
      }
    };
    if (!contractorOptions.length) fetchContractors();
    if (!projectOptions.length) fetchProjects();
  }, []);

  useEffect(() => {
    if (!selectedContractorId) setFilteredProjectOptions([]);
    setFilteredProjectOptions(
      projectOptions
        .filter((p) => p.contractorId === selectedContractorId)
        .map((p) => ({ value: p._id, label: getProjectName(p) }))
    );
  }, [selectedContractorId]);

  const onSubmit = (data) => {
    const normalizedData = Object.entries(data)
      .filter(([, value]) => value !== 'All' && (!Array.isArray(value) || value.length > 0))
      .reduce((res, [key, value]) => ({ ...res, [key]: value }), {});
    if (normalizedData.status.length === statusOptions.length) delete normalizedData.status;
    if (normalizedData.rated.length === projectTypeOptions.length) delete normalizedData.rated;
    dispatch(schedulePageActions.setFilters(normalizedData));
    closeDrawer();
  };

  const contractorOptionsEx = [{ value: 'All', label: 'All' }, ...contractorOptions];
  const filteredProjectOptionsEx = [{ value: 'All', label: 'All' }, ...filteredProjectOptions];

  return (
    <div className={classes.formWrapper} role="presentation">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={classnames(classes.formFlex, classes?.mobileSpace)}
      >
        <div>
          <Typography variant="h3" className={classes.marginHeader}>
            Filters
          </Typography>
          <IconButton
            className={classes.closeButton}
            onClick={closeDrawer}
            aria-label="Close"
            disableTouchRipple
          >
            <CloseIcon />
          </IconButton>
          <Grid container spacing={2} className={classes.flexContainer}>
            <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
              <label htmlFor="status">
                <Typography variant="body1" color="textSecondary">
                  Status:
                </Typography>
                <Controller
                  name="status"
                  control={control}
                  rules={{
                    validate: (value) => !!value.length || 'Please, select at least 1',
                  }}
                  render={({ value, onChange }) => (
                    <TextField
                      select
                      id="status"
                      name="status"
                      variant="outlined"
                      fullWidth
                      error={Boolean(errors.status)}
                      value={value}
                      onChange={(e) => {
                        const eventValue = e.target.value;
                        if (eventValue.length === statusOptions.length + 1) return onChange([]);
                        if (eventValue.includes('All'))
                          return onChange(statusOptions.map((o) => o.value));
                        onChange(eventValue);
                      }}
                      SelectProps={{
                        multiple: true,
                        native: isIOS,
                        renderValue: (selected) =>
                          selected.length === statusOptions.length ? 'All' : selected.join(', '),
                      }}
                    >
                      <Option
                        value="All"
                        className={
                          value.length === statusOptions.length ? classes.customSelectedStyle : ''
                        }
                        disableTouchRipple
                      >
                        All
                      </Option>
                      {statusOptions.map(({ value, label }) =>
                        createOptionsWithLabel({ value, label })
                      )}
                    </TextField>
                  )}
                />
                {errors.status ? (
                  <div className={classes.error}>{errors.status.message}</div>
                ) : null}
              </label>
            </Grid>
            <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
              <label htmlFor="shift">
                <Typography variant="body1" color="textSecondary">
                  Shift:
                </Typography>
                <Controller
                  name="shift"
                  control={control}
                  as={
                    <TextField
                      select
                      id="shift"
                      name="shift"
                      variant="outlined"
                      fullWidth
                      error={Boolean(errors.shift)}
                      SelectProps={{
                        native: isIOS,
                      }}
                    >
                      {shiftOptions.map(createOptions)}
                    </TextField>
                  }
                />
                {errors.shift ? <div className={classes.error}>{errors.shift.message}</div> : null}
              </label>
            </Grid>
            <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
              <label htmlFor="userId">
                <Typography variant="body1" color="textSecondary">
                  Person:
                </Typography>
                <Controller
                  name="userId"
                  control={control}
                  render={({ onChange, value }) => {
                    return (
                      <Autocomplete
                        name="userId"
                        id="userId"
                        onChange={(_, v) => (v && v.value ? onChange(v.value) : null)}
                        options={personIdOptions}
                        getOptionLabel={(option) => option.label || 'All'}
                        getOptionSelected={(option, value) => option.value === value.value}
                        renderOption={({ value, label }) => createListWithOptions({ value, label })}
                        value={personIdOptions.find((e) => e.value === value)} //value it's userId from react-hook-form default values
                        disablePortal
                        renderInput={(params) => (
                          <TextField {...params} className={classes.inputAuto} variant="outlined" />
                        )}
                      />
                    );
                  }}
                />
                {errors.userId ? (
                  <div className={classes.error}>{errors.userId.message}</div>
                ) : null}
              </label>
            </Grid>
            <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
              <label htmlFor="equipment">
                <Typography variant="body1" color="textSecondary">
                  Equipment:
                </Typography>
                <Controller
                  name="equipment"
                  control={control}
                  render={({ onChange, value }) => {
                    return (
                      <Autocomplete
                        name="equipment"
                        id="equipment"
                        onChange={(_, v) => (v && v.value ? onChange(v.value) : null)}
                        options={equipmentIdOptions}
                        disablePortal
                        getOptionLabel={(option) => option.label || 'All'}
                        getOptionSelected={(option, value) => option.value === value.value}
                        renderOption={({ value, label }) => createListWithOptions({ value, label })}
                        value={equipmentIdOptions.find((e) => e.value === value)} //value it's equipment from react-hook-form default values
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                      />
                    );
                  }}
                />
                {errors.equipment ? (
                  <div className={classes.error}>{errors.equipment.message}</div>
                ) : null}
              </label>
            </Grid>
            <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
              <label htmlFor="contractorId">
                <Typography variant="body1" color="textSecondary">
                  Contractor:
                </Typography>
                <Controller
                  name="contractorId"
                  control={control}
                  render={({ onChange, value }) => {
                    return (
                      <Autocomplete
                        name="contractorId"
                        id="contractorId"
                        onChange={(_, v) => (v && v.value ? onChange(v.value) : null)}
                        options={contractorOptionsEx}
                        disablePortal
                        getOptionLabel={(option) => option.label || 'All'}
                        getOptionSelected={(option, value) => option.value === value.value}
                        renderOption={({ value, label }) => createListWithOptions({ value, label })}
                        value={contractorOptionsEx.find((e) => e.value === value)} //value it's contractorId from react-hook-form default values
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                      />
                    );
                  }}
                />
                {errors.contractorId ? (
                  <div className={classes.error}>{errors.contractorId.message}</div>
                ) : null}
              </label>
            </Grid>
            <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
              <label htmlFor="projectId">
                <Typography variant="body1" color="textSecondary">
                  Project:
                </Typography>
                <Controller
                  name="projectId"
                  id="projectId"
                  control={control}
                  render={({ onChange, value }) => {
                    return (
                      <Autocomplete
                        id="projectId"
                        onChange={(_, v) => (v && v.value ? onChange(v.value) : null)}
                        options={filteredProjectOptionsEx}
                        getOptionLabel={(option) => option?.label || 'All'}
                        getOptionSelected={(option, value) =>
                          option.value === value?.value || 'All'
                        }
                        renderOption={({ value, label }) => createListWithOptions({ value, label })}
                        disablePortal
                        value={filteredProjectOptionsEx.find((e) => e.value === value)} //value it's projectId from react-hook-form default values
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                      />
                    );
                  }}
                />
                {errors.projectId ? (
                  <div className={classes.error}>{errors.projectId.message}</div>
                ) : null}
              </label>
            </Grid>
            <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
              <label htmlFor="rated">
                <Typography variant="body1" color="textSecondary">
                  Project Types:
                </Typography>
                <Controller
                  name="rated"
                  control={control}
                  rules={{
                    validate: (value) => !!value.length || 'Please, select at least 1',
                  }}
                  render={({ value, onChange }) => (
                    <TextField
                      select
                      id="rated"
                      name="rated"
                      variant="outlined"
                      fullWidth
                      error={Boolean(errors.rated)}
                      value={value}
                      onChange={(e) => {
                        const eventValue = e.target.value;
                        if (eventValue.length === projectTypeOptions.length + 1)
                          return onChange([]);
                        if (eventValue.includes('All'))
                          return onChange(projectTypeOptions.map((o) => o.value));
                        onChange(eventValue);
                      }}
                      SelectProps={{
                        native: isIOS,
                        multiple: true,
                        renderValue: (selected) =>
                          selected.length === projectTypeOptions.length
                            ? 'All'
                            : selected.join(', '),
                      }}
                    >
                      <Option
                        value="All"
                        className={
                          value.length === projectTypeOptions.length
                            ? classes.customSelectedStyle
                            : ''
                        }
                        disableTouchRipple
                      >
                        All
                      </Option>
                      {projectTypeOptions.map(({ value, label }) =>
                        createOptionsWithLabel({ value, label })
                      )}
                    </TextField>
                  )}
                />
                {errors.rated ? <div className={classes.error}>{errors.rated.message}</div> : null}
              </label>
            </Grid>
            <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
              <label htmlFor="forceAccount">
                <Typography variant="body1" color="textSecondary">
                  T&M:
                </Typography>
                <Controller
                  name="forceAccount"
                  control={control}
                  as={
                    <TextField
                      select
                      id="forceAccount"
                      name="forceAccount"
                      variant="outlined"
                      fullWidth
                      error={Boolean(errors.forceAccount)}
                      SelectProps={{
                        native: isIOS,
                      }}
                    >
                      {forceAccountOptions.map(({ value, label }) =>
                        createOptionsWithLabel({ value, label })
                      )}
                    </TextField>
                  }
                />
                {errors.forceAccount ? (
                  <div className={classes.error}>{errors.forceAccount.message}</div>
                ) : null}
              </label>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button
              color="primary"
              className={classes.resetButton}
              onClick={() => {
                reset();
                reset({
                  status: statusOptions.map((o) => o.value),
                  shift: shiftOptions[0],
                  userId: 'All',
                  equipment: 'All',
                  contractorId: 'All',
                  projectId: 'All',
                  rated: projectTypeOptions.map((o) => o.value),
                  forceAccount: forceAccountOptions[0].value,
                });
              }}
              disableTouchRipple
            >
              Reset all filters
            </Button>
          </Grid>
        </div>
        <div className={classes.buttonHolder}>
          <Button color="secondary" className={classes.cancelButton} onClick={closeDrawer}>
            Cancel
          </Button>

          <div className={classes.verticalDivider} />

          <Button type="submit" color="primary" className={classes.saveButton}>
            Apply
          </Button>
        </div>
      </form>
    </div>
  );
};

ScheduleFilters.propTypes = {
  closeDrawer: propTypes.func.isRequired,
};
