import Tabs from '@material-ui/core/Tabs'
import { withStyles } from '@material-ui/core'

const StyledTabs = withStyles({
  root: {
    fontFamily: 'Poppins',
    borderBottom: '1px solid #e8e8e8',
    paddingTop: 0,
    marginBottom: 10,
    color: '#a4a5b3',
  }
})(Tabs)

export default StyledTabs
