import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { styles } from './styles';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: '#EDEDED',
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#6588e8',
  },
}))(LinearProgress);

// Inspired by the former Facebook spinners.

const useStyles = makeStyles(styles);

const CustomizedProgressBar = ({ maxValue = 100, value = 0, label }) => {
  const classes = useStyles();

  function calculatePercentageOfMaximum(maxValue, value) {
    if (value <= 0) {
      return 0;
    }

    let min = Math.floor(value);
    let max = Math.floor(maxValue);

    if (value > maxValue) {
      return 100;
    }

    return (Math.floor((min * 100) / max));
  }

  return (
    <div className={classes.root}>
      <div className={classes.linearProgressInfo}>
        <span className={classes.linearProgressTitle}>{label}</span>
        <span className={classes.linearProgressCount}>{value}</span>
      </div>

      <BorderLinearProgress
        variant="determinate"
        value={calculatePercentageOfMaximum(maxValue, value)}
      />
    </div>
  );
};
export default CustomizedProgressBar;
