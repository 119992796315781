export default theme => ({
  dialogRoot: {
    minWidth: '90%',
    [theme.breakpoints.up('sm')]: {
      minWidth: 380,
    }
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 20px 10px'
  },
  flexBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  cancelButton: {
    textTransform: 'capitalize',
    margin: '0 20px',
    minWidth: 100,
    '&:hover': {
      color: 'inherit'
    },
    '&:disabled': {
      backgroundColor: '#fff',
    },
  },
  saveButton: {
    textTransform: 'capitalize',
    margin: '0 20px',
    minWidth: 100,
    '&:hover': {
      color: theme.palette.textColor.primary,
    },
    '&:disabled': {
      backgroundColor: '#fff',
    },
  },
  textArea: {
    '& textarea': {
      boxShadow: 'none',
      padding: 10,
      fontSize: 14,
      height: '60px !important',
      maxHeight: '60px !important'
    }
  },
  closeButton: {
    position: 'absolute',
    right: 15,
    top: 12,
    color: theme.palette.textColor.dark,
    padding: 5,
    '&:hover': {
      backgroundColor: '#fff !important',
      color: `${theme.palette.textColor.dark} !important`
    },
  }
});
