import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import Fuse from 'fuse.js';
import Drawer from '@material-ui/core/Drawer';
import Table from '../Table/Table';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import {
  fetchClients,
  postNewClient,
  putClient,
  loginToOrganization,
} from 'store/superadmin/superadminOperations';
import ClientsFilter from './components/Filters/Filters';
import AddClientForm from './components/AddClientForm/AddClientForm';
import EditClientForm from './components/EditClient/EditClient';
import FiltersBar from '../FilterBar/FilterBar';
import { searchOptions, DRAWER_TYPES, headCells } from './constants';
import CloseIcon from 'assets/images/close-icon-grey.svg';
import EditIcon from 'assets/images/edit-icon.svg';

import './styles.scss';

const Clients = ({ setSnackbar }) => {
  const clientsData = useSelector((state) => state.superadmin.clients);
  const userOrganization = useSelector((store) => store.personalProfile.organization);

  const [data, setData] = useState(clientsData);
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortBy, setSortBy] = useState('name');
  const [drawer, setDrawer] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [fuse, setFuse] = useState(null);
  const [filters, setFilters] = useState({ subscription: 'All', active: 'All' });
  const [editingClient, setEditingClient] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchClients(filters));
  }, [filters]);

  useEffect(() => {
    setFuse(new Fuse(clientsData, searchOptions));
  }, [clientsData]);

  useEffect(() => {
    const direction = sortOrder === 'asc' ? 1 : -1;

    const sorted = clientsData.slice().sort((a, b) => {
      let left = a[sortBy];
      let right = b[sortBy];
      if (typeof left === 'string' || right === 'string') {
        left = `${a[sortBy]}`.toLowerCase();
        right = `${b[sortBy]}`.toLowerCase();
      }

      return left > right ? 1 * direction : left < right ? -1 * direction : 0;
    });

    setData(sorted);
  }, [clientsData, sortBy, sortOrder]);

  const filteredClients = useMemo(() => {
    if (fuse && searchValue) return [...fuse.search(searchValue)];
    return [...data];
  }, [data, fuse, searchValue]);

  const openDrawer = (type) => {
    setDrawer(type);
  };

  const closeDrawer = () => {
    setDrawer(false);
  };

  const onUpdateFilters = (values) => {
    setFilters(values);
    closeDrawer();
  };

  const filtersReset = () => {
    setFilters({ subscription: 'All', active: 'All' });
    closeDrawer();
  };

  const handleSort = useCallback(
    (property) => {
      const isAsc = sortBy !== property || sortOrder === 'desc';
      setSortOrder(isAsc ? 'asc' : 'desc');
      setSortBy(property);
    },
    [sortBy, sortOrder]
  );

  const onEditClient = useCallback(
    (_id) => {
      const clientToEdit = data.find((client) => client._id === _id);
      if (!clientToEdit) return;

      setEditingClient(clientToEdit);
      openDrawer(DRAWER_TYPES.editClient);
    },
    [data]
  );

  const onLogIn = (organization) => {
    if (!organization.active || userOrganization?.name == organization.name) return;

    dispatch(loginToOrganization(organization._id));
    window.location.reload();
  };

  const addClient = async (values) => {
    await dispatch(postNewClient(values))
      .then((e) => closeDrawer())
      .catch((e) => {
        console.log('err');
      });
  };

  const editClient = async (_id, values) => {
    await dispatch(putClient(_id, values))
      .then((e) => {
        setEditingClient(null);
        closeDrawer();
      })
      .catch((e) => {
        console.log('err');
      });
  };

  const isLogged = (organizationName) => {
    if (userOrganization?.name == organizationName) {
      return <div className="cell-button cell-button--gray"> Logged in </div>;
    }
    return <div className="cell-button">Log in</div>;
  };

  const rows = useMemo(() => {
    return filteredClients.map((client) => ({
      key: client._id,
      cells: [
        { align: 'left', children: client.name },
        { align: 'left', children: client.subscription },
        { align: 'left', children: client.activeUsers || 0 },
        {
          children: (
            <div className={classnames('cell-status', client.active && 'active')}>
              {client.active ? 'Active' : 'Inactive'}
            </div>
          ),
        },
        {
          className: 'action-cell',
          onClick: () => onEditClient(client._id),
          children: (
            <div className="cell-button">
              <img className="cell-button__icon" src={EditIcon} alt="edit" />
              Edit
            </div>
          ),
        },
        {
          className: classnames(
            'action-cell',
            'client-cell-login',
            client.active ? '' : 'disabled'
          ),
          onClick: () => onLogIn(client),
          children: isLogged(client.name),
        },
      ],
    }));
  }, [filteredClients, userOrganization?.name]);

  return (
    <div className={'clients-wrapper'}>
      <div className={'clients-root'}>
        <FiltersBar
          openFilters={() => openDrawer(DRAWER_TYPES.filters)}
          updateSearchValue={setSearchValue}
          isDynamicSearch={true}
        >
          <Button
            variant="contained"
            color="secondary"
            className={'filters-button'}
            onClick={() => openDrawer(DRAWER_TYPES.addClient)}
            type="submit"
            disableTouchRipple
          >
            <img
              src={CloseIcon}
              className={'filters-button__icon'}
              style={{ transform: 'rotate(45deg)', scale: '0.8' }}
              alt="close"
            />
            Add
          </Button>
        </FiltersBar>
        <Paper>
          <Table
            headCells={headCells}
            rows={rows}
            sortBy={sortBy}
            sortOrder={sortOrder}
            onSort={handleSort}
          />
        </Paper>
      </div>
      <Drawer anchor="right" open={!!drawer}>
        {drawer && drawer === DRAWER_TYPES.filters && (
          <ClientsFilter
            closeDrawer={closeDrawer}
            onUpdateFilters={onUpdateFilters}
            filters={filters}
            filtersReset={filtersReset}
          />
        )}
        {drawer && drawer === DRAWER_TYPES.addClient && (
          <AddClientForm closeDrawer={closeDrawer} addClient={addClient} />
        )}
        {drawer && drawer === DRAWER_TYPES.editClient && editClient && (
          <EditClientForm
            closeDrawer={closeDrawer}
            client={editingClient}
            editClient={editClient}
          />
        )}
      </Drawer>
    </div>
  );
};

export default Clients;
