import React from 'react';
import cs from 'classnames';
import DayReport from '../DayReport';
import Summary from '../Summary';
import './styles.scss';
import LoadingSpinner from 'components/LoadingSpinner';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';

const Report = ({
  editable,
  onRemoveTimeclock,
  onChangeTimeclock,
  onCreateTimeclock,
  data,
  onChangeLunchTime,
}) => {

  const { loading } = useSelector((state) => state.timeclocks);


  if(loading)return (
    <Typography align={'center'}>
      <LoadingSpinner height={40} margin={32} />
    </Typography>)  

  return(
  <div>
    {data.summaries &&
      data.summaries.map((s) => (
        <DayReport
          className={cs('timeclock-report-day', { 'timeclock-report-day-empty': !s.summary })}
          data={s.summary}
          date={s.date}
          onChangeLunchTime={onChangeLunchTime}
          onRemoveTimeclock={onRemoveTimeclock}
          onCreateTimeclock={onCreateTimeclock}
          onChangeTimeclock={onChangeTimeclock}
          editable={editable}
          worklog={!!s.summary}
          key={`${s.date}_${s.summary ? s.summary.user && s.summary.user._id : 'user'}`}
        />
      ))}
    <Summary className={'timeclock-report-summary'} data={data} />
  </div>
)};

export default Report;
