export const validation = (values) => {
  const errors = {};
  const requiredFieldError = "This field can't be blank";
  const passFieldError = 'Please enter correct password';
  const numberError = 'Phone number must be 10 digits';
  const correctEmail = 'Enter valid email address';

  if (!values.name.trim()) {
    errors.name = requiredFieldError;
  }

  if (!values.fullName.trim()) {
    errors.fullName = requiredFieldError;
  }

  if (!values.employeeNum.trim()) {
    errors.employeeNum = requiredFieldError;
  }

  if (values.allowedTime < 0) {
    errors.allowedTime = requiredFieldError;
  }

  if (!values.email.trim()) {
    errors.email = requiredFieldError;
  }
  if (!values.email.trim().includes('@') || !values.email.trim().includes('.')) {
    errors.email = correctEmail;
  }

  const phoneNumberDigits = values.phoneNumber.replace(/[^\d]*/g, '');
  if (!values.phoneNumber.trim()) {
    errors.phoneNumber = requiredFieldError;
  }
  if (phoneNumberDigits.length !== 10) {
    errors.phoneNumber = numberError;
  }

  if (values.userPass.trim()) {
    var reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9\s]).{8,}/;
    var rule = reg.test(values.userPass);
    if (!rule) {
      errors.userPass = passFieldError;
    }
  }
  return errors;
};

export const emergencyValidation = (values) => {
  const errors = {};
  const requiredFieldError = "This field can't be blank";
  const numberError = 'Phone number must be 10 digits';

  const emergencyPhoneDigits = values.emergencyPhone.replace(/[^\d]*/g, '');
  if (!values.emergencyPhone.trim()) {
    errors.emergencyPhone = requiredFieldError;
  }
  if (emergencyPhoneDigits.length !== 10) {
    errors.emergencyPhone = numberError;
  }

  if (!values.emergencyRelationship.trim()) {
    errors.emergencyRelationship = requiredFieldError;
  }
  if (!values.emergencyName.trim()) {
    errors.emergencyName = requiredFieldError;
  }

  return errors;
};
