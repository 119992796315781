export default (theme) => ({
  dayWrapper: {
    display: 'flex',
    padding: '8px',
    borderRadius: '10px',
    flexDirection: 'column',
    alignItems: 'center',
    display: 'flex',
    width: '100%',
    minWidth: '25px',
    maxWidth: '45px',
  },
  selectedDay: {
    backgroundColor: '#F3F5FD',
    border: `1px solid ${theme.palette.textColor.primary}`,
  },
});
