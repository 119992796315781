import { convertTo24HourFormat } from 'common/dateFormatConfig';

export const filterDataByTab = (tabIndex, data, settings) => {
  switch (tabIndex) {
    case 0:
      return shiftDataBuilder(data);
    case 1:
      return homePageDataBuilder(data);
    case 2:
      return emailNotificationsDataBuilder(data, settings);
    case 3:
      return reportsDataBuilder(data);
    default:
      return {};
  }
};

const shiftDataBuilder = (values) => {
  return {
    path: 'settings/shifts',
    requestType: 'json',
    data: {
      defaultDayTime: {
        hour:
          values.defaultDayTime &&
          convertTo24HourFormat(values.defaultDayTime.hour, values.defaultDayTime.amPm),
        minute: values.defaultDayTime && values.defaultDayTime.minute,
      },
      defaultNightTime: {
        hour:
          values.defaultNightTime &&
          convertTo24HourFormat(values.defaultNightTime.hour, values.defaultNightTime.amPm),
        minute: values.defaultNightTime && values.defaultNightTime.minute,
      },
      headquartersLocation: (values.headquartersLocation || '').trim(),
      allowedTimeoff: Number.parseInt(values.allowedTimeoff || 0),
      weekStart: values?.weekStart,
      miscStipend: values?.miscStipend,
      injuryReport: values?.injuryReport,
      scheduleNotifications: values?.updateScheduleNotifications,
    },
  };
};
const homePageDataBuilder = (values) => {
  const data = {
    homepage: {
      quote: values.homePageQuote,
      quoteColor: values.homePageQuoteColor,
      countdownDate: values.countdownDate,
      countdownTitle: (values.countdownTitle || '').trim(),
      countdownEnabled: values.countdownEnabled,
    },
  };

  const formData = new FormData();
  formData.append('data', JSON.stringify(data));
  if (values?.deleteImage) {
    formData.append('deleteImage', values?.deleteImage);
  } else if (values && values.homePageImage) {
    formData.append('desktopImage', values.homePageImage);
    formData.append('mobileImage', values.mobileImgFile);
  }
  return {
    path: 'settings/home',
    requestType: 'form-data',
    data: formData,
  };
};
const emailNotificationsDataBuilder = (values, settings) => {
  const result = {
    path: 'settings/email-notifications',
    requestType: 'json',
    data: {
      emailReceivers: (values.emailReceivers || []).filter(
        (receiverObject) => !!receiverObject.email
      ),
      forceAccountAdminEmail: (values.forceAccountAdminEmail || [])
        .filter((email) => !!email)
        .map((email) => email.trim()),
      PTOAdminEmail: (values.PTOAdminEmail || [])
        .filter((email) => !!email)
        .map((email) => email.trim()),
      payrollAdminEmail: (values.payrollAdminEmail || [])
        .filter((email) => !!email)
        .map((email) => email.trim()),
    },
  };

  if (settings?.generateDocusignCertificates) {
    result.data.docuSignSigner = values?.docuSignSigner || { name: '', email: '' };
  }

  return result;
};
const reportsDataBuilder = (values) => {
  return {
    path: 'settings/reports',
    requestType: 'json',
    data: {
      lateClockInEmails: (values.lateClockInEmails || [])
        .filter((email) => !!email)
        .map((email) => email.trim()),
      injuryReportEmails: (values?.injuryReportEmails || [])
        .filter((email) => !!email)
        .map((email) => email.trim()),
    },
  };
};
