import React, { useEffect, useState } from 'react';
import { Typography, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { useSelector } from 'react-redux';
import { FilterButton } from 'pages/Schedule/components/ScheduleHeader/components/FilterButton/FilterButton';
import { DRAWER_TYPES } from '../../../../../SchedulePage/helpers/drawerType';
import { ManageButtons } from 'pages/Schedule/components/ScheduleHeader/components/ManageButtons/ManageButtons';

const Header = ({ classes, openDrawer, publishSchedule }) => {
  const [showPublishModal, setShowPublishModal] = useState(false);

  const updatePublishControl = (value) => () => {
    if (value !== showPublishModal) {
      publishSchedule()
    }
  };
  return (
    <div style={{ margin: '10px 15px 10px 0' }}>
      <Grid container justifyContent="space-between" alignItems="center">
        <FilterButton openDrawer={openDrawer(DRAWER_TYPES.filters)} />
        <ManageButtons
          openDrawer={openDrawer(DRAWER_TYPES.addWorklog)}
          // reconcile={reconcile}
          // showReconcileModal={showReconcileModal}
          reconcile={{}}
          showReconcileModal={() => {}}
          updatePublishControl={updatePublishControl}
        />
      </Grid>
    </div>
  );
};

export default withStyles(styles)(Header);
