import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStylesFacebook = makeStyles((theme) => ({
  root: {
    top: '26px',
    left: '46px',
    position: 'absolute',
    zIndex: 1,
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  top: {
    color: '#1a90ff',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
  circle: {
    color: '#fff',
    strokeLinecap: 'round',
  },
  bgc: {
    color: '#67747b',
  },
}));

export function FacebookCircularProgress(props) {
  const classes = useStylesFacebook();

  if (props.progress == 0) return null;

  return (
    <div className={classes.root}>
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={30}
        thickness={4}
        {...props}
        value={100}
        classes={{
          circle: classes.bgc,
        }}
      />
      <CircularProgress
        variant={props.progress < 100 ? 'determinate' : 'indeterminate'}
        disableShrink
        value={props.progress || 0}
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={30}
        thickness={4}
        {...props}
      />
    </div>
  );
}
