import { createReducer } from '@reduxjs/toolkit';
import contractorsPageActions from './contractorsPageActions';

const initialState = {
  data: [],
  isLoading: false,
  isError: null,
  hasMore: true,
};

const contractors = createReducer(initialState, {
  [contractorsPageActions.getContractorsData]: (state, { payload }) => ({
    ...state,
    data: payload.data,
    isLoading: false,
    hasMore: payload.hasMore,
  }),
  [contractorsPageActions.getContractorsDataAdd]: (state, { payload }) => {
    return {
      ...state,
      data: [...state.data, ...payload.data],
      isLoading: false,
      isError: null,
      hasMore: payload.hasMore,
    };
  },
  [contractorsPageActions.toggleContractorStatus]: (state, { payload }) => {
    const updatedData = state.data.map(el=>{
      if (el._id === payload){
        return {...el, active: !el.active}
      }else return el
    })

    return {
    ...state,
    data: updatedData,
    isError: null,
    }
  },
  [contractorsPageActions.deleteContractor]: (state, { payload }) => ({
    ...state,
    data: state.data.filter(el=>el._id !== payload),
    isError: null,
  }),
  [contractorsPageActions.updateContractor]: (state, { payload }) => {
    const updatedData = state.data.map(el=>{
      if (el._id === payload._id){
        return {...el, ...payload}
      }else return el
    })
    
    return {
    ...state,
    data: updatedData,
    isError: null,
  }},
  [contractorsPageActions.createNewContractor]: (state, { payload }) => ({
    ...state,
    data: [ payload, ...state.data],
  }),
  [contractorsPageActions.contractorsRequest]: (state, { payload }) => ({
    ...state,
    isLoading: payload,
    isError: null,
  }),
  [contractorsPageActions.contractorsError]: (state, { payload }) => ({
    ...state,
    isLoading: false,
    isError: payload,
  }),
});

export default contractors;
