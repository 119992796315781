import React, { useState, useEffect } from 'react';
import './styles.scss';
import SpecialRoleInfo from './components/SpecialRoleInfo/SpecialRoleInfo';
import GeneralInfoBlock from './components/GeneralInfoPart/GeneralPartInfo';
import {
  fetchHomePageData,
  fetchHomePageDataSelectedUser,
} from 'store/homePage/homePageOperations';
import { useDispatch, useSelector } from 'react-redux';
import PageLoader from 'components/PageLoader2/PageLoader2';
import { onDSLogin } from 'pages/Settings/components/SettingsForm/components/ShiftsBlock/WorkShiftsHeader/WorkShiftsHeader';
import { hasPermissionsFor, checkUserRole } from 'helpers/_helpers';
import { useSearchParams, useLocation } from 'react-router-dom';
import { authorizeToDocuSign } from './components/apiCalls';
import { fetchPersonalProfile } from 'store/personalProfile/personalProfileOperations';

const HomePage = () => {
  const data = useSelector((state) => state.homePage.homePage);
  const user = useSelector((store) => store.personalProfile.user);
  const settings = useSelector((store) => store.personalProfile.organization?.settings);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [selectedUser, setSelectedUser] = useState(null);
  const dataSelectedUser = useSelector((state) => state.homePage.homePageSelectedUser);
  const docuSignAuthorization = searchParams.get('code');

  const location = useLocation();
  useEffect(() => {
    setSelectedUser(null);
  }, [location]);

  useEffect(() => {
    if (!!selectedUser) {
      dispatch(fetchHomePageDataSelectedUser(selectedUser._id));
    }
  }, [selectedUser]);

  useEffect(() => {
    if (docuSignAuthorization) {
      authorizeToDocuSign()
        .then((e) => {
          dispatch(fetchPersonalProfile());
        })
        .catch((e) => {});
    }
  }, [docuSignAuthorization]);

  useEffect(() => {
    dispatch(fetchHomePageData());
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const userName = user?.profile?.fullName.split(' ')[0] || user.username;
  const isDocuSignUnauthorized = user?.organization?.docuSignStatus === 'unauthorized';
  const docuSignPermissions =
    hasPermissionsFor('materialCertification') || checkUserRole('Super Admin');

  const generalStatisticData = selectedUser
    ? Object.keys(dataSelectedUser).length
      ? dataSelectedUser
      : data
    : data;

  return (
    <>
      {!data || !user ? (
        <PageLoader loading>
          <div />
        </PageLoader>
      ) : (
        <>
          {!!data && !!user && (
            <section className="section">
              <div className="header-title">
                <h3 className="main-container-title">Hi, {userName}!</h3>
                {docuSignPermissions && isDocuSignUnauthorized && (
                  <span className="primary-text" onClick={onDSLogin}>
                    Login to DocuSign
                  </span>
                )}
              </div>
              <div className="main-container-information">
                <GeneralInfoBlock
                  user={user}
                  selectedUser={selectedUser}
                  data={generalStatisticData}
                  isDocuSignUnauthorized={isDocuSignUnauthorized}
                  docuSignPermissions={docuSignPermissions}
                />
                <SpecialRoleInfo
                  user={user}
                  selectedUser={selectedUser}
                  setSelectedUser={setSelectedUser}
                  data={data}
                  settings={settings}
                />
              </div>
            </section>
          )}
        </>
      )}
    </>
  );
};
export default HomePage;
