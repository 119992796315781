export default theme => ({
  root: {
    padding: '10px 15px !important',
    maxWidth: '1280px',
    margin: '0 auto',
  },
  drawer: {
    // [theme.breakpoints.down('sm')]: {
    //   width: '100%',
    // },
  },
  tabs: {
    borderBottom: '1px solid #e8e8e8',
    paddingTop: 0,
    marginBottom: 10,
    color: theme.palette.textColor.grey,
    '& button': {
      minWidth: 110
    },
    '& button:hover': {
      backgroundColor: 'transparent !important'
    },
    '& button.Mui-selected': {
      color: theme.palette.primary.blue,
    },
    '& span': {
      fontSize: '0.875rem',
      fontWeight: 500
    }
  },
});
