import React from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { getEquipmentColorClass } from 'helpers/_helpers';
import styles from './styles';

const EquipmentItem = ({
  classes,
  equipmentData,
  getFormattedHoursRange,
  // hasAccess,
  // openUserWorklogs,
}) => {
  const getEquipmentInfo = (field) => {
    if (field === 'number') {
      return parseInt(equipmentData.name);
    }
    if (field === 'className') {
      return getEquipmentColorClass(equipmentData.color);
    }
    return equipmentData[field];
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={5} md={equipmentData.hours ? 3 : 12}>
        <div
          className={classnames(
            classes.equipmentBlock,
            getEquipmentInfo('className'),
            // hasAccess && classes.clickable
          )}
          style={{ backgroundColor: getEquipmentInfo('color') }}
          // onClick={hasAccess ? openUserWorklogs : () => null}
        >
          <div className={classes.equipmentNumber}>{getEquipmentInfo('name')}</div>
        </div>
      </Grid>
      <Grid container item xs={7} md={8} spacing={2}>
        <Grid item xs={12} md={5}>
          {!!equipmentData.hours?.length && equipmentData.hours.map((hoursPair) => (
            <div className={classnames(classes.inlineFlex)}>
              <Typography variant="body1" color="textPrimary">
                {getFormattedHoursRange(hoursPair)}
              </Typography>
            </div>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

EquipmentItem.propTypes = {
  classes: propTypes.object.isRequired,
  equipmentData: propTypes.object.isRequired,
  getFormattedHoursRange: propTypes.func.isRequired,
};

export default withStyles(styles)(EquipmentItem);
