import React from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { DetailsView } from './components/DetailsView';
import { createWorklogName } from './helpers/createWorklogName';
import { useHandleDrop } from './hooks/useHandleDrop';
import styles from './styles';


const ClScheduleEvent = (props) => {

  const { handleDrop } = useHandleDrop({
    allSheetsThisDay: props.allSheetsThisDay,
    sheetsInView: props.sheetsInView,
    sheet: props.sheet,
  });

  const handleDragOver = (e) => e.preventDefault();

  const openWorklogDetails = () =>{
    props.navigate(`/schedule/${props.sheet._id}`);
  }

  const openDetailsView = (e) => {
    e.stopPropagation();
    const { openDetails, sheet, cl } = props;
    openDetails(sheet._id, cl?._id);
  };
  const closeDetailsView = (e) => {
    props.openDetails('', '');
  };

  const {
    classes,
    sheet,
    sheetsWithoutForemanIds,
    detailsShown,
    column,
    cl,
    el,
    hasAccess,
    selectedDate,
  } = props;

  return (
    <>
      <div
        id={sheet._id + sheet.COLUMN}
        className={classnames(
          classes.rootEvent,
          sheetsWithoutForemanIds.includes(sheet._id) && classes.missingCL
        )}
        onClick={openWorklogDetails}
        onDragOver={sheet.canceledAt ? null : handleDragOver}
        onDrop={sheet.canceledAt ? null : handleDrop}
      >
        <div
          className={classnames(
            classes.paddingBox,
            sheet.timeOfDay === 'Day' ? classes.dayShift : classes.nightShift,
            classes.fullHeight
          )}
        >
          <Button
            color="secondary"
            className={classes.expandButton}
            onClick={detailsShown ? closeDetailsView : openDetailsView}
            disableRipple
          >
            <ExpandMoreIcon />
          </Button>
          <Typography
            variant="body1"
            color="textSecondary"
            className={classnames(classes.contractor)}
          >
            {sheet.project.contractor.name}
          </Typography>
          <Typography variant="h4" className={classes.header}>
            <span className={classes.marginRightSmall}>
              {createWorklogName(sheet, true)}
            </span>
            {sheet.project.jobNumber && (
              <span className={classes.projectId}>
                #{sheet.project.jobNumber}
              </span>
            )}
          </Typography>
        </div>
      </div>
      {detailsShown && (
        <DetailsView
          openModal={detailsShown}
          selectedDate={selectedDate}
          scheduleEventEl={el}
          sheet={sheet}
          cl={cl}
          handleClose={closeDetailsView}
          hasAccess={hasAccess}
          column={column}
        />
      )}
    </>
  );
};

ClScheduleEvent.propTypes = {
  classes: propTypes.object.isRequired,
  sheet: propTypes.object.isRequired,
  cl: propTypes.string,
  column: propTypes.number,
  hasAccess: propTypes.bool.isRequired,
  sheetsWithoutForemanIds: propTypes.array.isRequired,
  allSheetsThisDay: propTypes.array.isRequired,
  sheetsInView: propTypes.array.isRequired,
  openDetails: propTypes.func.isRequired,
};

export default withStyles(styles)(ClScheduleEvent);
