import React, { useState, memo } from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from 'assets/images/close-icon.svg';
import { hasPermissionsFor } from '../../../../../../helpers/_helpers';
import ConfirmDialog from '../../../../../../components/ConfirmDialog';
import styles from './styles';
import { drawerTypes } from '../../../helpers/drawerTypes';
import { useDispatch } from 'react-redux';
import { fetchDeleteEquipment } from 'store/equipment/equipmentPageOperations';

const EquipmentCard = memo(({ classes, equipment, openDrawer, openSnackbar }) => {
  const dispatch = useDispatch();

  const [isExpanded, setIsExpanded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleExpandClick = (e) => {
    e.stopPropagation();
    setIsExpanded((prev) => !prev);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const deleteEquipment = async() => {
    await dispatch(fetchDeleteEquipment(equipment._id));
    openSnackbar('success', 'Successfully Deleted!')
    closeModal();
  };

  const openDeleteModal = (e) => {
    e.stopPropagation();
    setIsModalOpen(true);
  };

  const isDivider = () => {
    const { yearMade, model } = equipment;
    return Boolean(yearMade || model);
  };

  return (
    <>
      <Card
        className={classes.card}
        onClick={
          hasPermissionsFor('equipmentFullAccess')
            ? openDrawer(drawerTypes.update, equipment)
            : null
        }
      >
        {equipment.color && (
          <div className={classes.colorLine} style={{ backgroundColor: equipment.color }} />
        )}
        <Grid container className={classes.paddingBox}>
          <Grid item xs={12} className={classes.paddingBottom}>
            <div className={classnames(classes.inlineFlex, classes.flexHeader)}>
              <Typography variant="h6" color="textPrimary" className={classes.fixedWidth}>
                {equipment.name}
              </Typography>
              {hasPermissionsFor('equipmentFullAccess') && (
                <Button
                  color="secondary"
                  className={classes.deleteButton}
                  onClick={openDeleteModal}
                  disableRipple
                >
                  <img src={CloseIcon} alt="delete" />
                </Button>
              )}
            </div>
          </Grid>
          {equipment.number && (
            <Grid item xs={12}>
              <Typography variant="body1" color="textSecondary">
                #{equipment.number}
              </Typography>
            </Grid>
          )}
          {isDivider() && (
            <Grid item xs={12} className={classnames(classes.paddingBottom, classes.paddingTop)}>
              <Divider />
            </Grid>
          )}
          {equipment.yearMade && (
            <Grid item xs={12} className={classes.paddingBottom}>
              <Typography
                variant="body1"
                color="textSecondary"
                className={classnames(classes.regularFontWeight, classes.paddingBottomSmall)}
              >
                Year made:
              </Typography>
              <Typography variant="body1" color="textPrimary">
                {equipment.yearMade}
              </Typography>
            </Grid>
          )}
          {equipment.model && (
            <Grid item xs={12} className={classes.paddingBottom}>
              <Typography
                variant="body1"
                color="textSecondary"
                className={classnames(classes.regularFontWeight, classes.paddingBottomSmall)}
              >
                Model:
              </Typography>
              <Typography variant="body1" color="textPrimary">
                {equipment.model}
              </Typography>
            </Grid>
          )}
        </Grid>

        {equipment.description ? (
          <Grid item xs={12}>
            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  className={classnames(classes.paddingBox, classes.paddingTop, classes.borderTop)}
                >
                  <Typography variant="body1" color="textPrimary" className={classes.description}>
                    {equipment.description}
                  </Typography>
                </Grid>
              </Grid>
            </Collapse>
            <CardActions
              className={classes.actionsWrapper}
              disableSpacing
              onClick={handleExpandClick}
            >
              <IconButton
                className={classnames(classes.expand, {
                  [classes.expandOpen]: isExpanded,
                })}
                aria-expanded={isExpanded}
                aria-label="Show more"
                disableRipple
                disableFocusRipple
              >
                <ExpandMoreIcon />
              </IconButton>
              <Typography color="textSecondary">
                {isExpanded ? 'Hide item description' : 'Show item description'}
              </Typography>
              <IconButton
                className={classnames(classes.expand, {
                  [classes.expandOpen]: isExpanded,
                })}
                aria-expanded={isExpanded}
                aria-label="Show more"
                disableRipple
                disableFocusRipple
              >
                <ExpandMoreIcon />
              </IconButton>
            </CardActions>
          </Grid>
        ) : null}
      </Card>

      {isModalOpen && (
        <ConfirmDialog
          isOpen={isModalOpen}
          onClose={closeModal}
          onSubmit={deleteEquipment}
          text="Are you sure you want to delete this piece of equipment?"
          disableEscape
          loadingOnSubmit
        />
      )}
    </>
  );
});

EquipmentCard.propTypes = {
  classes: propTypes.object.isRequired,
  equipment: propTypes.object.isRequired,
  openDrawer: propTypes.func.isRequired,
  updateVisibleEquipment: propTypes.func.isRequired,
};

export default withStyles(styles)(EquipmentCard);
