import {
  allDatesFormat,
  globalBEDateFormat,
  GLOBAL_DATE_FORMAT,
  DAY_PART,
  FNS_DAY_PART_FORMAT,
} from 'common/dateFormatConfig';
import {
  isToday,
  isTomorrow,
  isYesterday,
  parse,
  format,
  addDays,
  startOfDay,
  getHours,
  getMinutes,
  add,
  isSameDay,
  isBefore,
  startOfWeek,
  isValid,
  getTime,
} from 'date-fns';
const dateRegex = /\d{1,2}-\d{1,2}-\d{1,2}/;

export const dayOfWeeekDate = function (timestamp, dateFormatObjConfig) {
  if (timestamp) {
    let momentTimestamp = dateRegex.test(timestamp)
      ? parse(timestamp, allDatesFormat[4], new Date()) //dateFormatObjConfig.dateFormat
      : parse(timestamp, dateFormatObjConfig.dateFormat, new Date());

    if (isToday(momentTimestamp)) {
      return 'Today';
    } else if (isTomorrow(momentTimestamp)) {
      return 'Tomorrow';
    } else if (isYesterday(momentTimestamp)) {
      return 'Yesterday';
    } else {
      return format(momentTimestamp, dateFormatObjConfig.dayDateSettingsFormat);
    }
  }
  return '';
};

export const agoDate = function (timestamp, incrementDay, dateFormat) {
  return format(addDays(new Date(timestamp), incrementDay || 0), dateFormat);
};

export const numberDate = function (timestamp, incrementDay, dateFormat, dateFormatTo) {
  var m;
  if (typeof timestamp == 'string' && timestamp.match(/^[0-9]{1,2}-[0-9]{1,2}-[0-9]{1,4}$/)) {
    m = parse(timestamp, dateFormat, new Date());
  } else {
    m = parseStringDate(timestamp, dateFormat);
  }

  return format(addDays(m, incrementDay || 0), dateFormatTo);
};

export const shortDate = function (timestamp, incrementDay, dateForm = 'M/d') {
  return format(addDays(new Date(timestamp), incrementDay || 0), dateForm);
};

export const signatureDate = function (timestamp) {
  return format(new Date(timestamp), GLOBAL_DATE_FORMAT.fullDate);
};

export const clockTime = function (timestamp, timeFormat) {
  return format(new Date(timestamp), timeFormat);
};

export const getDateFromHours = function (hours, createdAt, getTimeFormatHook) {
  let date = startOfDay(new Date(createdAt));

  const startHoursObj = [null, hours.hourStart, hours.minuteStart, hours.amPmStart];
  const formatForTimePars = getTimeFormatHook().formatForTimePars;
  const formattedStartTime = getTimeFormatHook(startHoursObj).formattedTime;

  const startHours = getHours(parse(formattedStartTime, formatForTimePars, new Date()));

  const startMinutes = getMinutes(parse(formattedStartTime, formatForTimePars, new Date()));

  const endHoursObj = [null, hours.hourEnd, hours.minuteEnd, hours.amPmEnd];
  const formattedEndTime = getTimeFormatHook(endHoursObj).formattedTime;

  const endHours = getHours(parse(formattedEndTime, formatForTimePars, new Date()));
  const endMinutes = getMinutes(parse(formattedEndTime, formatForTimePars, new Date()));

  let start = add(date, {
    days: hours.dayStart,
    hours: startHours,
    minutes: startMinutes,
  });

  let end = add(date, {
    days: hours.dayEnd,
    hours: endHours,
    minutes: endMinutes,
  });

  const travelTimeTo = {
    hours: hours?.travelTimeToHours,
    minutes: hours?.travelTimeToMinutes,
  };

  const travelTimeFrom = {
    hours: hours?.travelTimeFromHours,
    minutes: hours?.travelTimeFromMinutes,
  };

  return {
    start,
    end,
    travelTimeTo,
    travelTimeFrom,
  };
};

export const isWithinCurrentWeek = (date, weekDayFromO) => {
  if (!date || !isValid(date)) return false;
  const weekStart = startOfWeek(new Date(), { weekStartsOn: weekDayFromO });
  return isSameDay(weekStart, date) || isBefore(weekStart, date);
};

export const convertTo24HourFormat = (input, meridiem) => {
  let hour = typeof input === 'string' ? parseInt(input, 10) : input;

  if (meridiem === DAY_PART[0] && hour === 12) {
    hour = 0;
  } else if (meridiem === DAY_PART[1] && hour < 12) {
    hour += 12;
  }

  return hour < 10 ? `0${hour}` : `${hour}`;
};

export const convertToArr = (date, dateFormat, getTimeFormatHook) => {
  const rawHour = getHours(date);
  const hour = getTimeFormatHook().is12Format ? (rawHour % 12 === 0 ? 12 : rawHour % 12) : rawHour;
  return [format(date, dateFormat), String(hour), format(date, FNS_DAY_PART_FORMAT.upper)];
};

export const validParse = (date, dateFormat) => {
  return isValid(parse(date, dateFormat, new Date()))
    ? parse(date, dateFormat, new Date())
    : parse(date, globalBEDateFormat, new Date());
};

export const parseStringDate = (date, format) => {
  let result;
  if (format) {
    result = parse(date, format, new Date());
  } else if (/^\d{4}-\d{2}-\d{2}$/.test(date)) {
    const [year, month, day] = date.split('-');
    result = parse(date, globalBEDateFormat, new Date((+year), (+month - 1), (+day), 0));
  } else if (isValid(new Date(date))) {
    result = new Date(date);
  }

  return result;
}

export const getSheetStartTimeDateFns = (date, startTime) => {
  const startDate = startOfDay(new Date(date));
  const dateTimeWithTime = add(startDate, { hours: startTime.hour, minutes: startTime.minute });

  return getTime(dateTimeWithTime);
}