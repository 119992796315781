import React from 'react';
import styles from './styles';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CloseIcon from 'assets/images/close-icon.svg';

const SurfaceCard = ({ classes, onDelete = () => null, data, ...props }) => (
  <div className={classes.root} {...props}>
    <div className={classes.header}>
      <span className={classes.title}>{data.name}</span>
      <Button
        color="secondary"
        className={classes.deleteButton}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onDelete(data);
        }}
        disableRipple
      >
        <img src={CloseIcon} alt="delete" />
      </Button>
    </div>
  </div>
);

export default withStyles(styles)(SurfaceCard);
