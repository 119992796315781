import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  TextField,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
  postProjectTypes,
  putProjectTypes,
  deleteProjectType,
} from 'store/projectTypes/projectTypesOperations';
import ProjectTypesActions from 'store/projectTypes/projectTypesActions';
import CloseIcon from 'assets/images/close-icon.svg'
import PencilI from "assets/images/edit-icon-pencil.svg"
import MenuItem from '@material-ui/core/MenuItem';
import styles from './styles';

// Try to make it as a reusable component
const ProjectTypes = ({
  classes,
  setFieldValue,
  project,
  handleBlur,
  errors,
  value,
  touched,
  openSnackbar,
  isSubmitting,
}) => {
  const projectTypesOptions = useSelector((state) => state.projectTypes.projectTypes.projectTypes);
  const snackBarInfo = useSelector((state) => state.projectTypes.projectTypes.snackBarInfo);
  const dispatch = useDispatch();

  const [isInput, setIsInput] = useState(false);
  const [typeInputValue, setTypeInputValue] = useState({ _id: '', name: '' });
  const [typeSelectValue, setTypeSelectValue] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [ifProjectExists, setIfProjectExists] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [deleteType, setDeleteType] = useState(false);

  const [newTypeValue, setNewTypeValue] = useState('');

  useEffect(() => {
    if (snackBarInfo) {

      openSnackbar(snackBarInfo.type, snackBarInfo.text);
      dispatch(ProjectTypesActions.snackBarInfo(null));
      if (newTypeValue && snackBarInfo.type === 'success') {
        const newValue = projectTypesOptions.find((elem) => elem.name === newTypeValue);

        setFieldValue('type', newValue.name);
        setTypeSelectValue(newValue._id);
        setNewTypeValue('');
      }
      if (newTypeValue && snackBarInfo.type === 'error') {
        setNewTypeValue('');
      }
    }
  }, [snackBarInfo]);

  useEffect(() => {
    if (project && value && !isSubmitting) {
      const typeId = projectTypesOptions.find((elem) => elem.name === project.type);
      if (typeId) {
        setTypeSelectValue(typeId._id);
      } else {
        setIsInput(true);
        setTypeInputValue({ _id: '', name: project.type });
        setDeleteType(true);
      }
    }
  }, [project]);

  useEffect(() => {
    if (value) {
      const typeId = projectTypesOptions.find((elem) => elem.name === value);
      if (typeId) {
        setTypeSelectValue(typeId._id);
      }
    }
  }, []);

  const handleChangeInput = (e) => {
    const value = e.target.value;
    setTypeInputValue((prev) => ({ ...prev, name: value }));
  };

  const saveType = () => {
    delete errors.type;
    dispatch(postProjectTypes({ name: typeInputValue.name }));
    setDeleteType(false);
    setNewTypeValue(typeInputValue.name);
    setIsInput(false);
  };
  const handleSaveEdit = () => {
    editProjectTypes(typeInputValue._id);
    setIsInput(false);
    setTypeSelectValue('');
    setNewTypeValue(typeInputValue.name);
    setIsEdit(false);
  };
  const removeInput = (e) => {
    setIsInput(false);
    setIsEdit(false);
    setDeleteType(false);
    if (deleteType) {
      setFieldValue('type', '');
    }
  };

  const deleteProjectTypes = (typeId) => {
    dispatch(deleteProjectType(typeId));
    if (typeId === typeSelectValue) {
      setTypeSelectValue('');
      setFieldValue('type', '');
    }
  };

  const editProjectTypes = (typeId) => {
    dispatch(putProjectTypes(typeId, { name: typeInputValue.name }));
  };

  const handleChangeTypeSelect = (e) => {
    const value = e.target.value;
    if (value === 'add') {
      setIsInput(true);
    } else {
      setTypeSelectValue(value);
      const typeName = projectTypesOptions.find((elem) => elem._id === value);
      setFieldValue('type', typeName.name);
    }
  };

  const handleEditButton = (id) => (e) => {
    const item = projectTypesOptions.find((elem) => elem._id === id);
    setTypeInputValue(item);
    setTypeSelectValue(item._id);
    setIsInput(true);
    setIsEdit(true);
  };

  const handleDeleteButton = (id) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    const item = projectTypesOptions.find((elem) => elem._id === id);
    if (item.hasSubmittedWLs) {
      setIfProjectExists(true);
      setItemToDelete(id);
    } else {
      deleteProjectTypes(id);
    }
  };

  const deleteItem = () => {
    deleteProjectTypes(itemToDelete);
    setIfProjectExists(false);
    setItemToDelete(null);
  };

  const declineDeleteItem = () => {
    setIfProjectExists(false);
  };

  const handleAddBtn = () => {
    setTypeInputValue({ _id: '', name: '' });
    setIsInput(true);
  };

  return (
    <>
      {isInput ? (
        <TextField
          value={typeInputValue.name || ''}
          onChange={handleChangeInput}
          type="text"
          className={classes.textField}
          variant="outlined"
          fullWidth
          inputProps={{ 'aria-label': 'Type' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <div className={classes.inlineFlex}>
                  {!isEdit && (
                    <Button
                      variant="text"
                      className={classnames(classes.inputButton, classes.primaryButton)}
                      onClick={saveType}
                      disabled={!typeInputValue.name}
                    >
                      Add
                    </Button>
                  )}
                  {isEdit && (
                    <Button
                      variant="text"
                      className={classnames(classes.inputButton, classes.primaryButton)}
                      onClick={handleSaveEdit}
                      disableRipple
                    >
                      Save
                    </Button>
                  )}
                  <Button
                    variant="text"
                    className={classes.inputButton}
                    onClick={removeInput}
                    disableRipple
                  >
                    Cancel
                  </Button>
                </div>
              </InputAdornment>
            ),
          }}
        />
      ) : (
        <TextField
          select
          name="type"
          id="type"
          value={typeSelectValue}
          onChange={handleChangeTypeSelect}
          onBlur={handleBlur}
          error={Boolean(errors.type) && Boolean(touched.type)}
          className={classes.textField}
          variant="outlined"
          fullWidth
        >
          {projectTypesOptions &&
            projectTypesOptions.map((type) => (
              <MenuItem key={type._id} value={type._id} className={classes.option}>
                {type.name}
                <div className={classes.inputAdor}>
                  <div className={classes.inlineFlex}>
                    <Button
                      color="primary"
                      className={classes.iconButton}
                      onClick={handleEditButton(type._id)}
                      disableRipple
                    >
                      <img
                        src={PencilI}
                        className={classes.optionsIcons}
                        alt="edit"
                      />
                    </Button>
                    <Button
                      color="primary"
                      className={classes.iconButton}
                      onClick={handleDeleteButton(type._id)}
                      disableRipple
                    >
                      <img
                        src={CloseIcon}
                        alt="delete"
                        className={classes.optionsIcons}
                      />
                    </Button>
                  </div>
                </div>
              </MenuItem>
            ))}
          <MenuItem key={5} value="add">
            <Button
              variant="text"
              className={classes.addButton}
              onClick={handleAddBtn}
              disableRipple
            >
              + Add Project Type
            </Button>
          </MenuItem>
        </TextField>
      )}
      {deleteType && (
        <Typography variant="body1" color="textSecondary">
          This project type was deleted. Do you want to add it again?
        </Typography>
      )}
      {ifProjectExists && (
        <Dialog
          open={Boolean(ifProjectExists)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            classes: {
              root: classes.dialogRoot,
            },
          }}
          disableEscapeKeyDown
        >
          <DialogContent>
            <Typography variant="h5" component="div">
              There are submitted Work-Logs in the system with this project type. Are you sure that
              you want to delete it?
            </Typography>
          </DialogContent>
          <DialogActions>
            <div className={classes.flexBox}>
              <Button
                color="primary"
                variant="outlined"
                size="medium"
                className={classes.saveButton}
                disableTouchRipple
                onClick={deleteItem}
              >
                Yes
              </Button>
              <Button
                color="secondary"
                variant="outlined"
                size="medium"
                className={classes.cancelButton}
                onClick={declineDeleteItem}
                disableTouchRipple
              >
                No
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default withStyles(styles)(ProjectTypes);
