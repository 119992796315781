import $api from 'http/index';
import { getTimeZone } from 'helpers/getTimeZone';

export const resendEmail = async (id, openSnackbar) => {

  const options = {
    headers: {
      'X-Auth-Token': localStorage.getItem('authorization-token'),
      'X-User-TZ': getTimeZone(),
    },
  };

  try {
    const res = await $api.post(`${process.env.REACT_APP_BASE_URL}/submissions/${id}/resend-email`, {}, options);
    openSnackbar('success', 'Email Resent');
  } catch (e) {
    openSnackbar('error', JSON.stringify(e));
  }
};
