import React, { useMemo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DayReport from '../../../TimeClock/components/DayReport';
import useStyles from './useStyles';
import NoData from '../../../TimeClock/components/NoData';
import useTimeclockActions from '../../../TimeClock/hooks/useTimeclockActions';
import { getTimeclockSummary } from 'store/cip/cipPagesOperations';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import Typography from '@material-ui/core/Typography';

const Hours = () => {
  const hours = useSelector((state) => state.cip.hours);
  const classes = useStyles();
  const dispatch = useDispatch();
  const timeclocks = useMemo(
    () => hours.data.reduce((accum, d) => [...accum, ...d.timeclock], []),
    [hours.data]
  );
  const [loading, setLoading] = useState(false);
  const { onChange, onRemove, onCreate, onChangeLunchTime } = useTimeclockActions(timeclocks);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(getTimeclockSummary(hours.query?.date, true));
      setLoading(false);
    };
    fetchData();
  }, [hours.query.date]);

  const refreshAfter = async(callback, timeclock) =>{
    await callback(timeclock)
    dispatch(getTimeclockSummary(hours.query?.date, true));
  }

  return (
    <div className={classes.root}>
      {loading ? (
        <Typography align={'center'}>
          {' '}
          <LoadingSpinner height={40} margin={32} />
        </Typography>
      ) : (
        <>
          {hours.data.map((report) => (
            <DayReport
              userTitle={{
                name: report.user.profile.fullName,
                number: report.user.profile.employeeNum,
              }}
              className={classes.dayReport}
              key={report.user._id}
              data={report}
              worklog={true}
              editable={true}
              onChangeTimeclock={(timeclock)=> refreshAfter(onChange,timeclock)}
              onRemoveTimeclock={(timeclock)=> refreshAfter(onRemove,timeclock)}
              onCreateTimeclock={(timeclock)=> refreshAfter(onCreate,timeclock)}
              onChangeLunchTime={(timeclock)=> refreshAfter(onChangeLunchTime,timeclock)}
            />
          ))}
          {!hours.data.length && <NoData />}
        </>
      )}
    </div>
  );
};

export default Hours;
