import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import useStyles from './useStyles';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';

const BaseDrawer = ({ title, footer, children, anchor = 'right', onClose = () => null, ...props }) => {
  const classes = useStyles();

  return (
    <Drawer
      {...props}
      anchor={anchor}
      PaperProps={{ className: classes.paper }}
      ModalProps={{
        onBackdropClick: () => onClose(),
      }}
    >
      <div className={classes.root}>
        <Grid className={classes.grid} container spacing={1} direction={'column'} justifyContent={'space-between'}>
          <Grid item xs={'auto'}>
            <div className={classes.header}>
              <div className={classes.title}>{title}</div>
              <div className={classes.closeBtn}>
                <IconButton onClick={onClose}>
                  <Close />
                </IconButton>
              </div>
            </div>
          </Grid>
          <Grid item xs>
            <div className={classes.body}>{children}</div>
          </Grid>
          <Grid item xs={'auto'}>
            <div className={classes.footer}>{footer}</div>
          </Grid>
        </Grid>
      </div>
    </Drawer>
  );
};

export default BaseDrawer;
