export default (theme) => ({
  textField: {
    maxHeight: 30,
    [theme.breakpoints.up('lg')]: {
      // maxWidth: 250,
    },
  },
  error: {
    color: theme.palette.textColor.red,
    padding: '0 5px',
    fontSize: 11,
  },
  requiredSign: {
    color: theme.palette.textColor.red,
  },
  zeroPaddingTop: {
    paddingTop: '0px !important',
  },
  paddingTop: {
    paddingTop: 8,
  },
  inlineFlex: {
    display: 'inline-flex',
  },
  flexEnd: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
  primaryTextButton: {
    color: theme.palette.textColor.primary,
    '&:hover': {
      color: theme.palette.textColor.primary,
      background: theme.palette.background.default,
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
      background: theme.palette.background.default,
    },
  },
  button: {
    borderColor: 'rgba(255, 255, 255, 0)',
    padding: '8px 16px',
    position: 'relative',
    zIndex: 0,
    height: 40,
    fontSize: 14,
    color: theme.palette.textColor.secondary,
    '&:hover': {
      color: theme.palette.textColor.secondary,
      background: theme.palette.background.default,
    },
    '&:focus': {
      color: theme.palette.textColor.secondary,
      background: theme.palette.background.default,
    },
  },
  selectedButton: {
    border: `1px solid ${theme.palette.borderColor.blue} !important`,
    color: theme.palette.textColor.primary,
    position: 'relative',
    zIndex: 100,
    top: '-1px',
    height: 42,
    paddingTop: 9,
    background: theme.palette.background.default,
    borderRadius: '4px !important',
    '&:hover': {
      color: theme.palette.textColor.primary,
      background: theme.palette.background.default,
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
      background: theme.palette.background.default,
    },
  },
  buttonsWrapper: {
    border: `1px solid ${theme.palette.borderColor.grey}`,
    borderRadius: 5,
    height: 42,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  doubleButton: {
    '& button': {
      width: 140,
    },
  },
  trippleButton: {
    '& button': {
      width: 97,
    },
  },
  leftButton: {
    borderRadius: '4px 0 0 4px',
    borderRightColor: 'rgba(255, 255, 255, 0)',
  },
  leftShift: {
    left: '-1px',
    paddingLeft: 18,
  },
  centerButton: {
    borderRadius: 0,
    borderLeftColor: 'rgba(255, 255, 255, 0)',
    borderRightColor: 'rgba(255, 255, 255, 0)',
  },
  borderLeft: {
    '&::before': {
      content: '""',
      width: 1,
      height: 41,
      position: 'absolute',
      top: -1,
      left: 0,
      backgroundColor: theme.palette.borderColor.grey,
    },
  },
  borderRight: {
    '&::after': {
      content: '""',
      width: 1,
      height: 41,
      position: 'absolute',
      top: -1,
      right: 0,
      backgroundColor: theme.palette.borderColor.grey,
    },
  },
  rightButton: {
    borderRadius: '0 4px 4px 0',
    borderLeftColor: 'rgba(255, 255, 255, 0)',
  },
  rightShift: {
    right: '-1px',
    paddingRight: 18,
  },
  textArea: {
    width: '100%',
    '& textarea': {
      boxShadow: 'none',
      padding: 10,
      fontSize: 14,
      height: '60px !important',
      maxHeight: '60px !important',
    },
  },
});
