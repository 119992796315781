import React from 'react';
import { useSelector } from 'react-redux';
import ConfirmDialog from '../../../../../../components/ConfirmDialog/ConfirmDialog';
import AlertModal from '../../../../../../components/AlertModal/AlertModal';
import ResourceConflictModal from '../../../ScheduleCalendar/components/ResourceConflictModal/ResourceConflictModal';
import OverlappingModal from '../../../../../../components/OverlapsingModal/OverlapsingModal';

const Modals = () => {
  const { modals } = useSelector(state => state.modals);

  return (
    <>
      {modals.remove?.open && <ConfirmDialog
        isOpen={modals.remove.open}
        onClose={modals.remove.onClose}
        onSubmit={modals.remove.onSubmit}
        text={modals.remove.data}
        loadingOnSubmit
      />}
      {modals.info?.open && (
        <AlertModal
          isOpen={modals.info.open}
          info={modals.info.data}
          onClose={modals.info.onClose}
        />
      )}
      {modals.conflict?.open && (
        <ResourceConflictModal
          data={modals.conflict.data}
          closeModal={modals.conflict.onClose}
        />
      )}
      {modals.overlapping?.open && (
        <OverlappingModal
          data={modals.overlapping.data && modals.overlapping.data.data}
          message={modals.overlapping.data && modals.overlapping.data.reason}
          onClose={modals.overlapping.onClose}
          onSave={modals.overlapping.onSubmit}
          moveFrom={modals.overlapping.data.moveFrom}
        />
      )}
    </>
  );
};


export default Modals;