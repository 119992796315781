import { createReducer } from '@reduxjs/toolkit';
import modalsPageActions from 'store/modals/modalsPageActions';

const initialState = {
  modals: {},
  modalTokens: [],
};

const modals = createReducer(initialState, {
  [modalsPageActions.closeAllModals]: (state, { payload }) => ({
    ...state,
    modals: {
      ...state.modalTokens.reduce((result, token) => ({ ...result, [token]: { ...state.modals[token], open: false } }), {})
    }
  }),
  [modalsPageActions.closeModal]: (state, { payload }) => {
    const token = payload?.name || payload;

    if (typeof token !== 'string') return state;
    if (!state.modalTokens.includes(token)) return state;

    return {
      ...state,
      modals: {
        ...state.modals,
        [token]: {
          ...state.modals[token],
          open: false,
        }
      }
    }
  },
  [modalsPageActions.addModal]: (state, { payload }) => {
    const modals = { ...state.modals };
    const modalTokens = [...state.modalTokens];
    
    if (!payload.name || !payload.onSubmit || !payload.onClose) return state;
    if (!modalTokens.includes(payload.name )) modalTokens.push(payload.name);
    modals[payload.name] = {
      ...payload,
      open: false,
      data: payload.data || null,
      onSubmit: payload.onSubmit,
      onClose: payload.onClose,
    }
    
    return {
      ...state,
      modals,
      modalTokens,
    }
  },
  [modalsPageActions.addModals]: (state, { payload }) => {
    if (!Array.isArray(payload)) return state;
    const modals = { ...state.modals };
    const modalTokens = [...state.modalTokens];
    for (const modal of payload) {
      if (!modal.name || !modal.onSubmit || !modal.onClose) return state;
      if (!modalTokens.includes(modal.name )) modalTokens.push(modal.name);
      modals[modal.name] = {
        ...modal,
        open: false,
        data: modal.data || null,
        onSubmit: modal.onSubmit,
        onClose: modal.onClose,
      }
    }
    
    return {
      ...state,
      modals,
      modalTokens,
    }
  },
  [modalsPageActions.openModal]: (state, { payload }) => {
    if (!state.modalTokens.includes(payload.name)) return state;

    return {
      ...state,
      modals: {
        ...state.modals,
        [payload.name]: {
          ...state.modals[payload.name],
          ...payload,
          open: true,
          data: payload.data || state.modals[payload.name].data,
        }
      }
    }
  },
  [modalsPageActions.setModalData]: (state, { payload }) => {
    if (!state.modalTokens.includes(payload.name)) return state;

    return {
      ...state,
      modals: {
        ...state.modals,
        [payload.name]: {
          ...state.modals[payload.name],
          ...payload,
        }
      }
    }
  },
  [modalsPageActions.removeModal]: (state, { payload }) => {
    const modals = { ...state.modals };
    const modalTokens = state.modalTokens.filter(token => token !== payload.name);
    delete modals[payload.name];

    return {
      ...state,
      modals,
      modalTokens,
    }
  },
});

export default modals;
