export default (theme) => ({
  secondaryText: {
    color: theme.palette.textColor.secondary,
    fontSize: '0.8rem',
    fontWeight: 400,
    marginLeft: 10,
  },
  inlineBlock: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  specificField: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  specificFieldInput: {
    width: '60%',
    [theme.breakpoints.down('sm')]: {
      width: 250,
    },
  },
});
