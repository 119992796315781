import {InputBase, withStyles} from "@material-ui/core";
import PopoverBase from "@material-ui/core/Popover";

export default withStyles({
    root: {
        border: '1px solid #D9DAE3 !important',
        borderRadius: '4px !important',
        backgroundColor: '#FFFFFF !important',
        padding: '10px !important',
        margin: '0 !important',
        height: '40px !important',
        color: '#202020 !important',
        fontSize: '14px !important',
        fontWeight: '500 !important',
        letterSpacing: '0 !important',
        lineHeight: '20px !important',
    },
    input: {
        padding: '0 !important',
        backgroundColor: 'transparent !important',
        margin: '0 !important',
        border: 'none !important',
        boxShadow: 'none !important',
    }
})(InputBase)

export const Popover = withStyles({
    paper: {
        overflow: 'visible',
        backgroundColor: 'transparent'
    }
})(PopoverBase)