export default (theme) => ({
  bold: {
    fontWeight: 500,
    fontSize: 14,
  },
  regularText: {
    fontSize: 12,
  },
  regularBold: {
    fontWeight: 500,
  },
  btn: {
    marginLeft: '-10px',
    '&:hover': {
      background: `${theme.palette.background.transparent} !important`,
    },
    '&:focus': {
      background: `${theme.palette.background.transparent} !important`,
    },
  },
  cardWrapper: {
    marginRight: 25,
    marginBottom: 15,

    position: 'relative',
    width: '120px',

  },
  delImg: {
    width: '18px',
    height: '18px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '5px',
    justifyContent: 'center',
    backgroundColor: '#e1e9fc',
    position: 'absolute',
    top: '-9px',
    right: '-9px',
    cursor: 'pointer',
    '& svg': {
      scale: '0.6',
    },
  },
  positionerWrapper: {
    position: 'relative',
  },
  imgWrapper: {
    width: '120px',
    height: '80px',
    borderRadius: '3px',
    overflow: 'hidden',
    marginBottom: 8,

    '& img': {
      objectFit: 'cover',
      width: '100%',
      height: '100%',
    },
  },
  circularProgress: {
    position: 'absolute',
  },
  refreshI: {
    top: '28px',
    left: '50px',
    color: '#ffffffd4',
    width: '25px',
    height: '25px',
    position: 'absolute',
    fontSize: '22px',
    zIndex: 1,
  },
  uploadBtn:{
    cursor: 'pointer',
  },
});
