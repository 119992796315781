import React from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { hasPermissionsFor } from 'helpers/_helpers';
import styles from './styles';
import { drawerTypes } from '../helpers/drawerTypes';

const EquipmentHeader = ({ classes, openDrawer }) => {
  return (
    <header className={classes.header}>
      <Typography variant="h3">Equipment</Typography>
      {hasPermissionsFor('equipmentFullAccess') && (
        <Button
          variant="text"
          color="secondary"
          className={classes.addButton}
          onClick={openDrawer(drawerTypes.create)}
          disableTouchRipple
        >
          + Add Equipment
        </Button>
      )}
    </header>
  );
};

EquipmentHeader.propTypes = {
  classes: propTypes.object.isRequired,
  openDrawer: propTypes.func.isRequired,
};

export default withStyles(styles)(EquipmentHeader);
