import TextField from '@material-ui/core/TextField';
import { Option, isIOS } from 'components/Option';
import { withStyles } from '@material-ui/core';
import styles from './styles';
import { CALENDAR_VIEW_TYPES } from './constants';
import { useSelector, useDispatch } from 'react-redux';
import schedulePageActions from 'store/schedule/schedulePageActions';

const DateTypeSelector = ({ classes }) => {
  const dispatch = useDispatch();
  const scheduleDateType = useSelector((state) => state.schedule.calendarTypeView);
  const onHandleChange = (type) => {
    dispatch(schedulePageActions.setCalendarTypeView(type));
    if (type === CALENDAR_VIEW_TYPES.monthly) dispatch(schedulePageActions.setSheets([]));
  };

  return (
    <div>
      <TextField
        select
        id="week"
        name="week"
        value={scheduleDateType}
        onChange={(e) => onHandleChange(e.target.value)}
        className={classes.dateSelector}
        variant="outlined"
        fullWidth
        SelectProps={{
          native: isIOS,
        }}
      >
        <Option key={CALENDAR_VIEW_TYPES.daily} value={CALENDAR_VIEW_TYPES.daily}>
          Daily
        </Option>
        <Option key={CALENDAR_VIEW_TYPES.monthly} value={CALENDAR_VIEW_TYPES.monthly}>
          Monthly
        </Option>
      </TextField>
    </div>
  );
};

export default withStyles(styles)(DateTypeSelector);
