import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import classnames from 'classnames';

import styles from './styles';
import { isIOS, Option } from '../Option';
import {
  patchLocationWorklogMaterial,
  patchSurfaceWorklogMaterial,
} from 'store/currentWorklog/operations';
import $api from 'http/index';
import { getOptions } from 'helpers/getOptions';
import currentWorklogActions from 'store/currentWorklog/actions';
import SurfaceInput from 'components/SurfaceInput/index';

const LocationSurfaceInput = ({
  classes,
  locationOptions,
  setLocationOptions,
  surfaceOptions,
  groupedMaterials,
  sheet,
  openSnackbar,
  shouldRenderAddButton,
  openAdditionalDrawer,
  isMaterialForm,
  setFieldValue,
  values,
  errors,
  touched,
  handleBlur,
  hasAccess,
}) => {
  const dispatch = useDispatch();
  const [isLocationInputOpen, setIsLocationInputOpen] = useState(false);
  const [locationInputValue, setLocationInputValue] = useState('');

  const locationValue = useMemo(() => {
    if (isMaterialForm && !values?.location) return '';

    if (values?.location) {
      return values.location;
    }
    if (groupedMaterials?.location) {
      return groupedMaterials.location;
    }
    if (locationOptions.length) {
      const index = locationOptions.length === 1 ? 0 : locationOptions.length - 1;
      return locationOptions[index].id;
    }
    return '';
  }, [groupedMaterials && groupedMaterials.location, values && values.location]);

  const surfaceValue = useMemo(() => {
    return values?.surface || groupedMaterials.surface || surfaceOptions[0];
  }, [groupedMaterials && groupedMaterials.surface, values && values.surface]);

  const handleChangeInput = (e) => setLocationInputValue(e.target.value);

  const handleChangeLocationSelect = (e) => {
    const value = e.target.value;
    if (value === 'add') {
      setLocationInputValue('');
      setIsLocationInputOpen(true);
    } else {
      if (isMaterialForm) {
        setFieldValue('location', value);
      } else {
        const prevLocation = locationValue;

        dispatch(
          patchLocationWorklogMaterial(sheet._id, {
            prevLocation,
            nextLocation: value,
            surface: surfaceValue,
          })
        );
      }
    }
  };

  const saveLocation = async () => {
    if (locationInputValue.trim().length < 1) return;

    try {
      const res = await $api.post(
        `${process.env.REACT_APP_BASE_URL}/sheets/${sheet._id}/materials/location
      `,
        { name: locationInputValue.trim() },
        {
          ...getOptions(),
        }
      );
      if (isMaterialForm) {
        setLocationOptions((prev) => [...prev, res.data]);
        setFieldValue('location', res.data.id);
      }
      dispatch(
        currentWorklogActions.setWorklogSheet({
          ...sheet,
          materialLocations: [
            ...(sheet?.materialLocations ? sheet.materialLocations : []),
            res.data,
          ],
        })
      );
      setIsLocationInputOpen(false);
      setLocationInputValue('');
    } catch (e) {
      openSnackbar('error', 'Location with this name already exists for this worklog');
      return;
    }
  };

  const removeInput = () => setIsLocationInputOpen(false);

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} md={shouldRenderAddButton ? 5 : 6}>
        <Typography variant="body1" color="textSecondary">
          Location{isMaterialForm ? <span className={classes.requiredSign}>*</span> : null}:
        </Typography>
        {isLocationInputOpen ? (
          <TextField
            value={locationInputValue}
            onChange={handleChangeInput}
            className={classes.textField}
            error={Boolean(errors?.location)}
            variant="outlined"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <div className={classes.inlineFlex}>
                    <Button
                      variant="text"
                      className={classnames(classes.inputButton, classes.primaryButton)}
                      onClick={saveLocation}
                      disableRipple
                    >
                      Add
                    </Button>
                    <Button
                      variant="text"
                      className={classes.inputButton}
                      onClick={removeInput}
                      disableRipple
                    >
                      Cancel
                    </Button>
                  </div>
                </InputAdornment>
              ),
            }}
          />
        ) : (
          <TextField
            select
            name="location"
            value={locationValue}
            onChange={handleChangeLocationSelect}
            onBlur={isMaterialForm ? handleBlur : null}
            error={isMaterialForm ? Boolean(errors.location && touched.location) : null}
            className={classes.locationSelect}
            variant="outlined"
            fullWidth
            disabled={!isMaterialForm && !hasAccess}
            SelectProps={{
              native: isIOS,
            }}
          >
            {isMaterialForm && isIOS ? <Option key={0} value="" /> : null}
            {locationOptions.length
              ? locationOptions.map((location) => (
                  <Option key={location?._id || location?.id} value={location?._id || location?.id}>
                    {location.name || location.address}
                  </Option>
                ))
              : null}
            <Option key={1} value="add">
              + Add Location
            </Option>
          </TextField>
        )}
        {isMaterialForm && errors.location && touched.location ? (
          <div className={classes.error}>{errors.location}</div>
        ) : null}
      </Grid>

      <Grid item xs={12} md={shouldRenderAddButton ? 4 : 6}>
        <SurfaceInput
          values={values}
          locationOptions={locationOptions}
          groupedMaterials={groupedMaterials}
          isMaterialForm={isMaterialForm}
          setFieldValue={setFieldValue}
          hasAccess={hasAccess}
          sheet={sheet}
          errors={errors}
          handleBlur={handleBlur}
          touched={touched}
        />
      </Grid>

      {shouldRenderAddButton && (
        <Grid item xs={12} md={3} className={classes.addWorkButton}>
          <Button
            variant="text"
            className={classnames(classes.inputButton, classes.primaryButton, classes.noHover)}
            onClick={openAdditionalDrawer('material', 'create')}
            disableRipple
          >
            + Add Material
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

LocationSurfaceInput.propTypes = {
  classes: propTypes.object.isRequired,
  locationOptions: propTypes.array.isRequired,
  surfaceOptions: propTypes.array.isRequired,
  groupedMaterials: propTypes.object.isRequired,
  sheet: propTypes.object.isRequired,
  openSnackbar: propTypes.func,
  shouldRenderAddButton: propTypes.bool,
  openAdditionalDrawer: propTypes.func,
  isMaterialForm: propTypes.bool,
  setFieldValue: propTypes.func,
  values: propTypes.object,
  errors: propTypes.object,
  touched: propTypes.object,
  handleBlur: propTypes.func,
  hasAccess: propTypes.bool,
};

export default withStyles(styles)(LocationSurfaceInput);
