export default (theme) => ({
  flexBox: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 20
    }
  },
  inlineFlex: {
    display: 'inline-flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  column: {
    flexDirection: 'column',
  },
  reverseColumn: {
    flexDirection: 'column-reverse',
  },
  marginBottom: {
    marginBottom: 8,
  },
  rowWrapper: {
    marginBottom: 8,
    width: '100%',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  textField: {
    minWidth: 60,
    margin: '0 5px',
    '&:last-child': {
      margin: '0 0 0 5px',
    },
    '&:first-child': {
      margin: '0 5px 0 0',
    },
    '& .MuiInputAdornment-positionEnd': {
      marginLeft: 0,
    },
  },
  dateField: {
    minWidth: '70px!important',
  },
  regularFontWeight: {
    fontWeight: 300,
  },
  paddingBottom: {
    paddingBottom: 8,
  },
  disabledButton: {
    cursor: 'not-allowed',
  },
  deleteButton: {
    padding: 0,
    marginLeft: 15,
    color: theme.palette.textColor.red,
    width: 24,
    '&:hover': {
      color: theme.palette.textColor.red,
      background: theme.palette.background.default,
    },
    '&:focus': {
      color: theme.palette.textColor.red,
      background: theme.palette.background.default,
    },
  },
  individualDeleteButton:{
    [theme.breakpoints.down('sm')]: {
      marginBottom: '-25px',
    }
  },
  travelTimeBlock: {
    margin: '10px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  adornment: {
    color: theme.palette.textColor.secondary,
    paddingRight: '5px',
    fontSize: '0.875rem',
  },
  travelInput: {
    minWidth: 65,
  },
  travelTimeWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  travelTimeInputWrapper: {
    display: 'flex',
    '& > div': {
      maxWidth: 80,
      '&:first-child': {
        marginRight: 5,
      },
    },
  },
  travelInputsWrapper: {
    width: '100%',
  },
  hidden: {
    visibility: 'hidden',
  },
  addButton: {
    padding: 0,
    color: theme.palette.textColor.primary,
    '&:hover': {
      color: theme.palette.textColor.primary,
      background: `${theme.palette.background.default} !important`,
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
      background: `${theme.palette.background.default} !important`,
    },
  },
  textCenter: {
    textAlign: 'center',
  },
  startTimeWrapper: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
  },
  mobileHidden: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
});
