import React, { useState, useEffect, useRef, useMemo } from 'react';
import { withStyles } from '@material-ui/core/styles/index';
import UnitsType from './components/UnitsType/UnitsType';
import MaterialMeasurement from './components/MaterialMeasurement/MaterialMeasurement';
import MarkingTypes from './components/MarkingTypes/MarkingTypes';
import LineSpecifications from './components/LineSpecifications/LineSpecifications';
import LegendSpecifications from './components/LegendSpecifications/LegendSpecification';
import LetteringSpecifications from './components/LetteringSpecifications/LetteringSpecifications';
import GlassBeats from './components/GlassBeats/GlassBeats';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography/index';
import IconButton from '@material-ui/core/IconButton/index';
import PageLoader from 'components/PageLoader/PageLoaderMaterialForm';
// import Button from '@material-ui/core/Button/index';
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';
import { Formik, Form, FieldArray } from 'formik';
import getDefaultData from '../helpers/defaultValues';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAddMaterial, fetchUpdateMaterial } from 'store/supplies/suppliesOperations';
import MaterialSchema from '../helpers/materialsSchema';
import suppliesActions from 'store/supplies/suppliesActions';
import { fallbacksCleaner, prepareUpdates } from './prepare-updates';
import _ from 'lodash';

import './styles.css';
import styles from './styles.js';
import MaterialTicks from '../../../MaterialTicks/MaterialTicks';
import OptionalModal from 'components/OptionalModal/OptionalModal';

const options = {
  title: 'Are you sure you want to exit without changes?',
  text: '',
  cancelBtnText: 'Discard',
  saveBtnText: 'Save',
};

const MaterialForm = ({ classes, closeDrawer, data, type, snackbarInfo }) => {
  const formikRef = useRef();
  const [state, setState] = useState({});
  const [isCorrectRequest, setIsCorrectRequest] = useState(true);
  const [modal, setModal] = useState({
    isOpen: false,
  });

  const dispatch = useDispatch();

  const settings = useSelector((state) => state.personalProfile?.organization?.settings);
  const loading = useSelector(
    (state) => state.supplies && state.supplies.materials && state.supplies.materials.loading
  );
  const isSuccessfullyAdded = useSelector((state) => state.supplies.materials.isSuccessfullyAdded);
  const handleSwitchChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  useEffect(() => {
    if (state.line || state.lettering || state.legend) {
      setIsCorrectRequest(true);
    }
  }, [state]);
  useEffect(() => {
    if (isSuccessfullyAdded.type === true) {
      snackbarInfo({
        text: 'Successfully Added!',
        type: 'success',
      });
      dispatch(suppliesActions.isPostMaterialSuccessfully({ type: null, message: null }));
    }
    if (isSuccessfullyAdded.type === false) {
      snackbarInfo({
        text: isSuccessfullyAdded.message,
        type: 'error',
      });
      dispatch(suppliesActions.isPostMaterialSuccessfully({ type: null, message: null }));
    }
  }, [isSuccessfullyAdded]);

  useEffect(() => {
    if (!data) return;
    const line = Boolean(data && data.lines && data.lines.length);
    const legend = Boolean(data && data.legends && data.legends.length);
    const lettering = Boolean(data && data.letterings && data.letterings.length);
    const glassBead = Boolean(data && data.glassBeads && data.glassBeads.length);

    setState({ ...state, glassBead, line, legend, lettering });
  }, [data]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = '';
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const defaultData = useMemo(() => getDefaultData(settings), [settings])

  const handleSubmitChecker = (values) => {
    const formData = fallbacksCleaner(prepareUpdates(values, state));
    const isFormUnchanged = _.isEqual(formData, data || defaultData);
    if (formikRef.current) {
      if (isFormUnchanged) {
        closeDrawer()
      } else {
        setModal((prev) => ({ ...prev, isOpen: true }));
      }
    }
  };

  return (
    <div className={classes.formWrapper} role="presentation">
      <PageLoader loading={loading}>
        <Formik
          innerRef={formikRef}
          initialValues={data || defaultData}
          validationSchema={MaterialSchema}
          validateOnChange={false}
          validateOnBlur={true}
          onSubmit={(values, { setSubmitting }) => {
            if (
              values.type === 'each' ||
              (state.line && values.lines.length) ||
              (state.legend && values.legends.length) ||
              (state.lettering && values.letterings.length)
            ) {
              const updates = prepareUpdates(values, state);
              const clearedUpdates = fallbacksCleaner(updates);
              if (data && data._id) {
                dispatch(fetchUpdateMaterial(data._id, clearedUpdates));
                setSubmitting(false);
              } else {
                dispatch(fetchAddMaterial(clearedUpdates));
                setSubmitting(false);
              }
            } else {
              setIsCorrectRequest(false);
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit} autoComplete="off" className={classes.formFlex}>
              <Grid>
                <Typography variant="h3" className={classes.marginHeader}>
                  {type === 'create' ? 'Add New' : 'Edit'} Material
                </Typography>
                <IconButton
                  className={classes.closeButton}
                  onClick={() => {
                    handleSubmitChecker(values);
                  }}
                  aria-label="Close"
                  disableTouchRipple
                >
                  <CloseIcon />
                </IconButton>
                <MaterialMeasurement
                  errors={errors}
                  values={values}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
                <UnitsType
                  errors={errors}
                  values={values}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />

                <MarkingTypes
                  state={state}
                  errors={errors}
                  onChange={handleSwitchChange}
                  handleChange={handleChange}
                  values={values}
                  type={values.type}
                />

                {state.line && values && values.type !== 'each' && (
                  <FieldArray name="lines">
                    {({ remove, push, replace, move }) => (
                      <LineSpecifications
                        errors={errors}
                        type={values.type}
                        values={values.lines}
                        measurement={values.measurement}
                        remove={remove}
                        push={push}
                        replace={replace}
                        move={move}
                        setIsCorrectRequest={setIsCorrectRequest}
                      />
                    )}
                  </FieldArray>
                )}

                {state.legend && values && values.type !== 'each' && (
                  <FieldArray name="legends">
                    {({ remove, push, replace, move }) => (
                      <LegendSpecifications
                        errors={errors}
                        values={values.legends}
                        type={values.type}
                        measurement={values.measurement}
                        remove={remove}
                        push={push}
                        replace={replace}
                        move={move}
                        setIsCorrectRequest={setIsCorrectRequest}
                      />
                    )}
                  </FieldArray>
                )}

                {state.lettering && values && values.type !== 'each' && (
                  <FieldArray name="letterings">
                    {({ remove, push, replace, move }) => (
                      <LetteringSpecifications
                        errors={errors}
                        values={values.letterings}
                        type={values.type}
                        measurement={values.measurement}
                        remove={remove}
                        push={push}
                        replace={replace}
                        move={move}
                        setIsCorrectRequest={setIsCorrectRequest}
                      />
                    )}
                  </FieldArray>
                )}

                {state.glassBead && values && values.type !== 'each' && (
                  <FieldArray name="glassBeads">
                    {({ remove, push, replace, move }) => (
                      <GlassBeats
                        errors={errors}
                        values={values.glassBeads}
                        type={values.type}
                        measurement={values.measurement}
                        remove={remove}
                        push={push}
                        move={move}
                        replace={replace}
                      />
                    )}
                  </FieldArray>
                )}

                {settings?.generateCertificates &&
                  (state.line || state.legend || state.lettering || values.type === 'each') &&
                  (
                    <MaterialTicks
                      errors={errors}
                      values={values?.ticks || {}}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                    />
                  )}
              </Grid>
              {!isCorrectRequest && (
                <div className={classes.postError}>
                  {
                    "It's impossible to create a material without the required marking type (Line/Lettering/Legend)"
                  }
                </div>
              )}
              <div className={classes.buttonHolder}>
                <Button color="secondary" className={classes.cancelButton} onClick={closeDrawer}>
                  Cancel
                </Button>
                <div className={classes.verticalDivider}></div>
                <Button type="submit" color="primary" className={classes.saveButton}>
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </PageLoader>
      <OptionalModal
        isOpen={modal.isOpen}
        onClose={() => {
          setModal((prev) => ({ ...prev, isOpen: false }));
        }}
        onCancel={closeDrawer}
        onSave={() => {
          formikRef.current.submitForm();
        }}
        options={options}
      />
    </div>
  );
};
export default withStyles(styles)(MaterialForm);
