import {makeStyles} from "@material-ui/core/styles";

export default makeStyles({
    root: {
        background: 'none',
        padding: '6px 8px !important',
        border: 'none',
        outline: 'none',
        whiteSpace: 'nowrap',
        textTransform: 'none',
        color: '#A4A5B3',
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: 0,
        lineHeight: '20px',
        '&:hover, &:focus, &:active': {
            padding: '6px 8px !important',
            color: '#A4A5B3',
            background: 'none',
            border: 'none',
            outline: 'none',
        },
    }
})