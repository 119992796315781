import React from 'react';
import Typography from '@material-ui/core/Typography/index';
import { Grid } from '@material-ui/core';
import IOSSwitch from 'components/IOSSwitch';
import './../LineSpecifications/styles.css'

const MarkingTypes = ({ errors, onChange, values, state, handleChange, type }) => {
  return (
    <Grid container xs={12} style={{ borderTop: '1px solid #D9DAE3', marginBottom: '15px' }}>
      <Grid item xs={12} style={{ marginTop: '10px' }}>
        <Grid item xs={8} md={'auto'} style={{ marginBottom: '17px' }}>
          <Typography variant="h5">Marking Types Available:</Typography>
        </Grid>

        <Grid container spacing={2}>
          {type !== 'each' && (
            <Grid
              item
              xs={6}
              lg={6}
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Typography variant="body1" color="textSecondary">
                Line
              </Typography>
              <IOSSwitch
                name="line"
                small
                onChange={onChange}
                checked={!!state.line}
                disabled={type === ''}
              />
            </Grid>
          )}
          {type !== 'each' && (
            <Grid
              item
              xs={6}
              lg={6}
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Typography variant="body1" color="textSecondary">
                Legend
              </Typography>
              <IOSSwitch
                name="legend"
                small
                onChange={onChange}
                checked={!!state.legend}
                disabled={type === ''}
              />
            </Grid>
          )}
          {type !== 'each' && (
            <Grid
              item
              xs={6}
              lg={6}
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Typography variant="body1" color="textSecondary">
                Lettering
              </Typography>
              <IOSSwitch
                name="lettering"
                small
                onChange={onChange}
                checked={!!state.lettering}
                disabled={type === ''}
              />
            </Grid>
          )}
          {type !== 'each' && (
            <Grid
              item
              xs={6}
              lg={6}
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Typography variant="body1" color="textSecondary">
                Reflective Media
              </Typography>
              <IOSSwitch
                name="glassBead"
                small
                onChange={onChange}
                checked={!!state.glassBead}
                disabled={type === ''}
              />
            </Grid>
          )}
          <Grid
            item
            xs={6}
            lg={6}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Typography variant="body1" color="textSecondary">
              Custom Description
            </Typography>
            <IOSSwitch
              name="customDescription"
              small
              defaultValue={values.customDescription}
              checked={values.customDescription}
              onChange={handleChange}
              disabled={type === ''}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default MarkingTypes;
