import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { styles } from '../ExitModal/styles';

const useStyles = makeStyles(styles);

const StillHereModal = ({ onCancel, onSubmit }) => {
  const classes = useStyles();
  const [counter, setCounter] = useState(10);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((value) => value - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (counter === 0) return onCancel();
  }, [counter]);

  return (
    <Dialog
      open
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        classes: {
          root: cn(classes.dialogRoot, classes.stillHereBack),
        },
      }}>
      <DialogContent style={{ paddingTop: '3rem' }}>
        <Box display="flex" flexDirection="column">
          <Typography variant="body1" style={{ fontSize: '1.5rem', maxWidth: 300, textAlign: 'center' }}>
            Are you still here? {counter}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button
          onClick={onSubmit}
          variant="outlined"
          className={cn(classes.confirmBtn, classes.stillHereBtn)}
          disableTouchRipple>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StillHereModal;
