import React, { useEffect, useState } from 'react';
import IconButton from '../../../../../../../../components/core/IconButton/IconButton';
import CrossIcon from '../../../../../../../../components/icons/Cross';
import ClockIcon from '../../../../../../../../components/icons/Clock';
import formatNumber from '../../../../../../utils/formatNumber';
import { Grid } from '@material-ui/core/index';
import { Box } from '@material-ui/core/index';
import { TotalTime } from '../../../styled';
import { Root } from './styled';
import RemovableRow from '../../../RemovableRow';
import ManualOverride from './components/ManualOverride';
import { styles } from './styled';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Warning from '../../../../../../../../components/icons/Warning';
import { format } from 'date-fns';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';
const useStyles = makeStyles(styles);

const TimeclockItem = ({
  data,
  editMode,
  onChange,
  onEditLunchTime,
  onDeleteLunchTime,
  onDeleteStartTimeOverride,
  onDeleteEndTimeOverride,
  onDelete,
  currentRoute,
  payrollTimeclockItem,
  hasPayroll,
  payrollVerified,
  ...props
}) => {
  const getTimeFormatHook = useTimeSettingsFormat();
  const formatForTimePars = getTimeFormatHook().formatForTimePars;
  const [payrollDifferentStartTime, setPayrollDifferentStartTime] = useState(false);
  const [payrollDifferentEndTime, setPayrollDifferentEndTime] = useState(false);
  const [payrollDifferentLunchTime, setPayrollDifferentLunchTime] = useState(false);
  useEffect(() => {
    if (payrollTimeclockItem) {
      setPayrollDifferentStartTime(
        new Date(payrollTimeclockItem.startTime).getTime() !==
          new Date(data.modified?.inTime || data.inTime).getTime()
      );
      setPayrollDifferentEndTime(
        new Date(payrollTimeclockItem.endTime).getTime() !==
          new Date(data.modified?.outTime || data.outTime).getTime()
      );
      setPayrollDifferentLunchTime(
        (payrollTimeclockItem.lunchTime || 0) !== (data.modified?.lunchTime || data.lunchTime || 0)
      );
    } else {
      // set difference to true if timeclock was added after verifying payroll
      setPayrollDifferentStartTime(true);
      setPayrollDifferentEndTime(true);
      setPayrollDifferentLunchTime(!!data.modified?.lunchTime || !!data.lunchTime);
      // in this case lunch time should have exclamation mark
    }
  }, [data, payrollTimeclockItem]);

  const handleEditLunchTime = (e) => {
    e.preventDefault();
    onEditLunchTime(data);
  };

  const handleDeleteLunchTime = (e) => {
    e.preventDefault();
    onDeleteLunchTime(data);
  };

  const handleEditTimeclockTime = (e) => {
    e.preventDefault();
    onChange(data);
  };

  const handleDeleteTimeclockTime = (e) => {
    e.preventDefault();
    onDelete(data);
  };

  const showExclamationMark = currentRoute && hasPayroll && !payrollVerified;

  const classes = useStyles();

  return (
    <Box className={classes.root} {...props}>
      <Grid className={classes.mainWrapper}>
        <Box className={classes.timeSection}>
          <Grid className={classes.timeSectionItem}>
            {editMode && data.overrideClockIn ? (
              <ManualOverride
                label={'Manual override:'}
                onRemoveClick={(e) => {
                  e.preventDefault();
                  onDeleteStartTimeOverride(data);
                }}
                value={`${data.overrideClockIn.minus ? '-' : '+'} ${data.overrideClockIn.hours}:${
                  data.overrideClockIn.minutes
                }`}
              >
                <dl className={'dl'}>
                  <dt className={'dt'}>Clock-In:</dt>
                  <dd className={'dd'}>{format(new Date(data.inTime), formatForTimePars)}</dd>
                </dl>
              </ManualOverride>
            ) : (
              <dl className={'dl'}>
                <dt className={'dt'}>Clock-In:</dt>
                <dd className={'dd'}>
                  {format(new Date(data.modified?.inTime || data.inTime), formatForTimePars)}
                </dd>
                {showExclamationMark && payrollDifferentStartTime && (
                  <dd className={'warning'}>
                    <Warning />
                  </dd>
                )}
              </dl>
            )}
          </Grid>
          <Grid className={classes.timeSectionItem}>
            {editMode && data.overrideClockOut ? (
              <ManualOverride
                onRemoveClick={(e) => {
                  e.preventDefault();
                  onDeleteEndTimeOverride(data);
                }}
                value={`${data.overrideClockOut.minus ? '-' : '+'} ${data.overrideClockOut.hours}:${
                  data.overrideClockOut.minutes
                }`}
              >
                <dl className={'dl'}>
                  <dt className={'dt'}>Clock-Out:</dt>
                  <dd className={'dd'}>
                    {data.outTime ? format(new Date(data.outTime), formatForTimePars) : '-'}
                  </dd>
                </dl>
              </ManualOverride>
            ) : (
              <dl className={'dl'}>
                <dt className={'dt'}>Clock-Out:</dt>
                <dd className={'dd'}>
                  {data.outTime
                    ? format(new Date(data.modified?.outTime || data.outTime), formatForTimePars)
                    : '-'}
                </dd>
                {showExclamationMark && payrollDifferentEndTime && (
                  <dd className={'warning'}>
                    <Warning />
                  </dd>
                )}
              </dl>
            )}
          </Grid>
          <Grid className={classes.timeSectionItem}>
            {editMode ? (
              <Grid container justifyContent="space-between">
                <Grid item>
                  <dl className={'dl'}>
                    <dt className={'dt'}>Lunch time:</dt>
                    <dd className={'dd'}>{formatNumber(data.modified?.lunchTime || 0)}</dd>
                    {showExclamationMark && payrollDifferentLunchTime && (
                      <dd className={'warning'}>
                        <Warning />
                      </dd>
                    )}
                  </dl>
                </Grid>
                <Grid item>
                  <Grid container justifyContent={'center'} spacing={1}>
                    <Grid item>
                      <IconButton onClick={handleEditLunchTime}>
                        <ClockIcon />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton onClick={handleDeleteLunchTime}>
                        <CrossIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <dl className={'dl'}>
                <dt className={'dt'}>Lunch time:</dt>
                <dd className={'dd'}>{formatNumber(data.modified?.lunchTime || 0)}</dd>
                {showExclamationMark && payrollDifferentLunchTime && (
                  <dd className={'warning'}>
                    <Warning />
                  </dd>
                )}
              </dl>
            )}
          </Grid>
          {/* <Grid className={classes.timeSectionItem}>
            {editMode ? (
              <RemovableRow
                canEdit
                onEditClick={(e) => {
                  e.preventDefault();
                  onEditLunchTime(data);
                }}
                onRemoveClick={(e) => {
                  e.preventDefault();
                  onDeleteLunchTime(data);
                }}
              >
                <dl className={'dl'}>
                  <dt className={'dt'}>Lunch time:</dt>
                  <dd className={'dd'}>{formatNumber(data.lunchTime)}</dd>
                </dl>
              </RemovableRow>
            ) : (
              <dl className={'dl'}>
                <dt className={'dt'}>Lunch time:</dt>
                <dd className={'dd'}>{formatNumber(data.lunchTime)}</dd>
                {currentRoute && payroll && data.lunchTime !== payrollLaunchTime && (
                  <dd className={'warning'}>
                    <Warning />
                  </dd>
                )}
            </dl>
          )}
        </Grid> */}
        </Box>
        <Grid className={classes.timeSectionHours}>
          {editMode ? (
            <Grid
              container
              justifyContent={'flex-end'}
              alignItems={'center'}
              style={{ width: 150 }}
              spacing={1}
            >
              <Grid item xs={'auto'}>
                <Grid container justifyContent={'space-between'} spacing={1}>
                  <Grid item>
                    <IconButton onClick={handleEditTimeclockTime}>
                      <ClockIcon />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={handleDeleteTimeclockTime}>
                      <CrossIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={'auto'}>
                <TotalTime style={{ width: 65 }}>
                  {formatNumber(data.modified?.hoursThisDay || data.hoursThisDay)}
                </TotalTime>
              </Grid>
            </Grid>
          ) : (
            <Grid container alignItems={'center'} className={classes.timeSectionHoursMobile}>
              <Grid item xs={'auto'}>
                <TotalTime style={{ width: 65 }}>
                  {formatNumber(data.modified?.hoursThisDay || data.hoursThisDay)}
                </TotalTime>
                <Typography className={classes.timeSectionTimeWord}>hours</Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default TimeclockItem;
