import React from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from './styles';

const PageLoaderFullBlur = ({ classes, loading }) => {
  return (
    <>
      {loading && (
        <div className={classes.root} style={{ display: loading ? 'block' : 'none' }}>
          <div className={classes.progressWrapper}>
            <CircularProgress className={classes.progress} />
          </div>
        </div>
      )}
    </>
  );
};

PageLoaderFullBlur.propTypes = {
  classes: propTypes.object.isRequired,
  loading: propTypes.bool.isRequired,
};

export default withStyles(styles)(PageLoaderFullBlur);
