import React, { useMemo, useState, useEffect, useRef } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import { surfaces } from 'api/data/surface-options';
import PageLoader from '../PageLoader2';
import MetarialDetails from './components/MetarialDetails';
import {
  getMaterialSaveObject,
  validation,
  initialValuesOnCreate,
  initialValuesOnUpdate,
  filterByProp,
  createOptionsForLettering,
} from './helpers';
import { fetchMaterials } from 'store/supplies/suppliesOperations';
import {
  fetchAddWorklogMaterial,
  fetchUpdateWorklogMaterial,
} from 'store/currentWorklog/operations';
import currentWorklogActions from 'store/currentWorklog/actions';
import _ from 'lodash'
import styles from './styles';
import suppliesActions from 'store/supplies/suppliesActions';

const MaterialForm = ({
  classes,
  closeDrawer,
  openSnackbar,
  type,
  // locationOptions=[],
  material={},
  isDataLoading,
}) => {
  const dispatch = useDispatch();
  const surfaceOptions = useSelector((store) => store.currentWorklog.worklogSheet.surfaces) || [];

  const [state, setState] = useState({
    type: '',
    colorOptions: '',
    materialObject: '',
    manufacturersArr: '',
    eachSymbolWidth: '',
  });
  const [currentTab, setCurrentTab] = useState({
    active: '',
    allTabs: '',
  });
  const [isCorrectRequest, setIsCorrectRequest] = useState(true);
  
  const sheet = useSelector((store) => store.currentWorklog.worklogSheet.sheet);
  const allMaterials = useSelector((state) => state.supplies.materials.data);
  const groupedMaterials = useSelector((state) => state.currentWorklog.groupedMaterials.data);
  const isSuccessfullyAdded = useSelector(
    (state) => state.currentWorklog.groupedMaterials.isSuccessfullyAdded
    );

    const createLocationOptions = () => {
      if (sheet && sheet.project) {
        if (sheet.sheetLocations) {
          const { sheetLocations, materialLocations } = sheet;
          const fullLocationsAndDeleted = _.uniqBy(
            [...(sheetLocations || []), ...(sheet.project.locations || [])],
            '_id'
          );
          return fullLocationsAndDeleted.concat(materialLocations).filter(el => !!el);
        }
  
        return sheet.materialLocations ? [...sheet.materialLocations] : [];
      }
  
      return [];
    };

  const [locationOptions, setLocationOptions] = useState(createLocationOptions);

  const formikRef = useRef();

  useEffect(() => {
    if (isSuccessfullyAdded.type === true) {
      // openSnackbar('success', 'Material successfully added');
      dispatch(
        currentWorklogActions.isPostWorklogMaterialSuccessfully({ type: null, message: null })
      );
      closeDrawer();
    }
    if (isSuccessfullyAdded.type === false) {
      openSnackbar('error', isSuccessfullyAdded.message);
      dispatch(
        currentWorklogActions.isPostWorklogMaterialSuccessfully({ type: null, message: null })
      );
      closeDrawer();
    }
  }, [isSuccessfullyAdded]);

  useEffect(() => {
    dispatch(fetchMaterials({ measurement: sheet.measurement }, false, true));

    return () => {
      dispatch(suppliesActions.getMaterialsSuccess([]))
    }
  }, []);

  const handleSelectMaterial = (setFieldValue) => (e) => {
    const name = e.target.value;
    setState({
      type: '',
      colorOptions: '',
      materialObject: '',
      manufacturersArr: '',
      eachSymbolWidth: '',
    });
    // setState((prev) => ({ ...prev, manufacturersArr: '' }));
    // setState((prev) => ({ ...prev, eachSymbolWidth: '' }));
    setIsCorrectRequest(true);
    setCurrentTab({});
    setFieldValue('materialSpecifications.colorOrNumber', '');
    setFieldValue('manufacturerId', '');
    setFieldValue('allColors', '');
    setFieldValue('dataType', '');
    setFieldValue('eachSymbolWidth', '');
    setFieldValue('glassBeads', '');
    setFieldValue('symbolLegend', '');

    const materialObject = allMaterials.find((obj) => obj.name === name);
    setState((prev) => ({ ...prev, materialObject: materialObject }));

    setFieldValue('materialName', name);
    setFieldValue('materialType', materialObject.type);
    setState((prev) => ({ ...prev, materialType: materialObject.type }));

    if (materialObject && materialObject.glassBeads && materialObject.glassBeads.length) {
      setFieldValue('glassBeads', materialObject.glassBeads);
    }

    if (materialObject && materialObject.inventories && materialObject.inventories.length) {
      const colors = Array.from(
        materialObject.inventories.reduce((set, m) => {
          if (m.colorOrNumber) {
            set.add(m.colorOrNumber);
          }
          return set;
        }, new Set())
      );
      setState((prev) => ({ ...prev, colorOptions: colors }));
      setFieldValue('materialSpecifications.colorOrNumber', colors[0]);
      setFieldValue('allColors', colors);

      let eachSymbolWidth = [];
      if (materialObject.type === 'each') {
        const arrSymbols = [];
        materialObject.inventories.forEach((el) => {
          if (el.colorOrNumber && el.symbolOrWidth) {
            el.colorOrNumber === colors[0] && arrSymbols.push(el)
          }
        });
        eachSymbolWidth = filterByProp(arrSymbols, 'symbolOrWidth');
        if (eachSymbolWidth.length) {
          setState((prev) => ({
            ...prev,
            eachSymbolWidth: eachSymbolWidth,
            _idEachInventory: eachSymbolWidth[0].symbolOrWidth,
          }));
          setFieldValue('eachSymbolWidth', eachSymbolWidth[0].symbolOrWidth);
        } else {
          setState((prev) => ({ ...prev, eachSymbolWidth: '', _idEachInventory: '' }));
          setFieldValue('eachSymbolWidth', '');
        }
      }

      const arr = [];
      if (materialObject.type === 'each' && eachSymbolWidth.length) {
        materialObject.inventories.forEach((el) => {
          if (el.colorOrNumber && el.manufacturer && el.symbolOrWidth) {
            el.colorOrNumber === colors[0] && el.symbolOrWidth === eachSymbolWidth[0].symbolOrWidth
              && arr.push(el.manufacturer)
          }
        });
      } else {
        materialObject.inventories.forEach((el) => {
          if (el.colorOrNumber && el.manufacturer) {
            el.colorOrNumber === colors[0] && arr.push(el.manufacturer)
          }
        });
      }
      let manufacturers = filterByProp(arr, 'name');
      if (manufacturers.length) {
        setState((prev) => ({ ...prev, manufacturersArr: manufacturers }));
        setFieldValue('manufacturerId', manufacturers[0]._id);
      }
    }

    if (materialObject.type !== 'each') {
      const tabs = [];
      if (materialObject.lines && materialObject.lines.length) {
        tabs.push('lines');
      }
      if (materialObject.legends && materialObject.legends.length) {
        tabs.push('legends');
        const AllSymbols = materialObject.legends.map((el) => el.symbol);
        setFieldValue('symbolLegend', AllSymbols[0]);
      }
      if (materialObject.letterings && materialObject.letterings.length) {
        tabs.push('letterings');
      }
      setCurrentTab((prev) => ({ active: tabs[0], allTabs: tabs }));
      setFieldValue('dataType', tabs[0]);
    }
    if (materialObject.type === 'each' && !materialObject.alternativeWork) {
      setFieldValue('dataType', 'each');
    }
    if (materialObject && materialObject.alternativeWork) {
      setFieldValue('dataType', 'alternative');
    }
  };
  useEffect(() => {
    if (state.materialType && state.materialType !== 'each') {
      if (currentTab.active === 'lines') {
        const width = state.materialObject.lines.map((el) => el.width);
        const ratio = state.materialObject.lines.map((el) => el.ratio);

        let widthLine = width[0];
        let ratioLine = ratio[0];
        if (material && material.materialSpecifications && material.materialSpecifications.line) {
          const autocompleteValue = state.materialObject.lines.find((el) => el.width === material.materialSpecifications.line.width);
          widthLine = (autocompleteValue && autocompleteValue.width) || width[0];
          ratioLine = state.materialObject.lines.find((el) => el.width === widthLine).ratio;
        }
        if (currentTab.options?.hasOwnProperty('widthLine') && width.includes(currentTab.options.widthLine)) {
          widthLine = currentTab.options.widthLine;
        }
        if (currentTab.options?.hasOwnProperty('ratio') && ratio.includes(currentTab.options.ratio)) {
          ratioLine = currentTab.options.ratio;
        }
        if (formikRef?.current?.setFieldValue) {
          formikRef.current.setFieldValue('widthLine', widthLine);
          // formikRef.current.setFieldValue('lineQuantity', 0);
          // formikRef.current.setFieldValue('lineDescription', '');
        }

        setCurrentTab((prev) => ({
          ...prev,
          options: {
            width: width,
            widthLine: widthLine,
            ratio: ratioLine,
          },
        }));
      }

      if (currentTab.active === 'legends') {
        const AllSymbols = state.materialObject.legends.map((el) => el.symbol);
        const ratio = state.materialObject.legends.map((el) => el.ratio);
        const areas = state.materialObject.legends.map((el) => el.area);

        let symbols = AllSymbols[0];
        let areaLegend = areas[0];
        let ratioLegend = ratio[0];
        if (material && material.materialSpecifications && material.materialSpecifications.legend) {
          const autocompleteValue = state.materialObject.legends.find(
            (el) => el.symbol === material.materialSpecifications.legend.symbol
          );
          symbols = (autocompleteValue && autocompleteValue.symbol) || AllSymbols[0];
          areaLegend = material.materialSpecifications.legend.area;
          ratioLegend = state.materialObject.legends.find((el) => el.symbol === symbols).ratio;
        }
        if (currentTab.options?.hasOwnProperty('symbols') && AllSymbols.includes(currentTab.options.symbols)) {
          symbols = currentTab.options.symbols;
        }
        if (currentTab.options?.hasOwnProperty('ratio') && ratio.includes(currentTab.options.ratio)) {
          ratioLegend = currentTab.options.ratio;
        }
        if (currentTab.options?.hasOwnProperty('areas') && AllSymbols.includes(currentTab.options.areas)) {
          areaLegend = currentTab.options.areas;
        }
        if (formikRef?.current?.setFieldValue) {
          formikRef.current.setFieldValue('symbolLegend', symbols);
          // formikRef.current.setFieldValue('legendQuantity', 0);
          // formikRef.current.setFieldValue('legendDescription', '');
        }

        setCurrentTab((prev) => ({
          ...prev,
          options: {
            symbols: symbols,
            AllSymbols: AllSymbols,
            area: areaLegend,
            ratio: ratioLegend,
          },
        }));
      }

      if (currentTab.active === 'letterings') {
        const AllParams = createOptionsForLettering(state.materialObject.letterings, sheet.measurement);

        let _idLettering = AllParams[0]._id;
        if (
          material &&
          material.materialSpecifications &&
          material.materialSpecifications.lettering
        ) {
          _idLettering = material.materialSpecifications.lettering.name;
        }
        if (currentTab.options?.hasOwnProperty('_idLettering') && AllParams.some((param) => param._id === currentTab.options._idLettering)) {
          _idLettering = currentTab.options._idLettering;
        }
        if (formikRef?.current?.setFieldValue) {
          formikRef.current.setFieldValue('paremeters', _idLettering);
          // formikRef.current.setFieldValue('letteringText', '');
          // formikRef.current.setFieldValue('letteringQuantity', 0);
          // formikRef.current.setFieldValue('letteringDescription', '');
        }

        setCurrentTab((prev) => ({
          ...prev,
          options: {
            _idLettering: _idLettering,
            AllParams: AllParams,
          },
        }));
      }
    }
  }, [currentTab.active, state.materialObject, material, formikRef]);

  const handleChangeManufacturer = (setFieldValue) => (e) => {
    const newValue = e.target.value;
    setFieldValue('manufacturerId', newValue);
  };

  const handleSelectColor = (setFieldValue) => (e) => {
    const newValue = e.target.value;
    setFieldValue('materialSpecifications.colorOrNumber', newValue);
    let eachSymbolWidth = [];
    if (state.materialObject.type === 'each') {
      const arrSymbols = [];
      state.materialObject.inventories &&
        state.materialObject.inventories.forEach((el) => {
          if (el.colorOrNumber && el.symbolOrWidth) {
            el.colorOrNumber === newValue && arrSymbols.push(el)
          }
        });
      eachSymbolWidth = filterByProp(arrSymbols, 'symbolOrWidth');
      if (eachSymbolWidth.length) {
        setState((prev) => ({
          ...prev,
          eachSymbolWidth: eachSymbolWidth,
          _idEachInventory: eachSymbolWidth[0].symbolOrWidth,
        }));
        setFieldValue('eachSymbolWidth', eachSymbolWidth[0].symbolOrWidth);
      } else {
        setState((prev) => ({ ...prev, eachSymbolWidth: '', _idEachInventory: '' }));
        setFieldValue('eachSymbolWidth', '');
      }
    }

    if (state.materialObject && state.materialObject.inventories.length) {
      const arr = [];
      if (state.materialObject.type === 'each' && eachSymbolWidth.length) {
        state.materialObject.inventories.forEach((el) => {
          if (el.colorOrNumber && el.manufacturer && el.symbolOrWidth) {
            el.colorOrNumber === newValue && el.symbolOrWidth === eachSymbolWidth[0].symbolOrWidth
              && arr.push(el.manufacturer)
          }
        });
      } else {
        state.materialObject.inventories.forEach((el) => {
          if (el.colorOrNumber && el.manufacturer) {
            el.colorOrNumber === newValue && arr.push(el.manufacturer)
          }
        });
      }

      let manufacturers = filterByProp(arr, 'name');

      if (manufacturers.length) {
        setState((prev) => ({ ...prev, manufacturersArr: manufacturers }));
        setFieldValue('manufacturerId', manufacturers[0]._id);
      } else {
        setState((prev) => ({ ...prev, manufacturersArr: '' }));
        setFieldValue('manufacturerId', '');
      }
    }
  };

  const handlecurrentTab = (setFieldValue) => (event, newTab) => {
    if (newTab !== null) {
      setCurrentTab((prev) => ({ ...prev, active: newTab }));
      setFieldValue('dataType', newTab);

      if (newTab === 'legends') {
        const AllSymbols = state.materialObject.legends.map((el) => el.symbol);
        setFieldValue('symbolLegend', AllSymbols[0]);
      }
    }
  };
  const handleWidthLine = (setFieldValue) => (e) => {
    const newValue = e.target.value;
    setFieldValue('widthLine', newValue);
    const searchedObj = state.materialObject.lines.find((el) => el.width == newValue);
    const ratio = searchedObj.ratio;

    setCurrentTab((prev) => ({
      ...prev,
      options: {
        width: prev.options.width,
        widthLine: newValue,
        ratio: ratio,
      },
    }));
  };

  const handleSymbolLegend = (setFieldValue) => (e) => {
    const newValue = e.target.value;

    const obj = state.materialObject.legends.find((el) => el.symbol === newValue);
    const areas = obj.area;
    const ratio = obj.ratio;

    setCurrentTab((prev) => ({
      ...prev,
      options: {
        symbols: newValue,
        AllSymbols: prev.options.AllSymbols,
        area: areas,
        ratio: ratio,
      },
    }));
    setFieldValue('symbolLegend', newValue);
  };

  const handleParameters = (setFieldValue) => (e) => {
    const newValue = e.target.value;

    setCurrentTab((prev) => ({
      ...prev,
      options: {
        _idLettering: newValue,
        AllParams: prev.options.AllParams,
      },
    }));

    setFieldValue('paremeters', newValue);
  };

  const handleChangeeachSymbolWidth = (setFieldValue, values) => (e) => {
    const newValue = e.target.value;

    setState((prev) => ({ ...prev, _idEachInventory: newValue }));
    setFieldValue('eachSymbolWidth', newValue);
    const arr = [];
    state.materialObject.inventories.forEach((el) => {
      if (el.colorOrNumber && el.manufacturer && el.symbolOrWidth) {
        el.colorOrNumber === values.materialSpecifications.colorOrNumber &&
        el.symbolOrWidth === newValue
          && arr.push(el.manufacturer)
      }
    });

    let manufacturers = filterByProp(arr, 'name');

    if (manufacturers.length) {
      setState((prev) => ({ ...prev, manufacturersArr: manufacturers }));
      setFieldValue('manufacturerId', manufacturers[0]._id);
    } else {
      setState((prev) => ({ ...prev, manufacturersArr: '' }));
      setFieldValue('manufacturerId', '');
    }
  };

  const handleChangeTypeOfWork = (value, setFieldValue) => () => {
    setFieldValue('typeOfWork', value);
  };

  const handleChangeLetteringText = (setFieldValue) => (e) => {
    const value = e.target.value;
    // const resultValue = value.replace(/\s+/g, ' ').toUpperCase();
    const resultValue = value.toUpperCase();
    setFieldValue('letteringText', resultValue);
  };

  const getTypeOfWork = () => {
    const { forceAccount } = sheet;
    if (!forceAccount) return 'contract';
    return forceAccount === 'Full' ? 'forceAccount' : 'contract';
  };

  const fillPrevious = (resetForm) => {
    if (groupedMaterials.length) {
      const materialsArr = groupedMaterials.reduce((arr, curr) => [...arr, ...curr.materials], []);
      const res = materialsArr.sort(
        (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      );
      resetForm({ values: initialValuesOnUpdate(res[res.length-1], allMaterials, sheet, setState, setCurrentTab) });
    }
  };

  const onCreate = useMemo(
          () => initialValuesOnCreate(getTypeOfWork(), surfaceOptions, locationOptions),
          [getTypeOfWork(), surfaceOptions, locationOptions]
        )
  const onUpdate = useMemo(
          () => 
          type !== 'create' && initialValuesOnUpdate(material, allMaterials, sheet, setState, setCurrentTab),
          [material, allMaterials]
        );

  const initVals =
    type === 'create'
      ? onCreate
      : onUpdate


  return (
    <div className={classes.formWrapper} role="presentation">
      {!allMaterials || isDataLoading ? (
        <PageLoader loading>
          <div />
        </PageLoader>
      ) : (
        <Formik
          innerRef={formikRef}
          validate={validation(state.materialObject, currentTab)}
          validateOnChange={false}
          validateOnBlur={true}
          initialValues={initVals}
          enableReinitialize={true}
          onSubmit={(values) => {
            const data = getMaterialSaveObject(values, state, currentTab);
            if (data.quantity) {
              if (type === 'create') {
                dispatch(fetchAddWorklogMaterial(sheet._id, data));
              } else {
                dispatch(fetchUpdateWorklogMaterial(sheet._id, material._id, data));
              }
            } else {
              setIsCorrectRequest(false);
            }
          }}
        >
          {({
            errors,
            touched,
            values,
            handleChange,
            handleBlur,
            isSubmitting,
            setFieldValue,
            setFieldError,
            isValidating,
            resetForm,
          }) => (
            <PageLoader loading={false} small>
              <Form autoComplete="off" className={classes.formFlex}>
                <div>
                <div className={classes.titleWrapper}>
                  <Typography variant="h3" style={{ marginBottom: '20px' }}>
                    {type === 'create' ? 'Add' : 'Edit'} material
                  </Typography>
                  {!!groupedMaterials.length && (
                        <Button
                          variant="text"
                          onClick={() => fillPrevious(resetForm)}
                          className={classes.primaryTextButton}
                          disableRipple
                          disableTouchRipple
                        >
                          Fill previous
                        </Button>
                      )}
                  <IconButton
                    className={classes.closeButton}
                    onClick={closeDrawer}
                    aria-label="Close"
                    disableTouchRipple
                  >
                    <CloseIcon />
                  </IconButton>
                  </div>

                  <MetarialDetails
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    setFieldError={setFieldError}
                    allMaterials={allMaterials}
                    handleSelectMaterial={handleSelectMaterial}
                    state={state}
                    handleSelectColor={handleSelectColor}
                    handleChangeManufacturer={handleChangeManufacturer}
                    handlecurrentTab={handlecurrentTab}
                    currentTab={currentTab}
                    handleWidthLine={handleWidthLine}
                    handleSymbolLegend={handleSymbolLegend}
                    handleParameters={handleParameters}
                    handleChangeeachSymbolWidth={handleChangeeachSymbolWidth}
                    sheet={sheet}
                    locationOptions={locationOptions}
                    surfaceOptions={surfaceOptions}
                    openSnackbar={openSnackbar}
                    type={type}
                    fillPrevious={fillPrevious}
                    handleChangeTypeOfWork={handleChangeTypeOfWork}
                    handleChangeLetteringText={handleChangeLetteringText}
                    resetForm={resetForm}
                    setLocationOptions={setLocationOptions}
                    measurement={sheet.measurement}
                  />

                  {!isCorrectRequest && (
                    <div className={classes.postError}>
                      {"It's impossible to add material which not include in inventory"}
                    </div>
                  )}
                </div>

                <div className={classes.buttonHolder}>
                  <Button
                    color="secondary"
                    variant="outlined"
                    className={classes.cancelButton}
                    onClick={closeDrawer}
                    // disabled={isSubmitting}
                    disableTouchRipple
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    variant="outlined"
                    className={classes.saveButton}
                    // disabled={isSubmitting || isValidating}
                    disableTouchRipple
                  >
                    Save
                  </Button>
                </div>
              </Form>
            </PageLoader>
          )}
        </Formik>
      )}
    </div>
  );
};

MaterialForm.propTypes = {
  classes: propTypes.object.isRequired,
  closeDrawer: propTypes.func.isRequired,
  openSnackbar: propTypes.func.isRequired,
  sheet: propTypes.object.isRequired,
  type: propTypes.string.isRequired,
  locationOptions: propTypes.array.isRequired,
  surfaceOptions: propTypes.array.isRequired,
};

export default withStyles(styles)(MaterialForm);

