
import { EQUIPMENT_EVENT } from 'subscriptions/events/events.enum';
import { subscriptionsFabric } from 'subscriptions/subscriptions.fabric';
import workerSchedulePageActions from 'store/workerSchedule/workerSchedulePageActions';

const handlers = {
  [EQUIPMENT_EVENT.updated]: (store) => (data) => {
    if (data?._id) {
      const equipData = {
        _id: data._id,
        name: data.name,
        number: data.number,
        color: data.color,
        model: data.model,
      }
      store.dispatch(workerSchedulePageActions.patchMyScheduleEquipment(equipData));
    }
  },
}

export const myScheduleEquipmentSubscriptionMiddleware = subscriptionsFabric(
  workerSchedulePageActions,
  EQUIPMENT_EVENT,
  handlers
);
