import React, { useState, useLayoutEffect } from 'react';

import EquipmentPage from './EquipmentPage';
import SnackBar from '../../components/SnackBar';
import equipmentPageActions from 'store/equipment/equipmentPageActions';
import { useDispatch } from 'react-redux';

const EquipmentWrapper = () => {
  const dispatch = useDispatch()
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    text: '',
    type: 'success',
  });

  useLayoutEffect(() => {
    dispatch(
      equipmentPageActions.getEquipmentsData({
        data: [],
        hasMore: true,
      })
    );
  }, []);

  const openSnackbar = (type, text) => setSnackbar({ isOpen: true, type, text });

  const closeSnackbar = () => setSnackbar((prev) => ({ ...prev, isOpen: false }));

  return (
    <>
      <EquipmentPage openSnackbar={openSnackbar} />
      <SnackBar closeSnackbar={closeSnackbar} {...snackbar} />
    </>
  );
};

export default EquipmentWrapper;
