import hiCodesActions from './hiCodesActions';
import { getOptions } from 'helpers/getOptions';
import $api from 'http/index';


const catchError = (error) => {
  if (error.response) {
    if (Array.isArray(error.response.data.message))
      throw new Error(error.response.data.message.join('\n'));
    else throw new Error(error.response.data.message);
  } else {
    throw new Error(error.message);
  }
};

export const fetchHICodes = () => async (dispatch) => {
    const options = {
      headers: {
        ...getOptions().headers,
      },
    };

    try {
      dispatch(hiCodesActions.setLoading(true));
      const response = await $api.get(`${process.env.REACT_APP_BASE_URL}/hi-codes`, options);

      dispatch(hiCodesActions.getHICodes(response.data));
      dispatch(hiCodesActions.setLoading(false));
    } catch (error) {
      catchError(error);
    }
  };

export const postHiCode = (data) => async (dispatch) => {
  const options = {
    headers: {
      ...getOptions().headers,
    },
  };

  try {
    dispatch(hiCodesActions.setLoading(true));
    const response = await $api.post(`${process.env.REACT_APP_BASE_URL}/hi-codes`, data, options);

    dispatch(hiCodesActions.postHICode(response.data));
    dispatch(hiCodesActions.setLoading(false));
  } catch (error) {
    catchError(error);
  }
};

export const putHICode = (data) => async (dispatch) => {
  const options = {
    headers: {
      ...getOptions().headers,
    },
  };

  try {
    dispatch(hiCodesActions.setLoading(true));
    const response = await $api.put(
      `${process.env.REACT_APP_BASE_URL}/hi-codes/${data._id}`,
      data,
      options
    );

    dispatch(hiCodesActions.putHICode(response.data));
    dispatch(hiCodesActions.setLoading(false));
  } catch (error) {
    catchError(error);
  }
};

export const deleteHICode = (hiCodeId) => async (dispatch) => {
  const options = {
    headers: {
      ...getOptions().headers,
    },
  };

  try {
    dispatch(hiCodesActions.setLoading(true));
    await $api.delete(`${process.env.REACT_APP_BASE_URL}/hi-codes/${hiCodeId}`, options);

    dispatch(hiCodesActions.deleteHICode({ _id: hiCodeId }));
    dispatch(hiCodesActions.setLoading(false));
  } catch (error) {
    catchError(error);
  }
};
