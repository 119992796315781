import React, { useEffect, useMemo, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Button, Divider, Grid, IconButton, Paper, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { format, parseISO } from 'date-fns';
import photoPlaceholder from 'assets/images/user-photo-placeholder.svg';
import LocationIcon from 'assets/images/location-icon.svg';

import {
  useCrewBlockStyles,
  useWorklogStyles,
  useCrewUserStyles,
} from 'components/WorklogDetails/hooks';
import { compareByForeman, getProjectName } from 'helpers/_helpers';
import { useGetEquipmentInfo } from '../../../../../hooks/useGetEquipmentInfo';
import { useResourcesStyles } from '../../../../ResourcesSidebar/components/ResourcesList/hooks/useResourcesStyles';
import { removeResources } from 'store/schedule/schedulePageOperation';
import { postWorklogCL } from 'store/currentWorklog/operations';
import { setCrewLeader } from 'store/schedule/schedulePageOperation';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';
import { allDatesFormat } from 'common/dateFormatConfig';

export const DetailsView = ({
  sheet,
  cl: crewLeader,
  handleClose,
  column,
  hasAccess,
  scheduleEventEl,
  selectedDate,
  openModal,
}) => {
  const { getEquipmentInfo } = useGetEquipmentInfo();
  const getTimeFormatHook = useTimeSettingsFormat();

  const worklogClasses = useWorklogStyles();
  const crewBlockClasses = useCrewBlockStyles();
  const crewUserClasses = useCrewUserStyles();
  const resourcesClasses = useResourcesStyles();

  const sheetLocation = (sheet, isStandalone, locationId) => {
    if (!sheet.project) return '';
    const allLocations = [
      ...(sheet?.sheetRallyPoints || []),
      ...(sheet.project?.rallyPoints || []),
      ...(sheet?.sheetLocations || []),
      ...(sheet.project?.locations || []),
    ];
    const location = allLocations.find((l) => l._id === locationId);
    if (isStandalone) {
      if (!location) return '';
      const locationZip = location.zipcode ? `, ${location.zipcode}` : '';
      return location.name || `${location.address}${locationZip}`;
    }
    const nameOrAddress = location.name || location.address;
    return location && nameOrAddress ? ', ' + nameOrAddress : '';
  };
  const openLocation = (locationId) => () => {
    const allLocations = [
      ...(sheet?.sheetRallyPoints || []),
      ...(sheet.project?.rallyPoints || []),
      ...(sheet?.sheetLocations || []),
      ...(sheet.project?.locations || []),
    ];
    const location = allLocations.find((l) => l._id === locationId);
    const url = `https://www.google.com/maps/place/${location.address}`;
    window.open(url, '_blank', 'noreferrer');
  };

  const dispatch = useDispatch();
  const setForeman = (worker) => async () => {
    const sheetId = sheet._id;
    const userId = worker._id;
    const value = !worker.foreman;

    await dispatch(postWorklogCL({ foreman: value }, sheetId, userId));

    await dispatch(
      setCrewLeader({
        sheetId: sheetId,
        workerId: userId,
        isForeman: value,
      })
    );
  };

  const handleRemoveResource = (type, id) => () => {
    dispatch(removeResources({ sheetId: sheet._id, [type]: [id] }));
  };

  const maxHeight = Math.min(document.querySelector('.fullCalendarWrapper').clientHeight - 70, 450);
  const calendarFullHeight = document.querySelector('.fc-time-grid').clientHeight;
  const callendarFullWidth = document.querySelector('.fc-time-grid');
  const headerFullWidth = document.querySelector('.fc-time-grid__header');
  const container = document.querySelector('.fc-content-col');
  const left =
    (scheduleEventEl.style.left ? Number.parseInt(scheduleEventEl.style.left) : column * 203) + 210;
  const startHour = parseISO(sheet.hours.start).getHours();
  const endHour = parseISO(sheet.hours.end).getHours();
  const currentDate = new Date(selectedDate).getDate();
  const sheetDayStart = new Date(sheet.hours.start).getDate();
  const dayToRender = currentDate === sheetDayStart ? startHour : endHour;

  if (openModal && left + 432 > callendarFullWidth.clientWidth) {
    callendarFullWidth.style.width = `${callendarFullWidth.clientWidth + 485}px`;
    headerFullWidth.style.width = `${headerFullWidth.clientWidth + 485}px`;
  }

  const close = (e) => {
    e.stopPropagation();
    if (!openModal && left + 432 > callendarFullWidth.clientWidth) {
      callendarFullWidth.style.width = `${callendarFullWidth.clientWidth - 487}px`;
      headerFullWidth.style.width = `${headerFullWidth.clientWidth - 485}px`;
    }
    handleClose();
  };

  const minTop = 55;
  const defaultTop = dayToRender * 40 - 400;
  const maxTop = calendarFullHeight - maxHeight;

  const sheetHasLocation = sheet.locations && !!sheet.locations.length;
  const sheetHasRallyPoints = !!sheet?.rallyPoints?.length;

  const ref = useRef();

  useEffect(() => {
    ref.current && ref.current.scrollIntoView({ block: 'center', inline: 'center' });
  }, []);

  return ReactDOM.createPortal(
    <Paper
      ref={ref}
      elevation={3}
      style={{
        width: 400,
        padding: 16,
        position: 'absolute',
        maxHeight,
        overflowY: 'auto',
        top: dayToRender < 12 ? Math.max(defaultTop, minTop) : Math.min(defaultTop + 320, maxTop),
        left,
        zIndex: 6,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className={worklogClasses.flexBox}>
            <div className={worklogClasses.photoWrapper}>
              <img src={photoPlaceholder} className={worklogClasses.photo} />
            </div>
            <Typography variant="body1" color="textPrimary" className={worklogClasses.name}>
              {crewLeader ? crewLeader.username : 'No CL'}
            </Typography>
            {sheet.forceAccount && sheet.forceAccount !== 'No' && (
              <div className={worklogClasses.flexWrapper}>
                <Typography variant="body2" className={worklogClasses.forceAccount}>
                  T&M: {sheet.forceAccount}
                </Typography>
              </div>
            )}
          </div>
          <IconButton
            className={worklogClasses.closeButton}
            style={{ height: 34, width: 34, padding: 5 }}
            onClick={close}
            aria-label="Close"
            disableRipple
          >
            <CloseIcon />
          </IconButton>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1" color="textSecondary" style={{ marginBottom: '.5rem' }}>
            {sheet.project.contractor.name}
          </Typography>
          <Typography variant="body1" color="textPrimary" style={{ marginBottom: '.5rem' }}>
            {getProjectName(sheet.project)}
            {sheet.project.county ? `, ${sheet.project.county}` : ''}
          </Typography>
          <Typography variant="body1" color="textSecondary" style={{ marginBottom: '.5rem' }}>
            #{sheet.project.jobNumber}
          </Typography>
          {sheet.hours?.start && sheet.hours?.end && (
            <Typography variant="body1">
              {format(
                parseISO(sheet.hours.start),
                `${getTimeFormatHook().formatForTimePars} ${format(
                  new Date(sheet.hours.start),
                  getTimeFormatHook().localDayMonth
                )}`
              )}
              {' — '}
              {format(
                parseISO(sheet.hours.end),
                `${getTimeFormatHook().formatForTimePars} ${format(
                  new Date(sheet.hours.end),
                  getTimeFormatHook().localDayMonth
                )}`
              )}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={6}>
          {sheetHasRallyPoints &&
            sheet.rallyPoints.map((locationId) => (
              <Typography
                key={locationId}
                variant="body1"
                color="textSecondary"
                className={classnames(worklogClasses.flexBox, worklogClasses.locations)}
              >
                <img
                  src={LocationIcon}
                  alt="location"
                  onClick={openLocation(locationId)}
                  style={{ marginRight: 8 }}
                />
                {sheetLocation(sheet, true, locationId)}
              </Typography>
            ))}
          {sheetHasLocation &&
            sheet.locations.map((locationId) => (
              <Typography
                key={locationId}
                variant="body1"
                color="textSecondary"
                className={classnames(worklogClasses.flexBox, worklogClasses.locations)}
              >
                <img
                  src={LocationIcon}
                  alt="location"
                  onClick={openLocation(locationId)}
                  style={{ marginRight: 8 }}
                />
                {sheetLocation(sheet, true, locationId)}
              </Typography>
            ))}
          {!sheetHasLocation && !sheetHasRallyPoints && (
            <Typography variant="body1" color="textSecondary" className={worklogClasses.flexBox}>
              N/A
            </Typography>
          )}
        </Grid>

        <Grid item xs={12}>
          <div className={crewBlockClasses.header}>
            <Typography
              variant="body1"
              color="textSecondary"
              className={classnames(worklogClasses.regularFontWeight, worklogClasses.paddingBottom)}
            >
              Notes:
            </Typography>
          </div>
          <Typography variant="body1" color="textPrimary" style={{ wordBreak: 'break-word' }}>
            {sheet.notes || 'N/A'}
          </Typography>
        </Grid>

        {!!sheet.workers.length && (
          <Grid item xs={12}>
            <div className={crewBlockClasses.header}>
              <Typography
                variant="body1"
                color="textSecondary"
                className={classnames(
                  worklogClasses.regularFontWeight,
                  worklogClasses.paddingBottom
                )}
              >
                People:
              </Typography>
            </div>
            {/* .map((w) => ({
                ...w,
                ...(workers.find((worker) => worker._id === w._id) || {}),
              })) */}
            {sheet.workers.sort(compareByForeman).map((w) => (
              <Grid container key={w._id} className={crewUserClasses.paddingBottom}>
                <Grid item xs={12}>
                  <div className={crewUserClasses.flexBox}>
                    <div className={crewUserClasses.inlineFlex}>
                      <div
                        className={classnames(
                          crewUserClasses.photoWrapper,
                          crewUserClasses[`${w?.shifts?.timeOfDay?.toLowerCase()}Border`]
                        )}
                      >
                        <img src={photoPlaceholder} className={crewUserClasses.photo} />
                      </div>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        className={classnames(
                          crewUserClasses.name,
                          crewUserClasses[w?.shifts?.timeOfDay?.toLowerCase()]
                        )}
                      >
                        {w.username}
                      </Typography>
                    </div>

                    <div>
                      <Button
                        variant="text"
                        className={
                          w.foreman ? crewUserClasses.activeButton : crewUserClasses.inactiveButton
                        }
                        onClick={hasAccess ? setForeman(w) : null}
                        disableRipple
                      >
                        (CL)
                      </Button>
                      <IconButton
                        className={resourcesClasses.closeButton}
                        onClick={handleRemoveResource('workers', w._id)}
                        aria-label="Close"
                        disableRipple
                        disableTouchRipple
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </div>
                </Grid>
              </Grid>
            ))}
          </Grid>
        )}

        {!!sheet.equipment.length && (
          <Grid item xs={12}>
            <div className={crewBlockClasses.header}>
              <Typography
                variant="body1"
                color="textSecondary"
                className={classnames(
                  worklogClasses.regularFontWeight,
                  worklogClasses.paddingBottom
                )}
              >
                Equipment:
              </Typography>
            </div>
            {sheet.equipment.map((e) => (
              <Grid container key={e._id} className={crewUserClasses.paddingBottom}>
                <Grid item xs={12}>
                  <div className={crewUserClasses.flexBox}>
                    <div
                      className={classnames(
                        resourcesClasses.equipmentBlock,
                        getEquipmentInfo(e._id, 'className')
                      )}
                      style={{
                        backgroundColor: getEquipmentInfo(e._id, 'color'),
                      }}
                    >
                      <div className={resourcesClasses.equipmentNumber}>
                        {getEquipmentInfo(e._id, 'name')}
                      </div>
                    </div>
                    <IconButton
                      className={resourcesClasses.closeButton}
                      onClick={handleRemoveResource('equipment', e._id)}
                      aria-label="Close"
                      disableRipple
                      disableTouchRipple
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                </Grid>
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
    </Paper>,
    container
  );
};
