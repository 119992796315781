import React, { useEffect, useMemo, useState, useCallback } from 'react';
import propTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import classnames from 'classnames';
import photoPlaceholder from 'assets/images/user-photo-placeholder.svg';
import styles from '../../ResourcesForm/styles';
import { Sheet } from './Sheet';
import AlertModal from '../../AlertModal/AlertModal';
import OverlappingModal from '../OverlapsingModal';
import { invalidTimePeriods } from '../../ResourcesForm/helpers';
import ConfirmDialog from '../../ConfirmDialog';
import { useCurrentUser } from '../../Kiosk/hooks/useCurrentUser';
import SnackBar from 'components/SnackBar';
import { useSelector, useDispatch } from 'react-redux';
import { checkUserEditSheetPermissions } from 'helpers/_helpers';
import { putWorklogDayHours } from 'store/currentWorklog/operations';
import { removeWorkerFromWorklog } from '../../../store/currentWorklog/operations';
import { useWeekStart } from 'hooks/useWeekStart';
import { isValid } from 'date-fns';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';
import { convertTo24HourFormat } from 'common/dateFormatConfig';

const useStyles = makeStyles(styles);

const ChangeTimeModal = ({ onClose, data, save, moveFrom, isDuplicate }) => {
  const classes = useStyles();
  const { user } = useCurrentUser();
  const usersList = useSelector((store) => store.schedule.resources.workers);
  const peopleList = useSelector((store) => store.people.users);
  const { weekDayFromO } = useWeekStart();
  const getTimeFormatHook = useTimeSettingsFormat();
  const is12Format = getTimeFormatHook().is12Format;

  //* data.data - mean that data came not from the error
  const dispatch = useDispatch();
  const currSheet = useSelector((store) => store.currentWorklog.worklogSheet.sheet);
  const [sheets, setSheets] = useState(data.data || data.overlapseSheets || []);

  const [timeData, setTimeData] = useState(null);
  const [reset, setReset] = useState(null);
  const [loading, setLoading] = useState(false);
  const [confirmRemove, setConfirmRemove] = useState(null);
  const [infoModal, setInfoModal] = useState({
    isOpen: false,
    text: '',
  });
  const [overlappingModal, setOverlappingModal] = useState(null);
  const timeDataError = useMemo(
    () => timeData && timeData.reduce((res, curr) => res || curr.data.error, false),
    [timeData]
  );

  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    text: '',
    type: 'success',
  });

  const openSnackbar = useCallback((type, text) => setSnackbar({ isOpen: true, type, text }), []);

  const closeSnackbar = useCallback(() => setSnackbar((p) => ({ ...p, isOpen: false })), []);

  useEffect(() => {
    if (!sheets || !sheets.length) onClose();
  }, [sheets]);

  const closeModal = () => setInfoModal({ isOpen: false, text: '' });
  const closeOverlappingModal = () => {
    setOverlappingModal(null);
    onClose();
  };

  const updateTimeData = (sheetId, workerId) => (data) => {
    setTimeData((prev) => {
      if (!prev) return [{ sheetId, workerId, data }];
      const hasChanged = prev.find((sheet) => sheet.sheetId === sheetId);
      return hasChanged
        ? prev.map((sheet) => (sheet.sheetId === sheetId ? { sheetId, workerId, data } : sheet))
        : [...prev, { sheetId, workerId, data }];
    });
  };

  const onSave = async () => {
    if (!timeData || timeDataError) return;

    const sheetsData = data.data ? sheets : sheets.map((sheet) => sheet.sheet);
    const permissionChecks = timeData.map((sheet) => {
      const sheetForCheck = sheetsData.find((el) => el._id === sheet.sheetId);
      return checkUserEditSheetPermissions(sheetForCheck, weekDayFromO);
    });

    const filteredTimeData = timeData.filter((_, i) => permissionChecks[i]);

    const workerSheets = filteredTimeData.map((sheet) => {
      const originalSheet = sheetsData.find((el) => el._id === sheet.sheetId);

      const startTime = sheet.data
        ? {
            hour: is12Format
              ? convertTo24HourFormat(sheet.data.start.hourStart, sheet.data.start.amPmStart)
              : sheet.data.start.hourStart,
            minute: sheet.data.start.minuteStart,
          }
        : null;

      const dataObj = {
        sheetId: sheet.sheetId,
        startTime: startTime,
        hours:
          sheet.data && sheet.data.onJob.length
            ? sheet.data.onJob.map((hours) => {
                const tmpHours = {};
                tmpHours.start = new Date(hours.start);
                tmpHours.end = new Date(hours.end);
                tmpHours.travelTimeTo = {
                  hours: hours.travelTimeTo?.hours || '0',
                  minutes: hours.travelTimeTo?.minutes || '0',
                };
                tmpHours.travelTimeFrom = {
                  hours: hours.travelTimeFrom?.hours || '0',
                  minutes: hours.travelTimeFrom?.minutes || '0',
                };
                tmpHours.isWlHours = hours.isWlHours;
                tmpHours.isWlTravelTime = hours.isWlTravelTime;

                if (
                  !isValid(tmpHours.start) ||
                  !isValid(tmpHours.end) ||
                  originalSheet?.travelTimeOnly ||
                  tmpHours.isWlHours
                ) {
                  delete tmpHours.start;
                  delete tmpHours.end;
                }
                return tmpHours;
              })
            : null,
      };
      return dataObj;
    });
    const currWorkerId = data.data ? data?.data[0]?.worker?._id : data?.worker?._id;
    try {
      //|| data.overlapseSheets[0].sheet._id, - on scheduale we don`t know currSheet
      setLoading(true);
      await dispatch(
        putWorklogDayHours(
          { workerSheets, moveFrom },
          currSheet?._id ||
            (data?.data && data?.data[0]?._id) ||
            (data?.overlapseSheets[0] && data?.overlapseSheets[0]?.sheet?._id),
          currWorkerId,
          isDuplicate
        )
      );
      setLoading(false);
      save();
      return;
    } catch (e) {
      console.log(e);
      setLoading(false);
      openSnackbar('error', e.response.data.message);

      if (e.response && e.response.data.type === 'sheet-time-overlapse') {
        setOverlappingModal({
          reason: e.response.data.message,
          data: e.response.data.data,
        });
      }
      if (e.response && e.response.data.type === 'timeoff-time-overlapse') {
        setInfoModal({ isOpen: true, text: e.response.data.message });
      }
    }
  };

  const onReset = () => setReset(!reset);

  const showConfirmRemove = (sheetId, workerId) => () => setConfirmRemove({ sheetId, workerId });
  const closeConfirmRemove = () => setConfirmRemove(null);

  const removeWorker = async () => {
    try {
      dispatch(removeWorkerFromWorklog(confirmRemove.sheetId, confirmRemove.workerId, data));

      setSheets((prev) => {
        return data.data
          ? prev.filter((sheet) => sheet._id !== confirmRemove.sheetId)
          : prev.filter((sheet) => sheet.sheet._id !== confirmRemove.sheetId);
      });

      setTimeData((prev) => prev.filter((time) => time.sheetId !== confirmRemove.sheetId));
      closeConfirmRemove();
    } catch (e) {
      console.log(e);
    }
  };

  let invalidSheetIndexes = [];
  if (timeData && timeData[0] && timeData[0].data.start) {
    const data = [];
    timeData.forEach((curr, index) => {
      if (curr.data.onJob?.length) {
        data.push(...curr.data.onJob.map((data) => ({ index, data })));
        return;
      }

      const sheet = sheets.find((sheet) => sheet._id === curr.sheetId);
      if (sheet) {
        const hours = Array.isArray(sheet.hours) ? sheet.hours[0] : sheet.hours;
        data.push({ data: hours, index });
      }
    });
    invalidSheetIndexes = invalidTimePeriods(data);
  }

  const currWorker = () => {
    if (data.data) {
      const allUsers = usersList ? usersList : peopleList;
      return (
        allUsers.find((worker) => worker._id === data?.data[0]?.worker?._id) || data.extendedWorker
      );
    }

    if (data.overlapseSheets) {
      return data.worker;
    }
  };

  const workerOnJobDate = (sheet) => {
    if (data.data) {
      //if clicked on worker
      const result = sheet.worker.hours ? sheet.worker.hours : sheet.hours;
      result.isWlHours = result.isWlHours || false;
      result.isWlTravelTime = result.isWlTravelTime || false;

      return result;
    } else {
      // if overlaps error

      // sheet?.sheet?.travelTimeOnly //mutate object - due to change result link call rerender
      //   ? sheet.overlapseHours.forEach((el) => {
      //       delete el.start; //del extra hours - because sheet tto
      //       delete el.end;
      //     })
      //   : sheet.overlapseHours;

      return sheet.overlapseHours;
    }
  };
  const workerStartTime = (sheet) => {
    if (data.data) {
      //if clicked on worker
      return sheet.worker.startTime ? sheet.worker.startTime : sheet.startTime;
    } else {
      //if overlaps error
      return sheet.startTime ? sheet.startTime : sheet.sheet.startTime;
    }
  };

  return (
    <>
      <Dialog
        open
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          classes: {
            root: classes.dialog,
          },
        }}
      >
        <DialogTitle id="alert-dialog-title" disableTypography style={{ paddingBottom: 8 }}>
          <Typography variant="h3">Edit Work Time</Typography>
          <IconButton
            className={classes.closeButton}
            onClick={onClose}
            aria-label="Close"
            style={{ right: 14, top: 14 }}
            disableRipple
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <>
          {!!currWorker() ? (
            <>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <div className={classes.inlineFlex}>
                      <div
                        className={classnames(
                          classes.photoWrapper,
                          // classes[`${(data.worker.shifts.timeOfDay || '').toLowerCase()}Border`]
                          classes[
                            `${(data.data
                              ? currWorker()?.profile?.shifts?.timeOfDay
                              : currWorker()?.shifts?.timeOfDay || ''
                            )?.toLowerCase()}Border`
                          ]
                        )}
                      >
                        <img src={photoPlaceholder} className={classes.photo} />
                      </div>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        className={classnames(
                          classes.name,
                          // classes[(data.worker.shifts.timeOfDay || '').toLowerCase()]
                          classes[
                            (data.data
                              ? currWorker()?.profile?.shifts?.timeOfDay
                              : currWorker()?.shifts?.timeOfDay || ''
                            )?.toLowerCase()
                          ]
                        )}
                      >
                        {currWorker().username}
                      </Typography>
                    </div>
                  </Grid>
                  {sheets.map((sheet, i) => (
                    <Sheet
                      key={data.data ? sheet._id : sheet.sheet._id}
                      data={data.data ? sheet : sheet.sheet}
                      workerStartTime={workerStartTime(sheet)}
                      onJobDate={workerOnJobDate(sheet)}
                      workerId={currWorker()._id}
                      update={
                        data.data
                          ? updateTimeData(sheet._id, currWorker()._id)
                          : updateTimeData(sheet.sheet._id, currWorker()._id)
                      }
                      reset={reset}
                      error={invalidSheetIndexes.includes(i)}
                      removeSheet={
                        data.data
                          ? showConfirmRemove(sheet._id, currWorker()._id)
                          : showConfirmRemove(sheet.sheet._id, currWorker()._id)
                      }
                    />
                  ))}
                </Grid>
              </DialogContent>
            </>
          ) : (
            'Incorrect data'
          )}
        </>

        <DialogActions>
          <Button
            onClick={onReset}
            variant="outlined"
            color="secondary"
            className={classes.cancelButton}
            disableTouchRipple
          >
            Reset to Origin
          </Button>
          <Button
            disabled={loading || !timeData || !!timeDataError || !!invalidSheetIndexes.length}
            onClick={onSave}
            variant="outlined"
            color="primary"
            className={classes.saveButton}
            disableTouchRipple
          >
            Save
          </Button>
        </DialogActions>
        <SnackBar closeSnackbar={closeSnackbar} {...snackbar} />
      </Dialog>
      {infoModal.isOpen && (
        <AlertModal isOpen={infoModal.isOpen} info={infoModal.text} onClose={closeModal} />
      )}
      {confirmRemove && (
        <ConfirmDialog
          isOpen
          onSubmit={removeWorker}
          onClose={closeConfirmRemove}
          text="Are you sure you want to delete this User from the Worklog?"
        />
      )}
      {overlappingModal && (
        <OverlappingModal
          data={overlappingModal.data}
          message={overlappingModal.reason}
          onClose={closeOverlappingModal}
          onSave={isDuplicate ? save : undefined}
        />
      )}
    </>
  );
};

ChangeTimeModal.propTypes = {
  onClose: propTypes.func.isRequired,
  data: propTypes.object.isRequired,
};

export default ChangeTimeModal;
