export default theme => ({
  root: {
    maxWidth: 1280,
    margin: '10px auto 0',
    border: 'none'
  },
  table: {
    // minWidth: 650,
    // tableLayout: 'fixed',
    width: '100%',
    // border: 'none'
    border: `1px solid ${theme.palette.borderColor.dark}`
  },
  zeroPadding: {
    padding: '0px',
    '&:last-child': {
      paddingRight: 0
    }
  },
  noBorder: {
    borderLeft: 'none !important',
    borderRight: 'none !important'
  },
  cellContent: {
    width: 140,
    minHeight: 100
  },
  dayNumber: {
    fontSize: 14,
    lineHeight: 1.5,
    color: theme.palette.textColor.dark,
    padding: '0 5px',
    border: `1px solid ${theme.palette.borderColor.dark}`,
    borderLeft: 'none'
  },
  list: {
    padding: '0 5px'
  },
  resourceTitle: {
    maxWidth: '100%',
    wordBreak: 'break-word',
    paddingBottom: 8,
    lineHeight: 1.2,
    fontSize: 14
  },
  holidayTitle: {
    maxWidth: '100%',
    wordBreak: 'break-word',
    paddingBottom: 16,
    lineHeight: 1.2,
    fontSize: 16
  },
  marginBottom: {
    marginBottom: 8
  }
});
