/** Configuration time
 *
 * @param time - time in string format
 * @returns {time} - like '00:00:00'
 */
export const configureTimeForTimer = (time) => {
  const currentTime = new Date();
  const clockedInTime = new Date(time);
  const diffSec = (currentTime - clockedInTime) / 1000;

  const hours = Math.trunc(diffSec / 60 / 60);
  const minutes = Math.trunc((diffSec - hours * 60 * 60) / 60);
  const seconds = Math.trunc(diffSec - hours * 60 * 60 - minutes * 60);

  const str = (v) => v.toString().padStart(2, '0');
  return `${str(hours)}:${str(minutes)}:${str(seconds)}`;
};
