import {makeStyles} from "@material-ui/core/styles";

export default makeStyles(() => ({
    root: {
        margin: '0 14px 18px'
    },
    title: {
        color: '#202020',
        fontSize: 20,
        fontWeight: 500,
        letterSpacing: '0.25px',
        lineHeight: '30px'
    }
}))