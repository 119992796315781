import GearImg from 'assets/images/Gear.png'
export const styles = (theme) => ({
  headingWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '650px',
      margin: '15px auto 25px',
      justifyContent: 'flex-start',
    },
    '@media (max-width: 680px)': {
      width: '500px',
    },
    '@media (max-width: 530px)': {
      margin: '15px 0 25px 0',
      width: 'auto',
      justifyContent: 'space-between',
    },
  },
  heading: {
    fontWeight: 500,
    fontSize: '1.5rem',
  },
  time: {
    fontSize: '1.125rem',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    // marginRight: 25,
  },
  email: {
    '&:hover, &:focus, &:active': {
      backgroundColor: 'transparent',
      color: theme.palette.textColor.secondary,
    },
    '& svg': {
      fill: theme.palette.textColor.primary,
    },
    '&.MuiButton-text': {
      padding: '0 8px 10px 8px',
    },
  },
  emailWrap: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  mobileCalendar: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '650px',
      margin: '0 auto 25px',
    },
    '@media (max-width: 680px)': {
      width: '500px',
    },
    '@media (max-width: 510px)': {
      width: 'auto',
      justifyContent: 'center',
    },
    '@media (max-width: 420px)': {
      margin: '0 0 25px 0',
    },
  },
  background: {
    backgroundImage: `url(${GearImg})`,
    height: 'calc(100vh - 72px)',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  },
  imgLogo: {
    maxWidth: '400px',
    '@media (max-width: 750px)': {
      maxWidth: '265px',
    },
  },
});
