import React, { useState, useEffect, useRef } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import classnames from 'classnames';
import _ from 'lodash';
import { formatWeatherToString, getProjectName } from 'helpers/_helpers';
import { generateHoursObject } from 'helpers/helpers';
import { agoDate, shortDate, signatureDate, clockTime } from 'helpers/_date-helpers';
import PrintHLogo from 'assets/images/print-logo-header.png';
import PrintFLogo from 'assets/images/print-logo-footer.png';
import SubmitModal from '../SubmitModal';
import styles from './styles';
import { materialGroups } from 'helpers/_sort-helpers';
import { useSelector } from 'react-redux';
import { getBrowser } from 'api/_utility';
import { Typography } from '@material-ui/core';
import { SheetPrintImgLeaf } from '../SheetPrintImgLeaf/SheetPrintImgLeaf';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';
import { MEASUREMENT_UNITS, MEASURE_UNITS_BY_SYSTEM } from 'common/measurements';

const SheetItem = (props) => {
  const wlWrapper = useRef();
  const [sheetProject, setSheetProject] = useState(null);

  const [locationOptions, setLocationOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenSubmitModal, setIsOpenSubmitModal] = useState(false);
  const [submitModalType, setSubmitModalType] = useState('dot');
  const [submissionId, setSubmissionId] = useState(null);
  const settingsCompanyName = useSelector(
    (state) => state.personalProfile?.organization?.name || '3208 Technology, Inc.'
  );
  const sheetCompanyName = props.sheet?.organization?.name || '3208 Technology, Inc.';
  const companyName = props.isPrintById ? sheetCompanyName : settingsCompanyName;
  const { dayDateSettingsFormat } = useDateSettingsFormat({
    dateFormat: props.sheet?.organization?.settings?.dateFormat,
    timeFormat: props.sheet?.organization?.settings?.timeFormat,
  });
  const getTimeFormatHook = useTimeSettingsFormat({
    dateFormat: props.sheet?.organization?.settings?.dateFormat,
    timeFormat: props.sheet?.organization?.settings?.timeFormat,
  });
  const formatForTimePars = getTimeFormatHook().formatForTimePars;
  useEffect(() => {
    const { sheetLocations, sheetRallyPoints, materialLocations, project } = props.sheet;
    const sheetProject = project;
    const allLocations = [
      ...(sheetRallyPoints || []),
      ...(project?.rallyPoints || []),
      ...(sheetLocations || []),
      ...(project?.locations || []),
    ];
    const locationOptions = allLocations.concat(materialLocations).filter((el) => !!el);
    // if (sheetProject) {
    //   locationOptions = _.sortBy(sheetProject.locations, function (location) {
    //     return (locations || []).includes(location.id) ? 0 : 1; // Sheet Location First
    //   }).concat(materialLocations);
    // }
    setSheetProject(sheetProject);
    setLocationOptions(locationOptions);

    setIsLoading(false);

    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (!/^Guidemark/.test(companyName)) {
      props.updateHeaderLogoLoadedValue(props?.sheet?._id)();
    }
  }, []);

  const cancelStatus = () => (props.sheet.canceledAt ? ', CANCELED' : null);
  //
  const getFormattedHoursRange = (hoursObj, isCapital) => {
    const { createdAt, hours } = props.sheet;
    if (!hours) return;
    const currentHours = hoursObj || hours;
    const { hourStart, minuteStart, amPmStart, dayStart, hourEnd, minuteEnd, amPmEnd, dayEnd } =
      generateHoursObject(currentHours.start, currentHours.end, createdAt, getTimeFormatHook);
    // let start;
    // let end;
    // if (isCapital) {
    //   start = `${hourStart}:${minuteStart} ${amPmStart?.toUpperCase() || amPmStart} ${shortDate(
    //     createdAt,
    //     dayStart
    //   )}`;
    //   end = `${hourEnd}:${minuteEnd} ${amPmEnd?.toUpperCase() || amPmEnd} ${shortDate(
    //     createdAt,
    //     dayEnd
    //   )}`;
    // } else {
    //   start = `${hourStart}:${minuteStart}${amPmStart} ${shortDate(createdAt, dayStart)}`;
    //   end = `${hourEnd}:${minuteEnd}${amPmEnd} ${shortDate(createdAt, dayEnd)}`;
    // }
    const startObj = [null, hourStart, minuteStart, amPmStart];
    const endObj = [null, hourEnd, minuteEnd, amPmEnd];

    const start = `${getTimeFormatHook(startObj).formattedTime} ${shortDate(
      createdAt,
      dayStart,
      getTimeFormatHook().localDayMonth
    )}`;
    const end = `${getTimeFormatHook(endObj).formattedTime} ${shortDate(
      createdAt,
      dayEnd,
      getTimeFormatHook().localDayMonth
    )}`;

    return `${start} \u2013 ${end}`;
  };

  const getMaterialLocationName = (id) => {
    if (!locationOptions.length) return '';
    const locationObj = locationOptions.find((option) => option?._id === id || option?.id === id);
    return locationObj ? (locationObj.name ? locationObj.name : locationObj.address) : '';
  };

  const openSubmitModal = (type, id) => () => {
    setIsOpenSubmitModal(true);
    setSubmitModalType(type);
    setSubmissionId(id);
  };

  const closeSubmitModal = () => setIsOpenSubmitModal(false);

  const printMaterialDescription = (material) => {
    const widthUnit = MEASURE_UNITS_BY_SYSTEM[props.sheet?.measurement || props.sheet?.organization?.settings?.measurement]?.[MEASUREMENT_UNITS.width].replace(/in/, '"').replace(/cm/, ' cm');
    const lengthUnit = MEASURE_UNITS_BY_SYSTEM[props.sheet?.measurement || props.sheet?.organization?.settings?.measurement]?.[MEASUREMENT_UNITS.length];
    let description = '';
    const { materialSpecifications } = material;
    if (materialSpecifications.line) {
      if (materialSpecifications.line.isSF !== true) {
        description += `${materialSpecifications.line.width}${widthUnit} Line`;
      }
    }
    if (materialSpecifications.legend) {
      description += `${material.quantity} ${materialSpecifications.legend.symbol}(s)`;
    }
    if (materialSpecifications.lettering) {
      const letteringParams = [
        materialSpecifications.lettering.height
          ? `${materialSpecifications.lettering.height}${lengthUnit} H`
          : '',
        materialSpecifications.lettering.width
          ? `${materialSpecifications.lettering.width}${widthUnit} W`
          : '',
        materialSpecifications.lettering.stroke
          ? `${materialSpecifications.lettering.stroke}${widthUnit} S`
          : '',
      ]
        .filter((param) => !!param)
        .join(' - ');
      description += `"${material.letteringText}", ${letteringParams}`;
    }
    if (material.materialType === 'each') {
      description += `${material.quantity} ${MEASURE_UNITS_BY_SYSTEM
        [props.sheet?.measurement || props.sheet?.organization?.settings?.measurement]
        [materialSpecifications.discreteUnitType]}`;
    }

    if (material.materialType === 'each' && materialSpecifications.symbolOrWidth) {
      description += ` - ${materialSpecifications.symbolOrWidth}`;
    }

    if (material.description) {
      description += ' - ' + material.description;
    }

    if (materialSpecifications.colorOrNumber) {
      description += description
        ? ` - ${materialSpecifications.colorOrNumber}`
        : materialSpecifications.colorOrNumber;
    }

    return description;
  };

  if (isLoading) return null;

  const { classes, sheet } = props;
  function isIOS() {
    const userAgent = navigator.userAgent.toLowerCase();
    return /macintosh|mac os x/.test(userAgent);
  }

  const SAFARI = 977;
  const CHROME = isIOS() ? 1048 : 1044;
  const FOOTER_IMG = 70;
  const A4 = getBrowser() === 'Safari' ? SAFARI : CHROME;

  const lengthUnit = MEASURE_UNITS_BY_SYSTEM[props.sheet?.measurement || props.sheet?.organization?.settings?.measurement]?.[MEASUREMENT_UNITS.length]

  return (
    <>
      <div
        style={{
          height: Math.ceil((wlWrapper?.current?.offsetHeight + FOOTER_IMG) / A4) * A4,
          overflow: 'hidden',
        }}
      >
        <div container ref={wlWrapper}>
          {/^Guidemark/.test(companyName) ? (
            <div item xs={12}>
              <img
                className={classnames(classes.logo)}
                src={PrintHLogo}
                onLoad={props.updateHeaderLogoLoadedValue(sheet._id)}
              />
            </div>
          ) : (
            <div item xs={12} className={classes.textCenter}>
              <h1 className={classes.header1}>{companyName}</h1>
            </div>
          )}

          <div item xs={12} className={classes.textCenter}>
            <h1 className={classes.header1}>DAILY WORK LOG</h1>
            <table className={classes.linedTable}>
              <tbody>
                <tr>
                  <td>Date:</td>
                  <td>{agoDate(sheet.createdAt, null, dayDateSettingsFormat)}</td>
                  <td className={classes.middleHeading}>Contractor:</td>
                  <td>{sheetProject.contractor.name}</td>
                </tr>
                <tr>
                  <td>Job number:</td>
                  <td>{sheetProject.jobNumber}</td>
                  <td className={classes.middleHeading}>Project:</td>
                  <td>
                    {getProjectName(sheetProject)}
                    {cancelStatus()}
                  </td>
                </tr>
                <tr>
                  <td>Project ID:</td>
                  <td>{sheetProject.id}</td>
                  <td className={classnames(classes.forceAccount, classes.middleHeading)}>
                    TIME & MATERIAL:
                  </td>
                  <td>
                    {sheet.forceAccount}
                    {sheet.forceAccountTime
                      ? `(${sheet.forceAccountTime.hours}:${sheet.forceAccountTime.minutes} Hours)`
                      : ''}
                  </td>
                </tr>
                <tr>
                  <td>On&nbsp;Job Time:</td>
                  <td>{getFormattedHoursRange(null, true)}</td>
                  <td className={classes.middleHeading}>Weather:</td>
                  <td>{formatWeatherToString(sheet.weather)}</td>
                </tr>
                <tr>
                  <td>Crew:</td>
                  <td>
                    <table className={classes.hoursTable}>
                      <tbody>
                        {sheet.workers?.map((w) => (
                          <tr key={w._id}>
                            <td>{w.username}</td>
                            <td>
                              {w.hours?.map((h, i) => (
                                <div key={i}>{getFormattedHoursRange(h, true)}</div>
                              ))}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </td>
                  <td className={classes.middleHeading}>Equipment:</td>
                  <td>
                    <table className={classes.hoursTable}>
                      <tbody>
                        {sheet.equipment?.map((e) => (
                          <tr key={e._id}>
                            <td>{e.name}</td>
                            <td>{e.hours?.map((h, i) => (
                              <div>{getFormattedHoursRange(h, true)}</div>
                            ))}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr></tr>
                <tr>
                  <td>Notes:</td>
                  <td colSpan={3}>{sheet.notes}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div>
            {sheet?.materials &&
              materialGroups(sheet.materials).map((mg) => (
                <table key={mg.location || '' + mg.surface} className={classes.materialsTable}>
                  <thead>
                    <tr>
                      <td colSpan="3" className={classes.normalFontWeight}>
                        <div>
                          <div className={classes.inlineBlock}>
                            Location: {getMaterialLocationName(mg.location)}
                          </div>
                          <div className={classes.inlineBlock}>Surface: {mg.surface}</div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className={classes.width20}>QTY</td>
                      <td className={classes.width40}>DESCRIPTION</td>
                      <td className={classes.width40}>MATERIAL</td>
                    </tr>
                  </thead>

                  <tbody>
                    {mg.materials ? (
                      mg.materials.map((material) => (
                        <tr key={material._id}>
                          <td>
                            {!!material.quantity &&
                              (!!material.materialSpecifications.line
                                ? `${material.quantity} ${lengthUnit}`
                                : material.quantity)}
                          </td>
                          <td>{printMaterialDescription(material)}</td>
                          <td>
                            {material.other ? (
                              'Other work'
                            ) : (
                              <>
                                <span>{material.materialName}</span>
                                {!!material.materialSpecifications.colorOrNumber && (
                                  <>
                                    , <span>{material.materialSpecifications.colorOrNumber}</span>
                                  </>
                                )}
                              </>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3" style={{ textAlign: 'center' }}>
                          NO WORK LOGGED
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              ))}
          </div>

          {!!sheet.submissions &&
            sheet.submissions.map((submission) => (
              <div>
                <table className={classes.linedTable}>
                  <tbody>
                    <tr>
                      <td>DOT:</td>
                      <td>
                        {submission.signatures.dot?.length ? (
                          submission.signatures.dot.map((contact) => (
                            <div className={classes.signatureView}>
                              <div className={classes.title}>
                                {contact.title ? `${contact.title}:` : ''}
                                <span>{contact.name}</span>
                              </div>
                              <div className={classes.time} data-time={contact.time}>
                                {signatureDate(contact.time)}{' '}
                                {clockTime(contact.time, formatForTimePars)}
                              </div>
                              <div className={classes.email}>{contact.email}</div>

                              <img src={contact.image} />
                            </div>
                          ))
                        ) : (
                          <Button
                            variant="outlined"
                            className={classes.addSign}
                            disableRipple
                            onClick={openSubmitModal('dot', submission._id)}
                          >
                            Sign
                          </Button>
                        )}
                      </td>
                      <td className={classes.middleHeading}>Contractor:</td>
                      <td>
                        {submission.signatures.contractor?.length ? (
                          submission.signatures.contractor.map((data) => (
                            <div className={classes.signatureView}>
                              <div className={classes.title}>
                                {data.title ? `${data.title}:` : ''}
                                <span>{data.name}</span>
                              </div>
                              <div className={classes.time} data-time={data.time}>
                                {signatureDate(data.time)} {clockTime(data.time, formatForTimePars)}
                              </div>
                              <div className={classes.email}>{data.email}</div>

                              <img src={data.image} />
                            </div>
                          ))
                        ) : (
                          <Button
                            variant="outlined"
                            className={classes.addSign}
                            disableRipple
                            onClick={openSubmitModal('contractor', submission._id)}
                          >
                            Sign
                          </Button>
                        )}
                      </td>
                    </tr>

                    <tr>
                      <td>{companyName}:</td>
                      <td>
                        <div>{submission.username}</div>
                        <div>
                          {signatureDate(submission.startedAt)}{' '}
                          {clockTime(submission.startedAt, formatForTimePars)}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ))}

          {!!sheet?.images?.length && props.printImg && (
            <>
              <Typography style={{ fontSize: 14, marginTop: '25px' }} color="textSecondary">
                Images attached to the Work Log:
              </Typography>
              <div
                style={{
                  display: 'flex',
                  marginTop: '15px',
                  flexWrap: 'wrap',
                }}
              >
                {sheet.images.map((image, i) => (
                  <div
                    style={{
                      width: '120px',
                      height: '80px',
                      overflow: 'hidden',
                      borderRadius: '3px',
                      marginBottom: '8px',
                      marginRight: 15,
                    }}
                  >
                    <img
                      alt="WL Image"
                      key={image._id}
                      width="120px"
                      height="80px"
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                      onLoad={() => {
                        props.updateAllImgReadyState();
                      }}
                      className={classnames()}
                      src={`${process.env.REACT_APP_BASE_URL}/sheets/image?key=${
                        image?.x480 || image.key
                      }`}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
        </div>

        <div></div>
        <div className={classes.footerLogoWrapper}>
          <img
            className={classnames(
              /^Guidemark/.test(companyName) ? classes.logo : classes.logoHidden,
              classes.footerLogo
            )}
            src={PrintFLogo}
            onLoad={props.updateFooterLoadedState}
          />
        </div>
      </div>

      {isOpenSubmitModal && (
        <SubmitModal
          closeModal={closeSubmitModal}
          type={submitModalType}
          submissionId={submissionId}
        />
      )}
    </>
  );
};

SheetItem.propTypes = {
  classes: propTypes.object.isRequired,
  sheet: propTypes.object.isRequired,
  updateHeaderLogoLoadedValue: propTypes.func.isRequired,
  updateFooterLoadedState: propTypes.func.isRequired,
  companyName: propTypes.string,
};

export default withStyles(styles)(SheetItem);
