export default theme => ({
  card: {
    paddingTop: 10,
    marginBottom: 10,
    [theme.breakpoints.only('xl')]: {
      width: 237,
    },
  },
  regularFontWeight: {
    fontWeight: 300,
  },
  projectId: {
    color: theme.palette.textColor.secondary,
    fontSize: '0.8rem',
    fontWeight: 400,
    margin: '0 8px',
    cursor: 'default'
  },
  paddingBox: {
    padding: '0 8px 8px',
  },
  paddingBottom: {
    paddingBottom: 8
  },
  paddingBottomSmall: {
    paddingBottom: 4
  },
  marginBottomNormal: {
    marginBottom: 8
  },
  marginLeft: {
    marginLeft: 8
  },
  primaryColor: {
    color: theme.palette.textColor.primary,
    cursor: 'pointer',
  },
  darkColor: {
    color: theme.palette.textColor.dark
  },
  redColor: {
    color: '#ff0000',
  },
  greyColor: {
    color: theme.palette.textColor.secondary,
  },
  name: {
    wordBreak: 'break-word'
  },
  forceAccount: {
    display: 'inline',
    color: '#fff',
    backgroundColor: theme.palette.primary.blue,
    borderRadius: 9,
    padding: '3px 5px',
  },
  flexWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    minWidth: 65,
  },
  moreButton: {
    color: theme.palette.textColor.primary,
    padding: 0,
    minWidth: 'auto',
    '& span': {
      lineHeight: 1.1
    },
    '&:hover': {
      color: theme.palette.textColor.primary,
      background: theme.palette.background.default
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
      background: theme.palette.background.default
    }
  },
  notesSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  notes: {
    fontWeight: 500
  },
  columnDirection: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& button': {
      marginTop: 4
    }
  },
  flexBox: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 40
  },
  inlineFlex: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  pointer: {
    cursor: 'pointer',
  },
  contactLink: {
    textDecoration: 'none'
  },
});
