import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  printButton: {
    padding: 0,
    color: theme.palette.textColor.primary,
    '&:hover': {
      color: theme.palette.textColor.primary,
      background: `${theme.palette.background.default} !important`,
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
      background: `${theme.palette.background.default} !important`,
    },
    '& img': {
      marginRight: 5,
    },
  },
}));
