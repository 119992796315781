import React from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Fade from '@material-ui/core/Fade';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { signatureDate, clockTime } from 'helpers/_date-helpers';
import styles from './styles';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';

const SignaturePopover = ({
  classes,
  anchorEl,
  handleClosePopover,
  signatures,
  defaultPosition,
}) => {
  const getTimeFormatHook = useTimeSettingsFormat();
  const formatForTimePars = getTimeFormatHook().formatForTimePars;
  return (
    <Popper
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClosePopover}
      placement="bottom"
      onClick={handleClosePopover}
      className={classnames(!defaultPosition && classes.popper)}
      disablePortal
      transition
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper classes={{ root: classes.paper }}>
            <ClickAwayListener onClickAway={handleClosePopover}>
              <div>
                {Boolean(signatures && signatures.dot?.length) &&
                  signatures.dot?.map((contact) => (
                    <div className={classes.root}>
                      <Typography variant="body1" color="textPrimary" className={classes.title}>
                        DOT Inspector: {contact.name}
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        className={classes.time}
                        data-time={contact.time}
                      >
                        {signatureDate(contact.time)} {clockTime(contact.time, formatForTimePars)}
                      </Typography>
                      <div className={classes.email}>{contact.email}</div>
                      <img src={contact.image} className={classes.signatureImg} />
                    </div>
                  ))}
                {Boolean(signatures && signatures.contractor?.length) &&
                  signatures.contractor.map((contractor) => (
                    <div className={classes.root}>
                      <Typography variant="body1" color="textPrimary" className={classes.title}>
                        Contractor: {contractor.name}
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        className={classes.time}
                        data-time={contractor.time}
                      >
                        {signatureDate(contractor.time)}{' '}
                        {clockTime(contractor.time, formatForTimePars)}
                      </Typography>
                      <div className={classes.email}>{contractor.email}</div>
                      <img src={contractor.image} className={classes.signatureImg} />
                    </div>
                  ))}
              </div>
            </ClickAwayListener>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
};

SignaturePopover.propTypes = {
  classes: propTypes.object.isRequired,
  anchorEl: propTypes.object,
  handleClosePopover: propTypes.func.isRequired,
  signatures: propTypes.object,
  defaultPosition: propTypes.bool,
};

export default withStyles(styles)(SignaturePopover);
