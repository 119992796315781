import React from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Checkbox from '@material-ui/core/Checkbox';
import IOSSwitch from '../../../../../../components/IOSSwitch';
import photoPlaceholder from 'assets/images/user-photo-placeholder.svg';
import ArchiveIcon from 'assets/images/archive-icon.svg';
import UploadIcon from 'assets/images/upload-icon.svg'
import UnarchiveIcon from 'assets/images/unarchive-icon.svg';

import styles from './styles';
import DoneI from 'assets/images/done.svg';
import { GetImage } from '../../../NotesCards/components/ProjectNoteCard/ProjectNoteCard';
import { format, isValid } from 'date-fns';
import { GLOBAL_DATE_FORMAT } from 'common/dateFormatConfig';

const NoteDetails = ({
  classes,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  filesPreviewSources,
  previousUploadedFiles,
  handleFileChange,
  deleteImage,
  deletePreviousUploadedFiles,
  note,
  deleteNote,
  restoreNote,
  setNoteIdToResolve,
}) => {
  const formatCreationDate = () =>{
    return isValid(new Date(note.createdAt)) ? format(new Date(note.createdAt), GLOBAL_DATE_FORMAT.fullDate):''
  };

  return (
    <Grid container spacing={4} justifyContent="space-between" className={classes.root}>
      <Grid item xs={10} sm={10}>
        <div className={classes.flexBox}>
          <div className={classes.userPhotoWrapper}>
            <img src={photoPlaceholder} alt="user photo" className={classes.userPhoto} />
          </div>
          <Typography variant="h6" color="textPrimary">
            {note.ownerUser && note.ownerUser.profile.fullName}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {formatCreationDate()}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={2} sm={2}>
        <div className={classes.flexButt}>
          {note.deletedAt ? (
            <Button color="secondary" className={classes.deleteButton} onClick={restoreNote(note._id)} disableRipple>
              <img src={UnarchiveIcon} alt="unarchive" />
            </Button>
          ) : (
            <Button color="secondary" className={classes.deleteButton} onClick={deleteNote(note._id)} disableRipple>
              <img src={ArchiveIcon} alt="archive" />
            </Button>
          )}
        </div>
      </Grid>
      <Grid item xs={12} className={classes.zeroPaddingTop}>
        <TextField
          id="text"
          name="text"
          rowsMax={2}
          value={values.text}
          onChange={handleChange}
          error={Boolean(errors.text) && Boolean(touched.text)}
          onBlur={handleBlur}
          className={classes.textArea}
          variant="outlined"
          multiline
        />
        {errors.text && touched.text ? <div className={classes.error}>{errors.text}</div> : null}
      </Grid>
      <Grid item xs={12} className={classes.zeroPaddingTop}>
        <label htmlFor="file" className={classes.buttonsWrapper}>
          <label className={classes.uplWrapper}>
            <input
              type="file"
              name="file"
              id="file"
              accept="image/*"
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            <img src={UploadIcon} alt="upload" style={{ marginRight: '10px' }} />
            <p style={{ fontSize: '14px' }}>Upload image</p>
          </label>
        </label>
      </Grid>
      {filesPreviewSources.length || previousUploadedFiles.length ? (
        <Grid item xs={12} className={classes.imagesWrapper}>
          {previousUploadedFiles.map((source, i) => (
            <div key={source} className={classes.fragment}>
            <GetImage img={source} classes={classes}/>
              <Button
                color="secondary"
                className={classes.deleteButton}
                onClick={deletePreviousUploadedFiles(source)}
                disableRipple
              >
                <CloseIcon />
              </Button>
            </div>
          ))}
          {filesPreviewSources.map((source, i) => (
            <div key={source}>
              <img src={source} alt="uploaded image99" className={classes.imagePreview} />
              <Button color="secondary" className={classes.deleteButton} onClick={deleteImage(i)} disableRipple>
                <CloseIcon />
              </Button>
            </div>
          ))}
        </Grid>
      ) : null}
      <Grid item xs={6} className={classes.zeroPaddingTop}>
        <label className={classes.uplWrapper} onClick={setNoteIdToResolve(note._id)}>
          <img src={DoneI} alt="done" />
          <p style={{ fontSize: '0.85rem' }}>Resolve</p>
        </label>
      </Grid>
      <Grid item xs={6} style={{ textAlign: 'right', paddingRight: '27px' }} className={classes.zeroPaddingTop}>
        <label htmlFor="highPriority" className={classes.buttonsWrapper} style={{ display: 'inline-flex' }}>
          <Typography variant="body1" color="textSecondary" style={{ marginRight: '10px' }}>
            Priority:
          </Typography>
          <IOSSwitch name="highPriority" checked={values.highPriority} onChange={handleChange} value="highPriority" />
        </label>
      </Grid>
    </Grid>
  );
};

NoteDetails.propTypes = {
  classes: propTypes.object.isRequired,
  values: propTypes.object.isRequired,
  errors: propTypes.object.isRequired,
  touched: propTypes.object.isRequired,
  handleChange: propTypes.func.isRequired,
  handleBlur: propTypes.func.isRequired,
  filesPreviewSources: propTypes.array.isRequired,
  previousUploadedFiles: propTypes.array.isRequired,
  handleFileChange: propTypes.func.isRequired,
  deleteImage: propTypes.func.isRequired,
  deletePreviousUploadedFiles: propTypes.func.isRequired,
  note: propTypes.object.isRequired,
  deleteNote: propTypes.func.isRequired,
  restoreNote: propTypes.func.isRequired,
  setNoteIdToResolve: propTypes.func.isRequired,
};

export default withStyles(styles)(NoteDetails);
