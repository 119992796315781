import { Box, Typography, withStyles } from '@material-ui/core';
import BaseBadge from 'components/Badge';

export const Root = withStyles({
  root: {
    margin: '0 -16px 26px',
    padding: '0 16px',
    '&:last-child': {
      marginBottom: 0,
    },
    '@media (max-width: 900px)': {
      margin: 0,
      padding: '0 8px',
    },
    '@media print': {
      margin: '0 -16px 26px',
      padding: '0 16px',
    }
  },
})(Box);

export const Title = withStyles({
  body1: {
    color: '#5A87EF',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '21px',
    marginBottom: 12,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'space-between',
    '@media (max-width: 900px)': {
      borderBottom: '1px solid #e8e8e870',
      paddingBottom: '15px',
      justifyContent: 'space-between',
    },
    '@media print': {
      borderBottom: 'none',
      paddingBottom: '0px',
    }
  },
})(Typography);

export const ID = withStyles({
  root: {
    color: '#202020',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '25px',
    marginBottom: 10,
  },
})(Box);

export const LightText = withStyles({
  body1: {
    color: '#A4A5B3 !important',
    display: 'inline',
  },
})(Typography);

export const Badge = withStyles({
  root: {
    marginLeft: 20,
  },
})(BaseBadge);
