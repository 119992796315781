import React, { useState } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import photoPlaceholder from 'assets/images/user-photo-placeholder.svg';
import styles from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { postWorklogCL } from 'store/currentWorklog/operations';
import TimeInfo from './components/TimeInfo/TimeInfo';

const CrewUser = ({ classes, workerData, getFormattedHoursRange, hasAccess, openUserWorklogs }) => {
  const dispatch = useDispatch();
  const sheet = useSelector((store) => store.currentWorklog.worklogSheet.sheet);
  // const usersList = useSelector((store) => store.people.users);
  const [clUpdating, setClUpdating] = useState(false);

  const setForeman = async () => {
    setClUpdating(true);
    const sheetId = sheet._id;
    const userId = workerData._id;
    const value = !workerData.foreman;

    await dispatch(postWorklogCL({ foreman: value }, sheetId, userId));

    setClUpdating(false);
  };

  // const userData = usersList.find(el=>el._id == workerData._id) || null

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3}>
        <div className={classes.flexBox} style={{ height: 25 }}>
          <div
            className={classnames(classes.inlineFlex, hasAccess && classes.clickable)}
            onClick={hasAccess ? openUserWorklogs : () => null}
          >
            <div
              className={classnames(
                classes.photoWrapper,
                classes['dayBorder'],
                classes[`${workerData && workerData.shifts.timeOfDay.toLowerCase()}Border`]
              )}
            >
              <img src={photoPlaceholder} className={classes.photo} />
            </div>
            <Typography
              variant="body1"
              color="textPrimary"
              className={classnames(
                classes.name,
                classes['day'],
                classes[workerData && workerData.shifts.timeOfDay.toLowerCase()]
              )}
            >
              {workerData && workerData.username}
            </Typography>
          </div>

          <Button
            variant="text"
            className={workerData.foreman ? classes.activeButton : classes.inactiveButton}
            onClick={hasAccess ? setForeman : null}
            disableRipple
            disabled={clUpdating}
          >
            (CL)
          </Button>
        </div>
      </Grid>
      <Grid container item xs={8} md={8}>
        {workerData && workerData.hours && workerData.hours.length && (
          <TimeInfo
            workerHours={workerData.hours}
            classes={classes}
            sheet={sheet}
            getFormattedHoursRange={getFormattedHoursRange}
          />
        )}
      </Grid>
      <Grid item container xs={1} justifyContent={'flex-end'} spacing={0}></Grid>
    </Grid>
  );
};

CrewUser.propTypes = {
  classes: propTypes.object.isRequired,
  workerData: propTypes.object.isRequired,
  userData: propTypes.object.isRequired,
  getFormattedHoursRange: propTypes.func.isRequired,
  openUserWorklogs: propTypes.func.isRequired,
  hasAccess: propTypes.bool.isRequired,
};

export default withStyles(styles)(CrewUser);
