import { useMemo } from 'react';
import { getToggleHeightButton } from '../helpers/getToggleHeightButton';

export const useToggleHeightBtn = (props) => {
  const isToggleHeightBtn = useMemo(
    () =>
      getToggleHeightButton({
        isXXLWidth: props.isXXLWidth,
        workers: props.sheet.workers,
        equipment: props.sheet.equipment,
      }),
    [props.sheet, props.isXXLWidth]
  );
  return { isToggleHeightBtn };
};
