import React, { Component } from 'react';
import LoadingSpinner from '../../components/LoadingSpinner';
import ProjectsAndContractors from './Projects';
import SnackBar from '../../components/SnackBar';

class ProjectsWrapper extends Component {
  state = {
    snackbar: {
      isOpen: false,
      text: '',
      type: 'success',
    },
  };

  openSnackbar = (type, text) => this.setState({ snackbar: { isOpen: true, type, text } });

  closeSnackbar = () => this.setState({ snackbar: { ...this.state.snackbar, isOpen: false } });

  render() {
    const loading = false;
    const { snackbar } = this.state;
    return loading ? (
      <div
        style={{
          display: 'flex',
          alignItem: 'center',
          justifyContent: 'center',
          marginTop: '100px',
        }}
      >
        <LoadingSpinner height={40} margin={32} />
      </div>
    ) : (
      <>
        <ProjectsAndContractors openSnackbar={this.openSnackbar} />
        <SnackBar closeSnackbar={this.closeSnackbar} {...snackbar} />
      </>
    );
  }
}

export default ProjectsWrapper;
