import { checkPermissions } from 'helpers/checkPermissions';

export const checkAccessRTS = async ({ sheet, user }) => {
  const hasFullAccess = () => checkPermissions('worklogsFullAccess');

  const isForeman = () => sheet.workers.some((worker) => worker._id === user._id && worker.foreman);
  const canEditSubmitted = () => checkPermissions('worklogsEditSubmitted');

  const isOwner = () => sheet.owner === user._id;
  const isAssignedWorker = () => sheet.workers.some((worker) => worker._id === user._id);

  const hasScheduleFullAccess = () => checkPermissions('scheduleFullAccess');
  const canEditSchedule = () => checkPermissions('scheduleEdit');

  return (
    (await hasFullAccess()) ||
    (sheet.submittedAt
      ? isForeman() || (await canEditSubmitted())
      : isOwner() ||
        isAssignedWorker() ||
        (await hasScheduleFullAccess()) ||
        (await canEditSchedule()))
  );
};
