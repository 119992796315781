import React from 'react';
import propTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconSunny from 'assets/images/icon-sunny.svg';
import IconMoon from 'assets/images/icon-moon.svg';
import PageLoader from '../../../../../../../../components/PageLoader';
import styles from './styles';
import { isIOS, Option } from '../../../../../../../../components/Option';
import { reasons } from '../../../../../../../../components/Kiosk/components/AddTimeOff/helpers/helpers';
import { createOptions } from '../../../../../../../../helpers/createOptions';
import { defaultPeopleCalendarFilters } from '../../PeopleCalendar';

const validation = (values) => {
  const errors = {};
  if (values.userRole.length === 0) {
    errors.userRole = "This field can't be blank";
  }
  return errors;
};

const SortingAndFilteringPanel = ({ classes, closeDrawer, filter, updateFilter, isLoading }) => {
  const [userRole, setUserTypes] = React.useState(filter.userRole);

  const defaultValues = {
    sortBy: 'username',
    sortOrder: 'asc',
    timeOfDay: 'All',
    weekDays: 'All',
    userRole: [
      'Admin',
      'Field Technician',
      'Dispatcher',
      'Accounting',
      'Fleet Maintenance',
      'Project Management',
      'Machine Shop',
    ],
  };

  const handleChangeMultipleSelect = (setFieldValue) => (e) => {
    const clickedOption = e.currentTarget.dataset.value;
    const newValue = e.target.value;
    if (clickedOption === 'All') {
      if (newValue.length === 8) {
        setUserTypes([]);
        setFieldValue('userRole', []);
      } else {
        setUserTypes(defaultValues.userRole);
        setFieldValue('userRole', defaultValues.userRole);
      }
    } else {
      setUserTypes(newValue);
      setFieldValue('userRole', newValue);
    }
  };

  return (
    <div className={classes.formWrapper} role="presentation">
      <Formik
        validation={validation}
        validateOnChange={false}
        validateOnBlur={true}
        initialValues={filter}
        enableReinitialize={true}
        onSubmit={(values) => {
          updateFilter(values);
        }}
      >
        {({ values, errors, touched, handleChange, setFieldValue, resetForm }) => (
          <PageLoader loading={isLoading}>
            <Form autoComplete="off" className={classes.formFlex}>
              <div>
                <Typography variant="h3" className={classes.marginHeader}>
                  Filters
                </Typography>

                <IconButton
                  className={classes.closeButton}
                  onClick={closeDrawer}
                  aria-label="Close"
                  disableTouchRipple
                >
                  <CloseIcon />
                </IconButton>

                <Grid container spacing={2} className={classes.flexContainer}>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="reason" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Time off type:
                      </Typography>
                      <TextField
                        select
                        id="reason"
                        name="reason"
                        value={values.reason}
                        onChange={handleChange}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Sort by' }}
                        fullWidth
                        SelectProps={{
                          native: isIOS,
                        }}
                      >
                        <Option key={1} value="All">
                          All
                        </Option>
                        {reasons.map(createOptions)}
                      </TextField>
                    </label>
                  </Grid>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="isPTO" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        PTO:
                      </Typography>
                      <TextField
                        select
                        id="isPTO"
                        name="isPTO"
                        value={values.isPTO}
                        onChange={handleChange}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Days' }}
                        fullWidth
                        SelectProps={{
                          native: isIOS,
                        }}
                      >
                        <Option key={1} value="All">
                          All
                        </Option>
                        <Option key={2} value={true}>
                          Paid
                        </Option>
                        <Option key={3} value={false}>
                          Unpaid
                        </Option>
                      </TextField>
                    </label>
                  </Grid>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="userShift" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Shift:
                      </Typography>
                      <TextField
                        select
                        id="userShift"
                        name="userShift"
                        value={values.userShift}
                        onChange={handleChange}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Shift' }}
                        fullWidth
                        SelectProps={{
                          native: isIOS,
                        }}
                      >
                        <Option key={1} value="All">
                          All
                        </Option>
                        <Option key={2} value="Day">
                          <img src={IconSunny} alt="sunny" className={classes.shiftImage} />
                          Day
                        </Option>
                        <Option key={3} value="Night">
                          <img
                            src={IconMoon}
                            alt="moon"
                            className={classes.shiftImage}
                          />
                          Night
                        </Option>
                        <Option key={4} value="Inactive">
                          Inactive
                        </Option>
                      </TextField>
                    </label>
                  </Grid>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="userRole" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        User types
                        <span className={classes.requiredSign}>*</span>:
                      </Typography>
                      <TextField
                        select
                        id="userRole"
                        name="userRole"
                        value={userRole}
                        onChange={handleChangeMultipleSelect(setFieldValue)}
                        error={Boolean(errors.userRole) && Boolean(touched.userRole)}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'User type' }}
                        SelectProps={{
                          multiple: true,
                          renderValue: (selected) =>
                            selected.length === 7 ? 'All' : selected.join(', '),
                          native: isIOS,
                        }}
                        fullWidth
                      >
                        <Option
                          key={1}
                          value="All"
                          className={userRole.length === 7 ? classes.customSelectedStyle : ''}
                          disableTouchRipple
                        >
                          All
                        </Option>
                        <Option key={2} value="Admin" disableTouchRipple>
                          Admin
                        </Option>
                        <Option key={3} value="Field Technician" disableTouchRipple>
                          Field Technician
                        </Option>
                        <Option key={4} value="Dispatcher" disableTouchRipple>
                          Dispatcher
                        </Option>
                        <Option key={5} value="Accounting" disableTouchRipple>
                          Accounting
                        </Option>
                        <Option key={6} value="Fleet Maintenance" disableTouchRipple>
                          Fleet Maintenance
                        </Option>
                        <Option key={7} value="Project Management" disableTouchRipple>
                          Project Management
                        </Option>
                        <Option key={8} value="Machine Shop" disableTouchRipple>
                          Machine Shop
                        </Option>
                      </TextField>
                    </label>
                    {errors.userRole && touched.userRole ? (
                      <div className={classes.error}>{errors.userRole}</div>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="type" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Duration:
                      </Typography>
                      <TextField
                        select
                        id="type"
                        name="type"
                        value={values.type}
                        onChange={handleChange}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Days' }}
                        fullWidth
                        SelectProps={{
                          native: isIOS,
                        }}
                      >
                        <Option key={1} value="All">
                          All
                        </Option>
                        <Option key={2} value="full">
                          Full
                        </Option>
                        <Option key={3} value="partial">
                          Partial
                        </Option>
                      </TextField>
                    </label>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                <Button
                  color="primary"
                  className={classes.resetButton}
                  onClick={() => {
                    resetForm({ values: defaultPeopleCalendarFilters });
                    setUserTypes(defaultValues.userRole);
                    setFieldValue('userRole', defaultValues.userRole);
                  }}
                  disableTouchRipple
                >
                  Reset all filters
                </Button>
              </Grid>
              </div>

              <div className={classes.buttonHolder}>
                <Button color="secondary" className={classes.cancelButton} onClick={closeDrawer}>
                  Cancel
                </Button>

                <div className={classes.verticalDivider}></div>

                <Button type="submit" color="primary" className={classes.saveButton}>
                  Apply
                </Button>
              </div>
            </Form>
          </PageLoader>
        )}
      </Formik>
    </div>
  );
};

SortingAndFilteringPanel.propTypes = {
  classes: propTypes.object.isRequired,
  closeDrawer: propTypes.func.isRequired,
  filter: propTypes.object.isRequired,
  updateFilter: propTypes.func.isRequired,
  isLoading: propTypes.bool.isRequired,
};

export default withStyles(styles)(SortingAndFilteringPanel);
