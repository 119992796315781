import $api from 'http/index';

export const fetchHours = async (token) => {
  try {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        'x-kiosk-hours-report-token': token,
      },
    };

    const res = await $api.get(`${process.env.REACT_APP_BASE_URL}/kiosk/hours-report`, options);
    return res.data;
  } catch (error) {
    console.log(error, 'Hours error');
  }
};
