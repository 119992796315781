import React from 'react';
import SearchInput from './../SearchInput';

const ConfigItemPicker = ({
  onChange,
  options,
  placeholder,
  inputId,
  getOptionLabel,
  getOptionKey,
}) => {

  return (
    <SearchInput
      placeholder={placeholder}
      onChange={onChange}
      options={options}
      inputId={inputId}
      getOptionLabel={getOptionLabel}
      getOptionKey={getOptionKey}
    />
  );
};

export default ConfigItemPicker;
