export default theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      width: 550
    }
  },
  textField: {
    [theme.breakpoints.up('lg')]: {
      // maxWidth: 250,
    },
  },
  marginVertical: {
    margin: '20px 0',
  },
  shiftImage: {
    height: 16,
    margin: '0 5px 2px 0',
  },
  passwordToggler: {
    width: 50,
    textAlign: 'center',
    color: theme.palette.textColor.primary,
    cursor: 'pointer'
  },
  error: {
    color: theme.palette.textColor.red,
    padding: '0 5px',
    fontSize: 11
  },
  uplWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& p':{
      marginBottom: 0,
      color: '#5A87EF',
    }
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: '50%',
    '& p':{
      marginBottom: 0,
    }
  },
  imagePreview: {
    width: 150,
    height: 150,
    objectFit: 'contain'
  },
  media: {
    width: 150,
    height: 150,
    objectFit: 'contain'
  },
  fragment: {
    display: 'flex',
    alignItems: 'flex-start',
    margin: 5,
  },
  deleteButton: {
    color: theme.palette.error.red,
    verticalAlign: 'top',
    minWidth: 20,
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff !important',
      color: `${theme.palette.error.red} !important`,
    }
  },
  imagesWrapper: {
    display: 'flex',
    paddingTop: '0 !important',
    flexWrap: 'wrap',
    '& > div': {
      width: 190
    }
  },
  userPhotoWrapper: {
    borderRadius: '50%',
    border: `1px solid ${theme.palette.primary.blue}`,
    borderTopColor: 'transparent',
    borderBottomColor: 'transparent',
    width: 32,
    height: 32,
    transform: 'rotate(135deg)',
  },
  userPhoto: {
    display: 'block',
    transform: 'rotate(-135deg)',
    height: 26,
    margin: '2px auto 0'
  },
  flexBox: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 20,
    '& > *': {
      marginRight: 20,
      [theme.breakpoints.down('sm')]: {
        marginRight: 8,
      },
    }
  },
  flexButt: {
    paddingTop: 22,
    textAlign: 'right',
    marginRight: 10,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      '& button': {
        paddingRight: 0,
        paddingLeft: 0
      }
    }
  },
  textArea: {
    width: '98%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    '& textarea': {
      boxShadow: 'none',
      padding: 10,
      fontSize: 14,
      height: '60px !important',
      maxHeight: '60px !important'
    }
  },
  uplWrapper: {
    display: 'inline-flex',
    alignItems: 'center',
    '& p':{
      marginBottom: 0,
      color: '#5A87EF',
    },
    '& img': {
      marginRight: '5px'
    },
  },
  checkboxWrapper: {
    display: 'inline-flex',
    alignItems: 'center',
    paddingRight: 10,
    '& > span': {
      padding: '0 10px 0 0',
      color: `${theme.palette.error.red} !important`,
      '&:hover': {
        backgroundColor: '#fff important'
      }
    },
    '& > p': {
      color: theme.palette.error.red
    }
  },
  zeroPaddingTop: {
    paddingTop: '0px !important'
  },
  restoreIcon: {
    '&:hover': {
      background: '#fff !important',
    },
    color: theme.palette.primary.blue
  }
});
