export default (theme) => ({
  dateSelector: {
    margin: 0,

    '& .MuiSelect-root': {
      color: '#777',
      fontWeight: 500,
      fontSize: 14,
      width: 60,
      padding: 10,
    },
    '& .MuiSelect-icon': {
      right: 4,
    },
  },
});
