export default theme => ({
  root: {
    padding: '20px 0 0',
    margin: '0 auto'
  },
  color: {
    color: '#60615b'
  },
  header: {
    display: 'block !important',
    textAlign: 'center',
    'h1&': {
      fontSize: '2.75rem',
    },
    'h2&': {
      fontSize: '2.3125rem',
    },
  },
  resourceHolder: {
    paddingLeft: 20
  },
  dayHeader: {
    paddingTop: 20
  },
  column: {
    position: 'relative',
    paddingLeft: '0.9375rem',
    paddingRight: '0.9375rem',
    float: 'left',
    width: '100%'
  },
  row: {
    margin: '0 auto',
    maxWidth: '62.5rem',
    width: '100%',
    '&:before': {
      content: '" "',
      display: 'table'
    },
    '&:after': {
      content: '" "',
      display: 'table',
      clear: 'both'
    }
  },
  large12: {
    width: '100%'
  }
});
