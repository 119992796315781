import { createReducer } from '@reduxjs/toolkit';
import notesPageActions from './notesPageActions';

const initialState = {
  notes: [],
  projects: [],
  allProjects: [],
  project: {},
  filter: {
    sortBy: 'date',
    sortOrder: 'asc',
  },
  projectId: null,
  searchValue: '',
};

const notes = createReducer(initialState, {
  [notesPageActions.setNotes]: (state, { payload }) => {
    return {
      ...state,
      notes: payload,
    };
  },

  [notesPageActions.setProjectNotes]: (state, { payload }) => {
    return {
      ...state,
      projects: payload,
    };
  },
  [notesPageActions.setAllProjectsNotes]: (state, { payload }) => {
    return {
      ...state,
      allProjects: payload,
    };
  },
  [notesPageActions.setSelctedByIdProjectNotes]: (state, { payload }) => {
    return {
      ...state,
      project: payload,
    };
  },
  [notesPageActions.setNotesFilter]: (state, { payload }) => {
    return {
      ...state,
      filter: payload,
    };
  },
  [notesPageActions.setNotesProjectId]: (state, { payload }) => {
    return {
      ...state,
      projectId: payload,
    };
  },
  [notesPageActions.setNotesSearchValue]: (state, { payload }) => {
    return {
      ...state,
      searchValue: payload,
    };
  },
});

export default notes;
