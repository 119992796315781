export default theme => ({
  search: {
    width: 'calc(100% - 16px)',
    '& > div': {
      border: 'none'
    },
    '& input': {
      padding: 6,
      height: 31
    }
  },
  searchIcon: {
    color: theme.palette.textColor.secondary,
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 8
  }
});
