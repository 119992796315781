import { withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core/index';
export const styles = (theme) => ({
  root: {
    marginBottom: 8,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  mainWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      marginBottom: 20,
      width: 552,
    },
    '@media (max-width: 680px)': {
      width: 400,
    },
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
    '@media print': {
      flexDirection: 'row',
      marginBottom: 15,
      width: 'auto',
    }
  },
  timeSection: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '75%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: 20,
    },
    '@media print': {
      justifyContent: 'space-between',
      width: '75%',
      marginBottom: 0,
    }
  },
  timeSectionHours: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {},
  },
  timeSectionHoursMobile: {
    width: '150px',
    justifyContent: 'flex-end',
    '@media (max-width: 999px)': {
      justifyContent: 'flex-start',
    },
    '@media (max-width: 530px)': {
      justifyContent: 'center',
    },
    '@media print': {
      width: '150px',
      justifyContent: 'flex-end',
    }
  },
  timeSectionTimeWord: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'inline-block',
      marginLeft: 10,
      color: '#202020',
      fontSize: 16,
      textAlign: 'right',
      fontWeight: 500,
      letterSpacing: 0.25,
    },
    '@media print': {
      display: 'none',
    }
  },
  timeSectionItem: {
    flexBasis: '33.3333%',
    maxWidth: '33.3333%',
    '@media (max-width: 999px)': {
      flexBasis: 'auto',
      maxWidth: 'none',
      width: 100,
    },
    '@media print': {
      flexBasis: '33.3333%',
      maxWidth: '33.3333%',
      width: 'auto',
    }
  },
  timeSectionItemLunch: {
    minWidth: '130px',
    margin: 0,
    lineHeight: '20px',
    [theme.breakpoints.down('sm')]: {},
  },
});

export const Root = withStyles({
  root: {
    marginBottom: 8,
    '&:last-child': {
      marginBottom: 0,
    },
  },
})(Box);
