const palette = {
  background: {
    default: '#fff',
    lightBlue: '#dde7fe',
    blue: '#5386F7',
    lightGrey: '#f4f4f4',
    lightGrey2: '#f5f5f8',
    lightRed: '#f8dfdf',
    indicatorColor: '#3e82ff',
    dayShift: '#fefff1',
    nightShift: '#f1f4fd',
    green: '#388e3c',
    greenBright: '#509804',
    lightGreen: '#f2fbea',
    dark: '#333',
    transparent: '#ffffff00',
  },
  error: {
    main: '#ce0d00',
    // red: '#de6464',
    red: '#dd6060',
  },
  primary: {
    // main: '#008cba',
    // main: '#42a5f5',
    main: '#5a87ef',
    blue: '#5a87ef',
  },
  secondary: {
    // main: '#c7c7c7',
    main: '#a4a5b3',
  },
  borderColor: {
    // primary: '#c7c7c7',
    primary: '#d9dae3',
    secondary: '#a5a4b7',
    grey: '#a4a5b3',
    red: '#dd6060',
    blue: '#5a87ef',
    white: '#fff',
    lightGrey: '#d2d2db',
    dark: '#202020',
  },
  textColor: {
    // secondary: '#9d9d9d',
    secondary: '#a4a5b3',
    primary: '#5a87ef',
    headerLinkColor: '#3e82ff',
    // red: '#de6464',
    red: '#dd6060',
    dark: '#202020',
    blue: '#5a87ef',
    grey: '#a4a5b3',
    yellow: '#dfa400',
    yellow2: '#f4b84a',
    darkBlue: '#005888',
    white: '#fff',
    green: '#4e9800',
    lightBlue: 'rgba(90, 135, 239, 0.1)'
  },
};

export default palette;
