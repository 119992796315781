import React, { useCallback } from 'react';
import { TextField, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

import { useTimeStates } from 'hooks/useTimeStates';
import { isIOS } from 'components/Option';
import DatePickerInput from 'components/DatePickerInput';
import styles from './styles';
import { createOptions } from 'helpers/createOptions';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';
import { validateEndAfterStart } from 'components/Kiosk/helpers/validate';
import { DAY_PART } from 'common/dateFormatConfig';

const useStyles = makeStyles(styles);

const TimeForm = ({ selectedTime, setSelectedTime, openCalendar, formValues, errors }) => {
  const { hours, minutes, dayPart } = useTimeStates({ takeSettingsHours: true });

  const getTimeFormatHook = useTimeSettingsFormat();
  const classes = useStyles();
  const labels = [
    ['Start Date:', 'Start Time:'],
    ['End Date:', 'End Time:'],
  ];
  const handleTimeChange = (index, subField) => (e) => {
    e.persist();
    setSelectedTime((prev) =>
      prev.map((value, i) => {
        if (i === index) {
          const nextTime = { ...value.time, [subField]: e.target.value };
          
          if (subField === 'hour' && !getTimeFormatHook().is12Format) {
            nextTime[subField] = `${(+e.target.value % 12) || 12}`;
            nextTime['amPm'] = ((+e.target.value) >= 12) ? DAY_PART[1] : DAY_PART[0];
          }

          return { ...value, time: nextTime };
        }
        return value;
      })
    );
  };

  const fields =
    formValues.holidayType === 'period' ? selectedTime : [{ date: selectedTime[0].date }];

  const getTimeHour = useCallback((time) => {
    return getTimeFormatHook().is12Format
      ? time.hour
      : ((+time.hour % 12) + (time.amPm === DAY_PART[1] ? 12 : 0));
  }, [getTimeFormatHook])

  return (
    <Grid container>
      {fields.map(({ date, time }, i) => (
        <React.Fragment key={i}>
          <Grid item xs={6} className={classes.rootContainer}>
            <label htmlFor={`date-${i}`} className={classes.label}>
              <Typography variant="body1" color="textSecondary">
                {labels[i][0]}
              </Typography>
              <div
                className={classnames(classes.wrapper, classes.dateContainer)}
                onClick={openCalendar(i)}
              >
                <DatePickerInput
                  selectedDay={date}
                  setFieldValue={() => null}
                  field="startDate"
                  // disabled
                  sm
                  fullwidth
                />
              </div>
            </label>
          </Grid>
          {time && (
            <Grid item xs={6} className={classes.rootContainer}>
              <label htmlFor={`hour-${i}`} className={classes.label}>
                <Typography variant="body1" color="textSecondary">
                  {labels[i][1]}
                </Typography>
                <div className={classes.wrapper}>
                  <TextField
                    select
                    id={`hour-${i}`}
                    variant="outlined"
                    value={getTimeHour(time)}
                    onChange={handleTimeChange(i, 'hour')}
                    error={!!errors?.startHours}
                    SelectProps={{
                      native: isIOS,
                    }}
                  >
                    {hours.map(createOptions)}
                  </TextField>
                  <Typography variant="body1" className={classes.delimiter}>
                    :
                  </Typography>
                  <TextField
                    select
                    variant="outlined"
                    value={time.minute}
                    error={!!errors?.startHours}
                    onChange={handleTimeChange(i, 'minute')}
                    SelectProps={{
                      native: isIOS,
                    }}
                  >
                    {minutes.map(createOptions)}
                  </TextField>
                  {getTimeFormatHook().is12Format && (
                    <TextField
                      select
                      variant="outlined"
                      value={time.amPm}
                      error={!!errors?.startHours}
                      onChange={handleTimeChange(i, 'amPm')}
                      SelectProps={{
                        native: isIOS,
                      }}
                    >
                      {dayPart.map(createOptions)}
                    </TextField>
                  )}
                </div>
              </label>
            </Grid>
          )}
        </React.Fragment>
      ))}
      <Grid style={{ padding: '0px 4px 10px' }} xs={12}>
        {errors?.startHours ? (
          <div className={classes.error}>{errors.startHours?.message}</div>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default TimeForm;
