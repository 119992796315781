import { getOptions } from "helpers/getOptions"
import $api from "http/index"

export const removeCalendarEvent = async (eventId) => {
  return $api.delete(
    `${process.env.REACT_APP_BASE_URL}/calendar-events/${eventId}`,
    getOptions(),
  );
}

export const updateCalendarEvent = async (eventId, updates) => {
  return $api.put(
    `${process.env.REACT_APP_BASE_URL}/calendar-events/${eventId}`,
    updates,
    getOptions(),
  );
}

export const splitCalendarEvent = async (eventId, data) => {
  return $api.post(
    `${process.env.REACT_APP_BASE_URL}/calendar-events/${eventId}/split`,
    data,
    getOptions(),
  );
}