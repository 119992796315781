export const CALENDAR_EVENTS_EVENT = {
  namespace: 'calendar-events',
  created: 'calendar-events:created',
  deleted: 'calendar-events:deleted',
};

export const SHEET_EVENT = {
  namespace: 'sheets',
  created: 'sheets:created',
  updated: 'sheets:updated',
  resourcesUpdated: 'sheets:resources-updated',
  notes: 'sheets:notes',
  noteCommentAdded: 'sheets:note-comment-added',
  noteCommentUpdated: 'sheets:note-comment-updated',
  noteCommentDeleted: 'sheets:note-comment-deleted',
  weather: 'sheets:weather',
  materialLocation: 'sheets:material-location',
  updatedBunch: 'sheets:updated-bunch',
  returnToShopChanged: 'sheets:return-to-shop-changed',
  updatedCrewLeader: 'sheets:updated-crew-leader',
  deleted: 'sheets:deleted',
  submissionDeleted: 'sheets:submission-deleted',
  canceled: 'sheets:canceled',
  uncanceled: 'sheets:uncanceled',
  reconciledBunch: 'sheets:reconciled-bunch',
  unpublished: 'sheets:unpublished',
  publishedBunch: 'sheets:publish-changed',
  gridPositionChanged: 'sheets:grid-position-changed',
  submitted: 'sheets:submitted',
  myScheduleAdded: 'sheets:my-schedule:{userId}:added',
  sheetsImageAdded: 'sheets:image-added',
  sheetsImageDeleted: 'sheets:image-deleted',
};

export const SHEET_MATERIALS_EVENT = {
  namespace: 'sheet-materials',
  created: 'sheet-materials:created',
  updated: 'sheet-materials:updated',
  deleted: 'sheet-materials:deleted',
};

export const SHEET_SURFACES_EVENT = {
  namespace: 'surfaces',
  created: 'surfaces:created',
  updated: 'surfaces:updated',
  deleted: 'surfaces:deleted',
};

export const EQUIPMENT_EVENT = {
  namespace: 'equipment',
  created: 'equipment:created',
  updated: 'equipment:updated',
  deleted: 'equipment:deleted',
};

export const WORKER_EVENT = {
  namespace: 'users',
  created: 'users:created',
  shiftChanged: 'users:shift-changed',
  activeScheduleChanged: 'users:active-schedule-changed',
  usernameChanged: 'users:username-changed',
  deleted: 'users:deleted',
};

export const TIMEOFFS_EVENT = {
  namespace: 'timeoffs',
  created: 'timeoffs:created',
  updated: 'timeoffs:updated',
  updatedStatus: 'timeoffs:updated-status',
  deleted: 'timeoffs:deleted',
};

export const HOLIDAYS_EVENT = {
  namespace: 'holidays',
  created: 'holidays:created',
  paidUpdated: 'holidays:paid-updated',
  paidDeleted: 'holidays:paid-deleted',
};

export const CONTRACTORS_EVENT = {
  namespace: 'contractors',
  updated: 'contractors:updated',
};

export const PROJECTS_EVENT = {
  namespace: 'projects',
  updated: 'projects:updated',
};

export const SETTINGS_EVENT = {
  namespace: 'settings',
  updated: 'settings:updated',
};

export const KIOSK_EVENT = {
  namespace: 'kiosk',
  usersAdded: 'kiosk:users:added', // arr of users
  usersUpdated: 'kiosk:users:updated',
  usersDeleted: 'kiosk:users:deleted',
};
