import React from 'react';
import cn from 'classnames';
import { oneOf, func, node, bool } from 'prop-types';

import { useStyles } from './styles';
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

export const ArrowIcon = ({ direction, className, onClick, disabled }) => {
  const classes = useStyles();
  return (
    <>
      {direction === 'left' && (
        <ArrowLeftIcon
          className={cn(classes.arrowBtn, { [classes.disabled]: disabled }, className)}
          onClick={disabled ? null : onClick}
        />
      )}
      {direction === 'right' && (
        <ArrowRightIcon
          className={cn(classes.arrowBtn, { [classes.disabled]: disabled }, className)}
          onClick={disabled ? null : onClick}
        />
      )}
    </>
  );
};

ArrowIcon.propTypes = {
  direction: oneOf(['left', 'right']).isRequired,
  className: node,
  onClick: func.isRequired,
  disabled: bool,
};
