import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import NoteDetails from './components/NoteDetails';
import PageLoader from '../PageLoader';
import styles from './styles';
import $api from 'http/index';
import { getOptions } from 'helpers/getOptions';
import { useSelector, useDispatch } from 'react-redux';
import { getProjectNotes } from 'store/notes/notesPageOperations';
import { useParams } from 'react-router-dom';

const validation = (values) => {
  const errors = {};
  if (!values.contractorId.trim()) {
    errors.contractorId = "This field can't be blank";
  }
  if (!values.projectId.trim()) {
    errors.projectId = "This field can't be blank";
  }
  if (!values.text.trim()) {
    errors.text = "This field can't be blank";
  }
  return errors;
};

const NoteCreationForm = ({ classes, closeDrawer, openSnackbar, project, onSuccess }) => {
  const dispatch = useDispatch();
  const params = useParams()
  const { projects: allProjects } = useSelector((store) => store.notes);
  const [projects, setProjects] = useState([]);
  const [contractors, setContractors] = useState([]);
  const [files, setFiles] = useState([]);
  const [filesPreviewSources, setFilesPreviewSources] = useState([]);
  const [selectedContractorId, setSelectedContractorId] = useState(null);

  useEffect(() => {
    const apiCall = async () => {
      try {
        const res = await $api.get(
          `${process.env.REACT_APP_BASE_URL}/contractors?isNotes=true`,
          getOptions()
        );
        setContractors(res.data);
      } catch (error) {}
    };
    apiCall();

    if (project && project.contractorId) {
      setSelectedContractorId(project.contractorId);
    }
  }, [project]);

  useEffect(() => {
    if (!selectedContractorId) {
      setProjects([]);
      return;
    }
    setProjects(allProjects.filter((el) => el.contractorId === selectedContractorId));
  }, [selectedContractorId]);

  const handleChangeContractor = (handleChange) => (e) => {
    setSelectedContractorId(e.target.value);
    handleChange(e);
  };

  const readURL = (files) => {
    if (files.length) {
      const reader = new FileReader();
      reader.onload = (e) => setFilesPreviewSources((prev) => [...prev, e.target.result]);
      reader.readAsDataURL(files[0]);
    }
  };

  const handleFileChange = (e) => {
    const addedFiles = e.target.files;
    readURL(addedFiles);
    setFiles((prev) => [...prev, addedFiles]);
  };

  const deleteImage = (index) => (e) => {
    setFiles((prev) => prev?.filter((el, i) => i !== index));
    setFilesPreviewSources((prev) => prev?.filter((el, i) => i !== index));
  };

  const initialValues = {
    contractorId: project ? project.contractorId : '',
    projectId: project ? project._id : '',
    text: '',
    highPriority: true,
  };

  return (
    <div className={classes.formWrapper} role="presentation">
      <Formik
        validate={validation}
        validateOnChange={false}
        validateOnBlur={true}
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={async (values, { setSubmitting }) => {
          const uploadedFiles = files.map((el) => el[0]);

          const options = {
            headers: {
              'X-Auth-Token': localStorage.getItem('authorization-token'),
              'Content-Type': 'multipart/form-data',
            },
          };

          const requestData = new FormData();
          requestData.append('data', JSON.stringify(values));
          if (files.length) {
            for (let i = 0; i < uploadedFiles.length; i++) {
              requestData.append(`images`, uploadedFiles[i]);
            }
          }
          try {
            const res = await $api.post(
              `${process.env.REACT_APP_BASE_URL}/notes`,
              requestData,
              options
            );
            if(params?.projectId){
              await dispatch(getProjectNotes());
            }
            onSuccess();
            openSnackbar('success', 'The Note was added successfully');
          } catch (e) {}
        }}
      >
        {({
          errors,
          touched,
          values,
          handleChange,
          handleBlur,
          isSubmitting,
          setFieldValue,
          isValidating,
        }) => (
          <PageLoader loading={isSubmitting}>
            <Form autoComplete="off" className={classes.formFlex}>
              <div>
                <Typography variant="h3" style={{ marginBottom: '20px' }}>
                  Create Note
                </Typography>

                <IconButton
                  className={classes.closeButton}
                  onClick={closeDrawer}
                  aria-label="Close"
                  disableTouchRipple
                >
                  <CloseIcon />
                </IconButton>

                <NoteDetails
                  projects={projects}
                  contractors={contractors}
                  filesPreviewSources={filesPreviewSources}
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  handleChangeContractor={handleChangeContractor}
                  handleFileChange={handleFileChange}
                  deleteImage={deleteImage}
                />
              </div>

              <div className={classes.buttonHolder}>
                <Button
                  color="secondary"
                  variant="outlined"
                  className={classes.cancelButton}
                  onClick={closeDrawer}
                  disabled={isSubmitting}
                  disableTouchRipple
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  variant="outlined"
                  className={classes.saveButton}
                  disabled={isSubmitting}
                  disableTouchRipple
                >
                  Save
                </Button>
              </div>
            </Form>
          </PageLoader>
        )}
      </Formik>
    </div>
  );
};

NoteCreationForm.propTypes = {
  classes: propTypes.object.isRequired,
  closeDrawer: propTypes.func.isRequired,
  openSnackbar: propTypes.func.isRequired,
  project: propTypes.object,
};

export default withStyles(styles)(NoteCreationForm);
