export default theme => ({
  noMaterials: {
    textAlign: 'center',
    margin: '20px 0 30px'
  },
  primaryButton: {
    padding: 4,
    minWidth: 'auto',
    color: theme.palette.textColor.primary,
    '&:hover': {
      color: theme.palette.textColor.primary,
      background: theme.palette.background.transparent
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
      background: theme.palette.background.transparent
    }
  }
});
