import { TIMEOFFS_EVENT } from 'subscriptions/events/events.enum';
import { subscriptionsFabric } from 'subscriptions/subscriptions.fabric';
import currentWorklogActions from 'store/currentWorklog/actions';

const handlers = {
  [TIMEOFFS_EVENT.created]: (store) => (data) => {
    const currentTimeOffs = store.getState().currentWorklog.worklogSheet?.timeOffs;
    if (Array.isArray(currentTimeOffs)) {
      store.dispatch(currentWorklogActions.setWorklogTimeOffs([...currentTimeOffs, data]));
    } else {
      store.dispatch(currentWorklogActions.setWorklogTimeOffs(data));
    }
  },
  [TIMEOFFS_EVENT.updated]: (store) => (data) => {
    const currentTimeOffs = store.getState().currentWorklog.worklogSheet?.timeOffs;
    if (Array.isArray(currentTimeOffs)) {
      const updatedTimeoffs = currentTimeOffs.map(el => el._id === data._id ? data : el)
      store.dispatch(currentWorklogActions.setWorklogTimeOffs(updatedTimeoffs));
    } else {
      store.dispatch(currentWorklogActions.setWorklogTimeOffs([data]));
    }
  },
  [TIMEOFFS_EVENT.updatedStatus]: (store) => (data) => {
    const currentTimeOffs = store.getState().currentWorklog.worklogSheet?.timeOffs;
    if (Array.isArray(currentTimeOffs)) {
      const updatedTimeoffs = currentTimeOffs.map(el => el._id === data._id ? {...el, ...data} : el)
      store.dispatch(currentWorklogActions.setWorklogTimeOffs(updatedTimeoffs));
    } else {
      store.dispatch(currentWorklogActions.setWorklogTimeOffs([data]));
    }
  },
  [TIMEOFFS_EVENT.deleted]: (store) => (data) => {
    const currentTimeOffs = store.getState().currentWorklog.worklogSheet?.timeOffs;
    if (Array.isArray(currentTimeOffs)) {
      const updatedTimeoffs = currentTimeOffs.filter(el => el._id !== data._id)
      store.dispatch(currentWorklogActions.setWorklogTimeOffs(updatedTimeoffs));
    }
  },
};

export const currentWorklogTimeoffsSubscriptionMiddleware = subscriptionsFabric(
  currentWorklogActions,
  TIMEOFFS_EVENT,
  handlers
);
