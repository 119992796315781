import authManager from './auth-manager';

/**
 * calls regular fetch inside, but refreshes token on token expiration and sends request again
 * @param {string|URL|Request} - Every url param accepted by ƒetch
 * @param {RequestInit} - Request options accepted by ƒetch
 */
export const $fetch = async (url, options) => {
  const res = await fetch(url, options);
  if (!res.ok && res.status === 401) {
    const tokens = await authManager.refreshToken();

    const authToken = tokens.find(token => token.key === 'authorization-token');
    if (!authToken?.value) throw new Error('Internal error: Token after refreshing was not returned')

    const updatedOptions = { ...options, headers: { ...(options?.headers || {}), 'X-Auth-Token': authToken?.value } };

    // return original request once more
    return fetch(url, updatedOptions);
  }

  return res;
}