import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser } from 'store/auth/authOperations';

export const useCurrentUser = () => {
  const user = useSelector((store) => store.personalProfile.user);

  const dispatch = useDispatch();
  const [flag, setFlag] = useState(0);

  const trigger = () => setFlag((prev) => prev + 1);

  useEffect(() => {
    if (flag === 0 && user) return;
    dispatch(getCurrentUser());
  }, [flag]);

  return { user, trigger };
};
