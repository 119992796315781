import $api from 'http/index';
import { getOptions } from 'helpers/getOptions';

export const LIMIT = 40

export const getNotes = async (filter, page, searchValue ) => {

  const queryParams = new URLSearchParams();
  if (filter) {
    Object.entries(filter).map(([key, value]) => queryParams.append(key, value));
  }
  if (searchValue) {
    queryParams.append('searchValue', searchValue);
  }
  queryParams.append('limit', LIMIT)
  queryParams.append('skip', page * LIMIT)

  const res = await $api.get(
    `${process.env.REACT_APP_BASE_URL}/notes?${queryParams}`,
    getOptions()
  );
  return res.data;
};