export default (theme) => ({
  error: {
    color: theme.palette.textColor.red,
    padding: '0 5px',
    fontSize: 11,
  },
  zeroPaddingTop: {
    paddingTop: '0px !important',
  },
  zeroPaddingTopBottom: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
  },
  flexBox: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > div': {
      minWidth: 70,
    },
  },
  closeButton: {
    padding: 4,
    color: theme.palette.textColor.secondary,
    '&:hover': {
      color: theme.palette.textColor.secondary,
      background: `${theme.palette.background.default} !important`,
    },
    '&:focus': {
      color: theme.palette.textColor.secondary,
      background: `${theme.palette.background.default} !important`,
    },
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& p': {
      marginRight: 15,
    },
  },
  flexBox2: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    '& > label': {
      width: '50%',
      display: 'block',
      '&:nth-child(2)': {
        paddingLeft: 16,
      },
      '& > span:last-child': {
        color: theme.palette.textColor.dark,
      },
    },
  },
  timeSelectWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
    },
  },
  timeSelectFields: {
    display: 'flex',
    alignItems: 'center',
    width: '50%',
    '& > div': {
      width: 70,
    },
  },
});
