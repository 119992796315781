import { createReducer } from '@reduxjs/toolkit';
import cipPageActions from './cipPageActions';

const initialState = {
  loading: false,
  disabled: false,
  hours: {
    data: [],
    query: {
      date: new Date()
    },
    exported: null,
  },
  projects: {
    data: [],
    query: {},
    adding: null,
    editing: null,
    deleting: null,
  },
  activeTab: 0,
  tabs: [
    {
      index: 0,
      name: 'hours',
      label: 'Hours',
      href: '/cip/hours',
    },
    {
      index: 1,
      name: 'projects',
      label: 'Projects',
      href: '/cip/projects',
    },
  ],
};

const cip = createReducer(initialState, {
  [cipPageActions.setDisabled]: (state, { payload }) => ({ ...state, disabled: payload }),
  [cipPageActions.setActiveTab]: (state, { payload }) => ({ ...state, activeTab: payload }),
  [cipPageActions.setLoading]: (state, { payload }) => ({ ...state, loading: payload }),
  [cipPageActions.setHours]: (state, { payload }) => ({ ...state, hours: payload }),
  [cipPageActions.setCipProjects]: (state, { payload }) => ({ ...state, projects: {...state.projects, data: payload}}),
  [cipPageActions.deleteCipProjects]: (state, { payload }) => {
    const updatedData = state.projects.data.filter(el=> el._id !== payload)

    return {
      ...state,
      projects: {
        ...state.projects,
        data: updatedData,
      }
    }
  },
  [cipPageActions.addCipProject]: (state, { payload }) => {

    return {
      ...state,
      projects: {
        ...state.projects,
        data: [...state.projects.data, payload],
      }
    }
  },
  [cipPageActions.toggleActivateCipProjects]: (state, { payload }) => {
    const updatedData = state.projects.data.map(el=>{
      if (el._id === payload){
        return {
          ...el,
          active: !el.active
        }
      }
      return el
    })

    return {
      ...state,
      projects: {
        ...state.projects,
        data: updatedData,
      }
    }
  },
  [cipPageActions.setProjects]: (state, { payload }) => ({ ...state, projects: payload }),
  [cipPageActions.setAddingProject]: (state, { payload }) => ({
    ...state,
    projects: { ...state.projects, adding: payload },
  }),
  [cipPageActions.setEditingProject]: (state, { payload }) => ({
    ...state,
    projects: { ...state.projects, editing: payload },
  }),
  [cipPageActions.reset]: (state, { payload }) => ({ ...initialState }),
});

export default cip;
