import React, { useEffect, useState, memo } from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import _ from 'lodash';
import styles from './styles';
import { drawerTypes } from '../../../../../Equipment/components/helpers/drawerTypes';
import photoPlaceholder from 'assets/images/user-photo-placeholder.svg';
import EditIcon from 'assets/images/edit-icon.svg';
import ArchiveIcon from 'assets/images/archive-icon.svg';
import DoneI from 'assets/images/done.svg';
import $api from 'http/index';
import { getOptions } from 'helpers/getOptions';
import UnarchiveIcon from 'assets/images/unarchive-icon.svg';
import PageLoader from 'components/PageLoader';
import { format } from 'date-fns';
import { GLOBAL_DATE_FORMAT } from 'common/dateFormatConfig';

export const GetImage = ({ img, classes }) => {
  const [src, setSrc] = useState('');

  useEffect(() => {
    const api = async () => {
      try {
        const res = await $api.get(
          `${
            process.env.REACT_APP_BASE_URL
          }/notes/note-image?image=${img}&authToken=${localStorage.getItem('authorization-token')}`,
          { responseType: 'blob' }
        );
        setSrc(URL.createObjectURL(res.data));
      } catch (error) {
        setSrc(null)
      }
    };
    api();
  }, []);

  if(src === ''){
    return (<PageLoader loading={true}>
      <div className={classes.media}></div>
  </PageLoader>)
  }

  return (
    <div className={classes.lol}>
      {
        src ? 
          <CardMedia
            key={src}
            className={classes.media}
            image={src}
            title="Uploaded image"
            onClick={() => {
              var image = new Image();
              image.src = src;
              var w = window.open('');
              w.document.write(image.outerHTML);
            }}
          />
          :
        <div>....</div>
      }
    </div>
  );
};

const ProjectNoteCard = memo(
  ({ classes, note, deleteNote, restoreNote, openDrawer, setNoteIdToResolve }) => {
    const formatDate = (date) =>{
      if (!date) return ''
      return format(new Date(date), GLOBAL_DATE_FORMAT.fullDate)
    };

    const truncateNote = (text) => (text && text.length > 90 ? text.slice(0, 90) + '...' : text);

    const createResolvedInfoString1 = () => {
      const date = formatDate(note.resolved.createdAt || note.createdAt);
      return `Resolved ${date}`;
    };

    const createResolvedInfoString2 = () => {
      const resolvedBy = note?.resolved?.resolvedUser?.profile?.fullName ? note.resolved.resolvedUser.profile.fullName : null;
      return `by ${resolvedBy}`;
    };

    return (
      <Card
        className={classNames(
          note.deletedAt && classes.opacity,
          note.highPriority && classes.redBorder,
          classes.card
        )}
      >
        <Grid container alignItems="center">
          <Grid item xs={8} sm={8} lg={8}>
            <div className={classes.flexBox}>
              <div className={classes.userPhotoWrapper}>
                <img src={photoPlaceholder} alt="user photo" className={classes.userPhoto} />
              </div>
              <Typography variant="body2" color="textPrimary" className={classes.maxWidthUsername}>
                {note.ownerUser && (note.ownerUser.profile.fullName || note.ownerUser.username)}
              </Typography>
            </div>
          </Grid>
          {
            <Grid item xs={4} sm={4} lg={4}>
              <div className={classes.flexButt}>
                {_.isEmpty(note.resolved) ? (
                  <Button
                    color="secondary"
                    className={classes.deleteButton}
                    onClick={openDrawer(drawerTypes.update, true, note)}
                    disableRipple
                  >
                    <img src={EditIcon} alt="edit" />
                  </Button>
                ) : null}
                {note.deletedAt ? (
                  <Button
                    color="secondary"
                    className={classes.deleteButton}
                    onClick={restoreNote(note._id)}
                    disableRipple
                  >
                    <img src={UnarchiveIcon} alt="unarchive" />
                  </Button>
                ) : (
                  <Button
                    color="secondary"
                    className={classes.deleteButton}
                    onClick={deleteNote(note._id)}
                    disableRipple
                  >
                    <img src={ArchiveIcon} alt="archive" />
                  </Button>
                )}
              </div>
            </Grid>
          }
          <Grid item xs={12} sm={12} lg={12}>
            <Typography
              variant="caption"
              component="p"
              color="textSecondary"
              className={classes.marginBottomSmall}
              style={{ fontFamily: 'Open Sans' }}
            >
              {formatDate(note.createdAt)}
            </Typography>
            <div className={classes.paddingBox}>
              <Divider className={classes.divider} />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} lg={12}>
            <Typography
              variant="body1"
              color="textSecondary"
              className={classNames(classes.marginBottomSmall, classes.paddingSmall)}
            >
              Note:
            </Typography>
          </Grid>
        </Grid>
        <CardContent className={classes.mainContent}>
          <Typography
            variant="caption"
            component="p"
            color="textPrimary"
            className={classes.marginBottomNormal}
          >
            {truncateNote(note.text)}
          </Typography>
          <div className={classes.marginBottomNormal}>
            {note.images.map((imageKey) => (
              <GetImage key={imageKey} img={imageKey} classes={classes} />
            ))}
          </div>
          {_.isEmpty(note.resolved) ? (
            <label className={classes.uplWrapper} onClick={setNoteIdToResolve(note._id)}>
              <img src={DoneI} alt="done" />
              <p style={{ fontSize: '0.85rem' }}>Resolve</p>
            </label>
          ) : (
            <>
              <Typography variant="body1" color="textSecondary">
                {createResolvedInfoString1()}
                <br />
                {createResolvedInfoString2()}
              </Typography>
              <Box
                fontStyle="italic"
                m={1}
                style={{ margin: 0, fontSize: 12, padding: '8px 0 10px', fontFamily: 'Open Sans', wordBreak:'break-word' }}
              >
                {note.resolved.comment}
              </Box>
            </>
          )}
        </CardContent>
      </Card>
    );
  }
);

ProjectNoteCard.propTypes = {
  classes: propTypes.object.isRequired,
  note: propTypes.object.isRequired,
  deleteNote: propTypes.func.isRequired,
  restoreNote: propTypes.func.isRequired,
  openDrawer: propTypes.func.isRequired,
  setNoteIdToResolve: propTypes.func.isRequired,
};

export default withStyles(styles)(ProjectNoteCard);
