import React from 'react';
import { Button, CloseButton, Dialog, DialogActions, DialogTitle, DialogContentText } from '../base';

const DeleteModal = ({ title, text, onClose, onSubmit, onCancel, open }) => (
  <Dialog open={open}>
    <CloseButton onClick={onClose} />
    <DialogTitle disableTypography={true}>{title}</DialogTitle>
    <DialogContentText>{text}</DialogContentText>
    <DialogActions disableSpacing={false}>
      <Button variant={'outlined'} color={'secondary'} fullWidth={true} onClick={onSubmit}>
        Yes
      </Button>
      <Button variant={'outlined'} fullWidth={true} onClick={onCancel}>
        No
      </Button>
    </DialogActions>
  </Dialog>
);

export default DeleteModal;
