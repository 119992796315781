import { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import classnames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import styles from './../styles';
import LocationModal from './LocationModal';
import { v4 as uuidv4 } from 'uuid';
import { hasPermissionsFor } from 'helpers/_helpers';

const LocationCreator = ({
  classes,
  setFieldValue,
  values,
  handleLocationEditor,
  setLocations,
  locationEditor,
  type,
  fieldName,
}) => {
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [zipcode, setZip] = useState('');
  const [modal, setModal] = useState(false);

  const addLocationFromEditor = (relatesTo) => {
    const newLocation = {
      name,
      address,
      zipcode,
      relatesTo,
      type,
      new: true,
      _id: uuidv4(),
    };

    setLocations((prev) => [...prev, newLocation]);
    const selectorIndex = locationEditor;

    if (values[fieldName][selectorIndex] === '') {
      const newSheetLocations = values[fieldName].slice();
      newSheetLocations[selectorIndex] = newLocation._id;
      setFieldValue(fieldName, newSheetLocations);
    } else {
      setFieldValue(fieldName, [...values[fieldName], newLocation._id]);
    }
    handleLocationEditor(false);
  };

  const handleModal = (value) => {
    setModal(value);
  };

  const hasAddToProjAccess = () => {
    return hasPermissionsFor('projectsFullAccess') || hasPermissionsFor('projectsEdit');
  };

  const addLocation = () => {
    if (hasAddToProjAccess()) {
      handleModal(true); //open modal to run with the parameters addLocationFromEditor('sheet' | 'project')
      return;
    }
    addLocationFromEditor('sheet');
  };

  const enableAdditionRule = !!name.trim() && !!address.trim();

  return (
    <>
      <Grid container xs={12} lg={12} spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs={10} lg={4}>
          <Typography variant="body1" color="textSecondary">
            Location:
          </Typography>
          <TextField
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            variant="outlined"
            inputProps={{ 'aria-label': 'Name' }}
            placeholder="Nickname"
            fullWidth
          />
        </Grid>
        <Grid item xs={10} lg={4}>
          <Typography variant="body1" color="textSecondary">
            Street:
          </Typography>
          <TextField
            value={address}
            onChange={(e) => {
              setAddress(e.target.value);
            }}
            variant="outlined"
            inputProps={{ 'aria-label': 'Address' }}
            placeholder="Street Address or Lat,Long"
            fullWidth
          />
        </Grid>
        <Grid item xs={10} lg={2}>
          <Typography variant="body1" color="textSecondary">
            Zip Code:
          </Typography>
          <TextField
            value={zipcode}
            onChange={(e) => {
              setZip(e.target.value);
            }}
            variant="outlined"
            inputProps={{ 'aria-label': 'Zip Code' }}
            placeholder="Zip Code"
            fullWidth
          />
        </Grid>
        <Grid item xs={4} lg={1}>
          <IconButton
            className={classnames(classes.addButtonAlign, classes.legendAlign)}
            onClick={() => {
              addLocation();
            }}
            disableRipple
            disabled={!enableAdditionRule}
          >
            Save
          </IconButton>
        </Grid>
        <Grid item xs={4} lg={1}>
          <IconButton
            className={classnames(classes.deleteButtonAlign, classes.legendAlign)}
            onClick={() => handleLocationEditor(false)}
            disableRipple
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <LocationModal
        type={type}
        isOpen={modal}
        handleModal={handleModal}
        addLocationFromEditor={addLocationFromEditor}
      />
    </>
  );
};

export default withStyles(styles)(LocationCreator);
