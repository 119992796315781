export default () => ({
  root: {
    marginBottom: '16px',
    '@global': {
      'div.DayPickerInput': {
        width: '100% !important',
      },
    },
  },
  paddingRight: {
    paddingRight: '8px',
  },
  paddingLeft: {
    paddingLeft: '8px',
  },
  marginBottom: {
    marginBottom: '6px',
  },
});
