import React from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import UploadIcon from 'assets/images/upload-icon.svg'

import IOSSwitch from '../../../IOSSwitch';
import styles from './styles';
import { isIOS, Option } from '../../../Option';

const NoteDetails = ({
  classes,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  projects,
  contractors,
  filesPreviewSources,
  handleChangeContractor,
  handleFileChange,
  deleteImage
}) => {
  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} lg={6}>
          <label htmlFor="contractorId">
            <Typography variant="body1" color="textSecondary">
              Contractor<span className={classes.requiredSign}>*</span>:
            </Typography>
            <TextField
              select
              id="contractorId"
              name="contractorId"
              value={values.contractorId}
              onChange={handleChangeContractor(handleChange)}
              error={Boolean(errors.contractorId) && Boolean(touched.contractorId)}
              onBlur={handleBlur}
              className={classes.textField}
              variant="outlined"
              inputProps={{ 'aria-label': 'Contractor' }}
              fullWidth
              SelectProps={{
                native: isIOS,
              }}
            >
              <Option key={1} value='' />
              {contractors.map(contractor => (
                <Option key={contractor._id} value={contractor._id}>
                  {contractor.name}
                </Option>
              ))}
            </TextField>
            {errors.contractorId && touched.contractorId ? (
              <div className={classes.error}>{errors.contractorId}</div>
            ) : null}
          </label>
        </Grid>
        <Grid item xs={12} lg={6} className={classes.smallScreenNoTopPadding}>
          <label htmlFor="projectId">
            <Typography variant="body1" color="textSecondary">
              Project<span className={classes.requiredSign}>*</span>:
            </Typography>
            <TextField
              select
              id="projectId"
              name="projectId"
              value={values.projectId}
              onChange={handleChange}
              error={Boolean(errors.projectId) && Boolean(touched.projectId)}
              onBlur={handleBlur}
              className={classes.textField}
              variant="outlined"
              inputProps={{ 'aria-label': 'Project' }}
              fullWidth
              SelectProps={{
                native: isIOS,
              }}
            >
              <Option key={1} value='' />
              {projects.map(project => (
                <Option key={project._id} value={project._id}>
                  {project.route + (project.section ? ', Section ' + project.section : '')}
                </Option>
              ))}
            </TextField>
            {errors.projectId && touched.projectId ? (
              <div className={classes.error}>{errors.projectId}</div>
            ) : null}
          </label>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: '0px' }}>
          <label htmlFor="text">
            <Typography variant="body1" color="textSecondary">
              Notes<span className={classes.requiredSign}>*</span>:
            </Typography>
            <TextField
              type="text"
              id="text"
              name="text"
              fullWidth
              className={classNames(classes.textArea, classes.textField)}
              value={values.text}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(errors.text) && Boolean(touched.text)}
              variant="outlined"
              rowsMax={2}
              InputLabelProps={{
                shrink: true,
              }}
              multiline
            />
            {errors.text && touched.text ? (
              <div className={classes.error}>{errors.text}</div>
            ) : null}
          </label>
        </Grid>

      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6} lg={6}>
          <label htmlFor="file" className={classes.buttonsWrapper}>
            <Typography variant="body1" color="textSecondary">
                Image:
            </Typography>
            <label className={classes.uplWrapper}>
              <input
                type="file"
                name="file"
                id="file"
                accept="image/*"
                style={{display: 'none'}}
                onChange={handleFileChange}
              />
              <img src={UploadIcon} alt='upload' style={{marginRight: '10px'}} />
              <p style={{ fontSize: '14px' }}>Upload</p>
            </label>
          </label>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
        <label htmlFor="highPriority" className={classes.buttonsWrapper}>
          <Typography variant="body1" color="textSecondary">
            Priority:
          </Typography>
          <IOSSwitch
            name="highPriority"
            checked={values.highPriority}
            onChange={handleChange}
            value='highPriority'
          />
          </label>
        </Grid>
        <Grid item xs={12} className={classes.imagesWrapper}>
          {filesPreviewSources.map((source, i) => (
            <div key={i}>
              <img src={source} alt="uploaded image" className={classes.imagePreview} />
              <Button
                color="secondary"
                className={classes.deleteButton}
                onClick={deleteImage(i)}
                disableRipple
              >
                <CloseIcon />
              </Button>
            </div>
          ))}
          </Grid>
      </Grid>
    </>
  );
};

NoteDetails.propTypes = {
  classes: propTypes.object.isRequired,
  values: propTypes.object.isRequired,
  errors: propTypes.object.isRequired,
  touched: propTypes.object.isRequired,
  handleChange: propTypes.func.isRequired,
  handleBlur: propTypes.func.isRequired,
  projects: propTypes.array.isRequired,
  contractors: propTypes.array.isRequired,
  filesPreviewSources: propTypes.array.isRequired,
  handleChangeContractor: propTypes.func.isRequired,
  handleFileChange: propTypes.func.isRequired,
  deleteImage: propTypes.func.isRequired
};

export default withStyles(styles)(NoteDetails);
