import React from 'react';
import '../../styles.scss';
import InfoItem from '../InfoItem/InfoItem';
import isCorrectNumber from '../helpers/isCorrectNumber';
import makeArrFromObj from '../helpers/makeArrFromObj';

function ProjectManagementRole({ data }) {
  return (
    <>
      {data && data.projects && (
        <>
          <h2 className="info-block-title">Active projects</h2>
          <div className="cards-wrapper">
            {makeArrFromObj(data.projects).map((e) => (
              <InfoItem key={e.title} quantity={isCorrectNumber(e.total)} info={e.title} />
            ))}
            {/* <InfoItem quantity={isCorrectNumber(data.projects.pennDOT)} info={'PennDot'} />
            <InfoItem quantity={isCorrectNumber(data.projects.private)} info={'Private'} />
            <InfoItem quantity={isCorrectNumber(data.projects.PTC)} info={'Turnpike (PTC)'} />
            <InfoItem quantity={isCorrectNumber(data.projects.other)} info={'Other'} /> */}
          </div>
        </>
      )}
    </>
  );
}

export default ProjectManagementRole;
