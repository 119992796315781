export default theme => ({
  search: {
    width: '100%',
    '& input': {
      padding: 6,
      height: 31
    }
  },
  searchIcon: {
    color: theme.palette.textColor.secondary,
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 8
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  toggleFiltersBtn: {
    height: 35,
    width: '100%',
    paddingTop: 4,
    marginTop: 8,
    marginBottom: 8,
  },
  filtersIcon: {
    marginRight: 10,
  }
});
