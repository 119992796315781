import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import styles from './styles';
import { generateHoursObject } from 'helpers/helpers';
import { addDays, subDays, format } from 'date-fns';
import { connect } from 'react-redux';
import { getTimeFormat, allDatesFormat } from 'common/dateFormatConfig';
import { shortDate } from 'helpers/_date-helpers';

class WorkersSection extends PureComponent {
  state = {
    expanded: false,
  };

  toggleExpand = () => this.setState((prevState) => ({ expanded: !prevState.expanded }));

  formatHours = (hourValueObj) => {
    const { sheet } = this.props;
    const h = hourValueObj;
    const getTimeFormatHook = (hourObj) =>
      getTimeFormat(hourObj, this.props.timeFormat, this.props.dateFormat);

    const { hourStart, minuteStart, amPmStart, dayStart, hourEnd, minuteEnd, amPmEnd, dayEnd } =
      generateHoursObject(h.start, h.end, sheet.createdAt, getTimeFormatHook);

    const startObj = [null, hourStart, minuteStart, amPmStart];
    const endObj = [null, hourEnd, minuteEnd, amPmEnd];

    const start = `${getTimeFormatHook(startObj).formattedTime} ${shortDate(
      sheet.createdAt,
      dayStart,
      getTimeFormatHook().localDayMonth
    )}`;
    const end = `${getTimeFormatHook(endObj).formattedTime} ${shortDate(
      sheet.createdAt,
      dayEnd,
      getTimeFormatHook().localDayMonth
    )}`;

    return `${start} \u2013 ${end}`;
  };

  render() {
    const { classes, sheet } = this.props;
    const { expanded } = this.state;

    return (
      !!(sheet.workers && sheet.workers.length) && (
        <>
          <Grid item xs={12} className={classes.paddingBottomSmall}>
            <Typography
              variant="body1"
              color="textPrimary"
              className={classnames(
                classes.username,
                classes[sheet?.workers[0].shifts.timeOfDay.toLowerCase()],
                classes.paddingBottom
              )}
            >
              {sheet.workers[0].username}
              {sheet.workers[0].foreman && <span className={classes.darkColor}> (CL)</span>}
            </Typography>
            {!sheet.travelTimeOnly && (
              <div>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  className={classnames(classes.regularFontWeight, classes.paddingBottomSmall)}
                >
                  On Job Time:
                </Typography>
                {sheet.workers[0].hours && sheet.workers[0].hours.length ? (
                  sheet.workers[0].hours.map((h) => (
                    <Typography
                      key={h.id}
                      variant="body1"
                      color="textPrimary"
                      className={classes.paddingBottomSmall}
                    >
                      {this.formatHours(h)}
                    </Typography>
                  ))
                ) : (
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    className={classes.paddingBottomSmall}
                  >
                    {this.formatHours(sheet.hours)}
                  </Typography>
                )}
              </div>
            )}
          </Grid>
          {sheet.workers.length > 1 && (
            <Grid item xs={12} className={classes.paddingBottom}>
              <CardActions
                disableSpacing
                onClick={this.toggleExpand}
                className={classes.cardActionsWrapper}
              >
                <Typography className={classnames(classes.primaryColor, classes.mediumFontWeight)}>
                  Other workers
                </Typography>
                <IconButton
                  className={classnames(classes.expand, {
                    [classes.expandOpen]: expanded,
                  })}
                  aria-expanded={expanded}
                  aria-label="Show more"
                  disableRipple
                  disableFocusRipple
                >
                  <ExpandMoreIcon />
                </IconButton>
              </CardActions>
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                {sheet.workers.map((w, i, arr) =>
                  i !== 0 ? (
                    <div key={w._id} className={classes.paddingBottomSmall}>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        className={classnames(
                          classes.username,
                          classes[w.shifts.timeOfDay.toLowerCase()],
                          classes.paddingBottom
                        )}
                      >
                        {w?.username}
                        {w.foreman && <span className={classes.darkColor}> (CL)</span>}
                      </Typography>
                      <div>
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          className={classnames(
                            classes.regularFontWeight,
                            classes.paddingBottomSmall
                          )}
                        >
                          On Job Time:
                        </Typography>
                        {w.hours && w.hours.length ? (
                          w.hours.map((h) => (
                            <Typography
                              key={h.id}
                              variant="body1"
                              color="textPrimary"
                              className={classes.paddingBottomSmall}
                            >
                              {this.formatHours(h)}
                            </Typography>
                          ))
                        ) : (
                          <Typography
                            variant="body1"
                            color="textPrimary"
                            className={classes.paddingBottomSmall}
                          >
                            {this.formatHours(sheet.hours)}
                          </Typography>
                        )}
                      </div>
                      {i + 1 !== arr.length && (
                        <div className={classes.dividerWrapper}>
                          <Divider />
                        </div>
                      )}
                    </div>
                  ) : null
                )}
              </Collapse>
            </Grid>
          )}
        </>
      )
    );
  }
}

WorkersSection.propTypes = {
  classes: propTypes.object.isRequired,
  sheet: propTypes.object.isRequired,
};

export default connect((state) => ({
  dateFormat: state.personalProfile.organization?.settings?.dateFormat,
  timeFormat: state.personalProfile.organization?.settings?.timeFormat,
}))(withStyles(styles)(WorkersSection));
