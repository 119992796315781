import React from 'react';
import { Formik, Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import styles from './styles';
import { validation } from './validation';

const EditEmergencyContactForm = ({ classes, closeDrawer, contact, setFieldValue, index }) => {
  const initialValues = {
    relationship: contact.relationship,
    name: contact.name,
    phoneNumber: contact.phoneNumber,
    address: contact.address,
  };

  return (
    <div className={classes.formWrapper} role="presentation">
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validate={validation}
        onSubmit={(values) => {
          for (const key in values){
            values[key] = values[key].trim()
          }
          setFieldValue(`emergencyContacts[${index}]`, values);
          closeDrawer();
        }}
      >
        {({ values, handleChange, handleBlur, errors, touched }) => (
          <Form autoComplete="off" className={classes.formFlex}>
            <div>
              <Typography variant="h3" className={classes.marginHeader}>
                Edit Emergency Contact
              </Typography>

              <IconButton
                className={classes.closeButton}
                onClick={closeDrawer}
                aria-label="Close"
                disableTouchRipple
              >
                <CloseIcon />
              </IconButton>
              <div className={'emergency-block'}>
                <Grid container spacing={3} alignItems={'center'}>
                  <Grid item xs={12} lg={12}>
                    <label htmlFor={'relationship'}>
                      <Typography variant="body1" color="textSecondary">
                        Relationship <span className={classes.requiredSign}>*</span>:
                      </Typography>
                      <TextField
                        type="text"
                        id={'relationship'}
                        value={values.relationship}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(errors.relationship) && Boolean(touched.relationship)}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Relationship' }}
                        fullWidth
                      />
                      {errors.relationship && touched.relationship ? (
                        <div className={classes.error}>{errors.relationship}</div>
                      ) : null}
                    </label>
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <label>
                      <Typography variant="body1" color="textSecondary">
                        Name <span className={classes.requiredSign}>*</span>:
                      </Typography>
                      <TextField
                        type="text"
                        id={'name'}
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(errors.name) && Boolean(touched.name)}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Name' }}
                        fullWidth
                      />
                      {errors.name && touched.name ? (
                        <div className={classes.error}>{errors.name}</div>
                      ) : null}
                    </label>
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <label>
                      <Typography variant="body1" color="textSecondary">
                        Phone number <span className={classes.requiredSign}>*</span>:
                      </Typography>
                      <TextField
                        type="text"
                        id={'phoneNumber'}
                        value={values.phoneNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(errors.phoneNumber) && Boolean(touched.phoneNumber)}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Phone number' }}
                        fullWidth
                      />
                      {errors.phoneNumber && touched.phoneNumber ? (
                        <div className={classes.error}>{errors.phoneNumber}</div>
                      ) : null}
                    </label>
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <label htmlFor={'address'}>
                      <Typography variant="body1" color="textSecondary">
                        Address <span className={classes.requiredSign}>*</span>:
                      </Typography>
                      <TextField
                        type="text"
                        id={'address'}
                        value={values.address}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(errors.address) && Boolean(touched.address)}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Address' }}
                        fullWidth
                      />
                      {errors.address && touched.address ? (
                        <div className={classes.error}>{errors.address}</div>
                      ) : null}
                    </label>
                  </Grid>
                </Grid>
              </div>
            </div>

            <div className={classes.buttonHolder}>
              <Button color="secondary" className={classes.cancelButton} onClick={closeDrawer}>
                Cancel
              </Button>
              <div className={classes.verticalDivider} />
              <Button type="submit" color="primary" className={classes.saveButton}>
                Save
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default withStyles(styles)(EditEmergencyContactForm);
