export default (theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  leftPart: {
    width: 290,
  },
  rightPart: {
    // width: 271,
    [theme.breakpoints.only('xs')]: {
      paddingTop: 15,
    },
  },
  empty: {
    width: 96,
    height: 1,
    display: 'inline-block',
  },
  headerText: {
    display: 'inline-block',
    verticalAlign: 'middle',
    width: 150,
  },
  printLink: {
    display: 'inline-block',
    textDecoration: 'none !important',
    padding: '7px 8px 0 7px',
    margin: '0 20px 0 40px',
    color: `${theme.palette.textColor.secondary} !important`,
    fontSize: '0.875rem',
    fontWeight: 500,
    height: 26,
    verticalAlign: 'sub',
    [theme.breakpoints.only('xs')]: {
      padding: '2px 0 0',
      marginRight: 15,
    },
    '& img': {
      marginRight: 10,
    },
    '&:hover': {
      color: theme.palette.textColor.secondary,
      backgroundColor: `${theme.palette.background.default} !important`,
    },
    '&:focus': {
      color: theme.palette.textColor.secondary,
      backgroundColor: `${theme.palette.background.default} !important`,
    },
  },
  inactiveBtn: {
    display: 'inline-block',
    textTransform: 'none',
    padding: '4px 10px',
    marginLeft: 50,
    color: theme.palette.textColor.dark,
    border: `1px solid ${theme.palette.borderColor.secondary}`,
    backgroundColor: theme.palette.background.default,
    '&:hover': {
      color: theme.palette.textColor.dark,
      backgroundColor: `${theme.palette.background.default} !important`,
    },
    '&:focus': {
      color: theme.palette.textColor.dark,
      backgroundColor: theme.palette.background.default,
    },
  },
  activeBtn: {
    display: 'inline-block',
    textTransform: 'none',
    padding: '4px 10px',
    marginLeft: 50,
    color: theme.palette.textColor.primary,
    border: `1px solid ${theme.palette.background.default}`,
    backgroundColor: theme.palette.background.lightBlue,
    '&:hover': {
      color: theme.palette.textColor.primary,
      backgroundColor: `${theme.palette.background.lightBlue} !important`,
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
      backgroundColor: `${theme.palette.background.lightBlue} !important`,
    },
  },
});
