import { createAction } from '@reduxjs/toolkit';

const getWorklogMaterialsRequest = createAction('currentWorklog/getWorklogMaterialsRequest');
const getWorklogMaterialsSuccess = createAction('currentWorklog/getWorklogMaterialsSuccess');
const getWorklogMaterialsError = createAction('currentWorklog/getWorklogMaterialsError');

const postWorklogMaterialRequest = createAction('currentWorklog/postWorklogMaterialRequest');
const postWorklogMaterialSuccess = createAction('currentWorklog/postWorklogMaterialSuccess');

const updateWorklogMaterialRequest = createAction('currentWorklog/updateWorklogMaterialRequest');
const updateWorklogMaterialSuccess = createAction('currentWorklog/updateWorklogMaterialSuccess');

const deleteWorklogMaterialRequest = createAction('currentWorklog/deleteWorklogMaterialRequest');
const deleteWorklogMaterialSuccess = createAction('currentWorklog/deleteWorklogMaterialSuccess');

const patchWorklogMaterialRequest = createAction('currentWorklog/patchWorklogMaterialRequest');
const patchWorklogMaterialSuccess = createAction('currentWorklog/patchWorklogMaterialSuccess');
const isPostWorklogMaterialSuccessfully = createAction(
  'currentWorklog/isPostWorklogMaterialSuccessfully'
);

const duplicateWorklogMaterialRequest = createAction(
  'currentWorklog/duplicateWorklogMaterialRequest'
);
const duplicateWorklogMaterialSuccess = createAction(
  'currentWorklog/duplicateWorklogMaterialSuccess'
);

const deleteAllWorklogMaterials = createAction('currentWorklog/deleteAllWorklogMaterials');

const setWorklogSheet = createAction('currentWorklog/setWorklogSheet');
const clearWorklogSheet = createAction('currentWorklog/clearWorklogSheet');
const patchWorklogSheet = createAction('currentWorklog/patchWorklogSheet');
const patchWorklogRTS = createAction('currentWorklog/patchWorklogRTS');
const newNotesComment = createAction('currentWorklog/newNotesComment');
const editNotesComment = createAction('currentWorklog/editNotesComment');
const deleteNotesComment = createAction('currentWorklog/deleteNotesComment');
const toggleCL = createAction('currentWorklog/toggleCL');
const removeWorker = createAction('currentWorklog/removeWorker');
const setWorklogTimeOffs = createAction('currentWorklog/setWorklogTimeOffs');
const updateWorker = createAction('currentWorklog/updateWorker');
const updateEquipment = createAction('currentWorklog/updateEquipment');
const removeEquipment = createAction('currentWorklog/removeEquipment');
const socketConnect = createAction('currentWorklog/socketConnect');
const socketDisconnect = createAction('currentWorklog/socketDisconnect');

const setWorklogSurfaces = createAction('currentWorklog/setWorklogSurfaces');
const addWorklogSurface = createAction('currentWorklog/addWorklogSurface');
const updateWorklogSurface = createAction('currentWorklog/updateWorklogSurface');
const deleteWorklogSurface = createAction('currentWorklog/deleteWorklogSurface');
const setCreatedWlSurface = createAction('currentWorklog/setCreatedWlSurface');

export default {
  getWorklogMaterialsRequest,
  getWorklogMaterialsSuccess,
  getWorklogMaterialsError,

  postWorklogMaterialRequest,
  postWorklogMaterialSuccess,

  updateWorklogMaterialRequest,
  updateWorklogMaterialSuccess,

  deleteWorklogMaterialRequest,
  deleteWorklogMaterialSuccess,

  duplicateWorklogMaterialRequest,
  duplicateWorklogMaterialSuccess,

  patchWorklogMaterialRequest,
  patchWorklogMaterialSuccess,
  deleteAllWorklogMaterials,
  isPostWorklogMaterialSuccessfully,

  //* worklogSheet

  setWorklogSheet,
  clearWorklogSheet,
  patchWorklogSheet,
  patchWorklogRTS,
  newNotesComment,
  editNotesComment,
  deleteNotesComment,
  toggleCL,
  removeWorker,
  setWorklogTimeOffs,
  updateWorker,
  updateEquipment,
  removeEquipment,

  //* worklogSheet sockets

  socketConnect,
  socketDisconnect,

  //* Surfaces

  setWorklogSurfaces,
  addWorklogSurface,
  updateWorklogSurface,
  deleteWorklogSurface,
  setCreatedWlSurface,
};
