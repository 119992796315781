import React, { useEffect, useLayoutEffect } from 'react';
import Header from './components/Header';
import Tabs from './components/Tabs/Tabs';
import useStyles from './useStyles';
import Hours from './components/Hours';
import Projects from './components/Projects';
import { useDispatch, useSelector } from 'react-redux';
import LoadingSpinner from '../../components/LoadingSpinner';
import Typography from '@material-ui/core/Typography';
import cipPageActions from 'store/cip/cipPageActions';
import { SnackbarProvider } from 'notistack';
import { useLocation } from 'react-router-dom';
import store from 'store/store';

export const CIPPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();

  const activeTab = useSelector((state) => state.cip.activeTab);
  const tabs = useSelector((state) => state.cip.tabs);

  const loading = useSelector((state) => state.cip.loading);

  useLayoutEffect(() => {
    if (location.pathname.includes('/cip-projects')) {
      dispatch(cipPageActions.setActiveTab(1));
    }
    if (location.pathname.includes('/cip-hours')) {
      dispatch(cipPageActions.setActiveTab(0));
    }
  }, [location.pathname]);

  useEffect(() => {
    return () => dispatch(cipPageActions.reset());
  }, []);

  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={1500}
    >
      <div className={classes.root}>
        <Header className={classes.header} />
        <Tabs
          className={classes.tabs}
          tabs={tabs}
          value={activeTab}
          onChange={(event, tab) => dispatch(cipPageActions.setActiveTab(tab))}
        />
        {!loading && (
          <>
            {activeTab === 0 && <Hours />}
            {activeTab === 1 && <Projects />}
          </>
        )}
        {loading && (
          <Typography align={'center'}>
            {' '}
            <LoadingSpinner height={40} margin={32} />
          </Typography>
        )}
      </div>
    </SnackbarProvider>
  );
};

export default CIPPage;
