import React, { useEffect, useMemo, useState, useCallback } from 'react';
import propTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import classnames from 'classnames';
import styles from '../../../ResourcesForm/styles';
import { EquipmentSheet } from './EquipmentSheet';
import OverlappingModal from '../../OverlapsingModal';
import SnackBar from 'components/SnackBar';
import { useSelector, useDispatch } from 'react-redux';
import { checkUserEditSheetPermissions, getEquipmentColorClass } from 'helpers/_helpers';
import { putEquipmentWorklogDayHours } from 'store/currentWorklog/operations';
import { removeEquipmentFromWorklog } from 'store/currentWorklog/operations';
import { useWeekStart } from 'hooks/useWeekStart';
import { isValid } from 'date-fns';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';
import { convertTo24HourFormat } from 'common/dateFormatConfig';
import AlertModal from 'components/AlertModal';
import ConfirmDialog from 'components/ConfirmDialog';
import { invalidTimePeriods } from 'components/ResourcesForm/helpers';

const useStyles = makeStyles(styles);

export const ChangeEquipTimeModal = ({ onClose, data, save, moveFrom, isDuplicate, type }) => {
  const classes = useStyles();
  const equipmentList = useSelector((store) => store.schedule.resources.equipment);
  const { weekDayFromO } = useWeekStart();
  const getTimeFormatHook = useTimeSettingsFormat();
  const is12Format = getTimeFormatHook().is12Format;

  //* data.data - mean that data came not from the error
  const dispatch = useDispatch();
  const currSheet = useSelector((store) => store.currentWorklog.worklogSheet.sheet);
  const [sheets, setSheets] = useState(data.data || data.overlapseSheets || []);

  const [timeData, setTimeData] = useState(null);
  const [reset, setReset] = useState(null);
  const [loading, setLoading] = useState(false);
  const [confirmRemove, setConfirmRemove] = useState(null);
  const [infoModal, setInfoModal] = useState({
    isOpen: false,
    text: '',
  });
  const [overlappingModal, setOverlappingModal] = useState(null);
  const timeDataError = useMemo(
    () => timeData && timeData.reduce((res, curr) => res || curr.data.error || curr.data.onJob?.some(h => h.error), false),
    [timeData]
  );

  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    text: '',
    type: 'success',
  });

  const openSnackbar = useCallback((type, text) => setSnackbar({ isOpen: true, type, text }), []);

  const closeSnackbar = useCallback(() => setSnackbar((p) => ({ ...p, isOpen: false })), []);

  useEffect(() => {
    if (!sheets || !sheets.length) onClose();
  }, [sheets]);

  const closeModal = () => setInfoModal({ isOpen: false, text: '' });
  const closeOverlappingModal = () => {
    setOverlappingModal(null);
    onClose();
  };

  const updateTimeData = (sheetId, equipId) => (data) => {
    setTimeData((prev) => {
      if (!prev) return [{ sheetId, equipId, data }];
      const hasChanged = prev.find((sheet) => sheet.sheetId === sheetId);
      return hasChanged
        ? prev.map((sheet) => (sheet.sheetId === sheetId ? { sheetId, equipId, data } : sheet))
        : [...prev, { sheetId, equipId, data }];
    });
  };

  const onSave = async () => {
    if (!timeData || timeDataError) return;

    const sheetsData = data.data ? sheets : sheets.map((sheet) => sheet.sheet);
    const permissionChecks = timeData.map((sheet) => {
      const sheetForCheck = sheetsData.find((el) => el._id === sheet.sheetId);
      return checkUserEditSheetPermissions(sheetForCheck, weekDayFromO);
    });

    const filteredTimeData = timeData.filter((_, i) => permissionChecks[i]);

    const equipmentSheets = filteredTimeData.map((sheet) => {
      const originalSheet = sheetsData.find((el) => el._id === sheet.sheetId);

      const hours = sheet.data.onJob?.length ? sheet.data.onJob : [originalSheet.hours];

      const dataObj = {
        sheetId: sheet.sheetId,
        hours: hours,
      };
      return dataObj;
    });
    const currEquipId = data.data ? data?.data[0]?.equipment?._id : data?.equipment?._id;
    try {
      //|| data.overlapseSheets[0].sheet._id, - on scheduale we don`t know currSheet
      setLoading(true);
      dispatch(
        putEquipmentWorklogDayHours(
          { equipmentSheets, moveFrom },
          currSheet?._id ||
            (data?.data && data?.data[0]?._id) ||
            (data?.overlapseSheets[0] && data?.overlapseSheets[0]?.sheet?._id),
          currEquipId,
          isDuplicate
        )
      );
      setLoading(false);
      save();
      return;
    } catch (e) {
      console.log(e);
      setLoading(false);
      openSnackbar('error', e.response.data.message);

      if (e.response && e.response.data.type === 'sheet-time-overlapse') {
        setOverlappingModal({
          reason: e.response.data.message,
          data: e.response.data.data,
          type: e.response.data.resourceType,
        });
      }
      if (e.response && e.response.data.type === 'timeoff-time-overlapse') {
        setInfoModal({ isOpen: true, text: e.response.data.message });
      }
    }
  };

  const onReset = () => setReset(!reset);

  const showConfirmRemove = (sheetId, equipId) => () => setConfirmRemove({ sheetId, equipId });
  const closeConfirmRemove = () => setConfirmRemove(null);

  const removeEquipment = async () => {
    try {
      dispatch(removeEquipmentFromWorklog(confirmRemove.sheetId, confirmRemove.equipId, data));

      setSheets((prev) => {
        return data.data
          ? prev.filter((sheet) => sheet._id !== confirmRemove.sheetId)
          : prev.filter((sheet) => sheet.sheet._id !== confirmRemove.sheetId);
      });

      setTimeData((prev) => prev.filter((time) => time.sheetId !== confirmRemove.sheetId));
      closeConfirmRemove();
    } catch (e) {
      console.log(e);
    }
  };

  let invalidSheetIndexes = [];
  if (timeData && timeData[0] && timeData[0].data.onJob?.length && timeData[0].data.onJob?.[0]?.start) {
    const data = [];
    timeData.forEach((curr, index) => {
      if (curr.data.onJob?.length) {
        data.push(...curr.data.onJob.map((data) => ({ index, data })));
        return;
      }

      const sheet = sheets.find((sheet) => sheet._id === curr.data.sheetId);
      if (sheet) {
        if (Array.isArray(sheet.overlapseHours)) {
          sheet.overlapseHours.forEach(dataHours => data.push({ index, data: dataHours }))
        } else if (sheet.sheet?.hours?.start) {
          data.push({ index, data: sheet.sheet.hours })
        } else if (sheet.hours?.start) {
          data.push({ index, data: sheet.hours })
        }
      }
    });

    invalidSheetIndexes = invalidTimePeriods(data);
  }

  const getCurrEquipment = () => {
    if (data.data) {
      return (
        equipmentList.find((equipment) => equipment._id === data?.data[0]?.equipment?._id) || data.data?.equipment
      );
    }

    if (data.overlapseSheets) {
      return data.equipment;
    }
  };

  const onJobHours = (sheet) => {
    if (data.data) {
      //if clicked on equip
      const result = sheet.equipment.hours?.length ? sheet.equipment.hours : [sheet.hours];
      result.isWlHours = result.isWlHours || false;
      result.isWlTravelTime = result.isWlTravelTime || false;

      return result;
    } else {
      return sheet.overlapseHours;
    }
  };

  const currEquipment = useMemo(() => {
    return getCurrEquipment();
  }, [equipmentList, data]); //! data can call infinite rerenders

  return (
    <>
      <Dialog
        open
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          classes: {
            root: classes.dialog,
          },
        }}
      >
        <DialogTitle id="alert-dialog-title" disableTypography style={{ paddingBottom: 8 }}>
          <Typography variant="h3">Edit Work Time</Typography>
          <IconButton
            className={classes.closeButton}
            onClick={onClose}
            aria-label="Close"
            style={{ right: 14, top: 14 }}
            disableRipple
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <>
          {!!currEquipment ? (
            <>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <div className={classes.inlineFlex}>
                      <div
                        className={classnames(
                          classes.equipmentBlock,
                          getEquipmentColorClass(currEquipment.color)
                        )}
                        style={{ backgroundColor: currEquipment.color }}
                      >
                        <div className={classes.equipmentNumber}>{currEquipment.name}</div>
                      </div>
                    </div>
                  </Grid>
                  {sheets.map((sheet, i) => (
                    <EquipmentSheet
                      key={data.data ? sheet._id : sheet.sheet._id}
                      data={data.data ? sheet : sheet.sheet}
                      onJobDate={onJobHours(sheet)}
                      equipId={currEquipment._id}
                      update={
                        data.data
                          ? updateTimeData(sheet._id, currEquipment._id)
                          : updateTimeData(sheet.sheet._id, currEquipment._id)
                      }
                      reset={reset}
                      error={invalidSheetIndexes.includes(i)}
                      removeSheet={
                        data.data
                          ? showConfirmRemove(sheet._id, currEquipment._id)
                          : showConfirmRemove(sheet.sheet._id, currEquipment._id)
                      }
                    />
                  ))}
                </Grid>
              </DialogContent>
            </>
          ) : (
            'Incorrect data'
          )}
        </>

        <DialogActions>
          <Button
            onClick={onReset}
            variant="outlined"
            color="secondary"
            className={classes.cancelButton}
            disableTouchRipple
          >
            Reset to Origin
          </Button>
          <Button
            disabled={loading || !timeData || !!timeDataError || !!invalidSheetIndexes.length}
            onClick={onSave}
            variant="outlined"
            color="primary"
            className={classes.saveButton}
            disableTouchRipple
          >
            Save
          </Button>
        </DialogActions>
        <SnackBar closeSnackbar={closeSnackbar} {...snackbar} />
      </Dialog>
      {infoModal.isOpen && (
        <AlertModal isOpen={infoModal.isOpen} info={infoModal.text} onClose={closeModal} />
      )}
      {confirmRemove && (
        <ConfirmDialog
          isOpen
          onSubmit={removeEquipment}
          onClose={closeConfirmRemove}
          text="Are you sure you want to delete this User from the Worklog?"
        />
      )}
      {overlappingModal && (
        <OverlappingModal
          data={overlappingModal.data}
          message={overlappingModal.reason}
          onClose={closeOverlappingModal}
          onSave={isDuplicate ? save : undefined}
          type={type}
        />
      )}
    </>
  );
};

ChangeEquipTimeModal.propTypes = {
  onClose: propTypes.func.isRequired,
  data: propTypes.object.isRequired,
};
