import React, { useEffect, useState, useMemo } from 'react';
import Typography from '@material-ui/core/Typography';

import Grid from '@material-ui/core/Grid';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import { shortDate } from 'helpers/_date-helpers';
import { TimeEquipForm } from './../TimeEquipForm/TimeEquipForm';

import { generateHoursObject } from 'helpers/helpers';
import { checkUserEditSheetPermissions, getProjectName } from 'helpers/_helpers';
import { useWeekStart } from 'hooks/useWeekStart';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';
import { convertTimeObject } from 'common/dateFormatConfig';
import useWorklogStyles from 'components/WorklogDetails/hooks/useWorklogStyles';
import { useSelectedUserClasses } from 'components/ResourcesForm/components/PeopleBlock/components/SelectedUser/useStyles';

export const EquipmentSheet = ({
  data,
  equipId,
  update,
  reset,
  error,
  removeSheet,
  onJobDate,
}) => {
  const { weekDayFromO } = useWeekStart();
  const worklogClasses = useWorklogStyles();
  const selectedUserClasses = useSelectedUserClasses();
  const classes = {};
  const [canEdit, setCanEdit] = useState(false);
  const getTimeFormatHook = useTimeSettingsFormat();
  const is12Format = getTimeFormatHook().is12Format;

  const getFormattedHoursRange = () => {
    const { createdAt, hours } = data;
    if (!hours || !hours?.start || !hours?.end) return;
    const { hourStart, minuteStart, amPmStart, dayStart, hourEnd, minuteEnd, amPmEnd, dayEnd } =
      generateHoursObject(
        hours.start || hours[0].start,
        hours.end || hours[0].end,
        createdAt,
        getTimeFormatHook
      );

    const startObj = [null, hourStart, minuteStart, amPmStart];
    const endObj = [null, hourEnd, minuteEnd, amPmEnd];

    const start = `${getTimeFormatHook(startObj).formattedTime} ${shortDate(
      createdAt,
      dayStart,
      getTimeFormatHook().localDayMonth
    )}`;
    const end = `${getTimeFormatHook(endObj).formattedTime} ${shortDate(
      createdAt,
      dayEnd,
      getTimeFormatHook().localDayMonth
    )}`;
    return `${start} \u2013 ${end}`;
  };

  useEffect(() => {
    const response = checkUserEditSheetPermissions(data, weekDayFromO);
    setCanEdit(response);
  }, [data._id]);

  const { hour, minute, amPm } = convertTimeObject(
    { hour: data.startTime.hour, minute: data.startTime.minute },
    is12Format
  );

  const startTimeInfo = getTimeFormatHook([null, hour, minute, amPm]).formattedTime;

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h4">
          {getProjectName(data.project)}
          {data.project.county ? `, ${data.project.county}` : ''}

          {data.project.jobNumber && (
            <span className={worklogClasses.jobNumber}>#{data.project.jobNumber}</span>
          )}
        </Typography>
      </Grid>
      <Grid container item xs={10} spacing={1}>
        {!data.travelTimeOnly && (
          <Grid item xs={12} md={12}>
            <Typography
              variant="body1"
              color="textSecondary"
              className={classnames(classes.regularFontWeight, classes.paddingBottom)}
            >
              On Job Time:
            </Typography>
            <Typography variant="body1" color="textPrimary">
              {getFormattedHoursRange()}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} md={2}>
          <div className={classnames(classes.header, classes.paddingBottom)}>
            <Typography
              variant="body1"
              color="textSecondary"
              className={classnames(classes.regularFontWeight)}
            >
              Start time:
            </Typography>
          </div>
          <Typography variant="body1" color="textPrimary">
            {startTimeInfo}
          </Typography>
        </Grid>
      </Grid>
      <Grid style={{ textAlign: 'right' }} item xs={12} md={2}>
        {canEdit ? (
          <Button
            variant="text"
            onClick={removeSheet}
            className={selectedUserClasses.removeButton}
            disableRipple
            disableTouchRipple
          >
            Remove
          </Button>
        ) : (
          <Typography variant="body1">
            <strong>CL: </strong> {data.crewLeaders ? data.crewLeaders.join(', ') : 'Unknown'}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12}>
        <TimeEquipForm
          sheet={data}
          equipId={equipId}
          update={update}
          reset={reset}
          sheetError={error}
          onJobDate={onJobDate}
        />
      </Grid>
      {Boolean(error) && (
        <div className={selectedUserClasses.error}>Time periods cross each other</div>
      )}
    </>
  );
};
