import { MEASUREMENT_SYSTEM, MEASUREMENT_UNITS, MEASURE_UNITS_BY_SYSTEM } from "common/measurements";

export const getGlassBeadsTypes = (measurement = MEASUREMENT_SYSTEM.imperial) => ([
  {
    name: MEASURE_UNITS_BY_SYSTEM[measurement][MEASUREMENT_UNITS.weightPerVolume],
    type: MEASUREMENT_UNITS.weightPerVolume,
    materialType: 'volume',
  },
  {
    name: MEASURE_UNITS_BY_SYSTEM[measurement][MEASUREMENT_UNITS.weightPerArea],
    type: MEASUREMENT_UNITS.weightPerArea,
    materialType: 'weight',
  },
]);
