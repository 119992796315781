import { rolePermissions } from './rolePermissions';

export const checkHasUserPermissions = (requestedPermissions, user) => {
  const userRole = user?.profile?.role?.roleName;
  const userCorePermissions = rolePermissions[userRole];
  const userExtPermissions = user?.profile?.role?.extPermissions;

  if (!userRole) {
    return false;
  }

  return requestedPermissions.reduce(
    (hasAccess, permission) => 
    (hasAccess || userCorePermissions?.[permission] || userExtPermissions?.[permission]),
  false);
}