import React, { useEffect } from 'react';
import { useCurrentUser } from 'components/Kiosk/hooks/useCurrentUser';
import Kiosk from 'components/Kiosk';
import { getClockInStatus } from 'store/kiosk/kioskPageOperations';
import { useDispatch } from 'react-redux';
import kioskPageActions from 'store/kiosk/kioskPageActions';
import Box from 'components/Field/Field';

const RemoteKioskPage = () => {
  const { user: currentUser, trigger } = useCurrentUser();
  const dispatch = useDispatch();

  const logoutFromStore = () => dispatch(kioskPageActions.logout());

  useEffect(() => {
    if (!currentUser) return;

    dispatch(getClockInStatus(currentUser._id));
  }, [currentUser]);

  if (!currentUser) return null;

  return (
    <Box style={{maxWidth: 1280, margin: '0 auto', padding: 15, width: '100%' }}>
      <Kiosk
        authorize={trigger}
        logoutFromStore={logoutFromStore}
        isRemoteKiosk
        selectedUser={currentUser}
        currentUser={currentUser}
      />
    </Box>
  );
};

export default RemoteKioskPage;
