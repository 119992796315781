export const generateUpdateBody = (values) => ({
  username: values.name.slice(0, 120),
  profile: {
    fullName: values.fullName.trim().slice(0, 120),
    employeeNum: values.employeeNum.slice(0, 50),
    HICode: values.HICode,
    phoneNumber: values.phoneNumber.trim().slice(0, 50) || undefined,
    email: values.email,
    shifts: {
      timeOfDay: values.timeOfDay,
      weekDays: values.weekDays,
    },
    timeoff: {
      allowedTime: parseInt(`${values.allowedTime}`.slice(0, 5)),
    },
    role: {
      roleName: values.userType,
      extPermissions: values.permissions,
    },
    emergencyContacts: values.emergencyContacts,
  },
});
