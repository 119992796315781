export default (theme) => ({
  root: {
    padding: '10px 15px',
    maxWidth: '1280px',
    margin: '0 auto',
    '& dl, dd': {
      fontFamily: 'Poppins,Open Sans,sans-serif',
    },
  },
  tabs: {
    '& a': {
      minWidth: 110,
      textTransform: 'none',
    },
    '& a:hover': {
      backgroundColor: 'transparent !important',
      color: '#5a87ef'
    },
    '& a.Mui-selected': {
      color: '#5a87ef',
    },
    '& .MuiTabs-indicator': {
      backgroundColor: '#5a87ef'
    },
    '& span': {
      fontSize: '0.875rem',
      fontWeight: 500
    }
  },
  addButton: {
    textTransform: 'none',
    color: '#5A87EF',
    '&:hover': {
      color: '#5A87EF',
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
    '&:focus': {
      color: '#5A87EF',
      backgroundColor: '#fff',
    },
    '&.Mui-disabled': {
      backgroundColor: '#fff',
    }
  }
})
