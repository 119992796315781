import React from 'react';
import { Formik, Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close';
import PageLoader from 'components/PageLoader';
import styles from './styles';
import { isIOS, Option } from 'components/Option/Option';

const validate = (values) => {
  const errors = {};

  if (!values.organizationId) {
    errors.organizationId = 'Choose organization';
  }

  return errors;
}

const AssignToClient = ({ classes, closeDrawer, clients, assignUsers }) => {
  return (
    <div className={classes.formWrapper} role="presentation">
      <Formik
        initialValues={{ organizationId: '', status: 'Active' }}
        enableReinitialize={true}
        validate={validate}
        onSubmit={(values) => {
          assignUsers(values);
        }}
      >
        {({ values, handleChange, errors, touched }) => (
          <PageLoader loading={false}>
            <Form autoComplete="off" className={classes.formFlex}>
              <div>
                <Typography variant="h3" className={classes.marginHeader}>
                  Assign To Company
                </Typography>

                <IconButton
                  className={classes.closeButton}
                  onClick={closeDrawer}
                  aria-label="Close"
                  disableTouchRipple
                >
                  <CloseIcon />
                </IconButton>

                <Grid container spacing={2} className={classes.flexContainer}>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="organizationId" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Company name:
                      </Typography>
                      <TextField
                        id="organizationId"
                        name="organizationId"
                        select
                        value={values.organizationId}
                        onChange={handleChange}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Company' }}
                        fullWidth
                        SelectProps={{
                          native: isIOS,
                        }}
                      >
                        {clients.map((client) => {
                          return (
                            <Option key={client._id} value={client._id}>
                              {client.name}
                            </Option>
                          )
                        })}
                      </TextField>
                      {errors.organizationId && touched.organizationId ? (
                        <span>{errors.organitionId}</span>
                      ) : null}
                    </label>
                  </Grid>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="status" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Status:
                      </Typography>
                      <TextField
                        select
                        id="status"
                        name="status"
                        value={values.status}
                        onChange={handleChange}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Status' }}
                        fullWidth
                        SelectProps={{
                          native: isIOS,
                        }}
                      >
                        <Option key="Active" value="Active">
                          Active
                        </Option>
                        <Option key="Inactive" value="Inactive">
                          Inactive
                        </Option>
                      </TextField>
                    </label>
                  </Grid>
                </Grid>
              </div>
              <div className={classes.buttonHolder}>
                <Button color="secondary" className={classes.cancelButton} onClick={closeDrawer}>
                  Cancel
                </Button>
                <div className={classes.verticalDivider} />
                <Button type="submit" color="primary" className={classes.saveButton}>
                  Save
                </Button>
              </div>
            </Form>
          </PageLoader>
        )}
      </Formik>
    </div>
  );
};

export default withStyles(styles)(AssignToClient);