import React, { useState, useEffect, useMemo } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import classnames from 'classnames';
import { parse, differenceInCalendarDays } from 'date-fns';
import photoPlaceholder from 'assets/images/user-photo-placeholder.svg';
import { getEquipmentColorClass } from '../../../../../../../../helpers/_helpers';
import PageLoader from '../../../../../../../../components/PageLoader';
import styles from './styles';
import { useSelector } from 'react-redux';
import Fuse from 'fuse.js';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';


const ResourcesList = ({
  selectedResources,
  updateResources,
  classes,
  loading,
  isAllSelected,
  updateAllResources,
  type,
  selectedDates,
  searchValue,
  usedResources,
  isChanged,
}) => {
  const { dateFormat, dateFormatWithTime, dayDateSettingsFormat } = useDateSettingsFormat();

  const searchOptions = useMemo(() => ({
    shouldSort: true,
    keys: type === 'people' ? [
      'username',
      'profile.HICode',
      'profile.fullName',
      'profile.phoneNumber',
      'profile.employeeNum',
    ] : ['name', 'number'],
  }), [type]);

  const [fuse, setFuse] = useState(null);
  const {users: userList, equipment: equipmentList} = useSelector(store => store.availability)

  const filterIfAlreadyUsed = (arr) => {
    if (!arr) return null

    const usedId = type == 'people' ? 'userId' : 'resourceId'
    return arr.filter(resource => !(usedResources.some(el => el[usedId] == resource._id)))
  }

  const resourcesData = useMemo(() => type === 'people' ? filterIfAlreadyUsed(userList) : filterIfAlreadyUsed(equipmentList), [type, userList ,equipmentList]);

  useMemo(() => {
    setFuse(new Fuse( resourcesData, searchOptions));
  }, [type]);

  useEffect(() => {
    if(!isChanged) return

    if (isAllSelected) {
      const resources = type === 'people' ? userList : equipmentList;
      const mappedResources = resources.map((el) => ({ id: el._id, dates: selectedDates }));
      updateAllResources(mappedResources);
    } else {
      updateAllResources([]);
    }
  }, [isAllSelected, userList, equipmentList]);

  const getUserShift = (user) => {
    return user.profile.shifts.timeOfDay.toLowerCase();
  };

  const isSelected = (id) => selectedResources.some((el) => el.id === id);

  const isMoreThanOneDaySelected = () => {
    const startDate = parse(selectedDates[0], dateFormat, new Date());
    const endDate = parse(selectedDates[1], dateFormat, new Date());
    return differenceInCalendarDays(endDate, startDate) > 1;
  };

  const createDaysContentText = () => {
    const startDate = parse(selectedDates[0], dateFormat, new Date());
    const endDate = parse(selectedDates[1], dateFormat, new Date());
    const daysCount = differenceInCalendarDays(endDate, startDate);
    if (daysCount === 2) return '2 days';
    if (daysCount >= 3) return '3+ days';
  };

  const searchedUsers = fuse && searchValue ? fuse.search(searchValue) : resourcesData;

  return (
    <div>
      {type === 'people' && !userList?.length && (
        <Typography variant="h6" align="center" style={{ margin: '1.72em 0 2em', opacity: 0.5 }}>
          No Results
        </Typography>
      )}
      {type !== 'people' && !equipmentList?.length && (
        <Typography variant="h6" align="center" style={{ margin: '1.72em 0 2em', opacity: 0.5 }}>
          No Results
        </Typography>
      )}
      <PageLoader loading={loading}>
        <div>
          {type === 'people'
            ? searchedUsers?.map((user) => (
                <div
                  key={user._id}
                  className={classnames(classes.flexBox, isSelected(user._id) && classes.selected)}
                  onClick={updateResources({ id: user._id, dates: selectedDates })}
                >
                  <div className={classes.flexBoxInline}>
                    <div
                      className={classnames(
                        classes.userPhotoWrapper,
                        classes[`${getUserShift(user)}Border`]
                      )}
                    >
                      <img src={photoPlaceholder} alt="user photo" className={classes.userPhoto} />
                    </div>
                    <Typography
                      className={classnames(
                        classes.name,
                        classes[getUserShift(user)],
                        isSelected(user._id) && isMoreThanOneDaySelected() && classes.width1
                      )}
                    >
                      {user.username} ({user.profile.shifts.weekDays})
                    </Typography>
                  </div>

                  <div className={classes.flexBoxInline}>
                    {isSelected(user._id) && isMoreThanOneDaySelected() && (
                      <div className={classes.severalDays}>{createDaysContentText()}</div>
                    )}
                    <Checkbox
                      className={classes.root}
                      disableRipple
                      color="default"
                      checkedIcon={
                        <span className={classnames(classes.icon, classes.checkedIcon)} />
                      }
                      icon={<span className={classes.icon} />}
                      checked={isSelected(user._id)}
                    />
                  </div>
                </div>
              ))
            : searchedUsers.map((equipment) => (
                <div
                  key={equipment._id}
                  className={classnames(
                    classes.flexBox,
                    isSelected(equipment._id) && classes.selected
                  )}
                  onClick={updateResources({ id: equipment._id, dates: selectedDates })}
                >
                  <div
                    className={classnames(
                      classes.equipmentBlock,
                      getEquipmentColorClass(equipment.color)
                    )}
                    style={{ backgroundColor: equipment.color }}
                  >
                    <div className={classes.equipmentNumber}>{equipment.name}</div>
                  </div>

                  <div className={classes.flexBoxInline}>
                    {isSelected(equipment._id) && isMoreThanOneDaySelected() && (
                      <div className={classes.severalDays}>{createDaysContentText()}</div>
                    )}
                    <Checkbox
                      className={classes.root}
                      disableRipple
                      color="default"
                      checkedIcon={
                        <span className={classnames(classes.icon, classes.checkedIcon)} />
                      }
                      icon={<span className={classes.icon} />}
                      checked={isSelected(equipment._id)}
                    />
                  </div>
                </div>
              ))}
        </div>
      </PageLoader>
    </div>
  );
};

ResourcesList.propTypes = {
  classes: propTypes.object.isRequired,
  loading: propTypes.bool.isRequired,
  userList: propTypes.array.isRequired,
  equipmentList: propTypes.array.isRequired,
  type: propTypes.string.isRequired,
  usedResources: propTypes.array.isRequired,
  selectedResources: propTypes.array.isRequired,
  updateResources: propTypes.func.isRequired,
  selectedDates: propTypes.arrayOf(propTypes.string).isRequired,
  isAllSelected: propTypes.bool.isRequired,
  updateAllResources: propTypes.func.isRequired,
};

export default (withStyles(styles)(ResourcesList));
