import classnames from 'classnames';
import styles from './styles';
import { withStyles } from '@material-ui/core/styles';
import { format, parse } from 'date-fns';
import CloseIcon from 'assets/images/close-icon.svg';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import WLAccordionItem from '../WLAccordionItem/WLAccordionItem';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';
import { allDatesFormat } from 'common/dateFormatConfig';

const MonthPreviewModal = ({
  classes,
  data,
  onClose,
  openedWLId,
  openWLAccordion,
  sheetsWithoutForemanIds,
  handleUpdate,
}) => {
const { dateFormat } = useDateSettingsFormat();

  return (
    <div className={classnames(classes.wrapper)}>
      <div className={classnames(classes.header)}>
        <div className={classnames(classes.dateBlock)}>
          <Typography variant="h5" color="textPrimary">
            {format(parse(data.dayNumber, dateFormat, new Date()), allDatesFormat[2])}
          </Typography>
        </div>
        <div className={classnames(classes.closeBlock)}>
          <Button
            color="secondary"
            className={classes.deleteButton}
            onClick={onClose}
            disableRipple
          >
            <img src={CloseIcon} alt="delete" />
          </Button>
        </div>
      </div>
      {!!data.events?.length && (
        <>
          <Typography variant="body1" color="textSecondary" className={classes.subHeader}>
            {data.events?.length} worklogs:
          </Typography>
          <div className={classnames(classes.listOfWl)}>
            {data.events
              ?.slice()
              .sort((a, b) => new Date(b.createdAtDate) - new Date(a.createdAtDate))
              .map((sheet) => (
                <WLAccordionItem
                  key={sheet?._id}
                  sheet={sheet}
                  // openedWLId={openedWLId}
                  sheetsWithoutForemanIds={sheetsWithoutForemanIds}
                  handleUpdate={handleUpdate}
                />
              ))}
          </div>
        </>
      )}
    </div>
  );
};

export default withStyles(styles)(MonthPreviewModal);
