import React, { useState, useEffect, useCallback } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';

import EquipmentHeader from './components/EquipmentHeader';
import EquipmentList from './components/EquipmentList';
import EquipmentForm from './components/EquipmentForm';
import SortingAndFilteringPanel from './components/SortingAndFilteringPanel';
import FiltersBar from '../../components/FiltersBar';
import PageLoader from '../../components/PageLoader';
import styles from './styles';
import { drawerTypes } from './components/helpers/drawerTypes';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEquipmentsData } from 'store/equipment/equipmentPageOperations';

const EquipmentPage = ({ classes, openSnackbar }) => {
  const dispatch = useDispatch()
  const [drawer, setDrawer] = useState(null);
  const [userFilter, setUserFilter] = useState({
    sortBy: 'name',
    sortOrder: 'asc',
  });
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    updateVisibleEquipment(null, null, searchValue);
  }, []);

  const { data, isLoading, hasMore } = useSelector((store) => store.equipments);

  const updateSearchValue = (value) => {
    setSearchValue(value);
    updateVisibleEquipment(userFilter, null , value, true);
  };

  const updateFilter = (filter) => {
    setUserFilter(filter)
    updateVisibleEquipment(filter, null , searchValue, true);
    closeDrawer()
  };


  const updateVisibleEquipment = useCallback( async (filter, page, searchValue, forceUpdate) => {
    setLoading(true)
    const usedFilter = filter || userFilter
    const entriesMultiplier = page || 0;

    await dispatch(fetchEquipmentsData(usedFilter, entriesMultiplier, searchValue, forceUpdate )).catch((err) => openSnackbar('error', err.message));
    setLoading(false)
    setInitialLoad(false)
  }, []);

  const openDrawer =
    (type, equipment = {}) =>
    () => {
      setDrawer ({ type, equipment });
    };

  const closeDrawer = () => {
    setDrawer(null);
  };

  return (
    <div className={classes.root}>
      <EquipmentHeader openDrawer={openDrawer} />
      <FiltersBar openDrawer={openDrawer} updateSearchValue={updateSearchValue} isDynamicSearch={true}/>
      <PageLoader loading={loading}>
          <EquipmentList
            equipment={data}
            openDrawer={openDrawer}
            updateVisibleEquipment={updateVisibleEquipment}
            hasMore={hasMore}
            isLoading={loading}
            openSnackbar={openSnackbar}
            initialLoad={initialLoad}
          />
      </PageLoader>

      <Drawer
        anchor="right"
        open={!!drawer}
        classes={{
          paper: classes.drawer,
        }}
      >
        {drawer && drawer.type !== drawerTypes.filters && (
          <EquipmentForm
            closeDrawer={closeDrawer}
            type={drawer.type}
            equipment={drawer.type === drawerTypes.update ? drawer.equipment : null}
            openSnackbar={openSnackbar}
            updateVisibleEquipment={updateVisibleEquipment}
          />
        )}
        {drawer && drawer.type === drawerTypes.filters && (
          <SortingAndFilteringPanel
            closeDrawer={closeDrawer}
            filter={userFilter}
            updateFilter={updateFilter}
            isLoading={loading}
          />
        )}
      </Drawer>
    </div>
  );
};

EquipmentPage.propTypes = {
  classes: propTypes.object.isRequired,
  openSnackbar: propTypes.func.isRequired,
};

export default withStyles(styles)(EquipmentPage);
