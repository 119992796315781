import React, { Component } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { agoDate, clockTime } from 'helpers/_date-helpers';
import { checkUserRole } from 'helpers/_helpers';
import SignaturePopover from '../../../SignaturePopover';
import SubmitModal from './components/SubmitModal';
import styles from './styles';
import SignIcon from 'assets/images/sign-icon-grey.svg';
import { connect } from 'react-redux';
import { dateSettingsFormat, getTimeFormat } from 'common/dateFormatConfig';

class SubmitSection extends Component {
  state = {
    anchorEl: null,
    submissionId: null,
    submitModal: {
      isOpen: false,
      type: 'Submit',
    },
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleOpenPopover = (submissionId) => (event) => {
    if (this._isMounted) {
      this.setState({ anchorEl: event.currentTarget, submissionId });
    }
  };

  handleClosePopover = () => {
    if (this._isMounted) {
      this.setState({ anchorEl: null });
    }
  };

  isAnySignature = (submission) => {
    const { signatures } = submission;
    return Boolean(signatures && (signatures.dot || signatures.contractor));
  };

  openSubmitModal = (type) => () => {
    if (this._isMounted) {
      this.setState({
        submitModal: { type, isOpen: true },
      });
    }
  };

  closeSubmitModal = () => {
    if (this._isMounted) {
      this.setState((prevState) => ({
        submitModal: { ...prevState.modal, isOpen: false },
      }));
    }
  };

  hasSubmitAccess = () => {
    const { sheet } = this.props;
    const userId = this.props.user._id;
    if (checkUserRole('Field Technician')) {
      return sheet.workers.some((w) => w._id === userId);
    }
    return true;
  };

  render() {
    const { classes, sheet, submissions, openSnackbar, hasAccess } = this.props;
    const { anchorEl, submitModal, submissionId } = this.state;

    return (
      <div>
        {submissions && submissions.length ? (
          <div className={classes.flexBox}>
            <div className={classes.leftFlexPart}>
              {submissions.map((s, i) => (
                <div key={s._id} className={classes.inlineFlex}>
                  <Typography variant="body1" color="textPrimary" className={classes.leftPart}>
                    {i === 0 && <span className={classes.label}>Submitted: </span>}
                    {agoDate(
                      s.startedAt,
                      null,
                      dateSettingsFormat(this.props.dateFormat).dayDateSettingsFormat
                    )}{' '}
                    at{' '}
                    {clockTime(
                      s.startedAt,
                      getTimeFormat([], this.props.timeFormat, this.props.dateFormat)
                        .formatForTimePars
                    )}{' '}
                    by {s.username}
                  </Typography>
                  {this.isAnySignature(s) && (
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      onClick={this.handleOpenPopover(s._id)}
                      className={classes.signature}
                    >
                      <img src={SignIcon} alt="show signature" className={classes.signIcon} />
                      Signature
                    </Typography>
                  )}
                  {submissionId === s._id && (
                    <SignaturePopover
                      anchorEl={anchorEl}
                      handleClosePopover={this.handleClosePopover}
                      signatures={s.signatures}
                      defaultPosition
                    />
                  )}
                </div>
              ))}
            </div>
            {hasAccess && (
              <Button
                variant="text"
                className={classes.submitButton}
                onClick={this.openSubmitModal('Resubmit')}
                disableRipple
                disableTouchRipple
              >
                Resubmit
              </Button>
            )}
          </div>
        ) : (
          this.hasSubmitAccess() && (
            <Button
              variant="text"
              className={classes.submitButton}
              onClick={this.openSubmitModal('Submit')}
              disableRipple
              disableTouchRipple
            >
              Submit
            </Button>
          )
        )}

        {submitModal.isOpen && (
          <SubmitModal
            closeModal={this.closeSubmitModal}
            type={submitModal.type}
            sheet={sheet}
            openSnackbar={openSnackbar}
          />
        )}
      </div>
    );
  }
}

SubmitSection.propTypes = {
  classes: propTypes.object.isRequired,
  sheet: propTypes.object.isRequired,
  submissions: propTypes.array,
  openSnackbar: propTypes.func.isRequired,
  hasAccess: propTypes.bool.isRequired,
};

export default connect((state) => ({
  dateFormat: state.personalProfile.organization?.settings?.dateFormat,
  timeFormat: state.personalProfile.organization?.settings?.timeFormat,
}))(withStyles(styles)(SubmitSection));
