import React, { useEffect, useState, useMemo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { useSelector, useDispatch } from 'react-redux';
import Header from './components/Header/Header';
import { Drawer } from '@material-ui/core';
import SheetForm from 'pages/Worklogs/components/SheetForm/SheetForm';
import { ScheduleFilters } from '../../../ScheduleHeader/components/ScheduleFilters/ScheduleFilters';
import { DRAWER_TYPES } from 'pages/Schedule/components/SchedulePage/helpers/drawerType';
import { useMobileStyles } from './components/useStyles';
import SheetList from './components/SheetList/SheetList';
import { getSheets, publish } from 'store/schedule/schedulePageOperation';
import { format } from 'date-fns';
import { FORMATS } from 'helpers/formats';
import WorklogDetails from 'components/WorklogDetails';
import ResourcesForm from 'components/ResourcesForm';
import CommentsForm from 'components/CommentsForm';
import MaterialForm from 'components/MaterialForm';
import TimeForm from 'components/WorklogDetails/components/TimeBlock/components/TimeForm/TimeForm';
import WeatherForm from 'components/WorklogDetails/components/WeatherBlock/components/WeatherForm/WeatherForm';
import NotesForm from 'components/WorklogDetails/components/NotesBlock/components/NotesForm/NotesForm';
import GeneralInfoForm from 'components/WorklogDetails/components/GeneralInfoBlock/components/GeneralInfoForm/GeneralInfoForm';
import Modals from '../../../SchedulePage/components/Modals/Modals';
import modalsPageActions from 'store/modals/modalsPageActions';
import _ from 'lodash';
import { getSheetsWithoutForeman } from 'pages/Schedule/components/ScheduleCalendar/scheduleHelpers';
import AlertModal from 'components/AlertModal/AlertModal';
import ConfirmDialog from 'components/ConfirmDialog';
import UpdateModal from 'pages/Schedule/components/ScheduleCalendar/components/UpdateModal/UpdateModal';
import { globalBEDateFormat } from 'common/dateFormatConfig';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';
import FiltersBar from 'components/FiltersBar';
import Fuse from 'fuse.js';

const searchOptions = {
  shouldSort: true,
  keys: [
    'equipment.name',
    'workers.username',
    'project.route',
    'project.jobNumber',
    'project.contractor.name',
  ],
  threshold: 0.1,
};

const MySchedule = ({ classes, openSnackbar }) => {
  const mobileStyles = useMobileStyles();
  const dispatch = useDispatch();

  const sheets = useSelector((state) => state.schedule.sheets);

  const resourcesWorkers = useSelector((state) => state.schedule.resources.workers);
  const selectedDate = useSelector((state) => state.schedule.selectedDate);
  const settings = useSelector((state) => state.settings.settings);
  const filters = useSelector((state) => state.schedule.filters);
  const unpublishedSchedule = useSelector((state) => state.schedule.unpublishedSchedule);
  const { dateFormat } = useDateSettingsFormat();
  const [searchValue, setSearchValue] = useState('');
  const [fuse, setFuse] = useState(null);

  const [sheetsWithoutForemanIds, setSheetsWithoutForemanIds] = useState([]);
  const [additionalDrawer, setAdditionalDrawer] = useState({
    type: 'resources',
    subType: 'people',
    isOpen: false,
    material: {},
  });
  const [drawer, setDrawer] = useState({
    type: 'filters',
    isOpen: false,
    sheet: {},
  });
  const [infoModal, setInfoModal] = useState({
    isOpen: false,
    text: '',
  });
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    text: '',
  });
  const [updateModal, setUpdateModal] = useState({
    isOpen: false,
    data: {},
  });
  const openDrawer =
    (type, sheet = {}) =>
    () => {
      setDrawer({ type, isOpen: true, sheet });
    };

  const closeDrawer = () => {
    setDrawer((prev) => ({ ...prev, type: 'details', isOpen: false }));
  };

  useMemo(() => {
    setFuse(new Fuse(sheets, searchOptions));
  }, [sheets]);

  const openAdditionalDrawer =
    (type, subType, material = {}) =>
    () => {
      setAdditionalDrawer({ type, subType, isOpen: true, material });
    };

  const closeAdditionalDrawer = () => {
    setAdditionalDrawer((prev) => ({ ...prev, isOpen: false }));
  };

  const fetchSheets = () => {
    const date = format(selectedDate, globalBEDateFormat);
    dispatch(
      getSheets({
        date,
        filters,
        isCrewLeader: false,
        isGridView: false,
        isMobile: true,
      })
    );
  };

  useEffect(() => {
    fetchSheets();
  }, [selectedDate, filters]);

  useEffect(() => {
    dispatch(
      modalsPageActions.addModals([
        // set correct onSubmit on opening 'remove' modal
        {
          name: 'remove',
          onSubmit: () => null,
          onClose: () => dispatch(modalsPageActions.closeModal('remove')),
        },
        {
          name: 'info',
          onSubmit: () => null,
          onClose: () => dispatch(modalsPageActions.closeModal('info')),
        },
        {
          name: 'conflict',
          onSubmit: () => null,
          onClose: () => dispatch(modalsPageActions.closeModal('conflict')),
        },
        {
          name: 'overlapping',
          onSubmit: () => dispatch(modalsPageActions.closeModal('overlapping')),
          onClose: () => dispatch(modalsPageActions.closeModal('overlapping')),
        },
      ])
    );

    return () => {
      dispatch(modalsPageActions.removeModal('remove'));
      dispatch(modalsPageActions.removeModal('info'));
      dispatch(modalsPageActions.removeModal('conflict'));
      dispatch(modalsPageActions.removeModal('overlapping'));
      // sessionStorage.setItem('scheduleDate', formatISO(selectedDate));
    };
  }, []);

  const publishSchedule = async () => {
    const sheetsInView = _.cloneDeep(sheets);
    const isGridView = false;
    const currentDate = format(selectedDate, dateFormat);
    let sheetsThisDay = sheetsInView.filter((s) => !s.noWorkers);

    if (unpublishedSchedule) {
      //* for publish
      let notPublishedSheets = sheetsThisDay.filter((s) => !s.sheet.published);
      const sheetsWithoutForemanIds = getSheetsWithoutForeman(notPublishedSheets);
      if (sheetsWithoutForemanIds.length) {
        setSheetsWithoutForemanIds(sheetsWithoutForemanIds);
        setInfoModal({ isOpen: true, text: 'Crew Leader missing from highlighted project(s).' });
      } else {
        const ids = sheetsInView
          .filter((s) => !s.noWorkers)
          .filter((s) => !s.sheet.published)
          .map((s) => s.sheet._id);

        if (!ids.length) {
          //! new feature for deny request if you have no data for publish
          setInfoModal({ isOpen: true, text: 'No sheets for publish' });
          // updatePublishControl(false)();
          // this.updateVisibleSheets();//?
          return;
        }

        const requiredTextPart = 'Do you want to publish this schedule?';
        const confirmText = settings.scheduleNotifications
          ? `Notices will be sent to all scheduled employees. ${requiredTextPart}`
          : `Notifications are currently turned off. Notices will not be sent. ${requiredTextPart}`;
        setConfirmModal({ isOpen: true, text: confirmText, type: 'publish' });
      }
    } else {
      //* for update
      let publishedSheets = sheetsThisDay.filter(
        (s) => s.sheet.published && s.sheet.unpublishedChanges
      );
      const sheetsWithoutForemanIds = getSheetsWithoutForeman(publishedSheets);
      if (sheetsWithoutForemanIds.length) {
        setSheetsWithoutForemanIds(sheetsWithoutForemanIds);
        setInfoModal({ isOpen: true, text: 'Crew Leader missing from highlighted project(s).' });
      } else {
        let ids = publishedSheets.map((s) => s.sheet._id);
        let workers = [];
        publishedSheets.forEach((s) => {
          let project = s.sheet.project;
          const title = project && project.route + (project.section ? ', ' + project.section : '');
          s.sheet.workers.forEach((add) => {
            let w = workers.find((worker) => worker._id === add._id);
            if (w) {
              w.addedTo.push(title);
            } else {
              workers.push({ _id: add._id, addedTo: [title] });
            }
          });
        });
        workers = workers.map((w) => {
          w.user = resourcesWorkers?.find((worker) => worker._id === w._id);
          return w;
        });

        if (!ids.length) {
          //! new feature for deny request if  you have no data for update
          setInfoModal({ isOpen: true, text: 'No sheets for update' });
          // updatePublishControl(false)();
          // this.updateVisibleSheets();//?
          return;
        }

        setSheetsWithoutForemanIds([]);
        setUpdateModal({
          isOpen: true,
          data: { workers, ids, isGridView, currentDate },
        });
      }
    }
    // updatePublishControl(false)();
    // this.updateVisibleSheets();//?
  };

  const submitPublishSchedule = async () => {
    const ids = sheets
      .filter((s) => !s.noWorkers)
      .filter((s) => !s.sheet.published)
      .map((s) => s.sheet._id);

    try {
      await dispatch(publish({ sheetIds: ids }));
      setSheetsWithoutForemanIds([]);
      setConfirmModal({ isOpen: false, text: '', type: 'publish' });
    } catch (err) {
      setSheetsWithoutForemanIds([]);
      setInfoModal({ isOpen: true, text: err?.response?.data?.message || err.message });
      setConfirmModal({ isOpen: false, text: '', type: 'publish' });
    }
  };

  const searchedSheets = fuse && searchValue ? fuse.search(searchValue) : sheets;

  return (
    <>
      <Header openDrawer={openDrawer} publishSchedule={publishSchedule} />
      <div style={{ padding: '0px 15px' }}>
        <FiltersBar
          openDrawer={openDrawer}
          updateSearchValue={setSearchValue}
          isDynamicSearch={true}
          noFilters
        />
      </div>

      <SheetList
        openSnackbar={openSnackbar}
        sheetsWithoutForemanIds={sheetsWithoutForemanIds}
        sheets={searchedSheets}
      />

      <WorklogDetails
        openSnackbar={openSnackbar}
        updateVisibleSheets={() => {}}
        openDrawer={openDrawer}
        openAdditionalDrawer={openAdditionalDrawer}
        updateDrawerFromRouter={closeDrawer}
        sheetIds={[]}
        hasMore={false}
        isSchedule
        mobileViewSheetsRefresh={fetchSheets}
      />
      <Drawer anchor="left" open={additionalDrawer.isOpen}>
        {additionalDrawer.type === 'resources' && (
          <ResourcesForm closeDrawer={closeAdditionalDrawer} type={additionalDrawer.subType} />
        )}
        {additionalDrawer.type === 'comments' && (
          <CommentsForm closeDrawer={closeAdditionalDrawer} openSnackbar={openSnackbar} />
        )}
        {additionalDrawer.type === 'material' && (
          <MaterialForm
            closeDrawer={closeAdditionalDrawer}
            openSnackbar={openSnackbar}
            type={additionalDrawer.subType}
            material={additionalDrawer.material}
          />
        )}
        {additionalDrawer.type === 'time' && (
          <TimeForm closeDrawer={closeAdditionalDrawer} openSnackbar={openSnackbar} />
        )}
        {additionalDrawer.type === 'weather' && <WeatherForm closeDrawer={closeAdditionalDrawer} />}
        {additionalDrawer.type === 'notes' && (
          <NotesForm closeDrawer={closeAdditionalDrawer} openSnackbar={openSnackbar} />
        )}
        {additionalDrawer.type === 'general' && (
          <GeneralInfoForm closeDrawer={closeAdditionalDrawer} />
        )}
      </Drawer>
      <Drawer anchor="right" open={drawer.isOpen}>
        {drawer.type === DRAWER_TYPES.addWorklog && (
          <SheetForm
            closeDrawer={closeDrawer}
            type={'create'}
            openSnackbar={openSnackbar}
            defaultDate={selectedDate}
          />
        )}
        {drawer.type === DRAWER_TYPES.filters && (
          <ScheduleFilters closeDrawer={closeDrawer} mobileStyles={mobileStyles} />
        )}
      </Drawer>
      {infoModal.isOpen && (
        <AlertModal
          isOpen={infoModal.isOpen}
          info={infoModal.text}
          onClose={() =>
            setInfoModal({
              isOpen: false,
              text: '',
            })
          }
        />
      )}
      {confirmModal.isOpen && (
        <ConfirmDialog
          isOpen={confirmModal.isOpen}
          onClose={() =>
            setConfirmModal({
              isOpen: false,
              text: '',
            })
          }
          onSubmit={confirmModal.type === 'publish' ? submitPublishSchedule : null}
          // onSubmit={confirmModal.type === 'publish' ? submitPublishSchedule : submitReconcile}
          text={confirmModal.text}
          loadingOnSubmit={true}
        />
      )}
      {updateModal.isOpen && (
        <UpdateModal
          isOpen={updateModal.isOpen}
          onClose={() => {
            setUpdateModal({
              isOpen: false,
              data: {},
            });
          }}
          data={updateModal.data}
          openSnackbar={openSnackbar}
        />
      )}
      <Modals />
    </>
  );
};

export default withStyles(styles)(MySchedule);
