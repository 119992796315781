const permissions = {
  Admin: {
    usersFullAccess: true,
    userPermissionsEdit: true,
    userRolesEdit: true,
    worklogsFullAccess: true,
    worklogsSubmit: true,
    scheduleFullAccess: true,
    gridViewFullAccess: true,
    notesFullAccess: true,
    projectsFullAccess: true,
    availabilityFullAccess: true,
    settings: true,
    timeClockFullAccess: true,
    timelineFullAccess: true,
    editClocks: true,
    editWorkTime: true,
    suppliesFullAccess: true,
    equipmentFullAccess: true,
    kioskMode: true,
    cipFullAccess: true,
    internalNotesFullAccess: true,
    timeClockAnalytics: true,
    holidaysFullAccess: true,
  },
  'Field Technician': {
    worklogsRead: true,
    worklogsSubmit: true,
    worklogsCreate: true,
    assignedWorklogs: true,
    assignedSchedule: true,
    worklogsDateEdit: false,
    worklogsEditSubmitted: false,
    worklogsEditAssigned: true,
    worklogsEditCreated: true,
    worklogsEditCLSubmitted: true,
    notesFullAccess: true,
  },
  Dispatcher: {
    worklogsFullAccess: true,
    worklogsSubmit: true,
    scheduleFullAccess: true,
    gridViewFullAccess: true,
    notesFullAccess: true,
    projectsFullAccess: true,
    // availabilityFullAccess: true,
    settings: true,
    timeClockFullAccess: true,
    usersBasicRead: true,
    usersFieldTechnicianToggle: true,
    kioskMode: true,
    timelineFullAccess: true,
    internalNotesFullAccess: true,
    timeClockAnalytics: true,
  },
  Accounting: {
    usersBasicRead: true,
    scheduleRead: true,
    availabilityRead: true,
    timeClockRead: true,
    timeClockExcel: true,
    kioskModeLogTime: true,
    timelineRead: true,
    timeClockAnalytics: true,
    // payrollActions: true,
  },
  'Fleet Maintenance': {
    scheduleRead: true,
  },
  'Project Management': {
    scheduleRead: true,
    availabilityRead: true,
    worklogsRead: true,
    notesFullAccess: true,
  },
  'Machine Shop': {
    kioskMode: true,
  },
  All: {
    scheduleRead: true,
    scheduleRead: true,
    availabilityRead: true,
    worklogsRead: true,
    notesFullAccess: true,
    usersFullAccess: true,
    userPermissionsEdit: true,
    userRolesEdit: true,
    worklogsFullAccess: true,
    worklogsSubmit: true,
    scheduleFullAccess: true,
    gridViewFullAccess: true,
    notesFullAccess: true,
    projectsFullAccess: true,
    availabilityFullAccess: true,
    settings: true,
    timeClockFullAccess: true,
    timelineFullAccess: true,
    editClocks: true,
    editWorkTime: true,
    suppliesFullAccess: true,
    equipmentFullAccess: true,
    kioskMode: true,
    cipFullAccess: true,
    internalNotesFullAccess: true,
    timeClockAnalytics: true,
    holidaysFullAccess: true,
    worklogsRead: true,
    worklogsSubmit: true,
    worklogsCreate: true,
    assignedWorklogs: true,
    assignedSchedule: true,
    myScheduleFull: true,
    worklogsDateEdit: true,
    worklogsEditSubmitted: false,
    worklogsEditAssigned: true,
    worklogsEditCreated: true,
    worklogsEditCLSubmitted: true,
    notesFullAccess: true,
    worklogsFullAccess: true,
    worklogsSubmit: true,
    scheduleFullAccess: true,
    gridViewFullAccess: true,
    notesFullAccess: true,
    projectsFullAccess: true,
    settings: true,
    timeClockFullAccess: true,
    usersBasicRead: true,
    usersFieldTechnicianToggle: true,
    kioskMode: true,
    timelineFullAccess: true,
    internalNotesFullAccess: true,
    timeClockAnalytics: true,
    usersBasicRead: true,
    scheduleRead: true,
    availabilityRead: true,
    timeClockRead: true,
    timeClockExcel: true,
    kioskModeLogTime: true,
    timelineRead: true,
    timeClockAnalytics: true,
  },
};

const extPermissions = {
  Admin: {
    cipKioskMode: false,
    editPTO: false,
    activeSchedule: false,
    PTOOvertimeException: false,
    HolidayOvertimeException: false,
    remoteKiosk: false,
    payrollActions: false,
  },
  Dispatcher: {
    editPTO: false,
    activeSchedule: false,
    suppliesFullAccess: false,
    availabilityEquipmentEdit: false,
    availabilityPeopleEdit: false,
    deletePTODay: false,
    PTOOvertimeException: false,
    HolidayOvertimeException: false,
    remoteKiosk: false,
  },
  'Field Technician': {
    cipKioskMode: false,
    remoteKiosk: false,
    activeSchedule: false,
    PTOOvertimeException: false,
    HolidayOvertimeException: false,
    myScheduleFull: false,
  },
  Accounting: {
    projectsRead: false,
    cipFullAccess: false,
    equipmentRead: false,
    usersFullAccess: false,
    availabilityFullAccess: false,
    timeClockFullAccess: false,
    editPTO: false,
    holidaysCreate: false,
    holidaysEdit: false,
    worklogsRead: false,
    projectsFullAccess: false,
    kioskMode: false,
    activeSchedule: false,
    PTOOvertimeException: false,
    HolidayOvertimeException: false,
    remoteKiosk: false,
    payrollActions: false,
    scheduleFullAccess: false,
  },
  'Fleet Maintenance': {
    cipKioskMode: false,
    cipProjectsFullAccess: false,
    equipmentFullAccess: false,
    availabilityRead: false,
    availabilityEquipmentEdit: false,
    activeSchedule: false,
    PTOOvertimeException: false,
    HolidayOvertimeException: false,
    remoteKiosk: false,
  },
  'Project Management': {
    projectsFullAccess: false,
    worklogsEdit: false,
    worklogsEditSubmitted: false,
    worklogsEditAssigned: false,
    worklogsEditCreated: false,
    worklogsEditCLSubmitted: false,
    activeSchedule: false,
    scheduleFullAccess: false,
    suppliesFullAccess: false,
    PTOOvertimeException: false,
    HolidayOvertimeException: false,
    remoteKiosk: false,
  },
  'Machine Shop': {
    cipKioskMode: false,
    activeSchedule: false,
    PTOOvertimeException: false,
    HolidayOvertimeException: false,
    remoteKiosk: false,
  },
};

const roles = [
  'Admin',
  'Field Technician',
  'Dispatcher',
  'Accounting',
  'Fleet Maintenance',
  'Project Management',
  'Machine Shop',
];

export { permissions, extPermissions, roles };
