import React, { useCallback } from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { hasPermissionsFor } from '../../../../../../../../../../helpers/_helpers';
import { format, parse } from 'date-fns';
import CloseIcon from 'assets/images/close-icon.svg';
import EditIcon from 'assets/images/edit-icon.svg';

import styles from './styles';
import { GLOBAL_DATE_FORMAT, globalBEDateFormat } from 'common/dateFormatConfig';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';

const HolidayCard = ({ classes, holiday, toggleHolidayModal, openDeleteModal }) => {
  const { dateFormat } = useDateSettingsFormat();

  const formatHolidayDates = useCallback(() => {
    const { startDate, endDate } = holiday;
    const formattedStartDate = format(parse(startDate, globalBEDateFormat, new Date()), dateFormat);
    const formattedEndDate = format(parse(endDate, globalBEDateFormat, new Date()), dateFormat);
    if (formattedStartDate === formattedEndDate) {
      return formattedStartDate;
    } else return `${formattedStartDate} - ${formattedEndDate}`;
  }, [holiday]);

  const handleDeleteHoliday = useCallback(() => {
    openDeleteModal(holiday._id);
  }, [holiday]);

  const lastActionUsername = (holiday) => {
    if (holiday.lastAction === 'Updated' || holiday.lastAction === 'Update') {
      return holiday.updatedByUser?.profile?.fullName || holiday?.updatedByUser?.username || '';
    }
    if (holiday.lastAction === 'Created' || holiday.lastAction === 'Create') {
      return holiday.createdByUser?.profile?.fullName || holiday?.createdByUser?.username || '';
    }
    if (holiday.lastAction === 'Deleted' || holiday.lastAction === 'Delete') {
      return holiday.deletedByUser?.profile?.fullName || holiday?.deletedByUser?.username || '';
    }
    return '';
  };

  const lastActionAt = (holiday) => {
    const dateFormat = GLOBAL_DATE_FORMAT.fullDate;
    if (holiday.lastAction === 'Updated' || holiday.lastAction === 'Update') {
      return format(new Date(holiday.updatedAt), dateFormat);
    }
    if (holiday.lastAction === 'Created' || holiday.lastAction === 'Create') {
      return format(new Date(holiday.createdAt), dateFormat);
    }
    if (holiday.lastAction === 'Deleted' || holiday.lastAction === 'Delete') {
      return format(new Date(holiday.deletedAt), dateFormat);
    }
    return '';
  };

  const lastActionLabel = (holiday) => {
    if (holiday.lastAction === 'Updated' || holiday.lastAction === 'Update') {
      return 'Updated';
    }
    if (holiday.lastAction === 'Created' || holiday.lastAction === 'Create') {
      return 'Created';
    }
    if (holiday.lastAction === 'Deleted' || holiday.lastAction === 'Delete') {
      return 'Deleted';
    }
    return '';
  };

  const canEditHoliday =
    hasPermissionsFor('holidaysFullAccess') || hasPermissionsFor('holidaysEdit');

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      className={classnames(classes.root, holiday.deletedAt && classes.deletedHoliday)}
    >
      <Grid item lg={3}>
        <Typography
          variant="body1"
          color="textPrimary"
          className={classnames(classes.holidayName, classes.holidayText)}
        >
          {holiday.name}
        </Typography>
      </Grid>
      <Grid item lg={3}>
        <Typography variant="body1" color="textSecondary" className={classes.regularFontWeight}>
          Date/Period: &nbsp;
          <span className={classes.holidayText}>{formatHolidayDates()}</span>
        </Typography>
      </Grid>
      <Grid item lg={1}>
        <Typography variant="body1" color="textSecondary" className={classes.regularFontWeight}>
          PTO: &nbsp;
          <span className={classes.holidayText}>{holiday.isPaid ? 'Yes' : 'No'}</span>
        </Typography>
      </Grid>
      <Grid item lg={2}>
        <Typography variant="body1" color="textSecondary" className={classes.regularFontWeight}>
          {lastActionLabel(holiday)}: &nbsp;
          <span className={classes.holidayText}>{lastActionUsername(holiday)}</span>
        </Typography>
      </Grid>
      <Grid item lg={2}>
        <Typography variant="body1" color="textSecondary" className={classes.regularFontWeight}>
          Date: &nbsp;
          <span className={classes.holidayText}>{lastActionAt(holiday)}</span>
        </Typography>
      </Grid>

      <Grid item lg={1} className={classes.textRight}>
        {holiday.deletedAt ? null : canEditHoliday ? (
          <>
            <IconButton
              className={classes.actionBtn}
              onClick={toggleHolidayModal(holiday)}
              disableRipple
            >
              <img src={EditIcon} alt="edit" />
            </IconButton>
            <IconButton className={classes.actionBtn} onClick={handleDeleteHoliday} disableRipple>
              <img src={CloseIcon} alt="delete" />
            </IconButton>
          </>
        ) : null}
      </Grid>
    </Grid>
  );
};

HolidayCard.propTypes = {
  classes: propTypes.object.isRequired,
  holiday: propTypes.object.isRequired,
  toggleHolidayModal: propTypes.func.isRequired,
  openDeleteModal: propTypes.func.isRequired,
};

export default withStyles(styles)(HolidayCard);
