export default (theme) => ({
  root: {
    padding: '20px 15px 0',
    minHeight: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      width: 570,
    },
  },
  formWrapper: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  closeButton: {
  },
  title: {
    flexGrow: 0,
    marginBottom: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  buttonHolder: {
    textAlign: 'center',
    flexGrow: 0,
  },
  cancelButton: {
    margin: 20,
    minWidth: 100,
    '&:hover': {
      color: 'inherit',
    },
    '&:disabled': {
      backgroundColor: '#fff',
    },
  },
  saveButton: {
    margin: 20,
    minWidth: 100,
    '&:hover': {
      color: theme.palette.textColor.primary,
    },
    '&:disabled': {
      backgroundColor: '#fff',
    },
  },
  formFlex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  picListWrapper: {
    flexGrow: '1',
  },
  picListWrapperNative: {
    flexGrow: '1',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  picListWrapperColumn:{
    '& > div': {
      marginBottom: 25,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  picWrapper: {
    border: '1px solid #D9DAE3',
    borderRadius: '5px',
    padding: '10px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      width: 260,
    },
  },
  flexHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 10,
  },
  avatarBox: {
    display: 'flex',
    alignItems: 'center',
    flexBasis: '60%',
  },
  deleteButton: {
    minWidth: 24,
    height: 24,
    padding: 5,
    marginTop: 3,
    color: theme.palette.error.red,
    '&:hover': {
      background: `${theme.palette.background.default}!important`,
    },
    '&:focus': {
      background: `${theme.palette.background.default}!important`,
    },
  },
  icon: {
    '&:hover': {
      background: `${theme.palette.background.default}!important`,
    },
    '&:focus': {
      background: `${theme.palette.background.default}!important`,
    },
  },
  flexBox: {
    display: 'flex',
  },
  photoWrapper: {
    borderRadius: '50%',
    border: `1px solid ${theme.palette.textColor.blue}`,
    borderTopColor: 'transparent',
    borderBottomColor: 'transparent',
    width: 32,
    height: 32,
    transform: 'rotate(135deg)',
    minWidth: 32,
  },
  photo: {
    display: 'block',
    transform: 'rotate(-135deg)',
    height: 26,
    maxWidth: 26,
    borderRadius: '50%',
    margin: '2px auto 0',
  },
  name: {
    fontSize: 14,
    margin: '0px 10px',
  },
  actionsBlock: {
    flexBasis: '40%',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
  },
  dateBlock: {
    color: theme.palette.textColor.secondary,
    fontSize: 14,
  },
  divider: {
    margin: '10px 0',
  },
  imgBlock: {
    height: 120,
    borderRadius: '5px',
    overflow: 'hidden',
    marginBottom: 10,
    [theme.breakpoints.down('sm')]: {
      height: 320,
      maxHeight: 320,
    },

    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  infoBlock: {
    color: theme.palette.textColor.secondary,
    fontSize: 12,
    marginBottom: 10,
  },
  text: {
    fontWeight: 500,
    fontSize: 12,
    marginBottom: 10,
    wordWrap: 'break-word',
  },
  expand: {
    transform: 'rotate(0deg)',
    padding: 0,
    '&:hover': {
      color: theme.palette.textColor.secondary,
      backgroundColor: '#fff',
    },
    '&:focus': {
      color: theme.palette.textColor.secondary,
      backgroundColor: '#fff',
    },
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  actionsWrapper: {
    borderTop: `1px solid ${theme.palette.borderColor.primary}`,
    justifyContent: 'space-around',
  },
});
