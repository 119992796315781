import { useCallback, useState } from 'react';

import { MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
export const useMaterialLabelsDnd = ({ values, move }) => {
  const [items, setItems] = useState([...(values || [])]);
  const [activeId, setActiveId] = useState(null);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        delay: 250,
      },
    }),
    useSensor(TouchSensor)
  );
  const handleDragStart = useCallback((event) => {
    setActiveId(event.active.id);
  }, []);

  const handleDragEnd = useCallback((event) => {
    const { active, over } = event;
    if (!active.id || !over?.id) return;
    if (active.id !== over?.id) {
      setItems((items) => {
        const oldItem = items.find(({ _id }) => _id === active.id);
        const newItem = items.find(({ _id }) => _id === over.id);
        const oldIndex = items.indexOf(oldItem);
        const newIndex = items.indexOf(newItem);
        move(oldIndex, newIndex);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
    // setActiveId(null);
  }, []);

  const handleDragCancel = useCallback(() => {
    setActiveId(null);
  }, []);
  return { items, setItems, activeId, sensors, handleDragStart, handleDragEnd, handleDragCancel };
};
