import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  title: {
    marginBottom: 6,
  },
  root: {
    maxWidth: 380,
  },
  field: {
    display: 'block',
    marginBottom: 10,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  formTitle: {
    marginBottom: 10,
    color: '#000000',
    fontSize: 16,
    letterSpacing: 0,
    lineHeight: '25px',
  },
  form: {
    marginBottom: 46,
  },
  inlineFlex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  delimiter: {
    margin: '0 4px',
  },
}));
