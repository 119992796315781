import { PROJECTS_EVENT } from 'subscriptions/events/events.enum';
import { subscriptionsFabric } from 'subscriptions/subscriptions.fabric';
import currentWorklogActions from 'store/currentWorklog/actions';


const handlers = {
  [PROJECTS_EVENT.updated]: (store) => (data) => {
    if (!data._id) return;

    const currentWorklog = store.getState().currentWorklog.worklogSheet?.sheet;
    if (currentWorklog?._id && currentWorklog?.projectId === data._id && !currentWorklog.published) {
      store.dispatch(
        currentWorklogActions.patchWorklogSheet({ project: { ...currentWorklog.project, ...data } })
      );
    }
  },
};

export const currentWorklogProjectsSubscriptionMiddleware = subscriptionsFabric(
  currentWorklogActions,
  PROJECTS_EVENT,
  handlers
);
