import axios from 'axios';
import { getOptions } from 'helpers/getOptions';

export const authorizeToDocuSign = async () => {
  try {
    await axios.get(`${process.env.REACT_APP_BASE_URL}/docusign/check-status`, getOptions());
  } catch (error) {
    throw new Error(error.message);
  }
};
