export default theme => ({
  locationSelect: {
    '& > div': {
      height: '34px !important'
    }
  },
  inlineFlex: {
    display: 'inline-flex'
  },
  inputButton: {
    padding: 4,
    minWidth: 'auto',
    color: theme.palette.textColor.secondary,
    '&:hover': {
      color: theme.palette.textColor.secondary
    },
    '&:focus': {
      color: theme.palette.textColor.secondary
    }
  },
  primaryButton: {
    color: theme.palette.textColor.primary,
    '&:hover': {
      color: theme.palette.textColor.primary
    },
    '&:focus': {
      color: theme.palette.textColor.primary
    }
  },
  noHover: {
    '&:hover': {
      background: theme.palette.background.default
    },
    '&:focus': {
      background: theme.palette.background.default
    }
  },
  addWorkButton: {
    [theme.breakpoints.up('md')]: {
      marginTop: 15,
      textAlign: 'right'
    }
  },
  error: {
    color: theme.palette.textColor.red,
    padding: '0 5px',
    fontSize: 11
  },
  requiredSign: {
    color: theme.palette.textColor.red
  },
});
