import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { hasPermissionsFor } from 'helpers/_helpers';
import { permLabels, changePermission, userTypesArray, extPermissions } from 'common/permissions';
import { patchUserRole, patchUserPermissions } from 'store/people/peoplePageOperations';
import IOSSwitch from 'components/IOSSwitch';
import { isIOS, Option } from 'components/Option';
import PageLoader from 'components/PageLoader';

import styles from './styles';


const UserPermissions = ({ classes, user, currentUserId, openSnackbar }) => {
  const dispatch = useDispatch();

  const [permissions, setPermissions] = useState(user?.profile?.role?.extPermissions);
  const [role, setRole] = useState(user?.profile?.role?.roleName);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!extPermissions[role]) {
      return setPermissions({});
    }
    
    const values = user?.profile?.role?.extPermissions
    const userPermissions = extPermissions[role];
    const nextPermissions = userPermissions.reduce((res, permission) => ({ ...res, [permission]: !!values[permission] }), {});
    setPermissions(nextPermissions)
  }, [user, role]);

  
  const handleChangeRole = useCallback(async (e) => {
    const role = e.target.value;
    if (!userTypesArray.includes(role)) {
      console.error('Invalid User Type');
    }

    setLoading(true);

    try {
      setRole(role);
      await dispatch(patchUserRole(user._id, role));
    } catch (err) {
      openSnackbar('error', err?.message)
    }
    
    setLoading(false);
  }, [user, role])


  const handleChangePermission = useCallback((permission) => async () => {
    const newPermissions = changePermission(user?.profile?.role?.roleName, permissions, permission) ;

    setLoading(true);
    try {
      await dispatch(patchUserPermissions(user._id, newPermissions));
    } catch (err) {
      openSnackbar('error', err?.message)
    }

    setLoading(false);
  }, [user, permissions])


  return (
    <PageLoader loading={loading} small>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={4} lg={12} className={classes.paddingBottomRemove}>
            <label htmlFor="userType">
              <Typography variant="body1" color="textSecondary">
                User type:
              </Typography>
              <TextField
                select
                id="userType"
                name="userType"
                value={role}
                onChange={handleChangeRole}
                className={classes.textField}
                variant="outlined"
                inputProps={{ 'aria-label': 'User type' }}
                fullWidth
                SelectProps={{
                  native: isIOS,
                }}
                disabled={!hasPermissionsFor('usersFullAccess') || user._id === currentUserId}
              >
                {userTypesArray.map((type) => (
                  <Option key={type} value={type}>
                    {type}
                  </Option>
                ))}
              </TextField>
            </label>
            <Divider className={classes.divider} />
          </Grid>
          <Grid item xs={12} md={8} lg={12}>
            <Grid container>
              {Object.keys(permissions).length !== 0 &&
                Object.keys(permissions)
                  .filter(permission => permLabels[permission])
                  .sort((a, b) => a.localeCompare(b))
                  .map((permission) => (
                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={12}
                      key={`${user._id}_${permission}`}
                    >
                      <FormControlLabel
                        label={permLabels[permission] + ':'}
                        labelPlacement="start"
                        className={classes.switchesWrapper}
                        control={
                          <IOSSwitch
                            checked={permissions[permission]}
                            onChange={handleChangePermission(permission)}
                            value={permissions[permission]}
                            disabled={
                              !hasPermissionsFor('usersFullAccess', permission, role) ||
                              user._id === currentUserId
                            }
                          />
                        }
                      />
                    </Grid>
                  ))}
            </Grid>
          </Grid>
        </Grid>
      </PageLoader>
  )
}

UserPermissions.propTypes = {
  classes: propTypes.object.isRequired,
  user: propTypes.object,
  currentUserId: propTypes.string.isRequired,
};

export default withStyles(styles)(UserPermissions);
