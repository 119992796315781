
export const filterTodayEvents = (event, filter) => {
  const isToday = event.isSheetInCurrentDay;
  if (filter !== 'all') {
    const shift = event.sheet.timeOfDay === 'Night' ? 'night' : 'day';
    return shift === filter && isToday;
  }
  return isToday;
};

export const calcPrevNextForResources = (
  resource,
  i,
  eventIndex,
  events,
  resourceType
) => {
  const next = events
    .slice(eventIndex + 1)
    .some((event) => event[resourceType].some((r) => r._id === resource._id));
  const prev = eventIndex
    ? events
        .slice(0, eventIndex)
        .some((event) =>
          event[resourceType].some((r) => r._id === resource._id)
        )
    : false;
  return Object.assign(resource, { next, prev });
};

export const filterSheetsStartThisDay = (sheets) => {
  return sheets.filter(
    (sheet) => !sheet.isFromPreviousDay && sheet.isSheetInCurrentDay
  );
};

export const getSheetsWithoutForeman = (sheets) => {
  return sheets
    .filter((job) => !job.workers.some((w) => w.foreman))
    .map((job) => job.sheet._id);
};
