import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

export const useResourcesUsers = ({ usedWorkers }) => {
  const workers = useSelector((state) => state.schedule.resources.workers);
  const workersInitialized = useMemo(() => workers !== null, [workers]);
  const [userList, setUserList] = useState(null);

  useEffect(() => {
    if (!workersInitialized) return setUserList(null);
    if (!usedWorkers) return setUserList(workers);
    setUserList(
      workers.map((user) => ({
        ...user,
        isUsed: !!usedWorkers.find((usedWorker) => usedWorker._id === user._id),
      }))
    );
  }, [workers, usedWorkers, workersInitialized]);

  return { userList };
};
