import React, { useCallback, useEffect } from 'react';
import propTypes from 'prop-types';
import { Formik, Form } from 'formik/dist/index';
import { withStyles } from '@material-ui/core/styles/index';
import Typography from '@material-ui/core/Typography/index';
import IconButton from '@material-ui/core/IconButton/index';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid/index';
import TextField from '@material-ui/core/TextField/index';
import Button from '@material-ui/core/Button/index';
import { isIOS, Option } from 'components/Option';
import PageLoader from 'components/PageLoader';
import styles from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAddGlassBead, fetchUpdateGlassBead } from 'store/supplies/suppliesOperations';
import suppliesActions from 'store/supplies/suppliesActions';
import { glassBeadsTypes } from '../../Materials/components/MaterialForm/constants';
import MaterialTicks from '../../MaterialTicks/MaterialTicks';

const FormPanel = ({ classes, closeDrawer, data, onSave, type }) => {
  const dispatch = useDispatch();

  const settings = useSelector((state) => state.personalProfile?.organization?.settings);
  const drawerGlassBeadsLoading = useSelector(
    (state) => state.supplies.glassBeads.drawerGlassBeadsLoading
  );
  const successfullyAdded = useSelector(
    (state) => state.supplies.glassBeads.successfullyAddedGlassBeads
  );

  const onSubmit = useCallback((values) => {
    if (data?._id) {
      // Cast oldBeadId explicitly, so it'll always be right number type
      const preparedValues = { ...values };
      if (!!preparedValues.oldBeadId) {
        preparedValues.oldBeadId = parseInt(preparedValues.oldBeadId) || undefined;
      }
      dispatch(fetchUpdateGlassBead(preparedValues));
    } else {
      dispatch(fetchAddGlassBead(values));
    }
  }, []);

  useEffect(() => {
    if (successfullyAdded) {
      onSave();
      dispatch(suppliesActions.successfullyAddedGlassBead(false));
    }
  }, [successfullyAdded]);

  const initialValues = data?._id
    ? {
        ...data,
      }
    : {
        name: '',
        ticks: {}
      };

  return (
    <div className={classes.formWrapper} role="presentation">
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validate={(values) => {
          const errors = {};
          if (!values.name) {
            errors.name = 'Name is required';
          }
          return errors;
        }}
        onSubmit={(values) => {
          onSubmit(values);
        }}
      >
        {({ values, errors, touched, handleChange, setFieldValue }) => (
          <PageLoader loading={drawerGlassBeadsLoading}>
            <Form autoComplete="off" className={classes.formFlex}>
              <div>
                <Typography variant="h3" className={classes.marginHeader}>
                  {type === 'create' ? 'Create' : 'Edit'} Reflective Media
                </Typography>

                <IconButton
                  className={classes.closeButton}
                  onClick={() => {
                    dispatch(suppliesActions.validationGlassBeadError(false));
                    closeDrawer();
                  }}
                  aria-label="Close"
                  disableTouchRipple
                >
                  <CloseIcon />
                </IconButton>

                <Grid container xs={12}>
                  <Grid container spacing={1} style={{ justifyContent: 'space-between' }}>
                    <Grid item xs={12}>
                      <label htmlFor="name">
                        <Typography variant="body1" color="textSecondary">
                          Type of Reflective Media
                        </Typography>
                        <TextField
                          id="name"
                          value={values.name}
                          variant="outlined"
                          className="materials-input"
                          InputProps={{
                            inputMode: 'numeric',
                            pattern: '[0-9]*',
                            className: `${errors.name ? 'materials-input--error' : ''}`,
                          }}
                          fullWidth={true}
                          onChange={handleChange}
                        />
                      </label>
                      {errors.name && (
                        <span className="materials-input--error-text">{errors.name}</span>
                      )}
                    </Grid>
                  </Grid>
                  {settings?.generateCertificates &&
                  (
                    <MaterialTicks
                      errors={errors}
                      values={values?.ticks || {}}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                    />
                  )}
                </Grid>
              </div>

              <div className={classes.buttonHolder}>
                <Button
                  color="secondary"
                  className={classes.cancelButton}
                  onClick={() => {
                    dispatch(suppliesActions.validationGlassBeadError(false));
                    closeDrawer();
                  }}
                >
                  Cancel
                </Button>

                <div className={classes.verticalDivider}></div>

                <Button type="submit" color="primary" className={classes.saveButton}>
                  Save
                </Button>
              </div>
            </Form>
          </PageLoader>
        )}
      </Formik>
    </div>
  );
};

FormPanel.propTypes = {
  classes: propTypes.object.isRequired,
  closeDrawer: propTypes.func.isRequired,
  data: propTypes.object,
  onUpdate: propTypes.func,
  onSave: propTypes.func,
};

// FormPanel.defaultProps = {
//   data: {
//     _id: '',
//     name: '',
//     ratio: '',
//     unitType: '',
//   },
//   onUpdate: () => {},
//   onSave: () => {},
// };

export default withStyles(styles)(FormPanel);
