import React from 'react';
import styles from './styles';
import { withStyles } from '@material-ui/core';
import cs from 'classnames';

const IconButton = ({ classes, children, className, ...props }) => (
  <button type={'button'} className={cs(classes.root, className)} {...props}>
    {children}
  </button>
);

export default withStyles(styles)(IconButton);
