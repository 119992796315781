import palette from '../../../../theme/palette';

export default {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h2': {
      marginBottom: 5,
    },
  },
  addButton: {
    color: palette.textColor.blue,
    textTransform: 'none',
    '&:hover': {
      color: palette.textColor.blue,
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
    '&:focus': {
      color: palette.textColor.blue,
      backgroundColor: palette.background.default,
    },
    '&.Mui-disabled': {
      backgroundColor: palette.background.default,
    },
  },
};
