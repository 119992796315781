export default (theme) => ({
  title: {
    flexGrow: 0,
    marginBottom: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  icon: {
    '&:hover': {
      background: `${theme.palette.background.default}!important`,
    },
    '&:focus': {
      background: `${theme.palette.background.default}!important`,
    },
  },
});
