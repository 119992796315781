import formatNumber from 'pages/TimeClock/utils/formatNumber';
import { format } from 'date-fns';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';

export const TimeClockBlock = ({ day }) => {
  const user = day?.user || {};
  const getTimeFormatHook = useTimeSettingsFormat();
  const formatForTimePars = getTimeFormatHook().formatForTimePars;
  return (
    <>
      {day?.timeclock.map((timeclock, i) => {
        return (
          <>
            <tr>
              {i === 0 ? (
                <>
                  <td class="timeclock-table-employee">{user?.profile?.employeeNum}</td>
                  <td>{user?.username}</td>
                  <td class="timeclock-table-fullname">{user?.profile?.fullName}</td>
                  <td>{day?.hiCode || user?.profile?.HICode}</td>
                  <td></td>
                </>
              ) : (
                <td colspan="5"></td>
              )}
              <td></td>
              <td></td>
              <td>
                {format(
                  new Date(timeclock.modified?.inTime || timeclock.inTime),
                  formatForTimePars
                )}
              </td>
              <td>
                {timeclock.modified?.outTime || timeclock.outTime
                  ? format(
                      new Date(timeclock.modified?.outTime || timeclock.outTime),
                      formatForTimePars
                    )
                  : '-'}
              </td>

              <td>
                {formatNumber(timeclock.modified?.hoursThisDay || timeclock.hoursThisDay)}
                {`${timeclock.modified?.outTime || timeclock.outTime ? '' : '+'}`}
              </td>
            </tr>
            {timeclock.modified?.lunchTime || timeclock.lunchTime ? (
              <tr>
                <td class="timeclock-table-employee"></td>
                <td class="text-red">Lunch Break (sub time)</td>
                <td class="timeclock-table-fullname"></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="text-right"></td>
                <td class="text-red">
                  - {formatNumber(timeclock.modified?.lunchTime || timeclock.lunchTime)}
                </td>
              </tr>
            ) : (
              ''
            )}
          </>
        );
      })}
      <tr class="last-row">
        <td colspan="8"></td>
        <td class="text-right" colspan="1">
          <strong>Total Time:</strong>
        </td>
        <td>{formatNumber(day.totalTime)}</td>
      </tr>
    </>
  );
};
