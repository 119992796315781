import { createAction } from '@reduxjs/toolkit';

const setImage = createAction('settings/setImage');
const getImage = createAction('settings/getImage');
const deleteImage = createAction('settings/deleteImage');

const getSettings = createAction('settings/getSettings');
const updateSettings = createAction('settings/updateSettings');

const setTitle = createAction('settings/setTitle');
const getTitle = createAction('settings/getTitle');
const deleteTitle = createAction('settings/deleteTitle');

const setTitleColor = createAction('settings/setTitleColor');
const getTitleColor = createAction('settings/getTitleColor');

const setSettingsToggle = createAction('settings/setSettingsToggle');

export default {
  setImage,
  getImage,
  deleteImage,
  setTitle,
  getTitle,
  deleteTitle,
  setTitleColor,
  getTitleColor,
  setSettingsToggle,
  getSettings,
  updateSettings,
};
