import React from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import EditIcon from 'assets/images/edit-icon.svg';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';

import EquipmentItem from './components/EquipmentItem';
import styles from './styles';

const EquipmentBlock = ({
  classes,
  equipment,
  getFormattedHoursRange,
  openAdditionalDrawer,
  hasAccess,
  // openUserWorklogs,
}) => {
  return (
    <div>
      <Grid
        container
        spacing={2}
        direction={'row'}
        wrap={'nowrap'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Grid item xs={7} md={3}>
          <Typography variant="body1" color="textSecondary" className={classes.regularFontWeight}>
            Equipment:
          </Typography>
        </Grid>
        <Grid container item xs={8}>
          <Grid item xs={8} md={4}>
            {equipment.some((el) => el.hours?.length) && (
              <Typography
                variant="body1"
                color="textSecondary"
                className={classes.regularFontWeight}
              >
                On Job Time:
              </Typography>
            )}
          </Grid>
          {/* <Grid item xs={4}>
            <Typography variant="body1" color="textSecondary" className={classes.regularFontWeight}>
              Travel Time To Job:
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1" color="textSecondary" className={classes.regularFontWeight}>
              Travel Time From Job:
            </Typography>
          </Grid> */}
        </Grid>

        <Grid item container xs={1} justifyContent={'flex-end'} spacing={0}>
          {hasAccess && (
            <IconButton
              className={classes.editButton}
              onClick={openAdditionalDrawer('resources', 'equipment')}
              disableRipple
              disableTouchRipple
            >
              <img src={EditIcon} alt="edit equipment" />
            </IconButton>
          )}
        </Grid>
      </Grid>
      <div>
        {equipment.map((w) => (
          <EquipmentItem
            key={w._id}
            equipmentData={w}
            getFormattedHoursRange={getFormattedHoursRange}
            // hasAccess={hasAccess}
            // openUserWorklogs={openUserWorklogs(w._id, w.hours, w)}
          />
        ))}
      </div>
    </div>
  );
};

EquipmentBlock.propTypes = {
  classes: propTypes.object.isRequired,
  equipment: propTypes.array.isRequired,
  getFormattedHoursRange: propTypes.func.isRequired,
  openAdditionalDrawer: propTypes.func.isRequired,
  hasAccess: propTypes.bool.isRequired,
};

export default withStyles(styles)(EquipmentBlock);
