import React, { useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog } from '@material-ui/core';
import { useForm } from 'react-hook-form';

import { CipCheck, CipQuestions, LunchQuestions } from './components';

import { useTimeStates } from 'hooks/useTimeStates';
import { apiCalls } from '../../apiCalls';
import { styles } from './styles';
import { hasPermissionsFor, checkUserPermissions } from 'helpers/_helpers';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { InjuryCheck } from './components/InjuryCheck/InjuryCheck';
import { InjuryReport } from './components/InjuryReport/InjuryReport';
import { InjuryReportConfirmation } from './components/InjuryReportConfirmation/InjuryReportConfirmation';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';

const useStyles = makeStyles(styles);

const CLOCK_OUT_STAGES = {
  cipCheck: 'cipCheck',
  cipQuestions: 'cipQuestions',
  lunchQuestions: 'lunchQuestions',
  injuryCheck: 'injuryCheck',
  injuryReport: 'injuryReport',
  injuryReportConfirmation: 'injuryReportConfirmation',
};

const ClockOutModal = ({ userId, openSnackbar, clockedIn, onClose, onSuccess, settings }) => {
  const classes = useStyles();
  const { minutes } = useTimeStates({ takeSettingsHours: true });
  const userPermissions = useSelector((store) => store.kiosk.fullUserPermissions);
  const location = useLocation();
  const isMobileKiosk = location.pathname === '/mobile-kiosk';
  const getTimeFormatHook = useTimeSettingsFormat();
  const formatForTimePars = getTimeFormatHook().formatForTimePars;

  const [stage, setStage] = useState(CLOCK_OUT_STAGES.lunchQuestions);
  const [cipProjectFormData, setSipProjectFormData] = useState(null);
  const [cipPermission, setCipPermission] = useState(false);

  useEffect(() => {
    if (settings?.injuryReport) {
      setStage(CLOCK_OUT_STAGES.injuryCheck);
    } else if (cipPermission) {
      setStage(CLOCK_OUT_STAGES.cipCheck);
    } else {
      setStage(CLOCK_OUT_STAGES.lunchQuestions);
    }
  }, [settings, cipPermission]);

  useEffect(() => {
    if (!userId) return;

    if (isMobileKiosk) {
      setCipPermission(checkUserPermissions(['cipKioskMode'], userPermissions));
    } else {
      setCipPermission(hasPermissionsFor('cipKioskMode'));
    }
  }, [userId]);

  const lunchOptions = useMemo(
    () => [
      { label: 'No', value: false },
      { label: 'Yes', value: true },
    ],
    []
  );

  const clockOutForm = useForm({
    defaultValues: {
      lunchTime: lunchOptions[0].value,
      hours: 0,
      minutes: minutes[0],
    },
  });

  const { getValues } = clockOutForm;

  const handleSubmit = () => {
    const values = getValues();

    const clockingOutWithCip = async () => {
      try {
        const clockOutBody = {
          cip: cipProjectFormData.map((formData) => ({
            hours: +formData.hours + +formData.minutes / 60,
            cipProjectId: formData.project,
          })),
          lunchTime: values.lunchTime ? +values.hours + +values.minutes / 60 : 0,
        };

        await apiCalls.clockOut(clockOutBody);

        openSnackbar('success', `Clocked out at ${format(new Date(), formatForTimePars)}`);
        onSuccess();
      } catch (e) {
        console.log(e);
        openSnackbar('error', e?.response?.data?.message || e.message);
      }
    };

    const clockingOut = async () => {
      try {
        const body = {
          cip: cipProjectFormData
            ? cipProjectFormData.map((formData) => ({
                cipProjectId: formData.project,
                hours: +formData.project,
              }))
            : [],
          lunchTime: values.lunchTime ? +values.hours + +values.minutes / 60 : 0,
        };

        await apiCalls.clockOut(body);
        openSnackbar('success', `Clocked out at ${format(new Date(), formatForTimePars)}`);
        onSuccess();
      } catch (e) {
        console.log(e);
        openSnackbar('error', e?.response?.data?.message || e.message);
      }
    };

    const isRestValid = (restTimeInSeconds) => {
      const workTimeInSeconds = (new Date() - new Date(clockedIn.inTime)) / 1000;

      return workTimeInSeconds - restTimeInSeconds > 0;
    };

    if (cipProjectFormData) {
      const cipTime = cipProjectFormData.reduce(
        (res, curr) => res + +curr.hours * 3600 + +curr.minutes * 60,
        0
      );
      const lunchTime = +values.hours * 3600 + +values.minutes * 60;
      if (!isRestValid(lunchTime ? lunchTime + cipTime : cipTime)) {
        openSnackbar(
          'error',
          'Your time for CIP projects and/or lunch are greater than time clock'
        );
        return;
      }

      clockingOutWithCip();
      return;
    }

    if (!isRestValid(values.lunchTime ? +values.hours * 3600 + +values.minutes * 60 : 0)) {
      openSnackbar('error', 'Lunch time cannot be greater than time clock');
      return;
    }
    clockingOut();
  };

  const onCipFormSubmit = (data) => {
    setStage(CLOCK_OUT_STAGES.lunchQuestions);
    setSipProjectFormData(data);
  };

  const confirmInjuryReport = async (e) => {
    try {
      cipPermission
        ? setStage(CLOCK_OUT_STAGES.cipCheck)
        : setStage(CLOCK_OUT_STAGES.lunchQuestions);
      await apiCalls.injuryReportConfirmation();
    } catch (err) {
      console.log(e);
      openSnackbar('error', e?.response?.data?.message || e.message);
    }
  };

  const modalShow = (sequence) => {
    switch (sequence) {
      case CLOCK_OUT_STAGES.injuryCheck:
        return (
          <InjuryCheck
            classes={classes}
            onClose={onClose}
            onNegative={() =>
              cipPermission
                ? setStage(CLOCK_OUT_STAGES.cipCheck)
                : setStage(CLOCK_OUT_STAGES.lunchQuestions)
            }
            onPositive={() => setStage(CLOCK_OUT_STAGES.injuryReportConfirmation)}
          />
        );
      case CLOCK_OUT_STAGES.injuryReportConfirmation:
        return (
          <InjuryReportConfirmation
            classes={classes}
            onClose={onClose}
            onNegative={() => setStage(CLOCK_OUT_STAGES.injuryReport)}
            onPositive={confirmInjuryReport}
            openSnackbar={openSnackbar}
          />
        );
      case CLOCK_OUT_STAGES.injuryReport:
        return (
          <InjuryReport
            classes={classes}
            onClose={onClose}
            onCancel={() =>
              cipPermission
                ? setStage(CLOCK_OUT_STAGES.cipCheck)
                : setStage(CLOCK_OUT_STAGES.lunchQuestions)
            }
            onSuccess={() =>
              cipPermission
                ? setStage(CLOCK_OUT_STAGES.cipCheck)
                : setStage(CLOCK_OUT_STAGES.lunchQuestions)
            }
            openSnackbar={openSnackbar}
          />
        );
      case CLOCK_OUT_STAGES.cipCheck:
        return (
          <CipCheck
            classes={classes}
            onClose={onClose}
            onNegative={() => setStage(CLOCK_OUT_STAGES.lunchQuestions)}
            onPositive={() => setStage(CLOCK_OUT_STAGES.cipQuestions)}
          />
        );
      case CLOCK_OUT_STAGES.cipQuestions:
        return (
          <CipQuestions
            classes={classes}
            onClose={onClose}
            setSipProjectFormData={setSipProjectFormData}
            onCipFormSubmit={onCipFormSubmit}
          />
        );
      default:
        return (
          <LunchQuestions
            classes={classes}
            onClose={onClose}
            handleSubmit={handleSubmit}
            clockOutForm={clockOutForm}
          />
        );
    }
  };

  return (
    <Dialog
      open
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        classes: {
          root: classes.dialogRoot,
        },
      }}
    >
      {modalShow(stage)}
    </Dialog>
  );
};

export default React.memo(ClockOutModal);
