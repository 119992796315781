import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import SearchForm from '../../../AvailabilityCalendar/components/AssignModal/components/SearchForm/SearchForm';
import DialogContent from '@material-ui/core/DialogContent';
import ResourcesList from '../../../AvailabilityCalendar/components/AssignModal/components/ResourcesList/ResourcesList';
import Button from '@material-ui/core/Button';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../AvailabilityCalendar/components/AssignModal/styles';
import DialogActions from '@material-ui/core/DialogActions';

const AssignModal = ({ classes, selectedResources, setSelectedResources, handleClose, small, isIndividual }) => {
  const [searchValue, setSearchValue] = useState('');
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [selected, setSelected] = useState(selectedResources);

  const updateResources = (data) => (e) =>
    setSelected((prev) =>
      prev.find((el) => el.id === data.id) ? prev.filter((el) => el.id !== data.id) : [...prev, data]
    );
  const updateAllSelectedFlag = () =>{
    setIsChanged(true);
    setIsAllSelected((prev) => !prev)
  };

  const createHeaderCounter = () => {
    const ending = selected.length === 1 ? '' : 's';
    return `${selected.length} user${ending} selected`;
  };

  const handleAssign = () => {
    setSelectedResources(selected);
    handleClose();
  };

  return (
    <div className={classnames(classes.rightSidebar, classes.holiday, small ? classes.holidaySm : '')}>
      <div className={classes.header}>
        <Typography variant="h4">{isIndividual ? 'Assign' : 'Exclude'} People</Typography>
      </div>

      <div className={classes.content}>
        <Divider />
        <SearchForm searchValue={searchValue} updateSearchValue={setSearchValue} />
        <Divider />
        <Typography className={classes.selectedCount}>{createHeaderCounter()}</Typography>
        <Divider />
      </div>

      <DialogContent classes={{ root: classes.content }}>
        <ResourcesList
          type="people"
          usedResources={[]}
          selectedResources={selected}
          updateResources={updateResources}
          selectedDates={[]}
          isAllSelected={isAllSelected}
          updateAllResources={setSelected}
          searchValue={searchValue}
          isChanged={isChanged}
        />
      </DialogContent>

      <div className={classes.btnHolder}>
        <Button
          color="secondary"
          variant="text"
          className={classnames(classes.extraBtn, classes.selectBtn)}
          onClick={updateAllSelectedFlag}
          disableTouchRipple
        >
          {isAllSelected ? 'Clear' : 'Select'} all
        </Button>
      </div>
      <DialogActions classes={{ root: classes.rootActions }}>
        <Button onClick={handleClose} variant="outlined" className={classes.cancelBtn} disableTouchRipple>
          Cancel
        </Button>
        <Button onClick={handleAssign} variant="outlined" className={classes.confirmBtn} disableTouchRipple>
          {isIndividual ? 'Assign' : 'Exclude'}
        </Button>
      </DialogActions>
    </div>
  );
};

export default withStyles(styles)(AssignModal);
