import React from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import classnames from 'classnames';
import styles from './styles';

const PageLoader2 = ({ classes, loading, children, small }) => {
  return (
    <div className={classnames(loading && (small ? classes.smallHeight : classes.root))}>
      {loading && (
        <div className={classes.progressWrapper}>
          <CircularProgress className={classes.progress} />
        </div>
      )}
      {children}
    </div>
  );
};

PageLoader2.propTypes = {
  classes: propTypes.object.isRequired,
  loading: propTypes.bool.isRequired,
  children: propTypes.element.isRequired,
  small: propTypes.bool
};

export default withStyles(styles)(PageLoader2);
