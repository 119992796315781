import React from 'react';
import { Formik, Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import PageLoader from 'components/PageLoader';
import { isIOS, Option } from 'components/Option/Option';
import styles from './styles';
import { subscriptions } from '../../constants';

const Filters = ({ classes, closeDrawer, filtersReset, filters, onUpdateFilters }) => {
  return (
    <div className={classes.formWrapper} role="presentation">
      <Formik
        initialValues={filters}
        enableReinitialize={true}
        onSubmit={(values) => {
          onUpdateFilters(values);
        }}
      >
        {({ values, handleChange }) => (
          <PageLoader loading={false}>
            <Form autoComplete="off" className={classes.formFlex}>
              <div>
                <Typography variant="h3" className={classes.marginHeader}>
                  Filters
                </Typography>

                <IconButton
                  className={classes.closeButton}
                  onClick={closeDrawer}
                  aria-label="Close"
                  disableTouchRipple
                >
                  <CloseIcon />
                </IconButton>

                <Grid container spacing={2} className={classes.flexContainer}>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="subscription" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Subscription:
                      </Typography>
                      <TextField
                        select
                        id="subscription"
                        name="subscription"
                        value={values.subscription}
                        onChange={handleChange}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Subscription' }}
                        fullWidth
                        SelectProps={{
                          native: isIOS,
                        }}
                      >
                        <Option key={1} value="All">
                          All
                        </Option>
                        {subscriptions.map((el) => (
                          <Option key={el} value={el}>
                            {el}
                          </Option>
                        ))}
                      </TextField>
                    </label>
                  </Grid>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="active" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Status:
                      </Typography>
                      <TextField
                        select
                        id="active"
                        name="active"
                        value={values.active}
                        onChange={handleChange}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Status' }}
                        fullWidth
                        SelectProps={{
                          native: isIOS,
                        }}
                      >
                        <Option key={1} value="All">
                          All
                        </Option>
                        <Option key={2} value={true}>
                          Active
                        </Option>
                        <Option key={3} value={false}>
                          Inactive
                        </Option>
                      </TextField>
                    </label>
                  </Grid>
                </Grid>
                <button className={classes.linkButton} onClick={filtersReset}>
                  Reset all filters
                </button>
              </div>
              <div className={classes.buttonHolder}>
                <Button color="secondary" className={classes.cancelButton} onClick={closeDrawer}>
                  Cancel
                </Button>
                <div className={classes.verticalDivider} />
                <Button type="submit" color="primary" className={classes.saveButton}>
                  Apply
                </Button>
              </div>
            </Form>
          </PageLoader>
        )}
      </Formik>
    </div>
  );
};

export default withStyles(styles)(Filters);
