import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {},
  delimiter: {
    textAlign: 'center',
    color: '#202020',
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: '0.17px',
    lineHeight: '21px',
    minWidth: 12,
    maxWidth: 18,
    width: '100%',
    display: 'inline-block',
  },
}));
