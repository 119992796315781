import React from 'react';
import { Button, CloseButton, DialogActions } from '../base';
import { DialogTitle, DialogDescription, ExportForm, Dialog } from './styled';

const ExportExcelModal = ({ onChange, onCancel, onSubmit, value, onClose, open, chooseUser }) => {
  return (
    <Dialog open={open}>
      <CloseButton onClick={onClose} />
      <DialogTitle disableTypography={true}>Excel export</DialogTitle>
      <DialogDescription>
        Select a date period to export. <br />
        Exports a date period including the day selected.
      </DialogDescription>
      {value && <ExportForm value={value} onChange={onChange} chooseUser={chooseUser} />}
      <DialogActions disableSpacing={false}>
        <Button
          variant={'outlined'}
          color={'secondary'}
          fullWidth={true}
          onClick={() => onCancel()}
        >
          Cancel
        </Button>
        <Button variant={'outlined'} fullWidth={true} onClick={() => onSubmit()}>
          Download
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExportExcelModal;
