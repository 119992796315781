import $api from 'http/index';
import { getOptions } from 'helpers/getOptions';

export const notesResolve = async (comment, noteId) => {
  const res = await $api.post(
    `${process.env.REACT_APP_BASE_URL}/notes/${noteId}/resolve`, { comment },
    getOptions()
  );
  return res.data;
};
