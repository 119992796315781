import { useMemo } from 'react';
import { getHiddenResources } from '../helpers/getHiddenResources';

export const useHiddenResources = (props) => {
  const hiddenResources = useMemo(
    () =>
      getHiddenResources({
        isXXLWidth: props.isXXLWidth,
        workers: props.sheet.workers,
        equipment: props.sheet.equipment,
      }),
    [props.sheet, props.isXXLWidth]
  );
  return { hiddenResources };
};
