import formatNumber from 'pages/TimeClock/utils/formatNumber';

export const FooterBlock = ({ day }) => {
  return (
    <>
      <tr class="border-top">
        <td colspan="3 text-left">Hours this week: {formatNumber(day.weekHours)}</td>
        <td colspan="5">
          {/* {{#if isNegative shopTime}}
                <span class='error right' style='margin-bottom: 0; display: inline-block;'>Project hours are greater than timeclock hours.</span>
              {{/if}} */}
        </td>
        <td class="text-right" colspan="1">
          <strong>Shop Time:</strong>
        </td>
        <td colspan="1">{day.shopTime}</td>
      </tr>
    </>
  );
};
