export default (theme) => ({
  search: {
    width: '100%',
    
    '& input': {
      boxSizing: 'border-box',
      padding: 6,
      height: 31,
    },
  },
  searchIcon: {
    color: theme.palette.textColor.secondary,
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 8,
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
});
