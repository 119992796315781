import { useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';
import { exportWorklogsExcel } from './apiCalls';

export default () => {
  const [isExported, setIsExported] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  const onDownload = useCallback(async () => {
    try {
      await exportWorklogsExcel(isExported);
      setIsExported(null)
    } catch (e) {
      enqueueSnackbar('Error creating excel report.', { variant: 'error' });
      console.error(e);
    }
  }, [isExported]);

  return {
    isExported,
    setIsExported,
    onDownload,
  };
};
