import React from 'react';
import IconButton from '../../../../../../../../components/core/IconButton/IconButton';
import ClockIcon from '../../../../../../../../components/icons/Clock';
import { Grid } from '@material-ui/core/index';
import { TotalTime } from '../../../styled';
import { Root } from './styled';

const TimeclockItemEmpty = ({ data, onChange, ...props }) => (
  <Root {...props}>
    <Grid container spacing={1} alignItems="center" justifyContent={'flex-end'}>
      <Grid item xs={3}>
        <dl className={'dl'}>
          <dt className={'dt'}>Clock-In:</dt>
          <dd className={'dd'}>-</dd>
        </dl>
      </Grid>
      <Grid item xs={3}>
        <dl className={'dl'}>
          <dt className={'dt'}>Clock-Out:</dt>
          <dd className={'dd'}>-</dd>
        </dl>
      </Grid>
      <Grid item xs={3} />
      <Grid item xs={3}>
        <Grid container justifyContent={'flex-end'} alignItems={'center'} spacing={4}>
          <Grid item xs={'auto'}>
            <Grid container justifyContent={'space-between'} spacing={1} style={{ width: 50 }}>
              <Grid item xs={12}>
                <IconButton
                  onClick={(e) => {
                    e.preventDefault();
                    onChange(data);
                  }}
                >
                  <ClockIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={'auto'}>
            <TotalTime style={{ width: 50 }}>-</TotalTime>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Root>
);

export default TimeclockItemEmpty;
