export default (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
  },
  fileDescription: {
    marginLeft: 10,
  },
  fileIcon: {
    display: 'inline-block',
    width: 28,
    height: 28,
    float: 'left',
  },
  fileName: {
    display: 'block',
    color: '#202020',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '18px',
  },
  fileSize: {
    display: 'block',
    color: '#A4A5B3',
    fontFamily: 'Open Sans',
    fontSize: 12,
    lineHeight: '10px',
  },
  downloadIcon: {
    width: 16,
    height: 16,
    cursor: 'pointer',
  },
  fileDescriptionWrapp: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
});
