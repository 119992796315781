import React, { useCallback, useState, useEffect, useMemo, useRef } from 'react';
import UsersCarousel from '../../../../components/UsersCarousel';
import Report from '../Report';
import { hasPermissionsFor } from 'helpers/_helpers';
import { useDispatch, useSelector } from 'react-redux';
import timeclocksPageActions from 'store/timeclocks/timeclocksPageActions';
import SearchInput from '../../../../components/form/SearchInput/SearchInput';
import debounce from 'lodash/debounce';
import useTimeclockActions from '../../hooks/useTimeclockActions';
import useTimeclocks from '../../hooks/useTimeclocks';
import {
  getWeekTimeclockSummary,
  fetchTimeclockUsers,
} from 'store/timeclocks/timeclocksPageOperations';
import { getAdjacentUsers } from 'api/_utility';
import Fuse from 'fuse.js';

const searchOptions = {
    shouldSort: true,
    keys: ['username', 'profile.employeeNum', 'profile.fullName'],
  };

export const formatUserOptions = (users) =>
  users.map((u) => ({
    title: `${u.username} / ${u.profile.employeeNum} / ${u.profile.fullName}`,
    value: u._id,
  }));

const ByIndividualWeek = ({ classes }) => {
  const dispatch = useDispatch();
  const [fuse, setFuse] = useState(null);
  const [search, setSearch] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const { users, weekSummary, loading } = useSelector((state) => state.timeclocks);
  const allUsers = useSelector((state) => state.people.users);

  const timeclocks = useTimeclocks();
  const { onChange, onRemove, onCreate, onChangeLunchTime } = useTimeclockActions(timeclocks, 'byIndividualWeek');

  useEffect(() => {
    dispatch(fetchTimeclockUsers());
  }, []);

  const getWeekTimeclockByQueryId = useCallback(debounce((id) => {
    dispatch(getWeekTimeclockSummary(id));
  }, 300),[]);

  useEffect(() => {
    if (!weekSummary.query?.period || !users?.current?._id) {
      dispatch(timeclocksPageActions.setLoading(false))
      return;
    };
    dispatch(timeclocksPageActions.setLoading(true))

    if (users?.current?._id) {
      getWeekTimeclockByQueryId(users.current._id);
    }


  }, [weekSummary.query?.period, users?.current]);

  const onSearchFocus = useCallback(async (e) => {}, []);


  const onChangeUser = (userId) => {
    let userIndex = 0;
    const chosenUserIndex = allUsers.findIndex((user) => user._id === userId);
    if (chosenUserIndex !== -1) {
      userIndex = chosenUserIndex;
    }
    
    const users = {
      current: {
        ...allUsers[userIndex],
        userIndex: userIndex,
      },
    };

    const { prev, next } = getAdjacentUsers(allUsers, users.current);
    users.previous = prev;
    users.next = next;

    dispatch(timeclocksPageActions.setUsers(users));
    dispatch(timeclocksPageActions.setWeekSummary({
      ...weekSummary,
      query: {
        ...weekSummary.query,
        userId,
      },
    }));
  };

  const onUserSelect = ({ value }) => {
    onChangeUser(value);
  };

  useMemo(() => {
    setFuse(new Fuse(allUsers, searchOptions));
  }, [allUsers]);

  const usersForSearch = formatUserOptions(fuse && search ? fuse.search(`${search}`) : allUsers);

  return (
    <>
      <div className={classes.filters}>
        <SearchInput
          placeholder={'Search by User Name & Full Name & Employee #'}
          onFocus={onSearchFocus}
          value={search}
          loading={searchLoading}
          onInput={(e) => {
            setSearchLoading(true);
            setSearch(e.target.value);
            setSearchLoading(false);
          }}
          onSelect={onUserSelect}
          options={usersForSearch}
        />
      </div>
        <>
          <UsersCarousel className={classes.usersSlider} onChange={onChangeUser} {...users} />
          {weekSummary.data && (
            <Report
              className={classes.report}
              data={weekSummary.data}
              onChangeLunchTime={onChangeLunchTime}
              onCreateTimeclock={onCreate}
              onRemoveTimeclock={onRemove}
              onChangeTimeclock={onChange}
              editable={hasPermissionsFor('timeClockFullAccess')}
            />
          )}
        </>
    </>
  );
};
export default ByIndividualWeek;
