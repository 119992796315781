import React, { Component } from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';

import LoadingSpinner from 'components/LoadingSpinner';
import SnackBar from 'components/SnackBar';
import SchedulePage from './components/SchedulePage/index';
import { getEquipment, getWorkers } from 'store/schedule/schedulePageOperation';
import { getPermissions } from 'store/permissions/permissionOperations';
import {
  CALENDAR_EVENTS_EVENT,
  EQUIPMENT_EVENT,
  WORKER_EVENT,
  SHEET_EVENT,
  HOLIDAYS_EVENT,
  CONTRACTORS_EVENT,
  PROJECTS_EVENT,
  SETTINGS_EVENT,
} from 'store/schedule/subscriptions';
import AdminMobileCalendar from './components/AdminMobileSchedulePage/AdminMobileSchedulePage';
import { checkUserRole, isMobileSchedule } from 'helpers/_helpers';
import schedulePageActions from 'store/schedule/schedulePageActions';
import { fetchPersonalProfile } from 'store/personalProfile/personalProfileOperations';
import personalProfileActions from 'store/personalProfile/personalProfileActions';

class ScheduleWrapper extends Component {
  state = {
    snackbar: {
      isOpen: false,
      text: '',
      type: 'success',
    },
    loading: true,
  };

  openSnackbar = (type, text) => this.setState({ snackbar: { isOpen: true, type, text } });

  closeSnackbar = () => this.setState({ snackbar: { ...this.state.snackbar, isOpen: false } });

  componentDidMount() {
    this.setState({ loading: false });
    this.props.getEquipment();
    this.props.getWorkers();
    !this.props.currentUser && this.props.getCurrentUser();
    this.props.socketsConnect();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props.currentUser) return;
    const justGotUser = !prevProps.currentUser && this.props.currentUser;
    const userChanged = !justGotUser && prevProps.currentUser._id !== this.props.currentUser._id;
    const permissionWasNotChecked =
      this.props.permissions.loading === 0 &&
      !this.props.permissions.hasOwnProperty('scheduleFullAccess');
    if (justGotUser || userChanged || permissionWasNotChecked) {
      this.props.getPermissions('scheduleFullAccess');
    }
  }

  shouldComponentUpdate(nextProps) {
    return !nextProps.loading;
  }

  componentWillUnmount() {
    this.props.socketsDisconnect();
  }

  render() {
    const { loading } = this.props;
    const { snackbar } = this.state;

    const isMobile = isMobileSchedule();

    return (
      <div>
        {loading || this.state.loading ? (
          <div
            style={{
              display: 'flex',
              alignItem: 'center',
              justifyContent: 'center',
              marginTop: '100px',
            }}
          >
            <LoadingSpinner height={40} margin={32} />
          </div>
        ) : (
          <>
            {(isMobile) ? (
              <AdminMobileCalendar />
            ) : (
              <>
                <SchedulePage openSnackbar={this.openSnackbar} />
                <SnackBar closeSnackbar={this.closeSnackbar} {...snackbar} />
              </>
            )}
          </>
        )}
      </div>
    );
  }
}

ScheduleWrapper.propTypes = {
  loading: propTypes.bool.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  getEquipment: (...args) => {
    dispatch(getEquipment(args));
  },
  getWorkers: (...args) => {
    dispatch(getWorkers(args));
  },
  getCurrentUser: (...args) => {
    dispatch(fetchPersonalProfile(...args));
  },
  getPermissions: (...args) => {
    dispatch(getPermissions(...args));
  },
  socketsConnect: (...args) => {
    dispatch(schedulePageActions.socketConnect(SHEET_EVENT));
    dispatch(schedulePageActions.socketConnect(CALENDAR_EVENTS_EVENT));
    dispatch(schedulePageActions.socketConnect(EQUIPMENT_EVENT));
    dispatch(schedulePageActions.socketConnect(WORKER_EVENT));
    dispatch(schedulePageActions.socketConnect(HOLIDAYS_EVENT));
    dispatch(schedulePageActions.socketConnect(CONTRACTORS_EVENT));
    dispatch(schedulePageActions.socketConnect(PROJECTS_EVENT));
    dispatch(personalProfileActions.socketConnect(SETTINGS_EVENT));
  },
  socketsDisconnect: (...args) => {
    dispatch(schedulePageActions.socketDisconnect(SHEET_EVENT));
    dispatch(schedulePageActions.socketDisconnect(CALENDAR_EVENTS_EVENT));
    dispatch(schedulePageActions.socketDisconnect(EQUIPMENT_EVENT));
    dispatch(schedulePageActions.socketDisconnect(WORKER_EVENT));
    dispatch(schedulePageActions.socketDisconnect(HOLIDAYS_EVENT));
    dispatch(schedulePageActions.socketDisconnect(CONTRACTORS_EVENT));
    dispatch(schedulePageActions.socketDisconnect(PROJECTS_EVENT));
    dispatch(personalProfileActions.socketDisconnect(SETTINGS_EVENT));
  }
});

export default connect(
  (state) => ({
    currentUser: state.personalProfile.user,
    loading: state.schedule.loading > 0,
    permissions: state.permissions,
  }),
  mapDispatchToProps
)(ScheduleWrapper);
