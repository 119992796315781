import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  switchTypeBtn: {
    padding: 4,
    marginRight: 5,
    '&:last-child': {
      marginRight: 0,
    },
    '&:hover': {
      background: theme.palette.background.default,
    },
    '&:focus': {
      background: theme.palette.background.default,
    },
  },
}));
