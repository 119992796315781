import React, { useEffect, useState, useCallback } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Drawer, Typography } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroller';

import LoadingSpinner from '../../components/LoadingSpinner';
import NotesHeader from './components/NotesHeader';
import NotesList from './components/NotesList';
import SortingAndFilteringPanel from './components/SortingAndFilteringPanel';
import NoteCreationForm from '../../components/NoteCreationForm';
import FiltersBar from '../../components/FiltersBar';
import styles from './styles';
import { drawerTypes } from '../Equipment/components/helpers/drawerTypes';
import { useNavigate } from 'react-router-dom';
import { getNotes } from './apiCalls';
import { LIMIT } from './apiCalls';

export const defaultFilters = {
  sortBy: 'date',
  sortOrder: 'desc',
}

const NotesPage = ({ classes, openSnackbar }) => {
  const navigate = useNavigate();
  const [visibleProjects, setVisibleProjects] = useState([]);
  const [drawer, setDrawer] = useState({
    type: drawerTypes.filters,
    isOpen: false,
  });
  const [filter, setFilter] = useState(defaultFilters);
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);

  useEffect(() => {
    updateVisibleProjects();
  }, [searchValue, filter]);

  const updateVisibleProjects = async (
    argFilter = filter,
    argPage = page,
    propSearchValue = searchValue
  ) => {
    setIsLoading(true);
    await getNotes(argFilter, argPage, propSearchValue)
      .then((data) => {
        setVisibleProjects((prev) => (page === 0 ? data : [...prev, ...data]));
        setPage((prev) => prev + 1);
        setHasMore(data.length === LIMIT);
        setDrawer((prev) => ({ ...prev, isOpen: false }));
      })
      .catch((e) => {
        openSnackbar('error', e.message);
      });
    setIsLoading(false);
  };

  const openDrawer = (type) => () => setDrawer({ type, isOpen: true });
  const closeDrawer = () => setDrawer((prev) => ({ ...prev, isOpen: false }));

  const onNoteCreate = () => {
    closeDrawer();
    updateVisibleProjects();
  };

  const openProjectNotes = (projectId) => (e) => {
    navigate(`/notes/${projectId}`);
  };

  const updateFilter = useCallback((filters) => {
    setPage(0);
    setFilter(filters);
  }, []);
  const updateSearchValue = useCallback((value) => {
    setPage(0);
    setSearchValue(value);
  }, []);

  const loadMore = () => {
    if (!hasMore || isLoading) return;
    updateVisibleProjects(filter, page, searchValue);
  };

  const isHasMore = () => {
    if (!hasMore) return false;
    return true;
  };

  return (
    <div className={classes.root}>
      <NotesHeader openDrawer={openDrawer} />
      <FiltersBar openDrawer={openDrawer} updateSearchValue={updateSearchValue} isDynamicSearch={true}/>
      {isLoading && (
        <div style={{ display: 'flex', alignItem: 'center', justifyContent: 'center' }}>
          <LoadingSpinner height={40} margin={32} />
        </div>
      )}
      {!!visibleProjects.length && (
        <InfiniteScroll
          pageStart={0}
          loadMore={loadMore}
          hasMore={isHasMore()}
          loader={
            <div key={0} style={{ display: 'flex', alignItem: 'center', justifyContent: 'center' }}>
              <LoadingSpinner height={40} margin={32} />
            </div>
          }
          threshold={400}
        >
          <NotesList
            projects={visibleProjects}
            openProjectNotes={openProjectNotes}
            updateVisibleProjects={loadMore}
          />
        </InfiniteScroll>
      )}
      {searchValue && !visibleProjects.length && (
        <Typography variant="h3" align="center" style={{ margin: '0.8em 0 2em', opacity: 0.5 }}>
          No Results
        </Typography>
      )}
      <Drawer
        anchor="right"
        open={drawer.isOpen}
        classes={{
          paper: classes.drawer,
        }}
      >
        {drawer.type !== drawerTypes.filters ? (
          <NoteCreationForm
            onSuccess={onNoteCreate}
            closeDrawer={closeDrawer}
            openSnackbar={openSnackbar}
          />
        ) : (
          <SortingAndFilteringPanel
            closeDrawer={closeDrawer}
            filter={filter}
            updateFilter={updateFilter}
            isLoading={isLoading}
          />
        )}
      </Drawer>
    </div>
  );
};

NotesPage.propTypes = {
  classes: propTypes.object.isRequired,
  openSnackbar: propTypes.func.isRequired,
};

export default withStyles(styles)(NotesPage);
