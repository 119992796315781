import React, { forwardRef } from 'react';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import { MEASUREMENT_UNITS, MEASURE_UNITS_BY_SYSTEM } from 'common/measurements';

export const GridDND = ({ children, classes }) => {
  return <div className={classes.dndWrapper}>{children}</div>;
};

export const SortableItem = (props) => {
  const { isDragging, attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: props.id,
    disabled: !!props.editIndex,
  });
  const style = {
    // transform: CSS.Transform.toString(transform),
    transform: CSS.Translate.toString(transform),
    transition: transition || undefined,
  };

  return (
    <Item
      ref={setNodeRef}
      style={style}
      withOpacity={isDragging}
      {...props}
      {...attributes}
      {...listeners}
    />
  );
};

export const Item = forwardRef(
  (
    {
      id,
      withOpacity,
      isDragging,
      style,
      remove,
      elem,
      editIndex,
      index,
      type,
      measurement,
      setItems,
      typeOfLabel,
      classes,
      ...props
    },
    ref
  ) => {
    const inlineStyles = {
      opacity: withOpacity ? '0.5' : '1',
      background: editIndex === index ? '#6288e8' : 'rgb(90, 135, 239,0.18)',
      cursor: isDragging ? 'grabbing' : 'pointer',
      ...style,
    };

    const getItemName = () => {
      const widthUnit = MEASURE_UNITS_BY_SYSTEM[measurement][MEASUREMENT_UNITS.width].replace(/in/, '"')
      if (typeOfLabel === 'line')
        return `${elem?.width}${widthUnit} - ${elem?.ratio} ${MEASURE_UNITS_BY_SYSTEM[measurement][type === 'volume' ? MEASUREMENT_UNITS.lengthPerVolume : MEASUREMENT_UNITS.lengthPerWeight]}`;
      if (typeOfLabel === 'legend') return `${elem.symbol}`;
      if (typeOfLabel === 'lettering') return `${elem.name}`;
      if (typeOfLabel === 'glassBead')
        return `${elem.ratio} ${MEASURE_UNITS_BY_SYSTEM[measurement][elem.unitType]}`;
    };

    return (
      <div ref={ref} style={inlineStyles} className={classes.dndItem} {...props}>
        <span
          style={{
            color: editIndex === index ? '#fff' : '#5A87EF',
          }}
          className={classes.dndItemText}
        >
          {getItemName()}
        </span>
        <IconButton
          style={{
            padding: 0,
            margin: 0,
            color: Number.isInteger(editIndex)
              ? editIndex === index
                ? 'rgb(98, 136, 232)'
                : '#e2eafc'
              : '#5A87EF',
            background: 'transparent',
          }}
          onClick={
            Number.isInteger(editIndex)
              ? null
              : (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  remove(index);
                  setItems((items) => items.filter((el) => el._id !== elem._id));
                }
          }
          aria-label="remove"
          disableTouchRipple
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </div>
    );
  }
);
