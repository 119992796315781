import { createReducer } from '@reduxjs/toolkit';
import authActions from './authActions';

const initialState = null;

const auth = createReducer(initialState, {
  [authActions.setCurrentUser]: (_, { payload }) => payload,
});

export default auth;
