import React, { useState } from 'react';
import propTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';

import PageLoader from '../../../../components/PageLoader';
import EquipmentDetails from './components/EquipmentDetails';
import styles from './styles';
import { drawerTypes } from '../helpers/drawerTypes';
import { postCreateEquipment, putUpdateEquipment } from 'store/equipment/equipmentPageOperations';
import { useDispatch } from 'react-redux';

const validation = (values) => {
  const errors = {};
  if (!values.name.trim()) {
    errors.name = "This field can't be blank";
  }
  if (!values.number.trim()) {
    errors.number = "This field can't be blank";
  }
  if (`${values.yearMade}`.trim()) {
    if (!/^[0-9]{4}$/i.test(values.yearMade)) {
      errors.yearMade = 'The number is invalid. The field can contain 4 figures only';
    } else if (parseInt(values.yearMade) < 1800 || `${values.yearMade}`.length !== 4) {
      errors.yearMade = 'Invalid year number';
    }
  }
  return errors;
};

const EquipmentForm = ({
  classes,
  closeDrawer,
  openSnackbar,
  equipment,
  type,
  updateVisibleEquipment,
}) => {
  const dispatch = useDispatch();
  const [palette, setPalette] = useState(() => [
    '#f0592a',
    '#f00',
    '#f90',
    '#ff0',
    '#0f0',
    '#0ff',
    '#00f',
    '#90f',
    '#f0f',
    '#c00',
    '#e69138',
    '#f1c232',
    '#6aa84f',
    '#45818e',
    '#3d85c6',
    '#674ea7',
    '#a64d79',
    '#900',
    '#b45f06',
    '#bf9000',
    '#38761d',
    '#134f5c',
    '#0b5394',
    '#351c75',
    '#741b47',
    '#600',
    '#783f04',
    '#7f6000',
    '#274e13',
    '#0c343d',
    '#073763',
    '#20124d',
    '#4c1130',
  ]);

  const initialValues = equipment
    ? {
        name: equipment.name,
        color: equipment.color || palette[0],
        number: equipment.number || '',
        yearMade: equipment.yearMade || '',
        model: equipment.model || '',
        description: equipment.description || '',
      }
    : {
        name: '',
        color: palette[0],
        number: '',
        yearMade: '',
        model: '',
        description: '',
      };

  return (
    <div className={classes.formWrapper} role="presentation">
      <Formik
        validate={validation}
        validateOnChange={false}
        validateOnBlur={true}
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={async (values, { setFieldError, setSubmitting }) => {
          const data = {
            ...values,
            yearMade: +values.yearMade || undefined,
          };

          if (type === drawerTypes.create) {
            try {
              await dispatch(postCreateEquipment(data));
              openSnackbar('success', 'Successfully Added!');
              closeDrawer();
            } catch (err) {
              openSnackbar('error', err?.response?.data?.message || err.message);
              setSubmitting(false);
              if (err.error === 'equipment-number-must-be-unique') {
                setFieldError('number', 'This Equipment Number is already taken');
              }
            }
          } else {
            try {
              await dispatch(putUpdateEquipment(data, equipment._id));
              openSnackbar('success', 'Successfully Updated!');
              closeDrawer();
            } catch (err) {
              console.log(err?.response?.data);
              openSnackbar('error', err?.response?.data?.message || err.message);
              setSubmitting(false);
              if (err.error === 'equipment-number-must-be-unique') {
                setFieldError('number', 'This Equipment Number is already taken');
              }
            }
          }
        }}
      >
        {({ errors, touched, values, handleChange, handleBlur, isSubmitting, setFieldValue }) => (
          <PageLoader loading={isSubmitting}>
            <Form autoComplete="off" className={classes.formFlex}>
              <div>
                <Typography variant="h3" style={{ marginBottom: '40px' }}>
                  {type === drawerTypes.create ? 'Create' : 'Edit'} Equipment
                </Typography>

                <IconButton
                  className={classes.closeButton}
                  onClick={closeDrawer}
                  aria-label="Close"
                  disableTouchRipple
                >
                  <CloseIcon />
                </IconButton>

                <EquipmentDetails
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  palette={palette}
                />
              </div>

              <div className={classes.buttonHolder}>
                <Button
                  color="secondary"
                  variant="outlined"
                  className={classes.cancelButton}
                  onClick={closeDrawer}
                  disabled={isSubmitting}
                  disableTouchRipple
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  variant="outlined"
                  className={classes.saveButton}
                  disabled={isSubmitting}
                  disableTouchRipple
                >
                  Save
                </Button>
              </div>
            </Form>
          </PageLoader>
        )}
      </Formik>
    </div>
  );
};

EquipmentForm.propTypes = {
  classes: propTypes.object.isRequired,
  closeDrawer: propTypes.func.isRequired,
  openSnackbar: propTypes.func.isRequired,
  updateVisibleEquipment: propTypes.func.isRequired,
  type: propTypes.string.isRequired,
  equipment: propTypes.object,
};

export default withStyles(styles)(EquipmentForm);
