import React, { Component } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { parse, format } from 'date-fns';
import { formatDate, parseDate } from 'react-day-picker/moment';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import cn from 'classnames';
import './styles.css';
import { connect } from 'react-redux';
import { getWeekStartDayNumO } from 'helpers/_helpers';
import { dateSettingsFormat, momentAdepter } from 'common/dateFormatConfig';

class DatePickerInputMobile extends Component {
  state = {
    isDayPickerOpen: false,
    displayedDate: format(
      parse(this.props.selectedDay, this.props.dateFormat, new Date()),
      dateSettingsFormat(this.props.dateFormat).dayDateSettingsFormat
    ),
  };
  ref = React.createRef();

  handleDayChange = (day) => {
    this.props.setFieldValue(this.props.field, format(new Date(day), this.props.dateFormat));
    this.setState({
      isDayPickerOpen: false,
      displayedDate: format(
        new Date(day),
        dateSettingsFormat(this.props.dateFormat).dayDateSettingsFormat
      ),
    });
  };

  createDisabledDayRules = () => {
    const disabledDays = [];
    if (this.props.maxDate) {
      disabledDays.push({ after: parse(this.props.maxDate, this.props.dateFormat, new Date()) });
    }
    if (this.props.minDate) {
      disabledDays.push({ before: parse(this.props.minDate, this.props.dateFormat, new Date()) });
    }
    return disabledDays;
  };

  formatSelectedDate = () => {
    return parse(this.props.selectedDay, this.props.dateFormat, new Date());
  };
  close = () => {
    this.ref.current?.hideDayPicker();
    this.setState((prevState) => ({
      ...prevState,
      isDayPickerOpen: false,
    }));
  };
  toggle = () => {
    this.setState(
      (prevState) => ({ isDayPickerOpen: !prevState.isDayPickerOpen }),
      () => {
        if (this.state.isDayPickerOpen) {
          this.ref.current?.showDayPicker();
        } else {
          this.ref.current?.hideDayPicker();
        }
      }
    );
  };
  render() {
    const classname1 = this.props.field === 'endDate' ? 'endDatePicker' : '';
    const className2 = this.props.alignRight ? 'endDatePicker' : '';
    const className3 = this.props.fullWidth ? 'fullWidth' : '';
    const className4 = this.props.sm ? 'sm' : '';
    const selectedDayNameActive = this.state.isDayPickerOpen ? 'selectedDayNameActive' : '';

    const modifiers = {
      disabled: this.createDisabledDayRules(),
    };
    const { userWeekends, weekStart } = this.props;
    if (userWeekends && userWeekends.length) {
      modifiers.weekends = { daysOfWeek: userWeekends };
    }

    return (
      <div className={`${classname1} ${className2} ${className3} ${className4}`}>
        <ClickAwayListener onClickAway={this.close}>
          <div className="dayPickerWrapperMob endDatePicker fullWidth">
            {!!this.props.disabled && (
              <div className="selectedDayNameDisable">
                {format(
                  parse(this.props.selectedDay, this.props.dateFormat, new Date()),
                  dateSettingsFormat(this.props.dateFormat).dayDateSettingsFormat
                )}
              </div>
            )}
            {!this.props.disabled && (
              <>
                <div
                  className={cn('selectedDayNameMob', `${selectedDayNameActive}`)}
                  onClick={this.toggle}
                >
                  {format(
                    parse(this.props.selectedDay, this.props.dateFormat, new Date()),
                    dateSettingsFormat(this.props.dateFormat).dayDateSettingsFormat
                  )}
                </div>
                <DayPickerInput
                  ref={this.ref}
                  formatDate={formatDate}
                  parseDate={parseDate}
                  format={
                    momentAdepter(dateSettingsFormat(this.props.dateFormat).dayDateSettingsFormat)
                      .momentShortDayDate
                  } //* @ "ddd MM-DD-YYYY"(settings format for ex. MM-DD-YYYY)
                  value={this.formatSelectedDate()}
                  onDayChange={this.handleDayChange}
                  inputProps={{
                    disabled: !!this.props.disabled,
                  }}
                  dayPickerProps={{
                    firstDayOfWeek: getWeekStartDayNumO(weekStart),
                    selectedDays: this.formatSelectedDate(),
                    modifiers,
                  }}
                />
              </>
            )}
          </div>
        </ClickAwayListener>
      </div>
    );
  }
}

export default connect((state) => ({
  weekStart: state.settings?.settings?.weekStart || state.kiosk.company?.settings?.weekStart || 'Sun',
  dateFormat: state.personalProfile.organization?.settings?.dateFormat || state.kiosk.company?.settings?.dateFormat,
}))(DatePickerInputMobile);
