export default theme => ({
  textField: {
    [theme.breakpoints.up('lg')]: {
      // maxWidth: 250,
    },
  },
  smallScreenNoTopPadding: {
    [theme.breakpoints.down('md')]: {
      paddingTop: '0px !important'
    }
  },
  textArea: {
    '& textarea': {
      boxShadow: 'none',
      padding: 10,
      fontSize: 14,
      height: '60px !important',
      maxHeight: '60px !important'
    }
  },
  marginVertical: {
    margin: '20px 0',
  },
  shiftImage: {
    height: 16,
    margin: '0 5px 2px 0',
  },
  passwordToggler: {
    width: 50,
    textAlign: 'center',
    color: theme.palette.textColor.primary,
    cursor: 'pointer'
  },
  error: {
    color: theme.palette.textColor.red,
    padding: '0 5px',
    fontSize: 11
  },
  requiredSign: {
    color: theme.palette.textColor.red
  },
  uplWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& p':{
      marginBottom: 0,
      color: '#5A87EF',
    }
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& p':{
      marginBottom: 0,
    }
  },
  imagePreview: {
    width: 150,
    height: 150,
    objectFit: 'contain'

  },
  deleteButton: {
    color: theme.palette.error.red,
    verticalAlign: 'top',
    minWidth: 20,
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff !important',
      color: `${theme.palette.error.red} !important`,
    }
  },
  imagesWrapper: {
    display: 'flex',
    paddingTop: '0 !important',
    flexWrap: 'wrap',
    '& > div': {
      width: 190
    }
  }
});
