import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import SheetItem from './components/SheetItem';
import styles from './styles';

class WorklogsPrintPage extends PureComponent {
  state = {
    isHeaderLogoReadyArray: this.props.sheets.map((el) => ({ id: el._id, value: false })),
    isFooterLogoReady: false,
    hasPrinted: false,
    isAllImgReady: !this.props.printImg,
    totalStateImgQuantity: this.props.sheets.reduce((accumulator, currentValue) => {
      if (!!currentValue?.images?.length) {
        return accumulator + currentValue?.images?.length || 0;
      } else return accumulator;
    }, 0),
    tmpStateImgQuantity: 0,
  };

  componentDidUpdate() {
    const { isHeaderLogoReadyArray, isFooterLogoReady, hasPrinted, isAllImgReady } = this.state;
    if ((this.state.tmpStateImgQuantity) >= this.state.totalStateImgQuantity) {
      this.setState({ isAllImgReady: true });
    }
    if (
      isHeaderLogoReadyArray.every((el) => el.value) &&
      isFooterLogoReady &&
      isAllImgReady &&
      !hasPrinted
    ) {
      this.setState({ hasPrinted: true }, () =>
        setTimeout(() => {
          window.print();
        }, 200)
      );
    }
  }

  updateHeaderLogoLoadedValue = (id) => () => {
    if (this.state.hasPrinted) return;
    const newIsHeaderLogoReady = this.state.isHeaderLogoReadyArray.map((el) => {
      if (el.id === id && !el.value) {
        return { id: el.id, value: true };
      } else return el;
    });
    this.setState({ isHeaderLogoReadyArray: newIsHeaderLogoReady });
  };

  updateFooterLoadedState = () => {
    if (!this.state.isFooterLogoReady) {
      this.setState({ isFooterLogoReady: true });
    }
  };

  updateAllImgReadyState = () => {
    if ((this.state.tmpStateImgQuantity + 1) >= this.state.totalStateImgQuantity) {
      this.setState({ isAllImgReady: true });
    } else {
      this.setState((prevState) => ({
        ...prevState,
        tmpStateImgQuantity: prevState.tmpStateImgQuantity + 1,
      }));
    }
  };

  render() {
    const { classes, sheets, isPrintById } = this.props;
    return (
      <div className={classes.wrapper}>
        {sheets.map((sheet, i) => (
          <div key={sheet._id} className={classes.root}>
            <SheetItem
              sheet={sheet}
              updateHeaderLogoLoadedValue={this.updateHeaderLogoLoadedValue}
              updateFooterLoadedState={this.updateFooterLoadedState}
              isPrintById={isPrintById}
              updateAllImgReadyState={this.updateAllImgReadyState}
              printImg={this.props.printImg}
              i={i}
            />
          </div>
        ))}
      </div>
    );
  }
}

WorklogsPrintPage.propTypes = {
  classes: propTypes.object.isRequired,
  sheets: propTypes.array.isRequired,
};

export default withStyles(styles)(WorklogsPrintPage);
