export default (theme) => ({
  rootContainer: {
    padding: '0 10px',
  },
  wrapper: {
    display: 'flex',
    '& > div': {
      paddingRight: 4,
      width: '100%',
    },
    '& input': {
      pointerEvents: 'none',
    },
  },
  delimiter: {
    alignSelf: 'center',
    paddingRight: 4,
  },
  label: {
    marginBottom: 4,
    display: 'flex',
    flexDirection: 'column',
  },
  dateContainer: {
    '& input': {
      cursor: 'pointer!important',
      margin: '8px 0!important',
      padding: '0.3rem .4rem!important',
      fontSize: '.8rem!important',
      backgroundColor: `${theme.palette.background.default}!important`,
    },
  },
});
