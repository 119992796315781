import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { useResources } from './hooks/useResources';
import { useGetEquipmentInfo } from '../../../../hooks/useGetEquipmentInfo';
import {
  getCalendarEvents,
  getResourcesTimeoffsAndHolidays,
} from 'store/schedule/schedulePageOperation';
import styles from './styles';
import { WarningToolTip } from '../WarningToolTip/WarningToolTip';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';

export const RESOURCE_TYPE = {
  equipment: 'equipment',
  people: 'people',
};

const ResourcesList = ({ classes, searchValue, isAdminMobile }) => {
  const { dateFormatWithTime, dateFormat } = useDateSettingsFormat();
  const containerRef = useRef(null);
  const dispatch = useDispatch();
  const date = useSelector((state) => state.schedule.selectedDate);
  const scheduleAvailability = useSelector((state) => state.schedule.availability);

  const { userList, equipmentList, canDragResources } = useResources();
  const [visibleUsers, setVisibleUsers] = useState(userList);
  const [visibleEquipment, setVisibleEquipment] = useState(equipmentList);

  const handleHolidayCreation = () => dispatch(getCalendarEvents(date));

  useEffect(() => {
    dispatch(getResourcesTimeoffsAndHolidays(date));
  }, [date]);

  useEffect(() => {
    const trimmedSearchValue = searchValue.trim();
    if (!trimmedSearchValue) return setVisibleUsers(userList);

    setVisibleUsers(() =>
      userList.filter((user) =>
        user.username.toLowerCase().includes(trimmedSearchValue.toLowerCase())
      )
    );
  }, [userList, searchValue]);

  useEffect(() => {
    const trimmedSearchValue = searchValue.trim();
    if (!trimmedSearchValue) return setVisibleEquipment(equipmentList);

    setVisibleEquipment(() =>
      equipmentList.filter((item) =>
        item.name.toLowerCase().includes(trimmedSearchValue.toLowerCase())
      )
    );
  }, [equipmentList, searchValue]);

  const dataReady = useMemo(
    () => visibleUsers && visibleEquipment,
    [visibleUsers, visibleEquipment]
  );

  const { getEquipmentInfo } = useGetEquipmentInfo(visibleEquipment);

  const setDragElementData = useCallback(
    (resourceId, resourceType) => (e) => {
      e.dataTransfer.setData('resourceId', resourceId);
      e.dataTransfer.setData('resourceType', resourceType);
    },
    []
  );

  const resources = useMemo(
    () => [
      {
        title: 'People',
        data: visibleUsers,
        unavailableClass: 'unavailableUser',
        resourceType: 'workers',
        timeoffs: scheduleAvailability.timeoffs,
        holidays: scheduleAvailability.holidays,
        type: RESOURCE_TYPE.people,
        Template: ({ item }) => (
          <div
            className={classnames(
              classes.flexBox,
              item.isUsed && classes.usedResource,
              canDragResources && classes.cursorPointer
            )}
          >
            <Typography
              variant="body1"
              color="textPrimary"
              className={classnames(
                classes.username,
                classes[item.profile.shifts.timeOfDay.toLowerCase()]
              )}
            >
              {item.username} ({item.profile.shifts.weekDays})
            </Typography>
          </div>
        ),
      },
      {
        title: 'Equipment',
        data: visibleEquipment,
        unavailableClass: 'unavailableItem',
        resourceType: 'equipments',
        holidays: scheduleAvailability.holidays,
        timeoffs: scheduleAvailability.calendarEvents,
        type: RESOURCE_TYPE.equipment,
        Template: ({ item }) => (
          <div
            className={classnames(
              classes.equipmentBlock,
              getEquipmentInfo(item._id, 'className'),
              item.isUsed && classes.usedResource,
              canDragResources && classes.cursorPointer
            )}
            style={{ backgroundColor: getEquipmentInfo(item._id, 'color') }}
          >
            <div className={classes.equipmentNumber}>{getEquipmentInfo(item._id, 'name')}</div>
          </div>
        ),
      },
    ],
    [visibleEquipment, visibleUsers, classes, getEquipmentInfo, scheduleAvailability]
  );

  return (
    <div
      ref={containerRef}
      className={classnames(isAdminMobile ? classes.flexColum : classes.resourcesWrapper)}
    >
      {resources.map(
        ({ title, resourceType, data, unavailableClass, Template, timeoffs, type, holidays }) => (
          <div className={classes.column} key={title}>
            <Typography variant="body1" color="textPrimary" className={classes.marginBottomBig}>
              {title}
            </Typography>
            {dataReady && (
              <>
                {!data.length && (
                  <Typography variant="body1" color="textPrimary">
                    No Results
                  </Typography>
                )}
                {!!data.length &&
                  data.map((item) => (
                    <div className={classes.resourceWrapper}>
                      <WarningToolTip
                        classes={classes}
                        resource={item}
                        timeoffs={timeoffs}
                        scheduleDate={date}
                        dateFormatWithTime={dateFormatWithTime}
                        dateFormat={dateFormat}
                        containerRef={containerRef}
                        type={type}
                        holidays={holidays}
                      />
                      <div
                        key={item._id}
                        className={classnames(
                          classes.marginBottom,
                          classes.itemWrapper,
                        )}
                        draggable={canDragResources}
                        onDragStart={setDragElementData(item._id, resourceType)}
                      >
                        <Template item={item} />
                      </div>
                    </div>
                  ))}
              </>
            )}
          </div>
        )
      )}
    </div>
  );
};

ResourcesList.propTypes = {
  classes: propTypes.object.isRequired,
  searchValue: propTypes.string.isRequired,
};

export default withStyles(styles)(ResourcesList);
