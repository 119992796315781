import React, { useMemo } from 'react';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@material-ui/core';
import { Controller } from 'react-hook-form';

import { CloseButton } from 'pages/TimeClock/modals/base';
import { useTimeStates } from 'hooks/useTimeStates';
import { isIOS, Option } from 'components/Option';

const createOption = (option) => (
  <Option value={option.value} key={option.value}>
    {option.label}
  </Option>
);

export const LunchQuestions = ({ classes, onClose, handleSubmit, clockOutForm }) => {
  const {
    control,
    clearErrors,
    formState: { errors },
    watch,
    reset,
  } = clockOutForm;
  const { hours, minutes } = useTimeStates({
    hourMaxValue: 13,
    hourStart: 0,
    takeSettingsHours: true,
    overlapsHourMaxValue: true,
  });

  const lunchOptions = useMemo(
    () => [
      { label: 'No', value: false },
      { label: 'Yes', value: true },
    ],
    []
  );

  const lunchTime = watch('lunchTime');

  return (
    <>
      <DialogTitle
        disableTypography
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <Typography variant="h6">Clock Out</Typography>
        <CloseButton style={{ top: 16 }} onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column">
          <label htmlFor="lunchTime">
            <Typography variant="body1" color="textSecondary">
              Did you take a lunch break?
            </Typography>
            <Controller
              control={control}
              name="lunchTime"
              render={({ value }) => (
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  value={value}
                  onChange={(e) =>
                    reset({ lunchTime: e.target.value, hours: 0, minutes: minutes[0] })
                  }
                  className={classes.lunchTime}
                  inputProps={{
                    'aria-label': 'Did you take a lunch break?',
                  }}
                  SelectProps={{
                    native: isIOS,
                  }}
                >
                  {lunchOptions.map(createOption)}
                </TextField>
              )}
            />
          </label>
          <Box display="flex" justifyContent="space-between">
            <label style={{ width: '48%' }} htmlFor="hours">
              <Typography variant="body1" color="textSecondary">
                Hours
              </Typography>
              <Controller
                control={control}
                name="hours"
                render={({ onChange, value }) => (
                  <TextField
                    select
                    variant="outlined"
                    fullWidth
                    disabled={!lunchTime}
                    value={value}
                    onChange={onChange}
                    error={!!errors.hours}
                    className={classes.password}
                    inputProps={{
                      'aria-label': 'Hours',
                    }}
                    SelectProps={{
                      native: isIOS,
                    }}
                  >
                    {hours.map((hour) => createOption({ value: hour, label: hour }))}
                  </TextField>
                )}
              />
              {errors.hours ? <div className={classes.error}>{errors.hours.message}</div> : null}
            </label>
            <label style={{ width: '48%' }} htmlFor="minutes">
              <Typography variant="body1" color="textSecondary">
                Minutes
              </Typography>
              <Controller
                control={control}
                name="minutes"
                render={({ onChange, value }) => (
                  <TextField
                    select
                    variant="outlined"
                    fullWidth
                    disabled={!lunchTime}
                    value={value}
                    onChange={(e) => {
                      clearErrors();
                      onChange(e.target.value);
                    }}
                    error={!!errors.minutes}
                    className={classes.minutes}
                    inputProps={{
                      'aria-label': 'Hours',
                    }}
                    SelectProps={{
                      native: isIOS,
                    }}
                  >
                    {minutes.map((minute) => createOption({ value: minute, label: minute }))}
                  </TextField>
                )}
              />
              {errors.hours ? <div className={classes.error}>{errors.hours.message}</div> : null}
            </label>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="outlined"
          color="primary"
          className={classes.cancelBtn}
          disableTouchRipple
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          className={classes.confirmBtn}
          disableTouchRipple
        >
          Save
        </Button>
      </DialogActions>
    </>
  );
};
