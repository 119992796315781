import { Box, withStyles } from '@material-ui/core';

export const Root = withStyles({
  root: {
    position: 'relative',
    display: 'inline-block',
    paddingBottom: 32,
  },
})(Box);

export const RemoveBtn = withStyles({
  root: {
    position: 'absolute',
    right: -30,
    bottom: 0,
  },
})(Box);

export const Value = withStyles({
  root: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    color: '#DD6060',
    fontFamily: 'Lucida Grande',
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: '20px',
  },
})(Box);

export const Label = withStyles({
  root: {
    position: 'absolute',
    left: -110,
    bottom: 0,
    color: '#DD6060',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '20px',
  },
})(Box);
