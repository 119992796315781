import { makeStyles } from '@material-ui/core';

export default makeStyles({
  root: {
    padding: '20px 30px',
    maxWidth: '1280px',
    margin: '0 auto',
  },
  header: {
    marginBottom: 10,
  },
  tabs: {
    // marginBottom: 20,
    borderBottom: '1px solid #e8e8e8',
  },
});
