import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import kioskPageActions from 'store/kiosk/kioskPageActions';
import Box from '../../components/Field';
import Kiosk from '../../components/Kiosk';
import { useKioskUser } from './hooks/useKioskUser';
import './styles.scss';
import { getKioskUsers } from 'store/kiosk/kioskPageOperations';
import { removeKey } from 'helpers/localStorage';
import { KIOSK_EVENT } from 'subscriptions/events/events.enum';

export const MobileKiosk = () => {
  const dispatch = useDispatch();
  const { user, authorize, logout, error: authError, clearError } = useKioskUser();
  const userList = useSelector((store) => store.kiosk.users);

  const logoutFromStore = () => {
    removeKey('x-auth-kiosk-token');
    dispatch(kioskPageActions.logout());
    dispatch(kioskPageActions.clearPersonalProfile());
  };

  useEffect(() => {
    logoutFromStore();

    dispatch(getKioskUsers());
    return () => logoutFromStore();
  }, []);

  useEffect(() => {
    // sockets
    dispatch(kioskPageActions.socketConnect(KIOSK_EVENT));

    return () => {
      dispatch(kioskPageActions.socketDisconnect(KIOSK_EVENT));
    };
  }, []);

  return (
    <Box display="flex" flexDirection="column" className="kioskModeWrapper">
      <Box className="page-width fullTab">
        <Box
          style={{
            padding: '0 15px',
            boxSizing: 'border-box',
            maxWidth: '1280px',
            margin: '0 auto',
          }}
        >
          <Kiosk
            logoutFromStore={logoutFromStore}
            authError={authError}
            clearAuthError={clearError}
            selectedUser={user}
            userList={userList}
            authorize={authorize}
            logout={logout}
          />
        </Box>
      </Box>
    </Box>
  );
};
