import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import UserDetails from './components/UserDetails/UserDetails';
import PageLoader from '../../components/PageLoader';
import { validation, emergencyValidation } from './validation';
import SnackBar from '../../components/SnackBar/SnackBar';
import {
  fetchPersonalProfile,
  patchPersonalProfile,
  changePasswordPersonalProfile,
} from 'store/personalProfile/personalProfileOperations';
import { generateUpdateBody } from './request-body';
import photoPlaceholder from 'assets/images/user-photo-placeholder.svg';
import { drawerTypes } from './components/drawerTypes';
import { Drawer } from '@material-ui/core';

import './styles.scss';
import EditEmergencyContactForm from './components/EditEmergencyContactForm/index';
import { hasPermissionsFor } from 'helpers/_helpers';
import { fetchHICodes } from 'store/people/hiCodesOperations';

const ProfileSettings = () => {
  const user = useSelector((store) => store.personalProfile.user);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isNewEmergencyBlock, setIsNewEmergencyBlock] = useState(false);
  const [snackbar, setSnackbar] = useState(null);
  const [isAdmin, setIsAdmin] = useState(null);
  const [emergencyError, setEmergencyError] = useState({});
  const [drawer, setDrawer] = useState({
    type: null,
    isOpen: false,
    data: null,
    index: null,
  });

  useEffect(() => {
    if (
      user?.profile?.role?.roleName === 'Admin' ||
      user?.profile?.role?.roleName === 'Super Admin'
    )
      setIsAdmin(true);
    if (
      user?.profile?.role?.roleName === 'Accounting' && 
      hasPermissionsFor('usersFullAccess')
    )
      setIsAdmin(true);
  }, [user]);

  const initialValues = useMemo(() => {
    const userValues = user
      ? {
          name: user.username,
          roleName: user.profile.role.roleName || '',
          fullName: user.profile.fullName || '',
          email: user.profile.email || '',
          phoneNumber: user.profile.phoneNumber || '',
          employeeNum: user.profile.employeeNum || '',
          HICode: user.profile.HICode,
          timeOfDay: user.profile.shifts.timeOfDay,
          weekDays: user.profile.shifts.weekDays,
          allowedTime: user.profile.timeoff.allowedTime || 0,
          emergencyContacts: user.profile.emergencyContacts || [],
        }
      : {
          name: '',
          roleName: '',
          fullName: '',
          email: '',
          phoneNumber: '',
          employeeNum: '',
          HICode: '00',
          timeOfDay: 'Day',
          weekDays: 'Mon-Fri',
          allowedTime: 0,
          emergencyContacts: [],
        };

    return {
      ...userValues,
      userPass: '',
      newPass: '',
      emergencyName: '',
      emergencyPhone: '',
      emergencyRelationship: '',
      emergencyAdress: '',
    };
  }, [user]);

  useEffect(() => {
    const loadUser = async () => {
      setIsLoading(true);

      await dispatch(fetchPersonalProfile()).catch((err) =>
        setSnackbar({ type: 'error', text: err.message })
      );

      setIsLoading(false);
    };
    if (!user) {
      loadUser();
    }
  }, []);

  useEffect(() => {
    if (isAdmin) {
      dispatch(fetchHICodes());
    }
  }, [isAdmin])

  const clearErrors = (prop) => {
    if (!prop) {
      setEmergencyError({});
    } else {
      const errorsObject = Object.assign({}, emergencyError);
      delete errorsObject[prop];
      setEmergencyError(errorsObject);
    }
  };

  const handleEmergencyName = (setFieldValue, id, i, values) => (e) => {
    let index = id ? values.emergencyContacts.findIndex((el) => el._id === id) : i;
    setFieldValue(`emergencyContacts[${index}].name`, e.target.value.trim());
  };
  const handleEmergencyPhoneNumber = (setFieldValue, id, i, values) => (e) => {
    let index = id ? values.emergencyContacts.findIndex((el) => el._id === id) : i;
    setFieldValue(`emergencyContacts[${index}].phoneNumber`, e.target.value.trim());
  };
  const handleEmergencyAddress = (setFieldValue, id, i, values) => (e) => {
    let index = id ? values.emergencyContacts.findIndex((el) => el._id === id) : i;
    setFieldValue(`emergencyContacts[${index}].address`, e.target.value.trim());
  };

  const handleNewEmergencyName = (setFieldValue) => (e) => {
    clearErrors('emergencyName');
    const value = e.target.value;
    setFieldValue('emergencyName', value);
  };
  const handleNewEmergencyPhoneNumber = (setFieldValue) => (e) => {
    clearErrors('emergencyPhone');
    const value = e.target.value;
    setFieldValue('emergencyPhone', value);
  };
  const handleNewEmergencyRelationship = (setFieldValue) => (e) => {
    clearErrors('emergencyRelationship');
    const value = e.target.value;
    setFieldValue('emergencyRelationship', value);
  };

  const addNewEmergencyContact = (setFieldValue, values) => async (e) => {
    const errorKeys = Object.keys(emergencyValidation(values));

    if (!errorKeys.length) {
      setIsNewEmergencyBlock(false);
      const newContact = {
        // id: Math.floor(+new Date() + Math.random() * 0xffffffff).toString(36),
        relationship: values.emergencyRelationship.trim(),
        name: values.emergencyName.trim(),
        phoneNumber: values.emergencyPhone.trim(),
        address: values.emergencyAdress.trim(),
      };
      setFieldValue('emergencyContacts', [...values.emergencyContacts, newContact]);
      setFieldValue('emergencyRelationship', '');
      setFieldValue('emergencyName', '');
      setFieldValue('emergencyPhone', '');
      setFieldValue('emergencyAdress', '');

      clearErrors();
    } else {
      setEmergencyError(emergencyValidation(values));
    }
  };

  const openNewEmergencyBlock = (e) => {
    setIsNewEmergencyBlock(true);
  };

  const setUpNewPass = (setFieldValue, values, setFieldTouched) => async () => {
    setFieldTouched('userPass', true, true);
    const errorKeys = Object.keys(validation(values));
    if (!errorKeys.includes('userPass')) {
      try {
        await changePasswordPersonalProfile(values.userPass);
        setSnackbar({ type: 'success', text: 'Your password has been changed!' });
        setFieldValue('userPass', '');
      } catch (err) {
        setSnackbar({ type: 'error', text: err.message });
      }
    }
  };

  const cancelResetForm = (resetForm) => (e) => {
    resetForm(initialValues);
    setIsNewEmergencyBlock(false);
    clearErrors();
  };

  const closeNewEmergencyBlock = (setFieldValue) => (e) => {
    setIsNewEmergencyBlock(false);
    clearErrors();
    setFieldValue('emergencyRelationship', '');
    setFieldValue('emergencyName', '');
    setFieldValue('emergencyPhone', '');
    setFieldValue('emergencyAdress', '');
  };

  const deleteContact = (setFieldValue, id, i, values) => (e) => {
    const contactArr = id
      ? values.emergencyContacts.filter((el) => el.id !== i)
      : values.emergencyContacts.filter((el, index) => index !== i);
    setFieldValue('emergencyContacts', contactArr);
  };

  const openDrawer = (type, data, index) => () => setDrawer({ type, isOpen: true, data, index });
  const closeDrawer = () => setDrawer({ type: null, isOpen: false, data: null, index: null });

  return (
    <section>
      <div className={'profile-settings-wrapper'} role="presentation">
        <Formik
          validate={(values) => validation(values)}
          validateOnChange={false}
          validateOnBlur={true}
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={async (values, { isSubmitting }) => {
            if (isSubmitting) return;
            try {
              setIsNewEmergencyBlock(false);
              setEmergencyError({});
              await dispatch(patchPersonalProfile(generateUpdateBody(values)));
              setSnackbar({ type: 'success', text: 'Your personal profile has been updated!' });
            } catch (err) {
              setSnackbar({ type: 'error', text: err.message });
            }
          }}
        >
          {({
            errors,
            touched,
            values,
            handleChange,
            handleBlur,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
            isValidating,
            resetForm,
            validateForm,
            validateField,
          }) => (
            <PageLoader loading={isLoading}>
              <Form autoComplete="off" className={''}>
                <div>
                  <Typography variant="h3">Profile settings</Typography>

                  <Grid container>
                    <Grid item>
                      <div className={'avatar-block'}>
                        <div className={'profile-img-wrapper'}>
                          <img src={photoPlaceholder} alt="user photo" className={'profile-img'} />
                        </div>

                        <div className={'avatar-block-info'}>
                          <Typography variant="h4">{user && user.username}</Typography>
                          <Typography variant="h5" color="textSecondary">
                            {values.roleName}
                          </Typography>
                        </div>
                      </div>
                    </Grid>
                  </Grid>

                  <UserDetails
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    handleEmergencyName={handleEmergencyName}
                    deleteContact={deleteContact}
                    handleEmergencyPhoneNumber={handleEmergencyPhoneNumber}
                    handleEmergencyAddress={handleEmergencyAddress}
                    addNewEmergencyContact={addNewEmergencyContact}
                    openNewEmergencyBlock={openNewEmergencyBlock}
                    isNewEmergencyBlock={isNewEmergencyBlock}
                    closeNewEmergencyBlock={closeNewEmergencyBlock}
                    setFieldTouched={setFieldTouched}
                    validateForm={validateForm}
                    validateField={validateField}
                    setUpNewPass={setUpNewPass}
                    isAdmin={isAdmin}
                    clearErrors={clearErrors}
                    emergencyError={emergencyError}
                    handleNewEmergencyName={handleNewEmergencyName}
                    handleNewEmergencyPhoneNumber={handleNewEmergencyPhoneNumber}
                    handleNewEmergencyRelationship={handleNewEmergencyRelationship}
                    openDrawer={openDrawer}
                    openSnackbar={setSnackbar}
                  />
                </div>

                <div className={'buttons-block'}>
                  <Button
                    color="secondary"
                    variant="outlined"
                    className={'buttons-block-btn'}
                    onClick={cancelResetForm(resetForm, setFieldValue)}
                    // disabled={isSubmitting}
                    disableTouchRipple
                  >
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    color="primary"
                    variant="outlined"
                    className={'buttons-block-btn'}
                    // disabled={isSubmitting}
                    disableTouchRipple
                  >
                    Save
                  </Button>
                </div>
                <Drawer anchor="right" open={drawer.isOpen}>
                  {drawer.type === drawerTypes.edit && (
                    <EditEmergencyContactForm
                      contact={drawer.data}
                      closeDrawer={closeDrawer}
                      setFieldValue={setFieldValue}
                      index={drawer.index}
                    />
                  )}
                </Drawer>
              </Form>
            </PageLoader>
          )}
        </Formik>
      </div>
      {snackbar && <SnackBar closeSnackbar={() => setSnackbar(null)} {...snackbar} isOpen={true} />}
    </section>
  );
};
export default ProfileSettings;
