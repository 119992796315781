import React, { useState, useEffect, useRef } from 'react';
import cs from 'classnames';
import DatePicker from './components/DatePicker';
import { withStyles, Typography } from '@material-ui/core';
import { fetchMaterialExportOptions } from 'pages/Worklogs/components/hooks/apiCalls';
import styles from './styles';
import ConfigItemPicker from './components/ConfigItemPicker/ConfigItemPicker';

const componentConfig = [
  {
    label: 'Job Number',
    optionName: 'jobNumber',
    shouldRender: () => true,
    placeholder: 'Search by Job Number #',
    inputId: 'export-materials__job-number',
  },
  {
    label: 'CL',
    optionName: 'cl',
    shouldRender: () => true,
    placeholder: 'Search by Crew Leader',
    inputId: 'export-materials__crew-leader',
    getOptionLabel: (option) => option.username,
    getOptionKey: (option) => option._id,
  },
  {
    label: 'Material Name',
    optionName: 'materialName',
    shouldRender: () => true,
    placeholder: 'Search by Material Name',
    inputId: 'export-materials__material-name',
  },
  {
    label: 'Material Unit',
    optionName: 'materialUnit',
    shouldRender: () => true,
    placeholder: 'Search by Material Unit',
    inputId: 'export-materials__material-unit',
  },
  {
    label: 'Material Color',
    optionName: 'materialColor',
    shouldRender: () => true,
    placeholder: 'Search by Material Color',
    inputId: 'export-materials__material-color',
  },
  {
    label: 'Material Specification',
    optionName: 'materialSpecification',
    shouldRender: () => true,
    placeholder: 'Search by Material Specification',
    inputId: 'export-materials__material-specification',
  },
  {
    label: 'Line Width',
    optionName: 'lineWidth',
    shouldRender: (options) => options?.materialSpecification?.includes('Line'),
    placeholder: 'Search by Line Width',
    inputId: 'export-materials__line-width',
  },
  {
    label: 'Symbol',
    optionName: 'legendSymbol',
    shouldRender: (options) => options?.materialSpecification?.includes('Legend'),
    placeholder: 'Search by Symbol',
    inputId: 'export-materials__symbol',
  },
  {
    label: 'Lettering',
    optionName: 'letteringText',
    shouldRender: (options) => options?.materialSpecification?.includes('Lettering'),
    placeholder: 'Search by Lettering',
    inputId: 'export-materials__lettering',
  },
  {
    label: 'Parameters',
    optionName: 'letteringParameters',
    shouldRender: (options) => options?.materialSpecification?.includes('Lettering'),
    placeholder: 'Search by Parameters',
    inputId: 'export-materials__parameters',
  },
];

const ExportForm = ({ className, onChange, classes, value = {} }) => {
  const [options, setOptions] = useState({});
  const inputRef = useRef(null);

  useEffect(() => {
    fetchMaterialExportOptions(value).then((data) =>
      setOptions({ ...data, lineWidth: data.lineWidth.map((number) => `${number}`) })
    );
  }, [value]);

  const handleScrollTo = (index) => {
    if (!inputRef.current) return;
    setTimeout(() => {
      inputRef.current.scrollTop = index * 79;
    }, 0);
  };

  return (
    <form className={cs(className, classes.root)}>
      <div
      className={cs(classes.formWrapper)}
      >
        <DatePicker
          value={value.period}
          className={classes.control}
          onChange={(period) => {
            onChange({ ...value, period });
          }}
        />
      </div>
      <div ref={inputRef} className={cs(classes.scrollableFields)}>
        {componentConfig.map((fieldItem, i) => (
          <>
            {fieldItem.shouldRender(value) ? (
              <div
                className={classes.field}
                key={fieldItem.label}
                onClick={() => {
                  handleScrollTo(i);
                }}
              >
                <Typography variant="body1" color="textSecondary" className={classes.marginBottom}>
                  {fieldItem.label}:
                </Typography>
                <ConfigItemPicker
                  options={options?.[fieldItem.optionName]}
                  placeholder={fieldItem.placeholder}
                  inputId={fieldItem.inputId}
                  onChange={(newValue) => onChange({ ...value, [fieldItem.optionName]: newValue })}
                  {...fieldItem}
                />
              </div>
            ) : null}
          </>
        ))}
      </div>
    </form>
  );
};

export default withStyles(styles)(ExportForm);
