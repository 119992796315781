import notesPageActions from './notesPageActions';
import { getOptions } from 'helpers/getOptions';
import $api from 'http/index';

export const getProjectNotes = () => async (dispatch, getState) => {
  const { filter, searchValue, projectId } = getState().notes;

  const queryParams = new URLSearchParams();
  Object.entries(filter).map(([key, value]) => queryParams.append(key, value));

  if (searchValue) {
    queryParams.append('searchValue', searchValue);
  }

  const res = await $api.get(
    `${process.env.REACT_APP_BASE_URL}/notes/project/${projectId}?${queryParams}`,
    getOptions()
  );
  dispatch(notesPageActions.setNotes(res.data));
  // return res.data;
};

export const postCreateEquipment = (data) => async (dispatch) => {
  const res = await $api.post(`${process.env.REACT_APP_BASE_URL}/equipment`, data, getOptions());
  dispatch(notesPageActions.createNewEquipment(res.data));
};

export const putUpdateEquipment = (data, equipmentId) => async (dispatch) => {
  const res = await $api.put(
    `${process.env.REACT_APP_BASE_URL}/equipment/${equipmentId}`,
    data,
    getOptions()
  );
  dispatch(notesPageActions.updateEquipment(res.data));
};

export const getNotesProjects = () => async (dispatch, getState) => {
  const projectId = getState().notes.projectId;

  try {
    const res = await $api.get(
      `${process.env.REACT_APP_BASE_URL}/projects?isNotes=true`,
      getOptions()
    );
    dispatch(notesPageActions.setAllProjectsNotes(res.data?.projects));
    dispatch(notesPageActions.setProjectNotes(res.data?.projects?.filter((el) => el.active)));

    if(projectId){
      dispatch(notesPageActions.setSelctedByIdProjectNotes(res.data?.projects?.find((el) => el._id === projectId) || {}));
    }
  } catch (e) {
    console.log(e);
  }
};
