import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PrintIcon from 'assets/images/print-icon.svg';
import DownloadIcon from 'assets/images/download-icon.svg';
import Button from '@material-ui/core/Button';
import PageLoader from 'components/PageLoader';
import styles from './styles';
import PicCardList from './components/PicCardList/PicCardList';
import $api from 'http/index';
import { getOptions } from '../../helpers/getOptions';
import { deleteImage } from 'components/WorklogDetails/components/NotesBlock/components/ImagesBlock/components/ImageCard/ImageCard';
import { downloadImageArchive } from 'components/WorklogDetails/components/NotesBlock/components/NotesForm/components/PicCardListWrapper/PicCardListWrapper';
import { WORKLOG_PRINT_QUERY } from 'pages/WorklogPrint/components/constants';

export const fetchImages = async (sheetId) => {
  try {
    const result = await $api.get(
      `${process.env.REACT_APP_BASE_URL}/sheets/${sheetId}/images
    `,
      getOptions()
    );
    return result.data;
  } catch (error) {
    console.error('Error fetch images:', error);
    throw error;
  }
};

export const postCardDescription = async (sheetId, imageId, description) => {
  try {
    const result = await $api.post(
      `${process.env.REACT_APP_BASE_URL}/sheets/${sheetId}/images/${imageId}/description
    `,
      { description },
      getOptions()
    );
    return result.data;
  } catch (error) {
    console.error('Error post images:', error);
    throw error;
  }
};

export const openPrintPage = (sheetId, query, path, additionalQuery ) => {
  const url = `/worklog/print/${sheetId}${path ? `/${path}` : ''}?${query === 'true' ? 'printImg=true' : ''}${additionalQuery ? additionalQuery : ''}`;
  window.open(url, '_blank', 'noreferrer');
};

const ImagesForm = ({ classes, closeDrawer, openSnackbar, sheetId }) => {
  const [imgData, setImgData] = useState([]);

  useEffect(() => {
    fetchImages(sheetId)
      .then((data) => setImgData(data || []))
      .catch((e) => console.log(e));
  }, []);

  const handleDeleteNoteImg = (id) => async () => {
    deleteImage(sheetId, id).then((e) => setImgData((prev) => prev.filter((el) => el._id !== id)));
  };

  const handleEditNoteImg = async (id, description) => {
    const editedState = imgData.map((cardItem) =>
      cardItem._id === id ? { ...cardItem, description } : cardItem
    );
    postCardDescription(sheetId, id, description).then((e) =>
      setImgData(editedState).catch((e) => console.error('Error post images:', e))
    );
  };


// the Images Form in the Notes Page
  return (
    <div className={classes.root}>
      <div className={classes.formWrapper} role="presentation">
        {/* <PageLoader loading={false}> */}
        <div className={classes.title}>
          <Typography variant="h3">Images</Typography>
          <div>
            <Button className={classes.icon} onClick={() => {openPrintPage(sheetId, 'true', null, `&${WORKLOG_PRINT_QUERY.printAllImgsOnSheet}=true`)}} disableRipple>
              <img src={PrintIcon} />
            </Button>
            <Button
              className={classes.icon}
              onClick={() => downloadImageArchive(sheetId)}
              disableRipple
            >
              <img src={DownloadIcon} alt="download" />
            </Button>
            <IconButton onClick={closeDrawer} aria-label="Close" disableRipple disableTouchRipple>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <PicCardList
          imgData={imgData}
          handleDeleteNoteImg={handleDeleteNoteImg}
          handleEditNoteImg={handleEditNoteImg}
          sheetId={sheetId}
        />
        <div className={classes.buttonHolder}>
          <Button
            color="secondary"
            variant="outlined"
            className={classes.cancelButton}
            onClick={closeDrawer}
            disableTouchRipple
          >
            Cancel
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="outlined"
            className={classes.saveButton}
            disableTouchRipple
          >
            Save
          </Button>
        </div>
        {/* </PageLoader> */}
      </div>
    </div>
  );
};

export default withStyles(styles)(ImagesForm);
