import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  relativePosition: {
    position: 'relative',
  },
  filterBtn: {
    width: 85,
    color: theme.palette.textColor.secondary,
    background: theme.palette.background.default,
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.textColor.primary,
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
      background: theme.palette.background.default,
    },
    '& img': {
      marginRight: 8,
    },
    '@media (max-width: 280px)': {
      width: '40px',
    },
  },
  popperContainer: {
    zIndex: 100,
  },
  paper: {
    width: 85,
    padding: 8,
    borderRadius: 0,
    borderColor: theme.palette.borderColor.lightGrey,
  },
  options: {
    fontSize: '0.875rem',
    fontWeight: 500,
    color: theme.palette.textColor.secondary,
    padding: '0 10px',
    margin: '10px 0',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.textColor.primary,
    },
  },
  activeOption: {
    color: theme.palette.textColor.primary,
  },
}));
