export default theme => ({
  root: {
    [theme.breakpoints.up('xl')]: {
      width: 237
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: 8,
      paddingBottom: 8
    }
  },
  paddingBox: {
    padding: '5px 12px 0',
  },
  paddingBottomRemove: {
    paddingBottom: '0 !important'
  },
  smallPaddingTop: {
    paddingTop: '5px !important'
  },
  cardHeaderRoot: {
    padding: '0 12px',
  },
  cardHeaderContent: {
    '& > span': {
      marginBottom: 5,
    }
  },
  cardContent: {
    padding: '0 12px',
    [theme.breakpoints.only('sm')]: {
      padding: 12,
    },
    '&:last-child': {
      paddingBottom: 0
    }
  },
  marginBottomNormal: {
    marginBottom: 8,
  },
  marginBottomSmall: {
    marginBottom: 5,
  },
  shiftDays: {
    textTransform: 'uppercase',
    marginLeft: 10,
  },
  deleteButton: {
    padding: 10,
    width: 170,
    margin: '20px auto 0',
    display: 'flex',
    color: theme.palette.textColor.red,
    '&:hover': {
      color: `${theme.palette.textColor.red} !important`,
      backgroundColor: 'rgba(0, 0, 0, 0.08) !important',
    },
    '&:focus': {
      color: `${theme.palette.textColor.red} !important`,
      backgroundColor: '#fff !important',
    },
    '&:disabled': {
      backgroundColor: '#fff',
    },
  },
  divider: {
    marginBottom: 10,
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    },
    [theme.breakpoints.up('lg')]: {
      display: 'block'
    },
  },
  lastSeen: {
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    },
    [theme.breakpoints.up('lg')]: {
      display: 'block'
    },
  },
  wordBreak: {
    wordBreak: 'break-word',
  },
  userPhotoWrapper: {
    borderRadius: '50%',
    border: `2px solid ${theme.palette.textColor.primary}`,
    borderTopColor: 'transparent',
    borderBottomColor: 'transparent',
    width: 60,
    height: 60,
    transform: 'rotate(135deg)',
  },
  userPhoto: {
    display: 'block',
    transform: 'rotate(-135deg)',
    height: 50,
    margin: '3px auto 0'
  },
  flexBox: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 10px 0',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      width: '100%'
    },
  },
  fixedWidth: {
    width: 125,
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    },
    [theme.breakpoints.up('lg')]: {
      display: 'block'
    },
  },
  expand: {
    transform: 'rotate(0deg)',
    padding: 0,
    '&:hover': {
      color: theme.palette.textColor.secondary,
      backgroundColor: '#fff'
    },
    '&:focus': {
      color: theme.palette.textColor.secondary,
      backgroundColor: '#fff'
    }
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  contentWrapper: {
    padding: 12,
    borderTop: `1px solid ${theme.palette.borderColor.primary}`
  },
  actionsWrapper: {
    borderTop: `1px solid ${theme.palette.borderColor.primary}`,
    justifyContent: 'space-around',
    padding: 5,
    [theme.breakpoints.down('md')]: {
      paddingBottom: 0
    },
  }
});
