export default (theme) => ({
  card: {
    // maxWidth: 237,
    paddingTop: 8,
    paddingBottom: 8,
    [theme.breakpoints.up('xl')]: {
      width: 237,
    },
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    borderRadius: 5,
    margin: '0 0 10px',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  userPhotoWrapper: {
    borderRadius: '50%',
    border: `1px solid ${theme.palette.primary.blue}`,
    borderTopColor: 'transparent',
    borderBottomColor: 'transparent',
    width: 32,
    height: 32,
    transform: 'rotate(135deg)',
  },
  userPhoto: {
    display: 'block',
    transform: 'rotate(-135deg)',
    height: 26,
    margin: '2px auto 0',
  },
  flexBox: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '10px',
    '& p': {
      marginBottom: 0,
      fontSize: '14px',
      marginLeft: '10px',
    },
  },
  flexButt: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px',
    justifyContent: 'flex-end',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  marginBottomSmall: {
    padding: 10,
  },
  paddingSmall: {
    paddingBottom: '5px !important',
  },
  marginBottomNormal: {
    marginBottom: 10,
  },
  divider: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.up('lg')]: {
      display: 'block',
    },
  },
  mainContent: {
    padding: '0 10px',
    '& p': {
      // fontSize: 14,
    },
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  paddingBox: {
    padding: '0 12px',
  },
  uplWrapper: {
    display: 'inline-flex',
    alignItems: 'center',
    '& p': {
      marginBottom: 0,
      color: '#5A87EF',
    },
    '& img': {
      marginRight: '5px',
    },
  },
  deleteButton: {
    minWidth: 28,
    height: 34,
    padding: 5,
    '&:hover': {
      background: '#fff !important',
    },
  },
  redBorder: {
    borderColor: theme.palette.error.red,
  },
  opacity: {
    opacity: 0.4,
  },
  restoreIcon: {
    '&:hover': {
      background: '#fff !important',
    },
    color: theme.palette.primary.blue,
  },
  maxWidthUsername: {
    maxWidth: 105,
  },
});
