import { NavLink, useMatch } from 'react-router-dom';

const CustomLink = ({ children, to, onClick, ...props }) => {
    return (
        <NavLink onClick={onClick} to={to} {...props}>
            {children}
        </NavLink>
    );
};

export default CustomLink;
