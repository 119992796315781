export default {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    color: '#202020',
    fontSize: '1.5rem',
    fontFamily: 'Poppins,Open Sans,sans-serif',
    fontWeight: 500,
    lineHeight: 1.4,
    letterSpacing: '0.3px',
    marginBottom: 3,
  }
}
