export default theme => ({
  search: {
    width: '100%',
    marginBottom: 20,
    '& input': {
      padding: 6,
      height: 31
    }
  },
  searchIcon: {
    color: theme.palette.textColor.secondary,
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 8
  },
  userWrapper: {
    padding: '4px 8px',
    fontSize: '1rem',
    width: '100%',
    boxSizing: 'border-box',
    overflow: 'hidden'
  },
  highlighted: {
    background: theme.palette.background.lightGrey
  },
  day: {
    color: theme.palette.textColor.yellow,
  },
  night: {
    color: theme.palette.textColor.darkBlue,
  },
  inactive: {
    color: theme.palette.textColor.secondary,
  },
  equipmentBlock: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '17px',
    width: 'max-content'
  },
  equipmentNumber: {
    color: theme.palette.textColor.white,
    padding: '2px 4px',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  usedResource: {
    opacity: 0.3
  },
  unavailableResource: {
    backgroundColor: '#b4b4b4 !important',
    color: '#fff !important',
    pointerEvents: 'none',
    '& div': {
      color: '#fff !important'
    }
  }
});
