import { useCallback } from 'react';
import { getOptions } from 'helpers/getOptions';
import { useDispatch } from 'react-redux';
import {
  updateSheetAddResource,
} from 'store/schedule/schedulePageOperation';
import schedulePageActions from 'store/schedule/schedulePageActions';
import $api from 'http/index';

export const useAddResource = () => {
  const dispatch = useDispatch();

  const addResource = useCallback(async ({ sheetId, resources }) => {
    
    const postData = !!resources.workers ? { workers: resources.workers } : { equipment: resources.equipments }
    const path = !!resources.workers ? 'workers' : 'equipment'
    const response = await $api.put(
      `${process.env.REACT_APP_BASE_URL}/sheets/${sheetId}/${path}`,
      postData,
      getOptions(),
    )

    dispatch(updateSheetAddResource(response.data._id, 1));
    dispatch(schedulePageActions.setUnpublishedChanges(true));
    return response.data;
  }, []);

  return { addResource };
};
