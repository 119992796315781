import React from 'react';
import Typography from '@material-ui/core/Typography/index';
import { Grid } from '@material-ui/core';
import IOSSwitch from 'components/IOSSwitch';

const MaterialTicks = ({ errors, values, handleChange, type, setFieldValue }) => {
  return (
    <Grid container xs={12} style={{ borderTop: '1px solid #D9DAE3', marginBottom: '15px' }}>
      <Grid item xs={12} style={{ marginTop: '10px' }}>
        <Grid item xs={8} md={'auto'} style={{ marginBottom: '17px' }}>
          <Typography variant="h5">Material source</Typography>
        </Grid>
        <Grid container spacing={2}>
          <Grid
              item
              xs={6}
              sm={4}
              md={4}
              style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Typography variant="body1" color="textSecondary">
                Manufactured
              </Typography>
              <IOSSwitch
                name="ticks.manufactured"
                small
                onChange={handleChange}
                checked={values?.manufactured}
                disabled={type === ''}
              />
          </Grid>
          <Grid
              item
              xs={6}
              sm={4}
              md={4}
              style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Typography variant="body1" color="textSecondary">
                Fabricated
              </Typography>
              <IOSSwitch
                name="ticks.fabricated"
                small
                onChange={handleChange}
                checked={values?.fabricated}
                disabled={type === ''}
              />
          </Grid>
          <Grid
              item
              xs={6}
              sm={4}
              md={4}
              style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Typography variant="body1" color="textSecondary">
                Coated
              </Typography>
              <IOSSwitch
                name="ticks.coated"
                small
                onChange={handleChange}
                checked={values?.coated}
                disabled={type === ''}
              />
          </Grid>
          <Grid
              item
              xs={6}
              sm={4}
              md={4}
              style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Typography variant="body1" color="textSecondary">
                Precasted
              </Typography>
              <IOSSwitch
                name="ticks.precasted"
                small
                onChange={handleChange}
                checked={values?.precasted}
                disabled={type === ''}
              />
          </Grid>
          <Grid
              item
              xs={6}
              sm={4}
              md={4}
              style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Typography variant="body1" color="textSecondary">
                Produced
              </Typography>
              <IOSSwitch
                name="ticks.produced"
                small
                onChange={handleChange}
                checked={values?.produced}
                disabled={type === ''}
              />
          </Grid>
        </Grid>

        <Grid item xs={8} md={'auto'} style={{ marginBottom: '17px', marginTop: '17px' }}>
          <Typography variant="h5">Composition of material</Typography>
        </Grid>
        <Grid container spacing={2}>
          <Grid
              item
              xs={12}
              sm={8}
              md={8}
              style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Typography variant="body1" color="textSecondary">
                Check if product contains iron or steel
              </Typography>
              <IOSSwitch
                name="ticks.steelPresence"
                small
                onChange={(e) => {
                  handleChange(e);
                  if (!e.target.checked) {
                    (() => {
                      setFieldValue('ticks.usSteel', false);
                      setFieldValue('ticks.foreignSteel', false);
                      setFieldValue('ticks.identifiableSteel', false);
                      setFieldValue('ticks.inPlantSteel', false);
                      setFieldValue('ticks.unidentifiableSteel', false);
                    })()
                  }
                }}
                checked={!!values?.steelPresence}
                disabled={type === ''}
              />
          </Grid>
          <Grid item xs={0} sm={4} md={4} />
          {values?.steelPresence && (
            <Grid
              item
              xs={6}
              sm={4}
              md={4}
              style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Typography variant="body1" color="textSecondary">
                100% US Steel
              </Typography>
              <IOSSwitch
                name="ticks.usSteel"
                small
                onChange={(e) => {
                  console.log(e.target.checked);
                  handleChange(e);
                  if (e.target.checked) {
                    (() => setFieldValue('ticks.foreignSteel', false))();
                  }
                }}
                checked={!!values?.usSteel}
                disabled={type === ''}
              />
            </Grid>
          )}
          {values?.steelPresence && (
            <Grid
              item
              xs={6}
              sm={4}
              md={4}
              style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Typography variant="body1" color="textSecondary">
                Foreign Steel
              </Typography>
              <IOSSwitch
                name="ticks.foreignSteel"
                small
                onChange={(e) => {
                  console.log('values: ', values);
                  handleChange(e);
                  if (e.target.checked) {
                    (() => setFieldValue('ticks.usSteel', false))();
                  }
                }}
                checked={!!values?.foreignSteel}
                disabled={type === ''}
              />
            </Grid>
          )}
          {values?.steelPresence && (
            <Grid item xs={0} sm={4} />
          )}
          {values?.steelPresence && (
            <Grid
              item
              xs={6}
              sm={4}
              md={4}
              style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Typography variant="body1" color="textSecondary">
                Identifiable Steel
              </Typography>
              <IOSSwitch
                name="ticks.identifiableSteel"
                small
                onChange={(e) => {
                  handleChange(e);
                  if (e.target.checked) {
                    (() => {
                      setFieldValue('ticks.inPlantSteel', false)
                      setFieldValue('ticks.unidentifiableSteel', false)
                    })();
                  }
                }}
                checked={!!values?.identifiableSteel}
                disabled={type === ''}
              />
            </Grid>
          )}
          {values?.steelPresence && (
            <Grid
              item
              xs={6}
              sm={4}
              md={4}
              style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Typography variant="body1" color="textSecondary">
                In-Plant Steel
              </Typography>
              <IOSSwitch
                name="ticks.inPlantSteel"
                small
                onChange={(e) => {
                  handleChange(e);
                  if (e.target.checked) {
                    (() => {
                      setFieldValue('ticks.identifiableSteel', false)
                      setFieldValue('ticks.unidentifiableSteel', false)
                    })();
                  }
                }}
                checked={!!values?.inPlantSteel}
                disabled={type === ''}
              />
            </Grid>
          )}
          {values?.steelPresence && (
            <Grid
              item
              xs={6}
              sm={4}
              md={4}
              style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Typography variant="body1" color="textSecondary">
                Unidentifiable Steel
              </Typography>
              <IOSSwitch
                name="ticks.unidentifiableSteel"
                small
                onChange={(e) => {
                  handleChange(e);
                  if (e.target.checked) {
                    (() => {
                      setFieldValue('ticks.identifiableSteel', false)
                      setFieldValue('ticks.inPlantSteel', false)
                    })();
                  }
                }}
                checked={!!values?.unidentifiableSteel}
                disabled={type === ''}
              />
            </Grid>
          )}
          
        </Grid>
        <Grid item xs={8} md={'auto'} style={{ marginBottom: '17px', marginTop: '17px' }}>
            <Typography variant="h5">Vendor Classification</Typography>
          </Grid>
          <Grid container spacing={2}>
            <Grid
                item
                xs={6}
                sm={4}
                md={4}
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              >
                <Typography variant="body1" color="textSecondary">
                  Bulletin
                </Typography>
                <IOSSwitch
                  name="ticks.bulletin"
                  small
                  onChange={(e) => {
                    handleChange(e)
                    if (e.target.checked) {
                      (() => setFieldValue('ticks.nonBulletin', false))()
                    }
                  }}
                  checked={!!values?.bulletin}
                  disabled={type === ''}
                />
            </Grid>
            <Grid
                item
                xs={6}
                sm={4}
                md={4}
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              >
                <Typography variant="body1" color="textSecondary">
                  Non Bulletin
                </Typography>
                <IOSSwitch
                  name="ticks.nonBulletin"
                  small
                  onChange={(e) => {
                    handleChange(e)
                    if (e.target.checked) {
                      (() => setFieldValue('ticks.bulletin', false))()
                    }
                  }}
                  checked={!!values?.nonBulletin}
                  disabled={type === ''}
                />
            </Grid>
          </Grid>
      </Grid>
    </Grid>
  );
};
export default MaterialTicks;
