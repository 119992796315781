export default theme => ({
  dialogRoot: {
    [theme.breakpoints.up('sm')]: {
      width: 420,
    },
    [theme.breakpoints.down('sm')]: {
      margin: 5
    }
  },
  content: {
    color: theme.palette.textColor.dark,
    fontSize: 14,
    fontWeight: 500,
    padding: '5px 0'
  },
  button: {
    margin: '0 auto',
    color: '#fff',
    background: theme.palette.primary.blue,
    '&:hover': {
      color: '#fff',
      background: '#1976D2! important',
    },
    '&:focus': {
      color: '#fff',
      background: `${theme.palette.primary.blue} !important`,
    }
  }
});
