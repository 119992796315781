import React, { useCallback, useEffect } from 'react';
import propTypes from 'prop-types';
import { Formik, Form } from 'formik/dist/index';
import { withStyles } from '@material-ui/core/styles/index';
import Typography from '@material-ui/core/Typography/index';
import IconButton from '@material-ui/core/IconButton/index';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid/index';
import TextField from '@material-ui/core/TextField/index';
import Button from '@material-ui/core/Button/index';

import PageLoader from 'components/PageLoader';
import styles from './styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAddManufacture,
  fetchUpdateManufacture,
} from 'store/supplies/suppliesOperations';
import suppliesActions from 'store/supplies/suppliesActions';

const FormPanel = ({ classes, closeDrawer, data, onUpdate, onSave }) => {
  const dispatch = useDispatch();

  const drawerManufacturersLoading = useSelector(
    (state) => state.supplies.manufacturers.drawerManufacturersLoading
  );
  const successfullyAdded = useSelector(
    (state) => state.supplies.manufacturers.successfullyAddedManufacturer
  );
  const validationError = useSelector(
    (state) => state.supplies.manufacturers.validationManufactureError
  );

  const onSubmit = useCallback((values) => {
    if (data._id) {
      dispatch(fetchUpdateManufacture(values));
    } else {
      dispatch(fetchAddManufacture(values));
    }
  }, []);

  useEffect(() => {
    if (successfullyAdded) {
      onSave();
      dispatch(suppliesActions.successfullyAddedManufacturer(false));
    }
  }, [successfullyAdded]);

  return (
    <div className={classes.formWrapper} role="presentation">
      <Formik
        initialValues={data}
        enableReinitialize={true}
        validate={(values) => {
          const errors = {};
          if (!values.name) {
            errors.name = 'Name is required';
          }

          return errors;
        }}
        onSubmit={(values) => {
          onSubmit(values);
        }}
      >
        {({ values, errors, touched, handleChange }) => (
          <PageLoader loading={drawerManufacturersLoading}>
            <Form autoComplete="off" className={classes.formFlex}>
              <div>
                <Typography variant="h3" className={classes.marginHeader}>
                  Add Manufacturer
                </Typography>

                <IconButton
                  className={classes.closeButton}
                  onClick={() => {
                    dispatch(suppliesActions.validationManufactureError(false));
                    closeDrawer();
                  }}
                  aria-label="Close"
                  disableTouchRipple
                >
                  <CloseIcon />
                </IconButton>

                <Grid container spacing={2} className={classes.flexContainer}>
                  <Grid item xs={12} lg={6}>
                    <label htmlFor="name" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Name:
                      </Typography>
                      <TextField
                        id="name"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        className={classes.textField}
                        error={(errors.name && touched.name) || validationError}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Name' }}
                        fullWidth
                      />
                      <span className={classes.error}>
                        {errors.name && touched.name && errors.name}
                      </span>
                    </label>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <label htmlFor="supplierCode" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Supplier Code:
                      </Typography>
                      <TextField
                        id="supplierCode"
                        name="supplierCode"
                        value={values.supplierCode}
                        onChange={handleChange}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Supplier Code' }}
                        fullWidth
                      />
                    </label>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <label htmlFor="publication408" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Publication 408:
                      </Typography>
                      <TextField
                        id="publication408"
                        name="publication408"
                        value={values.publication408}
                        onChange={handleChange}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Publication 408' }}
                        fullWidth
                      />
                    </label>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <label htmlFor="designation" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Designation:
                      </Typography>
                      <TextField
                        id="designation"
                        name="designation"
                        value={values.designation}
                        onChange={handleChange}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Designation' }}
                        fullWidth
                      />
                    </label>
                  </Grid>
                </Grid>
              </div>

              <div className={classes.buttonHolder}>
                <Button
                  color="secondary"
                  className={classes.cancelButton}
                  onClick={() => {
                    dispatch(suppliesActions.validationManufactureError(false));
                    closeDrawer();
                  }}
                >
                  Cancel
                </Button>

                <div className={classes.verticalDivider}></div>

                <Button type="submit" color="primary" className={classes.saveButton}>
                  Save
                </Button>
              </div>
            </Form>
          </PageLoader>
        )}
      </Formik>
    </div>
  );
};

FormPanel.propTypes = {
  classes: propTypes.object.isRequired,
  closeDrawer: propTypes.func.isRequired,
  data: propTypes.object,
  onUpdate: propTypes.func,
  onSave: propTypes.func,
};

FormPanel.defaultProps = {
  data: {
    name: '',
    supplierCode: '',
    publication408: '',
    designation: '',
  },
  onUpdate: () => {},
  onSave: () => {},
};

export default withStyles(styles)(FormPanel);
