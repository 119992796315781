import { formatToAmPm, DAY_PART, convertTimeObject } from 'common/dateFormatConfig';
import { parseStringDate } from 'helpers/_date-helpers';
import {
  format,
  startOfDay,
  differenceInDays,
  parse,
  isSameMinute,
  isAfter,
  getHours,
  getMinutes,
} from 'date-fns';

export const dateToHoursStart = (date, createdAt, getTimeFormatHook) => {
  if (!date) {
    return {
      dayStart: '0',
      hourStart: '1',
      minuteStart: '00',
      amPmStart: DAY_PART[0],
    };
  }

  const { hour, minute, amPm } = convertTimeObject(
    {
      hour: getHours(parseStringDate(date)),
      minute: getMinutes(parseStringDate(date)),
    },
    getTimeFormatHook()?.is12Format
  );

  const dayStart = differenceInDays(startOfDay(parseStringDate(date)), startOfDay(parseStringDate(createdAt)));
  const hourStart = String(hour);
  const minuteStart = addFirstZero(minute);
  const amPmStart = formatToAmPm(getHours(parseStringDate(date)));

  return {
    dayStart: '' + dayStart,
    hourStart,
    minuteStart,
    amPmStart,
  };
};

export const generateHoursObject = (start, end, createdAt, getTimeFormatHook) => {
  return Object.assign(
    dateToHoursStart(start, createdAt, getTimeFormatHook),
    dateToHoursEnd(end, createdAt, getTimeFormatHook)
  );
};
export const generateTravelTimeHours = (travelTimeTo, travelTimeFrom, reserve) => {
  return {
    travelTimeToHours: travelTimeTo?.hours || reserve,
    travelTimeToMinutes: travelTimeTo?.minutes || reserve,
    travelTimeFromHours: travelTimeFrom?.hours || reserve,
    travelTimeFromMinutes: travelTimeFrom?.minutes || reserve,
  };
};

export const dateToHoursEnd = (date, createdAt, getTimeFormatHook) => {
  if (!date) {
    return {
      dayEnd: '0',
      hourEnd: '1',
      minuteEnd: '00',
      amPmEnd: DAY_PART[0],
    };
  }
  const { hour, minute, amPm } = convertTimeObject(
    {
      hour: getHours(parseStringDate(date)),
      minute: getMinutes(parseStringDate(date)),
    },
    getTimeFormatHook()?.is12Format
  );

  const dayEnd = differenceInDays(startOfDay(parseStringDate(date)), startOfDay(parseStringDate(createdAt)));
  const hourEnd = String(hour);
  const minuteEnd = addFirstZero(minute);
  const amPmEnd = formatToAmPm(getHours(parseStringDate(date)));

  return {
    dayEnd: '' + dayEnd,
    hourEnd,
    minuteEnd,
    amPmEnd,
  };
};

export function getHoursMinutes(start) {
  let { amPm, hour, minute } = start;
  hour = hour === '12' ? '0' : hour;
  let startHour = Number(hour);
  if (amPm === DAY_PART[1]) {
    startHour = Number(hour) + 12;
  }
  return {
    hours: startHour,
    minutes: minute,
  };
}

const TRAVEL_TIMES_FIELDS_HOURS = ['travelTimeToHours', 'travelTimeFromHours'];
const TRAVEL_TIMES_FIELDS_MINUTES = ['travelTimeToMinutes', 'travelTimeFromMinutes'];

export const isSelectedTimeValid = (values, getTimeFormatHook, dateFormat) => {
  const errorsOnFields = [];

  if (Number(values.dayEnd) < Number(values.dayStart)) {
    errorsOnFields.push('dayStart', 'dayEnd');
  }

  if (Number(values.dayStart) === Number(values.dayEnd)) {
    const date = dateFormat ? format(new Date(), dateFormat) : null;
    const startObj = [date, values.hourStart, values.minuteStart, values.amPmStart];
    const startTime = parse(
      getTimeFormatHook(startObj).formattedTime,
      getTimeFormatHook(startObj).formatForTimePars,
      new Date()
    );

    const endObj = [date, values.hourEnd, values.minuteEnd, values.amPmEnd];
    const endTime = parse(
      getTimeFormatHook(endObj).formattedTime,
      getTimeFormatHook(endObj).formatForTimePars,
      new Date()
    );
    if (isSameMinute(startTime, endTime) || isAfter(startTime, endTime)) {
      errorsOnFields.push(
        'hourStart',
        'hourEnd',
        'minuteStart',
        'minuteEnd',
        'amPmStart',
        'amPmEnd'
      );
    }
  }

  const travelTimeErrors = isSelectedTravelTimeTimeValid(values);

  if (travelTimeErrors.length || (errorsOnFields.length && values.isShowHours)) {
    return {
      isValid: false,
      errorsOnFields: [...travelTimeErrors, ...errorsOnFields],
    };
  }
  return { isValid: true, errorsOnFields: [] };
};

export const isSelectedTravelTimeTimeValid = (values, mainValidation) => {
  const travelTimeErrors = [];
  TRAVEL_TIMES_FIELDS_HOURS.forEach((fieldName) => {
    if (values[fieldName] && !/^[0-9]+$/i.test(values[fieldName])) {
      travelTimeErrors.push(fieldName);
    } else if (+values[fieldName] > 24 || +values[fieldName] < 0) {
      travelTimeErrors.push(fieldName);
    }
  });
  TRAVEL_TIMES_FIELDS_MINUTES.forEach((fieldName) => {
    if (values[fieldName] && !/^[0-9]+$/i.test(values[fieldName])) {
      travelTimeErrors.push(fieldName);
    } else if (+values[fieldName] > 59 || +values[fieldName] < 0) {
      travelTimeErrors.push(fieldName);
    }
  });

  if (mainValidation) {
    if (travelTimeErrors.length) {
      return {
        isValid: false,
        errorsOnFields: travelTimeErrors,
      };
    }
    return { isValid: true, errorsOnFields: [] };
  }
  return travelTimeErrors;
};

export const addLeadingZero = (input = '00') => {
  let inputStr = String(input);
  let characters = inputStr.split('');

  if (characters.length === 2 && characters[0] === '0') {
    return characters[1];
  } else {
    return inputStr;
  }
};

export const addFirstZero = (input) => {
  const inputNumber = parseInt(input, 10);
  if (isNaN(inputNumber)) {
    return '00';
  }
  return inputNumber < 10 ? `0${inputNumber}` : `${inputNumber}`;
};
