const textColor = '#60615b';
export default {
  logo: {
    width: '100%',
    display: 'block',
    padding: '20px 0'
  },
  logoHidden: {
    display: 'block',
    padding: '20px 0',
    visibility: 'hidden',
  },
  footerLogo: {
    width: '20%',
    margin: '0 auto',
    paddingLeft: 25,
  },
  footerLogoWrapper: {
    position: 'absolute',
    bottom: 0,
    width: '100%'
  },
  paddingBottom: {
  },
  textCenter: {
    textAlign: 'center'
  },
  linedTable: {
    border: 'none',
    margin: '0 0 5px 0',
    fontSize: 13,
    lineHeight: '1.135rem',
    '& tr': {
      background: 'none !important'
    },
    '& td': {
      textAlign: 'left',
      verticalAlign: 'middle',
      padding: '0.8rem 0.5rem 0',
      borderBottom: `1px solid ${textColor}`,
      fontSize: 14,
      width: '48%',
      '&:first-child': {
        color: textColor,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        width: '1%',
        textAlign: 'right',
        borderBottom: 'none'
      }
    }
  },
  middleHeading: {
    color: textColor,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    width: '1% !important',
    textAlign: 'right !important',
    borderBottom: 'none',
    paddingLeft: '2em'
  },
  forceAccount: {
    whiteSpace: 'nowrap'
  },
  hoursTable: {
    border: 'none',
    margin: 0,
    '& tr': {
      background: 'none !important'
    },
    '& td': {
      border: 'none',
      padding: '0 0 0.25em',
      verticalAlign: 'top',
      '&:first-child': {
        textTransform: 'none',
        textAlign: 'left',
        fontWeight: 'normal',
        minWidth: '80px',
        color: 'black'
      },
      '&:last-child': {
        paddingLeft: '0.5em',
        fontSize: '0.9em'
      }
    }
  },
  header1: {
    fontSize: 44,
    color: textColor,
    fontWeight: 'bold',
  },
  materialsTable: {
    width: '100%',
    margin: '1.4em 0 0',
    pageBreakAfter: 'auto',
    pageBreakInside: 'auto',
    '& tr': {
      background: 'none !important'
    },
    '& td': {
      padding: 6,
      border: `1px solid ${textColor}`,
      fontSize: 14,
    },
    '& thead': {
      background: '#f5f5f5',
    }
  },
  width20: {
    width: '20%',
    fontWeight: 700,
  },
  width40: {
    width: '40%',
    fontWeight: 700,
  },
  normalFontWeight: {
    fontWeight: 'normal'
  },
  pageBreakBefore: {
    pageBreakBefore: 'always'
  },
  height60: {
    height: 60
  },
  signatureView: {
    cursor: 'default',
    border: 'none',
    margin: 0,
    padding: 0,
    '& img': {
      width: 140
    }
  },
  title: {
    margin: '0.4rem 0 0 0.6em',
    fontSize: '0.9rem'
  },
  time: {
    marginLeft: '0.6em',
    fontSize: '0.8rem'
  },
  email: {
    marginLeft: '0.6em',
    fontSize: '0.8rem',
    color: '#4d4c4c'
  },
  addSign: {
    '&:hover': {
      color: '#000000de'
    },
    '&:focus': {
      color: '#000000de'
    },
    '@media print': {
      display: 'none'
    }
  },
  inlineBlock: {
    display: 'inline-block',
    width: '48%',
    verticalAlign: 'middle'
  },
};
