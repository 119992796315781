export default (theme) => ({
  root: {
    width: '100%',
    marginTop: 5,
    overflowX: 'auto',
    border: 0,
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
    },
    boxSizing: 'border-box',
  },
  headerDetailsPart: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 15,
  },
  table: {
    minWidth: 280,
    border: 0,
    marginBottom: 30,
  },
  tableHeader: {
    '& th': {
      fontSize: 14,
      fontWeight: 400,
      color: theme.palette.textColor.grey,
      fontFamily: 'Open Sans',
    },
  },
  badge: {
    height: '25px',
    width: '25px',
    display: 'inline-block',
    color: '#5A87EF',
    textAlign: 'center',
    verticalAlign: 'middle',
    borderRadius: '50%',
    border: '1.5px solid #5A87EF !important',
    padding: '3px',
  },
  flexBox: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('lg')]: {
      width: 'auto',
      flexWrap: 'wrap',
    },
  },
  actionBtn: {
    textTransform: 'none',
    padding: 0,
    minWidth: 25,
    minHeight: 25,
    marginLeft: 15,
    '&:first-child': {
      marginLeft: 0,
    },
    '&:hover': {
      color: 'inherit',
      backgroundColor: 'inherit !important',
    },
    '&:focus': {
      color: 'inherit',
      backgroundColor: 'inherit !important',
    },
  },
  approveBtn: {
    color: theme.palette.textColor.primary,
    backgroundColor: 'inherit',
    minWidth: 50,
    '&:hover': {
      color: theme.palette.textColor.primary,
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
    },
  },
  declineBtn: {
    color: theme.palette.textColor.red,
    backgroundColor: 'inherit',
    minWidth: 50,
    '&:hover': {
      color: theme.palette.textColor.red,
    },
    '&:focus': {
      color: theme.palette.textColor.red,
    },
  },
  usernameCell: {
    [theme.breakpoints.down('md')]: {
      width: 100,
    },
  },
  datesCell: {
    width: 100,
    [theme.breakpoints.down('md')]: {
      width: 84,
    },
  },
  timeCell: {
    width: 100,
    [theme.breakpoints.down('md')]: {
      width: 50,
    },
  },
  hoursCell: {
    width: 72,
    [theme.breakpoints.down('md')]: {
      width: 56,
    },
  },
  typeCell: {
    width: 140,
    [theme.breakpoints.down('md')]: {
      width: 124,
    },
  },
  ptoCell: {
    width: 60,
    [theme.breakpoints.down('md')]: {
      width: 44,
    },
  },
  notesCell: {
    width: 150,
    // [theme.breakpoints.down('md')]: {
    //   width: 110
    // }
    wordBreak: 'break-word',
  },
  loader: {
    fontSize: 14,
    paddingBottom: 10,
    '&:nth-of-type(1)': {
      display: 'none',
    },
  },
  cell: {
    textAlign: 'center',
  },
});
