import { useMemo } from 'react';
import { useSelector } from "react-redux";
import { canEditWorklog } from 'common/permissions/can-edit-worklog';

/**
 * @param {Worklog|Sheet} sheet 
 * @returns {boolean}
 */
export const useCanEditWorklog = (sheet) => {
  const { user, organization } = useSelector(state => state.personalProfile || {});
  const settings = organization?.settings;

  return useMemo(() => {
    if (!user || !settings || !sheet?._id) return false;

    return canEditWorklog(sheet, user, settings)
  }, [user, settings, sheet])
}