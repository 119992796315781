import React from 'react'

export const Calendar = () => <svg width="16px"
                                   height="16px"
                                   viewBox="0 0 16 16"
                                   xmlns="http://www.w3.org/2000/svg">
  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g transform="translate(-865.000000, -398.000000)" fill="#5286F7" fillRule="nonzero">
      <g transform="translate(525.000000, 241.000000)">
        <g transform="translate(21.000000, 120.000000)">
          <g transform="translate(319.000000, 37.000000)">
            <circle cx="12.0625" cy="6.5625" r="1"/>
            <path
              d="M13.5,1.25 L12.6875,1.25 L12.6875,0.625 C12.6875,0.2798125 12.4076875,0 12.0625,0 C11.7173125,0 11.4375,0.2798125 11.4375,0.625 L11.4375,1.25 L8.59375,1.25 L8.59375,0.625 C8.59375,0.2798125 8.3139375,0 7.96875,0 C7.6235625,0 7.34375,0.2798125 7.34375,0.625 L7.34375,1.25 L4.53125,1.25 L4.53125,0.625 C4.53125,0.2798125 4.2514375,0 3.90625,0 C3.5610625,0 3.28125,0.2798125 3.28125,0.625 L3.28125,1.25 L2.5,1.25 C1.1215,1.25 0,2.3715 0,3.75 L0,13.5 C0,14.8785 1.1215,16 2.5,16 L7.28125,16 C7.6264375,16 7.90625,15.7201875 7.90625,15.375 C7.90625,15.0298125 7.6264375,14.75 7.28125,14.75 L2.5,14.75 C1.81075,14.75 1.25,14.18925 1.25,13.5 L1.25,3.75 C1.25,3.06075 1.81075,2.5 2.5,2.5 L3.28125,2.5 L3.28125,3.125 C3.28125,3.4701875 3.5610625,3.75 3.90625,3.75 C4.2514375,3.75 4.53125,3.4701875 4.53125,3.125 L4.53125,2.5 L7.34375,2.5 L7.34375,3.125 C7.34375,3.4701875 7.6235625,3.75 7.96875,3.75 C8.3139375,3.75 8.59375,3.4701875 8.59375,3.125 L8.59375,2.5 L11.4375,2.5 L11.4375,3.125 C11.4375,3.4701875 11.7173125,3.75 12.0625,3.75 C12.4076875,3.75 12.6875,3.4701875 12.6875,3.125 L12.6875,2.5 L13.5,2.5 C14.18925,2.5 14.75,3.06075 14.75,3.75 L14.75,7.3125 C14.75,7.6576875 15.0298125,7.9375 15.375,7.9375 C15.7201875,7.9375 16,7.6576875 16,7.3125 L16,3.75 C16,2.3715 14.8785,1.25 13.5,1.25 Z"/>
            <path
              d="M12.21875,8.4375 C10.13375,8.4375 8.4375,10.13375 8.4375,12.21875 C8.4375,14.30375 10.13375,16 12.21875,16 C14.30375,16 16,14.30375 16,12.21875 C16,10.13375 14.30375,8.4375 12.21875,8.4375 Z M12.21875,14.75 C10.8230312,14.75 9.6875,13.6145 9.6875,12.21875 C9.6875,10.823 10.8230312,9.6875 12.21875,9.6875 C13.6144688,9.6875 14.75,10.823 14.75,12.21875 C14.75,13.6145 13.6144688,14.75 12.21875,14.75 Z"/>
            <path
              d="M13.125,11.59375 L12.84375,11.59375 L12.84375,10.9375 C12.84375,10.5923125 12.5639375,10.3125 12.21875,10.3125 C11.8735625,10.3125 11.59375,10.5923125 11.59375,10.9375 L11.59375,12.21875 C11.59375,12.5639375 11.8735625,12.84375 12.21875,12.84375 L13.125,12.84375 C13.4701875,12.84375 13.75,12.5639375 13.75,12.21875 C13.75,11.8735625 13.4701875,11.59375 13.125,11.59375 Z"/>
            <circle cx="9.34375" cy="6.5625" r="1"/>
            <circle cx="6.625" cy="9.28125" r="1"/>
            <circle cx="3.90625" cy="6.5625" r="1"/>
            <circle cx="3.90625" cy="9.28125" r="1"/>
            <circle cx="3.90625" cy="12" r="1"/>
            <circle cx="6.625" cy="12" r="1"/>
            <circle cx="6.625" cy="6.5625" r="1"/>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>

export default Calendar
