import React from 'react';
import propTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import PageLoader from '../../../../components/PageLoader';
import styles from './styles';
import { isIOS, Option } from '../../../../components/Option';
import { defaultFilters } from '../../NotesPage';

const SortingAndFilteringPanel = ({ classes, closeDrawer, filter, updateFilter, isLoading }) => {
  const sortingTypeLabel = (sortBy) => {
    switch (sortBy) {
      case 'date':
        return [{ label:'Newest \u2013 Oldest', value: 'desc' }, { label:'Oldest \u2013 Newest', value: 'asc' }];
      case 'projectId':
        return  [{ label: '0 \u2013 9', value: 'asc' }, { label: '9 \u2013 0', value: 'desc' }];
      default:
        return [{ label: 'A \u2013 Z', value: 'asc' }, { label: 'Z \u2013 A', value: 'desc' }];
    }
  };

  const specialHandleChange = (setFieldValue, values) => (e) => {

    if (e.target.value == 'date') {// if sortBy date revert sortOrder for select
      setFieldValue('sortBy', 'date');
      if (values.sortOrder == 'asc') {
        setFieldValue('sortOrder', 'desc');
        return
      } else {
        setFieldValue('sortOrder', 'asc');
        return
      }
    }

    setFieldValue("sortBy", e.target.value)
    if (values.sortBy == 'date') {
      if (values.sortOrder == 'asc') {
        setFieldValue('sortOrder', 'desc');
      } else {
        setFieldValue('sortOrder', 'asc');
      }
    }
  };

  return (
    <div className={classes.formWrapper} role="presentation">
      <Formik
        initialValues={filter}
        enableReinitialize={true}
        onSubmit={(values) => {
          updateFilter(values);
        }}>
        {({ values, handleChange, setFieldValue, resetForm }) => (
          <PageLoader loading={isLoading}>
            <Form autoComplete="off" className={classes.formFlex}>
              <div>
                <Typography variant="h3" className={classes.marginHeader}>
                  Filters
                </Typography>

                <IconButton className={classes.closeButton} onClick={closeDrawer} aria-label="Close" disableTouchRipple>
                  <CloseIcon />
                </IconButton>

                <Grid container spacing={2} className={classes.flexContainer}>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="sortBy" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Sort by:
                      </Typography>
                      <TextField
                        select
                        id="sortBy"
                        name="sortBy"
                        value={values.sortBy}
                        onChange={specialHandleChange(setFieldValue, values)}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Sort by' }}
                        fullWidth
                        SelectProps={{
                          native: isIOS,
                        }}>
                        <Option key={1} value="date">
                          Most recent
                        </Option>
                        <Option key={2} value="projectName">
                          Project name
                        </Option>
                        <Option key={3} value="projectId">
                          Project ID
                        </Option>
                        <Option key={4} value="contractor">
                          Contractor
                        </Option>
                      </TextField>
                    </label>
                  </Grid>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="sortOrder" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Sort from:
                      </Typography>
                      <TextField
                        select
                        id="sortOrder"
                        name="sortOrder"
                        value={values.sortOrder}
                        onChange={handleChange}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Days' }}
                        fullWidth
                        SelectProps={{
                          native: isIOS,
                        }}
                      >
                        {sortingTypeLabel(values.sortBy).map(option => (
                          <Option key={`${option.label}_${option.value}`} value={option.value}>
                            {option.label}
                          </Option>
                        ))}
                      </TextField>
                    </label>
                  </Grid>
                </Grid>
              <Grid item xs={12}>
                <Button
                  color="primary"
                  className={classes.resetButton}
                  onClick={() => {
                    resetForm({ values: defaultFilters });
                  }}
                  disableTouchRipple
                >
                  Reset all filters
                </Button>
              </Grid>
              </div>

              <div className={classes.buttonHolder}>
                <Button color="secondary" className={classes.cancelButton} onClick={closeDrawer}>
                  Cancel
                </Button>

                <div className={classes.verticalDivider} />

                <Button type="submit" color="primary" className={classes.saveButton}>
                  Apply
                </Button>
              </div>
            </Form>
          </PageLoader>
        )}
      </Formik>
    </div>
  );
};

SortingAndFilteringPanel.propTypes = {
  classes: propTypes.object.isRequired,
  closeDrawer: propTypes.func.isRequired,
  filter: propTypes.object.isRequired,
  updateFilter: propTypes.func.isRequired,
  isLoading: propTypes.bool.isRequired,
};

export default withStyles(styles)(SortingAndFilteringPanel);
