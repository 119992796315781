import React, { useState } from 'react';
import propTypes from 'prop-types';
import LoadingSpinner from '../../components/LoadingSpinner';
import AvailabilityPage from './AvailabilityPage';
import SnackBar from '../../components/SnackBar';

const AvailabilityWrapper = () => {

  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    text: '',
    type: 'success',
  });

  const openSnackbar = (type, text) => setSnackbar({ isOpen: true, type, text });
  const closeSnackbar = () => setSnackbar(prev=>({...prev, isOpen: false}));

  const loading = false;

    if (loading)
      return (
        <div
          style={{
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            marginTop: '100px',
          }}
        >
          <LoadingSpinner height={40} margin={32} />
        </div>
      );

    return (
      <>
        <AvailabilityPage
          openSnackbar={openSnackbar}
        />
        <SnackBar closeSnackbar={closeSnackbar} {...snackbar} />
      </>
    );
}

AvailabilityWrapper.propTypes = {
  loading: propTypes.bool.isRequired,
  timeoffsByType: propTypes.objectOf(propTypes.array).isRequired,
  equipmentEvents: propTypes.array.isRequired,
};

export default AvailabilityWrapper;
