import React, { useState, useEffect, useCallback } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useNavigate, useParams } from 'react-router-dom';

import WorklogsHeader from './components/WorklogsHeader';
import ActiveWorklogs from './components/ActiveWorklogs';
import SubmittedWorklogs from './components/SubmittedWorklogs';
import ForemanWorklogs from './components/ForemanWorklogs';
import SheetForm from './components/SheetForm/SheetForm';
import styles from './styles';
import { useDispatch, useSelector } from 'react-redux';
import worklogsPageActions from 'store/worklogs/worklogsPageActions';
import ExportExcelButton from 'pages/TimeClock/components/ExportExcelButton/ExportExcelButton';
import Grid from '@material-ui/core/Grid';
import useExcelExport from './components/hooks/useExcelExport';
import ExportExcelModal from './components/ExportExcelModal/ExportExcelModal';
import { subDays } from 'date-fns';

const WorklogsPage = ({ classes, openSnackbar, pageType }) => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const { error, isLoading } = useSelector((store) => store.worklogs);

  const [tabValue, setTabValue] = useState(() => setInitialTabValue());
  const [drawer, setDrawer] = useState({
    type: 'create',
    isOpen: false,
  });

  useEffect(() => {
    if (!!error) {
      openSnackbar('error', error?.response?.data?.message || error.message);
    }
  }, [error]);

  useEffect(() => {
    if (!params.type) {
      navigate('/worklog/active', { replace: true });
    }
  }, []);

  function setInitialTabValue() {
    if (pageType.includes('submissions')) return 1;
    if (pageType.includes('worklog-foreman')) return 2;
    return 0;
  }

  const handleChangeTab = (event, newValue) => {
    dispatch(worklogsPageActions.setError(null));
    setTabValue(newValue);
  };

  const openDrawer = (type) => () => {
    setDrawer({ type, isOpen: true });
  };

  const closeDrawer = () => {
    setDrawer({ type: 'create', isOpen: false });
  };

  const { isExported, setIsExported, onDownload } = useExcelExport();

  const handleExcelExport = useCallback(() => {
    setIsExported({
      period: {
        from: subDays(new Date(), 14),
        to: new Date(),
      },
      jobNumber: [],
      cl: [],
      materialName: [],
      materialUnit: [],
      materialColor: [],
      materialSpecification: [],
      lineWidth: [],
      legendSymbol: [],
      letteringText: [],
      letteringParameters: [],
    });
  }, []);

  return (
    <div className={classes.root}>
      <WorklogsHeader
        openDrawer={openDrawer}
        pageType={pageType}
        handleExcelExport={handleExcelExport}
      />
      <div className={classes.tabsWrapper}>
        <Tabs value={tabValue} onChange={handleChangeTab} className={classes.tabs}>
          <Tab
            label="Active"
            disabled={isLoading}
            disableRipple
            component="a"
            onClick={(event) => {
              event.preventDefault();
              navigate('/worklog/active', { replace: true });
            }}
            href="/worklog/active"
          />
          <Tab
            label="Submitted"
            disabled={isLoading}
            disableRipple
            component="a"
            onClick={(event) => {
              event.preventDefault();
              navigate('/worklog/submissions', { replace: true });
            }}
            href="/worklog/submissions"
          />

          <Tab
            label="Crew leader"
            disabled={isLoading}
            disableRipple
            component="a"
            onClick={(event) => {
              event.preventDefault();
              navigate('/worklog/worklog-foreman', { replace: true });
            }}
            href="/worklog/worklog-foreman"
          />
        </Tabs>
      </div>

      {tabValue === 0 && <ActiveWorklogs openSnackbar={openSnackbar} params={params} />}
      {tabValue === 1 && <SubmittedWorklogs openSnackbar={openSnackbar} params={params} />}
      {tabValue === 2 && <ForemanWorklogs openSnackbar={openSnackbar} params={params} />}

      <Drawer
        anchor="right"
        open={drawer.isOpen}
        classes={{
          paper: classes.drawer,
        }}
      >
        <SheetForm
          closeDrawer={closeDrawer}
          type={drawer.type}
          sheet={null}
          openSnackbar={openSnackbar}
        />
      </Drawer>
      <ExportExcelModal
        open={!!isExported}
        value={isExported}
        onChange={(value) => setIsExported(value)}
        onSubmit={onDownload}
        onClose={() => setIsExported(null)}
        onCancel={() => setIsExported(null)}
      />
    </div>
  );
};

WorklogsPage.propTypes = {
  classes: propTypes.object.isRequired,
  openSnackbar: propTypes.func.isRequired,
  pageType: propTypes.string.isRequired,
};

export default withStyles(styles)(WorklogsPage);
