import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useController } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import styles from './styles';
import CloseIcon from 'assets/images/close-icon.svg';

const DocuSignSigner = ({ classes, pageType, control }) => {

  const {
    field: { onChange, value: docuSignSigner },
  } = useController({
    name: 'docuSignSigner',
    control,
  });

  const handleNotificationChange = (fieldName) => (e) => {
    const { value } = e.target;
    const nextValue = { ...docuSignSigner, [fieldName]: value };
    onChange(nextValue);
  };

  const handleEmailRemoving = () => {
    onChange({ name: '', email: '' });
  };

  return (
    <div className={classes.root}>
      <div className={classes.actionsWrapper}>
        {
          <Grid container className={classes.emailsWrapper}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={10} sm={6} md={4} className={classes.zeroPaddingTop}>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  className={classes.regularFontWeight}
                >
                  Name:
                </Typography>

                <TextField
                  id={`docuSignSigner.name`}
                  name={`docuSignSigner.name`}
                  type="text"
                  value={docuSignSigner.name}
                  className={classes.textField}
                  onChange={handleNotificationChange('name')}
                  variant="outlined"
                  inputProps={{ 'aria-label': 'Name Notifications' }}
                  fullWidth
                  disabled={pageType === 'read'}
                />
              </Grid>
              <Grid item xs={10} sm={6} md={4} className={classes.zeroPaddingTop}>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  className={classes.regularFontWeight}
                >
                  Email:
                </Typography>

                <TextField
                  id={`docuSignSigner.email`}
                  name={`docuSignSigner.email`}
                  type="email"
                  value={docuSignSigner.email}
                  className={classes.textField}
                  onChange={handleNotificationChange('email')}
                  variant="outlined"
                  inputProps={{ 'aria-label': 'Email Notifications' }}
                  fullWidth
                  disabled={pageType === 'read'}
                />
              </Grid>
              {pageType === 'edit' && (
                <span
                  className={classes.removeEmail}
                  onClick={handleEmailRemoving}
                  style={{ marginTop: 15 }}
                >
                  <img src={CloseIcon} alt="delete" />
                </span>
              )}
            </Grid>
          </Grid>
        }
      </div>
    </div>
  );
};

DocuSignSigner.propTypes = {
  classes: propTypes.object.isRequired,
  pageType: propTypes.string.isRequired,
  errors: propTypes.object.isRequired,
  control: propTypes.object.isRequired,
};

export default withStyles(styles)(DocuSignSigner);
