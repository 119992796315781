export default (theme) => ({
  datePickerWrapper: {
    position: 'relative',
  },
  btnHolder: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 16px 10px',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    borderRadius: '4px',
  },
  selectedCount: {
    fontSize: '14px',
    lineHeight: 1,
    fontWeight: 500,
    color: theme.palette.textColor.secondary,
  },
  inlineFlex: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  extraBtn: {
    height: 24,
    padding: 0,
    fontSize: 14,
    lineHeight: 1.3,
  },
  assignBtn: {
    '&:hover': {
      color: theme.palette.textColor.primary,
      backgroundColor: 'rgba(255, 255, 255, 0.8) !important',
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
      backgroundColor: 'rgba(255, 255, 255, 0.8) !important',
    },
  },
  selectBtn: {
    '&:hover': {
      color: theme.palette.textColor.secondary,
      backgroundColor: 'rgba(255, 255, 255, 0.8) !important',
    },
    '&:focus': {
      color: theme.palette.textColor.secondary,
      backgroundColor: 'rgba(255, 255, 255, 0.8) !important',
    },
    '& svg': {
      height: 20,
    },
  },
  closeButton: {
    position: 'absolute',
    zIndex: 10,
    right: '5px',
    top: '12px',
    cursor: 'pointer',
    padding: 5,
    '&:hover': {
      color: theme.palette.textColor.secondary,
      backgroundColor: 'rgba(255, 255, 255, 0.8) !important',
    },
    '&:focus': {
      color: theme.palette.textColor.secondary,
      backgroundColor: 'rgba(255, 255, 255, 0.8) !important',
    },
  },
});
