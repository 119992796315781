import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import classNames from 'classnames';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import { hasPermissionsFor, checkUserRole } from 'helpers/_helpers';
import EditIcon from 'assets/images/edit-icon.svg';
import CloseIcon from 'assets/images/close-icon.svg';
import styles from './styles';
import { drawerTypes } from '../../../../../Equipment/components/helpers/drawerTypes';
import { useSelector } from 'react-redux';
import { format, isValid } from 'date-fns';
import { GLOBAL_DATE_FORMAT, allDatesFormat } from 'common/dateFormatConfig';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';
import { useCanEditWorklog } from 'common/hooks/use-can-edit-worklog';

const WorklogNoteCard = ({ classes, note, handleNote, openDrawer, openImagesDrawer }) => {
  const { projects, projectId, project: projectById } = useSelector((store) => store.notes);
  const user = useSelector((store) => store.personalProfile.user) || {};
  const getTimeFormatHook = useTimeSettingsFormat();

  const [project, setProject] = useState({});
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    setProject(projectById || {});
  }, [projects]);

  const formatDate = (date) => {
    if (!isValid(new Date(date))) {
      return 'xxx';
    }
    return format(new Date(date), GLOBAL_DATE_FORMAT.fullDate);
  };

  const formatOnJobTime = (hours) => {
    const start = `${format(new Date(hours.start), getTimeFormatHook().formatForTimePars)} ${format(
      new Date(hours.start),
      getTimeFormatHook().localDayMonth
    )}`;
    const end = `${format(new Date(hours.end), getTimeFormatHook().formatForTimePars)} ${format(
      new Date(hours.end),
      getTimeFormatHook().localDayMonth
    )}`;
    return `${start} \u2013 ${end}`;
  };

  const handleExpandClick = () => setIsExpanded((prevState) => !prevState);

  const canEditWorklog = useCanEditWorklog(note);

  const canEditInternalNotes = () =>
    checkUserRole('Super Admin') || checkUserRole('Admin') || checkUserRole('Dispatcher');

  const truncateNote = (text) => (text && text.length > 90 ? text.slice(0, 90) + '...' : text);

  const isDrawer =
    Boolean(note.notes && note.notes.trim()) || Boolean(note.cancelNote && note.cancelNote.trim());

  return (
    <Card className={classes.card}>
      <Grid
        container
        onClick={isDrawer && canEditWorklog ? openDrawer(drawerTypes.update, true, note) : null}
      >
        <Grid
          item
          xs={note.forceAccount ? 8 : 12}
          sm={note.forceAccount ? 8 : 12}
          lg={note.forceAccount ? 8 : 12}
        >
          <div className={classes.flexBox}>
            <Typography variant="h6" color="textPrimary" className={classes.marginBottomNormal}>
              {project.route + (project.section ? ', Section ' + project.section : '')}
            </Typography>
          </div>
        </Grid>
        {note.forceAccount !== 'No' ? (
          <Grid item xs={4} sm={4} lg={4}>
            <div className={classes.flexWrapper}>
              <Typography
                variant="body2"
                className={classNames(classes.forceAccount, classes.marginBottomNormal)}
              >
                T&M: {note.forceAccount}
              </Typography>
            </div>
          </Grid>
        ) : null}
        <Grid item xs={12} sm={12} lg={12}>
          <div className={classes.paddingBox}>
            <Divider className={classes.divider} />
          </div>
        </Grid>
      </Grid>
      <CardContent
        className={classes.mainContent}
        onClick={isDrawer && canEditWorklog ? openDrawer(drawerTypes.update, true, note) : null}
      >
        <Grid container justifyContent="space-between">
          <Grid item xs={12} sm={12} lg={6}>
            <Typography variant="body1" color="textSecondary" className={classes.marginBottomSmall}>
              Date:
            </Typography>
            <Typography variant="body1" color="textPrimary" className={classes.marginBottomNormal}>
              {formatDate(note.hours?.start || note?.createdAt)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} lg={5}>
            <Typography variant="body1" color="textSecondary" className={classes.marginBottomSmall}>
              Job number:
            </Typography>
            <Typography variant="body1" color="textPrimary" className={classes.marginBottomNormal}>
              {project.jobNumber}
            </Typography>
          </Grid>
          {note?.hours?.start && note?.hours?.start && (
            <Grid item xs={12}>
              <Typography
                variant="body1"
                color="textSecondary"
                className={classes.marginBottomSmall}
              >
                On Job Time:
              </Typography>
              <Typography
                variant="body1"
                color="textPrimary"
                className={classes.marginBottomNormal}
              >
                {formatOnJobTime(note.hours)}
              </Typography>
            </Grid>
          )}
        </Grid>
      </CardContent>

      <Grid item xs={12} className={classes.smallPaddingTop}>
        <Collapse in={isExpanded} timeout="auto" unmountOnExit className={classes.contentWrapper}>
          <Grid container>
            <Grid item xs={12} md={12} lg={12}>
              {isDrawer ? (
                <>
                  <div
                    className={classNames(
                      classes.zeroPadding,
                      classes.paddingBottom,
                      classes.flexBox
                    )}
                  >
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      className={classNames(classes.notesPadding, classes.paddingSmall)}
                    >
                      {note.canceledAt ? 'Cancel Note' : 'Note'}:
                    </Typography>
                    {canEditWorklog && (
                      <div>
                        <Button
                          color="secondary"
                          className={classes.deleteButton}
                          onClick={handleNote(note._id, 'notes', note.notes, false)}
                          disableRipple
                        >
                          <img src={EditIcon} alt="edit" />
                        </Button>
                        <Button
                          color="secondary"
                          className={classNames(classes.deleteIcon, classes.deleteButton)}
                          onClick={handleNote(note._id, 'notes', '', true)}
                          disableRipple
                        >
                          <img src={CloseIcon} alt="delete" />
                        </Button>
                      </div>
                    )}
                  </div>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    className={classNames(classes.marginBottomNormal, classes.wordBreak)}
                  >
                    {truncateNote(
                      note.canceledAt ? (note.cancelNote ? note.cancelNote : 'N/A') : note.notes
                    )}
                  </Typography>
                  {canEditWorklog && (
                    <Button
                      className={classes.commentButton}
                      onClick={openDrawer(drawerTypes.update, true, note)}
                      disableRipple
                      disableFocusRipple
                    >
                      {note.noteComments ? note.noteComments.length : 0} Comment(s)
                    </Button>
                  )}
                </>
              ) : null}

              {note.schedulerNotes && !note.canceledAt ? (
                <>
                  {note.notes ? (
                    <div className={classes.noteDividerPadding}>
                      <Divider className={classes.divider} />
                    </div>
                  ) : null}
                  <div
                    className={classNames(
                      classes.zeroPadding,
                      classes.paddingBottom,
                      classes.flexBox
                    )}
                  >
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      className={classNames(classes.notesPadding, classes.paddingSmall)}
                    >
                      Internal Note:
                    </Typography>
                    {canEditInternalNotes() && (
                      <div>
                        <Button
                          color="secondary"
                          className={classes.deleteButton}
                          onClick={handleNote(
                            note._id,
                            'schedulerNotes',
                            note.schedulerNotes,
                            false
                          )}
                          disableRipple
                        >
                          <img src={EditIcon} alt="edit" />
                        </Button>
                        <Button
                          color="secondary"
                          className={classNames(classes.deleteIcon, classes.deleteButton)}
                          onClick={handleNote(note._id, 'schedulerNotes', '', true)}
                          disableRipple
                        >
                          <img src={CloseIcon} alt="delete" />
                        </Button>
                      </div>
                    )}
                  </div>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    className={classNames(classes.marginBottomNormal, classes.wordBreak)}
                  >
                    {truncateNote(note.schedulerNotes)}
                  </Typography>
                </>
              ) : null}
              {!!note.images?.length ? (
                <>
                  {note.notes && (
                    <div className={classes.noteDividerPadding}>
                      <Divider className={classes.divider} />
                    </div>
                  )}
                  <div
                    className={classNames(
                      classes.zeroPadding,
                      classes.paddingBottom,
                      classes.flexBox
                    )}
                  >
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      className={classNames(classes.notesPadding, classes.paddingSmall)}
                    >
                      Images:
                    </Typography>
                  </div>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    className={classNames(classes.marginBottomNormal, classes.wordBreak)}
                  >
                    <Button
                      className={classes.commentButton}
                      onClick={() => {
                        openImagesDrawer(note);
                      }}
                      disableRipple
                      disableFocusRipple
                    >
                      {note.images.length} Image(s)
                    </Button>
                  </Typography>
                </>
              ) : null}
            </Grid>
          </Grid>
        </Collapse>
        <CardActions disableSpacing onClick={handleExpandClick} className={classes.actionsWrapper}>
          <IconButton
            className={classNames(classes.expand, {
              [classes.expandOpen]: isExpanded,
            })}
            aria-expanded={isExpanded}
            aria-label="Show more"
            disableRipple
            disableFocusRipple
          >
            <ExpandMoreIcon />
          </IconButton>
          <Typography color="textSecondary">
            {isExpanded ? 'Hide worklog notes' : 'Show worklog notes'}
          </Typography>
          <IconButton
            className={classNames(classes.expand, {
              [classes.expandOpen]: isExpanded,
            })}
            aria-expanded={isExpanded}
            aria-label="Show more"
            disableRipple
            disableFocusRipple
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>
      </Grid>
    </Card>
  );
};

WorklogNoteCard.propTypes = {
  classes: propTypes.object.isRequired,
  note: propTypes.object.isRequired,
  handleNote: propTypes.func.isRequired,
};

export default withStyles(styles)(WorklogNoteCard);
