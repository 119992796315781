import React from 'react'
import styles from './styles'
import { withStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import cs from 'classnames'

const FiltersButton = ({ classes, ...props }) => <Button
  variant="contained"
  color="secondary"
  className={classes.toggleFiltersBtn}
  disableTouchRipple
  {...props}
>
  <i className={cs('fa fa-sliders', classes.filtersIcon)} aria-hidden="true"></i>
  Filters
</Button>

export default withStyles(styles)(FiltersButton)
