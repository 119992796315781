export default theme => ({
  dialogRoot: {
    [theme.breakpoints.up('md')]: {
      maxWidth: 800
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      margin: 0,
      minHeight: '100%'
    }
  },
  formWrapper: {
    padding: '20px 16px 0',
    [theme.breakpoints.down('sm')]: {
      minWidth: 320,
      height: 'auto',
    },
    [theme.breakpoints.up('md')]: {
      width: 800,
      height: 660,
    }
  },
  closeButton: {
    position: 'absolute',
    right: 15,
    top: 12,
  },
  buttonHolder: {
    textAlign: 'center',
  },
  cancelButton: {
    margin: 20,
    minWidth: 100,
    '&:hover': {
      color: 'inherit'
    },
    '&:disabled': {
      backgroundColor: '#fff',
    },
  },
  saveButton: {
    margin: 20,
    minWidth: 100,
    '&:hover': {
      color: theme.palette.textColor.primary,
    },
    '&:disabled': {
      backgroundColor: '#fff',
    },
  },
  formFlex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  }
});
