export default {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h3': {
      marginBottom: 3,
    },
  },
  addButton: {
    color: '#5A87EF',
    '&:hover': {
      color: '#5A87EF',
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
    '&:focus': {
      color: '#5A87EF',
      backgroundColor: '#fff',
    },
    '&.Mui-disabled': {
      backgroundColor: '#fff',
    }
  }
};
