import { checkUserPermissions } from 'helpers/_helpers';
import permissionsActions from './permissionsActions';

export const getPermissions = (permission) => async (dispatch, getState) => {
  try {
    const userId = localStorage.getItem('userId');
    if (!userId) return false;

    const permissions = getState().permissions;
    const {fullUserPermissions} = getState().personalProfile;

    if (permissions.hasOwnProperty(permission)) return permissions[permission];

    dispatch(permissionsActions.setLoading(true));


    const response = checkUserPermissions([permission], fullUserPermissions )

    dispatch(permissionsActions.setPermissions({ [permission]: response }));
    dispatch(permissionsActions.setLoading(false));
  } catch (e) {
    console.log(e);
  }
};
