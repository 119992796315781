import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { getOptions } from 'helpers/getOptions';
import schedulePageActions from 'store/schedule/schedulePageActions';
import $api from 'http/index';

export const useRemoveSheet = ({ sheetId }) => {
  const dispatch = useDispatch();
  const removeSheet = useCallback(async () => {
    try{
      await $api.delete(`${process.env.REACT_APP_BASE_URL}/sheets/${sheetId}`, {
        ...getOptions(),
      });

      dispatch(schedulePageActions.deleteWorklog({ _id: sheetId }));
    } catch (e) {
      console.log(e);
    }

  }, [sheetId]);

  return { removeSheet };
};
