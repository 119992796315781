export default theme => ({
  selected: {
    backgroundColor: 'rgba(83, 134, 247, 0.05)'
  },
  flexBox: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 16px 6px',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  flexBoxInline: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  userPhotoWrapper: {
    borderRadius: '50%',
    width: 21,
    height: 21,
    marginRight: 10
  },
  dayBorder: {
    border: `1px solid ${theme.palette.textColor.yellow}`,
  },
  nightBorder: {
    border: `1px solid ${theme.palette.textColor.darkBlue}`,
  },
  inactiveBorder: {
    border: `1px solid ${theme.palette.textColor.secondary}`,
  },
  userPhoto: {
    display: 'block',
    height: 19
  },
  name: {
    fontSize: 14,
    fontWeight: 500,
    maxWidth: 295,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  day: {
    color: theme.palette.textColor.yellow,
  },
  night: {
    color: theme.palette.textColor.darkBlue,
  },
  inactive: {
    color: theme.palette.textColor.secondary,
  },
  equipmentBlock: {
    borderRadius: '17px',
    minWidth: 34,
    height: 21,
    color: '#fff',
    maxWidth: 338
  },
  equipmentNumber: {
    height: 19,
    fontSize: 14,
    fontWeight: 500,
    fontFamily: 'Poppins',
    padding: '3px 6px 0',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  severalDays: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.textColor.blue,
    border: `1px solid ${theme.palette.borderColor.blue}`,
    borderRadius: '10px',
    marginLeft: 5,
    width: 56,
    padding: '1px 0 2px',
    textAlign: 'center'
  },
  root: {
    padding: 0,
    marginLeft: 10,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 20,
    height: 20,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: theme.palette.background.blue,
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 6,
      height: 6,
      backgroundColor: theme.palette.background.default,
      content: '""',
      borderRadius: '50%',
      margin: '7px auto'
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
  width1:{
    maxWidth:235
  }
});
