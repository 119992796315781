import { createReducer } from '@reduxjs/toolkit';
import loginActions from './loginActions';


const initialState = {
  profileName: '',
  id: '',
  requesting: false,
  authorized: !!localStorage.getItem('authorization-token'),
  messages: [],
  error: '',
  isTokenValid: false,
  role: 0,
};

const login = createReducer(initialState, {
  [loginActions.setAuthUser]: (state, { payload }) => {
    return {
      ...state,
      authorized: true,
      id: payload.token,
    };
  },
  [loginActions.clearUserData]: (state, _) => {

    return{
    ...state,
    authorized: false,
  }},
});

export default login;
